import moment from 'moment';

import { SET_SHIFT_TIMING, UPDATE_SHIFT_TIMING } from '../constants/ActionTypes';

const initialState = {};

function shiftTiming(state = initialState, action) {
  switch (action.type) {
    case SET_SHIFT_TIMING:
      return action.values.reduce((a, b) => {
        // eslint-disable-next-line no-param-reassign
        a[b.shiftId] = { start: b.start, end: b.end };
        return a;
      }, {});

    case UPDATE_SHIFT_TIMING: {
      const newState = { ...state };

      if (action.start) {
        const start = moment(action.start);
        const end = moment(action.start).add('minutes', action.minutes);
        newState[action.shiftId] = {
          start,
          end,
        };
      }

      if (action.end) {
        const end = moment(action.end);
        const start = moment(action.end).subtract('minutes', action.minutes);
        newState[action.shiftId] = {
          start,
          end,
        };
      }

      return newState;
    }
    default:
      return state;
  }
}

export default shiftTiming;

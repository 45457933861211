import moment from 'moment-timezone';
import { formatPhoneNumbers } from '../constants/Formats';
import { ActiveStaffStatus, PendingStaffStatus, OnholdStaffStatus } from '../constants/DomainTypes';
import { backend } from '../services';

export function prepareAddressObject(address) {
  if (address) {
    // eslint-disable-next-line no-param-reassign
    address.formatted = address.street;
    if (address.city) {
      // eslint-disable-next-line no-param-reassign
      address.formatted += `, ${address.city}`;
    }

    Object.assign(address, { validLatLon: address.lat && address.lon });
  }

  return address;
}

export function prepareEmployeeObject(employee) {
  if (employee) {
    // eslint-disable-next-line no-param-reassign
    employee.employeeName =
      employee.firstName && employee.lastName ? `${employee.firstName} ${employee.lastName}` : null;

    // eslint-disable-next-line no-param-reassign
    employee.compareName = `${employee.lastName}${employee.firstName}`;

    // eslint-disable-next-line no-param-reassign
    employee.isCaregiver = employee.dualCaregiverId != null;

    // eslint-disable-next-line no-param-reassign
    employee.roles = employee.roles
      ? employee.roles.filter(
          r => r === 'scheduler' || r === 'admin' || r === 'hrmanager' || r === 'elearn'
        )
      : employee.roles;

    // eslint-disable-next-line no-param-reassign
    employee.phones = employee.phones ? formatPhoneNumbers(employee.phones) : employee.phones;

    const roleNames = [];
    if (employee.isCaregiver) {
      roleNames.push('Caregiver');
    }
    if (employee.isManager) {
      roleNames.push('Manager');
    }
    if (employee.isCoordinator) {
      roleNames.push('Coordinator');
    }

    if (roleNames.length > 0) {
      // eslint-disable-next-line no-param-reassign
      employee.roleName = roleNames.join(', ');
    } else {
      // eslint-disable-next-line no-param-reassign
      employee.roleName = null;
    }
  }
  return employee;
}

export function prepareEvent(event) {
  if (event.shift) {
    // eslint-disable-next-line no-param-reassign
    event.shift = prepareShortShifts(event.shift);
  }
  return event;
}

export function prepareShortShifts(shift) {
  // eslint-disable-next-line no-param-reassign
  shift.start = moment.utc(shift.start);
  // eslint-disable-next-line no-param-reassign
  shift.end = moment.utc(shift.end);

  const duration = moment.duration(shift.end.diff(shift.start));
  // eslint-disable-next-line no-param-reassign
  shift.hours = duration.asHours();
  // eslint-disable-next-line no-param-reassign
  shift.minutes = duration.asMinutes();

  // eslint-disable-next-line no-param-reassign
  shift.timestamp = moment.utc(shift.timestamp);

  // eslint-disable-next-line no-param-reassign
  shift.timezone = shift.timezone
    ? moment.tz.zone(shift.timezone)
    : moment.tz.zone(moment.tz.guess());

  return shift;
}

export function prepareCaregiverObject(caregiver) {
  if (caregiver && !caregiver.prepared) {
    // eslint-disable-next-line no-param-reassign
    caregiver.prepared = true;

    // eslint-disable-next-line no-param-reassign
    caregiver.caregiverName = `${caregiver.firstName} ${caregiver.lastName}`;

    // eslint-disable-next-line no-param-reassign
    caregiver.address = prepareAddressObject(caregiver.address);

    // eslint-disable-next-line no-param-reassign
    caregiver.primaryContact = prepareEmployeeObject(caregiver.primaryContact);

    // eslint-disable-next-line no-param-reassign
    caregiver.manager = prepareEmployeeObject(caregiver.manager);

    // eslint-disable-next-line no-param-reassign
    caregiver.casePref = caregiver.casePref ? caregiver.casePref : {};

    const mapWarnings = () => {
      const settings = backend.getCachedSettings();
      const ssnRequired = settings && settings.ssnRequired != null ? settings.ssnRequired : true;
      const duplicatePhoneCgNames = caregiver.phones
        ? caregiver.phones
            .filter(i => i.duplicates)
            .map(p => p.duplicates.map(d => `${d.firstName} ${d.lastName}`).join(', '))
            .reduce((a, b) => a.concat(b), [])
        : [];
      const duplicatePhones =
        duplicatePhoneCgNames.length > 0
          ? [
              {
                type: 'duplicatePhones',
                iconName: 'phone square',
                message: 'Phone number already in use',
              },
            ]
          : [];
      const phoneMissing = !(caregiver.phones && !!caregiver.phones.find(p => p.type === 3))
        ? [
            {
              type: 'phoneMissing',
              iconName: 'phone square',
              message: 'Cell phone number is missing',
            },
          ]
        : [];
      const duplicateEmailCgNames = caregiver.duplicateEmails
        ? caregiver.duplicateEmails.map(e => `${e.firstName} ${e.lastName}`).join(', ')
        : null;
      const duplicateEmails = duplicateEmailCgNames
        ? [{ type: 'duplicateEmails', iconName: 'mail', message: 'Email already in use' }]
        : [];
      const emailMissing = !caregiver.email
        ? [{ type: 'emailMissing', iconName: 'mail', message: 'Email is missing' }]
        : [];
      const ssnMissing =
        !caregiver.maskedSsn && ssnRequired
          ? [{ type: 'ssnMissing', iconName: '', message: 'SSN is missing' }]
          : [];
      const dobMissing = !caregiver.dob
        ? [{ type: 'dobMissing', iconName: '', message: 'Birthday is missing' }]
        : [];
      const staffStatus =
        caregiver.staffStatus !== ActiveStaffStatus
          ? [{ type: 'staffStatus', iconName: '', message: 'Caregiver status is not active' }]
          : [];

      const staffStatusForAppInvite =
        caregiver.staffStatus !== ActiveStaffStatus &&
        caregiver.staffStatus !== PendingStaffStatus &&
        caregiver.staffStatus !== OnholdStaffStatus &&
        caregiver.status !== 'active'
          ? [
              {
                type: 'staffStatusForAppInvite',
                iconName: '',
                message: 'Caregiver is not invitable',
              },
            ]
          : [];
      const locationMissing =
        caregiver.address &&
        !(
          caregiver.address.city &&
          caregiver.address.street &&
          caregiver.address.zip &&
          caregiver.address.state
        )
          ? [{ type: 'locationMissing', iconName: '', message: 'Location is missing' }]
          : [];
      return duplicatePhones
        .concat(phoneMissing)
        .concat(emailMissing)
        .concat(duplicateEmails)
        .concat(ssnMissing)
        .concat(dobMissing)
        .concat(staffStatus)
        .concat(staffStatusForAppInvite)
        .concat(locationMissing);
    };

    // eslint-disable-next-line no-param-reassign
    caregiver.warnings = mapWarnings();

    const contactInfoAvailable = !(
      !!caregiver.warnings.find(p => p.type === 'emailMissing') &&
      !!caregiver.warnings.find(p => p.type === 'phoneMissing')
    );
    const requiredInfoAvailable = !(
      !!caregiver.warnings.find(p => p.type === 'ssnMissing') ||
      !!caregiver.warnings.find(p => p.type === 'dobMissing') ||
      !!caregiver.warnings.find(p => p.type === 'staffStatus')
    );
    const noDuplicates = !(
      !!caregiver.warnings.find(p => p.type === 'duplicatePhones') ||
      !!caregiver.warnings.find(p => p.type === 'duplicateEmails')
    );
    const locationInfoAvailable = !caregiver.warnings.find(p => p.type === 'locationMissing');
    // eslint-disable-next-line no-param-reassign
    caregiver.isInvitable =
      contactInfoAvailable && requiredInfoAvailable && noDuplicates && locationInfoAvailable;

    const requiredInfoForAppInvite = !(
      !!caregiver.warnings.find(p => p.type === 'ssnMissing') ||
      !!caregiver.warnings.find(p => p.type === 'dobMissing') ||
      !!caregiver.warnings.find(p => p.type === 'staffStatusForAppInvite')
    );
    // eslint-disable-next-line no-param-reassign
    caregiver.appInvitable =
      contactInfoAvailable && requiredInfoForAppInvite && noDuplicates && locationInfoAvailable;

    const cgInfoAvailable = !(
      !!caregiver.warnings.find(p => p.type === 'ssnMissing') ||
      !!caregiver.warnings.find(p => p.type === 'dobMissing')
    );
    // eslint-disable-next-line no-param-reassign
    caregiver.infoCompleted = contactInfoAvailable && cgInfoAvailable && noDuplicates;

    // eslint-disable-next-line no-param-reassign
    caregiver.shifts = caregiver.shifts
      ? caregiver.shifts.map(s => prepareShortShifts(s)).sort((a, b) => a.start.isAfter(b.start))
      : null;

    // eslint-disable-next-line no-param-reassign
    caregiver.phones = caregiver.phones ? formatPhoneNumbers(caregiver.phones) : [];
    const addPhoneType = phoneType => {
      if (!caregiver.phones.find(i => i.type === phoneType)) {
        caregiver.phones.push({ type: phoneType, number: '' });
      }
    };
    addPhoneType(1);
    addPhoneType(3);

    // eslint-disable-next-line no-param-reassign
    caregiver.clientExclusion = caregiver.clientExclusion
      ? caregiver.clientExclusion.map(cg => prepareClientObject(cg))
      : caregiver.clientExclusion;
  }

  return caregiver;
}

export function prepareClientObject(client) {
  if (client) {
    // eslint-disable-next-line no-param-reassign
    client.clientName = `${client.firstName} ${client.lastName}`;
    // eslint-disable-next-line no-param-reassign
    client.address = prepareAddressObject(client.address);

    // eslint-disable-next-line no-param-reassign
    client.primaryContact = prepareEmployeeObject(client.primaryContact);

    // eslint-disable-next-line no-param-reassign
    client.recentCaregivers = client.recentCaregivers
      ? client.recentCaregivers.map(cg => prepareCaregiverObject(cg))
      : client.recentCaregivers;

    // eslint-disable-next-line no-param-reassign
    client.caregiverExclusion = client.caregiverExclusion
      ? client.caregiverExclusion.map(cg => prepareCaregiverObject(cg))
      : client.caregiverExclusion;

    // eslint-disable-next-line no-param-reassign
    client.manager = prepareEmployeeObject(client.manager);

    // eslint-disable-next-line no-param-reassign
    client.phones = client.phones ? formatPhoneNumbers(client.phones) : client.phones;

    // eslint-disable-next-line no-param-reassign
    client.referralDate = client.referralDate ? client.referralDate : null;

    // eslint-disable-next-line no-param-reassign
    client.startDate = client.startDate ? client.startDate : null;

    // eslint-disable-next-line no-param-reassign
    client.holdDate = client.holdDate ? client.holdDate : null;

    // eslint-disable-next-line no-param-reassign
    client.dischargeDate = client.dischargeDate ? client.dischargeDate : null;

    // eslint-disable-next-line no-param-reassign
    client.cancelDate = client.cancelDate ? client.cancelDate : null;
  }
  return client;
}

export function prepareShiftObject(shift, useMoment) {
  // eslint-disable-next-line no-param-reassign
  shift.client = prepareClientObject(shift.client);

  // eslint-disable-next-line no-param-reassign
  shift.timezone = shift.timezone
    ? moment.tz.zone(shift.timezone)
    : moment.tz.zone(moment.tz.guess());

  // eslint-disable-next-line no-param-reassign
  shift.invites = Number.isInteger(shift.invites) ? shift.invites : 0;

  // eslint-disable-next-line no-param-reassign
  shift.invitationsSent = shift.assignedCaregivers ? shift.assignedCaregivers.length : 0;

  // eslint-disable-next-line no-param-reassign
  shift.invitationsAccepted = shift.assignedCaregivers
    ? shift.assignedCaregivers.filter(
        cg => cg.shiftAssigmentStatus === 'assigned' || cg.shiftAssigmentStatus === 'applied'
      ).length
    : 0;

  const latestNote = shift.assignedCaregivers
    ? shift.assignedCaregivers.reduce((a, b) => {
        if (!b.offeredDate) {
          return a;
        }

        if (a) {
          return b.offeredDate > a.offeredDate
            ? { offeredDate: b.offeredDate, invitationNotes: b.invitationNotes }
            : a;
        }
        return { offeredDate: b.offeredDate, invitationNotes: b.invitationNotes };
      }, null)
    : undefined;

  // eslint-disable-next-line no-param-reassign
  shift.latestInvitationNote = latestNote ? latestNote.invitationNotes : '';

  // eslint-disable-next-line no-param-reassign
  shift.assignedCaregivers = shift.assignedCaregivers
    ? shift.assignedCaregivers
        .filter(cg => cg.shiftAssigmentStatus === 'assigned')
        .map(cg => prepareCaregiverObject(cg))
    : shift.assignedCaregivers;

  let duration;
  if (useMoment) {
    if (shift.timezone) {
      // eslint-disable-next-line no-param-reassign
      shift.start = moment.tz(shift.start, shift.timezone.name);
      // eslint-disable-next-line no-param-reassign
      shift.end = moment.tz(shift.end, shift.timezone.name);
    } else {
      // eslint-disable-next-line no-param-reassign
      shift.start = moment.utc(shift.start);
      // eslint-disable-next-line no-param-reassign
      shift.end = moment.utc(shift.end);
    }

    duration = moment.duration(shift.end.diff(shift.start));
  } else {
    duration = moment.duration(moment.utc(shift.end).diff(moment.utc(shift.start)));
  }

  // eslint-disable-next-line no-param-reassign
  shift.hours = duration.asHours();
  // eslint-disable-next-line no-param-reassign
  shift.minutes = duration.asMinutes();

  const standaloneChecks =
    shift.source !== 'sam' && shift.source !== 'hcp'
      ? shift.status !== '04' && shift.status !== '09' && shift.status !== '02'
      : true;

  // eslint-disable-next-line no-param-reassign
  shift.actionable =
    shift.shiftAssigmentStatus !== 'assigned' &&
    shift.status !== '10' &&
    standaloneChecks &&
    shift.client &&
    shift.client.address &&
    shift.client.address.validLatLon &&
    moment().isBefore(moment(shift.end));

  return shift;
}

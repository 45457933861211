/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  & > h1 {
    margin: 0;
    color: #4a4a4a;
    font-size: 16px;
    margin-right: 2.5rem;
  }
  & > p {
    margin: 0;
    color: #9b9b9b;
    font-size: 12px;
    margin-right: 1rem;
  }
`;

export const AddCaregiverContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 1rem;
  & > p {
    margin: 0;
    color: #4a4a4a;
    font-size: 12px;
    font-weight: bold;
  }
  & .care-round-button {
    margin-left: 1rem;
  }
`;

import {
  GET_CAREGIVER_SURVEY_REQUEST,
  GET_CAREGIVER_SURVEY_SUCCESS,
  GET_CAREGIVER_SURVEY_FAILURE,
  GET_CLIENT_SURVEY_REQUEST,
  GET_CLIENT_SURVEY_SUCCESS,
  GET_CLIENT_SURVEY_FAILURE,
  GET_SURVEY_SUMMERY_REQUEST,
  GET_SURVEY_SUMMERY_SUCCESS,
  GET_SURVEY_SUMMERY_FAILURE,
  GET_SURVEY_DETAILS_REQUEST,
  GET_SURVEY_DETAILS_SUCCESS,
  GET_SURVEY_DETAILS_FAILURE,
} from '../../constants/ActionTypes';

const initialState = {
  loading: false,
  caregiverSurvey: null,
  surveySummary: [],
};

const startState = JSON.parse(JSON.stringify(initialState));

function covidSurvey(state = startState, action) {
  switch (action.type) {
    case GET_CAREGIVER_SURVEY_REQUEST:
      return { ...state, loading: true };
    case GET_CAREGIVER_SURVEY_SUCCESS:
      return { ...state, loading: false, caregiverSurvey: action.results };
    case GET_CAREGIVER_SURVEY_FAILURE:
      return { ...state, loading: false };
    case GET_CLIENT_SURVEY_REQUEST:
      return { ...state, loading: true };
    case GET_CLIENT_SURVEY_SUCCESS:
      return { ...state, loading: false, clientSurvey: action.results };
    case GET_CLIENT_SURVEY_FAILURE:
      return { ...state, loading: false };
    case GET_SURVEY_SUMMERY_REQUEST:
      return { ...state, loading: true };
    case GET_SURVEY_SUMMERY_SUCCESS:
      return { ...state, loading: false, surveySummary: action.results };
    case GET_SURVEY_SUMMERY_FAILURE:
      return { ...state, loading: false };
    case GET_SURVEY_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_SURVEY_DETAILS_SUCCESS:
      return { ...state, loading: false, surveyDetails: action.results };
    case GET_SURVEY_DETAILS_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export default covidSurvey;

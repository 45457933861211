/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { Component } from 'react';
import { Button, Image, Loader, Dropdown, Popup, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as CompHelper from './complianceHelpers';
import * as compTypes from './ComplianceMedicalTypes';
import { caregiverActions } from '../../../actions';
import CGComplianceUIActions from '../../../actions/CGComplianceUIActions';
import { dateFormat } from '../../../helpers/common';

type Props = {
  blocks: Array<Block>;
  caregiverComplianceUI: any;
  caregiverCompliance: any;
  dispatch: (arg: any) => void;
  caregiver: any;
};

type State = {
  closeEnabled: boolean;
  showResultEditPanel: boolean;
  showDeclinationPanel: boolean;
  showConsentPanel: boolean;
  draftState: DraftState;
};

type DraftState = {
  declinationDate?: compTypes.DraftStateField;
  consentDate?: compTypes.DraftStateField;
  hBv1Date?: compTypes.DraftStateField;
  hBv2Date?: compTypes.DraftStateField;
  hBv3Date?: compTypes.DraftStateField;
  series1CompletionDate?: compTypes.DraftStateField;
  series1Result?: compTypes.DraftStateField;
  hBv4Date?: compTypes.DraftStateField;
  hBv5Date?: compTypes.DraftStateField;
  hBv6Date?: compTypes.DraftStateField;
};

interface Block extends compTypes.Blocks {
  fields: Array<BlockField>;
  name:
    | 'Hepatitis B Test Consent'
    | 'Hepatitis B Test Series 1'
    | 'Hepatitis B Test Series 2'
    | 'Hepatitis B Test Declination';
}

interface HBTBlocks {
  [key: string]: Block;
  testSeriesOne: Block;
  consent: Block;
  decline: Block;
  testSeriesTwo: Block;
}

interface BlockField extends compTypes.BlockField {
  filesDownloaded?: boolean;
  downloadLinks: FormLinks;
}

type FormLinks = {
  [key: string]: string;
};

class ComplianceHepBTest extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showResultEditPanel: false,
      showDeclinationPanel: false,
      showConsentPanel: false,
      closeEnabled: false,
      draftState: {
        declinationDate: undefined,
        consentDate: undefined,
        hBv1Date: undefined,
        hBv2Date: undefined,
        hBv3Date: undefined,
        series1CompletionDate: undefined,
        series1Result: undefined,
        hBv4Date: undefined,
        hBv5Date: undefined,
        hBv6Date: undefined,
      },
    };
  }

  public componentDidMount = () => {
    this.setFieldsToState();
  };

  private readonly setFieldsToState = (): void => {
    const { blocks } = this.props;
    const [testSeriesOne] = blocks.filter((b: Block) => b.name === 'Hepatitis B Test Series 1');
    const [consent] = blocks.filter((b: Block) => b.name === 'Hepatitis B Test Consent');
    const [decline] = blocks.filter((b: Block) => b.name === 'Hepatitis B Test Declination');
    const [testSeriesTwo] = blocks.filter((b: Block) => b.name === 'Hepatitis B Test Series 2');
    const [declinedDate] = decline
      ? decline?.fields.filter((f: BlockField) => f.type === 'date')
      : [];
    const [consentDate] = consent
      ? consent?.fields.filter((f: BlockField) => f.type === 'date')
      : [];

    const [hBv1Date] = testSeriesOne.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Hbv1Date'
    );
    const [hBv2Date] = testSeriesOne.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Hbv2Date'
    );
    const [hBv3Date] = testSeriesOne.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Hbv3Date'
    );
    const [series1completion] = testSeriesOne.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Completion'
    );
    const [series1Result] = testSeriesOne.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === 'Result'
    );

    const [hBv4Date] = testSeriesTwo.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Hbv4Date'
    );
    const [hBv5Date] = testSeriesTwo.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Hbv5Date'
    );
    const [hBv6Date] = testSeriesTwo.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Hbv6Date'
    );

    this.setState(s => ({
      ...s,
      draftState: {
        ...s.draftState,
        declinationDate: {
          value: declinedDate?.value ? moment(declinedDate.value) : '',
          id: declinedDate?.id,
        },
        consentDate: {
          value: consentDate?.value ? moment(consentDate.value) : '',
          id: consentDate?.id,
        },
        hBv1Date: {
          value: hBv1Date.value ? moment(hBv1Date.value) : '',
          id: hBv1Date.id,
        },
        hBv2Date: {
          value: hBv2Date.value ? moment(hBv2Date.value) : '',
          id: hBv2Date.id,
        },
        hBv3Date: {
          value: hBv3Date.value ? moment(hBv3Date.value) : '',
          id: hBv3Date.id,
        },
        series1CompletionDate: {
          value: series1completion.value ? moment(series1completion.value) : '',
          id: series1completion.id,
        },
        series1Result: {
          value: series1Result.value || '',
          id: series1Result.id,
        },
        hBv4Date: {
          value: hBv4Date.value ? moment(hBv4Date.value) : '',
          id: hBv4Date.id,
        },
        hBv5Date: {
          value: hBv5Date.value ? moment(hBv5Date.value) : '',
          id: hBv5Date.id,
        },
        hBv6Date: {
          value: hBv6Date.value ? moment(hBv6Date.value) : '',
          id: hBv6Date.id,
        },
      },
    }));
  };

  private readonly addCompletion = (): void => {
    this.setState(s => ({
      ...s,
      showResultEditPanel: !s.showResultEditPanel,
      closeEnabled: true,
    }));
  };

  private readonly openDeclinePanel = (): void => {
    this.setState(s => ({
      ...s,
      showDeclinationPanel: !s.showDeclinationPanel,
      closeEnabled: true,
    }));
  };

  private readonly openConsentPanel = (): void => {
    this.setState(s => ({
      ...s,
      showConsentPanel: !s.showConsentPanel,
      closeEnabled: true,
    }));
  };

  private readonly onClose = (): void => {
    const { dispatch } = this.props;
    this.setFieldsToState();
    this.setState(
      s => ({
        ...s,
        closeEnabled: false,
        showResultEditPanel: false,
        showDeclinationPanel: false,
        showConsentPanel: false,
      }),
      () => dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: [] }))
    );
  };

  private readonly onFieldChanged = (field: BlockField, value: any) => {
    const { caregiverComplianceUI, dispatch } = this.props;
    const { dirtyMedicalBlocks } = caregiverComplianceUI;

    const changedValue = value;

    let theField = dirtyMedicalBlocks.find((f: BlockField) => f.id === field.id);

    if (!theField) {
      theField = JSON.parse(JSON.stringify(field));
      dirtyMedicalBlocks.push(theField);
    }
    theField.value = changedValue;

    if (field.type === 'date') {
      this.onDateChange(value, field.id);
    }

    if (field.type === 'text') {
      this.setState(s => ({
        ...s,
        draftState: { ...s.draftState, series1Result: { value, id: field.id } },
      }));
    }

    dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks }));
  };

  private readonly onDateChange = (date: any, selected: number): void => {
    const { draftState } = this.state;
    let valueToSet: string;
    let selectedId: number;
    /* eslint-disable-next-line no-restricted-syntax */
    for (const [key, value] of Object.entries(draftState)) {
      if (value?.id === selected) {
        valueToSet = key;
        selectedId = value.id;
      }
    }

    this.setState(s => ({
      ...s,
      draftState: {
        ...s.draftState,
        [valueToSet]: {
          value: date && moment(date).isBefore(moment()) ? moment(date) : '',
          id: selectedId,
        },
      },
    }));
  };

  private readonly uploadDocument = (e: any, fieldId: number) => {
    const { caregiver, dispatch } = this.props;
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      dispatch(caregiverActions.uploadComplianceDocument(fieldId, file, caregiver.id));
    }
    e.target.value = '';
  };

  private readonly documentRemoved = (fieldId: number, filename: string) => {
    const { caregiver, dispatch } = this.props;
    dispatch(caregiverActions.removeComplianceDocument(caregiver.id, fieldId, filename));
  };

  private readonly updateMedicalBlock = () => {
    const { caregiver, blocks, caregiverComplianceUI, dispatch } = this.props;
    const { dirtyMedicalBlocks } = caregiverComplianceUI;

    if (Object.keys(dirtyMedicalBlocks).length) {
      const dirtyFieldsToSave: Array<BlockField> = [];
      dirtyMedicalBlocks.forEach((s: BlockField) => {
        const [block] = blocks.filter((a: Block) =>
          a.fields.find((f: BlockField) => f.id === s.id)
        );
        const b = block?.fields.find((a: BlockField) => a.id === s.id);
        if (b && ((b.value && b.value !== s.value) || (!b.value && s.value))) {
          dirtyFieldsToSave.push(s);
        }
      });

      if (dirtyFieldsToSave.length) {
        dispatch(
          caregiverActions.setCaregiverComplianceBlockFields(caregiver.id, dirtyFieldsToSave)
        );
        dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: [] }));
      }
    }

    this.setState(prevState => ({
      ...prevState,
      showResultEditPanel: false,
      showDeclinationPanel: false,
      showConsentPanel: false,
      closeEnabled: false,
    }));
  };

  private readonly onRemove = () => {
    const dirtyFieldToClear: Array<BlockField> = [];
    const { caregiver, dispatch, blocks } = this.props;
    blocks.forEach((block: Block) =>
      block.fields.forEach(f => {
        if (f.type === 'document' && f.value?.length) {
          f.value.forEach((v: any) => this.documentRemoved(f.id, v.filename));
        } else {
          dirtyFieldToClear.push({
            id: f.id,
            name: f.name,
            type: f.type,
            fixed: 1,
            value: null,
            isDirty: true,
            downloadLinks: {},
          });
        }
      })
    );
    dispatch(caregiverActions.setCaregiverComplianceBlockFields(caregiver.id, dirtyFieldToClear));

    this.setState(
      s => ({
        ...s,
        draftState: {
          ...s.draftState,
          declinationDate: {
            ...s.draftState.declinationDate,
            value: '',
          },
          consentDate: {
            ...s.draftState.consentDate,
            value: '',
          },
          hBv1Date: {
            ...s.draftState.hBv1Date,
            value: '',
          },
          hBv2Date: {
            ...s.draftState.hBv2Date,
            value: '',
          },
          hBv3Date: {
            ...s.draftState.hBv3Date,
            value: '',
          },
          series1CompletionDate: {
            ...s.draftState.series1CompletionDate,
            value: '',
          },
          series1Result: {
            ...s.draftState.series1Result,
            value: '',
          },
          hBv4Date: {
            ...s.draftState.hBv4Date,
            value: '',
          },
          hBv5Date: {
            ...s.draftState.hBv5Date,
            value: '',
          },
          hBv6Date: {
            ...s.draftState.hBv6Date,
            value: '',
          },
        },
        showResultEditPanel: false,
        closeEnabled: false,
      }),
      () => dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: [] }))
    );
  };

  private readonly showRemove = () => {
    return (
      <span
        className='removeButton'
        role='presentation'
        style={{ paddingRight: '10px', width: '40px' }}
        onClick={this.onRemove}
      >
        remove
      </span>
    );
  };

  private readonly renderDocumentField = (documentField: BlockField) => {
    const { caregiverCompliance } = this.props;
    const { uploadingDocument } = caregiverCompliance;
    let addNewClass = 'complianceDocumentItem';
    if (uploadingDocument) addNewClass += ' disabled';

    const previewDocument = (link: string, filename: string) => {
      if (filename.endsWith('.pdf')) {
        return (
          <a href={link}>
            <div style={{ textAlign: 'center' }}>.pdf</div>
          </a>
        );
      }
      return <Image width='100px' height='100px' src={link} />;
    };
    return (
      <div className='complianceDocumentsContainer' key={documentField.id}>
        {!documentField.filesDownloaded && documentField.value && !!documentField.value.length && (
          <div className='complianceDocumentItem'>
            <div style={{ height: '100px' }}>
              <Loader active inline />
            </div>
          </div>
        )}
        {documentField.filesDownloaded &&
        documentField.downloadLinks &&
        !!documentField.value?.length ? (
          documentField.value.map((file: any) => {
            return (
              <div className='complianceDocumentItem' key={file}>
                {previewDocument(documentField.downloadLinks[file.filename], file.filename)}
                <Button
                  circular
                  icon='delete'
                  className='documentRemoveButton'
                  onClick={() => this.documentRemoved(documentField.id, file.filename)}
                />
              </div>
            );
          })
        ) : (
          <div className={addNewClass}>
            {uploadingDocument ? (
              <>
                {uploadingDocument === documentField.id ? (
                  <div style={{ height: '100px' }}>
                    <Loader active inline />
                  </div>
                ) : (
                  <p style={{ color: '#ccc' }}>+</p>
                )}
              </>
            ) : (
              <>
                <input
                  type='file'
                  accept='.jpg,.png,.jpeg,.gif,.pdf'
                  id={`fileinput${documentField.id}`}
                  className='fileinput'
                  style={{ display: 'none' }}
                  onChange={e => this.uploadDocument(e, documentField.id)}
                />
                <label htmlFor={`fileinput${documentField.id}`} style={{ padding: '36px' }}>
                  +
                </label>
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  public render(): JSX.Element {
    const { blocks } = this.props;
    const {
      showResultEditPanel,
      showDeclinationPanel,
      showConsentPanel,
      closeEnabled,
      draftState,
    } = this.state;

    const {
      declinationDate,
      consentDate,
      hBv1Date,
      hBv2Date,
      hBv3Date,
      series1CompletionDate,
      series1Result,
      hBv4Date,
      hBv5Date,
      hBv6Date,
    } = draftState;

    const { caregiverComplianceUI } = this.props;

    const { dirtyMedicalBlocks } = caregiverComplianceUI;

    const showDeclineConsentPanel = showConsentPanel || showDeclinationPanel;
    const consentDeclinationText = showConsentPanel ? 'consent' : 'declination';

    const [testSeriesOne] = blocks.filter((b: Block) => b.name === 'Hepatitis B Test Series 1');
    const [consent] = blocks.filter((b: Block) => b.name === 'Hepatitis B Test Consent');
    const [decline] = blocks.filter((b: Block) => b.name === 'Hepatitis B Test Declination');
    const [testSeriesTwo] = blocks.filter((b: Block) => b.name === 'Hepatitis B Test Series 2');

    const [declinedDateField] = decline ? decline.fields.filter(f => f.type === 'date') : [];
    const [declinedDocumentField] = decline
      ? decline.fields?.filter(f => f.type === 'document')
      : [];

    const [consentDateField] = consent?.fields?.filter(f => f.type === 'date');
    const [consentDocumentField] = consent?.fields?.filter(f => f.type === 'document');

    let latestDocumentUpdated;

    if (consentDateField?.value && declinedDateField?.value) {
      latestDocumentUpdated = moment(consentDateField?.value).isAfter(
        moment(declinedDateField?.value)
      )
        ? 'Consent'
        : 'Declination';
    } else if (consentDateField?.value || declinedDateField?.value) {
      latestDocumentUpdated = consentDateField?.value ? 'Consent' : 'Declination';
    }

    const [hBv1DateField] = testSeriesOne.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Hbv1Date'
    );
    const [hBv2DateField] = testSeriesOne.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Hbv2Date'
    );
    const [hBv3DateField] = testSeriesOne.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Hbv3Date'
    );
    const [series1completionField] = testSeriesOne.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Completion'
    );
    const [series1ResultField] = testSeriesOne.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === 'Result'
    );

    const [hBv4DateField] = testSeriesTwo.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Hbv4Date'
    );
    const [hBv5DateField] = testSeriesTwo.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Hbv5Date'
    );
    const [hBv6DateField] = testSeriesTwo.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Hbv6Date'
    );

    const HBTBlocks: HBTBlocks = {
      testSeriesOne,
      consent,
      decline,
      testSeriesTwo,
    };

    const seriesOneStarted = !!hBv1Date?.value;
    const seriesTwoStarted = !!hBv4Date?.value;
    const seriesTwoEnd = !!hBv4Date?.value && !!hBv5Date?.value && !!hBv6Date?.value;
    const consentFormCompleted = !!consentDateField?.value;
    const declinedFormCompleted = !!declinedDateField?.value;
    const seriesOnePassed = series1ResultField?.value === '-' || series1ResultField?.value === 'I';
    const seriesOneFails = series1ResultField?.value === '+' || series1ResultField?.value === 'NI';

    const blockCompleted =
      testSeriesOne.completed && (seriesOnePassed || seriesTwoEnd || declinedFormCompleted);

    const showConsentDeclineInfo = consentFormCompleted || declinedFormCompleted;

    let formLink;

    if (showConsentDeclineInfo) {
      if (
        latestDocumentUpdated === 'Consent' &&
        Object.getOwnPropertyNames(consentDocumentField?.downloadLinks || {}).length
      ) {
        formLink = consentDocumentField?.downloadLinks[consentDocumentField?.value[0]?.filename];
      } else if (
        latestDocumentUpdated === 'Declination' &&
        Object.getOwnPropertyNames(declinedDocumentField?.downloadLinks || {}).length
      ) {
        formLink = declinedDocumentField?.downloadLinks[declinedDocumentField?.value[0]?.filename];
      }
    }

    const showSeriesOneInfoPanel =
      (showConsentDeclineInfo && seriesOneStarted && !showResultEditPanel) || seriesOneFails;

    const showRemoveButton =
      consentFormCompleted || declinedFormCompleted || showSeriesOneInfoPanel;

    const showSeriesTwoInfoPane =
      showConsentDeclineInfo && seriesTwoStarted && !showResultEditPanel;

    const incomplete = testSeriesOne?.dosesInfo?.inProcess || testSeriesTwo?.dosesInfo?.inProcess;

    const titleStatusColor = () => {
      if (!testSeriesOne.completed) {
        return 'redTitle';
      }
      if (incomplete) {
        return 'warningTitle';
      }
      return 'blackTitle';
    };

    return (
      <div className='infoPanelMedicalBlock'>
        <div className='compliances-tab-header'>
          <img src={`/compliance/${testSeriesOne.icon}`} alt='compliance-icon' />
          <p className={titleStatusColor()} style={{ margin: '0' }}>
            Hepatitis B Test
          </p>
          {incomplete ? <p className='incomplete-warning'> (Incomplete)</p> : ''}
          {!HBTBlocks.testSeriesOne.completed ? (
            <img
              style={{ marginLeft: '10px' }}
              src='/compliance/attention.svg'
              alt='attention-icon'
            />
          ) : (
            ''
          )}
        </div>
        <div>
          <p className='compliance-medium-gray'>
            Hepatitis B testing is a <b className='compliance-charcoal-text-color'>One-time</b>{' '}
            requirement needed to be in compliance to work. Caregivers must receive, or prove they
            have received the vaccination and undergo a test to determine immunity.
          </p>
        </div>
        {showConsentDeclineInfo && (
          <div className='hep-b-concent-declined-info'>
            <p
              className='compliance-medium-gray'
              style={{ display: 'inline-block', marginRight: '10px' }}
            >
              {`${latestDocumentUpdated} Form Submitted: ${
                latestDocumentUpdated === 'Consent'
                  ? moment(consentDate?.value).format(dateFormat)
                  : moment(declinationDate?.value).format(dateFormat)
              }`}
            </p>
            {formLink ? (
              <a className='compliance-form-link' href={formLink} target='_blank' rel='noreferrer'>
                {`Review ${latestDocumentUpdated} Form`}
              </a>
            ) : (
              <p
                className='compliance-add-decline-text'
                style={{ display: 'inline-block' }}
                onClick={
                  latestDocumentUpdated === 'Consent'
                    ? this.openConsentPanel
                    : this.openDeclinePanel
                }
              >
                {`Add ${latestDocumentUpdated} Form`}
              </p>
            )}
          </div>
        )}
        {showSeriesOneInfoPanel && (
          <div className='hep-b-test-series-one-container compliance-info-panel'>
            <h5 className='blackTitle'>HEP B SERIES 1</h5>
            <div className='hep-b-dose-dates-container'>
              <div className='label-input-block-container'>
                <p className='compliance-block-completion-field-text'>Series 1 Dose 1 Date</p>
                <CompHelper.DateText date={hBv1DateField?.value} />
              </div>
              <div className='label-input-block-container'>
                <p className='compliance-block-completion-field-text'>Series 1 Dose 2 Date</p>
                <CompHelper.DateText date={hBv2DateField?.value} />
              </div>
              <div className='label-input-block-container'>
                <p className='compliance-block-completion-field-text'>Series 1 Dose 3 Date</p>
                <CompHelper.DateText date={hBv3DateField?.value} />
              </div>
            </div>
            <div className='hep-b-test-dates-container'>
              <div className='label-input-block-container'>
                <p className='compliance-block-completion-field-text'>Series 1 Test Date</p>
                <CompHelper.DateText date={series1completionField?.value} />
              </div>
              <div className='label-input-block-container'>
                <p className='compliance-block-completion-field-text'>Series 1 Test Result</p>
                <CompHelper.ResultText value={series1Result?.value} group='HBT' />
              </div>
            </div>
            <div className='multi-dose-info-container'>
              {incomplete && !seriesOneFails && (
                <p>
                  This item needs to be updated by{' '}
                  <strong>{moment(testSeriesOne.expirationDate).format(dateFormat)}</strong>{' '}
                  otherwise it will expire.
                </p>
              )}
            </div>
          </div>
        )}
        {seriesOneFails && (
          <div className='series-two-divider compliance-info-panel'>
            <h3 className='compliance-add-completion-panel-header'>hep b series 2</h3>
            <p className='compliance-block-completion-field-description'>
              Due to failing the Series 1 test this caregiver must undergo another course of
              treatment and testing to be compliant.
            </p>
            {showSeriesTwoInfoPane && (
              <>
                <h5 className='blackTitle'>HEP B SERIES 2</h5>
                <div className='hep-b-dose-dates-container'>
                  <div className='label-input-block-container'>
                    <p className='compliance-block-completion-field-text'>Series 2 Dose 1 Date</p>
                    <CompHelper.DateText date={hBv4Date?.value} />
                  </div>
                  <div className='label-input-block-container'>
                    <p className='compliance-block-completion-field-text'>Series 2 Dose 2 Date</p>
                    <CompHelper.DateText date={hBv5Date?.value} />
                  </div>
                  <div className='label-input-block-container'>
                    <p className='compliance-block-completion-field-text'>Series 2 Dose 3 Date</p>
                    <CompHelper.DateText date={hBv6Date?.value} />
                  </div>
                </div>
                <div className='multi-dose-info-container'>
                  {incomplete && (
                    <p>
                      This item needs to be updated by{' '}
                      <strong>{moment(testSeriesTwo.expirationDate).format(dateFormat)}</strong>{' '}
                      otherwise it will expire.
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        )}
        {closeEnabled && seriesOneFails && (
          <Image className='tinyCloseButton' src='/close-icon.svg' onClick={this.onClose} />
        )}

        {showResultEditPanel && seriesOneFails && (
          <div className='hep-b-test-series-one-container compliance-add-completion-panel'>
            <h3 className='compliance-add-completion-panel-header'>
              Enter Hep B Series 2 Completion Details
            </h3>
            <p className='compliance-block-completion-field-description'>
              Due to failing the Series 1 test this caregiver must undergo another course of
              treatment and testing to be compliant.
            </p>
            <div className='hep-b-dose-dates-column-container'>
              <div className='label-input-in-line-container'>
                <p className='compliance-block-completion-field-text'>Dose 1 Date:</p>
                <CompHelper.ComplianceDatePicker
                  field={hBv4DateField}
                  state={hBv4Date}
                  disabled={false}
                  onChange={this.onFieldChanged}
                />
              </div>
              <div className='label-input-in-line-container'>
                <p className='compliance-block-completion-field-text'>Dose 2 Date:</p>
                <CompHelper.ComplianceDatePicker
                  field={hBv5DateField}
                  state={hBv5Date}
                  disabled={!hBv4Date?.value}
                  onChange={this.onFieldChanged}
                />
              </div>
              <div className='label-input-in-line-container'>
                <p className='compliance-block-completion-field-text'>Dose 3 Date:</p>
                <CompHelper.ComplianceDatePicker
                  field={hBv6DateField}
                  state={hBv6Date}
                  disabled={!hBv5Date?.value}
                  onChange={this.onFieldChanged}
                />
              </div>
            </div>
            <CompHelper.UpdateButton
              disabled={!dirtyMedicalBlocks.length}
              update={this.updateMedicalBlock}
            />
            <div className='multi-dose-info-container'>
              <p>
                Dose 2 should be administered <strong>30 days</strong> after Dose 1.
                <br />
                Similarly, Dose 3 should be administered <strong>150 days</strong> after Dose 2.
              </p>

              {incomplete && (
                <p>
                  This item needs to be updated by{' '}
                  <strong>{moment(testSeriesOne.expirationDate).format(dateFormat)}</strong>{' '}
                  otherwise it will expire.
                </p>
              )}
            </div>
          </div>
        )}
        {!showResultEditPanel && !showDeclinationPanel && !showConsentPanel && (
          <div className='compliance-buttons-container'>
            <div>
              {!consentFormCompleted && !declinedFormCompleted && (
                <CompHelper.GreenButton
                  style={{ marginLeft: '35px' }}
                  onClick={this.openConsentPanel}
                >
                  Add Consent Form
                </CompHelper.GreenButton>
              )}
              {latestDocumentUpdated === 'Consent' &&
                !blockCompleted &&
                !seriesOnePassed &&
                !declinedFormCompleted && (
                  <CompHelper.SimpleButton onClick={this.addCompletion}>
                    Add Completion Info
                  </CompHelper.SimpleButton>
                )}
            </div>
            {!consentFormCompleted && !declinedFormCompleted && (
              <div>
                <p className='compliance-add-decline-text' onClick={this.openDeclinePanel}>
                  Add Declination Form
                </p>
                <p className='compliance-add-decline-sub-text'>
                  if the caregiver refuses the Hep B Shot
                </p>
              </div>
            )}
            {blockCompleted && (
              <p className='block-result-text' style={{ color: '#70DC94' }}>
                <Image
                  src='/compliance/check.svg'
                  style={{ paddingRight: '5px', display: 'inline' }}
                />
                COMPLETED:{' '}
                <CompHelper.DateText
                  date={hBv6Date?.value || series1CompletionDate?.value || declinationDate?.value}
                />
              </p>
            )}
            {showRemoveButton && this.showRemove()}
          </div>
        )}
        {closeEnabled && !seriesOneFails && (
          <Image className='tinyCloseButton' src='/close-icon.svg' onClick={this.onClose} />
        )}
        {showDeclineConsentPanel && (
          <div className='compliance-declination-panel compliance-add-completion-panel'>
            <h3 className='compliance-add-completion-panel-header'>{`ADD ${consentDeclinationText} FORM`}</h3>
            <div className='compliance-hep-date-container'>
              <p className='compliance-block-completion-field-text'>
                {`Enter date of ${consentDeclinationText} submission`}
              </p>
              <div className='label-input-in-line-container'>
                <CompHelper.ComplianceDatePicker
                  field={showConsentPanel ? consentDateField : declinedDateField}
                  state={showConsentPanel ? consentDate : declinationDate}
                  disabled={false}
                  onChange={this.onFieldChanged}
                />
              </div>
              <div>
                <p className='compliance-block-completion-field-text'>{`Upload ${consentDeclinationText} Form`}</p>
                <p className='compliance-block-completion-field-description'>
                  {`The submitted ${consentDeclinationText} form will be stored on records as proof of consent by
                  the caregiver.`}
                </p>
              </div>
              <div style={{ justifySelf: 'flex-end' }}>
                {showConsentPanel
                  ? this.renderDocumentField(consentDocumentField)
                  : this.renderDocumentField(declinedDocumentField)}
              </div>
              <CompHelper.UpdateButton
                disabled={!dirtyMedicalBlocks.length}
                update={this.updateMedicalBlock}
              />
            </div>
          </div>
        )}
        {showResultEditPanel && !seriesOneFails && (
          <div className='hep-b-test-series-one-container compliance-add-completion-panel'>
            <div className='compliance-header-plus-info-container'>
              <h3 className='compliance-add-completion-panel-header'>
                Enter Hep B Series 1 Details
              </h3>
              <Popup
                trigger={
                  <div className='round-grey-question'>
                    <Icon name='question' />
                  </div>
                }
                content='Every caregiver is required to undergo a series of 3 consecutive shots
                  to show Hepatitis B immunity. Following the 3 shots the caregiver must take 
                  Hep B1 test for immunity. If the caregiver is not immune they must undertake 
                  another series of shots.'
                on='hover'
                position='top left'
                popperModifiers={{
                  preventOverflow: {
                    boundariesElement: 'offsetParent',
                  },
                }}
              />
            </div>
            <div className='hep-b-dose-dates-column-container'>
              <div className='label-input-in-line-container'>
                <p className='compliance-block-completion-field-text'>Dose 1 Date:</p>
                <CompHelper.ComplianceDatePicker
                  field={hBv1DateField}
                  state={hBv1Date}
                  disabled={false}
                  onChange={this.onFieldChanged}
                />
              </div>
              <div className='label-input-in-line-container'>
                <p className='compliance-block-completion-field-text'>Dose 2 Date:</p>
                <CompHelper.ComplianceDatePicker
                  field={hBv2DateField}
                  state={hBv2Date}
                  disabled={!hBv1Date?.value}
                  onChange={this.onFieldChanged}
                />
              </div>
              <div className='label-input-in-line-container'>
                <p className='compliance-block-completion-field-text'>Dose 3 Date:</p>
                <CompHelper.ComplianceDatePicker
                  field={hBv3DateField}
                  state={hBv3Date}
                  disabled={!hBv2Date?.value}
                  onChange={this.onFieldChanged}
                />
              </div>
            </div>
            <div className='label-input-in-line-container'>
              <p className='compliance-block-completion-field-text'>Hep B Series 1 Test Date:</p>
              <CompHelper.ComplianceDatePicker
                field={series1completionField}
                state={series1CompletionDate}
                disabled={!hBv3Date?.value}
                onChange={this.onFieldChanged}
              />
            </div>
            <div className='label-input-in-line-container'>
              <p className='compliance-block-completion-field-text'>Hep B Series 1 Test Result:</p>
              <Dropdown
                placeholder='choose'
                selection
                className='compliance-dropdown'
                key={series1ResultField.id}
                value={series1Result?.value}
                disabled={!series1CompletionDate?.value}
                options={[
                  { text: 'Immune', value: '-' },
                  { text: 'Not Immune', value: '+' },
                ]}
                onChange={(_e, input) => {
                  this.onFieldChanged(series1ResultField, input.value);
                }}
              />
            </div>
            <CompHelper.UpdateButton
              disabled={!dirtyMedicalBlocks.length}
              update={this.updateMedicalBlock}
            />

            <div className='multi-dose-info-container'>
              <p>
                Dose 2 should be administered <strong>30 days</strong> after Dose 1.
                <br />
                Similarly, Dose 3 should be administered <strong>150 days</strong> after Dose 2.
              </p>

              {incomplete && (
                <p>
                  This item needs to be updated by{' '}
                  <strong>{moment(testSeriesOne.expirationDate).format(dateFormat)}</strong>{' '}
                  otherwise it will expire.
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { caregiverDetails, caregiverCompliance, caregiverComplianceUI } = state;
  return {
    caregiver: caregiverDetails.caregiver,
    caregiverCompliance,
    caregiverComplianceUI,
  };
};

export default connect(mapStateToProps)(ComplianceHepBTest);

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-deprecated */
import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { store } from '../../helpers';
import { alertActions } from '../../actions/alert';

import { formatTime, MOMENT_HOURS } from '../../constants/Formats';

const ValidHourFormats = [
  'hh:mm',
  'h:mm',
  'hhmm',
  'hh',
  'h',
  'hha',
  'ha',
  'hh:mma',
  'h:mma',
  'hhmma',
  'hh a',
  'h a',
  'hh:mm a',
  'h:mm a',
  'hhmm a',
  'HHmm',
  'HH:mm',
  'HH',
];

const dropdownOptions = [
  { key: '12:00 am', content: '12:00 am', value: '12:00 am' },
  { key: '12:30 am', content: '12:30 am', value: '12:30 am' },
  { key: '01:00 am', content: '01:00 am', value: '01:00 am' },
  { key: '01:30 am', content: '01:30 am', value: '01:30 am' },
  { key: '02:00 am', content: '02:00 am', value: '02:00 am' },
  { key: '02:30 am', content: '02:30 am', value: '02:30 am' },
  { key: '03:00 am', content: '03:00 am', value: '03:00 am' },
  { key: '03:30 am', content: '03:30 am', value: '03:30 am' },
  { key: '04:00 am', content: '04:00 am', value: '04:00 am' },
  { key: '04:30 am', content: '04:30 am', value: '04:30 am' },
  { key: '05:00 am', content: '05:00 am', value: '05:00 am' },
  { key: '05:30 am', content: '05:30 am', value: '05:30 am' },
  { key: '06:00 am', content: '06:00 am', value: '06:00 am' },
  { key: '06:30 am', content: '06:30 am', value: '06:30 am' },
  { key: '07:00 am', content: '07:00 am', value: '07:00 am' },
  { key: '07:30 am', content: '07:30 am', value: '07:30 am' },
  { key: '08:00 am', content: '08:00 am', value: '08:00 am' },
  { key: '08:30 am', content: '08:30 am', value: '08:30 am' },
  { key: '09:00 am', content: '09:00 am', value: '09:00 am' },
  { key: '09:30 am', content: '09:30 am', value: '09:30 am' },
  { key: '10:00 am', content: '10:00 am', value: '10:00 am' },
  { key: '10:30 am', content: '10:30 am', value: '10:30 am' },
  { key: '11:00 am', content: '11:00 am', value: '11:00 am' },
  { key: '11:30 am', content: '11:30 am', value: '11:30 am' },
  { key: '12:00 pm', content: '12:00 pm', value: '12:00 pm' },
  { key: '12:30 pm', content: '12:30 pm', value: '12:30 pm' },
  { key: '01:00 pm', content: '01:00 pm', value: '01:00 pm' },
  { key: '01:30 pm', content: '01:30 pm', value: '01:30 pm' },
  { key: '02:00 pm', content: '02:00 pm', value: '02:00 pm' },
  { key: '02:30 pm', content: '02:30 pm', value: '02:30 pm' },
  { key: '03:00 pm', content: '03:00 pm', value: '03:00 pm' },
  { key: '03:30 pm', content: '03:30 pm', value: '03:30 pm' },
  { key: '04:00 pm', content: '04:00 pm', value: '04:00 pm' },
  { key: '04:30 pm', content: '04:30 pm', value: '04:30 pm' },
  { key: '05:00 pm', content: '05:00 pm', value: '05:00 pm' },
  { key: '05:30 pm', content: '05:30 pm', value: '05:30 pm' },
  { key: '06:00 pm', content: '06:00 pm', value: '06:00 pm' },
  { key: '06:30 pm', content: '06:30 pm', value: '06:30 pm' },
  { key: '07:00 pm', content: '07:00 pm', value: '07:00 pm' },
  { key: '07:30 pm', content: '07:30 pm', value: '07:30 pm' },
  { key: '08:00 pm', content: '08:00 pm', value: '08:00 pm' },
  { key: '08:30 pm', content: '08:30 pm', value: '08:30 pm' },
  { key: '09:00 pm', content: '09:00 pm', value: '09:00 pm' },
  { key: '09:30 pm', content: '09:30 pm', value: '09:30 pm' },
  { key: '10:00 pm', content: '10:00 pm', value: '10:00 pm' },
  { key: '10:30 pm', content: '10:30 pm', value: '10:30 pm' },
  { key: '11:00 pm', content: '11:00 pm', value: '11:00 pm' },
  { key: '11:30 pm', content: '11:30 pm', value: '11:30 pm' },
];

const timePickerStyle = {
  width: '100%',
  fontSize: '12px',
  fontWeight: 'regular',
};

class CareTimePicker extends React.Component {
  static formatMoment(m, timezone) {
    return formatTime(m, timezone, MOMENT_HOURS);
  }

  constructor() {
    super();
    this.state = { freeText: null, chars: [] };

    this.timePickerChange = this.timePickerChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.keypress = this.keypress.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState(() => ({
      freeText: CareTimePicker.formatMoment(this.props.value, this.props.timezone),
      editActive: false,
      original: this.props.value,
      originalWithZone:
        this.props.value && this.props.timezone
          ? moment.tz(this.props.value, this.props.timezone.name)
          : undefined,
    }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(s => {
      const newVal = !s.editActive
        ? CareTimePicker.formatMoment(nextProps.value, nextProps.timezone)
        : s.freeText;

      return {
        freeText: newVal,
        original: nextProps.value,
        originalWithZone:
          nextProps.value && nextProps.timezone
            ? moment.tz(nextProps.value, this.props.timezone.name)
            : undefined,
      };
    });
  }

  onBlur() {
    this.setState(s => {
      const testVal =
        s.freeText.search(/[ap]/i) >= 0 ? s.freeText : s.freeText + s.original.format('a');

      const newVal = moment(testVal, ValidHourFormats, true);
      let newStateVal = null;
      if (newVal.isValid()) {
        if (!this.props.timezone) {
          store.dispatch(
            alertActions.errorNotification(
              'Shift time zone is missing. Can not modify time without a time zone'
            )
          );
        } else {
          // eslint-disable-next-line no-underscore-dangle
          const o = moment.tz(testVal, newVal._f, this.props.timezone.name);

          const hoffset = o.get('hour') - this.state.originalWithZone.get('hour');
          const moffset = o.get('minute') - this.state.originalWithZone.get('minute');

          const newInOriginal = moment(this.state.original)
            .add('hours', hoffset)
            .add('minutes', moffset);

          this.props.onChange(newInOriginal);
          newStateVal = newInOriginal;
        }
      } else {
        newStateVal = s.original;
      }

      return {
        freeText: CareTimePicker.formatMoment(newStateVal, this.props.timezone),
        editActive: false,
      };
    });
  }

  timePickerChange(e, input) {
    const testVal =
      input.value.search(/[ap]/i) >= 0
        ? input.value
        : input.value + this.state.originalWithZone.format('a');

    const newVal = moment(testVal, ValidHourFormats, true);
    if (newVal.isValid()) {
      if (!this.props.timezone) {
        store.dispatch(
          alertActions.errorNotification(
            'Shift time zone is missing. Can not modify time without a time zone'
          )
        );
      } else {
        // eslint-disable-next-line no-underscore-dangle
        const o = moment.tz(testVal, newVal._f, this.props.timezone.name);

        const hoffset = o.get('hour') - this.state.originalWithZone.get('hour');
        const moffset = o.get('minute') - this.state.originalWithZone.get('minute');

        const newInOriginal = moment(this.state.original)
          .add('hours', hoffset)
          .add('minutes', moffset);

        this.props.onChange(newInOriginal);
        this.setState(() => ({ freeText: input.value, editActive: true }));
      }
    }
  }

  keypress(e) {
    if (e.key === 'Enter') {
      this.onBlur();
    }
  }

  render() {
    return (
      <Dropdown
        id='care-time-picker-dropdown'
        style={timePickerStyle}
        onChange={this.timePickerChange}
        onClose={this.onBlur}
        onBlur={this.onBlur}
        value={this.state.freeText}
        options={dropdownOptions}
        allowAdditions
        fluid
        selection
        search
        onAddItem={this.timePickerChange}
        text={`${this.state.freeText}`}
        disabled={this.props.disabled}
      />
    );
  }
}

CareTimePicker.defaultProps = {
  timezone: moment.tz.zone(moment.tz.guess()),
  disabled: false,
};

CareTimePicker.propTypes = {
  timezone: PropTypes.shape(),
  value: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default CareTimePicker;

import _ from 'underscore';

import {
  SEARCH_CLIENT_SUCCESS,
  LIST_SERVICES_SUCCESS,
  CREATE_SHIFTS_REQUEST,
  CREATE_SHIFTS_SUCCESS,
  CREATE_SHIFTS_FAILURE,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_CLEAR,
} from '../constants/ActionTypes';

import { prepareClientObject } from './helpers';

const initialState = { services: [], submitting: false };

function shiftBuilder(state = initialState, action) {
  switch (action.type) {
    case SEARCH_CLIENT_SUCCESS: {
      const results = action.results ? action.results.results.map(x => prepareClientObject(x)) : [];

      let newResults = action.partialUpdate ? state.results.concat(results) : results;
      newResults = _.uniq(newResults, false, s => s.id);

      return {
        ...state,
        results: newResults,
        tooManyResults: action.tooManyResults,
        nextPage: action.results.nextPage,
      };
    }
    case LIST_SERVICES_SUCCESS: {
      const services = action.results ? action.results.services : undefined;
      return { ...state, services };
    }
    case CREATE_SHIFTS_REQUEST: {
      return { ...state, createdShifts: null, submitting: true };
    }
    case CREATE_SHIFTS_SUCCESS: {
      return { ...state, createdShifts: action.results, submitting: false };
    }
    case CREATE_SHIFTS_FAILURE: {
      return { ...state, submitting: false };
    }
    case CREATE_CLIENT_SUCCESS: {
      const createdClient = prepareClientObject(action.results);
      return { ...state, createdClient };
    }
    case CREATE_CLIENT_CLEAR: {
      return { ...state, createdClient: undefined };
    }
    default:
      return state;
  }
}

export default shiftBuilder;

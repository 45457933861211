import React from 'react';
import { Grid, Checkbox } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import {
  StyledTextarea,
  HeaderContainer,
  HeaderText,
  RecordTile,
  RecordHeader,
  CheckBoxField,
  HorizontalLine,
  BodyText,
  TinyHeader,
  MiniHeaderContainer,
  HeaderIcon,
  IconImage,
  IconAlignment,
  GridRow,
} from './styles';

interface IElearningSettings {
  id: number;
  enabled: boolean;
  text: string;
  createdOn: string;
  modifiedOn: string;
}
interface IProps {
  onValueUpdated: (name: string, value: string | boolean) => void;
  elearningSettings: IElearningSettings;
}

const maxLength = 200;
const body = `
  This policy will be displayed to the caregivers in the CareConnect application, course details.
  The changes you make to the policy text, will be updated in the application automatically
  after you save the changes.
`;
const ElearningSettings: React.FC<IProps> = props => {
  const { elearningSettings, onValueUpdated } = props;
  const { enabled, text } = elearningSettings;
  const currentLength = text?.length || 0;

  const toggle = () => {
    onValueUpdated('enabled', !enabled);
  };

  const textUpdated = (e: React.ChangeEvent<HTMLInputElement>) => {
    onValueUpdated('text', e.target.value);
    if (!e.target.value.length) {
      onValueUpdated('enabled', false);
    }
  };

  return (
    <RecordTile>
      <HeaderContainer>
        <HeaderIcon>
          <IconAlignment />
          <IconImage src='/Skills.svg' alt='elearning settings' />
        </HeaderIcon>
        <HeaderText size='tiny'>Policy setting</HeaderText>
        <RecordHeader>eLearning policy for caregivers</RecordHeader>
        <Form>
          <Form.Group grouped widths='equal'>
            <CheckBoxField>
              <Checkbox checked={enabled === true} onChange={toggle} toggle />
            </CheckBoxField>
          </Form.Group>
        </Form>
      </HeaderContainer>
      <HorizontalLine />
      <Form>
        <Grid padded>
          <GridRow>
            <Grid.Column width={16}>
              <BodyText>{body}</BodyText>
            </Grid.Column>
          </GridRow>
          <GridRow>
            <Grid.Column>
              <MiniHeaderContainer>
                <TinyHeader>Policy text</TinyHeader>
              </MiniHeaderContainer>
            </Grid.Column>
          </GridRow>
          <GridRow>
            <Grid.Column width={16}>
              <StyledTextarea onChange={textUpdated} value={text} maxLength={maxLength} />
            </Grid.Column>
            <Grid.Column floated='right' textAlign='right' width={5}>
              {currentLength}/{maxLength}
            </Grid.Column>
          </GridRow>
        </Grid>
      </Form>
    </RecordTile>
  );
};

export default ElearningSettings;

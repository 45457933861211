/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const MainGrid = styled.div`
  display: grid;
  grid-template-columns: 248px auto;
  grid-template-rows: 98px auto;
  grid-template-areas: 'rail top' 'rail child';
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100vw;
  height: auto;
  && {
    padding: 0;
  }
`;

export const RailContainer = styled.div`
  grid-area: rail;
  width: 245px;
  height: auto;
`;

export const BarContainer = styled.div`
  grid-area: top;
  height: 98px;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ChildContent = styled.div`
  grid-area: child;
  height: auto;
  width: 100%;
  padding: 0 1rem;
`;

export const SelectAllSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    margin: 0 1rem;
  }
`;

/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Tab, Dimmer } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ELSideInfo from './ELSideInfo';
import { ELCoursesActions } from '../actions/ELApp/ElCoursesActions';
import { userActions } from '../actions';
import BlockELearningModal from './subcomponents/eLAPP/BlockELearningModal';
import CoursesListView from './subcomponents/eLAPP/CoursesListView';
import CompletedCourses from './subcomponents/eLAPP/CompletedCourses';
import CareLoader from './subcomponents/CareLoader';
import './ELCourses.css';
import * as helpers from '../helpers/common';

class ELCourses extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    // eslint-disable-next-line react/no-unused-state
    eLearning: true,
    activeTabIndex: 0,
    redirected: false,
    fetchCourseDetails: false,
    listRefresh: false,
    CoursesListFetched: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(userActions.getUserSummary());
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.setState({ fetchCourseDetails: false });
  }

  // eslint-disable-next-line react/sort-comp
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location?.state && !prevState.redirected) {
      const tab = nextProps.location.state;
      return { activeTabIndex: tab, redirected: true };
    }
    if (
      !nextProps.eLCourses.loading &&
      !nextProps.eLCourses.courses.length &&
      nextProps.principal?.roles?.length &&
      !prevState.CoursesListFetched
    ) {
      if (nextProps.principal.roles.includes('caregiver')) {
        if (nextProps.profile?.languages) {
          const language = helpers.getProfileLanguage(nextProps.profile);
          nextProps.dispatch(ELCoursesActions.getCoursesList(language));
          return { CoursesListFetched: true };
        }
      } else {
        nextProps.dispatch(ELCoursesActions.getCoursesList());
        return { CoursesListFetched: true };
      }
    }
    if (
      (!nextProps.eLCourses.loading &&
        nextProps.eLCourses.courses.length &&
        !nextProps.eLCourses.courseDetails.userCourse) ||
      nextProps.eLCourses.refreshInProgress
    ) {
      const { courses } = nextProps.eLCourses;
      const inProgressCourseId = courses
        ? courses.filter(c => !c.completionDate && c.isRegistered).map(c => c.id)
        : [];
      if (!prevState.fetchCourseDetails || nextProps.eLCourses.refreshInProgress) {
        const language = helpers.getProfileLanguage(nextProps.profile);
        nextProps.dispatch(ELCoursesActions.getCoursesDetail(inProgressCourseId, language));
        return { fetchCourseDetails: true };
      }
    }

    if (
      !nextProps.eLCourses.loading &&
      nextProps.eLCourses.courses.length &&
      nextProps.eLCourses.refreshCourseList &&
      !prevState.listRefresh
    ) {
      const language = helpers.getProfileLanguage(nextProps.profile);
      nextProps.dispatch(ELCoursesActions.getCoursesList(language));
      return { listRefresh: true, fetchCourseDetails: false };
    }
    return null;
  }

  render() {
    const { activeTabIndex } = this.state;
    const courses = this.props.eLCourses?.courses ? this.props.eLCourses.courses : [];
    const courseDetails = this.props.eLCourses?.courseDetails?.userCourse
      ? this.props.eLCourses.courseDetails
      : {};
    const completedCoursesList = courses ? courses.filter(c => c.completionDate) : [];
    const nonCompletedCourseList = courses
      ? courses.filter(c => !c.completionDate && !c.isRegistered)
      : [];
    const pendingCoursesCount = courses.length - completedCoursesList.length;
    const { onShift } = this.props.principal;

    const { loading, buttonLoading } = this.props.eLCourses;
    const panes = [
      {
        menuItem: `Course List (${pendingCoursesCount})`,
        pane: {
          key: 'courseList',
          content: (
            <CoursesListView
              inProgressCourse={courseDetails}
              courses={nonCompletedCourseList}
              dispatch={this.props.dispatch}
              loading={loading}
              buttonLoading={buttonLoading}
              languages={this.props.profile.languages}
            />
          ),
        },
      },
      {
        menuItem: `Completed Courses (${completedCoursesList.length})`,
        pane: {
          key: 'completedCourses',
          content: (
            <CompletedCourses courses={completedCoursesList} buttonLoading={buttonLoading} />
          ),
        },
      },
    ];
    return (
      <div className='your-courses-container'>
        {loading && (
          <Dimmer active inverted>
            <CareLoader loading={loading} showText={false} />
          </Dimmer>
        )}
        {onShift && <BlockELearningModal />}
        <h1 className='your-courses-header'>YOUR COURSES</h1>
        <div className='your-courses-side-help-container'>
          <ELSideInfo path='courses' />
        </div>
        <Tab
          className='your-courses-tab'
          panes={panes}
          renderActiveOnly={false}
          activeIndex={activeTabIndex}
          onTabChange={(e, data) => this.setState({ activeTabIndex: data.activeIndex })}
        />
      </div>
    );
  }
}

ELCourses.propTypes = {
  dispatch: PropTypes.func.isRequired,
  eLCourses: PropTypes.shape().isRequired,
  profile: PropTypes.shape().isRequired,
};

const mapStateToProps = state => {
  const { principal, eLCourses } = state;
  const { profile } = principal;
  return {
    principal,
    profile,
    eLCourses,
  };
};

export default connect(mapStateToProps)(ELCourses);

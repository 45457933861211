import React from 'react';
import { Modal, Header, Grid, Segment, Button } from 'semantic-ui-react';

interface IProps {
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmDeletionModal: React.FC<IProps> = ({ onCancel, onConfirm }) => {
  return (
    <Modal
      closeOnDimmerClick={false}
      open
      dimmer
      size='tiny'
      onClose={onCancel}
      className='confirmDialog'
      style={{ marginTop: '-20vh' }}
    >
      <Modal.Header
        style={{
          backgroundColor: '#F16778',
        }}
        className='confirmHeader'
      >
        <Header textAlign='center' as='h2'>
          Delete In-Service Training Item?
        </Header>
      </Modal.Header>
      <Modal.Content>
        <Grid centered>
          <Grid.Column width={14}>
            <Segment basic>
              By deleting the training item you will be negatively effecting the caregivers annual
              in-service training quota.
            </Segment>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions style={{ backgroundColor: '#FFFFFF' }}>
        <Grid centered>
          <Grid.Column width={14} textAlign='center'>
            <Button onClick={onConfirm} className='care-green'>
              Confirm &amp; Delete
            </Button>
            <br />
            <Button onClick={onCancel} className='cancelBlue'>
              Cancel
            </Button>
          </Grid.Column>
        </Grid>
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmDeletionModal;

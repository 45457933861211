import moment from 'moment';

import {
  RESET_CAREGIVERS_LIST,
  SET_TARGET_SHIFTS_REQUEST,
  SET_TARGET_SHIFTS_SUCCESS,
  SET_TARGET_SHIFTS_FAILURE,
  ADD_CAREGIVER_TO_SHIFT_SUCCESS,
  SET_SHIFT_TIMING,
  UPDATE_SHIFT_TIMING,
  NOTIFICATION_SHIFT_UPDATED,
  UPDATE_SHIFT_SUCCESS,
  UPDATE_SHIFT_WITH_LIST_CAREGIVERS_SUCCESS,
} from '../constants/ActionTypes';

import { prepareShiftObject } from './helpers';

const initialState = {
  loadingShiftList: false,
  shiftList: null,
  active: false,
  destinations: [],
  timing: {},
};

function multiShiftSelector(state = initialState, action) {
  switch (action.type) {
    case RESET_CAREGIVERS_LIST:
      return {
        ...state,
        shiftList: null,
        client: null,
        loadingShiftList: false,
        active: false,
        destinations: [],
      };
    case SET_TARGET_SHIFTS_REQUEST:
      return { ...state, loadingShiftList: true, active: true, client: null, destinations: [] };
    case SET_TARGET_SHIFTS_SUCCESS: {
      const shiftList = action.results.results
        .map(s => prepareShiftObject(s, true))
        .sort((a, b) => a.start.isAfter(b.start));
      const client = shiftList && shiftList.length > 0 ? shiftList[0].client : null;

      shiftList.forEach(s => {
        // eslint-disable-next-line no-param-reassign
        s.oStart = s.start;
        // eslint-disable-next-line no-param-reassign
        s.oEnd = s.end;
      });

      return {
        ...state,
        loadingShiftList: false,
        shiftList,
        client,
        destinations: shiftList.map(s => s.client.address),
        active: shiftList.length > 0,
      };
    }
    case SET_TARGET_SHIFTS_FAILURE:
      return {
        ...state,
        loadingShiftList: false,
        shiftList: null,
        active: false,
        client: null,
        destinations: [],
      };
    case ADD_CAREGIVER_TO_SHIFT_SUCCESS: {
      let newState = { ...state };

      if (Array.isArray(action.results)) {
        newState = { ...state };
      } else {
        action.results.caregiver.shifts.forEach(shift => {
          const shiftIndex = state.shiftList.findIndex(i => i.id === shift.id);
          if (shiftIndex >= 0 && shift.status === 'assigned') {
            const s = { ...newState.shiftList[shiftIndex] };
            s.shiftAssigmentStatus = shift.status;
            s.actionable = false;
            const shiftList = Object.assign([...newState.shiftList], { [shiftIndex]: s });

            newState = { ...newState, shiftList };
          }
        });
      }

      return newState;
    }
    case NOTIFICATION_SHIFT_UPDATED: {
      if (!state.shiftList) {
        return state;
      }

      const shiftList = [...state.shiftList];
      action.results.forEach(s => {
        const index = shiftList.findIndex(i => s.id === i.id);
        if (index >= 0) {
          const updatedShift = prepareShiftObject({ ...s }, true);
          // eslint-disable-next-line no-param-reassign
          updatedShift.oStart = updatedShift.start;
          // eslint-disable-next-line no-param-reassign
          updatedShift.oEnd = updatedShift.end;
          shiftList[index] = updatedShift;
        }
      });
      return { ...state, shiftList };
    }
    case SET_SHIFT_TIMING: {
      const timing = action.values.reduce((a, b) => {
        // eslint-disable-next-line no-param-reassign
        a[b.shiftId] = { start: b.start, end: b.end };
        return a;
      }, {});
      return { ...state, timing };
    }
    case UPDATE_SHIFT_TIMING: {
      const newTiming = { ...state.timing };
      let start;
      let end;

      if (action.start) {
        start = moment(action.start);
        end = moment(action.start).add('minutes', action.minutes);
        newTiming[action.shiftId] = {
          start,
          end,
        };
      }

      if (action.end) {
        end = moment(action.end);
        start = moment(action.end).subtract('minutes', action.minutes);
        newTiming[action.shiftId] = {
          start,
          end,
        };
      }

      // update timing to shift
      const shiftIndex = state.shiftList.findIndex(i => i.id === action.shiftId);
      const shift = state.shiftList[shiftIndex];
      let { shiftList } = state;
      if (shift) {
        let newShift;
        if (shift.oStart.isSame(start) && shift.oEnd.isSame(end)) {
          newShift = { ...shift };
          newShift.timingChanged = false;
          newTiming[action.shiftId].isChanged = false;
        } else {
          newShift = { ...shift };
          newShift.start = moment(start);
          newShift.end = moment(end);
          newShift.timingChanged = true;
          newTiming[action.shiftId].isChanged = true;
        }
        shiftList = [...state.shiftList];
        shiftList[shiftIndex] = newShift;
      }

      return { ...state, timing: newTiming, shiftList };
    }
    case UPDATE_SHIFT_WITH_LIST_CAREGIVERS_SUCCESS:
    case UPDATE_SHIFT_SUCCESS: {
      if (!state.shiftList) {
        return state;
      }
      const newTiming = { ...state.timing };
      const shiftList = state.shiftList.map(s => {
        const newShift = { ...s };
        newShift.oStart = newTiming[s.id].start;
        newShift.oEnd = newTiming[s.id].end;
        newShift.timingChanged = false;
        newTiming[s.id].isChanged = false;
        return newShift;
      });
      return { ...state, timing: newTiming, shiftList };
    }
    default:
      return state;
  }
}

export default multiShiftSelector;

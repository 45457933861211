import React from 'react';
import { Message, Image, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  PhoneTypes,
  StaffStatusMap,
  avatarStyle,
  avatarUrl,
  getStatusDotColor,
} from '../constants/DomainTypes';
import Address from './subcomponents/Address';
import './CaregiverDetails.css';

const CaregiverDetailsTab = ({ caregiver }) => {
  const phones = caregiver.phones.map(v => {
    const title = PhoneTypes(v);
    return (
      <div key={v.type} className='caregiver-details-info-container'>
        <h4 className='caregiver-details-header'>{title}:</h4>
        <p className='caregiver-details-text'>{v.number}</p>
      </div>
    );
  });
  const CaregiverGender = gender => {
    let genderName;
    if (gender === 'f') {
      genderName = 'Female';
    } else if (gender === 'm') {
      genderName = 'Male';
    } else {
      genderName = 'Other';
    }
    return (
      <div className='caregiver-details-gender-container'>
        <h4 className='caregiver-details-header'>Gender:</h4>
        <Image
          className='caregiver-details-tab-gender-image'
          size='mini'
          circular
          style={avatarStyle(null, gender)}
          src={avatarUrl(null, gender)}
        />
        <p className='caregiver-details-text'>{genderName}</p>
      </div>
    );
  };
  const staffStatusLabel = StaffStatusMap[caregiver.staffStatus]
    ? StaffStatusMap[caregiver.staffStatus].name
    : null;
  return (
    <div className='caregiver-details-tab-main-container'>
      {!caregiver.maskedSsn && (
        <div>
          <Message error content='SSN is missing from this caregiver record!' />
        </div>
      )}
      <div className='caregiver-details-info-container'>
        <h4 className='caregiver-details-header'>Disciplines:</h4>
        <p className='caregiver-details-text'>{caregiver.discipline}</p>
      </div>
      <hr className='caregiver-details-separator' />
      {CaregiverGender(caregiver.gender)}
      <hr className='caregiver-details-separator' />
      <div className='caregiver-details-status-container'>
        <h4 className='caregiver-details-header'>Status:</h4>
        <Icon name='circle' style={getStatusDotColor(caregiver.staffStatus, true)} />
        <p className='caregiver-details-text'>{staffStatusLabel}</p>
      </div>
      <hr className='caregiver-details-separator' />
      <Address
        object={caregiver}
        editable={false}
        onValueChange={() => null}
        dispatch={() => null}
        showCoordinate
      />
      <hr className='caregiver-details-separator' />
      {phones}
      <div className='caregiver-details-info-container'>
        <h4 className='caregiver-details-header'>Email:</h4>
        <p className='caregiver-details-text'>{caregiver.email}</p>
      </div>
      <hr className='caregiver-details-separator' />
      <div className='caregiver-details-info-container'>
        <h4 className='caregiver-details-header'>Social Security:</h4>
        <p className='caregiver-details-text'>{caregiver.maskedSsn}</p>
      </div>
      <hr className='caregiver-details-separator' />
      <div className='caregiver-details-info-container'>
        <h4 className='caregiver-details-header'>Birthday:</h4>
        <p className='caregiver-details-text'>
          {caregiver.dob && moment(caregiver.dob).format('MM/DD/YYYY')}
        </p>
      </div>
      <hr className='caregiver-details-separator' />
      <div className='caregiver-details-info-container'>
        <h4 className='caregiver-details-header'>Hire Date:</h4>
        <p className='caregiver-details-text'>
          {caregiver.hireDate && moment(caregiver.hireDate).format('MM/DD/YYYY')}
        </p>
      </div>
    </div>
  );
};

CaregiverDetailsTab.propTypes = {
  caregiver: PropTypes.shape().isRequired,
};

export default CaregiverDetailsTab;

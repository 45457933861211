/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { BlueButton, SimpleButton } from '../../../../styles/common';

export const RailHeader = styled.div`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  font-weight: bold;
  color: #9b9b9b;
  padding: 0 20px;
  height: 98px;
  & .left-rail-divider {
    width: 100%;
    height: 1px;
    background-color: #e3e3e3;
    margin-top: 1.05rem;
  }
  & img {
    position: relative;
    display: inline-block;
    top: 2px;
    margin-right: 10px;
  }
`;

export const SubHeader = styled.p`
  font-size: 11px;
  font-weight: 600;
  color: #9b9b9b;
  margin-bottom: 2rem;
`;

export const RailLabel = styled.p`
  font-size: 11px;
  color: #9b9b9b;
  margin-bottom: 0;
`;

export const DropdownLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2rem;
  & > p {
    margin-bottom: 0.5rem;
  }
`;

export const CheckLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const RailBody = styled.div`
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const RailFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 150px;
  padding: 0 20px;
  & .left-rail-divider {
    width: 100%;
    height: 1px;
    background-color: #e3e3e3;
    margin: 1.05rem 0;
  }
`;

export const RailBlueButton = styled(BlueButton)`
  &&&& {
    width: 92px;
    border-radius: 6px;
    padding: 0;
    height: 30px;
  }
`;

export const RailSimpleButton = styled(SimpleButton)`
  &&&& {
    width: 92px;
    border-radius: 6px;
    padding: 0;
    height: 30px;
    a {
      color: #9b9b9b;
    }
  }
`;

export const HRInputRangeContainer = styled.div`
  &
    .input-range
    .input-range__track
    .input-range__slider-container
    .input-range__label
    .input-range__label-container {
    margin-left: 3rem;
    color: #9b9b9b;
    font-size: 11px;
    text-transform: lowercase;
  }
`;

/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Modal, TextArea, Header, Form, Segment, Button, Divider } from 'semantic-ui-react';

class CaregiverInvitationNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processingInvites: props.processingInvites,
      noteTemplate: props.noteTemplate,
      pendingInvitation: props.pendingInvitation,
      open: true,
    };
    this.onNoteChange = this.onNoteChange.bind(this);
    this.onNoteClose = this.onNoteClose.bind(this);
  }

  onNoteChange(e, input) {
    this.setState(s => ({
      ...s,
      noteTemplate: input.value.substr(0, 1000),
    }));
  }

  onNoteClose() {
    this.setState = { open: false };
    this.props.submit(null, true);
  }

  onNoteSubmit = () => {
    const { noteTemplate } = this.state;
    this.props.submit(noteTemplate);
    this.setState({ noteTemplate: null });
  };

  render() {
    const { processingInvites, noteTemplate, pendingInvitation, open } = this.state;

    return (
      <Modal
        closeOnDimmerClick={false}
        open={open}
        closeIcon
        dimmer
        size='tiny'
        className='invitationModel'
        onClose={this.onNoteClose}
        style={{ marginTop: '-30vh' }}
      >
        {this.props.children}

        {(pendingInvitation.status === 'offered' || processingInvites) && [
          <Modal.Header>
            <Header textAlign='center' as='h2'>
              ADD INVITATION NOTE
            </Header>
          </Modal.Header>,
          <Divider />,
          <Modal.Content>
            <Grid centered>
              <Grid.Column width={14}>
                <Segment basic>
                  Add any optional notes, instructions, or additional considerations for caregivers
                  to be included in their shift invitation.
                </Segment>
                <Form>
                  <TextArea
                    autoHeight
                    placeholder='Type notes'
                    value={noteTemplate}
                    onChange={this.onNoteChange}
                  />
                </Form>
              </Grid.Column>
            </Grid>
          </Modal.Content>,
          <Modal.Actions>
            <Grid centered>
              <Grid.Column width={14} textAlign='center'>
                <Button
                  id='caregiver-invitation-notes-send-button'
                  onClick={this.onNoteSubmit}
                  className='care-green'
                  disabled={processingInvites}
                >
                  Send Invitations
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Actions>,
        ]}

        {(pendingInvitation.status === 'assigned' || processingInvites) && [
          <Modal.Header
            className='assignedHeader'
            style={{
              backgroundColor: '#F16778',
            }}
          >
            <Header textAlign='center' as='h2'>
              Directly Assign Caregiver?
            </Header>
          </Modal.Header>,
          <Modal.Content>
            <Grid centered>
              <Grid.Column width={14}>
                <Segment basic>
                  <p>
                    By assigning the selected caregiver you will bypass the invitation process and
                    immediately update the status of the associated shift.
                  </p>
                  <p style={{ fontWeight: 'bold' }}>
                    Only proceed if you are sure that your caregiver has acknowledged and agreed
                    that they are available and willing to undertake the shift.
                  </p>
                </Segment>
              </Grid.Column>
            </Grid>
          </Modal.Content>,
          <Modal.Actions>
            <Grid centered>
              <Grid.Column width={14} textAlign='center'>
                <Button
                  id='caregiver-invitation-note-cancel-button'
                  style={{ width: '140px', marginRight: '15px' }}
                  onClick={this.onNoteClose}
                  className='care-red'
                >
                  Cancel
                </Button>
                <Button
                  id='caregiver-invitation-note-continue-button'
                  style={{ width: '140px' }}
                  onClick={this.onNoteSubmit}
                  className='care-green'
                  disabled={processingInvites}
                >
                  Continue
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Actions>,
        ]}
      </Modal>
    );
  }
}

CaregiverInvitationNote.propTypes = {
  submit: PropTypes.func.isRequired,
  processingInvites: PropTypes.bool.isRequired,
  noteTemplate: PropTypes.string.isRequired,
  pendingInvitation: PropTypes.shape().isRequired,
  children: PropTypes.bool.isRequired,
};

export default CaregiverInvitationNote;

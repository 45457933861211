/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

class WizardView extends React.Component {
  constructor() {
    super();
    this.state = { index: 0 };
    this.changeForm = this.changeForm.bind(this);
    this.next = this.next.bind(this);
    this.back = this.back.bind(this);
  }

  currentIndex() {
    return this.state.index;
  }

  next() {
    this.changeForm(this.state.index + 1);
  }

  back() {
    if (this.state.index > 0) {
      this.changeForm(this.state.index - 1);
    }
  }

  changeForm(newIndex) {
    const dir = newIndex > this.state.index ? 1 : -1;
    const content = this.formContent;
    const setTranslate = value => {
      content.style.WebkitTransform = `translate(${dir * value}px, 0px)`;
      content.style.OTransform = `translate(${dir * value}px, 0px)`;
      content.style.msTransform = `translate(${dir * value}px, 0px)`;
      content.style.MozTransform = `translate(${dir * value}px, 0px)`;
    };
    const enableTransition = value => {
      content.style.Transition = value ? '0.3s ease-in-out' : 'none';
      content.style.WebkitTransition = value ? '0.3s ease-in-out' : 'none';
      content.style.msTransition = value ? '0.3s ease-in-out' : 'none';
      content.style.OTTransition = value ? '0.3s ease-in-out' : 'none';
      content.style.MozTransition = value ? '0.3s ease-in-out' : 'none';
    };
    enableTransition(true);
    setTranslate(-1000);
    setTimeout(() => {
      enableTransition(false);
      setTranslate(1000);
      this.setState({ index: newIndex });
      setTimeout(() => {
        enableTransition(true);
        setTranslate(0);
        setTimeout(() => this.props.onViewChanged(newIndex), 300);
      }, 50);
    }, 300);
  }

  render() {
    const { index } = this.state;

    return (
      <div
        className={this.props.class}
        ref={input => {
          this.formContent = input;
        }}
      >
        {this.props.renderContent(index)}
      </div>
    );
  }
}

WizardView.defaultProps = {
  class: '',
  onViewChanged: () => {},
};

WizardView.propTypes = {
  renderContent: PropTypes.func.isRequired,
  onViewChanged: PropTypes.func,
  class: PropTypes.string,
};

export default WizardView;

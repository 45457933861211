/* eslint-disable import/prefer-default-export */
import * as types from '../../constants/ActionTypes';
import { backend } from '../../services';
// eslint-disable-next-line import/no-cycle
import { alertActions } from '../alert';

function getEvents() {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.getComplianceTrainingEvents(true);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: types.GET_COMPLIANCE_TRAINING_EVENTS_REQUEST };
  }
  function success(results) {
    return { type: types.GET_COMPLIANCE_TRAINING_EVENTS_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_COMPLIANCE_TRAINING_EVENTS_FAILURE, error };
  }
}

function createEvent(event) {
  return async dispatch => {
    dispatch(request());
    try {
      const body = { ...event, classRoom: event.classRoom ? event.classRoom.id : undefined };
      const res = await backend.createComplianceTrainingEvent(body);
      dispatch(success(res));
      dispatch(getEvents());
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: types.CREATE_COMPLIANCE_TRAINING_EVENT_REQUEST };
  }
  function success(results) {
    return {
      type: types.CREATE_COMPLIANCE_TRAINING_EVENT_SUCCESS,
      results: { event: results, select: true },
    };
  }
  function failure(error) {
    return { type: types.CREATE_COMPLIANCE_TRAINING_EVENT_FAILURE, error };
  }
}

function removeEvent(event) {
  return async dispatch => {
    dispatch(request());
    try {
      await backend.removeComplianceTrainingEvent(event);
      dispatch(getEvents());
      dispatch(success());
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: types.GET_COMPLIANCE_TRAINING_EVENTS_REQUEST };
  }
  function success() {
    return { type: types.REMOVE_COMPLIANCE_TRAINING_EVENTS_SUCCESS };
  }
  function failure(error) {
    return { type: types.GET_COMPLIANCE_TRAINING_EVENTS_FAILURE, error };
  }
}

function updateEvent(event, addAttendees = [], removeAttendees = [], select = false) {
  return async dispatch => {
    dispatch(request());
    try {
      const addRemove = async (add, remove) => {
        if (add.length) {
          const toAdd = [...add];
          const adding = toAdd.splice(0, 10);
          return Promise.allSettled(
            adding.map(a => {
              const newPromise = Promise.resolve(
                dispatch(inviteCaregiver(a.event, a.caregiver.id))
              );
              return newPromise;
            })
          )
            .then(() => addRemove(toAdd, remove))
            .catch(error => {
              dispatch(failure(error));
              return addRemove(toAdd, remove);
            });
        }
        if (remove.length) {
          const toRemove = [...remove];
          const removing = toRemove.splice(0, 10);
          return await Promise.allSettled(
            removing.map(r => {
              const newPromise = Promise.resolve(
                dispatch(removeAttendee(r.caregiver.id, r.eventId))
              );
              return newPromise;
            })
          )
            .then(() => addRemove([], toRemove))
            .catch(error => {
              dispatch(failure(error));
              return addRemove([], toRemove);
            });
        }
        return null;
      };

      await addRemove(addAttendees, removeAttendees);
      // support in case of multi instructor selection
      const instructors = Array.isArray(event.instructor)
        ? event.instructor.map(i => i.id)
        : event.instructor?.id;
      const body = {
        ...event,
        classRoom: event.classRoom ? event.classRoom.id : undefined,
        instructor: instructors,
      };
      const res = await backend.updateComplianceTrainingEvent(body);
      dispatch(success(res));
      dispatch(getEvents());
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: types.UPDATE_COMPLIANCE_TRAINING_EVENT_REQUEST };
  }
  function success(results) {
    return {
      type: types.UPDATE_COMPLIANCE_TRAINING_EVENT_SUCCESS,
      results: { event: results, select },
    };
  }
  function failure(error) {
    return { type: types.UPDATE_COMPLIANCE_TRAINING_EVENT_FAILURE, error };
  }
}

function submitEvent(event, attendees) {
  return async dispatch => {
    dispatch(request());
    try {
      await dispatch(updateEvent(event, true));
      if (attendees && attendees.length > 0) {
        await dispatch(updateAttendeesCompletionValues(event.id, attendees));
      }
      const res = await backend.submitTrainingEvent(event);
      dispatch(success(res));
      dispatch(getEvents());
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: types.SUBMIT_COMPLIANCE_TRAINING_EVENT_REQUEST };
  }
  function success(results) {
    return {
      type: types.SUBMIT_COMPLIANCE_TRAINING_EVENT_SUCCESS,
      results: { event: results, select: false },
    };
  }
  function failure(error) {
    return {
      type: types.SUBMIT_COMPLIANCE_TRAINING_EVENT_FAILURE,
      error,
    };
  }
}

function inviteCaregiver(event, caregiverId) {
  return async dispatch => {
    dispatch(request());

    // attendee request will be launched after this action
    dispatch({ type: types.GET_EVENT_ATTENDEES_REQUEST });

    try {
      const res = await backend.createCaregiverInServiceTraining(caregiverId, {
        trainingEventId: event.id,
      });
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch({ type: types.GET_EVENT_ATTENDEES_FAILURE, error });
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: types.CREATE_CAREGIVER_IN_SERVICE_TRAINING_REQUEST };
  }
  function success(results) {
    return { type: types.CREATE_CAREGIVER_IN_SERVICE_TRAINING_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.CREATE_CAREGIVER_IN_SERVICE_TRAINING_FAILURE, error };
  }
}

function removeAttendee(caregiverId, eventId) {
  return async dispatch => {
    dispatch(request());

    try {
      await backend.removeCaregiverTrainingEvent(caregiverId, eventId);
    } catch (error) {
      dispatch(failure(error));
    }
  };

  function request() {
    return { type: types.GET_EVENT_ATTENDEES_REQUEST };
  }
  function failure(error) {
    return { type: types.GET_EVENT_ATTENDEES_FAILURE, error };
  }
}

function getAttendees(eventId, search, nextPage) {
  return async dispatch => {
    dispatch(request());

    try {
      const attendees = await backend.getTrainingEventAttendees(eventId, search, nextPage);
      dispatch(success(attendees));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: types.GET_EVENT_ATTENDEES_REQUEST };
  }
  function success(results) {
    return { type: types.GET_EVENT_ATTENDEES_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_EVENT_ATTENDEES_FAILURE, error };
  }
}

function updateAttendeesCompletionValues(eventId, attendees) {
  return async dispatch => {
    dispatch(request());

    try {
      const result = await backend.updateTrainingEventAttendeesCompletionValues(eventId, attendees);
      dispatch(success(result));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: types.GET_EVENT_ATTENDEES_REQUEST };
  }
  function success(results) {
    return { type: types.GET_EVENT_ATTENDEES_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_EVENT_ATTENDEES_FAILURE, error };
  }
}

function initiateVirtualEvent(virtualEventId, successCallback) {
  return async dispatch => {
    dispatch(request());

    try {
      const result = await backend.initiateVirtualEvent(virtualEventId);
      dispatch(success(result));
      successCallback();
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: types.INITIATE_VIRTUAL_EVENT_REQUEST };
  }
  function success(results) {
    if (results?.virtualTrainingHostUrl) {
      const link = document.createElement('a');
      link.href = results.virtualTrainingHostUrl;
      link.target = '_blank';
      link.dispatchEvent(new MouseEvent('click'));
    }
    return { type: types.INITIATE_VIRTUAL_EVENT_SUCCESS };
  }
  function failure(error) {
    return { type: types.INITIATE_VIRTUAL_EVENT_FAILURE, error };
  }
}

function clearAttendees() {
  return { type: types.CLEAR_EVENT_ATTENDEES };
}

function clearEventToSelect() {
  return { type: types.CLEAR_EVENT_TO_SELECT };
}

function preSelectBlockId(blockId) {
  return { type: types.PRESELECT_BLOCK_ID, blockId };
}

export const eventActions = {
  getAttendees,
  clearAttendees,
  createEvent,
  updateEvent,
  getEvents,
  inviteCaregiver,
  updateAttendeesCompletionValues,
  clearEventToSelect,
  removeAttendee,
  removeEvent,
  submitEvent,
  preSelectBlockId,
  initiateVirtualEvent,
};

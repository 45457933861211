/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import PropTypes from 'prop-types';
import states from '../../constants/States';
import LocationMap from './LocationMap';
import LocationButton from './LocationButton';
import { beginningSearch } from '../../constants/Formats';

const stateOptions = states.states.map(x => ({ value: x.abbreviation, text: x.abbreviation }));

class AddressFormsy extends React.Component {
  constructor() {
    super();
    this.mapRef = null;
    this.googleMapsRef = null;
  }

  render() {
    const { dispatch, person, isEmployee, onValueChange } = this.props;
    const labelWidth = 4;
    const instantValidation = true;

    return (
      <Grid className='addClientAddressGrid'>
        <Grid.Row>
          <Grid.Column width={labelWidth}>
            <label htmlFor='address.street'>Address:</label>
          </Grid.Column>
          <Grid.Column width={12}>
            <Form.Input
              id='address-formsy-street-input'
              name='address.street'
              placeholder='Address 1'
              onChange={onValueChange}
              value={person.address.street}
              validations={{
                minLength: 2,
                maxLength: 200,
              }}
              validationErrors={{ isValid: 'address.street1 is not valid' }}
              instantValidation={instantValidation}
              className='hideLabel'
              required={!isEmployee}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={labelWidth} />
          <Grid.Column className='smallRightPadding' width={8}>
            <Form.Input
              id='address-formsy-street2-input'
              name='address.street2'
              placeholder='Address 2'
              onChange={onValueChange}
              value={person.address.street2}
              validations={{
                minLength: 2,
                maxLength: 200,
              }}
              validationErrors={{ isValid: 'address.street2 is not valid' }}
              instantValidation={instantValidation}
              className='hideLabel'
            />
          </Grid.Column>
          <Grid.Column className='smallLeftPadding' width={4}>
            <Form.Input
              id='address-formsy-apartment-input'
              name='address.apartment'
              placeholder='Apt #'
              onChange={onValueChange}
              value={person.address.apartment}
              validations={{
                minLength: 1,
                maxLength: 10,
              }}
              validationErrors={{ isValid: 'address.apartment is not valid' }}
              instantValidation={instantValidation}
              className='hideLabel'
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={labelWidth} />
          <Grid.Column width={12}>
            <Form.Input
              id='address-formsy-city-input'
              name='address.city'
              placeholder='City'
              onChange={onValueChange}
              value={person.address.city}
              validations={{
                minLength: 2,
                maxLength: 50,
              }}
              validationErrors={{ isValid: 'address.city is not valid' }}
              instantValidation={instantValidation}
              required={!isEmployee}
              className='hideLabel'
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={labelWidth} />
          <Grid.Column width={12}>
            <Form.Input
              id='address-formsy-county-input'
              name='address.county'
              placeholder='County'
              onChange={onValueChange}
              value={person.address.county}
              validations={{
                minLength: 2,
                maxLength: 50,
              }}
              validationErrors={{ isValid: 'address.county is not valid' }}
              instantValidation={instantValidation}
              className='hideLabel'
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={labelWidth} />
          <Grid.Column className='smallRightPadding' width={4}>
            <Form.Select
              id='address-formsy-state-select'
              name='address.state'
              style={{ minWidth: 0 }}
              options={stateOptions}
              placeholder='State'
              onChange={onValueChange}
              value={person.address.state}
              required={!isEmployee}
              className='hideLabel'
              search={beginningSearch}
            />
          </Grid.Column>
          <Grid.Column className='smallLeftPadding' width={8}>
            <Form.Input
              id='address-formsy-zip-input'
              name='address.zip'
              placeholder='Zip'
              onChange={onValueChange}
              validations={{
                minLength: 1,
                maxLength: 50,
              }}
              validationErrors={{ isValid: 'address.zip is not valid' }}
              instantValidation={instantValidation}
              value={person.address.zip}
              required={!isEmployee}
              className='hideLabel'
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <LocationButton
              address={person.address}
              dispatch={dispatch}
              locationUpdate={loc => {
                onValueChange({}, { name: 'address.lat', value: loc.lat });
                onValueChange({}, { name: 'address.lon', value: loc.lon });
                if (this.googleMapsRef) {
                  this.googleMapsRef.setCenter({ lat: loc.lat, lng: loc.lon });
                }
              }}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <Form.Input
              id='address-formsy-lat-input'
              name='address.lat'
              placeholder='Latitude'
              className='hideLabel'
              innerRef={c => {
                this.latInput = c;
              }}
              onChange={(e, input) => {
                onValueChange(e, { name: input.name, value: Number.parseFloat(input.value) });
              }}
              value={person.address.lat}
              validations={{
                isFloat: true,
                customValidation: (values, value) => value >= -90.0 && value <= 90,
              }}
              instantValidation={instantValidation}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <Form.Input
              id='address-formsy-lon-input'
              innerRef={c => {
                this.lonInput = c;
              }}
              name='address.lon'
              placeholder='Longitude'
              className='hideLabel'
              onChange={(e, input) => {
                onValueChange(e, { name: input.name, value: Number.parseFloat(input.value) });
              }}
              value={person.address.lon}
              validations={{
                isFloat: true,
                customValidation: (values, value) => value >= -180.0 && value <= 180,
              }}
              instantValidation={instantValidation}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <LocationMap
              lat={person.address.lat}
              lon={person.address.lon}
              locationCallback={loc => {
                onValueChange({}, { name: 'address.lat', value: loc.lat });
                onValueChange({}, { name: 'address.lon', value: loc.lon });
              }}
              mapRefCallback={(mapRef, googleMapsRef) => {
                this.mapRef = mapRef;
                this.googleMapsRef = googleMapsRef;
              }}
              showMarker
              loadingElement={<div style={{ height: '100%' }} />}
              containerElement={<div style={{ height: '400px' }} />}
              mapElement={<div style={{ height: '100%' }} />}
              editable
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

AddressFormsy.defaultProps = {};

AddressFormsy.propTypes = {
  dispatch: PropTypes.func.isRequired,
  person: PropTypes.shape().isRequired,
  isEmployee: PropTypes.bool.isRequired,
  onValueChange: PropTypes.func.isRequired,
};

export default AddressFormsy;

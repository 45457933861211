/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Image, Modal, Form, Checkbox, Icon, Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { GreenButton, SimpleButton } from '../../../styles/common';
import { QuizActions } from '../../../actions/ELApp/QuizActions';
import '../../ELCourses.css';

class Quiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actualTabIndex: 0,
      actualQuestionIndex: 0,
      backVisible: false,
      selectedAnswers: {},
      isStarted: false,
      isFinish: false,
      quizInProgress: false,
      review: false,
      sendAnswers: false,
      instructionsOn: false,
    };
  }

  componentDidMount() {}

  // eslint-disable-next-line react/sort-comp
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.quizInProgress && nextProps.eLCourses?.quizId) {
      const { moduleId, courseId, quizId } = nextProps.eLCourses;
      const params = {
        moduleId,
        courseId,
        quizId,
      };
      nextProps.dispatch(QuizActions.getQuizAttempt(params));
      return { quizInProgress: true };
    }
    if (prevState.quizInProgress && nextProps.eLCourses?.reviewMode) {
      return { selectedAnswers: nextProps.eLCourses.quizReview, review: true };
    }
    return null;
  }

  getLabel = (a, review) => {
    if (review) {
      if (a.isSelected && !a.isCorrect) {
        return (
          <div className='wrong-answer-label'>
            <div className='wrong-answer-cross' />
          </div>
        );
      }
    }
    return null;
  };

  handleStart = () => {
    const selectedAnswersBody = {
      isSubmitted: false,
      questions: this.props.eLCourses.quiz.questions,
    };
    const newTabIndex = this.state.actualTabIndex + 1;
    return this.setState({
      actualTabIndex: newTabIndex,
      isStarted: true,
      selectedAnswers: selectedAnswersBody,
    });
  };

  handleClickNext = () => {
    const { moduleId, courseId, quizId, reviewMode } = this.props.eLCourses;

    if (!this.state.backVisible) this.setState({ backVisible: true });

    if (!reviewMode) {
      const params = {
        moduleId,
        courseId,
        quizId,
        answers: this.state.selectedAnswers,
      };
      this.props.dispatch(QuizActions.updateQuizAttemptAnswers(params));
    }
    if (this.state.selectedAnswers.questions.length === this.state.actualTabIndex) {
      if (!reviewMode) {
        return this.setState({ sendAnswers: true, isFinish: true });
      }
      return this.setState({ isFinish: true });
    }

    const newActualQuestionIndex = this.state.actualQuestionIndex + 1;
    const newTabIndex = this.state.actualTabIndex + 1;

    if (newActualQuestionIndex === this.state.selectedAnswers.questions.length) {
      return this.setState({
        actualQuestionIndex: newActualQuestionIndex,
        actualTabIndex: newTabIndex,
      });
    }
    return this.setState({
      actualQuestionIndex: newActualQuestionIndex,
      actualTabIndex: newTabIndex,
    });
  };

  handleClickBack = () => {
    const newActualQuestionIndex = this.state.actualQuestionIndex - 1;
    const newTabIndex = this.state.actualTabIndex - 1;
    if (newActualQuestionIndex === 0) {
      return this.setState({
        actualQuestionIndex: newActualQuestionIndex,
        actualTabIndex: newTabIndex,
        backVisible: false,
      });
    }
    return this.setState({
      actualQuestionIndex: newActualQuestionIndex,
      actualTabIndex: newTabIndex,
    });
  };

  handleChange = checkId => {
    const newSelectedAnswers = this.state.selectedAnswers;
    if (newSelectedAnswers.questions[this.state.actualQuestionIndex].type === 'MultipleAnswers') {
      const answerIndex = newSelectedAnswers.questions[
        this.state.actualQuestionIndex
      ].answers.findIndex(a => a.id === checkId);
      if (answerIndex >= 0) {
        if (
          newSelectedAnswers.questions[this.state.actualQuestionIndex].answers[answerIndex]
            .isSelected === false
        ) {
          newSelectedAnswers.questions[this.state.actualQuestionIndex].answers[
            answerIndex
          ].isSelected = true;
        } else {
          newSelectedAnswers.questions[this.state.actualQuestionIndex].answers[
            answerIndex
          ].isSelected = false;
        }
      }
      return this.setState({ selectedAnswers: newSelectedAnswers });
    }
    if (newSelectedAnswers.questions[this.state.actualQuestionIndex].type === 'SingleAnswer') {
      const answerIndex = newSelectedAnswers.questions[
        this.state.actualQuestionIndex
      ].answers.findIndex(a => a.isSelected === true);

      if (answerIndex >= 0) {
        newSelectedAnswers.questions[this.state.actualQuestionIndex].answers[
          answerIndex
        ].isSelected = false;
        newSelectedAnswers.questions[this.state.actualQuestionIndex].answers[
          newSelectedAnswers.questions[this.state.actualQuestionIndex].answers.findIndex(
            a => a.id === checkId
          )
        ].isSelected = true;
      } else {
        newSelectedAnswers.questions[this.state.actualQuestionIndex].answers[
          newSelectedAnswers.questions[this.state.actualQuestionIndex].answers.findIndex(
            a => a.id === checkId
          )
        ].isSelected = true;
      }
      return this.setState({ selectedAnswers: newSelectedAnswers });
    }
    return this.setState({ selectedAnswers: newSelectedAnswers });
  };

  handleClickReview = () => {
    this.setState({
      actualTabIndex: 1,
      actualQuestionIndex: 0,
      isFinish: false,
      backVisible: false,
    });
  };

  checkCorrectAnswer = answers => {
    const selectedAnswers = answers.filter(s => s.isSelected);
    const correctAnswer = answers.filter(c => c.isCorrect);
    const wrongAnswer = answers.filter(c => c.isSelected && !c.isCorrect);

    const correction = correctAnswer.filter(c => selectedAnswers.find(s => s.id === c.id));

    if (correction.length === correctAnswer.length && !wrongAnswer.length) {
      return (
        <div>
          <div className='quiz-modal-review-correct' />
          <p className='quiz-modal-review-correct-text'>{correctAnswer[0].feedback || 'Correct'}</p>
        </div>
      );
    }
    if (selectedAnswers.length) {
      return (
        <div>
          <div className='quiz-modal-review-wrong' />
          <p className='quiz-modal-review-wrong-text'>{selectedAnswers[0].feedback || 'Wrong'}</p>
        </div>
      );
    }
    return (
      <div>
        <div className='quiz-modal-review-wrong' />
        <p className='quiz-modal-review-wrong-text'>WRONG</p>
      </div>
    );
  };

  handleSendAnswers = () => {
    const { moduleId, courseId, quizId } = this.props.eLCourses;
    const submittedSelectedAnswers = this.state.selectedAnswers;
    Object.assign(submittedSelectedAnswers, { isSubmitted: true });
    const params = {
      moduleId,
      courseId,
      quizId,
      answers: submittedSelectedAnswers,
    };
    this.props.dispatch(QuizActions.updateQuizAttemptAnswers(params));
    return this.setState({ sendAnswers: false });
  };

  handleNotSendAnswers = () => {
    if (this.state.actualQuestionIndex === 0) {
      return this.setState({ sendAnswers: false, isFinish: false, backVisible: false });
    }
    return this.setState({ sendAnswers: false, isFinish: false });
  };

  handleClose() {
    this.setState({
      actualTabIndex: 0,
      actualQuestionIndex: 0,
      backVisible: false,
      selectedAnswers: {},
      isStarted: false,
      isFinish: false,
      quizInProgress: false,
      review: false,
    });
    this.props.closeDetailsClicked();
  }

  handleViewInstructions = () => this.setState({ instructionsOn: true });

  handleCloseInstructions = () => this.setState({ instructionsOn: false });

  renderInstructions = (questionsCount, percentage) => (
    <Modal
      closeOnDimmerClick={false}
      open
      closeIcon={<Image src='/icon_close.svg' />}
      dimmer
      size='tiny'
      onClose={() => this.handleCloseInstructions()}
      className='clientEditor quiz-modal'
      style={{ marginTop: '-25vh', marginLeft: '-26vw' }}
      centered
    >
      <div className='quiz-instructions-container'>
        <p className='quiz-instructions-text'>
          There will be {questionsCount} questions in this quiz. A score of {percentage}% is
          required to satisfy the training requirements. <br />
          The questions will be asked as a series of multiple choice and true/false questions.
          Select the appropriate answer and click the next button to page to the next question. When
          completed press the OK button to save the answers. If you have answered everything then
          you will be asked if you want to submit the questions for scoring, otherwise the answers
          will be saved and you can exit and return to the quiz at a later point in time.
          <br />
          You will be given a maximum of 3 attempts and then must retake the course to try again.
        </p>
      </div>
    </Modal>
  );

  render() {
    const { quiz, loading } = this.props.eLCourses;
    const { description } = this.props;
    const {
      actualQuestionIndex,
      actualTabIndex,
      backVisible,
      selectedAnswers,
      isStarted,
      isFinish,
      review,
      sendAnswers,
      instructionsOn,
    } = this.state;

    const minimumScore = quiz.minimumScore * 100;

    const answerIsSelected =
      selectedAnswers.questions && selectedAnswers.questions[actualQuestionIndex]?.answers
        ? !!selectedAnswers.questions[actualQuestionIndex].answers.filter(a => a.isSelected).length
        : false;

    return (
      <Modal
        closeOnDimmerClick={false}
        open
        closeIcon={<Image src='/icon_close.svg' />}
        dimmer
        size='tiny'
        onClose={() => this.handleClose()}
        className='quiz-modal clientEditor'
        style={{ marginTop: '-35vh' }}
      >
        <Modal.Content style={{ padding: '0', borderRadius: '10px' }}>
          <div className='quiz-modal-body'>
            {loading && (
              <Dimmer active inverted>
                <div style={{ height: '100%' }}>
                  <Image
                    src='/loader_anim.gif'
                    style={{ width: '100px', height: 'auto' }}
                    className='quiz-modal-loader-image'
                  />
                </div>
              </Dimmer>
            )}
            {instructionsOn && this.renderInstructions(quiz.questions.length, minimumScore)}
            <div className='quiz-modal-header'>
              <h3 className='quiz-modal-header-text'>QUIZ</h3>
              <div className='quiz-modal-header-progress'>
                <div
                  className={`quiz-modal-progress-bar-first-dot ${
                    actualTabIndex === 0 ? 'not-started-dot' : 'started-dot'
                  }`}
                />
                {quiz.questions?.length &&
                  quiz.questions.map((a, i) => {
                    const lineWidth = quiz.questions.length < 6 ? '70px' : '30px';
                    let bgColor;
                    let barBgColor;
                    let borderColor;
                    if (actualTabIndex - 1 === i && !isFinish) {
                      borderColor = '1px solid #69db8f';
                      bgColor = '#ffffff';
                      barBgColor = '#69db8f';
                    } else if (actualTabIndex > i || isFinish) {
                      borderColor = '1px solid #69db8f';
                      bgColor = '#69db8f';
                      barBgColor = '#69db8f';
                    } else {
                      borderColor = '1px solid #D8D8D8';
                      bgColor = '#D8D8D8';
                      barBgColor = '#D8D8D8';
                    }
                    return (
                      <span
                        key={a.id}
                        className='quiz-modal-progress-bar'
                        style={{ width: lineWidth, backgroundColor: barBgColor }}
                      >
                        <div
                          className='quiz-modal-progress-bar-dot'
                          style={{ backgroundColor: bgColor, border: borderColor }}
                        />
                      </span>
                    );
                  })}
              </div>
              <p className='quiz-modal-header-attempt'>Attempt {quiz.attemptCount} of 3</p>
            </div>
            {!isStarted && !isFinish && (
              <div className='quiz-modal-start-tab'>
                <ReactMarkdown className='quiz-modal-text quiz-modal-start-tab-text'>
                  {description || ''}
                </ReactMarkdown>
                {!this.props.eLCourses.error ? (
                  <GreenButton
                    className='quiz-start-bottom'
                    loading={
                      this.props.eLCourses.loading || !this.props.eLCourses?.quiz?.questions?.length
                    }
                    disabled={
                      this.props.eLCourses.loading ||
                      this.props.eLCourses.error ||
                      !(
                        this.props.eLCourses.quiz.questions &&
                        this.props.eLCourses.quiz.questions.length
                      )
                    }
                    onClick={() => this.handleStart()}
                  >
                    Start Quiz
                  </GreenButton>
                ) : (
                  <div>
                    <p className='quiz-start-bottom quiz-start-error'>ERROR</p>
                    <SimpleButton
                      loading={this.props.eLCourses.buttonLoading}
                      disabled={this.props.eLCourses.buttonLoading}
                      className='quiz-review-button'
                      onClick={() => this.handleClose()}
                    >
                      CLOSE
                    </SimpleButton>
                  </div>
                )}
              </div>
            )}
            {isStarted && !isFinish && (
              <div className='quiz-modal-questions-tab'>
                <ReactMarkdown className='quiz-modal-text'>
                  {selectedAnswers.questions &&
                    selectedAnswers.questions.length &&
                    selectedAnswers.questions[actualQuestionIndex].questionMarkdown}
                </ReactMarkdown>

                <Form className='quiz-modal-answers-form'>
                  {selectedAnswers.questions &&
                    selectedAnswers.questions.length &&
                    selectedAnswers.questions[actualQuestionIndex].answers.map(a => {
                      const displayLabel = this.getLabel(a, review);
                      return (
                        <Form.Field
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.handleChange(a.id)}
                        >
                          <Checkbox
                            style={{ display: 'inline-block', width: '30px' }}
                            className={`round quiz-modal-check-box ${
                              review ? 'quiz-modal-review-answers' : ''
                            }`}
                            checked={a.isSelected}
                            label={displayLabel}
                          />
                          <ReactMarkdown
                            className={`quiz-modal-answer-${
                              a.isSelected ? 'selected' : 'unselected'
                            }`}
                          >
                            {a.answerMarkdown}
                          </ReactMarkdown>
                        </Form.Field>
                      );
                    })}
                </Form>
                <div className='quiz-modal-questions-tab-bottoms-footer'>
                  {backVisible && (
                    <SimpleButton
                      className='quiz-prev-button'
                      onClick={() => this.handleClickBack()}
                    >
                      Previous
                    </SimpleButton>
                  )}
                  <div
                    className={`quiz-modal-questions-info-footer ${
                      !backVisible ? 'info-footer-centered' : ''
                    }`}
                  >
                    {review ? (
                      this.checkCorrectAnswer(
                        selectedAnswers.questions[actualQuestionIndex].answers
                      )
                    ) : (
                      <span
                        role='button'
                        tabIndex={0}
                        onClick={() => this.handleViewInstructions()}
                      >
                        <Icon
                          className='Quiz-info-icon'
                          name='question circle'
                          size='tiny'
                          color='gray'
                        />
                        instructions
                      </span>
                    )}
                  </div>
                  <GreenButton
                    disabled={!answerIsSelected}
                    className={`quiz-next-button ${!backVisible ? 'next-bottom-centered' : ''}`}
                    onClick={() => this.handleClickNext()}
                  >
                    Next
                  </GreenButton>
                </div>
              </div>
            )}
            {sendAnswers && (
              <div className='quiz-modal-send-answer-modal'>
                <p className='quiz-modal-text send-answer-modal-text'>
                  Are you sure you want to send your answers?
                </p>
                <GreenButton
                  className='quiz-send-answers-bottom'
                  onClick={() => this.handleSendAnswers()}
                >
                  Yes
                </GreenButton>
                <GreenButton
                  className='quiz-not-send-answers-bottom'
                  onClick={() => this.handleNotSendAnswers()}
                >
                  No
                </GreenButton>
              </div>
            )}
            {isFinish && !sendAnswers && (
              <div className='quiz-modal-end-tab'>
                {this.props.eLCourses.quizReview && this.props.eLCourses.quizReview.id ? (
                  <div className='quiz-modal-end-resolution'>
                    <p className='quiz-modal-text'>Your score was</p>
                    <Image
                      style={{ position: 'relative' }}
                      className='quiz-modal-end-resolution-image'
                      src={
                        this.props.eLCourses.quizReview.passed
                          ? '/quiz-review-success.svg'
                          : '/quiz-review-course-wrong.svg'
                      }
                    />
                    <p className='quiz-modal-score-text'>
                      {this.props.eLCourses.quizReview && this.props.eLCourses.quizReview.score} out
                      of {this.props.eLCourses.quizReview && this.props.eLCourses.quizReview.total}
                    </p>
                  </div>
                ) : (
                  <div />
                )}
                <div className='quiz-modal-end-resolution'>
                  <p className='quiz-modal-text'>Would you like to review the quiz?</p>
                  <div>
                    <SimpleButton
                      loading={this.props.eLCourses.buttonLoading}
                      disabled={this.props.eLCourses.buttonLoading}
                      className='quiz-review-button'
                      onClick={() => this.handleClickReview()}
                    >
                      YES
                    </SimpleButton>
                    <SimpleButton
                      loading={this.props.eLCourses.buttonLoading}
                      disabled={this.props.eLCourses.buttonLoading}
                      className='quiz-review-button'
                      onClick={() => this.handleClose()}
                    >
                      CLOSE
                    </SimpleButton>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

Quiz.propTypes = {
  closeDetailsClicked: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  eLCourses: PropTypes.shape().isRequired,
  description: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  const { eLCourses } = state;
  return {
    eLCourses,
  };
};

export default connect(mapStateToProps)(Quiz);

import { Grid, Header, Segment, Form } from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

const Notes = ({ object, editable, onValueChange, placeHolder, maxLength, shift }) =>
  editable ? (
    <Grid.Row>
      <Grid.Column width={5}>
        <Header className={shift ? 'shift-notes' : ''} size='tiny' floated='left'>
          {shift ? 'NOTES' : 'Notes:'}
        </Header>
      </Grid.Column>
      <Grid.Column width={11}>
        <Header size='tiny'>
          <Form.TextArea
            id='notes-editable-text-area'
            className={shift ? 'shift-notes' : ''}
            width={16}
            name='notes'
            value={object.notes}
            maxLength={maxLength}
            onChange={onValueChange}
            placeholder={placeHolder}
          />
        </Header>
      </Grid.Column>
    </Grid.Row>
  ) : (
    <Grid.Row>
      <Grid.Column width={5}>
        <Header className={shift ? 'shift-notes' : ''} size='tiny' floated='left'>
          {shift ? 'NOTES' : 'Notes:'}
        </Header>
      </Grid.Column>
      <Grid.Column width={11}>
        <Header size='tiny'>
          <Segment
            id='notes-not-editable-text-segment'
            className={shift ? 'shift-notes' : 'notes'}
            basic
            style={{ wordWrap: 'break-word' }}
          >
            {object.notes}
          </Segment>
        </Header>
      </Grid.Column>
    </Grid.Row>
  );

Notes.propTypes = {
  maxLength: PropTypes.number.isRequired,
  object: PropTypes.shape().isRequired,
  editable: PropTypes.bool.isRequired,
  onValueChange: PropTypes.func.isRequired,
  shift: PropTypes.bool.isRequired,
  placeHolder: PropTypes.string.isRequired,
};

export default Notes;

/* eslint-disable import/prefer-default-export */
import * as types from '../constants/ActionTypes';
import { backend } from '../services';
import { alertActions } from './alert';

function getElearningSettings() {
  return async dispatch => {
    dispatch(request());

    try {
      const response = await backend.getElearningSettings(true);
      const { clientSettings } = response;
      dispatch(success(clientSettings));
    } catch (error) {
      dispatch(failure(error));
    }
  };

  function request() {
    return { type: types.GET_ELEARNING_SETTINGS_REQUEST };
  }
  function success(elearningSettings) {
    return { type: types.GET_ELEARNING_SETTINGS_SUCCESS, elearningSettings };
  }
  function failure(error) {
    return { type: types.GET_ELEARNING_SETTINGS_FAILURE, error };
  }
}

function updateElearningSettings(fields) {
  return async dispatch => {
    dispatch(request());

    try {
      const { loading, ...rest } = fields;
      const response = await backend.updateElearningSettings(rest);
      const { clientSettings } = response;
      dispatch(success(clientSettings));
      dispatch(alertActions.notification('eLearning settings saved'));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: types.UPDATE_ELEARNING_SETTINGS_REQUEST };
  }
  function success(elearningSettings) {
    return { type: types.UPDATE_ELEARNING_SETTINGS_SUCCESS, elearningSettings };
  }
  function failure(error) {
    return { type: types.UPDATE_ELEARNING_SETTINGS_FAILURE, error };
  }
}

function createElearningSettings(fields) {
  return async dispatch => {
    dispatch(request());

    try {
      const { loading, ...rest } = fields;
      const response = await backend.createElearningSettings(rest);
      const { clientSettings } = response;
      dispatch(success(clientSettings));
      dispatch(alertActions.notification('eLearning settings saved'));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: types.CREATE_ELEARNING_SETTINGS_REQUEST };
  }
  function success(elearningSettings) {
    return { type: types.CREATE_ELEARNING_SETTINGS_SUCCESS, elearningSettings };
  }
  function failure(error) {
    return { type: types.CREATE_ELEARNING_SETTINGS_FAILURE, error };
  }
}

export const elearningSettingsActions = {
  getElearningSettings,
  updateElearningSettings,
  createElearningSettings,
};

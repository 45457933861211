/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Table } from 'semantic-ui-react';
import { SimpleBlueButton } from '../../../../styles/common';
import iconCheckNo from '../../../../images/icon_check_no.svg';
import attentionOrange from '../../../../images/Attention-orange.svg';

interface IViewButtonProps {
  expired: boolean;
  pending: boolean;
}

interface IMainTable {
  hasData: boolean;
}

export const TableContainer = styled.div`
  position: relative;
  top: 0;
  margin: 0;
  padding: 0;
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
`;

export const MainTable = styled(Table).attrs({
  sortable: true,
  striped: true,
})<IMainTable>`
  && {
    width: 100%;
    border-top: none;
    border-left: ${props => (props.hasData ? '0.5px solid #e3e3e3' : 'none')};
    border-right: ${props => (props.hasData ? '0.5px solid #e3e3e3' : 'none')};
    border-bottom: ${props => (props.hasData ? '0.5px solid #e3e3e3' : 'none')};
  }
`;

export const TableHeader = styled(Table.Header)`
  &&& {
    position: sticky;
    top: 0;
    background-color: transparent;
  }
`;

export const TableHeaderCell = styled(Table.HeaderCell)`
  &&& {
    background-color: #f1f1f1;
    &&&:first-child {
      border-radius: 5px 0 0 0;
    }
    &&&:last-child {
      border-radius: 0 5px 0 0;
    }
    &&:hover {
      background-color: #e3e3e3;
    }
  }
`;

export const ViewButton = styled(SimpleBlueButton)<IViewButtonProps>`
  &&&& {
    height: 25px;
    padding: 0 1rem;
    font-size: 12px;
    border-radius: 8px;
    position: relative;
    & span {
      display: ${props => (props.pending || props.expired ? 'inline-block' : 'none')};
      position: absolute;
      top: -6px;
      right: -8px;
      width: 15px;
      height: 15px;
      background-image: ${props =>
        props.expired ? `url(${iconCheckNo})` : `url(${attentionOrange})`};
      background-repeat: no-repeat;
      background-position: center;
      background-size: 15px 15px;
    }
  }
`;

export const CompletionViewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > * {
    margin: 0 0.5rem;
  }
  p {
    font-size: 9px;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Circle } from 'react-google-maps';
import { compose, withProps, lifecycle } from 'recompose';
import { googleMapURL } from '../../constants/Constants';

const LocationMap = compose(
  lifecycle({
    UNSAFE_componentWillMount() {
      const refs = {};

      this.setState({
        position: null,
        onMarkerMounted: ref => {
          refs.marker = ref;
        },
        onDragEnd: () => {
          const position = refs.marker.getPosition();
          if (this.props.locationCallback) {
            this.props.locationCallback({ lat: position.lat(), lon: position.lng() });
          }
        },
      });
    },
  }),
  withProps({ googleMapURL }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    ref={mapRef => {
      if (props.mapRefCallback) {
        // eslint-disable-next-line no-underscore-dangle
        const googleMapsRef = ((mapRef || {}).context || {})
          .__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
        props.mapRefCallback(mapRef, googleMapsRef);
      }
    }}
    defaultZoom={props.defaultZoom ? props.defaultZoom : 16}
    defaultCenter={{ lat: props.lat, lng: props.lon }}
    zoom={props.zoom ? props.zoom : 16}
  >
    {props.showMarker && (
      <Marker
        position={{ lat: props.lat, lng: props.lon }}
        icon={props.markerIcon ? props.markerIcon : '/MapPin.png'}
        draggable={props.editable}
        ref={props.onMarkerMounted}
        onDragEnd={props.onDragEnd}
      />
    )}
    {props.circleRadius && (
      <Circle
        center={{ lat: props.lat, lng: props.lon }}
        radius={props.circleRadius}
        options={{
          strokeColor: '#04A5D5',
          strokeOpacity: 0.6,
          strokeWeight: 1,
          fillColor: '#04A5D5',
          fillOpacity: 0.2,
        }}
      />
    )}
  </GoogleMap>
));

LocationMap.defaultProps = {
  editable: false,
  showMarker: false,
  lat: 0.0,
  lon: 0.0,
};

LocationMap.propTypes = {
  lat: PropTypes.number,
  lon: PropTypes.number,
  editable: PropTypes.bool,
  showMarker: PropTypes.bool,
  mapRefCallback: PropTypes.func,
  locationCallback: PropTypes.func,
  zoom: PropTypes.number,
};

export default LocationMap;

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import SubTabHeader from './subcomponents/SubTabHeader';
import TileAgencyExclusion from './subcomponents/records/TileAgencyExclusion';
import TileAgencyContacts from './subcomponents/records/TileAgencyContacts';
import TileGrid from './subcomponents/records/TileGrid';
import TileRecentCaregivers from './subcomponents/records/TileRecentCaregivers';

class ClientAgencyInformation extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onActive(this.props.tabIndex);
  }

  render() {
    const {
      original,
      intl,
      client,
      employees,
      onValueChange,
      onAgencyChange,
      onSave,
      saveEnabled,
      editable,
    } = this.props;

    return (
      <>
        <SubTabHeader
          text={intl.formatMessage({ id: 'records.agency.information' }).toUpperCase()}
          buttonTitle={intl.formatMessage({ id: 'records.saveUpdates' })}
          buttonOnClick={onSave}
          buttonDisabled={!saveEnabled}
        />
        <TileGrid>
          <TileAgencyContacts
            original={original}
            person={client}
            employees={employees}
            onAgencyChange={onAgencyChange}
            onValueChange={onValueChange}
            type='client'
            editable={editable}
          />
          <TileAgencyExclusion
            person={client}
            onValueChange={onValueChange}
            editable={editable}
            type='client'
          />
          <TileRecentCaregivers client={client} />
        </TileGrid>
      </>
    );
  }
}

ClientAgencyInformation.defaultProps = {
  saveEnabled: false,
  editable: false,
  onActive: () => {},
};

ClientAgencyInformation.propTypes = {
  intl: intlShape.isRequired,
  client: PropTypes.shape().isRequired,
  original: PropTypes.shape().isRequired,
  onSave: PropTypes.func.isRequired,
  saveEnabled: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
  onAgencyChange: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  employees: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onActive: PropTypes.func,
  tabIndex: PropTypes.number.isRequired,
};

export default injectIntl(ClientAgencyInformation);

import moment from 'moment';
import React, { Component } from 'react';
import { Table, Image } from 'semantic-ui-react';
import styled, { StyledComponent } from 'styled-components';
import { formatPhoneNumber, MOMENT_HOURS_24, SHORT_DATE } from '../../../constants/Formats';
import { IApplicantProfile } from '../../../reducers/types/applicants';
import { SimpleButton, GreenButton } from '../../../styles/common';
import { ButtonsContainer } from '../../HRApplicants';
import HRApplicantNameCell from './HRApplicantNameCell';
import icon_check_yes from '../../../images/icon_check_yes.svg';

interface IProps {
  applicants: IApplicantProfile[];
  applicantResetStatus: (tokens: string[]) => void;
  applicantApprove: (tokens: string[]) => void;
  handleNameClick: (applicant: IApplicantProfile) => void;
  sortApplicants: (
    applicants: IApplicantProfile[],
    column: string,
    direction: string
  ) => IApplicantProfile[];
}

interface IState {
  column: string;
  direction: string;
  sortedApplicants: IApplicantProfile[];
  selected: string[];
  allSelected: boolean;
}

interface ICheckBoxProps {
  checked?: boolean;
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const CheckBox: StyledComponent<'div', any, ICheckBoxProps, never> & ICheckBoxProps = styled.div`
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 6px;
  border: ${(props: ICheckBoxProps) => (props.checked ? 'none' : '1px solid #c8cbd3')};
  outline: none;
  background-color: ${(props: ICheckBoxProps) => (props.checked ? '#69DB8F' : '')};
  background-image: ${(props: ICheckBoxProps) => (props.checked ? `url(${icon_check_yes})` : '')};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
`;

class HRApplicantsApprovedTable extends Component<IProps, IState> {
  public state: IState = {
    column: 'name',
    direction: 'descending',
    sortedApplicants: [],
    selected: [],
    allSelected: false,
  };

  public fileInput: { [key: string]: HTMLInputElement | null } = {};

  private readonly handleSort = (newColumn: string) => {
    const { direction, column } = this.state;
    const { applicants, sortApplicants } = this.props;
    const newDirection =
      column === newColumn && direction === 'descending' ? 'ascending' : 'descending';
    const sortedApplicants = sortApplicants(applicants, newColumn, newDirection);

    this.setState(s => ({
      ...s,
      column: newColumn,
      direction: newDirection,
      sortedApplicants,
    }));
  };

  private readonly handleSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const token = e.currentTarget.id;
    this.fileInput[token]?.click();
  };

  private readonly onCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { selected } = this.state;
    const newValue = e.target.id;
    let newSelection = selected;
    if (selected.includes(newValue)) {
      newSelection = selected.filter(t => t !== newValue);
    } else {
      newSelection.push(newValue);
    }
    return this.setState(s => ({ ...s, selected: newSelection }));
  };

  private readonly handleApplicantResetStatus = () => {
    const { selected } = this.state;
    const { applicantResetStatus } = this.props;
    applicantResetStatus(selected);
    this.setState(s => ({ ...s, selected: [] }));
  };

  private readonly handleApplicantApprove = () => {
    const { selected } = this.state;
    const { applicantApprove } = this.props;
    applicantApprove(selected);
    this.setState(s => ({ ...s, selected: [] }));
  };

  private readonly tableHeader = (
    column: string,
    direction: string,
    onHeaderClick: (column: string) => void
  ) => {
    const headerArray = [
      {
        id: 'hr-applicant-new-table-header-name',
        key: 'name',
        content: 'Name',
        onClick: () => onHeaderClick('name'),
        className: '',
        filter: true,
      },
      {
        id: 'hr-applicant-new-table-header-email',
        key: 'email',
        content: <span>Email</span>,
        textAlign: 'center',
        onClick: () => onHeaderClick('email'),
        className: '',
        filter: true,
      },
      {
        id: 'hr-applicant-new-table-header-phone',
        key: 'phone',
        content: <span>Phone</span>,
        textAlign: 'center',
        onClick: () => onHeaderClick('phone'),
        className: '',
        filter: true,
      },
      {
        id: 'hr-applicant-new-table-header-zip',
        key: 'zip',
        content: 'Zip',
        textAlign: 'center',
        onClick: () => onHeaderClick('zip'),
        className: '',
        filter: true,
      },
      {
        id: 'hr-applicant-new-table-header-submission-date',
        key: 'submission-date',
        content: 'Submission Date',
        textAlign: 'center',
        onClick: () => onHeaderClick('submission-date'),
        className: '',
        filter: true,
      },
      {
        id: 'hr-applicant-new-table-header-action',
        key: 'action',
        filter: false,
      },
    ];

    headerArray
      .filter(f => f.filter)
      .forEach(h => {
        Object.assign(h, {
          className: column === h.key ? `${direction} sorted` : undefined,
        });
        Object.assign(h, { filter: undefined });
      });

    return headerArray;
  };

  private readonly tableBody = (x: IApplicantProfile) => {
    const { selected, allSelected } = this.state;
    const { handleNameClick } = this.props;

    const phone = x.additionalInformation?.cellPhone
      ? formatPhoneNumber(x.additionalInformation.cellPhone)
      : '-';
    const check = allSelected || selected.includes(x.token);

    return {
      key: `${x.token}`,
      className: 'infoText',
      cells: [
        {
          key: 'name',
          content: <HRApplicantNameCell handleNameClick={handleNameClick} applicant={x} />,
        },
        {
          key: 'email',
          verticalAlign: 'middle',
          textAlign: 'center',
          content: x.basicInformation.email,
        },
        { key: 'phone', textAlign: 'center', content: phone },
        {
          key: 'zip',
          verticalAlign: 'middle',
          textAlign: 'center',
          content: x.additionalInformation.address.zip,
        },
        {
          key: 'submission-date',
          verticalAlign: 'middle',
          textAlign: 'center',
          content: (
            <>
              <span className='infoTextDate'>
                {moment(x.profileStatus.created).format(SHORT_DATE)}
              </span>
              {moment(x.profileStatus.created).format(MOMENT_HOURS_24)}
            </>
          ),
        },
        {
          key: 'action',
          verticalAlign: 'middle',
          textAlign: 'center',
          content: (
            <CheckBox id={x.token} onClick={this.handleSelect} checked={check}>
              <input
                // eslint-disable-next-line no-return-assign
                ref={r => (this.fileInput[x.token] = r)}
                id={x.token}
                onChange={this.onCheckBoxChange}
                type='checkbox'
                checked={check}
                style={{ display: 'none' }}
              />
            </CheckBox>
          ),
        },
      ],
    };
  };

  public render(): JSX.Element {
    const { applicants } = this.props;
    const { column, direction, selected, sortedApplicants } = this.state;
    return (
      <div>
        <Table
          className='Applicant-table'
          sortable
          singleLine
          striped
          headerRow={this.tableHeader(column, direction, this.handleSort)}
          renderBodyRow={this.tableBody}
          tableData={sortedApplicants.length ? sortedApplicants : applicants}
        />
        {!applicants.length ? (
          <div>
            <div className='e-learning-empty-table'>
              <h3>No Matching Results</h3> <Image src='/nomatch.svg' />
              <p>Try Removing some of your search filters</p>
            </div>
          </div>
        ) : (
          <ButtonsContainer>
            <SimpleButton disabled={!selected.length} onClick={this.handleApplicantResetStatus}>
              Revert Application
            </SimpleButton>
            <GreenButton disabled={!selected.length} onClick={this.handleApplicantApprove}>
              Move To Candidate
            </GreenButton>
          </ButtonsContainer>
        )}
      </div>
    );
  }
}

export default HRApplicantsApprovedTable;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Range } from 'react-input-range';
import { CSVLink } from 'react-csv';
import { useSelector, useDispatch } from 'react-redux';
import { Accordion, AccordionTitleProps, DropdownProps } from 'semantic-ui-react';
import { Data } from 'react-csv/components/CommonPropTypes';
import {
  HRFilterType,
  IHRCandidatesFilter,
  IHRCaregiversFilter,
  IComplianceOptions,
} from '../../../../reducers/types/HRFilters';
import CandidatesRail from './CandidatesRail';
import CaregiversRail from './CaregiversRail';
import { FilterSections, findCaregiversActiveAccordion } from './helper';
import { RailHeader, RailBody, RailFooter, RailBlueButton, RailSimpleButton } from './styles';
import filterActions from '../../../../actions/hrapp/filterActions';
import { Blocks } from '../../compliance/ComplianceMedicalTypes';
import { Employee } from '../../../../reducers/types/principal';
import hrHelper from '../../../../helpers/hrHelper';
import { alertActions } from '../../../../actions';

interface IProps {
  handleUpdate: () => void;
  generateFileName: (prefix: string) => string;
  candidates?: boolean;
  compliancePrintData: string | Data;
  vaccinePrintData: string | Data;
}

const HRLeftRail: React.FC<IProps> = ({
  candidates,
  handleUpdate,
  generateFileName,
  compliancePrintData,
  vaccinePrintData,
}) => {
  const [active, setActive] = useState<FilterSections[]>([]);
  const [prevFilters, setPrevFilters] = useState('');
  const csvComplianceRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const csvVaccineRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const dispatch = useDispatch();
  const caregiversFilters = useSelector(
    (state: any) => state.HRCaregiverFilter as IHRCaregiversFilter
  );
  const candidatesFilters = useSelector(
    (state: any) => state.HRCandidatesFilter as IHRCandidatesFilter
  );
  const employee = useSelector((state: any) => state.employee);
  const { employeeList } = employee;
  const principal = useSelector((state: any) => state.principal);
  const {
    disciplines,
    secondaryStatusCodes,
    complianceSettings: { blocks = [], preHireBlocks = [] } = {},
    settings: { vaccineServiceEnabled },
  } = principal;

  useEffect(() => {
    if (candidates) {
      const cloneCandidatesFilters = JSON.parse(JSON.stringify(candidatesFilters));
      setPrevFilters(JSON.stringify(cloneCandidatesFilters.compliancesIssues));
    } else {
      const cloneCaregiversFilters = JSON.parse(JSON.stringify(caregiversFilters));
      setPrevFilters(
        `${JSON.stringify(cloneCaregiversFilters.compliancesIssues)}, ${JSON.stringify(
          cloneCaregiversFilters.vaccinationValidation
        )}`
      );
    }
    setActive(findCaregiversActiveAccordion(candidatesFilters, caregiversFilters, candidates));
    return () => {
      setActive([]);
    };
  }, [candidates]);

  const primaryContactOption = employeeList
    .filter((e: Employee) => e.isCoordinator)
    .map((e: Employee) => ({
      text: `${e.lastName || ''}, ${e.firstName || ''}`,
      value: e.id,
    }));

  const primaryContactManagerOptions = employeeList
    .filter((e: Employee) => e.isManager)
    .map((e: Employee) => ({
      text: `${e.lastName || ''}, ${e.firstName || ''}`,
      value: e.id,
    }));

  const preHireOptions = preHireBlocks.reduce(
    (r: IComplianceOptions, b: Blocks) => {
      const blockOption = { text: b.name, value: b.id };
      switch (b.type) {
        case 'medical':
          r.medical.push(blockOption);
          break;
        case 'training':
          r.training.push(blockOption);
          break;
        case 'documentation':
          r.documentation.push(blockOption);
          break;
        default:
          r.eligibility?.push(blockOption);
          break;
      }
      return r;
    },
    {
      eligibility: [],
      medical: [],
      training: [],
      documentation: [],
    }
  );

  const complianceOptions = blocks.reduce(
    (r: IComplianceOptions, b: Blocks) => {
      if (b.groupName && b.groupName === 'COVID') {
        return r;
      }
      if (!b.active || b.schedulable) return r;
      const blockOption = { text: b.name, value: b.id };
      switch (b.type) {
        case 'medical':
          r.medical.push(blockOption);
          break;
        case 'training':
          r.training.push(blockOption);
          break;
        case 'documentation':
          r.documentation.push(blockOption);
          break;
        default:
          break;
      }
      if (b.includedInEligibility) {
        r.eligibility?.push(blockOption);
      }
      return r;
    },
    {
      eligibility: [],
      medical: [],
      training: [],
      documentation: [],
    }
  );

  const onUpdate = () => {
    if (candidates) {
      const cloneCandidatesFilters = JSON.parse(JSON.stringify(candidatesFilters));
      setPrevFilters(JSON.stringify(cloneCandidatesFilters.compliancesIssues));
    } else {
      const cloneCaregiversFilters = JSON.parse(JSON.stringify(caregiversFilters));
      setPrevFilters(
        `${JSON.stringify(cloneCaregiversFilters.compliancesIssues)}, ${JSON.stringify(
          cloneCaregiversFilters.vaccinationValidation
        )}`
      );
    }
    handleUpdate();
  };

  const handleAccordionClick = (_e: unknown, titleProps: AccordionTitleProps) => {
    const { index } = titleProps;
    if (typeof index === 'undefined') {
      return;
    }
    if (active.find((i: FilterSections) => i === index)) {
      const newActives = active.filter((i: FilterSections) => i !== index);
      setActive(newActives);
      return;
    }
    setActive([...active, index as FilterSections]);
  };

  const handleDropdownChange = (data: DropdownProps, type: string) => {
    const { id, tabIndex, value } = data;
    let newValue = value;
    if (typeof value === 'object' && value.length) {
      if (value[value.length - 1] === 0 || value[value.length - 1] === '00') {
        newValue = value.filter(v => v === 0 || v === '00');
      } else {
        newValue = value.filter(v => v !== 0 && v !== '00');
      }
    }
    const camelSection = _.camelCase(tabIndex as string);
    switch (type) {
      case 'caregivers':
        dispatch(filterActions.caregiverFilterChange(camelSection as FilterSections, id, newValue));
        break;
      case 'candidates':
        dispatch(
          filterActions.candidatesFilterChange(camelSection as FilterSections, id, newValue)
        );
        break;
      default:
        break;
    }
  };

  const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, dataset } = e.currentTarget;
    const { section } = dataset;
    const camelSection = _.camelCase(section);
    const prevStatus =
      caregiversFilters[camelSection as keyof IHRCaregiversFilter][id as keyof HRFilterType];
    dispatch(filterActions.caregiverFilterChange(camelSection as FilterSections, id, !prevStatus));
  };

  const handleRangeChange = (value: number | Range) => {
    dispatch(
      filterActions.caregiverFilterChange('compliancesIssues' as FilterSections, 'dueDate', value)
    );
  };

  const complianceSelected = () =>
    candidates
      ? candidatesFilters.compliancesIssues.eligibility.length ||
        candidatesFilters.compliancesIssues.medical.length ||
        candidatesFilters.compliancesIssues.documentation.length ||
        candidatesFilters.compliancesIssues.training.length
      : caregiversFilters.compliancesIssues.eligibility.length ||
        caregiversFilters.compliancesIssues.medical.length ||
        caregiversFilters.compliancesIssues.documentation.length ||
        caregiversFilters.compliancesIssues.training.length;

  const vaccineSelected = () =>
    !candidates && caregiversFilters.vaccinationValidation.vaccine?.length;

  const printReports = () => {
    if (vaccineSelected() && complianceSelected()) {
      dispatch(alertActions.notification('Compliance and Vaccination reports exported'));
    }
    if (vaccineSelected()) {
      csvVaccineRef?.current?.link.click();
    }
    if (complianceSelected()) {
      csvComplianceRef?.current?.link.click();
    }
  };

  const hasChange = () => {
    if (candidates) {
      return JSON.stringify(candidatesFilters.compliancesIssues) === prevFilters;
    }
    return (
      `${JSON.stringify(caregiversFilters.compliancesIssues)}, ${JSON.stringify(
        caregiversFilters.vaccinationValidation
      )}` === prevFilters
    );
  };

  const printDisabled = () => !((complianceSelected() || vaccineSelected()) && hasChange());

  return (
    <>
      <CSVLink
        style={{ display: 'none' }}
        ref={csvComplianceRef}
        filename={generateFileName('Compliances')}
        headers={hrHelper.CSV_HEADERS}
        data={compliancePrintData}
      />
      <CSVLink
        style={{ display: 'none' }}
        ref={csvVaccineRef}
        filename={generateFileName('Vaccination')}
        headers={hrHelper.CSV_VACCINATION_HEADERS}
        data={vaccinePrintData}
      />
      <RailHeader>
        <div>
          <img src='/filters_icon.svg' width='18' alt='searching icon' />
          <span>Filter Options</span>
        </div>
        <div className='left-rail-divider' />
      </RailHeader>
      <RailBody>
        <Accordion fluid exclusive={false}>
          {candidates ? (
            <CandidatesRail
              active={active}
              disciplinesOptions={disciplines}
              complianceOptions={preHireOptions}
              primaryContactOption={primaryContactOption}
              primaryManagerOption={primaryContactManagerOptions}
              filters={candidatesFilters}
              onAccordionClick={handleAccordionClick}
              onDropdownChange={handleDropdownChange}
            />
          ) : (
            <CaregiversRail
              active={active}
              disciplinesOptions={disciplines}
              complianceOptions={complianceOptions}
              vaccineEnabled={vaccineServiceEnabled}
              primaryContactOption={primaryContactOption}
              primaryManagerOption={primaryContactManagerOptions}
              filters={caregiversFilters}
              secondaryStatus={secondaryStatusCodes}
              onAccordionClick={handleAccordionClick}
              onDropdownChange={handleDropdownChange}
              onCheckBoxChange={handleCheckChange}
              onRangeChange={handleRangeChange}
            />
          )}
        </Accordion>
      </RailBody>
      <RailFooter>
        <div className='left-rail-divider' />
        <RailBlueButton onClick={onUpdate}>Update</RailBlueButton>
        <RailSimpleButton disabled={printDisabled()} onClick={printReports}>
          Export
        </RailSimpleButton>
      </RailFooter>
    </>
  );
};

export default HRLeftRail;

import _ from 'underscore';

import {
  LIST_SCHEDULES_REQUEST,
  LIST_SCHEDULES_SUCCESS,
  LIST_SCHEDULES_FAILURE,
  RESET_SCHEDULES_SEARCH,
  NOTIFICATION_SHIFT_UPDATED,
  SCHEDULE_CB_CLICKED,
  SCHEDULE_CB_ALL_CLICKED,
  CREATE_SHIFTS_SUCCESS,
  ADD_CAREGIVER_TO_SHIFT_SUCCESS,
} from '../constants/ActionTypes';

import { prepareShiftObject } from './helpers';

const initialState = {
  loading: false,
  initialRequestDone: false,
  pickedShifts: {},
  activeClient: null,
  activeService: null,
  allSelected: false,
  inviteDisabled: true,
  uniqueClients: {},
  partialUpdate: false,
};

const defaultValue = uniqueClients =>
  uniqueClients && uniqueClients.length === 1 ? uniqueClients[0] : null;

function schedules(state = initialState, action) {
  switch (action.type) {
    case LIST_SCHEDULES_REQUEST:
      return {
        ...state,
        loading: true,
        initialRequestDone: true,
        partialUpdate: action.partialUpdate,
      };
    case LIST_SCHEDULES_SUCCESS: {
      const results = action.results ? action.results.results.map(x => prepareShiftObject(x)) : [];

      let newResults = action.partialUpdate ? state.results.concat(results) : results;
      newResults = _.uniq(newResults, false, s => s.id);

      const uniqueClients = [...new Set(newResults.map(x => x.client.id))];
      const uniqueServices = [...new Set(newResults.map(x => x.service))];

      return {
        ...state,
        initialRequestDone: true,
        loading: false,
        pickedShifts: {},
        activeClient: defaultValue(uniqueClients),
        activeService: defaultValue(uniqueServices),
        allSelected: false,
        results: newResults,
        tooManyResults: action.tooManyResults,
        nextPage: action.results.nextPage,
        uniqueClients,
        uniqueServices,
        partialUpdate: action.partialUpdate,
      };
    }
    case RESET_SCHEDULES_SEARCH:
      return { ...state, results: undefined };
    case CREATE_SHIFTS_SUCCESS:
      return { ...state, initialRequestDone: false };
    case NOTIFICATION_SHIFT_UPDATED: {
      if (!state.results) {
        return state;
      }

      const newResults = [...state.results];
      action.results.forEach(s => {
        const index = newResults.findIndex(i => s.id === i.id);
        if (index >= 0) {
          newResults[index] = prepareShiftObject({ ...s });
        }
      });
      return { ...state, results: newResults };
    }
    case LIST_SCHEDULES_FAILURE:
      return {
        ...state,
        initialRequestDone: true,
        loading: false,
        partialUpdate: action.partialUpdate,
      };

    case SCHEDULE_CB_CLICKED: {
      if (
        (state.activeClient && state.activeClient !== action.clientId) ||
        (state.activeService && state.activeService !== action.service)
      ) {
        return state;
      }

      const newItems = {
        ...state.pickedShifts,
        [action.shiftId]: !state.pickedShifts[action.shiftId],
      };

      let activeClient = action.clientId;
      let activeService = action.service;
      if (!Object.values(newItems).find(i => i)) {
        activeClient = defaultValue(state.uniqueClients);
        activeService = defaultValue(state.uniqueServices);
      }

      return {
        ...state,
        pickedShifts: newItems,
        activeClient,
        activeService,
        allSelected: false,
        inviteDisabled: !Object.values(newItems).find(i => i),
      };
    }
    case SCHEDULE_CB_ALL_CLICKED: {
      const allSelected = !state.allSelected;
      let newItems = {};
      if (allSelected) {
        newItems = {
          ...state.pickedShifts,
        };

        state.results.forEach(r => {
          if (
            r.clientId === state.activeClient &&
            r.service === state.activeService &&
            r.actionable
          ) {
            newItems[r.id] = true;
          }
        });
      }

      return {
        ...state,
        pickedShifts: newItems,
        allSelected,
        activeClient: allSelected ? state.activeClient : defaultValue(state.uniqueClients),
        activeService: allSelected ? state.activeService : defaultValue(state.uniqueServices),
        inviteDisabled: !Object.values(newItems).find(i => i),
      };
    }
    case ADD_CAREGIVER_TO_SHIFT_SUCCESS: {
      if (!state.results) {
        return state;
      }

      let newResults = [...state.results];

      if (Array.isArray(action.results)) {
        newResults = [...state.results];
      } else {
        action.results.caregiver.shifts.forEach(s => {
          const index = newResults.findIndex(i => i.id === s.id);
          if (index >= 0) {
            if (s.status === 'offered' && action.status !== 'remind') {
              newResults[index].invites += 1;
            } else if (s.status === 'assigned') {
              newResults[index].actionable = false;
              newResults[index].shiftAssigmentStatus = 'assigned';
              newResults[index].assignedCaregivers.push(action.results.caregiver);
            }
          }
        });
      }

      return { ...state, results: newResults };
    }
    default:
      return state;
  }
}

export default schedules;

/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import TableWithExport from './TableWithExport';
import { formatTime, SHORT_DATE, MOMENT_HOURS_24 } from '../../constants/Formats';
import { sortTableData } from '../../helpers/common';
import LoadMore from './LoadMore';
import NoMatch from './NoMatch';
import InitialState from './InitialState';

class ChangeLogChatTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      column: 'timestamp',
      data: props.data,
      direction: 'descending',
    };

    this.handleSort = this.handleSort.bind(this);
    this.tableHeader = this.tableHeader.bind(this);
    this.tableBody = this.tableBody.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !!nextState.update || this.props.loading !== nextProps.loading;
  }

  onExportClick() {
    return this.onExportClick ? this.onExportClick() : undefined;
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;

    if (!data) return;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: sortTableData(data, clickedColumn),
        direction: 'ascending',
        update: true,
      });

      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
      update: true,
    });
  };

  timestampColumn = (timestamp, boldTime, fromCaregiver = true) => {
    const dirIcon = fromCaregiver ? (
      <Icon name='caret up' className='icon-green' />
    ) : (
      <Icon name='caret down' className='icon-red' />
    );
    return (
      <span className='infoText'>
        <span className={boldTime ? 'infoTextBold' : ''}>
          {' '}
          {dirIcon} {formatTime(timestamp, null, SHORT_DATE)}
        </span>{' '}
        at {formatTime(timestamp, null, MOMENT_HOURS_24)}
      </span>
    );
  };

  responseColumn = (timestamp, applied) => (
    <li className={applied ? 'greenText' : 'redText'}>
      {applied ? 'Requested' : 'Declined'} {this.timestampColumn(timestamp)}
    </li>
  );

  assignedColumn = timestamp => {
    if (timestamp) {
      return <li className='greenText'>Assigned {this.timestampColumn(timestamp)}</li>;
    }

    return undefined;
  };

  tableHeader = (column, direction, onHeaderClick) => {
    const { caregiverId } = this.props;

    const headerArray = [
      {
        id: 'change-log-chat-table-header-timestamp',
        key: 'timestamp',
        content: 'Date & Time',
        filter: true,
      },
      ...(caregiverId
        ? []
        : [
            {
              id: 'change-log-chat-table-header-caregivers',
              key: 'caregivers',
              content: 'Caregiver',
              filter: true,
            },
          ]),
      {
        id: 'change-log-chat-table-header-employees',
        key: 'employees',
        content: 'Employee(s)',
        filter: true,
      },
      { id: 'change-log-chat-table-header-text', key: 'text', content: 'Message', filter: true },
    ];

    headerArray
      .filter(f => f.filter)
      .forEach(h => {
        // eslint-disable-next-line no-param-reassign
        h.className =
          column === h.key || (Array.isArray(column) && h.key === 'timestamp')
            ? `${direction} sorted`
            : undefined;

        // eslint-disable-next-line no-param-reassign
        h.onClick = onHeaderClick(h.key);

        // eslint-disable-next-line no-param-reassign
        h.filter = undefined;
      });

    return headerArray;
  };

  tableBody = (x, i) => {
    const fromCaregiver = x.sender === 'caregiver';
    const caregivers = x.participants.filter(z => z.role === 'caregiver');
    const schedulers = x.participants.filter(z => z.role === 'scheduler');

    const { caregiverId } = this.props;

    const timeText = `${formatTime(x.timestamp, null, SHORT_DATE)} at ${formatTime(
      x.timestamp,
      null,
      MOMENT_HOURS_24
    )}`;
    return {
      key: `${x.id}-${i}}`,
      className: 'infoText',
      cells: [
        {
          key: 'timestamp',
          content: this.timestampColumn(x.timestamp, true, fromCaregiver),
          textContent: `${fromCaregiver ? 'Caregiver' : 'Employee'} ${timeText}`,
        },
        ...(caregiverId
          ? []
          : [
              {
                key: 'caregivers',
                content: caregivers.map(c => `${c.firstName} ${c.lastName}`).join(', '),
              },
            ]),
        {
          key: 'employees',
          content: schedulers.map(c => `${c.firstName} ${c.lastName}`).join(', '),
        },
        {
          key: 'text',
          content: x.text,
          textContent: x.text,
          width: 'four',
          style: { wordWrap: 'break-word' },
        },
      ],
    };
  };

  render() {
    const { column, data, direction } = this.state;

    return (
      <div>
        <TableWithExport
          className='changeLogResults'
          sortable
          singleLine
          striped
          fixed
          headerRow={this.tableHeader(column, direction, this.handleSort)}
          renderBodyRow={this.tableBody}
          tableData={this.props.loading && !this.props.partialUpdate ? [] : data}
          getOnClick={clickFunc => {
            this.onExportClick = clickFunc;
            return null;
          }}
        />
        <div className='loadMore'>
          <LoadMore
            onLoadMore={() => this.props.onLoadMore(this.props.nextPage)}
            loading={this.props.loading}
            partialUpdate={this.props.partialUpdate}
            nextPage={this.props.nextPage}
          />
        </div>
        {!this.props.loading && data && data.length === 0 && (
          <Grid className='noResults'>
            <NoMatch id='chatResultsNoMatch' />
          </Grid>
        )}
        {!data ||
          (this.props.loading && !this.props.partialUpdate && (
            <InitialState searchingFor='chat messages' loading={this.props.loading} />
          ))}
      </div>
    );
  }
}

ChangeLogChatTable.defaultProps = {
  caregiverId: undefined,
};

ChangeLogChatTable.propTypes = {
  data: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  partialUpdate: PropTypes.bool.isRequired,
  nextPage: PropTypes.string.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  caregiverId: PropTypes.number,
};

export default ChangeLogChatTable;

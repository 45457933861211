import {
  GET_COURSES_LIST_REQUEST,
  GET_COURSES_LIST_SUCCESS,
  GET_COURSES_LIST_FAILURE,
  GET_COURSES_DETAIL_REQUEST,
  GET_COURSES_DETAIL_SUCCESS,
  GET_COURSES_DETAIL_FAILURE,
  GET_COURSES_DETAIL_LIST_SUCCESS,
  START_COURSE_REQUEST,
  START_COURSE_SUCCESS,
  START_COURSE_FAILURE,
  RESUME_COURSE,
  START_MODULE_REQUEST,
  START_MODULE_SUCCESS,
  START_MODULE_FAILURE,
  EXIT_MODULE_REQUEST,
  EXIT_MODULE_SUCCESS,
  EXIT_MODULE_FAILURE,
  CREATE_QUIZ_ATTEMPT_REQUEST,
  CREATE_QUIZ_ATTEMPT_SUCCESS,
  CREATE_QUIZ_ATTEMPT_FAILURE,
  GET_QUIZ_ATTEMPT_REQUEST,
  GET_QUIZ_ATTEMPT_SUCCESS,
  GET_QUIZ_ATTEMPT_FAILURE,
  UPDATE_QUIZ_ATTEMPT_REQUEST,
  UPDATE_QUIZ_ATTEMPT_SUCCESS,
  UPDATE_QUIZ_ATTEMPT_FAILURE,
  REVIEW_QUIZ_REQUEST,
  REVIEW_QUIZ_SUCCESS,
  REVIEW_QUIZ_FAILURE,
  CLEAR_QUIZ,
  CLEAR_SINGLE_COURSE,
  GET_COURSE_CERTIFICATION_REQUEST,
  GET_COURSE_CERTIFICATION_SUCCESS,
  GET_COURSE_CERTIFICATION_FAILURE,
  CLEAR_COURSE_CERTIFICATION,
  DELETE_COURSE_REQUEST,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_FAILURE,
  GET_USER_E_SIGNATURE_REQUEST,
  GET_USER_E_SIGNATURE_SUCCESS,
  GET_USER_E_SIGNATURE_FAILURE,
  PUT_USER_E_SIGNATURE_REQUEST,
  PUT_USER_E_SIGNATURE_SUCCESS,
  PUT_USER_E_SIGNATURE_FAILURE,
  GET_SIGNING_TEMPLATE_REQUEST,
  GET_SIGNING_TEMPLATE_SUCCESS,
  GET_SIGNING_TEMPLATE_FAILURE,
  POST_SIGNED_TEMPLATE_REQUEST,
  POST_SIGNED_TEMPLATE_SUCCESS,
  POST_SIGNED_TEMPLATE_FAILURE,
} from '../../constants/ActionTypes';

const initialState = {
  loading: false,
  courses: [],
  courseDetails: {},
  buttonLoading: false,
  refreshCourseList: false,
  moduleOnProgress: '',
  resumingCourse: false,
  error: false,
  quizId: null,
  courseId: null,
  moduleId: null,
  quiz: {},
  quizReview: {},
  reviewMode: false,
  courseCertificate: '',
  certificationOn: false,
  refreshInprogress: false,
  exitedModule: false,
  signTemplate: '',
  checkRequiredSignature: false,
  coursesSigned: [],
  nextModule: null,
};

const startState = JSON.parse(JSON.stringify(initialState));

function eLCourses(state = startState, action) {
  switch (action.type) {
    case GET_COURSES_LIST_REQUEST:
      return { ...state, loading: true };
    case GET_COURSES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        refreshCourseList: false,
        courses: [...action.results.courses],
        refreshInprogress: true,
      };
    case GET_COURSES_LIST_FAILURE:
      return { ...state, loading: false };
    case GET_COURSES_DETAIL_REQUEST:
      return { ...state, loading: true };
    case GET_COURSES_DETAIL_SUCCESS: {
      let newCourseDetails = {};
      if (action.results.userCourse) {
        newCourseDetails = {
          userCourse: action.results.userCourse,
          modules: action.results.modules,
          supportPhone: action.results.supportPhone,
        };
      }
      return {
        ...state,
        loading: false,
        courseDetails: newCourseDetails,
        refreshInProgress: false,
        exitedModule: false,
      };
    }
    case GET_COURSES_DETAIL_LIST_SUCCESS:
      return { ...state, loading: false };
    case GET_COURSES_DETAIL_FAILURE:
      return { ...state, loading: false };
    case START_COURSE_REQUEST:
      return { ...state, buttonLoading: true };
    case START_COURSE_SUCCESS:
      return { ...state, buttonLoading: false, refreshCourseList: true, courses: [] };
    case START_COURSE_FAILURE:
      return { ...state, buttonLoading: false };
    case RESUME_COURSE:
      return { ...state, resumeCourse: true };
    case START_MODULE_REQUEST: {
      return { ...state, buttonLoading: true, resumeCourse: false };
    }
    case START_MODULE_SUCCESS: {
      return { ...state, moduleOnProgress: action.results.value, buttonLoading: false };
    }
    case START_MODULE_FAILURE: {
      return { ...state, buttonLoading: false };
    }
    case EXIT_MODULE_REQUEST: {
      return { ...state, buttonLoading: true, resumingCourse: false, loading: true };
    }
    case EXIT_MODULE_SUCCESS: {
      return {
        ...state,
        loading: false,
        buttonLoading: false,
        moduleOnProgress: '',
        resumingCourse: false,
        exitedModule: true,
        nextModule: action.nextModule,
      };
    }
    case EXIT_MODULE_FAILURE: {
      return { ...state, loading: false, buttonLoading: false };
    }
    case CREATE_QUIZ_ATTEMPT_REQUEST:
      return { ...state, loading: true, buttonLoading: true, resumeCourse: false, error: false };
    case CREATE_QUIZ_ATTEMPT_SUCCESS:
      return {
        ...state,
        loading: false,
        buttonLoading: false,
        quizId: action.results.quizId,
        courseId: action.params.courseId,
        moduleId: action.params.moduleId,
        error: false,
      };
    case CREATE_QUIZ_ATTEMPT_FAILURE:
      return { ...state, loading: false, buttonLoading: false, error: true };
    case GET_QUIZ_ATTEMPT_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_QUIZ_ATTEMPT_SUCCESS: {
      return { ...state, loading: false, error: false, quiz: action.results.quiz };
    }
    case GET_QUIZ_ATTEMPT_FAILURE:
      return { ...state, loading: false, error: true };
    case UPDATE_QUIZ_ATTEMPT_REQUEST:
      return { ...state, loading: true };
    case UPDATE_QUIZ_ATTEMPT_SUCCESS: {
      return { ...state, loading: false };
    }
    case UPDATE_QUIZ_ATTEMPT_FAILURE:
      return { ...state, loading: false };
    case REVIEW_QUIZ_REQUEST:
      return { ...state, buttonLoading: true, loading: true };
    case REVIEW_QUIZ_SUCCESS:
      return {
        ...state,
        loading: false,
        buttonLoading: false,
        reviewMode: true,
        quizReview: action.results,
      };
    case REVIEW_QUIZ_FAILURE:
      return { ...state, buttonLoading: false, loading: false };
    case CLEAR_QUIZ: {
      return {
        ...state,
        loading: false,
        quizId: null,
        courseId: null,
        moduleId: null,
        quiz: {},
        quizReview: {},
        reviewMode: false,
        resumingCourse: false,
      };
    }
    case CLEAR_SINGLE_COURSE:
      return { ...state, loading: false, courseDetails: {} };
    case GET_COURSE_CERTIFICATION_REQUEST:
      return { ...state, buttonLoading: true, loading: true };
    case GET_COURSE_CERTIFICATION_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        loading: false,
        courseCertificate: action.results.url,
        certificationOn: true,
      };
    case GET_COURSE_CERTIFICATION_FAILURE:
      return { ...state, buttonLoading: false, loading: false };
    case CLEAR_COURSE_CERTIFICATION:
      return {
        ...state,
        buttonLoading: false,
        loading: false,
        courseCertificate: '',
        certificationOn: false,
      };
    case DELETE_COURSE_REQUEST: {
      return { ...state, buttonLoading: true, loading: true };
    }
    case DELETE_COURSE_SUCCESS: {
      return { ...state, buttonLoading: false, loading: false, courses: [], courseDetails: {} };
    }
    case DELETE_COURSE_FAILURE:
      return { ...state, buttonLoading: false, loading: false };
    case PUT_USER_E_SIGNATURE_REQUEST:
      return { ...state, loading: true };
    case PUT_USER_E_SIGNATURE_SUCCESS:
    case PUT_USER_E_SIGNATURE_FAILURE:
      return { ...state, loading: false };
    case GET_USER_E_SIGNATURE_REQUEST:
      return { ...state, loading: true };
    case GET_USER_E_SIGNATURE_SUCCESS: {
      return {
        ...state,
        loading: false,
        eSignature: action.results.downloadUrl,
      };
    }
    case GET_USER_E_SIGNATURE_FAILURE:
      return { ...state, loading: false };
    case GET_SIGNING_TEMPLATE_REQUEST:
      return { ...state, loading: true };
    case GET_SIGNING_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        signTemplate: action.results.content,
      };
    }
    case GET_SIGNING_TEMPLATE_FAILURE:
      return { ...state, loading: false };
    case POST_SIGNED_TEMPLATE_REQUEST:
      return { ...state, loading: true, checkRequiredSignature: false };
    case POST_SIGNED_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        checkRequiredSignature: !action.results.courseIsComplete,
        signCourseComplete: action.results.courseIsComplete,
        coursesSigned: [...state.coursesSigned, action.curriculumId],
        signTemplate: '',
      };
    }
    case POST_SIGNED_TEMPLATE_FAILURE:
      return { ...state, loading: false, checkRequiredSignature: false };
    default:
      return state;
  }
}

export default eLCourses;

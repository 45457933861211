import React, { Component } from 'react';
import { Dimmer } from 'semantic-ui-react';
import styled, { StyledComponent } from 'styled-components';
import { backend } from '../services';
import { GreenButton, RedButton } from '../styles/common';

export interface ITerms {
  id: number;
  feature: string;
  policyUrl: string;
  status: string;
  termsUrl: string;
  presentation: {
    body: string;
    buttonLabel: string;
    confirmationLabel: string;
    policyLabel: string;
    termsLabel: string;
    title: string;
    canDecline: boolean;
    defaultApproved: boolean;
    positiveCheckBox: string;
    negativeCheckBox: string;
    negativePrompt: INegativePrompt;
  };
}

interface INegativePrompt {
  body: string;
  negativeButton: string;
  positiveButton: string;
  title: string;
}

interface IProps {
  terms: ITerms;
  submit: () => void;
}

interface IState {
  acceptCondition: boolean;
  declineCondition: boolean;
  propsSets: boolean;
  loading: boolean;
  openConfirmation: boolean;
}

interface ICheckBoxProps {
  checked?: boolean;
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
const CheckBoxApproved: StyledComponent<'div', any, ICheckBoxProps, never> &
  ICheckBoxProps = styled.div`
  width: 25px;
  height: 25px;
  display: inline-block;
  border-radius: 50%;
  border: ${(props: ICheckBoxProps) => (props.checked ? 'none' : '1px solid #c8cbd3')};
  outline: none;
  background-color: ${(props: ICheckBoxProps) => (props.checked ? '#69DB8F' : '')};
  background-image: ${(props: ICheckBoxProps) =>
    props.checked ? 'url(../images/icon_check_yes.svg)' : ''};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px 25px;
`;

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const CheckBoxDenied: StyledComponent<'div', any, ICheckBoxProps, never> &
  ICheckBoxProps = styled.div`
  width: 25px;
  height: 25px;
  display: inline-block;
  border-radius: 50%;
  border: ${(props: ICheckBoxProps) => (props.checked ? 'none' : '1px solid #c8cbd3')};
  outline: none;
  background-color: ${(props: ICheckBoxProps) => (props.checked ? '#f16778' : '')};
  background-image: ${(props: ICheckBoxProps) =>
    props.checked ? 'url(../images/quiz-review-course-wrong.svg)' : ''};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px 25px;
`;

const CheckContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  justify-items: center;
  margin: 2rem 1rem 1rem;
`;

const AnswerContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  place-content: center;
  align-items: center;
  justify-items: center;
`;

const TermsContainer = styled.div`
  padding: 15px 45px;
`;

const HeaderText = styled.h2`
  font-size: 16px;
  color: #4a4a4a;
`;

const BodyText = styled.p`
  font-size: 11px;
  color: #9b9b9b;
`;

const AgreementText = styled.p`
  margin: 0 0 0 1rem;
  font-size: 11px;
  color: #4a4a4a;
  font-weight: bold;
`;

const ReviewText = styled.p`
  font-size: 11px;
  color: #4a4a4a;
`;

const LinkText = styled.a`
  font-size: 11px;
  color: #04a5d5;
`;

const ButtonAgree = styled(GreenButton)`
  &&&& {
    vertical-align: middle;
    padding: 0 23px !important;
  }
`;

const DimmerBackground = styled.div`
  display: flex !important;
  flex-direction: row;
  place-content: center;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 100%;
`;

const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  justify-items: center;
  background-color: #f3f3f3;
  width: 355px;
  height: 275px;
  z-index: 100;
  padding: 1rem;
  border-radius: 10px;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

class TermsAndConditions extends Component<IProps, IState> {
  public state: IState = {
    acceptCondition: false,
    declineCondition: false,
    openConfirmation: false,
    propsSets: false,
    loading: false,
  };

  public approveInput?: HTMLInputElement | null;

  public declineInput?: HTMLInputElement | null;

  public static getDerivedStateFromProps(nextProps: IProps, prevState: IState): IState | null {
    if (nextProps.terms?.presentation?.defaultApproved && !prevState.propsSets) {
      return {
        ...prevState,
        acceptCondition: nextProps.terms?.presentation.defaultApproved,
        propsSets: true,
      };
    }
    return null;
  }

  private readonly onCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;
    const { acceptCondition, declineCondition } = this.state;
    switch (id) {
      case 'sms-agree-checkbox':
        return this.setState({ acceptCondition: !acceptCondition, declineCondition: false });
      case 'sms-denied-checkbox':
        return this.setState({
          declineCondition: !declineCondition,
          acceptCondition: false,
          openConfirmation: !declineCondition,
        });
      default:
        return null;
    }
  };

  private readonly handleClickApprove = () => {
    this.approveInput?.click();
  };

  private readonly handleClickDenied = () => {
    this.declineInput?.click();
  };

  private readonly changeOption = () => {
    this.setState({ declineCondition: false, acceptCondition: false, openConfirmation: false });
  };

  private readonly continueDecline = () => {
    this.setState({ openConfirmation: false });
  };

  private readonly handleContinue = () => {
    const { submit, terms } = this.props;
    const { acceptCondition } = this.state;
    const { id } = terms;
    this.setState(s => ({ ...s, loading: true }));
    try {
      backend.putTerms(acceptCondition, id).then(() => submit());
    } catch {
      submit();
    }
  };

  public render(): JSX.Element {
    const { acceptCondition, declineCondition, openConfirmation, loading } = this.state;
    const { terms } = this.props;
    const answerChosen = acceptCondition || declineCondition;

    return (
      <TermsContainer>
        {openConfirmation && (
          <Dimmer active page>
            <DimmerBackground>
              <ConfirmationContainer>
                <img
                  style={{ width: '36px', height: '36px' }}
                  src='/Attention.svg'
                  alt='sms-attention'
                />
                <HeaderText>{terms.presentation?.negativePrompt?.title}</HeaderText>
                <BodyText>{terms.presentation?.negativePrompt?.body}</BodyText>
                <ButtonsContainer>
                  <RedButton onClick={this.continueDecline}>
                    {terms.presentation?.negativePrompt?.negativeButton}
                  </RedButton>
                  <GreenButton onClick={this.changeOption}>
                    {terms.presentation?.negativePrompt?.positiveButton}
                  </GreenButton>
                </ButtonsContainer>
              </ConfirmationContainer>
            </DimmerBackground>
          </Dimmer>
        )}
        <img style={{ width: '36px', height: '36px' }} src='/Attention.svg' alt='sms-attention' />
        <HeaderText>{terms.presentation?.title}</HeaderText>
        <img
          style={{ width: '125px', height: '135px' }}
          src='/sms-holding-phone.svg'
          alt='sms-holding-phone'
        />
        <CheckContainer>
          <AgreementText>{terms.presentation?.confirmationLabel}</AgreementText>
          <AnswerContainer>
            <CheckBoxApproved onClick={this.handleClickApprove} checked={acceptCondition}>
              <input
                /* eslint-disable-next-line no-return-assign */
                ref={r => (this.approveInput = r)}
                id='sms-agree-checkbox'
                onChange={this.onCheckBoxChange}
                type='checkbox'
                checked={acceptCondition}
                style={{ display: 'none' }}
              />
            </CheckBoxApproved>
            <AgreementText>{terms.presentation?.positiveCheckBox}</AgreementText>
            <div style={{ width: '100px' }} />
            <CheckBoxDenied onClick={this.handleClickDenied} checked={declineCondition}>
              <input
                /* eslint-disable-next-line no-return-assign */
                ref={r => (this.declineInput = r)}
                id='sms-denied-checkbox'
                onChange={this.onCheckBoxChange}
                type='checkbox'
                checked={declineCondition}
                style={{ display: 'none' }}
              />
            </CheckBoxDenied>
            <AgreementText>{terms.presentation?.negativeCheckBox}</AgreementText>
          </AnswerContainer>
        </CheckContainer>
        <BodyText>{terms.presentation?.body}</BodyText>
        <ReviewText>
          <LinkText href={terms.termsUrl} target='_blank' rel='nooper noreferrer'>
            {terms.presentation?.termsLabel}
          </LinkText>
        </ReviewText>
        <ButtonAgree
          loading={loading}
          disabled={loading || !answerChosen}
          onClick={this.handleContinue}
        >
          {terms.presentation?.buttonLabel}
        </ButtonAgree>
      </TermsContainer>
    );
  }
}

export default TermsAndConditions;

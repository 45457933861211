/* eslint-disable import/prefer-default-export */
import * as types from '../constants/ActionTypes';

const setInputGroupState = (groupName, state) => dispatch => {
  const action = {
    type: types.SET_UI_GROUP_STATE,
    groupName,
    state,
  };

  dispatch(action);
};

const inputChange = (groupName, input) => dispatch => {
  const inputChangedAction = {
    type: types.INPUT_CHANGED,
    groupName,
    input,
  };

  dispatch(inputChangedAction);
};

const inputChangeHandler = (dispatch, groupName) => (e, input) => {
  dispatch(inputChange(groupName, input));
};

const mapStateOfGroupToProps = (groupName, anotherMapper) => state => {
  let anotherMappings = {};
  if (anotherMapper) {
    anotherMappings = anotherMapper(state);
  }

  return {
    uiState: {
      ...state.uiState.inputGroups[groupName],
      primaryContacts: state.employee.employeeList.filter(e => e.isCoordinator),
      primaryManagers: state.employee.employeeList.filter(e => e.isManager),
    },
    ...anotherMappings,
  };
};

const uiStateChange = (groupName, stateName, value) => dispatch => {
  const inputChangedAction = {
    type: types.UI_STATE_CHANGED,
    groupName,
    stateName,
    value,
  };

  dispatch(inputChangedAction);
};

const resetInputGroupState = groupName => dispatch => {
  const action = {
    type: types.RESET_UI_GROUP_STATE,
    groupName,
  };

  dispatch(action);
};

export const uiStateActions = {
  inputChangeHandler,
  mapStateOfGroupToProps,
  uiStateChange,
  setInputGroupState,
  resetInputGroupState,
};

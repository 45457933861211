const PreferenceCategories = {
  environment: {
    label: 'Home Environment',
    availableToCreate: true,
    caregiverTitle: 'Home Environment',
    clientTitle: 'Home Environment',
  },
  skills: {
    label: 'Skills',
    availableToCreate: true,
    caregiverTitle: 'Skills',
    clientTitle: 'Desired Caregiver Skills',
  },
  experience: {
    label: 'Experience',
    availableToCreate: true,
    caregiverTitle: 'Experience',
    clientTitle: 'Desired Caregiver Experience',
  },
  other: {
    label: 'Other',
    caregiverTitle: 'Other',
    availableToCreate: true,
    clientTitle: 'Other',
  },
  availability: {
    label: 'Availability',
    caregiverTitle: 'Availability',
    availableToCreate: false,
  },
};

const ClientStatusMap = {
  '01': { name: 'Pending' },
  '02': { name: 'Active' },
  '03': { name: 'On Hold' },
  '04': { name: 'Inactive' },
  '05': { name: 'Cancelled' },
};

const StaffStatusMap = {
  '01': { name: 'Pending' },
  '02': { name: 'Active' },
  '03': { name: 'On Hold' },
  '04': { name: 'Inactive' },
  '05': { name: 'Cancelled' },
};

const clientStatusOptions = Object.keys(ClientStatusMap).map(x => ({
  key: x,
  value: x,
  text: ClientStatusMap[x].name,
}));

const staffStatusOptions = Object.keys(StaffStatusMap).map(x => ({
  key: x,
  value: x,
  text: StaffStatusMap[x].name,
}));

const ActiveStaffStatus = '02';
const PendingStaffStatus = '01';
const OnholdStaffStatus = '03';

const ApplicationModes = {
  scheduler: 'scheduler',
  hr: 'hr',
  eLearningMgmt: 'eLearningMgmt',
  training: 'training',
  embedReports: 'embedReports',
};

const activeModeTab = {
  ccDashBoard: 'ccDashBoard',
  shifts: 'shifts',
  clients: 'clients',
  caregivers: 'caregivers',
  changeLog: 'changeLogs',
  embedComplianceReports: 'embedComplianceReports',
  hrDashBoard: 'hrDashBoard',
  hrApplicants: 'hrApplicants',
  hrCandidates: 'hrCandidates',
  hrCaregivers: 'hrCaregivers',
  hrCalendar: 'hrCalendar',
  ELDashboard: 'ELDashboard',
  ELCourses: 'ELCourses',
  ELCompleteCourses: 'ELCompleteCourses',
  ELUserDirectory: 'ELUserDirectory',
  ELReports: 'ELReports',
  ELCertificates: 'ELCertificates',
  profile: 'profile',
  settings: 'settings',
};

const getClientStatusName = s => (ClientStatusMap[s] ? ClientStatusMap[s].name : null);

const getClientStatusData = client => {
  let text = '';
  let color = '#e3e3e3';
  let date;
  switch (client.status) {
    case '01':
      text = 'Referral or Pending';
      color = '#C8CBD3';
      date = client.referralDate;
      break;
    case '02':
      text = 'Active';
      color = '#69DB8F';
      date = client.startDate;
      break;
    case '03':
      text = 'Hold or Not Taken Under Care';
      color = '#F5A623';
      date = client.holdDate;
      break;
    case '04':
      text = 'Discharged';
      color = '#04A5D5';
      date = client.dischargeDate;
      break;
    case '05':
      text = 'Cancelled';
      color = '#04A5D5';
      date = client.cancelDate;
      break;
    default:
      text = 'Unknown';
  }

  return { text, color, date };
};

const colorToCssClassLightBg = color => `light-${color}-bg`;

const getShiftAssigmentStatusColor = status => {
  const statusColorMap = {
    unassigned: { iconClass: 'icon-blue', bgClass: 'bg-blue' },
    assigned: { iconClass: 'icon-green', bgClass: 'bg-green' },
    onhold: { iconClass: 'icon-orange', bgClass: 'bg-orange' },
  };

  return statusColorMap[status] || { iconClass: 'icon-red', bgClass: 'bg-red' };
};

const getCaregiverStatusColor = status => {
  const statusColorMap = {
    unassigned: { iconClass: 'icon-blue', bgClass: 'bg-blue' },
    assigned: { iconClass: 'icon-green', bgClass: 'bg-green' },
    onhold: { iconClass: 'icon-red', bgClass: 'bg-red' },
  };

  return statusColorMap[status] || { iconClass: 'icon-red', bgClass: 'bg-red' };
};

const avatarUrl = (avatar, gender) => {
  const defaultByGender = () => {
    switch (gender) {
      case 'm':
        return '/Male.svg';
      case 'f':
        return '/Female.svg';
      case 'u':
        return '/Other.svg';
      default:
        return '/Missing.svg';
    }
  };

  const url = avatar ? avatar.url.replace('.jpg', '-200.jpg') : defaultByGender();

  return url;
};

const avatarStyle = (avatar, gender, size, editable) => {
  const defaultByGender = () => {
    switch (gender) {
      case 'm':
        return '#04A5D5';
      case 'f':
        return '#F16778';
      case 'u':
        return '#69DB8F';
      default:
        return '#D9D9D9';
    }
  };

  const margin = editable ? '7px' : null;

  const borderSize = size && size === 'large' ? '5px' : '2px';

  const borderColor = avatar ? defaultByGender() : '#FFFFFF';

  return { border: `${borderSize} solid ${borderColor}`, marginTop: `${margin}` };
};

const getStatusDotColor = (status, editable) => {
  const statusColor = () => {
    switch (status) {
      case '01':
        return '#C8CBD3';
      case '02':
        return '#69DB8F';
      case '03':
        return '#F5A623';
      case '04':
        return '#04A5D5';
      case '05':
        return '#C8CBD3';
      default:
        return '#C8CBD3';
    }
  };
  const visible = editable ? 'inline' : 'none';

  return { color: statusColor(), display: `${visible}` };
};

const PhoneTypes = v => {
  let title = null;
  switch (v.type) {
    case 1:
      title = 'Home Phone';
      break;
    case 2:
      title = 'Work Phone';
      break;
    case 3:
      title = 'Cell Phone';
      break;
    case 4:
      title = 'Fax Number';
      break;
    default:
      title = 'Unknown';
      break;
  }
  return title;
};

const Roles = [
  { value: 'caregiver', text: 'Caregiver' },
  { value: 'scheduler', text: 'Scheduler' },
  { value: 'admin', text: 'Admin' },
  { value: 'suspended', text: 'Suspended' },
  { value: 'grace', text: 'Grace' },
  { value: 'invited', text: 'Invited' },
  { value: 'hrmanager', text: 'HR Manager' },
  { value: 'elearn', text: 'Trainee' },
];

const EmployeeAvailableRoles = [
  { value: 'scheduler', text: 'Employee' },
  { value: 'admin', text: 'Administrator' },
  { value: 'hrmanager', text: 'HR Manager' },
  { value: 'elearn', text: 'Trainee' },
];

const isAdmin = roles => roles && roles.indexOf('admin') >= 0;
const isHRManager = roles => (roles && roles.indexOf('hrmanager') >= 0) || isAdmin(roles);
const isScheduler = roles => (roles && roles.indexOf('scheduler') >= 0) || isAdmin(roles);
const isTrainee = roles => roles && roles.indexOf('elearn') >= 0;
const isCaregiver = roles => roles && roles.indexOf('caregiver') >= 0;

module.exports = {
  PreferenceCategories,
  ClientStatusMap,
  getClientStatusName,
  getClientStatusData,
  colorToCssClassLightBg,
  getShiftAssigmentStatusColor,
  getCaregiverStatusColor,
  avatarUrl,
  avatarStyle,
  getStatusDotColor,
  PhoneTypes,
  Roles,
  EmployeeAvailableRoles,
  isAdmin,
  isHRManager,
  isScheduler,
  isTrainee,
  isCaregiver,
  StaffStatusMap,
  ActiveStaffStatus,
  PendingStaffStatus,
  OnholdStaffStatus,
  clientStatusOptions,
  staffStatusOptions,
  ApplicationModes,
  activeModeTab,
};

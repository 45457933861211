/* eslint-disable react/require-default-props */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Header, Grid, Icon, Divider, TextArea, Button } from 'semantic-ui-react';
import { Form, Select } from 'formsy-semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import { RecordsGrid, RecordsHeader, RecordsTile, RecordsForm } from '../../../styles/records';
import { MOMENT_DOB } from '../../../constants/Formats';
import { getStatusDotColor, staffStatusOptions } from '../../../constants/DomainTypes';
import { caregiverActions } from '../../../actions';

const ALLOWED_STAFF_STATUS_OPTIONS = {
  '01': staffStatusOptions.filter(x => x.value === '01' || x.value === '05'),
  '02': staffStatusOptions.filter(x => x.value === '02' || x.value === '03' || x.value === '04'),
  '03': staffStatusOptions.filter(x => x.value === '03' || x.value === '02' || x.value === '04'),
  '04': staffStatusOptions.filter(x => x.value === '04' || x.value === '01'),
  '05': staffStatusOptions.filter(x => x.value === '05' || x.value === '01'),
};

class TileProfileStatus extends React.Component {
  state = {
    initialHireHistory: [],
  };

  componentDidMount() {
    this.setInitialHireHistory();
  }

  setInitialHireHistory = () => {
    const { person } = this.props;
    this.setState({ initialHireHistory: person.hireHistory ? [...person.hireHistory] : [] });
  };

  hireHistoryItem = () => {
    const { person, intl, onValueChange, onAgencyChange } = this.props;
    const { initialHireHistory } = this.state;
    const dateFormat = 'MM/DD/YYYY';
    const separationOptions = [
      { text: 'Terminated', value: 'terminated' },
      { text: 'Resigned', value: 'resigned' },
    ];
    const hireDateHeader = intl.formatMessage({ id: 'records.profile.hireDate' });
    const separationDateHeader = intl.formatMessage({ id: 'records.profile.separationDate' });
    const separationReasonHeader = intl.formatMessage({ id: 'records.profile.separationReason' });
    const additionalNotesPlaceholder = intl.formatMessage({
      id: 'records.profile.additionalNotesPlaceholder',
    });
    const reasonPlaceholder = intl.formatMessage({ id: 'dropdown.choose' });
    const historyHire = person.hireHistory.sort(
      (a, b) => moment(b.separationDate).toDate() - moment(a.separationDate).toDate()
    );

    return historyHire.map((h, i) => {
      const setSeparationDate = (e, d) => {
        onAgencyChange(e, { ...d, name: `hireHistory.${i}.separationDate` });
      };
      return (
        <RecordsForm key={h.separationDate} size='tiny'>
          <Divider />
          <Grid>
            <Grid.Row style={{ paddingBottom: '0px' }}>
              <Grid.Column width={5}>
                <Header size='tiny'>{hireDateHeader}</Header>
              </Grid.Column>
              <Grid.Column width={5}>
                <Header size='tiny'>{separationDateHeader}</Header>
              </Grid.Column>
              <Grid.Column width={6}>
                <Header size='tiny'>{separationReasonHeader}</Header>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <p>{h.hireDate ? moment(h.hireDate).format(dateFormat) : ''}</p>
              </Grid.Column>
              <Grid.Column width={5}>
                {person.hireHistory.length > initialHireHistory.length &&
                i === person.hireHistory.length - 1 ? (
                  <DatePicker
                    customInput={
                      <Form.Input
                        id='tile-profile-status-hire-date-input'
                        name={`hireHistory.${i}.separationDate`}
                        placeholder='Please Select'
                        required
                      />
                    }
                    placeholderText='Please Select'
                    float
                    name={`hireHistory.${i}.separationDate`}
                    id='separationDate'
                    selected={h.separationDate ? moment(h.separationDate, MOMENT_DOB) : ''}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    onChange={setSeparationDate}
                    dateFormat='MM/DD/YYYY'
                    maxDate={moment()}
                    className='hireDateInput'
                    required
                  />
                ) : (
                  <p>{h.separationDate ? moment(h.separationDate).format(dateFormat) : ''}</p>
                )}
              </Grid.Column>
              <Grid.Column width={6}>
                <Select
                  id='tile-profile-status-select'
                  style={{ marginTop: '-12px', width: '100%', minWidth: '0px' }}
                  name={`hireHistory.${i}.separationReason`}
                  options={separationOptions}
                  value={h.separationReason}
                  onChange={onValueChange}
                  placeholder={reasonPlaceholder}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: '0px' }}>
              <Grid.Column width={16}>
                <TextArea
                  id='tile-profile-status-text-area'
                  placeholder={additionalNotesPlaceholder}
                  value={h.separationNotes ? h.separationNotes : ''}
                  onChange={onValueChange}
                  name={`hireHistory.${i}.separationNotes`}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </RecordsForm>
      );
    });
  };

  completeCaregiver(caregiverId) {
    this.props.dispatch(
      caregiverActions.completeCaregiverPrehire([caregiverId], { caregiverProfile: true })
    );
  }

  render() {
    const {
      person,
      intl,
      onAgencyChange,
      type,
      onValid,
      onInvalid,
      editability,
      original,
      secondaryStatusCodes,
      showActivation,
    } = this.props;
    const { initialHireHistory } = this.state;
    const isCaregiver = type === 'caregiver';
    const secondaryStatusCodeOptions = (secondaryStatusCodes || []).filter(
      x => x.staffStatus === person.staffStatus
    );

    if (!person.secondaryStatusCode) {
      person.secondaryStatusCode = secondaryStatusCodeOptions.length
        ? secondaryStatusCodeOptions[0].value
        : null;
    }

    const lastStatusStyle = {
      paddingLeft: '15px',
      position: 'relative',
      top: '1px',
    };
    const staffStatusDot = (
      <Icon
        name='circle'
        size='small'
        style={{
          ...getStatusDotColor(person.staffStatus, true),
          zIndex: 11,
          position: 'absolute',
          top: !editability.bySource || editability.staffStatus ? '15px' : '13px',
          left: '4px',
        }}
      />
    );

    const centeredStyle = {
      zIndex: '100',
      position: 'absolute',
      top: '50%',
      transform: 'translate(0, -50%)',
    };

    return isCaregiver ? (
      <RecordsTile>
        <RecordsHeader>{intl.formatMessage({ id: 'records.profile' }).toUpperCase()}</RecordsHeader>
        <RecordsForm
          key='careRecordsForm'
          onValid={() => onValid('details')}
          onInvalid={() => onInvalid('details')}
          size='tiny'
          ref={c => {
            this.form = c;
          }}
          className='detailsBox'
        >
          <RecordsGrid style={{ backgroundColor: '#f3f3f3', margin: '0px', paddingBottom: '4px' }}>
            <Grid.Row style={{ paddingBottom: '0px' }}>
              <Grid.Column width={6}>
                <Header size='tiny'>{intl.formatMessage({ id: 'records.status' })}</Header>
              </Grid.Column>
              <Grid.Column width={1} />
              <Grid.Column width={9}>
                <Header size='tiny'>{intl.formatMessage({ id: 'records.secondaryStatus' })}</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ height: '50px', padding: '0px' }}>
              <Grid.Column width={6}>
                <div style={centeredStyle}>
                  <Form.Field required={editability.bySource}>
                    {staffStatusDot}
                    <Select
                      id='form-staff-details-last-status'
                      name='staffStatus'
                      style={lastStatusStyle}
                      options={ALLOWED_STAFF_STATUS_OPTIONS[original.staffStatus]}
                      placeholder='Choose'
                      onChange={(event, props) => {
                        const { value } = props;
                        if (value === '04') {
                          if (!person.hireHistory) person.hireHistory = [];
                          person.hireHistory.push({
                            created: true,
                            hireDate: person.hireDate,
                            separationDate: moment(),
                          });
                          person.status = 'inactive';
                        } else {
                          if (person.hireHistory.length > initialHireHistory.length) {
                            person.hireHistory.pop();
                          }
                          person.status = original.status;
                          person.hireHistory = original.hireHistory;
                        }
                        person.secondaryStatusCode = null;
                        this.props.onValueChange(event, props);
                      }}
                      value={person.staffStatus}
                      disabled={!editability.staffstatus}
                    />
                  </Form.Field>
                </div>
              </Grid.Column>
              <Grid.Column width={1} />
              <Grid.Column width={9}>
                <div style={centeredStyle}>
                  <Form.Field required={editability.bySource}>
                    <Select
                      id='form-staff-details-secondary-status'
                      name='secondaryStatusCode'
                      style={lastStatusStyle}
                      options={secondaryStatusCodeOptions}
                      placeholder='Choose'
                      onChange={(e, p) => this.props.onValueChange(e, p, true)}
                      value={person.secondaryStatusCode}
                      disabled={!editability.staffstatus}
                    />
                  </Form.Field>
                </div>
              </Grid.Column>
            </Grid.Row>
            {showActivation && (
              <div style={{ padding: '1rem' }}>
                <Button
                  id='caregiver-activate-button'
                  compact
                  className='care-green'
                  size='tiny'
                  onClick={() => this.completeCaregiver(person.id)}
                >
                  Activate
                </Button>
              </div>
            )}
            {person.staffStatus !== '05' && person.staffStatus !== '01' && (
              <Grid.Row>
                <Grid.Column width={6}>
                  <Header size='tiny'>{intl.formatMessage({ id: 'records.hireDate' })}</Header>
                </Grid.Column>
                <Grid.Column width={1} />
                {person.staffStatus === '02' && (
                  <Grid.Column width={6}>
                    <Header size='tiny'>
                      {intl.formatMessage({ id: 'records.activationDate' })}
                    </Header>
                  </Grid.Column>
                )}
              </Grid.Row>
            )}
            {person.staffStatus !== '05' && person.staffStatus !== '01' && (
              <Grid.Row style={{ height: '50px', padding: '0px' }}>
                <Grid.Column width={6}>
                  <DatePicker
                    customInput={
                      <Form.Input
                        id='tile-profile-status-hire-date-input'
                        name='hireDateInput'
                        placeholder='MM/DD/YYYY'
                        required={editability.hiredate}
                        disabled={!editability.hiredate}
                      />
                    }
                    placeholderText='MM/DD/YYYY'
                    float
                    name='hireDate'
                    id='hireDate'
                    selected={person && person.hireDate && moment(person.hireDate, MOMENT_DOB)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    onChange={(e, i) => onAgencyChange(e, { ...i, name: 'hireDate' })}
                    dateFormat='MM/DD/YYYY'
                    maxDate={moment()}
                    disabled={!editability.hiredate}
                    className={
                      person.staffStatus === '02' ? 'hireDateInput' : 'hireDateInputDisable'
                    }
                  />
                </Grid.Column>
                <Grid.Column width={1} />
                {person.staffStatus === '02' && (
                  <Grid.Column width={6}>
                    <DatePicker
                      customInput={
                        <Form.Input
                          id='tile-profile-status-activation-date-input'
                          name='activationDateInput'
                          placeholder='MM/DD/YYYY'
                          required={editability.bySource}
                          disabled={!editability.bySource}
                        />
                      }
                      placeholderText='MM/DD/YYYY'
                      float
                      name='activationDate'
                      id='activationDate'
                      popperPlacement='top-end'
                      selected={
                        person && person.activationDate && moment(person.activationDate, MOMENT_DOB)
                      }
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      onChange={(e, i) => onAgencyChange(e, { ...i, name: 'activationDate' })}
                      dateFormat='MM/DD/YYYY'
                      maxDate={moment()}
                      disabled
                      className='activationDateInput'
                    />
                  </Grid.Column>
                )}
              </Grid.Row>
            )}
          </RecordsGrid>
        </RecordsForm>
        {person.hireHistory && (
          <>
            <RecordsHeader>
              {intl.formatMessage({ id: 'records.profile.hiringHistory' })}
            </RecordsHeader>
            {this.hireHistoryItem()}
          </>
        )}
      </RecordsTile>
    ) : (
      ''
    );
  }
}

TileProfileStatus.defaultProps = {
  person: {},
  onValid: () => {},
  onInvalid: () => {},
  type: 'caregiver',
};

TileProfileStatus.propTypes = {
  person: PropTypes.shape().isRequired,
  original: PropTypes.shape().isRequired,
  editability: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  intl: intlShape.isRequired,
  onAgencyChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  onValid: PropTypes.func.isRequired,
  onInvalid: PropTypes.func.isRequired,
  secondaryStatusCodes: PropTypes.shape().isRequired,
  showActivation: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default injectIntl(TileProfileStatus);

/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import { Modal, Dimmer, Header, Button, Grid, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Detector } from 'react-detect-offline';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import { MOMENT_DOB } from '../constants/Formats';
import { employeeActions, alertActions } from '../actions';
import CareEditorTabs from './subcomponents/CareEditorTabs';
import CCEditorInfo from './CCEditorInfo';
import CareLoader from './subcomponents/CareLoader';

import './Common.css';
import './Clients.css';
import { config } from '../helpers';

class EmployeeEditor extends React.Component {
  constructor() {
    super();
    this.state = { activeTab: null, isValid: false, isChanged: false };

    this.closeDetailsClicked = this.closeDetailsClicked.bind(this);
    this.tabClick = this.tabClick.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.disableSubmitButton = this.disableSubmitButton.bind(this);
    this.enableSubmitButton = this.enableSubmitButton.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.dispatch(employeeActions.createEmployeeClear());
    this.setState(() => ({
      activeTab: this.props.tab ? this.props.tab : 0,
      wasClientChanged: false,
      employee: {
        address: { forceGeocoding: true },
        meta: {},
      },
      invitedEmployee: true,
    }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const employeeId = (((nextProps.employeeEditor || {}).employee || {}).profile || {}).id;

    if (nextProps.uiState) {
      this.setState({ uiState: nextProps.uiState });
    }

    if (nextProps.employeeEditor && nextProps.employeeEditor.done === true && employeeId) {
      this.props.dispatch(employeeActions.getEmployeeList());
      this.props.onCloseClicked();
      this.props.dispatch(alertActions.notification('Invitation has been sent'));
    }
  }

  onValueChange(e, input) {
    if (!e) {
      return;
    }

    this.setState(s => {
      const setValue = (path, obj, value) => {
        const key = path.shift();
        if (path.length > 0) {
          setValue(path, obj[key], value);
        } else {
          // eslint-disable-next-line no-param-reassign
          obj[key] = value?.length || Number.isInteger(value) ? value : null;
        }
      };

      const newState = { ...s };
      // eslint-disable-next-line no-underscore-dangle
      if (e._isAMomentObject) {
        setValue(['dob'], newState.employee, e.format(MOMENT_DOB));
      } else if (input.type === 'checkbox') {
        setValue(input.name.split('.'), newState.employee, input.checked ? 'Y' : 'N');
      } else {
        setValue(input.name.split('.'), newState.employee, input.value);
      }

      const isChanged = JSON.stringify(newState.employee) !== JSON.stringify(newState.original);
      // const isValid = this.validateInputs();
      return Object.assign(newState, { isChanged });
    });
  }

  tabClick(e, input) {
    this.setState({
      activeTab: input.name,
    });
  }

  closeDetailsClicked() {
    this.props.onCloseClicked({ wasClientChanged: this.state.wasClientChanged });
  }

  disableSubmitButton() {
    this.setState({ isValid: false });
  }

  enableSubmitButton() {
    this.setState({ isValid: true });
  }

  render() {
    const { loading } = this.props.employeeEditor;
    const { dispatch, languages } = this.props;
    const { activeTab, employee, isChanged, isValid } = this.state;

    if (loading) {
      return (
        <Dimmer active page>
          <CareLoader loading={loading} invert showText={false} />
        </Dimmer>
      );
    }

    const applyChangesClick = () => {
      const newEmployee = {
        ...this.state.employee,
      };

      if (newEmployee.phones) {
        newEmployee.phones = [{ primary: true, type: 3, number: newEmployee.phones }];
      }

      newEmployee.isManager = newEmployee.title === 'manager';
      newEmployee.isCoordinator = newEmployee.title === 'scheduler';
      newEmployee.title = undefined;

      dispatch(employeeActions.createEmployee(newEmployee));
    };

    const offlineClick = () => {
      const error = new Error('Error no network connection');
      dispatch(alertActions.error(error, alertActions.REASON_NETWORK_CONNECTIVITY));
    };

    return (
      <Modal
        closeOnDimmerClick={false}
        open
        closeIcon={<Image src='/icon_close.svg' />}
        dimmer
        size='tiny'
        onClose={this.closeDetailsClicked}
        className='clientEditor'
        style={{ marginTop: '-46vh' }}
      >
        <Modal.Header>
          <Grid centered className='headerGrid'>
            <Grid.Column width={16} textAlign='center'>
              <Header size='tiny' style={{ color: '#364866' }}>
                ADD NEW EMPLOYEE
              </Header>
            </Grid.Column>
            <Grid.Column width={16} textAlign='center' className='careEditorTabContainer'>
              <CareEditorTabs labels={['Employee Info']} activeTab={this.state.activeTab} />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content scrolling>
          {activeTab === 0 && (
            <CCEditorInfo
              dispatch={dispatch}
              type={CCEditorInfo.EMPLOYEE}
              values={employee}
              onValueChange={this.onValueChange}
              disableSubmitButton={this.disableSubmitButton}
              enableSubmitButton={this.enableSubmitButton}
              languages={languages}
              disciplines={[]}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Grid centered className='buttonArea'>
            <Grid.Column width={10} textAlign='center'>
              <Detector
                render={({ online }) => (
                  <Button
                    id='employee-editor-create-button'
                    disabled={!isChanged || !isValid || loading}
                    onClick={online ? applyChangesClick : offlineClick}
                  >
                    Create Employee Record
                  </Button>
                )}
                polling={{
                  url: config.OFFLINE_DETECTOR_POLLING_URL,
                  interval: 60000,
                  timeout: 15000,
                }}
              />
            </Grid.Column>
            {activeTab === 0 && (
              <label className='editorBottomLabel' htmlFor='createButton'>
                This will invite your employee to your agency through the CareConnect Web portal and
                manage their profile in CareConnect Web portal.
              </label>
            )}
          </Grid>
        </Modal.Actions>
      </Modal>
    );
  }
}

EmployeeEditor.defaultProps = {
  tab: 0,
  employee: null,
};

EmployeeEditor.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uiState: PropTypes.shape().isRequired,
  employeeEditor: PropTypes.shape().isRequired,
  onCloseClicked: PropTypes.func.isRequired,
  tab: PropTypes.number,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => {
  const { languages } = state.principal;
  const { employeeEditor } = state;

  return {
    employeeEditor,
    languages,
  };
};

export default connect(mapStateToProps)(EmployeeEditor);

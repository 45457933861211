/* eslint-disable import/prefer-default-export */
import * as types from '../constants/ActionTypes';
import { backend } from '../services';
// eslint-disable-next-line import/no-cycle
import { alertActions } from './alert';

function getComplianceBlocks() {
  return async dispatch => {
    dispatch(request());

    try {
      const blocks = await backend.getComplianceBlocks();
      dispatch(success(blocks));
    } catch (error) {
      dispatch(failure(error));
    }
  };

  function request() {
    return { type: types.GET_COMPLIANCE_BLOCKS_REQUEST };
  }
  function success(blocks) {
    return { type: types.GET_COMPLIANCE_BLOCKS_SUCCESS, blocks };
  }
  function failure(error) {
    return { type: types.GET_COMPLIANCE_BLOCKS_FAILURE, error };
  }
}

function updateComplianceBlocks(blocksToUpdate, forceUpdate = false) {
  return async dispatch => {
    dispatch(request());

    try {
      const result = await backend.updateComplianceBlocks(blocksToUpdate, forceUpdate);
      dispatch(success(result));
      dispatch(alertActions.notification('System settings saved'));
    } catch (error) {
      dispatch(failure(error));
      if (
        !error.json ||
        (error.json.type !== 4018 &&
          error.json.type !== 4014 &&
          error.json.type !== 'ComplianceBlockHasPastEventsError' &&
          error.json.type !== 'ComplianceBlockInUseError')
      ) {
        dispatch(alertActions.error(error));
      }
    }
  };

  function request() {
    return { type: types.UPDATE_COMPLIANCE_BLOCKS_REQUEST };
  }
  function success(blocks) {
    return { type: types.UPDATE_COMPLIANCE_BLOCKS_SUCCESS, blocks };
  }
  function failure(error) {
    return { type: types.UPDATE_COMPLIANCE_BLOCKS_FAILURE, error };
  }
}

function getComplianceTrainingCourses() {
  return dispatch => {
    dispatch(request());
    backend.getComplianceTrainingCourses().then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
      }
    );
  };

  function request() {
    return { type: types.GET_COMPLIANCE_TRAINING_COURSES_REQUEST };
  }
  function success(results) {
    return { type: types.GET_COMPLIANCE_TRAINING_COURSES_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_COMPLIANCE_TRAINING_COURSES_FAILURE, error };
  }
}

function createComplianceClassroom(classRoom) {
  return dispatch => {
    dispatch(request());
    backend.createComplianceClassroom(classRoom).then(
      results => {
        dispatch(success(results));
        dispatch(getComplianceClassrooms());
      },
      error => {
        dispatch(failure(error.message));
      }
    );
  };

  function request() {
    return { type: types.CREATE_COMPLIANCE_CLASS_ROOMS_REQUEST };
  }
  function success(results) {
    return { type: types.CREATE_COMPLIANCE_CLASS_ROOMS_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.CREATE_COMPLIANCE_CLASS_ROOMS_FAILURE, error };
  }
}

function updateComplianceClassrooms(classRooms) {
  return dispatch => {
    dispatch(request());
    backend.updateComplianceClassrooms(classRooms).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
      }
    );
  };

  function request() {
    return { type: types.UPDATE_COMPLIANCE_CLASS_ROOMS_REQUEST };
  }
  function success(results) {
    return { type: types.UPDATE_COMPLIANCE_CLASS_ROOMS_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.UPDATE_COMPLIANCE_CLASS_ROOMS_FAILURE, error };
  }
}

function getComplianceClassrooms() {
  return dispatch => {
    dispatch(request());
    backend.getComplianceClassrooms().then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
      }
    );
  };

  function request() {
    return { type: types.GET_COMPLIANCE_CLASS_ROOMS_REQUEST };
  }
  function success(results) {
    return { type: types.GET_COMPLIANCE_CLASS_ROOMS_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_COMPLIANCE_CLASS_ROOMS_FAILURE, error };
  }
}

function createComplianceInstructor(instructor) {
  return dispatch => {
    dispatch(request());
    backend.createComplianceInstructor(instructor).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
      }
    );
  };

  function request() {
    return { type: types.CREATE_COMPLIANCE_INSTRUCTORS_REQUEST };
  }
  function success(results) {
    return { type: types.CREATE_COMPLIANCE_INSTRUCTORS_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.CREATE_COMPLIANCE_INSTRUCTORS_FAILURE, error };
  }
}

function getComplianceInstructors() {
  return dispatch => {
    dispatch(request());
    backend.getComplianceInstructors().then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
      }
    );
  };

  function request() {
    return { type: types.GET_COMPLIANCE_INSTRUCTORS_REQUEST };
  }
  function success(results) {
    return { type: types.GET_COMPLIANCE_INSTRUCTORS_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_COMPLIANCE_INSTRUCTORS_FAILURE, error };
  }
}

function hideComplianceRemoveAgreement() {
  return { type: types.COMPLIANCE_SETTINGS_HIDE_REMOVE_AGREEMENT };
}

export const complianceSettingsActions = {
  updateComplianceBlocks,
  getComplianceBlocks,
  getComplianceTrainingCourses,
  hideComplianceRemoveAgreement,
  getComplianceClassrooms,
  createComplianceClassroom,
  createComplianceInstructor,
  getComplianceInstructors,
  updateComplianceClassrooms,
};

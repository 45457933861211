/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Image, Checkbox, Modal, Dimmer, Icon, Popup } from 'semantic-ui-react';
import CareRoundButton from '../CareRoundButton';
import { ELManageActions } from '../../../actions/ELApp/ELManageActions';
import { GreenButton, SimpleButton } from '../../../styles/common';
import CareLoader from '../CareLoader';
import '../../CGeLearning.css';
import { backend } from '../../../services';
import { ELUserActions } from '../../../actions/ELApp/ELUserActions';

const ProgramsList = ({
  toggleProgramListModal,
  agencyPrograms,
  programWithCurriculums,
  onProgramSelectionChange,
  programCheck,
  handleAddUserToProgram,
}) => {
  const availablePrograms =
    agencyPrograms?.length &&
    agencyPrograms.filter(
      aP =>
        !programWithCurriculums.find(p => p.program.id === aP.id) &&
        (!aP.terminationDate || moment(aP.terminationDate).isAfter(moment()))
    );

  const paginazerHeight = availablePrograms.length * 30;

  return (
    <Modal
      dimmed
      open
      closeOnEscape={false}
      closeOnDimmerClick={false}
      closeIcon={<Image src='/icon_close.svg' />}
      className='e-learning-add-program-list'
      onClose={() => toggleProgramListModal()}
      style={{ marginTop: '-35vh' }}
    >
      <div className='e-learning-add-to-program-container'>
        <div className='e-learning-add-program-header'>
          <h3 className='e-learning-programs-header'>PROGRAMS</h3>
        </div>
        {availablePrograms.length ? (
          <div className='e-learning-add-program-list-container'>
            <div className='e-learning-add-program-list-head'>
              <span className='e-learning-add-program-list-header'>Program Name</span>
              <span className='e-learning-add-program-list-header'>Effective Date</span>
              <span className='e-learning-add-program-list-header'>Termination Date</span>
              <span className='e-learning-add-program-list-header'>Add Program</span>
            </div>
            <div style={{ height: `${paginazerHeight}px`, maxHeight: '300px', overflow: 'auto' }}>
              {availablePrograms.map(p => (
                <div className='e-learning-add-program-list-body'>
                  <span className='e-learning-add-program-body'>{p.programName}</span>
                  <span className='e-learning-add-program-body'>
                    {moment(p.effectiveDate).format('MMM Do YYYY')}
                  </span>
                  <span className='e-learning-add-program-body'>
                    {moment(p.terminationDate).format('MMM Do YYYY')}
                  </span>
                  <span className='e-learning-add-program-body'>
                    <Checkbox
                      className='e-learning-add-program-list-check-box'
                      name='eLearningProgramsListCheckBox'
                      checked={programCheck === p.id}
                      onChange={() => onProgramSelectionChange(p.id)}
                      booleanMode
                    />
                  </span>
                </div>
              ))}
            </div>
            <div className='e-learning-add-program-list-button-container'>
              <GreenButton
                disabled={!programCheck}
                onClick={() => handleAddUserToProgram('program', programCheck)}
                className='e-learning-add-program-list-add-program-button'
              >
                Add Program
              </GreenButton>
            </div>
          </div>
        ) : (
          <div className='e-learning-add-program-list-empty-container'>
            <p className='e-learning-add-program-list-empty-main-text'>
              No extra programs to assign.
            </p>
            <img
              className='e-learning-add-program-list-empty-image'
              src='/course-view-empty.svg'
              alt='empty view'
            />
            <p className='e-learning-add-program-list-empty-sub-text'>
              This user it&#39;s been added to all existing programs.
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

ProgramsList.propTypes = {
  toggleProgramListModal: PropTypes.func.isRequired,
  agencyPrograms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  programWithCurriculums: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onProgramSelectionChange: PropTypes.func.isRequired,
  programCheck: PropTypes.number.isRequired,
  handleAddUserToProgram: PropTypes.func.isRequired,
};

const CurriculumsList = ({
  toggleCurriculumListModal,
  programCurriculums,
  programWithCurriculums,
  onCurriculumSelectionChange,
  curriculumCheck,
  handleAddUserToCurriculum,
}) => {
  const availableCurriculums =
    programCurriculums?.length &&
    programCurriculums
      .filter(aC => !programWithCurriculums.find(p => p.curriculums.find(c => c.id === aC.id)))
      .filter(e => e.enabled);

  const paginazerHeight = availableCurriculums.length * 40;

  return (
    <Modal
      dimmed
      open
      closeOnEscape
      closeOnDimmerClick={false}
      closeIcon={<Image src='/icon_close.svg' />}
      className='e-learning-add-program-list'
      onClose={() => toggleCurriculumListModal()}
      style={{ marginTop: '-35vh' }}
    >
      <div className='e-learning-add-to-program-container'>
        {!availableCurriculums && (
          <Dimmer active inverted>
            <CareLoader loading={!availableCurriculums} invert showText={false} />
          </Dimmer>
        )}
        <div className='e-learning-add-program-header'>
          <h3 className='e-learning-programs-header'>CURRICULUMS</h3>
        </div>
        {availableCurriculums.length ? (
          <div className='e-learning-add-program-list-container'>
            <div className='e-learning-add-program-list-head'>
              <span className='e-learning-add-program-list-header'>Description</span>
              <span className='e-learning-add-program-list-header'>Effective Date</span>
              <span className='e-learning-add-program-list-header'>Termination Date</span>
              <span className='e-learning-add-program-list-header'>Hours</span>
              <span className='e-learning-add-program-list-header'>Add Curriculum</span>
            </div>
            <div style={{ minHeight: `${paginazerHeight}px`, overflow: 'auto' }}>
              {availableCurriculums.map(c => (
                <div className='e-learning-add-program-list-body'>
                  <span className='e-learning-add-program-body'>
                    <p className='curriculum-courses-list-description'>{c.description}</p>
                  </span>
                  <span className='e-learning-add-program-body'>
                    {moment(c.effectiveDate).format('MMM Do YYYY')}
                  </span>
                  <span className='e-learning-add-program-body'>
                    {c.terminationDate ? moment(c.terminationDate).format('MMM Do YYYY') : ''}
                  </span>
                  <span className='e-learning-add-program-body'>{c.numberOfHours}</span>
                  <span className='e-learning-add-program-body'>
                    <Checkbox
                      className='e-learning-add-program-list-check-box'
                      name='eLearningProgramsListCheckBox'
                      checked={curriculumCheck === c.id}
                      onChange={() => onCurriculumSelectionChange(c.id)}
                      booleanMode
                    />
                  </span>
                </div>
              ))}
            </div>
            <div className='e-learning-add-program-list-button-container'>
              <GreenButton
                disabled={!curriculumCheck}
                onClick={() => handleAddUserToCurriculum('curriculum', curriculumCheck)}
                className='e-learning-add-program-list-add-program-button'
              >
                Add Curriculum
              </GreenButton>
            </div>
          </div>
        ) : (
          <div className='e-learning-add-program-list-empty-container'>
            <p className='e-learning-add-program-list-empty-main-text'>
              No extra curriculum to assign.
            </p>
            <img
              className='e-learning-add-program-list-empty-image'
              src='/course-view-empty.svg'
              alt='empty view'
            />
            <p className='e-learning-add-program-list-empty-sub-text'>
              This user has been added to all existing curriculums.
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

CurriculumsList.propTypes = {
  curriculumCheck: PropTypes.number.isRequired,
  programCurriculums: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  programWithCurriculums: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  toggleCurriculumListModal: PropTypes.bool.isRequired,
  handleAddUserToCurriculum: PropTypes.func.isRequired,
  onCurriculumSelectionChange: PropTypes.func.isRequired,
};

class ProgramsTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programsListOpen: false,
      curriculumListOpen: false,
      programCheck: null,
      curriculumCheck: null,
      confirmationType: null,
      openConfirmation: false,
      confirmationObject: {},
      resetUserCourseDetails: {
        modal: false,
        loading: false,
      },
    };
  }

  onProgramSelectionChange = id => {
    this.setState({ programCheck: id });
  };

  onCurriculumSelectionChange = id => {
    this.setState({ curriculumCheck: id });
  };

  toggleProgramListModal = () => {
    const { programsListOpen } = this.state;
    this.setState(s => ({ ...s, programsListOpen: !programsListOpen, programCheck: null }));
  };

  toggleCurriculumListModal = () => {
    const { curriculumListOpen } = this.state;
    this.setState(s => ({ ...s, curriculumListOpen: !curriculumListOpen, curriculumCheck: null }));
  };

  handleCancelConfirmation = () => {
    this.setState(s => ({
      ...s,
      openConfirmation: false,
      confirmationObject: {},
    }));
  };

  openCourseResetConfirmationModal = (id, name) => {
    this.setState(s => ({
      ...s,
      resetUserCourseDetails: { id, name, modal: true, loading: false },
    }));
  };

  handleCancelCourseReset = () => {
    this.setState(s => ({
      ...s,
      resetUserCourseDetails: { modal: false, loading: false },
    }));
  };

  handleProceedCourseReset = async () => {
    const { profileId, dispatch, userId } = this.props;
    const { resetUserCourseDetails } = this.state;
    this.setState(s => ({
      ...s,
      resetUserCourseDetails: { ...resetUserCourseDetails, loading: true },
    }));
    await backend.resetUserCourseAsAdmin({ profileId, userCourseId: resetUserCourseDetails.id });
    dispatch(ELUserActions.getUserCoursesList(userId));
    this.setState(s => ({
      ...s,
      resetUserCourseDetails: { modal: false, loading: false },
    }));
  };

  handleProceedConfirmation = type => {
    const { confirmationObject } = this.state;
    const { profileId, dispatch } = this.props;
    const saveConfirmationObject = confirmationObject;
    const reason = saveConfirmationObject.reason.toLowerCase();
    if (type === 'program') {
      if (reason === 'eligibility') {
        const params = {
          programId: saveConfirmationObject.id,
          hasAccess: false,
          profileIds: [profileId],
        };
        dispatch(ELManageActions.changeAccessToProgram(params));
      } else {
        const params = {
          programId: saveConfirmationObject.id,
          profileIds: [profileId],
        };
        dispatch(ELManageActions.deleteUserFromProgram(params));
      }
    } else if (type === 'curriculum') {
      if (reason === 'eligibility') {
        const params = {
          curriculumId: saveConfirmationObject.id,
          hasAccess: false,
          profileIds: [profileId],
        };
        dispatch(ELManageActions.changeAccessToCurriculum(params));
      } else {
        const params = {
          curriculumId: saveConfirmationObject.id,
          profileIds: [profileId],
        };
        dispatch(ELManageActions.deleteUserFromCurriculum(params));
      }
    }
    return this.setState(s => ({
      ...s,
      openConfirmation: false,
      confirmationObject: {},
    }));
  };

  handleAddUserToProgramOrCurriculum = (type, addedObjectId) => {
    const { disabledPrograms, disabledCurriculums, profileId, dispatch } = this.props;
    if (type === 'program') {
      const disabled = disabledPrograms.find(p => p.id === addedObjectId);
      if (disabled) {
        const params = {
          programId: addedObjectId,
          profileIds: [profileId],
        };
        dispatch(ELManageActions.deleteUserFromProgram(params));
      } else {
        const params = {
          programId: addedObjectId,
          hasAccess: true,
          profileIds: [profileId],
        };
        dispatch(ELManageActions.changeAccessToProgram(params));
      }
      this.toggleProgramListModal();
    } else if (type === 'curriculum') {
      const disabled = disabledCurriculums.find(p => p.id === addedObjectId);
      if (disabled) {
        const params = {
          curriculumId: addedObjectId,
          profileIds: [profileId],
        };
        dispatch(ELManageActions.deleteUserFromCurriculum(params));
      } else {
        const params = {
          curriculumId: addedObjectId,
          hasAccess: true,
          profileIds: [profileId],
        };
        dispatch(ELManageActions.changeAccessToCurriculum(params));
      }
      this.toggleCurriculumListModal();
    }
  };

  handleOpenAddUserToCurriculum = programId => {
    const { dispatch } = this.props;
    const params = {
      programId,
    };
    dispatch(ELManageActions.getCurriculumWithCourses(params));
    this.toggleCurriculumListModal();
  };

  handleRemoveFrom = (type, a) => {
    let newConfirmationObject;
    switch (type) {
      case 'program':
        newConfirmationObject = {
          type,
          name: a.programName,
          id: a.id,
          reason: a.reason,
        };
        break;
      case 'curriculum':
        newConfirmationObject = {
          type: 'curriculum',
          name: 'this curriculum',
          id: a.id,
          reason: a.reason,
        };
        break;
      default:
        newConfirmationObject = {};
    }

    this.setState({
      confirmationType: type,
      openConfirmation: true,
      confirmationObject: newConfirmationObject,
    });
  };

  render() {
    const {
      programsListOpen,
      displayCurriculum,
      confirmationObject,
      confirmationType,
      openConfirmation,
      curriculumListOpen,
      programCheck,
      curriculumCheck,
      resetUserCourseDetails,
    } = this.state;
    const {
      programWithCurriculums,
      loading,
      agencyPrograms,
      programCurriculums,
      coursesLoading,
      courses,
      courseChecked,
      handleCourseRequiredChange,
      updateInProgress,
    } = this.props;

    return (
      <div className='caregiver-records-e-learning-programs-container'>
        {programsListOpen && (
          <ProgramsList
            toggleProgramListModal={this.toggleProgramListModal}
            agencyPrograms={agencyPrograms}
            programWithCurriculums={programWithCurriculums}
            onProgramSelectionChange={this.onProgramSelectionChange}
            programCheck={programCheck}
            handleAddUserToProgram={this.handleAddUserToProgramOrCurriculum}
          />
        )}
        {curriculumListOpen && (
          <CurriculumsList
            toggleCurriculumListModal={this.toggleCurriculumListModal}
            programCurriculums={programCurriculums}
            programWithCurriculums={programWithCurriculums}
            coursesLoading={coursesLoading}
            onCurriculumSelectionChange={this.onCurriculumSelectionChange}
            curriculumCheck={curriculumCheck}
            handleAddUserToCurriculum={this.handleAddUserToProgramOrCurriculum}
          />
        )}
        <Modal
          dimmed
          open={openConfirmation}
          size='mini'
          closeOnEscape={false}
          closeOnDimmerClick={false}
          className='e-learning-confirmation-modal'
          style={{ marginTop: '-20vh' }}
        >
          <div className='e-learning-confirmation-modal-container'>
            <div className='e-learning-confirmation-modal-header'>
              {`REMOVING USER FROM ${confirmationType}`}
            </div>
            <div className='e-learning-confirmation-modal-text'>
              {`Are you sure you want to remove the user from ${confirmationObject.name}
              you can add user again to this ${confirmationType} in the future`}
            </div>
            <GreenButton
              onClick={() => this.handleCancelConfirmation()}
              className='e-learning-confirmation-cancel-button'
            >
              CANCEL
            </GreenButton>
            <GreenButton
              onClick={() => this.handleProceedConfirmation(confirmationType)}
              className='e-learning-confirmation-proceed-button'
            >
              PROCEED
            </GreenButton>
          </div>
        </Modal>
        <Modal
          dimmed
          open={resetUserCourseDetails.modal}
          size='mini'
          closeOnEscape={false}
          closeOnDimmerClick={false}
          className='e-learning-confirmation-modal'
          style={{ marginTop: '-20vh' }}
        >
          <div className='e-learning-confirmation-modal-container'>
            <div className='e-learning-confirmation-modal-header'>RESET USER COURSE</div>
            <div className='e-learning-confirmation-modal-text'>
              Are you sure you want to reset the user course <br />
              <b>{resetUserCourseDetails.name}</b>
            </div>
            <GreenButton
              onClick={() => this.handleCancelCourseReset()}
              className='e-learning-confirmation-cancel-button'
            >
              CANCEL
            </GreenButton>
            <GreenButton
              onClick={() => this.handleProceedCourseReset()}
              className='e-learning-confirmation-proceed-button'
              loading={resetUserCourseDetails.loading}
            >
              PROCEED
            </GreenButton>
          </div>
        </Modal>
        {updateInProgress && (
          <div className='programs-tile-updates-in-progress'>
            <div className='update-in-progress-header'>
              <h3 className='update-in-progress-header-text'>UPDATE IN PROGRESS</h3>{' '}
            </div>
            <div className='update-inprogress-content'>
              <p className='update-in-progress-text'>
                Your update requests are in progress. This can take a couple of minutes.
              </p>
            </div>
          </div>
        )}
        <div className='e-learning-programs-head'>
          <h3 className='e-learning-programs-header'>PROGRAMS</h3>
          <div className='add-user-to-program-container'>
            <p className='add-user-text'>Add Program</p>
            <CareRoundButton
              content='+'
              onClick={() => this.toggleProgramListModal()}
              id='e-learning-add-new-program-button'
            />
          </div>
        </div>
        {loading && (
          <div>
            <Image
              src='/loader_anim.gif'
              style={{ width: '100px', height: 'auto' }}
              className='loaderImage'
              centered
            />
          </div>
        )}
        <div className='e-learning-programs-content'>
          {programWithCurriculums?.length ? (
            programWithCurriculums.map(p => {
              const curriculumsIds = p.curriculums.map(c => c.id);
              const anyCurriculumWithCompleteCourse = courses
                .filter(co => !!co.curriculums.find(cc => curriculumsIds.includes(cc.curriculumId)))
                .find(completed => completed.completionDate);
              return (
                <div className='e-learning-program'>
                  <div className='program-name-with-divider'>
                    <h4 className='program-name'>{p.program.programName}</h4>
                    <div className='program-divider-decoration' />
                  </div>
                  {p.curriculums.map(c => {
                    const anyCompletedCourse = courses
                      .filter(co => !!co.curriculums.find(cc => cc.curriculumId === c.id))
                      .find(completed => completed.completionDate);
                    return (
                      <div className='curriculum-container'>
                        <div className='curriculum-head'>
                          <p className='curriculum-name'>
                            Curriculum:{' '}
                            {c.terminationDate
                              ? `${moment(c.effectiveDate).format('MMM Do YYYY')} - ${moment(
                                  c.terminationDate
                                ).format('MMM Do YYYY')}`
                              : `from ${moment(c.effectiveDate).format('MMM Do YYYY')}`}
                          </p>
                          <p className='curriculum-courses-count'> {c.numberOfCourses} Courses </p>
                          <span className='curriculum-expand-courses'>
                            <div
                              className='curriculum-expand-courses-sign'
                              role='button'
                              tabIndex='0'
                              onClick={() => {
                                const newDisplay = displayCurriculum === c.id ? null : c.id;
                                this.setState({ displayCurriculum: newDisplay });
                              }}
                            >
                              +
                            </div>
                          </span>
                        </div>
                        <div
                          className={`${
                            displayCurriculum === c.id
                              ? 'curriculum-courses-display'
                              : 'curriculum-courses-hide'
                          }`}
                        >
                          {c.description && (
                            <>
                              <div className='curriculum-courses-list-head'>
                                <p className='curriculum-course-list-header'>Description</p>
                              </div>
                              <p className='curriculum-courses-list-description'>{c.description}</p>
                            </>
                          )}
                          <div className='curriculum-courses-list-head'>
                            <p className='curriculum-course-list-header'>Course Name</p>
                            <p className='curriculum-course-list-header'>Status</p>
                            <p className='curriculum-course-list-header'>Required</p>
                          </div>
                          {courses?.length && (
                            <div className='curriculum-courses-list'>
                              {courses
                                .filter(co => !!co.curriculums.find(cc => cc.curriculumId === c.id))
                                .map(course => {
                                  let status;
                                  if (course.completionDate) {
                                    status = 'completed';
                                  } else if (course.isRegistered && !course.completionDate) {
                                    status = 'started';
                                  } else {
                                    status = 'pending';
                                  }
                                  const isNotRequired = course.curriculums
                                    .filter(cr => cr.curriculumId === c.id)
                                    .map(ex => ex.exemption)[0];
                                  const isCourseChecked = !!courseChecked.find(
                                    cc => cc.userCourseId === course.id && cc.curriculumId === c.id
                                  );
                                  return (
                                    <div
                                      key={course.id}
                                      className={`curriculum-courses-list-body ${
                                        isCourseChecked ? 'curriculum-course-dirty' : ''
                                      }`}
                                    >
                                      <p className='curriculum-courses-name'>{course.courseName}</p>
                                      <div className='curriculum-status-container'>
                                        <div
                                          className={`curriculum-courses-circle-status-${status}`}
                                        />
                                        <div className='curriculum-status-text-date'>
                                          {status === 'started' ? 'in progress' : status}
                                          {status === 'completed'
                                            ? `(${moment(course.completionDate).format(
                                                'MMM Do YYYY'
                                              )})`
                                            : ''}
                                          {status === 'started' && (
                                            <Popup
                                              trigger={
                                                <Icon
                                                  onClick={() =>
                                                    this.openCourseResetConfirmationModal(
                                                      course.id,
                                                      course.courseName
                                                    )
                                                  }
                                                  className='curriculum-course-reset-icon'
                                                  size='large'
                                                  name='trash'
                                                />
                                              }
                                              content='Reset course'
                                              on='hover'
                                              position='top left'
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <Checkbox
                                        className='curriculum-status-reg-toggle'
                                        name='curriculumCourseRegistered'
                                        toggle
                                        checked={
                                          (!isNotRequired && !isCourseChecked) ||
                                          (isNotRequired && isCourseChecked)
                                        }
                                        onChange={() =>
                                          status !== 'completed'
                                            ? handleCourseRequiredChange(
                                                c.id,
                                                course.id,
                                                course.curriculums
                                              )
                                            : null
                                        }
                                        booleanMode
                                      />
                                    </div>
                                  );
                                })}
                            </div>
                          )}
                          <SimpleButton
                            disabled={anyCompletedCourse}
                            onClick={() => this.handleRemoveFrom('curriculum', c)}
                            className='program-button curriculum-button'
                          >
                            Remove Curriculum
                          </SimpleButton>
                        </div>
                      </div>
                    );
                  })}
                  <div className='program-buttons-container'>
                    <SimpleButton
                      onClick={() => this.handleOpenAddUserToCurriculum(p.program.id)}
                      className='program-button'
                    >
                      Add Curriculum
                    </SimpleButton>
                    <SimpleButton
                      disabled={anyCurriculumWithCompleteCourse}
                      onClick={() => this.handleRemoveFrom('program', p.program)}
                      className='program-button'
                    >
                      Remove Program
                    </SimpleButton>
                  </div>
                </div>
              );
            })
          ) : (
            <div className='e-learning-courses-content-empty'>
              <div />
            </div>
          )}
        </div>
      </div>
    );
  }
}

ProgramsTile.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  displayCurriculum: null,
};

ProgramsTile.propTypes = {
  programWithCurriculums: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  agencyPrograms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  courseChecked: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  profileId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleCourseRequiredChange: PropTypes.func.isRequired,
  programCurriculums: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  courses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  coursesLoading: PropTypes.bool.isRequired,
  disabledPrograms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disabledCurriculums: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateInProgress: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
};

export default ProgramsTile;

/* eslint-disable import/prefer-default-export */
import {
  GET_COURSES_LIST_REQUEST,
  GET_COURSES_LIST_SUCCESS,
  GET_COURSES_LIST_FAILURE,
  GET_COURSES_DETAIL_REQUEST,
  GET_COURSES_DETAIL_SUCCESS,
  GET_COURSES_DETAIL_FAILURE,
  START_COURSE_REQUEST,
  START_COURSE_SUCCESS,
  START_COURSE_FAILURE,
  RESUME_COURSE,
  START_MODULE_REQUEST,
  START_MODULE_SUCCESS,
  START_MODULE_FAILURE,
  EXIT_MODULE_REQUEST,
  EXIT_MODULE_SUCCESS,
  EXIT_MODULE_FAILURE,
  CLEAR_SINGLE_COURSE,
  GET_COURSE_CERTIFICATION_REQUEST,
  GET_COURSE_CERTIFICATION_SUCCESS,
  GET_COURSE_CERTIFICATION_FAILURE,
  CLEAR_COURSE_CERTIFICATION,
  DELETE_COURSE_REQUEST,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_FAILURE,
  PUT_USER_E_SIGNATURE_REQUEST,
  PUT_USER_E_SIGNATURE_SUCCESS,
  PUT_USER_E_SIGNATURE_FAILURE,
  GET_USER_E_SIGNATURE_REQUEST,
  GET_USER_E_SIGNATURE_SUCCESS,
  GET_USER_E_SIGNATURE_FAILURE,
  GET_SIGNING_TEMPLATE_REQUEST,
  GET_SIGNING_TEMPLATE_SUCCESS,
  GET_SIGNING_TEMPLATE_FAILURE,
  POST_SIGNED_TEMPLATE_REQUEST,
  POST_SIGNED_TEMPLATE_SUCCESS,
  POST_SIGNED_TEMPLATE_FAILURE,
} from '../../constants/ActionTypes';
import { history } from '../../helpers';
import { backend } from '../../services';
import { alertActions } from '../alert';

function getCoursesList(language) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.getCoursesList(language);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: GET_COURSES_LIST_REQUEST };
  }
  function success(results) {
    return { type: GET_COURSES_LIST_SUCCESS, results };
  }
  function failure(error) {
    return { type: GET_COURSES_LIST_FAILURE, error };
  }
}

function getCoursesDetail(id, language) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.getCoursesDetail(id, language);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: GET_COURSES_DETAIL_REQUEST };
  }
  function success(results) {
    return { type: GET_COURSES_DETAIL_SUCCESS, results };
  }
  function failure(error) {
    return { type: GET_COURSES_DETAIL_FAILURE, error };
  }
}

function startCourse(params) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.startCourse(params);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: START_COURSE_REQUEST };
  }
  function success(results) {
    history.push(`/home/e-learning/courses/course-details/${params.id}`);
    return { type: START_COURSE_SUCCESS, results };
  }
  function failure(error) {
    return { type: START_COURSE_FAILURE, error };
  }
}

function resumeCourse(id) {
  history.push(`/home/e-learning/courses/course-details/${id}`);
  return { type: RESUME_COURSE };
}

function startModule(params) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.startModule(params);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: START_MODULE_REQUEST };
  }
  function success(results) {
    return { type: START_MODULE_SUCCESS, results };
  }
  function failure(error) {
    return { type: START_MODULE_FAILURE, error };
  }
}

function exitModule(params, language) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.exitModule(params);

      let nextModule = null;
      const prevId = parseInt(params.moduleId, 10);
      if (res.nextModuleId && res.nextModuleId !== prevId) {
        const course = await backend.getCoursesDetail(params.courseId, language);
        const module = course.modules.find(m => m.id === res.nextModuleId);
        nextModule = {
          ...module,
          courseId: parseInt(params.courseId, 10),
          prevName: course.modules.find(m => m.id === prevId).displayName,
        };
      }

      dispatch(success(res, nextModule));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: EXIT_MODULE_REQUEST };
  }
  function success(results, nextModule) {
    return { type: EXIT_MODULE_SUCCESS, results, nextModule };
  }
  function failure(error) {
    return { type: EXIT_MODULE_FAILURE, error };
  }
}

function clearSetSingleCoursesDetail() {
  return { type: CLEAR_SINGLE_COURSE };
}

function getCourseCertificate(params) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.getCourseCertification(params);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: GET_COURSE_CERTIFICATION_REQUEST };
  }
  function success(results) {
    return { type: GET_COURSE_CERTIFICATION_SUCCESS, results };
  }
  function failure(error) {
    return { type: GET_COURSE_CERTIFICATION_FAILURE, error };
  }
}

function cleanCourseCertificate() {
  return { type: CLEAR_COURSE_CERTIFICATION };
}

function deleteCourse(id) {
  return async dispatch => {
    dispatch(request(dispatch));
    try {
      const res = await backend.deleteCourse(id);
      dispatch(success(res));
      dispatch(getCoursesDetail(id));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: DELETE_COURSE_REQUEST };
  }
  function success(results) {
    return { type: DELETE_COURSE_SUCCESS, results };
  }
  function failure(error) {
    return { type: DELETE_COURSE_FAILURE, error };
  }
}

function putESignature(params) {
  const uploadFile = uploadLink =>
    new Promise((resolve, reject) => {
      const upload = () => {
        const path = uploadLink;
        fetch(path, {
          method: 'PUT',
          body: params.signature,
          headers: {
            'content-length': params.signature.size,
            'Content-Type': params.signature.type,
          },
        })
          .then(s => {
            if (s.status >= 400) {
              const error = new Error(`HTTP Error ${s.statusText}`);
              error.status = s.statusText;
              error.statusCode = s.status;
              error.response = s;
              throw error;
            } else {
              resolve();
            }
            return {};
          })
          .catch(error => reject(error));
      };

      const reader = new FileReader();
      reader.onload = upload;
      reader.readAsArrayBuffer(params.signature);
    });
  return dispatch => {
    dispatch(request());
    backend
      .uploadESignature()
      .then(results => uploadFile(results.uploadUrl))
      .then(() => success())
      .then(() => dispatch(getESignature()))
      .catch(error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      });
  };

  function request() {
    return { type: PUT_USER_E_SIGNATURE_REQUEST };
  }
  function success() {
    return { type: PUT_USER_E_SIGNATURE_SUCCESS };
  }
  function failure(error) {
    return { type: PUT_USER_E_SIGNATURE_FAILURE, error };
  }
}

function getESignature() {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.getESignature();
      return dispatch(success(res));
    } catch (error) {
      if (error.statusCode === 404) {
        return null;
      }
      dispatch(failure(error));
      return dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: GET_USER_E_SIGNATURE_REQUEST };
  }
  function success(results) {
    return { type: GET_USER_E_SIGNATURE_SUCCESS, results };
  }
  function failure(error) {
    return { type: GET_USER_E_SIGNATURE_FAILURE, error };
  }
}

function getSigningTemplate(courseId) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.getSigningTemplate(courseId);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: GET_SIGNING_TEMPLATE_REQUEST };
  }
  function success(results) {
    return { type: GET_SIGNING_TEMPLATE_SUCCESS, results };
  }
  function failure(error) {
    return { type: GET_SIGNING_TEMPLATE_FAILURE, error };
  }
}

function postSignedTemplate(params) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.postSignedTemplate(params);
      dispatch(success(res, params.curriculumId));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: POST_SIGNED_TEMPLATE_REQUEST };
  }
  function success(results, curriculumId) {
    return { type: POST_SIGNED_TEMPLATE_SUCCESS, results, curriculumId };
  }
  function failure(error) {
    return { type: POST_SIGNED_TEMPLATE_FAILURE, error };
  }
}

export const ELCoursesActions = {
  getCoursesList,
  getCoursesDetail,
  startCourse,
  startModule,
  exitModule,
  resumeCourse,
  clearSetSingleCoursesDetail,
  getCourseCertificate,
  cleanCourseCertificate,
  deleteCourse,
  putESignature,
  getESignature,
  getSigningTemplate,
  postSignedTemplate,
};

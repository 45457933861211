/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Icon, Popup } from 'semantic-ui-react';
import moment from 'moment';
import { formatTime, MOMENT_HOURS_24, SHORT_DATE } from '../../constants/Formats';
import { sortTableData, parseAssigned, parseOffered, parseCancelled } from '../../helpers/common';
import TableWithExport from './TableWithExport';
import Overlay from './Overlay';
import LoadMore from './LoadMore';
import NoMatch from './NoMatch';
import InitialState from './InitialState';
import Caregivers from '../Caregivers';

class ChangeLogShiftTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      column: 'timestamp',
      data: props.data,
      direction: 'descending',
    };

    this.handleSort = this.handleSort.bind(this);
    this.tableHeader = this.tableHeader.bind(this);
    this.tableBody = this.tableBody.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !!nextState.update || this.props.loading !== nextProps.loading;
  }

  onExportClick() {
    return this.onExportClick ? this.onExportClick() : undefined;
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;

    if (!data) return;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: sortTableData(data, clickedColumn),
        direction: 'ascending',
        update: true,
      });

      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
      update: true,
    });
  };

  timestampColumn = (timestamp, boldTime, timezone = null) =>
    timestamp ? (
      <span className='infoText'>
        <span className={boldTime ? 'infoTextBold' : ''}>
          {formatTime(timestamp, timezone, SHORT_DATE)}
        </span>{' '}
        at {formatTime(timestamp, timezone, MOMENT_HOURS_24)}
      </span>
    ) : (
      undefined
    );

  responseColumn = (timestamp, applied) =>
    timestamp ? (
      <li className={applied ? 'greenText' : 'redText'}>
        {applied ? 'Requested' : 'Declined'} {this.timestampColumn(timestamp)}
      </li>
    ) : (
      undefined
    );

  assignedColumn = (timestamp, cancelled) => {
    if (cancelled) {
      return timestamp ? (
        <li className='redText'>Cancelled {this.timestampColumn(timestamp)}</li>
      ) : (
        undefined
      );
    }
    return timestamp ? (
      <li className='greenText'>Assigned {this.timestampColumn(timestamp)}</li>
    ) : (
      undefined
    );
  };

  costColumn = (meta, canceled) => {
    if (canceled) {
      return '--';
    }
    if (meta) {
      let metaObj;
      try {
        metaObj = JSON.parse(meta);
        return metaObj.extra_costs !== undefined ? Caregivers.costPopOver(metaObj) : undefined;
      } catch (e) {
        return undefined;
      }
    }

    return undefined;
  };

  costPenaltyInfo = () => (
    <div>
      Cost Penalty
      <Popup
        trigger={<Icon name='question circle outline' className='infoIcon' />}
        content='Estimated additional costs incurred by assigning this caregiver as known at the time of assignment '
        on='hover'
        position='top left'
        popperModifiers={{
          preventOverflow: {
            boundariesElement: 'offsetParent',
          },
        }}
      />
    </div>
  );

  userColumn = user => <p>{user}</p>;

  tableHeader = (column, direction, onHeaderClick) => {
    const { caregiverId } = this.props;

    const headerArray = [
      {
        id: 'change-log-shift-table-header-shiftStart',
        key: 'shiftStart',
        content: 'Shift Start',
        filter: true,
      },
      ...(caregiverId
        ? []
        : [
            {
              id: 'change-log-shift-table-header-caregiver',
              key: 'caregiver',
              content: 'Caregiver',
              filter: true,
            },
          ]),
      {
        id: 'change-log-shift-table-header-shiftId',
        key: 'shiftId',
        content: 'Shift Id#',
        filter: true,
      },
      {
        id: 'change-log-shift-table-header-client',
        key: 'client',
        content: 'Client',
        filter: true,
      },
      {
        id: 'change-log-shift-table-header-created',
        key: 'created',
        content: 'Created',
        filter: true,
      },
      {
        id: 'change-log-shift-table-header-invited',
        key: 'invited',
        content: 'Invited',
        filter: true,
      },
      {
        id: 'change-log-shift-table-header-viewed',
        key: 'viewed',
        content: 'Viewed',
        filter: true,
      },
      {
        id: 'change-log-shift-table-header-response',
        key: 'response',
        content: 'Response',
        filter: true,
      },
      {
        id: 'change-log-shift-table-header-assigned',
        key: 'assigned',
        content: 'Assigned',
        filter: true,
      },
      {
        id: 'change-log-shift-table-header-cost',
        key: 'cost',
        content: this.costPenaltyInfo(),
        textContent: 'Cost Penalty',
        filter: true,
      },
      {
        id: 'change-log-shift-table-header-invitedBy',
        key: 'invitedBy',
        content: 'Invited By',
        filter: true,
      },
      {
        id: 'change-log-shift-table-header-assignedBy',
        key: 'assignedBy',
        content: 'Assigned By',
        filter: true,
      },
    ];

    headerArray
      .filter(f => f.filter)
      .forEach(h => {
        // eslint-disable-next-line no-param-reassign
        h.className =
          column === h.key || (Array.isArray(column) && h.key === 'timestamp')
            ? `${direction} sorted`
            : undefined;

        // eslint-disable-next-line no-param-reassign
        h.onClick = onHeaderClick(h.key);

        // eslint-disable-next-line no-param-reassign
        h.filter = undefined;
      });

    return headerArray;
  };

  tableBody = (x, i) => {
    const { caregiverId } = this.props;

    const { response, applied } =
      x.shift.declined && (!x.shift.applied || moment(x.shift.declined).isAfter(x.shift.applied))
        ? { response: x.shift.declined, applied: false }
        : { response: x.shift.applied, applied: !!x.shift.applied };
    const offeredEvent = parseOffered(x);
    const assignedEvent = parseAssigned(x);
    const cancelledEvent = parseCancelled(x);
    const cancelledAssignedEvent = cancelledEvent && assignedEvent;
    return {
      key: `${x.shift.id}-${i}}`,
      className: 'infoText',
      cells: [
        { key: 'shiftStart', content: this.timestampColumn(x.shift.start, null, x.shift.timezone) },
        ...(caregiverId
          ? []
          : [
              {
                key: 'caregiver',
                content: (
                  <Overlay caregiver={{ id: x.caregiverId, caregiverName: x.caregiverName }} />
                ),
              },
            ]),
        {
          key: 'shiftId',
          content: <Overlay shift={{ id: x.shift.id, externalId: x.shift.externalId }} />,
        },
        {
          key: 'client',
          content: <Overlay client={{ id: x.shift.clientId, clientName: x.shift.clientName }} />,
        },
        { key: 'created', content: this.timestampColumn(x.shift.created) },
        { key: 'invited', content: this.timestampColumn(x.shift.offered) },
        { key: 'viewed', content: this.timestampColumn(x.shift.read) },
        { key: 'response', content: this.responseColumn(response, applied) },
        {
          key: 'assigned',
          content: this.assignedColumn(
            cancelledAssignedEvent ? x.shift.cancelled : x.shift.assigned,
            cancelledAssignedEvent
          ),
        },
        {
          key: 'cost',
          content: this.costColumn(
            assignedEvent ? assignedEvent.meta : undefined,
            cancelledAssignedEvent
          ),
        },
        {
          key: 'invitedBy',
          content: this.userColumn(offeredEvent ? offeredEvent.employee : undefined),
        },
        {
          key: 'assignedBy',
          content: this.userColumn(assignedEvent ? assignedEvent.employee : undefined),
        },
      ],
    };
  };

  render() {
    const { column, data, direction } = this.state;

    return (
      <div>
        <TableWithExport
          className='changeLogResults'
          sortable
          singleLine
          striped
          fixed
          headerRow={this.tableHeader(column, direction, this.handleSort)}
          renderBodyRow={this.tableBody}
          tableData={this.props.loading && !this.props.partialUpdate ? [] : data}
          getOnClick={clickFunc => {
            this.onExportClick = clickFunc;
            return null;
          }}
        />
        <div className='loadMore'>
          <LoadMore
            onLoadMore={() => this.props.onLoadMore(this.props.nextPage)}
            loading={this.props.loading}
            partialUpdate={this.props.partialUpdate}
            nextPage={this.props.nextPage}
          />
        </div>
        {!this.props.loading && data && data.length === 0 && (
          <Grid className='noResults'>
            <NoMatch id='shiftResultsNoMatch' />
          </Grid>
        )}
        {!data ||
          (this.props.loading && !this.props.partialUpdate && (
            <InitialState searchingFor='shift activity' loading={this.props.loading} />
          ))}
      </div>
    );
  }
}

ChangeLogShiftTable.defaultProps = {
  caregiverId: undefined,
};

ChangeLogShiftTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  partialUpdate: PropTypes.bool.isRequired,
  nextPage: PropTypes.string.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  caregiverId: PropTypes.number,
};

export default ChangeLogShiftTable;

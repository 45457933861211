import React from 'react';
import { FormattedMessage } from 'react-intl';

const shiftStatus = {
  '01': {
    value: <FormattedMessage id='shift.status.pending' />,
    prev: <FormattedMessage id='shift.status.pending.short' />,
    className: 'icon-gray',
    bgClass: 'bg-gray',
  },
  '02': {
    value: <FormattedMessage id='shift.status.confirmed' />,
    prev: <FormattedMessage id='shift.status.confirmed.short' />,
    className: 'icon-green',
    bgClass: 'bg-green',
  },
  '03': {
    value: <FormattedMessage id='shift.status.inProcess' />,
    prev: <FormattedMessage id='shift.status.inProcess.short' />,
    className: 'icon-pink',
    bgClass: 'bg-pink',
  },
  '04': {
    value: <FormattedMessage id='shift.status.closed' />,
    prev: <FormattedMessage id='shift.status.closed.short' />,
    className: 'icon-blue',
    bgClass: 'bg-blue',
  },
  '09': {
    value: <FormattedMessage id='shift.status.hold' />,
    prev: <FormattedMessage id='shift.status.hold.short' />,
    className: 'icon-orange',
    bgClass: 'bg-orange',
  },
  '05': {
    value: <FormattedMessage id='shift.status.cancelled' />,
    prev: <FormattedMessage id='shift.status.cancelled.short' />,
    className: 'icon-red',
    bgClass: 'bg-red',
  },
};

const getShiftStatusDetails = status =>
  shiftStatus[status] || { value: '-', color: 'gray', bgClass: 'bg-gray' };

export { shiftStatus, getShiftStatusDetails };

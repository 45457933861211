import {
  CREATE_CAREGIVER_REQUEST,
  CREATE_CAREGIVER_SUCCESS,
  CREATE_CAREGIVER_FAILURE,
  CREATE_CAREGIVER_CLEAR,
} from '../constants/ActionTypes';

import { prepareCaregiverObject } from './helpers';

const initialState = {
  loading: false,
  done: false,
  submitError: false,
};

function caregiverEditor(state = initialState, action) {
  switch (action.type) {
    case CREATE_CAREGIVER_REQUEST:
      return { ...state, loading: true, done: false };
    case CREATE_CAREGIVER_SUCCESS: {
      const caregiver = prepareCaregiverObject(action.results);
      return { ...state, done: true, loading: false, caregiver };
    }
    case CREATE_CAREGIVER_FAILURE:
      return { ...state, loading: false, done: false, submitError: true };

    case CREATE_CAREGIVER_CLEAR:
      return { ...state, done: false, caregiver: null, submitError: false };
    default:
      return state;
  }
}

export default caregiverEditor;

import { Grid, Header, Form } from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import { PhoneTypes } from '../../constants/DomainTypes';
import { formatPhoneNumber } from '../../constants/Formats';

const Phones = ({ object, editable, onValueChange }) => {
  const phones = object.phones || [{ id: 0, type: 1 }];
  return phones.map(v => {
    const title = PhoneTypes(v);
    const num = v.number !== undefined && v.number !== null ? formatPhoneNumber(v.number) : '-';
    return (
      <Grid.Row>
        <Grid.Column width={5}>
          <Header size='tiny' floated='left'>
            {title}:
          </Header>
        </Grid.Column>
        <Grid.Column width={11}>
          <Header className='phone-field' size='tiny'>
            {editable ? (
              <Form.Input
                placeholder={title}
                width={16}
                name='phones'
                phoneId={v.id}
                phoneType={v.type}
                value={v.number}
                onChange={onValueChange}
              />
            ) : (
              num
            )}
          </Header>
        </Grid.Column>
      </Grid.Row>
    );
  });
};

Phones.propTypes = {
  object: PropTypes.shape().isRequired,
  editable: PropTypes.bool.isRequired,
  onValueChange: PropTypes.func,
};

export default Phones;

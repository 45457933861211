import styled from 'styled-components';

export const NoResultsContainer = styled.div`
  display: grid;
  width: 100%;
  height: 50%;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  place-items: center;
  padding: 25px;

  & h3 {
    font-size: 14px;
    color: #4a4a4a;
  }
`;

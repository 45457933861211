import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import LocationMap from '../LocationMap';

const Address = styled.span`
  font-size: 11px;
  color: #a9adb4;
  letter-spacing: 0.5px;
  line-height: 16px;
`;

const GridColumnNoPadding = styled(Grid.Column)`
  &&&& {
    padding-left: 0;
    padding-right: 0;
  }
`;

class ClientLocation extends React.PureComponent {
  render() {
    const { person } = this.props;

    let address = `${person.address.formatted}`;
    if (person.address.state) address += `, ${person.address.state}`;
    if (person.address.zip) address += `, ${person.address.zip}`;

    return (
      <>
        <Grid style={{ margin: '1rem' }}>
          <GridColumnNoPadding width='12'>
            <Grid.Row>
              <Address>{address}</Address>
            </Grid.Row>
          </GridColumnNoPadding>
        </Grid>
        <LocationMap
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '280px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          showMarker
          markerIcon={{
            url: '/location_marker.svg',
            scaledSize: { width: 20, height: 20 },
            anchor: { x: 10, y: 7 },
          }}
          lat={person.address.lat}
          lon={person.address.lon}
          // circleRadius={16000}
          // defaultZoom={9}
        />
      </>
    );
  }
}

ClientLocation.propTypes = {
  person: PropTypes.shape().isRequired,
};

export default injectIntl(ClientLocation);

import React, { Component, SyntheticEvent } from 'react';
import { Table, Dropdown, DropdownProps, Image } from 'semantic-ui-react';
import styled, { StyledComponent } from 'styled-components';
import moment from 'moment';
import { SHORT_DATE, formatPhoneNumber, MOMENT_HOURS_24 } from '../../../constants/Formats';
import { IApplicantProfile } from '../../../reducers/types/applicants';
import HRApplicantNameCell from './HRApplicantNameCell';

interface IProps {
  applicants: IApplicantProfile[];
  handleToProceed: (token: string) => void;
  handleDecline: (token: string) => void;
  handleNameClick: (applicant: IApplicantProfile) => void;
  sortApplicants: (
    applicants: IApplicantProfile[],
    column: string,
    direction: string
  ) => IApplicantProfile[];
}

interface IState {
  column: string;
  direction: string;
  sortedApplicants: IApplicantProfile[];
  proceed: string[];
  decline: string[];
}

interface IButtonProps {
  disabled?: boolean;
}

const ACTION_OPTIONS = [
  {
    value: 'PROCEED',
    text: 'To Proceed',
  },
  {
    value: 'DECLINE',
    text: 'decline',
  },
];

const DropdownField = styled(Dropdown)`
  &&&& {
    font-family: Lato;
    border: 1px solid #c8cbd3;
    max-height: 38px;
    width: 100px;
    margin-right: 1rem;
  }
  &&&& > i {
    border-left: 1px solid #c8cbd3;
    color: #c8cbd3;
  }
`;

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const ActionButton: StyledComponent<'button', any, IButtonProps, never> &
  IButtonProps = styled.button`
  outline: none;
  border: none;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: ${(props: IButtonProps) => (props.disabled ? '#c8cbd3' : '#417505')};
  background-color: ${(props: IButtonProps) => (props.disabled ? '#e3e3e3' : '#69db8f')};
  font-family: Lato;
  font-weight: bold;
  font-size: 1rem;
  &::after {
    content: '>';
  }
`;

class HRApplicantsNewTable extends Component<IProps, IState> {
  public state: IState = {
    column: 'name',
    direction: 'descending',
    sortedApplicants: [],
    proceed: [],
    decline: [],
  };

  private readonly handleSort = (newColumn: string) => {
    const { direction, column } = this.state;
    const { applicants, sortApplicants } = this.props;
    const newDirection =
      column === newColumn && direction === 'descending' ? 'ascending' : 'descending';
    const sortedApplicants = sortApplicants(applicants, newColumn, newDirection);
    this.setState(s => ({
      ...s,
      column: newColumn,
      direction: newDirection,
      sortedApplicants,
    }));
  };

  private readonly onSelectionChange = (_e: SyntheticEvent, d: DropdownProps) => {
    const { proceed, decline } = this.state;
    const token: string = d.id;
    let newDeclined = decline;
    let newProceed = proceed;
    switch (d.value) {
      case 'PROCEED': {
        if (decline.includes(token)) {
          newDeclined = decline.filter(t => t !== token);
        }
        newProceed.push(token);
        break;
      }
      case 'DECLINE': {
        if (proceed.includes(token)) {
          newProceed = proceed.filter(t => t !== token);
        }
        newDeclined.push(token);
        break;
      }
      default:
        break;
    }
    return this.setState(s => ({ ...s, proceed: newProceed, decline: newDeclined }));
  };

  private readonly handleAction = (e: React.MouseEvent) => {
    const { proceed, decline } = this.state;
    const { handleToProceed, handleDecline } = this.props;
    e.preventDefault();
    const token = e.currentTarget.id;

    if (proceed.includes(token)) {
      handleToProceed(token);
    }
    if (decline.includes(token)) {
      handleDecline(token);
    }
  };

  private readonly tableHeader = (
    column: string,
    direction: string,
    onHeaderClick: (column: string) => void
  ) => {
    const headerArray = [
      {
        id: 'hr-applicant-new-table-header-name',
        key: 'name',
        content: 'Name',
        onClick: () => onHeaderClick('name'),
        className: '',
        filter: true,
      },
      {
        id: 'hr-applicant-new-table-header-email',
        key: 'email',
        content: <span>Email</span>,
        textAlign: 'center',
        onClick: () => onHeaderClick('email'),
        className: '',
        filter: true,
      },
      {
        id: 'hr-applicant-new-table-header-phone',
        key: 'phone',
        content: <span>Phone</span>,
        textAlign: 'center',
        onClick: () => onHeaderClick('phone'),
        className: '',
        filter: true,
      },
      {
        id: 'hr-applicant-new-table-header-zip',
        key: 'zip',
        content: 'Zip',
        textAlign: 'center',
        onClick: () => onHeaderClick('zip'),
        className: '',
        filter: true,
      },
      {
        id: 'hr-applicant-new-table-header-submission-date',
        key: 'submission-date',
        content: 'Submission Date',
        textAlign: 'center',
        onClick: () => onHeaderClick('submission-date'),
        className: '',
        filter: true,
      },
      {
        id: 'hr-applicant-new-table-header-action',
        key: 'action',
        content: 'Action',
        textAlign: 'center',
        onClick: () => onHeaderClick('action'),
        className: '',
        filter: false,
      },
    ];

    headerArray
      .filter(f => f.filter)
      .forEach(h => {
        Object.assign(h, {
          className: column === h.key ? `${direction} sorted` : undefined,
        });
        Object.assign(h, { filter: undefined });
      });

    return headerArray;
  };

  private readonly tableBody = (x: IApplicantProfile) => {
    const { proceed, decline } = this.state;
    const { handleNameClick } = this.props;
    const phone = x.additionalInformation.cellPhone
      ? formatPhoneNumber(x.additionalInformation.cellPhone)
      : '-';

    let actionValue: string | undefined;

    if (proceed.includes(x.token)) {
      actionValue = 'PROCEED';
    }
    if (decline.includes(x.token)) {
      actionValue = 'DECLINE';
    }

    return {
      key: `${x.token}`,
      className: 'infoText',
      cells: [
        {
          key: 'name',
          content: <HRApplicantNameCell handleNameClick={handleNameClick} applicant={x} />,
        },
        {
          key: 'email',
          verticalAlign: 'middle',
          textAlign: 'center',
          content: x.basicInformation.email,
        },
        { key: 'phone', textAlign: 'center', content: phone },
        {
          key: 'zip',
          verticalAlign: 'middle',
          textAlign: 'center',
          content: x.additionalInformation.address.zip,
        },
        {
          key: 'submission-date',
          verticalAlign: 'middle',
          textAlign: 'center',
          content: (
            <>
              <span className='infoTextDate'>
                {moment(x.profileStatus.created).format(SHORT_DATE)}
              </span>
              {moment(x.profileStatus.created).format(MOMENT_HOURS_24)}
            </>
          ),
        },
        {
          key: 'action',
          verticalAlign: 'middle',
          textAlign: 'center',
          content: (
            <div>
              <DropdownField
                id={x.token}
                selection
                options={ACTION_OPTIONS}
                value={actionValue}
                placeholder='Choose'
                onChange={this.onSelectionChange}
              />
              <ActionButton id={x.token} disabled={!actionValue} onClick={this.handleAction} />
            </div>
          ),
        },
      ],
    };
  };

  public render(): JSX.Element {
    const { applicants } = this.props;
    const { column, direction, sortedApplicants } = this.state;
    return (
      <div>
        <Table
          className='Applicant-table'
          sortable
          singleLine
          striped
          headerRow={this.tableHeader(column, direction, this.handleSort)}
          renderBodyRow={this.tableBody}
          tableData={sortedApplicants.length ? sortedApplicants : applicants}
        />
        {!applicants.length && (
          <div>
            <div className='e-learning-empty-table'>
              <h3>No Matching Results</h3> <Image src='/nomatch.svg' />
              <p>Try Removing some of your search filters</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default HRApplicantsNewTable;

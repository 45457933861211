/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

interface ITabMenuItem {
  active: boolean;
}

export const TabContainer = styled.div`
  width: 100%;
  min-width: fit-content;
`;

export const TabMenuBar = styled.div`
  display: flex;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding-right: 1rem;
  background-color: #f1f1f1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
  p {
    display: inline-block;
    color: #9b9b9b;
    font-size: 12px;
    margin: 0;
  }
`;

export const TabsButtonsContent = styled.div`
  flex-shrink: 0;
  padding-right: 1rem;
  box-shadow: 0 10px #f1f1f1 inset, 0 -10px #f1f1f1 inset, -1px 0 #9b9b9b inset;
  border-top-left-radius: 15px;
`;

export const TabMenuItem = styled.button`
  border: none;
  padding: 1.5rem;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: ${(props: ITabMenuItem) => (props.active ? '#4a4a4a' : '#9b9b9b')};
  border-bottom: ${(props: ITabMenuItem) => (props.active ? '2px solid #04a5d5' : 'none')};
  background-color: ${(props: ITabMenuItem) => (props.active ? '#ffffff' : 'transparent')};
`;

export const TabContent = styled.div`
  width: 100%;
  display: ${(props: ITabMenuItem) => (props.active ? 'block' : 'none')};
  background-color: #ffffff;
  padding: 1rem;
`;

import { Grid, Header, List } from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

const Timezone = ({ title, object }) =>
  (object.address || {}).timezone ? (
    <Grid.Row>
      <Grid.Column width={5}>
        <Header size='tiny'>{title}</Header>
      </Grid.Column>
      <Grid.Column width={11}>
        <Header size='tiny'>
          <List>
            <List.Item content={object.address.timezone} />
          </List>
        </Header>
      </Grid.Column>
    </Grid.Row>
  ) : null;

Timezone.defaultProps = {
  title: 'Timezone:',
};

Timezone.propTypes = {
  object: PropTypes.shape().isRequired,
  title: PropTypes.string,
};

export default Timezone;

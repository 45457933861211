/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Menu, Accordion, Button, Form, Dropdown, Divider, Image, Icon } from 'semantic-ui-react';

import DatePicker from 'react-datepicker';
import InputRange from 'react-input-range';
import moment from 'moment-timezone';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-input-range/lib/css/index.css';

import { store } from '../helpers/index';
import { uiStateActions, schedulerActions } from '../actions';
import './Schedules.css';
import TeamForm from './TeamForm';
import DaveBox from './subcomponents/DaveBox';
import { analytics, Events } from '../helpers/analytics';

const ShiftStatusForm = ({ uiState, onChange }) => {
  const shiftStatus = [
    { text: <FormattedMessage id='shift.allShifts' />, value: 'allshifts' },
    { text: <FormattedMessage id='shift.status.pending' />, value: 'pending' },
    { text: <FormattedMessage id='shift.status.inProcess' />, value: 'inprocess' },
    { text: <FormattedMessage id='shift.status.confirmed' />, value: 'confirmed' },
    { text: <FormattedMessage id='shift.status.closed' />, value: 'closed' },
    { text: <FormattedMessage id='shift.status.hold' />, value: 'hold' },
    { text: <FormattedMessage id='shift.status.cancelled' />, value: 'cancelled' },
  ];

  return (
    <Form className='filterMenu'>
      <Form.Group grouped>
        <Dropdown
          id='shiftsSearchStatusDropdown'
          fluid
          selection
          name='shiftStatus'
          options={shiftStatus}
          onChange={onChange}
          value={uiState.shiftStatus}
        />
        <DaveBox
          id='shiftsSearchStatusCheckboxAssigned'
          label='Assigned'
          name='shiftCompinedStatus'
          value='assigned'
          checked={uiState.shiftCompinedStatus.indexOf('assigned') >= 0}
          onChange={onChange}
        />
        <DaveBox
          id='shiftsSearchStatusCheckboxUnassigned'
          label='Unassigned'
          name='shiftCompinedStatus'
          value='unassigned'
          checked={uiState.shiftCompinedStatus.indexOf('unassigned') >= 0}
          onChange={onChange}
        />
      </Form.Group>
    </Form>
  );
};

ShiftStatusForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  uiState: PropTypes.shape().isRequired,
};

const FilterGroup = ({ uiState, onTitleClick, onValueChange, DateTime }) => (
  <Accordion as={Menu} exclusive={false} widths='sixteen'>
    <Menu.Item className='text-align-left'>
      <Accordion.Title
        id='schedules-menu-accordion-Shift-status'
        className='side-menu-item'
        active={uiState.shiftStatusMenu}
        content='Shift Status'
        indexname='shiftStatusMenu'
        onClick={onTitleClick}
      />
      <Accordion.Content active={uiState.shiftStatusMenu}>
        <ShiftStatusForm uiState={uiState} onChange={onValueChange} />
      </Accordion.Content>
    </Menu.Item>
    <Menu.Item className='text-align-left'>
      <Accordion.Title
        id='schedules-menu-accordion-date-time'
        className='side-menu-item'
        active={uiState.datetimeMenu}
        content='Date / Time'
        indexname='datetimeMenu'
        onClick={onTitleClick}
      />
      <Accordion.Content active={uiState.datetimeMenu} style={{ padding: '0' }}>
        {DateTime}
      </Accordion.Content>
    </Menu.Item>
    <TeamForm uiState={uiState} onChange={onValueChange} onTitleClick={onTitleClick} />
  </Accordion>
);

FilterGroup.propTypes = {
  uiState: PropTypes.shape().isRequired,
  onTitleClick: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  DateTime: PropTypes.func.isRequired,
};

class SchedulesMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      hasFilterChange: false,
    };
    this.handleUpdateClick = this.handleUpdateClick.bind(this);
  }

  handleFilterTitleClick = (e, titleProps) => {
    this.props.dispatch(
      uiStateActions.uiStateChange('shiftFilter', titleProps.indexname, !titleProps.active)
    );
  };

  handleUpdateClick = () => {
    this.props.onUpdateClick();

    const fullTimeRange =
      this.props.uiState.timeRange.min === 1 && this.props.uiState.timeRange.max === 24;

    const timezone = moment.tz.guess();
    const { selectedPrimaryContacts } = this.props.uiState;
    let primaryContact;
    if (
      selectedPrimaryContacts &&
      selectedPrimaryContacts.length &&
      !selectedPrimaryContacts.includes(0)
    ) {
      primaryContact = selectedPrimaryContacts;
    }
    const params = {
      withText: this.props.uiState.clientName,
      shiftStatus: this.props.uiState.shiftStatus,
      shiftCompinedStatus: this.props.uiState.shiftCompinedStatus,
      dateStart: this.props.uiState.startDate
        .tz(timezone)
        .startOf('day')
        .toISOString(),
      dateEnd: this.props.uiState.endDate
        .tz(timezone)
        .endOf('day')
        .toISOString(),
      hoursStart: fullTimeRange ? undefined : this.props.uiState.timeRange.min,
      hoursEnd: fullTimeRange ? undefined : this.props.uiState.timeRange.max,
      primaryContact,
    };

    this.props.dispatch(schedulerActions.listSchedules(params));
    analytics.track(Events.SHIFTS_SEARCH);
    this.setState({ hasFilterChange: false });
  };

  handleOnValueChange = (e, input) => {
    uiStateActions.inputChangeHandler(this.props.dispatch, 'shiftFilter')(e, input);
    if (this.state.hasFilterChange === false) {
      this.setState({ hasFilterChange: true });
    }
  };

  DateTimeForm = uiState => (
    <Form className='filterMenu'>
      <Form.Group>
        <div className='field'>
          <DatePicker
            id='shiftsSearchDateStart'
            selected={uiState.startDate}
            onChange={this.datePickerChange('startDate')}
            dateFormat='MM/DD/YY'
          />
        </div>
        <div className='field'>
          <Icon name='angle right' fitted style={{ paddingTop: '16px', color: '#364866' }} />
        </div>
        <div className='field'>
          <DatePicker
            id='shiftsSearchDateEnd'
            selected={uiState.endDate}
            onChange={this.datePickerChange('endDate')}
            dateFormat='MM/DD/YY'
          />
        </div>
      </Form.Group>
      <div className='side-menu-slider-title' style={{ color: '#9B9B9B', fontWeight: 'normal' }}>
        Shifts Occurring between
      </div>
      <Form.Group widths='equal'>
        <div className='field shiftsSearchTimeRange'>
          <InputRange
            id='shiftsSearchTimeRange'
            maxValue={24}
            minValue={1}
            value={uiState.timeRange}
            formatLabel={this.rangeLabels}
            onChange={this.timeRangeChange('timeRange')}
          />
        </div>
      </Form.Group>
    </Form>
  );

  datePickerChange = stateName => e => {
    store.dispatch(uiStateActions.uiStateChange('shiftFilter', stateName, e));
    if (this.state.hasFilterChange === false) {
      this.setState({ hasFilterChange: true });
    }
  };

  timeRangeChange = stateName => e => {
    store.dispatch(uiStateActions.uiStateChange('shiftFilter', stateName, e));
    if (this.state.hasFilterChange === false) {
      this.setState({ hasFilterChange: true });
    }
  };

  rangeLabels = value => {
    if (value === 0) return '12am';
    if (value < 12) {
      return `${value}am`;
    }

    const tmp = value - 12;
    if (tmp === 0) return '12pm';
    return tmp === 12 ? `${tmp}am` : `${tmp}pm`;
  };

  render() {
    const { loading, uiState } = this.props;

    const items = [
      {
        key: 'filters',
        content: (
          <FilterGroup
            uiState={uiState}
            onTitleClick={this.handleFilterTitleClick}
            onValueChange={(e, input) => this.handleOnValueChange(e, input)}
            DateTime={this.DateTimeForm(uiState)}
          />
        ),
      },
      {
        key: 'update',
        content: (
          <Button
            id='shiftsSearchUpdateButton'
            primary
            content='Update'
            className={!this.state.hasFilterChange ? 'care-grey' : 'care-grey-active'}
            loading={loading}
            onClick={this.handleUpdateClick}
          />
        ),
      },
    ];

    return (
      <div className='filterBar'>
        <div className='filterTitle'>
          <Image src='/filters_icon.svg' verticalAlign='middle' /> <span>Filter Shifts</span>
        </div>
        <Divider />
        <Menu className='filterItem' vertical secondary fluid items={items} />
      </div>
    );
  }
}

SchedulesMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uiState: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  onUpdateClick: PropTypes.func.isRequired,
};

export default connect(uiStateActions.mapStateOfGroupToProps('shiftFilter'))(SchedulesMenu);

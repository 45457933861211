/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Modal, Button, Grid, Image, Dimmer } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { caregiverActions } from '../actions';
import { avatarStyle, avatarUrl, PreferenceCategories } from '../constants/DomainTypes';
import CareLoader from './subcomponents/CareLoader';
import Status from './subcomponents/Status';
import CaregiverDetailsTab from './CaregiverDetailsTab';
import { history } from '../helpers/history';
import './CaregiverDetails.css';
import './Schedules.css';

const PrefAndSkillsTab = ({ caregiver }) => {
  const languages = caregiverLanguages => {
    const primaryLanguage = caregiverLanguages
      ? caregiverLanguages.filter(l => l.primary === true)
      : [];
    const additionLanguages = caregiverLanguages
      ? caregiverLanguages.filter(l => l.primary === false)
      : [];
    const listAdditionalLanguages = additionLanguages.map((a, i) => {
      const coma = additionLanguages.length - 1 > i ? ',' : '';
      return (
        <b key={a.id} className='caregiver-details-bold-text'>
          {`${a.name}${coma} `}
        </b>
      );
    });
    return (
      <div className='caregiver-details-languages-container'>
        <h3 className='caregiver-details-section-header'>LANGUAGES</h3>
        <div className='caregiver-details-info-container'>
          <h6 className='caregiver-details-header'>Primary:</h6>
          <p className='caregiver-details-bold-text'>
            {primaryLanguage && primaryLanguage.length ? primaryLanguage[0].name : ''}
          </p>
        </div>
        <div className='caregiver-details-info-container'>
          <h6 className='caregiver-details-header accumulative-children-header'>Additional:</h6>
          <p className='caregiver-details-bold-text'>{listAdditionalLanguages}</p>
        </div>
      </div>
    );
  };

  const travelTime = caregiver.casePref ? caregiver.casePref.travelUpToMinutes : 60;

  const preferencesUneditable = caregiverPreferences =>
    Object.keys(PreferenceCategories).map(category => {
      const all = caregiverPreferences
        ? caregiverPreferences.filter(x => x.category === category)
        : [];
      const options = all.map(x => ({
        value: x.id,
        text: x.caregiverLabel || x.label,
        checked: x.value === 'Y',
      }));

      if (options && options.length) {
        let categoryTitle;
        if (PreferenceCategories[category].caregiverTitle === 'Skills') {
          categoryTitle = 'skills & training';
        } else if (PreferenceCategories[category].caregiverTitle === 'Home Environment') {
          categoryTitle = 'environment preferences';
        } else {
          categoryTitle = PreferenceCategories[category].caregiverTitle;
        }
        return (
          <React.Fragment key={category}>
            <div>
              <h4 className='caregiver-details-section-header'>{categoryTitle.toUpperCase()}</h4>
            </div>
            {options.map(v => (
              <div key={v.value} className='caregiver-details-info-container'>
                <div>
                  <p
                    className={
                      v.checked
                        ? 'caregiver-details-text pref-text'
                        : 'caregiver-details-text-not-checked pref-text'
                    }
                  >
                    {v.text}:
                  </p>
                </div>
                {v.checked && (
                  <img className='checked-preference' alt='checked' src='/compliance/check.svg' />
                )}
              </div>
            ))}
            <hr className='caregiver-details-separator' />
          </React.Fragment>
        );
      }
      return undefined;
    });

  return (
    <div className='caregiver-details-pref-main-container'>
      <div>
        <h4 className='caregiver-details-section-header'>TRAVEL PREFERENCES</h4>
      </div>
      <div className='caregiver-details-info-container'>
        <h6 className='caregiver-details-header'>Travel Time:</h6>
        <p className='caregiver-details-bold-text'>{travelTime} minutes</p>
      </div>
      <div className='caregiver-details-info-container'>
        <h6 className='caregiver-details-header'>Transit Pref:</h6>
        <div className='travelImage'>
          <img
            id='travel-pref-image'
            alt='transit-pref'
            src={
              caregiver.casePref && caregiver.casePref.transportType
                ? `/icon_${caregiver.casePref.transportType}blue.svg`
                : '/icon_publicgrey.svg'
            }
          />
          <p className='caregiver-details-bold-text transit-text'>Transit</p>
        </div>
      </div>
      <hr className='caregiver-details-separator' />
      <div>{languages(caregiver.languages)}</div>
      <hr className='caregiver-details-separator' />
      {preferencesUneditable(caregiver.preferences)}
      {caregiver.customFields && caregiver.customFields.length > 0 && (
        <div>
          <h4 className='caregiver-details-section-header'>CUSTOM PREFERENCES</h4>
          {caregiver.customFields.map(v => (
            <div key={v.id} className='caregiver-details-info-container'>
              <p
                className={
                  v.value === 'Y'
                    ? 'caregiver-details-text pref-text'
                    : 'caregiver-details-text-not-checked pref-text'
                }
              >
                {v.name}:
              </p>
              {v.value === 'Y' && (
                <img className='checked-preference' alt='checked' src='/compliance/check.svg' />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

PrefAndSkillsTab.propTypes = {
  caregiver: PropTypes.shape().isRequired,
};

const AgencyInfoTab = ({ caregiver }) => {
  const splitRecipients = caregiver.notificationRecipients
    ? caregiver.notificationRecipients.split(',')
    : [];

  const recipientsList = splitRecipients.map(r => (
    <div key={r}>
      <p className='caregiver-details-text'>{r}</p>
    </div>
  ));

  const exclusions = caregiver.clientExclusion
    ? caregiver.clientExclusion.map(t => (
        <div key={t.id}>
          <p className='caregiver-details-text'>{t.clientName}</p>
        </div>
      ))
    : [];

  return (
    <div className='caregiver-details-pref-main-container'>
      <div className='caregiver-details-info-container'>
        <h6 className='caregiver-details-header'>Primary Contact:</h6>
        <p className='caregiver-details-text'>
          {caregiver.primaryContact ? caregiver.primaryContact.employeeName : '-'}
        </p>
      </div>
      <hr className='caregiver-details-separator' />
      <div className='caregiver-details-info-container'>
        <h6 className='caregiver-details-header'>Status:</h6>
        <Status object={caregiver} editable={false} onValueChange={() => null} />
      </div>
      <hr className='caregiver-details-separator' />
      <div className='caregiver-details-info-container'>
        <h6 className='caregiver-details-header accumulative-children-header'>
          Change notification recipients:
        </h6>
        <div>{recipientsList}</div>
      </div>
      <hr className='caregiver-details-separator' />
      <div className='caregiver-details-info-container'>
        <h6 className='caregiver-details-header accumulative-children-header'>
          Client Exclusions:
        </h6>
        <div>{exclusions}</div>
      </div>
    </div>
  );
};

AgencyInfoTab.propTypes = {
  caregiver: PropTypes.shape().isRequired,
};

class CaregiverDetails extends React.Component {
  constructor() {
    super();
    this.state = { activeTab: 'details' };
    this.tabClick = this.tabClick.bind(this);
    this.viewCaregiverRecords = this.viewCaregiverRecords.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.dispatch(caregiverActions.getCaregiver(this.props.caregiverId));
    this.setState({
      activeTab: this.props.tab ? this.props.tab : 'details',
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.caregiverDetails && !nextProps.caregiverDetails.loading) {
      if (nextProps.caregiverDetails.caregiver) {
        this.setState({
          caregiver: nextProps.caregiverDetails.caregiver,
          original: {
            ...nextProps.caregiverDetails.caregiver,
            address: { ...nextProps.caregiverDetails.caregiver.address },
            casePref: { ...nextProps.caregiverDetails.caregiver.casePref },
            phones: nextProps.caregiverDetails.caregiver.phones.map(p => ({ ...p })),
            customFields:
              nextProps.caregiverDetails.caregiver.customFields &&
              JSON.parse(JSON.stringify(nextProps.caregiverDetails.caregiver.customFields)),
          },
          isChanged: false,
          isValid: false,
        });
      } else {
        this.props.onCloseClicked();
      }
    }
  }

  tabClick(e, input) {
    this.setState({
      activeTab: input.name,
    });
  }

  viewCaregiverRecords() {
    this.props.onCloseClicked();
    if (this.props.sitePath === 'HR') {
      return history.push(`/home/hr/caregivers/records/${this.state.caregiver.id}`);
    }
    return history.push(`/home/caregivers/records/${this.state.caregiver.id}`);
  }

  render() {
    const { loading } = this.props.caregiverDetails;
    const { activeTab, caregiver } = this.state;

    if (loading || !caregiver) {
      return (
        <Dimmer active page>
          <CareLoader loading={loading} invert showText={false} />
        </Dimmer>
      );
    }

    return (
      <Modal
        id='caregiver-details-modal'
        open
        closeOnDimmerClick={false}
        closeIcon={<Image className='caregiver-modal-close-icon' src='/icon_close.svg' />}
        dimmer
        onClose={this.props.onCloseClicked}
        className='caregiverDetails'
        style={{ marginTop: '-45vh' }}
      >
        <Modal.Header>
          <Grid centered className='modalHeaderGrid'>
            <Grid.Column width={10} textAlign='center' className='caregiver-details-name-header'>
              <h4 className='caregiver-details-caregiver-name'>{caregiver.caregiverName}</h4>
              <span className='id-text'>ID: {caregiver.externalId}</span>
            </Grid.Column>
            <Grid.Column width={10} textAlign='center' className='headerPic'>
              <div className='modalHeaderImages'>
                <Image className='modal-bubbles' inline size='mini' src='/circles.svg' />
                <Image
                  id='caregiver-details-avatar'
                  inline
                  circular
                  size='tiny'
                  style={avatarStyle(caregiver.avatar, caregiver.gender, 'large')}
                  src={avatarUrl(caregiver.avatar, caregiver.gender)}
                />
              </div>
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Button.Group fluid size='tiny' className='careTabs'>
          <Button
            id='caregiver-details-details-tab-button'
            className='careTab'
            active={activeTab === 'details'}
            onClick={this.tabClick}
            name='details'
          >
            Details
          </Button>
          <Button
            id='caregiver-details-preferences-tab-button'
            className='careTab'
            active={activeTab === 'prefAndSkills'}
            onClick={this.tabClick}
            name='prefAndSkills'
          >
            Preferences &amp; Skills
          </Button>
          <Button
            id='caregiver-details-agency-tab-button'
            className='careTab'
            active={activeTab === 'agency'}
            onClick={this.tabClick}
            name='agency'
          >
            Agency Info
          </Button>
        </Button.Group>
        <Modal.Content scrolling style={{ overflowX: 'hidden' }}>
          <Grid centered>
            <Grid.Column id='caregiver-details-column' width={10}>
              {activeTab === 'details' && <CaregiverDetailsTab caregiver={caregiver} />}
              {activeTab === 'prefAndSkills' && <PrefAndSkillsTab caregiver={caregiver} />}
              {activeTab === 'agency' && <AgencyInfoTab caregiver={caregiver} />}
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Grid centered className='buttonArea'>
            <Grid.Column width={10} textAlign='center'>
              <Button
                id='caregiver-details-view-edit-button'
                className='care-green'
                onClick={this.viewCaregiverRecords}
              >
                View/Edit Full Profile
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
    );
  }
}

CaregiverDetails.defaultProps = {
  tab: 'details',
  sitePath: null,
};

CaregiverDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  caregiverId: PropTypes.number.isRequired,
  tab: PropTypes.string,
  onCloseClicked: PropTypes.func.isRequired,
  caregiverDetails: PropTypes.shape().isRequired,
  sitePath: PropTypes.string,
};

const mapStateToProps = state => {
  const { caregiverDetails } = state;
  const { disciplines, languages } = state.principal;
  return {
    caregiverDetails,
    languages,
    employees: state.employee.employeeList,
    settings: state.principal.settings,
    disciplines,
  };
};

export default connect(mapStateToProps)(CaregiverDetails);

import { IDispatchAction } from '../../actions/hrapp/filterActions';
import {
  UPDATE_HR_CAREGIVER_FILTER,
  FILTER_BY_PENDING,
  FILTER_BY_EXPIRED,
  FILTER_BY_COVID_VALIDATION,
  CLEAN_COMPLIANCE_OPTIONS,
  CLEAN_VACCINATION_OPTIONS,
  CLEAN_ALL_HR_FILTERS,
} from '../../constants/ActionTypes';
import { IHRCaregiversFilter } from '../types/HRFilters';

const initialState: IHRCaregiversFilter = {
  nameOrId: { nameOrId: '' },
  disciplines: { disciplines: [] },
  compliancesIssues: {
    eligibility: [],
    medical: [],
    documentation: [],
    training: [],
    incomplete: false,
    pending: false,
    expired: false,
    dueDate: 30,
  },
  vaccinationValidation: {
    vaccine: [],
    validation: false,
    upload: false,
    complete: false,
    declined: false,
  },
  primaryContact: { primaryContact: [] },
  primaryManager: { primaryManager: [] },
  caregiverStatus: { status: [] },
  secondaryStatus: { secondaryStatus: [] },
  blockShift: { blockShift: false },
};

const startState = JSON.parse(JSON.stringify(initialState));

function HRCaregiverFilter(state = startState, action: IDispatchAction) {
  switch (action.type) {
    case UPDATE_HR_CAREGIVER_FILTER: {
      const newState = { ...state };
      newState[action.changes.section][action.changes.filter] = action.changes.value;
      return newState;
    }
    case FILTER_BY_PENDING: {
      const newState = { ...state };
      Object.assign(newState, {
        compliancesIssues: {
          eligibility: [0],
          medical: [0],
          documentation: [0],
          training: [0],
          incomplete: false,
          pending: true,
          expired: false,
          dueDate: 30,
        },
        vaccinationValidation: {
          vaccine: [],
          validation: false,
          upload: false,
          complete: false,
          declined: false,
        },
      });
      return newState;
    }
    case FILTER_BY_EXPIRED: {
      const newState = { ...state };
      Object.assign(newState, {
        compliancesIssues: {
          eligibility: [0],
          medical: [0],
          documentation: [0],
          training: [0],
          incomplete: true,
          pending: false,
          expired: true,
          dueDate: 30,
        },
        vaccinationValidation: {
          vaccine: [],
          validation: false,
          upload: false,
          complete: false,
          declined: false,
        },
      });
      return newState;
    }
    case FILTER_BY_COVID_VALIDATION: {
      const newState = { ...state };
      Object.assign(newState, {
        compliancesIssues: {
          eligibility: [],
          medical: [],
          documentation: [],
          training: [],
          incomplete: false,
          pending: false,
          expired: true,
          dueDate: 30,
        },
        vaccinationValidation: {
          vaccine: ['COVID'],
          validation: true,
          upload: false,
          complete: false,
          declined: false,
        },
      });
      return newState;
    }
    case CLEAN_COMPLIANCE_OPTIONS: {
      const newState = { ...state };
      Object.assign(newState, {
        compliancesIssues: {
          ...newState.compliancesIssues,
          incomplete: false,
          pending: false,
          expired: false,
          dueDate: 30,
        },
      });
      return newState;
    }
    case CLEAN_VACCINATION_OPTIONS: {
      const newState = { ...state };
      Object.assign(newState, {
        vaccinationValidation: {
          ...newState.vaccinationValidation,
          validation: false,
          upload: false,
          complete: false,
          declined: false,
        },
      });
      return newState;
    }
    case CLEAN_ALL_HR_FILTERS: {
      const newState = JSON.parse(JSON.stringify(initialState));
      return newState;
    }
    default:
      return state;
  }
}

export default HRCaregiverFilter;

import { Image } from 'semantic-ui-react';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  row-gap: 30px;
  margin-top: 50px;
`;

const Tile = styled.div`
  width: 520px;
  height: 350px;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  text-align: center;
`;

const HeaderText = styled.p<{ sideMargin: number }>`
  font-weight: bold;
  font-size: 16px;
  padding-top: 30px;
  color: #4a4a4a;
  margin-bottom: 5px;
  margin-left: ${props => props.sideMargin}%;
  margin-right: ${props => props.sideMargin}%;
`;

const BodyText = styled.p<{ sideMargin: number }>`
  font-size: 12px;
  color: #9b9b9b;
  margin-left: ${props => props.sideMargin}%;
  margin-right: ${props => props.sideMargin}%;
  margin-bottom: 20px;
`;

interface Props {
  header: string;
  title: string;
  body?: string;
}

export const FeatureDisabledTile: React.FC<Props> = ({ header, title, body }) => {
  return (
    <Container>
      <Tile>
        <HeaderText sideMargin={10}>{header}</HeaderText>
        <Image centered src='/reports-icon.png' alt='Business Intelligence disabled' />
        <HeaderText sideMargin={10}>{title}</HeaderText>
        {body && <BodyText sideMargin={10}>{body}</BodyText>}
      </Tile>
    </Container>
  );
};

import * as types from '../constants/ActionTypes';
import { backend } from '../services';
// eslint-disable-next-line import/no-cycle
import { alertActions } from './index';

function travelTimeRequest(tripList, destination) {
  const trips = tripList.map(i => {
    const r = { ...i };
    r.arrivalTime = r.arrivalTime ? r.arrivalTime.toISOString() : r.arrivalTime;

    r.departureTime = r.departureTime ? r.departureTime.toISOString() : r.departureTime;

    return r;
  });

  const target = destination ? { latLon: destination } : null;

  return travelTimeProcess(true, trips, target);
}

function travelTimeProcess(initialCall, trips, target, partialResponses) {
  return dispatch => {
    if (initialCall) {
      dispatch(request());
    }

    backend.listTraveltimes({ trips, target }).then(
      results => {
        if (results.nextPage) {
          dispatch(partial());
          dispatch(
            travelTimeProcess(
              false,
              results.nextPage.trips,
              target,
              partialResponses ? partialResponses.concat(results.travelTimes) : results.travelTimes
            )
          );
        } else {
          dispatch(
            success(
              partialResponses ? partialResponses.concat(results.travelTimes) : results.travelTimes
            )
          );
        }
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.TRAVEL_TIME_REQUEST };
  }
  function success(results) {
    return { type: types.TRAVEL_TIME_SUCCESS, results };
  }
  function partial() {
    return { type: types.TRAVEL_TIME_PARTIAL };
  }
  function failure(error) {
    return { type: types.TRAVEL_TIME_FAILURE, error };
  }
}

// eslint-disable-next-line import/prefer-default-export
export const travelTimeActions = {
  travelTimeRequest,
};

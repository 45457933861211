import {
  CLIENT_CAREGIVERS_SCORE_REQUEST,
  CLIENT_CAREGIVERS_SCORE_SUCCESS,
  CLIENT_CAREGIVERS_SCORE_FAILURE,
  SHIFT_CAREGIVERS_SCORE_SUCCESS,
  SHIFT_CAREGIVERS_SCORE_REQUEST,
  SHIFT_CAREGIVERS_SCORE_FAILURE,
  CAREGIVERS_SCORE_RESET_REQUEST,
} from '../constants/ActionTypes';

const initialState = {
  loading: false,
  results: {},
  costs: {},
  initialRequestDone: false,
};

function caregiverScores(state = initialState, action) {
  switch (action.type) {
    case CAREGIVERS_SCORE_RESET_REQUEST: {
      return { ...state, results: {}, costs: {} };
    }
    case CLIENT_CAREGIVERS_SCORE_REQUEST: {
      const newState = { ...state, ...state, loading: true, initialRequestDone: true };

      if (!(action.params in newState.results)) {
        newState.results[action.params] = { scores: [], loading: true, error: false };
      } else {
        newState.results[action.params] = { ...newState.results[action.params], loading: true };
      }

      return newState;
    }
    case SHIFT_CAREGIVERS_SCORE_REQUEST: {
      const newState = { ...state, ...state, loading: true, initialRequestDone: true };

      const shiftIdKey = action.params;
      if (!(shiftIdKey in newState.costs)) {
        newState.costs[shiftIdKey] = { costs: [], loading: true, error: false };
      } else {
        newState.costs[shiftIdKey] = { ...newState.costs[action.params], loading: true };
      }

      return newState;
    }
    case CLIENT_CAREGIVERS_SCORE_FAILURE:
    case SHIFT_CAREGIVERS_SCORE_FAILURE: {
      const newState = { ...state, ...state, loading: false };
      const queryParam = action.params;
      if (action.type === CLIENT_CAREGIVERS_SCORE_FAILURE) {
        newState.results[queryParam] = { scores: [], loading: false, error: true };
      } else if (action.type === SHIFT_CAREGIVERS_SCORE_FAILURE) {
        newState.costs[queryParam] = { costs: [], loading: false, error: true };
      }

      return newState;
    }
    case CLIENT_CAREGIVERS_SCORE_SUCCESS:
    case SHIFT_CAREGIVERS_SCORE_SUCCESS: {
      const newState = { ...state, ...state, loading: false };
      if (!action.results.results) {
        // Error case when response is SUCCESS but no results
        if (action.type === SHIFT_CAREGIVERS_SCORE_SUCCESS) {
          newState.costs[action.params] = { costs: [], loading: false, error: false };
        } else if (action.type === CLIENT_CAREGIVERS_SCORE_SUCCESS) {
          newState.results[action.params] = { scores: [], loading: false, error: false };
        }
      } else {
        action.results.results.forEach(s => {
          if ('client_id' in s) {
            newState.results[s.client_id] = { scores: s.scores, loading: false, error: false };
          } else if ('shift_id' in s) {
            newState.costs[s.shift_id] = { costs: s.costs, loading: false, error: false };
          }
        });
      }

      return newState;
    }

    default:
      return state;
  }
}

export default caregiverScores;

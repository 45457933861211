import {
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_REQUEST,
  GET_EMPLOYEE_FAILURE,
  GET_EMPLOYEE_LIST_SUCCESS,
  GET_EMPLOYEE_LIST_REQUEST,
  GET_EMPLOYEE_LIST_FAILURE,
  UPDATE_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_DASHBOARD_REQUEST,
  GET_EMPLOYEE_DASHBOARD_SUCCESS,
  GET_EMPLOYEE_DASHBOARD_FAILURE,
  INVITE_EMPLOYEE_SUCCESS,
  UPDATE_USER_STATUS_SUCCESS,
  UPLOAD_AVATAR_REQUEST,
  UPLOAD_AVATAR_SUCCESS,
  UPLOAD_AVATAR_FAILURE,
} from '../constants/ActionTypes';
import { prepareEmployeeObject } from './helpers';

const initialState = {
  loading: false,
  employeeList: [],
  loadingEmployee: false,
  loadingDashboard: false,
  rootUser: false,
  employee: null,
  employeeEditor: null,
  updatedEmployeeId: null,
  loadingAvatar: false,
};

function employee(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_LIST_REQUEST:
      return { ...state, loading: true };
    case GET_EMPLOYEE_LIST_SUCCESS: {
      const employeeList = action.results.results
        .map(e => prepareEmployeeObject(e))
        .sort((a, b) => a.compareName.localeCompare(b.compareName));

      return { ...state, loading: false, employeeList };
    }
    case UPDATE_EMPLOYEE_SUCCESS:
    case INVITE_EMPLOYEE_SUCCESS: {
      const newEmployee = prepareEmployeeObject(action.results.profile);
      const newState = { ...state };
      const index = newState.employeeList.findIndex(e => e.id === newEmployee.id);
      newState.employeeList[index] = newEmployee;

      if (newState.employee && newState.employee.id === newEmployee.id) {
        newState.employee = newEmployee;
      }
      if (newState.employeeEditor && newState.employeeEditor.id === newEmployee.id) {
        newState.employeeEditor = newEmployee;
      }

      newState.updatedEmployeeId = newEmployee.id;

      return newState;
    }
    case GET_EMPLOYEE_LIST_FAILURE:
      return { ...state, loading: false };
    case GET_EMPLOYEE_REQUEST:
      return { ...state, loadingEmployee: true };
    case GET_EMPLOYEE_SUCCESS: {
      const employeeObj = action.results ? prepareEmployeeObject(action.results.profile) : null;

      const newState = { ...state, loadingEmployee: false };

      if (action.employeeId === 'self') {
        newState.rootUser = action.rootUser;
      }

      if (action.employeeEditor) {
        newState.employeeEditor = employeeObj;
        if (action.employeeId === 'self') {
          newState.employee = employeeObj;
        }
      } else {
        newState.employee = employeeObj;
      }
      return newState;
    }
    case GET_EMPLOYEE_FAILURE:
      return { ...state, loadingEmployee: false };
    case GET_EMPLOYEE_DASHBOARD_REQUEST:
      return { ...state, loadingDashboard: true };
    case GET_EMPLOYEE_DASHBOARD_SUCCESS: {
      return { ...state, loadingDashboard: false, dashboard: action.results };
    }
    case GET_EMPLOYEE_DASHBOARD_FAILURE:
      return { ...state, loadingDashboard: false };
    case UPDATE_USER_STATUS_SUCCESS: {
      const newState = { ...state };
      const index = newState.employeeList.findIndex(e => e.userId === action.userId);
      newState.employeeList[index].status = action.status;
      return newState;
    }
    case UPLOAD_AVATAR_REQUEST: {
      return { ...state, loadingAvatar: true };
    }
    case UPLOAD_AVATAR_FAILURE:
    case UPLOAD_AVATAR_SUCCESS: {
      return { ...state, loadingAvatar: false };
    }
    default:
      return state;
  }
}

export default employee;

import React from 'react';
import { InputOnChangeData } from 'semantic-ui-react';
import CareRoundButton from '../../CareRoundButton';
import HRCandidatesFilterForm from './HRCandidatesFilterForm';
import { AddCaregiverContainer, HeaderContainer } from './styles';

interface IProps {
  searchQuery: string;
  onSubmit: (params?: unknown) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
  handleEditorClick: () => void;
  loading: boolean;
  standaloneMode: boolean;
  headerText: string;
}

const HRCaregiverHeader: React.FC<IProps> = ({
  searchQuery = '',
  onSubmit,
  onChange,
  handleEditorClick,
  loading,
  standaloneMode,
  headerText,
}) => {
  const handleSubmit = () => {
    onSubmit();
  };
  return (
    <HeaderContainer>
      <h1>{headerText}</h1>
      <p>Search by</p>
      <HRCandidatesFilterForm
        searchValue={searchQuery}
        onSubmit={handleSubmit}
        onChange={onChange}
        loading={loading}
      />
      {standaloneMode && (
        <AddCaregiverContainer>
          <p>Add a New Caregiver</p>
          <CareRoundButton
            id='hr-caregivers-add-new-button'
            content='+'
            onClick={handleEditorClick}
          />
        </AddCaregiverContainer>
      )}
    </HeaderContainer>
  );
};

export default HRCaregiverHeader;

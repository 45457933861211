/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import moment from 'moment';
import {
  Modal,
  Dimmer,
  Header,
  Label,
  Button,
  Grid,
  Icon,
  List,
  Segment,
  Image,
} from 'semantic-ui-react';
import CareLoader from './subcomponents/CareLoader';
import { schedulerActions, caregiverActions } from '../actions';
import {
  formatTime,
  SHORT_DATE,
  MOMENT_SHORT_DATE,
  MOMENT_HOURS_CAPITAL,
  formatPhoneNumber,
  isFloat,
} from '../constants/Formats';
import { getShiftAssigmentStatusColor, PhoneTypes } from '../constants/DomainTypes';
import { getShiftStatusDetails } from '../constants/StatusMaps';

import AddressList from './subcomponents/AddressList';
import EnvironmentTab from './subcomponents/EnvironmentTab';
import CaregiverBox from './subcomponents/CaregiverBox';
import DetailsDropdown from './subcomponents/DetailsDropdown';
import Notes from './subcomponents/Notes';
import { googleMapURL } from '../constants/Constants';

import './Schedules.css';

const DetailViewMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap defaultZoom={14} defaultCenter={{ lat: props.lat, lng: props.lon }}>
      <Marker position={{ lat: props.lat, lng: props.lon }} icon='/MapPin.png' />
    </GoogleMap>
  ))
);

const WrapWithCol = ({ children }) => (
  <Grid.Row>
    <Grid.Column>{children}</Grid.Column>
  </Grid.Row>
);

WrapWithCol.propTypes = {
  children: PropTypes.node.isRequired,
};

const GreenIcon = ({ isGreen }) => (
  <Icon
    circular
    inverted
    color={isGreen ? 'green' : 'red'}
    size='small'
    name={isGreen ? 'checkmark' : 'remove'}
    className='ui right floated'
  />
);

GreenIcon.propTypes = {
  isGreen: PropTypes.bool.isRequired,
};

const AssignedCaregiver = ({ dispatch, shift, searchable, review }) => {
  const searchClick = history => {
    dispatch(caregiverActions.setTargetShifts([shift.id]));
    history.push('/home/caregivers');
  };

  if (!shift) return <span />;

  if (!shift.assignedCaregivers || !shift.assignedCaregivers.length) {
    if (moment().isBefore(shift.start)) {
      return (
        <span>
          <Segment basic textAlign='center' style={{ margin: '0' }}>
            <Route
              render={({ history }) => (
                <Button
                  id='shift-details-search-button'
                  basic
                  size='big'
                  disabled={!searchable}
                  onClick={() => searchClick(history)}
                  style={{
                    height: '40px',
                    width: '140px',
                    fontSize: '12px',
                    textAlign: 'center',
                    position: 'relative',
                    left: '21px',
                  }}
                >
                  {review ? 'Review' : 'Search'}
                </Button>
              )}
            />
          </Segment>
          {!review && (
            <Segment
              basic
              textAlign='center'
              style={{
                position: 'relative',
                left: '21px',
                fontSize: '10px',
                color: '#a9adb4',
                margin: '0',
              }}
            >
              {searchable
                ? 'This option will run a search against this shift information to find available caregivers'
                : 'Caregiver search is not available for shift with this status'}
            </Segment>
          )}
        </span>
      );
    }
    return <span>-</span>;
  }

  return shift.assignedCaregivers.map(cg => <CaregiverBox caregiver={cg} showRemove={false} />);
};

AssignedCaregiver.propTypes = {
  dispatch: PropTypes.func.isRequired,
  shift: PropTypes.shape(),
  searchable: PropTypes.bool,
  review: PropTypes.bool,
};

AssignedCaregiver.defaultProps = {
  shift: null,
  searchable: true,
  review: false,
};

const WrapColumns = ({ title, content }) => (
  <Grid.Row lang='line'>
    <Grid.Column width={5}>
      <Header size='tiny' floated='left' style={{ whiteSpace: 'nowrap' }}>
        {title}
      </Header>
    </Grid.Column>
    <Grid.Column width={11}>
      <Header size='tiny' className='icon-charcoal'>
        {content}
      </Header>
    </Grid.Column>
  </Grid.Row>
);

const shiftStatus = {
  '01': {
    value: <FormattedMessage id='shift.status.pending' />,
    prev: <FormattedMessage id='shift.status.pending.short' />,
    className: 'icon-gray',
    bgClass: 'bg-gray',
  },
  '02': {
    value: <FormattedMessage id='shift.status.confirmed' />,
    prev: <FormattedMessage id='shift.status.confirmed.short' />,
    className: 'icon-green',
    bgClass: 'bg-green',
  },
  '03': {
    value: <FormattedMessage id='shift.status.inProcess' />,
    prev: <FormattedMessage id='shift.status.inprocess.short' />,
    className: 'icon-pink',
    bgClass: 'bg-pink',
  },
  '04': {
    value: <FormattedMessage id='shift.status.closed' />,
    prev: <FormattedMessage id='shift.status.closed.short' />,
    className: 'icon-blue',
    bgClass: 'bg-blue',
  },
  '09': {
    value: <FormattedMessage id='shift.status.hold' />,
    prev: <FormattedMessage id='shift.status.hold.short' />,
    className: 'icon-orange',
    bgClass: 'bg-orange',
  },
  10: {
    value: <FormattedMessage id='shift.status.cancelled' />,
    prev: <FormattedMessage id='shift.status.cancelled.short' />,
    className: 'icon-red',
    bgClass: 'bg-red',
  },
};

const shiftStatusOptions = Object.keys(shiftStatus).map(statusKey => ({
  value: statusKey,
  text: shiftStatus[statusKey].value,
  key: statusKey,
}));

const cancellationOptions = intl => [
  { value: 'XL', key: 'XL', text: intl.formatMessage({ id: 'shift.cancellation.client' }) },
  { value: 'XC', key: 'XC', text: intl.formatMessage({ id: 'shift.cancellation.caregiver' }) },
];

WrapColumns.propTypes = {
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
};

const DetailsTabIntl = ({ dispatch, shift, onValueChange, editable, intl }) => {
  const statusDetails = getShiftStatusDetails(shift.status);
  const phones =
    shift.client.phones &&
    shift.client.phones.map((v, i) => {
      const title = `${PhoneTypes(v)}:`;
      const formattedNumber = formatPhoneNumber(v.number);
      return (
        <WrapColumns
          key={v.id ? `id_${v.id}` : `index_${i}_${v.type}`}
          title={title}
          content={formattedNumber}
        />
      );
    });

  return (
    <Grid className='detailGrid'>
      <Grid.Row>
        <Grid style={{ marginLeft: '0px', marginRight: '0px', marginBottom: '0px' }}>
          <Grid.Row lang='line' style={{ paddingBottom: '0' }}>
            <Grid.Column width={7}>
              <Header size='tiny'>Address:</Header>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header size='tiny' style={{ whiteSpace: 'nowrap' }}>
                Service being provided
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7}>
              <AddressList address={shift.address} />
            </Grid.Column>
            <Grid.Column width={7}>
              <List>
                <List.Item content={`${shift.service} - ${shift.serviceDescription}`} />
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Row>

      <Grid.Row lang='line'>
        <Grid.Column width={15}>
          <Header size='tiny'>Assigned Caregiver</Header>
          <AssignedCaregiver dispatch={dispatch} shift={shift} searchable={shift.actionable} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} style={{ fontSize: '10px', color: '#9b9b9b' }}>
          <FormattedMessage id='shift.status' />
        </Grid.Column>
        <Grid.Column
          style={{
            fontSize: '12px',
            color: '#4a4a4a',
          }}
        >
          {editable ? (
            <DetailsDropdown
              id='shift-dropdown-status'
              // header={<FormattedMessage id='shift.status' />}
              name='status'
              editable={editable}
              object={shift}
              options={shiftStatusOptions}
              onValueChange={onValueChange}
            />
          ) : (
            <div
              style={{
                paddingTop: '15px',
              }}
            >
              {statusDetails.value}
            </div>
          )}
        </Grid.Column>
      </Grid.Row>
      {editable && shift.status === '10' ? (
        <Grid.Row>
          <Grid.Column width={6} style={{ fontSize: '10px', color: '#9b9b9b' }}>
            <FormattedMessage id='shift.cancellation.reason' />
          </Grid.Column>
          <Grid.Column style={{ float: 'right' }}>
            {editable && shift.status === '10' ? (
              <DetailsDropdown
                id='shift-dropdown-cancel'
                // header={<FormattedMessage id='shift.cancellation.reason' />}
                name='cancellationCode'
                editable={editable}
                object={shift}
                error
                options={cancellationOptions(intl)}
                onValueChange={onValueChange}
              />
            ) : null}
          </Grid.Column>
        </Grid.Row>
      ) : null}
      <WrapColumns title='Shift ID:' content={shift.externalId} />
      <WrapColumns title='Payer:' content={shift.payerName ? shift.payerName : '-'} />
      {phones}
      <WrapColumns
        title='Last updated:'
        content={formatTime(shift.lastModified, undefined, SHORT_DATE)}
      />
      <Grid.Row>
        <Notes
          id='shift-details-notes'
          maxLength={1000}
          className='shift-notes'
          object={shift}
          editable={editable}
          onValueChange={onValueChange}
          placeHolder='Add any additional note for this schedule'
          shift
        />
      </Grid.Row>
    </Grid>
  );
};

const DetailsTab = injectIntl(DetailsTabIntl);

DetailsTab.defaultProps = {
  editable: false,
};

DetailsTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  shift: PropTypes.shape().isRequired,
  onValueChange: PropTypes.func.isRequired,
  editable: PropTypes.bool,
};

const AssignedTab = ({ dispatch, shift }) => (
  <span>
    <Grid padded='vertically' className='detailGrid assignedTab'>
      <WrapWithCol>
        <Header size='tiny'>Assigned Caregiver</Header>
        <div style={{ position: 'relative', left: '6px' }}>
          <AssignedCaregiver dispatch={dispatch} shift={shift} searchable={shift.actionable} />
        </div>
      </WrapWithCol>
      <Grid.Row>
        <Grid.Column width={6}>
          <Header size='tiny' style={{ whiteSpace: 'nowrap' }}>
            Invitation status:
          </Header>
        </Grid.Column>
        <Grid.Column width={8} style={{ padding: '0' }}>
          <Header size='tiny' className='requests'>
            {shift.invitationsSent} Requests Send
          </Header>
          <Header size='tiny' className={shift.invitationsAccepted > 0 ? 'accepted' : 'requests'}>
            {shift.invitationsAccepted} Accepted Requests
          </Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </span>
);

AssignedTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  shift: PropTypes.shape(),
};

AssignedTab.defaultProps = {
  shift: null,
};

class ScheduleDetails extends React.Component {
  constructor() {
    super();
    this.state = { activeTab: 'schedule' };

    this.tabClick = this.tabClick.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.dispatch(schedulerActions.getShift(this.props.shiftId));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.shift && !nextProps.shift.loading) {
      if (nextProps.shift.shift) {
        this.setState({
          shift: nextProps.shift.shift,
          original: {
            ...nextProps.shift.shift,
          },
          isChanged: false,
          isValid: false,
        });
      } else {
        this.props.onCloseClicked();
      }
    }
  }

  onValueChange(e, input) {
    if (!e) {
      return;
    }

    this.setState(s => {
      const setValue = (path, obj, value) => {
        const key = path.shift();
        if (path.length > 0) {
          setValue(path, obj[key], value);
        } else {
          // eslint-disable-next-line no-param-reassign
          obj[key] = value?.length || Number.isInteger(value) || isFloat(value) ? value : null;
        }
      };

      const newState = { ...s };
      setValue(input.name.split('.'), newState.shift, input.value);

      const isValid = newState.shift.status !== '10' || newState.shift.cancellationCode;
      const isChanged = JSON.stringify(newState.shift) !== JSON.stringify(newState.original);
      return Object.assign(newState, { isChanged, isValid });
    });
  }

  tabClick(e, input) {
    this.setState({
      activeTab: input.name,
    });
  }

  render() {
    const { shift, loading } = this.props.shift;
    const { dispatch } = this.props;
    const { activeTab, isChanged, isValid } = this.state;
    const editable = shift && shift.source !== 'sam' && shift.source !== 'hcp';

    if (loading || !shift) {
      return (
        <Dimmer active page>
          <CareLoader loading invert showText={false} />
        </Dimmer>
      );
    }
    const applyChangesClick = () => {
      this.props.dispatch(
        schedulerActions.updateShift({
          ...this.state.shift,
        })
      );
    };

    const className =
      shift.status === '09'
        ? getShiftStatusDetails(shift.status).bgClass
        : getShiftAssigmentStatusColor(shift.shiftAssigmentStatus).bgClass;

    return (
      <Modal
        id='shift-details-modal'
        closeOnDimmerClick={false}
        open
        closeIcon={<Image className='schedule-modal-close-icon' src='/icon_close.svg' />}
        dimmer
        onClose={this.props.onCloseClicked}
        className='scheduleDetails scrolling'
      >
        <Modal.Header className={`${className} top`}>
          <Header size='tiny' textAlign='center' className='scheduleHeader'>
            <div className='clientName'>{shift.client.clientName}</div>
            <Header.Subheader id='schedule-sub-header' size='tiny'>
              <div className='date'>
                {formatTime(shift.start, shift.timezone, MOMENT_SHORT_DATE)}
              </div>
              {shift.timezone && <div className='date'>{shift.timezone.name}</div>}
              <div>
                <Label className={`${className}-l1 l1`}>
                  {formatTime(shift.start, shift.timezone, MOMENT_HOURS_CAPITAL)}
                </Label>
                <span className='separator'>_</span>
                <Label className={`${className}-l2 l2`}>
                  {formatTime(shift.end, shift.timezone, MOMENT_HOURS_CAPITAL)}
                </Label>
              </div>
            </Header.Subheader>
          </Header>
        </Modal.Header>
        <DetailViewMap
          googleMapURL={googleMapURL}
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ position: 'relative', top: '-30px', height: '180px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          lat={shift.address.lat}
          lon={shift.address.lon}
        />
        <Button.Group fluid size='tiny' className='careTabs'>
          <Button
            id='shift-details-schedule-tab'
            className='careTab schedule-details-button'
            active={activeTab === 'schedule'}
            onClick={this.tabClick}
            name='schedule'
          >
            Schedule Details
          </Button>
          <Button
            id='shift-details-environment-tab'
            className='careTab'
            active={activeTab === 'environment'}
            onClick={this.tabClick}
            name='environment'
          >
            Environment
          </Button>
          <Button
            id='shift-details-assigned-tab'
            className='careTab'
            active={activeTab === 'assigned'}
            onClick={this.tabClick}
            name='assigned'
          >
            Assigned
          </Button>
        </Button.Group>
        <Modal.Content scrolling style={{ overflow: 'overlay' }}>
          <Grid centered>
            <Grid.Column width={15} className='shift-details-tabs-column'>
              {activeTab === 'schedule' && (
                <DetailsTab
                  dispatch={dispatch}
                  shift={shift}
                  onValueChange={this.onValueChange}
                  editable={editable}
                />
              )}
              {activeTab === 'environment' && <EnvironmentTab client={shift.client} />}
              {activeTab === 'assigned' && <AssignedTab dispatch={dispatch} shift={shift} />}
            </Grid.Column>
          </Grid>
        </Modal.Content>
        {editable ? (
          <Modal.Actions>
            <Grid centered className='buttonArea'>
              <Grid.Column width={10} textAlign='center'>
                <Button
                  className='shift-details-apply-button'
                  id='shift-details-apply-changes-button'
                  disabled={!(isChanged && isValid)}
                  onClick={applyChangesClick}
                >
                  Apply Changes
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Actions>
        ) : null}
      </Modal>
    );
  }
}

ScheduleDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  shiftId: PropTypes.number.isRequired,
  onCloseClicked: PropTypes.func.isRequired,
  shift: PropTypes.shape(),
};

ScheduleDetails.defaultProps = {
  shift: null,
};

const mapStateToProps = state => {
  const { shift } = state;
  return {
    shift,
  };
};

export default connect(mapStateToProps)(ScheduleDetails);

import React from 'react';
import PropTypes from 'prop-types';
import { Table, Icon, Form, Popup, Dropdown } from 'semantic-ui-react';
import '../TrainPref.css';
import { PreferenceCategories } from '../../constants/DomainTypes';
import { beginningSearch } from '../../constants/Formats';

const PreferencesTable = ({ data, onUpdate, onRemove, disciplines }) => {
  const header = [
    {
      id: 'preferences-table-header-name',
      key: 'name',
      width: '2',
      content: <div className='system-settings-header-wrap'>Name</div>,
    },
    {
      id: 'preferences-table-header-active',
      key: 'active',
      width: '1',
      content: <div className='system-settings-header-wrap'>Active</div>,
    },
    {
      id: 'preferences-table-header-disciplines',
      key: 'disciplines',
      width: '2',
      content: <div className='system-settings-header-wrap'>Applicable Disciplines</div>,
    },
    {
      id: 'preferences-table-header-category',
      key: 'category',
      width: '2',
      content: <div className='system-settings-header-wrap'>Category</div>,
    },
    {
      id: 'preferences-table-header-visibleInApp',
      key: 'visibleInApp',
      width: '1',
      content: <div className='system-settings-header-wrap'>Visible in App</div>,
    },
    {
      id: 'preferences-table-header-editableInApp',
      key: 'editableInApp',
      width: '1',
      content: <div className='system-settings-header-wrap'>Editable in App</div>,
    },
    {
      id: 'preferences-table-header-caregiverLabel',
      key: 'caregiverLabel',
      width: '2',
      content: <div className='system-settings-header-wrap'>Caregiver Label</div>,
    },
    {
      id: 'preferences-table-header-clientLabel',
      key: 'clientLabel',
      width: '2',
      content: <div className='system-settings-header-wrap'>Client Label</div>,
    },
    {
      id: 'preferences-table-header-notifyOnChange',
      key: 'notifyOnChange',
      width: '1',
      content: <div className='system-settings-header-wrap'>Notify on Change</div>,
    },
    { id: 'preferences-table-header-remove', key: 'remove', width: '1', content: '' },
  ];

  const categoryLabel = value =>
    PreferenceCategories[value] ? PreferenceCategories[value].label : value;

  const itemClass = item => {
    const classes = [];
    if (!item.removed && (item.created || item.updated)) {
      classes.push('table-item-updated');
    } else if (item.removed) {
      classes.push('table-item-removed');
    } else if (!item.global) classes.push('table-item-custom');

    if (!item.active) classes.push('table-item-inactive');

    return classes.join(' ');
  };

  const customLabelCell = (item, cellName) => {
    if (item.active) {
      if (item.global) {
        return item[cellName];
      }

      return (
        <input
          className='optional-label'
          size={30}
          maxLength={100}
          placeholder='Alt Label'
          defaultValue={item[cellName]}
          onChange={e => onUpdate(item.id, cellName, e.target.value)}
        />
      );
    }

    return '--';
  };

  const categoryCell = item => {
    if (item.global) {
      return categoryLabel(item.category);
    }

    if (item.active) {
      return (
        <Form.Dropdown
          id='preferences-table-category-dropdown'
          defaultValue={item.category}
          options={Object.keys(PreferenceCategories)
            .filter(x => PreferenceCategories[x].availableToCreate)
            .map(x => ({
              value: x,
              text: PreferenceCategories[x].label,
            }))}
          onChange={(_, { value }) => onUpdate(item.id, 'category', value)}
        />
      );
    }

    return '--';
  };

  return (
    <Table
      id='systemSettingsPreferencesTable'
      tableData={data}
      headerRow={header}
      renderBodyRow={x => ({
        className: itemClass(x),
        key: x.id,
        cells: [
          { key: 'name', content: x.label || x.name, className: 'name' },
          {
            key: 'active',
            content: (
              <Form.Checkbox
                toggle
                defaultChecked={x.active}
                onChange={(_, { checked }) => onUpdate(x.id, 'active', checked)}
              />
            ),
          },
          {
            key: 'disciplines',
            content: (
              <Dropdown
                id='preferences-table-disciplines-dropdown'
                className='preference-settings-dropdown'
                name='disciplines'
                placeholder='Disciplines'
                selection
                multiple
                search={beginningSearch}
                options={disciplines}
                value={x.disciplines || []}
                onChange={(_, { value }) => onUpdate(x.id, 'disciplines', value)}
              />
            ),
          },
          { key: 'category', content: categoryCell(x) },
          {
            key: 'visibleInApp',
            content: x.active ? (
              <Form.Checkbox
                className='round'
                defaultChecked={x.visibleInApp}
                onChange={(_, { checked }) => onUpdate(x.id, 'visibleInApp', checked)}
              />
            ) : (
              '--'
            ),
          },
          {
            key: 'editableInApp',
            content: x.active ? (
              <Form.Checkbox
                className='round'
                defaultChecked={x.editableInApp}
                onChange={(_, { checked }) => onUpdate(x.id, 'editableInApp', checked)}
              />
            ) : (
              '--'
            ),
          },
          { key: 'caregiverLabel', content: customLabelCell(x, 'caregiverLabel') },
          { key: 'clientLabel', content: customLabelCell(x, 'clientLabel') },
          {
            key: 'notifyOnChange',
            content: x.active ? (
              <Form.Checkbox
                toggle
                defaultChecked={x.notifyOnChange}
                onChange={(_, { checked }) => onUpdate(x.id, 'notifyOnChange', checked)}
              />
            ) : (
              '--'
            ),
          },
          {
            key: 'remove',
            content: (
              <div>
                {!x.global ? (
                  <Icon
                    id='preferences-table-remove-icon'
                    link
                    size='large'
                    name='trash'
                    onClick={() => onRemove(x.id, x.deleted)}
                  />
                ) : (
                  ''
                )}
                {x.created || x.updated || x.removed ? (
                  <Popup
                    trigger={<Icon name='question circle outline' />}
                    content='Updates have not been saved'
                    on='hover'
                    position='top left'
                    popperModifiers={{
                      preventOverflow: {
                        boundariesElement: 'offsetParent',
                      },
                    }}
                  />
                ) : (
                  ''
                )}
              </div>
            ),
          },
        ],
      })}
    />
  );
};

PreferencesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disciplines: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default PreferencesTable;

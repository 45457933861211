/* eslint-disable @typescript-eslint/no-this-alias */
const awsIot = require('aws-iot-device-sdk');
const uuid = require('uuid/v4');
const { backend } = require('./backend');

const iotCallback = () => {
  // if (err) {
  //   console.log(err);
  //   return;
  // }
  // console.log(r);
};

class IoT {
  constructor() {
    this.client = null;
  }

  disconnect() {
    // console.log('Disconnect IOT');
    if (this.client) {
      this.client.end();
      this.client = null;
    }
  }

  connect(
    topics,
    iotEndpoint,
    region,
    accessKey,
    secretKey,
    sessionToken,
    onMessage,
    onLifecycleEvent
  ) {
    const that = this;
    this.iotTopics = topics;

    this.client = awsIot.device({
      //      region,
      protocol: 'wss',
      accessKeyId: accessKey,
      secretKey,
      sessionToken,
      port: 443,
      host: iotEndpoint,
    });

    this.client.on('connect', () => {
      that.client.subscribe(that.iotTopics.subscribe);
      that.client.subscribe(that.iotTopics.webportal);
      onLifecycleEvent('connect', true);
    });

    this.client.on('message', (topic, messagePayload, payload) => {
      // console.log(`topic: ${topic}`);
      onMessage(topic, messagePayload, payload);
    });

    this.client.on('close', () => {
      onLifecycleEvent('close', false);
    });

    this.client.on('error', e => {
      onLifecycleEvent('error', false, e);
    });

    this.client.on('reconnect', () => {
      onLifecycleEvent('reconnect', true);
    });
    this.client.on('offline', () => {
      onLifecycleEvent('offline', false);
    });
  }

  sendMessage(messageContent, conversationId) {
    const topic = this.iotTopics.publish;
    const msgPayload = {
      type: 'message',
      conversationId,
      text: messageContent,
      messageId: uuid(),
    };
    this.client.publish(topic, JSON.stringify(msgPayload), null, iotCallback);
  }

  readAck(conversationId, timestamp) {
    if (this.iotTopics) {
      const topic = this.iotTopics.publish;
      const msgPayload = {
        type: 'readack',
        conversationId,
        timestamp: timestamp.toISOString(),
      };
      if (this.client) {
        this.client.publish(topic, JSON.stringify(msgPayload), null, iotCallback);
      }
    }
  }

  getActiveConversations() {
    const topic = [this.iotTopics.publish, '-', backend.authToken()].join('/');
    const msgPayload = {
      type: 'requestActiveConversations',
    };
    this.client.publish(topic, JSON.stringify(msgPayload), null, iotCallback);
  }

  getConversationInfo(channelId) {
    const topic = [this.iotTopics.publish, channelId, backend.authToken()].join('/');
    const msgPayload = {
      type: 'requestConversationInfo',
      channelId,
    };
    this.client.publish(topic, JSON.stringify(msgPayload), null, iotCallback);
  }
}

// eslint-disable-next-line import/prefer-default-export
export const iot = new IoT();

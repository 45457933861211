/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';
import { changeLogActions } from '../actions/changeLog';

export function openSaveFileDialog(params, filename, mimetype, activeTab, dispatch) {
  if (!params) return;

  if (activeTab && activeTab === 1) {
    const timezone = moment.tz.guess();
    Object.assign(params, { timezone });
    dispatch(changeLogActions.getShiftChangeLogCsv(params));
  } else {
    const blob =
      params.constructor !== Blob
        ? new Blob([params], { type: mimetype || 'application/octet-stream' })
        : params;

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
      return;
    }

    const lnk = document.createElement('a');
    const url = window.URL;

    if (mimetype) {
      lnk.type = mimetype;
    }

    lnk.download = filename || 'untitled';
    const objectURL = url.createObjectURL(blob);
    lnk.href = objectURL;
    lnk.dispatchEvent(new MouseEvent('click'));
    setTimeout(url.revokeObjectURL.bind(url, objectURL));
  }
}

/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import { debounce } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Header, Grid, Icon, Image, Form, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import CaregiverBox from './CaregiverBox';
import { avatarStyle, avatarUrl, PreferenceCategories } from '../../constants/DomainTypes';
import { caregiverActions } from '../../actions';

const GreenIcon = ({ isGreen }) => (
  <Icon
    circular
    className={`ui right floated ${isGreen ? 'overlay-pref-checked' : 'overlay-pref-unchecked'}`}
    size='small'
    name={isGreen ? 'checkmark' : 'remove'}
  />
);

GreenIcon.propTypes = {
  isGreen: PropTypes.bool.isRequired,
};

const SEARCH_INTERVAL_MS = 300;

class EnvironmentTabEditable extends React.Component {
  constructor(params) {
    super(params);

    this.onClientChange = this.onClientChange.bind(this);
  }

  // eslint-disable-next-line react/state-in-constructor
  state = {
    searchQuery: '',
    searchResults: null,
    excludedCaregivers: [],
    availableCaregivers: [],
    // eslint-disable-next-line react/no-unused-state
    selectedItems: [],
  };

  UNSAFE_componentWillMount() {
    const excluded = this.props.client.caregiverExclusion.map(e => ({
      value: e.id,
      text: e.caregiverName,
      id: e.id,
      key: e.id,
      image: { avatar: true, src: avatarUrl(e.avatar, e.gender) },
    }));
    this.setState({
      excludedCaregivers: excluded,
      searchQuery: '',
      availableCaregivers: excluded,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const results =
      this.state.searchQuery &&
      nextProps.clientEnvironmentTab &&
      nextProps.clientEnvironmentTab.results
        ? nextProps.clientEnvironmentTab.results.map(c => ({
            key: c.id,
            value: c.id,
            id: c.id,
            text: c.caregiverName,
            image: { avatar: true, src: avatarUrl(c.avatar, c.gender) },
          }))
        : null;

    let available = this.state.excludedCaregivers;
    if (results) {
      available = available.concat(results);
    }

    this.setState({
      searchResults: results,
      availableCaregivers: available,
    });
  }

  onSearchTextChange = (e, { searchQuery }) => {
    this.setState({ searchQuery });
    if (searchQuery && searchQuery.length > 2) {
      this.searchCaregivers();
    } else {
      this.setState({
        searchResults: null,
        availableCaregivers: this.state.excludedCaregivers,
      });
    }
  };

  onClientChange = (e, data) => {
    const { value } = data;
    const cgs = value.map(v => this.state.availableCaregivers.find(c => c.value === v));

    this.setState({
      excludedCaregivers: cgs,
      searchQuery: '',
      availableCaregivers: cgs,
      searchResults: null,
    });

    this.props.onValueChange(e, data);
  };

  // eslint-disable-next-line react/sort-comp
  searchCaregivers = debounce(() => {
    if (this.state.searchQuery) {
      this.props.dispatch(
        caregiverActions.searchCaregivers({
          name: this.state.searchQuery,
        })
      );
    }
  }, SEARCH_INTERVAL_MS);

  render() {
    const { searchResults, availableCaregivers, excludedCaregivers } = this.state;
    const { client, onPreferencesChange, exclusionsEditable } = this.props;

    const preferenceDropdowns = clientPreferences =>
      Object.keys(PreferenceCategories).map(category => {
        const all = clientPreferences ? clientPreferences.filter(x => x.category === category) : [];
        const options = all.map(x => ({ value: x.id, text: x.clientLabel || x.label }));
        const selected = all.filter(x => x.value === 'Y').map(x => x.id);

        if (options && options.length) {
          return (
            <Grid.Row>
              <Grid.Column width={16}>
                <Header size='tiny' className='headerText'>
                  {PreferenceCategories[category].clientTitle}:
                </Header>
                <Form>
                  <Form.Group grouped widths='equal'>
                    <Dropdown
                      id={`clientDetailsPreferencesDropdown_${category}`}
                      name='preferences'
                      placeholder={PreferenceCategories[category].caregiverTitle}
                      floating
                      selection
                      multiple
                      options={options}
                      defaultValue={selected}
                      onChange={(_, { value }) => onPreferencesChange(category, value)}
                    />
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
          );
        }

        return undefined;
      });

    const exclusionsUneditable = client.caregiverExclusion
      ? client.caregiverExclusion.map(t => (
          <div className='avatarWithName'>
            <Image
              avatar
              centered
              circular
              size='mini'
              floated='left'
              style={avatarStyle(t.avatar, t.gender)}
              src={avatarUrl(t.avatar, t.gender)}
            />
            <span>{t.caregiverName}</span>
          </div>
        ))
      : [];

    const selection = excludedCaregivers ? excludedCaregivers.map(t => t.value) : [];

    const exclusions = (
      <Dropdown
        fluid
        name='caregiverExclusion'
        floating
        selection
        multiple
        search
        upward
        onChange={this.onClientChange}
        noResultsMessage={searchResults !== null ? 'No matching results' : null}
        onSearchChange={this.onSearchTextChange}
        searchQuery={this.state.searchQuery}
        options={availableCaregivers}
        value={selection}
      />
    );

    const history = client.recentCaregivers
      ? client.recentCaregivers
          .slice(0, 4)
          .map(cg => <CaregiverBox caregiver={cg} showRemove={false} />)
      : [];

    return (
      <span className='prefsLists'>
        <Grid padded='vertically' className='detailGrid noBorderFirstRow'>
          {preferenceDropdowns(client.preferences)}
          <Grid.Row />
        </Grid>

        <Header size='tiny' className='headerText'>
          CAREGIVER HISTORY
        </Header>
        <div className='infoText'>
          Listed below are the most recent caregivers assigned to this client
        </div>
        <Grid padded='vertically' className='detailGrid caregiverHistory'>
          {history}
          {history.length === 0 && <span>--</span>}
        </Grid>
        <Header size='tiny' className='headerText'>
          CAREGIVER EXCLUSIONS
        </Header>
        <div className='infoText'>Caregivers this client has requested not to receive</div>
        <Grid padded='vertically' className='detailGrid'>
          {exclusionsEditable ? exclusions : exclusionsUneditable}
          {!exclusionsEditable && exclusionsUneditable.length === 0 && <span>--</span>}
        </Grid>
      </span>
    );
  }
}

EnvironmentTabEditable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  client: PropTypes.shape().isRequired,
  exclusionsEditable: PropTypes.bool.isRequired,
  onPreferencesChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  clientEnvironmentTab: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

const mapStateToProps = state => {
  const { clientEnvironmentTab } = state;
  return {
    clientEnvironmentTab,
  };
};

export default connect(mapStateToProps)(EnvironmentTabEditable);

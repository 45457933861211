import styled from 'styled-components';
import { Grid, Header } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';

export const RecordsTile = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 16px #00000022;
`;

export const RecordsHeader = styled(Header)`
  &&& {
    font-weight: bold;
    font-size: 16px;
    color: #364967;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
`;

export const RecordsSubHeader = styled(Header)`
  &&& {
    font-weight: normal;
    font-size: 14px;
    color: #4a4a4a;
    letter-spacing: 0.5px;
    line-height: 16px;
    margin-top: 0px;
  }
`;

export const RecordsGrid = styled(Grid)`
  width: 100%;
  font-size: 0.8rem;

  .ui.tiny.header {
    letter-spacing: 0.05rem !important;
    color: #9b9b9b;
    font-weight: normal;
    font-size: 10px;
  }

  .ui.selection.dropdown {
    font-size: 12px;
    font-weight: bold;
    color: #4a4a4a;
  }

  .ui.selection.dropdown .text.default {
    font-size: 12px;
    font-weight: normal !important;
    color: #9b9b9b !important;
  }

  .ui.disabled.selection.dropdown {
    opacity: 1;
    border: 0;
    padding: 0;

    .dropdown.icon::before {
      color: transparent;
    }
  }
`;

export const RecordsValueUneditable = styled.div`
  clear: both;
  font-size: 12px;
  font-weight: bold;
  color: #4a4a4a;
`;

export const RecordsForm = styled(Form)`
  .ui.tiny.header {
    letter-spacing: 0.05rem !important;
    color: #a9adb4;
    font-weight: 400 !important;
    font-size: 0.8rem;
  }

  .ui.input {
    input {
      border-top-width: 0px;
      border-left-width: 0;
      border-right-width: 0;
      padding: 0;
      padding-bottom: 0.25rem;
    }

    &.disabled {
      opacity: 1;

      input {
        border: 0;
      }
    }
  }

  .ui.form {
    .field {
      opacity: 1;
    }

    .field > label {
      color: #9b9b9b;
      letter-spacing: 0.05rem;
      opacity: 1;
      font-weight: 400;
      font-size: 11px;
    }

    .field > .ui.disabled.selection.dropdown {
      opacity: 1;
      border: 0;
      padding: 0;

      .dropdown.icon::before {
        color: transparent;
      }
    }
  }
`;

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import { connect } from 'react-redux';
import { Detector } from 'react-detect-offline';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal, Dimmer, Header, Button, Grid, Image } from 'semantic-ui-react';
import moment from 'moment';
import CareLoader from './subcomponents/CareLoader';
import { MOMENT_DOB, isFloat } from '../constants/Formats';
import { clientActions, alertActions } from '../actions';
import CareEditorTabs from './subcomponents/CareEditorTabs';
import CCEditorInfo from './CCEditorInfo';
import CCEditorAgency from './CCEditorAgency';
import './Common.css';
import './Clients.css';
import { config } from '../helpers';
import { GoBackButton } from '../styles/common';

class ClientEditor extends React.Component {
  constructor() {
    super();
    this.state = { activeTab: null, isValid: false, isChanged: false };

    this.closeDetailsClicked = this.closeDetailsClicked.bind(this);
    this.tabClick = this.tabClick.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.disableSubmitButton = this.disableSubmitButton.bind(this);
    this.enableSubmitButton = this.enableSubmitButton.bind(this);
    this.onAgencyChange = this.onAgencyChange.bind(this);
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.dispatch(clientActions.createClientClear());
    this.setState(() => ({
      activeTab: this.props.tab ? this.props.tab : 0,
      wasClientChanged: false,
      client: {
        address: { forceGeocoding: true },
        languages: [10],
      },
    }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.uiState) {
      this.setState({ uiState: nextProps.uiState });
    }

    if (nextProps.clientEditor && nextProps.clientEditor.done === true) {
      this.props.onCloseClicked(nextProps.clientEditor.client);
    }
  }

  onValueChange(e, input) {
    if (!e) {
      return;
    }

    this.setState(s => {
      const setValue = (path, obj, value) => {
        const key = path.shift();
        if (path.length > 0) {
          setValue(path, obj[key], value);
        } else {
          // eslint-disable-next-line no-param-reassign
          obj[key] =
            value != null && (value.length || Number.isInteger(value) || isFloat(value))
              ? value
              : undefined;
        }
      };

      const newState = { ...s };
      // eslint-disable-next-line no-underscore-dangle
      if (e._isAMomentObject) {
        setValue(['dob'], newState.client, e.format(MOMENT_DOB));
      } else if (input.type === 'checkbox') {
        setValue(input.name.split('.'), newState.client, input.checked ? 'Y' : 'N');
      } else {
        setValue(input.name.split('.'), newState.client, input.value);
      }

      const isChanged = JSON.stringify(newState.client) !== JSON.stringify(newState.original);
      // const isValid = this.validateInputs();
      return Object.assign(newState, { isChanged });
    });
  }

  onAgencyChange(e, data) {
    if (!e) {
      return;
    }

    this.setState(s => {
      const setValue = (path, obj, value) => {
        const key = path.shift();
        if (path.length > 0) {
          setValue(path, obj[key], value);
        } else {
          // eslint-disable-next-line no-param-reassign
          obj[key] = value?.length || Number.isInteger(value) ? value : null;
        }
      };
      const newState = { ...s };

      if (data) {
        if (data.name === 'primaryContact') {
          setValue(['primaryContactId'], newState.client, data.value);
        } else if (data.name === 'primaryManager') {
          setValue(['managerId'], newState.client, data.value);
        }
      }

      return Object.assign(newState, { isChanged: true });
    });
  }

  onBackButtonClick() {
    this.setState({ activeTab: 0 });
  }

  tabClick(e, input) {
    this.setState({
      activeTab: input.name,
    });
  }

  closeDetailsClicked() {
    this.props.onCloseClicked({ wasClientChanged: this.state.wasClientChanged });
  }

  disableSubmitButton() {
    this.setState({ isValid: false });
  }

  enableSubmitButton() {
    this.setState({ isValid: true });
  }

  render() {
    const { loading } = this.props.clientEditor;
    const { dispatch, uiState, languages } = this.props;
    const { activeTab, client, isChanged, isValid } = this.state;

    if (loading) {
      return (
        <Dimmer active page>
          <CareLoader loading={loading} invert showText={false} />
        </Dimmer>
      );
    }

    const applyChangesClick = () => {
      if (this.state.activeTab < 1) {
        this.setState(s => ({
          activeTab: s.activeTab + 1,
          isValid: false,
        }));
        return;
      }

      const newClient = {
        ...this.state.client,
        status: '02',
        startDate: moment.tz(null).format('YYYY-MM-DD'),
        languages: this.state.client.languages
          ? this.state.client.languages.map((x, i) => ({
              id: x,
              value: 'Y',
              primary: !!(i === 0),
            }))
          : undefined,
      };

      if (newClient.phones) {
        newClient.phones = [{ primary: true, type: 1, number: newClient.phones }];
      }

      if (newClient.address.lat != null && newClient.address.lon != null) {
        newClient.address.forceGeocoding = undefined;
      }

      dispatch(clientActions.createClient(newClient));
    };

    const offlineClick = () => {
      const error = new Error('Error no network connection');
      dispatch(alertActions.error(error, alertActions.REASON_NETWORK_CONNECTIVITY));
    };

    return (
      <Modal
        closeOnDimmerClick={false}
        open
        closeIcon={<Image src='/icon_close.svg' />}
        dimmer
        size='tiny'
        onClose={this.closeDetailsClicked}
        className='clientEditor'
        style={{ marginTop: '-46vh' }}
      >
        <Modal.Header>
          <Grid centered className='headerGrid'>
            <Grid.Column width={16} textAlign='center'>
              {activeTab === 1 ? (
                <div className='care-editor-header-container'>
                  <GoBackButton onClick={this.onBackButtonClick} />
                  <Header className='care-editor-header' size='tiny'>
                    ADD NEW CLIENT
                  </Header>
                </div>
              ) : (
                <Header size='tiny' className='care-editor-header'>
                  ADD NEW CLIENT
                </Header>
              )}
            </Grid.Column>
            <Grid.Column width={16} textAlign='center' className='careEditorTabContainer'>
              <CareEditorTabs
                labels={['Client Info', 'Agency Info']}
                activeTab={this.state.activeTab}
              />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content scrolling>
          {activeTab === 0 && (
            <CCEditorInfo
              dispatch={dispatch}
              languages={languages}
              disciplines={[]}
              values={client}
              type={CCEditorInfo.CLIENT}
              onValueChange={this.onValueChange}
              disableSubmitButton={this.disableSubmitButton}
              enableSubmitButton={this.enableSubmitButton}
            />
          )}
          {activeTab === 1 && (
            <CCEditorAgency
              isClientView
              uiState={uiState}
              values={client}
              onAgencyChange={this.onAgencyChange}
              disableSubmitButton={this.disableSubmitButton}
              enableSubmitButton={this.enableSubmitButton}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Grid centered className='buttonArea'>
            <Grid.Column width={10} textAlign='center'>
              <Detector
                render={({ online }) => (
                  <Button
                    id='client-editor-next-create-button'
                    disabled={!isChanged || !isValid || loading}
                    onClick={online ? applyChangesClick : offlineClick}
                  >
                    {this.state.activeTab === 1 ? 'Create Client Record' : 'Next'}
                  </Button>
                )}
                polling={{
                  url: config.OFFLINE_DETECTOR_POLLING_URL,
                  interval: 60000,
                  timeout: 15000,
                }}
              />
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
    );
  }
}

ClientEditor.defaultProps = {
  tab: 0,
};

ClientEditor.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uiState: PropTypes.shape().isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  clientEditor: PropTypes.shape().isRequired,
  onCloseClicked: PropTypes.func.isRequired,
  tab: PropTypes.number,
};

const mapStateToProps = state => {
  const { languages } = state.principal;
  const { clientEditor } = state;

  return {
    clientEditor,
    languages,
  };
};

export default connect(mapStateToProps)(ClientEditor);

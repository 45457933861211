import { IHRCandidatesFilter, IHRCaregiversFilter } from '../../../../reducers/types/HRFilters';

/* eslint-disable import/prefer-default-export */
export enum FilterSections {
  NAME_OR_ID = 'nameOrId',
  DISCIPLINES = 'disciplines',
  COMPLIANCES_ISSUES = 'compliances issues',
  VACCINATION_VALIDATION = 'vaccination validation',
  PRIMARY_CONTACT = 'primary contact',
  PRIMARY_MANAGER = 'primary manager',
  CAREGIVER_STATUS = 'caregiver status',
  CAREGIVER_SECONDARY_STATUS = 'secondary status',
  CANDIDATES_DISCIPLINES = 'candidates disciplines',
  CANDIDATES_COMPLIANCES = 'candidates compliances',
  CANDIDATES_PRIMARY_CONTACT = 'candidates primary contact',
  BLOCK_SHIFT = 'block shift',
}

export const displaySelectedOptions = (selection: number[]) => {
  const selectedOptions = selection.reduce((a, b) => {
    let counter = a;
    if (b) {
      counter += 1;
    }
    return counter;
  }, 0);
  return selectedOptions ? `(${selectedOptions})` : '';
};

export const findCaregiversActiveAccordion = (
  selectedCandidatesFilters: IHRCandidatesFilter,
  selectedCaregiversFilters: IHRCaregiversFilter,
  candidatesView?: boolean
) => {
  let newActiveAccordion: FilterSections[] = [];
  if (candidatesView) {
    const {
      disciplines: { disciplines: disciplineFilters },
      compliancesIssues: { eligibility, medical, documentation, training },
      primaryContact: { primaryContact },
      primaryManager: { primaryManager },
    } = selectedCandidatesFilters;
    const compliances = [...eligibility, ...medical, ...documentation, ...training];
    if (disciplineFilters.length) {
      newActiveAccordion = [...newActiveAccordion, FilterSections.CANDIDATES_DISCIPLINES];
    }
    if (compliances.length) {
      newActiveAccordion = [...newActiveAccordion, FilterSections.CANDIDATES_COMPLIANCES];
    }
    if (primaryContact.length || primaryManager.length) {
      newActiveAccordion = [...newActiveAccordion, FilterSections.CANDIDATES_PRIMARY_CONTACT];
    }
    return newActiveAccordion;
  }
  const {
    disciplines: { disciplines: disciplineFilters },
    compliancesIssues: { eligibility, medical, documentation, training },
    vaccinationValidation: { vaccine },
    primaryContact: { primaryContact },
    primaryManager: { primaryManager },
    caregiverStatus: { status },
    blockShift: { blockShift },
  } = selectedCaregiversFilters;
  const compliances = [...eligibility, ...medical, ...documentation, ...training];
  if (disciplineFilters.length) {
    newActiveAccordion = [...newActiveAccordion, FilterSections.DISCIPLINES];
  }
  if (compliances.length) {
    newActiveAccordion = [...newActiveAccordion, FilterSections.COMPLIANCES_ISSUES];
  }
  if (vaccine.length) {
    newActiveAccordion = [...newActiveAccordion, FilterSections.VACCINATION_VALIDATION];
  }
  if (primaryContact.length || primaryManager.length) {
    newActiveAccordion = [...newActiveAccordion, FilterSections.PRIMARY_CONTACT];
  }
  if (status.length) {
    newActiveAccordion = [...newActiveAccordion, FilterSections.CAREGIVER_STATUS];
  }
  if (blockShift) {
    newActiveAccordion = [...newActiveAccordion, FilterSections.BLOCK_SHIFT];
  }
  return newActiveAccordion;
};

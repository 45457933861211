import React from 'react';
import { Table, SemanticWIDTHS } from 'semantic-ui-react';
import moment from 'moment';

type HeaderCellProps = {
  id: string;
  key: string;
  width: SemanticWIDTHS;
  textAlign?: 'center' | 'left' | 'right';
  content: string;
  onClick(): void;
  sorted?: 'ascending' | 'descending';
};

export const RenderHeaderCell: React.FC<HeaderCellProps> = ({
  id,
  key,
  width,
  textAlign,
  content,
  onClick,
  sorted,
}): JSX.Element => {
  return (
    <Table.HeaderCell
      id={id}
      key={key}
      width={width}
      textAlign={textAlign}
      onClick={onClick}
      sorted={sorted}
    >
      {content}
    </Table.HeaderCell>
  );
};

export const formatDate = (date: string): string =>
  date ? moment(date).format('DD MMM YYYY') : '';

export type Sorted = 'ascending' | 'descending' | undefined;

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const sortReportTableData = (data: any, field: string, sorting: Sorted) => {
  if (!data) {
    return data;
  }

  return [...data].sort((a, b) => {
    let aVal = null;
    let bVal = null;
    switch (field) {
      case 'user':
        aVal = `${a.firstName}${a.lastName}`;
        bVal = `${b.firstName}${b.lastName}`;
        break;
      default:
        aVal = a[field];
        bVal = b[field];
    }
    aVal = aVal || '';
    bVal = bVal || '';

    if (aVal === bVal) return 0;
    if (sorting === 'descending') {
      if (aVal < bVal) return -1;
      return 1;
    }
    if (aVal > bVal) return -1;
    return 1;
  });
};

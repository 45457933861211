import {
  GET_APP_TOOL_CONFIG_REQUEST,
  GET_APP_TOOL_CONFIG_SUCCESS,
  GET_APP_TOOL_CONFIG_FAILURE,
  UPDATE_APP_TOOL_CONFIG_REQUEST,
  UPDATE_APP_TOOL_CONFIG_SUCCESS,
  UPDATE_APP_TOOL_CONFIG_FAILURE,
} from '../../constants/ActionTypes';

const initialState = {
  loadingApplicationToolConfig: false,
  applicationToolConfig: {},
};

function applicationTool(state = initialState, action) {
  switch (action.type) {
    case UPDATE_APP_TOOL_CONFIG_REQUEST:
    case GET_APP_TOOL_CONFIG_REQUEST: {
      return { ...state, loadingApplicationToolConfig: true };
    }
    case UPDATE_APP_TOOL_CONFIG_SUCCESS:
    case GET_APP_TOOL_CONFIG_SUCCESS: {
      return {
        ...state,
        loadingApplicationToolConfig: false,
        applicationToolConfig: action.applicationToolConfig,
      };
    }
    case UPDATE_APP_TOOL_CONFIG_FAILURE:
    case GET_APP_TOOL_CONFIG_FAILURE: {
      return { ...state, loadingApplicationToolConfig: false, applicationToolError: action.error };
    }
    default:
      return state;
  }
}

export default applicationTool;

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Grid, Input, Divider, Modal, Radio } from 'semantic-ui-react';
import { EventContent, VirtualEventContainer, EventAction } from './createEventStyles';
import { CCDropDown } from '../../../styles/commonStyled';
import { GreenButton } from '../../../styles/common';
import CareTimePicker from '../CareTimePicker';
import { eventActions, complianceSettingsActions } from '../../../actions';
import QuarterSelector from '../../QuarterSelector';
import hrHelper from '../../../helpers/hrHelper';
import { history } from '../../../helpers';
import '../../HRApp.css';

const { ClassRoomType } = hrHelper;
const moment = require('moment');

class CreateEventView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      draftEvent: props.draftEvent,
      addNewInstructorEnabled: false,
      virtualEvent: false,
      eligibleQuarter: '00',
      payableQuarter: '00',
    };
  }

  componentDidMount() {
    this.props.dispatch(complianceSettingsActions.getComplianceClassrooms());
    this.props.dispatch(complianceSettingsActions.getComplianceInstructors());
    this.checkForCalendarView();
  }

  checkForCalendarView = () => {
    if (this.props.draftEvent.blockId) {
      const { inServiceCourses } = this.props.principal;
      const { draftEvent } = this.state;

      if (!this.props.isCalendarView) {
        const { blockId } = this.props.draftEvent;
        const { start } = this.props.draftEvent;
        const { end } = this.props.draftEvent;
        const block = inServiceCourses.courses.find(c => c.id === blockId);
        const hours = block.hours ? block.hours : '0.5';
        Object.assign(draftEvent, {
          blockId,
          block,
          start,
          hours,
          end: block.hours ? start.add(hours, 'hours') : end,
          payableHours: hours,
          eligibleHours: hours,
        });
        this.setState({ draftEvent });
      } else {
        Object.assign(draftEvent, {
          blockId: null,
        });
        this.setState({ draftEvent });
      }
    }
  };

  render() {
    const { draftEvent, instructorsSearch, addNewInstructorEnabled, virtualEvent } = this.state;

    const {
      inServiceCourses,
      complianceClassrooms,
      complianceInstructors,
      createdInstructor,
      loadingComplianceInstructors,
    } = this.props.principal;

    const { employees, onClose } = this.props;

    const classRooms = complianceClassrooms
      ? complianceClassrooms.map(c => ({ text: c.name, value: c.id }))
      : [];
    const instructors = complianceInstructors
      ? [].concat(complianceInstructors.map(c => ({ text: c.name, value: c.id })))
      : [];
    const trainingCourses = inServiceCourses
      ? inServiceCourses.courses.map(c => ({ text: c.name, value: c.id }))
      : [];

    const virtualClassRoom = complianceClassrooms?.find(c => c.type === ClassRoomType.VIRTUAL);

    const employeeOptions = employees
      ? employees
          .map(e => ({ value: e.id, text: `${e.firstName} ${e.lastName}` }))
          .sort((a, b) => {
            if (a.text === b.text) {
              return 0;
            }
            if (a.text < b.text) {
              return -1;
            }
            return 1;
          })
      : [];

    classRooms.unshift({ text: '', value: null });
    instructors.unshift({ text: '', value: null });
    trainingCourses.unshift({ text: '', value: null });
    employeeOptions.unshift({ text: '', value: null });

    if (createdInstructor && draftEvent.instructor === undefined) {
      draftEvent.instructor = createdInstructor;
    }

    const addInstructor = () => {
      draftEvent.instructor = undefined;
      this.setState({ draftEvent, addNewInstructorEnabled: false });
      this.props.dispatch(
        complianceSettingsActions.createComplianceInstructor({ name: instructorsSearch })
      );
    };

    const createEvent = () => {
      const draft = Object.assign(draftEvent, {
        date: draftEvent.start,
      });
      this.props.dispatch(eventActions.createEvent(draft));
      if (this.props.location?.pathname !== '/home/hr/calendar') {
        history.push('/home/hr/calendar');
      }
    };

    const trainingCourseChanged = (e, input) => {
      if (!input.value) {
        const newDraft = {
          ...draftEvent,
          blockId: null,
          block: null,
          hours: 0,
          payableHours: 0,
          eligibleHours: 0,
        };
        return this.setState({ draftEvent: newDraft });
      }
      const blockId = input.value;
      const block = inServiceCourses.courses.find(c => c.id === blockId);
      const hours = block.hours
        ? block.hours
        : moment(draftEvent.end).diff(moment(draftEvent.start), 'hours', true);
      Object.assign(draftEvent, {
        blockId,
        block,
        hours,
        payableHours: hours,
        eligibleHours: hours,
      });
      return this.setState({ draftEvent });
    };

    const classRoomChanged = (e, input) => {
      if (!input.value) {
        const newDraft = {
          ...draftEvent,
          classRoom: null,
          classRoomId: null,
        };
        return this.setState({ draftEvent: newDraft });
      }
      const classRoomId = input.value;
      const classRoom = complianceClassrooms.find(c => c.id === classRoomId);
      Object.assign(draftEvent, {
        classRoom,
        classRoomId,
      });
      return this.setState({ draftEvent });
    };

    const instructorsChanged = (e, input) => {
      if (!input.value) {
        const newDraft = {
          ...draftEvent,
          instructor: null,
        };
        return this.setState({ draftEvent: newDraft });
      }
      const instructorId = input.value;
      Object.assign(draftEvent, {
        instructor: instructorId,
      });
      return this.setState({ draftEvent });
    };

    const employeeChanged = (e, input) => {
      if (!input.value) {
        const newDraft = {
          ...draftEvent,
          supervisor: null,
        };
        return this.setState({ draftEvent: newDraft });
      }
      const employeeId = input.value;
      Object.assign(draftEvent, {
        supervisor: employeeId,
      });
      return this.setState({ draftEvent });
    };

    const dateChanged = e => {
      const start = moment(draftEvent.start);
      start.set({
        date: e.get('date'),
      });
      if (start.isAfter(moment())) {
        this.setState({ draftEvent: Object.assign(draftEvent, { start: start.toDate() }) });
      } else {
        this.setState({ draftEvent });
      }
    };

    const startChanged = e => {
      const start = moment(draftEvent.start);
      start.set({
        hour: moment(e).get('hour'),
        minute: moment(e).get('minute'),
      });
      if (start.isAfter(moment())) {
        this.setState({
          draftEvent: Object.assign(draftEvent, {
            start: start.toDate(),
            hours: draftEvent.block.hours
              ? draftEvent.block.hours
              : moment(draftEvent.end).diff(moment(start), 'hours', true),
          }),
        });
      } else {
        this.setState({ draftEvent });
      }
    };

    const clearInstructorSearch = async () => {
      await new Promise(resolve => setTimeout(resolve, 100));
      this.setState({ instructorsSearch: undefined, addNewInstructorEnabled: false });
    };

    const onInstructorsSearch = (options, query) => {
      const regex = new RegExp(query, 'i');
      const res = options.filter(opt => regex.test(opt.text));
      const enabled = res.length === 0;
      if (
        this.state.addNewInstructorEnabled !== enabled ||
        this.state.instructorsSearch !== query
      ) {
        this.setState({ addNewInstructorEnabled: enabled, instructorsSearch: query });
      }
      return res;
    };

    const handleFocus = e => {
      e.target.select();
    };

    const convertToHundred = quarter => (quarter * 100) / 60;

    const onEligibleHoursChanged = (e, input) => {
      const { eligibleQuarter } = this.state;
      const newHour = input.value || '0';
      const valueToHundred = convertToHundred(parseInt(eligibleQuarter, 10));
      const floatHour = parseFloat(`${newHour}.${valueToHundred}`);
      draftEvent.eligibleHours = floatHour;
      this.setState({ draftEvent });
    };

    const onPayableHoursChanged = (e, input) => {
      const { payableQuarter } = this.state;
      const newHour = input.value || '0';
      const valueToHundred = convertToHundred(parseInt(payableQuarter, 10));
      const floatHour = parseFloat(`${newHour}.${valueToHundred}`);
      draftEvent.payableHours = floatHour;
      this.setState({ draftEvent });
    };

    const handleEligibleQuarterChange = value => {
      const { eligibleHours } = draftEvent;
      const newDraft = { ...draftEvent };
      const valueNumber = parseInt(value, 10);
      const valueToHundred = convertToHundred(valueNumber);
      newDraft.eligibleHours = parseFloat(`${eligibleHours || '0'}.${valueToHundred}`);
      this.setState({ draftEvent: newDraft, eligibleQuarter: value });
    };

    const handlePayableQuarterChange = value => {
      const { payableHours } = draftEvent;
      const newDraft = { ...draftEvent };
      const valueNumber = parseInt(value, 10);
      const valueToHundred = convertToHundred(valueNumber);
      newDraft.payableHours = parseFloat(`${payableHours || '0'}.${valueToHundred}`);
      this.setState({ draftEvent: newDraft, payableQuarter: value });
    };

    const endChanged = e => {
      const end = moment(draftEvent.start);
      end.set({
        hour: moment(e).get('hour'),
        minute: moment(e).get('minute'),
      });
      if (end.isAfter(moment(draftEvent.start))) {
        this.setState({
          draftEvent: Object.assign(draftEvent, {
            end: end.toDate(),
            hours: moment(end).diff(moment(draftEvent.start), 'hours', true),
          }),
        });
      } else {
        this.setState({ draftEvent });
      }
    };

    const onVirtualEventChange = () => {
      const newVirtualEvent = !virtualEvent;
      if (newVirtualEvent) {
        const newDraft = {
          ...draftEvent,
          classRoom: virtualClassRoom,
          classRoomId: virtualClassRoom.id,
        };
        return this.setState({ virtualEvent: newVirtualEvent, draftEvent: newDraft });
      }
      const newDraft = {
        ...draftEvent,
        classRoom: undefined,
        classRoomId: null,
      };
      return this.setState({ virtualEvent: newVirtualEvent, draftEvent: newDraft });
    };

    return (
      <Modal
        closeOnDimmerClick={false}
        open
        closeIcon
        className='hrapp create-event-modal'
        onClose={onClose}
      >
        <Modal.Header>
          <h3 className='create-event-header'>Define Your Event</h3>
          <Divider />
        </Modal.Header>
        <EventContent>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <h4 className='create-event-sub-header'>EVENT TYPE</h4>
                <p className='create-event-small-gray-text'>
                  Your selection of event will determine event duration and set your event ending
                  time.
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <CCDropDown
                  id='create-event-modal-course-dropdown'
                  options={trainingCourses}
                  selection
                  onChange={trainingCourseChanged}
                  fluid
                  placeholder='Select Course'
                  value={draftEvent ? draftEvent.blockId : null}
                />
              </Grid.Column>
            </Grid.Row>
            {virtualClassRoom && (
              <Grid.Row>
                <Grid.Column>
                  <VirtualEventContainer>
                    <p className='create-event-dark-text virtual-text'>Is this event virtual?</p>
                    <Radio toggle onChange={onVirtualEventChange} checked={virtualEvent} />
                  </VirtualEventContainer>
                </Grid.Column>
              </Grid.Row>
            )}
            <Divider />
            <Grid.Row>
              <Grid.Column>
                <h4 className='create-event-sub-header'>EVENT TIME</h4>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className='create-event-dates-container'>
                  <div>
                    <p className='create-event-label'>Date</p>
                    <DatePicker
                      id='trainingDate'
                      popperPlacement='top-start'
                      customInput={
                        <Input icon='calendar' iconPosition='right' style={{ width: '100px' }} />
                      }
                      placeholder='Select Date'
                      selected={moment(draftEvent ? draftEvent.start : null)}
                      onChange={dateChanged}
                      dateFormat='MM/DD/YY'
                    />
                  </div>
                  <div>
                    <p className='create-event-label'>Begins</p>
                    <CareTimePicker
                      value={moment(draftEvent ? draftEvent.start : null)}
                      disabled={!draftEvent.block}
                      onChange={startChanged}
                    />
                  </div>
                  <div>
                    <p className='create-event-label'>End</p>
                    {!draftEvent || !draftEvent.block ? (
                      <p className='create-event-dark-text virtual-text'>
                        End will be determined by event type.
                      </p>
                    ) : (
                      <CareTimePicker
                        disabled={!!draftEvent.block.hours}
                        value={moment(
                          draftEvent.block.hours
                            ? moment(draftEvent.start).add(draftEvent.block.hours, 'hours')
                            : draftEvent.end
                        )}
                        onChange={endChanged}
                      />
                    )}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className='create-event-hours-container'>
                  <div>
                    <p className='create-event-label'>In-Service hours</p>
                    <p className='create-event-small-gray-text'>
                      Hours contributing to the in-service training annual quota.
                    </p>
                    <Input
                      id='create-event-modal-eligible-hours-input'
                      type='number'
                      className='create-event-input'
                      onFocus={handleFocus}
                      step={1}
                      max={24}
                      min={0}
                      value={parseInt(draftEvent.eligibleHours, 10)}
                      onChange={onEligibleHoursChanged}
                    />
                    <span>&nbsp;hours&nbsp;</span>
                    <QuarterSelector onChange={handleEligibleQuarterChange} />
                  </div>
                  <div>
                    <p className='create-event-label'>Payable hours</p>
                    <p className='create-event-small-gray-text'>
                      Reimbursable hours for an attending caregiver.
                    </p>
                    <Input
                      id='create-event-modal-payable-hours-input'
                      type='number'
                      className='create-event-input'
                      onFocus={handleFocus}
                      step={1}
                      max={24}
                      min={0}
                      value={parseInt(draftEvent.payableHours, 10)}
                      onChange={onPayableHoursChanged}
                    />
                    <span>&nbsp;hours&nbsp;</span>
                    <QuarterSelector onChange={handlePayableQuarterChange} />
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column>
                <div>
                  <h4 className='create-event-sub-header'>Room</h4>
                  <p className='create-event-small-gray-text'>
                    Your selection of room will determine the capacity for the attendees.
                  </p>
                  <CCDropDown
                    id='create-event-modal-class-room-dropdown'
                    options={classRooms}
                    selection
                    onChange={classRoomChanged}
                    fluid
                    placeholder='Select Class Room'
                    disabled={virtualEvent}
                    value={draftEvent ? draftEvent.classRoomId : null}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column>
                <div>
                  <h4 className='create-event-sub-header'>Instructor</h4>
                  <p className='create-event-small-gray-text'>
                    You can select multiple instructors.
                  </p>
                  {
                    // if we want this to become multi selector add prop multiple
                  }
                  <div style={{ display: 'flex', width: '100%' }}>
                    <CCDropDown
                      id='create-event-modal-instructor-dropdown'
                      search={onInstructorsSearch}
                      options={instructors}
                      selection
                      onChange={instructorsChanged}
                      fluid
                      disabled={loadingComplianceInstructors}
                      onClose={clearInstructorSearch}
                      placeholder='Select instructor OR type to add a new instructor'
                      allowAdditions={addNewInstructorEnabled}
                      value={draftEvent ? draftEvent.instructor : null}
                      onAddItem={addInstructor}
                    />
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column>
                <div>
                  <h4 className='create-event-sub-header'>Assigned Supervisor</h4>
                  <CCDropDown
                    id='create-event-modal-supervisor-dropdown'
                    options={employeeOptions}
                    selection
                    onChange={employeeChanged}
                    fluid
                    placeholder='Select Supervisor'
                    value={draftEvent ? draftEvent.supervisor : null}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </EventContent>
        <EventAction>
          {/* 
            we can change instructor validation to length if multi selection is added  
            */}
          <GreenButton
            id='create-event-modal-create-button'
            disabled={
              !(
                draftEvent &&
                draftEvent.blockId &&
                draftEvent.hours > 0 &&
                (draftEvent.classRoom?.type === ClassRoomType.VIRTUAL || draftEvent.supervisor) &&
                draftEvent.instructor &&
                draftEvent.classRoomId &&
                (draftEvent.classRoom?.type === ClassRoomType.VIRTUAL ||
                  draftEvent.payableHours > 0) &&
                (draftEvent.classRoom?.type === ClassRoomType.VIRTUAL ||
                  draftEvent.eligibleHours > 0)
              )
            }
            content='Next'
            onClick={() => createEvent('in-service')}
          />
        </EventAction>
      </Modal>
    );
  }
}

CreateEventView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  principal: PropTypes.shape().isRequired,
  employees: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
  draftEvent: PropTypes.shape().isRequired,
  isCalendarView: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => {
  const { principal, employee } = state;
  return {
    principal,
    employees: employee.employeeList,
  };
};

export default connect(mapStateToProps)(CreateEventView);

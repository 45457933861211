/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react';
import { Offline } from 'react-detect-offline';
import styled, { StyledComponent } from 'styled-components';
import {
  Dropdown,
  Menu,
  Image,
  MenuItemProps,
  DropdownProps,
  Popup,
  Button,
} from 'semantic-ui-react';
import { userActions, uiStateActions } from '../actions';
import { history } from '../helpers';
import { Employee, Roles, Settings, User } from '../reducers/types/principal';
import {
  isAdmin,
  isHRManager,
  isScheduler,
  isTrainee,
  activeModeTab,
  ApplicationModes,
  avatarUrl,
} from '../constants/DomainTypes';
import './TopMenu.scss';
import { config } from '../helpers/config';

const HELP_URL = 'https://intercom.help/careconnectmobile-answers';

interface IAgencies {
  name: string;
  id: number;
}

interface IProps {
  heartbeat: { isBroken: boolean };
  employee: Employee;
  user: User;
  roles: Roles;
  settings: Settings;
  agencies: Array<IAgencies>;
  mode: string;
  subTab: string;
  dispatch: (arg: any) => void;
}

interface IState {
  settingsOpen: boolean;
}

interface ISubTabProps {
  nav?: string;
}

const TopContainer = styled.div`
  width: 100vw;
`;

const MenuContainer = styled(Menu)`
  &&&& {
    margin: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #dcdcdc;
  }
`;

const SubMenuContainer = styled(Menu)`
  &&&& {
    display: flex;
    place-items: center;
    margin: 0;
    border: none;
    background: linear-gradient(180deg, #f5f5f5 0%, #e7e7e7 100%);
  }
`;

const MenuDivider = styled.div`
  width: 1px;
  height: 10px;
  background-color: #ababab;
`;

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #04a5d5;
  margin-top: 1rem;
`;

/* eslint-disable-next-line @typescript-eslint/ban-types */
const ModeItem: StyledComponent<React.ComponentClass<MenuItemProps, any>, any, {}, never> = styled(
  Menu.Item
)`
  &&& {
    position: relative;
    top: 1px;
    display: flex;
    flex-direction: column;
    place-items: center;
    padding: 1.5em 16px 0 16px;
    max-width: 180px;
    height: 60px;
    background-color: ${props => (props.active ? '#F5F5F5 !important' : '#ffffff')};
    color: ${props => (props.active ? '#4a4a4a !important' : '#9b9b9b')};
    box-shadow: ${props =>
      props.active
        ? 'inset 0px 3px #04a5d5, 0px -1px #04a5d5, inset -1px 0px #dcdcdc,inset 1px 0px #dcdcdc !important'
        : 'inset 0px -1px #dcdcdc !important'};
  }
`;

const SubMenuItem: StyledComponent<
  React.ComponentClass<MenuItemProps, any>,
  any,
  ISubTabProps,
  never
> &
  ISubTabProps = styled(Menu.Item)`
  &&&&& {
    display: flex;
    place-items: center;
    padding: 1em 16px;
    max-width: 280px;
    height: 30px;
    background: transparent;
    color: ${props => (props.active ? '#4a4a4a !important' : '#9b9b9b')};
    box-shadow: ${props => (props.active ? '0px 2px #04a5d5' : '')};
    border-left: ${props => (props.active ? '1px solid #ebebeb' : '')};
    border-right: ${props => (props.active ? '1px solid #ebebeb' : '')};
  }
`;

const AgencyLogo = styled.img`
  max-width: 100%;
  max-height: 32.2px;
  display: block;
  border-radius: 5px;
  image-rendering: optimizeQuality;
`;

const PopupButton = styled.div`
  display: flex;
  place-items: center;
  border-bottom: 1px solid #ebebeb;
  padding: 1em;
  cursor: pointer;
`;

const PopupImage = styled.img`
  margin-right: 1em;
`;

const PopupText = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: #4a4a4a;
`;

class TopMenu extends Component<IProps, IState> {
  public state: IState = {
    settingsOpen: false,
  };

  public componentDidMount(): void {
    const { dispatch } = this.props;
    dispatch(userActions.getAgencies());
  }

  private readonly logOut = () => {
    const { dispatch } = this.props;
    this.setState({ settingsOpen: false });
    dispatch(userActions.logout());
    history.push('/login');
  };

  private readonly handleBackHome = () => {
    const { mode } = this.props;
    switch (mode) {
      case ApplicationModes.scheduler:
        return history.push('/home');
      case ApplicationModes.hr:
        return history.push('/home/hr');
      case ApplicationModes.training:
        return history.push('/home/e-learning');
      case ApplicationModes.eLearningMgmt:
        return history.push('/home/e-learning/manage');
      default:
        return history.push('/home');
    }
  };

  private readonly handleModeChange = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const { dispatch } = this.props;
    const { id } = e.currentTarget;
    switch (id) {
      case ApplicationModes.scheduler:
        dispatch(
          uiStateActions.setInputGroupState('application', {
            mode: ApplicationModes.scheduler,
          })
        );
        history.push('/home');
        break;
      case ApplicationModes.hr:
        dispatch(
          uiStateActions.setInputGroupState('application', {
            mode: ApplicationModes.hr,
          })
        );
        history.push('/home/hr');
        break;
      case ApplicationModes.eLearningMgmt:
        dispatch(
          uiStateActions.setInputGroupState('application', {
            mode: ApplicationModes.eLearningMgmt,
          })
        );
        history.push('/home/e-learning/manage');
        break;
      case ApplicationModes.training:
        dispatch(
          uiStateActions.setInputGroupState('application', {
            mode: ApplicationModes.training,
          })
        );
        history.push('/home/e-learning');
        break;
      case ApplicationModes.embedReports:
        dispatch(
          uiStateActions.setInputGroupState('application', {
            mode: ApplicationModes.embedReports,
          })
        );
        history.push('/home/reports/compliance');
        break;
      default:
        history.push('/home');
        break;
    }
  };

  private readonly openProfile = () => {
    this.setState({ settingsOpen: false });
    history.push('/home/profile');
  };

  private readonly openSettings = () => {
    this.setState({ settingsOpen: false });
    history.push('/home/systemsettings');
  };

  private readonly handleSubTabClick = (
    _e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    data: MenuItemProps
  ) => {
    history.push(data.nav);
  };

  private readonly renderSubMenu = () => {
    const { mode, subTab, roles, settings } = this.props;
    const { complianceEnabled, virtualTrainingsEnabled } = settings || {};

    const disabledHrSubMenus = {
      applicants: !complianceEnabled,
      candidates: !complianceEnabled,
      caregivers: !complianceEnabled,
      calendar: !complianceEnabled && !virtualTrainingsEnabled,
    };

    const calcPadding = () => {
      switch (mode) {
        case ApplicationModes.hr:
          if (roles.includes('scheduler')) {
            return '180px';
          }
          return '100px';
        case ApplicationModes.training:
          if (isScheduler(roles) && !isHRManager(roles)) {
            return '350px';
          }
          if (isHRManager(roles) && !isScheduler(roles)) {
            return '330px';
          }
          if (isHRManager(roles) && isScheduler(roles) && !isAdmin(roles)) {
            return '450px';
          }
          return '595px';
        default:
          return '140px';
      }
    };

    switch (mode) {
      case ApplicationModes.scheduler:
        return (
          <SubMenuContainer
            fluid
            borderless
            attached='top'
            className='topMenu'
            style={{ paddingLeft: calcPadding() }}
          >
            <SubMenuItem
              active={subTab === activeModeTab.shifts}
              tab={activeModeTab.shifts}
              nav='/home/shifts'
              onClick={this.handleSubTabClick}
            >
              Shifts
            </SubMenuItem>
            <MenuDivider />
            <SubMenuItem
              active={subTab === activeModeTab.clients}
              nav='/home/clients'
              onClick={this.handleSubTabClick}
            >
              Clients
            </SubMenuItem>
            <MenuDivider />
            <SubMenuItem
              active={subTab === activeModeTab.caregivers}
              nav='/home/caregivers'
              onClick={this.handleSubTabClick}
            >
              Caregivers
            </SubMenuItem>
            <MenuDivider />
            <SubMenuItem
              active={subTab === activeModeTab.changeLog}
              nav='/home/changelog'
              onClick={this.handleSubTabClick}
            >
              Change Logs
            </SubMenuItem>
          </SubMenuContainer>
        );
      case ApplicationModes.hr:
        return (
          <SubMenuContainer
            fluid
            borderless
            attached='top'
            className='topMenu'
            style={{ paddingLeft: calcPadding() }}
          >
            <SubMenuItem
              active={subTab === activeModeTab.hrApplicants}
              nav='/home/hr/applicants'
              onClick={this.handleSubTabClick}
              disabled={disabledHrSubMenus.applicants}
            >
              Applicants
            </SubMenuItem>
            <MenuDivider />
            <SubMenuItem
              active={subTab === activeModeTab.hrCandidates}
              nav='/home/hr/candidates'
              onClick={this.handleSubTabClick}
              disabled={disabledHrSubMenus.candidates}
            >
              Candidates
            </SubMenuItem>
            <MenuDivider />
            <SubMenuItem
              active={subTab === activeModeTab.hrCaregivers}
              nav='/home/hr/caregivers'
              onClick={this.handleSubTabClick}
              disabled={disabledHrSubMenus.caregivers}
            >
              Caregivers
            </SubMenuItem>
            <MenuDivider />
            <SubMenuItem
              active={subTab === activeModeTab.hrCalendar}
              nav='/home/hr/calendar'
              onClick={this.handleSubTabClick}
              disabled={disabledHrSubMenus.calendar}
            >
              Calendar
            </SubMenuItem>
          </SubMenuContainer>
        );
      case ApplicationModes.eLearningMgmt:
        return (
          <SubMenuContainer
            fluid
            borderless
            attached='top'
            className='topMenu'
            style={{ paddingLeft: '370px' }}
          >
            <SubMenuItem
              active={subTab === activeModeTab.ELUserDirectory}
              nav='/home/e-learning/manage'
              onClick={this.handleSubTabClick}
            >
              E-Learning User Directory
            </SubMenuItem>
            <MenuDivider />
            <SubMenuItem
              active={subTab === activeModeTab.ELReports}
              nav='/home/e-learning/reports'
              onClick={this.handleSubTabClick}
            >
              Reports
            </SubMenuItem>
            {/* <MenuDivider />
            // ! Certificates Export is part of EmbedReports and will need refactor to include this tab
            <SubMenuItem>Certificates Export</SubMenuItem> */}
          </SubMenuContainer>
        );
      case ApplicationModes.training:
        return (
          <SubMenuContainer
            fluid
            borderless
            attached='top'
            className='topMenu'
            style={{ paddingLeft: calcPadding() }}
          >
            <SubMenuItem
              active={subTab === activeModeTab.ELCourses}
              nav='/home/e-learning/courses'
              onClick={this.handleSubTabClick}
            >
              Your Courses
            </SubMenuItem>
            {/* <MenuDivider />
            // ! Completed Courses is part of Courses and will need refactor to include this tab
            <SubMenuItem>Completed Courses</SubMenuItem> */}
          </SubMenuContainer>
        );
      case ApplicationModes.embedReports:
        return (
          <SubMenuContainer
            fluid
            borderless
            attached='top'
            className='topMenu'
            style={{ paddingLeft: calcPadding() }}
          >
            <SubMenuItem
              active={subTab === activeModeTab.embedComplianceReports}
              nav='/home/reports/compliance'
              onClick={this.handleSubTabClick}
            >
              Compliance
            </SubMenuItem>
          </SubMenuContainer>
        );
      default:
        return <></>;
    }
  };

  private readonly switchAgency = (
    _e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    const { dispatch } = this.props;
    if (data.value) {
      dispatch(
        userActions.switchAgency({
          agencyId: data.value,
        })
      );
    }
  };

  public render(): JSX.Element {
    const { heartbeat, mode, roles, employee, settings, user, agencies } = this.props;
    const { settingsOpen } = this.state;

    const employeeRole = isAdmin(roles) || isHRManager(roles) || isScheduler(roles);

    const schedulerVisible = isAdmin(roles) || isScheduler(roles);
    const hrVisible = isAdmin(roles) || isHRManager(roles);
    const eLMngmtVisible = isAdmin(roles) && isTrainee(roles);
    const yourELearningVisible = (isAdmin(roles) && isTrainee(roles)) || isTrainee(roles);
    const embedReportsVisible = isAdmin(roles) || isScheduler(roles) || isHRManager(roles);

    const { agencyLogoUrl, agencyName } = settings || {};

    const [agencyId] = agencies ? agencies.filter(a => a.name === agencyName).map(a => a.id) : [0];
    return (
      <TopContainer>
        <Offline
          polling={{
            url: config.OFFLINE_DETECTOR_POLLING_URL,
            interval: 60000,
            timeout: 15000,
          }}
        >
          <div className='no-network-connection-bar'>
            <div className='antenna-container'>
              <div className='waves' />
              <div className='waves1' />
              <div className='waves2' />
              <div className='waves3' />
              <div className='waves4' />
              <div className='antenna' />
              <div className='slash' />
            </div>
            <p className='no-connection-text'>Network Connection Lost</p>
          </div>
        </Offline>
        {heartbeat && heartbeat.isBroken && (
          <div className='no-notification-connection-bar'>
            <p className='no-notifications-text'>
              Web notifications connection lost. Please refresh page
            </p>
          </div>
        )}
        <>
          <MenuContainer fluid borderless attached='top' className='topMenu'>
            <Menu.Item header exact onClick={this.handleBackHome}>
              <Image spaced='right' src='/logo_careconnect.svg' style={{ weight: '220px' }} />
            </Menu.Item>
            {schedulerVisible && (
              <ModeItem
                id={ApplicationModes.scheduler}
                active={mode === ApplicationModes.scheduler}
                onClick={this.handleModeChange}
              >
                Shift Booking
                {mode === ApplicationModes.scheduler && <ArrowDown />}
              </ModeItem>
            )}
            {hrVisible && (
              <ModeItem
                id={ApplicationModes.hr}
                active={mode === ApplicationModes.hr}
                onClick={this.handleModeChange}
              >
                HR Mgmt
                {mode === ApplicationModes.hr && <ArrowDown />}
              </ModeItem>
            )}
            {eLMngmtVisible && (
              <ModeItem
                id={ApplicationModes.eLearningMgmt}
                active={mode === ApplicationModes.eLearningMgmt}
                onClick={this.handleModeChange}
              >
                E-learning Mgmt
                {mode === ApplicationModes.eLearningMgmt && <ArrowDown />}
              </ModeItem>
            )}
            {yourELearningVisible && (
              <ModeItem
                id={ApplicationModes.training}
                active={mode === ApplicationModes.training}
                onClick={this.handleModeChange}
              >
                Your E-learning
                {mode === ApplicationModes.training && <ArrowDown />}
              </ModeItem>
            )}
            {embedReportsVisible && (
              <ModeItem
                id={ApplicationModes.embedReports}
                active={mode === ApplicationModes.embedReports}
                onClick={this.handleModeChange}
              >
                Business Intelligence
                {mode === ApplicationModes.embedReports && <ArrowDown />}
              </ModeItem>
            )}
            {employeeRole && (
              <Menu.Item
                id='menuTopHelpCenter'
                href={HELP_URL}
                target='_blank'
                className='helpCenter'
                position='right'
              >
                <div className='text'>
                  <span className='icon'>?</span>Help Center
                </div>
              </Menu.Item>
            )}
            {(agencyLogoUrl || agencyName) && (
              <Menu.Item className='logo-menu-item'>
                <div className='logo-name-container'>
                  {agencyLogoUrl ? (
                    <AgencyLogo src={agencyLogoUrl} />
                  ) : (
                    <AgencyLogo src='/android-chrome-72x72.png' />
                  )}
                  {agencies?.length > 1 && (
                    <Dropdown
                      inline
                      options={agencies.map(agency => ({
                        key: agency.id,
                        text: agency.name,
                        value: agency.id,
                      }))}
                      onChange={this.switchAgency}
                      value={agencyId}
                    />
                  )}
                  <h6 className='agency-name'>{agencyName}</h6>
                </div>
              </Menu.Item>
            )}
            <Menu.Item className='profileItem'>
              <Popup
                id='menuTopUserDropdown'
                onOpen={() => this.setState({ settingsOpen: true })}
                onClose={() => this.setState({ settingsOpen: false })}
                open={settingsOpen}
                hideOnScroll
                position='bottom right'
                trigger={
                  <span className='profile'>
                    {employee && (
                      <div className='profileColumn'>
                        {employee?.avatar?.url && (
                          <Image
                            className='employee-avatar'
                            src={avatarUrl(employee?.avatar, employee?.gender)}
                            circular
                          />
                        )}
                        <div>
                          <p className='userName'>
                            {employee.firstName} {employee.lastName}
                          </p>
                          <p className='role'>{employee.roleName}</p>
                        </div>
                      </div>
                    )}
                    {!employee && user && (
                      <div className='profileColumn'>
                        <p className='userName'>
                          {user.firstName} {user.lastName}
                        </p>
                        <p className='role'>&nbsp;</p>
                      </div>
                    )}
                  </span>
                }
                icon=''
                on='click'
              >
                <Popup.Content>
                  <div style={{ width: '100%', margin: '0px' }}>
                    {employeeRole && (
                      <div style={{ marginBottom: '1em' }}>
                        <PopupButton onClick={this.openProfile}>
                          <PopupImage src='/profile.svg' /> <PopupText>Your Profile</PopupText>
                        </PopupButton>
                        {isAdmin(roles) && (
                          <PopupButton onClick={this.openSettings}>
                            <PopupImage src='/Cog.svg' /> <PopupText>Settings</PopupText>
                          </PopupButton>
                        )}
                      </div>
                    )}
                    <div style={{ padding: '0px' }}>
                      <Button
                        key='logout'
                        id='menuTopUserLogout'
                        basic
                        fluid
                        content='Log out'
                        onClick={this.logOut}
                      />
                    </div>
                  </div>
                </Popup.Content>
              </Popup>
            </Menu.Item>
          </MenuContainer>
          {this.renderSubMenu()}
        </>
      </TopContainer>
    );
  }
}

export default TopMenu;

import React, { Component } from 'react';
import { Dimmer, Tab, TabProps } from 'semantic-ui-react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import HRApplicantsNewTable from './subcomponents/hrApp/HRApplicantsNewTable';
import HRApplicantsDeclinedTable from './subcomponents/hrApp/HRApplicantsDeclinedTable';
import HRApplicantsApprovedTable from './subcomponents/hrApp/HRApplicantApprovedTable';
import { applicantActions } from '../actions/applicants';
import { IApplicantProfile, IApplicantsState } from '../reducers/types/applicants';
import HRApplicantFilter from './subcomponents/hrApp/HRApplicantFilter';
import CareLoader from './subcomponents/CareLoader';
import HRApplicantOverview from './subcomponents/hrApp/HRApplicantOverview';

interface IProps {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  dispatch: (arg: any) => void;
  applicants: IApplicantsState;
}

interface IState {
  activeTabIndex?: number | string;
  searchValue: string;
  zipValue: string;
  applicantOverView?: IApplicantProfile;
  overViewOpen: boolean;
}

interface INotesParams {
  token: string;
  notes: string;
}

const HRApplicantsMainContent = styled.div`
  padding: 1rem;
  width: 100vw;
`;

const TabContainer = styled.div`
  padding: 1rem;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: flex-end;
  height: 72px;
`;

const HeaderText = styled.h3`
  font-family: Lato;
  font-size: 1.25rem;
  color: #4a4a4a;
  margin: 0 0 0 9px;
  padding: 1rem 0 2rem 3rem;
  display: inline-block;
  justify-self: flex-start;
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  justify-items: flex-end;
  align-items: center;
  column-gap: 1rem;
  padding: 1rem;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0 -1px 10px #d9d9d9;
`;

const ApplicantOverviewContainer = styled.div`
  display: grid !important;
  place-items: center;
  background-color: #f3f3f325;
  width: 100vw;
  height: 100vh;
`;

class HRApplicants extends Component<IProps, IState> {
  public state: IState = {
    activeTabIndex: 0,
    searchValue: '',
    zipValue: '',
    applicantOverView: undefined,
    overViewOpen: false,
  };

  public componentDidMount(): void {
    const { dispatch } = this.props;
    dispatch(applicantActions.getApplicantsList());
  }

  private readonly changeTabIndex = (
    _e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: TabProps
  ) => {
    this.setState(s => ({ ...s, activeTabIndex: data.activeIndex }));
  };

  public onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { dispatch } = this.props;
    const newValue = e.target.value;
    this.setState(s => ({ ...s, searchValue: newValue }));
    dispatch(applicantActions.filterApplicantByName(newValue));
  };

  public onZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { dispatch } = this.props;
    const newValue = e.target.value;
    this.setState(s => ({ ...s, zipValue: newValue }));
    dispatch(applicantActions.filterApplicantByZip(newValue));
  };

  public applicantToProceed = (token: string) => {
    const { dispatch } = this.props;
    dispatch(applicantActions.applicantToProceed(token));
  };

  public applicantDecline = (token: string) => {
    const { dispatch } = this.props;
    dispatch(applicantActions.applicantDecline(token));
  };

  public applicantResetStatus = (selected: string[]) => {
    const { dispatch } = this.props;
    dispatch(applicantActions.applicantReset(selected));
  };

  public applicantApprove = (selected: string[]) => {
    const { dispatch } = this.props;
    dispatch(applicantActions.applicantApprove(selected));
  };

  public applicantDelete = (selected: string[]) => {
    const { dispatch } = this.props;
    dispatch(applicantActions.applicantDelete(selected));
  };

  public handleApplicantNameClick = (applicant: IApplicantProfile) => {
    this.setState(s => ({ ...s, overViewOpen: true, applicantOverView: applicant }));
  };

  public handleCloseOverview = () => {
    this.setState(s => ({ ...s, overViewOpen: false, applicantOverView: undefined }));
  };

  public dispatchSaveNotes = (params: INotesParams) => {
    const { dispatch } = this.props;
    dispatch(applicantActions.saveNotes(params));
  };

  public sortApplicants = (applicants: IApplicantProfile[], column: string, direction: string) => {
    const sortedApplicants = applicants.sort((a, b) => {
      let aVal = null;
      let bVal = null;
      switch (column) {
        case 'name':
          aVal = `${a.basicInformation.firstName.toLowerCase()}${a.basicInformation.lastName.toLowerCase()}`;
          bVal = `${b.basicInformation.firstName.toLowerCase()}${b.basicInformation.lastName.toLowerCase()}`;
          break;
        case 'email':
          aVal = a.basicInformation.email;
          bVal = b.basicInformation.email;
          break;
        case 'phone':
          aVal = a.additionalInformation.cellPhone;
          bVal = b.additionalInformation.cellPhone;
          break;
        case 'zip':
          aVal = a.additionalInformation.address.zip;
          bVal = b.additionalInformation.address.zip;
          break;
        case 'submission-date':
          aVal = a.profileStatus.created;
          bVal = b.profileStatus.created;
          break;
        default:
          aVal = 0;
          bVal = 1;
          break;
      }
      if (direction === 'descending') {
        if (aVal === bVal) return 0;
        if (aVal && bVal && aVal > bVal) return -1;
        return 1;
      }
      if (aVal === bVal) return 0;
      if (aVal && bVal && aVal < bVal) return -1;
      return 1;
    });
    return sortedApplicants;
  };

  public render(): JSX.Element {
    const { activeTabIndex, searchValue, zipValue, overViewOpen, applicantOverView } = this.state;

    const { applicants } = this.props;
    const panes = [
      {
        menuItem: 'New Applicants',
        pane: {
          key: 'newApplicants',
          content: (
            <div>
              <HRApplicantsNewTable
                applicants={applicants.newApplicants}
                handleToProceed={this.applicantToProceed}
                handleDecline={this.applicantDecline}
                handleNameClick={this.handleApplicantNameClick}
                sortApplicants={this.sortApplicants}
              />
            </div>
          ),
        },
      },
      {
        menuItem: 'Declined',
        pane: {
          key: 'declinedApplicants',
          content: (
            <div>
              <HRApplicantsDeclinedTable
                applicantResetStatus={this.applicantResetStatus}
                applicantDelete={this.applicantDelete}
                applicants={applicants.declinedApplicants}
                handleNameClick={this.handleApplicantNameClick}
                sortApplicants={this.sortApplicants}
              />
            </div>
          ),
        },
      },
      {
        menuItem: 'To Proceed',
        pane: {
          key: 'approvedApplicants',
          content: (
            <div>
              <HRApplicantsApprovedTable
                applicantResetStatus={this.applicantResetStatus}
                applicantApprove={this.applicantApprove}
                applicants={applicants.approvedApplicants}
                handleNameClick={this.handleApplicantNameClick}
                sortApplicants={this.sortApplicants}
              />
            </div>
          ),
        },
      },
    ];

    return (
      <HRApplicantsMainContent>
        {overViewOpen && (
          <Dimmer active onClickOutside={this.handleCloseOverview} page>
            <ApplicantOverviewContainer>
              <HRApplicantOverview
                customFields={applicants.customFields}
                applicant={applicantOverView}
                handleSaveNotes={this.dispatchSaveNotes}
                handleClose={this.handleCloseOverview}
              />
            </ApplicantOverviewContainer>
          </Dimmer>
        )}
        <HeaderContainer>
          <HeaderText>APPLICANTS</HeaderText>
          <HRApplicantFilter
            search={searchValue}
            zip={zipValue}
            onSearchChange={this.onSearchChange}
            onZipChange={this.onZipChange}
          />
        </HeaderContainer>
        <TabContainer>
          {applicants.loading && (
            <Dimmer active inverted>
              <CareLoader loading showText={false} />
            </Dimmer>
          )}
          <Tab
            id='hr-applicants-tab'
            panes={panes}
            renderActiveOnly={false}
            activeIndex={activeTabIndex}
            onTabChange={this.changeTabIndex}
          />
        </TabContainer>
      </HRApplicantsMainContent>
    );
  }
}

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
const mapStateToProps = (state: any) => {
  const { applicants } = state;

  return {
    applicants,
  };
};

export default connect(mapStateToProps)(HRApplicants);

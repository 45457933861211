import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Icon,
  Divider,
  Modal,
  Dropdown,
  TextArea,
  Form,
  Item,
  Header,
  Segment,
  Popup,
  Dimmer,
} from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { LinkButton, BlueButton } from '../../../styles/common';
import CareLoader from '../CareLoader';
import { eventActions, alertActions } from '../../../actions';
import { MOMENT_HOURS } from '../../../constants/Formats';
import { avatarUrl } from '../../../constants/DomainTypes';
import hrHelper from '../../../helpers/hrHelper';
import pdfCreator from '../../../helpers/pdfCreator';
import { CheckBox } from '../../../styles/commonStyled';
import NoMatch from '../NoMatch';

const { ClassRoomType } = hrHelper;

const moment = require('moment');

const StartMeetingModal = styled(Modal)`
  &&& {
    color: #4a4a4a;
    max-width: 400px;
    margin-top: -20vh;
  }
  &&& > .close.icon {
    top: 1.0535rem;
    right: 1rem;
    color: rgba(0, 0, 0, 0.87);
  }
  & > .loading-event {
    width: 400px;
    height: 273px;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
  }
  &&& > .header {
    height: 100px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &&& > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > h2 {
      font-size: 22px;
    }
    & > h3 {
      margin-top: 1rem;
      font-size: 14px;
    }
    & > div {
      text-align: center;
      font-size: 11px;
    }
  }
  &&& > .actions {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

class EventView extends React.Component {
  constructor() {
    super();
    this.state = {
      event: null,
      dirtyAttendees: {},
      dirtyAddedAttendees: [],
      dirtyRemovedAttendees: [],
      preferenceIds: [],
      selectedAttendeeTab: 0,
      visible: false,
      goToEvent: false,
    };
    this.eventInfoAndDate = this.eventInfoAndDate.bind(this);
    this.copyEventToState = this.copyEventToState.bind(this);
    this.hasChanged = this.hasChanged.bind(this);
    this.CheckRef = [];
  }

  componentDidMount() {
    const { event } = this.props;
    this.checkPropsEvent(event);
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { event } = next;
    this.setPreferenceIds(next);
    this.checkPropsEvent(event);
  }

  componentWillUnmount() {
    this.setState({
      event: null,
      dirtyAttendees: {},
      dirtyAddedAttendees: [],
      dirtyRemovedAttendees: [],
      preferenceIds: [],
      selectedAttendeeTab: 0,
      visible: false,
      goToEvent: false,
    });
  }

  setPreferenceIds(next) {
    const { event, preferenceIds } = this.state;
    const { preferences } = this.props;
    if (
      next.preferences &&
      ((preferences &&
        (next.preferences.length !== preferences.length || next.preferences !== preferences)) ||
        !preferenceIds.length)
    ) {
      const filteredPrefs = next.preferences
        .filter(i => i.preference_id !== null)
        .map(i => i.preference_id);
      event.preferences = filteredPrefs;
      const newEvent = Object.assign(event, { preferences: filteredPrefs });
      this.setState({ event: newEvent });
      this.setState({
        preferenceIds: filteredPrefs,
      });
    }
  }

  toggleGoToEventModal = () => {
    const { goToEvent } = this.state;
    this.setState({ goToEvent: !goToEvent });
  };

  confirmGoToEvent = () => {
    const { dispatch } = this.props;
    const {
      event: { virtualTrainingEventId },
    } = this.state;
    dispatch(eventActions.initiateVirtualEvent(virtualTrainingEventId, this.closeGoToEventModal));
  };

  closeGoToEventModal = () => {
    this.setState({ goToEvent: false });
  };

  sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  copyEventToState = async copyEvent => {
    await this.sleep(50);
    const { event } = this.state;

    const { loadingAttendees } = this.props;
    this.checkOverride(event);
    const copy = copyEvent ? JSON.parse(JSON.stringify(copyEvent)) : null;
    if (copy) {
      copy.date = moment(copy.date).toDate();
    }
    this.setState({ event: copy });
    if (!loadingAttendees) this.findAttendees(copyEvent);
  };

  checkOverride = async event => {
    const changed = event && this.hasChanged(event);
    await this.sleep(100);
    const { doNotAskSaving } = this.state;
    if (!doNotAskSaving && changed) {
      this.setState({ overridedEvent: event, doNotAskSaving: false });
    } else {
      this.setState({ overridedEvent: null, doNotAskSaving: false });
    }
  };

  findAttendees = (event, search, nextPage) => {
    const { dispatch } = this.props;
    if (event?.blockId !== undefined) {
      dispatch(eventActions.getAttendees(event.id, search, nextPage));
    } else {
      dispatch(eventActions.clearAttendees());
    }
  };

  validateEvent = event =>
    event.blockId &&
    event.supervisor &&
    event.classRoom &&
    event.instructor &&
    event.signOffSupervisor;

  onAddAttendee = (event, caregiver, filter) => {
    const { dirtyAddedAttendees } = this.state;
    const { invited, dispatch } = this.props;
    const newDirtyAttendee = { event, caregiver, filter };
    let newDirtyAddedAttendees = [];

    const classFull = event.classRoom.capacity <= invited.length + dirtyAddedAttendees.length;

    if (dirtyAddedAttendees.find(a => a.caregiver.id === caregiver.id)) {
      newDirtyAddedAttendees = dirtyAddedAttendees.filter(a => a.caregiver.id !== caregiver.id);
    } else if (!classFull) {
      newDirtyAddedAttendees = [...dirtyAddedAttendees, newDirtyAttendee];
    } else {
      return dispatch(alertActions.error('Classroom is full'));
    }

    return this.setState(s => ({
      ...s,
      dirtyAddedAttendees: newDirtyAddedAttendees,
    }));
  };

  onRemoveAttendee = (caregiver, eventId) => {
    const { dirtyRemovedAttendees } = this.state;
    const newRemoveAttendee = { caregiver, eventId };
    let newDirtyRemovedAttendee = [];

    if (dirtyRemovedAttendees.find(a => a.caregiver.id === caregiver.id)) {
      newDirtyRemovedAttendee = dirtyRemovedAttendees.filter(a => a.caregiver.id !== caregiver.id);
    } else {
      newDirtyRemovedAttendee = [...dirtyRemovedAttendees, newRemoveAttendee];
    }

    this.setState(s => ({
      ...s,
      dirtyRemovedAttendees: newDirtyRemovedAttendee,
    }));
  };

  checkPropsEvent(checkEvent) {
    const { event } = this.state;
    if ((checkEvent && !event) || checkEvent?.id !== event?.id) {
      this.copyEventToState(checkEvent);
    }
  }

  hasChanged(draft) {
    const { preferences, invited } = this.props;
    const { event: propEvent } = this.props;
    const { dirtyAttendees, event, dirtyAddedAttendees, dirtyRemovedAttendees } = this.state;

    this.updateAttendees = [];
    if (dirtyAttendees) {
      Object.keys(dirtyAttendees).forEach(key => {
        const attendee = invited.filter(i => i.id === parseInt(key, 10));
        if (
          attendee &&
          attendee.length > 0 &&
          (attendee[0].attended !== dirtyAttendees[key].attended ||
            dirtyAttendees[key].completed !== attendee[0].completed)
        ) {
          this.updateAttendees.push({
            caregiverId: key,
            completed: dirtyAttendees[key].completed,
            attended: dirtyAttendees[key].attended,
          });
        }
      });
    }

    const storedPreferences = (preferences
      ? preferences.filter(i => i.preference_id !== null)
      : []
    ).map(i => i.preference_id);

    if (draft.preferences && storedPreferences) {
      this.addPreferences = draft.preferences.filter(e => !storedPreferences.includes(e));
      this.remPreferences = storedPreferences.filter(e => !draft.preferences.includes(e));
    }

    return (
      dirtyAddedAttendees?.length ||
      dirtyRemovedAttendees?.length ||
      this.addPreferences?.length ||
      this.remPreferences?.length ||
      this.updateAttendees?.length ||
      propEvent?.supervisor !== event.supervisor ||
      propEvent?.notes !== event.notes ||
      propEvent?.attendeeNotes !== event.attendeeNotes
    );
  }

  eventInfoAndDate() {
    const { event, goToEvent } = this.state;
    const {
      principal: { inServiceCourses },
      principal: { user },
      loadingEvents,
    } = this.props;

    const selectedCourse =
      inServiceCourses && event.blockId
        ? inServiceCourses.courses.find(c => c.id === event.blockId)
        : null;

    return (
      <Grid style={{ marginLeft: '0px', marginRight: '0px' }}>
        {goToEvent && (
          <StartMeetingModal
            closeOnDimmerClick={false}
            open
            dimmer
            size='tiny'
            onClose={this.toggleGoToEventModal}
            closeOnEscape
            closeIcon
          >
            {loadingEvents ? (
              <>
                <Modal.Header>
                  <img src='/care_connect_classroom.svg' alt='care connect classroom header' />
                </Modal.Header>
                <div className='loading-event'>
                  <CareLoader loading showText={false} />
                </div>
              </>
            ) : (
              <>
                <Modal.Header>
                  <img src='/care_connect_classroom.svg' alt='care connect classroom header' />
                </Modal.Header>
                <Modal.Content>
                  <h2>Start the meeting</h2>
                  <h3>{`${user.firstName} ${user.lastName}`}</h3>
                  <div>
                    <p>
                      You can start the meeting by clicking the button below. After you start the
                      meeting, attendees will be able to join.
                      <br />
                      <br />
                      If you want to test the meeting, you can start the meeting for yourself and
                      come back for the actual meeting time.
                    </p>
                  </div>
                </Modal.Content>
                <Modal.Actions>
                  <BlueButton onClick={this.confirmGoToEvent}>Continue</BlueButton>
                </Modal.Actions>
              </>
            )}
          </StartMeetingModal>
        )}
        <Grid.Row>
          <div>
            <p style={{ fontSize: 12 }}>Event Type</p>
            <h3 style={{ margin: '0px', fontSize: 24 }}>{selectedCourse && selectedCourse.name}</h3>
          </div>
        </Grid.Row>
        <Grid.Row>
          <Grid verticalAlign='middle' style={{ width: '100%' }}>
            <Grid.Row style={{ paddingBottom: '0px', fontSize: 12 }}>
              <Grid.Column width={4}>
                <p>Date</p>
                <p>{moment(event.date).format('MM/DD/YYYY')}</p>
              </Grid.Column>
              <Grid.Column width={3}>
                <p>From</p>
                <p>{moment(event.date).format(MOMENT_HOURS)}</p>
              </Grid.Column>
              <Grid.Column width={3}>
                <p>To</p>
                <p>
                  {moment(event.date)
                    .add(event.hours, 'hours')
                    .format(MOMENT_HOURS)}
                </p>
              </Grid.Column>
              {event.classRoom.type === ClassRoomType.VIRTUAL && !event.signedOff && (
                <Grid.Column className='go-event-button-container' width={6}>
                  <BlueButton content='Start Event' onClick={this.toggleGoToEventModal} />
                  <Popup
                    trigger={
                      <span className='round-grey-question'>
                        <Icon name='question' />
                      </span>
                    }
                    content='By clicking “Start Event”,
                      you will be directed to the meeting in web browser, 
                      from there you can start the meeting 
                      and attendees will be allowed to join.'
                    on={['hover', 'focus']}
                    position='top left'
                    popperModifiers={{
                      preventOverflow: {
                        boundariesElement: 'offsetParent',
                      },
                    }}
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column style={{ padding: '0px' }} width={4}>
            <p style={{ fontSize: 12 }}>
              Duration:{' '}
              <b style={{ padding: '4px', fontSize: 20 }} className='medium black'>
                {Math.round(event.hours * 10) / 10}hrs
              </b>
            </p>
          </Grid.Column>
          <Grid.Column style={{ padding: '0px' }} width={8}>
            <p style={{ fontSize: 12 }}>
              Contributing Hours:{' '}
              <b style={{ padding: '4px', fontSize: 20 }} className='medium black'>
                {event.eligibleHours}hrs
              </b>
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>{this.eventDetails()}</Grid.Row>
      </Grid>
    );
  }

  attendee(caregiver, invited, inProgress, ref) {
    const {
      dirtyAttendees,
      event,
      attendeeAddingFilter,
      dirtyAddedAttendees,
      dirtyRemovedAttendees,
    } = this.state;
    const { loadingAttendees } = this.props;
    const completed =
      (!!caregiver.completed && !(caregiver.id in dirtyAttendees)) ||
      (caregiver.id in dirtyAttendees && !!dirtyAttendees[caregiver.id].completed);
    const attended =
      (!!caregiver.attended && !(caregiver.id in dirtyAttendees)) ||
      (caregiver.id in dirtyAttendees && !!dirtyAttendees[caregiver.id].attended);

    const inDirtyRemoved = dirtyRemovedAttendees.find(r => r.caregiver.id === caregiver.id);

    const inDirtyAdd = dirtyAddedAttendees.find(a => a.caregiver.id === caregiver.id);

    const handleSelect = () => {
      ref.current.click();
    };

    const removeAttendee = () => {
      return this.onRemoveAttendee(caregiver, event.id);
    };

    const addAttendee = () => {
      return this.onAddAttendee(event, caregiver, attendeeAddingFilter);
    };

    const checkBoxChanged = (name, value) => {
      if (!(caregiver.id in dirtyAttendees)) {
        dirtyAttendees[caregiver.id] = {
          completed,
          attended,
        };
      }
      if (name === 'completed' && value) dirtyAttendees[caregiver.id].attended = value;
      dirtyAttendees[caregiver.id][name] = value;
      this.setState({ dirtyAttendees });
    };

    const statusColor = status => {
      switch (status) {
        case 'pending':
          return '#f5a623';
        case 'declined':
          return '#f16778';
        case 'confirmed':
          return '#69db8f';
        default:
          return '#4a4a4a';
      }
    };

    return (
      <Item
        style={{
          padding: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Item.Image
          verticalAlign='middle'
          size='mini'
          src={avatarUrl(caregiver.avatar, caregiver.gender)}
        />
        <Item.Content verticalAlign='middle'>
          <div>
            <p style={{ fontWeight: 'bold', fontSize: '11px', marginBottom: 0 }}>
              {caregiver.firstName} {caregiver.lastName}
            </p>
            <p
              style={{
                color: '#9b9b9b',
                fontSize: '11px',
              }}
            >
              {caregiver.email}
            </p>
            {!inProgress && !event.signedOff && invited && (
              <p
                style={{
                  color: '#4a4a4a',
                  fontSize: '11px',
                }}
              >
                Attendance status:{' '}
                <b
                  style={{
                    color: statusColor(caregiver.attendanceConfirmationStatus),
                    fontWeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  {caregiver.attendanceConfirmationStatus}
                </b>
              </p>
            )}
          </div>
        </Item.Content>
        <Item.Extra verticalAlign='middle' style={{ margin: 'auto', width: '20px' }}>
          {!inProgress && !event.signedOff && invited && (
            <CheckBox
              id={caregiver.id}
              onClick={handleSelect}
              checked={!inDirtyRemoved}
              disabled={loadingAttendees}
              tabIndex={loadingAttendees ? -1 : 0}
            >
              <input
                ref={ref}
                id={`event-view-delete-check${caregiver.id}`}
                onChange={removeAttendee}
                type='checkbox'
                checked={!inDirtyRemoved}
                style={{ display: 'none' }}
              />
            </CheckBox>
          )}
          {!inProgress && !event.signedOff && !invited && (
            <CheckBox
              id={caregiver.id}
              onClick={handleSelect}
              checked={!!inDirtyAdd}
              disabled={loadingAttendees}
              tabIndex={loadingAttendees ? -1 : 0}
            >
              <input
                ref={ref}
                id={`event-view-plus-check${caregiver.id}`}
                onChange={addAttendee}
                type='checkbox'
                checked={!!inDirtyAdd}
                style={{ display: 'none' }}
              />
            </CheckBox>
          )}
          {(inProgress || event.signedOff) && (
            <Grid style={{ width: '150px', margin: '0px', float: 'right' }}>
              <Grid.Column width={8} style={{ padding: '0 12px' }}>
                <Form.Checkbox
                  className='round'
                  disabled={loadingAttendees || event.signedOff}
                  checked={attended}
                  onChange={(_, { checked }) => {
                    checkBoxChanged('attended', checked);
                  }}
                />
              </Grid.Column>
              <Grid.Column width={8} style={{ padding: '0 12px' }}>
                <Form.Checkbox
                  className='round'
                  disabled={loadingAttendees || event.signedOff}
                  checked={completed}
                  onChange={(_, { checked }) => {
                    checkBoxChanged('completed', checked);
                  }}
                />
              </Grid.Column>
            </Grid>
          )}
        </Item.Extra>
      </Item>
    );
  }

  eventAttendees() {
    const { event, selectedAttendeeTab, preferenceIds } = this.state;
    const { attendees, invited, attendeesNextPage, loadingAttendees } = this.props;
    const { attendeeAddingFilter } = this.state;
    const {
      principal: { attributes, settings },
    } = this.props;
    let showMore = false;

    const printAttendees = () => {
      pdfCreator.createAttendeesPdf(event, invited, settings.agencyName);
    };
    const printCertificates = () => {
      const logoUrl = settings.agencyLogoUrl || '/heart-logo.png';
      const completed = invited.filter(i => i.completed);
      pdfCreator.createCertificatePdf(event, completed, settings.agencyName, logoUrl);
    };

    const trainingOptions = attributes.preferences
      .filter(a => a.category === 'skills' && a.active)
      .map(a => ({ text: `${a.label.charAt(0).toUpperCase()}${a.label.slice(1)}`, value: a.id }));

    const attendeesOptions = attendees
      ? attendees.map(a => ({
          value: a.id,
          text: `${a.firstName} ${a.lastName}`,
        }))
      : [];

    if (attendeesNextPage) {
      showMore = true;
    }

    const trainingItemsChanged = selection => {
      event.preferences = selection;
      this.setState(event);
      this.setState({ preferenceIds: [...event.preferences] });
    };

    const attendeeToAddChanged = (e, i) => {
      this.setState({ attendeeAddingFilter: i.value });
    };

    const handleFindAttendees = () => {
      this.findAttendees(event, attendeeAddingFilter);
    };

    const expiredAttendees = attendees ? attendees.filter(a => a.hasExpired) : [];
    const aboutToExpireAttendees = attendees ? attendees.filter(a => a.aboutToExpire) : [];
    const wellComplianceAttendees = attendees
      ? attendees.filter(a => !(a.hasExpired || a.aboutToExpire))
      : [];

    const dot = {
      width: '12px',
      height: '12px',
      borderRadius: '12px',
      float: 'left',
      marginRight: '16px',
      marginTop: '3px',
    };

    return (
      <div style={{ marginLeft: '1rem', height: 'fit-content' }}>
        {attendees && (
          <>
            {!event.inProgress && !event.signedOff ? (
              <>
                <h4>Attendees</h4>
                <p>
                  The selected location has a <b>{event?.classRoom?.capacity} person limit.</b>
                </p>
                <p style={{ marginTop: '-4px' }} className='small gray'>
                  Once the limit is reached you must remove an attendee to add a new one.
                </p>
                <br />
                <Grid style={{ marginLeft: '0px', marginRight: '0px' }}>
                  <Grid.Column
                    width={8}
                    onClick={() => this.setState({ selectedAttendeeTab: 0 })}
                    className={selectedAttendeeTab === 0 ? 'subTab selected' : 'subTab'}
                  >
                    <p>Added ({invited ? invited.length : '0'})</p>
                  </Grid.Column>
                  <Grid.Column
                    width={8}
                    onClick={() => this.setState({ selectedAttendeeTab: 1 })}
                    className={selectedAttendeeTab === 1 ? 'subTab selected' : 'subTab'}
                  >
                    <p>
                      Available ({attendees ? attendees.length : '0'}
                      {showMore && '+'})
                    </p>
                  </Grid.Column>
                </Grid>
                <Divider />
                {selectedAttendeeTab === 0 ? (
                  <div className='event-attendees-container'>
                    <p className='attendees-info-text'>
                      Below listed attendees are added to this event. If you want to remove an
                      attendee, de-select from the list and save changes.
                    </p>
                    <Item.Group divided>
                      {invited.map(a => {
                        this.CheckRef[a.id] = React.createRef();
                        return this.attendee(a, true, event.inProgress, this.CheckRef[a.id]);
                      })}
                    </Item.Group>
                  </div>
                ) : (
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <p className='attendees-info-text'>
                          Select caregivers to add to this event. Below listed caregivers can be
                          added. Don’t forget to save the changes before you close event details.
                        </p>
                        <div className='round-search'>
                          <div className='round-search-dropdown-border'>
                            <Form onSubmit={handleFindAttendees}>
                              <Form.Input
                                id='event-view-caregiver-name-input'
                                className='round-search-input'
                                options={attendeesOptions}
                                search
                                fluid
                                icon={null}
                                placeholder='Enter Caregiver Name'
                                onChange={attendeeToAddChanged}
                                value={attendeeAddingFilter}
                                loading={loadingAttendees}
                              />
                            </Form>
                          </div>
                          <div>
                            <Button
                              id='event-view-go-button'
                              style={{ float: 'right' }}
                              circular
                              onClick={handleFindAttendees}
                            >
                              GO
                            </Button>
                          </div>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='event-attendees-container'>
                      <Grid.Column width={16}>
                        <div>
                          {!loadingAttendees &&
                            !attendeesNextPage &&
                            attendees &&
                            attendees.length === 0 && (
                              <div style={{ textAlign: 'center' }}>
                                <br />
                                <NoMatch
                                  id='attendeesResultsNoMatch'
                                  message='Try Removing some of your search filters'
                                />
                              </div>
                            )}
                          {expiredAttendees.length > 0 && (
                            <div style={{ marginBottom: '44px' }}>
                              <h5>
                                <div style={dot} className='bg-red' />
                                EXPIRED
                              </h5>
                              <Item.Group divided>
                                {expiredAttendees.map(a => {
                                  this.CheckRef[a.id] = React.createRef();
                                  return this.attendee(
                                    a,
                                    false,
                                    event.inProgress,
                                    this.CheckRef[a.id]
                                  );
                                })}
                              </Item.Group>
                            </div>
                          )}
                          {aboutToExpireAttendees.length > 0 && (
                            <div style={{ marginBottom: '44px' }}>
                              <h5>
                                <div style={dot} className='bg-orange' />
                                PENDING EXPIRATION
                              </h5>
                              <Item.Group divided>
                                {aboutToExpireAttendees.map(a => {
                                  this.CheckRef[a.id] = React.createRef();
                                  return this.attendee(
                                    a,
                                    false,
                                    event.inProgress,
                                    this.CheckRef[a.id]
                                  );
                                })}
                              </Item.Group>
                            </div>
                          )}
                          {wellComplianceAttendees.length > 0 && (
                            <div style={{ marginBottom: '16px' }}>
                              <h5>
                                <div style={dot} className='bg-green' />
                                READY TO WORK
                              </h5>
                              <Item.Group divided>
                                {wellComplianceAttendees.map(a => {
                                  this.CheckRef[a.id] = React.createRef();
                                  return this.attendee(
                                    a,
                                    false,
                                    event.inProgress,
                                    this.CheckRef[a.id]
                                  );
                                })}
                              </Item.Group>
                            </div>
                          )}
                          {showMore && (
                            <LinkButton
                              className='unselectableLink'
                              onClick={() => {
                                if (!loadingAttendees) {
                                  this.findAttendees(
                                    event,
                                    attendeeAddingFilter,
                                    attendeesNextPage
                                  );
                                }
                              }}
                            >
                              Show more...
                            </LinkButton>
                          )}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                )}
              </>
            ) : (
              <div>
                {!event.signedOff && (
                  <div>
                    <h4>TRAINING ITEMS</h4>
                    <p className='gray'>
                      Select any training items covered in this training class. Once completed by
                      caregivers the defined items will be added to their preferences in their
                      profile.
                    </p>
                    <Dropdown
                      id='event-view-training-options-dropdown'
                      className='create-event-dropdown'
                      style={{ padding: '9px' }}
                      fluid
                      selection
                      multiple
                      placeholder='Select training items...'
                      options={trainingOptions}
                      value={preferenceIds}
                      onChange={(i, e) => trainingItemsChanged(e.value)}
                    />
                  </div>
                )}
                <h4>ATTENDANCE &amp; COMPLETION</h4>
                <div style={{ display: 'flex', marginBottom: '1rem' }}>
                  <Button
                    id='event-view-print-attendees'
                    basic
                    style={{ border: 'none', boxShadow: 'none', padding: '0px' }}
                    onClick={printAttendees}
                  >
                    <div style={{ display: 'flex' }}>
                      <Icon name='print' size='large' style={{ color: '#aaa' }} />
                      <p style={{ color: '#aaa' }}>Print class attendees sign-off sheet</p>
                    </div>
                  </Button>
                  <Button
                    id='event-view-print-certificates'
                    basic
                    style={{
                      border: 'none',
                      marginLeft: '12px',
                      boxShadow: 'none',
                      padding: '0px',
                    }}
                    onClick={printCertificates}
                  >
                    <div style={{ display: 'flex' }}>
                      <Icon name='print' size='large' style={{ color: '#aaa' }} />
                      <p style={{ color: '#aaa' }}>Print Certificates</p>
                    </div>
                  </Button>
                </div>
                <Grid>
                  <Grid.Row style={{ padding: '0' }}>
                    <Grid.Column>
                      <div className='attendees-completed-column'>
                        <p className='small'>Attended</p>
                        <p className='small'>Completed</p>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ padding: '0' }}>
                    <Grid.Column>
                      <Item.Group
                        divided
                        className={
                          !event.signedOff
                            ? 'event-attendees-completed-container'
                            : 'event-attendees-signed-off-container'
                        }
                      >
                        {invited.map(a => this.attendee(a, true, event.inProgress))}
                      </Item.Group>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  eventDetails() {
    const { event } = this.state;
    const { employees } = this.props;
    const employeesOptions = employees
      ? employees.map(e => ({ text: `${e.firstName} ${e.lastName}`, value: e.id }))
      : [];

    // const statesOptions = states.states.map(s => ({ value: s.abbreviation, text: s.abbreviation }));

    // this is to support actual instructor and future multi selector instructor if implemented
    const instructors = Array.isArray(event.instructor) ? event.instructor : [event.instructor];

    const supervisorChanged = (e, input) => {
      this.supervisor = input.value;
      Object.assign(event, { supervisor: this.supervisor });
      this.setState({ event });
    };

    const notesChanged = (input, e) => {
      Object.assign(event, { notes: e.value });
      this.setState({ event });
    };

    const attendeeNotesChanged = (input, e) => {
      Object.assign(event, { attendeeNotes: e.value });
      this.setState({ event });
    };

    return (
      <div style={{ width: '100%' }}>
        {!!event.blockId && (
          <div style={{ marginTop: '8px' }}>
            <h4>EVENT DETAILS</h4>
            <Divider />
            <div className={event.signedOff ? 'event-details-signed-off' : 'event-details'}>
              <br />
              {event.block.description && (
                <>
                  <p className='small gray'>Description</p>
                  <p style={{ marginBottom: '30px' }}>{event.block.description}</p>
                </>
              )}

              <p className='small gray'>
                <Icon name='point' size='large' style={{ position: 'relative', top: '-2px' }} />
                Location
              </p>
              <p style={{ marginTop: '-6px', marginBottom: '30px' }}>{event.classRoom.name}</p>

              <p className='small gray'>
                <Icon size='large' name='user' style={{ position: 'relative', top: '-2px' }} />
                {`Instructor${instructors.length > 1 ? 's' : ''}`}
              </p>

              {instructors.map(i => (
                <p style={{ marginTop: '-6px', marginBottom: '30px' }}>{i.name}</p>
              ))}

              <p className='small gray'>Notes for instructors</p>
              <TextArea
                id='event-view-notes-text-area'
                style={{ width: '100%', resize: 'vertical', marginBottom: '30px' }}
                disabled={event.signedOff}
                onChange={notesChanged}
                value={event.notes}
                maxLength={1024}
              />
              <Divider />

              <br />
              <p className='small gray'>Notes for attendees</p>
              <TextArea
                id='event-view-attendees-notes-text-area'
                style={{ width: '100%', resize: 'vertical', marginBottom: '30px' }}
                disabled={event.signedOff}
                onChange={attendeeNotesChanged}
                value={event.attendeeNotes}
                maxLength={1024}
              />
              <Divider />

              <br />
              <p className='small gray'>Assigned Supervisor</p>
              <Dropdown
                id='event-view-supervisor-dropdown'
                className='create-event-dropdown'
                options={employeesOptions}
                selection
                fluid
                placeholder='Select Supervisor'
                disabled={event.signedOff}
                onChange={supervisorChanged}
                value={event.supervisor}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      event,
      overridedEvent,
      dirtyAttendees,
      visible,
      dirtyAddedAttendees,
      dirtyRemovedAttendees,
    } = this.state;
    const { onChanged, employees, loadingAttendees } = this.props;
    const employeesOptions = employees
      ? employees.map(e => ({ text: `${e.firstName} ${e.lastName}`, value: e.id }))
      : [];

    const signOffSupervisorChanged = (e, input) => {
      const signOffSupervisor = input.value;
      Object.assign(event, { signOffSupervisor });
      this.setState({ event });
    };

    const saveEvent = () => {
      const { dispatch } = this.props;
      if (this.updateAttendees.length > 0) {
        dispatch(eventActions.updateAttendeesCompletionValues(event.id, this.updateAttendees));
      }
      if (this.addPreferences && this.addPreferences.length) {
        const savePreferences = Object.values(this.addPreferences);
        // Object.assign({}, { preferences: savePreferences });
        Object.assign(event, { addedPreferences: savePreferences });
        this.setState({ event });
      }

      if (this.remPreferences && this.remPreferences.length) {
        const deletePreferences = Object.values(this.remPreferences);
        // Object.assign({}, { preferences: deletePreferences });
        Object.assign(event, { removedPreferences: deletePreferences });
        this.setState({ event });
      }
      dispatch(eventActions.updateEvent(event, dirtyAddedAttendees, dirtyRemovedAttendees));

      onChanged(event);
    };

    const submitEvent = () => {
      const { dispatch, onSubmitted } = this.props;
      const attendees = dirtyAttendees
        ? Object.keys(dirtyAttendees).map(k => ({
            caregiverId: k,
            completed: dirtyAttendees[k].completed,
            attended: dirtyAttendees[k].attended,
          }))
        : [];

      dispatch(eventActions.submitEvent(event, attendees));
      onSubmitted(event);
    };

    const removeEvent = () => {
      const { deleteEvent } = this.props;
      deleteEvent(event);
      this.setState({ visible: false });
    };

    const removeConfirm = () => {
      if (!visible) {
        this.setState({ visible: true });
      } else {
        this.setState({ visible: false });
      }
    };

    const contentHeight = event && event.inProgress ? '600px' : '680px';
    const bottomBarItemStyle = { margin: 'auto 0px auto 0px' };
    const bottomBarButtonStyle = {
      ...bottomBarItemStyle,
      margin: '0px 10px',
      height: '40px',
      float: 'right',
    };

    return (
      <>
        {event && (
          <Grid
            style={{ maxWidth: '1800px', marginLeft: 'auto', marginRight: 'auto' }}
            celled='internally'
            className='trainingEvent'
          >
            {loadingAttendees && (
              <Dimmer active inverted>
                <CareLoader loading={loadingAttendees} showText={false} />
              </Dimmer>
            )}
            <Grid.Row>
              <Grid
                style={{ padding: '24px 24px 0px', width: '100%', margin: '10px 10px 10px 0px' }}
              >
                <Grid.Column width={10}>
                  <h3 style={{ fontSize: 24 }}>
                    {event.finished || event.inProgress
                      ? 'EVENT COMPLETION CHECKLIST'
                      : 'EVENT INFO'}
                  </h3>
                </Grid.Column>
                {!event.signedOff && !event.inProgress && (
                  <Grid.Column width={6}>
                    {visible && (
                      <Modal
                        closeOnDimmerClick={false}
                        open
                        dimmer
                        size='tiny'
                        onClose={() => removeConfirm()}
                        className='confirmDialog'
                        style={{ marginTop: '-20vh' }}
                      >
                        <Modal.Header
                          style={{
                            backgroundColor: '#F16778',
                          }}
                          className='confirmHeader'
                        >
                          <Header textAlign='center' as='h2'>
                            Delete Event?
                          </Header>
                        </Modal.Header>
                        <Modal.Content>
                          <Grid centered>
                            <Grid.Column width={14}>
                              <Segment basic>
                                This will remove the event from all invited caregivers calendars.
                              </Segment>
                            </Grid.Column>
                          </Grid>
                        </Modal.Content>
                        <Modal.Actions style={{ backgroundColor: '#FFFFFF' }}>
                          <Grid centered>
                            <Grid.Column width={14} textAlign='center'>
                              <Button onClick={() => removeEvent()} className='care-green'>
                                Confirm &amp; Delete
                              </Button>
                              <br />
                              <Button
                                id='event-view-cancel-button'
                                onClick={() => removeConfirm()}
                                className='cancelBlue'
                              >
                                Cancel
                              </Button>
                            </Grid.Column>
                          </Grid>
                        </Modal.Actions>
                      </Modal>
                    )}
                    <div
                      style={{
                        width: '100%',
                        display: 'grid',
                        gridTemplateRows: '1fr',
                        gridTemplateColumns: '1fr 0.5fr',
                        justifyContent: 'stretch',
                        alignContent: 'center',
                        justifyItems: 'right',
                      }}
                    >
                      <Button
                        id='event-view-delete-button'
                        content='Delete this event'
                        basic
                        icon='trash'
                        iconPosition='left'
                        style={{
                          paddingRight: '5px',
                          border: 'none',
                          boxShadow: 'none',
                          float: 'right',
                          position: 'relative',
                        }}
                        onClick={removeConfirm}
                      />
                      <Button
                        id='event-view-save-button'
                        content='Save Changes'
                        color='green'
                        onClick={saveEvent}
                        disabled={!this.hasChanged(event)}
                      />
                    </div>
                  </Grid.Column>
                )}
              </Grid>
            </Grid.Row>
            <Grid.Row style={{ padding: '24px', height: contentHeight, overflow: 'hidden' }}>
              <Grid.Column width={8} style={{ height: 'fit-content' }}>
                {event && this.eventInfoAndDate()}
              </Grid.Column>
              <Grid.Column width={8}>{event && this.eventAttendees()}</Grid.Column>
            </Grid.Row>
            {(event.inProgress || event.signedOff) && (
              <Grid.Row>
                {!event.signedOff && (
                  <Grid.Column
                    style={{
                      position: 'relative',
                      padding: '1rem',
                      boxShadow: '0px -5px 16px 0px rgba(0,0,0,0.20)',
                      bottom: '-21px',
                    }}
                  >
                    <div>
                      <div style={bottomBarItemStyle}>
                        <p className='small gray'>Signing Off Supervisor</p>
                        <Dropdown
                          id='event-view-sign-off-supervisor-dropdown'
                          className='create-event-dropdown'
                          style={{ width: '48%' }}
                          pointing='top'
                          upward
                          options={employeesOptions}
                          selection
                          placeholder='Select Supervisor'
                          onChange={signOffSupervisorChanged}
                          value={event.signOffSupervisor}
                        />
                        <Button
                          id='event-view-submit-button'
                          style={{ ...bottomBarButtonStyle, borderRadius: '10px' }}
                          color='green'
                          content='Submit'
                          disabled={!this.validateEvent(event) || this.hasChanged(event)}
                          onClick={submitEvent}
                        />
                        <Button
                          id='event-view-save-changes-button'
                          style={{ ...bottomBarButtonStyle, borderRadius: '10px' }}
                          content='Save changes'
                          onClick={saveEvent}
                          disabled={!this.hasChanged(event)}
                        />
                        <p className='small gray'>
                          <br />
                          Saving changes will keep your current selections.
                          <br />
                          <b>Only Submit your changes once your class is completed.</b>
                        </p>
                      </div>
                    </div>
                  </Grid.Column>
                )}
                {event.signedOff && (
                  <div
                    style={{
                      padding: '12px 38px',
                      width: '100%',
                      textAlign: 'center',
                      boxShadow: '0px -5px 16px 0px rgba(0,0,0,0.20)',
                      position: 'relative',
                      bottom: '-21px',
                    }}
                  >
                    <h4 className='gray'>This event is signed off</h4>
                  </div>
                )}
              </Grid.Row>
            )}
          </Grid>
        )}
        <Modal
          closeOnDimmerClick={false}
          open={!!overridedEvent}
          onClose={() => this.setState({ overridedEvent: null })}
          style={{ marginTop: '-20vh' }}
        >
          <div>
            <h4>Do you want to save unsaved changes for this event?</h4>
            <p>{overridedEvent ? overridedEvent.title : ''}</p>
            <Button
              id='event-view-reminder-save-button'
              content='Save'
              onClick={() => {
                this.setState({ overridedEvent: null, doNotAskSaving: true });
                onChanged(overridedEvent);
              }}
            />
            <Button
              id='event-view-reminder-discard-button'
              content='Discard'
              onClick={() => {
                this.setState({ overridedEvent: null });
              }}
            />
          </div>
        </Modal>
      </>
    );
  }
}

EventView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  principal: PropTypes.shape().isRequired,
  employees: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  event: PropTypes.shape().isRequired,
  onChanged: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  attendees: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  invited: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  preferences: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loadingAttendees: PropTypes.bool.isRequired,
  attendeesNextPage: PropTypes.string.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  loadingEvents: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const { principal, employee, trainingEvent } = state;
  return {
    principal,
    employees: employee.employeeList,
    attendees: trainingEvent.attendees.available,
    attendeesNextPage: trainingEvent.attendees.nextPage,
    invited: trainingEvent.attendees.invited,
    preferences: trainingEvent.attendees.preferences,
    loadingAttendees: trainingEvent.loadingAttendees,
    attendeesError: trainingEvent.attendeesError,
    preferencesError: trainingEvent.preferencesError,
    loadingEvents: trainingEvent.loadingEvents,
  };
};

export default connect(mapStateToProps)(injectIntl(EventView));

import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Header, Grid, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './PrimaryContacts.css';

const name = c =>
  (c &&
    `${c.lastName || ''}, ${c.firstName || ''} ${c.status === 'suspended' ? '(Suspended)' : ''}`) ||
  '';

const primaryContactOptions = (employees, selected) => [
  {
    text: 'None',
    value: null,
  },
  ...employees
    .filter(e => e.isCoordinator || e.id === selected)
    .map(e => ({
      text: name(e),
      value: e.id,
      className: e.status === 'suspended' ? 'dropdown-suspended-employee' : '',
    })),
];

const primaryContactManagerOptions = (employees, selected) => [
  {
    text: 'None',
    value: null,
  },
  ...employees
    .filter(e => e.isManager || e.id === selected)
    .map(e => ({ text: name(e), value: e.id })),
];

const RecordsPrimaryContacts = ({
  object,
  employees,
  onValueChange,
  intl,
  editable,
  editability,
  isCaregiver,
}) => {
  const contacts = primaryContactOptions(employees, object.primaryContactId);
  const managers = primaryContactManagerOptions(employees, object.managerId);

  const primary = intl.formatMessage({ id: 'caregivers.records.primaryContact' });
  const manager = intl.formatMessage({ id: 'caregivers.records.primaryContactsManager' });
  const choose = intl.formatMessage({ id: 'dropdown.choose' });
  const fieldEditable = isCaregiver ? editability.primaryContact : editable;
  return (
    <Grid.Row>
      <Grid.Column width={8}>
        <Header size='tiny'>{primary}</Header>
        <Form.Dropdown
          id='primary-contact-id'
          selection
          upwards='true'
          placeholder={fieldEditable ? choose : '-'}
          name='primaryContactId'
          style={{ zIndex: '120', paddingLeft: fieldEditable ? '15px' : null }}
          options={contacts}
          value={object.primaryContactId}
          onChange={onValueChange}
          disabled={!fieldEditable}
          className='detailsBox'
        />
      </Grid.Column>
      <Grid.Column width={8}>
        <Header size='tiny'>{manager}</Header>
        <Form.Dropdown
          id='primary-contact-manager'
          selection
          upwards='true'
          placeholder={fieldEditable ? choose : '-'}
          style={{ zIndex: '120', paddingLeft: fieldEditable ? '15px' : null }}
          name='managerId'
          options={managers}
          value={object.managerId}
          onChange={onValueChange}
          disabled={!fieldEditable}
          className='detailsBox'
        />
      </Grid.Column>
    </Grid.Row>
  );
};

RecordsPrimaryContacts.defaultProps = {
  isCaregiver: false,
  editability: {},
};

RecordsPrimaryContacts.propTypes = {
  object: PropTypes.shape().isRequired,
  employees: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  editable: PropTypes.bool.isRequired,
  editability: PropTypes.arrayOf(PropTypes.shape()),
  onValueChange: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  isCaregiver: PropTypes.bool,
};

export default injectIntl(RecordsPrimaryContacts);

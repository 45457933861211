/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import SubTabHeader from './subcomponents/SubTabHeader';
import TileInformationDetails from './subcomponents/records/TileInformationDetails';
import TileInformationContact from './subcomponents/records/TileInformationContact';
import TileInformationAddress from './subcomponents/records/TileInformationAddress';
import TileGrid from './subcomponents/records/TileGrid';
import { CaregiverSurveyResult } from './subcomponents/SurveyReport';

class ClientInformation extends React.Component {
  constructor() {
    super();
    this.state = {
      isValid: false,
      isChanged: false,
      areFormsValid: false,
      latestSurvey: [],
    };
    this.validForms = { details: false, contacts: false, address: false };
    this.onValid = this.onValid.bind(this);
    this.onInvalid = this.onInvalid.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.onActive(this.props.tabIndex);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.editable) {
      this.validForms.details = true;
      this.validForms.address = true;
    }
    if (nextProps.surveyResults) {
      const latestSurvey = nextProps.surveyResults.filter(s =>
        moment(s.batch).isSame(moment().format('YYYY-MM-DD'))
      );
      this.setState({ latestSurvey });
    }
  }

  onValid(form) {
    this.validForms[form] = true;
    this.setState({ areFormsValid: Object.values(this.validForms).every(f => f === true) });
  }

  onInvalid(form) {
    this.validForms[form] = false;
    this.setState({ areFormsValid: false });
  }

  render() {
    const {
      intl,
      client,
      original,
      dispatch,
      onValueChange,
      onSave,
      saveEnabled,
      editable,
      isAdmin,
      surveyEnabled,
    } = this.props;
    const { areFormsValid, latestSurvey } = this.state;

    return (
      <>
        <SubTabHeader
          text={intl.formatMessage({ id: 'clients.records.information' }).toUpperCase()}
          buttonTitle={intl.formatMessage({ id: 'records.saveUpdates' })}
          buttonOnClick={onSave}
          buttonDisabled={!(saveEnabled && areFormsValid)}
        />
        {isAdmin && surveyEnabled ? (
          <TileGrid type='client'>
            <CaregiverSurveyResult surveyResults={latestSurvey} client />
            <TileInformationDetails
              original={original}
              person={client}
              editable={editable}
              onValueChange={onValueChange}
              onValid={this.onValid}
              onInvalid={this.onInvalid}
              type='client'
            />
            <TileInformationContact
              person={client}
              editable={editable}
              onValueChange={onValueChange}
              onValid={this.onValid}
              onInvalid={this.onInvalid}
              type='client'
            />
            <TileInformationAddress
              person={client}
              editable={editable}
              onValueChange={onValueChange}
              onValid={this.onValid}
              onInvalid={this.onInvalid}
              dispatch={dispatch}
              type='client'
            />
          </TileGrid>
        ) : (
          <TileGrid type='client'>
            <TileInformationContact
              person={client}
              editable={editable}
              onValueChange={onValueChange}
              onValid={this.onValid}
              onInvalid={this.onInvalid}
              type='client'
            />
            <TileInformationDetails
              original={original}
              person={client}
              editable={editable}
              onValueChange={onValueChange}
              onValid={this.onValid}
              onInvalid={this.onInvalid}
              type='client'
            />
            <TileInformationAddress
              person={client}
              editable={editable}
              onValueChange={onValueChange}
              onValid={this.onValid}
              onInvalid={this.onInvalid}
              dispatch={dispatch}
              type='client'
            />
          </TileGrid>
        )}
      </>
    );
  }
}

ClientInformation.defaultProps = {
  saveEnabled: false,
  editable: false,
  editableAttributes: false,
  onActive: () => {},
  isAdmin: false,
  surveyEnabled: false,
};

ClientInformation.propTypes = {
  intl: intlShape.isRequired,
  client: PropTypes.shape().isRequired,
  original: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  saveEnabled: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  onActive: PropTypes.func,
  tabIndex: PropTypes.number.isRequired,
  surveyResults: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isAdmin: PropTypes.bool,
  surveyEnabled: PropTypes.bool,
};

export default injectIntl(ClientInformation);

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Image } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import '../../CGCompliance.css';
import CareLoader from '../CareLoader';

const moment = require('moment');

const mmmmddyyyy = 'MMMM DD, YYYY';
const yyyymmdd = 'YYYY/MM/DD';
const locationIcon = '/compliance/MapPin.svg';
const phoneIcon = '/compliance/phone.svg';
const certificateIcon = '/compliance/certified.svg';

class ComplianceDOHCertifications extends React.Component {
  certification = (cert, index) => (
    <div id={index} key={index} className='listElement'>
      <Grid>
        <Grid.Row>
          <Grid.Column width={2}>
            <div style={{ marginLeft: '-8px', marginRight: '-16px' }}>
              <Image
                inline
                size='large'
                className='certificationIcon'
                name='certification'
                src={certificateIcon}
              />
            </div>
          </Grid.Column>
          <Grid.Column width={14}>
            <p className='large'>
              <b>{cert.type}</b>
            </p>
            <p className='smallGray'>Training Program</p>
            <p className='small'>
              <b>{cert.trainingProgram}</b>
            </p>
            <p className='small'>
              <Image inline size='large' className='iconImage' name='location' src={locationIcon} />
              {cert.address}
            </p>
            <p className='small'>
              <Image inline size='large' className='iconImage' name='phone' src={phoneIcon} />
              {cert.phone}
            </p>
            <br />
            <Grid columns={16} className='small'>
              <Grid.Row>
                <Grid.Column width={8}>
                  <p className='smallGray'>Certification Date</p>
                  {cert.certificationDate && (
                    <p>{moment(cert.certificationDate).format(mmmmddyyyy)}</p>
                  )}
                </Grid.Column>
                <Grid.Column width={8}>
                  <p className='smallGray'>Status Date</p>
                  {cert.statusDate && <p>{moment(cert.statusDate).format(mmmmddyyyy)}</p>}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );

  render() {
    const information = this.props.dohInfo ? this.props.dohInfo : {};

    return (
      <div className='infoPanel' key='certifications'>
        <p className='tileHeader'>CERTIFICATIONS</p>
        {!information || this.props.loadingDoh ? (
          <CareLoader loading centered showText={false} />
        ) : (
          <>
            {information.certifications &&
              information.certifications.map((cert, index) => this.certification(cert, index))}

            <br />
            <p className='smallGray'>Updated: {moment(information.modified).format(yyyymmdd)}</p>
          </>
        )}
      </div>
    );
  }
}

ComplianceDOHCertifications.defaultProps = {
  dohInfo: {},
  loadingDoh: true,
};

ComplianceDOHCertifications.propTypes = {
  dohInfo: PropTypes.shape(),
  loadingDoh: PropTypes.bool,
};

export default injectIntl(ComplianceDOHCertifications);

/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as types from '../constants/ActionTypes';
import { backend } from '../services/backend';
import { alertActions } from './index';

export interface INotesParams {
  token: string;
  notes: string;
}

function getApplicantsList(
  partialUpdate = false,
  startKey = undefined
): (dispatch: any) => Promise<void> {
  return async dispatch => {
    dispatch(request());

    try {
      const applicants = await backend.getApplicantsList(startKey);
      dispatch(success(applicants));
      if (applicants.lastKey) {
        dispatch(getApplicantsList(true, applicants.lastKey));
      }
    } catch (error) {
      dispatch(alertActions.error(error));
      dispatch(failure(error));
    }
  };

  function request(): {
    type: string;
    partialUpdate: boolean;
  } {
    return { type: types.GET_APPLICANTS_LIST_REQUEST, partialUpdate };
  }

  function success(
    results: any
  ): {
    type: string;
    results: any;
    partialUpdate: boolean;
  } {
    return { type: types.GET_APPLICANTS_LIST_SUCCESS, results, partialUpdate };
  }

  function failure(
    error: any
  ): {
    type: string;
    error: any;
    partialUpdate: boolean;
  } {
    return { type: types.GET_APPLICANTS_LIST_FAILURE, error, partialUpdate };
  }
}

function filterApplicantByName(
  name: string
): {
  type: string;
  name: string;
} {
  return { type: types.FILTER_APPLICANT_BY_NAME, name };
}

function filterApplicantByZip(
  zip: string
): {
  type: string;
  zip: string;
} {
  return { type: types.FILTER_APPLICANT_BY_ZIP, zip };
}

function applicantToProceed(token: string): (dispatch: any) => Promise<void> {
  return async dispatch => {
    dispatch(request(token));

    try {
      const response = await backend.applicantToProceed({ token });
      dispatch(success(response));
    } catch (error) {
      dispatch(alertActions.error(error));
      dispatch(failure(error));
    }
  };

  function request(
    givenToken: string
  ): {
    type: string;
    givenToken: string;
  } {
    return { type: types.APPLICANT_TO_PROCEED_STATUS_REQUEST, givenToken };
  }

  function success(
    response: any
  ): {
    type: string;
    response: any;
  } {
    return { type: types.APPLICANT_TO_PROCEED_STATUS_SUCCESS, response };
  }

  function failure(
    error: any
  ): {
    type: string;
    error: any;
  } {
    return { type: types.APPLICANT_TO_PROCEED_STATUS_FAILURE, error };
  }
}

function applicantDecline(token: string): (dispatch: any) => Promise<void> {
  return async dispatch => {
    dispatch(request(token));

    try {
      const response = await backend.applicantDecline({ token });
      dispatch(success(response));
    } catch (error) {
      dispatch(alertActions.error(error));
      dispatch(failure(error));
    }
  };

  function request(
    givenToken: string
  ): {
    type: string;
    givenToken: string;
  } {
    return { type: types.APPLICANT_DECLINE_STATUS_REQUEST, givenToken };
  }

  function success(
    response: any
  ): {
    type: string;
    response: any;
  } {
    return { type: types.APPLICANT_DECLINE_STATUS_SUCCESS, response };
  }

  function failure(
    error: any
  ): {
    type: string;
    error: any;
  } {
    return { type: types.APPLICANT_DECLINE_STATUS_FAILURE, error };
  }
}

function applicantReset(tokens: string[]): (dispatch: any) => Promise<void> {
  return async dispatch => {
    dispatch(request());

    try {
      backend.applicantReset({ tokens }).then(() => dispatch(success()));
    } catch (error) {
      dispatch(alertActions.error(error));
      dispatch(failure(error));
    }
  };

  function request(): {
    type: string;
  } {
    return { type: types.APPLICANT_RESET_STATUS_REQUEST };
  }

  function success(): (dispatch: any) => Promise<void> {
    return getApplicantsList();
  }

  function failure(
    error: any
  ): {
    type: string;
    error: any;
  } {
    return { type: types.APPLICANT_RESET_STATUS_FAILURE, error };
  }
}

function applicantDelete(tokens: string[]): (dispatch: any) => Promise<void> {
  return async dispatch => {
    dispatch(request());
    try {
      backend.applicantDelete({ tokens }).then(() => dispatch(success()));
    } catch (error) {
      dispatch(alertActions.error(error));
      dispatch(failure(error));
    }
  };

  function request(): {
    type: string;
  } {
    return { type: types.APPLICANT_RESET_STATUS_REQUEST };
  }

  function success(): (dispatch: any) => Promise<void> {
    return getApplicantsList();
  }

  function failure(
    error: any
  ): {
    type: string;
    error: any;
  } {
    return { type: types.APPLICANT_RESET_STATUS_FAILURE, error };
  }
}

function applicantApprove(tokens: string[]): (dispatch: any) => Promise<void> {
  return async dispatch => {
    dispatch(request());
    try {
      backend.applicantApprove({ tokens }).then(() => dispatch(success()));
    } catch (error) {
      dispatch(alertActions.error(error));
      dispatch(failure(error));
    }
  };

  function request(): {
    type: string;
  } {
    return { type: types.APPLICANT_RESET_STATUS_REQUEST };
  }

  function success(): (dispatch: any) => Promise<void> {
    return getApplicantsList();
  }

  function failure(
    error: any
  ): {
    type: string;
    error: any;
  } {
    return { type: types.APPLICANT_RESET_STATUS_FAILURE, error };
  }
}

function saveNotes(params: INotesParams): (dispatch: any) => Promise<void> {
  return async dispatch => {
    dispatch(request());
    try {
      backend.putApplicantNotes(params).then(() => dispatch(success(params)));
    } catch (error) {
      dispatch(alertActions.error(error));
      dispatch(failure(error));
    }
  };

  function request(): {
    type: string;
  } {
    return { type: types.APPLICANT_SAVE_NOTES_REQUEST };
  }

  function success(
    givenParams: INotesParams
  ): {
    type: string;
    givenParams: INotesParams;
  } {
    return { type: types.APPLICANT_SAVE_NOTES_SUCCESS, givenParams };
  }

  function failure(
    error: any
  ): {
    type: string;
    error: any;
  } {
    return { type: types.APPLICANT_SAVE_NOTES_FAILURE, error };
  }
}

export const applicantActions = {
  getApplicantsList,
  filterApplicantByName,
  filterApplicantByZip,
  applicantToProceed,
  applicantReset,
  applicantDecline,
  applicantDelete,
  applicantApprove,
  saveNotes,
};

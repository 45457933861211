/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Select, Grid, Header, Image, Form, Segment } from 'semantic-ui-react';
import { userActions, alertActions } from '../actions';
import { isValidEmail } from '../constants/Formats';
import WizardView from './subcomponents/WizardView';
import { history } from '../helpers';
import './Login.scss';
import TermsAndConditions from './TermsAndConditions';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      email: '',
      agencyId: false,
      submitted: false,
      viewIndex: 0,
      agencies: [],
      pendingTerms: false,
    };

    this.toggleMode = this.toggleMode.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAgencyChange = this.handleAgencyChange.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.schedulerLoginContent = this.schedulerLoginContent.bind(this);
    this.agencySelectorContent = this.agencySelectorContent.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { alert, agencies, pendingTerms } = nextProps;
    const { viewIndex, activeLoginTabIndex } = this.state;
    if (alert) {
      let msg;
      switch (true) {
        case alert.httpStatus === 400 && viewIndex === Login.views.login && alert.validationError:
          msg = 'The email and password combination entered was not recognized';
          break;
        case alert.httpStatus === 403 && viewIndex === Login.views.login:
          msg = 'The email and password combination entered was not recognized';
          break;
        case alert.httpStatus === 400 && viewIndex === Login.views.login:
          msg = alert.message;
          break;
        case alert.httpStatus < 500 && viewIndex === Login.views.login && activeLoginTabIndex === 0:
          msg = 'The email and password combination entered was not recognized';
          break;
        case alert.httpStatus === 404 &&
          (viewIndex === Login.views.passwordRecovery || viewIndex === Login.views.login):
          msg = 'The account was not found';
          break;
        case alert.httpStatus === 401 && alert.loggedOut:
          msg = '';
          break;
        default:
          msg = 'Failed to handle your request';
      }

      this.setState({
        alert: msg,
        submitted: false,
        recoverySubmitted: false,
      });
    } else {
      if (pendingTerms) {
        return this.setState({
          pendingTerms: true,
        });
      }
      if (agencies) {
        this.setState(
          {
            agencies: agencies.map(agency => ({
              key: agency.id,
              value: agency.id,
              text: agency.name,
            })),
          },
          () => (this.wizardView ? this.wizardView.changeForm(Login.views.agencySelector) : null)
        );
      } else if (this.state.recoverySubmitted && !nextProps.loading) {
        this.setState({
          recoverySubmitted: false,
          viewIndex: Login.views.recoverySent,
          pendingTerms: false,
        });
        this.wizardView.changeForm(Login.views.recoverySent);
      }
      this.setState({
        alert: null,
      });
    }
    return null;
  }

  onViewChanged = () => {};

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = e => {
    if (e) e.preventDefault();

    this.setState({ submitted: true });
    const { username, password, agencyId } = this.state;
    const { dispatch } = this.props;
    if (username && password) {
      dispatch(alertActions.clearErrors());

      dispatch(
        userActions.login(
          username,
          password,
          this.props.location.state ? this.props.location.state.from : null,
          agencyId
        )
      );
      this.setState({ pendingTerms: false });
    }
  };

  handleForgotPasswordSubmit = e => {
    e.preventDefault();
    const { email } = this.state;
    const { dispatch } = this.props;
    if (isValidEmail(email)) {
      dispatch(alertActions.clearErrors());
      dispatch(
        userActions.recover(email, () => {
          this.setState({ recoverySubmitted: true });
        })
      );
    } else {
      this.setState({
        alert: 'Please enter a valid email',
      });
    }
  };

  toggleMode = () => {
    let { viewIndex } = this.state;
    if (viewIndex === Login.views.login) viewIndex = Login.views.passwordRecovery;
    else viewIndex = Login.views.login;
    this.wizardView.changeForm(viewIndex);
    this.setState({ viewIndex });
  };

  dontHavePassword = () => {
    history.push('/elearning');
  };

  handleAgencyChange = (e, { value }) => {
    this.setState(
      {
        agencyId: value,
      },
      () => {
        this.handleSubmit();
      }
    );
  };

  agencySelectorContent() {
    return (
      <div style={{ marginTop: '60px' }}>
        <Header>CHOOSE AGENCY</Header>
        <Grid className='actionArea'>
          <Grid.Column verticalAlign='middle'>
            <Select
              placeholder='Choose'
              options={this.state.agencies}
              onChange={this.handleAgencyChange}
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  schedulerLoginContent() {
    const { username, password } = this.state;
    const { loading } = this.props;
    const enabled = username.length > 0 && password.length > 0;

    return (
      <div style={{ marginTop: '60px' }}>
        <Header>LOG IN</Header>
        <Grid className='actionArea'>
          <Grid.Column verticalAlign='middle'>
            <Form loading={loading} onSubmit={this.handleSubmit}>
              <Form.Input
                id='loginUsername'
                fluid
                size='large'
                placeholder='Email'
                name='username'
                value={username}
                onChange={this.handleChange}
              />
              <Form.Input
                id='loginPassword'
                fluid
                size='large'
                placeholder='Password'
                name='password'
                value={password}
                type='password'
                onChange={this.handleChange}
              />
              <Form.Button
                id='loginSubmitButton'
                className='care-blue'
                disabled={!enabled}
                primary
                content='Submit'
              />
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  renderContent(index) {
    const { email } = this.state;
    const { loading } = this.props;

    return (
      <div>
        {index === Login.views.agencySelector && this.agencySelectorContent()}
        {index === Login.views.login && this.schedulerLoginContent()}
        {index === Login.views.passwordRecovery && (
          <div style={{ marginTop: '60px' }}>
            <Header>PASSWORD RECOVERY</Header>
            <Grid className='actionArea'>
              <Grid.Column verticalAlign='middle'>
                <div className='prompt'>Enter the email you&apos;re using for your account.</div>
                <br />
                <Form loading={loading} onSubmit={this.handleForgotPasswordSubmit}>
                  <Form.Input
                    id='loginRecoveryEmail'
                    fluid
                    size='large'
                    placeholder='Email'
                    name='email'
                    value={email}
                    onChange={this.handleChange}
                  />
                  <Form.Button
                    id='loginRecoveryContinueButton'
                    className='care-blue'
                    primary
                    content='Continue'
                  />
                </Form>
              </Grid.Column>
            </Grid>
          </div>
        )}
        {index === Login.views.recoverySent && (
          <div style={{ marginTop: '60px' }}>
            <Header>THANK YOU!</Header>
            <Grid className='actionArea'>
              <Grid.Row verticalAlign='middle'>
                We&apos;ve sent password reset instructions to your email address. If no email is
                received within ten minutes, check that the submitted address is correct.
              </Grid.Row>
              <Grid.Row verticalAlign='middle' columns='2'>
                <Image src='/icon_mailsent.svg' className='recoverySent' />
              </Grid.Row>
              <Grid.Row />
            </Grid>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { terms } = this.props;
    const { viewIndex, alert, pendingTerms } = this.state;

    const bottomMsg = viewIndex !== Login.views.login ? 'Back to Log in' : 'forgot your password?';

    return (
      <div className='login-form'>
        <Grid stretched centered textAlign='center'>
          <Grid.Row>
            <Grid.Column width='three' verticalAlign='top'>
              <Header as='h2'>
                <Image src='/logo_login.svg' />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width='six' verticalAlign='middle'>
              {alert && (
                <Segment id='loginAlert' basic className='error'>
                  {alert}
                </Segment>
              )}
              {pendingTerms ? (
                <Segment className='termsBox'>
                  <div id='wizardParent'>
                    <TermsAndConditions terms={terms} submit={this.handleSubmit} />
                    <Image src='/circlesLogin.svg' fluid className='footer' />
                  </div>
                </Segment>
              ) : (
                <>
                  <Segment className='contentBox'>
                    <div id='wizardParent'>
                      <WizardView
                        onViewChanged={this.onViewChanged}
                        renderContent={this.renderContent}
                        ref={input => {
                          this.wizardView = input;
                        }}
                      />
                      <Image src='/circlesLogin.svg' fluid className='footer' />
                    </div>
                  </Segment>
                  <Segment
                    id='loginForgetPassword'
                    basic
                    className='forgetPassword'
                    as='a'
                    onClick={this.toggleMode}
                  >
                    {bottomMsg}
                  </Segment>

                  <Segment
                    id='loginDontHavePassword'
                    basic
                    className='forgetPassword'
                    as='a'
                    onClick={this.dontHavePassword}
                  >
                    I don&#39;t have a password
                  </Segment>
                </>
              )}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className='footer-page' stretched verticalAlign='bottom'>
            <img className='footer-page-image' src='/login_footer.svg' alt='Bubbles page footer' />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    message: PropTypes.string,
    success: PropTypes.bool,
    error: PropTypes.bool,
  }),
  loading: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      from: PropTypes.shape(),
    }),
  }),
  terms: PropTypes.shape({
    id: PropTypes.number,
    feature: PropTypes.string,
    policyUrl: PropTypes.string,
    status: PropTypes.string,
    termsUrl: PropTypes.string,
    presentation: PropTypes.shape({
      body: PropTypes.string,
      buttonLabel: PropTypes.string,
      confirmationLabel: PropTypes.string,
      policyLabel: PropTypes.string,
      termsLabel: PropTypes.string,
      title: PropTypes.string,
      canCancel: PropTypes.bool,
      defaultApproved: PropTypes.bool,
    }),
  }),
};

Login.defaultProps = {
  loading: false,
  alert: undefined,
  location: {},
  terms: {},
};

Login.views = {
  login: 0,
  passwordRecovery: 1,
  recoverySent: 2,
  agencySelector: 3,
};

const mapStateToProps = state => {
  const { alert, principal } = state;
  const lastAlert = alert ? alert[alert.length - 1] : null;

  const { loading, agencies, pendingTerms, terms } = principal;
  return {
    alert: lastAlert,
    loading,
    agencies,
    pendingTerms,
    terms,
  };
};

export default connect(mapStateToProps)(Login);

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';
import { Form, Grid, Header, Checkbox } from 'semantic-ui-react';
import '../TrainPref.css';
import './BotSettings.css';
import { RecordsTile, RecordsHeader } from '../../styles/records';

export const reminderTypes = { 0: 'none', 1: 'after_manual_invite' };

let changedRows = {};

class BotSettings extends React.Component {
  constructor(props) {
    super(props);

    this.onValueChanged.bind(this);
  }

  onValueChanged = (name, value) => {
    changedRows[name] = true;
    this.props.onValueUpdated(name, value);
  };

  render() {
    const {
      shiftReminderTime,
      registrationReminderTime,
      selectedShiftReminder,
      selectedRegistrationReminder,
      registrationReminderMaxAttempts,
      shiftReminderMaxAttempts,
      shiftReminderAlertEnabled,
      shiftReminderAlertTiming,
      dirty,
    } = this.props;

    if (!dirty) changedRows = {}; // clear changed rows, as data has been saved

    const registrationReminderTimeInHours = registrationReminderTime / 60;
    const shiftReminderAlertTimingInHours = shiftReminderAlertTiming / 60;
    const changedStyle = 'bot-table-item-updated';

    const shiftReminderTimeSliderText = 'Shift reminder delay ';
    const minutes = '(minutes)';

    const regReminderTimeSliderText = 'Registration reminder delay ';
    const hours = '(hours)';

    const MaxRemindersText = 'Maximum number of reminders:';

    const regReminderBody =
      'If a caregiver does not register for the app, SMS and email reminders will be sent by the system to drive them to take action.';
    const reminderBody =
      'When an invitation is sent to a caregiver they will receive reminders every set delay period until they respond or maximum reminders is reached';

    const shiftAlarmTimingText = 'Alert time before shift start';
    const upComingShiftBody =
      'The caregiver will receive notification of their upcoming shift on the defined time period prior to shift start time.';

    const shiftAlarmBody =
      'If caregiver does not respond to a shift invitation, reminders will be automatically sent by the system to drive them to take action.';
    const botSettings = 'Bot settings';
    const registrationRemainder = 'Automated App Registration Reminders';
    const ShiftReminder = 'Automated Shift Invitation Reminders';
    const upComingShiftReminderHeader = 'Up-Coming shift Reminders';

    const robotIcon = (
      <div className='robot-container'>
        <div className='robot-circle' />
        <div className='robot-antenna' />
        <div className='robot-antenna-mast' />
        <div className='robot-head'>
          <div className='robot-face' />
        </div>
        <div className='robot-body'>
          <div className='robot-inside-box'>
            <div className='robot-lights' />
          </div>
        </div>
        <div className='robot-arms' />
        <div className='robot-legs'>
          <div className='robot-wheel' />
        </div>
      </div>
    );

    return (
      <Grid padded columns={16} className='col-trainings-preferences'>
        <div className='bot-cards-container'>
          {this.props.children}
          <RecordsTile className='bot-setting-tile'>
            <div className='bot-setting-header-container'>
              {robotIcon}
              <Header size='tiny' className='headerText bot-headerText'>
                {botSettings}
              </Header>
              <RecordsHeader className='bot-setting-header'>{ShiftReminder}</RecordsHeader>
              <Form>
                <Form.Group grouped widths='equal'>
                  <div className='check-box-field'>
                    <Checkbox
                      id='shiftReminders'
                      checked={selectedShiftReminder === 1}
                      onChange={() => {
                        const value = selectedShiftReminder === 1 ? 0 : 1;
                        return this.onValueChanged('selectedShiftReminder', value);
                      }}
                      toggle
                    />
                  </div>
                </Form.Group>
              </Form>
            </div>
            <hr className='bot-hr' />
            <Grid.Row
              columns={2}
              className={dirty && changedRows.selectedShiftReminder ? changedStyle : ''}
            >
              <Grid.Column width={5}>
                <p className='bot-setting-body'>{shiftAlarmBody}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row
              columns={2}
              className={dirty && changedRows.shiftReminderTime ? changedStyle : ''}
            >
              <div className='tale-mini-header-container'>
                <p className='bot-setting-body'>
                  <bold className='bot-mini-header'>{shiftReminderTimeSliderText}</bold>
                  {minutes}
                </p>
              </div>
              <div className='tale-remainder-body'>
                <p className='remainder-body'>{reminderBody}</p>
              </div>

              <Form>
                <Form.Group grouped widths='equal'>
                  <div className='field'>
                    <InputRange
                      disabled={selectedShiftReminder === 0}
                      id='shiftReminderTimingRange'
                      step={10}
                      maxValue={90}
                      minValue={10}
                      value={shiftReminderTime}
                      onChange={value => this.onValueChanged('shiftReminderTime', value)}
                    />
                  </div>
                </Form.Group>
              </Form>
            </Grid.Row>
            <Grid.Row
              columns={2}
              className={dirty && changedRows.shiftReminderMaxAttempts ? changedStyle : ''}
            >
              <div className='bot-settings-text-and-input'>
                <div className='tale-mini-header-container'>
                  <p className='bot-setting-body'>
                    <bold className='bot-mini-header'>{MaxRemindersText}</bold>
                  </p>
                </div>
                <Form className='bot-input-reminds-form'>
                  <Form.Group grouped widths='equal'>
                    <Form.Input
                      disabled={selectedShiftReminder === 0}
                      className='rate-input-field'
                      placeholder='Reminders'
                      name='shiftReminderMaxAttemptsRange'
                      id='shiftReminderMaxAttemptsRange'
                      type='number'
                      value={shiftReminderMaxAttempts <= 0 ? 0 : shiftReminderMaxAttempts}
                      onChange={(_, { value }) =>
                        this.onValueChanged('shiftReminderMaxAttempts', value <= 0 ? 0 : value)
                      }
                    />
                  </Form.Group>
                </Form>
                <p className='remainder-body'>
                  After an initial shift invitation is sent there will be
                  {` ${shiftReminderMaxAttempts}`} follow up reminders delivered at the interval
                  period set above.
                </p>
              </div>
            </Grid.Row>
          </RecordsTile>
          <RecordsTile className='bot-setting-tile'>
            <div className='bot-setting-header-container'>
              {robotIcon}
              <Header size='tiny' className='headerText bot-headerText'>
                {botSettings}
              </Header>
              <RecordsHeader className='bot-setting-header'>
                {upComingShiftReminderHeader}
              </RecordsHeader>
              <Form>
                <Form.Group grouped widths='equal'>
                  <div className='check-box-field'>
                    <Checkbox
                      id='shiftReminderAlertEnabled'
                      checked={shiftReminderAlertEnabled}
                      onChange={() =>
                        this.onValueChanged('shiftReminderAlertEnabled', !shiftReminderAlertEnabled)
                      }
                      toggle
                    />
                  </div>
                </Form.Group>
              </Form>
            </div>
            <hr className='bot-hr' />
            <Grid.Row
              columns={3}
              className={
                dirty &&
                (changedRows.shiftReminderAlertEnabled || changedRows.shiftReminderAlertTiming)
                  ? changedStyle
                  : ''
              }
            >
              <Grid.Column width={2}>
                <Form>
                  <div className='tale-mini-header-container'>
                    <p className='bot-setting-body'>
                      <bold className='bot-mini-header'>{shiftAlarmTimingText}</bold>
                      {hours}
                    </p>
                  </div>
                  <Form.Group grouped widths='equal'>
                    <div className='field' style={{ marginTop: '12px' }}>
                      <p className='remainder-body'>{upComingShiftBody}</p>
                      <InputRange
                        disabled={!shiftReminderAlertEnabled}
                        id='shiftReminderAlertRange'
                        step={1}
                        maxValue={24}
                        minValue={1}
                        value={shiftReminderAlertTimingInHours}
                        onChange={value =>
                          this.onValueChanged('shiftReminderAlertTiming', value * 60)
                        }
                      />
                    </div>
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </RecordsTile>
          <RecordsTile className='bot-setting-tile'>
            <div className='bot-setting-header-container'>
              {robotIcon}
              <Header size='tiny' className='headerText bot-headerText'>
                {botSettings}
              </Header>
              <RecordsHeader className='bot-setting-header'>{registrationRemainder}</RecordsHeader>
              <Form>
                <Form.Group grouped widths='equal'>
                  <div className='check-box-field'>
                    <Checkbox
                      id='registrationReminders'
                      checked={selectedRegistrationReminder === 1}
                      onChange={() => {
                        const value = selectedRegistrationReminder === 1 ? 0 : 1;
                        return this.onValueChanged('selectedRegistrationReminder', value);
                      }}
                      toggle
                    />
                  </div>
                </Form.Group>
              </Form>
            </div>
            <hr className='bot-hr' />
            <Grid.Row
              columns={2}
              className={dirty && changedRows.selectedRegistrationReminder ? changedStyle : ''}
            >
              <Grid.Column width={5}>
                <p className='bot-setting-body'>{regReminderBody}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row
              columns={2}
              className={dirty && changedRows.registrationReminderTime ? changedStyle : ''}
            >
              <Grid.Column width={5}>
                <div className='tale-mini-header-container'>
                  <p className='bot-setting-body'>
                    <bold className='bot-mini-header'>{regReminderTimeSliderText}</bold>
                    {hours}
                  </p>
                </div>
                <div className='tale-remainder-body'>
                  <p className='remainder-body'>{reminderBody}</p>
                </div>
                <Form>
                  <Form.Group grouped widths='equal'>
                    <div className='field'>
                      <InputRange
                        disabled={selectedRegistrationReminder === 0}
                        id='registrationReminderTimingRange'
                        step={1}
                        maxValue={24}
                        minValue={1}
                        value={registrationReminderTimeInHours}
                        onChange={value =>
                          this.onValueChanged('registrationReminderTime', value * 60)
                        }
                      />
                    </div>
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row
              columns={2}
              className={dirty && changedRows.registrationReminderMaxAttempts ? changedStyle : ''}
            >
              <div className='bot-settings-text-and-input'>
                <div className='tale-mini-header-container'>
                  <p className='bot-setting-body'>
                    <bold className='bot-mini-header'>{MaxRemindersText}</bold>
                  </p>
                </div>
                <Form className='bot-input-reminds-form'>
                  <Form.Group grouped widths='equal'>
                    <Form.Input
                      disabled={selectedRegistrationReminder === 0}
                      className='rate-input-field'
                      placeholder='Reminders'
                      name='registrationReminderMaxAttemptsRange'
                      id='registrationReminderMaxAttemptsRange'
                      type='number'
                      value={
                        registrationReminderMaxAttempts <= 0 ? 0 : registrationReminderMaxAttempts
                      }
                      onChange={(_, { value }) =>
                        this.onValueChanged(
                          'registrationReminderMaxAttempts',
                          value <= 0 ? 0 : value
                        )
                      }
                    />
                  </Form.Group>
                </Form>
                <p className='remainder-body'>
                  After an initial reminder is sent there will be
                  {` ${registrationReminderMaxAttempts}`} follow up reminders delivered at the delay
                  period set above.
                </p>
              </div>
            </Grid.Row>
          </RecordsTile>
        </div>
      </Grid>
    );
  }
}

BotSettings.propTypes = {
  onValueUpdated: PropTypes.func.isRequired,
  shiftReminderTime: PropTypes.number.isRequired,
  registrationReminderTime: PropTypes.number.isRequired,
  selectedShiftReminder: PropTypes.number.isRequired,
  selectedRegistrationReminder: PropTypes.number.isRequired,
  registrationReminderMaxAttempts: PropTypes.number.isRequired,
  shiftReminderMaxAttempts: PropTypes.number.isRequired,
  shiftReminderAlertEnabled: PropTypes.bool.isRequired,
  shiftReminderAlertTiming: PropTypes.number.isRequired,
  dirty: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export const getKeyOfReminderMode = mode =>
  Number(Object.keys(reminderTypes).find(k => reminderTypes[k] === mode));

export default BotSettings;

// eslint-disable-next-line max-classes-per-file
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import URLSearchParams from 'url-search-params';
import React from 'react';
import {
  Visibility,
  Image,
  Table,
  GridColumn,
  GridRow,
  Grid,
  Form,
  Button,
  Dimmer,
} from 'semantic-ui-react';
import CareLoader from './CareLoader';
import Overlay from './Overlay';
import { history } from '../../helpers/history';
import '../../styles/survey.css';
import { formatPhoneNumber } from '../../constants/Formats';
import { backend } from '../../services/backend';
import { alertActions } from '../../actions';

const DATE_FORMAT = 'YYYY-MM-DD';

const getFileName = batch => `${batch}-survey-report.csv`;

const phoneNumberToTableCell = phone => {
  if (!phone) {
    return '';
  }

  return phone
    .split(',')
    .map(x => formatPhoneNumber(x))
    .join(',');
};

const nextShiftDate = (startTime, timezone) =>
  startTime
    ? moment(startTime)
        .tz(timezone || moment.tz.guess())
        .format('MM/DD/YYYY')
    : '';

const formatCsv = csvData =>
  csvData && csvData.length
    ? csvData.map(x => ({
        ...x,
        response: x.response ? JSON.stringify(x.response).replace(/["{}]/g, '') : '',
        mobileNumber: phoneNumberToTableCell(x.mobileNumber),
        homeNumber: phoneNumberToTableCell(x.homeNumber),
        sentToNumber: phoneNumberToTableCell(x.sentToNumber),
        nextShiftDate: nextShiftDate(x.nextShiftStart, x.nextShiftTimezone),
      }))
    : csvData;

const sortByName = data =>
  data && data.length
    ? data.sort((a, b) => {
        const aName = `${a.firstName}${a.lastName}`;
        const bName = `${b.firstName}${b.lastName}`;
        if (aName > bName) {
          return 1;
        }
        if (aName < bName) {
          return -1;
        }
        return 0;
      })
    : data;

class SurveySummary extends React.Component {
  constructor(props) {
    super(props);
    const surveyDate = moment();
    const batch = surveyDate.format(DATE_FORMAT);

    this.state = {
      csvData: [],
      batch,
      surveyDate,
      surveySummary: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    const { surveyDate } = this.state;
    if (prevState.surveyDate !== surveyDate) {
      this.refresh();
    }
  }

  onSurveyDateChange = surveyDate => {
    this.setState({ surveyDate, batch: surveyDate.format(DATE_FORMAT) });
  };

  setDownloadLinkRef = element => {
    this.downloadLinkRef = element;
  };

  doClick = async () => {
    const { batch } = this.state;
    const { type } = this.props;
    const csvData = formatCsv(sortByName(await backend.getSurveyDetails(batch, type)));
    this.setState({ csvData }, () => {
      this.downloadLinkRef.link.click();
    });
  };

  refresh = async () => {
    const { type, dispatch } = this.props;
    const { surveyDate } = this.state;
    const formattedDate = moment(surveyDate).format(DATE_FORMAT);

    this.setState({ loading: true });
    try {
      const surveySummary = await backend.getSurveySummery(type, formattedDate);
      this.setState({ surveySummary, loading: false });
    } catch (e) {
      this.setState({ loading: false });
      dispatch(alertActions.error(e));
    }
  };

  render() {
    const { surveyDate, surveySummary, csvData, batch, loading } = this.state;
    const { type } = this.props;

    if (!surveySummary) {
      return (
        <div className='survey-summary-tile'>
          <div className='survey-summary-tile-header'>
            <div className='survey-summary-header-grid'>
              <div>
                <Image
                  centered
                  ui={false}
                  src='/covid-icon-red.png'
                  className='survey-covid-icon'
                />
              </div>
              <div>
                <span>
                  Daily COVID-19 Survey Report <br />
                  <p>{`${type.toUpperCase()} RESPONSES`}</p>
                </span>
              </div>
              <div />
            </div>
          </div>
          <div className='survey-summary-loading'>
            {loading && <CareLoader loading={loading} showText={false} />}
          </div>
        </div>
      );
    }

    const fileName = getFileName(batch);
    const data = surveySummary.find(x => x.batch === batch) || {
      totalCount: 0,
      failCount: 0,
      passCount: 0,
    };

    return (
      <div className='survey-summary-tile'>
        <CSVLink
          style={{ display: 'none' }}
          ref={this.setDownloadLinkRef}
          data={csvData}
          filename={fileName}
        />

        <div className='survey-summary-tile-header'>
          <div className='survey-summary-header-grid'>
            <div>
              <Image centered ui={false} src='/covid-icon-red.png' className='survey-covid-icon' />
            </div>
            <div>
              <span>
                Daily COVID-19 Survey Report <br />
                <p>{`${type.toUpperCase()} RESPONSES`}</p>
              </span>
            </div>
            <div style={{ justifySelf: 'end' }}>
              <SurveyDatePicker surveyDate={surveyDate} onChange={this.onSurveyDateChange} />
            </div>
          </div>
        </div>

        <div className='survey-summary-date-row'>
          <p>{moment(surveyDate).format('ddd DD MMM YYYY')}</p>
          <div style={{ justifySelf: 'end' }}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href=''
              onClick={e => {
                e.preventDefault();
                this.doClick();
              }}
              className='survey-export-link'
            >
              <span>Export Report</span>
            </a>
          </div>
        </div>

        <div className='survey-summary-container'>
          <header className='survey-summary-container-header'>
            <div className='survey-summary-container-header-grid'>
              <div className='survey-summary-results'>Result</div>
              <div className='survey-summary-amount'>Amount</div>
            </div>
          </header>
          <section className='survey-summary-container-section'>
            <div className='survey-summary-container-section-row'>
              <div className='survey-summary-empty survey-summary-results'>
                <span>Not Completed</span>
              </div>
              <div className='survey-summary-result-column survey-summary-amount-res'>
                {data.totalCount - data.failCount - data.passCount}
              </div>
            </div>
            <div className='survey-summary-container-section-row'>
              <div className='survey-summary-pass survey-summary-results'>
                <span>Pass</span>
              </div>
              <div className='survey-summary-result-column survey-summary-amount-res'>
                {data.passCount || 0}
              </div>
            </div>
            <div className='survey-summary-container-section-row'>
              <div className='survey-summary-fail survey-summary-results'>
                <span>Fail</span>
              </div>
              <div
                className={
                  data.failCount > 0
                    ? 'survey-summary-result-column-attention survey-summary-result-column'
                    : 'survey-summary-result-column survey-summary-amount-res'
                }
              >
                {data.failCount || 0}
              </div>
            </div>
          </section>
        </div>
        <div style={{ justifySelf: 'end' }} className='survey-summary-actions'>
          <Button
            size='tiny'
            className='care-green'
            onClick={() => {
              window.open(`/reports/survey?type=${type}&surveyDate=${batch}`, '_blank');
            }}
          >
            View Full Report
          </Button>
        </div>
      </div>
    );
  }
}

SurveySummary.propTypes = {
  type: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const SurveyDatePicker = ({ surveyDate, onChange }) => (
  <DatePicker
    customInput={<Form.Input value={surveyDate} />}
    id='survey-date-picker'
    selected={surveyDate}
    showMonthDropdown
    showYearDropdown
    dropdownMode='select'
    popperPlacement='top-end'
    dateFormat={DATE_FORMAT}
    maxDate={moment()}
    onChange={onChange}
  />
);

SurveyDatePicker.propTypes = {
  surveyDate: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};

const getSurveyResponseStatus = response => {
  if (response === true) {
    return (
      <div className='survey-summary-fail survey-summary-amount-res'>
        <span>Fail</span>
      </div>
    );
  }

  if (response === false) {
    return (
      <div className='survey-summary-pass survey-summary-amount-res'>
        <span>Pass</span>
      </div>
    );
  }

  return (
    <div className='survey-summary-empty survey-summary-amount-res'>
      <span>--</span>
    </div>
  );
};

const CaregiverSurveyResult = ({ surveyResults, client }) => {
  const lastSurveyResult =
    surveyResults && surveyResults.length ? surveyResults[0].result : 'Not Completed';
  const { q1, q2, q3, q4, q5, q6, q7 } =
    surveyResults && surveyResults.length ? surveyResults[0].response || {} : {};

  let img = '';
  if (lastSurveyResult === 'PASS') {
    img = (
      <img alt='' src='/icon_check_yes.svg' style={{ marginTop: '0.2em', marginRight: '1.5em' }} />
    );
  }

  if (lastSurveyResult === 'FAIL') {
    img = <img alt='' src='/Attention.svg' style={{ marginRight: '1.5em' }} />;
  }

  return (
    <div className='survey-cg-grid'>
      <div className='survey-cg-tile'>
        <div className='survey-cg-tile-header'>
          <div className='survey-cg-header-grid'>
            <div>
              <Image centered ui={false} src='/covid-icon-red.png' className='survey-covid-icon' />
            </div>
            <div>{client ? 'Latest' : 'Daily'} COVID-19 Survey Report</div>
          </div>
        </div>

        <div className='survey-cg-date-row'>
          <div>{moment().format('ddd DD MMM YYYY')}</div>
          <div style={{ justifySelf: 'end' }}>{lastSurveyResult}</div>
          <div style={{ justifySelf: 'end' }}>{img}</div>
        </div>

        <div className='survey-summary-container'>
          <header className='survey-summary-container-header'>
            <div className='survey-summary-container-header-grid'>
              <div className='survey-summary-results'>Question</div>
              <div className='survey-summary-amount'>Result</div>
            </div>
          </header>
          <section className='survey-summary-container-section'>
            <div className='survey-summary-container-section-row'>
              <div className='survey-summary-results'>Question 1</div>
              {getSurveyResponseStatus(q1)}
            </div>
            <div className='survey-summary-container-section-row'>
              <div className='survey-summary-results'>Question 2</div>
              {getSurveyResponseStatus(q2)}
            </div>
            <div className='survey-summary-container-section-row'>
              <div className='survey-summary-results'>Question 3</div>
              {getSurveyResponseStatus(q3)}
            </div>
            {client && (
              <>
                <div className='survey-summary-container-section-row'>
                  <div className='survey-summary-results'>Question 4</div>
                  {getSurveyResponseStatus(q4)}
                </div>
                <div className='survey-summary-container-section-row'>
                  <div className='survey-summary-results'>Question 5</div>
                  {getSurveyResponseStatus(q5)}
                </div>
                <div className='survey-summary-container-section-row'>
                  <div className='survey-summary-results'>Question 6</div>
                  {getSurveyResponseStatus(q6)}
                </div>
                <div className='survey-summary-container-section-row'>
                  <div className='survey-summary-results'>Question 7</div>
                  {getSurveyResponseStatus(q7)}
                </div>
              </>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

CaregiverSurveyResult.defaultProps = {
  client: false,
};

CaregiverSurveyResult.propTypes = {
  surveyResults: PropTypes.shape().isRequired,
  client: PropTypes.bool,
};

// eslint-disable-next-line react/no-multi-comp
class SurveyReport extends React.Component {
  constructor(props) {
    super(props);

    let { surveyDate, type } = props;

    if (!surveyDate && props.location && props.location.search) {
      const searchParams = new URLSearchParams(props.location.search);
      surveyDate = moment(searchParams.get('surveyDate'));
      type = searchParams.get('type');
    }

    this.state = {
      data: null,
      fullData: null,
      page: 1,
      pageSize: 100,
      totalCount: null,
      tableRendering: false,
      surveyDate: surveyDate || moment(),
      type,
      loading: false,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  onDateChange = date => {
    this.setState({ surveyDate: date });
  };

  refresh = async () => {
    const { surveyDate, pageSize, type } = this.state;
    const batch = surveyDate.format(DATE_FORMAT);
    this.setState({ loading: true });
    const data = await backend.getSurveyDetails(batch, type);
    const sorted = sortByName(data);

    this.setState({
      page: 1,
      data: sorted.slice(0, pageSize),
      totalCount: sorted.length,
      fullData: sorted,
      loading: false,
    });
  };

  responseToTableCell = response => {
    const { type } = this.state;
    if (!response) {
      return '';
    }

    const { q1, q2, q3, q4, q5, q6, q7 } = response;
    return (
      <div
        className={`${type === 'clients' ? 'client-survey-response-grid' : 'survey-response-grid'}`}
      >
        <div className='survey-response-label'> Q1:</div>
        <div className={q1 ? 'survey-response-fail' : 'survey-response-pass'} />
        <div className='survey-response-label'> Q2:</div>
        <div className={q2 ? 'survey-response-fail' : 'survey-response-pass'} />
        <div className='survey-response-label'> Q3:</div>
        <div className={q3 ? 'survey-response-fail' : 'survey-response-pass'} />
        {type === 'clients' && (
          <>
            <div className='survey-response-label'> Q4:</div>
            <div className={q4 ? 'survey-response-fail' : 'survey-response-pass'} />
            <div className='survey-response-label'> Q5:</div>
            <div className={q5 ? 'survey-response-fail' : 'survey-response-pass'} />
            <div className='survey-response-label'> Q6:</div>
            <div className={q6 ? 'survey-response-fail' : 'survey-response-pass'} />
            <div className='survey-response-label'> Q7:</div>
            <div className={q7 ? 'survey-response-fail' : 'survey-response-pass'} />
          </>
        )}
      </div>
    );
  };

  resultToTableCell = result => {
    if (result === 'PASS') {
      return (
        <div className='survey-result-grid'>
          <div className='survey-result-pass' />
          <div>PASS</div>
        </div>
      );
    }

    if (result === 'FAIL') {
      return (
        <div className='survey-result-grid'>
          <div className='survey-result-fail' />
          <div>FAIL</div>
        </div>
      );
    }

    return (
      <div className='survey-result-grid'>
        <div className='survey-result-empty' />
        <div>--</div>
      </div>
    );
  };

  onNameClick = id => {
    const { type } = this.state;
    if (type === 'employees') {
      return history.push(`/home/profile?employeeId=${id}`);
    }
    return history.push(`/home/${type}/records/${id}`);
  };

  header = () => {
    const { type } = this.state;
    if (type === 'clients') {
      return [
        {
          id: 'survey-details-client',
          key: 'client',
          content: 'Client',
        },
        {
          id: 'survey-details-email',
          key: 'email',
          content: 'Email',
          textAlign: 'center',
        },
        {
          id: 'survey-details-called-to-phone',
          key: 'called-to-phone',
          content: 'Called to Phone',
        },
        {
          id: 'survey-details-primary-contact',
          key: 'primary-contact',
          content: 'Primary Contact',
        },
        {
          id: 'survey-details-responses',
          key: 'responses',
          content: 'Responses',
          textAlign: 'center',
        },
        {
          id: 'survey-details-result',
          key: 'result',
          content: 'Result',
          textAlign: 'center',
        },
      ];
    }
    if (type === 'employees') {
      return [
        {
          id: 'survey-details-employee',
          key: 'employee',
          content: 'employee',
        },
        {
          id: 'survey-details-email',
          key: 'email',
          content: 'Email',
          textAlign: 'center',
        },
        {
          id: 'survey-details-sent-to-number',
          key: 'sent-to-number',
          content: 'Sent to Number',
        },
        {
          id: 'survey-details-mobile-phone',
          key: 'mobile-phone',
          content: 'Mobile Phone',
        },
        {
          id: 'survey-details-home-phone',
          key: 'home-phone',
          content: 'Home Phone',
        },
        {
          id: 'survey-details-staff-status',
          key: 'staff-status',
          content: 'Staff Status',
        },
        {
          id: 'survey-details-employee-status',
          key: 'employee-status',
          content: 'employee Status',
        },
        {
          id: 'survey-details-disciplines',
          key: 'disciplines',
          content: 'Disciplines',
        },
        {
          id: 'survey-details-coordinator',
          key: 'is-coordinator',
          content: 'Coordinator',
        },
        {
          id: 'survey-details-manager',
          key: 'is-manager',
          content: 'HR-Manager',
        },
        {
          id: 'survey-details-responses',
          key: 'responses',
          content: 'Responses',
          style: { width: '160px' },
          textAlign: 'center',
        },
        {
          id: 'survey-details-result',
          key: 'result',
          content: 'Result',
          textAlign: 'center',
        },
      ];
    }
    return [
      {
        id: 'survey-details-caregiver',
        key: 'caregiver',
        content: 'Caregiver',
      },
      {
        id: 'survey-details-email',
        key: 'email',
        content: 'Email',
        textAlign: 'center',
      },
      {
        id: 'survey-details-sent-to-phone',
        key: 'sent-to-phone',
        content: 'Sent to Phone',
      },
      {
        id: 'survey-details-mobile-phone',
        key: 'mobile-phone',
        content: 'Mobile Phone',
      },
      {
        id: 'survey-details-home-phone',
        key: 'home-phone',
        content: 'Home Phone',
      },
      {
        id: 'survey-details-staff-status',
        key: 'staff-status',
        content: 'Staff Status',
      },
      {
        id: 'survey-details-caregiver-status',
        key: 'caregiver-status',
        content: 'Caregiver Status',
      },
      {
        id: 'survey-details-disciplines',
        key: 'disciplines',
        content: 'Disciplines',
      },
      {
        id: 'survey-details-primary-contact',
        key: 'primary-contact',
        content: 'Primary Contact',
      },
      {
        id: 'survey-details-responses',
        key: 'responses',
        content: 'Responses',
        style: { width: '160px' },
        textAlign: 'center',
      },
      {
        id: 'survey-details-result',
        key: 'result',
        content: 'Result',
        textAlign: 'center',
      },
      {
        id: 'survey-details-next-shift',
        key: 'shift',
        content: 'Next Shift Date',
      },
    ];
  };

  row = (x, i) => {
    const { type } = this.state;
    let cells;
    if (type === 'clients') {
      cells = [
        {
          key: 'client',
          content: (
            <div
              className='survey-table-name'
              role='presentation'
              onClick={() => this.onNameClick(x.clientId)}
            >{`${x.firstName} ${x.lastName}`}</div>
          ),
        },
        {
          key: 'email',
          content: x.email ? <a href={`mailto:${x.email}`}>{x.email}</a> : '',
        },
        {
          key: 'called-to-phone',
          content: phoneNumberToTableCell(x.sentToNumber),
        },
        {
          key: 'primary-contact',
          content: x.primaryLastName ? `${x.primaryFirstName} ${x.primaryLastName}` : '',
        },
        {
          key: 'responses',
          content: this.responseToTableCell(x.response),
        },
        {
          key: 'result',
          content: this.resultToTableCell(x.result),
        },
      ];
    }
    if (type === 'caregivers') {
      cells = [
        {
          key: 'caregiver',
          content: (
            <div
              className='survey-table-name'
              role='presentation'
              onClick={() => this.onNameClick(x.caregiverId)}
            >{`${x.firstName} ${x.lastName}`}</div>
          ),
        },
        {
          key: 'email',
          content: x.email ? <a href={`mailto:${x.email}`}>{x.email}</a> : '',
        },
        {
          key: 'sent-to-phone',
          content: phoneNumberToTableCell(x.sentToNumber),
        },
        {
          key: 'mobile-phone',
          content: phoneNumberToTableCell(x.mobileNumber),
        },
        {
          key: 'home-phone',
          content: phoneNumberToTableCell(x.homeNumber),
        },
        {
          key: 'staff-status',
          content: x.staffStatus,
          textAlign: 'center',
        },
        {
          key: 'caregiver-status',
          content: x.caregiverStatus,
          textAlign: 'center',
        },
        {
          key: 'disciplines',
          content: x.discipline,
        },
        {
          key: 'primary-contact',
          content: x.primaryLastName ? `${x.primaryFirstName} ${x.primaryLastName}` : '',
        },
        {
          key: 'responses',
          content: this.responseToTableCell(x.response),
        },
        {
          key: 'result',
          content: this.resultToTableCell(x.result),
          textAlign: 'center',
        },
        {
          key: 'shift',
          content: x.nextShiftId ? (
            <Overlay
              shift={{
                start: nextShiftDate(x.nextShiftStart, x.nextShiftTimezone),
                id: x.nextShiftId,
              }}
            />
          ) : (
            '--'
          ),
          textAlign: 'center',
        },
      ];
    }
    if (type === 'employees') {
      cells = [
        {
          key: 'employee',
          content: (
            <div
              className='survey-table-name'
              role='presentation'
              onClick={() => this.onNameClick(x.employeeId)}
            >{`${x.firstName} ${x.lastName}`}</div>
          ),
        },
        {
          key: 'email',
          content: x.email ? <a href={`mailto:${x.email}`}>{x.email}</a> : '',
        },
        {
          key: 'sent-to-Number',
          content: phoneNumberToTableCell(x.sentToNumber),
        },
        {
          key: 'mobile-phone',
          content: phoneNumberToTableCell(x.mobileNumber),
        },
        {
          key: 'home-phone',
          content: phoneNumberToTableCell(x.homeNumber),
        },
        {
          key: 'staff-status',
          content: x.staffStatus,
          textAlign: 'center',
        },
        {
          key: 'employee-status',
          content: x.employeeStatus,
          textAlign: 'center',
        },
        {
          key: 'disciplines',
          content: x.discipline,
        },
        {
          key: 'survey-details-coordinator',
          content: x.isCoordinator ? 'YES' : 'NO',
        },
        {
          key: 'survey-details-manager',
          content: x.isManager ? 'YES' : 'NO',
        },
        {
          key: 'responses',
          content: this.responseToTableCell(x.response),
        },
        {
          key: 'result',
          content: this.resultToTableCell(x.result),
          textAlign: 'center',
        },
      ];
    }
    return {
      key: i,
      style: x.result === 'FAIL' ? { backgroundColor: '#fad1d6' } : null,
      cells,
    };
  };

  render = () => {
    const {
      page,
      pageSize,
      totalCount,
      data,
      tableRendering,
      fullData,
      surveyDate,
      loading,
      type,
    } = this.state;

    if (!data) {
      return null;
    }

    return (
      <Grid style={{ backgroundColor: 'white', width: '100%', margin: '0px' }}>
        {loading && (
          <Dimmer active inverted>
            <CareLoader loading showText={false} />
          </Dimmer>
        )}
        <GridRow verticalAlign='middle' style={{ height: '70px' }}>
          <GridColumn width={2}>
            <Image ui={false} src='/logo_careconnect.svg' />
          </GridColumn>

          <GridColumn width={6}>
            <div className='survey-date-header-grid'>
              <div>
                <Image centered ui={false} src='/rectangle-divider.svg' />
              </div>
              <div>
                <Image
                  centered
                  ui={false}
                  src='/covid-icon-red.png'
                  className='survey-covid-icon'
                />
              </div>
              <div>
                <span>Daily COVID-19 {type} Survey Report</span>
              </div>
              <div>
                <SurveyDatePicker surveyDate={surveyDate} onChange={this.onDateChange} />
              </div>
              <div>
                <Button style={{ backgroundColor: 'white' }} onClick={this.refresh}>
                  <Image centered ui={false} src='/refresh.svg' />
                </Button>
              </div>
              <div>
                <Image centered ui={false} src='/rectangle-divider.svg' />
              </div>
            </div>
          </GridColumn>

          <GridColumn width={8} textAlign='right'>
            <CSVLink
              data={formatCsv(fullData)}
              filename={getFileName(surveyDate.format(DATE_FORMAT))}
              target='_blank'
              className='survey-export-link'
            >
              <span>Export Report</span>
            </CSVLink>
          </GridColumn>
        </GridRow>
        <GridRow>
          <Table
            id='survey-result-table'
            sortable={false}
            singleLine
            striped
            fixed
            headerRow={this.header()}
            renderBodyRow={this.row}
            tableData={data}
          />
          <Visibility
            once={false}
            onBottomVisible={() => {
              if (page * pageSize < totalCount) {
                const nextPage = page + 1;
                const nextData = data.concat(
                  fullData.slice(page * pageSize, page * pageSize + pageSize)
                );
                this.setState({
                  data: nextData,
                  page: nextPage,
                  tableRendering: true,
                });
              } else if (tableRendering) {
                this.setState({ tableRendering: false });
              }
            }}
          />
          {tableRendering && (
            <div style={{ width: '100%', height: '35px' }}>
              <Image
                src='/loader_anim.gif'
                style={{ width: '30px', height: 'auto' }}
                className='loaderImage'
                centered
              />
            </div>
          )}
        </GridRow>
        {!data.length && (
          <GridRow style={{ height: '300px', backgroundColor: 'white' }}>
            <GridColumn width={16} className='survey-no-results'>
              <div>
                <p>
                  <h1>No Results Found</h1>
                </p>
              </div>
            </GridColumn>
          </GridRow>
        )}
      </Grid>
    );
  };
}

SurveyReport.defaultProps = {
  surveyDate: null,
  location: null,
};

SurveyReport.propTypes = {
  surveyDate: PropTypes.shape(),
  location: PropTypes.shape(),
  type: PropTypes.string.isRequired,
};

export { SurveySummary, SurveyReport, CaregiverSurveyResult };

import React from 'react';
import { Dimmer, Image } from 'semantic-ui-react';
import CareLoader from '../subcomponents/CareLoader';
import { backend } from '../../services/index';
import { NoModuleAuthorizationBanner } from '../subcomponents/NoModuleAuthorizationBanner';
import { SolutionName } from '../../constants/Solution';
import { FeatureDisabledTile } from '../subcomponents/FeatureDisabledTile';
import { FrameDivider } from './FrameDivider';
import { BlurredNoAuthorizationBackground } from './BlurredNoAuthorizationBackground';
import { WelcomeTitle } from '../subcomponents/WelcomeTitle';
import { NoResultsContainer } from './styles';

interface EmbedReportResponse {
  authorized: boolean;
  enabled: boolean;
  reports: EmbedReportWebItem[];
}

interface EmbedReportWebItem {
  id: string;
  html: string;
  uiTabKey: string;
}

interface Props {
  solution: SolutionName;
}

interface Frame {
  loaded: boolean;
  element: HTMLIFrameElement | null;
}

interface State {
  loading: boolean;
  embed: EmbedReportResponse;
  heights: number[];
}

/**
 * Render embed reports for given EmbedReportTab
 */
export class EmbedReports extends React.Component<Props, State> {
  private static DEFAULT_FRAME_HEIGHT = 1620;

  private static DEFAULT_FRAME_ADD_HEIGHT = 15;

  private frames: Frame[] = [];

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      embed: {
        enabled: true,
        authorized: true,
        reports: [],
      },
      heights: [],
    };
  }

  /*
   * Register event listener which will manipulate iFrame height and
   * fetch embed report list by UI tab key from backend.
   *
   * https://developer.domo.com/docs/embed/dynamic-iframe-height
   */
  componentDidMount() {
    window.addEventListener('message', this.messageEventListener);

    const { solution } = this.props;
    this.setState({ loading: true });

    backend
      .getEmbeddedReportHtmlDoc(solution)
      .then(response => {
        const reports = response?.reports || [];
        this.frames = reports.map(() => ({ loaded: false, element: null }));
        const heights = reports.map(() => EmbedReports.DEFAULT_FRAME_HEIGHT);
        const loading = !!reports.length;
        this.setState({ loading, embed: response, heights });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  /*
   * Remove event listener when component will unmount
   */
  componentWillUnmount() {
    window.removeEventListener('message', this.messageEventListener);
  }

  /*
   * Event listener function for updating iFrame height dynamically
   */
  messageEventListener = (event: MessageEvent) => {
    if (event?.data?.params?.height) {
      const index = this.frames.findIndex(x => x.element?.contentWindow === event.source);
      if (index >= 0) {
        const { heights } = this.state;
        heights[index] = event.data.params.height + EmbedReports.DEFAULT_FRAME_ADD_HEIGHT;
        this.setState({ heights });
      }
    }
  };

  /*
   * Set iFrame as loaded
   */
  setFrameLoaded = (index: number) => {
    this.frames[index].loaded = true;
    if (!this.frames.find(x => !x.loaded)) {
      this.setState({ loading: false });
    }
  };

  /*
   * Set reference of the iFrame element into array
   */
  setFrameReference = (element: HTMLIFrameElement | null, index: number) => {
    this.frames[index].element = element;
  };

  render() {
    const { solution } = this.props;
    const {
      loading,
      embed: { enabled, authorized, reports },
      heights,
    } = this.state;

    return (
      <>
        {loading && (
          <Dimmer active inverted>
            <CareLoader loading={loading} centered showText={false} />
          </Dimmer>
        )}
        {enabled && authorized && (
          <>
            {!loading && <WelcomeTitle solution={solution} />}
            {reports.map((report: EmbedReportWebItem, index: number) => {
              const onRef = (element: HTMLIFrameElement) => this.setFrameReference(element, index);
              const onLoad = () => this.setFrameLoaded(index);
              return (
                <React.Fragment key={report.id}>
                  <iframe
                    title='Report'
                    id={report.id}
                    ref={onRef}
                    onLoad={onLoad}
                    srcDoc={report.html}
                    width='100%'
                    height={heights[index]}
                    frameBorder={0}
                    style={{ marginTop: '55px', marginLeft: '100px', marginRight: '100px' }}
                  />
                  <FrameDivider />
                </React.Fragment>
              );
            })}
            {/* When backend has no reports */}
            {!reports.length && (
              <NoResultsContainer>
                <h3>No Reports Available</h3>
                <Image src='/nomatch.svg' />
              </NoResultsContainer>
            )}
          </>
        )}
        {enabled && !authorized && (
          <>
            <BlurredNoAuthorizationBackground />
            <NoModuleAuthorizationBanner
              header='CareConnect Business Intelligence'
              title='Please contact your agency admin to request permission to access these reports'
            />
          </>
        )}
        {!enabled && (
          <FeatureDisabledTile
            header='CareConnect Business Intelligence'
            title='Contact your CareConnect representative to unlock access to your reports and dashboards.'
          />
        )}
      </>
    );
  }
}

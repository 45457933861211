/* eslint-disable import/prefer-default-export */
import * as types from '../constants/ActionTypes';
import { backend } from '../services';
// eslint-disable-next-line import/no-cycle
import { alertActions } from './alert';

function getDashboard() {
  return dispatch => {
    dispatch(request());
    backend.getDashboard().then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_DASHBOARD_REQUEST };
  }
  function success(results) {
    return { type: types.GET_DASHBOARD_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_DASHBOARD_FAILURE, error };
  }
}

export const dashboardActions = {
  getDashboard,
};

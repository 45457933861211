import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Form, Header } from 'semantic-ui-react';

const Name = ({ object, editable, onValueChange }) =>
  editable ? (
    <Grid.Row>
      <Grid.Column width={5}>
        <Header size='tiny'>Name:</Header>
      </Grid.Column>
      <Grid.Column width={11} className='ui form'>
        <Form.Group>
          <Form.Input
            width={13}
            placeholder='First Name'
            name='firstName'
            value={object.firstName}
            onChange={onValueChange}
          />
          <Form.Input
            width={3}
            placeholder='MI'
            name='middleName'
            value={object.middleName}
            onChange={onValueChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            width={16}
            placeholder='Last Name'
            name='lastName'
            value={object.lastName}
            onChange={onValueChange}
          />
        </Form.Group>
      </Grid.Column>
    </Grid.Row>
  ) : null;

Name.defaultProps = {};

Name.propTypes = {
  object: PropTypes.shape().isRequired,
  editable: PropTypes.bool.isRequired,
  onValueChange: PropTypes.func.isRequired,
};

export default Name;

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Grid, Header, Image, Button } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import '../CaregiverRecords.css';
import { formatPhoneNumber } from '../../constants/Formats';

class EmergencyContact extends React.Component {
  constructor() {
    super();
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      emergencyContact: [
        { name: '', relation: '', primaryContactNumber: '', secondaryContactNumber: '' },
      ],
    };
  }

  render() {
    const { header, onValueChange, emergencyContact, icon, editability, onDelete } = this.props;

    const relations = [
      { key: 'pa', text: 'Parent', value: 'parent' },
      { key: 'si', text: 'Sibling', value: 'sibling' },
      { key: 'ch', text: 'Child', value: 'child' },
      { key: 'sp', text: 'Spouse', value: 'spouse' },
      { key: 'ot', text: 'Other', value: 'other' },
    ];

    const instantValidation = true;

    return (
      <div className='emContactDetailsBox'>
        <Grid className='details-box-wide-grey'>
          <Grid.Row>
            <Grid.Column>
              <Header
                size='tiny'
                as='h3'
                floated='left'
                style={{ fontWeight: 'bold', color: '#000000de' }}
              >
                <Image className='ui image' src={icon} />
                {header}
              </Header>
              <Button
                floated='right'
                className='emergency-contact-clear-input-button'
                type='button'
                disabled={
                  !(
                    emergencyContact.primaryContactNumber ||
                    emergencyContact.name ||
                    emergencyContact.secondaryContactNumber ||
                    emergencyContact.relation
                  )
                }
                onClick={onDelete}
              >
                <Image inline size='mini' src='/icon_trashcan.svg' />
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row width={16} className='emergencyContact'>
            <Grid.Column width={8}>
              <Form.Input
                id='emergency-contact-name-input'
                name='emergencyContact.name'
                label='Name'
                placeholder='Name'
                value={emergencyContact.name ? emergencyContact.name : null}
                onChange={onValueChange}
                type='text'
                validations={{
                  minLength: 2,
                  maxLength: 25,
                }}
                disabled={!editability.emergencycontact}
              />
            </Grid.Column>
            <Grid.Column width={8} style={{ float: 'right' }}>
              <Form.Select
                id='emergency-contact-relation-select'
                name='emergencyContact.relation'
                label='Relationship'
                options={relations}
                placeholder='Choose...'
                style={{ fontWeight: 'bold' }}
                onChange={onValueChange}
                value={emergencyContact.relation ? emergencyContact.relation : null}
                disabled={!editability.emergencycontact}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row width={16} className='emergencyContact'>
            <Grid.Column width={8}>
              <Form.Input
                id='emergency-contact-primary-number'
                name='emergencyContact.primaryContactNumber'
                label='Primary Phone'
                placeholder='Primary Phone'
                type='text'
                onChange={onValueChange}
                value={
                  emergencyContact.primaryContactNumber
                    ? formatPhoneNumber(`${emergencyContact.primaryContactNumber}`)
                    : null
                }
                validations={{
                  matchRegexp: /^[0-9+()#.\s/ext-]+$/,
                  minLength: 6,
                  maxLength: 30,
                }}
                instantValidation={instantValidation}
                validationErrors={{ isValid: 'Invalid input' }}
                disabled={!editability.emergencycontact}
              />
            </Grid.Column>
            <Grid.Column width={8} style={{ float: 'right' }}>
              <Form.Input
                id='emergency-contact-secondary-number'
                label='Secondary Phone'
                name='emergencyContact.secondaryContactNumber'
                placeholder='Secondary Phone'
                type='text'
                onChange={onValueChange}
                validations={{
                  matchRegexp: /^[0-9+()#.\s/ext-]+$/,
                  minLength: 6,
                  maxLength: 30,
                }}
                value={
                  emergencyContact.secondaryContactNumber
                    ? formatPhoneNumber(`${emergencyContact.secondaryContactNumber}`)
                    : ''
                }
                instantValidation={instantValidation}
                validationErrors={{ isValid: 'Invailid input' }}
                disabled={!(emergencyContact.primaryContactNumber && editability.emergencycontact)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

EmergencyContact.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  emergencyContact: PropTypes.shape().isRequired,
  editability: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  icon: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default injectIntl(EmergencyContact);

import React, { Component } from 'react';
import styled from 'styled-components';
import { Radio, Dimmer } from 'semantic-ui-react';
import { SimpleButton, GreenButton, LinkButton } from '../../../styles/common';
import { ELCoursesActions } from '../../../actions/ELApp/ElCoursesActions';
import SignatureCanvas from './SignatureCanvas';
import CareLoader from '../CareLoader';
import './ESignature.scss';

const GreenRadio = styled(Radio)`
  && {
    margin: 30px 0 20px 0;
    width: 330px;
    justify-self: center;
    pointer-events: ${props => (props.scrolledDocument ? 'none' : 'initial')};
    opacity: ${props => (props.scrolledDocument ? 0.5 : 1)};
  }
  & > label::after {
    background-color: #69db8f !important;
  }
`;

interface UploadSignNode {
  dom: HTMLInputElement | null;
}

interface Props {
  signatureUrl: string;
  handleCancel(): void;
  handleSubmit(): void;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  dispatch: (arg: any) => void;
  signTemplate: string;
  signingAgency: string;
  loading: boolean;
}

interface State {
  changeSignature: boolean;
  agreed: boolean;
  documentScrolled: boolean;
}

export default class SignCertificate extends Component<Props, State> {
  public state = {
    changeSignature: false,
    agreed: false,
    documentScrolled: false,
  };

  private readonly changeSignature = () => {
    this.setState(s => ({ ...s, changeSignature: true }));
  };

  public onSignatureSave = () => {
    this.setState(s => ({ ...s, changeSignature: false }));
  };

  private readonly handleAgreement = () => {
    const { agreed } = this.state;
    this.setState(s => ({ ...s, agreed: !agreed }));
  };

  private readonly handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrolled =
      Math.floor(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) ===
      Math.floor(e.currentTarget.clientHeight);
    if (scrolled) {
      this.setState(s => ({ ...s, documentScrolled: true }));
    }
  };

  private readonly uploadSignaturePic = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { dispatch } = this.props;
    if (e?.target?.files?.length) {
      dispatch(ELCoursesActions.putESignature({ signature: e?.target?.files[0] }));
    }
    return this.onSignatureSave();
  };

  public render(): JSX.Element {
    const { changeSignature, agreed, documentScrolled } = this.state;
    const {
      signatureUrl,
      loading,
      handleCancel,
      handleSubmit,
      dispatch,
      signTemplate,
      signingAgency,
    } = this.props;

    const signUploadNode: UploadSignNode = {
      dom: null,
    };

    const clickUploadPic = () => {
      if (signUploadNode.dom) {
        signUploadNode?.dom?.click();
      }
    };

    return (
      <div className='eSignatureGrid'>
        <input
          type='file'
          accept='.png'
          style={{ display: 'none' }}
          onChange={this.uploadSignaturePic}
          ref={node => {
            signUploadNode.dom = node;
          }}
        />
        <h3 className='eSignTextCenter'>SIGNATURE</h3>
        <p className='eSignTextCenter'>{signingAgency}</p>
        {loading ? (
          <Dimmer active inverted>
            <CareLoader loading={loading} showText={false} />
          </Dimmer>
        ) : (
          <div>
            {signTemplate.length ? (
              <div
                onScroll={this.handleScroll}
                className='templateContainer'
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: signTemplate }}
              />
            ) : (
              <Dimmer active inverted>
                <CareLoader loading showText={false} />
              </Dimmer>
            )}
          </div>
        )}
        <GreenRadio
          label='I have read and agree the above statement'
          checked={agreed}
          onClick={this.handleAgreement}
          scrolledDocument={!documentScrolled}
        />
        {!changeSignature ? (
          <div className='eSignatureGrid gridTwoRowsOneColumn'>
            <div className='eSignatureGrid gridTwoRowsOneColumn signContainer'>
              <div className='eSignatureGrid gridTwoRowsOneColumn'>
                {loading && (
                  <Dimmer active inverted>
                    <CareLoader loading={loading} showText={false} />
                  </Dimmer>
                )}
                {signatureUrl ? (
                  <img className='signatureImage' src={signatureUrl} alt='signature' />
                ) : (
                  <div className='signatureImage' />
                )}
                <div className='signatureLine' />
              </div>
              <LinkButton
                style={{ width: '250px', justifySelf: 'center' }}
                onClick={this.changeSignature}
              >
                {signatureUrl ? 'Change Signature' : 'Draw Signature'}
              </LinkButton>
            </div>
            <LinkButton style={{ marginTop: '10px' }} onClick={clickUploadPic}>
              Upload an image file
            </LinkButton>
          </div>
        ) : (
          <div className='eSignatureGrid gridTwoRowsOneColumn signContainer'>
            <h4 style={{ textAlign: 'center', color: '#c4c4c4', margin: '10px 0 0 0' }}>
              DRAW YOUR SIGNATURE
            </h4>
            <SignatureCanvas dispatch={dispatch} onSignatureSave={this.onSignatureSave} />
          </div>
        )}
        <div className='signatureButtonsContainer'>
          <SimpleButton
            style={{ padding: '0', height: '30px', width: '130px' }}
            onClick={handleCancel}
          >
            Cancel
          </SimpleButton>
          <GreenButton
            style={{ padding: '0', height: '30px', width: '130px' }}
            disabled={!signatureUrl?.length || !agreed || changeSignature}
            onClick={handleSubmit}
          >
            Submit
          </GreenButton>
        </div>
      </div>
    );
  }
}

/* eslint-disable max-len */
import {
  UPDATE_ELEARNING_SETTINGS_REQUEST,
  GET_ELEARNING_SETTINGS_REQUEST,
  CREATE_ELEARNING_SETTINGS_REQUEST,
  UPDATE_ELEARNING_SETTINGS_SUCCESS,
  GET_ELEARNING_SETTINGS_SUCCESS,
  CREATE_ELEARNING_SETTINGS_SUCCESS,
  UPDATE_ELEARNING_SETTINGS_FAILURE,
  GET_ELEARNING_SETTINGS_FAILURE,
  CREATE_ELEARNING_SETTINGS_FAILURE,
} from '../constants/ActionTypes';

const initialState = {
  loading: false,
};

function elearningSettings(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ELEARNING_SETTINGS_REQUEST:
    case GET_ELEARNING_SETTINGS_REQUEST:
    case CREATE_ELEARNING_SETTINGS_REQUEST:
      return { ...state, ...state.elearningSettings, loading: true };
    case UPDATE_ELEARNING_SETTINGS_SUCCESS:
    case GET_ELEARNING_SETTINGS_SUCCESS:
    case CREATE_ELEARNING_SETTINGS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const policyText = action.elearningSettings ? action.elearningSettings.policyText : null;
      return { ...state, ...policyText, loading: false };
    case UPDATE_ELEARNING_SETTINGS_FAILURE:
    case GET_ELEARNING_SETTINGS_FAILURE:
    case CREATE_ELEARNING_SETTINGS_FAILURE:
      return { ...state, ...state.elearningSettings, loading: false };
    default:
      return state;
  }
}

export default elearningSettings;

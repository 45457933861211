import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

const Status = ({ object, editable, onValueChange }) => {
  let label;
  const onClick = () => {
    onValueChange({}, { name: 'status', value: 'inactive' });
  };

  switch (object.status) {
    case 'active':
      label = (
        <span>
          <Icon name='circle' className='icon-green' />
          Active (Ready for Work)
        </span>
      );
      break;
    case 'pending':
      label = (
        <span>
          <Icon name='circle' className='icon-blue' />
          Awaiting Activation
        </span>
      );
      break;
    case 'inactive':
      label = (
        <span>
          <Icon name='circle' className='icon-red' />
          Awaiting Invitation
        </span>
      );
      break;
    default:
      label = object.status;
  }
  return (
    <span>
      {label}{' '}
      {editable && object.status !== 'inactive' && (
        <span>
          &nbsp;
          <Button id='status-deactivate-button' onClick={onClick}>
            Deactivate
          </Button>
        </span>
      )}
    </span>
  );
};

Status.defaultProps = {};

Status.propTypes = {
  object: PropTypes.shape().isRequired,
  editable: PropTypes.bool.isRequired,
  onValueChange: PropTypes.func.isRequired,
};

export default Status;

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

class PageSizerHorizontal extends React.Component {
  constructor(props) {
    super(props);
    this.timeout = null;
  }

  scrollToTop() {
    this.tableContainer.scrollTop = 0;
  }

  render() {
    const { meterState, contentHeight } = this.props;

    let height = 'auto';
    if (meterState.top && meterState.bottom) {
      const bottom = Math.max(window.innerHeight, meterState.bottom);
      const spaceAvailable = Math.max(300, bottom - 60 - meterState.top);
      height = this.props.active && spaceAvailable < contentHeight ? `${spaceAvailable}px` : 'auto';
    }

    return (
      <Grid.Row colums='one'>
        <div
          style={{ height, overflowY: 'scroll', overflowX: 'auto' }}
          className='tableContainer horizontal-scrolling'
          ref={node => {
            this.tableContainer = node;
          }}
        >
          {this.props.children}
        </div>
      </Grid.Row>
    );
  }
}

PageSizerHorizontal.defaultProps = {
  active: true,
};

PageSizerHorizontal.propTypes = {
  active: PropTypes.bool,
  meterState: PropTypes.shape().isRequired,
  contentHeight: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default PageSizerHorizontal;

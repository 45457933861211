/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import PhoneInput from '../PhoneInput';
import DetailsBox from './DetailsBox';
import { RecordsForm } from '../../../styles/records';
import '../../CaregiverRecords.css';
import EmergencyContact from '../EmergencyContact';

const initPhones = newPhones => {
  const phones = newPhones || [];
  const primaryPhone = phones.filter(p => p.primary && p.type === 3)[0] ||
    phones.filter(p => p.type === 3)[0] || { id: 999999999, type: 3, primary: true };
  const otherPhones = phones.filter(p => p.id && p.id !== primaryPhone.id);
  return { primaryPhone, otherPhones };
};

class TileInformationContact extends React.Component {
  constructor(props) {
    super(props);
    const phones = initPhones(props.person.phones);
    this.state = {
      ...phones,
      email: props.person.email,
    };
    this.onPhoneChange = this.onPhoneChange.bind(this);
    this.onPhoneDelete = this.onPhoneDelete.bind(this);
    this.onPhoneAdd = this.onPhoneAdd.bind(this);
  }

  componentDidMount() {
    this.form.formsyForm.setFormPristine(false);
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(nextProps) {
    const phones = initPhones(nextProps.person.phones);
    this.setState({
      ...phones,
      email: nextProps.person.email,
    });
  }

  onPhoneChange(i, e) {
    const { primaryPhone, otherPhones } = this.state;

    if (e.name === 'primaryPhone') {
      primaryPhone.number = e.value;
    } else if (e.name === 'phoneType') {
      const phone = otherPhones[i];
      phone.type = e.value;
    } else if (e.name === 'phoneNumber') {
      const phone = otherPhones[i];
      phone.number = e.value;
    }
    this.props.onValueChange({}, { name: 'phones', value: [primaryPhone].concat(otherPhones) });
  }

  onPhoneDelete(i) {
    const newPhones = this.state.otherPhones.slice() || [];
    newPhones.splice(i, 1);
    this.setState({ otherPhones: newPhones });
    const { primaryPhone } = this.state;
    this.props.onValueChange({}, { name: 'phones', value: [primaryPhone].concat(newPhones) });
  }

  onPhoneAdd() {
    const newPhones = this.state.otherPhones.slice() || [];
    const genRand = () => Math.floor(Math.random() * 1000000 + 100000);

    let rand = genRand();
    const hasId = p => p.id === rand;
    while (newPhones.some(hasId)) {
      rand = genRand();
    }

    const type = this.state.otherPhones.some(p => p.type === 1) ? 2 : 1;

    newPhones.push({ type, number: '', primary: false, id: rand });
    this.setState({ otherPhones: newPhones });
    const { primaryPhone } = this.state;
    this.props.onValueChange({}, { name: 'phones', value: [primaryPhone].concat(newPhones) });
  }

  render() {
    const {
      intl,
      onValueChange,
      onValid,
      onInvalid,
      editable,
      editability,
      type,
      onDelete,
    } = this.props;
    const { otherPhones, email, primaryPhone } = this.state;
    const { phones, emergencyContact } = this.props.person;

    const cellPhone = intl.formatMessage({ id: 'records.cellPhonePrimary' });
    const emailLabel = intl.formatMessage({ id: 'records.email' });
    const addContact = intl.formatMessage({ id: 'records.addContact' });

    const instantValidation = false;
    const isClient = type === 'client';
    return (
      <DetailsBox
        text={intl.formatMessage({ id: 'records.contactInfo' }).toUpperCase()}
        icon='/icon_large_contact.svg'
      >
        <RecordsForm
          onValid={() => onValid('contacts')}
          onInvalid={() => onInvalid('contacts')}
          size='tiny'
          ref={c => {
            this.form = c;
          }}
        >
          <Grid>
            {isClient && (
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form.Input
                    id='client-contact-primary-phone'
                    fluid
                    name='primaryPhone'
                    placeholder={cellPhone}
                    label={cellPhone}
                    type='tel'
                    width={16}
                    validations={{
                      minLength: 2,
                      maxLength: 25,
                    }}
                    validationErrors={{ isValid: 'cellPhone not valid' }}
                    instantValidation={instantValidation}
                    value={primaryPhone.number}
                    onChange={this.onPhoneChange}
                    disabled={!editable}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {!isClient && (
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form.Input
                    id='client-contact-primary-phone'
                    fluid
                    name='primaryPhone'
                    placeholder={cellPhone}
                    label={cellPhone}
                    type='tel'
                    width={16}
                    validations={{
                      minLength: 2,
                      maxLength: 25,
                    }}
                    validationErrors={{ isValid: 'cellPhone not valid' }}
                    instantValidation={instantValidation}
                    required={!email}
                    value={primaryPhone.number}
                    onChange={this.onPhoneChange}
                    disabled={!editability.cellphone}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {isClient && (
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form.Input
                    id='client-contact-email'
                    fluid
                    name='email'
                    placeholder={emailLabel}
                    label={emailLabel}
                    width={16}
                    validations={{
                      matchRegexp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      maxLength: 200,
                    }}
                    validationErrors={{ isValid: 'email not valid' }}
                    instantValidation={instantValidation}
                    value={email}
                    onChange={onValueChange}
                    disabled={!editable}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {!isClient && (
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form.Input
                    id='client-contact-email'
                    fluid
                    name='email'
                    placeholder={emailLabel}
                    label={emailLabel}
                    width={16}
                    validations={{
                      matchRegexp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      maxLength: 200,
                    }}
                    validationErrors={{ isValid: 'email not valid' }}
                    instantValidation={instantValidation}
                    value={email}
                    onChange={onValueChange}
                    disabled={!editability.email}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {isClient &&
              otherPhones.map((p, i) => (
                <PhoneInput
                  phones={phones}
                  index={i}
                  phone={p}
                  key={p.id}
                  onValueChange={this.onPhoneChange}
                  onPhoneDelete={this.onPhoneDelete}
                  disabled={!editable}
                />
              ))}

            {isClient && otherPhones.length < 2 && editable && (
              <Button
                id='tile-information-contact-add-phone-button'
                style={{
                  background: 'none',
                  color: '#04A5D5',
                  fontSize: '12px',
                  fontWeight: 'normal',
                }}
                onClick={this.onPhoneAdd}
                disabled={!editable}
              >
                {addContact}
              </Button>
            )}
            {!isClient &&
              otherPhones.map((p, i) => (
                <PhoneInput
                  phones={phones}
                  index={i}
                  phone={p}
                  key={p.id}
                  onValueChange={this.onPhoneChange}
                  onPhoneDelete={this.onPhoneDelete}
                  disabled={!(p.type === 1 ? editability.homephone : editability.workphone)}
                />
              ))}

            {!isClient &&
              otherPhones.length < 2 &&
              (editability.workphone || editability.homephone) && (
                <Button
                  id='tile-information-contact-add-phone-button'
                  style={{
                    background: 'none',
                    color: '#04A5D5',
                    fontSize: '12px',
                    fontWeight: 'normal',
                  }}
                  onClick={this.onPhoneAdd}
                  disabled={
                    !(otherPhones.length && otherPhones[0].type === 1
                      ? editability.workphone
                      : editability.homephone)
                  }
                >
                  {addContact}
                </Button>
              )}
          </Grid>

          {!isClient && (
            <EmergencyContact
              header='EMERGENCY CONTACT'
              emergencyContact={emergencyContact}
              // icon={'/icon_large_contact.svg'}
              editability={editability}
              onDelete={onDelete}
              icon='/EmergencyContact.png'
              onValueChange={onValueChange}
            />
          )}
        </RecordsForm>
      </DetailsBox>
    );
  }
}

TileInformationContact.defaultProps = {
  onValid: () => {},
  onInvalid: () => {},
  editable: true,
  editability: {},
};

TileInformationContact.propTypes = {
  intl: intlShape.isRequired,
  person: PropTypes.shape().isRequired,
  onValueChange: PropTypes.func.isRequired,
  onValid: PropTypes.func,
  onInvalid: PropTypes.func,
  editable: PropTypes.bool,
  editability: PropTypes.arrayOf(PropTypes.shape()),
  type: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default injectIntl(TileInformationContact);

import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

const CareButton = styled(Button)`
  &&& {
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 400;
    height: 40px;

    &.disabled {
      background-color: #d9d9d9;
      color: #9b9b9b;
    }
  }
`;

export const BlueButton = styled(CareButton)`
  &&&& {
    background-color: #04a5d5;
    color: #31435e;
  }
`;

export const GoBackButton = styled(CareButton)`
  &&&& {
    width: 12px;
    height: 17px;
    position: relative;
    border-radius: 0;
    background-color: transparent;
    padding: 0;
    &::after {
      content: '';
      background-color: #4a4a4a;
      width: 0.143rem;
      height: 0.714rem;
      position: absolute;
      left: 0.357rem;
      bottom: 0.714rem;
      transform: translate(-0.071rem, 0.321rem) rotate(45deg);
      border-radius: 2px;
    }
    &::before {
      content: '';
      background-color: #4a4a4a;
      width: 0.714rem;
      height: 0.143rem;
      position: absolute;
      left: 0.357rem;
      bottom: 0.714rem;
      transform: translate(-0.357rem, 0.464rem) rotate(45deg);
      border-radius: 2px;
    }
    &:hover {
      opacity: 0.5;
    }
  }
`;

export const GreenButton = styled(CareButton)`
  &&& {
    background-color: #69db8f;
    color: #417505;
    &.disabled {
      background-color: #90b6a2;
      color: #3e4b30;
    }
  }
`;

export const RedButton = styled(CareButton)`
  &&& {
    background-color: #f16778;
    color: #64111b;
  }
`;

export const SimpleGreenButton = styled(CareButton)`
  &&&& {
    background-color: transparent;
    color: #69db8f;
    border: 1px solid #69db8f;
  }
  &:hover {
    background-color: transparent;
    color: #69db8f;
  }
`;

export const SimpleRedButton = styled(CareButton)`
  &&&& {
    background-color: transparent;
    color: #f16778;
    border: 1px solid #f16778;
  }
  &:hover {
    background-color: transparent;
    color: #f16778;
  }
`;

export const SimpleButton = styled(CareButton)`
  &&& {
    background-color: transparent;
    color: #9b9b9b;
    border: 1px solid #d9d9d9;
  }
`;

export const LinkButton = styled(CareButton)`
  &&& {
    background: transparent;
    color: #04a5d5;
    border: none;
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    padding: ${props => props.padding || '1rem'};
    height: ${props => props.height || 'auto'};
    &.disabled {
      background: transparent;
      color: #9b9b9b !important;
      border: none;
      font-size: ${props => props.fontSize};
      font-weight: ${props => props.fontWeight};
    }
  }
`;

export const SimpleBlueButton = styled(CareButton)`
  &&& {
    background-color: transparent;
    color: #04a5d5;
    border: 1px solid #04a5d5;
  }
`;

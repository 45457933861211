import React from 'react';
import { Image, Popup } from 'semantic-ui-react';
import {
  BaseValidationItem,
  Caregiver,
  Client,
  Employee,
  Shift,
  IFailRecords,
} from './importInterfaces';

const checkForInvalidField = (item: BaseValidationItem, field: string): string | null =>
  item?.invalidFields?.find(x => x.field === field)?.message || null;

const cellContent = (
  item: BaseValidationItem,
  field: string,
  text: string,
  failRecords?: IFailRecords
) => {
  const errMessage = checkForInvalidField(item, field);
  return (
    <div>
      {errMessage || failRecords?.error ? (
        <span>
          <Popup
            trigger={<Image src='/Attention.svg' inline />}
            content={errMessage || failRecords?.error}
            on='hover'
            flowing
          />
          {text}
        </span>
      ) : (
        text
      )}
    </div>
  );
};

const caregiverHeaders = [
  { key: 'externalId', content: 'externalId', filter: true },
  { key: 'agencyExternalId', content: 'agencyExternalId', filter: true },
  { key: 'lastModified', content: 'lastModified', filter: true },
  { key: 'firstName', content: 'firstName', filter: true },
  { key: 'lastName', content: 'lastName', filter: true },
  { key: 'nickName', content: 'nickName', filter: true },
  { key: 'middleName', content: 'middleName', filter: true },
  { key: 'gender', content: 'gender', filter: true },
  { key: 'suffix', content: 'suffix', filter: true },
  { key: 'ethnicity', content: 'ethnicity', filter: true },
  { key: 'marital', content: 'marital', filter: true },
  { key: 'disciplines', content: 'disciplines', filter: true },
  { key: 'complianceEndDate', content: 'complianceEndDate', filter: true },
  { key: 'notificationRecipients', content: 'notificationRecipients', filter: true },
  { key: 'hireDate', content: 'hireDate', filter: true },
  { key: 'blockShiftMatching', content: 'blockShiftMatching', filter: true },
  { key: 'facebookLink', content: 'facebookLink', filter: true },
  { key: 'linkedinLink', content: 'linkedinLink', filter: true },
  { key: 'activationDate', content: 'activationDate', filter: true },
  { key: 'preferenceNames', content: 'preferenceNames', filter: true },
  { key: 'email', content: 'email', filter: true },
  { key: 'ssn', content: 'ssn', filter: true },
  { key: 'dob', content: 'dob', filter: true },
  { key: 'staffStatus', content: 'staffStatus', filter: true },
  { key: 'street', content: 'street', filter: true },
  { key: 'street2', content: 'street2', filter: true },
  { key: 'apartment', content: 'apartment', filter: true },
  { key: 'city', content: 'city', filter: true },
  { key: 'state', content: 'state', filter: true },
  { key: 'zip', content: 'zip', filter: true },
  { key: 'countryCode', content: 'countryCode', filter: true },
  { key: 'languages', content: 'languages', filter: true },
  { key: 'primaryLanguage', content: 'primaryLanguage', filter: true },
  { key: 'mobilePhone', content: 'mobilePhone', filter: true },
  { key: 'emergencyContactName', content: 'emergencyContactName', filter: true },
  { key: 'emergencyContactRelation', content: 'emergencyContactRelation', filter: true },
  { key: 'emergencyContactNumber', content: 'emergencyContactNumber', filter: true },
  { key: 'primaryContactExternalId', content: 'primaryContactExternalId', filter: true },
];

const clientHeaders = [
  { key: 'externalId', content: 'externalId', filter: true },
  { key: 'agencyExternalId', content: 'agencyExternalId', filter: true },
  { key: 'status', content: 'status', filter: true },
  { key: 'lastModified', content: 'lastModified', filter: true },
  { key: 'firstName', content: 'firstName', filter: true },
  { key: 'lastName', content: 'lastName', filter: true },
  { key: 'middleName', content: 'middleName', filter: true },
  { key: 'title', content: 'title', filter: true },
  { key: 'email', content: 'email', filter: true },
  { key: 'dob', content: 'dob', filter: true },
  { key: 'ssn', content: 'ssn', filter: true },
  { key: 'gender', content: 'gender', filter: true },
  { key: 'priorityCode', content: 'priorityCode', filter: true },
  { key: 'defaultService', content: 'defaultService', filter: true },
  { key: 'startDate', content: 'startDate', filter: true },
  { key: 'holdDate', content: 'holdDate', filter: true },
  { key: 'referralDate', content: 'referralDate', filter: true },
  { key: 'dischargeDate', content: 'dischargeDate', filter: true },
  { key: 'cancelDate', content: 'cancelDate', filter: true },
  { key: 'notes', content: 'notes', filter: true },
  { key: 'primaryContactExternalId', content: 'primaryContactExternalId', filter: true },
  { key: 'preferenceNames', content: 'preferenceNames', filter: true },
  { key: 'street', content: 'street', filter: true },
  { key: 'street2', content: 'street2', filter: true },
  { key: 'apartment', content: 'apartment', filter: true },
  { key: 'city', content: 'city', filter: true },
  { key: 'state', content: 'state', filter: true },
  { key: 'zip', content: 'zip', filter: true },
  { key: 'countryCode', content: 'countryCode', filter: true },
  { key: 'languages', content: 'languages', filter: true },
  { key: 'primaryLanguage', content: 'primaryLanguage', filter: true },
  { key: 'mobilePhone', content: 'mobilePhone', filter: true },
  { key: 'homePhone', content: 'homePhone', filter: true },
  { key: 'workPhone', content: 'workPhone', filter: true },
];

const employeeHeaders = [
  { key: 'externalId', content: 'externalId', filter: true },
  { key: 'agencyExternalId', content: 'agencyExternalId', filter: true },
  { key: 'lastModified', content: 'lastModified', filter: true },
  { key: 'firstName', content: 'firstName', filter: true },
  { key: 'lastName', content: 'lastName', filter: true },
  { key: 'middleName', content: 'middleName', filter: true },
  { key: 'ssn', content: 'ssn', filter: true },
  { key: 'dob', content: 'dob', filter: true },
  { key: 'gender', content: 'gender', filter: true },
  { key: 'email', content: 'email', filter: true },
  { key: 'staffStatus', content: 'staffStatus', filter: true },
  { key: 'isCoordinator', content: 'isCoordinator', filter: true },
  { key: 'isManager', content: 'isManager', filter: true },
  { key: 'unit', content: 'unit', filter: true },
  { key: 'location', content: 'location', filter: true },
  { key: 'surveyNames', content: 'surveyNames', filter: true },
  { key: 'days', content: 'days', filter: true },
  { key: 'sendAt', content: 'sendAt', filter: true },
];

const shiftHeaders = [
  { key: 'externalId', content: 'externalId', filter: true },
  { key: 'agencyExternalId', content: 'agencyExternalId', filter: true },
  { key: 'lastModified', content: 'lastModified', filter: true },
  { key: 'clientExternalId', content: 'clientExternalId', filter: true },
  { key: 'eventCode', content: 'eventCode', filter: true },
  { key: 'clientScheduleType', content: 'clientScheduleType', filter: true },
  { key: 'employeeScheduleType', content: 'employeeScheduleType', filter: true },
  { key: 'admissionType', content: 'admissionType', filter: true },
  { key: 'company', content: 'company', filter: true },
  { key: 'payerId', content: 'payerId', filter: true },
  { key: 'payerName', content: 'payerName', filter: true },
  { key: 'start', content: 'start', filter: true },
  { key: 'end', content: 'end', filter: true },
  { key: 'service', content: 'service', filter: true },
  { key: 'status', content: 'status', filter: true },
  { key: 'type', content: 'type', filter: true },
  { key: 'serviceDescription', content: 'serviceDescription', filter: true },
  { key: 'cancellationCode', content: 'cancellationCode', filter: true },
  { key: 'recurringPattern', content: 'recurringPattern', filter: true },
  { key: 'template', content: 'template', filter: true },
  { key: 'notes', content: 'notes', filter: true },
  { key: 'assignTo', content: 'assignTo', filter: true },
  { key: 'timezone', content: 'timezone', filter: true },
];

const caregiverCells = (item: Caregiver, failRecords: IFailRecords[]) => {
  const failRecord = failRecords.find(r => r.externalId === item.externalId);
  return [
    {
      key: 'externalId',
      content: cellContent(item, 'externalId', item.externalId, failRecord),
      error: checkForInvalidField(item, 'externalId'),
    },
    {
      key: 'agencyExternalId',
      content: cellContent(item, 'agencyExternalId', item.agencyExternalId),
      error: checkForInvalidField(item, 'agencyExternalId'),
    },
    {
      key: 'lastModified',
      content: cellContent(item, 'lastModified', item.lastModified),
      error: checkForInvalidField(item, 'lastModified'),
    },
    {
      key: 'firstName',
      content: cellContent(item, 'firstName', item.firstName),
      error: checkForInvalidField(item, 'firstName'),
    },
    {
      key: 'lastName',
      content: cellContent(item, 'lastName', item.lastName),
      error: checkForInvalidField(item, 'lastName'),
    },
    {
      key: 'nickName',
      content: cellContent(item, 'nickName', item.nickName),
      error: checkForInvalidField(item, 'nickName'),
    },
    {
      key: 'middleName',
      content: cellContent(item, 'middleName', item.middleName),
      error: checkForInvalidField(item, 'middleName'),
    },
    {
      key: 'gender',
      content: cellContent(item, 'gender', item.gender),
      error: checkForInvalidField(item, 'gender'),
    },
    {
      key: 'suffix',
      content: cellContent(item, 'suffix', item.suffix),
      error: checkForInvalidField(item, 'suffix'),
    },
    {
      key: 'ethnicity',
      content: cellContent(item, 'ethnicity', item.ethnicity),
      error: checkForInvalidField(item, 'ethnicity'),
    },
    {
      key: 'marital',
      content: cellContent(item, 'marital', item.marital),
      error: checkForInvalidField(item, 'marital'),
    },
    {
      key: 'disciplines',
      content: cellContent(item, 'disciplines', item.disciplines),
      error: checkForInvalidField(item, 'disciplines'),
    },
    {
      key: 'complianceEndDate',
      content: cellContent(item, 'complianceEndDate', item.complianceEndDate),
      error: checkForInvalidField(item, 'complianceEndDate'),
    },
    {
      key: 'notificationRecipients',
      content: cellContent(item, 'notificationRecipients', item.notificationRecipients),
      error: checkForInvalidField(item, 'notificationRecipients'),
    },
    {
      key: 'hireDate',
      content: cellContent(item, 'hireDate', item.hireDate),
      error: checkForInvalidField(item, 'hireDate'),
    },
    {
      key: 'blockShiftMatching',
      content: cellContent(item, 'blockShiftMatching', item.blockShiftMatching),
      error: checkForInvalidField(item, 'blockShiftMatching'),
    },
    {
      key: 'facebookLink',
      content: cellContent(item, 'facebookLink', item.facebookLink),
      error: checkForInvalidField(item, 'facebookLink'),
    },
    {
      key: 'linkedinLink',
      content: cellContent(item, 'linkedinLink', item.linkedinLink),
      error: checkForInvalidField(item, 'linkedinLink'),
    },
    {
      key: 'activationDate',
      content: cellContent(item, 'activationDate', item.activationDate),
      error: checkForInvalidField(item, 'activationDate'),
    },
    {
      key: 'preferenceNames',
      content: cellContent(item, 'preferenceNames', item.preferenceNames),
      error: checkForInvalidField(item, 'preferenceNames'),
    },
    {
      key: 'email',
      content: cellContent(item, 'email', item.email),
      error: checkForInvalidField(item, 'email'),
    },
    {
      key: 'ssn',
      content: cellContent(item, 'ssn', item.ssn),
      error: checkForInvalidField(item, 'ssn'),
    },
    {
      key: 'dob',
      content: cellContent(item, 'dob', item.dob),
      error: checkForInvalidField(item, 'dob'),
    },
    {
      key: 'staffStatus',
      content: cellContent(item, 'staffStatus', item.staffStatus),
      error: checkForInvalidField(item, 'staffStatus'),
    },
    {
      key: 'street',
      content: cellContent(item, 'street', item.street),
      error: checkForInvalidField(item, 'street'),
    },
    {
      key: 'street2',
      content: cellContent(item, 'street2', item.street2),
      error: checkForInvalidField(item, 'street2'),
    },
    {
      key: 'apartment',
      content: cellContent(item, 'apartment', item.apartment),
      error: checkForInvalidField(item, 'apartment'),
    },
    {
      key: 'city',
      content: cellContent(item, 'city', item.city),
      error: checkForInvalidField(item, 'city'),
    },
    {
      key: 'state',
      content: cellContent(item, 'state', item.state),
      error: checkForInvalidField(item, 'state'),
    },
    {
      key: 'zip',
      content: cellContent(item, 'zip', item.zip),
      error: checkForInvalidField(item, 'zip'),
    },
    {
      key: 'countryCode',
      content: cellContent(item, 'countryCode', item.countryCode),
      error: checkForInvalidField(item, 'countryCode'),
    },
    {
      key: 'languages',
      content: cellContent(item, 'languages', item.languages),
      error: checkForInvalidField(item, 'languages'),
    },
    {
      key: 'primaryLanguage',
      content: cellContent(item, 'primaryLanguage', item.primaryLanguage),
      error: checkForInvalidField(item, 'primaryLanguage'),
    },
    {
      key: 'mobilePhone',
      content: cellContent(item, 'mobilePhone', item.mobilePhone),
      error: checkForInvalidField(item, 'mobilePhone'),
    },
    {
      key: 'emergencyContactName',
      content: cellContent(item, 'emergencyContactName', item.emergencyContactName),
      error: checkForInvalidField(item, 'emergencyContactName'),
    },
    {
      key: 'emergencyContactRelation',
      content: cellContent(item, 'emergencyContactRelation', item.emergencyContactRelation),
      error: checkForInvalidField(item, 'emergencyContactRelation'),
    },
    {
      key: 'emergencyContactNumber',
      content: cellContent(item, 'emergencyContactNumber', item.emergencyContactNumber),
      error: checkForInvalidField(item, 'emergencyContactNumber'),
    },
    {
      key: 'primaryContactExternalId',
      content: cellContent(item, 'primaryContactExternalId', item.primaryContactExternalId),
      error: checkForInvalidField(item, 'primaryContactExternalId'),
    },
  ];
};

const employeeCells = (item: Employee, failRecords: IFailRecords[]) => {
  const failRecord = failRecords.find(r => r.externalId === item.externalId);
  return [
    {
      key: 'externalId',
      content: cellContent(item, 'externalId', item.externalId, failRecord),
      error: checkForInvalidField(item, 'externalId'),
    },
    {
      key: 'agencyExternalId',
      content: cellContent(item, 'agencyExternalId', item.agencyExternalId),
      error: checkForInvalidField(item, 'agencyExternalId'),
    },
    {
      key: 'lastModified',
      content: cellContent(item, 'lastModified', item.lastModified),
      error: checkForInvalidField(item, 'lastModified'),
    },
    {
      key: 'firstName',
      content: cellContent(item, 'firstName', item.firstName),
      error: checkForInvalidField(item, 'firstName'),
    },
    {
      key: 'lastName',
      content: cellContent(item, 'lastName', item.lastName),
      error: checkForInvalidField(item, 'lastName'),
    },
    {
      key: 'middleName',
      content: cellContent(item, 'middleName', item.middleName),
      error: checkForInvalidField(item, 'middleName'),
    },
    {
      key: 'ssn',
      content: cellContent(item, 'ssn', item.ssn),
      error: checkForInvalidField(item, 'ssn'),
    },
    {
      key: 'dob',
      content: cellContent(item, 'dob', item.dob),
      error: checkForInvalidField(item, 'dob'),
    },
    {
      key: 'gender',
      content: cellContent(item, 'gender', item.gender),
      error: checkForInvalidField(item, 'gender'),
    },
    {
      key: 'email',
      content: cellContent(item, 'email', item.email),
      error: checkForInvalidField(item, 'email'),
    },
    {
      key: 'staffStatus',
      content: cellContent(item, 'staffStatus', item.staffStatus),
      error: checkForInvalidField(item, 'staffStatus'),
    },
    {
      key: 'isCoordinator',
      content: cellContent(item, 'isCoordinator', item.isCoordinator),
      error: checkForInvalidField(item, 'isCoordinator'),
    },
    {
      key: 'isManager',
      content: cellContent(item, 'isManager', item.isManager),
      error: checkForInvalidField(item, 'isManager'),
    },
    {
      key: 'unit',
      content: cellContent(item, 'unit', item.unit),
      error: checkForInvalidField(item, 'unit'),
    },
    {
      key: 'location',
      content: cellContent(item, 'location', item.location),
      error: checkForInvalidField(item, 'location'),
    },
    {
      key: 'surveyNames',
      content: cellContent(item, 'surveyNames', item.surveyNames),
      error: checkForInvalidField(item, 'surveyNames'),
    },
    {
      key: 'days',
      content: cellContent(item, 'days', item.days),
      error: checkForInvalidField(item, 'days'),
    },
    {
      key: 'sendAt',
      content: cellContent(item, 'sendAt', item.sendAt),
      error: checkForInvalidField(item, 'sendAt'),
    },
  ];
};

const clientCells = (item: Client, failRecords: IFailRecords[]) => {
  const failRecord = failRecords.find(r => r.externalId === item.externalId);
  return [
    {
      key: 'externalId',
      content: cellContent(item, 'externalId', item.externalId, failRecord),
      error: checkForInvalidField(item, 'externalId'),
    },
    {
      key: 'agencyExternalId',
      content: cellContent(item, 'agencyExternalId', item.agencyExternalId),
      error: checkForInvalidField(item, 'agencyExternalId'),
    },
    {
      key: 'status',
      content: cellContent(item, 'status', item.status),
      error: checkForInvalidField(item, 'status'),
    },
    {
      key: 'lastModified',
      content: cellContent(item, 'lastModified', item.lastModified),
      error: checkForInvalidField(item, 'lastModified'),
    },
    {
      key: 'firstName',
      content: cellContent(item, 'firstName', item.firstName),
      error: checkForInvalidField(item, 'firstName'),
    },
    {
      key: 'lastName',
      content: cellContent(item, 'lastName', item.lastName),
      error: checkForInvalidField(item, 'lastName'),
    },
    {
      key: 'middleName',
      content: cellContent(item, 'middleName', item.middleName),
      error: checkForInvalidField(item, 'middleName'),
    },
    {
      key: 'title',
      content: cellContent(item, 'title', item.title),
      error: checkForInvalidField(item, 'title'),
    },
    {
      key: 'email',
      content: cellContent(item, 'email', item.email),
      error: checkForInvalidField(item, 'email'),
    },
    {
      key: 'dob',
      content: cellContent(item, 'dob', item.dob),
      error: checkForInvalidField(item, 'dob'),
    },
    {
      key: 'ssn',
      content: cellContent(item, 'ssn', item.ssn),
      error: checkForInvalidField(item, 'ssn'),
    },
    {
      key: 'gender',
      content: cellContent(item, 'gender', item.gender),
      error: checkForInvalidField(item, 'gender'),
    },
    {
      key: 'priorityCode',
      content: cellContent(item, 'priorityCode', item.priorityCode),
      error: checkForInvalidField(item, 'priorityCode'),
    },
    {
      key: 'defaultService',
      content: cellContent(item, 'defaultService', item.defaultService),
      error: checkForInvalidField(item, 'defaultService'),
    },
    {
      key: 'startDate',
      content: cellContent(item, 'startDate', item.startDate),
      error: checkForInvalidField(item, 'startDate'),
    },
    {
      key: 'holdDate',
      content: cellContent(item, 'holdDate', item.holdDate),
      error: checkForInvalidField(item, 'holdDate'),
    },
    {
      key: 'referralDate',
      content: cellContent(item, 'referralDate', item.referralDate),
      error: checkForInvalidField(item, 'referralDate'),
    },
    {
      key: 'dischargeDate',
      content: cellContent(item, 'dischargeDate', item.dischargeDate),
      error: checkForInvalidField(item, 'dischargeDate'),
    },
    {
      key: 'cancelDate',
      content: cellContent(item, 'cancelDate', item.cancelDate),
      error: checkForInvalidField(item, 'cancelDate'),
    },
    {
      key: 'notes',
      content: cellContent(item, 'notes', item.notes),
      error: checkForInvalidField(item, 'notes'),
    },
    {
      key: 'primaryContactExternalId',
      content: cellContent(item, 'primaryContactExternalId', item.primaryContactExternalId),
      error: checkForInvalidField(item, 'primaryContactExternalId'),
    },
    {
      key: 'preferenceNames',
      content: cellContent(item, 'preferenceNames', item.preferenceNames),
      error: checkForInvalidField(item, 'preferenceNames'),
    },
    {
      key: 'street',
      content: cellContent(item, 'street', item.street),
      error: checkForInvalidField(item, 'street'),
    },
    {
      key: 'street2',
      content: cellContent(item, 'street2', item.street2),
      error: checkForInvalidField(item, 'street2'),
    },
    {
      key: 'apartment',
      content: cellContent(item, 'apartment', item.apartment),
      error: checkForInvalidField(item, 'apartment'),
    },
    {
      key: 'city',
      content: cellContent(item, 'city', item.city),
      error: checkForInvalidField(item, 'city'),
    },
    {
      key: 'state',
      content: cellContent(item, 'state', item.state),
      error: checkForInvalidField(item, 'state'),
    },
    {
      key: 'zip',
      content: cellContent(item, 'zip', item.zip),
      error: checkForInvalidField(item, 'zip'),
    },
    {
      key: 'countryCode',
      content: cellContent(item, 'countryCode', item.countryCode),
      error: checkForInvalidField(item, 'countryCode'),
    },
    {
      key: 'languages',
      content: cellContent(item, 'languages', item.languages),
      error: checkForInvalidField(item, 'languages'),
    },
    {
      key: 'primaryLanguage',
      content: cellContent(item, 'primaryLanguage', item.primaryLanguage),
      error: checkForInvalidField(item, 'primaryLanguage'),
    },
    {
      key: 'mobilePhone',
      content: cellContent(item, 'mobilePhone', item.mobilePhone),
      error: checkForInvalidField(item, 'mobilePhone'),
    },
    {
      key: 'homePhone',
      content: cellContent(item, 'homePhone', item.homePhone),
      error: checkForInvalidField(item, 'homePhone'),
    },
    {
      key: 'workPhone',
      content: cellContent(item, 'workPhone', item.workPhone),
      error: checkForInvalidField(item, 'workPhone'),
    },
  ];
};

const shiftCells = (item: Shift, failRecords: IFailRecords[]) => {
  const failRecord = failRecords.find(r => r.externalId === item.externalId);
  return [
    {
      key: 'externalId',
      content: cellContent(item, 'externalId', item.externalId, failRecord),
      error: checkForInvalidField(item, 'externalId'),
    },
    {
      key: 'agencyExternalId',
      content: cellContent(item, 'agencyExternalId', item.agencyExternalId),
      error: checkForInvalidField(item, 'agencyExternalId'),
    },
    {
      key: 'lastModified',
      content: cellContent(item, 'lastModified', item.lastModified),
      error: checkForInvalidField(item, 'lastModified'),
    },
    {
      key: 'clientExternalId',
      content: cellContent(item, 'clientExternalId', item.clientExternalId),
      error: checkForInvalidField(item, 'clientExternalId'),
    },
    {
      key: 'eventCode',
      content: cellContent(item, 'eventCode', item.eventCode),
      error: checkForInvalidField(item, 'eventCode'),
    },
    {
      key: 'clientScheduleType',
      content: cellContent(item, 'clientScheduleType', item.clientScheduleType),
      error: checkForInvalidField(item, 'clientScheduleType'),
    },
    {
      key: 'employeeScheduleType',
      content: cellContent(item, 'employeeScheduleType', item.employeeScheduleType),
      error: checkForInvalidField(item, 'employeeScheduleType'),
    },
    {
      key: 'admissionType',
      content: cellContent(item, 'admissionType', item.admissionType),
      error: checkForInvalidField(item, 'admissionType'),
    },
    {
      key: 'company',
      content: cellContent(item, 'company', item.company),
      error: checkForInvalidField(item, 'company'),
    },
    {
      key: 'payerId',
      content: cellContent(item, 'payerId', item.payerId),
      error: checkForInvalidField(item, 'payerId'),
    },
    {
      key: 'payerName',
      content: cellContent(item, 'payerName', item.payerName),
      error: checkForInvalidField(item, 'payerName'),
    },
    {
      key: 'start',
      content: cellContent(item, 'start', item.start),
      error: checkForInvalidField(item, 'start'),
    },
    {
      key: 'end',
      content: cellContent(item, 'end', item.end),
      error: checkForInvalidField(item, 'end'),
    },
    {
      key: 'service',
      content: cellContent(item, 'service', item.service),
      error: checkForInvalidField(item, 'service'),
    },
    {
      key: 'status',
      content: cellContent(item, 'status', item.status),
      error: checkForInvalidField(item, 'status'),
    },
    {
      key: 'type',
      content: cellContent(item, 'type', item.type),
      error: checkForInvalidField(item, 'type'),
    },
    {
      key: 'serviceDescription',
      content: cellContent(item, 'serviceDescription', item.serviceDescription),
      error: checkForInvalidField(item, 'serviceDescription'),
    },
    {
      key: 'cancellationCode',
      content: cellContent(item, 'cancellationCode', item.cancellationCode),
      error: checkForInvalidField(item, 'cancellationCode'),
    },
    {
      key: 'recurringPattern',
      content: cellContent(item, 'recurringPattern', item.recurringPattern),
      error: checkForInvalidField(item, 'recurringPattern'),
    },
    {
      key: 'template',
      content: cellContent(item, 'template', item.template),
      error: checkForInvalidField(item, 'template'),
    },
    {
      key: 'notes',
      content: cellContent(item, 'notes', item.notes),
      error: checkForInvalidField(item, 'notes'),
    },
    {
      key: 'assignTo',
      content: cellContent(item, 'assignTo', item.assignTo),
      error: checkForInvalidField(item, 'assignTo'),
    },
    {
      key: 'timezone',
      content: cellContent(item, 'timezone', item.timezone),
      error: checkForInvalidField(item, 'timezone'),
    },
  ];
};

export {
  caregiverHeaders,
  clientHeaders,
  employeeHeaders,
  shiftHeaders,
  caregiverCells,
  clientCells,
  employeeCells,
  shiftCells,
};

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-destructuring */
import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Dimmer, Modal, Dropdown, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Quiz from './Quiz';
import ELSideInfo from '../../ELSideInfo';
import { history } from '../../../helpers';
import { sortCoursesList } from '../../../helpers/common';
import { GreenButton, SimpleButton } from '../../../styles/common';
import { ELCoursesActions } from '../../../actions/ELApp/ElCoursesActions';
import { QuizActions } from '../../../actions/ELApp/QuizActions';
import { userActions } from '../../../actions';
import CareLoader from '../CareLoader';
import SignCertificate from './SignCertificate';
import '../../ELCourses.css';

// eslint-disable-next-line react/prop-types
const NextPrefCourse = ({ courses, buttonLoading, handleViewCourseInfo, dispatch, language }) => {
  const handleQuickStart = (course, lang) => {
    dispatch(userActions.getUserSummary());
    if (!course.isRegistered) {
      const params = {
        id: course.id,
        courseLanguageId: lang,
      };
      return dispatch(ELCoursesActions.startCourse(params));
    }
    return dispatch(ELCoursesActions.resumeCourse(course.id));
  };

  const inProgressCourses = courses.filter(c => c.isRegistered && !c.completionDate);
  const notStartedCourses = sortCoursesList(courses).filter(c => !c.isRegistered);

  let nextPreferenceCourse;
  let buttonText;
  if (inProgressCourses.length) {
    nextPreferenceCourse = inProgressCourses[0];
    buttonText = 'Continue';
  } else if (notStartedCourses.length) {
    nextPreferenceCourse = notStartedCourses[0];
    buttonText = 'Start Course';
  } else {
    nextPreferenceCourse = {};
  }

  const userLanguage =
    nextPreferenceCourse.availableLanguages && nextPreferenceCourse.availableLanguages.length
      ? nextPreferenceCourse.availableLanguages
          .filter(l =>
            l.name.toLowerCase() === language.length ? language[0].toLowerCase() : 'english'
          )
          .map(l => l.id)[0]
      : [];

  return nextPreferenceCourse.id ? (
    <div className='next-course-details-modules-container'>
      <h3 className='course-details-module-header'>Suggested Next Course</h3>
      <h4 className='next-course-suggested-header'>{nextPreferenceCourse.courseName}</h4>
      <div className='course-details-module-list'>
        <ReactMarkdown className='next-course-suggested-description'>
          {nextPreferenceCourse.courseDescription || ''}
        </ReactMarkdown>
        <div>
          <span>
            <SimpleButton
              className='course-record-view-button'
              onClick={() => handleViewCourseInfo(nextPreferenceCourse.id)}
            >
              View Course Info
            </SimpleButton>
          </span>
          <span>
            <GreenButton
              loading={buttonLoading}
              disabled={buttonLoading}
              onClick={() => handleQuickStart(nextPreferenceCourse, userLanguage)}
              className='course-record-resume-button'
            >
              {buttonText}
            </GreenButton>
          </span>
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
};

NextPrefCourse.prototypes = {
  courses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  handleViewCourseInfo: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

class CourseViewDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      courseFetched: false,
      quizOn: false,
      moduleId: null,
      moduleDescription: '',
      handleQuickStart: this.handleQuickStart.bind(this),
      handleOpenSignCertificate: this.handleOpenSignCertificate.bind(this),
      nextPrefCourse: false,
      language: null,
      fetchedCourses: false,
      nextAvailableCourseId: null,
      fetchNextAvailableCourse: false,
      courseInProgress: false,
      reFetchExitedModule: false,
      scorm: false,
      changeLanguageConfirmation: false,
      changeLanguage: null,
      certificateDownloaded: false,
      certificationCourseId: null,
      signCertificate: false,
      signatureAgencyUuid: '',
      courseToSign: null,
      checkedForNewSignature: true,
      coursesSigned: [],
      signingAgency: '',
      nextModuleCounter: null,
    };
  }

  componentDidMount() {
    const language = this.props.profile?.languages
      ? this.props.profile.languages.filter(l => l.primary).map(p => p.code)
      : ['eng'];
    if (this.props.location.search && !this.state.reFetchExitedModule) {
      const params = new URLSearchParams(this.props.location.search);
      const moduleId = params.get('moduleID');
      const courseId = params.get('courseID');
      if (moduleId && courseId) {
        this.handleExitModule(moduleId, courseId, language[0]);
      }
    }
    this.props.dispatch(ELCoursesActions.getCoursesDetail(this.props.courseId, language[0]));
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.eLCourses.nextModule !== this.props.eLCourses.nextModule &&
      this.props.eLCourses.nextModule
    ) {
      if (this.state.quizOn) {
        this.autoplayAfterQuiz = true;
      } else {
        this.beginNextModuleCountdown();
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(ELCoursesActions.clearSetSingleCoursesDetail());
  }

  // eslint-disable-next-line react/sort-comp
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.eLCourses?.moduleOnProgress?.length) {
      const link = document.createElement('a');
      link.href = nextProps.eLCourses.moduleOnProgress;
      link.dispatchEvent(new MouseEvent('click'));
    }
    if (nextProps.eLCourses.resumeCourse && !prevState.quizOn) {
      const resumeModule = nextProps.eLCourses.courseDetails.modules
        ? nextProps.eLCourses.courseDetails.modules
            .filter(m => !m.dateCompleted && m.attemptCount < 3)
            .sort((a, b) => a.moduleOrder - b.moduleOrder)
        : [];
      if (resumeModule.length) {
        prevState.handleQuickStart(
          resumeModule[0],
          nextProps.eLCourses.courseDetails.userCourse.id
        );
      }
    }
    if (
      nextProps.eLCourses?.courseDetails?.userCourse?.completionDate &&
      !prevState.nextPrefCourse
    ) {
      nextProps.dispatch(ELCoursesActions.getCoursesList());
      return { nextPrefCourse: true };
    }
    if (!nextProps.eLCourses.courses.length && !prevState.fetchedCourses) {
      nextProps.dispatch(ELCoursesActions.getCoursesList());
      return { fetchedCourses: true };
    }
    if (nextProps.eLCourses.courses.length && !prevState.fetchNextAvailableCourse) {
      const sortedNotCompletedCourses = nextProps.eLCourses.courses.length
        ? sortCoursesList(nextProps.eLCourses.courses).filter(c => !c.completionDate)
        : null;
      const courseInProgress = nextProps.eLCourses.courses.length
        ? !!nextProps.eLCourses.courses.find(c => !c.completionDate && c.isRegistered)
        : false;
      const nextCourse = sortedNotCompletedCourses.length ? sortedNotCompletedCourses[0].id : null;
      return {
        nextAvailableCourseId: nextCourse,
        fetchNextAvailableCourse: true,
        courseInProgress,
      };
    }
    if (nextProps.eLCourses.exitedModule && !prevState.reFetchExitedModule) {
      const primaryLanguage = nextProps.profile?.languages?.length
        ? nextProps.profile.languages.filter(l => l.primary).map(p => p.code)
        : ['eng'];
      nextProps.dispatch(ELCoursesActions.getCoursesDetail(nextProps.courseId, primaryLanguage[0]));
      return { reFetchExitedModule: true };
    }
    if (nextProps.eLCourses?.courseCertificate && !prevState.certificateDownloaded) {
      const lnk = document.createElement('a');
      const url = nextProps.eLCourses.courseCertificate;

      lnk.download = 'course certification';
      lnk.href = url;
      lnk.target = '_blank';
      lnk.dispatchEvent(new MouseEvent('click'));
      nextProps.dispatch(ELCoursesActions.cleanCourseCertificate());
      return { certificateDownloaded: true };
    }
    if (prevState.certificateDownloaded && !nextProps.eLCourses?.certificationOn) {
      return { certificateDownloaded: false };
    }
    if (!prevState.checkedForNewSignature && nextProps.eLCourses?.checkRequiredSignature) {
      prevState.handleOpenSignCertificate(
        nextProps.eLCourses?.courseDetails?.userCourse,
        nextProps.eLCourses.coursesSigned
      );
      return {
        checkedForNewSignature: true,
      };
    }
    if (!prevState.checkedForNewSignature && nextProps.eLCourses?.signCourseComplete) {
      const primaryLanguage = nextProps.profile?.languages?.length
        ? nextProps.profile.languages.filter(l => l.primary).map(p => p.code)
        : ['eng'];
      nextProps.dispatch(ELCoursesActions.getCoursesDetail(nextProps.courseId, primaryLanguage[0]));
      return {
        checkedForNewSignature: true,
      };
    }
    return null;
  }

  onLanguageChange = (v, isRegistered) => {
    const { courseDetails } = this.props.eLCourses;

    const { userCourse } = courseDetails;

    const registeredLanguage = userCourse.availableLanguages
      .filter(l => l.name === userCourse.languageName)
      .map(r => r.id)[0];

    const languageCode = v.options.filter(o => o.value === v.value).map(c => c.code);
    if (!isRegistered) {
      this.props.dispatch(ELCoursesActions.getCoursesDetail(this.props.courseId, languageCode[0]));
      return this.setState({ language: v.value });
    }
    if (v.value !== registeredLanguage) {
      return this.setState({ changeLanguageConfirmation: true, changeLanguage: v.value });
    }
    return null;
  };

  handleViewCourseInfo = id => history.push(`/home/e-learning/courses/course-details/${id}`);

  handleQuickStartCourse = (courseId, languageId) => {
    const { dispatch } = this.props;
    dispatch(userActions.getUserSummary());
    const params = {
      id: courseId,
      courseLanguageId: languageId,
    };
    return dispatch(ELCoursesActions.startCourse(params));
  };

  handleQuickStart(m, courseId) {
    if (m.moduleType === 'Quiz') {
      const params = {
        courseId,
        moduleId: m.id,
      };
      this.props.dispatch(QuizActions.createQuizAttempt(params));
      this.stopNextModule();
      return this.setState({ quizOn: true, moduleId: m.id, moduleDescription: m.description });
    }
    const params = {
      courseId,
      moduleId: m.id,
      returnUrl: this.props.location.pathname,
    };
    return this.props.dispatch(ELCoursesActions.startModule(params));
  }

  handleExitModule = (moduleId, courseId, language) => {
    const params = {
      courseId,
      moduleId,
    };
    this.props.dispatch(ELCoursesActions.exitModule(params, language));
  };

  handleGetCertificate = courseId => {
    this.props.dispatch(ELCoursesActions.getCourseCertificate(courseId));
  };

  handleClose = () => {
    this.props.dispatch(ELCoursesActions.cleanCourseCertificate());
  };

  handleResumeCourse = id => {
    this.props.dispatch(ELCoursesActions.deleteCourse(id));
    this.setState({ courseInProgress: false });
  };

  handleChangeCourseLanguage = () => {
    this.handleResumeCourse(this.props.eLCourses.courseDetails.userCourse.id);
    this.setState({
      changeLanguageConfirmation: false,
      // eslint-disable-next-line react/no-access-state-in-setstate
      language: this.state.changeLanguage,
    });
  };

  handleOpenSignCertificate = (course, coursesSigned = []) => {
    const { dispatch } = this.props;
    const courseToSign = course.curriculums.find(
      c => !coursesSigned.includes(c.curriculumId) && c.signatureTemplateId && !c.signatureDate
    );
    if (courseToSign?.curriculumId) {
      const agency = course.agencies.find(a => a.uuid === courseToSign.agencyUuid);
      const templateParams = {
        courseId: course.id,
        agencyUuid: courseToSign.agencyUuid,
      };
      dispatch(ELCoursesActions.getESignature());
      dispatch(ELCoursesActions.getSigningTemplate(templateParams));
      return this.setState(s => ({
        ...s,
        signCertificate: true,
        signatureAgencyUuid: courseToSign.agencyUuid,
        courseToSign: course.id,
        curriculumSigned: courseToSign.curriculumId,
        checkedForNewSignature: true,
        signingAgency: agency.name,
      }));
    }
    return this.setState(s => ({
      ...s,
      checkedForNewSignature: true,
      signCertificate: false,
    }));
  };

  handleCloseSignCertificate = () => {
    this.setState(s => ({ ...s, signCertificate: false }));
  };

  handleSubmitSignature = () => {
    const { dispatch } = this.props;
    const { signatureAgencyUuid, courseToSign, curriculumSigned } = this.state;
    const params = {
      courseId: courseToSign,
      agencyUuid: signatureAgencyUuid,
      curriculumId: curriculumSigned,
    };
    dispatch(ELCoursesActions.postSignedTemplate(params));
    return this.setState(s => ({ ...s, checkedForNewSignature: false, signCertificate: false }));
  };

  handleCloseLanguageConfirmation = () => this.setState({ changeLanguageConfirmation: false });

  closeDetailsClicked = () => {
    this.props.dispatch(QuizActions.clearQuiz());
    this.props.dispatch(ELCoursesActions.getCoursesDetail(this.props.courseId));
    this.setState({ quizOn: false, moduleId: null, moduleDescription: '' });
    if (this.autoplayAfterQuiz) {
      this.autoplayAfterQuiz = false;
      this.beginNextModuleCountdown();
    }
  };

  beginNextModuleCountdown = () => {
    let nextModuleCounter = 10;

    const updateCountdown = () => {
      this.setState({ nextModuleCounter });
      if (nextModuleCounter === 0) {
        clearInterval(this.nextModuleInterval);
        const { nextModule } = this.props.eLCourses;
        this.handleQuickStart(nextModule, nextModule.courseId);
      }
      nextModuleCounter -= 1;
    };
    this.nextModuleInterval = setInterval(updateCountdown, 1000);
    updateCountdown();
  };

  stopNextModule = () => {
    if (this.nextModuleInterval != null) clearInterval(this.nextModuleInterval);
    this.setState({ nextModuleCounter: null });
  };

  displayCertificationsAgency = (courseId, agencies) => {
    if (agencies.length === 1) {
      return this.handleDownloadCertificate(agencies[0].uuid, courseId);
    }
    if (this.state.certificationCourseId === courseId) {
      return this.setState({ certificationCourseId: null });
    }
    return this.setState({ certificationCourseId: courseId });
  };

  handleDownloadCertificate = (agencyUuid, courseId) => {
    const params = {
      courseId,
      agencyUuid,
    };
    this.props.dispatch(ELCoursesActions.getCourseCertificate(params));
    this.setState({ certificationCourseId: null });
  };

  renderActionButton = courseDetails => {
    const { buttonLoading } = this.props.eLCourses;
    const { courseInProgress, language, certificationCourseId } = this.state;
    const modulesComplete = courseDetails?.modules
      ? courseDetails?.modules?.filter(m => m.dateCompleted)
      : [];
    const allModulesComplete = courseDetails
      ? modulesComplete.length === courseDetails?.modules?.length
      : false;
    const { userCourse } = courseDetails || null;

    const courseId = userCourse ? userCourse.id : null;
    const agencies = userCourse ? userCourse.agencies : [];
    const completeCourse = !!userCourse?.completionDate;
    const registered = userCourse?.isRegistered;
    const requireSignatureCurriculums = userCourse
      ? userCourse.curriculums.filter(c => c.signatureTemplateId)
      : [];

    if (completeCourse) {
      return (
        <div className='course-record-view-certificate course-details-view-certificate-button'>
          <div
            role='button'
            tabIndex='0'
            loading={buttonLoading}
            disabled={buttonLoading}
            className={`course-record-view-certificate-dropdown ${
              certificationCourseId === courseId
                ? 'certificate-dropdown-open'
                : 'certificate-dropdown-closed'
            }`}
            onClick={() => this.displayCertificationsAgency(courseId, agencies)}
          >
            <p className='course-record-view-certificate-dropdown-text'>View Certificate</p>
          </div>
          <div
            className={`view-certificate-agencies-dropdown ${
              certificationCourseId === courseId ? '' : 'agencies-dropdown-closed'
            } `}
          >
            <p className='agencies-dropdown-text'>Select an Agency</p>
            {agencies?.length &&
              agencies.map(a => (
                <div
                  role='button'
                  tabIndex='0'
                  loading={buttonLoading}
                  disabled={buttonLoading}
                  className='course-record-view-certificate-button'
                  onClick={() => this.handleDownloadCertificate(a.uuid, courseId)}
                >
                  {a.name}
                </div>
              ))}
          </div>
        </div>
      );
    }
    if (registered && allModulesComplete && requireSignatureCurriculums.length) {
      return (
        <GreenButton
          loading={buttonLoading}
          disabled={buttonLoading}
          onClick={() => this.handleOpenSignCertificate(userCourse)}
          className='course-details-view-certificate-button'
        >
          Sign Completion and Submit
        </GreenButton>
      );
    }
    if (registered) {
      return (
        <GreenButton
          loading={buttonLoading}
          disabled={buttonLoading}
          onClick={() => this.handleResumeCourse(courseId)}
          className='course-details-view-certificate-button reset-button'
        >
          Reset Course
        </GreenButton>
      );
    }
    return (
      <GreenButton
        loading={buttonLoading}
        disabled={!language || buttonLoading || courseInProgress}
        onClick={() => this.handleQuickStartCourse(courseId, language)}
        className='course-details-view-certificate-button'
      >
        Start Course
      </GreenButton>
    );
  };

  renderModules = (modules, buttonLoading, courseId) => (
    <div className='course-details-modules-container'>
      <h3 className='course-details-module-header'>Modules</h3>
      <div className='course-details-module-list'>
        {modules
          .sort((a, b) => a.moduleOrder - b.moduleOrder)
          .map((m, i, a) => {
            let resolution;
            if (m.dateCompleted) {
              resolution = <p className='course-details-module-complete'>complete</p>;
            } else if (i === 0 || a[i - 1].dateCompleted) {
              let buttonText;
              let maxAttempts;
              if (!m.attemptCount) {
                buttonText = 'Start';
                maxAttempts = false;
              } else if (m.moduleType === 'Scorm') {
                buttonText = 'Continue';
                maxAttempts = false;
              } else if (m.moduleType === 'Quiz' && m.attemptCount < 3) {
                buttonText = 'Retry';
                maxAttempts = false;
              } else {
                buttonText = 'Max Attempts';
                maxAttempts = true;
              }
              resolution = (
                <GreenButton
                  loading={buttonLoading}
                  disabled={buttonLoading || maxAttempts}
                  onClick={() => this.handleQuickStart(m, courseId)}
                  className='quick-start-button'
                >
                  {buttonText}
                </GreenButton>
              );
            } else {
              resolution = <div />;
            }
            return (
              <div key={m.id} className='course-details-module'>
                <div className='course-details-module-container'>
                  <p className='course-details-module-name'>{m.displayName || ''}</p>
                </div>

                {resolution}
              </div>
            );
          })}
      </div>
    </div>
  );

  render() {
    const { signCertificate, signingAgency, nextModuleCounter } = this.state;
    const { eLCourses, dispatch } = this.props;
    const { courseDetails, loading, buttonLoading, courses, nextModule } = eLCourses;

    const { userCourse } = courseDetails;

    const name = userCourse ? userCourse.courseName : null;

    const modules = courseDetails?.modules ? courseDetails.modules : [];

    const description = userCourse ? userCourse.courseDescription : '';

    const objective = userCourse ? userCourse.objective : '';

    const language = userCourse?.isRegistered ? userCourse.courseLanguageId : this.state.language;

    const languageOptions = userCourse
      ? [
          { value: null, code: '', text: null },
          ...userCourse.availableLanguages.map(l => {
            const lValue = { value: l.id, code: l.code, text: l.name };
            return lValue;
          }),
        ]
      : [{ value: null, code: '', text: null }];

    const courseId = userCourse ? userCourse.id : null;

    const completeCourse = !!userCourse?.completionDate;

    const pendingCourses = courses.filter(c => !c.completionDate);

    const userLanguage = this.props.profile?.languages
      ? this.props.profile.languages.filter(l => l.primary).map(n => n.name)
      : ['english'];

    const courseLength = userCourse?.courseLength;

    const eSignature = eLCourses?.eSignature;
    const signTemplate = eLCourses?.signTemplate;

    const agenciesWithPolicyText = userCourse ? userCourse.agencies.filter(e => e.policyText) : [];

    return (
      <div className='course-details-main-content'>
        {loading && (
          <Dimmer active inverted>
            <CareLoader loading={loading} showText={false} />
          </Dimmer>
        )}
        {signCertificate && (
          <Modal
            style={{ marginTop: '-45vh', borderRadius: '20px' }}
            closeOnDimmerClick={false}
            open
            scrolling='true'
            dimmer
            size='small'
          >
            <Modal.Content style={{ borderRadius: '20px' }}>
              <SignCertificate
                signatureUrl={eSignature}
                handleCancel={this.handleCloseSignCertificate}
                handleSubmit={this.handleSubmitSignature}
                signTemplate={signTemplate}
                signingAgency={signingAgency}
                dispatch={dispatch}
                loading={loading}
              />
            </Modal.Content>
          </Modal>
        )}
        {this.state.changeLanguageConfirmation && (
          <Modal closeOnDimmerClick={false} open dimmer size='small' style={{ marginTop: '-20vh' }}>
            <div className='Change-language-confirmation-container'>
              <p className='Change-language-confirmation-text'>
                Changing course language will restart the course and you will lost your actual
                progress.
              </p>
              <p className='Change-language-confirmation-text'>
                Are you sure you want to change the course language?
              </p>
              <div className='Change-language-confirmation-bottoms'>
                <GreenButton
                  className='Change-language-confirmation-bottom-yes'
                  onClick={() => this.handleChangeCourseLanguage()}
                >
                  Yes
                </GreenButton>
                <GreenButton
                  className='Change-language-confirmation-bottom-no'
                  onClick={() => this.handleCloseLanguageConfirmation()}
                >
                  No
                </GreenButton>
              </div>
            </div>
          </Modal>
        )}
        {nextModuleCounter != null && (
          <Modal closeOnDimmerClick={false} open dimmer size='mini' style={{ marginTop: '-20vh' }}>
            <Modal.Content style={{ borderRadius: '20px', textAlign: 'center', padding: '3rem' }}>
              <h3>Congratulations!</h3>
              <p>
                You have completed &apos;{nextModule.prevName}&apos;.
                <br />
                <strong>{`'${nextModule.displayName}' will begin in ${nextModuleCounter} seconds...`}</strong>
              </p>
              <Button basic color='red' onClick={this.stopNextModule}>
                Cancel
              </Button>
            </Modal.Content>
          </Modal>
        )}
        {this.state.quizOn && (
          <Quiz
            description={this.state.moduleDescription}
            closeDetailsClicked={this.closeDetailsClicked}
          />
        )}
        <div
          className='course-details-back-button'
          role='button'
          tabIndex={0}
          onClick={() => history.push('/home/e-learning/courses', 0)}
        >
          ‹‹ Back To Course List
        </div>
        <h1 className='course-details-name-header'>{name}</h1>
        {this.renderActionButton(courseDetails)}
        <ELSideInfo className='your-courses-side-help-container' />
        <div className='course-details-container'>
          <p className='course-details-little-header'>Description:</p>
          <ReactMarkdown>{description || ''}</ReactMarkdown>
          Time: {courseLength}mins
          <p className='course-details-little-header'>Chosen Language</p>
          <div className='course-details-language-container'>
            <Dropdown
              id='course-language-dropdown'
              className='course-language-selection'
              name='languages'
              placeholder='Select Language'
              selection
              disabled={completeCourse}
              options={languageOptions}
              value={language}
              onChange={(e, v) => this.onLanguageChange(v, userCourse.isRegistered)}
            />
          </div>
          <p className='course-details-little-header'>Course Objective:</p>
          <ReactMarkdown>{objective || ''}</ReactMarkdown>
          {!!agenciesWithPolicyText.length && (
            <p className='course-details-little-header'>Policies:</p>
          )}
          {!!agenciesWithPolicyText.length &&
            agenciesWithPolicyText.map(e => (
              <>
                <span className='course-details-agency-name-header'>{e.name}</span>
                <ReactMarkdown>{e.policyText}</ReactMarkdown>
              </>
            ))}
        </div>
        {!!modules.length && this.renderModules(modules, buttonLoading, courseId)}
        {this.state.nextPrefCourse && !!pendingCourses.length && (
          <NextPrefCourse
            courses={courses}
            buttonLoading={buttonLoading}
            handleViewCourseInfo={this.handleViewCourseInfo}
            dispatch={this.props.dispatch}
            language={userLanguage}
          />
        )}
      </div>
    );
  }
}

CourseViewDetails.propTypes = {
  eLCourses: PropTypes.shape().isRequired,
  courseId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  profile: PropTypes.shape().isRequired,
};

const mapStateToProps = state => {
  const { eLCourses, principal } = state;
  const { profile } = principal;
  return {
    eLCourses,
    profile,
  };
};

export default connect(mapStateToProps)(CourseViewDetails);

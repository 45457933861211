/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Form, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class DaveBox extends React.Component {
  constructor() {
    super();
    this.onLabelClick = this.onLabelClick.bind(this);
    this.onCheckboxClick = this.onCheckboxClick.bind(this);
  }

  onLabelClick(e) {
    const { onChange, disabled } = this.props;
    if (!disabled) {
      onChange(e, {
        name: this.props.name,
        type: 'checkbox',
        checked: !this.props.checked,
        value: this.props.value,
      });
    }
  }

  onCheckboxClick(e, input) {
    e.stopPropagation();
    if (!this.props.disabled) {
      this.props.onChange(e, input);
    }
  }

  render() {
    const { label, name, checked, value, disabled, iconName, iconClass } = this.props;

    const dlabelClassName = checked ? 'dlabel checked-field' : 'dlabel field';

    const icon = iconName ? <Icon name={iconName} className={iconClass} /> : undefined;

    return (
      <div className='round' onClick={this.onLabelClick} role='presentation'>
        <div className={dlabelClassName}>
          {icon}
          {label}
        </div>
        <div id={this.props.id} className='dbox'>
          <Form.Checkbox
            label=''
            name={name}
            value={value}
            checked={checked}
            onChange={this.onCheckboxClick}
            disabled={disabled}
          />
        </div>
        <div style={{ clear: 'both' }} />
      </div>
    );
  }
}

DaveBox.defaultProps = {
  disabled: false,
  iconName: null,
  iconClass: null,
  id: '',
};

DaveBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  iconName: PropTypes.string,
  iconClass: PropTypes.string,
  id: PropTypes.string,
};

export default DaveBox;

import querystring from 'querystring';
import moment from 'moment';
import codes from 'iso-language-codes';
import { config } from '../helpers/config';
import { getVersion } from '../components/AppVersion';

class Client {
  static awsQueryString(params) {
    const aws = {};
    Object.keys(params).forEach(k => {
      aws[k] = Array.isArray(params[k]) ? params[k].join(',') : params[k];
    });

    return querystring.stringify(aws);
  }

  constructor() {
    this.principal = JSON.parse(localStorage.getItem(config.LOCAL_STORAGE_KEY));
    this.agencySettings = null;
  }

  getLanguage = () => {
    const lang = navigator.userLanguage || navigator.language;
    const sliceLang = lang.slice(0, 2);
    const langCode = codes.find(c => c.iso639_1 === sliceLang);
    return langCode.iso639_2T;
  };

  isLoggedIn() {
    this.principal = JSON.parse(localStorage.getItem(config.LOCAL_STORAGE_KEY));
    return !!this.principal;
  }

  async setPrincipal(principal) {
    this.principal = principal;
    localStorage.setItem(config.LOCAL_STORAGE_KEY, JSON.stringify(principal));

    // refresh and cache agency settings
    await this.getCachedAgencySettings();
  }

  removePrincipal() {
    this.principal = null;
    localStorage.removeItem(config.LOCAL_STORAGE_KEY);
    this.agencySettings = null;
  }

  getCachedSettings() {
    return this.principal ? this.principal.settings : null;
  }

  async getCachedAgencySettings() {
    if (
      !this.principal ||
      !this.principal?.roles?.find(r => r === 'admin' || r === 'scheduler' || r === 'hrmanager')
    ) {
      return null;
    }

    if (!this.agencySettings) {
      this.agencySettings = (await this.getAgencySettings(false))?.settings;
    }

    return this.agencySettings;
  }

  createHeaders(additionalHeaders = {}) {
    const authHeaders = this.isLoggedIn()
      ? { Authorization: `Bearer ${this.principal.token}`, 'x-api-key': config.API_GW_KEY }
      : { 'x-api-key': config.API_GW_KEY };
    const version = getVersion();
    const language = this.getLanguage();
    const timeZone = moment.tz.guess();

    const platform = {
      'x-cc-client': `platform=web;version=${version};lang=${language};tz=${timeZone}`,
    };
    return { ...authHeaders, ...additionalHeaders, ...platform };
  }

  authToken() {
    return this.isLoggedIn() ? this.principal.token : null;
  }

  login(username, password, agencyId) {
    const req = {
      username,
      password,
      type: 'webapp',
      agencies: true,
    };

    if (agencyId) req.agencyId = agencyId;
    return fetch(`${config.API_GW}/login`, {
      method: 'post',
      headers: this.createHeaders({ 'Content-type': 'application/json' }),
      body: JSON.stringify(req),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(({ user, token, roles, agencies }) => {
        if (agencies) return { agencies };
        this.setPrincipal({ user, token, roles });
        return { user, roles, agencies };
      });
  }

  getTerms() {
    return fetch(`${config.API_GW}/users/self/terms`, {
      method: 'get',
      headers: this.createHeaders({ 'Content-type': 'application/json' }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(response => response);
  }

  putTerms(approved, id) {
    return fetch(`${config.API_GW}/users/self/terms/${id}`, {
      method: 'put',
      headers: this.createHeaders({ 'Content-type': 'application/json' }),
      body: JSON.stringify({
        approved,
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(res => res);
  }

  loginWithToken(magicToken, dob) {
    return fetch(`${config.API_GW}/v2/login`, {
      method: 'post',
      headers: this.createHeaders({ 'Content-type': 'application/json' }),
      body: JSON.stringify({
        magicToken,
        dob,
        type: 'webapp',
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(({ user, token, settings, roles }) => {
        this.setPrincipal({ user, token, settings, roles });
        return { user, settings, roles };
      });
  }

  getMagicLogin(email) {
    return fetch(`${config.API_GW}/token?${Client.awsQueryString({ email, fromWeb: true })}`, {
      method: 'get',
      headers: this.createHeaders({ 'Content-type': 'application/json' }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(response => response);
  }

  signup(email, password, invitationToken) {
    return fetch(`${config.API_GW}/signup`, {
      method: 'post',
      headers: this.createHeaders({ 'Content-type': 'application/json' }),
      body: JSON.stringify({
        email,
        password,
        invitationToken,
        type: 'webapp',
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(({ user, token, settings, roles }) => {
        this.setPrincipal({ user, token, settings, roles });
        return { user, settings, roles };
      });
  }

  signupV2(verificationToken, dob) {
    return fetch(`${config.API_GW}/v2/signup`, {
      method: 'post',
      headers: this.createHeaders({ 'Content-type': 'application/json' }),
      body: JSON.stringify({
        dob,
        verificationToken,
        type: 'webapp',
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(({ user, token, settings, roles }) => {
        this.setPrincipal({ user, token, settings, roles });
        return { user, settings, roles };
      });
  }

  getTokenStatus(token) {
    return fetch(`${config.API_GW}/v2/token/status?${Client.awsQueryString({ token })}`, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(response => response)
      .catch(e => e.json.type);
  }

  updatePassword(username, newPassword, recoveryToken) {
    return fetch(`${config.API_GW}/users/self/password`, {
      method: 'put',
      headers: this.createHeaders({ 'Content-type': 'application/json' }),
      body: JSON.stringify({
        username,
        newPassword,
        recoveryToken,
        type: 'webapp',
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(({ user, token, settings, roles }) => {
        this.setPrincipal({ user, token, settings, roles });
        return { user, settings, roles };
      });
  }

  recover(email) {
    const params = {
      email,
      type: 'user',
      source: 'web',
    };
    const url = `${config.API_GW}/recover`;
    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders({ 'Content-type': 'application/json' }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getLanguages() {
    const url = `${config.API_GW}/agency/languages`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(json => json);
  }

  getDisciplines() {
    const url = `${config.API_GW}/agency/disciplines`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(json => json);
  }

  getAttributes() {
    const url = `${config.API_GW}/agency/attributes`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(json => json);
  }

  getAgencies() {
    const url = `${config.API_GW}/users/self/loginagencies`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(json => json);
  }

  updateAttributes(attributes) {
    const url = `${config.API_GW}/agency/attributes`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(attributes),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  logout() {
    const headers = this.createHeaders();
    this.removePrincipal();

    return fetch(`${config.API_GW}/logout`, {
      method: 'post',
      headers,
    })
      .then(res => this.checkStatus(res))
      .then(() => true);
  }

  switchAgency(attributes) {
    const url = `${config.API_GW}/agency/switch`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify(attributes),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(async ({ user, token, roles }) => {
        await this.setPrincipal({ user, token, roles });
        return { user, roles };
      });
  }

  getFeed() {
    const url = `${config.API_GW}/users/self/feed`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(json => json.feed);
  }

  getDashboard() {
    const url = `${config.API_GW}/dashboard`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(json => json);
  }

  listCaregivers(params) {
    const url = `${config.API_GW}/caregivers`;
    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  listTraveltimes(params) {
    const url = `${config.API_GW}/caregivers/traveltimes`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify(params),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getCaregiver(caregiverId) {
    const url = `${config.API_GW}/caregivers/${caregiverId}`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getCaregiverEvents(caregiverId, params) {
    const url = `${config.API_GW}/caregivers/${caregiverId}/events`;
    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  inviteCaregiver(
    caregiverId,
    shiftIds,
    status,
    invitationNotes,
    sendSignupRequest,
    logMeta = undefined
  ) {
    const url = `${config.API_GW}/caregivers/${caregiverId}/invite`;
    const params = {
      ...(shiftIds ? { shiftIds } : undefined),
      ...(status ? { status } : undefined),
      ...(invitationNotes ? { invitationNotes } : undefined),
      ...(sendSignupRequest ? { sendSignupRequest } : undefined),
      ...(logMeta ? { logMeta } : undefined),
    };

    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(params),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  inviteMultipleCaregivers(
    caregiverIds,
    shiftIds,
    status,
    invitationNotes,
    sendSignupRequest,
    logMeta = undefined
  ) {
    const url = `${config.API_GW}/caregivers/invite/multiple`;
    const params = {
      ...(caregiverIds ? { caregiverIds } : undefined),
      ...(shiftIds ? { shiftIds } : undefined),
      ...(status ? { status } : undefined),
      ...(invitationNotes ? { invitationNotes } : undefined),
      ...(sendSignupRequest ? { sendSignupRequest } : undefined),
      ...(logMeta ? { logMeta } : undefined),
    };

    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(params),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  updateCaregiver(caregiver) {
    const url = `${config.API_GW}/caregivers/${caregiver.id}`;

    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(caregiver),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  createCaregiver(caregiver) {
    const url = `${config.API_GW}/caregivers`;

    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify(caregiver),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  updateCaregiverCasePreferences(userId, casePreferences) {
    const url = `${config.API_GW}/users/${userId}/preferences`;

    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(casePreferences),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  listSchedules(params) {
    const url = `${config.API_GW}/shifts`;
    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getShift(shiftId) {
    const url = `${config.API_GW}/shifts/${shiftId}`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getShiftsByCaregiver(caregiverId, yearMonth) {
    const url = `${config.API_GW}/caregivers/${caregiverId}/shifts`;
    const params = { yearMonth };

    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  createShifts(shifts) {
    const url = `${config.API_GW}/shifts`;

    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify({ shifts }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  updateShift(shift) {
    const url = `${config.API_GW}/shifts/${shift.id}`;

    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(shift),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  listClients(params) {
    const url = `${config.API_GW}/clients`;
    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getClient(clientId) {
    const url = `${config.API_GW}/clients/${clientId}`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  createClient(client) {
    const url = `${config.API_GW}/clients`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify(client),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  updateClient(client) {
    const url = `${config.API_GW}/clients/${client.id}`;

    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(client),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  removeClient(clientId) {
    const url = `${config.API_GW}/clients/${clientId}`;

    return (
      fetch(url, {
        method: 'delete',
        headers: this.createHeaders(),
        // body: JSON.stringify(client),
      })
        .then(res => this.checkStatus(res))
        // .then(Client.parseJson);
        .then(res => Client.parseJson(res))
    );
  }

  getIOTKeys() {
    const url = `${config.API_GW}/conversation/keys`;

    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res))
      .then(json => json.connectDetails);
  }

  getConversation(channelId) {
    const url = `${config.API_GW}/conversation/${channelId}`;

    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  getConversations(params) {
    let url = `${config.API_GW}/conversations`;

    if (params.caregiverId) {
      url = `${config.API_GW}/caregivers/${params.caregiverId}/conversations`;
    }

    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  getActiveConversations(params) {
    const url = `${config.API_GW}/conversation`;
    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  createConversations(caregiverId, shiftId) {
    const url = `${config.API_GW}/conversation`;

    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify({ caregiverId, shiftId }),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  getEmployess() {
    const url = `${config.API_GW}/employees`;

    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  getEmployeeDashboard() {
    const url = `${config.API_GW}/dashboard`;
    const params = { type: 'employee' };
    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  createEmployee(employee) {
    const url = `${config.API_GW}/employees`;

    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify(employee),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  updateEmployee(employeeId, employee) {
    const url = `${config.API_GW}/employees/${employeeId}`;

    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(employee),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  inviteEmployee(employeeId, roles) {
    const url = `${config.API_GW}/employees/${employeeId}/invite`;

    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify({ roles }),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  uploadAvatar(employeeId, filename) {
    const url = `${config.API_GW}/users/${employeeId}/avatar`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify({ filename }),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  removeAvatar(employeeId) {
    const url = `${config.API_GW}/users/${employeeId}/avatar`;
    return fetch(url, {
      method: 'delete',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(() => true);
  }

  getEmployee(employeeId) {
    const url = `${config.API_GW}/employees/${employeeId}`;

    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  getImports() {
    const url = `${config.API_GW}/imports`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  getImportLines(importId) {
    const url = `${config.API_GW}/imports`;

    return fetch(`${url}?${Client.awsQueryString({ importId })}`, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  updateUserStatus(userId, status) {
    const url = `${config.API_GW}/users/${userId}/status`;

    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify({ status }),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  getCaregiverOnlineStatus(caregiverIds, timestamp) {
    const url = `${config.API_GW}/caregivers/online`;

    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify({ caregiverIds, timestamp }),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  getCaregiverComplianceDoh(caregiverId) {
    const url = `${config.API_GW}/compliance/${caregiverId}/doh`;

    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  getCaregiverComplianceExclusions(caregiverId) {
    const url = `${config.API_GW}/compliance/${caregiverId}/exclusions`;

    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  getCaregiverComplianceBlocks(caregiverId) {
    const url = `${config.API_GW}/compliance/${caregiverId}/blocks`;

    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }

    if (response.status === 401) this.removePrincipal();

    const parsingJson = response.json ? response.json() : Promise.resolve().then(() => null);

    return parsingJson
      .catch(() => null)
      .then(json => {
        const error = new Error(`HTTP Error ${response.statusText}`);
        error.status = response.statusText;
        error.statusCode = response.status;
        error.response = response;
        error.json = json;
        throw error;
      });
  }

  createProfileForAgency(profile) {
    const url = `${config.API_GW}/agency/profiles`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(profile),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  createClientForAgency(client) {
    const url = `${config.API_GW}/agency/clients`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(client),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  createShiftForAgency(shift) {
    const url = `${config.API_GW}/agency/shifts`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(shift),
    })
      .then(res => this.checkStatus(res))
      .then(res => Client.parseJson(res));
  }

  getEvents(params) {
    const url = `${config.API_GW}/events`;
    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getShiftEvents(params) {
    const url = `${config.API_GW}/events/shift`;
    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getShiftEventsForCsv(params) {
    const url = `${config.API_GW}/events/shift/csv`;
    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getEmployeeEvents(params) {
    const url = `${config.API_GW}/events/employee`;
    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  createEvent(params) {
    const url = `${config.API_GW}/events`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify(params),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  async getAgencySettings(setPrincipal) {
    const url = `${config.API_GW}/agency/settings`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(async res => {
        if (setPrincipal) {
          await this.setPrincipal({ ...this.principal, settings: res.settings });
        }
        return res;
      });
  }

  async updateAgencySettings(fields) {
    const url = `${config.API_GW}/agency/settings`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(fields),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  listServices(params) {
    const url = `${config.API_GW}/agency/services`;
    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  geocodeAddress(params) {
    const url = `${config.API_GW}/geocode`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify(params),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getRecommendations(clientId) {
    const url = `${config.API_GW}/recommend`;
    const params = { clientId };

    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getRecommendationsForShifts(shiftIds, includeTravelCost, caregiverFilter, caregiverIds) {
    const url = `${config.API_GW}/recommend`;
    let params = { shiftIds, includeTravelCost, caregiverIds };

    if (caregiverFilter) {
      let primaryContact;
      const { selectedPrimaryContacts } = caregiverFilter;
      if (
        selectedPrimaryContacts &&
        selectedPrimaryContacts.length &&
        !selectedPrimaryContacts.includes(0)
      ) {
        primaryContact = selectedPrimaryContacts;
      }
      params = {
        status: caregiverFilter.statusFilter,
        preference: caregiverFilter.preferenceFilter,
        profileFields: caregiverFilter.profileFieldsFilter,
        languages: caregiverFilter.languages,
        primaryContact,
        travelTime: caregiverFilter.travelTime,
        staffStatus: caregiverFilter.staffStatusFilter,
        gender: caregiverFilter.genderFilter,
        ...params,
      };
    }

    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  updateComplianceExclusions(caregiverId, updateSam) {
    const url = `${config.API_GW}/scraping/compliance/${caregiverId}/exclusions`;

    const params = { updateSam };
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify(params),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  updateComplianceDohInfos(caregiverId, registryNumber) {
    const url = `${config.API_GW}/scraping/compliance/${caregiverId}/doh`;

    const params = { registryNumber };
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify(params),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  clearComplianceDohInfos(caregiverId) {
    const url = `${config.API_GW}/compliance/${caregiverId}/doh`;

    return fetch(url, {
      method: 'delete',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getDohCandidates(caregiverId, registryNumber) {
    const url = `${config.API_GW}/scraping/compliance/${caregiverId}/dohcandidates/${registryNumber}`;

    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  setCaregiverComplianceBlockFields(caregiverId, fields) {
    const url = `${config.API_GW}/compliance/${caregiverId}/blocks`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(fields),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  setCaregiverComplianceByProfile(caregiverId, params) {
    const url = `${config.API_GW}/compliance/${caregiverId}/blocks/profilecontrol`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify(params),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getComplianceBlocks() {
    const url = `${config.API_GW}/compliance/block`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  updateComplianceBlocks(blocks, forceUpdate = false) {
    let url = `${config.API_GW}/compliance/block`;
    if (forceUpdate === true) url += '?forceUpdate=true';
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(blocks),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  downloadComplianceDocuments(caregiverId, documentsQuery) {
    const url = `${config.API_GW}/compliance/${caregiverId}/document?filenames=${documentsQuery}`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  uploadComplianceDocument(fieldId, filename, caregiverId, tag) {
    const url = `${config.API_GW}/compliance/${caregiverId}/document/${fieldId}`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify({ filename, tag }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  removeComplianceDocument(caregiverId, fieldId, filename) {
    const url = `${config.API_GW}/compliance/${caregiverId}/document/${fieldId}/${filename}`;
    return fetch(url, {
      method: 'delete',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  createComplianceClassroom(classroom) {
    const url = `${config.API_GW}/compliance/classroom`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify(classroom),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  updateComplianceClassrooms(classrooms) {
    const url = `${config.API_GW}/compliance/classroom`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(classrooms),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getComplianceClassrooms() {
    const url = `${config.API_GW}/compliance/classroom`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getComplianceTrainingCourses() {
    const url = `${config.API_GW}/compliance/block/schedulable`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getComplianceTrainingEvents(onlyCalendarEvents = false) {
    let url = `${config.API_GW}/compliance/training/event`;
    if (onlyCalendarEvents) url += '?onlyCalendarEvents=true';

    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getCaregiverInServiceSummary(caregiverId) {
    const url = `${config.API_GW}/compliance/${caregiverId}/inservice/summary`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  createCaregiverInServiceTraining(caregiverId, training) {
    const url = `${config.API_GW}/compliance/${caregiverId}/training/event`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify(training),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  addWorkhistoryToCaregiver(caregiverId, job) {
    const url = `${config.API_GW}/compliance/${caregiverId}/workhistory`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify(job),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  deleteWorkhistoryToCaregiver(caregiverId, job) {
    const url = `${config.API_GW}/compliance/${caregiverId}/workhistory/${job.id}`;
    return fetch(url, {
      method: 'delete',
      headers: this.createHeaders(),
      body: JSON.stringify(job),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  updateComplianceExclusionDetails(caregiverId, exclusion) {
    const url = `${config.API_GW}/compliance/${caregiverId}/exclusions`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(exclusion),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  createInServiceTrainingEvent(event) {
    const url = `${config.API_GW}/compliance/training/event`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify(event),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getTrainingEventAttendees(eventId, search, nextPage) {
    const url = `${config.API_GW}/compliance/training/event/attendees?trainingEventId=${eventId}${
      search ? `&search=${search}` : ''
    }
    ${nextPage ? `&nextPage=${nextPage}` : ''}`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  updateTrainingEventAttendeesCompletionValues(trainingEventId, attendees) {
    const url = `${config.API_GW}/compliance/training/event/attendees/completion`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify({ trainingEventId, attendees }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  submitTrainingEvent(event) {
    const url = `${config.API_GW}/compliance/training/event/submit`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify({ event }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  removeCaregiverTrainingEvent(caregiverId, eventId) {
    const url = `${config.API_GW}/compliance/${caregiverId}/training/event/${eventId}`;
    return fetch(url, {
      method: 'delete',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getComplianceInstructors() {
    const url = `${config.API_GW}/compliance/instructors`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  createComplianceInstructor(instructor) {
    const url = `${config.API_GW}/compliance/instructors`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify(instructor),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  createComplianceTrainingEvent(event) {
    const url = `${config.API_GW}/compliance/training/event`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify(event),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  removeComplianceTrainingEvent(event) {
    const url = `${config.API_GW}/compliance/training/event/${event.id}`;
    return fetch(url, {
      method: 'delete',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  updateComplianceTrainingEvent(event) {
    const url = `${config.API_GW}/compliance/training/event`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(event),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getTrainingCandidates(params) {
    const url = `${config.API_GW}/compliance/training/candidates`;
    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getCaregiversCompliance(params) {
    const url = `${config.API_GW}/compliance/training/caregivers`;
    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getComplianceHRDashboard() {
    const url = `${config.API_GW}/compliance/hr/dashboard`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  completeCaregiverPrehire(caregiverIds) {
    const url = `${config.API_GW}/compliance/training/completeprehire`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify({ caregiverIds }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getAnnouncementAudience(params) {
    const url = `${config.API_GW}/announcement/audience`;
    return fetch(params ? `${url}?${Client.awsQueryString(params)}` : url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  sendAnnouncement(params) {
    const url = `${config.API_GW}/announcement`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify(params),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getCoursesList(language = 'eng') {
    const url = `${config.API_GW}/users/self/elearning/courses?preferredLanguage=${language}`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getCoursesDetail(id, language = 'eng') {
    const url = `${config.API_GW}/users/self/elearning/courses/${id}?preferredLanguage=${language}`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  startCourse(param) {
    const url = `${config.API_GW}/users/self/elearning/courses/${param.id}`;
    const languageID = param.courseLanguageId;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify({ courseLanguageId: languageID }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  startModule(params) {
    const url = `${config.API_GW}/users/self/elearning/courses/${params.courseId}/modules/${params.moduleId}/launch`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify({
        returnUrl: `${config.APP_URL}${params.returnUrl}?courseID=${params.courseId}&moduleID=${params.moduleId}`,
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  exitModule(params) {
    const url = `${config.API_GW}/users/self/elearning/courses/${params.courseId}/modules/${params.moduleId}/exit`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  createQuizAttempt(params) {
    const url = `${config.API_GW}/users/self/elearning/courses/${params.courseId}/modules/${params.moduleId}/quiz`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getQuizAttempt(params) {
    const url = `${config.API_GW}/users/self/elearning/courses/${params.courseId}/modules/${params.moduleId}/quiz/${params.quizId}`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  updateQuizAttemptAnswers(params) {
    const url = `${config.API_GW}/users/self/elearning/courses/${params.courseId}/modules/${params.moduleId}/quiz/${params.quizId}`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(params.answers),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  reviewQuiz(params) {
    const url = `${config.API_GW}/users/self/elearning/courses/${params.courseId}/modules/${params.moduleId}/quiz/${params.quizId}/review`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getCourseCertification(params) {
    const url = `${config.API_GW}/users/self/elearning/courses/${params.courseId}/certificate?agencyUuid=${params.agencyUuid}&asLink=true`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  deleteCourse(courseId) {
    const url = `${config.API_GW}/users/self/elearning/courses/${courseId}`;
    return fetch(url, {
      method: 'delete',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getUserProfile() {
    const url = `${config.API_GW}/users/self/profile`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getUserSummary() {
    const url = `${config.API_GW}/users/self/summary`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getUserCoursesList(id, language = 'eng') {
    const url = `${config.API_GW}/users/${id}/elearning/courses?preferredLanguage=${language}&includeExempted=true&getExpired=true`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getUserCoursesDetail(id, language = 'eng') {
    const url = `${config.API_GW}/users/${id}/elearning/courses/${id}?preferredLanguage=${language}`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getElearningUtilization(params) {
    const url = `${config.API_GW}/elearning/reports/utilization?${Client.awsQueryString(params)}`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getCertificatesExport(params) {
    const url = `${config.API_GW}/elearning/reports/certificates?${Client.awsQueryString(params)}`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getSignaturesExport(params) {
    const url = `${config.API_GW}/elearning/reports/signatures?${Client.awsQueryString(params)}`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getQuizAnswers(params) {
    const url = `${config.API_GW}/elearning/reports/quizes?${Client.awsQueryString(params)}`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getElearningActiveUsers(params) {
    const url = `${config.API_GW}/elearning/reports/activeusers?${Client.awsQueryString(params)}`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getElearningProfilesList(params) {
    let url = `${config.API_GW}/elearning/profiles`;
    if (params) {
      url = `${config.API_GW}/elearning/profiles?${Client.awsQueryString(params)}`;
    }
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getCaregiverSurvey(id) {
    const url = `${config.API_GW}/caregivers/${id}/surveys`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getClientSurvey(id) {
    const url = `${config.API_GW}/clients/${id}/surveys`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getElearningProfiles(params) {
    const url = `${config.API_GW}/elearning/profiles?${Client.awsQueryString(params)}`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getElearningTraineePrograms(traineeId) {
    const url = `${config.API_GW}/elearning/profiles/${traineeId}/programs`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getSurveySummery(type, date) {
    const url = `${config.API_GW}/${type}/surveysummary?batch=${date}`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getSurveyDetails(date, type) {
    const url = `${config.API_GW}/${type}/surveydetails?batch=${date}`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getElearningTraineeCurriculums(traineeId) {
    const url = `${config.API_GW}/elearning/profiles/${traineeId}/curriculums`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getAgencyPrograms(params) {
    let url = `${config.API_GW}/elearning/programs`;
    if (params) {
      url = `${config.API_GW}/elearning/programs?${Client.awsQueryString(params)}`;
    }
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getCurriculumWithCourses(params) {
    let url = `${config.API_GW}/elearning/curriculums`;
    if (params) {
      url = `${config.API_GW}/elearning/curriculums?${Client.awsQueryString(params)}`;
    }
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  changeAccessToProgram(params) {
    const url = `${config.API_GW}/elearning/programs/accesslist`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify({
        programId: params.programId,
        hasAccess: params.hasAccess,
        profileIds: params.profileIds,
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  deleteUserFromProgram(params) {
    const url = `${config.API_GW}/elearning/programs/accesslist`;
    return fetch(url, {
      method: 'delete',
      headers: this.createHeaders(),
      body: JSON.stringify({
        programId: params.programId,
        profileIds: params.profileIds,
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  resetUserCourseAsAdmin(params) {
    const { profileId, userCourseId } = params;
    const url = `${config.API_GW}/elearning/profiles/${profileId}/courses/${userCourseId}`;
    return fetch(url, {
      method: 'delete',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  changeAccessToCurriculum(params) {
    const url = `${config.API_GW}/elearning/curriculums/accesslist`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify({
        curriculumId: params.curriculumId,
        hasAccess: params.hasAccess,
        profileIds: params.profileIds,
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  deleteUserFromCurriculum(params) {
    const url = `${config.API_GW}/elearning/curriculums/accesslist`;
    return fetch(url, {
      method: 'delete',
      headers: this.createHeaders(),
      body: JSON.stringify({
        curriculumId: params.curriculumId,
        profileIds: params.profileIds,
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  handleCourseExemption(params) {
    const url = `${config.API_GW}/elearning/curriculums/${params.curriculumId}/exemption`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify({
        userCourseId: params.userCourseId,
        exempt: params.exempt,
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  registerCaregiverOnline() {
    return fetch(`${config.API_GW}/users/self/online`, {
      method: 'put',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(response => response);
  }

  getExportFile(params) {
    const { profileType, userUuid, statuses, modifiedAfter, modifiedBefore } = params;
    let exportUrl;
    switch (params.profileType) {
      case 'caregiver':
      case 'employee':
      case 'client':
        exportUrl = 'profiles';
        break;
      case 'shift':
        exportUrl = 'shifts';
        break;
      default:
        break;
    }
    const url = `${config.API_GW}/agency/export/${exportUrl}`;
    return fetch(url, {
      method: 'POST',
      headers: this.createHeaders(),
      body: JSON.stringify({
        profileType,
        userUuid,
        filters: {
          statuses,
          modifiedAfter,
          modifiedBefore,
        },
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  uploadESignature() {
    const url = `${config.API_GW}/users/self/signature`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  deleteESignature(params) {
    const url = `${config.API_GW}/users/self/signature`;
    return fetch(url, {
      method: 'delete',
      headers: this.createHeaders(),
      body: JSON.stringify({
        signatureId: params.signatureId,
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getESignature() {
    return fetch(`${config.API_GW}/users/self/signature`, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getSigningTemplate(params) {
    const { courseId, agencyUuid } = params;
    return fetch(
      `${config.API_GW}/users/self/elearning/courses/${courseId}/signtemplate?agencyUuid=${agencyUuid}`,
      {
        method: 'get',
        headers: this.createHeaders(),
      }
    )
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getApplicantsList(startKey) {
    return fetch(`${config.API_APPLICANTS_GW}/profiles${startKey ? `?startKey=${startKey}` : ''}`, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  postSignedTemplate(params) {
    const { courseId } = params;
    const url = `${config.API_GW}/users/self/elearning/courses/${courseId}/sign`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify({
        agencyUuid: params.agencyUuid,
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  applicantToProceed(params) {
    const url = `${config.API_APPLICANTS_GW}/profiles/agencystatus`;
    const tokens = [params.token];
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify({
        tokens,
        status: 'TO_PROCEED',
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getAgencyFileUploadUrl(filename, contentType, publicAccess) {
    const url = `${config.API_GW}/agency/fileupload`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify({
        filename,
        contentType,
        public: publicAccess,
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  applicantDecline(params) {
    const url = `${config.API_APPLICANTS_GW}/profiles/agencystatus`;
    const tokens = [params.token];
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify({
        tokens,
        status: 'DECLINED',
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  updateApplicationToolConfig(agencyUuid, agencyConfig) {
    const url = `${config.API_APPLICANTS_GW}/agency/${agencyUuid}`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify(agencyConfig),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  applicantReset(params) {
    const url = `${config.API_APPLICANTS_GW}/profiles/agencystatus`;
    const tokens = [...params.tokens];
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify({
        tokens,
        status: 'NEW',
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  applicantApprove(params) {
    const url = `${config.API_APPLICANTS_GW}/profiles/agencystatus`;
    const tokens = [...params.tokens];
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify({
        tokens,
        status: 'APPROVED',
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  applicantDelete(params) {
    const url = `${config.API_APPLICANTS_GW}/profiles/agencystatus`;
    const tokens = [...params.tokens];
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify({
        tokens,
        status: 'DELETED',
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getApplicationToolConfig(agencyUuid) {
    const url = `${config.API_APPLICANTS_GW}/agency/${agencyUuid}`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getApplicantIdImage(token) {
    const url = `${config.API_APPLICANTS_GW}/profile/${token}/image`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  putApplicantNotes(params) {
    const url = `${config.API_APPLICANTS_GW}/profile/${params.token}/notes`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify({
        notes: params.notes,
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  initiateVirtualEvent(virtualEventId) {
    const url = `${config.API_GW}/compliance/training/event/start`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify({
        virtualTrainingEventId: virtualEventId,
      }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  getEmbeddedReportHtmlDoc(solution) {
    return fetch(`${config.API_GW}/agency/reports/embed?solution=${solution}`, {
      method: 'post',
      headers: this.createHeaders({ 'Content-type': 'application/json' }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson)
      .then(response => response);
  }

  async getElearningSettings() {
    const url = `${config.API_GW}/elearning/settings`;
    return fetch(url, {
      method: 'get',
      headers: this.createHeaders(),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  async updateElearningSettings(policyText) {
    const url = `${config.API_GW}/elearning/settings`;
    return fetch(url, {
      method: 'put',
      headers: this.createHeaders(),
      body: JSON.stringify({ clientSettings: { policyText } }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  async createElearningSettings(policyText) {
    const url = `${config.API_GW}/elearning/settings`;
    return fetch(url, {
      method: 'post',
      headers: this.createHeaders(),
      body: JSON.stringify({ clientSettings: { policyText } }),
    })
      .then(res => this.checkStatus(res))
      .then(Client.parseJson);
  }

  static parseJson(response) {
    return response.json();
  }
}

// eslint-disable-next-line import/prefer-default-export
export const backend = new Client();

export enum InServiceTrainingStatus {
  /**
   * Caregiver has completed all required In-Service trainings
   */
  COMPLETED = 'completed',
  /**
   * Expiration date will be reached soon, and remaining In-Service trainings should be completed
   */
  PENDING_EXPIRATION = 'pending_expiration',
  /**
   * Caregiver is out of compliance due to missing In-Service trainings
   */
  OUT_OF_COMPLIANCE = 'out_of_compliance',
  /**
   * Caregiver is ready to work, but has not completed all required trainings
   */
  READY_TO_WORK = 'ready_to_work',
  /**
   * Caregiver is not required to complete In-Service trainings
   */
  NOT_REQUIRED = 'not_required',
}

import React, { ReactElement } from 'react';
import { Image } from 'semantic-ui-react';
import { avatarStyle, avatarUrl } from '../../../constants/DomainTypes';
import { IApplicantProfile } from '../../../reducers/types/applicants';
import { LinkButton } from '../../../styles/common';

interface Props {
  applicant: IApplicantProfile;
  handleNameClick: (applicant: IApplicantProfile) => void;
}

function HRApplicantNameCell({ applicant, handleNameClick }: Props): ReactElement {
  const applicantName = `${applicant.basicInformation.firstName} ${applicant.basicInformation.lastName}`;
  const { gender } = applicant.additionalInformation;
  const imageStyle = { width: '28px', height: '28px', margin: '4px 0 0 0' };
  const handleClick = () => {
    handleNameClick(applicant);
  };
  return (
    <div style={{ maxHeight: '34px' }}>
      <Image
        id='hr-caregiver-name-cell-avatar'
        avatar
        centered
        circular
        size='mini'
        floated='left'
        style={{ ...avatarStyle(undefined, gender, false, false), ...imageStyle }}
        src={avatarUrl(undefined, gender)}
      />
      <LinkButton
        style={{ fontSize: '12px', padding: '0 18px', fontWeight: 'bold' }}
        onClick={handleClick}
      >
        {applicantName}
      </LinkButton>
    </div>
  );
}

export default HRApplicantNameCell;

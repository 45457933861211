/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { history } from '../../../helpers';
import { SimpleButton } from '../../../styles/common';
import { ELCoursesActions } from '../../../actions/ELApp/ElCoursesActions';
import '../../ELCourses.css';

class CompletedCourses extends React.Component {
  // eslint-disable-next-line react/sort-comp
  constructor(props) {
    super(props);
    this.state = { certificationCourseId: null, certificateDownloaded: false };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.eLCourses?.courseCertificate && !prevState.certificateDownloaded) {
      const lnk = document.createElement('a');
      const url = nextProps.eLCourses.courseCertificate;

      lnk.download = 'course certification';
      lnk.href = url;
      lnk.target = '_blank';
      lnk.dispatchEvent(new MouseEvent('click'));
      nextProps.dispatch(ELCoursesActions.cleanCourseCertificate());
      return { certificateDownloaded: true };
    }
    if (prevState.certificateDownloaded && !nextProps.eLCourses?.certificationOn) {
      return { certificateDownloaded: false };
    }
    return null;
  }

  handleViewCourseInfo = id => history.push(`/home/e-learning/courses/course-details/${id}`);

  displayCertificationsAgency = (courseId, agencies) => {
    if (agencies.length === 1) {
      return this.handleDownloadCertificate(agencies[0].uuid, courseId);
    }
    if (this.state.certificationCourseId === courseId) {
      return this.setState({ certificationCourseId: null });
    }
    return this.setState({ certificationCourseId: courseId });
  };

  handleDownloadCertificate = (agencyUuid, courseId) => {
    const params = {
      courseId,
      agencyUuid,
    };
    this.props.dispatch(ELCoursesActions.getCourseCertificate(params));
    this.setState({ certificationCourseId: null });
  };

  render() {
    const { certificationCourseId } = this.state;
    const { courses } = this.props;

    return (
      <div>
        {courses?.length ? (
          <div className='completed-courses-container'>
            <div className='courses-list-grid'>
              {!!courses.length &&
                courses.map(c => (
                  <div className='course-List-record-container'>
                    <h6 className='course-record-name'>{c.courseName}</h6>
                    <div />
                    <ReactMarkdown className='course-record-description'>
                      {c.courseDescription || ''}
                    </ReactMarkdown>
                    <section className='course-record-buttons-section'>
                      <SimpleButton
                        className='course-record-view-button'
                        onClick={() => this.handleViewCourseInfo(c.id)}
                      >
                        View Course Info
                      </SimpleButton>
                      <div className='course-record-view-certificate'>
                        <div
                          role='button'
                          tabIndex='0'
                          loading={this.props.buttonLoading}
                          disabled={this.props.buttonLoading}
                          className={`course-record-view-certificate-dropdown ${
                            certificationCourseId === c.id
                              ? 'certificate-dropdown-open'
                              : 'certificate-dropdown-closed'
                          }`}
                          onClick={() => this.displayCertificationsAgency(c.id, c.agencies)}
                        >
                          <p className='course-record-view-certificate-dropdown-text'>
                            View Certificate
                          </p>
                        </div>
                        <div
                          className={`view-certificate-agencies-dropdown ${
                            certificationCourseId === c.id ? '' : 'agencies-dropdown-closed'
                          } `}
                        >
                          <p className='agencies-dropdown-text'>Select an Agency</p>
                          {c.agencies.map(a => (
                            <div
                              role='button'
                              tabIndex='0'
                              loading={this.props.buttonLoading}
                              disabled={this.props.buttonLoading}
                              className='course-record-view-certificate-button'
                              onClick={() => this.handleDownloadCertificate(a.uuid, c.id)}
                            >
                              {a.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    </section>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <div className='empty-course-view-container'>
            <img
              className='empty-course-view-image'
              src='/complete-courses-empty.svg'
              alt='empty view'
            />
            <p className='empty-course-view-main-text'>You have yet to completed any courses.</p>
            <p className='empty-course-view-sub-text'>
              We will contact you when you are enrolled in your next course.
            </p>
          </div>
        )}
      </div>
    );
  }
}

CompletedCourses.propTypes = {
  dispatch: PropTypes.func.isRequired,
  courses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  buttonLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const { eLCourses } = state;
  return {
    eLCourses,
  };
};

export default connect(mapStateToProps)(CompletedCourses);

/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import { formatTime, SHORT_DATE, MOMENT_HOURS_24 } from '../../constants/Formats';
import { sortTableData } from '../../helpers/common';
import TableWithExport from './TableWithExport';
import LoadMore from './LoadMore';
import NoMatch from './NoMatch';
import InitialState from './InitialState';

class ChangeLogEmployeeTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      column: 'timestamp',
      data: props.data,
      direction: 'descending',
    };

    this.handleSort = this.handleSort.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !!nextState.update || this.props.loading !== nextProps.loading;
  }

  onExportClick() {
    return this.onExportClick ? this.onExportClick() : undefined;
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;

    if (!data) return;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: sortTableData(data, clickedColumn),
        direction: 'ascending',
        update: true,
      });

      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
      update: true,
    });
  };

  timestampColumn = (timestamp, boldTime) =>
    timestamp ? (
      <span className='infoText'>
        <span className={boldTime ? 'infoTextBold' : ''}>
          {formatTime(timestamp, null, SHORT_DATE)}
        </span>{' '}
        at {formatTime(timestamp, null, MOMENT_HOURS_24)}
      </span>
    ) : (
      undefined
    );

  userColumn = user => <p className='infoTextBold'>{user}</p>;

  actionColumn = action => {
    let title = '';
    switch (action) {
      case 'EMPLOYEE_LOG_IN':
        title = 'Log In';
        break;
      case 'EMPLOYEE_LOG_OUT':
        title = 'Log Out';
        break;
      default:
        title = 'Log Out';
    }
    return <p>{title}</p>;
  };

  tableHeader = (column, direction, onHeaderClick) => {
    const headerArray = [
      {
        id: 'change-log-employee-table-header-userName',
        key: 'userName',
        content: 'Employee',
        filter: true,
      },
      {
        id: 'change-log-employee-table-header-action',
        key: 'action',
        content: 'Action',
        filter: true,
      },
      {
        id: 'change-log-employee-table-header-timestamp',
        key: 'timestamp',
        content: 'Date',
        filter: true,
      },
    ];

    headerArray
      .filter(f => f.filter)
      .forEach(h => {
        // eslint-disable-next-line no-param-reassign
        h.className =
          column === h.key || (Array.isArray(column) && h.key === 'timestamp')
            ? `${direction} sorted`
            : undefined;

        // eslint-disable-next-line no-param-reassign
        h.onClick = onHeaderClick(h.key);

        // eslint-disable-next-line no-param-reassign
        h.filter = undefined;
      });

    return headerArray;
  };

  tableBody = (x, i) => ({
    key: `${x.userId}-${i}}`,
    className: 'infoText',
    cells: [
      { key: 'userName', content: this.userColumn(x.userName) },
      { key: 'action', content: this.actionColumn(x.action) },
      { key: 'timestamp', content: this.timestampColumn(x.timestamp) },
    ],
  });

  render() {
    const { column, data, direction } = this.state;

    return (
      <div>
        <TableWithExport
          className='changeLogResults'
          sortable
          singleLine
          striped
          fixed
          headerRow={this.tableHeader(column, direction, this.handleSort)}
          renderBodyRow={this.tableBody}
          tableData={this.props.loading && !this.props.partialUpdate ? [] : data}
          getOnClick={clickFunc => {
            this.onExportClick = clickFunc;
            return null;
          }}
        />
        <div className='loadMore'>
          <LoadMore
            onLoadMore={() => this.props.onLoadMore(this.props.nextPage)}
            loading={this.props.loading}
            partialUpdate={this.props.partialUpdate}
            nextPage={this.props.nextPage}
          />
        </div>
        {!this.props.loading && data && data.length === 0 && (
          <Grid className='noResults'>
            <NoMatch id='employeeResultsNoMatch' />
          </Grid>
        )}
        {!data ||
          (this.props.loading && !this.props.partialUpdate && (
            <InitialState searchingFor='employee activity' loading={this.props.loading} />
          ))}
      </div>
    );
  }
}

ChangeLogEmployeeTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  partialUpdate: PropTypes.bool.isRequired,
  nextPage: PropTypes.string.isRequired,
  onLoadMore: PropTypes.func.isRequired,
};

export default ChangeLogEmployeeTable;

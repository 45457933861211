import moment from 'moment';
// import { IEvents } from '../components/subcomponents/compliance/AnnualTraining/trainingTypes';
import { Blocks } from '../components/subcomponents/compliance/ComplianceMedicalTypes';
import { ICaregiver, IPendingAndExpiredBlocks } from '../reducers/types/caregiver';
import { IHRCandidatesFilter, IHRCaregiversFilter } from '../reducers/types/HRFilters';

interface ICSVData {
  firstName: string;
  lastName: string;
  id: number;
  discipline: string;
  staffStatus: string;
  compliance: string;
  complianceStatus: string;
  expirationDate: string;
  phoneNumber: string;
  primaryContactName: string;
  primaryContactManagerName: string;
}

interface IVaccineCSVData {
  firstName: string;
  lastName: string;
  id: number;
  discipline: string;
  staffStatus: string;
  vaccination: string; // vaccine type.
  validationStatus: string;
  validationRound: string | number;
  requiredRounds: string | number;
  phoneNumber: string;
  primaryContactName: string;
  primaryContactManagerName: string;
}

enum ClassRoomType {
  DEFAULT = 'default',
  VIRTUAL = 'virtual',
}

const CSV_HEADERS = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'ID', key: 'id' },
  { label: 'Discipline', key: 'discipline' },
  { label: 'Staff Status', key: 'staffStatus' },
  { label: 'Compliance', key: 'compliance' },
  { label: 'Compliance Status', key: 'complianceStatus' },
  { label: 'Expiration Date', key: 'expirationDate' },
  { label: 'Phone Number', key: 'phoneNumber' },
  { label: 'Primary Contact', key: 'primaryContactName' },
  { label: 'Primary Contact Manager', key: 'primaryContactManagerName' },
];

const CSV_VACCINATION_HEADERS = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'ID', key: 'id' },
  { label: 'Discipline', key: 'discipline' },
  { label: 'Staff Status', key: 'staffStatus' },
  { label: 'Vaccination', key: 'vaccination' },
  { label: 'Validation Status', key: 'validationStatus' },
  { label: 'Validation Round', key: 'validationRound' },
  { label: 'Required Round', key: 'requiredRounds' },
  { label: 'Phone Number', key: 'phoneNumber' },
  { label: 'Primary Contact', key: 'primaryContactName' },
  { label: 'Primary Contact Manager', key: 'primaryContactManagerName' },
];

const generateStatus = (status: string) => {
  let statusText = '';
  switch (status) {
    case '01':
      statusText = 'Pending';
      break;
    case '02':
      statusText = 'Active';
      break;
    case '03':
      statusText = 'On Hold';
      break;
    default:
      break;
  }
  return statusText;
};

const createNewRow = (
  caregiver: ICaregiver,
  block: Blocks,
  complianceStatus: string,
  expirationDate: string
): ICSVData => {
  return {
    firstName: caregiver.firstName,
    lastName: caregiver.lastName,
    id: caregiver.id,
    discipline: caregiver.discipline,
    staffStatus: generateStatus(caregiver.staffStatus),
    compliance: block.name,
    complianceStatus,
    expirationDate,
    phoneNumber: caregiver.phoneNumber || '--',
    primaryContactName: caregiver.primaryContactName,
    primaryContactManagerName: caregiver.primaryContactManagerName,
  };
};

const createNewVaccineRow = (caregiver: ICaregiver, vaccine: string): IVaccineCSVData => {
  const vaccination = caregiver.vaccinations.find(v => v.type === vaccine);
  return {
    firstName: caregiver.firstName,
    lastName: caregiver.lastName,
    id: caregiver.id,
    discipline: caregiver.discipline,
    staffStatus: generateStatus(caregiver.staffStatus),
    vaccination: vaccination?.type || '--',
    validationStatus: vaccination?.validationStatus || '--',
    validationRound: vaccination?.validationRound || '--',
    requiredRounds: vaccination?.requiredRounds || '--',
    phoneNumber: caregiver.phoneNumber || '--',
    primaryContactName: caregiver.primaryContactName,
    primaryContactManagerName: caregiver.primaryContactManagerName,
  };
};

const getRowsByType = (
  caregiver: ICaregiver,
  filters: IHRCaregiversFilter | IHRCandidatesFilter,
  activeBlocks: Blocks[]
) => {
  const {
    compliancesIssues: {
      eligibility,
      medical,
      documentation,
      training,
      incomplete = false,
      pending = false,
      expired = false,
    },
  } = filters;
  const hasComplianceType: string[] = [];
  if (eligibility?.length && eligibility[0] === 0) {
    hasComplianceType.push('eligibility');
  }
  if (medical?.length && medical[0] === 0) {
    hasComplianceType.push('medical');
  }
  if (documentation?.length && documentation[0] === 0) {
    hasComplianceType.push('documentation');
  }
  if (training?.length && training[0] === 0) {
    hasComplianceType.push('training');
  }
  const typeRows = activeBlocks.reduce((acc: ICSVData[], block: Blocks): ICSVData[] => {
    const newRows = acc;
    if (!hasComplianceType.includes(block.type)) {
      return newRows;
    }
    if (hasComplianceType.includes(block.type)) {
      if (incomplete || (!incomplete && !pending && !expired)) {
        if (caregiver.nonCompliantBlocks?.includes(block.id)) {
          const newRow = createNewRow(caregiver, block, 'Incomplete', '--');
          newRows.push(newRow);
        }
      }
      if (pending || (!incomplete && !pending && !expired)) {
        const pendingBlock = caregiver.aboutToExpireBlocks?.find(a => a.id === block.id);
        if (pendingBlock) {
          const newRow = createNewRow(
            caregiver,
            block,
            'Pending',
            pendingBlock?.expirationDate || '--'
          );
          newRows.push(newRow);
        }
      }
      if (expired || (!incomplete && !pending && !expired)) {
        const expiredBlock = caregiver.expiredBlocks?.find(e => e.id === block.id);
        if (expiredBlock) {
          const newRow = createNewRow(
            caregiver,
            block,
            'Expired',
            expiredBlock?.expirationDate || '--'
          );
          newRows.push(newRow);
        }
      }
    }

    return newRows;
  }, []);
  return typeRows;
};

const getRowById = (
  caregiver: ICaregiver,
  compliancesIds: number[],
  activeBlocks: Blocks[],
  incomplete = false,
  pending = false,
  expired = false
) => {
  const itemRows = compliancesIds.reduce((ir: ICSVData[], i): ICSVData[] => {
    const block = activeBlocks.find(b => b.id === i);
    if (!block) {
      return ir;
    }
    const newComplianceRows = ir;
    if (incomplete || (!incomplete && !pending && !expired)) {
      if (caregiver.nonCompliantBlocks?.includes(i)) {
        const newRow = createNewRow(caregiver, block, 'Incomplete', '--');
        newComplianceRows.push(newRow);
      }
    }
    if (pending || (!incomplete && !pending && !expired)) {
      const pendingBlock = caregiver.aboutToExpireBlocks?.find(a => a.id === i);
      if (pendingBlock) {
        const newRow = createNewRow(
          caregiver,
          block,
          'Pending',
          pendingBlock?.expirationDate || '--'
        );
        newComplianceRows.push(newRow);
      }
    }
    if (expired || (!incomplete && !pending && !expired)) {
      const expiredBlock = caregiver.expiredBlocks?.find(e => e.id === i);
      if (expiredBlock) {
        const newRow = createNewRow(
          caregiver,
          block,
          'Expired',
          expiredBlock?.expirationDate || '--'
        );
        newComplianceRows.push(newRow);
      }
    }
    return newComplianceRows;
  }, []);
  return itemRows;
};

const getVaccineRowById = (c: ICaregiver, vaccine?: string[]) => {
  if (!vaccine) {
    return [];
  }
  const vaccineRows = vaccine.reduce((vRows: IVaccineCSVData[], v): IVaccineCSVData[] => {
    const newVaccineRows = vRows;
    if (!c.vaccinations.find(cv => cv.type === v)) {
      return vRows;
    }
    const newVaccineRow = createNewVaccineRow(c, v);
    newVaccineRows.push(newVaccineRow);
    return newVaccineRows;
  }, []);
  return vaccineRows;
};

const generateCaregiverData = (
  caregivers: ICaregiver[],
  activeBlocks: Blocks[],
  filters: IHRCaregiversFilter | IHRCandidatesFilter
) => {
  const {
    compliancesIssues: {
      eligibility,
      medical,
      documentation,
      training,
      incomplete = false,
      pending = false,
      expired = false,
    },
  } = filters;
  const compliancesIds = [...eligibility, ...medical, ...documentation, ...training];
  if (caregivers?.length) {
    const caregiverRows = caregivers.reduce((r: ICSVData[], c): ICSVData[] => {
      const complianceTypeRows = getRowsByType(c, filters, activeBlocks);
      const complianceRows = getRowById(
        c,
        compliancesIds,
        activeBlocks,
        incomplete,
        pending,
        expired
      );
      const newRows = [...r, ...complianceRows, ...complianceTypeRows];
      return newRows;
    }, []);
    return caregiverRows;
  }
  return [];
};

const generateVaccineCaregiverData = (caregivers: ICaregiver[], filters: IHRCaregiversFilter) => {
  const { vaccinationValidation: { vaccine } = {} } = filters;
  if (caregivers?.length) {
    const caregiverRows = caregivers.reduce((r: IVaccineCSVData[], c): IVaccineCSVData[] => {
      const vaccineRows = getVaccineRowById(c, vaccine);
      const newRows = [...r, ...vaccineRows];
      return newRows;
    }, []);
    return caregiverRows;
  }
  return [];
};

const checkForRequiredExpiredOrPending = (
  compareBlocks: Blocks[],
  blockList?: IPendingAndExpiredBlocks[]
) => {
  if (!blockList?.length) {
    return false;
  }
  return !!compareBlocks.find(bk =>
    blockList.find(b => b.id === bk.id && bk.required && !bk.schedulable && bk.active)
  );
};

const getAnnualTrainingEvents = (events: any[], inServiceTrainingBlock: Blocks) => {
  return events.filter(
    training =>
      training.eligibleHours &&
      moment(training.date).isSameOrAfter(
        inServiceTrainingBlock.hire_date &&
          moment()
            .subtract(1, 'years')
            .isBefore(moment(inServiceTrainingBlock.hire_date))
          ? moment(inServiceTrainingBlock.expirationDate).subtract(1, 'years')
          : moment(inServiceTrainingBlock.expirationDate).subtract(2, 'years')
      )
  );
};

const getFieldFromBlockByType = (block: Blocks, fieldType: string) => {
  if (!block || !block.fields.length) {
    return null;
  }
  return block.fields.find(f => f.type === fieldType) || null;
};

const hrHelper = {
  ClassRoomType,
  CSV_HEADERS,
  CSV_VACCINATION_HEADERS,
  generateCaregiverData,
  generateVaccineCaregiverData,
  checkForRequiredExpiredOrPending,
  getAnnualTrainingEvents,
  getFieldFromBlockByType,
};

export default hrHelper;

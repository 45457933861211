/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import { history } from '../helpers';
import { alertActions } from '../actions';
import PrivateRoute from './PrivateRoute';
import Login from './Login';
import CaregiverSignUp from './CaregiverSignUp';
import Signup from './Signup';
import Home from './Home';
import localeData from '../l18n/strings.json';
import { config } from '../helpers/config';
import MagicLogin from './MagicLogin';
import LoginAskToken from './LoginAskToken';
import { SurveyReport } from './subcomponents/SurveyReport';

addLocaleData([...en]);

const localeStandalone = { ...localeData.en, ...localeData.enStandalone };

class App extends React.Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;

    history.listen(() => {
      // clear alerts on page change
      dispatch(alertActions.clearErrors());
    });
  }

  render() {
    const { settings } = this.props;

    const linkRedirect = ({ match }) => {
      const { linktype, tokentype, token } = match.params;

      const tokenString = tokentype ? `?${tokentype}=${token}` : '';
      // alert(`https://ccapp.test-app.link/${linktype}${tokenString}`)
      window.location = `${config.APPLINK_DOMAIN}/${linktype}${tokenString}`;
      return null;
    };

    return (
      <IntlProvider
        locale='en'
        messages={settings && settings.standaloneMode ? localeStandalone : localeData.en}
      >
        <Router history={history}>
          <Switch>
            <Route path='/:linktype(clk|elk|erd)/:tokentype/:token' component={linkRedirect} />
            <Route path='/:linktype(clk|elk|erd)' component={linkRedirect} />
            <Route path='/signup' component={Signup} />
            <PrivateRoute path='/home' component={Home} dispatch={this.props.dispatch} />
            <PrivateRoute path='/redirect' component={Home} dispatch={this.props.dispatch} />
            <PrivateRoute path='/reports/survey' component={SurveyReport} />
            <Route path='/login' component={Login} />
            <Route path='/elearning' component={LoginAskToken} />
            <Route path='/caregiversignup' component={CaregiverSignUp} />
            <Route exact path='/' render={() => <Redirect to='/home' />} />
            <Route exact path='/loginWithToken' component={MagicLogin} />
          </Switch>
        </Router>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  settings: PropTypes.shape().isRequired,
};

const mapStateToProps = state => {
  const { principal } = state;

  return principal;
};

export default connect(mapStateToProps)(App);

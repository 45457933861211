/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { backend } from '../../services';
import { alertActions } from '../../actions/alert';

const clean = oldObj => {
  const obj = { ...oldObj };
  // eslint-disable-next-line
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
    if (
      propName === 'lat' ||
      propName === 'lon' ||
      propName === 'validLatLon' ||
      propName === 'version' ||
      propName === 'id' ||
      propName === 'formatted' ||
      propName === 'lastModified'
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

const isValidAddress = address =>
  !!(address && address.street && address.city && address.zip && address.state);

class LocationButton extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  render() {
    const onClick = () => {
      const { address, locationUpdate } = this.props;
      this.setState({ loading: true });
      const cleaned = clean(address);
      backend
        .geocodeAddress({ address: cleaned })
        .then(results => {
          this.setState({ loading: false });
          if (results.lat != null && results.lon != null) {
            locationUpdate({ lat: results.lat, lon: results.lon });
          } else {
            this.props.dispatch(alertActions.notification('Error: Invalid address.'));
          }
        })
        .catch(() => {
          this.setState({ loading: false });
          this.props.dispatch(alertActions.notification('Error while geolocating address.'));
        });
    };
    const { content, className } = this.props;
    return (
      <Button
        id='location-button'
        onClick={onClick}
        loading={this.state.loading}
        disabled={!isValidAddress(this.props.address)}
        className={className}
      >
        {content}
      </Button>
    );
  }
}

LocationButton.defaultProps = {
  content: 'Locate',
  className: '',
};

LocationButton.propTypes = {
  address: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  content: PropTypes.string,
  locationUpdate: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default LocationButton;

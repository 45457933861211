import React from 'react';
import PropTypes from 'prop-types';
import { Header, Button, Divider } from 'semantic-ui-react';
import styled from 'styled-components';

const TabHeader = styled(Header)`
  &&& {
    margin-left: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 31px;
    float: left;
    color: #364967;
    font-size: 16px;
  }
`;

const SaveButton = styled(Button)`
  && {
    float: right;
    font-size: 12px;
    font-weight: normal;
    border: solid 1px #d9d9d9;
    background-color: #04a5d5;
    color: #01475c;
    margin-right: 3vw !important;
  }
  &&&&:hover {
    background-color: #1293b9;
    color: #073f50;
  }
  &&:disabled {
    background: #f7f7f7;
    color: #9b9b9b;
  }
`;

const HeaderDivider = styled(Divider)`
  &&&& {
    border-top: 1px solid #d8d8d8;
    margin-bottom: 2rem;
    clear: both;
    position: relative;
    top: 4px;
    margin-right: 3vw !important;
  }
`;

const SubTabHeader = ({ text, buttonOnClick, buttonTitle, buttonDisabled }) => (
  <div>
    <div>
      <TabHeader id='sub-tab-header-header'>{text}</TabHeader>
      {buttonTitle && (
        <SaveButton
          id='sub-tab-header-save-button'
          size='small'
          onClick={buttonOnClick}
          disabled={buttonDisabled}
        >
          {buttonTitle}
        </SaveButton>
      )}
    </div>
    <HeaderDivider />
  </div>
);

SubTabHeader.defaultProps = {
  buttonTitle: null,
  buttonOnClick: () => {},
  buttonDisabled: false,
};

SubTabHeader.propTypes = {
  text: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string,
  buttonOnClick: PropTypes.func,
  buttonDisabled: PropTypes.bool,
};

export default SubTabHeader;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { Component } from 'react';
import { Button, Image, Loader, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import * as CompHelper from './complianceHelpers';
import * as compTypes from './ComplianceMedicalTypes';
import 'react-datepicker/dist/react-datepicker.css';
import { caregiverActions } from '../../../actions';
import CGComplianceUIActions from '../../../actions/CGComplianceUIActions';
import { dateFormat } from '../../../helpers/common';

type Props = {
  blocks: Array<Block>;
  caregiverComplianceUI: any;
  caregiverCompliance: any;
  dispatch: (arg: any) => void;
  caregiver: any;
};

type State = {
  closeEnabled: boolean;
  closePpd2Enabled: boolean;
  showPpdQftEditPanel: boolean;
  showQuestionEditPanel: boolean;
  showXRayEditPanel: boolean;
  showPpd2EditPanel: boolean;
  filesChanged: boolean;
  showPPD1EditOptions: boolean;
  draftState: DraftState;
};

type DraftState = {
  ppdDate?: compTypes.DraftStateField;
  ppdResult?: compTypes.DraftStateField;
  ppd2Date?: compTypes.DraftStateField;
  ppd2Result?: compTypes.DraftStateField;
  qftDate?: compTypes.DraftStateField;
  qftResult?: compTypes.DraftStateField;
  xRayDate?: compTypes.DraftStateField;
  xRayResult?: compTypes.DraftStateField;
  questionDate?: compTypes.DraftStateField;
  docType?: compTypes.DraftStateField;
};

interface Block extends compTypes.Blocks {
  fields: Array<BlockField>;
  name: 'PPD' | 'XRAY' | 'PPD2' | 'QFT' | 'Screening Questionnaire';
  expirationDate?: string;
  ppd_date?: string;
  hasExpired: boolean;
}

interface BlockField extends compTypes.BlockField {
  filesDownloaded?: boolean;
  downloadLinks: FormLinks;
}

type FormLinks = {
  [key: string]: string;
};

type PropsFields = {
  [key: string]: BlockField;
};

const setCompletions = 'SET REQUIRED COMPLETION DETAILS';
const DropdownOptions = [
  { text: '', value: '' },
  { text: 'Negative', value: '-' },
  { text: 'Positive', value: '+' },
];

const EditRemoveContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-column: 2 / 3;
  & > span {
    margin: 0 0.5rem;
  }
`;

class ComplianceTBTest extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showPpdQftEditPanel: false,
      showQuestionEditPanel: false,
      showXRayEditPanel: false,
      showPpd2EditPanel: false,
      showPPD1EditOptions: false,
      closeEnabled: false,
      closePpd2Enabled: false,
      filesChanged: false,
      draftState: {
        ppdDate: undefined,
        ppdResult: undefined,
        ppd2Date: undefined,
        ppd2Result: undefined,
        qftDate: undefined,
        qftResult: undefined,
        xRayDate: undefined,
        xRayResult: undefined,
        questionDate: undefined,
        docType: undefined,
      },
    };
  }

  public componentDidMount = () => {
    this.setFieldsToState();
  };

  private readonly getPropFields = (): PropsFields => {
    const { blocks } = this.props;

    const [ppd] = blocks.filter((b: Block) => b.name === 'PPD');
    const [ppd2] = blocks.filter((b: Block) => b.name === 'PPD2');
    const [qft] = blocks.filter((b: Block) => b.name === 'QFT');
    const [xRay] = blocks.filter((b: Block) => b.name === 'XRAY');
    const [question] = blocks.filter((b: Block) => b.name === 'Screening Questionnaire');

    const [ppdDate] = ppd.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Completion'
    );
    const [ppdResult] = ppd.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === 'Result'
    );
    const [ppd2Date] = ppd2.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Completion'
    );
    const [ppd2Result] = ppd2.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === 'Result'
    );
    const [qftDate] = qft.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Completion'
    );
    const [qftResult] = qft.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === 'Result'
    );
    const [xRayDate] = xRay.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Completion'
    );
    const [xRayResult] = xRay.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === 'Result'
    );

    const [questionDate] = question.fields.filter((f: BlockField) => f.type === 'date');

    const [docType] = question.fields.filter((f: BlockField) => f.name === 'documentType');

    return {
      ppdDate,
      ppdResult,
      ppd2Date,
      ppd2Result,
      qftDate,
      qftResult,
      xRayDate,
      xRayResult,
      questionDate,
      docType,
    };
  };

  private readonly setFieldsToState = (): void => {
    const propFields = this.getPropFields();

    this.setState(s => ({
      ...s,
      draftState: {
        ...s.draftState,
        ppdDate: {
          value: propFields.ppdDate.value ? moment(propFields.ppdDate.value) : '',
          id: propFields.ppdDate.id,
        },
        ppdResult: {
          value: propFields.ppdResult.value || '',
          id: propFields.ppdResult.id,
        },
        ppd2Date: {
          value: propFields.ppd2Date.value ? moment(propFields.ppd2Date.value) : '',
          id: propFields.ppd2Date.id,
        },
        ppd2Result: {
          value: propFields.ppd2Result.value || '',
          id: propFields.ppd2Result.id,
        },
        qftDate: {
          value: propFields.qftDate.value ? moment(propFields.qftDate.value) : '',
          id: propFields.qftDate.id,
        },
        qftResult: {
          value: propFields.qftResult.value || '',
          id: propFields.qftResult.id,
        },
        xRayDate: {
          value: propFields.xRayDate.value ? moment(propFields.xRayDate.value) : '',
          id: propFields.xRayDate.id,
        },
        xRayResult: {
          value: propFields.xRayResult.value || '',
          id: propFields.xRayResult.id,
        },
        questionDate: {
          value: propFields.questionDate.value ? moment(propFields.questionDate.value) : '',
          id: propFields.questionDate.id,
        },
        docType: propFields.docType
          ? {
              value: propFields.docType.value || null,
              id: propFields.docType.id,
            }
          : undefined,
      },
    }));
  };

  private readonly addPpdQftCompletion = (): void => {
    this.setState(s => ({
      ...s,
      showPpdQftEditPanel: !s.showPpdQftEditPanel,
      closeEnabled: true,
    }));
  };

  private readonly addXRayCompletion = (): void => {
    this.setState(s => ({
      ...s,
      showXRayEditPanel: !s.showXRayEditPanel,
      closeEnabled: true,
    }));
  };

  private readonly openQuestionPanel = (): void => {
    this.setState(s => ({
      ...s,
      showQuestionEditPanel: !s.showQuestionEditPanel,
      closeEnabled: true,
    }));
  };

  private readonly addPpd2XRayCompletion = (): void => {
    this.setState(s => ({
      ...s,
      showXRayEditPanel: !s.showXRayEditPanel,
      closePpd2Enabled: true,
    }));
  };

  private readonly addPpd2Completion = (): void => {
    this.setState(s => ({
      ...s,
      showPpd2EditPanel: !s.showPpd2EditPanel,
      closePpd2Enabled: true,
    }));
  };

  private readonly onClose = (): void => {
    const { dispatch } = this.props;
    this.setFieldsToState();
    this.setState(
      s => ({
        ...s,
        closeEnabled: false,
        showPpdQftEditPanel: false,
        showXRayEditPanel: false,
        showQuestionEditPanel: false,
        filesChanged: false,
        showPpd2EditPanel: false,
      }),
      () => dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: [] }))
    );
  };

  private readonly onClosePpd2 = (): void => {
    const { dispatch } = this.props;
    this.setFieldsToState();
    this.setState(
      s => ({
        ...s,
        closePpd2Enabled: false,
        showXRayEditPanel: false,
        filesChanged: false,
        showPpd2EditPanel: false,
      }),
      () => dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: [] }))
    );
  };

  private readonly verifyChanges = (): void => {
    const { draftState } = this.state;

    const propsFields = this.getPropFields();

    const changes = [];

    /* eslint-disable-next-line no-restricted-syntax */
    for (const [key, value] of Object.entries(draftState)) {
      const propValue =
        key.includes('Date') && value?.value
          ? moment(value.value || undefined).format(dateFormat)
          : value?.value;

      const stateValue =
        key.includes('Date') && propsFields[key]?.value
          ? moment(propsFields[key].value || undefined).format(dateFormat)
          : '';

      if (propValue !== stateValue) {
        changes.push(key);
      }
    }

    if (changes.length) {
      return this.setState(s => ({ ...s, filesChanged: true }));
    }
    return this.setState(s => ({ ...s, filesChanged: false }));
  };

  private readonly onFieldChanged = (field: compTypes.BlockField, value: any) => {
    const { caregiverComplianceUI, dispatch } = this.props;
    const { dirtyMedicalBlocks } = caregiverComplianceUI;

    const changedValue = value;

    let theField = dirtyMedicalBlocks.find((f: compTypes.BlockField) => f.id === field.id);

    if (!theField) {
      theField = JSON.parse(JSON.stringify(field));
      dirtyMedicalBlocks.push(theField);
    }
    theField.value = changedValue;

    if (field.type === 'date') {
      this.onDateChange(value, field.id);
    }

    if (field.type === 'text') {
      this.onTextChange(value, field.id);
    }

    dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks }));
  };

  private readonly onTextChange = (text: any, selected: number): void => {
    const { draftState } = this.state;
    let valueToSet: string;
    /* eslint-disable-next-line no-restricted-syntax */
    for (const [key, value] of Object.entries(draftState)) {
      if (value?.id === selected) {
        valueToSet = key;
      }
    }

    this.setState(
      s => ({
        ...s,
        draftState: { ...s.draftState, [valueToSet]: { value: text, id: selected } },
      }),
      () => this.verifyChanges()
    );
  };

  private readonly onDateChange = (date: any, selected: number): void => {
    const { draftState } = this.state;
    let valueToSet: string;
    /* eslint-disable-next-line no-restricted-syntax */
    for (const [key, value] of Object.entries(draftState)) {
      if (value?.id === selected) {
        valueToSet = key;
      }
    }

    this.setState(
      s => ({
        ...s,
        draftState: {
          ...s.draftState,
          [valueToSet]: {
            value: date && moment(date).isBefore(moment()) ? moment(date) : '',
            id: selected,
          },
        },
      }),
      () => this.verifyChanges()
    );
  };

  private readonly uploadDocument = (e: any, fieldId: number) => {
    const { caregiver, dispatch } = this.props;
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      dispatch(caregiverActions.uploadComplianceDocument(fieldId, file, caregiver.id));
    }
    e.target.value = '';
  };

  private readonly documentRemoved = (fieldId: number, filename: string) => {
    const { caregiver, dispatch } = this.props;
    dispatch(caregiverActions.removeComplianceDocument(caregiver.id, fieldId, filename));
  };

  private readonly updateMedicalBlock = () => {
    const { caregiver, blocks, caregiverComplianceUI, dispatch } = this.props;
    const { dirtyMedicalBlocks } = caregiverComplianceUI;

    if (Object.keys(dirtyMedicalBlocks).length) {
      const dirtyFieldsToSave: Array<BlockField> = [];
      dirtyMedicalBlocks.forEach((s: BlockField) => {
        const [block] = blocks.filter((a: Block) =>
          a.fields.find((f: BlockField) => f.id === s.id)
        );
        const b = block?.fields.find((a: BlockField) => a.id === s.id);
        if (b && ((b.value && b.value !== s.value) || (!b.value && s.value))) {
          dirtyFieldsToSave.push(s);
        }
      });

      if (dirtyFieldsToSave.length) {
        dispatch(
          caregiverActions.setCaregiverComplianceBlockFields(caregiver.id, dirtyFieldsToSave)
        );
      }
    }
    this.setState(s => ({
      ...s,
      closePpd2Enabled: false,
      closeEnabled: false,
      showPpdQftEditPanel: false,
      showXRayEditPanel: false,
      showQuestionEditPanel: false,
      filesChanged: false,
      showPpd2EditPanel: false,
      showPPD1EditOptions: false,
    }));
  };

  private readonly toggleEditPpd1 = () => {
    const { showPPD1EditOptions } = this.state;
    this.setState(s => ({
      ...s,
      showPPD1EditOptions: !showPPD1EditOptions,
    }));
  };

  private readonly onRemove = () => {
    const dirtyFieldToClear: Array<BlockField> = [];
    const { caregiver, dispatch, blocks } = this.props;
    blocks.forEach((block: Block) =>
      block.fields.forEach(f => {
        if (f.type === 'document' && f.value?.length) {
          f.value.forEach((v: any) => this.documentRemoved(f.id, v.filename));
        } else {
          dirtyFieldToClear.push({
            id: f.id,
            name: f.name,
            type: f.type,
            fixed: 1,
            value: null,
            isDirty: true,
            downloadLinks: {},
          });
        }
      })
    );
    dispatch(caregiverActions.setCaregiverComplianceBlockFields(caregiver.id, dirtyFieldToClear));

    this.setState(
      s => ({
        ...s,
        draftState: {
          ...s.draftState,
          ppdDate: {
            ...s.draftState.ppdDate,
            value: '',
          },
          ppdResult: {
            ...s.draftState.ppdResult,
            value: '',
          },
          ppd2Date: {
            ...s.draftState.ppd2Date,
            value: '',
          },
          ppd2Result: {
            ...s.draftState.ppd2Result,
            value: '',
          },
          qftDate: {
            ...s.draftState.qftDate,
            value: '',
          },
          qftResult: {
            ...s.draftState.qftResult,
            value: '',
          },
          xRayDate: {
            ...s.draftState.xRayDate,
            value: '',
          },
          xRayResult: {
            ...s.draftState.xRayResult,
            value: '',
          },
          questionDate: {
            ...s.draftState.questionDate,
            value: '',
          },
          docType: s.draftState.docType
            ? {
                ...s.draftState.docType,
                value: null,
              }
            : undefined,
        },
        showResultEditPanel: false,
        filesChanged: false,
        closeEnabled: false,
      }),
      () => dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: [] }))
    );
  };

  private readonly onBlockRemove = (blockName: string) => {
    const dirtyFieldToClear: Array<BlockField> = [];
    const { caregiver, dispatch, blocks } = this.props;
    blocks
      .find((block: Block) => block.name === blockName)
      ?.fields.forEach(f => {
        dirtyFieldToClear.push({
          id: f.id,
          name: f.name,
          type: f.type,
          fixed: 1,
          value: null,
          isDirty: true,
          downloadLinks: {},
        });
      });

    dispatch(caregiverActions.setCaregiverComplianceBlockFields(caregiver.id, dirtyFieldToClear));

    this.setState(
      s => ({
        ...s,
        draftState: {
          ...s.draftState,
          ppdDate: {
            ...s.draftState.ppdDate,
            value: '',
          },
          ppdResult: {
            ...s.draftState.ppdResult,
            value: '',
          },
          ppd2Date: {
            ...s.draftState.ppd2Date,
            value: '',
          },
          ppd2Result: {
            ...s.draftState.ppd2Result,
            value: '',
          },
          qftDate: {
            ...s.draftState.qftDate,
            value: '',
          },
          qftResult: {
            ...s.draftState.qftResult,
            value: '',
          },
          xRayDate: {
            ...s.draftState.xRayDate,
            value: '',
          },
          xRayResult: {
            ...s.draftState.xRayResult,
            value: '',
          },
          questionDate: {
            ...s.draftState.questionDate,
            value: '',
          },
          docType: s.draftState.docType
            ? {
                ...s.draftState.docType,
                value: null,
              }
            : undefined,
        },
        showResultEditPanel: false,
        filesChanged: false,
        closeEnabled: false,
      }),
      () => dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: [] }))
    );
  };

  private readonly renderDocumentField = (documentField: BlockField) => {
    const { caregiverCompliance } = this.props;
    const { uploadingDocument } = caregiverCompliance;
    let addNewClass = 'complianceDocumentItem';
    if (uploadingDocument) addNewClass += ' disabled';

    const previewDocument = (link: string, filename: string) => {
      if (filename.endsWith('.pdf')) {
        return (
          <a href={link}>
            <div style={{ textAlign: 'center' }}>.pdf</div>
          </a>
        );
      }
      return <Image width='100px' height='100px' src={link} />;
    };
    return (
      <div className='complianceDocumentsContainer' key={documentField.id}>
        {!documentField.filesDownloaded && documentField.value && !!documentField.value.length && (
          <div className='complianceDocumentItem'>
            <div style={{ height: '100px' }}>
              <Loader active inline />
            </div>
          </div>
        )}
        {documentField.filesDownloaded &&
        documentField.downloadLinks &&
        !!documentField.value?.length ? (
          documentField.value.map((file: any) => {
            return (
              <div className='complianceDocumentItem' key={file}>
                {previewDocument(documentField.downloadLinks[file.filename], file.filename)}
                <Button
                  circular
                  icon='delete'
                  className='documentRemoveButton'
                  onClick={() => this.documentRemoved(documentField.id, file.filename)}
                />
              </div>
            );
          })
        ) : (
          <div className={addNewClass}>
            {uploadingDocument ? (
              <>
                {uploadingDocument === documentField.id ? (
                  <div style={{ height: '100px' }}>
                    <Loader active inline />
                  </div>
                ) : (
                  <p style={{ color: '#ccc' }}>+</p>
                )}
              </>
            ) : (
              <>
                <input
                  type='file'
                  accept='.jpg,.png,.jpeg,.gif,.pdf'
                  id={`fileinput${documentField.id}`}
                  className='fileinput'
                  style={{ display: 'none' }}
                  onChange={e => this.uploadDocument(e, documentField.id)}
                />
                <label htmlFor={`fileinput${documentField.id}`} style={{ padding: '36px' }}>
                  +
                </label>
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  getExpirationPeriod = (expirationPeriod?: string | null) => {
    switch (expirationPeriod) {
      case 'yearly':
        return 'Annual';
      case 'half_yearly':
        return '6 months.';
      case 'monthly':
        return 'Monthly';
      case 'two_years':
        return 'Two Years.';
      case 'three_years':
        return 'Three Years.';
      case 'four_years':
        return 'Four Years.';
      case 'five_years':
        return 'Five Years.';
      case 'six_years':
        return 'Six Years.';
      case 'ten_years':
        return 'Ten Years.';
      default:
        return 'One Time';
    }
  };

  getLatestUpdated = (blocks: Block[], latestCompletedDate: string) => {
    const filterBlocks = blocks.filter(b => b.name !== 'PPD2');
    const [lastFieldModify] = filterBlocks.filter(b =>
      b.fields.find(
        (f: BlockField) =>
          f.type === 'date' && f.value && moment(f.value).format(dateFormat) === latestCompletedDate
      )
    );
    return lastFieldModify;
  };

  public render(): JSX.Element {
    const { blocks } = this.props;
    const {
      showPpdQftEditPanel,
      showXRayEditPanel,
      showQuestionEditPanel,
      showPpd2EditPanel,
      closeEnabled,
      closePpd2Enabled,
      draftState,
      filesChanged,
      showPPD1EditOptions,
    } = this.state;

    const {
      ppdDate,
      ppdResult,
      ppd2Date,
      ppd2Result,
      qftDate,
      qftResult,
      xRayDate,
      xRayResult,
      questionDate,
      docType,
    } = draftState;

    const { caregiverComplianceUI } = this.props;
    const { dirtyMedicalBlocks } = caregiverComplianceUI;

    const { caregiver } = this.props;

    const [ppd] = blocks.filter((b: Block) => b.name === 'PPD');
    const [ppd2] = blocks.filter((b: Block) => b.name === 'PPD2');
    const [qft] = blocks.filter((b: Block) => b.name === 'QFT');
    const [xRay] = blocks.filter((b: Block) => b.name === 'XRAY');
    const [question] = blocks.filter((b: Block) => b.name === 'Screening Questionnaire');

    const [ppdDateField] = ppd.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Completion'
    );
    const [ppdResultField] = ppd.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === 'Result'
    );
    const [ppd2DateField] = ppd2.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Completion'
    );
    const [ppd2ResultField] = ppd2.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === 'Result'
    );
    const [qftDateField] = qft.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Completion'
    );
    const [qftResultField] = qft.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === 'Result'
    );
    const [xRayDateField] = xRay.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Completion'
    );
    const [xRayResultField] = xRay.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === 'Result'
    );

    const [questionDateField] = question.fields.filter((f: BlockField) => f.type === 'date');

    const [questionDocumentField] = question.fields.filter(
      (f: BlockField) => f.type === 'document'
    );

    const [docTypeField] = question.fields.filter((f: BlockField) => f.name === 'documentType');

    const questionnaireCompleted =
      !!questionDateField?.value &&
      moment(questionDateField?.value).isAfter(moment().subtract(1, 'year'));

    const preHireStatus = caregiver.staffStatus === '01';
    const ppd2WithLagCase =
      !!ppd2.lagDays &&
      ppd2.hire_date &&
      moment(ppd2.hire_date)
        .add(1, 'years')
        .isAfter(moment());

    const ppd1Fails = ppdResultField?.value === '+';
    const qftFails = qftResultField?.value === '+';
    const ppd2Fails = ppd2ResultField?.value === '+';
    const xRayFails = xRayResultField?.value === '+';

    const ppd1Pass = ppdResultField?.value === '-';
    const qftPass = qftResultField?.value === '-';
    const ppd2Pass = ppd2ResultField?.value === '-';
    const xRayPass = xRayResultField?.value === '-';

    const ppdFiled = ppdDateField?.value;
    const ppd2Filed = ppd2DateField?.value;
    const qftFiled = qftDateField?.value;
    const xRayFiled = xRayDateField?.value;

    const ppd1Completed = ppd.completed && ppdFiled;

    const ppd2Completed = ppd2.completed && ppd2Filed;

    let formLink;

    const dateValues = [
      ppd1Pass ? ppdDateField : null,
      qftPass ? qftDateField : null,
      xRayPass ? xRayDateField : null,
      questionDateField,
    ]
      ?.filter(f => f?.value)
      ?.map(f => moment(f?.value));

    const latestCompletedDate = moment.max(dateValues).format(dateFormat);

    const latestBlockUpdated = this.getLatestUpdated(blocks, latestCompletedDate);

    if (questionnaireCompleted) {
      if (Object.getOwnPropertyNames(questionDocumentField.downloadLinks || {}).length) {
        formLink = questionDocumentField?.downloadLinks[questionDocumentField?.value[0]?.filename];
      }
    }

    const expirationDate = latestBlockUpdated?.expirationDate;

    const showTestResultInfoPanel = ppdFiled || qftFiled;

    const showTest2ResultInfoPanel = ppd2Filed;

    const testFails = ppd1Fails || qftFails;

    const test2Fails = ppd2Fails;

    const showXRay2InfoPane = showTest2ResultInfoPanel && xRayFiled && !showXRayEditPanel;

    const showXRayInfoPane = showTestResultInfoPanel && xRayFiled && !showXRayEditPanel;

    const showRemovePpd1Button = dateValues?.length || xRayFails || showXRayInfoPane;

    const completed =
      (ppdFiled || ppd2Filed || qftFiled || xRayFiled || questionnaireCompleted) &&
      (ppd1Pass || qftPass || ppd2Pass || xRayPass || questionnaireCompleted) &&
      (expirationDate ? moment(expirationDate).isAfter(moment()) : true);

    const showAddTestOrDocumentInfo =
      (!completed && !questionnaireCompleted) || showPPD1EditOptions;
    const showAddXray =
      (testFails && !questionnaireCompleted && !xRayFiled) || (testFails && showPPD1EditOptions);

    const showEditButton = completed || questionnaireCompleted;

    return (
      <div className='infoPanelMedicalBlock'>
        <div className='compliances-tab-header'>
          <img src={`/compliance/${ppd.icon}`} alt='compliance-icon' />
          <p className={completed ? 'blackTitle' : 'redTitle'} style={{ margin: '0' }}>
            TB Test
          </p>
          {!completed ? (
            <img
              style={{ marginLeft: '10px' }}
              src='/compliance/attention.svg'
              alt='attention-icon'
            />
          ) : (
            ''
          )}
        </div>
        <div>
          <p className='compliance-medium-gray'>
            TB testing is an{' '}
            <b className='compliance-charcoal-text-color'>
              {this.getExpirationPeriod(ppd.expirationPeriod)}
            </b>{' '}
            requirement.
            <b style={{ color: '#F16778', fontWeight: 'normal' }}>
              {expirationDate && moment(expirationDate).isBefore(moment())
                ? ` EXPIRED ON ${moment(expirationDate).format(dateFormat)}`
                : ''}
            </b>
            {expirationDate && completed && moment(expirationDate).isAfter(moment())
              ? ` Next expires ${moment(expirationDate).format(dateFormat)}`
              : ''}
          </p>
        </div>
        {questionDateField?.value && (
          <div className='hep-b-concent-declined-info'>
            {!!docTypeField && (
              <p className='compliance-medium-gray' style={{ marginRight: '10px' }}>
                {`Document Type Submitted: ${docTypeField?.value || ''}`}
              </p>
            )}
            <p
              className='compliance-medium-gray'
              style={{ display: 'inline-block', marginRight: '10px' }}
            >
              {`${docTypeField ? '' : 'Screening Questionnaire '}Completed on: ${moment(
                questionDateField?.value
              ).format(dateFormat)}`}
            </p>
            {formLink ? (
              <a className='compliance-form-link' href={formLink} target='_blank' rel='noreferrer'>
                Review
              </a>
            ) : (
              <p
                className='compliance-add-decline-text'
                style={{ display: 'inline-block' }}
                onClick={this.openQuestionPanel}
              >
                {docType ? 'Add Document' : 'Add Questionnaire'}
              </p>
            )}
          </div>
        )}
        {showTestResultInfoPanel && (
          <div className='hep-b-test-series-one-container compliance-info-panel'>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                {`${ppdDateField?.value ? 'PPD' : 'QFT'} Date:`}
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-start', marginRight: '20px' }}
              >
                <CompHelper.DateText date={ppdDateField?.value || qftDateField?.value} />
              </div>
            </div>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                {`${ppdDateField?.value ? 'PPD' : 'QFT'} Result:`}
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-start', marginRight: '20px' }}
              >
                <CompHelper.ResultText
                  value={ppdResultField?.value || qftResultField?.value}
                  group='TB'
                />
              </div>
            </div>
          </div>
        )}
        {testFails && xRayFiled && (
          <div className='series-two-divider compliance-info-panel'>
            <h3 className='compliance-add-completion-panel-header'>X-RAY REQUIRED</h3>
            <p className='compliance-block-completion-field-description'>
              Due to failed test this caregiver must receive a chest x-ray.
              <b style={{ color: '#F16778', fontWeight: 'normal' }}>
                {xRay.expirationDate && moment(xRay.expirationDate).isBefore(moment())
                  ? ` EXPIRED ON ${moment(xRay.expirationDate).format(dateFormat)}`
                  : ''}
              </b>
            </p>
            {showXRayInfoPane && (
              <div className='hep-b-test-series-one-container'>
                <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
                  <p
                    className='compliance-block-completion-field-text'
                    style={{ justifySelf: 'flex-start' }}
                  >
                    X-Ray Date:
                  </p>
                  <div
                    className='medicalDateInput'
                    style={{ justifySelf: 'flex-start', marginRight: '20px' }}
                  >
                    <CompHelper.DateText date={xRayDateField?.value} />
                  </div>
                </div>
                <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
                  <p
                    className='compliance-block-completion-field-text'
                    style={{ justifySelf: 'flex-start' }}
                  >
                    X-Ray Result:
                  </p>
                  <div
                    className='medicalDateInput'
                    style={{ justifySelf: 'flex-start', marginRight: '20px' }}
                  >
                    <CompHelper.ResultText value={xRayResultField?.value} group='TB' />
                    <span
                      className='removeButton'
                      role='presentation'
                      style={{ paddingRight: '10px', width: '40px', paddingLeft: '100px' }}
                      onClick={() => this.onBlockRemove('XRAY')}
                    >
                      remove X-RAY Record
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {!showPpdQftEditPanel && !showQuestionEditPanel && !showXRayEditPanel && (
          <div className='compliance-buttons-container' style={{ paddingBottom: '10px' }}>
            {showAddTestOrDocumentInfo && (
              <CompHelper.SimpleButton onClick={this.addPpdQftCompletion}>
                Add Test Info
              </CompHelper.SimpleButton>
            )}
            {showAddXray && (
              <CompHelper.SimpleButton onClick={this.addXRayCompletion}>
                Add X-ray Info
              </CompHelper.SimpleButton>
            )}
            {showAddTestOrDocumentInfo && (
              <CompHelper.SimpleButton onClick={this.openQuestionPanel}>
                {docType ? 'Add Document' : 'Add Screening Questionnaire'}
              </CompHelper.SimpleButton>
            )}
            {completed && !showPPD1EditOptions && (
              <p className='block-result-text' style={{ color: '#70DC94' }}>
                <Image
                  src='/compliance/check.svg'
                  style={{ paddingRight: '5px', display: 'inline' }}
                />
                COMPLETED: <CompHelper.DateText date={latestCompletedDate} />
              </p>
            )}
            {showRemovePpd1Button && !showPPD1EditOptions && (
              <EditRemoveContainer>
                <CompHelper.RemoveButton onRemove={this.onRemove} />
                {showEditButton && <CompHelper.EditButton onEdit={this.toggleEditPpd1} />}
              </EditRemoveContainer>
            )}
            {showPPD1EditOptions && (
              <EditRemoveContainer>
                <CompHelper.CancelButton onCancel={this.toggleEditPpd1} />
              </EditRemoveContainer>
            )}
          </div>
        )}
        {closeEnabled && (
          <Image className='tinyCloseButton' src='/close-icon.svg' onClick={this.onClose} />
        )}
        {showXRayEditPanel && testFails && !showPpd2EditPanel && (
          <div className='hep-b-test-series-one-container compliance-add-completion-panel'>
            <h3 className='compliance-add-completion-panel-header'>{setCompletions}</h3>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                X-Ray Date:
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-end', marginRight: '20px' }}
              >
                <CompHelper.ComplianceDatePicker
                  field={xRayDateField}
                  state={xRayDate}
                  disabled={false}
                  onChange={this.onFieldChanged}
                />
              </div>
            </div>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                X-Ray Result:
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-end', marginRight: '20px' }}
              >
                <Dropdown
                  placeholder='Please Select'
                  selection
                  className='compliance-dropdown'
                  key={xRayResultField.id}
                  value={xRayResult?.value}
                  options={DropdownOptions}
                  onChange={(_e, input) => {
                    this.onFieldChanged(xRayResultField, input.value);
                  }}
                />
              </div>
            </div>
            <CompHelper.UpdateButton
              disabled={
                !(
                  dirtyMedicalBlocks.length &&
                  filesChanged &&
                  !!xRayResult?.value &&
                  !!xRayDate?.value
                )
              }
              update={this.updateMedicalBlock}
            />
          </div>
        )}
        {showQuestionEditPanel && (
          <div className='compliance-declination-panel compliance-add-completion-panel'>
            <h3 className='compliance-add-completion-panel-header'>
              {docType ? 'ADD DOCUMENT' : 'ADD SCREENING QUESTIONNAIRE'}
            </h3>
            <div className='compliance-hep-date-container'>
              {docType && (
                <>
                  <p className='compliance-block-completion-field-text'>Select document type</p>
                  <div
                    className='medicalDateInput'
                    style={{ justifySelf: 'flex-end', marginRight: '10px' }}
                  >
                    <CompHelper.DropdownFields
                      field={docTypeField}
                      selected={docType}
                      options={CompHelper.TBDocTypes}
                      disabled={false}
                      error={!docType?.value}
                      handleChange={this.onFieldChanged}
                    />
                  </div>
                </>
              )}
              <p className='compliance-block-completion-field-text'>
                Enter date of questionnaire completion
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-end', marginRight: '10px' }}
              >
                <CompHelper.ComplianceDatePicker
                  field={questionDateField}
                  state={questionDate}
                  disabled={false}
                  onChange={this.onFieldChanged}
                />
              </div>
              <div>
                <p className='compliance-block-completion-field-text'>Upload Document</p>
                <p className='compliance-block-completion-field-description'>
                  The submitted questionnaire will be stored on records. Requires annual renewal
                </p>
              </div>
              <div style={{ justifySelf: 'flex-end' }}>
                {this.renderDocumentField(questionDocumentField)}
              </div>
              <CompHelper.UpdateButton
                disabled={
                  !(
                    dirtyMedicalBlocks.length &&
                    filesChanged &&
                    (docType ? docType?.value : true) &&
                    questionDate?.value
                  )
                }
                update={this.updateMedicalBlock}
              />
            </div>
          </div>
        )}
        {showPpdQftEditPanel && (
          <div className='hep-b-test-series-one-container compliance-add-completion-panel'>
            <h3 className='compliance-add-completion-panel-header'>{setCompletions}</h3>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                PPD Date
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-end', marginRight: '20px' }}
              >
                <CompHelper.ComplianceDatePicker
                  field={ppdDateField}
                  state={ppdDate}
                  disabled={!!qftDate?.value && !qft.hasExpired}
                  onChange={this.onFieldChanged}
                />
              </div>
            </div>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                PPD Result
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-end', marginRight: '20px' }}
              >
                <Dropdown
                  placeholder='Please Select'
                  selection
                  className='compliance-dropdown'
                  key={ppdResultField.id}
                  value={ppdResult?.value}
                  disabled={!!qftDate?.value && !qft.hasExpired}
                  options={DropdownOptions}
                  onChange={(_e, input) => {
                    this.onFieldChanged(ppdResultField, input.value);
                  }}
                />
              </div>
            </div>
            <div className='tb-or-separator'>OR</div>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                QFT Date
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-end', marginRight: '20px' }}
              >
                <CompHelper.ComplianceDatePicker
                  field={qftDateField}
                  state={qftDate}
                  disabled={!!ppdDate?.value && !ppd.hasExpired}
                  onChange={this.onFieldChanged}
                />
              </div>
            </div>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                QFT Result
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-end', marginRight: '20px' }}
              >
                <Dropdown
                  placeholder='Please Select'
                  selection
                  className='compliance-dropdown'
                  key={qftResultField.id}
                  value={qftResult?.value}
                  disabled={!!ppdDate?.value && !ppd.hasExpired}
                  options={DropdownOptions}
                  onChange={(_e, input) => {
                    this.onFieldChanged(qftResultField, input.value);
                  }}
                />
              </div>
            </div>
            <CompHelper.UpdateButton
              disabled={
                !(
                  dirtyMedicalBlocks.length &&
                  filesChanged &&
                  ((!!ppdResult?.value && !!ppdDate?.value) ||
                    (!!qftResult?.value && !!qftDate?.value))
                )
              }
              update={this.updateMedicalBlock}
            />
          </div>
        )}
        {(preHireStatus || ppd2WithLagCase) &&
          (!qftFiled || qft.hasExpired) &&
          !ppd1Fails &&
          !questionnaireCompleted && (
            <div className='series-two-divider compliance-info-panel'>
              {ppd1Completed && ppdFiled && (
                <div>
                  <div className='compliances-tab-header'>
                    <img src={`/compliance/${ppd.icon}`} alt='compliance-icon' />
                    <p
                      className={ppd2Completed ? 'blackTitle' : 'redTitle'}
                      style={{ margin: '0' }}
                    >
                      TB - Secondary PPD Series (Pre-hire only)
                    </p>
                    {!ppd2Completed ? (
                      <img
                        style={{ marginLeft: '10px' }}
                        src='/compliance/attention.svg'
                        alt='attention-icon'
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <div>
                    {ppd2.lagDays ? (
                      <p className='compliance-medium-gray'>
                        This is an <b className='compliance-charcoal-text-color'>One-time</b>{' '}
                        requirement for new caregivers required to be completed{' '}
                        <b className='compliance-charcoal-text-color'>{ppd2.lagDays}</b> days from
                        PPD 1 completion date (
                        <b className='compliance-charcoal-text-color'>
                          {moment(ppd2.ppd_date || undefined).format(dateFormat)}
                        </b>
                        ).
                      </p>
                    ) : (
                      <p className='compliance-medium-gray'>
                        This is an <b className='compliance-charcoal-text-color'>One-time</b>{' '}
                        requirement for candidates prior to being approved for hiring in to active
                        caregiver pool
                      </p>
                    )}
                  </div>
                </div>
              )}
              {!completed && (
                <div>
                  <div className='compliances-tab-header'>
                    <img src='/compliance/tbtestGray.svg' alt='compliance-icon' />

                    <p className='compliance-medium-gray' style={{ margin: '0' }}>
                      TB - Secondary PPD Series (Pre-hire only)
                    </p>
                  </div>
                  <div>
                    <p className='compliance-medium-gray'>
                      This panel is inactive. It will only be required if your candidate has
                      completed the PPD Series on in the TB test main panel
                    </p>
                  </div>
                </div>
              )}
              {ppd2Filed && ppdFiled && (
                <div className='hep-b-test-series-one-container compliance-info-panel'>
                  <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
                    <p
                      className='compliance-block-completion-field-text'
                      style={{ justifySelf: 'flex-start' }}
                    >
                      Secondary PPD Date:
                    </p>
                    <div
                      className='medicalDateInput'
                      style={{ justifySelf: 'flex-start', marginRight: '20px' }}
                    >
                      <CompHelper.DateText date={ppd2DateField?.value} />
                    </div>
                  </div>
                  <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
                    <p
                      className='compliance-block-completion-field-text'
                      style={{ justifySelf: 'flex-start' }}
                    >
                      Secondary PPD Result:
                    </p>
                    <div
                      className='medicalDateInput'
                      style={{ justifySelf: 'flex-start', marginRight: '20px' }}
                    >
                      <CompHelper.ResultText value={ppd2ResultField?.value} group='TB' />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

        {ppd2Filed && test2Fails && ppdFiled && !testFails && (
          <div className='series-two-divider compliance-info-panel'>
            <h3 className='compliance-add-completion-panel-header'>X-RAY REQUIRED</h3>
            <p className='compliance-block-completion-field-description'>
              Due to failed test this caregiver must receive a chest x-ray.
            </p>
            {showXRay2InfoPane && (
              <div className='hep-b-test-series-one-container'>
                <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
                  <p
                    className='compliance-block-completion-field-text'
                    style={{ justifySelf: 'flex-start' }}
                  >
                    X-Ray Date:
                  </p>
                  <div
                    className='medicalDateInput'
                    style={{ justifySelf: 'flex-start', marginRight: '20px' }}
                  >
                    <CompHelper.DateText date={xRayDateField?.value} />
                  </div>
                </div>
                <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
                  <p
                    className='compliance-block-completion-field-text'
                    style={{ justifySelf: 'flex-start' }}
                  >
                    X-Ray Result:
                  </p>
                  <div
                    className='medicalDateInput'
                    style={{ justifySelf: 'flex-start', marginRight: '20px' }}
                  >
                    <CompHelper.ResultText value={xRayResultField?.value} group='TB' />
                    <span
                      className='removeButton'
                      role='presentation'
                      style={{ paddingRight: '10px', width: '40px', paddingLeft: '100px' }}
                      onClick={() => this.onBlockRemove('XRAY')}
                    >
                      remove X-RAY Record
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {(preHireStatus || ppd2WithLagCase) &&
          completed &&
          ppdFiled &&
          !ppd1Fails &&
          !ppd.hasExpired &&
          !showPpd2EditPanel && (
            <div className='compliance-buttons-container'>
              {!ppd2Filed && (
                <CompHelper.SimpleButton onClick={this.addPpd2Completion}>
                  Add Secondary PPD Info
                </CompHelper.SimpleButton>
              )}
              {ppd2Fails && !questionnaireCompleted && !xRayFiled && !showXRayEditPanel && (
                <CompHelper.SimpleButton onClick={this.addPpd2XRayCompletion}>
                  Add X-ray Info
                </CompHelper.SimpleButton>
              )}
              {ppd2Completed && ppd2Filed && (
                <p className='block-result-text' style={{ color: '#70DC94', paddingTop: '10px' }}>
                  <Image
                    src='/compliance/check.svg'
                    style={{ paddingRight: '5px', display: 'inline' }}
                  />
                  COMPLETED:{' '}
                  <CompHelper.DateText date={ppd2DateField?.value || ppdDateField?.value} />
                </p>
              )}
              {ppd2Filed && !showXRayEditPanel && (
                <>
                  <span
                    className='removeButton'
                    role='presentation'
                    style={{ paddingRight: '10px' }}
                    onClick={() => this.onBlockRemove('PPD2')}
                  >
                    remove Secondary PPD Record
                  </span>
                  <div>
                    {/* <CompHelper.RemoveButton onRemove={() => this.onBlockRemove('PPD2')} /> */}
                  </div>
                </>
              )}
            </div>
          )}
        {closePpd2Enabled && (
          <Image className='tinyCloseButton' src='/close-icon.svg' onClick={this.onClosePpd2} />
        )}
        {showPpd2EditPanel && (
          <div className='hep-b-test-series-one-container compliance-add-completion-panel'>
            <h3 className='compliance-add-completion-panel-header'>{setCompletions}</h3>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                Secondary PPD Date
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-end', marginRight: '20px' }}
              >
                <CompHelper.ComplianceDatePicker
                  field={ppd2DateField}
                  state={ppd2Date}
                  disabled={false}
                  onChange={this.onFieldChanged}
                />
              </div>
            </div>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                Secondary PPD Result
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-end', marginRight: '20px' }}
              >
                <Dropdown
                  placeholder='Please Select'
                  selection
                  className='compliance-dropdown'
                  key={ppd2ResultField.id}
                  value={ppd2Result?.value}
                  options={DropdownOptions}
                  onChange={(_e, input) => {
                    this.onFieldChanged(ppd2ResultField, input.value);
                  }}
                />
              </div>
            </div>
            <CompHelper.UpdateButton
              disabled={
                !(
                  dirtyMedicalBlocks.length &&
                  filesChanged &&
                  !!ppd2Result?.value &&
                  !!ppd2Date?.value
                )
              }
              update={this.updateMedicalBlock}
            />
          </div>
        )}
        {showXRayEditPanel && ppd2Fails && !qftFiled && ppd1Completed && (
          <div className='hep-b-test-series-one-container compliance-add-completion-panel'>
            <h3 className='compliance-add-completion-panel-header'>{setCompletions}</h3>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                X-Ray Date:
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-end', marginRight: '20px' }}
              >
                <CompHelper.ComplianceDatePicker
                  field={xRayDateField}
                  state={xRayDate}
                  disabled={false}
                  onChange={this.onFieldChanged}
                />
              </div>
            </div>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                X-Ray Result:
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-end', marginRight: '20px' }}
              >
                <Dropdown
                  placeholder='Please Select'
                  selection
                  className='compliance-dropdown'
                  key={xRayResultField.id}
                  value={xRayResult?.value}
                  options={DropdownOptions}
                  onChange={(_e, input) => {
                    this.onFieldChanged(xRayResultField, input.value);
                  }}
                />
              </div>
            </div>
            <CompHelper.UpdateButton
              disabled={
                !(
                  dirtyMedicalBlocks.length &&
                  filesChanged &&
                  !!xRayResult?.value &&
                  !!xRayDate?.value
                )
              }
              update={this.updateMedicalBlock}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { caregiverDetails, caregiverCompliance, caregiverComplianceUI } = state;
  return {
    caregiver: caregiverDetails.caregiver,
    caregiverCompliance,
    caregiverComplianceUI,
  };
};

export default connect(mapStateToProps)(ComplianceTBTest);

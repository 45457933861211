/* eslint-disable import/prefer-default-export */

import moment from 'moment';
import _ from 'underscore';
import * as types from '../constants/ActionTypes';
import { backend } from '../services';
// eslint-disable-next-line import/no-cycle
import { alertActions } from './index';
import { PreferenceCategories, ActiveStaffStatus } from '../constants/DomainTypes';
import hrActionsHelper from './hrapp/actionsHelper';

import DEFAULT_ADVANCED_FILTERS from '../constants/DefaultFilters';
import { analytics, Events } from '../helpers/analytics';

import { store } from '../helpers';

function combineCaregiverParams(
  shiftList,
  caregiverFilter,
  shiftStatusBarFilter,
  searchField,
  staffStatusList,
  shiftIds
) {
  const paramsShift = shiftList
    ? shiftsToCaregiverParams(shiftList, caregiverFilter != null, shiftIds)
    : {};
  let paramsCaregiver = {};
  if (caregiverFilter) {
    let primaryContact;
    const { selectedPrimaryContacts } = caregiverFilter;
    if (
      selectedPrimaryContacts &&
      selectedPrimaryContacts.length &&
      !selectedPrimaryContacts.includes(0)
    ) {
      primaryContact = selectedPrimaryContacts;
    }
    paramsCaregiver = {
      status: caregiverFilter.statusFilter,
      preference: caregiverFilter.preferenceFilter,
      profileFields: caregiverFilter.profileFieldsFilter,
      languages: caregiverFilter.languages,
      primaryContact,
      travelTime: caregiverFilter.travelTime,
      staffStatus: caregiverFilter.staffStatusFilter,
      gender: caregiverFilter.genderFilter,
    };
  }

  let paramsStatusBar = {};
  if (!shiftList && shiftStatusBarFilter && shiftStatusBarFilter.visible) {
    const start = moment(shiftStatusBarFilter.date)
      .second(0)
      .millisecond(0);
    const end = moment(start);
    start.set({
      hours: shiftStatusBarFilter.start.hours(),
      minute: shiftStatusBarFilter.start.minute(),
    });
    end.set({
      hours: shiftStatusBarFilter.end.hours(),
      minute: shiftStatusBarFilter.end.minute(),
    });

    paramsStatusBar = {
      address: shiftStatusBarFilter.address,
      start: start.toISOString(),
      end: end.toISOString(),
      virtualShift: true,
    };
  }

  const searchParams = searchField ? { name: searchField } : {};

  const staffStatus =
    staffStatusList && staffStatusList.length ? { staffStatus: staffStatusList } : {};

  return Object.assign(paramsShift, paramsCaregiver, paramsStatusBar, searchParams, staffStatus);
}

function shiftsToCaregiverParams(shiftList, enableFilters = true, shiftIds) {
  // because only one client per search is allowed....
  const shift = shiftList[0];
  const { attributes } = store.getState().principal;
  const { shiftNonCompliantWarning } = backend.getCachedSettings();
  const params = {
    shiftIdList: shiftIds || shiftList.map(s => s.id),
    hasHistory: true,
    lat: shift.client.address.lat,
    lon: shift.client.address.lon,
    travelTime: 120,
    preferenceList: attributes.preferences,
    compliance: shiftNonCompliantWarning,
  };
  const shiftDrivenPref = name => {
    const selected = attributes.preferences.find(o => o.name === name && o.shiftDriven === 'Y');
    return selected ? selected.caregiverDefaultValue === 'Y' : false;
  };
  const arr = [];

  const liveInsShift = shiftList.find(s => s.liveIns);
  if (liveInsShift && shiftDrivenPref('live-ins')) {
    const liveInsPref = attributes.preferences.find(
      p => p.name === 'live-ins' && p.category === 'availability'
    );
    if (liveInsPref) arr.push(liveInsPref.id);
  }

  const weekendsShift = shiftList.find(s => s.weekends);
  if (weekendsShift && shiftDrivenPref('weekends')) {
    const weekendsPref = attributes.preferences.find(
      p => p.name === 'weekends' && p.category === 'availability'
    );
    if (weekendsPref) arr.push(weekendsPref.id);
  }

  if (enableFilters) {
    Object.assign(params, {
      preference: shift.client.preferences
        ? shift.client.preferences
            .filter(p => p.value === 'Y')
            .map(p => p.id)
            .concat(arr)
        : arr,
      languages: shift.client.languages
        ? shift.client.languages.filter(l => l.value === 'Y').map(l => l.id)
        : [],
      advancedFilter: DEFAULT_ADVANCED_FILTERS,
    });
  }
  return params;
}

function resetCaregiverSelection(caregiverId) {
  return { type: types.RESET_CAREGIVERS_CHECKBOX_CLICKED, caregiverId };
}

function listCaregivers(params, partialUpdate = false) {
  return dispatch => {
    dispatch(request());
    const fetchTimestamp = moment();
    backend.listCaregivers(params).then(
      results => {
        dispatch(success(Object.assign(results, { timestamp: fetchTimestamp })));
        dispatch(resetCaregiverSelection());
        if (results.results.length) {
          dispatch(updateCategiverOnlineStatus(results.results.map(c => c.id)));
        }
      },
      error => {
        if (error.statusCode === 413) {
          dispatch(success(null, true));
          return;
        }

        dispatch(failure(error.message));
        dispatch(alertActions.error(error, alertActions.SEARCH_ERROR_TYPE));
      }
    );
  };

  function request() {
    return { type: types.LIST_CAREGIVERS_REQUEST, partialUpdate };
  }
  function success(results, tooManyResults) {
    return { type: types.LIST_CAREGIVERS_SUCCESS, results, tooManyResults, partialUpdate };
  }
  function failure(error) {
    return { type: types.LIST_CAREGIVERS_FAILURE, error, partialUpdate };
  }
}

function searchCaregivers(params) {
  return dispatch => {
    dispatch(request());
    backend.listCaregivers(params).then(
      results => {
        dispatch(success(results));
      },
      error => {
        if (error.statusCode === 413) {
          dispatch(success(null, true));
          return;
        }

        dispatch(failure(error.message));
        dispatch(alertActions.error(error, alertActions.SEARCH_ERROR_TYPE));
      }
    );
  };

  function request() {
    return { type: types.SEARCH_CAREGIVERS_REQUEST };
  }
  function success(results, tooManyResults) {
    return { type: types.SEARCH_CAREGIVERS_SUCCESS, results, tooManyResults };
  }
  function failure(error) {
    return { type: types.SEARCH_CAREGIVERS_FAILURE, error };
  }
}

function getCaregiver(caregiverId) {
  return dispatch => {
    dispatch(request());

    backend.getCaregiver(caregiverId).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_CAREGIVER_REQUEST };
  }
  function success(results) {
    return { type: types.GET_CAREGIVER_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_CAREGIVER_FAILURE, error };
  }
}

function getCaregiverComplianceBlocks(caregiverId) {
  return dispatch => {
    dispatch(request());

    backend.getCaregiverComplianceBlocks(caregiverId).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_CAREGIVER_COMPLIANCE_BLOCK_REQUEST };
  }
  function success(results) {
    return { type: types.GET_CAREGIVER_COMPLIANCE_BLOCK_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_CAREGIVER_COMPLIANCE_BLOCK_FAILURE, error };
  }
}

function clearCaregiverComplianceBlocks() {
  return dispatch => dispatch({ type: types.CLEAR_CAREGIVER_COMPLIANCE_BLOCKS });
}

function getCaregiverComplianceDoh(caregiverId) {
  return dispatch => {
    dispatch(request());

    backend.getCaregiverComplianceDoh(caregiverId).then(
      results => {
        dispatch(success(results));
        if (_.isEmpty(results.dohInfo)) {
          dispatch(updateComplianceDohInfos(caregiverId));
        }
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_CAREGIVER_COMPLIANCE_DOH_REQUEST };
  }
  function success(results) {
    return { type: types.GET_CAREGIVER_COMPLIANCE_DOH_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_CAREGIVER_COMPLIANCE_DOH_FAILURE, error };
  }
}

function getCaregiverComplianceExclusions(caregiverId) {
  return dispatch => {
    dispatch(request());

    backend.getCaregiverComplianceExclusions(caregiverId).then(
      results => {
        dispatch(success(results));
        if (_.isEmpty(results.exclusions)) {
          dispatch(updateComplianceExclusions(caregiverId));
        }
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_CAREGIVER_COMPLIANCE_EXCLUSIONS_REQUEST };
  }
  function success(results) {
    return { type: types.GET_CAREGIVER_COMPLIANCE_EXCLUSIONS_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_CAREGIVER_COMPLIANCE_EXCLUSIONS_FAILURE, error };
  }
}

function getDohCandidates(caregiverId, registryNumber) {
  return dispatch => {
    dispatch(request());

    backend.getDohCandidates(caregiverId, registryNumber).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_CAREGIVER_DOH_CANDIDATES_REQUEST };
  }
  function success(results) {
    return { type: types.GET_CAREGIVER_DOH_CANDIDATES_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_CAREGIVER_DOH_CANDIDATES_FAILURE, error };
  }
}

function getCaregiverEvents(caregiverId, params) {
  return dispatch => {
    dispatch(request());

    backend.getCaregiverEvents(caregiverId, params).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_CAREGIVER_EVENTS_REQUEST };
  }
  function success(results) {
    return { type: types.GET_CAREGIVER_EVENTS_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_CAREGIVER_EVENTS_FAILURE, error };
  }
}

function listCaregiversByShifts(shiftList) {
  const params = shiftsToCaregiverParams(shiftList);

  function setUiStateRequest(requestParams, shifts) {
    const collapse = {
      languagesMenu: !!(requestParams.languages && requestParams.languages.length),
    };
    const shift = shifts && shifts.length ? shifts[0] : undefined;
    const prefs = shift && shift.client && shift.client.preferences ? shift.client.preferences : [];

    Object.keys(PreferenceCategories).forEach(key => {
      const menu = `${key}PreferencesMenu`;
      collapse[menu] = !!prefs.find(x => x.category === key);
    });

    const state = {
      preferenceFilter: requestParams.preference.map(x => parseInt(x, 10)),
      travelTime: requestParams.travelTime,
      advancedFilter: requestParams.advancedFilter,
      languages: requestParams.languages,
      staffStatusFilter: [ActiveStaffStatus],
      statusFilter: [],
      selectedPrimaryContacts: [0],
      genderFilter: [],
      profileFieldsFilter: [],
      ...collapse,
    };

    return { type: types.SET_UI_GROUP_STATE, groupName: 'caregiverFilter', state };
  }

  function setMultiShiftFilters() {
    const state = {
      selectedShifts: shiftList.map(s => s.id),
      originalShifts: shiftList.map(s => s.id),
    };
    return { type: types.SET_UI_GROUP_STATE, groupName: 'multiShiftSelector', state };
  }

  return dispatch => {
    dispatch(setUiStateRequest(params, shiftList));
    dispatch(setMultiShiftFilters());
    listCaregivers(params)(dispatch);
  };
}

function clickCaregiverCheckbox(caregiverId) {
  return dispatch => {
    dispatch({ type: types.CAREGIVERS_CHECKBOX_CLICKED, caregiverId });
  };
}

function selectAllClicked() {
  return dispatch => {
    dispatch({ type: types.CAREGIVERS_SELECT_ALL_CLICKED });
  };
}

function inviteCaregiver(
  caregiver,
  shiftIdList,
  status,
  invitationNotes,
  sendSignupRequest,
  logMeta = undefined,
  fromCGProfile = undefined
) {
  const caregivers = Array.isArray(caregiver) ? [...caregiver] : [caregiver];
  const logMetas = Array.isArray(logMeta) ? [...logMeta] : [logMeta];

  return dispatch => {
    const next = () => {
      if (caregivers.length <= 0) {
        dispatch(completed());
        return;
      }

      const cg = caregivers.pop();
      const lm = _.find(logMetas, { caregiver_id: cg.id });
      dispatch(processCaregiverInvitation(cg.id));
      const promise = backend.inviteCaregiver(
        cg.id,
        shiftIdList,
        status,
        invitationNotes,
        sendSignupRequest || cg.status === 'inactive',
        lm
      );
      promise.then(
        results => {
          dispatch(success(results));
          dispatch(resetCaregiverSelection(cg.id));
          dispatch(processCaregiverInvitationCompleted(cg.id));
          if (!shiftIdList) {
            if (fromCGProfile) {
              dispatch(getCaregiver(cg.id));
            }
            dispatch(alertActions.notification('The invitation has been sent'));
          }

          if (sendSignupRequest || cg.status === 'inactive') {
            analytics.trackForCaregiver(cg, Events.CAREGIVERS_REGISTRATION_RECEIVE_INVITATION);
          }
          if (shiftIdList) {
            analytics.trackForCaregiver(cg, Events.CAREGIVERS_SHIFTS_RECEIVE_INVITATION);
          }

          next();
        },
        error => {
          dispatch(failure(error.message));
          dispatch(alertActions.error(error));
          dispatch(processCaregiverInvitationCompleted(cg.id));
          next();
        }
      );
    };

    dispatch(request());
    if (caregivers.length > 1) {
      const cgIds = caregivers.map(x => x.id);
      const lms = cgIds.map(id => _.find(logMetas, { caregiver_id: id }));
      dispatch(processCaregiverInvitation(cgIds));
      const promise = backend.inviteMultipleCaregivers(
        cgIds,
        shiftIdList,
        status,
        invitationNotes,
        sendSignupRequest,
        lms
      );
      promise
        .then(results => {
          dispatch(success(results));
        })
        .then(() => dispatch(processCaregiverInvitationCompleted(cgIds)))
        .then(() => dispatch(selectAllClicked()))
        .then(() =>
          store.getState().caregiver.allSelected
            ? dispatch(resetMultipleCaregiverSelection())
            : null
        )
        .then(() => {
          dispatch(alertActions.notification('The invitations has been sent'));
          dispatch(completed());
        });
      return;
    }
    next();
  };

  function request() {
    return { type: types.ADD_CAREGIVER_TO_SHIFT_REQUEST };
  }
  function completed() {
    return { type: types.ADD_CAREGIVER_TO_SHIFT_COMPLETED };
  }
  function success(results) {
    return { type: types.ADD_CAREGIVER_TO_SHIFT_SUCCESS, results, status };
  }
  function failure(error) {
    return { type: types.ADD_CAREGIVER_TO_SHIFT_FAILURE, error };
  }
  function resetMultipleCaregiverSelection() {
    return selectAllClicked();
  }
  function processCaregiverInvitation(caregiverId) {
    return { type: types.PROCESS_CAREGIVER_INVITATION, caregiverId };
  }
  function processCaregiverInvitationCompleted(caregiverId) {
    return { type: types.PROCESS_CAREGIVER_INVITATION_COMPLETED, caregiverId };
  }
}

function resetCaregiverList() {
  return dispatch => {
    dispatch({ type: types.RESET_CAREGIVERS_LIST });
  };
}

function setTargetShifts(withIds) {
  return dispatch => {
    if (!withIds || withIds.length === 0) {
      throw Error('No shifts selected for the search');
    }
    dispatch(request());
    backend.listSchedules({ withIds, languages: true }).then(
      results => {
        if (results.results.length === 0) {
          const msg = 'No shifts found with given ID';
          dispatch(failure(msg));
          dispatch(alertActions.error(msg));
          return;
        }

        dispatch(listCaregiversByShifts(results.results));
        const values = results.results.map(s => ({
          shiftId: s.id,
          start: moment(s.start),
          end: moment(s.end),
        }));
        dispatch({ type: types.SET_SHIFT_TIMING, values });
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.SET_TARGET_SHIFTS_REQUEST };
  }
  function success(results) {
    return { type: types.SET_TARGET_SHIFTS_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.SET_TARGET_SHIFTS_FAILURE, error };
  }
}

function updateCaregiver(caregiver) {
  return dispatch => {
    dispatch(request());
    backend.updateCaregiver(caregiver).then(
      results => {
        dispatch(success(results));
        dispatch(alertActions.notification('Caregiver settings saved.'));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error, alertActions.UPDATE_CAREGIVER_ERROR));
      }
    );
  };

  function request() {
    return { type: types.UPDATE_CAREGIVER_REQUEST };
  }
  function success(results) {
    return { type: types.UPDATE_CAREGIVER_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.UPDATE_CAREGIVER_FAILURE, error };
  }
}

function createCaregiver(caregiver) {
  return dispatch => {
    dispatch(request());
    backend.createCaregiver(caregiver).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error, alertActions.CREATE_CAREGIVER_ERROR));
      }
    );
  };

  function request() {
    return { type: types.CREATE_CAREGIVER_REQUEST };
  }
  function success(results) {
    return { type: types.CREATE_CAREGIVER_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.CREATE_CAREGIVER_FAILURE, error };
  }
}

function updateComplianceExclusions(caregiverId) {
  return dispatch => {
    dispatch(request());
    backend.updateComplianceExclusions(caregiverId).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.UPDATE_CAREGIVER_COMPLIANCE_EXCLUSIONS_REQUEST };
  }
  function success(results) {
    return { type: types.UPDATE_CAREGIVER_COMPLIANCE_EXCLUSIONS_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.UPDATE_CAREGIVER_COMPLIANCE_EXCLUSIONS_FAILURE, error };
  }
}

function updateComplianceDohInfos(caregiverId, registryNumber) {
  return dispatch => {
    dispatch(request());
    backend.updateComplianceDohInfos(caregiverId, registryNumber).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.UPDATE_CAREGIVER_COMPLIANCE_DOHINFO_REQUEST };
  }
  function success(results) {
    return { type: types.UPDATE_CAREGIVER_COMPLIANCE_DOHINFO_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.UPDATE_CAREGIVER_COMPLIANCE_DOHINFO_FAILURE, error };
  }
}
function clearComplianceDohInfos(caregiverId) {
  return dispatch => {
    dispatch(request());
    backend.clearComplianceDohInfos(caregiverId).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: types.UPDATE_CAREGIVER_COMPLIANCE_DOHINFO_REQUEST };
  }
  function success(results) {
    return { type: types.UPDATE_CAREGIVER_COMPLIANCE_DOHINFO_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.UPDATE_CAREGIVER_COMPLIANCE_DOHINFO_FAILURE, error };
  }
}
function setCaregiverComplianceBlockFields(caregiverId, fields) {
  return dispatch => {
    dispatch(request());
    backend.setCaregiverComplianceBlockFields(caregiverId, fields).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.SET_CAREGIVER_COMPLIANCE_BLOCK_REQUEST };
  }
  function success(results) {
    return { type: types.SET_CAREGIVER_COMPLIANCE_BLOCK_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.SET_CAREGIVER_COMPLIANCE_BLOCK_FAILURE, error };
  }
}

function setCaregiverComplianceByProfile(caregiverId, params) {
  return dispatch => {
    dispatch(request());
    backend.setCaregiverComplianceByProfile(caregiverId, params).then(
      results => {
        dispatch(success(results));
        if (params.preferenceId) {
          dispatch(getCaregiver(caregiverId));
        }
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.SET_CAREGIVER_COMPLIANCE_BY_PROFILE_REQUEST };
  }
  function success(results) {
    return { type: types.SET_CAREGIVER_COMPLIANCE_BY_PROFILE_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.SET_CAREGIVER_COMPLIANCE_BY_PROFILE_FAILURE, error };
  }
}

function updateCaregiverCasePreferences(userId, casePreferences) {
  return dispatch => {
    dispatch(request());
    backend.updateCaregiverCasePreferences(userId, casePreferences).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.UPDATE_CAREGIVER_CASE_PREFERENCES_REQUEST };
  }
  function success(results) {
    return { type: types.UPDATE_CAREGIVER_CASE_PREFERENCES_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.UPDATE_CAREGIVER_CASE_PREFERENCES_FAILURE, error };
  }
}

function updateCategiverOnlineStatus(caregiverIds, timestamp) {
  return dispatch => {
    dispatch(request());

    backend.getCaregiverOnlineStatus(caregiverIds, timestamp).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
      }
    );
  };

  function request() {
    return { type: types.CAREGIVERS_ONLINESTATUS_REQUEST };
  }
  function success(results) {
    return { type: types.CAREGIVERS_ONLINESTATUS_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.CAREGIVERS_ONLINESTATUS_FAILURE, error };
  }
}

class OnlineStatusChecks {
  constructor() {
    this.timer = null;
    this.clear = () => {
      clearTimeout(this.timer);
      this.timer = null;
    };
    this.initialize = (dispatch, getState) => {
      const update = () => {
        const state = getState();
        if (state.caregiver.results && state.caregiver.results.length > 0) {
          const caregiverIds = state.caregiver.results.map(c => c.id);
          dispatch(
            updateCategiverOnlineStatus(
              caregiverIds,
              state.caregiver.lastOnlineUpdate
                ? state.caregiver.lastOnlineUpdate.toISOString()
                : null
            )
          );
        }
      };
      const initTimer = () => {
        this.timer = setTimeout(() => {
          update();
          initTimer();
        }, 30000);
      };
      initTimer();
    };
  }
}

function downloadDocuments(caregiverId, documentsQuery) {
  return dispatch => {
    dispatch(request());

    backend.downloadComplianceDocuments(caregiverId, documentsQuery).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
      }
    );
  };

  function request() {
    return { type: types.DOWNLOAD_CAREGIVER_COMPLIANCE_DOCUMENTS_REQUEST };
  }
  function success(results) {
    return { type: types.DOWNLOAD_CAREGIVER_COMPLIANCE_DOCUMENTS_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.DOWNLOAD_CAREGIVER_COMPLIANCE_DOCUMENTS_FAILURE, error };
  }
}

function uploadComplianceDocument(fieldId, file, caregiverId, tag = null, callback = null) {
  const uploadFile = document =>
    new Promise((resolve, reject) => {
      const upload = e => {
        const path = document.uploadLink;
        fetch(path, {
          method: 'PUT',
          body: e.currentTarget.result,
          headers: {
            'content-length': file.size,
            'Content-Type': file.type,
          },
        })
          .then(s => {
            if (s.status >= 400) {
              const error = new Error(`HTTP Error ${s.statusText}`);
              error.status = s.statusText;
              error.statusCode = s.status;
              error.response = s;
              throw error;
            } else {
              resolve();
            }
            return {};
          })
          .catch(error => reject(error));
      };

      const reader = new FileReader();
      reader.onload = upload;
      reader.readAsArrayBuffer(file);
    });

  return dispatch => {
    dispatch(request());

    backend.uploadComplianceDocument(fieldId, file.name, caregiverId, tag).then(
      results => {
        const data = new FormData();
        data.append('file', file, file.name);
        return uploadFile(results)
          .then(() => {
            dispatch(getCaregiverComplianceBlocks(caregiverId));
            dispatch(success(results));
            if (callback) callback(results);
          })
          .catch(error => {
            dispatch(failure(error.message));
            dispatch(alertActions.error(error));
            dispatch(removeComplianceDocument(caregiverId, fieldId, results.file));
          });
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.UPLOAD_CAREGIVER_COMPLIANCE_DOCUMENT_REQUEST, fieldId };
  }
  function success(results) {
    return { type: types.UPLOAD_CAREGIVER_COMPLIANCE_DOCUMENT_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.UPLOAD_CAREGIVER_COMPLIANCE_DOCUMENT_FAILURE, error };
  }
}

function removeComplianceDocument(caregiverId, fieldId, filename) {
  return dispatch => {
    dispatch(request());
    backend.removeComplianceDocument(caregiverId, fieldId, filename).then(
      results => {
        dispatch(getCaregiverComplianceBlocks(caregiverId));
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
      }
    );
  };

  function request() {
    return { type: types.REMOVE_CAREGIVER_COMPLIANCE_DOCUMENT_REQUEST };
  }
  function success(results) {
    return { type: types.REMOVE_CAREGIVER_COMPLIANCE_DOCUMENT_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.REMOVE_CAREGIVER_COMPLIANCE_DOCUMENT_FAILURE, error };
  }
}

function getCaregiverInServiceSummary(caregiverId) {
  return dispatch => {
    dispatch(request());
    backend.getCaregiverInServiceSummary(caregiverId).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
      }
    );
  };

  function request() {
    return { type: types.GET_CAREGIVER_IN_SERVICE_SUMMARY_REQUEST };
  }
  function success(results) {
    return { type: types.GET_CAREGIVER_IN_SERVICE_SUMMARY_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_CAREGIVER_IN_SERVICE_SUMMARY_FAILURE, error };
  }
}

function includeCaregiverToTrainingEvent(caregiverId, training) {
  return dispatch => {
    dispatch(request());
    backend.createCaregiverInServiceTraining(caregiverId, training).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.CREATE_CAREGIVER_IN_SERVICE_TRAINING_REQUEST };
  }
  function success(results) {
    return { type: types.CREATE_CAREGIVER_IN_SERVICE_TRAINING_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.CREATE_CAREGIVER_IN_SERVICE_TRAINING_FAILURE, error };
  }
}

function removeCaregiverTrainingEvent(caregiverId, eventId) {
  return dispatch => {
    dispatch(request());
    backend.removeCaregiverTrainingEvent(caregiverId, eventId).then(
      results => {
        dispatch(success(results));
        dispatch(getCaregiverInServiceSummary(caregiverId));
        dispatch(getCaregiverComplianceBlocks(caregiverId));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.REMOVE_CAREGIVER_IN_SERVICE_TRAINING_REQUEST };
  }
  function success(results) {
    return { type: types.REMOVE_CAREGIVER_IN_SERVICE_TRAINING_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.REMOVE_CAREGIVER_IN_SERVICE_TRAINING_FAILURE, error };
  }
}

// same as includeCaregiverToTrainingEvent function,
// but it creates the event first by trainingCourseId, location, supervisor and completion values
function createCaregiverInServiceTrainingByCourse(
  caregiverId,
  training,
  fetchComplianceAfterSuccess = false
) {
  return dispatch => {
    dispatch(request());
    backend
      .createInServiceTrainingEvent({
        blockId: training.blockId,
        date: training.completion,
        supervisor: training.supervisor,
        hours: training.hours,
        classRoom: training.classRoom,
        eligibleHours: training.hours,
        payableHours: 0,
        visibleInCalendar: false,
      })
      .then(event => {
        backend
          .createCaregiverInServiceTraining(caregiverId, {
            completed: true,
            attended: true,
            trainingEventId: event.id,
            documents: training.documents,
            ignoreInvitation: true,
          })
          .then(
            results => {
              dispatch(success(results));
              if (fetchComplianceAfterSuccess) {
                dispatch(getCaregiverInServiceSummary(caregiverId));
                dispatch(getCaregiverComplianceBlocks(caregiverId));
              }
            },
            error => {
              dispatch(failure(error.message));
              dispatch(alertActions.error(error));
            }
          );
      });
  };

  function request() {
    return { type: types.CREATE_CAREGIVER_IN_SERVICE_TRAINING_REQUEST };
  }
  function success(results) {
    return { type: types.CREATE_CAREGIVER_IN_SERVICE_TRAINING_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.CREATE_CAREGIVER_IN_SERVICE_TRAINING_FAILURE, error };
  }
}

function addWorkhistory(caregiverId, job) {
  return dispatch => {
    dispatch(request());
    backend.addWorkhistoryToCaregiver(caregiverId, job).then(
      results => {
        dispatch(success(results));
        dispatch(getCaregiverComplianceDoh(caregiverId));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.ADD_WORKHISTORY_TO_CAREGIVER_REQUEST };
  }
  function success(results) {
    return { type: types.ADD_WORKHISTORY_TO_CAREGIVER_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.ADD_WORKHISTORY_TO_CAREGIVER_FAILURE, error };
  }
}

function getTrainingCandidates(params, partialUpdate = false) {
  return async dispatch => {
    dispatch(request());
    const buildedParams = hrActionsHelper.buildCandidatesParams(params);
    try {
      const candidatesReport = await backend.getTrainingCandidates(buildedParams);
      const candidates = await fetch(candidatesReport.reportDownloadUrl).then(data => data.json());
      dispatch(success(candidates));
    } catch (error) {
      dispatch(alertActions.error(error));
      dispatch(failure(error));
    }
  };

  function request() {
    return { type: types.GET_TRAINING_CANDIDATES_REQUEST, partialUpdate };
  }

  function success(results) {
    return { type: types.GET_TRAINING_CANDIDATES_SUCCESS, results, partialUpdate };
  }

  function failure(error) {
    return { type: types.GET_TRAINING_CANDIDATES_FAILURE, error, partialUpdate };
  }
}

function getCaregiversCompliance(params, partialUpdate = false) {
  return async dispatch => {
    dispatch(request());
    const buildedParams = hrActionsHelper.buildCaregiversParams(params);
    try {
      const caregiversReport = await backend.getCaregiversCompliance(buildedParams);
      const caregivers = await fetch(caregiversReport.reportDownloadUrl).then(data => data.json());
      dispatch(success(caregivers));
    } catch (error) {
      dispatch(alertActions.error(error));
      dispatch(failure(error));
    }
  };

  function request() {
    return { type: types.GET_CAREGIVERS_COMPLIANCE_REQUEST, partialUpdate };
  }

  function success(results) {
    return { type: types.GET_CAREGIVERS_COMPLIANCE_SUCCESS, results, partialUpdate };
  }

  function failure(error) {
    return { type: types.GET_CAREGIVERS_COMPLIANCE_FAILURE, error, partialUpdate };
  }
}

function completeCaregiverPrehire(caregiverIds, options) {
  return async dispatch => {
    dispatch(request());

    try {
      const result = await backend.completeCaregiverPrehire(caregiverIds);
      const failed = result.find(x => x.error);
      const successed = result.find(x => x.success === true);
      if (failed) {
        dispatch(alertActions.error(failed.error));
      }
      if (successed) {
        dispatch(alertActions.notification('Candidate has been activated'));
      }
      dispatch(success(result));
      if (options.caregiverProfile) {
        dispatch(getCaregiver(caregiverIds));
      }
      if (options.hrPortal) {
        dispatch(getTrainingCandidates());
      }
    } catch (error) {
      dispatch(failure(error));
      dispatch(getTrainingCandidates());
    }
  };

  function request() {
    return { type: types.COMPLETE_CAREGIVER_PREHIRE_REQUEST };
  }

  function success(results) {
    return { type: types.COMPLETE_CAREGIVER_PREHIRE_SUCCESS, results };
  }

  function failure(error) {
    return { type: types.COMPLETE_CAREGIVER_PREHIRE_FAILURE, error };
  }
}

function deleteWorkhistory(caregiverId, job) {
  return dispatch => {
    dispatch(request());
    backend.deleteWorkhistoryToCaregiver(caregiverId, job).then(
      results => {
        dispatch(success(results));
        dispatch(getCaregiverComplianceDoh(caregiverId));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.DELETE_WORKHISTORY_TO_CAREGIVER_REQUEST };
  }
  function success(results) {
    return { type: types.DELETE_WORKHISTORY_TO_CAREGIVER_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.DELETE_WORKHISTORY_TO_CAREGIVER_FAILURE, error };
  }
}

function updateComplianceExclusion(caregiverId, exclusion) {
  return dispatch => {
    dispatch(request());
    backend.updateComplianceExclusionDetails(caregiverId, exclusion).then(
      () => {
        dispatch(getCaregiverComplianceExclusions(caregiverId));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_CAREGIVER_COMPLIANCE_EXCLUSIONS_REQUEST };
  }
  function failure(error) {
    return { type: types.GET_CAREGIVER_COMPLIANCE_EXCLUSIONS_FAILURE, error };
  }
}

function createCaregiverClear() {
  return dispatch => dispatch({ type: types.CREATE_CAREGIVER_CLEAR });
}

export const caregiverActions = {
  getCaregiverComplianceDoh,
  getCaregiverComplianceExclusions,
  listCaregivers,
  resetCaregiverList,
  searchCaregivers,
  setTargetShifts,
  clickCaregiverCheckbox,
  inviteCaregiver,
  getCaregiver,
  getCaregiverEvents,
  selectAllClicked,
  updateCaregiver,
  createCaregiver,
  updateCaregiverCasePreferences,
  shiftsToCaregiverParams,
  combineCaregiverParams,
  OnlineStatusChecks,
  createCaregiverClear,
  updateComplianceDohInfos,
  clearComplianceDohInfos,
  updateComplianceExclusions,
  setCaregiverComplianceBlockFields,
  getCaregiverComplianceBlocks,
  getDohCandidates,
  downloadDocuments,
  uploadComplianceDocument,
  removeComplianceDocument,
  createCaregiverInServiceTrainingByCourse,
  includeCaregiverToTrainingEvent,
  addWorkhistory,
  removeCaregiverTrainingEvent,
  getTrainingCandidates,
  getCaregiversCompliance,
  deleteWorkhistory,
  updateComplianceExclusion,
  completeCaregiverPrehire,
  listCaregiversByShifts,
  setCaregiverComplianceByProfile,
  clearCaregiverComplianceBlocks,
  getCaregiverInServiceSummary,
};

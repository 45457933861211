/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Menu, Accordion, Form } from 'semantic-ui-react';

import { caregiverActions, clientActions, uiStateActions } from '../actions';
import { analytics, Events } from '../helpers/analytics';

const FindClientForm = ({ uiState, onChange, dispatch }) => {
  const searchClick = history => {
    dispatch(uiStateActions.resetInputGroupState('clientsFilter'));
    dispatch(clientActions.listClients({ name: uiState.clientName }));
    history.history.push('/home/clients');
    analytics.track(Events.DASHBOARD_SEARCH_CLIENT);
  };

  const render = () => history => (
    <Form onSubmit={() => searchClick(history)} className='filterMenu filterWithGo'>
      <Form.Group className='menu-input-with-button' inline>
        <Form.Input
          id='dashboardSearchClientInput'
          placeholder='Client Name'
          name='clientName'
          value={uiState.clientName}
          onChange={onChange}
        />
        <Form.Button id='DashboardSearchClientSubmit' circular>
          GO
        </Form.Button>
      </Form.Group>
    </Form>
  );

  return <Route render={render()} />;
};

FindClientForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uiState: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};

const FindCaregiverForm = ({ uiState, onChange, dispatch }) => {
  const searchClick = history => {
    dispatch(uiStateActions.resetInputGroupState('caregiverFilter'));
    dispatch(caregiverActions.resetCaregiverList());
    dispatch(caregiverActions.listCaregivers({ staffStatus: null, name: uiState.caregiverName }));
    history.history.push('/home/caregivers');
    analytics.track(Events.DASHBOARD_SEARCH_CAREGIVER);
  };

  const render = () => history => (
    <Form onSubmit={() => searchClick(history)} className='filterMenu filterWithGo'>
      <Form.Group className='menu-input-with-button' inline>
        <Form.Input
          id='dashboardSearchCaregiverInput'
          placeholder='Caregiver Name'
          name='caregiverName'
          value={uiState.caregiverName}
          onChange={onChange}
        />
        <Form.Button id='DashboardSearchCaregiverSubmit' circular>
          GO
        </Form.Button>
      </Form.Group>
    </Form>
  );

  return <Route render={render()} />;
};

FindCaregiverForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uiState: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};

const FilterGroup = ({ uiState, onTitleClick, onValueChange, dispatch }) => (
  <Accordion as={Menu} exclusive={false} widths='sixteen' className='filterSideMenu'>
    <Menu.Item className='text-align-left'>
      <Accordion.Title
        className='side-menu-item'
        active={uiState.findClientMenu}
        content='FIND CLIENT'
        indexname='findClientMenu'
        onClick={onTitleClick}
      />
      <Accordion.Content active={uiState.findClientMenu}>
        <FindClientForm uiState={uiState} onChange={onValueChange} dispatch={dispatch} />
      </Accordion.Content>
    </Menu.Item>
    <Menu.Item className='text-align-left'>
      <Accordion.Title
        className='side-menu-item'
        active={uiState.findCaregiverMenu}
        content='FIND CAREGIVER'
        indexname='findCaregiverMenu'
        onClick={onTitleClick}
      />
      <Accordion.Content active={uiState.findCaregiverMenu}>
        <FindCaregiverForm uiState={uiState} onChange={onValueChange} dispatch={dispatch} />
      </Accordion.Content>
    </Menu.Item>
  </Accordion>
);

FilterGroup.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uiState: PropTypes.shape().isRequired,
  onTitleClick: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
};

class DashboardMenu extends React.Component {
  handleFilterTitleClick = (e, titleProps) => {
    this.props.dispatch(
      uiStateActions.uiStateChange('dashboardFilter', titleProps.indexname, !titleProps.active)
    );
  };

  render() {
    const { uiState, dispatch } = this.props;
    const items = [
      {
        key: 'filters',
        disabled: true,
        content: (
          <FilterGroup
            dispatch={dispatch}
            uiState={uiState}
            onTitleClick={this.handleFilterTitleClick}
            onValueChange={uiStateActions.inputChangeHandler(
              this.props.dispatch,
              'dashboardFilter'
            )}
          />
        ),
      },
    ];

    return <Menu vertical secondary fluid items={items} />;
  }
}

DashboardMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uiState: PropTypes.shape().isRequired,
};

export default connect(uiStateActions.mapStateOfGroupToProps('dashboardFilter'))(DashboardMenu);

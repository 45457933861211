import React from 'react';
import PropTypes from 'prop-types';
import { PreferenceCategories } from '../../constants/DomainTypes';
import './EnvironmentTab.css';

const EnvironmentTab = ({ client }) => {
  const languages = clientLanguages => {
    const primaryLanguage = clientLanguages ? clientLanguages.filter(l => l.primary === true) : [];
    const additionLanguages = clientLanguages
      ? clientLanguages.filter(l => l.primary === false)
      : [];
    const listAdditionalLanguages = additionLanguages.map((a, i) => {
      const coma = additionLanguages.length - 1 > i ? ',' : '';
      return (
        <b key={a.id} className='environment-tab-additional-language'>
          {`${a.name}${coma} `}
        </b>
      );
    });
    return (
      <div className='environment-tab-languages-container'>
        <h3 className='environment-tab-language-header'>LANGUAGES</h3>
        <div className='environment-tab-display-language-container'>
          <h6 className='environment-tab-language-title'>Primary:</h6>
          <p className='environment-tab-language'>
            {primaryLanguage && primaryLanguage.length ? primaryLanguage[0].name : ''}
          </p>
        </div>
        <div className='environment-tab-display-language-container'>
          <h6 className='environment-tab-language-title'>Additional:</h6>
          <p className='environment-tab-language'>{listAdditionalLanguages}</p>
        </div>
      </div>
    );
  };

  const preferenceDropdowns = clientPreferences =>
    Object.keys(PreferenceCategories)
      .filter(c => PreferenceCategories[c].clientTitle)
      .map(category => {
        const all = clientPreferences ? clientPreferences.filter(x => x.category === category) : [];
        const options = all.map(x => ({ value: x.id, text: x.clientLabel || x.label }));
        const selected = all
          .filter(x => x.value === 'Y')
          .map(x => ({ id: x.id, text: x.clientLabel || x.label }));

        if (options && options.length && selected && selected.length) {
          return (
            <div
              className='environment-tab-selected-option-container'
              key={PreferenceCategories[category].label}
            >
              <h3 className='environment-tab-header'>
                {PreferenceCategories[category].clientTitle.toUpperCase()}
              </h3>

              {selected.map(v => (
                <div key={v.id}>
                  <p className='environment-tab-selected-option'>{v.text}</p>
                </div>
              ))}
            </div>
          );
        }

        return undefined;
      });

  return (
    <span className='environment-tab-main-container'>
      {languages(client.languages)}
      <div className='environment-tab-option-container'>
        {preferenceDropdowns(client.preferences)}
      </div>
    </span>
  );
};

EnvironmentTab.propTypes = {
  client: PropTypes.shape().isRequired,
};

export default EnvironmentTab;

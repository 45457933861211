/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/state-in-constructor */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import {
  Grid,
  Segment,
  Form,
  Image,
  Header,
  Button,
  Dropdown,
  Menu,
  Icon,
  Progress,
} from 'semantic-ui-react';
import { Link, NavLink } from 'react-router-dom';
import './Profile.css';
import URLSearchParams from 'url-search-params';
import { alertActions } from '../actions/alert';
import { employeeActions } from '../actions/employee';
import {
  PhoneTypes,
  EmployeeAvailableRoles,
  isAdmin,
  isTrainee,
  avatarUrl,
} from '../constants/DomainTypes';
import { getVersion, getGitHash } from './AppVersion';
import { isValidEmail } from '../constants/Formats';
import { GreenButton } from '../styles/common';
import { history } from '../helpers';
import { ELCoursesActions } from '../actions/ELApp/ElCoursesActions';
import { ELUserActions } from '../actions/ELApp/ELUserActions';
import { ELManageActions } from '../actions/ELApp/ELManageActions';
import ProgramTile from './subcomponents/eLAPP/ProgramsTile';
import CareRoundButton from './subcomponents/CareRoundButton';
import ELearningProfileAssignments from './subcomponents/ELearningProfileAssignments';
import EmployeeEditor from './EmployeeEditor';

const InProgressCourses = ({ courses }) => {
  const completed = courses.filter(c => c.completionDate);
  const handleResumeCourse = () => history.push('/home/e-learning/courses');
  const percentage = courses.length ? (completed.length * 100) / courses.length : 0;
  const hideBar = percentage <= 0 ? 'profile-hide-progress-bar' : '';
  return (
    <div className='profile-e-learning-progress-container'>
      <h6 className='profile-e-learning-progress-header'>E-Learning Progress </h6>
      <GreenButton
        disabled={!courses.length}
        className='profile-e-learning-progress-launch-button'
        onClick={() => handleResumeCourse()}
      >
        launch e-learning
      </GreenButton>
      <div className='profile-e-learning-progress-bra-container'>
        <Progress
          className={`profile-e-learning-progress-bar ${hideBar}`}
          percent={percentage}
          size='tiny'
        />
      </div>
      <section className='profile-e-learning-progress-count'>
        {`${completed.length} of ${courses.length} courses complete`}
      </section>
    </div>
  );
};

InProgressCourses.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export const ProfileMenu = ({ email, isUserAdmin, isRootAdmin, settings }) => {
  const EnableExportImport = isRootAdmin || settings?.standaloneMode;
  if (isUserAdmin) {
    return (
      <div className='profileMenu'>
        <Header>ADMINISTRATOR</Header>
        <Segment basic className='profileMenuText'>
          As the system administrator you have full access to the CareConnect system to add and
          manage employee profiles and access.
        </Segment>
        <Segment basic>
          <Menu.Menu position='right'>
            <Menu.Item id='menuProfile' as={NavLink} to='/home/profile'>
              <div className='menu-item'>
                <Icon name='user circle outline' className='icon' />
                <span className='item-title'>Administrator Profile</span>
              </div>
            </Menu.Item>
            {settings && settings.editableAttributes ? (
              <Menu.Item
                id='menuSystemSettings'
                as={NavLink}
                to='/home/systemsettings'
                className=''
                disabled
              >
                <div className='menu-item'>
                  <Icon name='setting' className='icon' />
                  <span className='item-title'> System Settings</span>
                </div>
              </Menu.Item>
            ) : (
              undefined
            )}
            {EnableExportImport ? (
              <Menu.Item id='menuImports' as={NavLink} to='/home/imports' className=''>
                <div className='menu-item'>
                  <Icon name='setting' className='icon' />
                  <span className='item-title'>Imports</span>
                </div>
              </Menu.Item>
            ) : (
              undefined
            )}
            {EnableExportImport ? (
              <Menu.Item id='menuExports' as={NavLink} to='/home/exports' className=''>
                <div className='menu-item'>
                  <Icon name='download' className='icon' />
                  <span className='item-title'>Exports</span>
                </div>
              </Menu.Item>
            ) : (
              undefined
            )}
          </Menu.Menu>
        </Segment>
        <Segment basic className='profileMenuText'>
          Version {getVersion()}
        </Segment>
        <Segment basic className='profileMenuText'>
          Git SHA1: {getGitHash()}
        </Segment>
        <Segment basic className='profileMenuText'>
          Browser timezone: {moment.tz.guess()}
        </Segment>
      </div>
    );
  }

  return (
    <div className='profileMenu'>
      <Segment basic>
        Your profile is maintained by your agency coordinator with limited edits available.
      </Segment>
      <Segment basic>
        If you see any information that is incorrect and need of change please contact your agency
        coordinator to make these changes.
      </Segment>
      <Segment basic as='a'>
        {email}
      </Segment>
    </div>
  );
};

ProfileMenu.defaultProps = {
  isUserAdmin: null,
  isRootAdmin: null,
  settings: null,
};

ProfileMenu.propTypes = {
  email: PropTypes.string.isRequired,
  isUserAdmin: PropTypes.bool,
  isRootAdmin: PropTypes.bool,
  settings: PropTypes.shape(),
};

const UserOverView = ({ dashboard }) => (
  <Grid padded className='detailsBox profile'>
    <Grid.Row className='headerRow' columns='1'>
      <Header size='medium'>USER OVERVIEW</Header>
    </Grid.Row>
    <Grid.Row>
      <Grid className='overviewTitle' padded='horizontally' style={{ margin: '10px 0px 5px' }}>
        <Grid.Column width='3'>
          <Segment circular className='blueLargeCircle' style={{ marginRight: '10px !important' }}>
            <span id='profile-schedulers-circle-text' className='blueLargeCircleText'>
              {dashboard ? dashboard.schedulers : '-'}
            </span>
          </Segment>
        </Grid.Column>
        <Grid.Column width='12'>
          <Header size='small'>Schedulers</Header>
          <p>
            Users with access to the Scheduling tool within the CareConnect system. These users have
            read only access to their profile information and team allocation.
          </p>
        </Grid.Column>
      </Grid>
      <Grid className='overviewTitle' padded='horizontally' style={{ margin: '5px 0px 5px' }}>
        <Grid.Column width='3'>
          <Segment circular className='greenLargeCircle'>
            <span id='profile-hr-managers-circle-text' className='greenLargeCircleText'>
              {dashboard ? dashboard.hrmanagers : '-'}
            </span>
          </Segment>
        </Grid.Column>
        <Grid.Column width='12'>
          <Header size='small'>HR Managers</Header>
          <p>
            Users with access to the HR Management tool within the CareConnect system. These users
            have read only access to their profile information and team allocation.
          </p>
        </Grid.Column>
      </Grid>
      <Grid className='overviewTitle' padded='horizontally' style={{ margin: '5px 0px 10px' }}>
        <Grid.Column width='3'>
          <Segment circular className='redLargeCircle'>
            <span id='profile-administrators-circle-text' className='redLargeCircleText'>
              {dashboard ? dashboard.admins : '-'}
            </span>
          </Segment>
        </Grid.Column>
        <Grid.Column width='12'>
          <Header size='small'>Administrators</Header>
          <p>
            Users with the additional rights to edit users, manage profiles, and manage user access
            to the CareConnect system.
          </p>
        </Grid.Column>
        <Grid.Column style={{ marginLeft: '12px' }} width='15' textAlign='center'>
          <Button
            id='profile-view-user-button'
            as={Link}
            to='/home/userdirectory'
            className='care-blue'
            style={{ fontWeight: 'normal', fontSize: '12px' }}
          >
            View User Directory
          </Button>
        </Grid.Column>
      </Grid>
    </Grid.Row>
  </Grid>
);

UserOverView.propTypes = {
  dashboard: PropTypes.shape(),
};

UserOverView.defaultProps = {
  dashboard: null,
};

const ProfileNameAndPic = ({
  loading,
  state,
  onChange,
  allowAvatarEdit,
  imageUploadEvent,
  removeAvatar,
}) => {
  const imageUploadNode = {
    dom: null,
  };
  const clickUpdate = () => {
    if (imageUploadNode.dom) {
      imageUploadNode.dom.click();
    }
  };

  const firstNameValid = state.firstName.length > 1;
  const lastNameValid = state.lastName.length > 1;

  return (
    <Grid padded className='nameAndPic' horizontal='true'>
      <Grid.Row>
        <Grid.Column width='4' className='imageContainer'>
          <input
            type='file'
            accept='.jpg, .jpeg'
            style={{ display: 'none' }}
            onChange={imageUploadEvent}
            ref={node => {
              imageUploadNode.dom = node;
            }}
          />
          {allowAvatarEdit &&
            (state.avatar ? (
              <Dropdown
                trigger={
                  <Button
                    id='profile-change-remove-picture-button'
                    circular
                    icon='plus'
                    size='tiny'
                    className='addButton care-blue'
                  />
                }
                icon=''
              >
                <Dropdown.Menu>
                  <Dropdown.Item onClick={clickUpdate}>Change picture</Dropdown.Item>
                  <Dropdown.Item onClick={removeAvatar}>Remove picture</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Button
                id='profile-change-remove-picture-button'
                circular
                icon='plus'
                // size='tiny'
                className='addButton care-blue'
                onClick={clickUpdate}
              />
            ))}
          {state &&
            (loading ? (
              <Image src='/loader_anim.gif' circular />
            ) : (
              <Image src={avatarUrl(state.avatar, state.gender)} circular />
            ))}
        </Grid.Column>
        <Grid.Column width='12'>
          <Form>
            <Form.Group inline>
              <Form.Input
                id='profile-first-name-input'
                error={!firstNameValid}
                label='First Name'
                placeholder='First name'
                name='firstName'
                value={state.firstName}
                onChange={onChange}
              />
              <Form.Input
                id='profile-last-name-input'
                error={!lastNameValid}
                label='Last Name'
                placeholder='Last name'
                name='lastName'
                value={state.lastName}
                onChange={onChange}
              />
            </Form.Group>
          </Form>
          <span className='role'>
            Role: <span style={{ fontWeight: 'bold' }}>{state.roleName}</span>
          </span>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

ProfileNameAndPic.defaultProps = {
  allowAvatarEdit: null,
};

ProfileNameAndPic.propTypes = {
  loading: PropTypes.bool.isRequired,
  state: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  imageUploadEvent: PropTypes.func.isRequired,
  removeAvatar: PropTypes.func.isRequired,
  allowAvatarEdit: PropTypes.bool,
};

const ProfileDetails = ({ isUserAdmin, state, onChange }) => {
  const phones = state.phones
    ? state.phones.map((v, i) => {
        const title = PhoneTypes(v);

        return (
          <Grid.Row key={v.id ? `id_${v.id}` : `index_${i}_${v.type}`}>
            <Grid.Column width={5}>
              <Header style={{ marginTop: '10px' }} size='tiny'>
                {title}
              </Header>
            </Grid.Column>
            <Grid.Column width={11}>
              <Segment basic className='phone-number-display'>
                <span style={{ fontSize: '14px' }}>{v.number}</span>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        );
      })
    : [];

  const emailValid = isValidEmail(state.email);

  return (
    <Grid padded='horizontally' className='detailsBox' style={{ marginBottom: '20px' }}>
      <Grid.Row columns='1' className='headerRow'>
        <Header size='medium'>DETAILS</Header>
      </Grid.Row>
      <Grid.Row columns='2'>
        <Grid.Column>
          <Form>
            <Form.Input
              id='profile-email-input'
              style={{ width: 'calc(100% + 26px' }}
              error={!emailValid}
              label='Email'
              placeholder='Email'
              disabled={!isUserAdmin}
              name='email'
              value={state.email}
              onChange={onChange}
            />
          </Form>
          <Segment basic compact>
            Note that changing the email address will also change the username used to log in.
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment className='profile-admin-phones' basic compact>
            {phones}
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <div className='ui divider' style={{ marginBottom: '0px' }} />
      <Grid.Row>
        <Grid.Column style={{ width: '100%' }}>
          <Form>
            <Form.Group widths={2}>
              <Form.Input
                id='profile-meta-unit'
                label='Unit'
                placeholder='Unit'
                disabled={!isUserAdmin}
                name='meta.unit'
                value={state.meta?.unit}
                onChange={onChange}
              />
              <Form.Input
                id='profile-meta-location'
                label='Location'
                placeholder='Location'
                disabled={!isUserAdmin}
                name='meta.location'
                value={state.meta?.location}
                onChange={onChange}
              />
            </Form.Group>
          </Form>
        </Grid.Column>
      </Grid.Row>
      <div className='ui divider' style={{ marginBottom: '0px' }} />
      <Grid.Row>
        <Grid.Column style={{ width: '100%' }}>
          <Form>
            <Form.Group widths='equal'>
              <div className='field'>
                <label htmlFor='roles'>Access Rights</label>
                <Dropdown
                  id='profile-roles-dropdown'
                  name='roles'
                  placeholder='Roles'
                  fluid
                  multiple
                  options={EmployeeAvailableRoles}
                  value={state.roles}
                  disabled={!isUserAdmin}
                  icon={isUserAdmin ? 'dropdown' : false}
                  onChange={onChange}
                />
              </div>
            </Form.Group>
          </Form>
        </Grid.Column>
        {!isUserAdmin && (
          <Grid.Column
            className='note'
            style={{ width: '100%', fontSize: '12px', color: '#a9adb4', marginTop: '10px' }}
          >
            <p>
              As an employee you have access to the tolls within the system but no ability to update
              or manage users
            </p>
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};

ProfileDetails.defaultProps = {
  isUserAdmin: null,
};

ProfileDetails.propTypes = {
  state: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  isUserAdmin: PropTypes.bool,
};

class Profile extends React.Component {
  constructor() {
    super();

    this.toggleAddNewUser = this.toggleAddNewUser.bind(this);

    this.inputValueChange = this.inputValueChange.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.imageUploadEvent = this.imageUploadEvent.bind(this);
    this.removeAvatar = this.removeAvatar.bind(this);
    this.updateVersionNumberAndAvatar = this.updateVersionNumberAndAvatar.bind(this);
  }

  state = {
    employeeId: 'self',
    newEmployeeVisible: false,
    editor: { meta: {} },
    fetchCourseDetails: false,
    userCoursesFetched: false,
    curriculumsPareToPrograms: false,
    programsFetched: false,
    programWithCurriculums: [],
    coursesFiltered: false,
    filterExemptCourses: [],
    courseChecked: [],
  };

  UNSAFE_componentWillMount() {
    const employeeIdFromUrl = this.getURLParams();
    if (employeeIdFromUrl) {
      this.updateEmployeeId(employeeIdFromUrl);
    } else {
      this.updateEmployeeId(
        this.props.location.state && this.props.location.state.employeeId
          ? this.props.location.state.employeeId
          : 'self'
      );
    }
    if (this.props.employee && this.props.employee.employeeEditor) {
      this.updateEmployee(this.props.employee.employeeEditor);
    }
  }

  componentDidMount() {
    this.props.dispatch(employeeActions.getEmployee(this.state.employeeId, true));
    this.props.dispatch(employeeActions.getEmployeeDashboard());
    this.props.dispatch(ELManageActions.clearCurriculumsAndPrograms());
    if (isTrainee(this.props.principal.roles) && !this.props.eLCourses.courses.length) {
      this.props.dispatch(ELCoursesActions.getCoursesList());
    }
  }

  getURLParams = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    return searchParams.get('employeeId');
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.employeeId && this.state.employeeId !== nextProps.employeeId) {
      this.updateEmployeeId(nextProps.employeeId);
      this.props.dispatch(employeeActions.getEmployee(nextProps.employeeId, true));
    }
    if (nextProps.employee && nextProps.employee.employeeEditor && !this.state.editor.modified) {
      this.updateEmployee(nextProps.employee.employeeEditor);
    }
    if (
      nextProps.employee &&
      nextProps.employee.employee &&
      this.props.employee &&
      this.props.employee.employee &&
      (this.props.employee.employee.version !== nextProps.employee.employee.version ||
        (this.props.employee.employee.avatar &&
          nextProps.employee.employee.avatar &&
          this.props.employee.employee.avatar.url !== nextProps.employee.employee.avatar.url))
    ) {
      this.updateVersionNumberAndAvatar(nextProps.employee);
    }
    if (
      this.props.principal?.roles?.length &&
      nextProps.employee?.employeeEditor?.userId &&
      this.props.employee?.employeeEditor?.userId
    ) {
      if (nextProps.employee.employeeEditor.userId !== this.props.employee.employeeEditor.userId) {
        this.setState({ userCoursesFetched: false, programsFetched: false });
        nextProps.dispatch(ELManageActions.clearCurriculumsAndPrograms());
        if (
          isTrainee(nextProps.employee.employeeEditor.roles) &&
          isTrainee(nextProps.principal.roles)
        ) {
          this.props.dispatch(
            ELUserActions.getUserCoursesList(nextProps.employee.employeeEditor.userId)
          );
          this.setState({ userCoursesFetched: true, coursesFiltered: false });
        }
      }
    }
    if (
      isTrainee(nextProps.employee?.employeeEditor?.roles) &&
      isTrainee(nextProps.principal.roles) &&
      !nextProps.userCourses?.courses?.length &&
      !this.state.userCoursesFetched
    ) {
      this.props.dispatch(
        ELUserActions.getUserCoursesList(nextProps.employee.employeeEditor.userId)
      );
      this.setState({ userCoursesFetched: true, coursesFiltered: false });
    }
    if (
      isTrainee(this.props.principal.roles) &&
      isAdmin(this.props.principal.roles) &&
      !nextProps.eLManage?.traineePrograms?.length &&
      nextProps.employee?.employeeEditor?.userId &&
      !this.state.programsFetched
    ) {
      nextProps.dispatch(
        ELManageActions.getTraineeProgramsAndCurriculums(nextProps.employee?.employeeEditor?.uuid)
      );
      this.setState({ programsFetched: true });
    }
    if (
      nextProps.userCourses?.courses?.length &&
      !this.state.coursesFiltered &&
      !nextProps.userCourses?.loading
    ) {
      const filterExemptCourses = nextProps.userCourses.courses.filter(c => {
        const courseExemption = c.curriculums.map(e => e.exemption);
        const courseExempted = courseExemption.every(ex => ex === true);
        if (!courseExempted) {
          return c;
        }
        return null;
      });
      this.setState({ filterExemptCourses, coursesFiltered: true });
    }
    if (
      this.state.programsFetched &&
      !nextProps.eLManage?.clear &&
      !this.state.curriculumsPareToPrograms
    ) {
      const programWithCurriculums = nextProps.eLManage.traineePrograms.map(p => {
        const curriculums = nextProps.eLManage.traineeCurriculums.filter(
          c => c.clientProgramId === p.id
        );
        return {
          program: p,
          curriculums,
        };
      });
      this.setState({ curriculumsPareToPrograms: true, programWithCurriculums });
    }
  }

  updateEmployeeId(employeeId) {
    this.setState({ employeeId });
  }

  updateVersionNumberAndAvatar(employee) {
    this.setState(s => {
      const newState = { ...s };
      newState.editor.version = employee.employee.version;

      if (employee.employee.avatar) {
        if (newState.editor.avatar) {
          newState.editor.avatar.url = employee.employee.avatar.url;
        } else {
          Object.assign(newState.editor, { avatar: { url: employee.employee.avatar.url } });
        }
      } else {
        delete newState.editor.avatar;
      }

      return newState;
    });
  }

  updateEmployee(employeeEditor) {
    this.setState(s => {
      const newState = { ...s };
      newState.editor = { ...employeeEditor };
      return newState;
    });
  }

  toggleAddNewUser() {
    this.setState(s => ({
      newEmployeeVisible: !s.newEmployeeVisible,
    }));
  }

  inputValueChange(e, input) {
    const keys = input.name.split('.');
    this.setState(s => {
      const newState = { ...s };
      newState.editor = { ...s.editor, modified: true };
      const obj = keys.reduce((val, key, i) => {
        if (i < keys.length - 1) {
          return val[key];
        }
        return val;
      }, newState.editor);
      obj[keys[keys.length - 1]] = input.value;

      return newState;
    });
  }

  saveChanges = () => {
    const { employee } = this.props.employee;
    const employeeId =
      employee && employee.id === this.state.editor.id ? 'self' : this.state.editor.id;
    const { courseChecked } = this.state;
    if (courseChecked.length) {
      this.props.dispatch(ELManageActions.handleCourseExemption(courseChecked));
      this.setState({ courseChecked: [] });
    }
    if (this.state.editor.roles.length > 0) {
      this.props.dispatch(employeeActions.updateEmployee(employeeId, this.state.editor));
      this.setState(s => {
        const newState = { ...s };
        newState.editor = { ...s.editor, modified: false };
        return newState;
      });
    } else {
      this.props.dispatch(alertActions.error('Access rights cannot be empty'));
    }
  };

  handleCourseRequiredChange = (curriculumId, userCourseId, courseCurriculums) => {
    const { courseChecked } = this.state;
    const newCourseChecked = courseChecked;
    const isCourseChecked = newCourseChecked.find(c => c.userCourseId === userCourseId);
    if (isCourseChecked) {
      const coursesCheckedFiltered = newCourseChecked.filter(c => c.userCourseId !== userCourseId);
      return this.setState(s => ({ ...s, courseChecked: coursesCheckedFiltered }));
    }
    const courseExempted = courseCurriculums.filter(c => c.curriculumId === curriculumId)[0];
    const checkedCourse = {
      curriculumId,
      userCourseId,
      exempt: courseExempted.exemption,
    };
    newCourseChecked.push(checkedCourse);
    return this.setState(s => ({ ...s, courseChecked: newCourseChecked }));
  };

  imageUploadEvent(a) {
    this.props.dispatch(employeeActions.uploadAvatar(this.state.editor.userId, a.target.files[0]));
  }

  removeAvatar() {
    this.props.dispatch(employeeActions.removeAvatar(this.state.editor.userId, 'self'));
  }

  render() {
    const { employee, employeeEditor, rootUser, dashboard, loadingAvatar } = this.props.employee;
    const { user, settings, roles } = this.props.principal;
    const {
      newEmployeeVisible,
      editor,
      employeeId,
      filterExemptCourses,
      courseChecked,
      programsListOpen,
      programWithCurriculums,
    } = this.state;

    const { userCourses, eLManage, dispatch } = this.props;

    const elearn = isTrainee(roles);

    const employeeEditorElearn =
      employeeEditor && employeeEditor.roles ? isTrainee(employeeEditor.roles) : false;

    if (!employee && !user) {
      return <span />;
    }

    const welcome = employeeEditor && employeeId === 'self' ? 'Welcome, ' : '';

    let name;
    if (employeeEditor && employee) {
      name = employeeId === 'self' ? employeeEditor.firstName : employeeEditor.employeeName;
    } else {
      name = employeeEditor ? employeeEditor.employeeName : user.firstName;
    }

    const isUserAdmin = isAdmin(roles);

    const saveEnabled =
      (editor.modified &&
        isValidEmail(editor.email) &&
        editor.firstName.length > 1 &&
        editor.lastName.length > 1) ||
      this.state.courseChecked.length;

    return (
      <Grid className='contentGrid' padded>
        <Grid.Column width='three' className='menuCol'>
          <ProfileMenu
            email='care@connect.com'
            isUserAdmin={isUserAdmin}
            isRootAdmin={rootUser && isUserAdmin && !employeeEditor}
            settings={settings}
          />
        </Grid.Column>

        <Grid.Column width='thirteen'>
          <Grid>
            <Grid.Row className='profileWelcomeTop'>
              <Grid.Column width='six' style={{ paddingTop: '3px' }}>
                <span className='welcome'>{welcome}</span>
                <span className='name'>{name}</span>
              </Grid.Column>
              <Grid.Column width='ten' textAlign='right'>
                {settings && settings.standaloneMode && isAdmin(roles) && (
                  <span className='add-button-with-label' style={{ paddingRight: 0 }}>
                    <span>Add a New Employee</span>
                    <CareRoundButton
                      id='profile-add-employee-button'
                      content='+'
                      onClick={this.toggleAddNewUser}
                    />
                  </span>
                )}
                {employeeEditor && (
                  <Button
                    style={{ verticalAlign: 'middle' }}
                    size='mini'
                    basic
                    className='saveButton'
                    id='profile-save-button'
                    onClick={this.saveChanges}
                    disabled={!saveEnabled || loadingAvatar}
                    loading={loadingAvatar}
                  >
                    {loadingAvatar ? '' : 'Save Updates'}
                  </Button>
                )}
              </Grid.Column>
            </Grid.Row>

            {rootUser && isUserAdmin && !employeeEditor && (
              <Grid.Row centered>
                <Grid.Column width='eight'>
                  <Segment textAlign='center' className='rootUserInfo'>
                    <Segment basic>
                      <Header>Root Administrator Account</Header>
                    </Segment>
                    <Segment basic>
                      You are a root user. No employee files attached to your account. You still
                      have have administrator rights and you can add and invite new employees to the
                      system.
                    </Segment>
                    <Segment basic>
                      <Button
                        id='profile-root-open-user-directory-button'
                        size='mini'
                        className='care-blue'
                        as={Link}
                        to='/home/userdirectory'
                      >
                        Open user directory
                      </Button>
                    </Segment>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            )}

            {newEmployeeVisible && <EmployeeEditor onCloseClicked={this.toggleAddNewUser} />}

            {employeeEditor && (
              <Grid.Row className='profile' style={{ paddingTop: '0px' }}>
                <Grid.Column width='sixteen'>
                  <Grid padded='vertically'>
                    <Grid.Row>
                      <Grid.Column width='8'>
                        <ProfileNameAndPic
                          loading={loadingAvatar}
                          onChange={this.inputValueChange}
                          state={editor}
                          allowAvatarEdit={
                            employee && employeeEditor && employee.id === employeeEditor.id
                          }
                          imageUploadEvent={this.imageUploadEvent}
                          removeAvatar={this.removeAvatar}
                        />
                        <ProfileDetails
                          isUserAdmin={isUserAdmin}
                          state={editor}
                          onChange={this.inputValueChange}
                        />
                        {isUserAdmin && elearn && employeeEditorElearn && employeeEditor && (
                          <ProgramTile
                            programWithCurriculums={programWithCurriculums}
                            programsListOpen={programsListOpen}
                            loading={eLManage.loading}
                            agencyPrograms={eLManage.agencyPrograms}
                            disabledPrograms={eLManage.disabledPrograms}
                            disabledCurriculums={eLManage.disabledCurriculums}
                            profileId={employeeEditor.uuid}
                            dispatch={dispatch}
                            programCurriculums={eLManage.programCurriculums}
                            courses={userCourses.courses}
                            coursesLoading={eLManage.coursesLoading}
                            handleCourseRequiredChange={this.handleCourseRequiredChange}
                            courseChecked={courseChecked}
                            updateInProgress={eLManage.updateInProgress}
                            userId={employeeEditor.userId}
                          />
                        )}
                      </Grid.Column>
                      <Grid.Column width='8'>
                        {elearn &&
                          employee &&
                          employeeEditor &&
                          employee.id === employeeEditor.id && (
                            <InProgressCourses courses={this.props.eLCourses.courses} />
                          )}
                        {isUserAdmin && !rootUser && <UserOverView dashboard={dashboard} />}
                        {isUserAdmin && elearn && employeeEditorElearn && employeeEditor && (
                          <ELearningProfileAssignments
                            courses={filterExemptCourses}
                            loading={userCourses.loading}
                          />
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Grid.Column>
      </Grid>
    );
  }
}

Profile.propTypes = {
  dispatch: PropTypes.func.isRequired,
  principal: PropTypes.shape().isRequired,
  employee: PropTypes.shape().isRequired,
  employeeId: PropTypes.string,
  location: PropTypes.shape(),
  eLCourses: PropTypes.shape.isRequired,
  userCourses: PropTypes.shape(),
  eLManage: PropTypes.shape().isRequired,
  updateInProgress: PropTypes.bool.isRequired,
};

Profile.defaultProps = {
  employeeId: null,
  location: {},
  userCourses: {},
};

const mapStateToProps = state => {
  const { principal, employee, eLCourses, userCourses, eLManage } = state;
  return {
    principal,
    employee,
    eLCourses,
    userCourses,
    eLManage,
  };
};

export default connect(mapStateToProps)(Profile);

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Grid, Segment, Header, Modal, Image } from 'semantic-ui-react';
import './Profile.css';

import { employeeActions } from '../actions/employee';
import { isAdmin } from '../constants/DomainTypes';
import { history } from '../helpers';
import CareRoundButton from './subcomponents/CareRoundButton';
import UserDirectoryTable from './subcomponents/UserDirectoryTable';
import EmployeeEditor from './EmployeeEditor';
import { backend } from '../services';

const UserDirectoryMenu = () => (
  <div className='profileMenu'>
    <Header>ADMINISTRATOR</Header>
    <Segment basic className='profileMenuText'>
      As the system administrator you have full access to the CareConnect system to add and manage
      employee profiles and access.
    </Segment>
  </div>
);

UserDirectoryMenu.propTypes = {};

class UserDirectory extends React.Component {
  constructor() {
    super();

    this.toggleAddNewUser = this.toggleAddNewUser.bind(this);
    this.toggleInviteUser = this.toggleInviteUser.bind(this);
    this.clickProfileRow = this.clickProfileRow.bind(this);
  }

  // eslint-disable-next-line react/state-in-constructor
  state = {
    newEmployeeVisible: false,
    inviteEmployeeVisible: false,
    // eslint-disable-next-line react/no-unused-state
    editor: {},
    complianceEnabled: false,
  };

  // eslint-disable-next-line react/no-deprecated
  async UNSAFE_componentWillMount() {
    this.props.dispatch(employeeActions.getEmployeeList());
    const { complianceEnabled } = await backend.getCachedAgencySettings();
    this.setState({ complianceEnabled });
  }

  // eslint-disable-next-line react/sort-comp
  toggleAddNewUser() {
    this.setState(s => ({
      newEmployeeVisible: !s.newEmployeeVisible,
    }));
  }

  toggleInviteUser() {
    this.setState(s => ({
      inviteEmployeeVisible: !s.inviteEmployeeVisible,
    }));
  }

  clickProfileRow = employeeId => {
    history.push('/home/profile', { employeeId });
  };

  render() {
    const { employee, rootUser } = this.props.employee;
    const { user, settings } = this.props.principal;
    const { newEmployeeVisible, inviteEmployeeVisible, complianceEnabled } = this.state;

    const isUserAdmin = rootUser || (employee && isAdmin(employee.roles));

    if (!isUserAdmin || (!employee && !user)) {
      return <span />;
    }

    return (
      <Grid className='contentGrid' padded>
        <Grid.Column width='three' className='menuCol'>
          <UserDirectoryMenu />
        </Grid.Column>

        <Grid.Column width='thirteen'>
          <Grid>
            <Grid.Row className='profileTop'>
              <Grid.Column width='six'>
                <Header floated='left' as='h3'>
                  USER DIRECTORY
                </Header>
              </Grid.Column>
              <Grid.Column width='ten' textAlign='right'>
                <span>
                  Invite employee
                  <CareRoundButton
                    className='green-button'
                    content='+'
                    onClick={this.toggleInviteUser}
                  />
                </span>
                {(settings?.standaloneMode || complianceEnabled) &&
                  (rootUser || (employee && isAdmin(employee.roles))) && (
                    <span className='add-button-with-label' style={{ paddingRight: 0 }}>
                      <span>Add a New Employee</span>
                      <CareRoundButton content='+' onClick={this.toggleAddNewUser} />
                    </span>
                  )}
              </Grid.Column>
            </Grid.Row>

            {inviteEmployeeVisible && (
              <Modal
                closeOnDimmerClick={false}
                open
                size='large'
                onClose={this.toggleInviteUser}
                closeIcon={<Image className='user-modal-close-icon' src='/icon_close.svg' />}
                dimmer
                className='inviteModal'
                style={{ top: '15%' }}
              >
                <UserDirectoryTable id='user-directory-table' inviteMode />
              </Modal>
            )}
            {newEmployeeVisible && <EmployeeEditor onCloseClicked={this.toggleAddNewUser} />}

            <Grid.Row className='profile'>
              <Grid.Column width='sixteen'>
                <UserDirectoryTable
                  id='usersTable'
                  inviteMode={false}
                  clickProfileRow={this.clickProfileRow}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid>
    );
  }
}

UserDirectory.propTypes = {
  dispatch: PropTypes.func.isRequired,
  principal: PropTypes.shape().isRequired,
  employee: PropTypes.shape().isRequired,
};

const mapStateToProps = state => {
  const { principal, employee } = state;
  return {
    principal,
    employee,
  };
};

export default connect(mapStateToProps)(UserDirectory);

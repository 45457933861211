import {
  UPDATE_CAREGIVER_COMPLIANCE_UI,
  CLEAR_CAREGIVER_COMPLIANCE_UI,
} from '../../constants/ActionTypes';

const initialState = {
  dirtyFields: [],
  dirtyMedicalBlocks: [],
  activeTab: 0,
  dirtyTrainingItems: [],
  undoneDraftTrainingItem: null,
  draftError: false,
  isNotValid: false,
  notValidValuesIds: [],
};
const startState = JSON.parse(JSON.stringify(initialState));

function caregiverComplianceUI(state = startState, action) {
  switch (action.type) {
    case UPDATE_CAREGIVER_COMPLIANCE_UI:
      return { ...state, ...action.state };
    case CLEAR_CAREGIVER_COMPLIANCE_UI: {
      return { ...JSON.parse(JSON.stringify(initialState)) };
    }
    default:
      return state;
  }
}

export default caregiverComplianceUI;

/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { Component } from 'react';
import URLSearchParams from 'url-search-params';
import DatePicker from 'react-datepicker';
import { Grid, Header, Segment, Form, Image } from 'semantic-ui-react';
import moment from 'moment';
import { connect } from 'react-redux';
import { alertActions, userActions } from '../actions';
import { backend } from '../services';
import { LinkButton } from '../styles/common';
import TermsAndConditions, { ITerms } from './TermsAndConditions';

const DOB_FORMAT = 'YYYY-MM-DD';

const ERROR_STATUSES: Array<number> = [4100, 4004, 4000];

interface Props {
  location: { [key: string]: string; search: string };
  dispatch: (e: any) => void;
  loading: boolean;
  alertMsg?: string;
  pendingTerms: boolean;
  terms: ITerms;
  preLogin: boolean;
}

interface UserParams {
  email: string;
  token: string;
  type: string;
}

interface State {
  expiredToken: boolean;
  linkReSend: boolean;
  userParams: UserParams;
  dob?: any;
  pendingTerms: boolean;
}

class CaregiverSignUp extends Component<Props, State> {
  public state: State = {
    linkReSend: false,
    expiredToken: false,
    userParams: {
      email: '',
      token: '',
      type: '',
    },
    dob: null,
    pendingTerms: false,
  };

  public componentDidMount(): void {
    this.getURLParams();
  }

  public static getDerivedStateFromProps(nextProps: Props, prevState: State): State | null {
    if (nextProps.pendingTerms && !prevState.pendingTerms) {
      return { ...prevState, pendingTerms: true };
    }
    return null;
  }

  private readonly verifyTokenStatus = async (verificationToken: string): Promise<void | null> => {
    const tokenStatus = await backend.getTokenStatus(verificationToken);

    if (ERROR_STATUSES.includes(tokenStatus)) {
      return this.setState(s => ({
        ...s,
        expiredToken: true,
      }));
    }
    return null;
  };

  private readonly getURLParams = (): void => {
    const { location } = this.props;
    const { search } = location;
    const searchParams = new URLSearchParams(search);
    const magicToken = searchParams.get('magicToken') || '';
    const verificationToken = searchParams.get('verificationToken') || '';
    const email = searchParams.get('email') || '';

    this.verifyTokenStatus(verificationToken || magicToken);

    const type = verificationToken ? 'sign-up' : 'login';

    const newUserParams = {
      email,
      token: verificationToken || magicToken,
      type,
    };

    return this.setState(s => ({ ...s, userParams: newUserParams }));
  };

  private readonly onValueChange = (e: any) => {
    const newDob = e ? moment(e) : null;
    this.setState(s => ({ ...s, dob: newDob }));
  };

  private readonly handleSignUp = (): void => {
    const { dispatch } = this.props;
    const { userParams, dob } = this.state;

    this.verifyTokenStatus(userParams?.token);

    dispatch(alertActions.clearErrors());
    return userParams?.type === 'sign-up'
      ? dispatch(userActions.signupV2(userParams?.token, moment(dob).format(DOB_FORMAT)))
      : dispatch(userActions.magicLogin(userParams?.token, moment(dob).format(DOB_FORMAT)));
  };

  private readonly handleSetPreLogin = (): void => {
    const { preLogin, dispatch } = this.props;
    const { userParams, dob } = this.state;

    dispatch(alertActions.clearErrors());
    if (userParams?.type === 'sign-up') {
      dispatch(
        userActions.signupV2(userParams?.token, moment(dob).format(DOB_FORMAT), preLogin, true)
      );
    } else {
      dispatch(
        userActions.magicLogin(userParams?.token, moment(dob).format(DOB_FORMAT), preLogin, true)
      );
    }
  };

  private readonly requestNewLink = () => {
    const { dispatch } = this.props;
    const { userParams } = this.state;
    dispatch(userActions.getMagicLogin(userParams?.email));
    this.setState(s => ({ ...s, linkReSend: true }));
  };

  public render(): JSX.Element {
    const { loading, alertMsg, terms } = this.props;
    const { dob, expiredToken, linkReSend, userParams, pendingTerms } = this.state;

    return (
      <div className='login-form'>
        <Grid centered textAlign='center'>
          <Grid.Row>
            <Grid.Column width='three' verticalAlign='top'>
              <Header as='h2'>
                <Image src='/logo_login.svg' />
              </Header>
            </Grid.Column>
          </Grid.Row>

          <div style={{ display: 'grid', placeItems: 'center' }}>
            {alertMsg && (
              <Segment
                id='loginAlert'
                basic
                className='error'
                style={{ maxWidth: '600px', margin: '0' }}
              >
                {alertMsg}
              </Segment>
            )}
            {pendingTerms && (
              <div
                className='magicLinkBox'
                style={{ marginTop: '20px', overflow: 'visible', maxWidth: '659px' }}
                id='loginContent'
              >
                <TermsAndConditions terms={terms} submit={this.handleSetPreLogin} />
                <Image src='/circlesLogin.svg' fluid className='magicLinkImageFooter' />
              </div>
            )}
            {!expiredToken && !pendingTerms && (
              <div
                className='magicLinkBox'
                style={{ marginTop: '20px', overflow: 'visible' }}
                id='loginContent'
              >
                <Header style={{ letterSpacing: '0.05em' }}>Verify Your Identity</Header>
                <img
                  alt='login avatar'
                  src='/missingLoginAvatar.svg'
                  style={{ width: '80px', height: '80px' }}
                />
                <p style={{ padding: '1em', fontSize: '12px', fontWeight: 'bold' }}>
                  Please enter your date of birth
                </p>
                <Form
                  loading={loading}
                  onSubmit={this.handleSignUp}
                  style={{ marginLeft: 'auto', marginRight: 'auto', width: 'fit-content' }}
                >
                  <DatePicker
                    customInput={
                      <Form.Input
                        id='magiclogin-dob-input'
                        name='dobInput'
                        placeholder='MM/DD/YYYY'
                        onChange={this.onValueChange}
                      />
                    }
                    className='hideLabel'
                    placeholderText='Enter Birthday'
                    name='dob'
                    id='userBirthDate'
                    selected={dob}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    onChange={this.onValueChange}
                    dateFormat='MM/DD/YYYY'
                    required
                  />
                  <Form.Button
                    id='loginRecoveryContinueButton'
                    className='care-blue'
                    primary
                    style={{ marginTop: '20px' }}
                    content='Submit'
                    disabled={!dob}
                  />
                </Form>

                <Image src='/circlesLogin.svg' fluid className='magicLinkImageFooter' />
              </div>
            )}
            {expiredToken && !linkReSend && !pendingTerms && (
              <div className='magicLinkBox' style={{ marginTop: '20px' }} id='loginContent'>
                <Header style={{ letterSpacing: '0.05em' }}>
                  {`This ${
                    userParams?.type === 'sign-up' ? 'Invitation' : 'Log In'
                  } Link Has Expired`}
                </Header>
                <img
                  alt='new Link Has expired'
                  src='/expiredLinkImage.svg'
                  style={{ width: '75px', height: '75px', margin: '1em' }}
                />
                <p style={{ padding: '1em', fontSize: '12px', fontWeight: 'bold' }}>
                  {`${
                    userParams?.type === 'sign-up' ? 'Invitation' : 'Log In'
                  } links must be activated within 5 days`}
                </p>
                <LinkButton fontSize='14px' fontWeight='bold' onClick={this.requestNewLink}>
                  Request a new link
                </LinkButton>
                <Image src='/circlesLogin.svg' fluid className='magicLinkImageFooter' />
              </div>
            )}
            {linkReSend && !pendingTerms && (
              <div className='magicLinkBox' style={{ marginTop: '20px' }} id='loginContent'>
                <Header style={{ letterSpacing: '0.05em' }}>
                  {`Your ${
                    userParams?.type === 'sign-up' ? 'Invitation' : 'Log In'
                  } Link Has Been Sent`}
                </Header>
                <p style={{ fontSize: '12px', color: '#04A5D5' }}>{userParams?.email}</p>
                <img
                  alt='new Link Has expired'
                  src='/linkSend.svg'
                  style={{ width: '75px', height: '75px', margin: '1em' }}
                />
                <p style={{ padding: '1em', fontSize: '12px', fontWeight: 'bold' }}>
                  Check your email and follow the instructions to
                  <br /> create your new account password.
                </p>
                <p style={{ padding: '1em', fontSize: '12px', color: '#9B9B9B' }}>
                  If no email is received within ten minutes, check that
                  <br /> the submitted address is correct
                </p>
                <Image src='/circlesLogin.svg' fluid className='magicLinkImageFooter' />
              </div>
            )}
          </div>
          <Grid.Row />
          <Grid.Row className='footer' verticalAlign='bottom'>
            <Image fluid src='/login_footer.svg' />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { alert, principal } = state;
  const lastAlert = alert ? alert[alert.length - 1] : null;
  const { loading, pendingTerms, terms, preLogin } = principal;

  let alertMsg;
  if (lastAlert) {
    switch (true) {
      case lastAlert.httpStatus === 404:
        alertMsg = 'The account was not found';
        break;
      case lastAlert.httpStatus === 409:
        alertMsg = 'Incorrect date of birth';
        break;
      default:
        alertMsg = 'Failed to handle your request';
    }
  }

  return {
    alertMsg,
    loading,
    pendingTerms,
    terms,
    preLogin,
  };
};

export default connect(mapStateToProps)(CaregiverSignUp);

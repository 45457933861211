import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';
import { RenderHeaderCell, Sorted, formatDate } from './ReportTableHelper';

type Props = {
  data: Array<Course>;
  sortClick(column: string, sorting: Sorted): void;
};

interface Course {
  firstName: string;
  lastName: string;
  profileUuid: string;
  courseName: string;
  dueDate: string;
  completionDate: string;
  enrolledAt: string;
  startedAt: string;
  lastAccessedAt: string;
  lastAccessedModule: string;
  score: number;
  status: string;
  canReAttempt: boolean;
  percentageComplete: number;
  agencyName: string;
  language: string;
  programName: string;
  courseLength: number;
  attemptCount: number;
  maxAttemptCount: number;
  externalId: string;
}

const UtilizationReportTable: React.FC<Props> = ({ data, sortClick }) => {
  const [sortedHeader, setSortedHeader] = useState('user');
  const [sorting, setSorting] = useState<Sorted>('descending');

  const swapSorting = () => {
    const newSort = sorting === 'ascending' ? 'descending' : 'ascending';
    setSorting(newSort);
    return sortClick(sortedHeader, newSort);
  };

  const onClickHeader = (column: string): void => {
    if (sortedHeader === column) {
      return swapSorting();
    }
    setSortedHeader(column);
    setSorting('descending');
    return sortClick(column, 'descending');
  };

  const header = [
    RenderHeaderCell({
      id: 'el-util-report-name',
      key: 'user',
      width: '3',
      textAlign: 'left',
      content: 'User',
      onClick: () => onClickHeader('user'),
      sorted: sortedHeader === 'user' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-util-report-course-name',
      key: 'courseName',
      width: '3',
      content: 'Course Name',
      onClick: () => onClickHeader('courseName'),
      sorted: sortedHeader === 'courseName' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-util-report-due-date',
      key: 'dueDate',
      width: '2',
      content: 'Due Date',
      onClick: () => onClickHeader('dueDate'),
      sorted: sortedHeader === 'dueDate' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-util-report-enrolled',
      key: 'enrolledAt',
      width: '2',
      content: 'Enrolled',
      onClick: () => onClickHeader('enrolledAt'),
      sorted: sortedHeader === 'enrolledAt' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-util-report-started',
      key: 'startedAt',
      width: '2',
      content: 'Started',
      onClick: () => onClickHeader('startedAt'),
      sorted: sortedHeader === 'startedAt' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-util-report-last-accessed',
      key: 'lastAccessedAt',
      width: '2',
      content: 'Last Accessed',
      onClick: () => onClickHeader('lastAccessedAt'),
      sorted: sortedHeader === 'lastAccessedAt' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-util-report-last-module',
      key: 'lastAccessedModule',
      width: '3',
      content: 'Last Accessed Module',
      onClick: () => onClickHeader('lastAccessedModule'),
      sorted: sortedHeader === 'lastAccessedModule' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-util-report-completed',
      key: 'completedAt',
      width: '3',
      content: 'Completed',
      onClick: () => onClickHeader('completedAt'),
      sorted: sortedHeader === 'completedAt' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-util-report-status',
      key: 'status',
      width: '2',
      content: 'Status',
      onClick: () => onClickHeader('status'),
      sorted: sortedHeader === 'status' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-util-report-percentage-completed',
      key: 'percentageCompleted',
      width: '3',
      content: 'Percentage Completed',
      onClick: () => onClickHeader('percentageCompleted'),
      sorted: sortedHeader === 'percentageCompleted' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-util-report-language',
      key: 'language',
      width: '3',
      content: 'Language',
      onClick: () => onClickHeader('language'),
      sorted: sortedHeader === 'language' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-util-report-program',
      key: 'programName',
      width: '3',
      content: 'Program Name',
      onClick: () => onClickHeader('programName'),
      sorted: sortedHeader === 'programName' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-util-report-length',
      key: 'courseLength',
      width: '2',
      content: 'Course Length (mins.)',
      onClick: () => onClickHeader('courseLength'),
      sorted: sortedHeader === 'courseLength' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-util-report-attempts',
      key: 'attempts',
      width: '2',
      content: 'Attempts',
      onClick: () => onClickHeader('attemptCount'),
      sorted: sortedHeader === 'attemptCount' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-util-report-external-id',
      key: 'externalId',
      width: '2',
      content: 'External ID',
      onClick: () => onClickHeader('externalId'),
      sorted: sortedHeader === 'externalId' ? sorting : undefined,
    }),
  ];

  const displayDataOrNull = (givenData: string | number): string => {
    return `${givenData || '--'}`;
  };

  return (
    <Table
      id='e-learning-utilization-report-table'
      className='e-learning-reports-table'
      tableData={data}
      headerRow={header}
      padded
      striped
      compact
      sortable
      textAlign='center'
      renderBodyRow={(x: Course, i: number) => ({
        key: `${x.profileUuid}${x.courseName}${x.lastAccessedAt}${i}`,
        cells: [
          {
            key: 'name',
            content: `${x.firstName} ${x.lastName}`,
            singleLine: true,
            textAlign: 'left',
          },
          { key: 'courseName', content: x.courseName, singleLine: true, textAlign: 'left' },
          { key: 'dueDate', content: displayDataOrNull(formatDate(x.dueDate)), singleLine: true },
          // { key: 'expiredAt', content: formatDate(x.), singleLine: true },
          {
            key: 'enrolledAt',
            content: displayDataOrNull(formatDate(x.enrolledAt)),
            singleLine: true,
          },
          {
            key: 'startedAt',
            content: displayDataOrNull(formatDate(x.startedAt)),
            singleLine: true,
          },
          {
            key: 'lastAccessedAt',
            content: displayDataOrNull(formatDate(x.lastAccessedAt)),
            singleLine: true,
          },
          {
            key: 'lastAccessedModule',
            content: displayDataOrNull(x.lastAccessedModule),
            singleLine: true,
          },
          {
            key: 'completedAt',
            content: displayDataOrNull(formatDate(x.completionDate)),
            singleLine: true,
          },
          { key: 'status', content: displayDataOrNull(x.status), singleLine: true },
          {
            key: 'percentageCompleted',
            content: `${x.percentageComplete ? x.percentageComplete * 100 : 0}`,
            singleLine: true,
          },
          { key: 'language', content: displayDataOrNull(x.language), singleLine: true },
          { key: 'programName', content: displayDataOrNull(x.programName), singleLine: true },
          { key: 'courseLength', content: displayDataOrNull(x.courseLength), singleLine: true },
          {
            key: 'attempts',
            content: `${x.attemptCount} / ${x.maxAttemptCount}`,
            singleLine: true,
          },
          {
            key: 'externalId',
            content: displayDataOrNull(x.externalId),
            singleLine: true,
          },
        ],
      })}
    />
  );
};

export default UtilizationReportTable;

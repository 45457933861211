/* eslint-disable import/prefer-default-export */
import * as types from '../constants/ActionTypes';
import { backend } from '../services';
import { alertActions } from './alert';

function getAgencySettings() {
  return async dispatch => {
    dispatch(request());

    try {
      const settings = await backend.getAgencySettings(true);
      dispatch(success(settings));
    } catch (error) {
      dispatch(failure(error));
    }
  };

  function request() {
    return { type: types.GET_AGENCY_SETTINGS_REQUEST };
  }
  function success(agencySettings) {
    return { type: types.GET_AGENCY_SETTINGS_SUCCESS, agencySettings };
  }
  function failure(error) {
    return { type: types.GET_AGENCY_SETTINGS_FAILURE, error };
  }
}

function updateAgencySettings(fields) {
  return async dispatch => {
    dispatch(request());

    try {
      const settings = await backend.updateAgencySettings(fields);
      dispatch(success(settings));
      dispatch(alertActions.notification('System settings saved'));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: types.UPDATE_AGENCY_SETTINGS_REQUEST };
  }
  function success(agencySettings) {
    return { type: types.UPDATE_AGENCY_SETTINGS_SUCCESS, agencySettings };
  }
  function failure(error) {
    return { type: types.UPDATE_AGENCY_SETTINGS_FAILURE, error };
  }
}

export const agencySettingsActions = {
  getAgencySettings,
  updateAgencySettings,
};

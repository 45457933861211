import {
  GET_SHIFT_REQUEST,
  GET_SHIFT_SUCCESS,
  GET_SHIFT_FAILURE,
  UPDATE_SHIFT_REQUEST,
  UPDATE_SHIFT_SUCCESS,
  UPDATE_SHIFT_FAILURE,
  UPDATE_SHIFT_WITH_LIST_CAREGIVERS_REQUEST,
  UPDATE_SHIFT_WITH_LIST_CAREGIVERS_SUCCESS,
  UPDATE_SHIFT_WITH_LIST_CAREGIVERS_FAILURE,
} from '../constants/ActionTypes';

import { prepareShiftObject } from './helpers';

const initialState = {
  loading: false,
  shift: null,
};

function shiftDetails(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SHIFT_WITH_LIST_CAREGIVERS_REQUEST:
    case UPDATE_SHIFT_REQUEST:
    case GET_SHIFT_REQUEST:
      return { ...state, loading: true, shift: null };

    case UPDATE_SHIFT_WITH_LIST_CAREGIVERS_SUCCESS:
    case UPDATE_SHIFT_SUCCESS: {
      const shift = prepareShiftObject(action.results.profile);
      return { ...state, loading: false, shift };
    }
    case GET_SHIFT_SUCCESS: {
      const shift = prepareShiftObject(action.results.shift);
      return { ...state, loading: false, shift };
    }

    case UPDATE_SHIFT_WITH_LIST_CAREGIVERS_FAILURE:
    case UPDATE_SHIFT_FAILURE:
    case GET_SHIFT_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export default shiftDetails;

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, Header } from 'semantic-ui-react';
import { RecordsTile, RecordsHeader } from '../../styles/records';
import '../TrainPref.css';

let changedRates = {};

class RateSettings extends React.Component {
  constructor(props) {
    super(props);

    this.onValueChanged.bind(this);
  }

  onValueChanged = (name, value) => {
    changedRates[name] = true;
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onValueUpdated(name, value);
  };

  render() {
    const {
      hourlyTravelTimePayRate,
      publicTransitPayRate,
      milePayRate,
      minWage,
      liveInEquivalency,
      dirty,
    } = this.props;

    const rateBody =
      'Set your default rate values for costs incurred by the agency for overages and penalties';

    if (!dirty) changedRates = {};

    return (
      <RecordsTile className='rate-setting-tile'>
        <Form>
          <Grid padded className='col-trainings-preferences'>
            <Grid.Row>
              <Grid.Column width={16}>
                <RecordsHeader>Default Rates</RecordsHeader>
              </Grid.Column>
            </Grid.Row>
            <hr className='rates-hr' />

            <Grid.Row>
              <Grid.Column width={16}>
                <p className='rate-body-text'>{rateBody}</p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row
              className={
                dirty && changedRates.hourlyTravelTimePayRate
                  ? 'table-item-updated default-rate-row'
                  : 'default-rate-row'
              }
            >
              <Grid.Column>
                <Form.Group inline className='rate-form-group-container'>
                  <Header size='tiny' className='rate-body-text'>
                    Average Hourly Commute Time Pay Rate:
                  </Header>
                  <p className='rate-body-text rate-symbol'> $ </p>
                  <Form.Input
                    id='rate-settings-hourly-commute-time'
                    className='rate-input-field'
                    placeholder='Rate'
                    name='hourlyTravelTimePayRate'
                    type='number'
                    value={hourlyTravelTimePayRate}
                    onChange={(_, { value }) =>
                      this.onValueChanged('hourlyTravelTimePayRate', value)
                    }
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row
              className={
                dirty && changedRates.publicTransitPayRate
                  ? 'table-item-updated default-rate-row'
                  : 'default-rate-row'
              }
            >
              <Grid.Column>
                <Form.Group inline className='rate-form-group-container'>
                  <Header size='tiny' className='rate-body-text'>
                    Average Per Trip Public Transit Pay Rate:
                  </Header>
                  <p className='rate-body-text rate-symbol'> $ </p>
                  <Form.Input
                    id='rate-settings-public-transit'
                    className='rate-input-field'
                    placeholder='Rate'
                    name='publicTransitPayRate'
                    type='number'
                    value={publicTransitPayRate}
                    onChange={(_, { value }) => this.onValueChanged('publicTransitPayRate', value)}
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row
              className={
                dirty && changedRates.milePayRate
                  ? 'table-item-updated default-rate-row'
                  : 'default-rate-row'
              }
            >
              <Grid.Column>
                <Form.Group inline className='rate-form-group-container'>
                  <Header size='tiny' className='rate-body-text'>
                    Average Per Mile Pay Rate:
                  </Header>
                  <p className='rate-body-text rate-symbol'> $ </p>
                  <Form.Input
                    id='rate-settings-mile'
                    className='rate-input-field'
                    placeholder='Rate'
                    name='milePayRate'
                    type='number'
                    value={milePayRate}
                    onChange={(_, { value }) => this.onValueChanged('milePayRate', value)}
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row
              className={
                dirty && changedRates.minWage
                  ? 'table-item-updated default-rate-row'
                  : 'default-rate-row'
              }
            >
              <Grid.Column>
                <Form.Group inline className='rate-form-group-container'>
                  <Header size='tiny' className='rate-body-text'>
                    Minimum Wage per Hour:
                  </Header>
                  <p className='rate-body-text rate-symbol'> $ </p>
                  <Form.Input
                    id='rate-settings-min-wage'
                    className='rate-input-field'
                    placeholder='Minimum Wage'
                    name='minWage'
                    type='number'
                    value={minWage}
                    onChange={(_, { value }) => this.onValueChanged('minWage', value)}
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row
              className={
                dirty && changedRates.liveInEquivalency
                  ? 'table-item-updated default-rate-row'
                  : 'default-rate-row'
              }
            >
              <Grid.Column>
                <Form.Group inline className='rate-form-group-container'>
                  <Header size='tiny' className='rate-body-text'>
                    Live-In Equivalency:
                  </Header>
                  <p className='rate-body-text rate-symbol'> Hours </p>
                  <Form.Input
                    id='rate-settings-min-wage-live-in'
                    className='rate-input-field'
                    placeholder='Live-In Equivalency'
                    name='liveInEquivalency'
                    type='number'
                    value={liveInEquivalency}
                    onChange={(_, { value }) => this.onValueChanged('liveInEquivalency', value)}
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </RecordsTile>
    );
  }
}

RateSettings.defaultProps = {
  hourlyTravelTimePayRate: null,
  publicTransitPayRate: null,
  milePayRate: null,
  minWage: null,
  liveInEquivalency: null,
  dirty: false,
};

RateSettings.propTypes = {
  onValueUpdated: PropTypes.func.isRequired,
  hourlyTravelTimePayRate: PropTypes.number,
  publicTransitPayRate: PropTypes.number,
  milePayRate: PropTypes.number,
  minWage: PropTypes.number,
  liveInEquivalency: PropTypes.number,
  dirty: PropTypes.bool,
};

export default RateSettings;

import React from 'react';
import PropTypes from 'prop-types';

const CareRoundButton = ({ content, ...others }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div className='care-round-button' {...others}>
    <p>{content}</p>
  </div>
);

CareRoundButton.defaultProps = {
  content: '',
  others: undefined,
};

CareRoundButton.propTypes = {
  content: PropTypes.string,
  others: PropTypes.any, // eslint-disable-line react/forbid-prop-types
};

export default CareRoundButton;

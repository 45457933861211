/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Dropdown, DropdownProps, Input } from 'semantic-ui-react';
import { ProfileMenu } from './Profile';
import { isAdmin } from '../constants/DomainTypes';
import { Settings, Roles, User } from '../reducers/types/principal';
import { alertActions } from '../actions/alert';
import './Exports.css';
import { GreenButton } from '../styles/common';
import { backend } from '../services';

type DropdownTypes = string | number | boolean | (string | number | boolean)[] | undefined;

interface StatusValues {
  key: string;
  text: string;
  value: string;
}

type Statuses = Array<StatusValues>;

interface Props {
  roles: Roles;
  settings: Settings;
  employee: { rootUser: boolean; [key: string]: any };
  user: User;
  dispatch: (arg: any) => void;
}

interface State {
  fileType: DropdownTypes;
  statuses: DropdownTypes;
  beforeDate: any;
  afterDate: any;
  loadingExport: boolean;
}

const FILE_TYPES = [
  { text: 'Caregiver', value: 'caregiver' },
  { text: 'Employee', value: 'employee' },
  { text: 'Client', value: 'client' },
  { text: 'Shift', value: 'shift' },
];

const DATE_FORMAT = 'YYYY-MM-DD';

class Exports extends PureComponent<Props, State> {
  public state = {
    fileType: 'caregiver',
    statuses: [],
    beforeDate: null,
    afterDate: null,
    loadingExport: false,
  };

  private readonly getStatus = (): Statuses => {
    const { fileType } = this.state;

    switch (fileType) {
      case 'caregiver':
      case 'employee':
      case 'client':
        return [
          { key: 'Pending', text: 'Pending', value: '01' },
          { key: 'Active', text: 'Active', value: '02' },
          { key: 'On Hold', text: 'On Hold', value: '03' },
          { key: 'Inactive', text: 'Inactive', value: '04' },
          { key: 'Cancelled', text: 'Cancelled', value: '05' },
        ];
      case 'shift':
        return [
          { key: 'Pending', text: 'Pending', value: '01' },
          { key: 'Confirmed', text: 'Confirmed', value: '02' },
          { key: 'In Process', text: 'In Process', value: '03' },
          { key: 'Closed', text: 'Closed', value: '04' },
          { key: 'On Hold', text: 'On Hold', value: '09' },
          { key: 'Cancelled', text: 'Cancelled', value: '10' },
        ];
      default:
        return [{ key: '', text: '', value: '' }];
    }
  };

  private readonly onFileTypeChange = (_e: any, input: DropdownProps): void => {
    this.setState(s => ({
      ...s,
      fileType: input.value,
      statuses: [],
    }));
  };

  private readonly onStatusChange = (_e: any, input: DropdownProps): void => {
    this.setState(s => ({
      ...s,
      statuses: input.value,
    }));
  };

  private readonly onDateChanged = (date: any, selected: string): void => {
    if (selected === 'after') {
      return this.setState(s => ({
        ...s,
        afterDate: date ? moment(date).startOf('day') : undefined,
      }));
    }
    return this.setState(s => ({ ...s, beforeDate: date ? moment(date).endOf('day') : undefined }));
  };

  private readonly exportFile = async (): Promise<any> => {
    const { fileType, statuses, afterDate, beforeDate } = this.state;
    const { user, dispatch } = this.props;
    const params = {
      profileType: fileType,
      userUuid: user.uuid,
      statuses: statuses.length ? statuses : undefined,
      modifiedAfter: moment(afterDate || undefined).format(DATE_FORMAT),
      modifiedBefore: moment(beforeDate || undefined).format(DATE_FORMAT),
    };
    this.setState(s => ({ ...s, loadingExport: true }));
    try {
      const link = document.createElement('a');
      const exportLink = await backend.getExportFile(params);
      link.href = exportLink.response.downloadLink;
      link.download = `${fileType}` || 'untitled';
      link.dispatchEvent(new MouseEvent('click'));
      return this.setState(s => ({ ...s, loadingExport: false }));
    } catch (error) {
      this.setState(s => ({ ...s, loadingExport: false }));
      return dispatch(alertActions.error(error));
    }
  };

  public render(): JSX.Element {
    const { employee, roles, settings } = this.props;
    const { rootUser } = employee;
    const isUserAdmin = rootUser || isAdmin(roles);
    const { fileType, statuses, afterDate, beforeDate, loadingExport } = this.state;

    return (
      <div className='exports-main'>
        <div className='contentGrid menuCol exports-profiles'>
          <ProfileMenu
            email='care@connect.com'
            isUserAdmin={isUserAdmin}
            isRootAdmin={rootUser && isUserAdmin}
            settings={settings}
          />
        </div>
        <div className='exports-header'>EXPORTS</div>
        <div className='exports-filters'>
          <div>
            <p>File Type:</p>
            <Dropdown
              id='exportFileTypeDropdown'
              selection
              name='exportFileType'
              options={FILE_TYPES}
              onChange={this.onFileTypeChange}
              value={fileType}
            />
          </div>
          <div>
            <p>Status:</p>
            <Dropdown
              id='exportStatusDropdown'
              selection
              multiple
              name='exportStatuses'
              placeholder='Select a Status'
              options={this.getStatus()}
              onChange={this.onStatusChange}
              value={statuses}
            />
          </div>
          <div>
            <p className='exports-date-picker-label'>Modified After:</p>
            <DatePicker
              id='CertificatesStartDate'
              customInput={
                <Input
                  style={{ width: '120px' }}
                  icon='calendar'
                  value={afterDate}
                  error={!afterDate}
                />
              }
              popperPlacement='top-start'
              selected={afterDate}
              maxDate={beforeDate}
              onChange={(date: any) => this.onDateChanged(date, 'after')}
              dateFormat='MM-DD-YYYY'
            />
          </div>
          <div>
            <p className='exports-date-picker-label'>Modified Before:</p>
            <DatePicker
              id='CertificatesEndDate'
              customInput={<Input style={{ width: '120px' }} icon='calendar' value={beforeDate} />}
              popperPlacement='top-start'
              selected={beforeDate}
              minDate={afterDate}
              onChange={(date: any) => this.onDateChanged(date, 'before')}
              dateFormat='MM-DD-YYYY'
            />
          </div>
          <GreenButton
            style={{ alignSelf: 'flex-end' }}
            loading={loadingExport}
            disabled={loadingExport || !afterDate}
            onClick={this.exportFile}
          >
            Export File
          </GreenButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { employee, principal } = state;
  return {
    principal,
    employee,
    settings: state.principal.settings,
    roles: state.principal.roles,
    user: state.principal.user,
  };
};

export default connect(mapStateToProps)(Exports);

import { SEARCH_CAREGIVERS_SUCCESS } from '../constants/ActionTypes';

import { prepareCaregiverObject } from './helpers';

const initialState = {};

function clientEnvironmentTab(state = initialState, action) {
  switch (action.type) {
    case SEARCH_CAREGIVERS_SUCCESS: {
      const results = action.results
        ? action.results.results.map(x => prepareCaregiverObject(x))
        : [];

      return { ...state, results, tooManyResults: action.tooManyResults };
    }

    default:
      return state;
  }
}

export default clientEnvironmentTab;

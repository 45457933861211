import { IDispatchAction } from '../../actions/hrapp/filterActions';
import { UPDATE_HR_CANDIDATES_FILTER, CLEAN_ALL_HR_FILTERS } from '../../constants/ActionTypes';
import { IHRCandidatesFilter } from '../types/HRFilters';

const initialState: IHRCandidatesFilter = {
  nameOrId: { nameOrId: '' },
  disciplines: { disciplines: [] },
  compliancesIssues: {
    eligibility: [],
    medical: [],
    documentation: [],
    training: [],
  },
  primaryContact: { primaryContact: [] },
  primaryManager: { primaryManager: [] },
};

const startState = JSON.parse(JSON.stringify(initialState));

function HRCandidatesFilter(state = startState, action: IDispatchAction) {
  switch (action.type) {
    case UPDATE_HR_CANDIDATES_FILTER: {
      const newState = { ...state };
      newState[action.changes.section][action.changes.filter] = action.changes.value;
      return newState;
    }
    case CLEAN_ALL_HR_FILTERS: {
      const newState = JSON.parse(JSON.stringify(initialState));
      return newState;
    }
    default:
      return state;
  }
}

export default HRCandidatesFilter;

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable @typescript-eslint/no-this-alias */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import DetailsBox from './DetailsBox';

class CGTileCustomFields extends React.Component {
  constructor() {
    super();
    this.state = { mapVisible: false };
    this.onViewMapClick = this.onViewMapClick.bind(this);
    this.locUpdate = this.locUpdate.bind(this);
  }

  componentDidMount() {
    this.form.formsyForm.setFormPristine(false);
  }

  onViewMapClick() {
    this.setState({ mapVisible: !this.state.mapVisible });
  }

  locUpdate(loc) {
    if (this.googleMapRef) {
      this.googleMapRef.setCenter({ lat: loc.lat, lng: loc.lon });
    }
  }

  render() {
    const { person, intl, onValueChange, onValid, onInvalid } = this.props;

    const caregiver = person;

    const self = this;

    return (
      <DetailsBox
        text={intl.formatMessage({ id: 'records.customFields' }).toUpperCase()}
        icon='/icon_large_contact.svg'
      >
        <Form
          onValid={() => onValid('customFields')}
          onInvalid={() => onInvalid('customFields')}
          size='tiny'
          ref={c => {
            self.form = c;
          }}
        >
          {caregiver.customFields &&
            caregiver.customFields.length > 0 &&
            caregiver.customFields.map((v, i) => {
              const checked = v.value === 'Y';
              return (
                <Grid.Row
                  key={`customField${v.id}`}
                  style={{
                    marginRight: '1rem',
                  }}
                >
                  {i === 0 && <Divider />}
                  <Form.Group>
                    <div
                      style={{
                        marginLeft: '0.5rem',
                        marginRight: '1rem',
                        width: '80%',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                      }}
                    >
                      <label
                        style={{
                          fontSize: '10px',
                          marginLeft: '0.5rem',
                          marginRight: '1rem',
                          color: checked ? 'black' : 'grey',
                        }}
                        htmlFor={`customFields.${i}`}
                      >
                        {v.name}
                      </label>
                    </div>
                    <Form.Checkbox
                      toggle
                      id={`customFields.${i}`}
                      name={`customFields.${i}.value`}
                      defaultChecked={checked}
                      onChange={onValueChange}
                    />
                  </Form.Group>
                  <Divider />
                </Grid.Row>
              );
            })}
        </Form>
      </DetailsBox>
    );
  }
}

CGTileCustomFields.defaultProps = {
  person: {},
  onValid: () => {},
  onInvalid: () => {},
};

CGTileCustomFields.propTypes = {
  person: PropTypes.shape(),
  intl: intlShape.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onValid: PropTypes.func,
  onInvalid: PropTypes.func,
};

export default injectIntl(CGTileCustomFields);

import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';
import { RenderHeaderCell, Sorted, formatDate } from './ReportTableHelper';

type Props = {
  data: Array<Course>;
  sortClick(column: string, sorting: Sorted): void;
};

interface Course {
  firstName: string;
  lastName: string;
  profileUuid: string;
  courseName: string;
  dueDate: string;
  completionDate: string;
  enrolledAt: string;
  startedAt: string;
  lastAccessedAt: string;
  lastAccessedModule: string;
  score: number;
  status: string;
  canReAttempt: boolean;
  percentageComplete: number;
  agencyName: string;
  language: string;
  programName: string;
  courseLength: number;
  attemptCount: number;
  maxAttemptCount: number;
  certificateUrl: string;
  signatureUrl?: string;
}

const CertificatesExportReportTable: React.FC<Props> = ({ data, sortClick }) => {
  const [sortedHeader, setSortedHeader] = useState('user');
  const [sorting, setSorting] = useState<Sorted>('descending');

  const swapSorting = () => {
    const newSort = sorting === 'ascending' ? 'descending' : 'ascending';
    setSorting(newSort);
    return sortClick(sortedHeader, newSort);
  };

  const onClickHeader = (column: string): void => {
    if (sortedHeader === column) {
      return swapSorting();
    }
    setSortedHeader(column);
    setSorting('descending');
    return sortClick(column, 'descending');
  };

  const header = [
    RenderHeaderCell({
      id: 'el-active-user-course-user-name',
      key: 'user',
      width: '3',
      content: 'User',
      textAlign: 'left',
      onClick: () => onClickHeader('user'),
      sorted: sortedHeader === 'user' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-active-user-course-course-name',
      key: 'programName',
      width: '3',
      content: 'Program Name',
      onClick: () => onClickHeader('programName'),
      sorted: sortedHeader === 'programName' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-active-user-course-course-name',
      key: 'courseName',
      width: '6',
      content: 'Course Name',
      onClick: () => onClickHeader('courseName'),
      sorted: sortedHeader === 'courseName' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-active-user-report-completion-date',
      key: 'completionDate',
      width: '3',
      content: 'Completion Date',
      onClick: () => onClickHeader('completionDate'),
      sorted: sortedHeader === 'completionDate' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-active-user-report-documents',
      key: 'documents',
      width: '3',
      content: 'Documents',
      onClick: () => onClickHeader('documents'),
      sorted: sortedHeader === 'documents' ? sorting : undefined,
    }),
  ];

  const getDocumentLinks = (c: Course) => {
    const signature = c.signatureUrl ? (
      <a href={c.signatureUrl} rel='noreferrer noopener' target='_blank'>
        Signature
      </a>
    ) : (
      ''
    );
    const certificate = c.certificateUrl ? (
      <a href={c.certificateUrl} rel='noreferrer noopener' target='_blank'>
        Certificate
      </a>
    ) : (
      ''
    );

    return (
      <span>
        {signature} {certificate}
      </span>
    );
  };

  return (
    <Table
      id='elearningCertificatesReportTable'
      className='e-learning-reports-table'
      tableData={data}
      headerRow={header}
      padded
      striped
      compact
      sortable
      textAlign='center'
      renderBodyRow={(x: Course, i: number) => ({
        key: `${x.profileUuid}${x.courseName}${i}`,
        cells: [
          {
            key: 'user',
            content: `${x.firstName} ${x.lastName}`,
            singleLine: true,
            textAlign: 'left',
          },
          { key: 'programName', content: x.programName, singleLine: true },
          { key: 'courseName', content: x.courseName, singleLine: true },
          {
            key: 'completionDate',
            content: formatDate(x.completionDate),
            singleLine: true,
          },
          {
            key: 'documents',
            content: getDocumentLinks(x),
          },
        ],
      })}
    />
  );
};

export default CertificatesExportReportTable;

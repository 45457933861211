/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Image } from 'semantic-ui-react';
import { avatarUrl } from '../../../constants/DomainTypes';
import { RecordsTile, RecordsHeader } from '../../../styles/records';

const avatarStyle = (avatar, gender, size) => {
  const defaultByGender = () => {
    switch (gender) {
      case 'm':
        return '#04A5D5';
      case 'f':
        return '#F16778';
      case 'u':
        return '#69DB8F';
      default:
        return '#D9D9D9';
    }
  };

  const borderSize = size && size === 'large' ? '5px' : '2px';

  const borderColor = avatar ? defaultByGender() : '#FFFFFF';

  return {
    border: `${borderSize} solid ${borderColor}`,
    width: '4.6875rem!important',
    height: '4.6875rem!important',
  };
};

class DetailsBox extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { text, icon, withoutIcon, person } = this.props;

    return (
      <RecordsTile id='details-box-records-tile'>
        {!withoutIcon && (
          <Grid>
            <Grid.Column width={3}>
              <div className='avatarCgDetails'>
                {icon ? (
                  <Image
                    id='details-box-image-icon'
                    style={{ transform: 'scale(0.8, 0.8)', top: '-1.5em', left: '-1em' }}
                    centered
                    circular
                    size='large'
                    floated='left'
                    src={icon}
                  />
                ) : (
                  <Image
                    id='details-box-image-avatar'
                    avatar
                    centered
                    circular
                    size='large'
                    floated='left'
                    style={avatarStyle(person.avatar, person.gender)}
                    src={avatarUrl(person.avatar, person.gender)}
                  />
                )}
                <br />
              </div>
            </Grid.Column>
            <Grid.Column style={{ left: '-1em' }} width={13}>
              <RecordsHeader id='details-box-header-with-icon'>{text}</RecordsHeader>
              {this.props.children}
            </Grid.Column>
          </Grid>
        )}

        {withoutIcon && (
          <Grid>
            <Grid.Column width={16}>
              <RecordsHeader id='details-box-header-without-icon'>{text}</RecordsHeader>
              {this.props.children}
            </Grid.Column>
          </Grid>
        )}
      </RecordsTile>
    );
  }
}

DetailsBox.defaultProps = {
  text: 'TESTING',
  children: [],
  person: {},
  icon: null,
  withoutIcon: false,
};

DetailsBox.propTypes = {
  person: PropTypes.shape(),
  icon: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  withoutIcon: PropTypes.bool,
};

export default DetailsBox;

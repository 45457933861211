/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import styled from 'styled-components';

interface IProps {
  search: string;
  zip: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onZipChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterContainer = styled.div`
  display: grid;
  align-items: flex-end;
  margin-left: 300px;
  margin-bottom: 2rem;
  grid-template-columns: 200px 1fr;
`;

const SearchInput = styled.input`
  border: none;
  height: 30px;
  background-color: #ffffff;
  padding-left: 10px;
  border-radius: 25px;
  border: 1px solid #e3e3e3;
  width: 200px;
  &::placeholder {
    font-size: 12px;
    font-family: Lato;
    color: #c8cbd3;
  }
  &:focus {
    outline: none;
    background-color: #ffffff;
  }
`;

const ZipInput = styled.input`
  border: none;
  height: 30px;
  background-color: #ffffff;
  padding-left: 10px;
  border-radius: 25px;
  border: 1px solid #e3e3e3;
  width: 100px;
  &::placeholder {
    font-size: 12px;
    font-family: Lato;
    color: #c8cbd3;
  }
  &:focus {
    outline: none;
    background-color: #ffffff;
  }
`;

const ZipContainer = styled.div`
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid #c8cbd3;
`;

const FilterLabel = styled.p`
  margin: 0 0 5px 10px;
  color: #9b9b9b;
  font-size: 12px;
`;

class HRApplicantFilter extends Component<IProps> {
  public render(): JSX.Element {
    const { search, zip, onSearchChange, onZipChange } = this.props;
    return (
      <FilterContainer>
        <SearchInput value={search} placeholder='Name' onChange={onSearchChange} />
        <ZipContainer>
          <FilterLabel>Isolate by Zip</FilterLabel>
          <ZipInput value={zip} placeholder='Zip' onChange={onZipChange} />
        </ZipContainer>
      </FilterContainer>
    );
  }
}

export default HRApplicantFilter;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { getVersion } from './AppVersion';
import './Dashboard.css';

global.appVersion = getVersion();

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

class NewUpdate extends React.Component {
  constructor() {
    super();
    this.state = {
      isNewVersionUpdate: false,
    };
  }

  componentDidMount() {
    fetch('/meta.json')
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          // eslint-disable-next-line react/destructuring-assignment
          if (!this.state.isNewVersionUpdate) {
            this.setState({ isNewVersionUpdate: true });
          }
        }
      });
  }

  render() {
    const { isNewVersionUpdate } = this.state;

    return (
      <div
        className='app-update-available-container'
        style={{ display: !isNewVersionUpdate ? 'none' : 'flex' }}
      >
        <p className='app-update-available-title'>New update available</p>
        <p
          id='new-update-refresh-text'
          onClick={() => {
            if (caches) {
              // Service worker cache should be cleared with caches.delete()
              caches.keys().then(names => {
                names.forEach(name => {
                  caches.delete(name);
                });
              });
            }
            window.location.reload(true);
          }}
          className='app-update-available-refresh'
        >
          Refresh Now
        </p>
      </div>
    );
  }
}

export default NewUpdate;

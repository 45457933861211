/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Image, Popup, Icon } from 'semantic-ui-react';
import moment from 'moment';

import { avatarStyle, avatarUrl } from '../../constants/DomainTypes';
import ClientDetails from '../ClientDetails';
import CaregiverDetails from '../CaregiverDetails';
import ScheduleDetails from '../ScheduleDetails';
import { formatTime, SHORT_DATE } from '../../constants/Formats';
import { history } from '../../helpers/history';

class Overlay extends React.Component {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
    this.chatClick = this.chatClick.bind(this);
  }

  // eslint-disable-next-line react/state-in-constructor
  state = {
    modalVisible: false,
    view: null,
  };

  onClick(e, input, val) {
    const tab = e && e.target && e.target.name ? e.target.name : null;

    if (this.props.caregiver && this.props.direct) {
      history.push(`/home/caregivers/records/${this.props.caregiver.id}`);
      return;
    }

    if (this.state.modalVisible && this.props.onCloseClicked) {
      this.props.onCloseClicked(e, {}, val);
    }
    this.setState(s => ({
      ...s,
      modalVisible: !s.modalVisible,
      tab,
    }));
  }

  chatClick() {
    // check last click time, one kind of double submit check... (see CC-1054)
    if (!this.chatLastClicked || moment().diff(this.chatLastClicked) > 2000) {
      this.props.handleChatClick(this.props.caregiver.id, this.props.caregiver.status);
      this.chatLastClicked = moment();
    }
  }

  caregiverDirect(caregiver) {
    const { handleChatClick, simple } = this.props;
    let onlineSinceClass;
    let onlineSinceText;

    const warnContent = caregiver.warnings
      ? caregiver.warnings.map(w => <div key={w.type}>{w.message}</div>)
      : null;

    const onlineSince =
      caregiver.lastOnline && caregiver.status === 'active'
        ? moment.duration(moment().diff(caregiver.lastOnline))
        : null;

    const showWarning =
      !caregiver.isInvitable || (caregiver.warnings && caregiver.warnings.length > 0);

    if (onlineSince && onlineSince.asDays() > 30 && onlineSince.asDays() < 60) {
      onlineSinceClass = 'months';
    } else if (onlineSince && onlineSince.asDays() < 1 && onlineSince.asMinutes() > 5) {
      onlineSinceClass = 'hours';
    } else if (onlineSince && onlineSince.asMinutes() < 5) {
      onlineSinceClass = 'online now';
    } else {
      onlineSinceClass = 'days';
    }

    if (onlineSince && onlineSince.asDays() > 60) {
      onlineSinceText = '--';
    } else if (onlineSince && onlineSince.asMinutes() < 5) {
      onlineSinceText = 'online now';
    } else if (onlineSince) {
      onlineSinceText = `Online: ${onlineSince.humanize()} ago`;
    } else {
      onlineSinceText = null;
    }

    return (
      <Grid key={caregiver.id} verticalAlign='middle' className='overlayDirect'>
        <Grid.Column width='13'>
          <div className='name'>
            <Image
              id='overlay-caregiver-avatar-image'
              avatar
              centered
              circular
              size='mini'
              floated='left'
              style={avatarStyle(caregiver.avatar, caregiver.gender)}
              src={avatarUrl(caregiver.avatar, caregiver.gender)}
            />
            <div className='nameBlock'>
              <div
                id='overlay-caregiver-name'
                role='presentation'
                className='overlayLink'
                onClick={this.onClick}
              >
                {`${caregiver.firstName ? caregiver.firstName : ''}  ${caregiver.lastName}`}
              </div>
              {onlineSince && <div className={onlineSinceClass}>{onlineSinceText}</div>}
            </div>
          </div>
        </Grid.Column>
        <Grid.Column width='3'>
          <div className='icons'>
            {simple && showWarning && (
              <div className='simpleIcons'>
                <Popup
                  id='overlay-caregiver-simple-warning-pop-up'
                  trigger={
                    <Icon
                      id='overlay-caregiver-simple-warning-pop-up-icon'
                      name='warning circle'
                      className='icon-red'
                      size='large'
                    />
                  }
                  content={warnContent}
                  on='hover'
                  position='top left'
                  popperModifiers={{
                    preventOverflow: {
                      boundariesElement: 'offsetParent',
                    },
                  }}
                />
              </div>
            )}
            {handleChatClick && (
              <div className='simpleIcons'>
                <Image
                  id='overlay-caregiver-chat-image'
                  src={
                    caregiver.status === 'active' ? '/icon_sbubbledark.svg' : '/icon_sbubble.png'
                  }
                  className='tinyTopMargin'
                  name='schedule'
                  onClick={this.chatClick}
                />
              </div>
            )}
            {!simple && (
              <div>
                {showWarning && (
                  <Popup
                    id='overlay-caregiver-simple-warning-pop-up'
                    trigger={
                      <Icon
                        id='overlay-caregiver-warning-pop-up-icon'
                        name='warning circle'
                        size='large'
                        className='icon-red'
                        onClick={this.onClick}
                      />
                    }
                    content={warnContent}
                    on='hover'
                    position='top left'
                    popperModifiers={{
                      preventOverflow: {
                        boundariesElement: 'offsetParent',
                      },
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </Grid.Column>
      </Grid>
    );
  }

  clientDirect(client) {
    return (
      <Grid key={client.id} verticalAlign='middle' className='overlayDirect'>
        <Grid.Column width='13'>
          <div key={client.id} className='name'>
            <Image
              id='overlay-client-avatar-image'
              avatar
              centered
              circular
              size='mini'
              floated='left'
              style={avatarStyle(false, client.gender)}
              src={avatarUrl(false, client.gender)}
            />
            <div className='nameBlock'>
              <div
                id='overlay-client-name'
                role='presentation'
                className='overlayLink'
                onClick={this.onClick}
              >
                {client.clientName}
              </div>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column width='3'>
          <div>
            {client.address && !client.address.validLatLon && (
              <Popup
                id='overlay-client-warning-pop-up'
                trigger={
                  <Icon
                    id='overlay-client-warning-icon'
                    name='warning circle'
                    className='icon-red'
                    size='large'
                  />
                }
                content='Missing valid address or location information'
                on='hover'
                position='top left'
                popperModifiers={{
                  preventOverflow: {
                    boundariesElement: 'offsetParent',
                  },
                }}
              />
            )}
          </div>
        </Grid.Column>
      </Grid>
    );
  }

  render() {
    const { caregiver, client, direct, shift, allowChat, warnings } = this.props;
    const { modalVisible, tab } = this.state;

    const elements = [];
    if (caregiver) {
      if (direct) {
        elements.push(this.caregiverDirect(caregiver, warnings));
      } else if (allowChat) {
        elements.push(
          <div className='overlayLink assigned-caregiver-shift'>
            <span
              id='overlay-assigned-caregiver-shift-name-with-chat'
              key={`cg_${caregiver.id}`}
              onClick={this.onClick}
              role='presentation'
            >
              {caregiver.caregiverName}
            </span>
            <span style={{ display: 'inline' }} className='simpleIcons'>
              <Image
                id='overlay-caregiver-assigned-shift-chat-image'
                src={caregiver.status === 'active' ? '/icon_sbubbledark.svg' : '/icon_sbubble.png'}
                className='tinyTopMargin'
                style={{ margin: '0' }}
                name='schedule'
                onClick={this.chatClick}
              />
            </span>
          </div>
        );
      } else {
        elements.push(
          <div className='overlayLink assigned-caregiver-shift'>
            <span
              id='overlay-assigned-caregiver-shift-name-no-chat'
              key={`cg_${caregiver.id}`}
              onClick={this.onClick}
              role='presentation'
            >
              {caregiver.caregiverName}
            </span>
          </div>
        );
      }

      if (modalVisible) {
        elements.push(
          <CaregiverDetails
            caregiverId={caregiver.id}
            onCloseClicked={this.onClick}
            tab={tab}
            sitePath={this.props.sitePath}
          />
        );
      }
    }

    if (client) {
      if (direct) {
        elements.push(this.clientDirect(client));
      } else {
        elements.push(
          <span
            id='overlay-client-overlayLink'
            key={`ol_${client.id}`}
            className='overlayLink'
            onClick={this.onClick}
            role='presentation'
          >
            {client.address && !client.address.validLatLon && (
              <Popup
                id='overlay-client-warning-location-pop-up'
                trigger={
                  <Icon
                    id='overlay-client-warning-location-pop-up-icon'
                    name='warning circle'
                    className='icon-red'
                    size='medium'
                  />
                }
                content='Missing valid address or location information'
                on='hover'
                position='top left'
                popperModifiers={{
                  preventOverflow: {
                    boundariesElement: 'offsetParent',
                  },
                }}
              />
            )}
            {client.clientName}
          </span>
        );
      }
      if (modalVisible) {
        elements.push(
          <ClientDetails clientId={client.id} onCloseClicked={this.onClick} tab={tab} />
        );
      }
    }

    if (shift) {
      elements.push(
        <span
          id='overlay-shift-overlayLink'
          key={`ol_${shift.id}`}
          className='overlayLink'
          onClick={this.onClick}
          role='presentation'
        >
          {shift.start ? formatTime(shift.start, shift.timezone, SHORT_DATE) : shift.externalId}
        </span>
      );

      if (modalVisible) {
        elements.push(
          <ScheduleDetails shiftId={shift.id} onCloseClicked={this.onClick} tab={tab} />
        );
      }
    }

    return elements;
  }
}

Overlay.defaultProps = {
  caregiver: null,
  client: null,
  shift: null,
  allowChat: false,
  direct: false,
  simple: false,
  handleChatClick: null,
  warnings: null,
  online: null,
  editableCaregiverDetails: false,
  sitePath: null,
};

Overlay.propTypes = {
  caregiver: PropTypes.shape(),
  client: PropTypes.shape(),
  shift: PropTypes.shape(),
  direct: PropTypes.bool,
  allowChat: PropTypes.bool,
  simple: PropTypes.bool,
  handleChatClick: PropTypes.func,
  warnings: PropTypes.arrayOf(PropTypes.shape()),
  onCloseClicked: PropTypes.func.isRequired,
  sitePath: PropTypes.string,
};

export default Overlay;

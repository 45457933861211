/* eslint-disable react/button-has-type */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-this-alias */
import React from 'react';
import { Grid, GridRow, GridColumn, Form, Label, Image, Visibility, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { avatarUrl } from '../constants/DomainTypes';
import './CareChat.css';
import { IoT } from '../actions';
import { MOMENT_HOURS } from '../constants/Formats';

const ChatHeader = styled(GridRow)`
  &&& {
    background-color: ${props => (props.unread ? '#04A5D5' : '#FFFFFF')};
    border-bottom: 1px #c3cbd8 solid;
    color: #c8c8d3;
    padding: 0.1em 0 0.1em 0.4em;
    width: 100%;
    font-size: 0.8rem;

    span {
      .bubble {
        height: 1.5rem;
        width: auto;
        margin: 0.6rem 0.5rem 0.2rem 0.3rem;
        fill: #c8c8d3;
      }

      .avatar {
        width: 2rem;
        height: 2rem;
        position: relative;
        top: 3px;
        margin: 0.3rem 0.8rem 0.2rem 0.5rem;
        object-fit: cover;
      }

      .participants {
        color: #364866;
        margin: 0.5rem 0.2rem;
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;
        text-align: center;
      }

      .schedule {
        color: #364866;
        font-size: 0.8rem;
        margin-top: -0.7rem;
        text-align: center;
      }

      .remove {
        margin: 0.5rem 0.2rem;
      }
    }
  }
`;

class CareChat extends React.Component {
  constructor(props) {
    super(props);

    this.state = { message: '', visible: true, error: undefined };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);

    this.messagesEnd = null;
  }

  componentDidMount() {
    this.props.dispatch(IoT.getConversation(this.props.channelId));
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  onToggle() {
    if (this.state.visible) {
      this.props.dispatch(IoT.markConversationRead(this.props.channelId));
    }
    this.setState(s => ({ visible: !s.visible }));
  }

  onCloseClick() {
    this.props.dispatch(IoT.closeConversation(this.props.channelId));
  }

  handleChange(event) {
    this.setState({ message: event.target.value });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState(s => ({ ...s, error: undefined }));

    if (this.state.message.length) {
      if (this.props.online) {
        IoT.sendMessage(this.state.message, this.props.channelId);
        this.setState(s => ({ ...s, error: undefined, message: '' }));
      } else {
        // cant send messages when offline...
        this.setState(s => ({ ...s, error: 'Message send failure.' }));
      }
    }
  };

  scrollToBottom() {
    if (this.node) {
      const { scrollHeight } = this.node;
      const height = this.node.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.node.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;

      if (this.props.conversation.lastMessage) {
        IoT.readAck(this.props.channelId, this.props.conversation.lastMessage.timestamp);
      }
    }
  }

  render() {
    const { principal, online } = this.props;
    const { messages, title, initiated, newMessages } = this.props.conversation;
    const { visible, error } = this.state;
    const thisRef = this;
    const writeAreaStyle = error ? { marginTop: '-1em' } : {};

    if (!initiated) {
      return <span />;
    }

    const messagesList = messages.map(message => {
      const pointing = message.sender.userId === principal.user.id ? 'left' : 'right';
      return (
        <div className='message' key={message.messageId}>
          <Label className={`messageCtr ${pointing}`} pointing={pointing}>
            {message.text}
            <div className='timeLabel'>{moment(message.timestamp).format(MOMENT_HOURS)}</div>
          </Label>
        </div>
      );
    });

    if (!online) {
      messagesList.push(
        <div className='message left' key='xtra'>
          <Label className='messageCtr alarm' pointing='left'>
            You seem to be disconnected...
          </Label>
        </div>
      );
    }

    return (
      <div
        className={`ui careChat left floated ${!visible ? 'hidden' : ''}`}
        padded='horizontally'
        columns='one'
      >
        <Grid>
          <GridColumn>
            <ChatHeader unread={newMessages} onClick={this.onToggle}>
              <span>
                {!title.avatar && (
                  <Image className='bubble' floated='left' src='/icon_sbubbleclear.svg' />
                )}
                {title.avatar && (
                  <Image
                    className='avatar'
                    floated='left'
                    circular
                    size='mini'
                    src={avatarUrl(title.avatar)}
                  />
                )}
                <div className='left floated'>
                  <div className='participants'>{title.participants}</div>
                  {visible && <div className='schedule'>{title.shiftSchedule}</div>}
                </div>
                <div className='right floated'>
                  <Icon name='remove' className='remove' size='large' onClick={this.onCloseClick} />
                </div>
                <div style={{ clear: 'both' }} />
              </span>
            </ChatHeader>
            {visible && (
              <GridRow className='messages'>
                <Visibility onBottomPassed={this.visibilityUpdate} continuous>
                  <div
                    fluid='true'
                    className='ui main text messageView'
                    ref={node => {
                      thisRef.node = node;
                    }}
                  >
                    {messagesList}
                    <div style={{ clear: 'both' }} />
                  </div>
                </Visibility>
              </GridRow>
            )}
            {visible && (
              <GridRow className='writing' style={writeAreaStyle}>
                <div>
                  <Form onSubmit={this.handleSubmit}>
                    <div className='ui input fluid'>
                      <input
                        className='msgInput'
                        value={this.state.message}
                        placeholder='Message...'
                        onChange={this.handleChange}
                      />
                      <button className='ui right floated button'>Send</button>
                    </div>
                  </Form>

                  {error && <div className='failure'>{error}</div>}
                </div>
              </GridRow>
            )}
          </GridColumn>
        </Grid>
      </div>
    );
  }
}

CareChat.propTypes = {
  dispatch: PropTypes.func.isRequired,
  channelId: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape),
  conversation: PropTypes.shape().isRequired,
  principal: PropTypes.shape().isRequired,
  online: PropTypes.bool.isRequired,
};

CareChat.defaultProps = {
  messages: [],
  visible: true,
};

const mapStateToProps = (state, ownProps) => {
  const { carechat, principal } = state;
  const conversation = ownProps.channelId ? carechat.conversations[ownProps.channelId] : {};
  const online = carechat.online ? carechat.online : false;
  return {
    ...ownProps,
    conversation,
    principal,
    online,
  };
};

export default connect(mapStateToProps)(CareChat);

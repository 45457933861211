import moment from 'moment-timezone';

export const parseAssigned = x => {
  const { userEvents } = x.shift || {};
  return userEvents ? userEvents.find(e => e.type === 'CAREGIVER_SHIFT_ASSIGNED') : undefined;
};

export const parseCancelled = x => {
  const { userEvents } = x.shift || {};
  return userEvents ? userEvents.find(e => e.type === 'CAREGIVER_SHIFT_CANCELLED') : undefined;
};

export const parseOffered = x => {
  const { userEvents } = x.shift || {};
  return userEvents ? userEvents.find(e => e.type === 'CAREGIVER_SHIFT_OFFERED') : undefined;
};

const parseCost = x => {
  const assignedEvent = parseAssigned(x);
  const { meta } = assignedEvent || {};
  if (meta) {
    const metaObj = JSON.parse(meta);
    return (metaObj || {}).extra_costs;
  }
  return undefined;
};

export const sortTableData = (data, field) => {
  if (!data) {
    return data;
  }

  return [...data].sort((a, b) => {
    let aVal = null;
    let bVal = null;
    switch (field) {
      case 'shiftStart':
        aVal = (a.shift || {}).start;
        bVal = (b.shift || {}).start;
        break;
      case 'caregiver':
        aVal = a.caregiverName;
        bVal = b.caregiverName;
        break;
      case 'shiftId':
        aVal = (a.shift || {}).externalId;
        bVal = (b.shift || {}).externalId;
        break;
      case 'client':
        aVal = (a.shift || {}).clientName;
        bVal = (b.shift || {}).clientName;
        break;
      case 'invited':
        aVal = (a.shift || {}).offered;
        bVal = (b.shift || {}).offered;
        break;
      case 'viewed':
        aVal = (a.shift || {}).read;
        bVal = (b.shift || {}).read;
        break;
      case 'response':
        aVal = (a.shift || {}).applied;
        bVal = (b.shift || {}).applied;
        break;
      case 'assigned':
        aVal = (a.shift || {}).assigned;
        bVal = (b.shift || {}).assigned;
        break;
      case 'cost':
        aVal = parseCost(a);
        bVal = parseCost(b);
        break;
      case 'invitedBy':
        aVal = (parseOffered(a) || {}).employee;
        bVal = (parseOffered(b) || {}).employee;
        break;
      case 'assignedBy':
        aVal = (parseAssigned(a) || {}).employee;
        bVal = (parseAssigned(b) || {}).employee;
        break;

      case 'caregivers':
        aVal = a.participants
          .filter(z => z.role === 'caregiver')
          .map(c => `${c.firstName} ${c.lastName}`)
          .join(', ');
        bVal = b.participants
          .filter(z => z.role === 'caregiver')
          .map(c => `${c.firstName} ${c.lastName}`)
          .join(', ');
        break;
      case 'employees':
        aVal = a.participants
          .filter(z => z.role === 'scheduler')
          .map(c => `${c.firstName} ${c.lastName}`)
          .join(', ');
        bVal = b.participants
          .filter(z => z.role === 'scheduler')
          .map(c => `${c.firstName} ${c.lastName}`)
          .join(', ');
        break;
      default:
        aVal = a[field];
        bVal = b[field];
    }
    aVal = aVal || '';
    bVal = bVal || '';

    if (aVal === bVal) return 0;
    if (aVal < bVal) return -1;
    return 1;
  });
};

export const getDueDate = curriculums => {
  const dueDate = curriculums
    ?.sort((a, b) => (moment(a).isBefore(moment(b)) ? -1 : 0))
    .map(c => c.dueDate)[0];
  return dueDate;
};

export const sortCoursesList = courses => {
  if (!courses) {
    return courses;
  }

  const sortedCourses = courses.sort((a, b) => {
    const courseA = a.course ? a.course.userCourse : a;
    const courseB = b.course ? b.course.userCourse : b;

    const courseADueDate = getDueDate(courseA.curriculums);
    const courseBDueDate = getDueDate(courseB.curriculums);

    if (moment(courseADueDate).isBefore(moment(courseBDueDate))) {
      return -1;
    }
    if (moment(courseADueDate).isSame(moment(courseBDueDate))) {
      return courseA.id - courseB.id;
    }
    return 0;
  });
  return sortedCourses;
};

export const getProfileLanguage = profile => {
  const [primaryLanguage] = profile?.languages?.length
    ? profile.languages.filter(l => l.primary).map(p => p.code)
    : [];

  return primaryLanguage || 'eng';
};

export const dateFormat = 'MM/DD/YYYY';

export const uploadFile = (uploadUrl, file) =>
  new Promise((resolve, reject) => {
    const upload = async event => {
      const response = await fetch(uploadUrl, {
        method: 'PUT',
        body: event.currentTarget.result,
        headers: {
          'Content-Length': file.size,
          'Content-Type': file.type,
        },
      });

      if (response.status >= 300) {
        return reject(new Error(`HTTP error ${response.statusText}`));
      }

      return resolve(response);
    };

    const reader = new FileReader();
    reader.onload = upload;
    reader.readAsArrayBuffer(file);
  });

export const isValidTimezone = tz => tz && !!moment.tz.zone(tz);

const getEmployeeName = (employees, employeeId) => {
  const employee = employees.find(e => e.id === employeeId);
  return employee ? `${employee.firstName} ${employee.lastName}` : '';
};

export default {
  sortTableData,
  parseAssigned,
  parseOffered,
  parseCancelled,
  getEmployeeName,
};

import {
  IHRCandidatesFilter,
  IHRCaregiversFilter,
  IHRCompliances,
  IHRVaccination,
} from '../../reducers/types/HRFilters';

const setCompliancesFilters = (complianceIssues: IHRCompliances) => {
  let complianceIds: number[] = [];
  let complianceTypes: string[] = [];
  let complianceStatus: string[] = [];

  if (complianceIssues.eligibility[0] === 0) {
    complianceTypes = [...complianceTypes, 'eligibility'];
  } else {
    complianceIds = [...complianceIds, ...complianceIssues.eligibility];
  }
  if (complianceIssues.medical[0] === 0) {
    complianceTypes = [...complianceTypes, 'medical'];
  } else {
    complianceIds = [...complianceIds, ...complianceIssues.medical];
  }
  if (complianceIssues.documentation[0] === 0) {
    complianceTypes = [...complianceTypes, 'documentation'];
  } else {
    complianceIds = [...complianceIds, ...complianceIssues.documentation];
  }
  if (complianceIssues.training[0] === 0) {
    complianceTypes = [...complianceTypes, 'training'];
  } else {
    complianceIds = [...complianceIds, ...complianceIssues.training];
  }
  if (complianceIssues.incomplete) {
    complianceStatus = [...complianceStatus, 'nonCompliant'];
  }
  if (complianceIssues.expired) {
    complianceStatus = [...complianceStatus, 'expired'];
  }
  if (complianceIssues.pending) {
    complianceStatus = [...complianceStatus, 'pending'];
  }
  return { complianceTypes, complianceIds, complianceStatus };
};

const setVaccineStatus = (vaccination: IHRVaccination) => {
  let vaccinationStatus: string[] = [];
  if (vaccination.validation) {
    vaccinationStatus = [...vaccinationStatus, 'validating'];
  }
  if (vaccination.upload) {
    vaccinationStatus = [...vaccinationStatus, 'pending'];
  }
  if (vaccination.complete) {
    vaccinationStatus = [...vaccinationStatus, 'completed'];
  }
  if (vaccination.declined) {
    vaccinationStatus = [...vaccinationStatus, 'declined'];
  }
  return vaccinationStatus;
};

const setStaffStatus = (status?: string[]) => {
  if (!status || !status.length) {
    return [];
  }
  if (status[0] === '00') {
    return [];
  }
  return status;
};

const setDefault = (param?: number[] | string[]) => {
  if (!param || !param.length) {
    return [];
  }
  if (param[0] === 0) {
    return [];
  }
  return param;
};

const buildCandidatesParams = (params: IHRCandidatesFilter) => {
  if (!params) {
    return {};
  }
  const complianceIDsAndTypes = setCompliancesFilters(params.compliancesIssues);

  return {
    disciplines: setDefault(params.disciplines.disciplines),
    compliancesIds: complianceIDsAndTypes.complianceIds,
    complianceTypes: complianceIDsAndTypes.complianceTypes,
    complianceStatus: complianceIDsAndTypes.complianceStatus,
    primaryContact: setDefault(params.primaryContact.primaryContact),
    primaryContactManager: setDefault(params.primaryManager.primaryManager),
    name: params.nameOrId.nameOrId,
  };
};

const buildCaregiversParams = (params: IHRCaregiversFilter) => {
  if (!params) {
    return {};
  }
  const complianceIDsAndTypes = setCompliancesFilters(params.compliancesIssues);
  const status = setStaffStatus(params.caregiverStatus.status);
  return {
    disciplines: setDefault(params.disciplines.disciplines),
    compliancesIds: complianceIDsAndTypes.complianceIds,
    complianceTypes: complianceIDsAndTypes.complianceTypes,
    complianceStatus: complianceIDsAndTypes.complianceStatus,
    pendingDueDate: params.compliancesIssues.dueDate,
    vaccineType: params.vaccinationValidation.vaccine,
    vaccineStatus: setVaccineStatus(params.vaccinationValidation),
    primaryContact: setDefault(params.primaryContact.primaryContact),
    primaryContactManager: setDefault(params.primaryManager.primaryManager),
    staffStatus: setStaffStatus(params.caregiverStatus.status),
    secondaryStatus:
      !status.length || status.length > 1 ? [] : params.secondaryStatus.secondaryStatus,
    name: params.nameOrId.nameOrId,
    blockShift: params.blockShift.blockShift || null,
  };
};

export default { buildCandidatesParams, buildCaregiversParams };

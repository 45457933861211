import moment from 'moment';
import _ from 'underscore';

import {
  ADD_CAREGIVER_TO_SHIFT_SUCCESS,
  ADD_CAREGIVER_TO_SHIFT_REQUEST,
  ADD_CAREGIVER_TO_SHIFT_FAILURE,
  ADD_CAREGIVER_TO_SHIFT_COMPLETED,
  LIST_CAREGIVERS_REQUEST,
  LIST_CAREGIVERS_SUCCESS,
  LIST_CAREGIVERS_FAILURE,
  RESET_CAREGIVERS_LIST,
  CAREGIVERS_CHECKBOX_CLICKED,
  CAREGIVERS_SELECT_ALL_CLICKED,
  RESET_CAREGIVERS_CHECKBOX_CLICKED,
  TRAVEL_TIME_SUCCESS,
  NOTIFICATION_SHIFT_UPDATED,
  UPDATE_CAREGIVER_SUCCESS,
  CAREGIVERS_ONLINESTATUS_SUCCESS,
  PROCESS_CAREGIVER_INVITATION_COMPLETED,
  PROCESS_CAREGIVER_INVITATION,
} from '../constants/ActionTypes';

import { prepareCaregiverObject, prepareShortShifts } from './helpers';

const initialState = {
  loading: false,
  processingInvites: false,
  processingCaregiver: {},
  selectedCaregivers: {},
  assignDisabled: true,
  inviteDisabled: true,
  partialUpdate: false,
  initialRequestDone: false,
  listCaregiversTimestamp: undefined,
};

function caregiver(state = initialState, action) {
  switch (action.type) {
    case ADD_CAREGIVER_TO_SHIFT_REQUEST: {
      return {
        ...state,
        processingInvites: true,
      };
    }
    case ADD_CAREGIVER_TO_SHIFT_SUCCESS: {
      if (!state.results) {
        return state;
      }
      if (Array.isArray(action.results)) {
        const allResults = action.results.reduce(
          (x, r) => {
            prepareCaregiverObject(r.caregiver);
            const index = state.results.findIndex(i => i.id === r.caregiver.id);
            if (index >= 0) {
              const cg = { ...x[index] };
              const newShifts = cg.shifts ? [...cg.shifts] : [];
              r.caregiver.shifts.forEach(s => {
                const shiftIndex = newShifts.findIndex(i => i.id === s.id);
                if (shiftIndex >= 0) {
                  newShifts[shiftIndex] = s;
                } else {
                  newShifts.push(s);
                }
              });
              cg.shifts = newShifts;
              cg.status = r.caregiver.status;
              const results = Object.assign([...x], { [index]: cg });
              return results;
            }
            return null;
          },
          [...state.results]
        );
        return { ...state, results: allResults };
      }
      if (!Array.isArray(action.results)) {
        prepareCaregiverObject(action.results.caregiver);
        const index = state.results.findIndex(i => i.id === action.results.caregiver.id);
        if (index >= 0) {
          const cg = { ...state.results[index] };
          const newShifts = cg.shifts ? [...cg.shifts] : [];
          action.results.caregiver.shifts.forEach(s => {
            const shiftIndex = newShifts.findIndex(i => i.id === s.id);
            if (shiftIndex >= 0) {
              newShifts[shiftIndex] = s;
            } else {
              newShifts.push(s);
            }
          });
          cg.shifts = newShifts;
          cg.status = action.results.caregiver.status;
          const results = Object.assign([...state.results], { [index]: cg });
          return { ...state, results };
        }
      }
      return state;
    }

    case NOTIFICATION_SHIFT_UPDATED: {
      if (!state.results) {
        return state;
      }
      const results = [...state.results];
      action.results.forEach(s => {
        s.assignedCaregivers.forEach(ac => {
          const index = results.findIndex(i => i.id === ac.id);
          if (index >= 0) {
            const cg = { ...results[index] };
            const shifts = cg.shifts ? [...cg.shifts] : [];
            const shiftIndex = shifts.findIndex(i => i.id === s.id);
            if (shiftIndex >= 0) {
              shifts[shiftIndex] = { ...shifts[shiftIndex], status: ac.shiftAssigmentStatus };
              cg.shifts = shifts;
            } else {
              const shortShift = prepareShortShifts({
                id: s.id,
                start: s.start,
                end: s.end,
                status: ac.shiftAssigmentStatus,
              });
              shifts.push(shortShift);
              cg.shifts = shifts;
            }

            results[index] = cg;
          }
        });
      });

      if (results) {
        return { ...state, results };
      }
      return state;
    }
    case ADD_CAREGIVER_TO_SHIFT_COMPLETED: {
      return {
        ...state,
        processingInvites: false,
      };
    }
    case ADD_CAREGIVER_TO_SHIFT_FAILURE: {
      return {
        ...state,
        processingInvites: false,
      };
    }
    case LIST_CAREGIVERS_REQUEST:
      return {
        ...state,
        loading: true,
        initialRequestDone: true,
        partialUpdate: action.partialUpdate,
      };
    case LIST_CAREGIVERS_SUCCESS: {
      if (
        !state.listCaregiversTimestamp ||
        !action.results.timestamp ||
        state.listCaregiversTimestamp.isBefore(action.results.timestamp)
      ) {
        const results = action.results
          ? action.results.results.map(x => prepareCaregiverObject(x))
          : [];
        let newResults = action.partialUpdate ? state.results.concat(results) : results;
        newResults = _.uniq(newResults, false, s => s.id);

        return {
          ...state,
          loading: false,
          destination: action.results ? action.results.destination : null,
          results: newResults,
          tooManyResults: action.tooManyResults,
          nextPage: action.results.nextPage,
          partialUpdate: action.partialUpdate,
          listCaregiversTimestamp: action.results.timestamp,
        };
      }
      return state;
    }
    case LIST_CAREGIVERS_FAILURE:
      return { ...state, loading: false, partialUpdate: action.partialUpdate };
    case RESET_CAREGIVERS_LIST:
      return { ...state, results: undefined, destination: undefined };
    case RESET_CAREGIVERS_CHECKBOX_CLICKED: {
      let newItems = {};
      if (action.caregiverId) {
        newItems = {
          ...state.selectedCaregivers,
          [action.caregiverId]: false,
        };
      }

      const selectedCount = Object.values(newItems).filter(i => i).length;
      return {
        ...state,
        selectedCaregivers: newItems,
        assignDisabled: selectedCount !== 1,
        inviteDisabled: selectedCount === 0,
      };
    }
    case CAREGIVERS_CHECKBOX_CLICKED: {
      const allSelected = false;
      const caregiverId = parseInt(action.caregiverId, 10);
      const newItems = {
        ...state.selectedCaregivers,
        [caregiverId]: !state.selectedCaregivers[caregiverId],
      };

      const selectedCount = Object.values(newItems).filter(i => i).length;
      let selected = null;

      if (selectedCount === 1) {
        selected = state.results.find(c => newItems[c.id]);
      }

      return {
        ...state,
        selectedCaregivers: newItems,
        assignDisabled: selectedCount !== 1,
        inviteDisabled: selectedCount === 0,
        allSelected,
        selected,
      };
    }
    case CAREGIVERS_SELECT_ALL_CLICKED: {
      const selectedCaregivers = {};
      const allSelected = !state.allSelected;
      let singleSelected = null;
      if (allSelected) {
        state.results.forEach(c => {
          if (
            c.filterFailure.filter(
              f =>
                f.name === 'extra_cost' ||
                f.name === 'conflict' ||
                f.name === 'travelTime' ||
                f.name === 'unavailable'
            ).length === 0
          ) {
            singleSelected = c;
            selectedCaregivers[c.id] = true;
          }
          if (!c.isInvitable) {
            selectedCaregivers[c.id] = false;
          }
        });
      } else {
        singleSelected = null;
        state.results.forEach(c => {
          selectedCaregivers[c.id] = false;
        });
      }
      const selectedCount = Object.values(selectedCaregivers).filter(i => i).length;
      return {
        ...state,
        selectedCaregivers,
        assignDisabled: selectedCount !== 1,
        inviteDisabled: selectedCount === 0,
        selected: selectedCount === 1 ? singleSelected : null,
        allSelected,
      };
    }
    case TRAVEL_TIME_SUCCESS: {
      if (state.results) {
        const results = [...state.results];
        action.results.forEach(r => {
          const index = results.findIndex(cg => cg.id === r.caregiverId);

          if (index >= 0) {
            const cg = { ...results[index] };
            const ttr = { ...cg.travelTimesRequests };

            // eslint-disable-next-line no-param-reassign
            r.arrivalTime = r.arrivalTime ? moment.utc(r.arrivalTime) : r.arrivalTime;

            // eslint-disable-next-line no-param-reassign
            r.departureTime = r.departureTime ? moment.utc(r.departureTime) : r.departureTime;

            ttr[r.targetId][r.type] = r;
            cg.travelTimesRequests = ttr;
            results[index] = cg;
          }
        });

        return { ...state, results };
      }
      return state;
    }
    case UPDATE_CAREGIVER_SUCCESS: {
      if (!state.results) {
        return state;
      }
      prepareCaregiverObject(action.results.profile);
      const index = state.results.findIndex(i => i.id === action.results.profile.id);
      if (index >= 0) {
        if (!action.results.profile.shifts) {
          // eslint-disable-next-line no-param-reassign
          delete action.results.profile.shifts;
        }
        const results = Object.assign([...state.results], {
          [index]: { ...state.results[index], ...action.results.profile },
        });
        return { ...state, results };
      }
      return state;
    }
    case CAREGIVERS_ONLINESTATUS_SUCCESS: {
      if (state.results) {
        const results = [...state.results];
        action.results.forEach(r => {
          // eslint-disable-next-line no-param-reassign
          r.lastOnline = moment.utc(r.lastOnline);

          const index = results.findIndex(cg => cg.id === r.caregiverId);
          if (index >= 0) {
            const cg = { ...results[index] };
            cg.lastOnline = r.lastOnline;
            results[index] = cg;
          }
        });

        return { ...state, results, lastOnlineUpdate: moment() };
      }
      return state;
    }
    case PROCESS_CAREGIVER_INVITATION_COMPLETED: {
      const tmp = Array.isArray(action.caregiverId)
        ? action.caregiverId.reduce((x, i) => Object.assign(x, { [i]: false }), {
            ...state.processingCaregiver,
          })
        : { ...state.processingCaregiver, [action.caregiverId]: false };
      return {
        ...state,
        processingCaregiver: tmp,
      };
    }
    case PROCESS_CAREGIVER_INVITATION: {
      const tmp = Array.isArray(action.caregiverId)
        ? action.caregiverId.reduce((x, i) => Object.assign(x, { [i]: true }), {
            ...state.processingCaregiver,
          })
        : { ...state.processingCaregiver, [action.caregiverId]: true };
      return {
        ...state,
        processingCaregiver: tmp,
      };
    }
    default:
      return state;
  }
}

export default caregiver;

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Image, Icon, Segment } from 'semantic-ui-react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import moment from 'moment-timezone';

import { avatarStyle, avatarUrl } from '../../constants/DomainTypes';
import '../ShiftBuilder.css';
import AddressList from './AddressList';
import { googleMapURL } from '../../constants/Constants';

const ClientMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      zoom={props.reset ? 14 : null}
      center={props.reset ? { lat: props.lat, lng: props.lon } : null}
    >
      <Marker position={{ lat: props.lat, lng: props.lon }} icon='/MapPin.png' />
    </GoogleMap>
  ))
);

class ClientSearchResults extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    client: null,
    reset: false,
  };

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(nextProps) {
    let reset = false;
    if (
      (!this.state.client && nextProps.client) ||
      (this.state.client && nextProps.client && this.state.client.id !== nextProps.client.id)
    ) {
      reset = true;
    }
    this.setState({
      client: nextProps.client,
      reset,
    });
  }

  render() {
    const { client, reset } = this.state;
    const { onClear } = this.props;

    return (
      <Grid className='clientSearchResults'>
        <Grid.Row>
          <Grid.Column width='3' textAlign='center'>
            <Image
              id='client-search-results-avatar-image'
              className='avatar'
              circular
              style={avatarStyle(false, client && client.gender, 'large')}
              src={avatarUrl(false, client && client.gender)}
            />
          </Grid.Column>
          <Grid.Column width='13'>
            {client && (
              <div>
                <span className='clientName'>{client.clientName}</span>
                <span
                  id='client-search-results-clear-client-button'
                  className='overlayLink clearClient'
                  role='presentation'
                  onClick={onClear}
                >
                  clear client
                </span>
              </div>
            )}
            {!client && (
              <div>Search for an existing client within your system or create a new client.</div>
            )}
            <div className='subHeader'>Shift Location:</div>
            <Grid>
              <Grid.Column width='2' className='mapPin'>
                <Image width='14' Height='20' className='noPadding' inline src='/MapPin.png' />
              </Grid.Column>
              <Grid.Column width='12'>
                {client && client.address && <AddressList address={client.address} />}
                {!client && 'Will be pulled from client profile'}
              </Grid.Column>
            </Grid>
            {client && client.address && !client.address.validLatLon && (
              <Segment basic>
                <Icon name='warning circle' className='icon-red' size='large' inline />
                Missing valid location information
              </Segment>
            )}
            <div className='subHeader'>Shift Timezone:</div>
            <Grid>
              <Grid.Column width='2' />
              <Grid.Column width='12'>
                {!client && 'Will be pulled from client profile'}
                {client &&
                  (client.address && client.address.timezone
                    ? client.address.timezone
                    : `Guessed: ${moment.tz.guess()}`)}
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {client && client.address && client.address.validLatLon && (
            <Grid.Column width='16'>
              <ClientMap
                googleMapURL={googleMapURL}
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '230px' }} />}
                mapElement={<div style={{ height: '230px' }} />}
                lat={client.address.lat}
                lon={client.address.lon}
                reset={reset}
              />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    );
  }
}

ClientSearchResults.propTypes = {
  client: PropTypes.shape(),
  onClear: PropTypes.func.isRequired,
};

ClientSearchResults.defaultProps = {
  client: null,
};

export default ClientSearchResults;

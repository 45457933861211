import React from 'react';
import { Image, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { avatarStyle, avatarUrl } from '../../constants/DomainTypes';

const CaregiverBox = ({ caregiver, showRemove }) => (
  <Segment basic className='assignedCaregiver'>
    <Segment clearing className='ctr'>
      <div className='img left floated'>
        <Image
          size='mini'
          circular
          style={avatarStyle(caregiver.avatar, caregiver.gender)}
          src={avatarUrl(caregiver.avatar, caregiver.gender)}
        />
      </div>
      <div className='name-discipline-container'>
        <div className='name'>{caregiver.caregiverName}</div>
        <div className='discipline'>{caregiver.discipline}</div>
      </div>
      {showRemove && <div className='remove right floated'>Remove</div>}
    </Segment>
  </Segment>
);

CaregiverBox.defaultProps = {
  showRemove: false,
};

CaregiverBox.propTypes = {
  caregiver: PropTypes.shape().isRequired,
  showRemove: PropTypes.bool,
};

export default CaregiverBox;

import {
  GET_EVENT_ATTENDEES_REQUEST,
  GET_EVENT_ATTENDEES_FAILURE,
  GET_EVENT_ATTENDEES_SUCCESS,
  CLEAR_EVENT_ATTENDEES,
  CREATE_COMPLIANCE_TRAINING_EVENT_REQUEST,
  CREATE_COMPLIANCE_TRAINING_EVENT_FAILURE,
  CREATE_COMPLIANCE_TRAINING_EVENT_SUCCESS,
  GET_COMPLIANCE_TRAINING_EVENTS_REQUEST,
  REMOVE_COMPLIANCE_TRAINING_EVENTS_SUCCESS,
  GET_COMPLIANCE_TRAINING_EVENTS_SUCCESS,
  GET_COMPLIANCE_TRAINING_EVENTS_FAILURE,
  CLEAR_EVENT_TO_SELECT,
  SUBMIT_COMPLIANCE_TRAINING_EVENT_FAILURE,
  SUBMIT_COMPLIANCE_TRAINING_EVENT_REQUEST,
  SUBMIT_COMPLIANCE_TRAINING_EVENT_SUCCESS,
  UPDATE_COMPLIANCE_TRAINING_EVENT_SUCCESS,
  UPDATE_COMPLIANCE_TRAINING_EVENT_FAILURE,
  UPDATE_COMPLIANCE_TRAINING_EVENT_REQUEST,
  INITIATE_VIRTUAL_EVENT_REQUEST,
  INITIATE_VIRTUAL_EVENT_FAILURE,
  INITIATE_VIRTUAL_EVENT_SUCCESS,
  PRESELECT_BLOCK_ID,
} from '../../constants/ActionTypes';

const initialState = {
  loadingAttendees: false,
  attendees: {},
  events: [],
  attendeesError: false,
  eventToSelect: undefined,
  createdEvent: false,
  preSelectedBlockId: undefined,
  loadingEvents: false,
  submittingEvent: false,
};

const startState = JSON.parse(JSON.stringify(initialState));

function trainingEvent(state = startState, action) {
  switch (action.type) {
    case GET_EVENT_ATTENDEES_REQUEST:
      return { ...state, loadingAttendees: true, attendeesError: false };
    case GET_EVENT_ATTENDEES_FAILURE:
      return { ...state, attendees: [], loadingAttendees: false, attendeesError: true };
    case GET_EVENT_ATTENDEES_SUCCESS: {
      const available =
        action.results.previousPage && state.attendees.available
          ? state.attendees.available.concat(action.results.available)
          : action.results.available;
      return {
        ...state,
        attendees: Object.assign(action.results, { available }),
        loadingAttendees: false,
        attendeesError: false,
      };
    }
    case CLEAR_EVENT_ATTENDEES:
      return { ...state, attendees: [], loadingAttendees: false, attendeesError: false };
    case UPDATE_COMPLIANCE_TRAINING_EVENT_REQUEST:
    case CREATE_COMPLIANCE_TRAINING_EVENT_REQUEST:
    case INITIATE_VIRTUAL_EVENT_REQUEST:
      return { ...state, loadingEvents: true, eventsError: false };
    case UPDATE_COMPLIANCE_TRAINING_EVENT_FAILURE:
    case CREATE_COMPLIANCE_TRAINING_EVENT_FAILURE:
    case INITIATE_VIRTUAL_EVENT_FAILURE:
      return { ...state, loadingEvents: false, eventsError: true, loadingAttendees: false };
    case UPDATE_COMPLIANCE_TRAINING_EVENT_SUCCESS:
    case CREATE_COMPLIANCE_TRAINING_EVENT_SUCCESS:
      return {
        ...state,
        eventToSelect: action.results.select ? action.results.event.id : null,
        createdEvent: action.type === CREATE_COMPLIANCE_TRAINING_EVENT_SUCCESS,
        loadingAttendees: false,
      };

    case INITIATE_VIRTUAL_EVENT_SUCCESS:
      return { ...state, loadingEvents: false, eventsError: false };

    case CLEAR_EVENT_TO_SELECT:
      return { ...state, eventToSelect: undefined, createdEvent: false };

    case PRESELECT_BLOCK_ID:
      return { ...state, preSelectedBlockId: action.blockId };

    case GET_COMPLIANCE_TRAINING_EVENTS_REQUEST:
      return { ...state, loadingEvents: true, eventsError: false };
    case REMOVE_COMPLIANCE_TRAINING_EVENTS_SUCCESS:
      return { ...state, loadingEvents: false, eventsError: false };
    case GET_COMPLIANCE_TRAINING_EVENTS_SUCCESS:
      return {
        ...state,
        loadingEvents: false,
        eventsError: false,
        events: action.results.events
          ? action.results.events.map(e => Object.assign(e, { blockId: e.block.id }))
          : [],
      };
    case GET_COMPLIANCE_TRAINING_EVENTS_FAILURE:
      return { ...state, loadingEvents: false, eventsError: true };

    case SUBMIT_COMPLIANCE_TRAINING_EVENT_REQUEST:
      return { ...state, submittingEvent: true, eventsError: false };
    case SUBMIT_COMPLIANCE_TRAINING_EVENT_FAILURE:
      return { ...state, submittingEvent: false, eventsError: true, loadingAttendees: false };
    case SUBMIT_COMPLIANCE_TRAINING_EVENT_SUCCESS:
      return {
        ...state,
        eventToSelect: action.results.select ? action.results.event.id : null,
        createdEvent: action.type === CREATE_COMPLIANCE_TRAINING_EVENT_SUCCESS,
        submittingEvent: false,
      };

    default:
      return state;
  }
}

export default trainingEvent;

/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Header, Button, Form, Table, Icon, Checkbox } from 'semantic-ui-react';
import { Route } from 'react-router-dom';
import moment from 'moment';
import { caregiverActions, schedulerActions, IoT, alertActions, uiStateActions } from '../actions';
import SchedulesMenu from './SchedulesMenu';
import ScheduleDetails from './ScheduleDetails';
import { getShiftStatusDetails } from '../constants/StatusMaps';
import { precisionRound, formatTime, MOMENT_HOURS } from '../constants/Formats';
import NoMatch from './subcomponents/NoMatch';
import InitialState from './subcomponents/InitialState';
import Overlay from './subcomponents/Overlay';
import PageSizer from './subcomponents/PageSizer';
import PageMeter from './subcomponents/PageMeter';
import LoadMore from './subcomponents/LoadMore';
import CareRoundButton from './subcomponents/CareRoundButton';
import { history } from '../helpers';
import { analytics, Events } from '../helpers/analytics';
import { caregiverScoresActions } from '../actions/caregiverScores';

const statusItem = status => {
  const statusDetails = getShiftStatusDetails(status);
  return {
    icon: { name: 'circle', className: statusDetails.className },
    content: statusDetails.prev,
  };
};

const searchShift = (dispatch, shiftId) => {
  dispatch(caregiverActions.resetCaregiverList());
  dispatch(caregiverActions.setTargetShifts([shiftId]));
  history.push('/home/caregivers');
  analytics.track(Events.SHIFTS_SEARCH_CAREGIVERS);
};

const caregiverCell = (dispatch, shift) => {
  const search = () => {
    searchShift(dispatch, shift.id);
  };

  if (shift.actionable) {
    const render = () => (
      <Button
        id='schedules-search-button'
        color='green'
        size='mini'
        style={{
          fontWeight: 'normal',
          color: '#4a4a4a',
          backgroundColor: '#69db8f',
          fontSize: '12px',
        }}
        onClick={search}
      >
        Search
      </Button>
    );
    return <Route render={render} />;
  }
  const handleChatClick = (caregiverId, caregiverStatus) => {
    if (caregiverStatus === 'active') {
      dispatch(IoT.createConversation(caregiverId, shift.id));
    } else {
      dispatch(alertActions.error('Cannot start chat. This caregiver is not active'));
    }
  };

  return shift.assignedCaregivers && shift.assignedCaregivers.length ? (
    shift.assignedCaregivers.map(cg => (
      <Overlay
        key={`cgol_${cg.id}`}
        caregiver={cg}
        handleChatClick={handleChatClick}
        editableCaregiverDetails
        allowChat
      />
    ))
  ) : (
    <span>-</span>
  );
};

const invitesCell = (dispatch, shift) => {
  if (!shift.actionable) {
    return { content: '--' };
  }

  const search = () => {
    searchShift(dispatch, shift.id);
  };

  const invites = Number.isInteger(shift.invites) ? shift.invites : 0;

  let content;
  if (invites > 0) {
    content = (
      <div className='overlayLink' onClick={search} role='presentation'>
        <span>{invites}</span>
        {shift.statusMap.applied > 0 && <Icon name='circle' className='icon-green' />}
      </div>
    );
  } else {
    content = invites;
  }

  return {
    content,
  };
};

const headers = (shift, column, direction, onHeaderClick) => {
  const headerArray = [
    {
      id: 'schedules-table-header-date',
      key: 'date',
      content: 'Date',
      textAlign: 'center',
      filter: true,
    },
    {
      id: 'schedules-table-header-client',
      key: 'client',
      content: 'Client',
      textAlign: 'left',
      filter: true,
    },
    {
      id: 'schedules-table-header-service',
      key: 'service',
      content: 'Service',
      textAlign: 'center',
      filter: true,
    },
    {
      id: 'schedules-table-header-caregiver',
      key: 'caregiver',
      content: 'Caregiver',
      filter: true,
    },
    {
      id: 'schedules-table-header-invites',
      key: 'invites',
      content: 'Invites',
      textAlign: 'center',
      filter: true,
    },
    {
      id: 'schedules-table-header-startTime',
      key: 'startTime',
      content: 'Start Time',
      textAlign: 'center',
      filter: true,
    },
    {
      id: 'schedules-table-header-endTime',
      key: 'endTime',
      content: 'End Time',
      textAlign: 'center',
      filter: true,
    },
    {
      id: 'schedules-table-header-hours',
      key: 'hours',
      textAlign: 'center',
      content: 'Hrs',
      filter: true,
    },
    {
      id: 'schedules-table-header-status',
      key: 'status',
      textAlign: 'center',
      content: 'Status',
      filter: true,
    },
    { id: 'schedules-table-header-extra', key: 'extra', textAlign: 'center', content: 'Select' },
  ];

  headerArray
    .filter(f => f.filter)
    .forEach(h => {
      // eslint-disable-next-line no-param-reassign
      h.className = column === h.key ? `${direction} sorted` : undefined;

      // eslint-disable-next-line no-param-reassign
      h.onClick = onHeaderClick(h.key);
      // eslint-disable-next-line no-param-reassign
      h.filter = undefined;
    });

  return headerArray;
};

class Schedules extends React.Component {
  static sort(data, field, direction) {
    if (!data) {
      return data;
    }

    return [...data].sort((a, b) => {
      let aVal = null;
      let bVal = null;
      switch (field) {
        case 'client':
          aVal = `${a.client.lastName ? a.client.lastName.toLowerCase().replace(/\s/g, '') : ''}${
            a.client.firstName ? a.client.firstName.toLowerCase().replace(/\s/g, '') : ''
          }`;
          bVal = `${b.client.lastName ? b.client.lastName.toLowerCase().replace(/\s/g, '') : ''}${
            b.client.firstName ? b.client.firstName.toLowerCase().replace(/\s/g, '') : ''
          }`;
          break;
        case 'caregiver':
          // eslint-disable-next-line no-nested-ternary
          aVal = (a.assignedCaregivers && a.assignedCaregivers.length
            ? `${
                a.assignedCaregivers[0].lastName
                  ? a.assignedCaregivers[0].lastName.toLowerCase().replace(/\s/g, '')
                  : ''
              }${
                a.assignedCaregivers[0].firstName
                  ? a.assignedCaregivers[0].firstName.toLowerCase().replace(/\s/g, '')
                  : ''
              }`
            : a.actionable
            ? 'ZZZ'
            : 'ZZZZ'
          ).toUpperCase();
          // eslint-disable-next-line no-nested-ternary
          bVal = (b.assignedCaregivers && b.assignedCaregivers.length
            ? `${
                b.assignedCaregivers[0].lastName
                  ? b.assignedCaregivers[0].lastName.toLowerCase().replace(/\s/g, '')
                  : ''
              }${
                b.assignedCaregivers[0].firstName
                  ? b.assignedCaregivers[0].firstName.toLowerCase().replace(/\s/g, '')
                  : ''
              }`
            : b.actionable
            ? 'ZZZ'
            : 'ZZZZ'
          ).toUpperCase();
          break;
        case 'date':
          aVal = a.start;
          bVal = b.start;
          break;
        case 'startTime': {
          aVal =
            parseInt(
              moment(a.start)
                .tz(a.timezone.name)
                .format('H'),
              10
            ) *
              60 +
            parseInt(
              moment(a.start)
                .tz(a.timezone.name)
                .format('m'),
              10
            );
          bVal =
            parseInt(
              moment(b.start)
                .tz(b.timezone.name)
                .format('H'),
              10
            ) *
              60 +
            parseInt(
              moment(b.start)
                .tz(b.timezone.name)
                .format('m'),
              10
            );

          break;
        }
        case 'endTime':
          aVal =
            parseInt(
              moment(a.end)
                .tz(a.timezone.name)
                .format('H'),
              10
            ) *
              60 +
            parseInt(
              moment(a.end)
                .tz(a.timezone.name)
                .format('m'),
              10
            );
          bVal =
            parseInt(
              moment(b.end)
                .tz(b.timezone.name)
                .format('H'),
              10
            ) *
              60 +
            parseInt(
              moment(b.end)
                .tz(b.timezone.name)
                .format('m'),
              10
            );
          break;
        default:
          aVal = a[field];
          bVal = b[field];
      }

      if (aVal === bVal) return 0;
      if (aVal < bVal) return direction === 'ascending' ? -1 : 1;
      return direction === 'ascending' ? 1 : -1;
    });
  }

  static checkInitialRequest(props) {
    if (
      props.schedules &&
      !props.schedules.initialRequestDone &&
      props.uiState &&
      props.uiState.selectedPrimaryContacts !== undefined
    ) {
      const { selectedPrimaryContacts } = props.uiState;
      let primaryContact;
      if (
        selectedPrimaryContacts &&
        selectedPrimaryContacts.length &&
        !selectedPrimaryContacts.includes(0)
      ) {
        primaryContact = selectedPrimaryContacts;
      }
      const params = {
        shiftStatus: props.uiState.shiftStatus,
        primaryContact,
        shiftCompinedStatus: ['unassigned'],
        dateStart: moment().toISOString(),
        dateEnd: moment()
          .add(30, 'days')
          .toISOString(),
      };

      props.dispatch(schedulerActions.listSchedules(params));
    }
  }

  constructor() {
    super();
    this.handleDetailsClick = this.handleDetailsClick.bind(this);
    this.handleDetailsClose = this.handleDetailsClose.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.searchSelectedClick = this.searchSelectedClick.bind(this);

    this.selectCell = this.selectCell.bind(this);
    this.body = this.body.bind(this);
    this.onMeterChanged = this.onMeterChanged.bind(this);
    this.onLoadMore = this.onLoadMore.bind(this);

    this.handleUpdateClick = this.handleUpdateClick.bind(this);
    this.onShiftBuilderClick = this.onShiftBuilderClick.bind(this);
  }

  state = {
    column: 'date',
    data: [],
    direction: 'ascending',
    shiftDetailId: null,
    searchField: '',
    shiftPicked: {},
  };

  UNSAFE_componentWillMount() {
    this.setState({
      data: Schedules.sort(this.props.schedules.results, this.state.column, this.state.direction),
      column: 'date',
      direction: 'ascending',
      shiftDetailId: null,
      searchField: '',
      shiftPicked: {},
      meterState: {},
      firstNameSearch: 0,
    });

    Schedules.checkInitialRequest(this.props);
  }

  componentDidMount() {
    analytics.track(Events.SHIFTS_SELECT_TAB);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.schedules && !nextProps.schedules.loading) {
      this.setState(s => ({
        ...s,
        data: Schedules.sort(nextProps.schedules.results, this.state.column, this.state.direction),
      }));
    }

    if (
      nextProps.schedules.uniqueClients.length > 0 &&
      !nextProps.caregiverScores.initialRequestDone
    ) {
      this.props.dispatch(
        caregiverScoresActions.getClientCaregiverScores(nextProps.schedules.uniqueClients[0])
      );
    }

    Schedules.checkInitialRequest(nextProps);
  }

  onMeterChanged(state) {
    this.setState(s => ({
      ...s,
      meterState: { ...s.meterState, ...state },
    }));
  }

  onLoadMore() {
    this.props.dispatch(
      schedulerActions.listSchedules({ nextPage: this.props.schedules.nextPage }, undefined, true)
    );
  }

  onShiftBuilderClick = () => {
    history.push('/home/shifts/add');
  };

  handleDetailsClick = (e, input) => {
    this.setState(current => ({ ...current, shiftDetailId: input.shiftid }));
  };

  handleSearchChange = (_e, input) => {
    const { dispatch } = this.props;
    dispatch(uiStateActions.uiStateChange('shiftFilter', 'clientName', input.value));
    this.setState({
      searchField: input.value,
    });
  };

  handleSearchSubmit() {
    const { dispatch, uiState } = this.props;
    const { searchField } = this.state;
    this.pageSizer.scrollToTop();

    const fullTimeRange = uiState.timeRange.min === 1 && uiState.timeRange.max === 24;

    const timezone = moment.tz.guess();
    const { selectedPrimaryContacts } = uiState;
    let primaryContact;
    if (
      selectedPrimaryContacts &&
      selectedPrimaryContacts.length &&
      !selectedPrimaryContacts.includes(0)
    ) {
      primaryContact = selectedPrimaryContacts;
    }
    const params = {
      withText: searchField,
      shiftStatus: uiState.shiftStatus,
      shiftCompinedStatus: uiState.shiftCompinedStatus,
      dateStart: uiState.startDate
        .tz(timezone)
        .startOf('day')
        .toISOString(),
      dateEnd: uiState.endDate
        .tz(timezone)
        .endOf('day')
        .toISOString(),
      hoursStart: fullTimeRange ? undefined : uiState.timeRange.min,
      hoursEnd: fullTimeRange ? undefined : uiState.timeRange.max,
      primaryContact,
    };

    dispatch(schedulerActions.listSchedules(params));
    this.setState(s => ({
      ...s,
      direction: 'ascending',
      column: 'date',
    }));
    analytics.track(Events.SHIFTS_SEARCH_FIELD);
  }

  handleDetailsClose = () => {
    this.setState(current => ({ ...current, shiftDetailId: null }));
  };

  searchSelectedClick = () => {
    const { pickedShifts } = this.props.schedules;
    const shiftIdList = Object.keys(pickedShifts).filter(v => pickedShifts[v]);
    this.props.dispatch(caregiverActions.resetCaregiverList());
    this.props.dispatch(caregiverActions.setTargetShifts(shiftIdList));
    history.push('/home/caregivers');
    analytics.track(Events.SHIFTS_SEARCH_CAREGIVERS_MULTI);
  };

  handleSort = clickedColumn => () => {
    this.setState(s => {
      const { column, data } = s;
      if (!data) return { ...s };

      if (column !== clickedColumn) {
        return {
          ...s,
          column: clickedColumn,
          data: Schedules.sort(data, clickedColumn, 'ascending'),
          direction: 'ascending',
        };
      }
      const newDirection = s.direction === 'ascending' ? 'descending' : 'ascending';
      return {
        ...s,
        data: Schedules.sort(data, clickedColumn, newDirection),
        direction: newDirection,
      };
    });
  };

  handleUpdateClick() {
    this.pageSizer.scrollToTop();
  }

  selectCell(shift) {
    // checked={this.props.schedules.pickedShifts[shift.id]}
    if (this.isDisabled(shift)) {
      if (this.props.schedules.pickedShifts[shift.id]) {
        this.props.dispatch(schedulerActions.clickShiftCheckbox(shift));
      }
      return <span />;
    }

    return (
      <Checkbox
        checked={!!this.props.schedules.pickedShifts[shift.id]}
        onClick={() => {
          this.props.dispatch(schedulerActions.clickShiftCheckbox(shift));
        }}
      />
    );
  }

  isDisabled(shift) {
    return (
      !shift.actionable ||
      (this.props.schedules.activeClient && this.props.schedules.activeClient !== shift.clientId) ||
      (this.props.schedules.activeService && this.props.schedules.activeService !== shift.service)
    );
  }

  body(dispatch) {
    return (x, i) => ({
      key: x.id || `row-${i}`,
      disabled: x.actionable && this.isDisabled(x),
      cells: [
        {
          id: 'schedules-shift-table-header-date',
          key: 'date',
          content: <Overlay shift={x} />,
          textAlign: 'center',
        },
        {
          id: 'schedules-shift-table-header-client',
          key: 'client',
          content: <Overlay client={x.client} />,
        },
        {
          id: 'schedules-shift-table-header-service',
          key: 'service',
          content: x.service,
          textAlign: 'center',
        },
        {
          id: 'schedules-shift-table-header-caregiver',
          key: 'caregiver',
          content: caregiverCell(dispatch, x),
        },
        { key: 'invites', textAlign: 'center', ...invitesCell(dispatch, x) },
        {
          id: 'schedules-shift-table-header-startTime',
          key: 'startTime',
          textAlign: 'center',
          content: formatTime(x.start, x.timezone, MOMENT_HOURS),
        },
        {
          id: 'schedules-shift-table-header-endTime',
          key: 'endTime',
          textAlign: 'center',
          content: formatTime(x.end, x.timezone, MOMENT_HOURS),
        },
        {
          id: 'schedules-shift-table-header-hours',
          key: 'hours',
          textAlign: 'center',
          content: precisionRound(x.hours, 1),
        },
        { key: 'status', textAlign: 'center', ...statusItem(x.status) },
        {
          id: 'schedules-shift-table-header-extra',
          key: 'extra',
          textAlign: 'center',
          content: this.selectCell(x),
        },
      ],
    });
  }

  render() {
    const { schedules } = this.props;
    const { settings } = this.props.principal;
    const { column, data, direction, shiftDetailId, searchField } = this.state;

    const shiftCount = data ? data.length : 0;

    const selectAllDisabled = !schedules.activeClient || !schedules.activeService;
    const selectAllLabelClass = `checkbox-label-left${selectAllDisabled ? ' disabled' : ''}`;

    const initState = !data || (schedules.loading && !schedules.partialUpdate);

    return (
      <Grid.Row className='noVerticalPadding'>
        {shiftDetailId && (
          <ScheduleDetails shiftId={shiftDetailId} onCloseClicked={this.handleDetailsClose} />
        )}
        <Grid padded style={{ width: '100%' }} className='contentGrid'>
          <Grid.Column width='three' textAlign='center' className='menuCol'>
            <SchedulesMenu loading={schedules.loading} onUpdateClick={this.handleUpdateClick} />
            <PageMeter name='bottom' onChange={this.onMeterChanged} />
          </Grid.Column>

          <Grid.Column width='13'>
            <Grid padded>
              <Grid.Column
                width='three'
                verticalAlign='middle'
                className='noVerticalPadding headerWithCount'
              >
                <Header floated='left' as='h3'>
                  SHIFTS
                </Header>
                <div id='schedules-shift-count'>({shiftCount})</div>
                <Header as='h3' />
              </Grid.Column>
              <Grid.Column width='8' className='noVerticalPadding'>
                <Form size='tiny' onSubmit={this.handleSearchSubmit}>
                  <Form.Group className='menu-input-with-button' inline>
                    <Form.Input
                      width='twelve'
                      id='shiftsSearchName'
                      size='tiny'
                      inline
                      floated='left'
                      placeholder='Find by Client Name'
                      value={searchField}
                      onChange={this.handleSearchChange}
                    />
                    <Form.Button
                      id='dashboardSearchShiftSubmit'
                      className='search-input-with-button'
                      circular
                      loading={schedules.loading}
                    >
                      Go
                    </Form.Button>
                  </Form.Group>
                </Form>
              </Grid.Column>
              <Grid.Column
                width='five'
                textAlign='right'
                verticalAlign='middle'
                className='noVerticalPadding right'
              >
                {settings && settings.standaloneMode ? (
                  <span className='add-button-with-label'>
                    <span>Add New Shifts</span>
                    <CareRoundButton
                      content='+'
                      onClick={this.onShiftBuilderClick}
                      id='schedules-add-new-shift-button'
                    />
                  </span>
                ) : (
                  undefined
                )}
              </Grid.Column>
            </Grid>
            <Grid padded centered>
              <PageSizer
                meterState={this.state.meterState}
                active={data && data.length > 0 && !initState}
                contentHeight={data ? data.length * 50 : 0}
                ref={node => {
                  this.pageSizer = node;
                }}
              >
                <PageMeter name='top' onChange={this.onMeterChanged} />
                <Table
                  className='stickyHeader'
                  id='shiftsResultsTable'
                  sortable
                  striped
                  headerRow={headers(null, column, direction, this.handleSort)}
                  renderBodyRow={this.body(this.props.dispatch, this.handleDetailsClick)}
                  tableData={!schedules.partialUpdate && schedules.loading ? [] : data}
                />
              </PageSizer>
              <LoadMore
                onLoadMore={this.onLoadMore}
                loading={schedules.loading}
                partialUpdate={schedules.partialUpdate}
                nextPage={schedules.nextPage}
              />
              {data && data.length === 0 && !schedules.loading && (
                <NoMatch id='shiftsResultsNoMatch' tooManyResults={schedules.tooManyResults} />
              )}
              {initState && <InitialState searchingFor='shifts' loading={schedules.loading} />}
              <Grid.Row>
                <Grid.Column width='16' textAlign='right'>
                  <Button
                    id='shiftsResultsSearchButton'
                    className='care-green'
                    size='mini'
                    disabled={schedules.inviteDisabled}
                    value='offered'
                    onClick={this.searchSelectedClick}
                  >
                    Search Selected
                  </Button>
                  <span className={selectAllLabelClass}>Select All</span>
                  <Checkbox
                    style={{ paddingRight: '2rem' }}
                    id='shiftsResultsSelectAll'
                    onClick={() => this.props.dispatch(schedulerActions.selectAllClicked())}
                    checked={schedules.allSelected}
                    disabled={selectAllDisabled}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid>
      </Grid.Row>
    );
  }
}

Schedules.defaultProps = {
  activeClient: null,
  caregiverScores: null,
};

Schedules.propTypes = {
  dispatch: PropTypes.func.isRequired,
  principal: PropTypes.shape().isRequired,
  schedules: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape()),
    loading: PropTypes.bool,
    pickedShifts: PropTypes.shape(),
    activeClient: PropTypes.number,
    activeService: PropTypes.string,
    nextPage: PropTypes.shape(),
    partialUpdate: PropTypes.bool,
    uniqueClients: PropTypes.shape(),
  }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  uiState: PropTypes.shape({
    shiftFilter: PropTypes.arrayOf(PropTypes.string),
    selectedPrimaryContacts: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  activeClient: PropTypes.number,
  caregiverScores: PropTypes.shape(),
};

const mapStateToProps = state => {
  const { schedules, principal, caregiverScores } = state;
  return {
    schedules,
    principal,
    caregiverScores,
  };
};

export default connect(uiStateActions.mapStateOfGroupToProps('shiftFilter', mapStateToProps))(
  Schedules
);

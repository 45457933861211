/* eslint-disable import/prefer-default-export */
import * as types from '../constants/ActionTypes';
import { backend } from '../services';
// eslint-disable-next-line import/no-cycle
import { alertActions } from './alert';

function getFeed() {
  return dispatch => {
    dispatch(request());

    backend.getFeed().then(
      feed => {
        dispatch(success(feed));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function request() {
    return { type: types.FEED_REQUEST };
  }
  function success(feed) {
    return { type: types.FEED_SUCCESS, feed };
  }
  function failure(error) {
    return { type: types.FEED_FAILURE, error };
  }
}

export const feedActions = {
  getFeed,
};

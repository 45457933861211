/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Header, Grid, Select, Icon } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import RecordsPrimaryContacts from '../RecordsPrimaryContacts';
import {
  RecordsGrid,
  RecordsValueUneditable,
  RecordsHeader,
  RecordsSubHeader,
  RecordsTile,
} from '../../../styles/records';
import {
  clientStatusOptions,
  getClientStatusData,
  getStatusDotColor,
} from '../../../constants/DomainTypes';
import { formatTime, SHORT_DATE } from '../../../constants/Formats';

class TileAgencyContacts extends React.PureComponent {
  render() {
    const {
      person,
      original,
      intl,
      onValueChange,
      onAgencyChange,
      employees,
      type,
      editable,
      editability,
    } = this.props;

    const isCaregiver = type === 'caregiver';
    const isClient = type === 'client';
    const clientStatus = isClient && getClientStatusData(person);
    const date =
      original.status === person.status
        ? formatTime(clientStatus.date, null, SHORT_DATE)
        : formatTime(moment.tz(null).format('YYYY-MM-DD'), null, SHORT_DATE);

    const title = intl.formatMessage({ id: 'records.agencyContacts' }).toUpperCase();
    const subTitle = intl.formatMessage({ id: `records.agencyContacts.details.${type}` });
    const since = intl.formatMessage({ id: 'records.since' });
    const status = intl.formatMessage({ id: 'records.status' });
    const lastSinceStyle = { paddingTop: editable ? '15px' : '7px' };
    const lastStatusStyle = { paddingLeft: editable ? '15px' : '10px' };
    const statusDot = (
      <Icon
        name='circle'
        size='small'
        id='form-agency-client-status-dot'
        style={getStatusDotColor(person.status, editable)}
      />
    );

    return (
      <RecordsTile>
        <RecordsHeader>{title}</RecordsHeader>
        <RecordsSubHeader>{subTitle}</RecordsSubHeader>
        <RecordsGrid padded='vertically'>
          <RecordsPrimaryContacts
            object={person}
            employees={employees}
            editable={editable}
            editability={editability}
            onValueChange={onAgencyChange}
            isCaregiver={isCaregiver}
          />
          {isCaregiver && <Grid.Row />}
          {isClient && (
            <div style={{ width: '100%' }}>
              <hr style={{ marginBottom: '21px' }} />
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form.Field required={editable}>
                    <label htmlFor='status'>{status}</label>
                    {statusDot}
                    <Select
                      id='form-agency-client-details-last-status'
                      className={editable ? 'vertical-line' : null}
                      name='status'
                      style={lastStatusStyle}
                      options={clientStatusOptions}
                      placeholder='Choose'
                      onChange={onValueChange}
                      value={person.status}
                      disabled={!editable}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header
                    style={{ marginBottom: '0px', position: 'relative', top: '6px' }}
                    size='tiny'
                  >
                    {since}
                  </Header>
                  <RecordsValueUneditable style={lastSinceStyle}>
                    {clientStatus && date}
                  </RecordsValueUneditable>
                </Grid.Column>
              </Grid.Row>
            </div>
          )}
        </RecordsGrid>
      </RecordsTile>
    );
  }
}

TileAgencyContacts.defaultProps = {
  person: {},
  onValid: () => {},
  onInvalid: () => {},
  type: 'caregiver',
  editability: {},
};

TileAgencyContacts.propTypes = {
  // eslint-disable-next-line react/require-default-props
  person: PropTypes.shape().isRequired,
  original: PropTypes.shape().isRequired,
  editable: PropTypes.bool.isRequired,
  employees: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  intl: intlShape.isRequired,
  onAgencyChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  editability: PropTypes.arrayOf(PropTypes.shape()),
};

export default injectIntl(TileAgencyContacts);

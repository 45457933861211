/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Grid,
  Image,
  Divider,
  Modal,
  Table,
  Form,
  Dropdown,
  Icon,
  Dimmer,
} from 'semantic-ui-react';
import './styles.css';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import { RecordsTile } from '../../../styles/records';
import CareRoundButton from '../CareRoundButton';
import { backend } from '../../../services';
import { GreenButton } from '../../../styles/common';
import { uploadFile } from '../../../helpers/common';
import { alertActions } from '../../../actions';
import INIT_CONFIG from './initConfig';
import CareLoader from '../CareLoader';

const CUSTOM_FIELDS_TYPES = [
  {
    id: 'textField',
    value: 'textField',
    text: 'Text Field',
  },
  {
    id: 'dropdown',
    value: 'dropdown',
    text: 'Dropdown',
  },
  {
    id: 'dateSelector',
    value: 'dateSelector',
    text: 'Date Selector',
  },
  {
    id: 'checkbox',
    value: 'checkbox',
    text: 'Checkbox',
  },
  {
    id: 'multiCheckbox',
    value: 'multiCheckbox',
    text: 'Multi Checkbox',
  },
  {
    id: 'multiDropdown',
    value: 'multiDropdown',
    text: 'Multi Dropdown',
  },
];

const FIELD_TYPES = {
  textField: {
    label: 'Text Field',
  },
  birthdayField: {
    label: 'Date Selector',
  },
  ssnField: {
    label: 'Text Field',
  },
  addressField: {
    label: 'Text Field',
  },
  genderSelector: {
    label: 'Selector',
  },
  dropdown: {
    label: 'Dropdown',
  },
  previousEmployersField: {
    label: 'Fields',
  },
  idUploadField: {
    label: 'Doc Uploader',
  },
  ageDeclarationField: {
    label: 'Checkbox',
  },
  dateSelector: {
    label: 'Date Selector',
  },
  multiCheckbox: {
    label: 'Multi Checkbox',
  },
  checkbox: {
    label: 'Checkbox',
  },
  primaryLanguageField: {
    label: 'Dropdown',
  },
  additionalLanguageField: {
    label: 'Multi Dropdown',
  },
  travelTimeField: {
    label: 'Slider',
  },
  travelMethodSelector: {
    label: 'Selector',
  },
  multiDropdown: {
    label: 'Multi Dropdown',
  },
};

class ApplicationToolConfig extends React.Component {
  state = {
    activeModalKey: null,
    config: JSON.parse(INIT_CONFIG),
    original: JSON.parse(INIT_CONFIG),
    loading: false,
  };

  componentDidMount() {
    this.getStateFromProps();
  }

  getStateFromProps = () => {
    const { applicationToolConfig } = this.props;
    if (
      !Object.entries(applicationToolConfig.constructor === Object ? applicationToolConfig : {})
        .length
    ) {
      return;
    }
    const newConfig = {
      ...JSON.parse(JSON.stringify(applicationToolConfig)),
      pages: this.sortPages(applicationToolConfig),
    };
    const newOriginal = {
      ...JSON.parse(JSON.stringify(newConfig)),
    };
    this.setState({
      config: newConfig,
      original: newOriginal,
    });
  };

  sortPages = param => {
    if (!param?.pages) return [];
    const sorted = {};
    Object.entries(param.pages)
      .sort((a, b) => a[1].order - b[1].order)
      .forEach(([key, val]) => {
        sorted[key] = val;
      });
    return sorted;
  };

  updateConfig = updated => {
    const newConfig = { ...updated };
    this.setState({ config: newConfig });
  };

  saveChanges = key => {
    const { config } = this.state;
    const updated = { ...config };
    updated.pages[key].fields = updated.pages[key].fields.map(x => ({
      ...x,
      pending: undefined,
    }));
    this.props.onValueUpdated(config);
    this.setState({ activeModalKey: true, original: JSON.parse(JSON.stringify(config)) });
  };

  updateApplicationSteps = updated => {
    this.setState({ config: updated });
    this.props.onValueUpdated(updated);
  };

  updateImage = async (event, key) => {
    const [file] = event.target?.files;
    if (file) {
      this.setState({ loading: true });
      const { uploadUrl, fileUrl } = await backend.getAgencyFileUploadUrl(
        file.name,
        file.type,
        true
      );
      uploadFile(uploadUrl, file)
        .then(() => {
          const updated = { ...this.state.config };
          updated.layout[key] = fileUrl;
          this.updateConfig(updated);
          return updated;
        })
        .then(updated => this.props.onValueUpdated(updated))
        .catch(error => {
          this.props.dispatch(
            alertActions.errorNotification(`File upload failed: ${error.message}`)
          );
        })
        .finally(() => this.setState({ loading: false }));
    }
  };

  editFieldsTable = (key, page, config) => (
    <>
      <div>
        <Table
          id='basic-information'
          tableData={page?.fields}
          headerRow={[
            {
              id: 'fields-header-name',
              key: 'name',
              width: '4',
              content: 'Name',
            },
            {
              id: 'fields-header-type',
              key: 'type',
              width: '2',
              content: 'Field Type',
            },
            {
              id: 'fields-header-options',
              key: 'options',
              width: '2',
              content: 'Options',
            },
            {
              id: 'fields-header-visible',
              key: 'visible',
              width: '1',
              content: 'Visible',
            },
            {
              id: 'fields-header-required',
              key: 'required',
              width: '1',
              content: 'Required',
            },
            {
              id: 'fields-header-actions',
              key: 'actions',
              width: '1',
              content: '',
            },
          ]}
          renderBodyRow={x => ({
            key: x.id,
            className: x.pending ? 'pending-save-field' : '',
            cells: [
              {
                key: 'name',
                content: x.pending ? (
                  <Form.Input
                    id={`${x.id}-name`}
                    required
                    value={x.label}
                    placeholder='Enter Field Name'
                    onChange={(_, { value }) => {
                      const updated = { ...config };
                      updated.pages[key].fields.find(z => z.id === x.id).label = value;
                      this.updateConfig(updated);
                    }}
                  />
                ) : (
                  <div className='name-cell-value'>{x.label}</div>
                ),
              },
              {
                key: 'type',
                content: x.pending ? (
                  <Dropdown
                    id={`${x.id}-type`}
                    selection
                    options={CUSTOM_FIELDS_TYPES}
                    closeOnChange
                    upward
                    className='field-type-selector'
                    value={x.type}
                    onChange={(_, { value }) => {
                      const updated = { ...config };
                      const field = updated.pages[key].fields.find(z => z.id === x.id);
                      field.type = value;

                      if (
                        value === 'dropdown' ||
                        value === 'multiDropdown' ||
                        value === 'multiCheckbox'
                      ) {
                        if (!field.options) {
                          field.options = ['Option 1', 'Option 2'];
                        }
                      } else {
                        field.options = undefined;
                      }
                      this.updateConfig(updated);
                    }}
                  />
                ) : (
                  FIELD_TYPES[x.type]?.label
                ),
              },
              {
                key: 'options',
                content:
                  x.pending &&
                  (x.type === 'dropdown' ||
                    x.type === 'multiDropdown' ||
                    x.type === 'multiCheckbox') ? (
                    <Form.Input
                      id={x.id}
                      name={x.id}
                      required
                      value={x.options && x.options.join(',')}
                      onChange={(_, { value }) => {
                        const updated = { ...config };
                        const options = value?.split(',').map(s => s.trimStart()) || [];
                        updated.pages[key].fields.find(z => z.id === x.id).options = options;
                        this.updateConfig(updated);
                      }}
                    />
                  ) : (
                    x.options?.map(o => (
                      <div className='options-cell-value'>
                        {o}
                        <br />
                      </div>
                    )) || 'N/A'
                  ),
              },
              {
                key: 'visible',
                content: (
                  <Form.Checkbox
                    className={x.deletable ? '' : 'round disabled-checkbox'}
                    checked={x.visible}
                    toggle={x.deletable}
                    onChange={() => {
                      const updated = { ...config };
                      updated.pages[key].fields.find(z => z.id === x.id).visible = !x.visible;
                      this.updateConfig(updated);
                    }}
                  />
                ),
              },
              {
                key: 'required',
                content: (
                  <Form.Checkbox
                    className={x.deletable ? '' : 'round disabled-checkbox'}
                    checked={x.required}
                    toggle={x.deletable}
                    onChange={() => {
                      const updated = { ...config };
                      updated.pages[key].fields.find(z => z.id === x.id).required = !x.required;
                      this.updateConfig(updated);
                    }}
                  />
                ),
              },
              {
                key: 'actions',
                content: x.custom ? (
                  <>
                    {!x.pending && (
                      <Icon
                        className='action-icon'
                        size='large'
                        name='pencil'
                        onClick={() => {
                          const updated = { ...config };
                          updated.pages[key].fields.find(z => z.id === x.id).pending = true;
                          this.updateConfig(updated);
                        }}
                      />
                    )}
                    <Icon
                      className='action-icon'
                      size='large'
                      name='trash'
                      onClick={() => {
                        const updated = { ...config };
                        updated.pages[key].fields = updated.pages[key].fields.filter(
                          z => z.id !== x.id
                        );
                        this.updateConfig(updated);
                      }}
                    />
                  </>
                ) : (
                  ''
                ),
              },
            ],
          })}
        />

        <div className='add-custom-field-row'>
          <Button
            id='add-custom-field-button'
            circular
            basic
            icon='plus'
            size='mini'
            onClick={() => {
              const updated = { ...config };
              const order = updated.pages[key].fields.length + 1;
              updated.pages[key].fields.push({
                deletable: true,
                id: uuid(),
                label: '',
                order,
                required: true,
                type: 'textField',
                visible: true,
                pending: true,
                custom: true,
              });
              this.updateConfig(updated);
            }}
          />
        </div>
      </div>
    </>
  );

  editTextArea = (key, page, config) => (
    <textarea
      rows={10}
      className='text-area'
      value={page?.fields?.find(x => x.id === 'header')?.value}
      onChange={e => {
        const updated = { ...config };
        updated.pages[key].fields.find(x => x.id === 'header').value = e.target.value;
        this.updateConfig(updated);
      }}
    />
  );

  pageDetails = (key, page, config) => {
    const pages = {
      agencyWelcomeNote: {
        title: '1. Agency Welcome Note',
        description: 'Customizable introductory note from your agency',
        modalContent: this.editTextArea(key, page, config),
      },
      basicInformation: {
        title: '2. Basic Information',
        description: 'Name, Email, Birthday, SSN',
        modalContent: this.editFieldsTable(key, page, config),
      },
      additionalInformation: {
        title: '3. Additional Information',
        description: 'Address, Cell Phone, Gender, optional Home Phone',
        modalContent: this.editFieldsTable(key, page, config),
      },
      workExperience: {
        title: '4. Work Experience',
        description: 'Can help determine the type of role or level of a candidate',
        modalContent: this.editFieldsTable(key, page, config),
      },
      backgroundInformation: {
        title: '5. Background Information',
        description: 'ID upload/snap, Age declaration',
        modalContent: this.editFieldsTable(key, page, config),
      },
      scheduleWorkPreferences: {
        title: '6. Schedule & Work Preferences',
        description: 'Key shift matching attributes',
        modalContent: this.editFieldsTable(key, page, config),
      },
      shiftClientPreferences: {
        title: '7. Shift & Client Preferences',
        description: 'Additional attributes for further refine matching',
        modalContent: this.editFieldsTable(key, page, config),
      },
      completionPage: {
        title: '8. Completion Confirmation',
        description: 'Note to applicant on next steps and what to expect',
        modalContent: this.editTextArea(key, page, config),
      },
    };

    return pages[key];
  };

  pageFragment = (key, page, config) => {
    const { title, description, modalContent } = this.pageDetails(key, page, config);
    const { activeModalKey } = this.state;

    return (
      <>
        <Grid.Row className={page.active ? '' : 'disabled'}>
          <Grid.Column width={4}>
            <span className='description'>{title}</span>
          </Grid.Column>
          <Grid.Column width={6}>
            <span className='description-small'>{description}</span>
          </Grid.Column>
          <Grid.Column width={2}>
            <span>&nbsp;</span>
          </Grid.Column>
          <Grid.Column width={2}>
            <span
              id={`${key}-page-edit`}
              className={page.active ? 'enabled-link' : 'disabled-link'}
              onClick={() => this.setState({ activeModalKey: key })}
              role='presentation'
            >
              edit
            </span>
            {activeModalKey && activeModalKey === key && (
              <Modal
                closeOnDimmerClick={false}
                open
                onClose={this.onModalClose}
                closeIcon
                dimmer
                className='edit-modal'
              >
                <div className='modal-header'>
                  <h2>{title}</h2>
                  <span className='description-small'>{description}</span>
                </div>
                <div className='modal-content'>{modalContent}</div>
                <div className='modal-footer'>
                  <GreenButton
                    content='Save'
                    disabled={this.validateData(key)}
                    onClick={() => {
                      this.saveChanges(key);
                    }}
                  />
                </div>
              </Modal>
            )}
          </Grid.Column>
          <Grid.Column width={1}>
            {!page.required && (
              <span className='description'>{page.active ? 'Active' : 'Skipped'}</span>
            )}
            {page.required && <span className='description'>Required</span>}
          </Grid.Column>
          <Grid.Column width={1}>
            {!page.required && (
              <Checkbox
                id={`${key}-page-checkbox`}
                checked={page.active}
                onChange={() => {
                  const updated = { ...config };
                  updated.pages[key] = { ...page, active: !page.active };
                  this.updateApplicationSteps(updated);
                }}
                toggle
              />
            )}
          </Grid.Column>
        </Grid.Row>
        <Divider />
      </>
    );
  };

  onModalClose = () => {
    const { original } = this.state;
    this.setState({ activeModalKey: null, config: JSON.parse(JSON.stringify(original)) });
  };

  validateData = key => {
    let notValid = false;
    const { config, original } = this.state;
    if (JSON.stringify(config) === JSON.stringify(original)) {
      notValid = true;
    }
    const fields = Object.keys(config.pages[key].fields);
    fields.forEach(index => {
      if (config.pages[key].fields[index].value === '') {
        notValid = true;
      }
      if (config.pages[key].fields[index].label === '') {
        notValid = true;
      }
      if (
        config.pages[key].fields[index].type === 'dropdown' ||
        config.pages[key].fields[index].type === 'multiDropdown' ||
        config.pages[key].fields[index].type === 'multiCheckbox'
      ) {
        if (
          config.pages[key].fields[index].options.length &&
          config.pages[key].fields[index].options[0] === ''
        ) {
          notValid = true;
        }
      }
    });
    return notValid;
  };

  render() {
    const { config } = this.state;

    return (
      <Grid padded>
        {this.state.loading && (
          <Dimmer active inverted>
            <CareLoader loading centered showText={false} />
          </Dimmer>
        )}
        <Grid.Row>
          <Grid.Column width={8}>
            <h3>General Settings</h3>
          </Grid.Column>
          <Grid.Column width={8}>
            <h3>Network Domain Settings</h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <RecordsTile className='general-settings-tile'>
              <div style={{ position: 'relative' }}>
                <Image
                  centered
                  ui={false}
                  src={config?.layout?.headerImage}
                  className='header-image'
                />
                <div className='add-header-image-container'>
                  <CareRoundButton
                    id='add-header-image-button'
                    className='care-round-button add-image-button'
                    content='+'
                    onClick={() => this.headerUploadInput.click()}
                  />
                  <input
                    type='file'
                    accept='.jpg,.png,.jpeg'
                    id='header-upload-input'
                    hidden
                    ref={e => {
                      this.headerUploadInput = e;
                    }}
                    onChange={e => this.updateImage(e, 'headerImage')}
                  />
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <Image
                  centered
                  ui={false}
                  src={config?.layout?.logoImage}
                  className='agency-logo'
                />
                <div className='add-logo-container'>
                  <CareRoundButton
                    id='add-logo-button'
                    className='care-round-button add-image-button'
                    content='+'
                    onClick={() => this.logoUploadInput.click()}
                  />
                  <input
                    type='file'
                    accept='.jpg,.png,.jpeg'
                    id='logo-upload-input'
                    hidden
                    ref={e => {
                      this.logoUploadInput = e;
                    }}
                    onChange={e => this.updateImage(e, 'logoImage')}
                  />
                </div>
              </div>
              <Grid.Row className='general-settings-header'>
                <p className='description'>Visual Customization</p>
                <p className='description-small'>
                  Add your own agency icon and background image to your application tool to make
                  them your own.
                </p>
              </Grid.Row>
            </RecordsTile>
          </Grid.Column>
          <Grid.Column width={8}>
            <RecordsTile>
              <Grid.Row>
                <p className='description'>Application Tool URL</p>
                <p className='description-small'>
                  Add your own unique URL to your application tool. When you save Application Tool
                  setting, system will automatically validate given URL and submit a configuration
                  request to CareConnect DevOps team. Configuration requests are processed within a
                  next business day.
                </p>
                <p className='description-small'>
                  If you need to change your existing URL, please contact CareConnect Customer
                  Support.
                </p>
                <br />
                {this.state?.config?.network?.domain && (
                  <span className='description-small'>
                    <a
                      rel='noopener noreferrer'
                      target='_blank'
                      href={`https://${this.state.config.network.domain}`}
                    >{`https://${this.state.config.network.domain}`}</a>
                  </span>
                )}
                {!this.state.config?.network?.locked && (
                  <span
                    id='url-page-edit'
                    className='url-edit-link enabled-link'
                    onClick={() => this.setState({ activeModalKey: 'urlEdit' })}
                    role='presentation'
                  >
                    edit
                  </span>
                )}
                {this.state.activeModalKey === 'urlEdit' && (
                  <Modal
                    closeOnDimmerClick={false}
                    open
                    onClose={() => this.setState({ activeModalKey: null })}
                    closeIcon
                    dimmer
                    className='edit-modal'
                  >
                    <div className='modal-header'>
                      <h2>Application Tool URL</h2>
                      <span className='description-small'>Edit your application tool URL</span>
                    </div>
                    <div className='modal-content'>
                      <Form.Input
                        id='url-prefix'
                        required
                        label='URL Prefix: '
                        value={
                          this.state.config?.network?.prefix !== undefined
                            ? this.state.config?.network?.prefix
                            : this.state.config?.network?.domain?.split('.')[0]
                        }
                        width='32'
                        style={{ width: '30%' }}
                        onChange={(_, { value }) => {
                          if (value.match(/^[a-z0-9-]{0,32}$/)) {
                            const updated = { ...config };
                            updated.network.prefix = value;
                            this.updateConfig(updated);
                          }
                        }}
                      />
                    </div>
                    <div className='modal-footer'>
                      <GreenButton
                        content='Save'
                        disabled={!this.state.config?.network?.prefix}
                        onClick={() => {
                          const suffix =
                            config.network.domain.indexOf('.') >= 0
                              ? `.${config.network.domain
                                  .split('.')
                                  ?.slice(1)
                                  ?.join('.')}`
                              : '';
                          const updated = { ...config };
                          updated.network.domain = `${config.network.prefix}${suffix}`;
                          this.updateConfig(updated, true);
                          this.props.onValueUpdated(updated);
                        }}
                      />
                    </div>
                  </Modal>
                )}
              </Grid.Row>
            </RecordsTile>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <h3>Application Steps</h3>
            <span className='description-small'>
              Edit and customize what information is to be gathered for each step of your
              application flow.
            </span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <RecordsTile>
              <Grid>
                {Object.entries(config?.pages).map(entry => {
                  const [key, page] = entry;
                  return this.pageFragment(key, page, config);
                })}
              </Grid>
            </RecordsTile>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

ApplicationToolConfig.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onValueUpdated: PropTypes.func.isRequired,
  applicationToolConfig: PropTypes.shape().isRequired,
};

export default connect()(ApplicationToolConfig);

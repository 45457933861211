import {
  GET_COMPLIANCE_HR_DASHBOARD_REQUEST,
  GET_COMPLIANCE_HR_DASHBOARD_FAILURE,
  GET_COMPLIANCE_HR_DASHBOARD_SUCCESS,
} from '../../constants/ActionTypes';

const initialState = {
  loading: false,
  error: false,
  caregiverNumbers: {},
};

const startState = JSON.parse(JSON.stringify(initialState));

function hrDashboard(state = startState, action) {
  switch (action.type) {
    case GET_COMPLIANCE_HR_DASHBOARD_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_COMPLIANCE_HR_DASHBOARD_FAILURE:
      return { ...state, loading: false, error: true };
    case GET_COMPLIANCE_HR_DASHBOARD_SUCCESS:
      return { ...state, loading: false, error: false, caregiverNumbers: action.results };
    default:
      return state;
  }
}

export default hrDashboard;

/* eslint-disable import/prefer-default-export */
import {
  GET_CAREGIVER_SURVEY_REQUEST,
  GET_CAREGIVER_SURVEY_SUCCESS,
  GET_CAREGIVER_SURVEY_FAILURE,
  GET_CLIENT_SURVEY_REQUEST,
  GET_CLIENT_SURVEY_SUCCESS,
  GET_CLIENT_SURVEY_FAILURE,
  GET_SURVEY_SUMMERY_REQUEST,
  GET_SURVEY_SUMMERY_SUCCESS,
  GET_SURVEY_SUMMERY_FAILURE,
  GET_SURVEY_DETAILS_REQUEST,
  GET_SURVEY_DETAILS_SUCCESS,
  GET_SURVEY_DETAILS_FAILURE,
} from '../../constants/ActionTypes';
import { backend } from '../../services';
import { alertActions } from '../alert';

function getCaregiverSurvey(id) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.getCaregiverSurvey(id);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: GET_CAREGIVER_SURVEY_REQUEST };
  }
  function success(results) {
    return { type: GET_CAREGIVER_SURVEY_SUCCESS, results };
  }
  function failure(error) {
    return { type: GET_CAREGIVER_SURVEY_FAILURE, error };
  }
}

function getClientSurvey(id) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.getClientSurvey(id);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: GET_CLIENT_SURVEY_REQUEST };
  }
  function success(results) {
    return { type: GET_CLIENT_SURVEY_SUCCESS, results };
  }
  function failure(error) {
    return { type: GET_CLIENT_SURVEY_FAILURE, error };
  }
}

function getSurveySummery() {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.getSurveySummery();
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: GET_SURVEY_SUMMERY_REQUEST };
  }
  function success(results) {
    return { type: GET_SURVEY_SUMMERY_SUCCESS, results };
  }
  function failure(error) {
    return { type: GET_SURVEY_SUMMERY_FAILURE, error };
  }
}

function getSurveyDetails(date) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.getSurveyDetails(date);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: GET_SURVEY_DETAILS_REQUEST };
  }
  function success(results) {
    return { type: GET_SURVEY_DETAILS_SUCCESS, results };
  }
  function failure(error) {
    return { type: GET_SURVEY_DETAILS_FAILURE, error };
  }
}

export const CovidSurveyActions = {
  getCaregiverSurvey,
  getSurveySummery,
  getSurveyDetails,
  getClientSurvey,
};

/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';
import '../Profile.css';

class ELearningProfileAssignments extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const courses = this.props.courses?.length
      ? this.props.courses.filter(c => {
          const courseExemption = c.curriculums.map(e => e.exemption);
          const courseExempted = courseExemption.every(ex => ex === true);
          if (!courseExempted) {
            return c;
          }
          return null;
        })
      : [];

    const sortedCourses = courses?.sort((a, b) =>
      a.isRegistered && !a.completionDate ? -1 : a.completionDate - b.completionDate
    );

    const { loading } = this.props;

    return (
      <div className='profile-e-learning-progress-container'>
        <div className='profile-e-learning-assignment-header'>
          <h6 className='profile-e-learning-progress-header'>E-LEARNING ASSIGNMENT</h6>
        </div>
        <div className='profile-e-learning-assignment-container'>
          {loading && (
            <div>
              <Image
                src='/loader_anim.gif'
                style={{ width: '100px', height: 'auto' }}
                className='quiz-modal-loader-image'
                centered
              />
            </div>
          )}
          {!loading && (
            <div>
              <h2 className='profile-e-learning-assignment-text'>
                Courses and modules shown based on agency role and group assignment
              </h2>
              {courses && courses.length ? (
                sortedCourses.map(c => {
                  let status;
                  if (c.completionDate) {
                    status = 'completed';
                  } else if (c.courseStatus === 'EXPIRED') {
                    status = 'expired';
                  } else if (c.courseStatus === 'INVISIBLE') {
                    status = 'invisible';
                  } else if (c.isRegistered && !c.completionDate) {
                    status = 'started';
                  } else {
                    status = 'pending';
                  }
                  return (
                    <div className='profile-e-learning-course'>
                      <p className='profile-e-learning-course-name'>{c.courseName}</p>
                      <p className={`profile-e-learning-course-status-${status}`}>
                        {status === 'started' ? 'in progress' : status}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div className='profile-e-learning-course-empty'>
                  <img
                    className='e-learning-empty-course-view-image'
                    src='/complete-courses-empty.svg'
                    alt='empty view'
                  />
                  <p className='profile-e-learning-assignment-text' style={{ textAlign: 'center' }}>
                    No courses have been assigned to this user
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

ELearningProfileAssignments.defaultProps = {
  courses: [],
};

ELearningProfileAssignments.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool.isRequired,
};

export default ELearningProfileAssignments;

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Dropdown, Image, Table } from 'semantic-ui-react';
import CareLoader from '../CareLoader';
import '../../ELManage.css';

class ELUsersTable extends Component {
  constructor(props) {
    super(props);
    this.tableRef = [];
    this.state = {
      column: 'firstName',
      reverted: false,
      sortedList: [],
      firstSort: false,
    };
  }

  componentDidMount() {
    const { column } = this.state;
    const { data } = this.props;
    this.sortTable(column, data);
  }

  // eslint-disable-next-line react/sort-comp
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data && !prevState.sortedList?.length && !prevState.firstSort) {
      const sortedList = nextProps.data.sort((a, b) =>
        a[prevState.column] < b[prevState.column] ? -1 : 1
      );
      return { sortedList, reverted: false };
    }
    return null;
  }

  onHeaderClick = (e, body) => {
    const { column, sortedList, reverted } = this.state;
    if (e === column) {
      if (sortedList.length === body.length) {
        const reverseList = sortedList.reverse();
        this.setState({ sortedList: reverseList });
      } else {
        this.sortTable(e, body);
      }
      this.setState({ reverted: !reverted });
    } else {
      this.setState({ column: e });
      this.sortTable(e, body);
    }
  };

  sortTable = (value, column) => {
    if (value === 'programs') {
      const sortedList = column?.sort((a, b) =>
        a.programs[0]?.programName < b.programs[0]?.programName ? -1 : 1
      );
      return this.setState({ sortedList, reverted: true });
    }
    const sortedList = column?.sort((a, b) => (a[value] < b[value] ? -1 : 1));
    return this.setState({ sortedList, reverted: false });
  };

  render() {
    const { column, reverted } = this.state;
    const {
      data,
      onNameClick,
      programs,
      programValue,
      onFilterProgramChange,
      onLoadMore,
      loadMore,
      loading,
    } = this.props;

    const header = [
      {
        id: 'el-manage-users',
        key: 'Name',
        width: '3',
        content: (
          <p
            className={`e-learning-table-header-tab ${column === 'firstName' ? 'selected' : ''}-${
              reverted ? 'up' : 'down'
            }`}
          >
            Name
          </p>
        ),
        onClick: () => this.onHeaderClick('firstName', data),
        textAlign: 'left',
      },
      {
        id: 'el-manage-users',
        key: 'email',
        width: '3',
        content: (
          <p
            className={`e-learning-table-header-tab ${column === 'email' ? 'selected' : ''}-${
              reverted ? 'up' : 'down'
            }`}
          >
            Email
          </p>
        ),
        onClick: () => this.onHeaderClick('email', data),
        textAlign: 'center',
      },
      {
        id: 'el-manage-users',
        key: 'programs',
        width: '3',
        content: (
          <p
            className={`e-learning-table-header-tab ${column === 'programs' ? 'selected' : ''}-${
              reverted ? 'up' : 'down'
            }`}
          >
            Programs
          </p>
        ),
        onClick: () => this.onHeaderClick('programs', data),
        textAlign: 'center',
      },
    ];

    return (
      <div className='e-learning-table'>
        <div className='e-learning-table-filter-container'>
          <h4 className='e-learning-table-filter-container-header'>Filter View</h4>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            htmlFor='e-learning-programs-dropdown'
            className='e-learning-table-filter-container-label'
          >
            View Programs
          </label>
          <Dropdown
            id='e-learning-programs-dropdown'
            className='e-learning-programs-dropdown'
            name='programs'
            placeholder='Select program'
            selection
            options={programs}
            value={programValue}
            onChange={(e, v) => onFilterProgramChange(v.value)}
          />
        </div>
        <Table
          id='elearningManageUsersTable'
          className='e-learning-manage-users-table'
          tableData={data}
          headerRow={header}
          padded
          striped
          compact
          sortable
          textAlign='center'
          renderBodyRow={x => ({
            key: x.profileUuid,
            cells: [
              {
                key: 'Name',
                content: (
                  <div
                    className='e-learning-table-body-tab-name'
                    role='button'
                    tabIndex='0'
                    onClick={() => onNameClick(x.uuid)}
                  >
                    {`${x.firstName} ${x.lastName}`}
                  </div>
                ),
                singleLine: true,
                textAlign: 'left',
              },
              { key: 'email', content: x.email, singleLine: true, textAlign: 'left' },
              { key: 'programs', content: x.programs.map(p => p.programName).join(',') },
            ],
          })}
        />

        {!!data?.length && loadMore && (
          <div
            className='e-learning-table-load-more'
            role='button'
            tabIndex='0'
            onClick={() => onLoadMore()}
          >
            Load More
          </div>
        )}
        {loading && (
          <Dimmer active inverted>
            <CareLoader loading={loading} showText={false} />
          </Dimmer>
        )}

        {!data?.length && (
          <div>
            <div className='e-learning-empty-table'>
              <h3>No Matches</h3>
              <Image src='/nomatch.svg' />
              <p>No match found. Please change your filter options</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ELUsersTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  onNameClick: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  loadMore: PropTypes.bool.isRequired,
  programs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onFilterProgramChange: PropTypes.func.isRequired,
  programValue: PropTypes.number.isRequired,
};

export default ELUsersTable;

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import URLSearchParams from 'url-search-params';
import 'react-datepicker/dist/react-datepicker.css';
import { Grid, Header, Image, Form, Segment } from 'semantic-ui-react';
import { userActions, alertActions } from '../actions';
import './Login.scss';
import { MOMENT_DOB } from '../constants/Formats';
import TermsAndConditions from './TermsAndConditions';

const moment = require('moment');

class MagicLogin extends React.Component {
  constructor(props) {
    super(props);
    const state = { dob: null };
    this.onValueChange = this.onValueChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    if (props.location.search) {
      const searchParams = new URLSearchParams(this.props.location.search);
      const magicToken = searchParams.get('magicToken');
      const verificationToken = searchParams.get('verificationToken');
      Object.assign(state, {
        token: magicToken ?? verificationToken,
        type: magicToken ? 'login' : 'signup',
      });
    }

    this.state = state;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.pendingTerms && !prevState.pendingTerms) {
      return { pendingTerms: true };
    }
    return null;
  }

  onValueChange = e => {
    this.setState({ dob: e });
  };

  handleLogin = e => {
    e.preventDefault();
    const { dispatch } = this.props;

    dispatch(alertActions.clearErrors());
    if (this.state.type === 'login') {
      dispatch(userActions.magicLogin(this.state.token, this.state.dob.format('YYYY-MM-DD')));
    } else {
      dispatch(userActions.signupV2(this.state.token, this.state.dob.format('YYYY-MM-DD')));
    }
    return this.setState({ pendingTerms: false });
  };

  handleSetPreLogin = () => {
    const { preLogin, dispatch } = this.props;
    dispatch(alertActions.clearErrors());
    if (this.state.type === 'login') {
      dispatch(
        userActions.magicLogin(
          this.state.token,
          this.state.dob.format('YYYY-MM-DD'),
          false,
          preLogin,
          true
        )
      );
    } else {
      dispatch(
        userActions.signupV2(this.state.token, this.state.dob.format('YYYY-MM-DD'), preLogin, true)
      );
    }
    return this.setState({ pendingTerms: false });
  };

  render() {
    const { loading, alertMsg, terms } = this.props;
    const { dob, pendingTerms } = this.state;

    return (
      <div className='login-form'>
        <Grid centered textAlign='center'>
          <Grid.Row>
            <Grid.Column width='three' verticalAlign='top'>
              <Header as='h2'>
                <Image src='/logo_login.svg' />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width='six' verticalAlign='middle'>
              {alertMsg && (
                <Segment id='loginAlert' basic className='error'>
                  {alertMsg}
                </Segment>
              )}
              {pendingTerms ? (
                <Segment className='termsBox'>
                  <div id='wizardParent'>
                    <TermsAndConditions terms={terms} submit={this.handleSetPreLogin} />
                    <Image src='/circlesLogin.svg' fluid className='footer' />
                  </div>
                </Segment>
              ) : (
                <Segment className='contentBox' style={{ overflow: 'visible' }}>
                  <div style={{ marginTop: '80px' }} id='loginContent'>
                    <Form
                      loading={loading}
                      onSubmit={this.handleLogin}
                      style={{ marginLeft: 'auto', marginRight: 'auto', width: 'fit-content' }}
                    >
                      <Header>LOG IN</Header>
                      <br />
                      <DatePicker
                        customInput={
                          <Form.Input
                            id='magiclogin-dob-input'
                            name='dobInput'
                            placeholder='MM/DD/YYYY'
                            onChange={this.onValueChange}
                          />
                        }
                        className='hideLabel'
                        placeholderText='Enter Birthday'
                        name='dob'
                        id='userBirthDate'
                        popperPlacement='top-end'
                        selected={dob && moment(dob, MOMENT_DOB)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        width={16}
                        onChange={this.onValueChange}
                        dateFormat='MM/DD/YYYY'
                        maxDate={moment()}
                        required
                      />

                      <Form.Button
                        id='loginRecoveryContinueButton'
                        className='care-blue'
                        primary
                        style={{ marginTop: '20px' }}
                        content='Submit'
                        disabled={!dob}
                      />
                    </Form>

                    <Image src='/circlesLogin.svg' fluid className='contentFooter' />
                  </div>
                </Segment>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row />
          <Grid.Row className='footer' verticalAlign='bottom'>
            <Image fluid src='/login_footer.svg' />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

MagicLogin.propTypes = {
  dispatch: PropTypes.func.isRequired,
  alertMsg: PropTypes.string,
  loading: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  pendingTerms: PropTypes.bool,
  terms: PropTypes.shape({
    id: PropTypes.number,
    feature: PropTypes.string,
    policyUrl: PropTypes.string,
    status: PropTypes.string,
    termsUrl: PropTypes.string,
    presentation: PropTypes.shape({
      body: PropTypes.string,
      buttonLabel: PropTypes.string,
      confirmationLabel: PropTypes.string,
      policyLabel: PropTypes.string,
      termsLabel: PropTypes.string,
      title: PropTypes.string,
      canCancel: PropTypes.bool,
      defaultApproved: PropTypes.bool,
    }),
  }),
  preLogin: PropTypes.shape(),
};

MagicLogin.defaultProps = {
  loading: false,
  // eslint-disable-next-line react/default-props-match-prop-types
  alert: undefined,
  alertMsg: undefined,
  pendingTerms: false,
  terms: {},
  preLogin: {},
};

const mapStateToProps = state => {
  const { alert, principal } = state;
  const lastAlert = alert ? alert[alert.length - 1] : null;
  const { loading, pendingTerms, terms, preLogin } = principal;

  let alertMsg;
  if (lastAlert) {
    switch (true) {
      case lastAlert.httpStatus === 404:
        alertMsg = 'The account was not found';
        break;
      case lastAlert.httpStatus === 409:
        alertMsg = 'Incorrect date of birth';
        break;
      default:
        alertMsg = 'Failed to handle your request';
    }
  }

  return {
    alertMsg,
    loading,
    pendingTerms,
    terms,
    preLogin,
  };
};

export default connect(mapStateToProps)(MagicLogin);

/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Form, Icon, Tab, Button, Modal, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import { analytics, Events } from '../helpers/analytics';
import './Common.css';
import './ChangeLog.css';
import { changeLogActions } from '../actions/changeLog';
import { IoT } from '../actions/carechat';
import { store } from '../helpers';
import { alertActions } from '../actions/alert';
import ChangeLogPreferenceTable from './subcomponents/ChangeLogPreferenceTable';
import ChangeLogShiftTable from './subcomponents/ChangeLogShiftTable';
import { openSaveFileDialog } from '../constants/Helpers';
import ChangeLogChatTable from './subcomponents/ChangeLogChatTable';
import ChangeLogEmployeeTable from './subcomponents/ChangeLogEmployeeTable';
import { isoStartOfDay, isoEndOfDay } from '../constants/Formats';

const preferenceTypes = [
  'CAREGIVER_PROFILE_UPDATED',
  'CLIENT_PROFILE_UPDATED',
  'CAREGIVER_COMPLIANCE_BLOCKS',
  'CAREGIVER_WORKHISTORY_UPDATE',
  'COMPLETE_CAREGIVER_PREHIRE',
];
const excludeSystemEvents = true;

class ChangeLog extends React.Component {
  constructor() {
    super();

    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.onPreferenceLoadMore = this.onPreferenceLoadMore.bind(this);
    this.onShiftLoadMore = this.onShiftLoadMore.bind(this);
    this.onChatLoadMore = this.onChatLoadMore.bind(this);
    this.onEmployeesLoadMore = this.onEmployeesLoadMore.bind(this);
  }

  // eslint-disable-next-line react/state-in-constructor
  state = {
    searchField: '',
    start: moment().subtract(30, 'days'),
    end: moment(),
    pristine: true,
    activeTabIndex: 0,
    showCsvModal: false,
    valid: true,
  };

  UNSAFE_componentWillMount() {
    this.props.onActive(this.props.tabIndex);
  }

  componentDidMount() {
    const { start, end } = this.state;
    const { caregiverId } = this.props;
    this.props.dispatch(
      changeLogActions.getPreferenceChangeLog({
        types: preferenceTypes,
        excludeSystemEvents,
        start: isoStartOfDay(start),
        end: isoEndOfDay(end),
        caregiverId,
      })
    );
    analytics.track(Events.CHANGELOG_SELECT_TAB);
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ pristine: true });
  }

  onPreferenceLoadMore(nextPage) {
    this.props.dispatch(changeLogActions.getPreferenceChangeLog({ nextPage }, true));
  }

  onShiftLoadMore(nextPage) {
    this.props.dispatch(changeLogActions.getShiftChangeLog({ nextPage }, true));
  }

  onChatLoadMore(nextPage) {
    this.props.dispatch(IoT.getConversations({ nextPage }, true));
  }

  onEmployeesLoadMore(nextPage) {
    this.props.dispatch(changeLogActions.getEmployeeChangeLog({ nextPage }, true));
  }

  onDateChange = (date, time) => {
    const errorExceed = 'Exceeded maximum export days of 90';
    const errorOrder = 'Start date must be before end date';
    const daysExceed =
      time === 'start'
        ? moment(this.state.end).diff(moment(date), 'days', true) > 90
        : moment(date).diff(moment(this.state.start), 'days', true) > 90;
    const daysOrder =
      time === 'start'
        ? moment(this.state.end).isBefore(date)
        : moment(date).isBefore(this.state.start);

    if (daysExceed || daysOrder) {
      this.setState({ valid: false });
      if (daysExceed) {
        store.dispatch(alertActions.error(errorExceed));
      } else {
        store.dispatch(alertActions.error(errorOrder));
      }
    } else if (!this.state.valid) {
      this.setState({ valid: true });
    }

    if (time === 'start') {
      this.setState({ start: date, pristine: false });
    } else {
      this.setState({ end: date, pristine: false });
    }
  };

  handleSearchSubmit(params) {
    const { caregiverId } = this.props;

    const nparams = {
      ...params,
      start: params.start ? isoStartOfDay(params.start) : undefined,
      end: params.end ? isoEndOfDay(params.end) : undefined,
    };

    if (caregiverId) {
      delete nparams.name;
    }

    switch (this.state.activeTabIndex) {
      case 0:
        return this.props.dispatch(
          changeLogActions.getPreferenceChangeLog({
            ...nparams,
            types: preferenceTypes,
            excludeSystemEvents,
          })
        );
      case 1:
        return this.props.dispatch(changeLogActions.getShiftChangeLog({ ...nparams }));
      case 2:
        return this.props.dispatch(
          IoT.getConversations({
            ...params,
            start: params.start ? isoStartOfDay(params.start) : undefined,
            end: params.end ? isoEndOfDay(params.end) : undefined,
            search: params.name ? params.name : undefined,
          })
        );
      case 3:
        return this.props.dispatch(
          changeLogActions.getEmployeeChangeLog({
            ...params,
            start: params.start ? isoStartOfDay(params.start) : undefined,
            end: params.end ? isoEndOfDay(params.end) : undefined,
          })
        );
      default:
        return this.props.dispatch(
          changeLogActions.getPreferenceChangeLog({
            ...nparams,
            types: preferenceTypes,
            excludeSystemEvents,
          })
        );
    }
  }

  handleTabChange = data => {
    const { start, end } = this.state;
    const { caregiverId } = this.props;

    this.setState({ activeTabIndex: data.activeIndex });

    const preferenceResult = this.props.changeLog.preference;
    const shiftResult = this.props.changeLog.shift;
    const employeesResult = this.props.changeLog.employee;
    const chatResult = this.props.chatLog;

    switch (data.activeIndex) {
      case 0:
        return !preferenceResult || (preferenceResult && preferenceResult.events.length <= 0)
          ? this.props.dispatch(
              changeLogActions.getPreferenceChangeLog({
                types: preferenceTypes,
                excludeSystemEvents,
                start: isoStartOfDay(start),
                end: isoEndOfDay(end),
                caregiverId,
              })
            )
          : null;
      case 1:
        return !shiftResult || (shiftResult && shiftResult.events.length <= 0)
          ? this.props.dispatch(
              changeLogActions.getShiftChangeLog({
                start: isoStartOfDay(start),
                end: isoEndOfDay(end),
                caregiverId,
              })
            )
          : null;
      case 2:
        return !chatResult || (chatResult && chatResult.events.length <= 0)
          ? this.props.dispatch(
              IoT.getConversations({
                start: isoStartOfDay(start),
                end: isoEndOfDay(end),
                caregiverId,
              })
            )
          : null;
      case 3:
        return !employeesResult || (employeesResult && employeesResult.events.length <= 0)
          ? this.props.dispatch(
              changeLogActions.getEmployeeChangeLog({
                start: isoStartOfDay(start),
                end: isoEndOfDay(end),
              })
            )
          : null;
      default:
        return this.props.dispatch(
          changeLogActions.getPreferenceChangeLog({
            types: preferenceTypes,
            excludeSystemEvents,
            start: isoStartOfDay(start),
            end: isoEndOfDay(end),
            caregiverId,
          })
        );
    }
  };

  flattenPreferenceChanges = preferences =>
    (preferences && preferences.length
      ? preferences.reduce((acc, cur) => {
          const flat =
            cur.details && Array.isArray(cur.details)
              ? cur.details.map(d => ({
                  ...cur,
                  details: {
                    old: d.old,
                    new: d.new,
                    key: d.key,
                    name: d.name,
                  },
                }))
              : [cur];
          return acc.concat(flat);
        }, [])
      : preferences
    ).filter(x => x.details);

  render() {
    const { searchField, start, end, activeTabIndex, valid } = this.state;
    const preferenceResult = this.props.changeLog.preference;
    const shiftResult = this.props.changeLog.shift;
    const employeesResult = this.props.changeLog.employee;
    const chatResult = this.props.chatLog;
    const { intl } = this.props;
    const { caregiverId } = this.props;

    // flatten received preference event log details as single items
    const preferences = this.flattenPreferenceChanges(preferenceResult.events);

    const shifts = shiftResult.events;
    const chats = chatResult.events;
    const employees = employeesResult.events;

    // show record count
    const recordCount = () => {
      switch (activeTabIndex) {
        case 0:
          return preferences.length;
        case 1:
          return shifts.length;
        case 2:
          return chats.length;
        case 3:
          return employees.length;
        default:
          return 0;
      }
    };

    const searchPlaceholder = () => {
      switch (activeTabIndex) {
        case 2:
          return 'changeLog.search.search';
        case 3:
          return 'changeLog.search.placeholder.employee';
        default:
          return 'changeLog.search.placeholder.caregiver';
      }
    };

    const exportButtonText = () => {
      switch (activeTabIndex) {
        case 1:
          return 'changeLog.export.button.shift';
        case 2:
          return 'changeLog.export.button.chat';
        case 3:
          return 'changeLog.export.button.employee';
        default:
          return 'changeLog.export.button';
      }
    };

    const panes = [
      {
        menuItem: intl.formatMessage({
          id: 'changeLog.profile.menu',
        }),
        pane: {
          key: 'preference',
          content: (
            <div>
              {!caregiverId && (
                <div className='tabHeader'>
                  {intl.formatMessage({ id: 'changeLog.preference.header' })}
                </div>
              )}
              <ChangeLogPreferenceTable
                data={preferences}
                loading={preferenceResult.loading}
                partialUpdate={preferenceResult.partialUpdate}
                nextPage={preferenceResult.nextPage}
                onLoadMore={this.onPreferenceLoadMore}
                caregiverId={this.props.caregiverId}
                ref={subComponentRef => {
                  this.changeLogPreferenceTable = subComponentRef;
                  return null;
                }}
              />
            </div>
          ),
        },
      },
      {
        menuItem: intl.formatMessage({
          id: 'changeLog.shift.menu',
        }),
        pane: {
          key: 'shift',
          content: (
            <div>
              {!caregiverId && (
                <div className='tabHeader'>
                  {intl.formatMessage({ id: 'changeLog.shift.header' })}
                </div>
              )}
              <ChangeLogShiftTable
                data={shifts}
                loading={shiftResult.loading}
                partialUpdate={shiftResult.partialUpdate}
                nextPage={shiftResult.nextPage}
                onLoadMore={this.onShiftLoadMore}
                caregiverId={this.props.caregiverId}
                ref={componentRef => {
                  this.changeLogShiftTable = componentRef;
                  return null;
                }}
              />
            </div>
          ),
        },
      },
      {
        menuItem: intl.formatMessage({
          id: 'changeLog.chat.menu',
        }),
        pane: {
          key: 'chat',
          content: (
            <div>
              <div className='infoText'>
                <ChangeLogChatTable
                  data={chats}
                  loading={chatResult.loading}
                  partialUpdate={chatResult.partialUpdate}
                  nextPage={chatResult.nextPage}
                  onLoadMore={this.onChatLoadMore}
                  caregiverId={this.props.caregiverId}
                  ref={componentRef => {
                    this.changeLogChatTable = componentRef;
                    return null;
                  }}
                />
              </div>
            </div>
          ),
        },
      },
      ...(caregiverId
        ? []
        : [
            {
              menuItem: intl.formatMessage({ id: 'changeLog.employee.menu' }),
              pane: {
                key: 'employee',
                content: (
                  <div>
                    <div className='infoText'>
                      <ChangeLogEmployeeTable
                        data={employees}
                        loading={employeesResult.loading}
                        partialUpdate={employeesResult.partialUpdate}
                        nextPage={employeesResult.nextPage}
                        onLoadMore={this.onEmployeesLoadMore}
                        ref={componentRef => {
                          this.changeLogEmployeesTable = componentRef;
                          return null;
                        }}
                      />
                    </div>
                  </div>
                ),
              },
            },
          ]),
    ];

    return (
      <Grid.Row className='noVerticalPadding'>
        <Grid className='fullPageContentGrid change-logs'>
          {activeTabIndex === 1 && this.state.showCsvModal && (
            <Modal
              closeOnDimmerClick={false}
              open
              size='tiny'
              className='csv-shift-modal'
              style={{ marginTop: '-35vh' }}
            >
              <Modal.Content>
                <Modal.Header className='csv-shift-modal-header'>
                  YOUR EXPORT IS BEING PREPARED
                </Modal.Header>
                <Image centered className='csv-shift-modal-image' src='/csvshiftmodal.svg' />
                <p className='csv-shift-modal-text'>
                  Your file will be compiled and will automatically download when ready.
                  <br />
                  Preparation can take a few minutes for larger date ranges.
                </p>
                <Button
                  id='change-log-csv-shift-modal-button'
                  className='csv-shift-modal-button'
                  size='tiny'
                  content='OK'
                  onClick={() => {
                    this.setState({ showCsvModal: false });
                  }}
                />
              </Modal.Content>
            </Modal>
          )}
          <Grid.Row style={{ height: '100px' }}>
            <Grid.Column className='headerColumn' verticalAlign='middle'>
              <Header floated='left' as='h3' style={{ color: '#364967' }}>
                {intl.formatMessage({
                  id: caregiverId ? 'activityLog.header.text' : 'changeLog.header.text',
                })}
              </Header>
            </Grid.Column>

            <Grid.Column width={10} verticalAlign='middle'>
              <Form
                size='tiny'
                onSubmit={() =>
                  this.handleSearchSubmit({ name: searchField, start, end, caregiverId })
                }
              >
                <Form.Group className='menu-input-with-button' inline>
                  {(!caregiverId || (caregiverId && activeTabIndex === 2)) && (
                    <Form.Input
                      id='caregiversSearchName'
                      size='tiny'
                      width={4}
                      inline
                      floated='left'
                      placeholder={intl.formatMessage({ id: searchPlaceholder() })}
                      value={searchField}
                      onChange={(e, input) =>
                        this.setState({ searchField: input.value, pristine: false })
                      }
                    />
                  )}
                  <Form.Field style={{ minWidth: '250px' }}>
                    {activeTabIndex === 2 && (
                      <div className='infoText'>
                        {intl.formatMessage({ id: 'changeLog.result.summary.showing' })}{' '}
                        <span className='infoTextBold'>{recordCount()}</span>{' '}
                        {intl.formatMessage({ id: 'changeLog.result.summary.sent' })}{' '}
                        <span className='infoTextBold'>
                          {intl.formatMessage({ id: 'changeLog.result.summary.messages' })}
                        </span>{' '}
                        {intl.formatMessage({ id: 'changeLog.result.summary.between' })}
                      </div>
                    )}
                    {activeTabIndex === 1 && (
                      <div className='infoText'>
                        {intl.formatMessage({ id: 'changeLog.result.summary.showing' })}{' '}
                        <span className='infoTextBold'>{recordCount()}</span>{' '}
                        {intl.formatMessage({ id: 'changeLog.result.summary.changes' })}{' '}
                        <span className='infoTextBold'>
                          {intl.formatMessage({ id: 'changeLog.result.summary.shifts' })}
                        </span>{' '}
                        {intl.formatMessage({ id: 'changeLog.result.summary.between' })}
                      </div>
                    )}
                    {activeTabIndex !== 2 && activeTabIndex !== 1 && (
                      <div className='infoText'>
                        {intl.formatMessage({ id: 'changeLog.result.summary.showing' })}{' '}
                        <span className='infoTextBold'>{recordCount()}</span>{' '}
                        {intl.formatMessage({ id: 'changeLog.result.summary.changes' })}{' '}
                        <span className='infoTextBold'>
                          {intl.formatMessage({ id: 'changeLog.result.summary.records' })}
                        </span>{' '}
                        {intl.formatMessage({ id: 'changeLog.result.summary.between' })}
                      </div>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <DatePicker
                      id='startDate'
                      autoComplete='off'
                      selected={start}
                      dateFormat='MM/DD/YY'
                      onChange={date => this.onDateChange(date, 'start')}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Icon name='angle right' color='grey' />
                  </Form.Field>
                  <Form.Field>
                    <DatePicker
                      id='endDate'
                      autoComplete='off'
                      selected={end}
                      dateFormat='MM/DD/YY'
                      onChange={date => this.onDateChange(date, 'end')}
                    />
                  </Form.Field>
                  <Form.Button
                    id='change-log-submit-button'
                    inline
                    circular
                    loading={
                      preferenceResult.loading ||
                      employeesResult.loading ||
                      chatResult.loading ||
                      shiftResult.loading
                    }
                    disabled={
                      preferenceResult.loading ||
                      employeesResult.loading ||
                      chatResult.loading ||
                      shiftResult.loading
                    }
                  >
                    {intl.formatMessage({ id: 'changeLog.submit.button' })}
                  </Form.Button>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={3} verticalAlign='middle'>
              <Button
                id='change-log-export-button'
                className='care-green shift-log-export'
                size='tiny'
                loading={activeTabIndex === 1 && shiftResult.loadingCSV}
                disabled={(activeTabIndex === 1 && shiftResult.loadingCSV) || !valid}
                onClick={() => {
                  if (
                    activeTabIndex === 0 &&
                    this.changeLogPreferenceTable &&
                    this.changeLogPreferenceTable.onExportClick
                  ) {
                    openSaveFileDialog(
                      this.changeLogPreferenceTable.onExportClick(),
                      'preference-change-log.csv',
                      'text/csv'
                    );
                  }
                  if (
                    activeTabIndex === 1 &&
                    this.changeLogShiftTable &&
                    this.changeLogShiftTable.onExportClick
                  ) {
                    this.setState({ showCsvModal: true });
                    openSaveFileDialog(
                      {
                        start: isoStartOfDay(start),
                        end: isoEndOfDay(end),
                        caregiverId,
                      },
                      'shift-change-log.csv',
                      'text/csv',
                      activeTabIndex,
                      this.props.dispatch
                    );
                  }
                  if (
                    activeTabIndex === 2 &&
                    this.changeLogChatTable &&
                    this.changeLogChatTable.onExportClick
                  ) {
                    openSaveFileDialog(
                      this.changeLogChatTable.onExportClick(),
                      'chat-log.csv',
                      'text/csv'
                    );
                  }
                  if (
                    activeTabIndex === 3 &&
                    this.changeLogEmployeesTable &&
                    this.changeLogEmployeesTable.onExportClick
                  ) {
                    openSaveFileDialog(
                      this.changeLogEmployeesTable.onExportClick(),
                      'employee-activity-log.csv',
                      'text/csv'
                    );
                  }
                }}
              >
                {intl.formatMessage({
                  id: exportButtonText(),
                })}
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Tab
              id='change-log-tab'
              panes={panes}
              renderActiveOnly={false}
              defaultActiveIndex={activeTabIndex}
              onTabChange={(e, data) => this.handleTabChange(data)}
            />
          </Grid.Row>
        </Grid>
      </Grid.Row>
    );
  }
}

ChangeLog.defaultProps = {
  caregiverId: undefined,
  tabIndex: 0,
  onActive: () => {},
};

ChangeLog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  changeLog: PropTypes.shape().isRequired,
  chatLog: PropTypes.shape().isRequired,
  intl: PropTypes.shape().isRequired,
  caregiverId: PropTypes.number,
  onActive: PropTypes.func,
  tabIndex: PropTypes.number,
};

const mapStateToProps = state => {
  const { changeLog, chatLog } = state;
  return {
    changeLog,
    chatLog,
  };
};

export default connect(mapStateToProps)(injectIntl(ChangeLog));

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Grid, Image, Button } from 'semantic-ui-react';

const AnnouncementConfirmed = props => {
  const ConfirmedImage = () => <Image src='/announcement_confirmed.svg' centered />;

  return (
    <Modal
      closeOnDimmerClick={false}
      size='tiny'
      open
      onClose={props.onClose}
      closeIcon={<Image src='/icon_close.svg' />}
      dimmer
      className='announcementModals'
      style={{ marginTop: '-20vh' }}
    >
      <Modal.Content>
        <h1>Your Announcement Has Been Sent</h1>
        {ConfirmedImage()}
        <p>
          Your Caregivers will receive your message as a<br />
          push notification on their mobile device.
        </p>
      </Modal.Content>

      <Modal.Actions>
        <Grid centered className='buttonArea'>
          <Grid.Column width={10} textAlign='center'>
            <Button onClick={props.onClose}>OK</Button>
          </Grid.Column>
        </Grid>
      </Modal.Actions>
    </Modal>
  );
};

AnnouncementConfirmed.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AnnouncementConfirmed;

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Modal, Image, Button } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { Form } from 'formsy-semantic-ui-react';
import { connect } from 'react-redux';
import { caregiverActions } from '../../actions';
import CareLoader from './CareLoader';
import { RecordsForm } from '../../styles/records';
import DetailsBox from './records/DetailsBox';

import '../CaregiverRecords.css';

const maleIcon = '/Male.svg';
const femaleIcon = '/Female.svg';
const otherIcon = '/Other.svg';

class CGInformationDohRegistrynumber extends React.Component {
  constructor() {
    super();
    this.state = { editRegistryNumber: false, showRemoveNote: false };

    this.findDohCandidates = this.findDohCandidates.bind(this);
    this.dohCandidate = this.dohCandidate.bind(this);
    this.editRegistryNumber = this.editRegistryNumber.bind(this);
    this.removeRegistryNumber = this.removeRegistryNumber.bind(this);
  }

  editRegistryNumber() {
    this.setState({
      editRegistryNumber: true,
    });
  }

  removeRegistryNumber() {
    const { caregiver } = this.props.caregiverDetails;
    this.props.dispatch(caregiverActions.clearComplianceDohInfos(caregiver.id));
    this.setState({ showRemoveNote: false });
  }

  findDohCandidates() {
    const { caregiver } = this.props.caregiverDetails;
    this.props.dispatch(
      caregiverActions.getDohCandidates(caregiver.id, this.state.registryNumberSearch)
    );
  }

  dohCandidate(c, drawAddBtn = false) {
    let approvementColor = '#aaa';
    if (c.approvedForEmployment === 'Yes') approvementColor = '#3A2';
    if (c.approvedForEmployment === 'No') approvementColor = '#A32';

    const addCandidate = candidate => {
      this.props.dispatch(
        caregiverActions.updateComplianceDohInfos(
          this.props.caregiverDetails.caregiver.id,
          parseInt(candidate.registryNumber, 10)
        )
      );
      this.setState({
        editRegistryNumber: false,
      });
    };

    const getGenderIcon = gender => {
      const g = gender ? gender.toLowerCase() : '';
      switch (g) {
        case 'male':
          return maleIcon;
        case 'female':
          return femaleIcon;
        default:
          return otherIcon;
      }
    };

    return (
      <div style={{ marginBottom: '16px' }} key='doh_registry_number'>
        <Grid>
          <Grid.Row>
            <Grid.Column width={3}>
              <Image src={getGenderIcon(c.gender)} width={32} height={32} />
            </Grid.Column>
            <Grid.Column width={10} verticalAlign='middle'>
              <h5>{c.name}</h5>
            </Grid.Column>
            <Grid.Column width={3} verticalAlign='middle'>
              {drawAddBtn && (
                <Button size='tiny' basic color='green' onClick={() => addCandidate(c)}>
                  Add
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <div>
          County: <b>{c.county}</b>
          <br />
          Approved for Employment:{' '}
          <b style={{ color: approvementColor }}>{c.approvedForEmployment}</b>
        </div>
      </div>
    );
  }

  render() {
    const {
      doh,
      dohError,
      loadingDoh,
      loadingDohCandidates,
      dohCandidates,
    } = this.props.caregiverCompliance;

    if (dohError) {
      return <></>;
    }

    let pattern;
    switch (this.props.agencySettings.state) {
      case 'OH':
        pattern = /^[A-Z0-9.-]{1,30}$/;
        break;
      case 'CA':
        pattern = /^[0-9]{1,30}$/;
        break;
      case 'NY':
      default:
        pattern = /^[0-9]{1,7}$/;
        break;
    }

    const onValueChange = (e, input) => {
      this.setState({
        registryNumberSearch: input.value,
        isEnabled: RegExp(pattern).test(input.value),
      });
    };

    return (
      <>
        <DetailsBox text='DOH REGISTRY NUMBER' withoutIcon>
          {!doh || loadingDoh || loadingDohCandidates ? (
            <div style={{ marginTop: '36px', marginLeft: '-40px' }}>
              <CareLoader loading centered showText={false} />
            </div>
          ) : (
            <RecordsForm
              size='tiny'
              ref={c => {
                this.form = c;
              }}
            >
              {!doh || !doh.registryNumber || this.state.editRegistryNumber ? (
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Form.Input
                        fluid
                        name='registryNumber'
                        placeholder='Set Registry Number'
                        label='Doh Registry Number'
                        width={16}
                        onChange={onValueChange}
                        validations={{
                          matchRegexp: pattern,
                        }}
                        validationErrors={{ isValid: 'registry number not valid' }}
                        instantValidation
                        required
                      />
                    </Grid.Column>
                    <Grid.Column verticalAlign='middle'>
                      <Button
                        className='ui circular compact icon button'
                        disabled={!this.state.isEnabled}
                      >
                        <i
                          aria-hidden='true'
                          className='search icon'
                          onClick={this.findDohCandidates}
                        />
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <div>
                        {dohCandidates && dohCandidates.map(d => this.dohCandidate(d, true))}
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ) : (
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={5} verticalAlign='middle'>
                      ID: <b>{doh.registryNumber}</b>
                    </Grid.Column>
                    <Grid.Column verticalAlign='middle'>
                      <Button className='ui basic circular compact icon button'>
                        <i
                          aria-hidden='true'
                          className='pencil alternate icon'
                          onClick={this.editRegistryNumber}
                        />
                      </Button>
                    </Grid.Column>
                    <Grid.Column />
                    <Grid.Column verticalAlign='middle'>
                      <Button className='ui basic circular compact icon button'>
                        <i
                          aria-hidden='true'
                          className='trash icon'
                          onClick={() => this.setState({ showRemoveNote: true })}
                        />
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={16}>{this.dohCandidate(doh)}</Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </RecordsForm>
          )}
        </DetailsBox>
        <Modal
          closeOnDimmerClick={false}
          open={this.state.showRemoveNote}
          onClose={() => this.setState({ showRemoveNote: false })}
        >
          <Modal.Content>
            <div>
              Do you really want to delete doh registry number? This will remove all automatically
              added workhistory and certification items.
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content='Cancel'
              onClick={() => {
                this.setState({ showRemoveNote: false });
              }}
            />
            <Button content='Yes' onClick={() => this.removeRegistryNumber()} />
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

CGInformationDohRegistrynumber.propTypes = {
  agencySettings: PropTypes.shape().isRequired,
  caregiverDetails: PropTypes.shape().isRequired,
  caregiverCompliance: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { caregiverDetails, caregiverCompliance, principal } = state;
  const { agencySettings } = principal;
  return {
    agencySettings,
    caregiverDetails,
    caregiverCompliance,
  };
};

export default connect(mapStateToProps)(injectIntl(CGInformationDohRegistrynumber));

/* eslint-disable import/prefer-default-export */
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

interface IBodyContent {
  dotColor: string;
}

export const CompletionModal = styled(Modal).attrs({
  size: 'tiny',
  open: true,
  closeOnDimmerClick: false,
  closeIcon: true,
})`
  &&&&& {
    background-color: #f3f3f3;
    border-radius: 10px;
    &:not(.scrolling) {
      transform: translate(0, -50%);
    }
    & > .close {
      top: 1.0535rem;
      right: 1rem;
      color: rgba(0, 0, 0, 0.87);
    }
  }
`;

export const ModalHeader = styled(Modal.Header)`
  &&&& {
    min-height: 100px;
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem 0 1rem;
    border-radius: 10px 10px 0 0;
    & > h2 {
      font-size: 16px;
      line-height: 22px;
      color: #4a4a4a;
    }
    & > p {
      font-size: 12px;
      color: #9b9b9b;
    }
  }
`;

export const HeaderTitles = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 20px;
  align-items: center;
  justify-items: flex-start;
  padding: 0 1rem;
  & > h3 {
    margin: 0;
    font-size: 12px;
    color: #4a4a4a;
    text-transform: capitalize;
  }
`;

export const ModalBody = styled(Modal.Content)`
  &&&& {
    padding: 1rem;
  }
`;

export const BodyContent = styled.div<IBodyContent>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  align-items: flex-start;
  justify-items: flex-start;
  padding: 1rem;
  &:not(:last-child) {
    border-bottom: 1px solid #e3e3e3;
  }
  & .compliance-type-content {
    padding-right: 1rem;
  }
  &:first-child {
    padding-top: 0;
  }
  & h4 {
    margin: 0;
    font-size: 11px;
    font-weight: normal;
    color: #4a4a4a;
    text-transform: capitalize;
    display: inline-block;
  }
  & .compliance-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.5rem;
    background-color: ${props => props.dotColor};
  }
  & .compliance-name {
    margin: 0;
    font-size: 12px;
    font-weight: normal;
    color: #4a4a4a;
    text-transform: capitalize;
  }
  & .note-text {
    margin: 0;
    font-size: 11px;
    color: #9b9b9b;
  }
`;

export const NameWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & p {
    padding-right: 0.5rem;
  }
`;

export const FootNote = styled.div`
  margin-top: 1rem;
  font-size: 11px;
  color: #9b9b9b;
  text-align: center;
`;

export const FooterContent = styled(Modal.Actions)`
  &&&& {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 10px 10px;
  }
`;

import React from 'react';
import { Grid, Segment, Image, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './ScheduleNoMatch.css';
import CaregiversMenu from '../CaregiversMenu';
import { uiStateActions } from '../../actions';

const removeCostfilter = dispatch => {
  uiStateActions.inputChangeHandler(dispatch, 'caregiverFilter')(
    {},
    {
      name: 'advancedFilter',
      value: ['conflict'],
    }
  );

  dispatch((CaregiversMenu.handleUpdateClick = () => {}));
};

const NoMatch = ({
  id,
  message,
  dispatch = () => null,
  extracost = false,
  tooManyResults = false,
}) => {
  let header;
  if (tooManyResults) {
    header = <h1 className='schedule-no-match-title'>Too many results</h1>;
  } else if (extracost) {
    header = (
      <div>
        <Image src='/Attention.svg' style={{ marginBottom: '0px' }} />
        <h1 className='schedule-no-match-title'>No Results</h1>
        <br />
      </div>
    );
  } else {
    header = (
      <p>
        <h1 className='schedule-no-match-title'>No Matching Results</h1>
      </p>
    );
  }

  let msg;
  if (message) {
    msg = (
      <p className='schedule-no-match-body'>
        <Image src='/nomatch.svg' />
        {message}
      </p>
    );
  } else if (tooManyResults) {
    msg = (
      <p className='schedule-no-match-body'>
        <Image src='/Attention.svg' />
        Please enter more information to narrow down search results
      </p>
    );
  } else if (extracost) {
    msg = (
      <p className='schedule-no-match-body'>
        Remove cost filter and rerun your search to <br /> allow matches with a cost penalty
        <br />
        <br />
        <Button className='care-blue' onClick={() => removeCostfilter(dispatch)}>
          Remove Cost Filter
        </Button>
      </p>
    );
  } else {
    msg = (
      <p className='schedule-no-match-body'>
        <Image src='/nomatch.svg' />
        Try removing some of your search filters on the left <br /> or expanding your date range
      </p>
    );
  }

  return (
    <Grid.Row id={id} columns='one' className='emptyStateRow' textAlign='center'>
      <Segment basic>
        {header}
        {msg}
      </Segment>
    </Grid.Row>
  );
};

NoMatch.defaultProps = {
  tooManyResults: false,
  id: '',
  message: null,
};

NoMatch.propTypes = {
  tooManyResults: PropTypes.bool,
  id: PropTypes.string,
  message: PropTypes.string,
  extracost: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default NoMatch;

import iso6393 from 'iso-639-3';
import moment from 'moment';
import React, { Component, SyntheticEvent } from 'react';
import { Button, Dimmer, Tab, TabProps } from 'semantic-ui-react';
import styled from 'styled-components';
import HRApplicantNotes from './HRApplicantNotes';
import { IApplicantProfile, ICustomFields } from '../../../reducers/types/applicants';
import { backend } from '../../../services';
import CareLoader from '../CareLoader';
import LocationMap from '../LocationMap';
import close_round from '../../../images/close_round.svg';
import { formatPhoneNumber } from '../../../constants/Formats';

interface IProps {
  applicant?: IApplicantProfile;
  handleClose: () => void;
  handleSaveNotes: (params: INotesParams) => void;
  customFields: ICustomFields[];
}

interface IState {
  activeTabIndex?: string | number;
  panes: Array<{
    menuItem: string;
    pane: {
      key: string;
      content: JSX.Element;
    };
  }>;
  applicantNotes: string;
}

interface INotesParams {
  token: string;
  notes: string;
}

interface INotesProps {
  value: string;
}

interface ILocationResults {
  lat: number;
  lon: number;
  timezone: string;
}

type Pages =
  | IApplicantProfile['basicInformation']
  | IApplicantProfile['additionalInformation']
  | IApplicantProfile['backgroundInformation']
  | IApplicantProfile['shiftPreferences']
  | IApplicantProfile['workExperience']
  | IApplicantProfile['workPreferences'];

const DATE_FORMAT = 'MM/YYYY';
const FULL_DATE_FORMAT = 'MM/DD/YYYY';

const ApplicantContainer = styled.div`
  display: grid;
  place-items: center;
  grid-template-rows: 1px auto 1fr;
  width: 100%;
  height: 100%;
  max-width: 822px;
  max-height: 600px;
  border-radius: 8px;
  overflow: hidden;
  padding: 1rem 0;
  background-color: #f1f1f1;
  color: #4a4a4a;
`;

const CloseButton = styled(Button)`
  &&& {
    position: relative;
    background-color: transparent;
    width: 30px;
    height: 30px;
    background-image: url(${close_round});
    background-size: 30px;
    background-position: center;
    background-repeat: no-repeat;
    justify-self: flex-end;
    align-self: flex-start;
  }
  &&&:hover {
    background-color: transparent;
    width: 30px;
    height: 30px;
    background-image: url(${close_round});
    background-size: 30px;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

const HeaderContent = styled.div`
  padding: 1rem 1rem 1rem 1.5rem;
  width: 100%;
  display: grid;
  grid-template-columns: auto 200px;
`;

const ApplicantTab = styled(Tab)`
  justify-self: stretch;
  align-self: stretch;
  height: 100%;
  && > div > .active.item {
    border-color: transparent !important;
    border-bottom: 3px solid #04a5d5 !important;
  }
  && .ui.bottom.attached.segment.active.tab {
    padding: 0 1rem 1rem 1rem;
  }
`;

const HeaderLabel = styled.p`
  color: #999999;
  text-align: start;
  font-size: 14px;
  font-weight: 700;
  font-family: Lato;
`;

const ApplicantName = styled.h3`
  color: #4a4a4a;
  text-align: start;
  font-size: 22px;
  font-weight: bold;
  font-family: Lato;
  margin-top: 0;
`;

const FieldLabel = styled.p`
  color: #9b9b9b;
  font-size: 12px;
  text-align: start;
  margin-bottom: 5px !important;
  font-family: Lato;
  font-weight: 400;
`;

const FieldValue = styled.p`
  color: #4b4b4b;
  font-size: 14px;
  text-align: start;
  margin-bottom: 5px !important;
  font-family: Lato;
  font-weight: bold;
`;

const FieldContainer = styled.div`
  padding: 1rem 0;
  color: #4b4b4b;
  font-size: 14px;
  border-radius: 10px;
  text-align: start;
  height: min-content;
`;

const DateLabel = styled.p`
  color: #4a4a4a;
  font-size: 14px;
  font-family: Lato;
  font-weight: bold;
`;

const SectionContainer = styled.div`
  padding: 1rem;
  color: #4b4b4b;
  font-size: 14px;
  box-shadow: 0px 0px 25px 1px #e6e6e6;
  border-radius: 10px;
  text-align: start;
  height: min-content;
`;

const TwoColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
`;

const ThreeColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 1rem;
  row-gap: 1rem;
`;

const TabScrollContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
  min-height: 430px;
  max-height: 430px;
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
`;

class HRApplicantOverview extends Component<IProps, IState> {
  public state: IState = {
    activeTabIndex: 0,
    applicantNotes: '',
    panes: [],
  };

  public componentDidMount(): void {
    this.prepareApplicantView();
  }

  private readonly changeTabIndex = (
    _e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: TabProps
  ) => {
    this.setState(s => ({ ...s, activeTabIndex: data.activeIndex }));
  };

  private readonly handleNotesChange = (_event: SyntheticEvent, data: INotesProps) => {
    this.setState(s => ({ ...s, applicantNotes: data.value }));
  };

  private readonly saveNotes = () => {
    const { applicant, handleSaveNotes } = this.props;
    const { applicantNotes } = this.state;
    const params = {
      token: applicant?.token || '',
      notes: applicantNotes,
    };
    handleSaveNotes(params);
  };

  private readonly prepareApplicantView = async () => {
    const { applicant, customFields } = this.props;

    const locateApplicant = async (): Promise<ILocationResults> => {
      const location = await backend
        .geocodeAddress({ address: applicant?.additionalInformation.address })
        .then((results: ILocationResults) => {
          return results;
        })
        .catch(() => {
          return { lat: 0, lon: 0, timezone: '' };
        });
      return location;
    };

    const getIdImage = async (): Promise<string> => {
      try {
        const image = await backend.getApplicantIdImage(applicant?.token);
        return image.url;
      } catch (e) {
        return '';
      }
    };

    const setGender = (gender?: string) => {
      switch (gender) {
        case 'm':
          return 'Male';
        case 'f':
          return 'Female';
        case 'o':
          return 'Other';
        default:
          return 'Undefined';
      }
    };

    const renderFields = (fieldsCustomFields: ICustomFields[], page: Pages) => {
      return fieldsCustomFields.map(f => {
        let value = page ? page[f.id] : null;
        switch (f.type) {
          case 'dateSelector':
            value = moment(value as string).format(FULL_DATE_FORMAT);
            break;
          case 'checkbox':
            value = value ? 'Yes' : '-';
            break;
          case 'multiCheckbox':
          case 'multiDropdown':
            value = Array.isArray(value)
              ? value.map((l, i, a) => (a.length === i + 1 ? l : `${l}, `))
              : '';
            break;
          case 'dropdown':
          case 'textField':
            value = value || '-';
            break;
          default:
            value = value || '-';
            break;
        }
        return (
          <FieldContainer>
            <FieldLabel>{f.label}</FieldLabel>
            <FieldValue>{value}</FieldValue>
          </FieldContainer>
        );
      });
    };

    const idImageUrl = getIdImage();

    const location = locateApplicant();

    const basicInfoKeys = Object.keys(applicant?.basicInformation || {});
    const additionalInfoKeys = Object.keys(applicant?.additionalInformation || {});
    const workExperienceKeys = Object.keys(applicant?.workExperience || {});
    const workPrefKeys = Object.keys(applicant?.workPreferences || {});
    const bgInfoKeys = Object.keys(applicant?.backgroundInformation || {});
    const shiftPrefKeys = Object.keys(applicant?.shiftPreferences || {});

    const basicInfoCustomFields = customFields.filter(f => basicInfoKeys.includes(f.id));
    const additionalInfoCustomFields = customFields.filter(f => additionalInfoKeys.includes(f.id));
    const workExperienceCustomFields = customFields.filter(f => workExperienceKeys.includes(f.id));
    const workPrefCustomFields = customFields.filter(f => workPrefKeys.includes(f.id));
    const bgInfoCustomFields = customFields.filter(f => bgInfoKeys.includes(f.id));
    const shiftPrefCustomFields = customFields.filter(f => shiftPrefKeys.includes(f.id));

    const renderBasicCustomFields =
      !!basicInfoCustomFields.length || !!additionalInfoCustomFields.length;

    const panes = [
      {
        menuItem: 'General Information',
        pane: {
          key: 'generalInformation',
          content: (
            <TabScrollContainer>
              <TwoColumnsContainer>
                <SectionContainer>
                  <HeaderLabel>Personal Information</HeaderLabel>
                  <TwoColumnsContainer>
                    <div>
                      <FieldLabel>Gender</FieldLabel>
                      <FieldValue>{setGender(applicant?.additionalInformation.gender)}</FieldValue>
                    </div>
                    <div>
                      <FieldLabel>Date of Birth</FieldLabel>
                      <FieldValue>
                        {moment(
                          `${applicant?.basicInformation.dob ||
                            applicant?.basicInformation.birthday}`
                        ).format(FULL_DATE_FORMAT)}
                      </FieldValue>
                    </div>
                    <div>
                      <FieldLabel>SSN</FieldLabel>
                      <FieldValue>{applicant?.basicInformation.ssn}</FieldValue>
                    </div>
                    <div />
                    <span>
                      <FieldLabel>Phone Number</FieldLabel>
                      <FieldValue>
                        {formatPhoneNumber(applicant?.additionalInformation.cellPhone)}
                      </FieldValue>
                    </span>
                    <span>
                      <FieldLabel>Home Number</FieldLabel>
                      <FieldValue>
                        {formatPhoneNumber(applicant?.additionalInformation.homePhone) || '--'}
                      </FieldValue>
                    </span>
                    <span style={{ gridColumn: '1 / span 2' }}>
                      <FieldLabel>Email</FieldLabel>
                      <FieldValue>{applicant?.basicInformation.email}</FieldValue>
                    </span>
                    <span>
                      <FieldLabel>Primary Language</FieldLabel>
                      <FieldValue>
                        {iso6393
                          .filter(l => l.iso6393 === applicant?.shiftPreferences?.primaryLanguage)
                          .map(l => l.name)}
                      </FieldValue>
                    </span>
                    <span>
                      <FieldLabel>Additional Language</FieldLabel>
                      <FieldValue>
                        {Array.isArray(applicant?.shiftPreferences?.additionalLanguages)
                          ? iso6393
                              .filter(
                                l =>
                                  Array.isArray(applicant?.shiftPreferences?.additionalLanguages) &&
                                  applicant?.shiftPreferences?.additionalLanguages.includes(
                                    l.iso6393
                                  )
                              )
                              .map((l, i, a) => (a.length === i + 1 ? l.name : `${l.name}, `))
                          : ''}
                      </FieldValue>
                    </span>
                  </TwoColumnsContainer>
                </SectionContainer>
                <SectionContainer>
                  <HeaderLabel>Address</HeaderLabel>
                  <ThreeColumnsContainer>
                    <span>
                      <FieldLabel>Street</FieldLabel>
                      <FieldValue>{applicant?.additionalInformation.address.street}</FieldValue>
                    </span>
                    <div />
                    <span>
                      <FieldLabel>Apt/Unit #</FieldLabel>
                      <FieldValue>{applicant?.additionalInformation.address.aptUnit}</FieldValue>
                    </span>
                    <span>
                      <FieldLabel>City</FieldLabel>
                      <FieldValue>{applicant?.additionalInformation.address.city}</FieldValue>
                    </span>
                    <span>
                      <FieldLabel>State</FieldLabel>
                      <FieldValue>{applicant?.additionalInformation.address.state}</FieldValue>
                    </span>
                    <span>
                      <FieldLabel>Zip</FieldLabel>
                      <FieldValue>{applicant?.additionalInformation.address.zip}</FieldValue>
                    </span>
                  </ThreeColumnsContainer>
                  <br />
                  <LocationMap
                    editable
                    loadingElement={<div style={{ height: '100%' }} />}
                    containerElement={<div style={{ height: '131px' }} />}
                    mapElement={<div style={{ height: '100%' }} />}
                    showMarker
                    lat={(await location).lat}
                    lon={(await location).lon}
                    markerIcon='/caregiver_marker_blue.svg'
                  />
                </SectionContainer>
              </TwoColumnsContainer>
              {renderBasicCustomFields && (
                <SectionContainer>
                  <HeaderLabel>Additional Information</HeaderLabel>
                  <TwoColumnsContainer>
                    {basicInfoCustomFields.length
                      ? renderFields(basicInfoCustomFields, applicant?.basicInformation)
                      : null}
                    {additionalInfoCustomFields.length
                      ? renderFields(additionalInfoCustomFields, applicant?.additionalInformation)
                      : null}
                  </TwoColumnsContainer>
                </SectionContainer>
              )}
            </TabScrollContainer>
          ),
        },
      },
    ];
    const workExperience = {
      menuItem: 'Work Experience',
      pane: {
        key: 'workExperience',
        content: (
          <TabScrollContainer>
            <TwoColumnsContainer>
              <SectionContainer>
                <HeaderLabel>Relevant Job Experience</HeaderLabel>
                <FieldContainer>
                  <FieldLabel>Years</FieldLabel>
                  <FieldValue>{applicant?.workExperience?.relevantYears}</FieldValue>
                </FieldContainer>
                {Array.isArray(applicant?.workExperience?.previousEmployers) ? (
                  <>
                    {applicant?.workExperience?.previousEmployers.map(p => {
                      return (
                        <FieldContainer>
                          <ThreeColumnsContainer>
                            <span>
                              <FieldLabel>Agency Name</FieldLabel>
                              <FieldValue>{p.agencyName}</FieldValue>
                            </span>
                            <span>
                              <FieldLabel>Start Date</FieldLabel>
                              <FieldValue>{moment(p.start).format(DATE_FORMAT)}</FieldValue>
                            </span>
                            <span>
                              <FieldLabel>End Date</FieldLabel>
                              <FieldValue>{moment(p.end).format(DATE_FORMAT)}</FieldValue>
                            </span>
                          </ThreeColumnsContainer>
                        </FieldContainer>
                      );
                    })}
                  </>
                ) : null}
              </SectionContainer>
              {!!workExperienceCustomFields.length && (
                <SectionContainer>
                  <HeaderLabel>Additional Information</HeaderLabel>
                  {renderFields(workExperienceCustomFields, applicant?.workExperience)}
                </SectionContainer>
              )}
            </TwoColumnsContainer>
          </TabScrollContainer>
        ),
      },
    };
    const backgroundInfo = {
      menuItem: 'Background Information',
      pane: {
        key: 'backgroundInformation',
        content: (
          <TabScrollContainer>
            <TwoColumnsContainer>
              <SectionContainer>
                <HeaderLabel>Identification</HeaderLabel>
                {!!(await idImageUrl).length && (
                  <div>
                    <img
                      alt='Id Document'
                      src={await idImageUrl}
                      style={{ maxWidth: '300px', maxHeight: '200px', textAlign: 'center' }}
                    />
                  </div>
                )}
              </SectionContainer>
              {!!bgInfoCustomFields.length && (
                <SectionContainer>
                  <HeaderLabel>Additional Information</HeaderLabel>
                  {renderFields(bgInfoCustomFields, applicant?.backgroundInformation)}
                </SectionContainer>
              )}
            </TwoColumnsContainer>
          </TabScrollContainer>
        ),
      },
    };
    const preferences = {
      menuItem: 'Preferences',
      pane: {
        key: 'preferences',
        content: (
          <TabScrollContainer>
            <TwoColumnsContainer>
              <SectionContainer>
                <HeaderLabel>Work Preferences</HeaderLabel>
                <FieldContainer>
                  <FieldLabel>Starting Date</FieldLabel>
                  <FieldValue>
                    {moment((applicant?.workPreferences?.startDate as string) || '').format(
                      FULL_DATE_FORMAT
                    )}
                  </FieldValue>
                </FieldContainer>
                <FieldContainer>
                  <FieldLabel>Type of Work</FieldLabel>
                  <FieldValue>
                    {Array.isArray(applicant?.workPreferences?.kindOfWork)
                      ? applicant?.workPreferences?.kindOfWork?.map((l, i, a) =>
                          a.length === i + 1 ? l : `${l}, `
                        )
                      : ''}
                  </FieldValue>
                </FieldContainer>
                <FieldContainer>
                  <FieldLabel>Type of Shift</FieldLabel>
                  <FieldValue>
                    {Array.isArray(applicant?.workPreferences?.preferredShiftTime)
                      ? applicant?.workPreferences?.preferredShiftTime?.map((l, i, a) =>
                          a.length === i + 1 ? l : `${l}, `
                        )
                      : ''}
                  </FieldValue>
                </FieldContainer>
                {applicant?.workPreferences?.weekDays && (
                  <FieldContainer>
                    <FieldLabel>Candidate is willing to work any week day (Mon-Fri)</FieldLabel>
                    <FieldValue>Yes</FieldValue>
                  </FieldContainer>
                )}
                {applicant?.workPreferences?.weekends && (
                  <FieldContainer>
                    <FieldLabel>Candidate is willing to work weekends (Sat-Sun)</FieldLabel>
                    <FieldValue>Yes</FieldValue>
                  </FieldContainer>
                )}
                {applicant?.workPreferences?.liveins && (
                  <FieldContainer>
                    <FieldLabel>Candidate is willing to work live-in</FieldLabel>
                    <FieldValue>Yes</FieldValue>
                  </FieldContainer>
                )}
                {workPrefCustomFields.length
                  ? renderFields(workPrefCustomFields, applicant?.workPreferences)
                  : null}
              </SectionContainer>
              <SectionContainer>
                <HeaderLabel>Client Preferences</HeaderLabel>
                <FieldContainer>
                  <FieldLabel>Travel Time</FieldLabel>
                  <FieldValue>{`${applicant?.shiftPreferences?.travelTime} hr`}</FieldValue>
                </FieldContainer>
                <FieldContainer>
                  <FieldLabel>Travel Method</FieldLabel>
                  <FieldValue>{applicant?.shiftPreferences?.transportMethod}</FieldValue>
                </FieldContainer>
                {applicant?.shiftPreferences?.smokers && (
                  <FieldContainer>
                    <FieldLabel>Candidate will work in a home with smokers present</FieldLabel>
                    <FieldValue>Yes</FieldValue>
                  </FieldContainer>
                )}
                {applicant?.shiftPreferences?.dogs && (
                  <FieldContainer>
                    <FieldLabel>Candidate will work in a home where there are dogs</FieldLabel>
                    <FieldValue>Yes</FieldValue>
                  </FieldContainer>
                )}
                {applicant?.shiftPreferences?.cats && (
                  <FieldContainer>
                    <FieldLabel>Candidate will work in a home where there are cats</FieldLabel>
                    <FieldValue>Yes</FieldValue>
                  </FieldContainer>
                )}
                {shiftPrefCustomFields.length
                  ? renderFields(shiftPrefCustomFields, applicant?.shiftPreferences)
                  : null}
              </SectionContainer>
            </TwoColumnsContainer>
          </TabScrollContainer>
        ),
      },
    };
    if (Object.keys(applicant?.workExperience || {}).length) {
      panes.push(workExperience);
    }
    if (Object.keys(applicant?.backgroundInformation || {}).length) {
      panes.push(backgroundInfo);
    }
    if (Object.keys(applicant?.shiftPreferences || applicant?.workPreferences || {}).length) {
      panes.push(preferences);
    }
    return this.setState({ panes, applicantNotes: applicant?.notes || '' });
  };

  private readonly onClose = () => {
    const { handleClose } = this.props;
    this.saveNotes();
    handleClose();
  };

  public render(): JSX.Element {
    const { applicant } = this.props;
    const { activeTabIndex, panes, applicantNotes } = this.state;

    const notes = {
      menuItem: 'Screener Notes',
      pane: {
        key: 'screenerNotes',
        content: (
          <TabScrollContainer>
            <HeaderLabel>Screener Notes</HeaderLabel>
            <FieldLabel>Notes will be saved when this tab is closed. </FieldLabel>
            <HRApplicantNotes notes={applicantNotes} handleNotesChange={this.handleNotesChange} />
          </TabScrollContainer>
        ),
      },
    };

    return (
      <ApplicantContainer>
        <CloseButton onClick={this.onClose} />
        <HeaderContent>
          <div style={{ justifySelf: 'flex-start' }}>
            <FieldLabel>Applicants Name</FieldLabel>
            <ApplicantName>{`${applicant?.basicInformation.firstName} ${applicant?.basicInformation.lastName}`}</ApplicantName>
          </div>
          <div style={{ justifySelf: 'center', textAlign: 'start' }}>
            <FieldLabel>Date of Application</FieldLabel>
            <DateLabel>
              {moment(applicant?.profileStatus?.completed || '').format(FULL_DATE_FORMAT)}
            </DateLabel>
          </div>
        </HeaderContent>
        {panes.length ? (
          <ApplicantTab
            id='hr-applicants-overview'
            panes={[...panes, notes]}
            renderActiveOnly={false}
            activeIndex={activeTabIndex}
            onTabChange={this.changeTabIndex}
          />
        ) : (
          <Dimmer active inverted>
            <CareLoader loading showText={false} />
          </Dimmer>
        )}
      </ApplicantContainer>
    );
  }
}

export default HRApplicantOverview;

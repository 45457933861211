import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import icon_check_yes from '../images/icon_check_yes.svg';
import colors from './colors';

const { primary, additional, neutrals } = colors;

interface ICheckBoxProps {
  checked?: boolean;
  disabled?: boolean;
  round?: boolean;
  small?: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

interface ISmallTextProps {
  display?: string;
  color?: string;
}

interface IRegularTextProps {
  color?: string;
  display?: string;
  fontWeight?: string;
}

interface ITitleHeaderProps {
  uppercase?: boolean;
  color?: string;
}

export const CheckBox = styled.div.attrs(props => ({
  tabIndex: 0,
  onClick: props.onClick,
}))<ICheckBoxProps>`
  width: ${(props: ICheckBoxProps) => (props.round || props.small ? '15px' : '20px')};
  height: ${(props: ICheckBoxProps) => (props.round || props.small ? '15px' : '20px')};
  display: inline-block;
  border-radius: ${(props: ICheckBoxProps) => (props.round ? '50%' : '4px')};
  border: ${(props: ICheckBoxProps) => (props.checked ? 'none' : `1px solid ${neutrals.steel}`)};
  outline: none;
  opacity: ${(props: ICheckBoxProps) => (props.disabled ? '0.5' : '1')};
  pointer-events: ${(props: ICheckBoxProps) => (props.disabled ? 'none' : 'auto')};
  background-color: ${(props: ICheckBoxProps) => (props.checked ? additional.grassGreen : '')};
  background-image: ${(props: ICheckBoxProps) => (props.checked ? `url(${icon_check_yes})` : '')};
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${(props: ICheckBoxProps) =>
    props.round || props.small ? '15px 15px' : '20px 20px'};
`;

export const CCDropDown = styled(Dropdown)`
  &&&& {
    min-height: 25px !important;
    padding: 0.2rem 2rem 0.2rem 0.2rem;
    border-radius: 4px;
    & .text {
      font-size: 11px;
      color: ${neutrals.charcoal};
    }
    & > .ui.label {
      font-size: 11px;
      color: ${neutrals.charcoal};
      margin-top: 1px !important;
      padding: 0.15rem 0.2rem;
      font-weight: 600;
      & .delete.icon {
        position: relative;
        top: 0.5px;
      }
    }
    & > input.search {
      font-size: 11px;
      color: ${neutrals.charcoal};
      padding: 0;
      margin: 0;
      top: ${props => (props.multiple ? '' : '0.4rem')};
      left: ${props => (props.multiple ? '' : '0.4rem')};
      position: ${props => (props.multiple ? 'relative' : 'absolute')};
    }
    & .menu > .item {
      padding: 0.5rem !important;
    }
    & > .dropdown.icon {
      position: absolute;
      padding: 0.3rem 0.3rem 0.35rem 0.3rem;
      border-left: 1px solid ${neutrals.steel};
      &::before {
        color: transparent;
      }
      &::after {
        content: '';
        position: absolute;
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 0.4rem solid ${neutrals.steel};
        border-left: 0.4rem solid transparent;
        border-right: 0.4rem solid transparent;
        top: 52%;
        left: 55%;
        right: 0;
        bottom: 0;
        transform: translate(-50%, -50%);
      }
    }
    & > .visible.menu.transition {
      width: calc(100% + 2px);
      & div {
        padding: 0.5rem;
      }
      & > .selected.item.addition {
        background-color: ${primary.teal};
      }
      & > div.active.selected.item {
        font-size: 11px;
        color: ${neutrals.charcoal};
      }
    }
    &.disabled {
      background-color: ${neutrals.aluminum};
      opacity: 1;
      & > * {
        opacity: 1;
      }
    }
  }
`;

export const QuestionIcon = styled.div`
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  background-color: ${neutrals.lightGray};
  position: relative;
  display: inline-block;
  &::after {
    content: '?';
    text-align: center;
    position: absolute;
    font-size: 1em;
    font-weight: bold;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: ${neutrals.white};
  }
`;

export const TitleHeader = styled.h2<ITitleHeaderProps>`
  font-size: 14px;
  margin: 0;
  display: inline-block;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  color: ${props => props.color || neutrals.charcoal};
`;

export const SubHeader = styled.h3`
  font-size: 12px;
  margin: 0;
  display: inline-block;
  color: ${neutrals.charcoal};
`;

export const RegularText = styled.p<IRegularTextProps>`
  font-size: 12px;
  color: ${props => props.color || neutrals.charcoal};
  margin: 0;
  display: ${props => props.display || 'block'};
  font-weight: ${props => props.fontWeight || 'normal'};
`;

export const SmallText = styled.p<ISmallTextProps>`
  font-size: 11px;
  margin: 0;
  color: ${props => props.color || neutrals.gray};
  display: ${props => props.display || 'block'};
`;

export default { CheckBox };

import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import { TableHeaderCell } from './styles';

interface IProps {
  tableType: 'caregivers' | 'candidates';
  completed: boolean;
  vaccine: boolean;
  sortName: string;
  sortDirection: string;
  handleSortHeader: (header: string) => void;
}

interface IHeaders {
  name: string;
  key: string;
  textAlign: string;
  content: string | JSX.Element;
}

const HEADERS: IHeaders[] = [
  { name: 'Name', key: 'name', textAlign: 'left', content: 'Name' },
  { name: 'ID', key: 'id', textAlign: 'center', content: 'ID' },
  { name: 'Discipline', key: 'discipline', textAlign: 'center', content: 'Discipline' },
  { name: 'Phone', key: 'phone', textAlign: 'center', content: 'Phone' },
  {
    name: 'Date of Added',
    key: 'date-of-added',
    textAlign: 'center',
    content: 'Date Added',
  },
  { name: 'Last Updated', key: 'last-updated', textAlign: 'center', content: 'Last Updated' },
];

const HeadersRow: React.FC<IProps> = ({
  tableType,
  completed,
  vaccine,
  sortName,
  sortDirection,
  handleSortHeader,
}) => {
  const [headers, setHeader] = useState(HEADERS);

  useEffect(() => {
    let newHeaders = HEADERS;
    if (completed && tableType === 'candidates') {
      if (newHeaders.some(h => h.key === 'completion-status')) {
        newHeaders = newHeaders.filter(h => h.key !== 'completion-status');
      }
      if (!newHeaders.some(h => h.key === 'selection')) {
        newHeaders.push({
          name: 'Selection',
          key: 'selection',
          textAlign: 'center',
          content: 'Selection',
        });
      }
    } else if (!newHeaders.some(h => h.key === 'completion-status')) {
      if (newHeaders.some(h => h.key === 'selection')) {
        newHeaders = newHeaders.filter(h => h.key !== 'selection');
      }
      newHeaders.push({
        name: 'Completion Status',
        key: 'completion-status',
        textAlign: 'center',
        content: 'Completion Status',
      });
    }
    if (vaccine && !newHeaders.some(h => h.key === 'COVID')) {
      const firstCells = newHeaders.slice(0, 2);
      const restCells = newHeaders.slice(2);
      firstCells.push({
        name: 'Vaccine',
        key: 'COVID',
        textAlign: 'center',
        content: <img width='30' src='/covid-icon-red.png' alt='covid red icon' />,
      });
      newHeaders = [...firstCells, ...restCells];
    }
    setHeader(newHeaders);
  }, [completed, vaccine]);

  return (
    <Table.Row>
      {headers.map(h => {
        const sort = () => {
          handleSortHeader(h.key);
        };
        return (
          <TableHeaderCell
            key={h.key}
            textAlign={h.textAlign}
            sorted={sortName === h.key ? sortDirection : ''}
            onClick={sort}
          >
            {h.content}
          </TableHeaderCell>
        );
      })}
    </Table.Row>
  );
};

export default HeadersRow;

/* eslint-disable import/prefer-default-export */
import * as types from '../constants/ActionTypes';
import { backend } from '../services';
import { alertActions } from './alert';

function getPreferenceChangeLog(params, partialUpdate = false) {
  return dispatch => {
    dispatch(request());

    backend.getEvents(params).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_PREFERENCE_CHANGELOG_REQUEST, partialUpdate };
  }
  function success(results) {
    return { type: types.GET_PREFERENCE_CHANGELOG_SUCCESS, results, partialUpdate };
  }
  function failure(error) {
    return { type: types.GET_PREFERENCE_CHANGELOG_FAILURE, error, partialUpdate };
  }
}

function getShiftChangeLog(params, partialUpdate = false) {
  return dispatch => {
    dispatch(request());

    backend.getShiftEvents(params).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_SHIFT_CHANGELOG_REQUEST, partialUpdate };
  }
  function success(results) {
    return { type: types.GET_SHIFT_CHANGELOG_SUCCESS, results, partialUpdate };
  }
  function failure(error) {
    return { type: types.GET_SHIFT_CHANGELOG_FAILURE, error, partialUpdate };
  }
}

function getShiftChangeLogCsv(params) {
  return dispatch => {
    dispatch(request());

    backend.getShiftEventsForCsv(params).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_SHIFT_CHANGELOG_CSV_REQUEST };
  }
  function success() {
    return { type: types.GET_SHIFT_CHANGELOG_CSV_REQUEST };
  }
  function failure(error) {
    return { type: types.GET_SHIFT_CHANGELOG_CSV_FAILURE, error };
  }
}

function getEmployeeChangeLog(params, partialUpdate = false) {
  return dispatch => {
    dispatch(request());

    backend.getEmployeeEvents(params).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_EMPLOYEE_CHANGELOG_REQUEST, partialUpdate };
  }
  function success(results) {
    return { type: types.GET_EMPLOYEE_CHANGELOG_SUCCESS, results, partialUpdate };
  }
  function failure(error) {
    return { type: types.GET_EMPLOYEE_CHANGELOG_FAILURE, error, partialUpdate };
  }
}

export const changeLogActions = {
  getPreferenceChangeLog,
  getShiftChangeLog,
  getEmployeeChangeLog,
  getShiftChangeLogCsv,
};

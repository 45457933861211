/* eslint-disable react/prop-types */
// eslint-disable-next-line max-classes-per-file
import React from 'react';
import PropTypes from 'prop-types';
import { Image, Select } from 'semantic-ui-react';
import { connect } from 'react-redux';

class BaseFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
    this.addFilter = this.addFilter.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.inactiveText = this.inactiveText.bind(this);
  }

  addFilter() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.addFilter(this.props.type);
  }

  removeFilter() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.removeFilter(this.props.filterState);
  }

  toggleEditMode() {
    this.setState(s => ({ ...s, active: !s.active }));
  }

  closeEditMode() {
    this.setState(s => ({ ...s, active: false }));
  }

  inactiveText(filterState) {
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.inactiveText(filterState);
  }

  render() {
    const { name, filterState } = this.props;
    const { active } = this.state;

    return (
      <div>
        {!filterState && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div className='addFilter clickable' role='button' tabIndex='0' onClick={this.addFilter}>
            +{name}
          </div>
        )}
        {!active && filterState && filterState.value && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            className='editFilter clickable'
            role='button'
            tabIndex='0'
            onClick={this.toggleEditMode}
          >
            {this.inactiveText(filterState)}
            <Image
              src='/remove_announcement_filter.svg'
              className='removeFilter'
              onClick={this.removeFilter}
            />
          </div>
        )}
        {filterState && (active || !filterState.value) && this.renderEditor()}
      </div>
    );
  }
}

BaseFilter.propTypes = {
  addFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  inactiveText: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  filterState: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
};

class SelectFilter extends BaseFilter {
  constructor() {
    super();

    this.selectValue = this.selectValue.bind(this);
    this.inactiveText = this.inactiveText.bind(this);
  }

  selectValue(e, target) {
    const newValue = { ...this.props.filterState, value: target.value };
    this.props.onChange(newValue);
    this.closeEditMode();
  }

  inactiveText(filterState) {
    const state = this.props.selectOptions.find(f => f.value === filterState.value);
    return this.props.inactiveText(state.text);
  }

  renderEditor() {
    return (
      <div className='editFilter'>
        {!this.props.filterState.value && (
          <div style={{ 'margin-bottom': '2px' }}>{this.props.inactiveText('...')}</div>
        )}
        <Select
          placeholder='Choose'
          options={this.props.selectOptions}
          onChange={this.selectValue}
          value={this.props.filterState.value}
          open={this.props.filterState.value}
        />
      </div>
    );
  }
}

const StatusFilterOptions = [
  { value: 'active', key: 'active', text: 'Active' },
  { value: 'inactive', key: 'inactive', text: 'Inactive' },
  { value: 'suspended', key: 'suspended', text: 'Suspended' },
  { value: 'pending', key: 'pending', text: 'Pending' },
];

// !PropTypes validation does not work properly on those functions

export const StatusFilter = ({ addFilter, removeFilter, filterState, filterChanged }) => (
  <SelectFilter
    type='status'
    name='Caregiver Status'
    inactiveText={text => `Caregiver Status is ${text}`}
    selectOptions={StatusFilterOptions}
    filterState={filterState}
    onChange={filterChanged}
    addFilter={addFilter}
    removeFilter={removeFilter}
  />
);

const DisciplineFilterModel = ({
  addFilter,
  removeFilter,
  filterState,
  filterChanged,
  disciplines,
}) => (
  <SelectFilter
    type='discipline'
    name='Disciplines'
    inactiveText={text => `Caregiver Disciplines are ${text}`}
    onChange={filterChanged}
    addFilter={addFilter}
    filterState={filterState}
    selectOptions={disciplines}
    removeFilter={removeFilter}
  />
);

const mapStateToProps = state => {
  const disciplines = state.principal.disciplines.map(d => ({ ...d, key: d.value }));
  return {
    disciplines,
  };
};

export const DisciplineFilter = connect(mapStateToProps)(DisciplineFilterModel);

const GenderFilterOptions = [
  { value: 'm', key: 'm', text: 'Male' },
  { value: 'f', key: 'f', text: 'Female' },
  { value: 'u', key: 'u', text: 'Other' },
];

export const GenderFilter = ({ addFilter, removeFilter, filterState, filterChanged }) => (
  <SelectFilter
    type='gender'
    name='Gender'
    inactiveText={text => `Caregiver Gender is ${text}`}
    onChange={filterChanged}
    filterState={filterState}
    selectOptions={GenderFilterOptions}
    addFilter={addFilter}
    removeFilter={removeFilter}
  />
);

export const filterWithState = (filterState, filterChanged, removeFilter) => {
  switch (filterState.type) {
    case 'status':
      return (
        <StatusFilter
          filterState={filterState}
          filterChanged={filterChanged}
          removeFilter={removeFilter}
        />
      );
    case 'discipline':
      return (
        <DisciplineFilter
          filterState={filterState}
          filterChanged={filterChanged}
          removeFilter={removeFilter}
        />
      );
    case 'gender':
      return (
        <GenderFilter
          filterState={filterState}
          filterChanged={filterChanged}
          removeFilter={removeFilter}
        />
      );
    default:
      return <div />;
  }
};

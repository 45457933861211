import React from 'react';
import PropTypes from 'prop-types';
import './CareEditorTabs.css';

const CareEditorTabs = ({ labels, activeTab }) => {
  const rows = [];
  for (let i = 0; i < labels.length; i += 1) {
    rows.push(
      <li key={i} className={activeTab === i ? 'active' : ''}>
        <span>{labels[i]}</span>
      </li>
    );
  }

  return (
    <div className='careEditorTabs'>
      <ul>{rows}</ul>
    </div>
  );
};

CareEditorTabs.defaultProps = {
  activeTab: 0,
  labels: [],
};

CareEditorTabs.propTypes = {
  activeTab: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.string),
};

export default CareEditorTabs;

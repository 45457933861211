/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react';
import { Image, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as CompHelper from './complianceHelpers';
import * as compTypes from './ComplianceMedicalTypes';
import { caregiverActions } from '../../../actions';
import CGComplianceUIActions from '../../../actions/CGComplianceUIActions';

type Props = {
  blocks: Array<Block>;
  caregiverComplianceUI: any;
  caregiverCompliance: any;
  dispatch: (arg: any) => void;
  caregiver: any;
};

type State = {
  closeEnabled: boolean;
  showResultEditPanel: boolean;
  showVaccinationEditPanel: boolean;
  draftState: DraftState;
};

type DraftState = {
  testDate?: compTypes.DraftStateField;
  testResult?: compTypes.DraftStateField;
  testReading?: compTypes.DraftStateField;
  vaccinationDate?: compTypes.DraftStateField;
};

interface Block extends compTypes.Blocks {
  name: 'Rubella Test' | 'Rubella Vaccination';
}

interface RubellaBlocks {
  [key: string]: Block;
  rubellaTest: Block;
  rubellaVaccination: Block;
}

type BlockField = compTypes.BlockField;

class ComplianceRubellaTest extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showResultEditPanel: false,
      showVaccinationEditPanel: false,
      closeEnabled: false,
      draftState: {
        testDate: undefined,
        testResult: undefined,
        testReading: undefined,
        vaccinationDate: undefined,
      },
    };
  }

  public componentDidMount = () => {
    this.setFieldsToState();
  };

  private readonly setFieldsToState = (): void => {
    const { blocks } = this.props;
    const [rubellaTest] = blocks.filter((b: Block) => b.name === 'Rubella Test');
    const [rubellaVaccination] = blocks.filter((b: Block) => b.name === 'Rubella Vaccination');

    const [testDate] = rubellaTest.fields.filter((f: BlockField) => f.type === 'date');
    const [testResult] = rubellaTest.fields.filter((f: BlockField) => f.type === 'text');
    const [testReading] = rubellaTest.fields.filter((f: BlockField) => f.type === 'double');
    const [vaccinationDate] = rubellaVaccination.fields.filter(
      (f: BlockField) => f.type === 'date'
    );

    this.setState(s => ({
      ...s,
      draftState: {
        ...s.draftState,
        testDate: {
          value: testDate.value ? moment(testDate.value) : '',
          id: testDate.id,
        },
        testResult: {
          value: testResult.value || '',
          id: testResult.id,
        },
        testReading: {
          value: testReading.value || '',
          id: testReading.id,
        },
        vaccinationDate: {
          value: vaccinationDate.value ? moment(vaccinationDate.value) : '',
          id: vaccinationDate.id,
        },
      },
    }));
  };

  private readonly addTitreCompletion = (): void => {
    this.setState(s => ({
      ...s,
      showResultEditPanel: !s.showResultEditPanel,
      closeEnabled: true,
    }));
  };

  private readonly addVaccinationCompletion = (): void => {
    this.setState(s => ({
      ...s,
      showVaccinationEditPanel: !s.showVaccinationEditPanel,
      closeEnabled: true,
    }));
  };

  private readonly onClose = (): void => {
    const { dispatch } = this.props;
    this.setFieldsToState();
    this.setState(
      s => ({
        ...s,
        closeEnabled: false,
        showResultEditPanel: false,
        showVaccinationEditPanel: false,
      }),
      () => dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: [] }))
    );
  };

  private readonly checkDoubleValid = (field: BlockField, value: any) => {
    const { caregiverComplianceUI, dispatch } = this.props;
    // eslint-disable-next-line no-restricted-globals
    const isNumber = !isNaN(value);
    const tooLong = value.length > 6;
    const notValid = caregiverComplianceUI && caregiverComplianceUI.notValidValuesIds;
    if ((!isNumber || tooLong) && !notValid.includes(field.id)) {
      notValid.push(field.id);
    }
    if (isNumber && !tooLong && notValid.includes(field.id)) {
      const index = notValid.indexOf(field.id);
      notValid.splice(index, 1);
    }

    return dispatch(
      CGComplianceUIActions.updateState({
        isNotValid: !!notValid.length,
        notValidValuesIds: [...notValid],
      })
    );
  };

  private readonly onFieldChanged = (field: BlockField, value: any) => {
    const { caregiverComplianceUI, dispatch } = this.props;
    const { dirtyMedicalBlocks } = caregiverComplianceUI;

    let changedValue = value;

    if (field.type === 'double') {
      this.checkDoubleValid(field, value);
      if (value.length === 0) {
        changedValue = null;
      }
    }

    let theField = dirtyMedicalBlocks.find((f: BlockField) => f.id === field.id);

    if (!theField) {
      theField = JSON.parse(JSON.stringify(field));
      dirtyMedicalBlocks.push(theField);
    }
    theField.value = changedValue;

    if (field.type === 'date') {
      this.onDateChange(value, field.id);
    }

    if (field.type === 'text') {
      this.setState(s => ({
        ...s,
        draftState: { ...s.draftState, testResult: { value, id: field.id } },
      }));
    }

    if (field.type === 'double') {
      this.setState(s => ({
        ...s,
        draftState: { ...s.draftState, testReading: { value, id: field.id } },
      }));
    }

    dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks }));
  };

  private readonly onDateChange = (date: any, selected: number): void => {
    const { draftState } = this.state;
    let valueToSet: string;
    let selectedId: number;
    /* eslint-disable-next-line no-restricted-syntax */
    for (const [key, value] of Object.entries(draftState)) {
      if (value?.id === selected) {
        valueToSet = key;
        selectedId = value.id;
      }
    }

    this.setState(s => ({
      ...s,
      draftState: {
        ...s.draftState,
        [valueToSet]: {
          value: date && moment(date).isBefore(moment()) ? moment(date) : '',
          id: selectedId,
        },
      },
    }));
  };

  private readonly updateMedicalBlock = () => {
    const { caregiver, blocks, caregiverComplianceUI, dispatch } = this.props;
    const { dirtyMedicalBlocks } = caregiverComplianceUI;

    if (Object.keys(dirtyMedicalBlocks).length) {
      const dirtyFieldsToSave: Array<BlockField> = [];
      dirtyMedicalBlocks.forEach((s: BlockField) => {
        const [block] = blocks.filter((a: Block) =>
          a.fields.find((f: BlockField) => f.id === s.id)
        );
        const b = block?.fields.find((a: BlockField) => a.id === s.id);
        if (b && ((b.value && b.value !== s.value) || (!b.value && s.value))) {
          dirtyFieldsToSave.push(s);
        }
      });

      if (dirtyFieldsToSave.length) {
        dispatch(
          caregiverActions.setCaregiverComplianceBlockFields(caregiver.id, dirtyFieldsToSave)
        );
        dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: [] }));
      }
    }

    this.setState(s => ({
      ...s,
      closeEnabled: false,
      showResultEditPanel: false,
      showVaccinationEditPanel: false,
    }));
  };

  private readonly onRemove = () => {
    const dirtyFieldToClear: Array<BlockField> = [];
    const { caregiver, dispatch, blocks } = this.props;
    blocks.forEach((block: Block) =>
      block.fields.forEach(f => {
        dirtyFieldToClear.push({
          id: f.id,
          name: f.name,
          type: f.type,
          fixed: 1,
          value: null,
          isDirty: true,
        });
      })
    );
    dispatch(caregiverActions.setCaregiverComplianceBlockFields(caregiver.id, dirtyFieldToClear));

    this.setState(
      s => ({
        ...s,
        draftState: {
          ...s.draftState,
          testDate: {
            ...s.draftState.testDate,
            value: '',
          },
          testResult: {
            ...s.draftState.testResult,
            value: '',
          },
          testReading: {
            ...s.draftState.testReading,
            value: '',
          },
          vaccinationDate: {
            ...s.draftState.vaccinationDate,
            value: '',
          },
        },
        closeEnabled: false,
      }),
      () => dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: [] }))
    );
  };

  public render(): JSX.Element {
    const { blocks, caregiverComplianceUI } = this.props;
    const { showResultEditPanel, closeEnabled, draftState, showVaccinationEditPanel } = this.state;

    const { testDate, testResult, testReading, vaccinationDate } = draftState;

    const { dirtyMedicalBlocks } = caregiverComplianceUI;

    const [rubellaTest] = blocks.filter((b: Block) => b.name === 'Rubella Test');
    const [rubellaVaccination] = blocks.filter((b: Block) => b.name === 'Rubella Vaccination');

    const [testDateField] = rubellaTest?.fields.length
      ? rubellaTest.fields.filter((f: BlockField) => f.type === 'date')
      : [];
    const [testResultField] = rubellaTest?.fields.length
      ? rubellaTest.fields.filter((f: BlockField) => f.type === 'text')
      : [];
    const [testReadingField] = rubellaTest?.fields.length
      ? rubellaTest.fields.filter((f: BlockField) => f.type === 'double')
      : [];
    const [vaccinationDateField] = rubellaVaccination?.fields.length
      ? rubellaVaccination.fields.filter((f: BlockField) => f.type === 'date')
      : [];

    const rubellaBlocks: RubellaBlocks = {
      rubellaTest,
      rubellaVaccination,
    };

    const testResultCompleted = !!testResult?.value;
    const vaccinationDateCompleted = !!vaccinationDate?.value;

    const testResultPass = testResultField?.value === 'I';

    const showTestInfoPanel = testResultCompleted && !showResultEditPanel;

    const showRemoveButton = showTestInfoPanel || vaccinationDateCompleted;

    const showVaccinationPanel = vaccinationDateCompleted && !showVaccinationEditPanel;

    const blockCompleted = testResultPass || vaccinationDateCompleted;

    const showCompletion = !showResultEditPanel && !showVaccinationEditPanel;

    return (
      <div className='infoPanelMedicalBlock'>
        <div className='compliances-tab-header'>
          <img src={`/compliance/${rubellaBlocks.rubellaTest?.icon}`} alt='compliance-icon' />
          <p
            className={rubellaBlocks.rubellaTest?.completed ? 'blackTitle' : 'redTitle'}
            style={{ margin: '0' }}
          >
            Rubella Test
          </p>
          {!rubellaBlocks.rubellaTest?.completed ? (
            <img
              style={{ marginLeft: '10px' }}
              src='/compliance/attention.svg'
              alt='attention-icon'
            />
          ) : (
            ''
          )}
        </div>
        <div>
          <p className='compliance-medium-gray'>
            Rubella testing is a <b className='compliance-charcoal-text-color'>One-time</b>{' '}
            requirement needed to be in compliance to work. Caregivers must receive, or prove they
            have received the vaccination and undergo a test to determine immunity.
          </p>
        </div>
        {showTestInfoPanel && (
          <div className='hep-b-test-series-one-container compliance-info-panel'>
            <div className='label-result-in-line-container'>
              <p className='compliance-block-completion-field-text'>Rubella Titre Date:</p>
              <div className='medicalDateInput'>
                <CompHelper.DateText date={testDate?.value} />
              </div>
            </div>
            <div className='label-result-in-line-container'>
              <p className='compliance-block-completion-field-text'>Rubella Titre Reading:</p>
              <div className='medicalDateInput'>
                <CompHelper.ReadingText testReading={testReading?.value} />
              </div>
            </div>
            <div className='label-result-in-line-container'>
              <p className='compliance-block-completion-field-text'>Rubella Titre Result:</p>
              <div className='medicalDateInput'>
                <CompHelper.ResultText value={testResult?.value} group='Rubella' />
              </div>
            </div>
          </div>
        )}
        {showVaccinationPanel && (
          <div className='series-two-divider compliance-info-panel'>
            <h3 className='compliance-add-completion-panel-header'>Rubella Vaccination</h3>
            <div className='label-result-in-line-container'>
              <p className='compliance-block-completion-field-text'>Rubella Vaccination Date:</p>
              <div className='medicalDateInput'>
                <CompHelper.DateText date={vaccinationDate?.value} />
              </div>
            </div>
          </div>
        )}
        {closeEnabled && (
          <Image className='tinyCloseButton' src='/close-icon.svg' onClick={this.onClose} />
        )}
        {showResultEditPanel && (
          <div className='hep-b-test-series-one-container compliance-add-completion-panel'>
            <h3 className='compliance-add-completion-panel-header'>ENTER TITRE TEST DETAILS</h3>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                Rubella Titre Date
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-end', marginRight: '20px' }}
              >
                <CompHelper.ComplianceDatePicker
                  field={testDateField}
                  state={testDate}
                  disabled={false}
                  onChange={this.onFieldChanged}
                />
              </div>
            </div>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                Rubella Titre Reading
              </p>
              <CompHelper.ComplianceReading
                field={testReadingField}
                state={testReading?.value}
                onChange={this.onFieldChanged}
              />
            </div>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                Rubella Titre Result
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-end', marginRight: '20px' }}
              >
                <Dropdown
                  placeholder='choose'
                  selection
                  className='compliance-dropdown'
                  key={testResultField.id}
                  value={testResult?.value}
                  options={[
                    { text: 'Immune', value: 'I' },
                    { text: 'Not Immune', value: 'NI' },
                  ]}
                  onChange={(_e, input) => {
                    this.onFieldChanged(testResultField, input.value);
                  }}
                />
              </div>
            </div>
            <CompHelper.UpdateButton
              disabled={
                !(
                  dirtyMedicalBlocks.length &&
                  !!testDate?.value &&
                  !!testResult?.value &&
                  // eslint-disable-next-line no-restricted-globals
                  !isNaN(testReading?.value)
                )
              }
              update={this.updateMedicalBlock}
            />
          </div>
        )}
        {showVaccinationEditPanel && (
          <div className='hep-b-test-series-one-container compliance-add-completion-panel'>
            <h3 className='compliance-add-completion-panel-header'>ENTER VACCINATION DETAILS</h3>
            <div className='hep-b-test-series-one-container compliance-add-completion-panel'>
              <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
                <p
                  className='compliance-block-completion-field-text'
                  style={{ justifySelf: 'flex-start' }}
                >
                  Vaccination Date
                </p>
                <div
                  className='medicalDateInput'
                  style={{ justifySelf: 'flex-end', marginRight: '20px' }}
                >
                  <CompHelper.ComplianceDatePicker
                    field={vaccinationDateField}
                    state={vaccinationDate}
                    disabled={false}
                    onChange={this.onFieldChanged}
                  />
                </div>
              </div>
              <CompHelper.UpdateButton
                disabled={!dirtyMedicalBlocks.length}
                update={this.updateMedicalBlock}
              />
            </div>
          </div>
        )}
        {showCompletion && (
          <div>
            {!blockCompleted && (
              <div className='compliance-buttons-container'>
                <CompHelper.SimpleButton onClick={this.addTitreCompletion}>
                  Add titre test info
                </CompHelper.SimpleButton>
                <CompHelper.SimpleButton onClick={this.addVaccinationCompletion}>
                  Add vaccination info
                </CompHelper.SimpleButton>
              </div>
            )}
            <div className='compliance-buttons-container'>
              {blockCompleted ? (
                <p className='block-result-text' style={{ color: '#70DC94' }}>
                  <Image
                    src='/compliance/check.svg'
                    style={{ paddingRight: '5px', display: 'inline' }}
                  />
                  COMPLETED:{' '}
                  <CompHelper.DateText date={vaccinationDate?.value || testDate?.value} />
                </p>
              ) : (
                <div />
              )}
              {showRemoveButton && (
                <span style={{ justifySelf: 'flex-end' }}>
                  <CompHelper.RemoveButton onRemove={this.onRemove} />
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { caregiverDetails, caregiverCompliance, caregiverComplianceUI } = state;
  return {
    caregiver: caregiverDetails.caregiver,
    caregiverCompliance,
    caregiverComplianceUI,
  };
};

export default connect(mapStateToProps)(ComplianceRubellaTest);

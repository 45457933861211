import {
  ALERT_MESSAGE,
  ALERT_NOTIFICATION,
  ALERT_ERROR,
  ALERT_CLEAR,
  ALERT_CLEAR_ERRORS,
} from '../constants/ActionTypes';

function findAlert(state, objOrId) {
  if (!objOrId) {
    return null;
  }

  let alertId = objOrId;
  if (typeof objOrId === 'object') {
    alertId = objOrId.id;
  }

  return state.filter(a => a.id === alertId)[0];
}

function alert(state = [], action) {
  const newState = [...state];
  switch (action.type) {
    case ALERT_MESSAGE:
      newState.push({
        message: action.message,
        label: action.opt.label,
        cancel: action.opt.cancel,
        dispatch: action.opt.action,
        avatar: action.opt.avatar,
        success: true,
        id: action.id,
        url: action.opt.url,
        group: action.group,
        httpStatus: action.httpStatus,
      });
      return newState;
    case ALERT_NOTIFICATION:
      newState.push({
        message: action.message,
        success: true,
        id: action.id,
        group: action.group,
        httpStatus: action.httpStatus,
      });
      return newState;
    case ALERT_ERROR:
      Object.assign(action, { opt: action.opt || {} });
      newState.push({
        message: action.message,
        label: action.opt.label,
        cancel: action.opt.cancel,
        dispatch: action.opt.action,
        avatar: action.opt.avatar,
        priority: action.priority,
        validationError: action.opt.validationError,
        error: true,
        id: action.id,
        url: action.opt.url,
        loggedOut: action.opt.loggedOut,
        group: action.group,
        httpStatus: action.httpStatus,
      });
      return newState;
    case ALERT_CLEAR: {
      const alertObj = findAlert(state, action.alert);

      if (alertObj) {
        const filter =
          alertObj && alertObj.group ? a => a.group !== alertObj.group : a => a.id !== alertObj.id;
        return newState.filter(filter);
      }
      return newState;
    }
    case ALERT_CLEAR_ERRORS: {
      return newState.filter(e => e.group !== 'error');
    }
    default:
      return state;
  }
}

export default alert;

/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MenuDropdownItem from './subcomponents/MenuDropdownItem';

const PrimaryContainer = styled.div`
  padding: 0 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const DropdownContainer = styled.div`
  width: 220px;
  margin: 0 0.5rem;
`;

class TeamForm extends React.Component {
  preparePrimaryList = () => {
    const {
      uiState: { primaryContacts },
    } = this.props;
    const emptyVal = [{ text: 'All Contacts', value: 0 }];
    if (primaryContacts) {
      const employeeList = primaryContacts
        ? emptyVal.concat(
            primaryContacts.map(e => ({
              text: `${e.lastName ? e.lastName : ''}, ${e.firstName ? e.firstName : ''} ${
                e.status === 'suspended' ? '(Suspended)' : ''
              }`,
              value: e.id,
              className: e.status === 'suspended' ? 'dropdown-suspended-employee' : '',
            }))
          )
        : emptyVal;
      return employeeList;
    }
    return emptyVal;
  };

  preparePrimaryManagerList = () => {
    const {
      uiState: { primaryContactManager },
    } = this.props;
    const emptyVal = [{ text: 'All Managers', value: 0 }];
    if (primaryContactManager) {
      const employeeList = primaryContactManager
        ? emptyVal.concat(
            primaryContactManager.map(e => ({
              text: `${e.lastName ? e.lastName : ''}, ${e.firstName ? e.firstName : ''} ${
                e.status === 'suspended' ? '(Suspended)' : ''
              }`,
              value: e.id,
              className: e.status === 'suspended' ? 'dropdown-suspended-employee' : '',
            }))
          )
        : emptyVal;
      return employeeList;
    }
    return emptyVal;
  };

  render() {
    const { uiState, onChange, onTitleClick, primaryContactManager } = this.props;

    return primaryContactManager ? (
      <PrimaryContainer>
        <DropdownContainer>
          <MenuDropdownItem
            active={uiState.teamMenu}
            title='Primary Contacts'
            index='teamMenu'
            onTitleClick={onTitleClick}
            name='selectedPrimaryContacts'
            options={this.preparePrimaryList()}
            values={uiState.selectedPrimaryContacts}
            onValueChange={onChange}
            id='clientsSearchPrimaryContactsDropdown'
            key='clientsSearchPrimaryContactsDropdown'
          />
        </DropdownContainer>
        <DropdownContainer>
          <MenuDropdownItem
            active={uiState.teamMenu}
            title='Primary Contacts Manager'
            index='teamMenu'
            onTitleClick={onTitleClick}
            name='selectedPrimaryContactsManager'
            options={this.preparePrimaryManagerList()}
            values={uiState.selectedPrimaryContactsManager}
            onValueChange={onChange}
            id='clientsSearchPrimaryContactsManagerDropdown'
            key='clientsSearchPrimaryContactsManagerDropdown'
          />
        </DropdownContainer>
      </PrimaryContainer>
    ) : (
      <MenuDropdownItem
        active={uiState.teamMenu}
        title='Primary Contacts'
        index='teamMenu'
        onTitleClick={onTitleClick}
        name='selectedPrimaryContacts'
        options={this.preparePrimaryList()}
        values={uiState.selectedPrimaryContacts}
        onValueChange={onChange}
        id='clientsSearchPrimaryContactsDropdown'
        key='clientsSearchPrimaryContactsDropdown'
      />
    );
  }
}

TeamForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  uiState: PropTypes.shape().isRequired,
  onTitleClick: PropTypes.func.isRequired,
};

export default TeamForm;

import * as types from '../constants/ActionTypes';

function updateState(state) {
  return dispatch => dispatch({ type: types.UPDATE_CAREGIVER_COMPLIANCE_UI, state });
}

function clearState() {
  return dispatch => dispatch({ type: types.CLEAR_CAREGIVER_COMPLIANCE_UI });
}

const CGComplianceUIActions = {
  updateState,
  clearState,
};

export default CGComplianceUIActions;

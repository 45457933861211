import React from 'react';
import { Grid, Segment, Image, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import CareLoader from './CareLoader';

const InitialState = ({ searchingFor, loading }) => (
  <Grid.Row colums='one' className='emptyStateRow' centered>
    {!loading && (
      <Segment basic>
        <Header color='grey' size='medium'>
          Enter Information To Search for Matches
        </Header>
        <Image src='/searchmatch.svg' />
        <Header color='grey' size='small'>
          Please enter some information to start searching
          <br /> for {searchingFor}
        </Header>
      </Segment>
    )}
    {loading && (
      <div className='emptyStateLoader'>
        <CareLoader loading={loading} />
      </div>
    )}
  </Grid.Row>
);

InitialState.propTypes = {
  searchingFor: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default InitialState;

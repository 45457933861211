/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Tab,
  Dimmer,
  Image,
  Dropdown,
  Grid,
  Pagination,
  Button,
  Modal,
  Input,
  DropdownOnSearchChangeData,
  DropdownProps,
  Form,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import FileSaver from 'file-saver';
import UtilizationReportTable from './subcomponents/eLAPP/UtilizationReportTable';
import CareLoader from './subcomponents/CareLoader';
import CertificatesExportReportTable from './subcomponents/eLAPP/CertificatesExportReportTable';
import QuizAnswersReportTable from './subcomponents/eLAPP/QuizAnswersReportTable';
import './subcomponents/eLAPP/ELearningUsersFilterForm.css';
import './ELCourses.css';
import './ELReports.css';
import { backend } from '../services';
import { alertActions } from '../actions/alert';
import { sortReportTableData, Sorted } from './subcomponents/eLAPP/ReportTableHelper';

const formatDate = (date: string): string => (date ? moment(date).toISOString() : '');

const PAGE_SIZE = 10;

const SleepTime = 2000;

type ReportsProps = {
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  dispatch: (e: any) => void;
};

type ReportState = {
  activeTabIndex: number | string | undefined;
  searchQuery: string;
  searchResults?: Array<SearchResult>;
  profiles: Array<Profile>;
  profile?: Profile;
  pageNum: number;
  pageSize: number;
  loadedPages: Page;
  loading: boolean;
  searchLoading: boolean;
  showCsvModal: boolean;
  startDate?: any;
  endDate?: any;
  exportLoading: boolean;
  zipSelected: boolean;
  filterCourse?: any;
  latestSearch: string;
};

type Program = {
  programName: string;
  id: number;
};

type Profile = {
  profileType: string;
  id: number;
  uuid: string;
  userUuid: string;
  agencyUuid: string;
  agencyExternalId: string;
  firstName: string;
  lastName: string;
  email: string;
  disciplines: Array<string>;
  staffStatus: string;
  languages: Array<string | null>;
  roles: Array<string>;
  programs: Array<Program>;
};

type Page = {
  [key: number]: any;
};

type SearchResult = {
  text: string;
  value: string;
};

type ReportParams = {
  pageNum: number;
  pageSize?: number;
  profileUuid?: string;
  csv?: boolean;
  tab?: number | string;
  failedOnly?: boolean;
  completedOnly?: boolean;
  startDate?: any;
  endDate?: any;
};

const DocumentType = {
  CERTIFICATE: 'certificate',
  SIGNATURE: 'signature',
};

class ELReports extends React.Component<ReportsProps, ReportState> {
  constructor(props: ReportsProps) {
    super(props);
    this.state = {
      activeTabIndex: 0,
      searchQuery: '',
      searchResults: [],
      profiles: [],
      profile: undefined,
      pageNum: 0,
      pageSize: PAGE_SIZE,
      loadedPages: {},
      loading: false,
      showCsvModal: false,
      searchLoading: false,
      startDate: moment()
        .subtract(7, 'd')
        .startOf('day'),
      endDate: moment().endOf('day'),
      exportLoading: false,
      zipSelected: false,
      filterCourse: 'all',
      latestSearch: '',
    };
  }

  private readonly ELReportsPanes = (
    child: JSX.Element,
    emptyData: boolean,
    name: string,
    key: string,
    totalPages: number
  ): { menuItem: string; pane: { key: string; content: JSX.Element } } => {
    const buttonText: string = key === 'certificatesExport' ? 'Export Documents' : 'Export CSV';
    const filterCoursesOptions = [
      { value: 'all', text: 'All' },
      { value: 'completedOnly', text: 'Completed' },
      { value: 'failedOnly', text: 'Failed' },
    ];
    const {
      profile,
      searchResults,
      searchQuery,
      searchLoading,
      pageNum,
      showCsvModal,
      startDate,
      endDate,
      loading,
      exportLoading,
      zipSelected,
      filterCourse,
    } = this.state;

    const table = {
      menuItem: name,
      pane: {
        key,
        content: (
          <Grid>
            <Grid.Row>
              <Grid.Column width={4} verticalAlign='middle' style={{ marginTop: '26px' }}>
                <Dropdown
                  id='elearning-reports-user-search'
                  className='elearning-reports-user-search-dropdown'
                  fluid
                  placeholder='Find by Learner Name'
                  clearable
                  selection
                  options={searchResults}
                  search={!profile?.uuid}
                  selectOnNavigation={false}
                  selectOnBlur={false}
                  searchQuery={searchResults?.length && profile?.uuid ? undefined : searchQuery}
                  disabled={loading}
                  onSearchChange={(_event, data: DropdownOnSearchChangeData) =>
                    this.onSearchTextChange(data)
                  }
                  onChange={(_event, data: DropdownProps) => this.onUserProfileChange(data)}
                  noResultsMessage={!searchResults?.length ? 'No matching results' : null}
                  icon={profile?.uuid ? 'delete' : 'dropdown'}
                  loading={searchLoading}
                />
              </Grid.Column>

              <Grid.Column width={6} verticalAlign='middle'>
                <div className='certificate-date-picker-container'>
                  <div className='certificate-date-container'>
                    <p>Start Date</p>
                    <DatePicker
                      id='CertificatesStartDate'
                      customInput={
                        <Input style={{ width: '120px' }} icon='calendar' value={startDate} />
                      }
                      popperPlacement='top-start'
                      selected={startDate}
                      maxDate={endDate}
                      onChange={(date: any) => this.onDateChanged(date, 'start')}
                      dateFormat='MM/DD/YY'
                    />
                  </div>
                  <div className='certificate-date-container'>
                    <p>End Date</p>
                    <DatePicker
                      id='CertificatesEndDate'
                      customInput={
                        <Input style={{ width: '120px' }} icon='calendar' value={endDate} />
                      }
                      popperPlacement='top-start'
                      selected={endDate}
                      minDate={startDate}
                      onChange={(date: any) => this.onDateChanged(date, 'end')}
                      dateFormat='MM/DD/YY'
                    />
                  </div>
                  {key === 'utilizationReport' && (
                    <div className='certificate-date-container'>
                      <Form style={{ marginRight: '15px' }}>
                        <Form.Field style={{ marginBottom: '4px' }}>Filter Courses</Form.Field>
                        <Dropdown
                          id='elearning-reports-filter-courses'
                          className='elearning-reports-filter-courses-dropdown'
                          fluid
                          selection
                          placeholder='Filter Courses'
                          options={filterCoursesOptions}
                          selectOnNavigation={false}
                          onChange={(_event, data: DropdownProps) =>
                            this.onFilterCourseChange(data)
                          }
                          value={filterCourse}
                        />
                      </Form>
                    </div>
                  )}
                  <div className='certificate-button-container'>
                    <button
                      type='button'
                      className='e-learning-user-filter-button'
                      onClick={this.handleSearch}
                    >
                      GO
                    </button>
                  </div>
                </div>
              </Grid.Column>

              <Grid.Column width={6} textAlign='right' verticalAlign='bottom'>
                <div className='certificate-exports-container'>
                  <div className='certificate-form-container'>
                    {key === 'certificatesExport' && (
                      <Form>
                        <Form.Field>Export as:</Form.Field>
                        <Form.Group>
                          <Form.Radio
                            label='ZIP'
                            name='radioGroup'
                            value='zip'
                            checked={zipSelected}
                            onChange={this.onRadioChange}
                          />
                          <Form.Radio
                            label='PDF'
                            name='radioGroup'
                            value='pdf'
                            checked={!zipSelected}
                            onChange={this.onRadioChange}
                          />
                        </Form.Group>
                      </Form>
                    )}
                  </div>
                  <div className='certificate-export-button'>
                    <Button
                      id='e-learning-reports-export-button'
                      className='care-green'
                      style={{ marginRight: '20px' }}
                      size='tiny'
                      disabled={exportLoading}
                      loading={exportLoading}
                      onClick={() => {
                        /* eslint-disable-next-line @typescript-eslint/no-unused-expressions */
                        key === 'certificatesExport'
                          ? this.handleDocumentExportClick(profile?.uuid)
                          : this.handleCsvExportClick();
                      }}
                    >
                      {buttonText}
                    </Button>
                  </div>
                </div>
                {showCsvModal && (
                  <Modal
                    closeOnDimmerClick={false}
                    open
                    size='tiny'
                    className='csv-shift-modal'
                    style={{ marginTop: '-25vh' }}
                  >
                    <Modal.Content>
                      <Modal.Header className='csv-shift-modal-header'>
                        YOUR EXPORT IS BEING PREPARED
                      </Modal.Header>
                      <Image centered className='csv-shift-modal-image' src='/csvshiftmodal.svg' />
                      <p className='csv-shift-modal-text'>
                        Your file will be compiled and will automatically download when ready.
                        <br />
                        Preparation can take a few minutes for larger date ranges.
                      </p>
                      <Button
                        id='change-log-csv-shift-modal-button'
                        className='csv-shift-modal-button'
                        size='tiny'
                        content='OK'
                        onClick={() => {
                          this.setState({ showCsvModal: false });
                        }}
                      />
                    </Modal.Content>
                  </Modal>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className='table-horizontal-scroll-container' style={{ overflowX: 'scroll' }}>
                  {child}
                  {emptyData && (
                    <div>
                      <div className='e-learning-empty-table'>
                        <h3>No Matches</h3> <Image src='/nomatch.svg' />
                        <p>No match found. Please change your filter options</p>
                      </div>
                    </div>
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
            {totalPages > 1 && (
              <Grid.Row>
                <Grid.Column textAlign='center'>
                  <Pagination
                    totalPages={totalPages}
                    onPageChange={(_e, data) => {
                      const page: any = data.activePage;
                      this.onPageChange(page - 1);
                    }}
                    activePage={pageNum + 1}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        ),
      },
    };
    return table;
  };

  public componentDidMount = () => {
    const { pageNum, pageSize, startDate, endDate } = this.state;
    this.getReportData({
      pageNum,
      pageSize,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    });
    this.searchUsers();
  };

  public onTabChange = (selectedActiveTabIndex: string | number | undefined): void => {
    const { profile, pageSize, activeTabIndex, startDate, endDate } = this.state;
    if (selectedActiveTabIndex !== activeTabIndex) {
      this.setState(
        s => ({
          ...s,
          activeTabIndex: selectedActiveTabIndex,
          loadedPages: {},
          pageNum: 0,
          filterCourse: 'all',
        }),
        () =>
          this.getReportData({
            pageNum: 0,
            pageSize,
            profileUuid: profile?.uuid,
            tab: selectedActiveTabIndex,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
          })
      );
    }
  };

  private readonly onUserProfileChange = (data: DropdownProps): void => {
    const { value } = data;
    const { profiles, activeTabIndex, startDate, endDate, searchResults } = this.state;
    const newSearchResult = value ? searchResults?.filter(r => r.value === value) : [];
    const profile = profiles.find(c => c.uuid === value);
    this.setState({
      profile,
      searchQuery: '',
      searchResults: newSearchResult,
      latestSearch: '',
      pageNum: 0,
      filterCourse: 'all',
    });
    if (profile === undefined) {
      const { pageSize } = this.state;
      this.setState(
        s => ({
          ...s,
          loadedPages: {},
        }),
        () =>
          this.getReportData({
            pageNum: 0,
            pageSize,
            tab: activeTabIndex,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
          })
      );
    }
  };

  private readonly onFilterCourseChange = (data: DropdownProps): void => {
    const { value } = data;
    this.setState(s => ({ ...s, filterCourse: value }));
  };

  private readonly onSearchTextChange = (data: DropdownOnSearchChangeData): void => {
    this.setState(s => ({ ...s, searchQuery: data.searchQuery }));
  };

  private readonly onDateChanged = (date: any, selected: string): void => {
    if (selected === 'start') {
      this.setState(s => ({ ...s, startDate: moment(date) }));
      return;
    }
    this.setState(s => ({ ...s, endDate: moment(date) }));
  };

  private readonly onRadioChange = (): void => {
    const { zipSelected } = this.state;
    this.setState(s => ({ ...s, zipSelected: !zipSelected }));
  };

  public onPageChange = (activePage: number): void => {
    const {
      pageSize,
      loadedPages,
      profile,
      activeTabIndex,
      startDate,
      endDate,
      filterCourse,
    } = this.state;
    this.setState(s => ({ ...s, pageNum: activePage }));
    if (!loadedPages[activePage]) {
      this.getReportData({
        pageNum: activePage,
        pageSize,
        profileUuid: profile?.uuid,
        tab: activeTabIndex,
        completedOnly: filterCourse === 'completedOnly',
        failedOnly: filterCourse === 'failedOnly',
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
      });
    }
    window.scrollTo(0, 0);
  };

  public handleSearch = (): void => {
    const { pageSize, startDate, endDate, activeTabIndex, profile, filterCourse } = this.state;
    let params: ReportParams = {
      profileUuid: profile?.uuid,
      tab: activeTabIndex,
      pageNum: 0,
      pageSize,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    };
    if (filterCourse === 'completedOnly') {
      params = { ...params, completedOnly: true };
    }
    if (filterCourse === 'failedOnly') {
      params = { ...params, failedOnly: true };
    }
    this.setState(
      s => ({
        ...s,
        loadedPages: {},
        pageNum: 0,
      }),
      () => this.getReportData(params)
    );
  };

  public handleCsvExportClick = (): Promise<void> => {
    const {
      pageSize,
      pageNum,
      profile,
      activeTabIndex,
      startDate,
      endDate,
      filterCourse,
    } = this.state;
    this.setState({ showCsvModal: true });
    const params: ReportParams = {
      pageNum,
      pageSize,
      profileUuid: profile?.uuid,
      csv: true,
      completedOnly: filterCourse === 'completedOnly',
      failedOnly: filterCourse === 'failedOnly',
      tab: activeTabIndex,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    };
    return this.getReportData(params);
  };

  public handleDocumentExportClick = async (profileUuid?: string): Promise<void> => {
    const { dispatch } = this.props;
    const filePrefix = moment().format('YYYYMMDDHHmmssSSS');

    this.setState(s => ({ ...s, exportLoading: true }));

    try {
      await this.exportDocuments({
        filePrefix,
        documentType: DocumentType.CERTIFICATE,
        profileUuid,
      }).then(() =>
        this.exportDocuments({ filePrefix, documentType: DocumentType.SIGNATURE, profileUuid })
      );
    } catch (error) {
      dispatch(alertActions.error(error));
    } finally {
      this.setState(s => ({ ...s, exportLoading: false }));
    }
  };

  public exportDocuments = async ({
    filePrefix,
    documentType,
    pageNumber,
    fileCounter,
    profileUuid,
  }: {
    filePrefix: string;
    documentType: string;
    pageNumber?: number;
    fileCounter?: number;
    profileUuid?: string;
  }): Promise<void> => {
    const { startDate, endDate, zipSelected } = this.state;
    const { dispatch } = this.props;

    const pageSize = 250;
    const firstPage = 0;
    const currentPage = pageNumber || firstPage;
    let currentFileCounter = fileCounter || 0;
    const mergeType = zipSelected ? 'zip' : 'pdf';
    const params = {
      pageNum: currentPage,
      pageSize,
      mergeType,
      profileUuid,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    };

    const {
      url,
      paging: { totalRows },
    } = await (documentType === DocumentType.CERTIFICATE
      ? backend.getCertificatesExport(params)
      : backend.getSignaturesExport(params));

    const totalPages = Math.ceil(totalRows / pageSize);
    if (url) {
      currentFileCounter += 1;
      const fileName = `${filePrefix}-${documentType}-${currentFileCounter}.${mergeType}`;
      const res = await fetch(url);
      const blob = await res.blob();
      FileSaver.saveAs(blob, fileName);
      dispatch(
        alertActions.message(
          `Exported file ${fileName}${totalPages > 1 ? ' - loading more...' : ''}`
        )
      );
    }

    const nextPage = currentPage + 1;
    if (nextPage < totalPages) {
      await this.exportDocuments({
        filePrefix,
        documentType,
        pageNumber: nextPage,
        fileCounter: currentFileCounter,
        profileUuid,
      });
    } else if (!currentFileCounter) {
      dispatch(alertActions.message(`No ${documentType} documents found`));
    } else if (totalPages > 1) {
      dispatch(alertActions.message(`All ${documentType} documents exported`));
    }
  };

  private readonly getReportData = (params: ReportParams): Promise<void> => {
    if (params.csv) {
      const csvParams = {
        ...params,
        csv: true,
        tzOffsetHours: moment().utcOffset() / 60,
        dateFormat: 'MM/DD/YY',
      };
      switch (params.tab) {
        case 0:
          return backend.getElearningUtilization(csvParams);
        case 1:
          return backend.getQuizAnswers(csvParams);
        default:
          return backend.getElearningUtilization(csvParams);
      }
    }
    switch (params.tab) {
      case 0:
        return this.getUtilizationReportData(params);
      case 1:
        return this.getQuizAnswersData(params);
      case 2:
        return this.getCertificatesReportData(params);
      default:
        return this.getUtilizationReportData(params);
    }
  };

  private readonly getUtilizationReportData = async (params: ReportParams): Promise<void> => {
    const { dispatch } = this.props;
    this.setState(s => ({ ...s, loading: true }));
    try {
      const { loadedPages } = this.state;
      const utilization = await backend.getElearningUtilization(params);
      loadedPages[params.pageNum] = utilization;
      loadedPages[params.pageNum].courses = sortReportTableData(
        loadedPages[params.pageNum].courses,
        'user',
        'descending'
      );
      this.setState(s => ({ ...s, loadedPages }));
    } catch (error) {
      dispatch(alertActions.error(error));
    }
    this.setState(s => ({ ...s, loading: false }));
  };

  private readonly getCertificatesReportData = async (params: ReportParams): Promise<void> => {
    const { dispatch } = this.props;
    this.setState(s => ({ ...s, loading: true }));
    const completedCoursesParams = { ...params, completedOnly: true };
    try {
      const { loadedPages } = this.state;
      const utilization = await backend.getElearningUtilization(completedCoursesParams);
      loadedPages[params.pageNum] = utilization;
      loadedPages[params.pageNum].courses = sortReportTableData(
        loadedPages[params.pageNum].courses,
        'user',
        'descending'
      );
      this.setState(s => ({ ...s, loadedPages }));
    } catch (error) {
      dispatch(alertActions.error(error));
    }
    this.setState(s => ({ ...s, loading: false }));
  };

  // awaiting for implementing active users reports
  // private getActiveUsersReportData = async (params: ReportParams): Promise<void> => {
  //   const { dispatch } = this.props;
  //   this.setState(s => ({ ...s, loading: true }));
  //   try {
  //     const { loadedPages } = this.state;
  //     const activeUsers = await backend.getElearningActiveUsers(params);
  //     loadedPages[params.pageNum] = activeUsers;
  //     this.setState(s => ({ ...s, loadedPages }));
  //   } catch (error) {
  //     dispatch(alertActions.error(error));
  //   }
  //   this.setState(s => ({ ...s, loading: false }));
  // };

  private readonly getQuizAnswersData = async (params: ReportParams): Promise<void> => {
    const { dispatch } = this.props;
    this.setState(s => ({ ...s, loading: true }));
    try {
      const { loadedPages } = this.state;
      const quizAnswers = await backend.getQuizAnswers(params);
      loadedPages[params.pageNum] = quizAnswers;
      loadedPages[params.pageNum].questions = sortReportTableData(
        loadedPages[params.pageNum].questions,
        'user',
        'descending'
      );
      this.setState(s => ({ ...s, loadedPages }));
    } catch (error) {
      dispatch(alertActions.error(error));
    }
    this.setState(s => ({ ...s, loading: false }));
  };

  private readonly searchUsers = async () => {
    const { searchQuery, latestSearch } = this.state;
    if (searchQuery.length < 2 || searchQuery === latestSearch) {
      return setTimeout(this.searchUsers, SleepTime);
    }
    const { dispatch } = this.props;
    if (searchQuery) {
      this.setState(s => ({ ...s, searchLoading: true }));
      try {
        const { profiles = [] } = await backend.getElearningProfiles({
          pageNum: 1,
          pageSize: 10,
          name: searchQuery.trimStart(),
        });
        const searchResults = profiles.map((x: Profile) => ({
          text: `${x.firstName} ${x.lastName} - email: ${x.email || '-'}`,
          value: x.uuid,
        }));
        this.setState(s => ({
          ...s,
          searchResults,
          profiles,
          searchLoading: false,
          latestSearch: searchQuery,
        }));
      } catch (err) {
        dispatch(alertActions.error(err));
        this.setState(s => ({ ...s, searchLoading: false }));
      }
    }
    return setTimeout(this.searchUsers, SleepTime);
  };

  public sortClick = (column: string, sorting: Sorted, quiz = false): void => {
    const { pageNum, loadedPages } = this.state;
    const sortType = quiz ? 'questions' : 'courses';
    const sortData = sortReportTableData(loadedPages[pageNum][sortType], column, sorting);
    const newSortedPage = Object.assign(loadedPages, {
      [pageNum]: { [sortType]: sortData, paging: loadedPages[pageNum].paging },
    });
    this.setState(s => ({ ...s, loadedPages: newSortedPage }));
  };

  public render(): JSX.Element {
    const { activeTabIndex, loading, pageNum, loadedPages } = this.state;
    const loadedData = loadedPages[pageNum];
    const totalPages: number = loadedData?.paging
      ? Math.ceil(loadedData.paging.totalRows / PAGE_SIZE)
      : 0;

    const panes = [
      this.ELReportsPanes(
        <UtilizationReportTable data={loadedData?.courses} sortClick={this.sortClick} />,
        !loadedData?.courses?.length,
        'Utilization',
        'utilizationReport',
        totalPages
      ),
      this.ELReportsPanes(
        <QuizAnswersReportTable data={loadedData?.questions} sortClick={this.sortClick} />,
        !loadedData?.questions?.length,
        'Quiz Answers',
        'quizAnswers',
        totalPages
      ),
      this.ELReportsPanes(
        <CertificatesExportReportTable data={loadedData?.courses} sortClick={this.sortClick} />,
        !loadedData?.courses?.length,
        'Certificates Export',
        'certificatesExport',
        totalPages
      ),
    ];

    return (
      <div className='e-learning-report-container'>
        {loading && (
          <Dimmer active inverted>
            <CareLoader loading={loading} showText={false} />
          </Dimmer>
        )}
        <h1 className='e-learning-report-header'>ELEARNING REPORTS</h1>
        <Tab
          className='your-courses-tab'
          panes={panes}
          renderActiveOnly={false}
          activeIndex={activeTabIndex}
          onTabChange={(_e, data) => this.onTabChange(data.activeIndex)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: { eLCourses: any }) => {
  const { eLCourses } = state;
  return {
    eLCourses,
  };
};

export default connect(mapStateToProps)(ELReports);

/* eslint-disable react/prop-types */
import { Image } from 'semantic-ui-react';
import React from 'react';
import { injectIntl } from 'react-intl';
import './CareLoader.css';

// !error  'loading' is missing in props validation and all the others , Props validation not working properly on functional components
const CareLoader = ({ loading, text, showText = true, centered = false, invert = false, intl }) => {
  if (loading) {
    let textClass = 'careLoaderText';
    if (invert) textClass += ' careLoaderInverted';
    const parentStyle = centered
      ? { height: '100%', width: '100%', background: 'transparent', display: 'table' }
      : {};
    const style = centered ? { verticalAlign: 'middle', display: 'table-cell' } : {};
    const gif = invert ? '/loader_anim-onblack.gif' : '/loader_anim.gif';
    return (
      <div style={parentStyle}>
        <div style={style}>
          <Image src={gif} className='loaderImage' centered />
          {showText && (
            <div className={textClass}>
              {text || intl.formatMessage({ id: 'careLoader.loading.text' })}
            </div>
          )}
        </div>
      </div>
    );
  }

  return null;
};

export default injectIntl(CareLoader);

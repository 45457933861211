import {
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE,
} from '../constants/ActionTypes';
import { prepareShiftObject } from './helpers';

const initialState = {
  loading: false,
};

function dashboard(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_REQUEST:
      return { ...state, loading: true };
    case GET_DASHBOARD_SUCCESS: {
      const { results } = action;
      results.unassignedShifts = results.unassignedShifts.map(s => prepareShiftObject(s));
      results.onholdShifts = results.onholdShifts.map(s => prepareShiftObject(s));
      return { ...state, loading: false, ...results };
    }
    case GET_DASHBOARD_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export default dashboard;

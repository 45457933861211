import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { history } from '../helpers';
import ElUsersTable from './subcomponents/eLAPP/ELUsersTable';
import ELearningUsersFilterForm from './subcomponents/eLAPP/ELearningUsersFilterForm';
import { ELManageActions } from '../actions/ELApp/ELManageActions';
import './ELManage.css';

class ELManage extends React.Component {
  // eslint-disable-next-line react/sort-comp
  constructor(props) {
    super(props);
    this.state = {
      listFetched: false,
      searchValue: '',
      filterValue: 0,
      traineesList: [],
      searching: false,
      loadingMore: false,
    };
  }

  // eslint-disable-next-line react/sort-comp
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.eLManage?.traineesList?.length && !prevState.listFetched) {
      nextProps.dispatch(ELManageActions.getElearningProfilesList(null));
      return { listFetched: true };
    }
    if (
      (nextProps.eLManage?.traineesList?.length && !prevState.traineesList?.length) ||
      prevState.searching ||
      (nextProps.eLManage?.traineesList?.length !== prevState.traineesList?.length &&
        prevState.loadingMore)
    ) {
      return {
        traineesList: nextProps.eLManage.traineesList,
        searching: false,
        loadingMore: false,
      };
    }
    return null;
  }

  onValueChange = v => {
    this.setState({ searchValue: v.target.value });
  };

  onNameClick = uuid => {
    const { eLManage, location } = this.props;
    const user = eLManage.traineesList.filter(u => u.uuid === uuid)[0];
    if (user.profileType === 'caregiver') {
      history.push(`/home/e-learning/caregivers/records/${user.id}/e-learning`, {
        prevPath: location,
      });
    } else {
      history.push('/home/profile', { employeeId: `${user.id}` });
    }
  };

  onLoadMore = () => {
    const { eLManage, dispatch } = this.props;
    const params = {
      pageNum: eLManage.paging.pageNum += 1,
    };
    dispatch(ELManageActions.loadMoreElearningProfilesList(params));
    this.setState({ loadingMore: true });
  };

  onFilterProgramChange = id => {
    this.setState({ filterValue: id });
  };

  submit = (e, v) => {
    const { dispatch } = this.props;
    e.preventDefault();
    const params = {
      name: v,
    };
    dispatch(ELManageActions.getElearningProfilesList(params));
    return this.setState({ searching: true });
  };

  render() {
    const { eLManage } = this.props;
    const { searchValue, traineesList, filterValue } = this.state;
    const { loading, agencyPrograms } = eLManage;

    const programsList = agencyPrograms?.length
      ? agencyPrograms.map(p => ({ text: p.programName, value: p.id }))
      : [];
    programsList.splice(0, 0, { text: 'All', value: 0 });

    const loadMore = eLManage?.paging?.pageNum * 500 <= eLManage?.paging?.totalRows;

    const tableData = filterValue
      ? traineesList.filter(t => t.programs.find(p => p.id === filterValue))
      : traineesList;

    return (
      <div style={{ width: '100%' }}>
        <div className='e-learning-manage-head'>
          <h1 className='e-learning-manage-header'>MANAGE LEARNERS</h1>
          <ELearningUsersFilterForm
            onValueChange={this.onValueChange}
            onSubmit={this.submit}
            value={searchValue}
            placeHolder='User Name'
          />
        </div>
        <div className='e-learning-manage-users-table-container'>
          <ElUsersTable
            data={tableData}
            onSubmit={this.onSubmit}
            loading={loading}
            onNameClick={this.onNameClick}
            onLoadMore={this.onLoadMore}
            loadMore={loadMore}
            programs={programsList}
            programValue={filterValue}
            onFilterProgramChange={this.onFilterProgramChange}
          />
        </div>
      </div>
    );
  }
}

ELManage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  eLManage: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

const mapStateToProps = state => {
  const { eLManage } = state;
  return {
    eLManage,
  };
};

export default connect(mapStateToProps)(ELManage);

/* eslint-disable  @typescript-eslint/no-explicit-any */
const moment = require('moment-timezone');
const isoLang = require('iso-639-3');

const DEFAULT_CLIENT_STATUS = 'active';
const DEFAULT_COUNTRY_CODE = 'US';
const SHIFT_INPUT_TS_FORMAT = 'MM/DD/YYYY HHmm';
const DEFAULT_SHIFT_SERVICE = 'HHA';
const DEFAULT_SHIFT_STATUS = 'pending';
const DEFAULT_SHIFT_TYPE = 'scheduled';

const getGender = (g: string): string | null => {
  switch (g) {
    case 'Male':
      return 'm';
    case 'Female':
      return 'f';
    case 'Other':
      return 'u';
    default:
      return null;
  }
};

export const FileConverterType = {
  HHAX: 'hhax',
};

export const convertHHAXClient = ({
  agencyExternalId,
  lastModified,
  data,
}: {
  agencyExternalId: string;
  lastModified: string;
  data: any;
}): any => {
  const externalId = data['Admission ID'];
  const firstName = data['First Name'];
  const lastName = data['Last Name'];
  const gender = getGender(data.Gender);
  const priorityCode = data['Provider Priority Code'];
  const defaultService = data.Discipline;
  const startDate = data['First Day of Service'];
  const street = data.Address1;
  const apartment = data.Address2;
  const city = data.City;
  const state = data.State;
  const zip = data['Zip Code'];
  const primaryLanguage = isoLang.find((x: any) => x.name === data['Primary Language'])?.iso6392T;
  const languages = isoLang.find((x: any) => x.name === data['Secondary Language'])?.iso6392T;

  return {
    externalId,
    agencyExternalId,
    lastModified,
    status: DEFAULT_CLIENT_STATUS,
    firstName,
    lastName,
    gender,
    priorityCode,
    defaultService,
    startDate,
    street,
    apartment,
    city,
    state,
    zip,
    countryCode: DEFAULT_COUNTRY_CODE,
    primaryLanguage,
    languages,
  };
};

export const convertHHAXShift = ({
  agencyExternalId,
  timezone,
  lastModified,
  data,
}: {
  agencyExternalId: string;
  timezone: string;
  lastModified: string;
  data: any;
}): any => {
  const clientExternalId = data['Admission ID'];
  const externalId = `${clientExternalId}-${moment.tz(data.Date, timezone).format('YYYYMMDD')}`;
  const payerName = data.Contract;
  const [startIn, endIn] = data.Schedule?.split('-') || [null, null];
  const start = moment
    .tz(moment(`${data.Date} ${startIn}`, SHIFT_INPUT_TS_FORMAT), timezone)
    .toISOString();
  const end = moment
    .tz(moment(`${data.Date} ${endIn}`, SHIFT_INPUT_TS_FORMAT), timezone)
    .toISOString();
  return {
    externalId,
    agencyExternalId,
    lastModified,
    clientExternalId,
    payerName,
    start,
    end,
    service: DEFAULT_SHIFT_SERVICE,
    status: DEFAULT_SHIFT_STATUS,
    type: DEFAULT_SHIFT_TYPE,
    timezone,
  };
};

export const applyConverter = ({
  agencyExternalId,
  timezone,
  lastModified,
  fileType,
  fileConverter,
  data,
}: {
  agencyExternalId: string;
  timezone: string;
  lastModified: string;
  fileType: string;
  fileConverter: string;
  data: any;
}): any => {
  if (fileType === 'shift-csv' && fileConverter === FileConverterType.HHAX) {
    return convertHHAXShift({ agencyExternalId, timezone, lastModified, data });
  }

  if (fileType === 'client-csv' && fileConverter === FileConverterType.HHAX) {
    return convertHHAXClient({ agencyExternalId, lastModified, data });
  }

  return data;
};

/* eslint-disable import/prefer-default-export */
import * as types from '../../constants/ActionTypes';
import { backend } from '../../services';
import { alertActions } from '../alert';

function getApplicationToolConfig() {
  return async dispatch => {
    dispatch(request());

    try {
      const { agencyUuid } = await backend.getCachedAgencySettings();
      const applicationToolConfig = await backend.getApplicationToolConfig(agencyUuid);
      dispatch(success(applicationToolConfig));
    } catch (error) {
      dispatch(failure(error));
    }
  };

  function request() {
    return { type: types.GET_APP_TOOL_CONFIG_REQUEST };
  }
  function success(applicationToolConfig) {
    return { type: types.GET_APP_TOOL_CONFIG_SUCCESS, applicationToolConfig };
  }
  function failure(error) {
    return { type: types.GET_APP_TOOL_CONFIG_FAILURE, error };
  }
}

function updateApplicationToolConfig(config) {
  return async dispatch => {
    dispatch(request());

    try {
      const { agencyUuid } = await backend.getCachedAgencySettings();
      const applicationToolConfig = await backend.updateApplicationToolConfig(agencyUuid, config);
      dispatch(success(applicationToolConfig));
      dispatch(alertActions.notification('Application tool configuration saved'));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: types.UPDATE_APP_TOOL_CONFIG_REQUEST };
  }
  function success(applicationToolConfig) {
    return { type: types.UPDATE_APP_TOOL_CONFIG_SUCCESS, applicationToolConfig };
  }
  function failure(error) {
    return { type: types.UPDATE_APP_TOOL_CONFIG_FAILURE, error };
  }
}

export const applicationToolActions = {
  getApplicationToolConfig,
  updateApplicationToolConfig,
};

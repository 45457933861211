/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Header, Grid, Form, Image, Loader } from 'semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import InputRange from 'react-input-range';
import LocationMap from './LocationMap';
import { clientActions, uiStateActions } from '../../actions';

class CGTravelTransit extends React.Component {
  constructor() {
    super();
    this.state = {
      clientsInArea: [],
      travelRadius: 0,
      zoom: 11,
      loading: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(clientActions.listClients({ status: '02' }));
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(next) {
    if (next.clients && !next.clients.loading && next.clients.results) {
      this.getTravelDistance(next.person.casePref.transportType);
    }
    this.getZoom(next.person.casePref.transportType);
  }

  getTravelDistance = pref => {
    this.setState({ loading: true });

    const { person } = this.props;

    const travelTime = person.casePref ? person.casePref.travelUpToMinutes : 60;

    const clientsList = this.props.clients.results
      ? this.props.clients.results.map(c => {
          const distance = this.calculateDistance(c.address, person.address);
          return distance;
        })
      : [];

    let travelSpeed;

    if (pref === 'walking') {
      travelSpeed = 5;
    } else if (pref === 'public') {
      travelSpeed = 30;
    } else if (pref === 'driving') {
      travelSpeed = 40;
    } else {
      travelSpeed = 5;
    }

    const travelRadius = (travelTime / 60) * travelSpeed * 1000;

    const clientsInArea = clientsList ? clientsList.filter(d => d <= travelRadius) : [];

    this.setState({ travelRadius, clientsInArea, loading: false });
  };

  getZoom = pref => {
    let zoom;

    if (pref === 'walking') {
      zoom = 11;
    } else if (pref === 'public') {
      zoom = 9;
    } else if (pref === 'driving') {
      zoom = 8;
    } else {
      zoom = 11;
    }
    this.setState({ zoom });
  };

  calculateDistance = (pointA, pointB) => {
    // http://www.movable-type.co.uk/scripts/latlong.html
    const lat1 = pointA.lat;
    const lon1 = pointA.lon;

    const lat2 = pointB.lat;
    const lon2 = pointB.lon;

    const R = 6371e3; // earth radius in meters
    const φ1 = lat1 * (Math.PI / 180);
    const φ2 = lat2 * (Math.PI / 180);
    const Δφ = (lat2 - lat1) * (Math.PI / 180);
    const Δλ = (lon2 - lon1) * (Math.PI / 180);

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * (Math.sin(Δλ / 2) * Math.sin(Δλ / 2));

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;
    return distance; // in meters
  };

  // eslint-disable-next-line react/sort-comp
  travelTime(travelTime) {
    const { intl } = this.props;
    const hours = Math.floor(travelTime / 60);
    const minutes = travelTime % 60;

    return (
      <span>
        {hours > 0 &&
          `${intl.formatMessage({ id: 'caregivers.records.hours' }, { count: hours })} `}
        {minutes > 0 &&
          intl.formatMessage({ id: 'caregivers.records.minutes' }, { count: minutes })}
      </span>
    );
  }

  travelPref = pref => {
    const { person } = this.props;
    const color = person.casePref && person.casePref.transportType === pref ? 'blue' : 'grey';

    return `/icon_${pref}${color}.svg`;
  };

  render() {
    const { person, intl, onValueChange } = this.props;
    const { transportType } = this.props.person.casePref;

    const travelTime = person.casePref ? person.casePref.travelUpToMinutes : 60;

    const { travelRadius, zoom, loading } = this.state;

    return (
      <>
        <Grid>
          <Grid.Row columns={3} centered verticalAlign='middle'>
            <Grid.Column width={5}>
              <Grid.Row style={{ marginTop: '1.8rem' }}>
                <Form>
                  <Form.Group grouped>
                    <Form.Field>
                      <InputRange
                        id='travelTime'
                        name='casePref.travelUpToMinutes'
                        type='range'
                        minValue={30}
                        maxValue={240}
                        step={30}
                        value={parseInt(travelTime, 10)}
                        formatLabel={() => ''}
                        onChange={value => {
                          const input = {
                            name: 'casePref.travelUpToMinutes',
                            value: parseInt(value, 10),
                          };
                          return onValueChange({ value: parseInt(value, 10) }, input);
                        }}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Grid.Row>
              <Grid.Row style={{ marginLeft: '7rem' }}>
                <span>{this.travelTime(travelTime)}</span>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={5} style={{ marginLeft: '1rem', marginRight: '1rem' }}>
              <Grid.Row>
                <Header style={{ marginBottom: '1em' }} size='tiny'>
                  {intl.formatMessage({ id: 'caregivers.records.transitPref' })}:
                </Header>
              </Grid.Row>
              <Grid.Row>
                <Form>
                  <Form.Group>
                    <Image
                      inline
                      size='large'
                      className='travelImage'
                      name='walking'
                      src={this.travelPref('walking')}
                      style={{ marginLeft: '1em' }}
                      onClick={e =>
                        onValueChange(e, { name: 'casePref.transportType', value: 'walking' })
                      }
                    />
                    <p
                      style={{
                        display: 'inline-block',
                        marginTop: '5px',
                        color: transportType === 'walking' ? '#364866' : '#9b9b9b',
                        fontWeight: transportType === 'walking' ? 'bold' : 'normal',
                        fontSize: '12px',
                      }}
                    >
                      Walking
                    </p>
                    <Image
                      inline
                      size='large'
                      className='travelImage'
                      name='public'
                      src={this.travelPref('public')}
                      style={{ marginLeft: '1em' }}
                      onClick={e =>
                        onValueChange(e, { name: 'casePref.transportType', value: 'public' })
                      }
                    />
                    <p
                      style={{
                        display: 'inline-block',
                        marginTop: '5px',
                        color: transportType === 'public' ? '#364866' : '#9b9b9b',
                        fontWeight: transportType === 'public' ? 'bold' : 'normal',
                        fontSize: '12px',
                      }}
                    >
                      Public
                    </p>
                    <Image
                      inline
                      size='large'
                      className='travelImage'
                      name='driving'
                      src={this.travelPref('driving')}
                      style={{ marginLeft: '1em' }}
                      onClick={e =>
                        onValueChange(e, { name: 'casePref.transportType', value: 'driving' })
                      }
                    />
                    <p
                      style={{
                        display: 'inline-block',
                        marginTop: '5px',
                        marginLeft: '5px',
                        color: transportType === 'driving' ? '#364866' : '#9b9b9b',
                        fontWeight: transportType === 'driving' ? 'bold' : 'normal',
                        fontSize: '12px',
                      }}
                    >
                      Driving
                    </p>
                  </Form.Group>
                </Form>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={5}>
              <span style={{ fontWeight: 'bold' }}>
                {loading ? (
                  <Loader active inline size='mini' style={{ top: '-3px' }} />
                ) : (
                  this.state.clientsInArea.length
                )}
                {intl.formatMessage({ id: 'caregivers.records.activeClients' })}
                <span style={{ fontWeight: 'normal' }}>
                  {intl.formatMessage({ id: 'caregivers.records.catchmentZone' })}
                </span>
              </span>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <LocationMap
          editable
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '350px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          showMarker
          lat={person.address.lat}
          lon={person.address.lon}
          markerIcon='/caregiver_marker_blue.svg'
          defaultZoom={zoom}
          circleRadius={travelRadius}
          zoom={zoom}
          mapRefCallback={(mapRef, googleMap) => {
            this.googleMapRef = googleMap;
          }}
        />
      </>
    );
  }
}

CGTravelTransit.defaultProps = {
  clients: [],
};

CGTravelTransit.propTypes = {
  person: PropTypes.shape().isRequired,
  intl: intlShape.isRequired,
  onValueChange: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  clients: PropTypes.shape(),
};

const mapStateToProps = state => {
  const { clients, principal } = state;
  return {
    clients,
    principal,
  };
};

export default connect(uiStateActions.mapStateOfGroupToProps('clientsFilter', mapStateToProps))(
  injectIntl(CGTravelTransit)
);

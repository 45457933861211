/* eslint-disable import/prefer-default-export */
import React from 'react';

import * as types from '../constants/ActionTypes';
// eslint-disable-next-line import/no-cycle
import { caregiverActions } from './caregiver';
// eslint-disable-next-line import/no-cycle
import { userActions } from './user';
import { avatarUrl } from '../constants/DomainTypes';
import { formatTime, MOMENT_HOURS_WITH_DATE } from '../constants/Formats';

const uuid = require('uuid/v4');

const SEARCH_ERROR_TYPE = 'SEARCH_ERROR_TYPE';
const REASON_NETWORK_CONNECTIVITY = 'NETWORK_CONNECTIVITY';

const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD';
const UPDATE_CAREGIVER_ERROR = 'UPDATE_CAREGIVER';
const CREATE_CLIENT_ERROR = 'CREATE_CLIENT';
const SIGNUP_ERROR = 'SIGNUP_ERROR';
const GEOCODE_ERROR = 'GEOCODE_ERROR';
const ANNOUNCEMENT_ERROR = 'ANNOUNCEMENT_ERROR';

const SHIFT_ACTION_TEXT = {
  applied: 'requested shift',
  offered: 'canceled shift request',
  declined: 'declined shift',
};

function errorByType(errorType, reason, errorJson) {
  const match = (et, r, ae) => {
    if (errorType === et) {
      if (r) {
        if (ae) {
          return errorJson && ae === errorJson.type;
        }
        return r === reason;
      }
      return true;
    }
    return false;
  };

  const invalidInstance = () => {
    const fields = errorJson.details && errorJson.details.fields;
    switch (true) {
      case !fields:
        return errorJson.error;
      case fields.startsWith('instance.address'):
        return 'Please enter a valid address.';
      case fields.startsWith('instance.phones'):
        return 'Please enter a valid phone number.';
      case fields.startsWith('instance.email'):
        return 'Please enter a valid email address.';
      case fields.startsWith('instance.dob'):
        return 'Please enter a valid birthday.';
      default:
        return errorJson.error;
    }
  };

  switch (true) {
    case match(SEARCH_ERROR_TYPE, REASON_NETWORK_CONNECTIVITY):
      return 'Please check your network connectivity and try your search again.';
    case match(SEARCH_ERROR_TYPE):
      return 'Please try your search again.';
    case match(UPDATE_PASSWORD_ERROR, 400, 4000):
      return (
        'Your password must be at least 8 characters long and include at least one uppercase letter, ' +
        'a lowercase letter, a number, and a special character'
      );
    case match(SIGNUP_ERROR, 400, 4002):
      return 'Email address already taken';
    case match(SIGNUP_ERROR, 404):
      return 'Signup link is expired or invalid';
    case match(SIGNUP_ERROR, 400, 4000):
      return (
        'Email must be valid and your password must be at least 8 characters long and include at least one uppercase letter, ' +
        'a lowercase letter, a number, and a special character'
      );
    case match(UPDATE_PASSWORD_ERROR, 400):
      return 'Cannot update password, your link is expired or invalid';
    case match(UPDATE_PASSWORD_ERROR, 404):
      return 'Cannot update password, your link is expired or invalid';
    case match(UPDATE_CAREGIVER_ERROR, 400, 4000):
      return invalidInstance(errorJson);
    case match(UPDATE_CAREGIVER_ERROR, 400) && errorJson && !!errorJson.error:
      return errorJson.error;
    case reason === REASON_NETWORK_CONNECTIVITY:
      return 'Please check your network connectivity and try again.';
    case match(types.UPLOAD_CSV_FAILURE, 400):
      return 'Could not upload data';
    default:
      return 'Sorry, we faced an unexpected error. Please retry in a little while.';
  }
}

function errorNotification(errorObj) {
  return dispatch => {
    const errorMessage = errorObj.json && errorObj.json.error ? errorObj.json.error : errorObj;
    const id = uuid();
    dispatch({ type: types.ALERT_NOTIFICATION, id, message: errorMessage });
  };
}

function notification(msg) {
  return dispatch => {
    const id = uuid();
    dispatch({ type: types.ALERT_NOTIFICATION, id, message: msg });
    setTimeout(() => dispatch(clear(id)), 3000);
  };
}

function message(msg, group, httpStatus, opt = {}) {
  return { type: types.ALERT_MESSAGE, group, id: uuid(), message: msg, httpStatus, opt };
}

function errorObject(msg, group, httpStatus, opt = {}) {
  return { type: types.ALERT_ERROR, group, id: uuid(), message: msg, httpStatus, opt };
}

function error(errorObj, errorType, priority) {
  if (typeof errorObj === 'string' || errorObj instanceof String) {
    return { type: types.ALERT_ERROR, id: uuid(), group: 'error', message: errorObj, priority };
  }

  const httpStatus = errorObj.response && errorObj.response.status;
  if (httpStatus === 401) {
    const opt = {
      label: 'Login',
      action: userActions.unauthorized(),
      url: '/login',
      loggedOut: true,
    };
    return errorObject('You have been logged out, please login again.', 'error', httpStatus, opt);
  }

  if (httpStatus === 500) {
    return errorObject(errorByType(errorType), 'error', httpStatus);
  }

  if (httpStatus === 400) {
    if (errorType) {
      return errorObject(errorByType(errorType, httpStatus, errorObj.json), 'error', httpStatus);
    }

    if (errorObj.json && errorObj.json.error) {
      const opt = errorObj.json.details;
      return errorObject(errorObj.json.error, 'error', httpStatus, opt);
    }
  }

  if (httpStatus === 404) {
    return errorObject(errorByType(errorType, httpStatus), 'error', httpStatus);
  }

  if (!httpStatus) {
    return errorObject(errorByType(errorType, REASON_NETWORK_CONNECTIVITY), 'error', httpStatus);
  }

  return {
    type: types.ALERT_ERROR,
    group: 'error',
    id: uuid(),
    message: errorObj.message,
    priority,
    httpStatus,
    opt: {},
  };
}

function clear(alert) {
  return { type: types.ALERT_CLEAR, alert };
}

function clearErrors() {
  return { type: types.ALERT_CLEAR_ERRORS };
}

function shiftStatusUpdated(shift, caregiver, reason) {
  return dispatch => {
    if (shift.shiftAssigmentStatus !== 'assigned') {
      const id = `s${shift.id}cg${caregiver.id}`;
      const formattedTime = formatTime(shift.start, shift.timezone, MOMENT_HOURS_WITH_DATE);
      const actionText = SHIFT_ACTION_TEXT[reason];
      const msg = (
        <div>
          <b>{caregiver.caregiverName}</b> {actionText}
          <br />
          {shift.client.clientName} {formattedTime}
        </div>
      );
      const opt = {
        label: 'View',
        cancel: true,
        avatar: avatarUrl(caregiver.avatar, caregiver.gender),
        action: caregiverActions.setTargetShifts([shift.id]),
        url: '/home/caregivers',
      };
      if (actionText) {
        dispatch(clear({ id }));
        dispatch({
          type: types.ALERT_MESSAGE,
          id,
          message: msg,
          opt,
        });
      }
    }
  };
}

export const alertActions = {
  notification,
  message,
  error,
  clear,
  clearErrors,
  shiftStatusUpdated,
  SEARCH_ERROR_TYPE,
  UPDATE_PASSWORD_ERROR,
  UPDATE_CAREGIVER_ERROR,
  CREATE_CLIENT_ERROR,
  SIGNUP_ERROR,
  GEOCODE_ERROR,
  ANNOUNCEMENT_ERROR,
  errorNotification,
};

import React, { useState } from 'react';
import { ICaregiver } from '../../../../reducers/types/caregiver';
import { SimpleBlueButton } from '../../../../styles/common';
import { Blocks } from '../../compliance/ComplianceMedicalTypes';
import {
  CompletionModal,
  ModalHeader,
  ModalBody,
  HeaderTitles,
  BodyContent,
  NameWithIcon,
  FooterContent,
  FootNote,
} from './styles';

interface IProps {
  caregiver: ICaregiver;
  blocks: Blocks[];
  onCloseClicked: () => void;
}

const ComplianceCompletionDetails: React.FC<IProps> = ({
  onCloseClicked,
  caregiver,
  blocks = [],
}) => {
  const [showMore, setShowMore] = useState(false);
  const [sliceShow, setSliceShow] = useState<number | undefined>(4);
  const activeBlocks = blocks?.filter(b => b.active);
  const { aboutToExpireBlocks = [], expiredBlocks = [], nonCompliantBlocks = [] } = caregiver;
  const completedBlocks = activeBlocks
    .filter(b => {
      const notCompleteOrPending = [...aboutToExpireBlocks, ...nonCompliantBlocks];
      const caregiverDisciplines = caregiver.discipline?.split(',') || [];
      const includedIndiscipline = b.disciplines?.length
        ? b.disciplines.find(bd => caregiverDisciplines.includes(bd))
        : true;
      return (
        b.name !== 'DOH' &&
        !notCompleteOrPending.includes(b.id) &&
        !b.schedulable &&
        b.required &&
        includedIndiscipline
      );
    })
    .map(b => b.id);

  const toggleShowMore = () => {
    const newShowMore = !showMore;
    setShowMore(newShowMore);
    setSliceShow(newShowMore ? undefined : 4);
  };

  const moreToShow =
    completedBlocks.length > 4 ||
    expiredBlocks.length > 4 ||
    aboutToExpireBlocks.length > 4 ||
    nonCompliantBlocks.length > 4;

  return (
    <CompletionModal onClose={onCloseClicked}>
      <ModalHeader>
        <h2>{`${caregiver.firstName} ${caregiver.lastName}`}</h2>
        <p>ID: {caregiver.id}</p>
        <HeaderTitles>
          <h3>Completion Status</h3>
          <h3>Items</h3>
        </HeaderTitles>
      </ModalHeader>
      <ModalBody>
        {!!expiredBlocks.length && (
          <BodyContent dotColor='#f16778'>
            <div className='compliance-type-content'>
              <div className='compliance-dot' />
              <h4>Expired Items: ({expiredBlocks.length})</h4>
              <p className='note-text'>Note: These items have been expired.</p>
            </div>
            <div>
              {expiredBlocks.slice(0, sliceShow).map(b => {
                const block = blocks?.find(bk => bk.id === b.id);
                const includedInComplete = !!completedBlocks.find(cb => cb === block?.id);
                return (
                  <NameWithIcon key={b.id}>
                    <p className='compliance-name'>{block?.name}</p>
                    {block?.required && !block.schedulable && !includedInComplete ? (
                      <img src='/icon_check_no.svg' alt='' />
                    ) : (
                      <p>*</p>
                    )}
                  </NameWithIcon>
                );
              })}
            </div>
          </BodyContent>
        )}
        {!!aboutToExpireBlocks.length && (
          <BodyContent dotColor='#f5a623'>
            <div className='compliance-type-content'>
              <div className='compliance-dot' />
              <h4>Pending Expirations: ({aboutToExpireBlocks.length})</h4>
              <p className='note-text'>
                Note: These items will expire within <b>30 days.</b>
              </p>
            </div>
            <div>
              {aboutToExpireBlocks.slice(0, sliceShow).map(b => {
                const block = blocks?.find(bk => bk.id === b.id);
                return (
                  <NameWithIcon key={b.id}>
                    <p className='compliance-name'>{block?.name}</p>
                    {block?.required ? <img src='/Attention-orange.svg' alt='' /> : <p>*</p>}
                  </NameWithIcon>
                );
              })}
            </div>
          </BodyContent>
        )}
        {!!nonCompliantBlocks.length && (
          <BodyContent dotColor='#e3e3e3'>
            <div className='compliance-type-content'>
              <div className='compliance-dot' />
              <h4>Incomplete Items: ({nonCompliantBlocks.length})</h4>
              <p className='note-text'>Note: These items have not been completed yet.</p>
            </div>
            <div>
              {nonCompliantBlocks
                .filter(nc => !expiredBlocks.find(eb => eb.id === nc))
                .slice(0, sliceShow)
                .map(b => {
                  const block = activeBlocks?.find(bk => bk.id === b);
                  return (
                    <NameWithIcon key={b}>
                      <p className='compliance-name'>{block?.name}</p>
                      {block?.required && !block.schedulable ? '' : <p>*</p>}
                    </NameWithIcon>
                  );
                })}
            </div>
          </BodyContent>
        )}
        {!!completedBlocks.length && (
          <BodyContent dotColor='#69db8f'>
            <div className='compliance-type-content'>
              <div className='compliance-dot' />
              <h4>Completed Items: ({completedBlocks.length})</h4>
              <p className='note-text'>Note: These items have been completed.</p>
            </div>
            <div>
              {completedBlocks.slice(0, sliceShow).map(b => {
                const block = activeBlocks?.find(bk => bk.id === b);
                return (
                  <NameWithIcon key={b}>
                    <p className='compliance-name'>{block?.name}</p>
                    {block?.required && !block.schedulable ? '' : <p>*</p>}
                  </NameWithIcon>
                );
              })}
            </div>
          </BodyContent>
        )}
        <FootNote> Items marked with (*) does not affect on caregiver compliance</FootNote>
      </ModalBody>
      <FooterContent>
        {moreToShow && (
          <SimpleBlueButton onClick={toggleShowMore}>
            {showMore ? 'Show Less' : 'Show More'}
          </SimpleBlueButton>
        )}
      </FooterContent>
    </CompletionModal>
  );
};

export default ComplianceCompletionDetails;

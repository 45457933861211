import React, { useEffect, useState, memo } from 'react';
import { Table, Grid, Visibility, VisibilityEventData, Dimmer } from 'semantic-ui-react';
import { ICaregiver } from '../../../../reducers/types/caregiver';
import CareLoader from '../../CareLoader';
import { Blocks } from '../../compliance/ComplianceMedicalTypes';
import NoMatch from '../../NoMatch';
import ComplianceCompletionDetails from '../ComplianceCompletionDetails/ComplianceCompletionDetails';
import HRCaregiverHelper from '../HRCaregiverHelper';
import BodyRows from './BodyRows';
import HeadersRow from './HeadersRow';
import { MainTable, TableHeader } from './styles';

interface IProps {
  data: ICaregiver[];
  isCompleted: boolean;
  includeVaccine: boolean;
  loading: boolean;
  tableType: 'candidates' | 'caregivers';
  blocks: Blocks[];
  selectedCandidates?: number[];
  onCheckClick?: (id?: number) => void;
}

const HRCaregiversCandidatesTable: React.FC<IProps> = ({
  isCompleted,
  includeVaccine,
  loading,
  tableType,
  blocks = [],
  data = [],
  selectedCandidates = [],
  onCheckClick = () => null,
}) => {
  const [sortedData, setSortedData] = useState<ICaregiver[]>([]);
  const [dataChunk, setDataChunk] = useState<ICaregiver[][]>([[]]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [visibleChunk, setVisibleChunk] = useState<ICaregiver[]>([]);
  const [sortName, setSortName] = useState('name');
  const [sortDirection, setSortDirection] = useState('descending');
  const [viewCompliancesCaregiver, setViewComplianceCaregiver] = useState<ICaregiver | null>(null);

  useEffect(() => {
    if (data && data.length) {
      setCurrentStep(0);
      const newSortedData: ICaregiver[] = HRCaregiverHelper.sortTableData(data, sortName);
      setSortedData(newSortedData);
      paginateData(newSortedData, true);
    }
  }, [data]);

  const paginateData = (dataSet: ICaregiver[], reset = false) => {
    const dataPages = Math.ceil(dataSet.length / 100);
    const newDataChunks = new Array(dataPages).fill(null).map((_d, i) => {
      const newChunk = dataSet.slice(i * 100, (i + 1) * 100);
      return newChunk;
    });
    setDataChunk(newDataChunks);
    if (reset) {
      setVisibleChunk([...newDataChunks[0]]);
      return;
    }
    let newVisible: ICaregiver[] = [];
    for (let i = 0; i <= currentStep; i += 1) {
      newVisible = [...newVisible, ...newDataChunks[i]];
    }
    setVisibleChunk(newVisible);
  };

  const sortHeader = (sortingName: string) => {
    let newSortedData = [...sortedData];
    if (sortingName === sortName) {
      if (sortDirection === 'descending') {
        setSortDirection('ascending');
      } else {
        setSortDirection('descending');
      }
      newSortedData = sortedData.reverse();
      setSortedData(newSortedData);
    } else {
      setSortName(sortingName);
      setSortDirection('descending');
      newSortedData = HRCaregiverHelper.sortTableData(data, sortingName);
      setSortedData(newSortedData);
    }
    paginateData(newSortedData);
  };

  const handleViewCompliance = (caregiver?: ICaregiver) => {
    setViewComplianceCaregiver(caregiver || null);
  };

  const closeViewCompliance = () => {
    setViewComplianceCaregiver(null);
  };

  const noData = !data.length;

  const onVisibilityUpdate = (_nothing: null, visibilityData: VisibilityEventData) => {
    const {
      calculations: { bottomVisible, direction },
    } = visibilityData;
    if (bottomVisible && direction === 'down') {
      const chunkLength = dataChunk.length;
      const newEnd = currentStep + 1;
      if (chunkLength && newEnd < chunkLength) {
        setCurrentStep(newEnd);
        if (newEnd < chunkLength) {
          setVisibleChunk([...visibleChunk, ...dataChunk[newEnd]]);
        }
      }
    }
  };

  return (
    <Visibility onUpdate={onVisibilityUpdate}>
      {viewCompliancesCaregiver && (
        <ComplianceCompletionDetails
          caregiver={viewCompliancesCaregiver}
          blocks={blocks}
          onCloseClicked={closeViewCompliance}
        />
      )}
      {loading && (
        <Dimmer active page>
          <CareLoader loading={loading} invert showText={false} />
        </Dimmer>
      )}
      <MainTable hasData={!noData}>
        <TableHeader>
          <HeadersRow
            tableType={tableType}
            completed={isCompleted}
            vaccine={includeVaccine}
            sortName={sortName}
            sortDirection={sortDirection}
            handleSortHeader={sortHeader}
          />
        </TableHeader>

        <Table.Body>
          {!!data.length && (
            <BodyRows
              data={visibleChunk}
              tableType={tableType}
              completed={isCompleted}
              vaccine={includeVaccine}
              blocks={blocks}
              selectedCandidates={selectedCandidates}
              viewCompliance={handleViewCompliance}
              onCheckClick={onCheckClick}
            />
          )}
        </Table.Body>
      </MainTable>
      {noData && (
        <Grid className='hr-table noResults'>
          <NoMatch
            id='candidatesResultsNoMatch'
            message='Try Removing some of your search filters'
          />
        </Grid>
      )}
    </Visibility>
  );
};

export default memo(HRCaregiversCandidatesTable);

/* eslint-disable import/prefer-default-export */
import * as types from '../constants/ActionTypes';
// eslint-disable-next-line import/no-cycle
import { uiStateActions } from '.';
// eslint-disable-next-line import/no-cycle
import { userActions } from './user';
import { backend } from '../services';

const moment = require('moment');

function heartbeatReceived(timestamp) {
  return { type: types.IOT_HEARTBEAT, timestamp: moment(timestamp) };
}

class HeartbeatTimer {
  constructor() {
    this.timer = null;
    this.clear = () => {
      clearTimeout(this.timer);
      this.timer = null;
    };
    this.initialize = (dispatch, getState) => {
      const update = async () => {
        const state = getState();
        const loggedIn = backend.isLoggedIn();

        if (
          loggedIn &&
          (!state.carechat.lastHeartbeat ||
            state.carechat.lastHeartbeat.isBefore(moment().subtract(70, 'seconds')))
        ) {
          dispatch(uiStateActions.uiStateChange('heartbeat', 'isBroken', true));
          const config = state.carechat.config ? state.carechat.config : {};
          await backend
            .createEvent({
              type: 'IOT_CONNECTION_LOST',
              meta: { endpoint: config.endpoint, sessionToken: config.sessionToken },
            })
            .catch(e => {
              const httpStatus = e.response && e.response.status;
              if (httpStatus === 401) return dispatch(userActions.logout());
              return undefined;
            });
        } else {
          dispatch(uiStateActions.uiStateChange('heartbeat', 'isBroken', false));
        }
      };
      const initTimer = () => {
        this.timer = setTimeout(() => {
          update();
          initTimer();
        }, 60000);
      };
      initTimer();
    };
  }
}

export const heartbeatActions = {
  heartbeatReceived,
  HeartbeatTimer,
};

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_SUCCESS_AGENCIES = 'LOGIN_SUCCESS_AGENCIES';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const PRE_LOGIN = 'PRE_LOGIN';

export const TERMS_PENDING = 'TERMS_PENDING';

export const GET_AGENCIES_REQUEST = 'GET_AGENCIES_REQUEST';
export const GET_AGENCIES_FAILURE = 'GET_AGENCIES_FAILURE';
export const GET_AGENCIES_SUCCESS = 'GET_AGENCIES_SUCCESS';

export const SWITCH_AGENCY_REQUEST = 'SWITCH_AGENCY_REQUEST';
export const SWITCH_AGENCY_FAILURE = 'SWITCH_AGENCY_FAILURE';
export const SWITCH_AGENCY_SUCCESS = 'SWITCH_AGENCY_SUCCESS';

export const GET_MAGIC_LOGIN_REQUEST = 'GET_MAGIC_LOGIN_REQUEST';
export const GET_MAGIC_LOGIN_SUCCESS = 'GET_MAGIC_LOGIN_SUCCESS';
export const GET_MAGIC_LOGIN_FAILURE = 'GET_MAGIC_LOGIN_FAILURE';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const RECOVER_REQUEST = 'RECOVER_REQUEST';
export const RECOVER_SUCCESS = 'RECOVER_SUCCESS';
export const RECOVER_FAILURE = 'RECOVER_FAILURE';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const GET_DISCIPLINES_REQUEST = 'GET_DISCIPLINES_REQUEST';
export const GET_DISCIPLINES_SUCCESS = 'GET_DISCIPLINES_SUCCESS';
export const GET_DISCIPLINES_FAILURE = 'GET_DISCIPLINES_FAILURE';

export const CLIENT_CAREGIVERS_SCORE_REQUEST = 'CLIENT_CAREGIVERS_SCORE_REQUEST';
export const CLIENT_CAREGIVERS_SCORE_SUCCESS = 'CLIENT_CAREGIVERS_SCORE_SUCCESS';
export const CLIENT_CAREGIVERS_SCORE_FAILURE = 'CLIENT_CAREGIVERS_SCORE_FAILURE';

export const SHIFT_CAREGIVERS_SCORE_REQUEST = 'SHIFT_CAREGIVERS_SCORE_REQUEST';
export const SHIFT_CAREGIVERS_SCORE_SUCCESS = 'SHIFT_CAREGIVERS_SCORE_SUCCESS';
export const SHIFT_CAREGIVERS_SCORE_FAILURE = 'SHIFT_CAREGIVERS_SCORE_FAILURE';

export const CAREGIVERS_SCORE_RESET_REQUEST = 'CAREGIVERS_SCORE_RESET_REQUEST';

export const GET_LANGUAGES_REQUEST = 'GET_LANGUAGES_REQUEST';
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';
export const GET_LANGUAGES_FAILURE = 'GET_LANGUAGES_FAILURE';

export const GET_ATTRIBUTES_REQUEST = 'GET_ATTRIBUTES_REQUEST';
export const GET_ATTRIBUTES_SUCCESS = 'GET_ATTRIBUTES_SUCCESS';
export const GET_ATTRIBUTES_FAILURE = 'GET_ATTRIBUTES_FAILURE';

export const UPDATE_ATTRIBUTES_REQUEST = 'UPDATE_ATTRIBUTES_REQUEST';
export const UPDATE_ATTRIBUTES_SUCCESS = 'UPDATE_ATTRIBUTES_SUCCESS';
export const UPDATE_ATTRIBUTES_FAILURE = 'UPDATE_ATTRIBUTES_FAILURE';

export const UPDATE_AGENCY_SETTINGS_REQUEST = 'UPDATE_AGENCY_SETTINGS_REQUEST';
export const UPDATE_AGENCY_SETTINGS_SUCCESS = 'UPDATE_AGENCY_SETTINGS_SUCCESS';
export const UPDATE_AGENCY_SETTINGS_FAILURE = 'UPDATE_AGENCY_SETTINGS_FAILURE';

export const GET_AGENCY_SETTINGS_REQUEST = 'GET_AGENCY_SETTINGS_REQUEST';
export const GET_AGENCY_SETTINGS_SUCCESS = 'GET_AGENCY_SETTINGS_SUCCESS';
export const GET_AGENCY_SETTINGS_FAILURE = 'GET_AGENCY_SETTINGS_FAILURE';

export const GET_CAREGIVER_COMPLIANCE_DOH_REQUEST = 'GET_CAREGIVER_COMPLIANCE_DOH_REQUEST';
export const GET_CAREGIVER_COMPLIANCE_DOH_SUCCESS = 'GET_CAREGIVER_COMPLIANCE_DOH_SUCCESS';
export const GET_CAREGIVER_COMPLIANCE_DOH_FAILURE = 'GET_CAREGIVER_COMPLIANCE_DOH_FAILURE';

export const GET_CAREGIVER_COMPLIANCE_EXCLUSIONS_REQUEST =
  'GET_CAREGIVER_COMPLIANCE_EXCLUSIONS_REQUEST';
export const GET_CAREGIVER_COMPLIANCE_EXCLUSIONS_SUCCESS =
  'GET_CAREGIVER_COMPLIANCE_EXCLUSIONS_SUCCESS';
export const GET_CAREGIVER_COMPLIANCE_EXCLUSIONS_FAILURE =
  'GET_CAREGIVER_COMPLIANCE_EXCLUSIONS_FAILURE';

export const LOGOUT = 'LOGOUT';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const CLEAR_ALL = 'CLEAR_ALL';

export const INPUT_CHANGED = 'INPUT_CHANGED';
export const UI_STATE_CHANGED = 'UI_STATE_CHANGED';
export const SET_UI_GROUP_STATE = 'SET_UI_GROUP_STATE';
export const RESET_UI_GROUP_STATE = 'RESET_UI_GROUP_STATE';

export const CREATE_EMPLOYEE_REQUEST = 'CREATE_EMPLOYEE_REQUEST';
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAILURE = 'CREATE_EMPLOYEE_FAILURE';
export const CREATE_EMPLOYEE_CLEAR = 'CREATE_EMPLOYEE_CLEAR';

export const UPDATE_EMPLOYEE_REQUEST = 'UPDATE_EMPLOYEE_REQUEST';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAILURE = 'UPDATE_EMPLOYEE_FAILURE';

export const UPDATE_USER_STATUS_REQUEST = 'UPDATE_USER_STATUS_REQUEST';
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS';
export const UPDATE_USER_STATUS_FAILURE = 'UPDATE_USER_STATUS_FAILURE';

export const UPLOAD_AVATAR_REQUEST = 'UPLOAD_AVATAR_REQUEST';
export const UPLOAD_AVATAR_SUCCESS = 'UPLOAD_AVATAR_SUCCESS';
export const UPLOAD_AVATAR_FAILURE = 'UPLOAD_AVATAR_FAILURE';

export const REMOVE_AVATAR_REQUEST = 'REMOVE_AVATAR_REQUEST';
export const REMOVE_AVATAR_SUCCESS = 'REMOVE_AVATAR_SUCCESS';
export const REMOVE_AVATAR_FAILURE = 'REMOVE_AVATAR_FAILURE';

export const GET_EMPLOYEE_DASHBOARD_REQUEST = 'GET_EMPLOYEE_DASHBOARD_REQUEST';
export const GET_EMPLOYEE_DASHBOARD_SUCCESS = 'GET_EMPLOYEE_DASHBOARD_SUCCESS';
export const GET_EMPLOYEE_DASHBOARD_FAILURE = 'GET_EMPLOYEE_DASHBOARD_FAILURE';

export const INVITE_EMPLOYEE_REQUEST = 'INVITE_EMPLOYEE_REQUEST';
export const INVITE_EMPLOYEE_SUCCESS = 'INVITE_EMPLOYEE_SUCCESS';
export const INVITE_EMPLOYEE_FAILURE = 'INVITE_EMPLOYEE_FAILURE';

export const GET_EMPLOYEE_LIST_REQUEST = 'GET_EMPLOYEE_LIST_REQUEST';
export const GET_EMPLOYEE_LIST_SUCCESS = 'GET_EMPLOYEE_LIST_SUCCESS';
export const GET_EMPLOYEE_LIST_FAILURE = 'GET_EMPLOYEE_LIST_FAILURE';

export const GET_EMPLOYEE_REQUEST = 'GET_EMPLOYEE_REQUEST';
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS';
export const GET_EMPLOYEE_FAILURE = 'GET_EMPLOYEE_FAILURE';

export const RESET_CAREGIVERS_LIST = 'RESET_CAREGIVERS_LIST';

export const CAREGIVERS_CHECKBOX_CLICKED = 'CAREGIVERS_CHECKBOX_CLICKED';
export const CAREGIVERS_SELECT_ALL_CLICKED = 'CAREGIVERS_SELECT_ALL_CLICKED';
export const RESET_CAREGIVERS_CHECKBOX_CLICKED = 'RESET_CAREGIVERS_CHECKBOX_CLICKED';

export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';

export const LIST_CAREGIVERS_REQUEST = 'LIST_CAREGIVERS_REQUEST';
export const LIST_CAREGIVERS_SUCCESS = 'LIST_CAREGIVERS_SUCCESS';
export const LIST_CAREGIVERS_FAILURE = 'LIST_CAREGIVERS_FAILURE';

export const SEARCH_CAREGIVERS_REQUEST = 'SEARCH_CAREGIVERS_REQUEST';
export const SEARCH_CAREGIVERS_SUCCESS = 'SEARCH_CAREGIVERS_SUCCESS';
export const SEARCH_CAREGIVERS_FAILURE = 'SEARCH_CAREGIVERS_FAILURE';

export const CAREGIVERS_ONLINESTATUS_REQUEST = 'CAREGIVERS_ONLINESTATUS_REQUEST';
export const CAREGIVERS_ONLINESTATUS_SUCCESS = 'CAREGIVERS_ONLINESTATUS_SUCCESS';
export const CAREGIVERS_ONLINESTATUS_FAILURE = 'CAREGIVERS_ONLINESTATUS_FAILURE';

export const GET_CAREGIVER_REQUEST = 'GET_CAREGIVER_REQUEST';
export const GET_CAREGIVER_SUCCESS = 'GET_CAREGIVER_SUCCESS';
export const GET_CAREGIVER_FAILURE = 'GET_CAREGIVER_FAILURE';

export const GET_CAREGIVER_EVENTS_REQUEST = 'GET_CAREGIVER_EVENTS_REQUEST';
export const GET_CAREGIVER_EVENTS_SUCCESS = 'GET_CAREGIVER_EVENTS_SUCCESS';
export const GET_CAREGIVER_EVENTS_FAILURE = 'GET_CAREGIVER_EVENTS_FAILURE';

export const CREATE_CAREGIVER_REQUEST = 'CREATE_CAREGIVER_REQUEST';
export const CREATE_CAREGIVER_SUCCESS = 'CREATE_CAREGIVER_SUCCESS';
export const CREATE_CAREGIVER_FAILURE = 'CREATE_CAREGIVER_FAILURE';
export const CREATE_CAREGIVER_CLEAR = 'CREATE_CAREGIVER_CLEAR';

export const UPDATE_CAREGIVER_REQUEST = 'UPDATE_CAREGIVER_REQUEST';
export const UPDATE_CAREGIVER_SUCCESS = 'UPDATE_CAREGIVER_SUCCESS';
export const UPDATE_CAREGIVER_FAILURE = 'UPDATE_CAREGIVER_FAILURE';

export const UPDATE_CAREGIVER_CASE_PREFERENCES_REQUEST =
  'UPDATE_CAREGIVER_CASE_PREFERENCES_REQUEST';
export const UPDATE_CAREGIVER_CASE_PREFERENCES_SUCCESS =
  'UPDATE_CAREGIVER_CASE_PREFERENCES_SUCCESS';
export const UPDATE_CAREGIVER_CASE_PREFERENCES_FAILURE =
  'UPDATE_CAREGIVER_CASE_PREFERENCES_FAILURE';

export const UPDATE_CAREGIVER_COMPLIANCE_DOHINFO_REQUEST =
  'UPDATE_CAREGIVER_COMPLIANCE_DOHINFO_REQUEST';
export const UPDATE_CAREGIVER_COMPLIANCE_DOHINFO_SUCCESS =
  'UPDATE_CAREGIVER_COMPLIANCE_DOHINFO_SUCCESS';
export const UPDATE_CAREGIVER_COMPLIANCE_DOHINFO_FAILURE =
  'UPDATE_CAREGIVER_COMPLIANCE_DOHINFO_FAILURE';

export const UPDATE_CAREGIVER_COMPLIANCE_EXCLUSIONS_REQUEST =
  'UPDATE_CAREGIVER_COMPLIANCE_EXCLUSIONS_REQUEST';
export const UPDATE_CAREGIVER_COMPLIANCE_EXCLUSIONS_SUCCESS =
  'UPDATE_CAREGIVER_COMPLIANCE_EXCLUSIONS_SUCCESS';
export const UPDATE_CAREGIVER_COMPLIANCE_EXCLUSIONS_FAILURE =
  'UPDATE_CAREGIVER_COMPLIANCE_EXCLUSIONS_FAILURE';

export const SET_CAREGIVER_COMPLIANCE_BLOCK_REQUEST = 'SET_CAREGIVER_COMPLIANCE_BLOCK_REQUEST';
export const SET_CAREGIVER_COMPLIANCE_BLOCK_SUCCESS = 'SET_CAREGIVER_COMPLIANCE_BLOCK_SUCCESS';
export const SET_CAREGIVER_COMPLIANCE_BLOCK_FAILURE = 'SET_CAREGIVER_COMPLIANCE_BLOCK_FAILURE';

export const SET_CAREGIVER_COMPLIANCE_BY_PROFILE_REQUEST =
  'SET_CAREGIVER_COMPLIANCE_BY_PROFILE_REQUEST';
export const SET_CAREGIVER_COMPLIANCE_BY_PROFILE_SUCCESS =
  'SET_CAREGIVER_COMPLIANCE_BY_PROFILE_SUCCESS';
export const SET_CAREGIVER_COMPLIANCE_BY_PROFILE_FAILURE =
  'SET_CAREGIVER_COMPLIANCE_BY_PROFILE_FAILURE';

export const GET_CAREGIVER_COMPLIANCE_BLOCK_REQUEST = 'GET_CAREGIVER_COMPLIANCE_BLOCK_REQUEST';
export const GET_CAREGIVER_COMPLIANCE_BLOCK_SUCCESS = 'GET_CAREGIVER_COMPLIANCE_BLOCK_SUCCESS';
export const GET_CAREGIVER_COMPLIANCE_BLOCK_FAILURE = 'GET_CAREGIVER_COMPLIANCE_BLOCK_FAILURE';

export const CLEAR_CAREGIVER_COMPLIANCE_BLOCKS = 'CLEAR_CAREGIVER_COMPLIANCE_BLOCKS';

export const LIST_SCHEDULES_REQUEST = 'LIST_SCHEDULES_REQUEST';
export const LIST_SCHEDULES_SUCCESS = 'LIST_SCHEDULES_SUCCESS';
export const LIST_SCHEDULES_FAILURE = 'LIST_SCHEDULES_FAILURE';

export const RESET_SCHEDULES_SEARCH = 'RESET_SCHEDULES_SEARCH';

export const SHIFT_BY_CAREGIVER_REQUEST = 'SHIFT_BY_CAREGIVER_REQUEST';
export const SHIFT_BY_CAREGIVER_SUCCESS = 'SHIFT_BY_CAREGIVER_SUCCESS';
export const SHIFT_BY_CAREGIVER_FAILURE = 'SHIFT_BY_CAREGIVER_FAILURE';

export const GET_SHIFT_REQUEST = 'GET_SHIFT_REQUEST';
export const GET_SHIFT_SUCCESS = 'GET_SHIFT_SUCCESS';
export const GET_SHIFT_FAILURE = 'GET_SHIFT_FAILURE';

export const CREATE_SHIFTS_REQUEST = 'CREATE_SHIFTS_REQUEST';
export const CREATE_SHIFTS_SUCCESS = 'CREATE_SHIFTS_SUCCESS';
export const CREATE_SHIFTS_FAILURE = 'CREATE_SHIFTS_FAILURE';

export const UPDATE_SHIFT_REQUEST = 'UPDATE_SHIFT_REQUEST';
export const UPDATE_SHIFT_SUCCESS = 'UPDATE_SHIFT_SUCCESS';
export const UPDATE_SHIFT_FAILURE = 'UPDATE_SHIFT_FAILURE';

export const UPDATE_SHIFT_WITH_LIST_CAREGIVERS_REQUEST =
  'UPDATE_SHIFT_WITH_LIST_CAREGIVERS_REQUEST';
export const UPDATE_SHIFT_WITH_LIST_CAREGIVERS_SUCCESS =
  'UPDATE_SHIFT_WITH_LIST_CAREGIVERS_SUCCESS';
export const UPDATE_SHIFT_WITH_LIST_CAREGIVERS_FAILURE =
  'UPDATE_SHIFT_WITH_LIST_CAREGIVERS_FAILURE';

export const ADD_CAREGIVER_TO_SHIFT_REQUEST = 'ADD_CAREGIVER_TO_SHIFT_REQUEST';
export const ADD_CAREGIVER_TO_SHIFT_COMPLETED = 'ADD_CAREGIVER_TO_SHIFT_COMPLETED';
export const ADD_CAREGIVER_TO_SHIFT_SUCCESS = 'ADD_CAREGIVER_TO_SHIFT_SUCCESS';
export const ADD_CAREGIVER_TO_SHIFT_FAILURE = 'ADD_CAREGIVER_TO_SHIFT_FAILURE';
export const PROCESS_CAREGIVER_INVITATION = 'PROCESS_CAREGIVER_INVITATION';
export const PROCESS_CAREGIVER_INVITATION_COMPLETED = 'PROCESS_CAREGIVER_INVITATION_COMPLETED';

export const LIST_CLIENT_REQUEST = 'LIST_CLIENT_REQUEST';
export const LIST_CLIENT_SUCCESS = 'LIST_CLIENT_SUCCESS';
export const LIST_CLIENT_FAILURE = 'LIST_CLIENT_FAILURE';

export const SEARCH_CLIENT_REQUEST = 'SEARCH_CLIENT_REQUEST';
export const SEARCH_CLIENT_SUCCESS = 'SEARCH_CLIENT_SUCCESS';
export const SEARCH_CLIENT_FAILURE = 'SEARCH_CLIENT_FAILURE';

export const GET_CLIENT_REQUEST = 'GET_CLIENT_REQUEST';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAILURE = 'GET_CLIENT_FAILURE';

export const CREATE_CLIENT_REQUEST = 'CREATE_CLIENT_REQUEST';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAILURE = 'CREATE_CLIENT_FAILURE';
export const CREATE_CLIENT_CLEAR = 'CREATE_CLIENT_CLEAR';

export const UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE';

export const REMOVE_CLIENT_REQUEST = 'REMOVE_CLIENT_REQUEST';
export const REMOVE_CLIENT_SUCCESS = 'REMOVE_CLIENT_SUCCESS';
export const REMOVE_CLIENT_FAILURE = 'REMOVE_CLIENT_FAILURE';

export const GET_ACTIVE_CONVERSATIONS_REQUEST = 'GET_ACTIVE_CONVERSATIONS_REQUEST';
export const GET_ACTIVE_CONVERSATIONS_SUCCESS = 'GET_ACTIVE_CONVERSATIONS_SUCCESS';
export const GET_ACTIVE_CONVERSATIONS_FAILURE = 'GET_ACTIVE_CONVERSATIONS_FAILURE';

export const GET_CONVERSATION_REQUEST = 'GET_CONVERSATION_REQUEST';
export const GET_CONVERSATION_SUCCESS = 'GET_CONVERSATION_SUCCESS';
export const GET_CONVERSATION_FAILURE = 'GET_CONVERSATION_FAILURE';

export const GET_CONVERSATIONS_REQUEST = 'GET_CONVERSATIONS_REQUEST';
export const GET_CONVERSATIONS_SUCCESS = 'GET_CONVERSATIONS_SUCCESS';
export const GET_CONVERSATIONS_FAILURE = 'GET_CONVERSATIONS_FAILURE';

export const MARK_CONVERSATION_READ = 'CHAT_MARK_CONVERSATION_READ';
export const CLOSE_CONVERSATION = 'CHAT_CLOSE_CONVERSATION';

export const FEED_REQUEST = 'FEED_REQUEST';
export const FEED_SUCCESS = 'FEED_SUCCESS';
export const FEED_FAILURE = 'FEED_FAILURE';

export const IOT_CONNECT_REQUEST = 'IOT_CONNECT_REQUEST';
export const IOT_CONNECT_SUCCESS = 'IOT_CONNECT_SUCCESS';
export const IOT_CONNECT_FAILURE = 'IOT_CONNECT_FAILURE';

export const IOT_CONNECT_MESSAGE = 'IOT_CONNECT_MESSAGE';
export const IOT_CONNECT_ACTIVE_CONVERSATION = 'IOT_CONNECT_ACTIVE_CONVERSATION';
export const IOT_LIFECYCLE = 'IOT_LIFECYCLE';

export const ALERT_NOTIFICATION = 'ALERT_NOTIFICATION';
export const ALERT_MESSAGE = 'ALERT_MESSAGE';
export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_CLEAR = 'ALERT_CLEAR';
export const ALERT_CLEAR_ERRORS = 'ALERT_CLEAR_ERRORS';

export const LIST_TRAVELTIMES_REQUEST = 'LIST_TRAVELTIMES_REQUEST';
export const LIST_TRAVELTIMES_SUCCESS = 'LIST_TRAVELTIMES_SUCCESS';
export const LIST_TRAVELTIMES_FAILURE = 'LIST_TRAVELTIMES_FAILURE';

export const WEB_NOTIFICATION = 'WEB_NOTIFICATION';

export const SET_SHIFT_TIMING = 'SET_SHIFT_TIMING'; // REM
export const UPDATE_SHIFT_TIMING = 'UPDATE_SHIFT_TIMING'; // OVE

export const SET_TARGET_SHIFTS_REQUEST = 'SET_TARGET_SHIFTS_REQUEST';
export const SET_TARGET_SHIFTS_SUCCESS = 'SET_TARGET_SHIFTS_SUCCESS';
export const SET_TARGET_SHIFTS_FAILURE = 'SET_TARGET_SHIFTS_FAILURE';

export const TRAVEL_TIME_REQUEST = 'TRAVEL_TIME_REQUEST';
export const TRAVEL_TIME_SUCCESS = 'TRAVEL_TIME_SUCCESS';
export const TRAVEL_TIME_PARTIAL = 'TRAVEL_TIME_PARTIAL';
export const TRAVEL_TIME_FAILURE = 'TRAVEL_TIME_FAILURE';

export const SCHEDULE_CB_CLICKED = 'SCHEDULE_CB_CLICKED';
export const SCHEDULE_CB_ALL_CLICKED = 'SCHEDULE_CB_ALL_CLICKED';

export const NOTIFICATION_SHIFT_UPDATED = 'NOTIFICATION_SHIFT_UPDATED';

export const UPLOAD_CSV_REQUEST = 'UPLOAD_CSV_REQUEST';
export const UPLOAD_CSV_SUCCESS = 'UPLOAD_CSV_SUCCESS';
export const UPLOAD_CSV_FAILURE = 'UPLOAD_CSV_FAILURE';

export const GET_IMPORTS_REQUEST = 'GET_IMPORTS_REQUEST';
export const GET_IMPORTS_SUCCESS = 'GET_IMPORTS_SUCCESS';
export const GET_IMPORTS_FAILURE = 'GET_IMPORTS_FAILURE';

export const GET_IMPORT_DETAILS_REQUEST = 'GET_IMPORT_DETAILS_REQUEST';
export const GET_IMPORT_DETAILS_SUCCESS = 'GET_IMPORT_DETAILS_SUCCESS';
export const GET_IMPORT_DETAILS_FAILURE = 'GET_IMPORT_DETAILS_FAILURE';
export const IMPORT_NOTIFICATION = 'IMPORT_NOTIFICATION';

export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE';

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';

export const GET_PREFERENCE_CHANGELOG_REQUEST = 'GET_PREFERENCE_CHANGELOG_REQUEST';
export const GET_PREFERENCE_CHANGELOG_SUCCESS = 'GET_PREFERENCE_CHANGELOG_SUCCESS';
export const GET_PREFERENCE_CHANGELOG_FAILURE = 'GET_PREFERENCE_CHANGELOG_FAILURE';

export const GET_SHIFT_CHANGELOG_CSV_REQUEST = 'GET_SHIFT_CHANGELOG_CSV_REQUEST';
export const GET_SHIFT_CHANGELOG_CSV_SUCCESS = 'GET_SHIFT_CHANGELOG_CSV_SUCCESS';
export const GET_SHIFT_CHANGELOG_CSV_FAILURE = 'GET_SHIFT_CHANGELOG_CSV_FAILURE';

export const GET_SHIFT_CHANGELOG_REQUEST = 'GET_SHIFT_CHANGELOG_REQUEST';
export const GET_SHIFT_CHANGELOG_SUCCESS = 'GET_SHIFT_CHANGELOG_SUCCESS';
export const GET_SHIFT_CHANGELOG_FAILURE = 'GET_SHIFT_CHANGELOG_FAILURE';
export const GET_EMPLOYEE_CHANGELOG_REQUEST = 'GET_EMPLOYEE_CHANGELOG_REQUEST';
export const GET_EMPLOYEE_CHANGELOG_SUCCESS = 'GET_EMPLOYEE_CHANGELOG_SUCCESS';
export const GET_EMPLOYEE_CHANGELOG_FAILURE = 'GET_EMPLOYEE_CHANGELOG_FAILURE';

export const LIST_SERVICES_REQUEST = 'LIST_SERVICES_REQUEST';
export const LIST_SERVICES_SUCCESS = 'LIST_SERVICES_SUCCESS';
export const LIST_SERVICES_FAILURE = 'LIST_SERVICES_FAILURE';

export const GEOCODE_REQUEST = 'GEOCODE_REQUEST';
export const GEOCODE_SUCCESS = 'GEOCODE_SUCCESS';
export const GEOCODE_FAILURE = 'GEOCODE_FAILURE';

export const GET_COMPLIANCE_BLOCKS_REQUEST = 'GET_COMPLIANCE_BLOCKS_REQUEST';
export const GET_COMPLIANCE_BLOCKS_SUCCESS = 'GET_COMPLIANCE_BLOCKS_SUCCESS';
export const GET_COMPLIANCE_BLOCKS_FAILURE = 'GET_COMPLIANCE_BLOCKS_FAILURE';

export const UPDATE_COMPLIANCE_BLOCKS_REQUEST = 'UPDATE_COMPLIANCE_BLOCKS_REQUEST';
export const UPDATE_COMPLIANCE_BLOCKS_SUCCESS = 'UPDATE_COMPLIANCE_BLOCKS_SUCCESS';
export const UPDATE_COMPLIANCE_BLOCKS_FAILURE = 'UPDATE_COMPLIANCE_BLOCKS_FAILURE';

export const GET_CAREGIVER_DOH_CANDIDATES_REQUEST = 'GET_CAREGIVER_DOH_CANDIDATES_REQUEST';
export const GET_CAREGIVER_DOH_CANDIDATES_SUCCESS = 'GET_CAREGIVER_DOH_CANDIDATES_SUCCESS';
export const GET_CAREGIVER_DOH_CANDIDATES_FAILURE = 'GET_CAREGIVER_DOH_CANDIDATES_FAILURE';

export const UPDATE_CAREGIVER_COMPLIANCE_UI = 'UPDATE_CAREGIVER_COMPLIANCE_UI';
export const CLEAR_CAREGIVER_COMPLIANCE_UI = 'CLEAR_CAREGIVER_COMPLIANCE_UI';

export const DOWNLOAD_CAREGIVER_COMPLIANCE_DOCUMENTS_REQUEST =
  'DOWNLOAD_CAREGIVER_COMPLIANCE_DOCUMENTS_REQUEST';
export const DOWNLOAD_CAREGIVER_COMPLIANCE_DOCUMENTS_SUCCESS =
  'DOWNLOAD_CAREGIVER_COMPLIANCE_DOCUMENTS_SUCCESS';
export const DOWNLOAD_CAREGIVER_COMPLIANCE_DOCUMENTS_FAILURE =
  'DOWNLOAD_CAREGIVER_COMPLIANCE_DOCUMENTS_FAILURE';

export const UPLOAD_CAREGIVER_COMPLIANCE_DOCUMENT_REQUEST =
  'UPLOAD_CAREGIVER_COMPLIANCE_DOCUMENT_REQUEST';
export const UPLOAD_CAREGIVER_COMPLIANCE_DOCUMENT_SUCCESS =
  'UPLOAD_CAREGIVER_COMPLIANCE_DOCUMENT_SUCCESS';
export const UPLOAD_CAREGIVER_COMPLIANCE_DOCUMENT_FAILURE =
  'UPLOAD_CAREGIVER_COMPLIANCE_DOCUMENT_FAILURE';

export const REMOVE_CAREGIVER_COMPLIANCE_DOCUMENT_REQUEST =
  'REMOVE_CAREGIVER_COMPLIANCE_DOCUMENT_REQUEST';
export const REMOVE_CAREGIVER_COMPLIANCE_DOCUMENT_SUCCESS =
  'REMOVE_CAREGIVER_COMPLIANCE_DOCUMENT_SUCCESS';
export const REMOVE_CAREGIVER_COMPLIANCE_DOCUMENT_FAILURE =
  'REMOVE_CAREGIVER_COMPLIANCE_DOCUMENT_FAILURE';

export const GET_COMPLIANCE_TRAINING_COURSES_REQUEST = 'GET_COMPLIANCE_TRAINING_COURSES_REQUEST';
export const GET_COMPLIANCE_TRAINING_COURSES_FAILURE = 'GET_COMPLIANCE_TRAINING_COURSES_FAILURE';
export const GET_COMPLIANCE_TRAINING_COURSES_SUCCESS = 'GET_COMPLIANCE_TRAINING_COURSES_SUCCESS';

export const COMPLIANCE_SETTINGS_HIDE_REMOVE_AGREEMENT =
  'COMPLIANCE_SETTINGS_HIDE_REMOVE_AGREEMENT';

export const CREATE_CAREGIVER_IN_SERVICE_TRAINING_REQUEST =
  'CREATE_CAREGIVER_IN_SERVICE_TRAINING_REQUEST';
export const CREATE_CAREGIVER_IN_SERVICE_TRAINING_SUCCESS =
  'CREATE_CAREGIVER_IN_SERVICE_TRAINING_SUCCESS';
export const CREATE_CAREGIVER_IN_SERVICE_TRAINING_FAILURE =
  'CREATE_CAREGIVER_IN_SERVICE_TRAINING_FAILURE';

export const ADD_WORKHISTORY_TO_CAREGIVER_REQUEST = 'ADD_WORKHISTORY_TO_CAREGIVER_REQUEST';
export const ADD_WORKHISTORY_TO_CAREGIVER_FAILURE = 'ADD_WORKHISTORY_TO_CAREGIVER_FAILURE';
export const ADD_WORKHISTORY_TO_CAREGIVER_SUCCESS = 'ADD_WORKHISTORY_TO_CAREGIVER_SUCCESS';

export const DELETE_WORKHISTORY_TO_CAREGIVER_REQUEST = 'DELETE_WORKHISTORY_TO_CAREGIVER_REQUEST';
export const DELETE_WORKHISTORY_TO_CAREGIVER_FAILURE = 'DELETE_WORKHISTORY_TO_CAREGIVER_FAILURE';
export const DELETE_WORKHISTORY_TO_CAREGIVER_SUCCESS = 'DELETE_WORKHISTORY_TO_CAREGIVER_SUCCESS';

export const GET_EVENT_ATTENDEES_REQUEST = 'GET_EVENT_ATTENDEES_REQUEST';
export const GET_EVENT_ATTENDEES_FAILURE = 'GET_EVENT_ATTENDEES_FAILURE';
export const GET_EVENT_ATTENDEES_SUCCESS = 'GET_EVENT_ATTENDEES_SUCCESS';

export const CREATE_COMPLIANCE_TRAINING_EVENT_REQUEST = 'CREATE_COMPLIANCE_TRAINING_EVENT_REQUEST';
export const CREATE_COMPLIANCE_TRAINING_EVENT_SUCCESS = 'CREATE_COMPLIANCE_TRAINING_EVENT_SUCCESS';
export const CREATE_COMPLIANCE_TRAINING_EVENT_FAILURE = 'CREATE_COMPLIANCE_TRAINING_EVENT_FAILURE';

export const SUBMIT_COMPLIANCE_TRAINING_EVENT_REQUEST = 'SUBMIT_COMPLIANCE_TRAINING_EVENT_REQUEST';
export const SUBMIT_COMPLIANCE_TRAINING_EVENT_SUCCESS = 'SUBMIT_COMPLIANCE_TRAINING_EVENT_SUCCESS';
export const SUBMIT_COMPLIANCE_TRAINING_EVENT_FAILURE = 'SUBMIT_COMPLIANCE_TRAINING_EVENT_FAILURE';

export const UPDATE_COMPLIANCE_TRAINING_EVENT_REQUEST = 'UPDATE_COMPLIANCE_TRAINING_EVENT_REQUEST';
export const UPDATE_COMPLIANCE_TRAINING_EVENT_SUCCESS = 'UPDATE_COMPLIANCE_TRAINING_EVENT_SUCCESS';
export const UPDATE_COMPLIANCE_TRAINING_EVENT_FAILURE = 'UPDATE_COMPLIANCE_TRAINING_EVENT_FAILURE';

export const GET_COMPLIANCE_TRAINING_EVENTS_REQUEST = 'GET_COMPLIANCE_TRAINING_EVENTS_REQUEST';
export const REMOVE_COMPLIANCE_TRAINING_EVENTS_SUCCESS =
  'REMOVE_COMPLIANCE_TRAINING_EVENTS_SUCCESS';
export const GET_COMPLIANCE_TRAINING_EVENTS_SUCCESS = 'GET_COMPLIANCE_TRAINING_EVENTS_SUCCESS';
export const GET_COMPLIANCE_TRAINING_EVENTS_FAILURE = 'GET_COMPLIANCE_TRAINING_EVENTS_FAILURE';

export const REMOVE_CAREGIVER_IN_SERVICE_TRAINING_REQUEST =
  'REMOVE_CAREGIVER_IN_SERVICE_TRAINING_REQUEST';
export const REMOVE_CAREGIVER_IN_SERVICE_TRAINING_SUCCESS =
  'REMOVE_CAREGIVER_IN_SERVICE_TRAINING_SUCCESS';
export const REMOVE_CAREGIVER_IN_SERVICE_TRAINING_FAILURE =
  'REMOVE_CAREGIVER_IN_SERVICE_TRAINING_FAILURE';

export const GET_TRAINING_CANDIDATES_REQUEST = 'GET_TRAINING_CANDIDATES_REQUEST';
export const GET_TRAINING_CANDIDATES_FAILURE = 'GET_TRAINING_CANDIDATES_FAILURE';
export const GET_TRAINING_CANDIDATES_SUCCESS = 'GET_TRAINING_CANDIDATES_SUCCESS';

export const GET_CAREGIVERS_COMPLIANCE_REQUEST = 'GET_CAREGIVERS_COMPLIANCE_REQUEST';
export const GET_CAREGIVERS_COMPLIANCE_FAILURE = 'GET_CAREGIVERS_COMPLIANCE_FAILURE';
export const GET_CAREGIVERS_COMPLIANCE_SUCCESS = 'GET_CAREGIVERS_COMPLIANCE_SUCCESS';

export const COMPLETE_CAREGIVER_PREHIRE_REQUEST = 'COMPLETE_CAREGIVER_PREHIRE_REQUEST';
export const COMPLETE_CAREGIVER_PREHIRE_FAILURE = 'COMPLETE_CAREGIVER_PREHIRE_FAILURE';
export const COMPLETE_CAREGIVER_PREHIRE_SUCCESS = 'COMPLETE_CAREGIVER_PREHIRE_SUCCESS';

export const CLEAR_EVENT_ATTENDEES = 'CLEAR_EVENT_ATTENDEES';
export const CLEAR_EVENT_TO_SELECT = 'CLEAR_EVENT_TO_SELECT';

export const PRESELECT_BLOCK_ID = 'PRESELECT_BLOCK_ID';

export const GET_COMPLIANCE_HR_DASHBOARD_REQUEST = 'GET_COMPLIANCE_HR_DASHBOARD_REQUEST';
export const GET_COMPLIANCE_HR_DASHBOARD_FAILURE = 'GET_COMPLIANCE_HR_DASHBOARD_FAILURE';
export const GET_COMPLIANCE_HR_DASHBOARD_SUCCESS = 'GET_COMPLIANCE_HR_DASHBOARD_SUCCESS';

export const OPEN_ANNOUCEMENT_TOOL = 'OPEN_ANNOUCEMENT_TOOL';
export const CLOSE_ANNOUCEMENT_TOOL = 'CLOSE_ANNOUCEMENT_TOOL';

export const ANNOUNCEMENT_AUDIENCE_REQUEST = 'ANNOUNCEMENT_AUDIENCE_REQUEST';
export const ANNOUNCEMENT_AUDIENCE_FAILURE = 'ANNOUNCEMENT_AUDIENCE_FAILURE';
export const ANNOUNCEMENT_AUDIENCE_SUCCESS = 'ANNOUNCEMENT_AUDIENCE_SUCCESS';

export const SEND_ANNOUNCEMENT_REQUEST = 'SEND_ANNOUNCEMENT_REQUEST';
export const SEND_ANNOUNCEMENT_FAILURE = 'SEND_ANNOUNCEMENT_FAILURE';
export const SEND_ANNOUNCEMENT_SUCCESS = 'SEND_ANNOUNCEMENT_SUCCESS';

export const GET_COMPLIANCE_CLASS_ROOMS_REQUEST = 'GET_COMPLIANCE_CLASS_ROOMS_REQUEST';
export const GET_COMPLIANCE_CLASS_ROOMS_FAILURE = 'GET_COMPLIANCE_CLASS_ROOMS_FAILURE';
export const GET_COMPLIANCE_CLASS_ROOMS_SUCCESS = 'GET_COMPLIANCE_CLASS_ROOMS_SUCCESS';

export const CREATE_COMPLIANCE_CLASS_ROOMS_REQUEST = 'CREATE_COMPLIANCE_CLASS_ROOMS_REQUEST';
export const CREATE_COMPLIANCE_CLASS_ROOMS_FAILURE = 'CREATE_COMPLIANCE_CLASS_ROOMS_FAILURE';
export const CREATE_COMPLIANCE_CLASS_ROOMS_SUCCESS = 'CREATE_COMPLIANCE_CLASS_ROOMS_SUCCESS';

export const UPDATE_COMPLIANCE_CLASS_ROOMS_REQUEST = 'UPDATE_COMPLIANCE_CLASS_ROOMS_REQUEST';
export const UPDATE_COMPLIANCE_CLASS_ROOMS_FAILURE = 'UPDATE_COMPLIANCE_CLASS_ROOMS_FAILURE';
export const UPDATE_COMPLIANCE_CLASS_ROOMS_SUCCESS = 'UPDATE_COMPLIANCE_CLASS_ROOMS_SUCCESS';

export const GET_COMPLIANCE_INSTRUCTORS_REQUEST = 'GET_COMPLIANCE_INSTRUCTORS_REQUEST';
export const GET_COMPLIANCE_INSTRUCTORS_FAILURE = 'GET_COMPLIANCE_INSTRUCTORS_FAILURE';
export const GET_COMPLIANCE_INSTRUCTORS_SUCCESS = 'GET_COMPLIANCE_INSTRUCTORS_SUCCESS';

export const CREATE_COMPLIANCE_INSTRUCTORS_REQUEST = 'CREATE_COMPLIANCE_INSTRUCTORS_REQUEST';
export const CREATE_COMPLIANCE_INSTRUCTORS_FAILURE = 'CREATE_COMPLIANCE_INSTRUCTORS_FAILURE';
export const CREATE_COMPLIANCE_INSTRUCTORS_SUCCESS = 'CREATE_COMPLIANCE_INSTRUCTORS_SUCCESS';

export const GET_CLIENT_SHIFT_REQUEST = 'GET_CLIENT_SHIFT_REQUEST';
export const GET_CLIENT_SHIFT_SUCCESS = 'GET_CLIENT_SHIFT_SUCCESS';
export const GET_CLIENT_SHIFT_FAILURE = 'GET_CLIENT_SHIFT_FAILURE';

export const IOT_HEARTBEAT = 'IOT_HEARTBEAT';

export const GET_COURSES_LIST_REQUEST = 'GET_COURSES_LIST_REQUEST';
export const GET_COURSES_LIST_SUCCESS = 'GET_COURSES_LIST_SUCCESS';
export const GET_COURSES_LIST_FAILURE = 'GET_COURSES_LIST_FAILURE';

export const GET_COURSES_DETAIL_REQUEST = 'GET_COURSES_DETAIL_REQUEST';
export const GET_COURSES_DETAIL_SUCCESS = 'GET_COURSES_DETAIL_SUCCESS';
export const GET_COURSES_DETAIL_FAILURE = 'GET_COURSES_DETAIL_FAILURE';

export const GET_COURSES_DETAIL_LIST_SUCCESS = 'GET_COURSES_DETAIL_LIST_SUCCESS';

export const START_COURSE_REQUEST = 'START_COURSE_REQUEST';
export const START_COURSE_SUCCESS = 'START_COURSE_SUCCESS';
export const START_COURSE_FAILURE = 'START_COURSE_FAILURE';

export const START_MODULE_REQUEST = 'START_MODULE_REQUEST';
export const START_MODULE_SUCCESS = 'START_MODULE_SUCCESS';
export const START_MODULE_FAILURE = 'START_MODULE_FAILURE';

export const RESUME_COURSE = 'RESUME_COURSE';

export const EXIT_MODULE_REQUEST = 'EXIT_MODULE_REQUEST';
export const EXIT_MODULE_SUCCESS = 'EXIT_MODULE_SUCCESS';
export const EXIT_MODULE_FAILURE = 'EXIT_MODULE_FAILURE';

export const CREATE_QUIZ_ATTEMPT_REQUEST = 'CREATE_QUIZ_ATTEMPT_REQUEST';
export const CREATE_QUIZ_ATTEMPT_SUCCESS = 'CREATE_QUIZ_ATTEMPT_SUCCESS';
export const CREATE_QUIZ_ATTEMPT_FAILURE = 'CREATE_QUIZ_ATTEMPT_FAILURE';

export const GET_QUIZ_ATTEMPT_REQUEST = 'GET_QUIZ_ATTEMPT_REQUEST';
export const GET_QUIZ_ATTEMPT_SUCCESS = 'GET_QUIZ_ATTEMPT_SUCCESS';
export const GET_QUIZ_ATTEMPT_FAILURE = 'GET_QUIZ_ATTEMPT_FAILURE';

export const UPDATE_QUIZ_ATTEMPT_REQUEST = 'UPDATE_QUIZ_ATTEMPT_REQUEST';
export const UPDATE_QUIZ_ATTEMPT_SUCCESS = 'UPDATE_QUIZ_ATTEMPT_SUCCESS';
export const UPDATE_QUIZ_ATTEMPT_FAILURE = 'UPDATE_QUIZ_ATTEMPT_FAILURE';

export const REVIEW_QUIZ_REQUEST = 'REVIEW_QUIZ_REQUEST';
export const REVIEW_QUIZ_SUCCESS = 'REVIEW_QUIZ_SUCCESS';
export const REVIEW_QUIZ_FAILURE = 'REVIEW_QUIZ_FAILURE';

export const CLEAR_QUIZ = 'CLEAR_QUIZ';

export const CLEAR_SINGLE_COURSE = 'CLEAR_SINGLE_COURSE';
export const CLEAR_COURSES_LIST = 'CLEAR_COURSES_LIST';

export const GET_COURSE_CERTIFICATION_REQUEST = 'GET_COURSE_CERTIFICATION_REQUEST';
export const GET_COURSE_CERTIFICATION_SUCCESS = 'GET_COURSE_CERTIFICATION_SUCCESS';
export const GET_COURSE_CERTIFICATION_FAILURE = 'GET_COURSE_CERTIFICATION_FAILURE';
export const CLEAR_COURSE_CERTIFICATION = 'CLEAR_COURSE_CERTIFICATION';

export const DELETE_COURSE_REQUEST = 'DELETE_COURSE_REQUEST';
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS';
export const DELETE_COURSE_FAILURE = 'DELETE_COURSE_FAILURE';

export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';

export const GET_USER_SUMMARY_REQUEST = 'GET_USER_SUMMARY_REQUEST';
export const GET_USER_SUMMARY_SUCCESS = 'GET_USER_SUMMARY_SUCCESS';
export const GET_USER_SUMMARY_FAILURE = 'GET_USER_SUMMARY_FAILURE';

export const GET_USER_COURSES_LIST_REQUEST = 'GET_USER_COURSES_LIST_REQUEST';
export const GET_USER_COURSES_LIST_SUCCESS = 'GET_USER_COURSES_LIST_SUCCESS';
export const GET_USER_COURSES_LIST_FAILURE = 'GET_USER_COURSES_LIST_FAILURE';
export const GET_USER_COURSES_DETAIL_REQUEST = 'GET_USER_COURSES_DETAIL_REQUEST';
export const GET_USER_COURSES_DETAIL_SUCCESS = 'GET_USER_COURSES_DETAIL_SUCCESS';
export const GET_USER_COURSES_DETAIL_FAILURE = 'GET_USER_COURSES_DETAIL_FAILURE';

export const PUT_USER_E_SIGNATURE_REQUEST = 'PUT_USER_E_SIGNATURE_REQUEST';
export const PUT_USER_E_SIGNATURE_SUCCESS = 'PUT_USER_E_SIGNATURE_SUCCESS';
export const PUT_USER_E_SIGNATURE_FAILURE = 'PUT_USER_E_SIGNATURE_FAILURE';
export const GET_USER_E_SIGNATURE_REQUEST = 'GET_USER_E_SIGNATURE_REQUEST';
export const GET_USER_E_SIGNATURE_SUCCESS = 'GET_USER_E_SIGNATURE_SUCCESS';
export const GET_USER_E_SIGNATURE_FAILURE = 'GET_USER_E_SIGNATURE_FAILURE';
export const DELETE_USER_E_SIGNATURE_REQUEST = 'DELETE_USER_E_SIGNATURE_REQUEST';
export const DELETE_USER_E_SIGNATURE_SUCCESS = 'DELETE_USER_E_SIGNATURE_SUCCESS';
export const DELETE_USER_E_SIGNATURE_FAILURE = 'DELETE_USER_E_SIGNATURE_FAILURE';

export const GET_ELEARNING_PROFILE_LIST_REQUEST = 'GET_ELEARNING_PROFILE_LIST_REQUEST';
export const GET_ELEARNING_PROFILE_LIST_SUCCESS = 'GET_ELEARNING_PROFILE_LIST_SUCCESS';
export const GET_ELEARNING_PROFILE_LIST_FAILURE = 'GET_ELEARNING_PROFILE_LIST_FAILURE';

export const GET_TRAINEE_PROGRAMS_REQUEST = 'GET_USER_PROGRAMS_REQUEST';
export const GET_TRAINEE_PROGRAMS_SUCCESS = 'GET_USER_PROGRAMS_SUCCESS';
export const GET_TRAINEE_PROGRAMS_FAILURE = 'GET_USER_PROGRAMS_FAILURE';

export const CLEAR_CURRICULUMS_AND_PROGRAMS = 'CLEAR_CURRICULUMS_AND_PROGRAMS';

export const GET_AGENCY_PROGRAMS_REQUEST = 'GET_AGENCY_PROGRAMS_REQUEST';
export const GET_AGENCY_PROGRAMS_SUCCESS = 'GET_AGENCY_PROGRAMS_SUCCESS';
export const GET_AGENCY_PROGRAMS_FAILURE = 'GET_AGENCY_PROGRAMS_FAILURE';

export const GET_CURRICULUM_WITH_COURSES_REQUEST = 'GET_CURRICULUM_WITH_COURSES_REQUEST';
export const GET_CURRICULUM_WITH_COURSES_SUCCESS = 'GET_CURRICULUM_WITH_COURSES_SUCCESS';
export const GET_CURRICULUM_WITH_COURSES_FAILURE = 'GET_CURRICULUM_WITH_COURSES_FAILURE';

export const LOAD_MORE_ELEARNING_PROFILE_LIST_REQUEST = 'LOAD_MORE_ELEARNING_PROFILE_LIST_REQUEST';
export const LOAD_MORE_ELEARNING_PROFILE_LIST_SUCCESS = 'LOAD_MORE_ELEARNING_PROFILE_LIST_SUCCESS';
export const LOAD_MORE_ELEARNING_PROFILE_LIST_FAILURE = 'LOAD_MORE_ELEARNING_PROFILE_LIST_FAILURE';

export const HANDLE_E_LEARNING_CALL_REQUEST = 'HANDLE_E_LEARNING_CALL_REQUEST';
export const HANDLE_E_LEARNING_CALL_SUCCESS = 'HANDLE_E_LEARNING_CALL_SUCCESS';
export const HANDLE_E_LEARNING_CALL_FAILURE = 'HANDLE_E_LEARNING_CALL_FAILURE';

export const GET_CLIENT_SURVEY_REQUEST = 'GET_CLIENT_SURVEY_REQUEST';
export const GET_CLIENT_SURVEY_SUCCESS = 'GET_CLIENT_SURVEY_SUCCESS';
export const GET_CLIENT_SURVEY_FAILURE = 'GET_CLIENT_SURVEY_FAILURE';

export const GET_CAREGIVER_SURVEY_REQUEST = 'GET_CAREGIVER_SURVEY_REQUEST';
export const GET_CAREGIVER_SURVEY_SUCCESS = 'GET_CAREGIVER_SURVEY_SUCCESS';
export const GET_CAREGIVER_SURVEY_FAILURE = 'GET_CAREGIVER_SURVEY_FAILURE';

export const GET_SURVEY_SUMMERY_REQUEST = 'GET_SURVEY_SUMMERY_REQUEST';
export const GET_SURVEY_SUMMERY_SUCCESS = 'GET_SURVEY_SUMMERY_SUCCESS';
export const GET_SURVEY_SUMMERY_FAILURE = 'GET_SURVEY_SUMMERY_FAILURE';

export const GET_SURVEY_DETAILS_REQUEST = 'GET_SURVEY_DETAILS_REQUEST';
export const GET_SURVEY_DETAILS_SUCCESS = 'GET_SURVEY_DETAILS_SUCCESS';
export const GET_SURVEY_DETAILS_FAILURE = 'GET_SURVEY_DETAILS_FAILURE';

export const GET_SIGNING_TEMPLATE_REQUEST = 'GET_SIGNING_TEMPLATE_REQUEST';
export const GET_SIGNING_TEMPLATE_SUCCESS = 'GET_SIGNING_TEMPLATE_SUCCESS';
export const GET_SIGNING_TEMPLATE_FAILURE = 'GET_SIGNING_TEMPLATE_FAILURE';

export const POST_SIGNED_TEMPLATE_REQUEST = 'POST_SIGNED_TEMPLATE_REQUEST';
export const POST_SIGNED_TEMPLATE_SUCCESS = 'POST_SIGNED_TEMPLATE_SUCCESS';
export const POST_SIGNED_TEMPLATE_FAILURE = 'POST_SIGNED_TEMPLATE_FAILURE';

export const GET_APPLICANTS_LIST_REQUEST = 'GET_APPLICANTS_LIST_REQUEST';
export const GET_APPLICANTS_LIST_SUCCESS = 'GET_APPLICANTS_LIST_SUCCESS';
export const GET_APPLICANTS_LIST_FAILURE = 'GET_APPLICANTS_LIST_FAILURE';

export const FILTER_APPLICANT_BY_NAME = 'FILTER_APPLICANT_BY_NAME';
export const FILTER_APPLICANT_BY_ZIP = 'FILTER_APPLICANT_BY_ZIP';

export const APPLICANT_TO_PROCEED_STATUS_REQUEST = 'APPLICANT_TO_PROCEED_STATUS_REQUEST';
export const APPLICANT_TO_PROCEED_STATUS_SUCCESS = 'APPLICANT_TO_PROCEED_STATUS_SUCCESS';
export const APPLICANT_TO_PROCEED_STATUS_FAILURE = 'APPLICANT_TO_PROCEED_STATUS_FAILURE';

export const APPLICANT_RESET_STATUS_REQUEST = 'APPLICANT_RESET_STATUS_REQUEST';
export const APPLICANT_RESET_STATUS_FAILURE = 'APPLICANT_RESET_STATUS_FAILURE';

export const APPLICANT_DECLINE_STATUS_REQUEST = 'APPLICANT_DECLINE_STATUS_REQUEST';
export const APPLICANT_DECLINE_STATUS_SUCCESS = 'APPLICANT_DECLINE_STATUS_SUCCESS';
export const APPLICANT_DECLINE_STATUS_FAILURE = 'APPLICANT_DECLINE_STATUS_FAILURE';
export const GET_APP_TOOL_CONFIG_REQUEST = 'GET_APP_TOOL_CONFIG_REQUEST';
export const GET_APP_TOOL_CONFIG_SUCCESS = 'GET_APP_TOOL_CONFIG_SUCCESS';
export const GET_APP_TOOL_CONFIG_FAILURE = 'GET_APP_TOOL_CONFIG_FAILURE';

export const UPDATE_APP_TOOL_CONFIG_REQUEST = 'UPDATE_APP_TOOL_CONFIG_REQUEST';
export const UPDATE_APP_TOOL_CONFIG_SUCCESS = 'UPDATE_APP_TOOL_CONFIG_SUCCESS';
export const UPDATE_APP_TOOL_CONFIG_FAILURE = 'UPDATE_APP_TOOL_CONFIG_FAILURE';

export const APPLICANT_SAVE_NOTES_REQUEST = 'APPLICANT_SAVE_NOTES_REQUEST';
export const APPLICANT_SAVE_NOTES_SUCCESS = 'APPLICANT_SAVE_NOTES_SUCCESS';
export const APPLICANT_SAVE_NOTES_FAILURE = 'APPLICANT_SAVE_NOTES_FAILURE';

export const INITIATE_VIRTUAL_EVENT_REQUEST = 'INITIATE_VIRTUAL_EVENT_REQUEST';
export const INITIATE_VIRTUAL_EVENT_SUCCESS = 'INITIATE_VIRTUAL_EVENT_SUCCESS';
export const INITIATE_VIRTUAL_EVENT_FAILURE = 'INITIATE_VIRTUAL_EVENT_FAILURE';

export const UPDATE_HR_CAREGIVER_FILTER = 'UPDATE_HR_CAREGIVER_FILTER';
export const UPDATE_HR_CANDIDATES_FILTER = 'UPDATE_HR_CANDIDATES_FILTER';
export const FILTER_BY_PENDING = 'FILTER_BY_PENDING';
export const FILTER_BY_EXPIRED = 'FILTER_BY_EXPIRED';
export const FILTER_BY_COVID_VALIDATION = 'FILTER_BY_COVID_VALIDATION';
export const CLEAN_COMPLIANCE_OPTIONS = 'CLEAN_COMPLIANCE_OPTIONS';
export const CLEAN_VACCINATION_OPTIONS = 'CLEAN_VACCINATION_OPTIONS';
export const CLEAN_ALL_HR_FILTERS = 'CLEAN_ALL_HR_FILTERS';

export const GET_CAREGIVER_IN_SERVICE_SUMMARY_REQUEST = 'GET_CAREGIVER_IN_SERVICE_SUMMARY_REQUEST';
export const GET_CAREGIVER_IN_SERVICE_SUMMARY_SUCCESS = 'GET_CAREGIVER_IN_SERVICE_SUMMARY_SUCCESS';
export const GET_CAREGIVER_IN_SERVICE_SUMMARY_FAILURE = 'GET_CAREGIVER_IN_SERVICE_SUMMARY_FAILURE';

export const UPDATE_ELEARNING_SETTINGS_REQUEST = 'UPDATE_ELEARNING_SETTINGS_REQUEST';
export const UPDATE_ELEARNING_SETTINGS_SUCCESS = 'UPDATE_ELEARNING_SETTINGS_SUCCESS';
export const UPDATE_ELEARNING_SETTINGS_FAILURE = 'UPDATE_ELEARNING_SETTINGS_FAILURE';

export const GET_ELEARNING_SETTINGS_REQUEST = 'GET_ELEARNING_SETTINGS_REQUEST';
export const GET_ELEARNING_SETTINGS_SUCCESS = 'GET_ELEARNING_SETTINGS_SUCCESS';
export const GET_ELEARNING_SETTINGS_FAILURE = 'GET_ELEARNING_SETTINGS_FAILURE';

export const CREATE_ELEARNING_SETTINGS_REQUEST = 'CREATE_ELEARNING_SETTINGS_REQUEST';
export const CREATE_ELEARNING_SETTINGS_SUCCESS = 'CREATE_ELEARNING_SETTINGS_SUCCESS';
export const CREATE_ELEARNING_SETTINGS_FAILURE = 'CREATE_ELEARNING_SETTINGS_FAILURE';

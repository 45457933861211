/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu, Accordion, Form } from 'semantic-ui-react';
import filterActions from '../actions/hrapp/filterActions';
import { uiStateActions } from '../actions';

const FindCandidateForm = ({ uiState, onChange, dispatch }) => {
  const caregiverId =
    uiState.candidateNameOrId && /^\d+$/.test(uiState.candidateNameOrId)
      ? uiState.candidateNameOrId
      : null;
  let name;
  if (!caregiverId) {
    name = uiState.candidateNameOrId || null;
  }

  const params = {
    name,
    caregiverId,
  };

  const searchClick = history => {
    dispatch(dispatch(filterActions.cleanAllHRFilters()));
    const value = params.name || params.caregiverId;
    dispatch(dispatch(filterActions.candidatesFilterChange('nameOrId', 'nameOrId', value)));
    history.history.push('/home/hr/candidates');
  };

  const render = () => history => (
    <Form onSubmit={() => searchClick(history)} className='filterMenu filterWithGo'>
      <Form.Group className='menu-input-with-button' inline>
        <Form.Input
          id='candidateNameOrId'
          placeholder='Name or id'
          name='candidateNameOrId'
          value={uiState.candidateNameOrId}
          onChange={onChange}
        />
        <Form.Button id='hrDashboardSearchCandidateSubmit' circular>
          Go
        </Form.Button>
      </Form.Group>
    </Form>
  );

  return <Route render={render()} />;
};

FindCandidateForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uiState: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};

const FindCaregiverForm = ({ uiState, onChange, dispatch }) => {
  const caregiverId =
    uiState.caregiverNameOrId && /^\d+$/.test(uiState.caregiverNameOrId)
      ? uiState.caregiverNameOrId
      : null;
  let name;
  if (!caregiverId) {
    name = uiState.caregiverNameOrId || null;
  }

  const params = {
    name,
    caregiverId,
  };

  const searchClick = history => {
    dispatch(dispatch(filterActions.cleanAllHRFilters()));
    const value = params.name || params.caregiverId;
    dispatch(dispatch(filterActions.caregiverFilterChange('nameOrId', 'nameOrId', value)));
    history.history.push('/home/hr/caregivers');
  };

  const render = () => history => (
    <Form onSubmit={() => searchClick(history)} className='filterMenu filterWithGo'>
      <Form.Group className='menu-input-with-button' inline>
        <Form.Input
          id='caregiverNameOrId'
          placeholder='Name or id'
          name='caregiverNameOrId'
          value={uiState.caregiverNameOrId}
          onChange={onChange}
        />
        <Form.Button id='hrDashboardSearchCaregiverSubmit' circular>
          Go
        </Form.Button>
      </Form.Group>
    </Form>
  );

  return <Route render={render()} />;
};

FindCaregiverForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uiState: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};

const FilterGroup = ({ uiState, onTitleClick, onValueChange, dispatch }) => (
  <Accordion as={Menu} exclusive={false} widths='sixteen' className='filterSideMenu'>
    <Menu.Item className='text-align-left'>
      <Accordion.Title
        className='side-menu-item'
        active={uiState.findCandidateMenu}
        content='Find a Candidate'
        indexname='findCandidateMenu'
        onClick={onTitleClick}
      />
      <Accordion.Content active={uiState.findCandidateMenu}>
        <FindCandidateForm uiState={uiState} onChange={onValueChange} dispatch={dispatch} />
      </Accordion.Content>
    </Menu.Item>
    <Menu.Item className='text-align-left'>
      <Accordion.Title
        className='side-menu-item'
        active={uiState.findCaregiverMenu}
        content='Find a Caregiver'
        indexname='findCaregiverMenu'
        onClick={onTitleClick}
      />
      <Accordion.Content active={uiState.findCaregiverMenu}>
        <FindCaregiverForm uiState={uiState} onChange={onValueChange} dispatch={dispatch} />
      </Accordion.Content>
    </Menu.Item>
  </Accordion>
);

FilterGroup.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uiState: PropTypes.shape().isRequired,
  onTitleClick: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
};

class HRDashboardMenu extends React.Component {
  componentDidMount() {
    this.props.dispatch(
      uiStateActions.resetInputGroupState('hrDashboardFilter', this.props.dispatch)
    );
  }

  handleFilterTitleClick = (e, titleProps) => {
    this.props.dispatch(
      uiStateActions.uiStateChange('hrDashboardFilter', titleProps.indexname, !titleProps.active)
    );
  };

  render() {
    const { uiState, dispatch } = this.props;
    const items = [
      {
        key: 'filters',
        disabled: true,
        content: (
          <FilterGroup
            dispatch={dispatch}
            uiState={uiState}
            onTitleClick={this.handleFilterTitleClick}
            onValueChange={uiStateActions.inputChangeHandler(
              this.props.dispatch,
              'hrDashboardFilter'
            )}
          />
        ),
      },
    ];

    return <Menu vertical secondary fluid items={items} />;
  }
}

HRDashboardMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uiState: PropTypes.shape().isRequired,
};

export default connect(uiStateActions.mapStateOfGroupToProps('hrDashboardFilter'))(HRDashboardMenu);

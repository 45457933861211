import {
  GET_CAREGIVER_IN_SERVICE_SUMMARY_REQUEST,
  CREATE_CAREGIVER_IN_SERVICE_TRAINING_REQUEST,
  REMOVE_CAREGIVER_IN_SERVICE_TRAINING_REQUEST,
  GET_CAREGIVER_IN_SERVICE_SUMMARY_SUCCESS,
  GET_CAREGIVER_IN_SERVICE_SUMMARY_FAILURE,
  CREATE_CAREGIVER_IN_SERVICE_TRAINING_FAILURE,
  REMOVE_CAREGIVER_IN_SERVICE_TRAINING_FAILURE,
} from '../../constants/ActionTypes';

const initialState = {
  loadingInServiceSummary: false,
  inServiceSummaryError: false,
};

const startState = JSON.parse(JSON.stringify(initialState));

function caregiverInServiceSummary(state = startState, action) {
  switch (action.type) {
    case REMOVE_CAREGIVER_IN_SERVICE_TRAINING_REQUEST:
    case CREATE_CAREGIVER_IN_SERVICE_TRAINING_REQUEST:
    case GET_CAREGIVER_IN_SERVICE_SUMMARY_REQUEST: {
      return { ...state, loadingInServiceSummary: true, inServiceSummaryError: false };
    }
    case REMOVE_CAREGIVER_IN_SERVICE_TRAINING_FAILURE:
    case CREATE_CAREGIVER_IN_SERVICE_TRAINING_FAILURE:
    case GET_CAREGIVER_IN_SERVICE_SUMMARY_FAILURE: {
      return { ...state, loadingInServiceSummary: false, inServiceSummaryError: true };
    }
    case GET_CAREGIVER_IN_SERVICE_SUMMARY_SUCCESS: {
      return {
        loadingInServiceSummary: false,
        inServiceSummaryError: false,
        ...action.results,
      };
    }
    default:
      return state;
  }
}

export default caregiverInServiceSummary;

import {
  ANNOUNCEMENT_AUDIENCE_REQUEST,
  ANNOUNCEMENT_AUDIENCE_SUCCESS,
  ANNOUNCEMENT_AUDIENCE_FAILURE,
  SEND_ANNOUNCEMENT_REQUEST,
  SEND_ANNOUNCEMENT_SUCCESS,
  SEND_ANNOUNCEMENT_FAILURE,
  OPEN_ANNOUCEMENT_TOOL,
  CLOSE_ANNOUCEMENT_TOOL,
} from '../constants/ActionTypes';

const initialState = {
  audienceList: [],
  audienceCount: 0,
  loading: false,
  isOpen: false,
  isConfirmed: false,
};

function announcement(state = initialState, action) {
  const countByList =
    action.results && action.results.audienceList ? action.results.audienceList.length : 0;
  switch (action.type) {
    case ANNOUNCEMENT_AUDIENCE_REQUEST:
      return { ...state, loading: true };
    case ANNOUNCEMENT_AUDIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        audienceCount: action.results.audienceCount ? action.results.audienceCount : countByList,
        audienceList: action.results.audienceList ? action.results.audienceList : [],
        nextPage: action.results.nextPage,
        partialUpdate: action.partialUpdate,
      };
    case ANNOUNCEMENT_AUDIENCE_FAILURE:
      return {
        ...state,
        loading: false,
        audience: [],
        audienceCount: 0,
        partialUpdate: action.partialUpdate,
      };
    case SEND_ANNOUNCEMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEND_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isConfirmed: true,
      };
    case SEND_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case OPEN_ANNOUCEMENT_TOOL:
      return {
        ...initialState,
        isOpen: true,
      };
    case CLOSE_ANNOUCEMENT_TOOL:
      return {
        ...initialState,
        isOpen: false,
      };
    default:
      return state;
  }
}

export default announcement;

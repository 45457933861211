/* eslint-disable react/require-default-props */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import { Modal, Dimmer, Header, Button, Grid, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import CareLoader from './subcomponents/CareLoader';
import { clientActions } from '../actions';
import { avatarStyle, avatarUrl } from '../constants/DomainTypes';
import './Common.css';
import './Clients.css';
import EnvironmentTab from './subcomponents/EnvironmentTab';
import EnvironmentTabEditable from './subcomponents/EnvironmentTabEditable';
import { isFloat } from '../constants/Formats';
import ClientDetailsTab from './ClientDetailsTab';
import { caregiverScoresActions } from '../actions/caregiverScores';
import { history } from '../helpers/history';
import ClientAgencyTab from './ClientAgencyTab';

class ClientDetails extends React.Component {
  constructor() {
    super();
    this.state = { activeTab: null };

    this.closeDetailsClicked = this.closeDetailsClicked.bind(this);
    this.tabClick = this.tabClick.bind(this);
    this.onPreferencesChange = this.onPreferencesChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.viewClientRecords = this.viewClientRecords.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.dispatch(clientActions.getClient(this.props.clientId));
    this.setState(() => ({
      activeTab: this.props.tab ? this.props.tab : 'details',
      wasClientChanged: false,
    }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.clientDetails && !nextProps.clientDetails.loading) {
      if (nextProps.clientDetails.client) {
        this.setState({
          client: nextProps.clientDetails.client,
          original: {
            ...nextProps.clientDetails.client,
            address: { ...nextProps.clientDetails.client.address },
            phones: nextProps.clientDetails.client.phones
              ? nextProps.clientDetails.client.phones.map(p => ({ ...p }))
              : undefined,
            languages: nextProps.clientDetails.client.languages
              ? { ...nextProps.clientDetails.client.languages }
              : undefined,
          },
          isChanged: false,
        });
      } else {
        this.props.onCloseClicked();
      }
    }
  }

  onValueChange(e, input) {
    if (!e) {
      return;
    }

    this.setState(s => {
      const setValue = (path, obj, value) => {
        const key = path.shift();
        if (path.length > 0) {
          setValue(path, obj[key], value);
        } else {
          // eslint-disable-next-line no-param-reassign
          obj[key] = value?.length || Number.isInteger(value) || isFloat(value) ? value : null;
        }
      };

      const newState = { ...s };
      if (input.name === 'languages') {
        newState.client.languages = input.value.map(x => ({ id: x, value: 'Y', primary: false }));
      } else if (input.name === 'phones') {
        const phone = { id: input.phoneId, type: input.phoneType, number: input.value };
        if (newState.client.phones) {
          const p = newState.client.phones.find(x => x.id === phone.id);
          if (p) {
            p.number = phone.number;
          } else {
            newState.client.phones.push(phone);
          }
        } else {
          newState.client.phones = [phone];
        }
      } else if (input.name === 'caregiverExclusion') {
        setValue(
          input.name.split('.'),
          newState.client,
          input.value.map(x => ({ id: x }))
        );
      } else {
        setValue(input.name.split('.'), newState.client, input.value);
      }

      const isChanged = JSON.stringify(newState.client) !== JSON.stringify(newState.original);
      const isValid = this.validateInputs();
      return Object.assign(newState, { isChanged, isValid });
    });
  }

  onPreferencesChange(category, selected) {
    this.setState(s => {
      const newState = { ...s };
      newState.client.preferences.forEach(x => {
        if (x.category === category) {
          Object.assign(x, { value: selected.indexOf(x.id) >= 0 ? 'Y' : 'N' });
        }
      });
      const isValid = this.validateInputs();
      return Object.assign(newState, { isChanged: true, isValid });
    });
  }

  validateInputs() {
    // since each tab defines 1..n form elements, and save operation is common -> just in case
    // validate some fields before sending request to back-end
    const errorFields = [];
    const person = this.state.client;

    if (
      person.firstName == null ||
      (person.firstName != null && person.firstName.trim().length < 2) ||
      person.lastName == null ||
      (person.lastName != null && person.lastName.trim().length < 2)
    ) {
      errorFields.push('names');
    }

    return errorFields.length === 0;
  }

  tabClick(e, input) {
    this.setState({
      activeTab: input.name,
    });
  }

  closeDetailsClicked() {
    this.props.onCloseClicked({ wasClientChanged: this.state.wasClientChanged });
  }

  viewClientRecords() {
    this.props.onCloseClicked();
    history.push(`/home/clients/records/${this.state.client.id}`);
  }

  render() {
    const { loading } = this.props.clientDetails;
    const { dispatch, employees, languages } = this.props;
    const { activeTab, client, isChanged, isValid } = this.state;

    if (loading || !client) {
      return (
        <Dimmer active page>
          <CareLoader loading={loading} invert showText={false} />
        </Dimmer>
      );
    }

    const editable = false; // client.source !== 'sam';
    const editableAttributes = false; // settings.editableAttributes;

    const applyChangesClick = () => {
      this.setState(s => Object.assign(s, { wasClientChanged: true }));
      this.props.dispatch(
        clientActions.updateClient({
          ...this.state.client,
          phones: this.state.client.phones
            ? this.state.client.phones.filter(p => p.number)
            : undefined,
          caregiverExclusion: this.state.client.caregiverExclusion || [],
        })
      );
      this.props.dispatch(caregiverScoresActions.resetScores());
    };

    return (
      <Modal
        id='client-detail-modal'
        closeOnDimmerClick={false}
        open
        closeIcon={<Image className='modal-close-icon' src='/icon_close.svg' />}
        centered
        onClose={this.closeDetailsClicked}
        className='clientDetails scrolling'
      >
        <Modal.Header>
          <Grid centered className='headerGrid'>
            <Grid.Column width={10} textAlign='center' className='headerPic'>
              <div className='clientHeaderImages'>
                <Image inline size='mini' src='/circlesTop.svg' />
                <Image
                  id='client-details-avatar'
                  className='avatar'
                  inline
                  circular
                  size='tiny'
                  style={avatarStyle(false, client.gender, 'large')}
                  src={avatarUrl(false, client.gender)}
                />
              </div>
            </Grid.Column>
            <Grid.Column width={10} textAlign='center'>
              <Header className='clientHeaderName'>{client.clientName}</Header>
              <span className='id-text'>ID: {client.externalId}</span>
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content scrolling>
          <Button.Group fluid size='tiny' className='careTabs'>
            <Button
              id='client-details-details-tab-button'
              className='careTab'
              active={activeTab === 'details'}
              onClick={this.tabClick}
              name='details'
            >
              Client Info
            </Button>
            <Button
              id='client-details-environment-tab-button'
              className='careTab'
              active={activeTab === 'environment'}
              onClick={this.tabClick}
              name='environment'
            >
              Preferences
            </Button>
            <Button
              id='client-details-agency-tab-button'
              className='careTab'
              active={activeTab === 'agency'}
              onClick={this.tabClick}
              name='agency'
            >
              Agency Info
            </Button>
          </Button.Group>
          <Grid centered>
            <Grid.Column id='client-details-column' centered width={10}>
              {activeTab === 'details' && (
                <ClientDetailsTab
                  dispatch={dispatch}
                  client={client}
                  languages={languages}
                  editable={editable}
                  onValueChange={this.onValueChange}
                />
              )}
              {activeTab === 'environment' && editableAttributes && (
                <EnvironmentTabEditable
                  client={client}
                  exclusionsEditable={editable}
                  onValueChange={this.onValueChange}
                  onPreferencesChange={this.onPreferencesChange}
                />
              )}
              {activeTab === 'environment' && !editableAttributes && (
                <EnvironmentTab client={client} />
              )}
              {activeTab === 'agency' && (
                <ClientAgencyTab
                  dispatch={dispatch}
                  editable={editable}
                  client={client}
                  employees={employees}
                  onValueChange={this.onValueChange}
                />
              )}
            </Grid.Column>
          </Grid>
        </Modal.Content>
        {editable && (
          <Modal.Actions>
            <Grid centered className='buttonArea'>
              <Grid.Column width={10} textAlign='center'>
                <Button
                  id='client-details-update-button'
                  disabled={!isChanged || !isValid}
                  onClick={applyChangesClick}
                >
                  Update Client Record
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Actions>
        )}
        <Modal.Actions>
          <Grid centered className='buttonArea'>
            <Grid.Column width={10} textAlign='center'>
              <Button
                id='client-details-view-client-button'
                className='care-green'
                disabled={false}
                onClick={this.viewClientRecords}
              >
                <FormattedMessage id='details.button.viewEdit' />
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
    );
  }
}
ClientDetails.defaultProps = {
  tab: null,
};

ClientDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  clientDetails: PropTypes.shape().isRequired,
  onCloseClicked: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
  employees: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => {
  const { clientDetails } = state;
  return {
    clientDetails,
    settings: state.principal.settings,
    employees: state.employee.employeeList,
    languages: state.principal.languages,
  };
};

export default connect(mapStateToProps)(injectIntl(ClientDetails));

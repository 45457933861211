/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react';
import { Button, Image, Loader, Divider } from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import * as CompHelper from './complianceHelpers';
import * as compTypes from './ComplianceMedicalTypes';
import { caregiverActions } from '../../../actions';
import CGComplianceUIActions from '../../../actions/CGComplianceUIActions';
import { LinkButton } from '../../../styles/common';
import { dateFormat } from '../../../helpers/common';

type Props = {
  blocks: Array<Block>;
  caregiverComplianceUI: any;
  caregiverCompliance: any;
  dispatch: (arg: any) => void;
  caregiver: any;
};

type State = {
  closeEnabled: boolean;
  showVaccinationEditPanel: boolean;
  showMaskEditPanel: boolean;
  showDeclinationEditPanel: boolean;
  editInfo: boolean;
  draftState: DraftState;
};

type DraftState = {
  maskDate?: compTypes.DraftStateField;
  maskReason?: compTypes.DraftStateField;
  vaccinationDate?: compTypes.DraftStateField;
  vaccineSite?: compTypes.DraftStateField;
  siteName?: compTypes.DraftStateField;
  declinationDate?: compTypes.DraftStateField;
  declinationForm?: compTypes.DraftStateField;
  declinationReason?: compTypes.DraftStateField;
};
interface BlockField extends compTypes.BlockField {
  filesDownloaded?: boolean;
  downloadLinks?: FormLinks;
}

type FormLinks = {
  [key: string]: string;
};

interface Block extends compTypes.Blocks {
  name: 'Influenza Vaccine' | 'Influenza Mask Collection' | 'Influenza Declination';
  validTo?: string;
  outOffDuration?: boolean;
  fields: BlockField[];
}

interface InfluenzaBlocks {
  [key: string]: Block;
  influenzaVaccine: Block;
  influenzaMask: Block;
}

interface IDeclinationProp {
  expired: boolean;
}

const DeclinationInfoPanel = styled.div<IDeclinationProp>`
  display: grid;
  margin: 0 0 0 25px;
  padding: 10px;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto;
  column-gap: 1rem;
  grid-template-areas:
    'title . . show'
    'date reason expiration show';
  & h3 {
    font-size: 12px;
    color: #4a4a4a;
    grid-area: title;
  }
  & p {
    font-size: 11px;
    color: #9b9b9b;
  }
  & span {
    font-size: 11px;
    color: #4a4a4a;
  }
  & .influenza-declination-date {
    grid-area: date;
  }
  & .influenza-declination-reason {
    grid-area: reason;
  }
  & .influenza-declination-expires {
    grid-area: expiration;
  }
  & .influenza-declination-show {
    grid-area: show;
    align-self: center;
  }
`;

const RemoveEditContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 1rem;
`;

class ComplianceInfluenzaVaccine extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showVaccinationEditPanel: false,
      showMaskEditPanel: false,
      showDeclinationEditPanel: false,
      editInfo: false,
      closeEnabled: false,
      draftState: {
        maskDate: undefined,
        vaccinationDate: undefined,
        vaccineSite: undefined,
        siteName: undefined,
      },
    };
  }

  public componentDidMount = () => {
    this.setFieldsToState();
  };

  private readonly setFieldsToState = (): void => {
    const { blocks } = this.props;
    const [influenzaVaccine] = blocks.filter((b: Block) => b.name === 'Influenza Vaccine');
    const [influenzaMask] = blocks.filter((b: Block) => b.name === 'Influenza Mask Collection');
    const [influenzaDeclination] = blocks.filter((b: Block) => b.name === 'Influenza Declination');

    const [maskDate] = influenzaMask.fields.filter((f: BlockField) => f.type === 'date');
    const [maskReason] = influenzaMask.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === 'Reason'
    );

    const [vaccinationDate] = influenzaVaccine.fields.filter((f: BlockField) => f.type === 'date');
    const [vaccineSite] = influenzaVaccine.fields.filter(
      (f: BlockField) => f.name === 'vaccineSite'
    );
    const [siteName] = influenzaVaccine.fields.filter((f: BlockField) => f.name === 'siteName');

    const [declinationDate] = influenzaDeclination
      ? influenzaDeclination.fields.filter((f: BlockField) => f.name === 'Completion')
      : [];
    const [declinationForm] = influenzaDeclination
      ? influenzaDeclination.fields.filter((f: BlockField) => f.name === 'Uploaded documents')
      : [];
    const [declinationReason] = influenzaDeclination
      ? influenzaDeclination.fields.filter((f: BlockField) => f.name === 'Reason')
      : [];

    const declinationDraft = influenzaDeclination
      ? {
          declinationDate: {
            value: declinationDate.value ? moment(declinationDate.value) : '',
            id: declinationDate.id,
          },
          declinationForm: {
            value: declinationForm.value || '',
            id: declinationForm.id,
          },
          declinationReason: {
            value: declinationReason.value || null,
            id: declinationReason.id,
          },
        }
      : {
          declinationDate: undefined,
          declinationForm: undefined,
          declinationReason: undefined,
        };

    this.setState(s => ({
      ...s,
      draftState: {
        ...s.draftState,
        ...declinationDraft,
        maskDate: {
          value: maskDate.value ? moment(maskDate.value) : '',
          id: maskDate.id,
        },
        vaccinationDate: {
          value: vaccinationDate.value ? moment(vaccinationDate.value) : '',
          id: vaccinationDate.id,
        },
        maskReason: maskReason
          ? {
              value: maskReason.value,
              id: maskReason.id,
            }
          : undefined,
        vaccineSite: vaccineSite
          ? {
              value: vaccineSite.value,
              id: vaccineSite.id,
            }
          : undefined,
        siteName: siteName
          ? {
              value: siteName.value,
              id: siteName.id,
            }
          : undefined,
      },
    }));
  };

  private readonly handleEditInfo = () => {
    this.setState({ editInfo: true });
  };

  private readonly addVaccinationCompletion = (): void => {
    this.setState(s => ({
      ...s,
      showVaccinationEditPanel: !s.showVaccinationEditPanel,
      closeEnabled: true,
    }));
  };

  private readonly addDeclinationCompletion = (): void => {
    this.setState(s => ({
      ...s,
      showDeclinationEditPanel: !s.showDeclinationEditPanel,
      closeEnabled: true,
    }));
  };

  private readonly addMaskCompletion = (): void => {
    this.setState(s => ({
      ...s,
      showMaskEditPanel: !s.showMaskEditPanel,
      closeEnabled: true,
    }));
  };

  private readonly onClose = (): void => {
    const { dispatch } = this.props;
    this.setFieldsToState();
    this.setState(
      s => ({
        ...s,
        closeEnabled: false,
        showMaskEditPanel: false,
        showVaccinationEditPanel: false,
        showDeclinationEditPanel: false,
        editInfo: false,
      }),
      () => dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: [] }))
    );
  };

  private readonly onFieldChanged = (field: BlockField, value: any) => {
    const { caregiverComplianceUI, dispatch } = this.props;
    const { dirtyMedicalBlocks } = caregiverComplianceUI;

    const changedValue = value;

    let theField = dirtyMedicalBlocks.find((f: BlockField) => f.id === field.id);

    if (!theField) {
      theField = JSON.parse(JSON.stringify(field));
      dirtyMedicalBlocks.push(theField);
    }
    theField.value = changedValue;

    if (field.type === 'date') {
      this.onDateChange(value, field.id);
    }

    if (field.type === 'text') {
      this.onTextChange(value, field.id);
    }

    dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks }));
  };

  private readonly onDateChange = (date: any, selected: number): void => {
    const { draftState } = this.state;
    let valueToSet: string;
    let selectedId: number;
    /* eslint-disable-next-line no-restricted-syntax */
    for (const [key, value] of Object.entries(draftState)) {
      if (value?.id === selected) {
        valueToSet = key;
        selectedId = value.id;
      }
    }

    this.setState(s => ({
      ...s,
      draftState: {
        ...s.draftState,
        [valueToSet]: {
          value: date && moment(date).isBefore(moment()) ? moment(date) : '',
          id: selectedId,
        },
      },
    }));
  };

  private readonly onTextChange = (value: any, selected: number): void => {
    const { draftState } = this.state;
    let valueToSet: string;
    let selectedId: number;
    /* eslint-disable-next-line no-restricted-syntax */
    for (const [key, val] of Object.entries(draftState)) {
      if (val?.id === selected) {
        valueToSet = key;
        selectedId = val.id;
      }
    }

    this.setState(s => ({
      ...s,
      draftState: {
        ...s.draftState,
        [valueToSet]: {
          value: value || '',
          id: selectedId,
        },
      },
    }));
  };

  private readonly uploadDocument = (e: any, fieldId: number) => {
    const { caregiver, dispatch } = this.props;
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      dispatch(caregiverActions.uploadComplianceDocument(fieldId, file, caregiver.id));
    }
    e.target.value = '';
  };

  private readonly documentRemoved = (fieldId: number, filename: string) => {
    const { caregiver, dispatch } = this.props;
    dispatch(caregiverActions.removeComplianceDocument(caregiver.id, fieldId, filename));
  };

  private readonly renderDocumentField = (documentField: BlockField) => {
    const { caregiverCompliance } = this.props;
    const { uploadingDocument } = caregiverCompliance;
    let addNewClass = 'complianceDocumentItem';
    if (uploadingDocument) addNewClass += ' disabled';

    const previewDocument = (link: string, filename: string) => {
      if (filename.endsWith('.pdf')) {
        return (
          <a href={link}>
            <div style={{ textAlign: 'center' }}>.pdf</div>
          </a>
        );
      }
      return <Image width='100px' height='100px' src={link} />;
    };
    return (
      <div className='complianceDocumentsContainer' key={documentField.id}>
        {!documentField.filesDownloaded && documentField.value && !!documentField.value.length && (
          <div className='complianceDocumentItem'>
            <div style={{ height: '100px' }}>
              <Loader active inline />
            </div>
          </div>
        )}
        {documentField.filesDownloaded &&
        documentField.downloadLinks &&
        !!documentField.value?.length ? (
          documentField.value.map((file: any) => {
            return (
              <div className='complianceDocumentItem' key={file}>
                {documentField?.downloadLinks &&
                  previewDocument(documentField?.downloadLinks[file.filename], file.filename)}
                <Button
                  circular
                  icon='delete'
                  className='documentRemoveButton'
                  onClick={() => this.documentRemoved(documentField.id, file.filename)}
                />
              </div>
            );
          })
        ) : (
          <div className={addNewClass}>
            {uploadingDocument ? (
              <>
                {uploadingDocument === documentField.id ? (
                  <div style={{ height: '100px' }}>
                    <Loader active inline />
                  </div>
                ) : (
                  <p style={{ color: '#ccc' }}>+</p>
                )}
              </>
            ) : (
              <label htmlFor={`fileinput${documentField.id}`} style={{ padding: '36px' }}>
                <input
                  type='file'
                  accept='.jpg,.png,.jpeg,.gif,.pdf'
                  id={`fileinput${documentField.id}`}
                  className='fileinput'
                  style={{ display: 'none' }}
                  onChange={e => this.uploadDocument(e, documentField.id)}
                />
                +
              </label>
            )}
          </div>
        )}
      </div>
    );
  };

  private readonly updateMedicalBlock = () => {
    const { caregiver, blocks, caregiverComplianceUI, dispatch } = this.props;
    const { dirtyMedicalBlocks } = caregiverComplianceUI;

    if (Object.keys(dirtyMedicalBlocks).length) {
      const dirtyFieldsToSave: Array<BlockField> = [];
      dirtyMedicalBlocks.forEach((s: BlockField) => {
        const [block] = blocks.filter((a: Block) =>
          a.fields.find((f: BlockField) => f.id === s.id)
        );
        const b = block?.fields.find((a: BlockField) => a.id === s.id);
        if (b && ((b.value && b.value !== s.value) || (!b.value && s.value))) {
          dirtyFieldsToSave.push(s);
        }
      });

      if (dirtyFieldsToSave.length) {
        dispatch(
          caregiverActions.setCaregiverComplianceBlockFields(caregiver.id, dirtyFieldsToSave)
        );
        dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: [] }));
      }
    }

    this.setState(prevState => ({
      ...prevState,
      showMaskEditPanel: false,
      showVaccinationEditPanel: false,
      showDeclinationEditPanel: false,
      closeEnabled: false,
      editInfo: false,
    }));
  };

  private readonly onRemove = () => {
    const dirtyFieldToClear: Array<BlockField> = [];
    const { caregiver, dispatch, blocks } = this.props;
    blocks.forEach((block: Block) =>
      block.fields.forEach(f => {
        if (f.type === 'document' && f.value?.length) {
          f.value.forEach((v: any) => this.documentRemoved(f.id, v.filename));
        } else {
          dirtyFieldToClear.push({
            id: f.id,
            name: f.name,
            type: f.type,
            fixed: 1,
            value: null,
            isDirty: true,
            downloadLinks: {},
          });
        }
      })
    );
    dispatch(caregiverActions.setCaregiverComplianceBlockFields(caregiver.id, dirtyFieldToClear));

    this.setState(
      s => ({
        ...s,
        draftState: {
          ...s.draftState,
          maskDate: {
            ...s.draftState.maskDate,
            value: '',
          },
          vaccinationDate: {
            ...s.draftState.vaccinationDate,
            value: '',
          },
          declinationDate: s.draftState.declinationDate
            ? {
                ...s.draftState.declinationDate,
                value: null,
              }
            : undefined,
          declinationReason: s.draftState.declinationReason
            ? {
                ...s.draftState.declinationReason,
                value: null,
              }
            : undefined,
          maskReason: s.draftState.maskReason
            ? {
                ...s.draftState.maskReason,
                value: null,
              }
            : undefined,
          vaccineSite: s.draftState.vaccineSite
            ? {
                ...s.draftState.vaccineSite,
                value: null,
              }
            : undefined,
          siteName: s.draftState.siteName
            ? {
                ...s.draftState.siteName,
                value: '',
              }
            : undefined,
        },
        closeEnabled: false,
        editInfo: false,
      }),
      () => dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: [] }))
    );
  };

  private readonly completedBy = (vaccination: Block, declination?: Block) => {
    const { cgDependBlocks, fields, hasExpired } = vaccination;
    const completion = fields.find(f => f.name === 'Completion');
    if (declination && moment(declination?.expirationDate).isAfter(vaccination.expirationDate)) {
      return 'declination';
    }
    if (
      completion?.value &&
      !hasExpired &&
      cgDependBlocks?.length &&
      cgDependBlocks.find(d => d.validResult && d.hasExpired)
    ) {
      return 'vaccination';
    }
    if (
      cgDependBlocks?.length &&
      cgDependBlocks.find(d => d.validResult) &&
      (!completion?.value || (completion?.value && hasExpired))
    ) {
      return 'declination';
    }
    return 'vaccination';
  };

  public render(): JSX.Element {
    const { blocks, caregiverComplianceUI } = this.props;
    const {
      showVaccinationEditPanel,
      showMaskEditPanel,
      showDeclinationEditPanel,
      closeEnabled,
      draftState,
      editInfo,
    } = this.state;

    const {
      maskDate,
      vaccinationDate,
      vaccineSite,
      siteName,
      declinationDate,
      declinationReason,
      maskReason,
    } = draftState;

    const { dirtyMedicalBlocks } = caregiverComplianceUI;

    let formLink;

    const [influenzaVaccine] = blocks.filter((b: Block) => b.name === 'Influenza Vaccine');
    const [influenzaMask] = blocks.filter((b: Block) => b.name === 'Influenza Mask Collection');
    const [influenzaDeclination] = blocks.filter((b: Block) => b.name === 'Influenza Declination');

    const [vaccinationDateField] = influenzaVaccine.fields.filter(
      (f: BlockField) => f.type === 'date'
    );
    const [vaccineSiteField] = influenzaVaccine.fields.filter(
      (f: BlockField) => f.name === 'vaccineSite'
    );
    const [siteNameField] = influenzaVaccine.fields.filter(
      (f: BlockField) => f.name === 'siteName'
    );

    const [maskDateField] = influenzaMask.fields.filter((f: BlockField) => f.type === 'date');
    const [maskReasonField] = influenzaMask.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === 'Reason'
    );

    const [declinationDateField] = influenzaDeclination
      ? influenzaDeclination.fields.filter((f: BlockField) => f.name === 'Completion')
      : [];
    const [declinationFormField] = influenzaDeclination
      ? influenzaDeclination.fields.filter((f: BlockField) => f.name === 'Uploaded documents')
      : [];
    const [declinationReasonField] = influenzaDeclination
      ? influenzaDeclination.fields.filter((f: BlockField) => f.name === 'Reason')
      : [];

    const InfluenzaBlocks: InfluenzaBlocks = {
      influenzaVaccine,
      influenzaMask,
    };

    const vaccinationPayload = influenzaVaccine?.payload
      ? JSON.parse(influenzaVaccine?.payload)
      : { startMonth: 8 };

    const maskCompleted = influenzaMask?.completed;
    const vaccinationDateCompleted = influenzaVaccine?.completed;
    const influenzaExpires = influenzaVaccine?.hasExpired || influenzaDeclination?.hasExpired;
    const vaccinationDateExpired = influenzaVaccine?.expirationDate;
    const declinationDateExpired = influenzaDeclination?.expirationDate;

    const influenzaCompletedBy =
      vaccinationDateCompleted || influenzaExpires
        ? this.completedBy(influenzaVaccine, influenzaDeclination)
        : '';

    const showCompletionButtons =
      !(vaccinationDate?.value || declinationDate?.value || maskDate?.value) || editInfo;

    const showDeclineInfoPanel =
      !!declinationDate?.value && influenzaCompletedBy === 'declination' && !closeEnabled;
    const showVaccinationInfoPanel =
      !!vaccinationDate?.value && influenzaCompletedBy === 'vaccination' && !closeEnabled;

    const showMaskInfoPanel =
      maskDate?.value &&
      !showVaccinationEditPanel &&
      !showMaskEditPanel &&
      influenzaCompletedBy !== 'vaccination' &&
      !closeEnabled;

    if (showDeclineInfoPanel) {
      if (Object.getOwnPropertyNames(declinationFormField?.downloadLinks || {}).length) {
        formLink = declinationFormField?.downloadLinks
          ? declinationFormField?.downloadLinks[declinationFormField?.value[0]?.filename]
          : null;
      }
    }

    const declinationExpired = influenzaDeclination?.hasExpired;

    const showRemoveEdit =
      (vaccinationDate?.value || declinationDate?.value || maskDate?.value) && !closeEnabled;

    return (
      <div className='infoPanelMedicalBlock'>
        <div className='compliances-tab-header'>
          <img src={`/compliance/${InfluenzaBlocks.influenzaVaccine.icon}`} alt='compliance-icon' />
          <p
            className={vaccinationDateCompleted || maskCompleted ? 'blackTitle' : 'redTitle'}
            style={{ margin: '0' }}
          >
            Influenza Vaccine
          </p>
          {!(vaccinationDateCompleted && maskCompleted) ? (
            <img
              style={{ marginLeft: '10px' }}
              src='/compliance/attention.svg'
              alt='attention-icon'
            />
          ) : (
            ''
          )}
        </div>
        <div>
          <p className='compliance-medium-gray'>
            Influenza vaccine is a <b className='compliance-charcoal-text-color'>Seasonal</b>{' '}
            requirement starting on {moment(vaccinationPayload.startMonth, 'MM').format('MMMM')}.
            All caregivers are required to have received the vaccine{' '}
            {influenzaDeclination ? ',' : 'or'} pick up an influenza mask from their agency on a
            monthly basis {influenzaDeclination ? 'or provide a declination form' : ''}.
          </p>
        </div>
        {showDeclineInfoPanel && (
          <DeclinationInfoPanel expired={declinationExpired}>
            <h3>Declination</h3>
            <div className='influenza-declination-date'>
              <p>Declination Date</p>
              <span>
                {declinationDate?.value && moment(declinationDate?.value).format(dateFormat)}
              </span>
            </div>
            <div className='influenza-declination-reason'>
              <p>Declination Reason</p>
              <span>{declinationReason?.value}</span>
            </div>
            <div className='influenza-declination-expires'>
              <p>Expires</p>
              <span>
                <CompHelper.DateText date={influenzaDeclination?.expirationDate} />
              </span>
            </div>
            <div className='influenza-declination-show'>
              {formLink ? (
                <a
                  className='compliance-form-link'
                  href={formLink}
                  style={{ fontSize: '11px' }}
                  target='_blank'
                  rel='noreferrer'
                >
                  See document
                </a>
              ) : (
                <LinkButton
                  style={{ display: 'inline-block', fontSize: '11px', padding: 0, margin: 0 }}
                  onClick={this.addDeclinationCompletion}
                >
                  Add document
                </LinkButton>
              )}
            </div>
          </DeclinationInfoPanel>
        )}
        {showMaskInfoPanel && (
          <>
            {showDeclineInfoPanel && <Divider />}
            <div className='compliance-influenza-mask-date-container'>
              <h3
                style={{
                  gridColumn: '1/ span 3',
                  gridRow: '1/2',
                  color: `${maskCompleted ? '#4a4a4a' : '#F16778'}`,
                }}
              >
                Influenza Mask Collection
              </h3>

              <p style={{ gridColumn: '1/2', gridRow: '2/3' }}>Last Completed</p>
              <span style={{ gridColumn: '1/2', gridRow: '3/4' }}>
                <CompHelper.DateText date={maskDate?.value} />
              </span>

              {maskReasonField && (
                <>
                  <p style={{ gridColumn: '2/3', gridRow: '2/3' }}>Mask Pick Up Reason:</p>
                  <span className='medicalDateInput' style={{ gridColumn: '2/3', gridRow: '3/4' }}>
                    {maskReasonField.value || 'N/A'}
                  </span>
                </>
              )}

              <p
                style={{
                  gridColumn: '3/4',
                  gridRow: '2/3',
                  color: `${maskCompleted ? '#9b9b9b' : '#F16778'}`,
                }}
              >
                Expires
              </p>
              <span
                style={{
                  gridColumn: '3/4',
                  gridRow: '3/4',
                  color: `${maskCompleted ? '#4a4a4a' : '#F16778'}`,
                }}
              >
                <CompHelper.DateText date={influenzaMask?.expirationDate} />
              </span>
            </div>
          </>
        )}
        {showVaccinationInfoPanel && (
          <>
            {(showDeclineInfoPanel || showMaskInfoPanel) && <Divider />}
            <div className='compliance-influenza-mask-date-container'>
              <h3
                style={{
                  gridColumn: '1/ span 3',
                  gridRow: '1/2',
                  color: `${vaccinationDateCompleted ? '#4a4a4a' : '#F16778'}`,
                }}
              >
                Vaccination
              </h3>

              <p style={{ gridColumn: '1/2', gridRow: '2/3' }}>Vaccination Date</p>
              <span style={{ gridColumn: '1/2', gridRow: '3/4' }}>
                <CompHelper.DateText date={vaccinationDate?.value} />
              </span>

              <p
                style={{
                  gridColumn: '2/3',
                  gridRow: '2/3',
                  color: `${maskCompleted ? '#9b9b9b' : '#F16778'}`,
                }}
              >
                Expires
              </p>
              <span
                style={{
                  gridColumn: '2/3',
                  gridRow: '3/4',
                  color: `${vaccinationDateCompleted ? '#4a4a4a' : '#F16778'}`,
                }}
              >
                <CompHelper.DateText date={influenzaVaccine?.expirationDate} />
              </span>
            </div>
          </>
        )}
        {!vaccinationDateCompleted && influenzaExpires && !influenzaVaccine.outOffDuration && (
          <div className='compliance-complete-container' style={{ paddingBottom: '15px' }}>
            <p className='block-result-text' style={{ color: '#F16778' }}>
              {influenzaCompletedBy === 'vaccination' ? (
                <>
                  VACCINATION EXPIRED ON: <CompHelper.DateText date={vaccinationDateExpired} />
                </>
              ) : (
                <>
                  EXPIRED ON: <CompHelper.DateText date={declinationDateExpired} />
                </>
              )}
            </p>
          </div>
        )}
        {!showVaccinationEditPanel && !showMaskEditPanel && !showDeclinationEditPanel && (
          <div>
            {showCompletionButtons && (
              <div className='compliance-buttons-container'>
                <CompHelper.SimpleButton onClick={this.addMaskCompletion}>
                  Add Mask Pickup Date
                </CompHelper.SimpleButton>
                <CompHelper.SimpleButton onClick={this.addVaccinationCompletion}>
                  Add Vaccination Date
                </CompHelper.SimpleButton>
                {influenzaDeclination && (
                  <CompHelper.SimpleButton onClick={this.addDeclinationCompletion}>
                    Add Declination
                  </CompHelper.SimpleButton>
                )}
              </div>
            )}
            {vaccinationDateCompleted && !influenzaVaccine.outOffDuration && (
              <>
                <div className='compliance-complete-container'>
                  <p className='block-result-text' style={{ color: '#69DB8F' }}>
                    <Image
                      src='/compliance/check.svg'
                      style={{ paddingRight: '5px', display: 'inline' }}
                    />
                    COMPLETED:{' '}
                    <CompHelper.DateText
                      date={
                        influenzaCompletedBy === 'vaccination'
                          ? vaccinationDate?.value
                          : declinationDate?.value
                      }
                    />
                  </p>
                </div>
                {vaccineSite && (
                  <>
                    <div className='compliance-buttons-container' style={{ marginTop: '10px' }}>
                      <p className='block-result-text'>Clinic Site:</p>
                      <p
                        className='compliance-block-completion-field-text'
                        style={{ marginLeft: '34px' }}
                      >
                        <CompHelper.TextField
                          field={vaccineSiteField}
                          state={vaccineSite}
                          disabled
                          handleChange={() => null}
                        />
                      </p>
                    </div>
                    <div className='compliance-buttons-container' style={{ marginTop: '10px' }}>
                      <p className='block-result-text'>Location Name:</p>
                      <p
                        className='compliance-block-completion-field-text'
                        style={{ marginLeft: '34px' }}
                      >
                        <CompHelper.TextField
                          field={siteNameField}
                          state={siteName}
                          disabled
                          handleChange={() => null}
                        />
                      </p>
                    </div>
                  </>
                )}
              </>
            )}

            {influenzaVaccine.outOffDuration && (
              <div className='compliance-buttons-container' style={{ marginTop: '10px' }}>
                <p className='block-result-text' style={{ color: '#69DB8F' }}>
                  Out Of Influenza Season
                </p>
                <p
                  className='compliance-block-completion-field-text'
                  style={{ marginLeft: '34px' }}
                >
                  Last Vaccination Taken: <CompHelper.DateText date={vaccinationDate?.value} />
                </p>
              </div>
            )}
          </div>
        )}
        {showRemoveEdit && (
          <RemoveEditContainer>
            <CompHelper.RemoveButton onRemove={this.onRemove} />
            <CompHelper.EditButton onEdit={this.handleEditInfo} />
          </RemoveEditContainer>
        )}
        {closeEnabled && (
          <Image className='tinyCloseButton' src='/close-icon.svg' onClick={this.onClose} />
        )}
        {showVaccinationEditPanel && (
          <div className='hep-b-test-series-one-container compliance-add-completion-panel'>
            <h3 className='compliance-add-completion-panel-header'>ENTER VACCINATION DATE</h3>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                Enter Vaccination Date:
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-end', marginRight: '20px' }}
              >
                <CompHelper.ComplianceDatePicker
                  field={vaccinationDateField}
                  state={vaccinationDate}
                  disabled={false}
                  onChange={this.onFieldChanged}
                />
              </div>
            </div>
            {vaccineSite && (
              <>
                <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
                  <p
                    className='compliance-block-completion-field-text'
                    style={{ justifySelf: 'flex-start' }}
                  >
                    Select Clinic Site:
                  </p>
                  <div
                    className='medicalDateInput'
                    style={{ justifySelf: 'flex-end', marginRight: '20px' }}
                  >
                    <CompHelper.DropdownFields
                      field={vaccineSiteField}
                      selected={vaccineSite}
                      options={CompHelper.siteOptions}
                      disabled={false}
                      handleChange={this.onFieldChanged}
                    />
                  </div>
                </div>
                <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
                  <p
                    className='compliance-block-completion-field-text'
                    style={{ justifySelf: 'flex-start' }}
                  >
                    Add Location Name:
                  </p>
                  <div
                    className='medicalDateInput'
                    style={{ justifySelf: 'flex-end', marginRight: '20px' }}
                  >
                    <CompHelper.TextField
                      field={siteNameField}
                      state={siteName}
                      disabled={false}
                      handleChange={this.onFieldChanged}
                    />
                  </div>
                </div>
              </>
            )}
            <CompHelper.UpdateButton
              disabled={
                vaccineSite
                  ? !(vaccineSite?.value && dirtyMedicalBlocks.length)
                  : !dirtyMedicalBlocks.length
              }
              update={this.updateMedicalBlock}
            />
          </div>
        )}
        {showDeclinationEditPanel && (
          <div className='hep-b-test-series-one-container compliance-add-completion-panel'>
            <h3 className='compliance-add-completion-panel-header'>ADD DECLINATION </h3>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                Enter Submission Date
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-end', marginRight: '20px' }}
              >
                <CompHelper.ComplianceDatePicker
                  field={declinationDateField}
                  state={declinationDate}
                  disabled={false}
                  onChange={this.onFieldChanged}
                />
              </div>
            </div>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                Declination Reason (optional)
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-end', marginRight: '20px' }}
              >
                <CompHelper.DropdownFields
                  field={declinationReasonField}
                  selected={declinationReason}
                  options={CompHelper.influenzaDeclinationOptions}
                  disabled={false}
                  handleChange={this.onFieldChanged}
                />
              </div>
            </div>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <div style={{ alignSelf: 'flex-start', marginTop: '1rem' }}>
                <p className='compliance-block-completion-field-text'>
                  Upload Completed Declination Form (optional)
                </p>
                <p className='compliance-block-completion-field-description'>
                  The submitted form will be stored on records associated with this caregiver.
                </p>
              </div>
              <div style={{ justifySelf: 'flex-end' }}>
                {this.renderDocumentField(declinationFormField)}
              </div>
            </div>

            <CompHelper.UpdateButton
              disabled={!(declinationDate?.value && dirtyMedicalBlocks.length)}
              update={this.updateMedicalBlock}
            />
          </div>
        )}
        {showMaskEditPanel && (
          <div className='hep-b-test-series-one-container compliance-add-completion-panel'>
            <h3 className='compliance-add-completion-panel-header'>Enter mask pick up Date</h3>
            <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
              <p
                className='compliance-block-completion-field-text'
                style={{ justifySelf: 'flex-start' }}
              >
                Add the date this caregiver picked up an influenza mask from your agency
              </p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-end', marginRight: '20px' }}
              >
                <CompHelper.ComplianceDatePicker
                  field={maskDateField}
                  state={maskDate}
                  disabled={false}
                  onChange={this.onFieldChanged}
                />
              </div>
            </div>
            {maskReason && (
              <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
                <p
                  className='compliance-block-completion-field-text'
                  style={{ justifySelf: 'flex-start' }}
                >
                  Enter Mask Pick Up Reason:
                </p>
                <div
                  className='medicalDateInput'
                  style={{ justifySelf: 'flex-end', marginRight: '20px' }}
                >
                  <CompHelper.DropdownFields
                    field={maskReasonField}
                    selected={maskReason}
                    options={CompHelper.influenzaDeclinationOptions}
                    disabled={false}
                    handleChange={this.onFieldChanged}
                  />
                </div>
              </div>
            )}

            <CompHelper.UpdateButton
              disabled={!(maskDate?.value && dirtyMedicalBlocks.length)}
              update={this.updateMedicalBlock}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { caregiverDetails, caregiverCompliance, caregiverComplianceUI } = state;
  return {
    caregiver: caregiverDetails.caregiver,
    caregiverCompliance,
    caregiverComplianceUI,
  };
};

export default connect(mapStateToProps)(ComplianceInfluenzaVaccine);

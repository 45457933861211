/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Grid, Header, Form, Table, Popup, Icon, Image } from 'semantic-ui-react';
import CareRoundButton from './subcomponents/CareRoundButton';
import ClientsMenu from './ClientsMenu';
import ClientDetails from './ClientDetails';
import ClientEditor from './ClientEditor';
import NoMatch from './subcomponents/NoMatch';
import InitialState from './subcomponents/InitialState';
import PageSizer from './subcomponents/PageSizer';
import PageMeter from './subcomponents/PageMeter';
import LoadMore from './subcomponents/LoadMore';
import { clientActions, uiStateActions, schedulerActions } from '../actions';
import { getClientStatusName, avatarStyle, avatarUrl } from '../constants/DomainTypes';
import { isValidUsZipCode } from '../constants/Formats';
import './Common.css';
import { analytics, Events } from '../helpers/analytics';
import { history } from '../helpers';

const preferenceActive = (preferences, name) => {
  const preference = preferences && preferences.find(p => p.name === name);
  return preference ? preference.value === 'Y' : false;
};

const body = handleClientShiftsClick => (x, i) => {
  const shiftCountItem = client => {
    const handleClick = () => {
      handleClientShiftsClick(client.id, client.shifts);
    };
    const shiftCount = client.shifts.length;
    const element =
      shiftCount > 0 ? (
        <span
          id='clients-shift-count'
          key={`sc_${client.id}`}
          className='overlayLink'
          role='presentation'
          onClick={handleClick}
        >
          {shiftCount}
        </span>
      ) : (
        <span>{shiftCount}</span>
      );

    return {
      key: 'schedules',
      textAlign: 'center',
      content: element,
    };
  };

  const onNameClick = () => {
    history.push(`/home/clients/records/${x.id}`);
  };

  return {
    key: x.id || `row-${i}`,
    cells: [
      {
        key: 'clientName',
        content: (
          <div className='overlayDirect'>
            <div key={x.id} className='name'>
              <Image
                avatar
                centered
                circular
                size='mini'
                floated='left'
                style={avatarStyle(false, x.gender)}
                src={avatarUrl(false, x.gender)}
              />
              <div className='nameBlock'>
                <div
                  id='clients-client-name'
                  role='presentation'
                  className='overlayLink'
                  onClick={onNameClick}
                >
                  {`${x.firstName ? x.firstName : ''} ${x.lastName}`}
                </div>
              </div>
            </div>
          </div>
        ),
      },
      { key: 'address', content: x.address.formatted || '-' },
      shiftCountItem(x),
      { key: 'status', content: getClientStatusName(x.status), textAlign: 'center' },
    ],
  };
};

const headers = (shift, column, direction, onHeaderClick) => {
  const shiftCountInfo = (
    <div>
      Shift #
      <Popup
        trigger={<Icon name='question circle outline' className='infoIcon' />}
        content='This includes all shifts, of any status, for this client in the next 2 weeks'
        on='hover'
        position='top left'
        popperModifiers={{
          preventOverflow: {
            boundariesElement: 'offsetParent',
          },
        }}
      />
    </div>
  );

  const headerArray = [
    { id: 'clients-table-header-clientName', key: 'clientName', content: 'Client', filter: true },
    { id: 'clients-table-header-address', key: 'address', content: 'Address', filter: true },
    {
      id: 'clients-table-header-schedules',
      key: 'schedules',
      content: shiftCountInfo,
      filter: true,
      textAlign: 'center',
    },
    {
      id: 'clients-table-header-status',
      key: 'status',
      content: 'Status',
      filter: true,
      textAlign: 'center',
    },
  ];

  headerArray
    .filter(f => f.filter)
    .forEach(h => {
      // eslint-disable-next-line no-param-reassign
      h.className = column === h.key ? `${direction} sorted` : undefined;

      // eslint-disable-next-line no-param-reassign
      h.onClick = onHeaderClick(h.key);
      // eslint-disable-next-line no-param-reassign
      h.filter = undefined;
    });

  return headerArray;
};

class Clients extends React.Component {
  static sort(data, field, direction) {
    if (!data) {
      return data;
    }

    const fieldValue = i => {
      switch (field) {
        case 'clientName':
          return `${i.lastName ? i.lastName.toLowerCase().replace(/\s/g, '') : ''}${
            i.firstName ? i.firstName.toLowerCase().replace(/\s/g, '') : ''
          }`;
        case 'pets':
        case 'smokers':
          return !preferenceActive(i.preferences, field);
        case 'address':
          return i.address.formatted
            ? `${i.address.city
                .toLowerCase()
                .replace(/\s/g, '')}${i.address.street.toLowerCase().replace(/\s/g, '')}`
            : '-';
        case 'schedules':
          return i.shifts.length;
        default:
          return i[field];
      }
    };

    return direction === 'ascending'
      ? [...data].sort((a, b) => {
          const aVal = fieldValue(a);
          const bVal = fieldValue(b);

          if (aVal === bVal) return 0;
          if (aVal < bVal) return -1;
          return 1;
        })
      : [...data].sort((a, b) => {
          const aVal = fieldValue(a);
          const bVal = fieldValue(b);

          if (aVal === bVal) return 0;
          if (aVal > bVal) return -1;
          return 1;
        });
  }

  static checkInitialRequest(props) {
    if (
      props.clients &&
      !props.clients.initialRequestDone &&
      props.uiState &&
      props.uiState.selectedPrimaryContacts !== undefined
    ) {
      const { selectedPrimaryContacts } = props.uiState;
      let primaryContact;
      if (
        selectedPrimaryContacts &&
        selectedPrimaryContacts.length &&
        !selectedPrimaryContacts.includes(0)
      ) {
        primaryContact = selectedPrimaryContacts;
      }
      const params = {
        primaryContact,
        status: props.uiState.clientStatusFilter,
      };

      props.dispatch(clientActions.listClients(params));
    }
  }

  constructor() {
    super();
    this.handleDetailsClick = this.handleDetailsClick.bind(this);
    this.handleDetailsClose = this.handleDetailsClose.bind(this);
    this.handleEditorClick = this.handleEditorClick.bind(this);
    this.handleEditorClose = this.handleEditorClose.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);

    this.onMeterChanged = this.onMeterChanged.bind(this);
    this.onLoadMore = this.onLoadMore.bind(this);
    this.handleUpdateClick = this.handleUpdateClick.bind(this);
    this.handleClientShiftsClick = this.handleClientShiftsClick.bind(this);
  }

  // eslint-disable-next-line react/state-in-constructor
  state = {
    column: 'clientName',
    data: [],
    direction: 'ascending',
    clientDetailId: null,
    clientDetailTab: null,
    clientEditorId: null,
    searchField: '',
    meterState: {},
  };

  UNSAFE_componentWillMount() {
    this.setState({
      data: Clients.sort(this.props.clients.results, 'clientName', 'ascending'),
      column: 'clientName',
      direction: 'ascending',
      searchField: '',
    });

    Clients.checkInitialRequest(this.props);
  }

  componentDidMount() {
    analytics.track(Events.CLIENTS_SELECT_TAB);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.clients && !nextProps.clients.loading) {
      this.setState(s => ({
        ...s,
        data: Clients.sort(nextProps.clients.results, s.column, s.direction),
      }));
    }
    Clients.checkInitialRequest(nextProps);
  }

  onMeterChanged(state) {
    this.setState(s => ({
      ...s,
      meterState: { ...s.meterState, ...state },
    }));
  }

  onLoadMore() {
    this.props.dispatch(clientActions.listClients({ nextPage: this.props.clients.nextPage }, true));
  }

  handleDetailsClick = (e, input) => {
    this.setState(current => ({ ...current, clientDetailId: input.clientid }));
  };

  handleDetailsClose = () => {
    this.setState(current => ({ ...current, clientDetailId: null, clientDetailTab: null }));
  };

  handleEditorClick = () => {
    this.setState(current => ({ ...current, clientEditorId: 1 }));
  };

  handleEditorClose = newClient => {
    const newState = { clientEditorId: null };
    if (newClient && newClient.id) {
      newState.clientDetailId = newClient.id;
      newState.clientDetailTab = 'environment';
    }

    this.setState(current => ({ ...current, ...newState }));
  };

  handleSearchSubmit() {
    this.pageSizer.scrollToTop();
    const searchParams = {
      status: this.props.uiState.clientStatusFilter,
    };

    if (isValidUsZipCode(this.state.searchField)) {
      searchParams.zip = this.state.searchField;
    } else {
      searchParams.name = this.state.searchField;
    }

    this.props.dispatch(clientActions.listClients(searchParams));
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;

    if (!data) return;

    if (column !== clickedColumn) {
      const newDirection = clickedColumn === 'schedules' ? 'descending' : 'ascending';
      this.setState({
        column: clickedColumn,
        data: Clients.sort(data, clickedColumn, newDirection),
        direction: newDirection,
      });

      return;
    }

    this.setState(s => ({
      ...s,
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    }));
  };

  handleUpdateClick() {
    this.pageSizer.scrollToTop();
  }

  handleClientShiftsClick = (clientId, shiftIds) => {
    const filter = {
      shiftStatus: 'allshifts',
      shiftCompinedStatus: ['unassigned'],
      startDate: moment(),
      endDate: moment().add(14, 'days'),
      timeRange: { min: 1, max: 24 },
      primaryContact: 0,
    };

    if (shiftIds.length > 0) {
      filter.shiftCompinedStatus.push('assigned');
      this.props.dispatch(
        schedulerActions.listSchedules(
          {
            withIds: shiftIds,
          },
          filter
        )
      );
    } else {
      filter.endDate = moment().add(30, 'days');
      this.props.dispatch(schedulerActions.resetSchedulesSearch(filter));
    }
    history.push('/home/shifts');
  };

  render() {
    const { clients } = this.props;
    const { settings } = this.props.principal;
    const {
      column,
      data,
      direction,
      clientDetailId,
      clientDetailTab,
      clientEditorId,
      searchField,
    } = this.state;

    const clientsCount = data ? data.length : 0;

    const initState = !data || (clients.loading && !clients.partialUpdate);

    return (
      <Grid.Row className='noVerticalPadding'>
        {clientDetailId && (
          <ClientDetails
            clientId={clientDetailId}
            onCloseClicked={this.handleDetailsClose}
            tab={clientDetailTab}
          />
        )}
        {clientEditorId && (
          <ClientEditor uiState={this.props.uiState} onCloseClicked={this.handleEditorClose} />
        )}

        <Grid padded style={{ width: '100%' }} className='contentGrid'>
          <Grid.Column width='three' textAlign='center' className='menuCol'>
            <ClientsMenu loading={clients.loading} onUpdateClick={this.handleUpdateClick} />
            <PageMeter name='bottom' onChange={this.onMeterChanged} />
          </Grid.Column>
          <Grid.Column width='13'>
            <Grid padded>
              <Grid.Column
                width='three'
                verticalAlign='middle'
                className='noVerticalPadding headerWithCount'
              >
                <Header floated='left' as='h3'>
                  CLIENTS
                </Header>
                <div id='clients-client-count'>({clientsCount})</div>
              </Grid.Column>
              <Grid.Column width='eight' verticalAlign='middle' className='noVerticalPadding'>
                <Form size='tiny' onSubmit={this.handleSearchSubmit}>
                  <Form.Group className='menu-input-with-button' inline>
                    <Form.Input
                      width='twelve'
                      id='clientsSearchName'
                      size='tiny'
                      inline
                      floated='left'
                      placeholder='Find by Client Name or Zip'
                      value={searchField}
                      onChange={(e, input) =>
                        this.setState({
                          searchField: input.value,
                        })
                      }
                    />
                    <Form.Button
                      id='clients-search-button'
                      className='search-input-with-button'
                      circular
                      loading={clients.loading}
                    >
                      Go
                    </Form.Button>
                  </Form.Group>
                </Form>
              </Grid.Column>
              <Grid.Column
                width='five'
                textAlign='right'
                verticalAlign='middle'
                className='noVerticalPadding right'
              >
                {settings && settings.standaloneMode ? (
                  <span className='add-button-with-label'>
                    <span>Add a New Client</span>
                    <CareRoundButton
                      content='+'
                      onClick={this.handleEditorClick}
                      id='clients-add-new-client-button'
                    />
                  </span>
                ) : (
                  undefined
                )}
              </Grid.Column>
            </Grid>
            <Grid padded centered>
              <PageSizer
                meterState={this.state.meterState}
                active={data && data.length > 0 && !initState}
                contentHeight={data ? data.length * 50 : 0}
                ref={node => {
                  this.pageSizer = node;
                }}
              >
                <PageMeter name='top' onChange={this.onMeterChanged} />
                <Table
                  className='stickyHeader'
                  id='clientsResultsTable'
                  sortable
                  striped
                  headerRow={headers(null, column, direction, this.handleSort)}
                  renderBodyRow={body(this.handleClientShiftsClick)}
                  tableData={!clients.partialUpdate && clients.loading ? [] : data}
                />
              </PageSizer>
              <LoadMore
                onLoadMore={this.onLoadMore}
                loading={clients.loading}
                partialUpdate={clients.partialUpdate}
                nextPage={clients.nextPage}
              />
              {data && data.length === 0 && !clients.loading && (
                <NoMatch id='clientsResultsNoMatch' tooManyResults={clients.tooManyResults} />
              )}
              {initState && <InitialState searchingFor='clients' loading={clients.loading} />}
            </Grid>
          </Grid.Column>
        </Grid>
      </Grid.Row>
    );
  }
}

Clients.propTypes = {
  dispatch: PropTypes.func.isRequired,
  principal: PropTypes.shape().isRequired,
  clients: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape()),
    loading: PropTypes.bool,
    nextPage: PropTypes.shape(),
    partialUpdate: PropTypes.bool,
  }).isRequired,
  uiState: PropTypes.shape({
    clientStatusFilter: PropTypes.arrayOf(PropTypes.string),
    selectedPrimaryContacts: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

const mapStateToProps = state => {
  const { clients, principal } = state;
  return {
    clients,
    principal,
  };
};

export default connect(uiStateActions.mapStateOfGroupToProps('clientsFilter', mapStateToProps))(
  Clients
);

/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import {
  Table,
  Button,
  Dropdown,
  Grid,
  Segment,
  Header,
  Image,
  Form,
  Icon,
  Modal,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { employeeActions, caregiverActions } from '../../actions';
import { EmployeeAvailableRoles, avatarUrl } from '../../constants/DomainTypes';
import { isValidEmail } from '../../constants/Formats';
import CareRoundButton from './CareRoundButton';

import '../Profile.css';

const NoMatch = () => (
  <Grid.Row colums='one' className='accesscol emptyStateRow' centered>
    <Segment basic>
      <Header color='grey' size='medium'>
        No active employees
      </Header>
      <Image src='/nomatch.svg' />
      <Header color='grey' size='small'>
        Try to add new employess
      </Header>
    </Segment>
  </Grid.Row>
);

const rolesEditor = (employee, onRolesChange, saveClick) => {
  if (employee.status !== 'active') {
    return (
      <Dropdown
        name='roles'
        size='tiny'
        placeholder='Roles'
        fluid
        multiple
        selection
        options={EmployeeAvailableRoles}
        value={employee.roles}
        onChange={onRolesChange}
        employeeid={employee.id}
      />
    );
  }

  return (
    <Form>
      <Form.Group inline fluid='true'>
        <Form.Field>
          <Dropdown
            name='roles'
            size='tiny'
            placeholder='Roles'
            className='role-dropdown'
            fluid
            multiple
            selection
            options={EmployeeAvailableRoles}
            value={employee.roles}
            onChange={onRolesChange}
            employeeid={employee.id}
          />
        </Form.Field>
        {employee.roles && JSON.stringify(employee.roles) !== JSON.stringify(employee.oldRoles) && (
          <Form.Field>
            <Button
              size='mini'
              className='care-blue'
              disabled={employee.roles.length < 1}
              onClick={() => saveClick(employee)}
            >
              Save
            </Button>
          </Form.Field>
        )}
      </Form.Group>
    </Form>
  );
};

const headers = (inviteMode, shift, column, direction, onHeaderClick) => {
  const headersArray = [
    { key: 'name', content: 'Name', textAlign: 'center', filter: true },
    { key: 'role', content: 'Role', textAlign: 'center' },
    { key: 'email', content: 'Email', className: 'emailCol', filter: true },
    { key: 'access', content: 'Access Rights', className: 'accessCol' },
    { key: 'unit', content: 'Unit', filter: true },
    { key: 'location', content: 'Location', filter: true },
  ];

  if (inviteMode) {
    headersArray.push({ key: 'invite', content: 'Add' });
  }

  headersArray
    .filter(f => f.filter)
    .forEach(h => {
      // eslint-disable-next-line no-param-reassign
      h.className = column === h.key ? `${direction} sorted ${h.className}` : h.className;

      // eslint-disable-next-line no-param-reassign
      h.onClick = onHeaderClick(h.key);
      // eslint-disable-next-line no-param-reassign
      h.filter = undefined;
    });

  return headersArray;
};

class UserDirectoryTable extends React.Component {
  static sort(data, field) {
    return [...data].sort((a, b) => {
      if (a[field] === b[field]) return 0;
      if (a[field] < b[field]) return -1;
      return 1;
    });
  }

  constructor() {
    super();

    this.handleSort = this.handleSort.bind(this);
    this.addEmployeeClick = this.addEmployeeClick.bind(this);
    this.addCaregiverClick = this.addCaregiverClick.bind(this);
    this.onRolesChange = this.onRolesChange.bind(this);
    this.filterByMode = this.filterByMode.bind(this);
    this.prepareEmployee = this.prepareEmployee.bind(this);

    this.body = this.body.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.addEmailClick = this.addEmailClick.bind(this);
    this.clickDetails = this.clickDetails.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.onNameMouseOver = this.onNameMouseOver.bind(this);
    this.onNameMouseOut = this.onNameMouseOut.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.deleteEmployeeClick = this.deleteEmployeeClick.bind(this);
    this.saveClick = this.saveClick.bind(this);
  }

  // eslint-disable-next-line react/state-in-constructor
  state = {
    column: null,
    data: [],
    clickedId: [],
    direction: null,
    emailEditor: {},
    hoveredEmployee: null,
    employeeToDelete: null,
  };

  UNSAFE_componentWillMount() {
    this.setState({
      data: this.props.employee.employeeList.filter(this.filterByMode).map(this.prepareEmployee),
      column: null,
      direction: null,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.employee && !nextProps.employee.loading) {
      this.setState(() => ({
        data: nextProps.employee.employeeList.filter(this.filterByMode).map(this.prepareEmployee),
        column: null,
        direction: null,
        emailEditor: { ...this.state.emailEditor, [nextProps.employee.updatedEmployeeId]: null },
      }));
    }
  }

  onRolesChange = (e, input) => {
    this.setState(state => {
      const newState = { ...state };
      const index = newState.data.findIndex(i => i.id === input.employeeid);
      newState.data[index] = { ...newState.data[index], roles: input.value.sort() };
      return newState;
    });
  };

  onNameMouseOver = e => {
    this.setState({ hoveredEmployee: e });
  };

  onNameMouseOut = () => {
    this.setState({ hoveredEmployee: null });
  };

  addEmployeeClick = e => {
    const employee = this.state.data.find(i => i.id === e);
    this.setState({ clickedId: [...this.state.clickedId, employee.id] });

    this.props.dispatch(employeeActions.inviteEmployee(employee.id, employee.roles));
  };

  addCaregiverClick = (id, status) => {
    this.setState({ clickedId: [...this.state.clickedId, id] });
    const cg = { id, status };

    this.props.dispatch(caregiverActions.inviteCaregiver(cg));
  };

  clickDetails = employeeid => {
    if (this.props.clickProfileRow) {
      window.scrollTo(0, 0);
      this.props.clickProfileRow(employeeid);
    }
  };

  emailChange = (e, input) => {
    this.setState(s => {
      const newState = { ...s };
      newState.emailEditor[input.name] = { value: input.value };
      return newState;
    });
  };

  addEmailClick = (e, input) => {
    const { employeeid } = input;
    const newEmail = this.state.emailEditor[employeeid];

    if (newEmail) {
      const employee = this.state.data.find(i => i.id === employeeid);
      this.props.dispatch(
        employeeActions.updateEmployee(employeeid, {
          ...employee,
          email: newEmail.value,
          roles: undefined,
        })
      );
    }
  };

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;

    if (!data) return;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: UserDirectoryTable.sort(data, clickedColumn, 'ascending'),
        direction: 'ascending',
      });
      return;
    }

    this.setState(s => ({
      ...s,
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    }));
  };

  openDeleteModal = () => {
    this.setState({ employeeToDelete: this.state.hoveredEmployee });
  };

  closeDeleteModal = () => {
    this.setState({ employeeToDelete: null });
  };

  deleteEmployeeClick = e => {
    this.props.dispatch(employeeActions.updateUserStatus(e.userId, 'suspended'));
    this.setState({ employeeToDelete: null });
  };

  saveClick = e => {
    this.props.dispatch(employeeActions.updateEmployee(e.id, e));
  };

  filterByMode(e) {
    if (this.props.inviteMode) {
      return e.status === 'inactive' || e.status === 'pending';
    }
    return e.status === 'active';
  }

  prepareEmployee(e) {
    const index = this.state.data.findIndex(i => i.id === e.id);
    return {
      ...e,
      oldRoles: e.roles && e.roles.sort(),
      roles:
        this.state.data[index] && this.state.data[index].roles
          ? this.state.data[index].roles.sort()
          : e.roles && e.roles.sort(),
    };
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;

    if (!data) return;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: UserDirectoryTable.sort(data, clickedColumn),
        direction: 'ascending',
      });

      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  };

  emailEditor = (employee, inviteMode) => {
    if (!inviteMode) {
      return (
        <a className='overlayLink' href={`mailto:${employee.email}`}>
          {employee.email}
        </a>
      );
    }

    const toggleEmailEditor = () => {
      this.setState(s => {
        const newState = { ...s };
        newState.emailEditor[employee.id] = this.state.emailEditor[employee.id]
          ? null
          : { value: employee.email };
        return newState;
      });
    };

    if (employee.email && !this.state.emailEditor[employee.id]) {
      return (
        <div className='overlayLink' onClick={toggleEmailEditor} role='presentation'>
          {employee.email}
        </div>
      );
    }

    const value = this.state.emailEditor[employee.id]
      ? this.state.emailEditor[employee.id].value
      : employee.email;

    const validEmail =
      this.state.emailEditor[employee.id] &&
      isValidEmail(this.state.emailEditor[employee.id].value);

    const emailSaveDisabled = this.state.emailEditor[employee.id]
      ? this.state.emailEditor[employee.id].value === employee.email || !validEmail
      : true;

    return (
      <Form>
        <Form.Group inline fluid='true'>
          <Form.Input
            width={14}
            size='tiny'
            name={employee.id}
            value={value}
            onChange={this.emailChange}
            placeholder='email'
          />
          <div className='field'>
            <div className='ui tiny input'>
              <Button
                size='mini'
                circular
                icon={employee.email ? 'checkmark' : 'plus'}
                basic
                employeeid={employee.id}
                onClick={this.addEmailClick}
                disabled={emailSaveDisabled}
              />
              {employee.email && (
                <Button
                  size='mini'
                  circular
                  icon='remove'
                  basic
                  employeeid={employee.id}
                  onClick={toggleEmailEditor}
                />
              )}
            </div>
          </div>
        </Form.Group>
      </Form>
    );
  };

  nameCell(employee, hoveredEmployee, inviteMode) {
    if (inviteMode) {
      return (
        <div className='avatarWithName'>
          <Image
            avatar
            centered
            circular
            size='mini'
            floated='left'
            src={avatarUrl(employee.avatar)}
          />
          <span>{employee.employeeName}</span>
        </div>
      );
    }
    return (
      <Grid
        verticalAlign='middle'
        onMouseOver={() => this.onNameMouseOver(employee)}
        onMouseOut={this.onNameMouseOut}
      >
        <Grid.Column
          width='12'
          className='name-cell'
          onClick={() => this.clickDetails(employee.id)}
        >
          <div className='avatarWithName'>
            <Image
              avatar
              centered
              circular
              size='mini'
              floated='left'
              src={avatarUrl(employee.avatar)}
            />
            <span>{employee.employeeName}</span>
          </div>
        </Grid.Column>
        <Grid.Column width='4'>
          <div
            className={
              hoveredEmployee && employee.id === hoveredEmployee.id
                ? 'delete-container'
                : 'delete-placeholder'
            }
            onClick={() => this.openDeleteModal()}
            role='presentation'
          >
            <Icon className='delete-icon' name='trash' />
            <div className='delete-text'>delete</div>
          </div>
        </Grid.Column>
      </Grid>
    );
  }

  body = inviteMode => (x, i) => {
    const cells = [
      { key: 'name', content: this.nameCell(x, this.state.hoveredEmployee, inviteMode) },
      { key: 'role', content: x.roleName ? x.roleName : '-', textAlign: 'center' },
      {
        key: 'email',
        content: inviteMode && x.isCaregiver ? x.email : this.emailEditor(x, inviteMode),
      },
      { key: 'access', content: rolesEditor(x, this.onRolesChange, this.saveClick, inviteMode) },
      { key: 'unit', content: x.meta?.unit },
      { key: 'location', content: x.meta?.location },
    ];

    if (inviteMode && !this.state.emailEditor[x.id]) {
      const clicked = this.state.clickedId.find(e => e === x.id)
        ? JSON.stringify(x.roles) === JSON.stringify(x.oldRoles)
        : null;
      cells.push({
        key: 'invite',
        content:
          x.email && x.roles && x.roles.length && !clicked ? (
            <CareRoundButton
              className='green-button'
              content='+'
              onClick={() => this.addEmployeeClick(x.id)}
            />
          ) : (
            <span />
          ),
      });
    }

    return {
      key: x.id || `row-${i}`,
      cells,
    };
  };

  render() {
    const { column, data, direction, employeeToDelete } = this.state;
    const { inviteMode } = this.props;

    const components = [
      <Grid padded='horizontally'>
        <Grid.Row>
          <Table
            id={this.props.id}
            className='UserDirectoryTable'
            sortable
            striped
            headerRow={headers(inviteMode, null, column, direction, this.handleSort)}
            renderBodyRow={this.body(inviteMode)}
            tableData={data || []}
          />
        </Grid.Row>
        {data && data.length === 0 && <NoMatch />}
      </Grid>,
    ];

    if (employeeToDelete) {
      components.push(
        <Modal
          closeOnDimmerClick={false}
          closeIcon
          onClose={this.closeDeleteModal}
          open
          style={{ marginTop: '-20vh' }}
        >
          <Modal.Header>Delete user account</Modal.Header>
          <Modal.Content>
            Are you sure you want to delete this user account: {employeeToDelete.employeeName}?
          </Modal.Content>
          <Modal.Actions>
            <Button basic onClick={this.closeDeleteModal}>
              Cancel
            </Button>
            <Button color='red' onClick={() => this.deleteEmployeeClick(employeeToDelete)}>
              Delete User
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }

    return components;
  }
}

UserDirectoryTable.defaultProps = {
  id: '',
};

UserDirectoryTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  inviteMode: PropTypes.bool.isRequired,
  employee: PropTypes.shape().isRequired,
  id: PropTypes.string,
  clickProfileRow: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { employee } = state;
  return {
    employee,
  };
};

export default connect(mapStateToProps)(UserDirectoryTable);

import * as types from '../constants/ActionTypes';
import { backend } from '../services';
// eslint-disable-next-line import/no-cycle
import { alertActions } from './alert';

const getEmployee = (employeeId, employeeEditor, updateAvatar) => {
  return (dispatch, getState) => {
    const { settings } = getState().principal;
    dispatch(request());
    backend.getEmployee(employeeId).then(
      results => {
        dispatch(success(results, false, settings));
        if (updateAvatar) {
          dispatch(alertActions.notification('Avatar has been updated'));
          dispatch(successUpload(results));
        }
      },
      error => {
        if (error.statusCode === 404) {
          dispatch(success(null, true, settings));
        } else {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      }
    );
  };

  function request() {
    return { type: types.GET_EMPLOYEE_REQUEST, employeeEditor };
  }
  function successUpload(results) {
    return { type: types.UPLOAD_AVATAR_SUCCESS, results };
  }
  function success(results, rootUser, settings) {
    return {
      type: types.GET_EMPLOYEE_SUCCESS,
      results,
      rootUser,
      employeeId,
      employeeEditor,
      settings,
    };
  }
  function failure(error) {
    return { type: types.GET_EMPLOYEE_FAILURE, error, employeeEditor };
  }
};

const getEmployeeList = () => {
  return dispatch => {
    dispatch(request());
    backend.getEmployess().then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_EMPLOYEE_LIST_REQUEST };
  }
  function success(results) {
    return { type: types.GET_EMPLOYEE_LIST_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_EMPLOYEE_LIST_FAILURE, error };
  }
};

const createEmployee = (employee, uiStateGroup) => {
  return dispatch => {
    dispatch(request());
    backend.createEmployee(employee).then(
      results => {
        dispatch(success(results));
      },
      error => {
        if (error.json && error.json.details && error.json.details.invalidFields) {
          dispatch(failure(error.message, error.json.details.invalidFields));
        } else {
          dispatch(failure(error));
        }
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.CREATE_EMPLOYEE_REQUEST };
  }
  function success(results) {
    return { type: types.CREATE_EMPLOYEE_SUCCESS, results };
  }
  function failure(error, invalidFields) {
    return { type: types.CREATE_EMPLOYEE_FAILURE, error, invalidFields, uiStateGroup };
  }
};

const updateEmployee = (employeeId, employee, uiStateGroup) => {
  return dispatch => {
    dispatch(request());
    backend.updateEmployee(employeeId, employee).then(
      results => {
        dispatch(success(results));
        dispatch(alertActions.notification('Employee profile saved.'));
      },
      error => {
        if (error.json && error.json.details && error.json.details.invalidFields) {
          dispatch(failure(error.message, error.json.details.invalidFields));
        } else {
          dispatch(failure(error));
        }
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.UPDATE_EMPLOYEE_REQUEST };
  }
  function success(results) {
    return { type: types.UPDATE_EMPLOYEE_SUCCESS, results };
  }
  function failure(error, invalidFields) {
    return { type: types.UPDATE_EMPLOYEE_FAILURE, error, invalidFields, uiStateGroup };
  }
};

const inviteEmployee = (employeeId, roles) => {
  return dispatch => {
    dispatch(request());
    backend.inviteEmployee(employeeId, roles).then(
      results => {
        dispatch(alertActions.notification('The invitation has been sent'));
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.INVITE_EMPLOYEE_REQUEST, employeeId };
  }
  function success(results) {
    return { type: types.INVITE_EMPLOYEE_SUCCESS, results, employeeId };
  }
  function failure(error) {
    return { type: types.INVITE_EMPLOYEE_FAILURE, error, employeeId };
  }
};

const getEmployeeDashboard = () => {
  return dispatch => {
    dispatch(request());
    backend.getEmployeeDashboard().then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_EMPLOYEE_DASHBOARD_REQUEST };
  }
  function success(results) {
    return { type: types.GET_EMPLOYEE_DASHBOARD_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_EMPLOYEE_DASHBOARD_FAILURE, error };
  }
};

/*
const uploadAvatar = (employeeId, fileName) => {
  const upload = (avatar, size) => (e) => {
    const path = avatar.uploadLink;
    fetch(path, { // Your POST endpoint
      method: 'PUT',
      body: e.currentTarget.result,
      headers: {
        'content-length': fileName.size,
        'Content-Type': 'image/jpeg',
      },
    }).then(
      s => console.log(s),
    ).catch(
      error => console.log(error),
    );
  };

  return (dispatch) => {
    dispatch(request());
    backend.uploadAvatar(employeeId, fileName.name)
    .then(
      (results) => {
        const reader = new FileReader();
        reader.onload = upload(results.avatar, fileName.size);
        reader.readAsArrayBuffer(fileName);
        console.log("readAsArrayBuffer")
        dispatch(success(results));
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request() {
    return { type: types.UPLOAD_AVATAR_REQUEST };
  }
  function success(results) {
    return { type: types.UPLOAD_AVATAR_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.UPLOAD_AVATAR_FAILURE, error };
  }
};
*/

const uploadAvatar = (employeeId, fileName) => {
  const uploadFile = avatar =>
    new Promise((resolve, reject) => {
      const upload = e => {
        const path = avatar.uploadLink;
        fetch(path, {
          method: 'PUT',
          body: e.currentTarget.result,
          headers: {
            'content-length': fileName.size,
            'Content-Type': 'image/jpeg',
          },
        })
          .then(s => {
            if (s.status >= 400) {
              const error = new Error(`HTTP Error ${s.statusText}`);
              error.status = s.statusText;
              error.statusCode = s.status;
              error.response = s;
              throw error;
            } else {
              resolve();
            }
            return {};
          })
          .catch(error => reject(error));
      };

      const reader = new FileReader();
      reader.onload = upload;
      reader.readAsArrayBuffer(fileName);
    });

  return dispatch => {
    if (fileName && fileName.name) {
      dispatch(request());
      backend
        .uploadAvatar(employeeId, fileName.name)
        .then(results => uploadFile(results.avatar))
        .catch(error => {
          dispatch(failure(error.message));
          dispatch(alertActions.error(error));
        });
    } else {
      dispatch(cancel());
    }
  };

  function request() {
    return { type: types.UPLOAD_AVATAR_REQUEST };
  }
  function cancel() {
    return { type: types.UPLOAD_AVATAR_SUCCESS };
  }
  function failure(error) {
    return { type: types.UPLOAD_AVATAR_FAILURE, error };
  }
};

const removeAvatar = (userId, employeeId) => {
  return dispatch => {
    dispatch(request());
    backend.removeAvatar(userId).then(
      results => {
        dispatch(success(results));
        dispatch(getEmployee(employeeId, true));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.REMOVE_AVATAR_REQUEST };
  }
  function success(results) {
    return { type: types.REMOVE_AVATAR_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.REMOVE_AVATAR_FAILURE, error };
  }
};

const updateUserStatus = (userId, status) => {
  return dispatch => {
    dispatch(request());
    backend.updateUserStatus(userId, status).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.UPDATE_USER_STATUS_REQUEST };
  }
  function success(results) {
    return { type: types.UPDATE_USER_STATUS_SUCCESS, results, userId, status };
  }
  function failure(error) {
    return { type: types.UPDATE_USER_STATUS_FAILURE, error };
  }
};

function createEmployeeClear() {
  return dispatch => dispatch({ type: types.CREATE_EMPLOYEE_CLEAR });
}

// eslint-disable-next-line import/prefer-default-export
export const employeeActions = {
  getEmployeeList,
  getEmployee,
  createEmployee,
  updateEmployee,
  inviteEmployee,
  getEmployeeDashboard,
  uploadAvatar,
  removeAvatar,
  updateUserStatus,
  createEmployeeClear,
};

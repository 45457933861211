import moment from 'moment';

class HRCaregiverHelper {
  static sortTableData = (data, field) => {
    if (!data) {
      return data;
    }
    return [...data].sort((a, b) => {
      const [covidVaccinationA] = a.vaccinations.filter(v => v.type === 'COVID');
      const [covidVaccinationB] = b.vaccinations.filter(v => v.type === 'COVID');
      const covidSorting = {
        validating: 1,
        firstCompleted: 2,
        completed: 3,
        rejected: 4,
        pending: 5,
        declined: 6,
      };
      let aVal = null;
      let bVal = null;
      switch (field) {
        case 'name':
          aVal = a.lastName ? a.lastName.toLowerCase() : '';
          bVal = b.lastName ? b.lastName.toLowerCase() : '';
          break;
        case 'phone':
          aVal = a.phoneNumber;
          bVal = b.phoneNumber;
          break;
        case 'completion':
          aVal = a.nonCompliantBlocks.length;
          bVal = b.nonCompliantBlocks.length;
          break;
        case 'last_modified':
        case 'lastModified':
          aVal = moment(a.last_modified);
          bVal = moment(b.last_modified);
          break;
        case 'created':
        case 'date-of-added':
          aVal = moment(a.created);
          bVal = moment(b.created);
          break;
        case 'COVID':
          if (
            covidVaccinationA?.validationStatus === 'pending' &&
            covidVaccinationA?.validationRound === 2
          ) {
            aVal = covidSorting.firstCompleted;
          } else {
            aVal = covidSorting[covidVaccinationA?.validationStatus];
          }

          if (
            covidVaccinationB?.validationStatus === 'pending' &&
            covidVaccinationB?.validationRound === 2
          ) {
            bVal = covidSorting.firstCompleted;
          } else {
            bVal = covidSorting[covidVaccinationB?.validationStatus];
          }
          break;
        default:
          aVal = a[field];
          bVal = b[field];
      }
      aVal = aVal || '';
      bVal = bVal || '';

      if (aVal === bVal) return 0;
      if (aVal < bVal) return -1;
      return 1;
    });
  };
}

export default HRCaregiverHelper;

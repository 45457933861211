/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import colors from '../../../../styles/colors';
import { SimpleBlueButton } from '../../../../styles/common';

const { neutrals, primary } = colors;

interface IJustifyContainer {
  alignItems?: string;
  justifyContent?: string;
}

interface IWarningIcon {
  marginLeft?: boolean;
}

interface IStyledSpan {
  color?: string;
  display?: string;
  fontWeight?: string;
}

export const TailContainer = styled.div`
  padding: 2rem;
  margin: 0 0 1rem 0;
  background-color: ${neutrals.white};
  border-radius: 10px;
  box-shadow: 0px 4px 16px #00000022;
`;

export const MainContent = styled.div`
  width: 100%;
  padding-bottom: 0.5rem;
  box-shadow: 0 1px ${neutrals.aluminum}, 2rem 0 ${neutrals.white}, 2rem 1px ${neutrals.aluminum},
    -2rem 0 ${neutrals.white}, -2rem 1px ${neutrals.aluminum};
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

export const ColumnsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10%;
  margin: 1rem 0;
`;

export const TrainingColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 1rem;
`;

export const Training = styled.div`
  border-bottom: 1px solid ${neutrals.aluminum};
  margin: 1rem 0;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  column-gap: 1rem;
  width: 100%;
  & > div {
    flex-grow: 1;
  }
  &:last-child {
    border-bottom: none;
  }
`;

export const TrainingHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LinkContainer = styled.div`
  width: 100%;
  max-width: 80px;
`;

export const WarningIcon = styled.img<IWarningIcon>`
  margin-left: ${props => (props.marginLeft ? '0.5 rem;' : '0 rem;')};
  margin-right: 0.5rem;
  width: 16px;
  height: 17px;
  vertical-align: text-bottom;
`;

export const DocLinkText = styled.p`
  color: ${primary.teal};
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  :hover {
    color: ${`${primary.teal}95`};
  }
`;

export const AddTrainingButton = styled(SimpleBlueButton)`
  &&&& {
    padding: 5px;
    font-size: 11px;
    height: 2rem;
    line-height: 0;
  }
`;

export const JustifyContainer = styled.div<IJustifyContainer>`
  display: flex;
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
`;

export const StyledSpan = styled.span<IStyledSpan>`
  font-size: 12px;
  color: ${props => props.color || neutrals.charcoal};
  margin: 0;
  display: ${props => props.display || 'block'};
  font-weight: ${props => props.fontWeight || 'normal'};
`;

import {
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
} from '../constants/ActionTypes';

const initialState = {
  events: [],
  loading: false,
};

function eventLog(state = initialState, action) {
  switch (action.type) {
    case GET_EVENTS_REQUEST:
      return { ...state, loading: true };
    case GET_EVENTS_SUCCESS:
      return { ...state, loading: false, events: action.results };
    case GET_EVENTS_FAILURE:
      return { ...state, loading: false, events: [] };
    default:
      return state;
  }
}

export default eventLog;

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Form } from 'formsy-semantic-ui-react';
import { Grid, Image, Segment, Divider, Button, Icon } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { avatarStyle, avatarUrl } from '../../../constants/DomainTypes';
import { MOMENT_DOB, formatTime, MOMENT_HOURS_WITH_DATE } from '../../../constants/Formats';
import DetailsBox from './DetailsBox';
import { RecordsForm } from '../../../styles/records';
import SSNInput from '../SSNInput';
import '../../CaregiverRecords.css';
import Status from '../Status';
import CGTileInformationNote from './CGTileInformationNote';
import { caregiverActions } from '../../../actions/caregiver';
import { analytics, Events } from '../../../helpers/analytics';

class TileInformationDetails extends React.Component {
  constructor() {
    super();
    this.state = { noteKey: Math.random() };
  }

  componentDidMount() {
    this.form.formsyForm.setFormPristine(false);
  }

  createNote = () => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.person.caregiverNotes.push({});
    this.setState({ noteKey: Math.random() });
  };

  render() {
    const {
      intl,
      person,
      disciplines,
      onValueChange,
      onValid,
      onInvalid,
      type,
      editable,
      editability,
      mobileHealthIntegration,
      multiDisciplineEnabled,
      dispatch,
      homeLocations,
    } = this.props;

    const disciplineOptions = disciplines;
    const instantValidation = true;
    const isCaregiver = type === 'caregiver';
    const isClient = type === 'client';

    const firstName = intl.formatMessage({ id: 'records.firstName' });
    const middleName = intl.formatMessage({ id: 'records.middleName' });
    const nickName = intl.formatMessage({ id: 'records.nickName' });
    const lastName = intl.formatMessage({ id: 'records.lastName' });
    const birthday = intl.formatMessage({ id: 'records.birthday' });
    const multiDisciplineLabel = intl.formatMessage({ id: 'records.disciplines' });
    const disciplineLabel = intl.formatMessage({ id: 'records.discipline' });
    const homeLocationLabel = intl.formatMessage({ id: 'records.homeLocation' });
    const inAppActivationStatus = intl.formatMessage({ id: 'records.inAppActivationStatus' });
    const ssn = intl.formatMessage({ id: 'records.ssn' });
    const ccId = intl.formatMessage({ id: 'records.id.cc' });
    const externalId = intl.formatMessage({ id: 'records.id.external' });
    const caregiverAgencyId = intl.formatMessage({ id: 'records.id.agencyDefined' });
    const clientNotes = intl.formatMessage({ id: 'clients.records.notes' });

    const genderOptions = [
      { key: 'm', text: intl.formatMessage({ id: 'gender.male' }), value: 'm' },
      { key: 'f', text: intl.formatMessage({ id: 'gender.female' }), value: 'f' },
      { key: 'u', text: intl.formatMessage({ id: 'gender.other' }), value: 'u' },
    ];
    const suffixOptions = [
      { key: 'mr', text: intl.formatMessage({ id: 'records.suffix.mr' }), value: 'mr' },
      { key: 'mrs', text: intl.formatMessage({ id: 'records.suffix.mrs' }), value: 'mrs' },
      { key: 'mis', text: intl.formatMessage({ id: 'records.suffix.miss' }), value: 'miss' },
      { key: 'ms', text: intl.formatMessage({ id: 'records.suffix.ms' }), value: 'ms' },
      { key: 'dr', text: intl.formatMessage({ id: 'records.suffix.dr' }), value: 'dr' },
    ];
    const ethnicity = intl.formatMessage({ id: 'records.ethnicity' });
    const ethnicityOptions = [
      { key: 'w', text: intl.formatMessage({ id: 'records.ethnicity.white' }), value: 1 },
      {
        key: 'aa',
        text: intl.formatMessage({ id: 'records.ethnicity.africanAmerican' }),
        value: 2,
      },
      {
        key: 'ai',
        text: intl.formatMessage({ id: 'records.ethnicity.americanIndian' }),
        value: 3,
      },
      { key: 'a', text: intl.formatMessage({ id: 'records.ethnicity.asian' }), value: 4 },
      {
        key: 'nh',
        text: intl.formatMessage({ id: 'records.ethnicity.nativeHawaiian' }),
        value: '5',
      },
    ];
    const maritalStatus = intl.formatMessage({ id: 'records.marital.status' });
    const maritalOptions = [
      { key: 's', text: intl.formatMessage({ id: 'records.marital.single' }), value: 's' },
      { key: 'm', text: intl.formatMessage({ id: 'records.marital.married' }), value: 'm' },
      { key: 'd', text: intl.formatMessage({ id: 'records.marital.divorced' }), value: 'd' },
      { key: 'w', text: intl.formatMessage({ id: 'records.marital.widowed' }), value: 'w' },
    ];

    const caregiverNotes = () => {
      if (!person.caregiverNotes || person.caregiverNotes.length === 0) {
        Object.assign(person, { caregiverNotes: [{}] });
      }
      return (
        person.caregiverNotes && (
          // eslint-disable-next-line react/destructuring-assignment
          <div key={this.state.noteKey}>
            {person.caregiverNotes.map((note, index) => (
              <CGTileInformationNote
                person={person}
                onValueChange={onValueChange}
                editable={editable}
                index={index}
                note={note}
              />
            ))}
          </div>
        )
      );
    };

    const inviteCaregiverClick = () => {
      dispatch(caregiverActions.inviteCaregiver(person, null, null, null, true, null, true));
      analytics.track(Events.CAREGIVERS_REGISTRATION_INVITE_CAREGIVER);
    };

    return (
      <DetailsBox
        person={person}
        text={intl.formatMessage({ id: `records.details.${type}` }).toUpperCase()}
        icon='/client-contact-icon-large.png'
      >
        <RecordsForm
          key='careRecordsForm'
          onValid={() => onValid('details')}
          onInvalid={() => onInvalid('details')}
          size='tiny'
          ref={c => {
            this.form = c;
          }}
          className='detailsBox'
        >
          <Grid>
            {isClient && (
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form.Input
                    id='form-client-details-first-name'
                    fluid
                    name='firstName'
                    placeholder={editable ? firstName : ' - '}
                    label={firstName}
                    width={16}
                    onChange={onValueChange}
                    value={person.firstName}
                    validations={{
                      minLength: 0,
                      maxLength: 25,
                    }}
                    validationErrors={{ isValid: 'firstName not valid' }}
                    instantValidation={instantValidation}
                    disabled={!editable}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Input
                    id='form-client-details-last-name'
                    fluid
                    name='lastName'
                    label={lastName}
                    placeholder={editable ? lastName : ' - '}
                    width={16}
                    onChange={onValueChange}
                    value={person.lastName}
                    validations={{
                      minLength: 2,
                      maxLength: 25,
                    }}
                    validationErrors={{ isValid: 'lastName not valid' }}
                    instantValidation={instantValidation}
                    required={editable}
                    disabled={!editable}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {isCaregiver && (
              <Grid.Row>
                <Grid.Column width={5}>
                  <Form.Select
                    id='form-client-suffix'
                    placeholder={editability.name ? 'Choose' : ' - '}
                    name='suffix'
                    label='Title'
                    options={suffixOptions}
                    onChange={onValueChange}
                    value={person.suffix}
                    disabled={!editability.name}
                    fluid
                  />
                </Grid.Column>
                <Grid.Column className='detail-input' width={11}>
                  <Form.Input
                    id='form-caregiver-details-first-name'
                    fluid
                    name='firstName'
                    placeholder={editability.name ? firstName : ' - '}
                    label={firstName}
                    width={16}
                    onChange={onValueChange}
                    value={person.firstName}
                    input='text'
                    maxLength={26}
                    validations={{
                      minLength: 2,
                      maxLength: 25,
                    }}
                    validationErrors={{ isValid: 'firstName not valid' }}
                    instantValidation={instantValidation}
                    disabled={!editability.name}
                  />

                  <Form.Input
                    id='form-caregiver-details-middle-name'
                    fluid
                    name='middleName'
                    placeholder={editability.name ? middleName : ' - '}
                    label={middleName}
                    width={16}
                    onChange={(e, i) => onValueChange(e, i, true)}
                    value={person.middleName}
                    input='text'
                    maxLength={26}
                    validations={{
                      maxLength: 25,
                    }}
                    validationErrors={{ isValid: 'middleName not valid' }}
                    instantValidation={instantValidation}
                    disabled={!editability.name}
                  />

                  <Form.Input
                    id='form-caregiver-details-last-name'
                    fluid
                    name='lastName'
                    label={lastName}
                    placeholder={editability.name ? lastName : ' - '}
                    width={16}
                    onChange={onValueChange}
                    value={person.lastName}
                    input='text'
                    maxLength={26}
                    validations={{
                      minLength: 2,
                      maxLength: 25,
                    }}
                    validationErrors={{ isValid: 'lastName not valid' }}
                    instantValidation={instantValidation}
                    required={!editability.name}
                    disabled={!editability.name || mobileHealthIntegration}
                  />
                  <Form.Input
                    id='form-caregiver-details-nick-name'
                    fluid
                    name='nickName'
                    label={nickName}
                    placeholder={editability.name ? nickName : ' - '}
                    width={16}
                    onChange={(e, i) => onValueChange(e, i, true)}
                    value={person.nickName}
                    input='text'
                    maxLength={50}
                    validations={{
                      maxLength: 50,
                    }}
                    validationErrors={{ isValid: 'nickName not valid' }}
                    instantValidation={instantValidation}
                    disabled={!editability.name}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {isCaregiver && (
              <Grid.Row className='details-box-wide-grey'>
                <Grid.Column width={8}>
                  {multiDisciplineEnabled ? (
                    <Form.Select
                      id='form-caregiver-discipline'
                      name='discipline'
                      label={multiDisciplineLabel}
                      options={disciplineOptions}
                      placeholder={editability.discipline ? 'Choose' : ' - '}
                      multiple
                      onChange={(e, i) => {
                        const strVal = i.value && i.value.length ? i.value.join(',') : null;
                        return onValueChange(e, { ...i, value: strVal }, true);
                      }}
                      value={person.discipline ? person.discipline.split(',') : []}
                      required={editability.discipline}
                      disabled={!editability.discipline}
                    />
                  ) : (
                    <Form.Select
                      id='form-caregiver-discipline'
                      name='discipline'
                      label={disciplineLabel}
                      options={disciplineOptions}
                      placeholder={editability.discipline ? 'Choose' : ' - '}
                      onChange={onValueChange}
                      value={person.discipline}
                      required={editability.discipline}
                      disabled={!editability.discipline}
                    />
                  )}
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Field required>
                    <label htmlFor='registrationStatus'>{inAppActivationStatus}</label>
                    {person && (
                      <div style={{ marginTop: '8px' }}>
                        <Status object={person} editable={false} />
                      </div>
                    )}
                  </Form.Field>
                  {person.status !== 'active' && (
                    <>
                      <div>
                        <Button
                          id='caregivers-free-view-status-send-invitation-button'
                          className='care-green'
                          disabled={!person.appInvitable}
                          style={{ fontSize: '.85714286rem' }}
                          compact
                          size='tiny'
                          onClick={inviteCaregiverClick}
                        >
                          {person.status === 'pending' ? 'Resend Invitation' : 'Send Invitation'}
                        </Button>
                      </div>
                      {person.latestInvitation && person.appInvitable && (
                        <span style={{ fontSize: '11px', color: '#9b9b9b' }}>
                          Invited:{' '}
                          {formatTime(person.latestInvitation, null, MOMENT_HOURS_WITH_DATE)}
                        </span>
                      )}
                      {!person.appInvitable && (
                        <>
                          <Icon name='warning circle' className='icon-red' size='large' />
                          <span style={{ fontSize: '11px', color: '#f16778' }}>
                            Missing Req Info
                          </span>
                        </>
                      )}
                    </>
                  )}
                </Grid.Column>
              </Grid.Row>
            )}
            {/* isClient && (
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form.Field required={editable}>
                    <label htmlFor='status'>{status}</label>
                    {statusDot}
                    <Select
                      id='form-client-details-last-status'
                      name='status'
                      style={lastStatusStyle}
                      options={clientStatusOptions}
                      placeholder={editable ? 'Choose' : ' - '}
                      onChange={onValueChange}
                      value={person.status}
                      disabled={!editable}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Input
                    id='client-details-last-since'
                    style={lastSinceStyle}
                    fluid
                    name='clientStatus'
                    label={since}
                    value={date}
                    disabled
                  />
                </Grid.Column>
              </Grid.Row>
             ) */}
            {isCaregiver && (
              <Grid.Row className='details-box-wide'>
                <Grid.Column width={8}>
                  <DatePicker
                    customInput={
                      <Form.Input
                        id='tile-information-details-dob-input'
                        name='dobInput'
                        placeholder='MM/DD/YYYY'
                        required={editability.dob}
                        label={birthday}
                        style={{ backgroundImage: 'none!important' }}
                        disabled={!editability.dob}
                      />
                    }
                    placeholderText={editability.dob ? birthday : ' - '}
                    float
                    name='dob'
                    id='clientBirthDate'
                    popperPlacement='top-end'
                    selected={person && person.dob && moment(person.dob, MOMENT_DOB)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    width={16}
                    onChange={(e, i) => onValueChange(e, { ...i, name: 'dob' })}
                    dateFormat='MM/DD/YYYY'
                    maxDate={moment()}
                    required={editability.dob}
                    disabled={
                      !editability.dob || person.status !== 'inactive' || mobileHealthIntegration
                    }
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <SSNInput
                    starMask
                    onValueChange={onValueChange}
                    isCaregiver
                    value={person.ssn}
                    label={ssn}
                    maskedValue={person.maskedSsn}
                    disabled={
                      !editability.ssn || person.status !== 'inactive' || mobileHealthIntegration
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {isClient && (
              <Grid.Row>
                <Grid.Column width={8}>
                  <label htmlFor='detailsSsn'>{ssn}</label>
                  <br />
                  <span id='detailsSsn'>{person.ssn || '-'}</span>
                </Grid.Column>
              </Grid.Row>
            )}
            {isCaregiver && [
              <Grid.Row className='details-box-wide'>
                <Grid.Column width={4}>
                  <Form.Select
                    id='form-caregiver-gender'
                    name='gender'
                    label='Gender'
                    options={genderOptions}
                    placeholder={editability.gender ? 'Choose' : ' - '}
                    onChange={onValueChange}
                    value={person.gender}
                    disabled={!editability.gender}
                  />
                </Grid.Column>
                <Grid.Column width={7} style={{ paddingLeft: '24px' }}>
                  <Form.Select
                    id='form-caregiver-ethnicity'
                    name='ethnicity'
                    label={ethnicity}
                    options={ethnicityOptions}
                    placeholder={editability.ethnicity ? 'Choose' : ' - '}
                    onChange={onValueChange}
                    value={person.ethnicity}
                    disabled={!editability.ethnicity}
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <Form.Select
                    id='form-caregiver-marital'
                    name='marital'
                    label={maritalStatus}
                    options={maritalOptions}
                    placeholder={editability.maritalstatus ? 'Choose' : ' - '}
                    onChange={onValueChange}
                    value={person.marital}
                    disabled={!editability.maritalstatus}
                  />
                </Grid.Column>
              </Grid.Row>,
              <Divider style={{ marginLeft: '-85px', width: '120%' }} />,
              <Grid.Row className='details-box-wide'>
                <Grid.Column width={5} className='detail-input'>
                  <Form.Input
                    id='form-caregiver-id'
                    fluid
                    name='caregiverId'
                    label={ccId}
                    placeholder={ccId}
                    value={person.id}
                    disabled
                  />
                </Grid.Column>
                <Grid.Column width={5} className='detail-input'>
                  <Form.Input
                    id='form-sam-id'
                    fluid
                    name='externalId'
                    label={externalId}
                    placeholder={externalId}
                    value={person.externalId}
                    disabled
                  />
                </Grid.Column>
                <Grid.Column width={6} className='detail-input'>
                  <Form.Input
                    id='form-agency-defined-id'
                    fluid
                    name='caregiverAgencyId'
                    label={caregiverAgencyId}
                    placeholder={caregiverAgencyId}
                    value={person.caregiverAgencyId}
                    disabled
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Select
                    id='form-caregiver-homeLocation'
                    name='homeLocation'
                    label={homeLocationLabel}
                    options={homeLocations}
                    placeholder='Choose'
                    onChange={onValueChange}
                    value={person.homeLocation}
                  />
                </Grid.Column>
              </Grid.Row>,
              editability.caregivernotes && [
                <Divider style={{ margin: '0 0 0 -85px', width: '120%' }} />,
                <Grid.Row className='details-box-wide' style={{ paddingBottom: 0 }}>
                  <p
                    style={{
                      marginLeft: '10px',
                      fontSize: '14px',
                      marginBottom: '10px',
                      fontWeight: '700',
                    }}
                  >
                    Notes
                  </p>
                </Grid.Row>,
                caregiverNotes(),
                <Grid.Row className='details-box-wide'>
                  <Button
                    circular
                    icon='plus'
                    style={{ marginLeft: '50%' }}
                    onClick={() => this.createNote()}
                  />
                </Grid.Row>,
              ],
            ]}
            {isClient && (
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form.Select
                    id='form-client-gender'
                    name='gender'
                    label='Gender'
                    options={genderOptions}
                    placeholder={editable ? ' Choose' : ' - '}
                    onChange={onValueChange}
                    value={person.gender}
                    disabled={!editable}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Segment basic>
                    <div className='avatarCgRecords'>
                      <Image
                        id='avatar-gender-record'
                        avatar
                        centered
                        circular
                        size='large'
                        floated='left'
                        style={avatarStyle(null, person.gender, null, editable)}
                        src={avatarUrl(null, person.gender)}
                      />
                      <br />
                    </div>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            )}
            <Divider style={{ marginLeft: '-85px', width: '120%' }} />
            {isClient && (
              <div>
                <Grid.Row style={{ marginLeft: '-80px' }}>
                  <p
                    style={{
                      fontSize: '14px',
                      marginBottom: '10px',
                      fontWeight: '700',
                    }}
                  >
                    {clientNotes}
                  </p>
                </Grid.Row>
                <Grid.Row style={{ marginLeft: '-80px', color: '#B8B8B8' }}>
                  <Grid.Column>
                    Add any additional helpful information about this client
                  </Grid.Column>
                </Grid.Row>
              </div>
            )}

            {isClient && (
              <Grid.Row style={{ marginLeft: '-85px', width: '120%' }}>
                <Grid.Column>
                  <Form.TextArea
                    id='tile-information-details-text-area'
                    style={
                      !editable && person.notes != null
                        ? {
                            border: '0px',
                            borderLeft: '5px solid #1593CB',
                            borderRadius: '0px',
                            fontStyle: 'italic',
                            fontWeight: 'normal',
                            fontSize: '12px',
                            color: '#6A6A6A',
                            padding: '5px',
                          }
                        : { width: '100%', backgroundColor: '#F1F1F1', color: '#6A6A6A' }
                    }
                    autoHeight
                    name='notes'
                    value={person.notes}
                    onChange={onValueChange}
                    disabled={!editable}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </RecordsForm>
      </DetailsBox>
    );
  }
}

TileInformationDetails.defaultProps = {
  person: { firstName: 'Testi', middleName: 'AJ', address: {} },
  // eslint-disable-next-line react/default-props-match-prop-types
  original: { firstName: 'Testi', middleName: 'AJ', address: {} },
  disciplines: [],
  onValid: () => {},
  onInvalid: () => {},
  type: 'caregiver',
  editable: true,
  editability: {},
  homeLocations: [],
};

TileInformationDetails.propTypes = {
  intl: intlShape.isRequired,
  person: PropTypes.shape(),
  editable: PropTypes.bool,
  editability: PropTypes.shape(),
  disciplines: PropTypes.arrayOf(PropTypes.shape()),
  onValueChange: PropTypes.func.isRequired,
  onValid: PropTypes.func,
  onInvalid: PropTypes.func,
  type: PropTypes.string,
  mobileHealthIntegration: PropTypes.bool.isRequired,
  multiDisciplineEnabled: PropTypes.bool.isRequired,
  homeLocations: PropTypes.arrayOf(PropTypes.shape()),
  dispatch: PropTypes.func.isRequired,
};

export default injectIntl(TileInformationDetails);

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Header, Image, Form, Segment } from 'semantic-ui-react';
import URLSearchParams from 'url-search-params';
import { userActions, alertActions } from '../actions';
import { history } from '../helpers';
import TermsAndConditions from './TermsAndConditions';
import './Login.scss';

class Signup extends React.Component {
  constructor(props) {
    super(props);

    this.props.dispatch(() => {
      userActions.logout();
    });
    this.state = {
      alert: null,
      email: '',
      password: '',
      password2: '',
      invitationToken: null,
      submitted: false,
      pendingTerms: false,
    };
  }

  UNSAFE_componentWillMount() {
    const params = new URLSearchParams(this.props.location.search);
    const recoveryToken = params.get('recoveryToken'); // bar
    const invitationToken = params.get('invitationToken'); // bar
    const email = params.get('email'); // bar

    this.setState(s => {
      const newState = { ...s };
      newState.invitationToken = invitationToken;
      newState.recoveryToken = recoveryToken;
      newState.email = email;
      return newState;
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { alert, pendingTerms } = nextProps;
    if (alert) {
      return this.setState({
        alert: alert.message,
        submitted: false,
        recoverySubmitted: false,
      });
    }
    if (pendingTerms) {
      return this.setState({
        alert: null,
        recoverySubmitted: false,
        pendingTerms: true,
      });
    }
    return this.setState({
      alert: null,
    });
  }

  handleChange = (e, { name, value }) => {
    this.setState(s => {
      if (s[name] === '' && value.length > 2) {
        // prevents auto complete to overried initial states....
        return s;
      }

      return { ...s, [name]: value };
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email, password, invitationToken, recoveryToken } = this.state;
    const { dispatch } = this.props;

    if (email && password && invitationToken) {
      dispatch(alertActions.clearErrors());
      dispatch(userActions.signup(email, password, invitationToken));
    }

    if (email && password && recoveryToken) {
      dispatch(alertActions.clearErrors());
      dispatch(userActions.updatePassword(email, password, recoveryToken));
    }
  };

  handleLogIn = () => {
    this.setState({ submitted: true });
    const { email, password } = this.state;
    const { dispatch } = this.props;
    if (email && password) {
      dispatch(userActions.login(email, password, false, false));
      this.setState({ pendingTerms: false });
    }
  };

  renderInvitation() {
    const { loading } = this.props;
    const { email, password, password2, invitationToken } = this.state;

    return (
      <Segment basic>
        <Header>SIGN UP</Header>
        <Grid className='actionArea'>
          <Grid.Column verticalAlign='middle'>
            {!invitationToken && <Segment basic>You need invitation to completed Sign Up</Segment>}
            <Form loading={loading} onSubmit={this.handleSubmit} autocomplete='off'>
              <Form.Input
                fluid
                id='signupEmail'
                icon='user'
                iconPosition='left'
                placeholder='Email'
                name='email'
                value={email}
                onChange={this.handleChange}
              />
              <Form.Input
                fluid
                id='signupPassword'
                icon='lock'
                iconPosition='left'
                placeholder='Password'
                name='password'
                value={password}
                type='password'
                autoComplete='off'
                onChange={this.handleChange}
              />
              <Form.Input
                fluid
                id='signupPassword2'
                icon='lock'
                iconPosition='left'
                placeholder='Re-enter New Password'
                name='password2'
                value={password2}
                type='password'
                onChange={this.handleChange}
                disabled={!invitationToken}
              />
              <Form.Button
                id='signupSubmitButton'
                primary
                disabled={!invitationToken || password.length < 1 || password !== password2}
                content='Submit'
              />
            </Form>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }

  renderPasswordRecovery() {
    const { loading } = this.props;
    const { password, password2 } = this.state;

    return (
      <Segment basic>
        <Header>PASSWORD RECOVERY</Header>
        <Grid className='actionArea'>
          <Grid.Column verticalAlign='middle'>
            <Form loading={loading} onSubmit={this.handleSubmit} autocomplete='off'>
              <Form.Input
                fluid
                id='signupRecoveryPassword'
                icon='lock'
                iconPosition='left'
                placeholder='New Password'
                name='password'
                value={password}
                type='password'
                onChange={this.handleChange}
              />
              <Form.Input
                fluid
                id='signupRecoveryPassword2'
                icon='lock'
                iconPosition='left'
                placeholder='Re-enter New Password'
                name='password2'
                value={password2}
                type='password'
                onChange={this.handleChange}
              />
              <Form.Button
                id='signupRecoverySubmitButton'
                primary
                disabled={password.length < 1 || password !== password2}
                content='Submit'
              />
            </Form>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }

  render() {
    const { terms } = this.props;
    const { alert, invitationToken, recoveryToken, pendingTerms } = this.state;

    return (
      <div className='login-form'>
        <Grid centered textAlign='center'>
          <Grid.Row>
            <Grid.Column width='six' verticalAlign='bottom'>
              <Header as='h2'>
                <Image src='/logo_careconnect.svg' />
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width='six' verticalAlign='middle'>
              {alert && (
                <Segment id='signupAlert' basic className='error'>
                  {alert}
                </Segment>
              )}
              {pendingTerms ? (
                <Segment className='termsBox'>
                  <div id='wizardParent'>
                    <TermsAndConditions terms={terms} submit={this.handleLogIn} />
                    <Image src='/circlesLogin.svg' fluid className='footer' />
                  </div>
                </Segment>
              ) : (
                <>
                  <Segment>
                    {recoveryToken && this.renderPasswordRecovery()}
                    {invitationToken && this.renderInvitation()}
                    <Image src='/circles.svg' />
                  </Segment>
                  <Segment
                    basic
                    fluid
                    className='forgetPassword'
                    as='a'
                    onClick={() => history.push('/login')}
                  >
                    Sign In
                  </Segment>
                </>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row />
        </Grid>
      </div>
    );
  }
}

Signup.propTypes = {
  dispatch: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    message: PropTypes.string,
    success: PropTypes.bool,
    error: PropTypes.bool,
  }).isRequired,
  loading: PropTypes.bool,
  location: PropTypes.shape().isRequired,
  terms: PropTypes.shape({
    id: PropTypes.number,
    feature: PropTypes.string,
    policyUrl: PropTypes.string,
    status: PropTypes.string,
    termsUrl: PropTypes.string,
    presentation: PropTypes.shape({
      body: PropTypes.string,
      buttonLabel: PropTypes.string,
      confirmationLabel: PropTypes.string,
      policyLabel: PropTypes.string,
      termsLabel: PropTypes.string,
      title: PropTypes.string,
      canCancel: PropTypes.bool,
      defaultApproved: PropTypes.bool,
    }),
  }),
  pendingTerms: PropTypes.bool,
};

Signup.defaultProps = {
  loading: false,
  terms: {},
  pendingTerms: false,
};

const mapStateToProps = state => {
  const { alert, principal } = state;
  const lastAlert = alert ? alert[alert.length - 1] : null;

  const { loading, pendingTerms, terms } = principal;
  return {
    alert: lastAlert,
    loading,
    pendingTerms,
    terms,
  };
};

export default connect(mapStateToProps)(Signup);

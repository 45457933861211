import { FilterSections } from '../../components/subcomponents/hrApp/hrLeftRail/helper';
import * as types from '../../constants/ActionTypes';
import { IHRFilterPayload } from '../payloadInterfaces';

export interface IDispatchAction {
  type: string;
  changes: IHRFilterPayload;
}

type FilterDispatch = (action: IDispatchAction) => void;

type Dispatch = (action: { type: string }) => void;

function caregiverFilterChange(section: FilterSections, filter: string, value: unknown) {
  const changes = { section, filter, value };
  return (dispatch: FilterDispatch) =>
    dispatch({ type: types.UPDATE_HR_CAREGIVER_FILTER, changes });
}

function filterByPendingCaregivers() {
  return (dispatch: Dispatch) => dispatch({ type: types.FILTER_BY_PENDING });
}

function filterByExpiredCaregivers() {
  return (dispatch: Dispatch) => dispatch({ type: types.FILTER_BY_EXPIRED });
}

function filterByCOVIDValidationCaregivers() {
  return (dispatch: Dispatch) => dispatch({ type: types.FILTER_BY_COVID_VALIDATION });
}

function cleanComplianceOptions() {
  return (dispatch: Dispatch) => dispatch({ type: types.CLEAN_COMPLIANCE_OPTIONS });
}

function cleanVaccinationOptions() {
  return (dispatch: Dispatch) => dispatch({ type: types.CLEAN_VACCINATION_OPTIONS });
}

function cleanAllHRFilters() {
  return (dispatch: Dispatch) => dispatch({ type: types.CLEAN_ALL_HR_FILTERS });
}

function candidatesFilterChange(section: FilterSections, filter: string, value: unknown) {
  const changes = { section, filter, value };
  return (dispatch: FilterDispatch) =>
    dispatch({ type: types.UPDATE_HR_CANDIDATES_FILTER, changes });
}

export default {
  caregiverFilterChange,
  candidatesFilterChange,
  filterByPendingCaregivers,
  filterByExpiredCaregivers,
  filterByCOVIDValidationCaregivers,
  cleanComplianceOptions,
  cleanVaccinationOptions,
  cleanAllHRFilters,
};

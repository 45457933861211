/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { TextArea, Grid, Image, Loader, Divider, Button, Modal } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import CareLoader from '../CareLoader';
import '../../CGCompliance.css';
import '../../Common.css';

const moment = require('moment');

const mmddyyyy = 'MM/DD/YYYY';
const checkIcon = '/compliance/check.svg';
const exclusionsFine = '/compliance/exclusions.svg';
const exclusionsWarning = '/compliance/exclusred.svg';
const warningIcon = '/compliance/warning.svg';

class ComplianceExclusions extends React.Component {
  constructor() {
    super();
    this.state = { modalVisible: false };
  }

  previewDocument = (link, filename) => {
    if (filename.endsWith('.pdf')) {
      return (
        <a href={link}>
          <div style={{ textAlign: 'center' }}>.pdf</div>
        </a>
      );
    }

    return <Image width='100px' height='100px' src={link} />;
  };

  // eslint-disable-next-line react/sort-comp
  documentForm(block) {
    const { uploadingDocument } = this.props;
    const { selectedExclusion } = this.state;
    const field = block.fields.find(f => f.type === 'document');

    let addNewClass = 'complianceDocumentItem';
    if (uploadingDocument) addNewClass += ' disabled';

    return (
      <div className='complianceDocumentsContainer' key={field.id}>
        {!field.filesDownloaded && field.value && field.value.length > 0 && (
          <div className='complianceDocumentItem'>
            <div style={{ height: '100px' }}>
              <Loader active inline />
            </div>
          </div>
        )}
        {field.filesDownloaded &&
          field.value
            .filter(file => file.tag === selectedExclusion.provider)
            .map(file => (
              <div className='complianceDocumentItem' key={file}>
                {this.previewDocument(field.downloadLinks[file.filename], file.filename)}
                <Button
                  circular
                  icon='delete'
                  className='documentRemoveButton'
                  onClick={() => this.props.documentRemoved(field.id, file.filename)}
                />
              </div>
            ))}
        <div className={addNewClass}>
          {uploadingDocument ? (
            <>
              {uploadingDocument === field.id ? (
                <div style={{ height: '100px' }}>
                  <Loader active inline />
                </div>
              ) : (
                <p style={{ color: '#ccc' }}>+</p>
              )}
            </>
          ) : (
            <>
              <input
                type='file'
                accept='.jpg,.png,.jpeg,.gif,.pdf'
                id={`fileinput${field.id}`}
                className='fileinput'
                style={{ display: 'none' }}
                onChange={e => this.props.uploadDocument(e, field.id, selectedExclusion.provider)}
              />
              <label htmlFor={`fileinput${field.id}`} style={{ padding: '36px' }}>
                +
              </label>
            </>
          )}
        </div>
      </div>
    );
  }

  exclusionModal = close => {
    const { blocks } = this.props;
    const { selectedExclusion, confirm } = this.state;
    const exclusionBlock = blocks.filter(b => b.type === 'exclusions')[0];

    const notesChanged = (e, i) => {
      Object.assign(selectedExclusion, { notes: i.value });
      this.setState({ selectedExclusion });
    };

    const submit = () => {
      Object.assign(selectedExclusion, { confirmation: confirm });
      this.props.onSubmitExclusion(selectedExclusion);
      close();
    };

    return (
      <Modal
        closeOnDimmerClick={false}
        open
        closeIcon
        dimmer
        className='compliance'
        onClose={() => close()}
        style={{ marginTop: '-40vh' }}
      >
        <Modal.Header>
          <div style={{ textAlign: 'center', zIndex: '1!important' }}>
            <h3>{confirm ? 'CONFIRM EXCLUSION' : 'DISMISS EXCLUSION'}</h3>
          </div>
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row centered>
              <h4>Documentation screenshot</h4>
            </Grid.Row>
            <Grid.Row centered>{this.documentForm(exclusionBlock)}</Grid.Row>
            <Divider />
            <Grid.Row centered>
              <h4> {confirm ? 'Additional Exclusion Information' : 'Reason for dismission'} </h4>
            </Grid.Row>
            <Grid.Row>
              <TextArea
                style={{ width: '100%', margin: '12px' }}
                onChange={notesChanged}
                value={selectedExclusion.notes}
              />
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button content={confirm ? 'Confirm' : 'Dismiss'} onClick={submit} />
        </Modal.Actions>
      </Modal>
    );
  };

  exclusion = (exclusion, downloadLinks, documents, showConfirmButtons = true) => {
    const {
      title,
      modified,
      exclusionDate,
      type,
      confirmation,
      confirmUserName,
      notes,
    } = exclusion;

    let { hasExclusion } = exclusion;
    if (hasExclusion === undefined) hasExclusion = !!exclusionDate && confirmation !== false;
    return (
      <div className='listElement' key={title}>
        <Grid columns={16}>
          <Grid.Row>
            <Grid.Column width={12}>
              <b>{title}</b>
              <br />
              {type && (
                <label className='red'>
                  Exclusion type: {type}
                  <br />
                </label>
              )}
              {exclusionDate && (
                <label>
                  Exclusion date: {moment(exclusionDate).format(mmddyyyy)}
                  <br />
                </label>
              )}
              <label className='smallGray'>Updated: {moment(modified).format(mmddyyyy)}</label>
            </Grid.Column>
            <Grid.Column width={4}>
              {hasExclusion ? (
                <Image inline size='large' className='checkIcon' name='check' src={warningIcon} />
              ) : (
                <Image inline size='large' className='checkIcon' name='check' src={checkIcon} />
              )}
            </Grid.Column>
          </Grid.Row>
          {confirmation != null && (
            <Grid.Row>
              <Grid.Column width={10}>
                <div>
                  <p>
                    <b>{confirmation ? 'Exclusion Confirmed' : 'Exclusion Dismissed'}</b>
                  </p>
                  <label className='smallGray'>
                    Verified by: <br />
                  </label>
                  <p>{confirmUserName}</p>

                  <label className='smallGray'>
                    Exclusion notes: <br />
                  </label>
                  <p>{notes}</p>
                </div>
              </Grid.Column>
              <Grid.Column width={6}>
                {documents &&
                  documents.map(d =>
                    this.previewDocument(downloadLinks[d.filename], d.originalName)
                  )}
              </Grid.Column>
            </Grid.Row>
          )}
          {showConfirmButtons && hasExclusion && confirmation == null && (
            <Grid.Row>
              <Grid.Column width={16}>
                <Button
                  size='mini'
                  onClick={() =>
                    this.setState({
                      modalVisible: true,
                      selectedExclusion: exclusion,
                      confirm: true,
                    })
                  }
                >
                  Confirm Exclusion
                </Button>
                <Button
                  size='mini'
                  onClick={() =>
                    this.setState({
                      modalVisible: true,
                      selectedExclusion: exclusion,
                      confirm: false,
                    })
                  }
                >
                  Dismiss Exclusion
                </Button>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </div>
    );
  };

  render() {
    const { modalVisible } = this.state;
    const { hasExclusions, exclusions, blocks, loadingExclusions, exclusionsError } = this.props;
    const exclusionBlock = blocks.filter(b => b.type === 'exclusions')[0];
    const documentField = exclusionBlock
      ? exclusionBlock.fields.find(f => f.type === 'document')
      : null;
    const downloadLinks =
      documentField && documentField.downloadLinks ? documentField.downloadLinks : {};

    const exclusionWarning = hasExclusions;
    const closeModal = () => this.setState({ modalVisible: false, selectedExclusion: null });
    return !exclusionsError ? (
      <>
        {modalVisible && this.exclusionModal(closeModal)}
        <div className='infoPanel' key='exclusions'>
          <p className='tileHeader'>EXCLUSIONS</p>
          {!exclusions || loadingExclusions ? (
            <>
              <CareLoader loading centered showText={false} />
            </>
          ) : (
            <>
              <>
                <Image
                  src={exclusionWarning ? exclusionsWarning : exclusionsFine}
                  name='exclusionsImage'
                  className='exclusionsImage'
                />
                <p className={exclusionWarning ? 'small center red' : 'small center'}>
                  {exclusionWarning
                    ? 'This caregiver has active exclusions.'
                    : 'This caregiver has no active exclusions.'}
                </p>
                {exclusions.map(e =>
                  this.exclusion(
                    e,
                    downloadLinks,
                    documentField && documentField.value
                      ? documentField.value.filter(v => v.tag === e.provider)
                      : []
                  )
                )}
                <br />
              </>
            </>
          )}
        </div>
      </>
    ) : (
      <></>
    );
  }
}

ComplianceExclusions.defaultProps = {
  exclusions: [],
  blocks: [],
  loadingExclusions: true,
  exclusionsError: false,
  hasExclusions: false,
};

ComplianceExclusions.propTypes = {
  exclusions: PropTypes.arrayOf(PropTypes.shape()),
  blocks: PropTypes.arrayOf(PropTypes.shape()),
  hasExclusions: PropTypes.bool,
  loadingExclusions: PropTypes.bool,
  uploadingDocument: PropTypes.bool.isRequired,
  documentRemoved: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired,
  exclusionsError: PropTypes.bool,
  onSubmitExclusion: PropTypes.func.isRequired,
};

export default injectIntl(ComplianceExclusions);

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Dropdown } from 'semantic-ui-react';
import { beginningSearch } from '../../constants/Formats';

const DetailsDropdown = ({
  editable,
  object,
  onValueChange,
  options,
  name,
  header,
  errorView,
  valueLabel,
}) => {
  if (errorView != null) {
    return (
      <Grid.Row>
        <Grid.Column width={16}>{errorView}</Grid.Column>
      </Grid.Row>
    );
  }

  return editable ? (
    <Grid.Row>
      <Grid.Column width={5}>
        <Header size='tiny' floated='left'>
          {header}
        </Header>
      </Grid.Column>
      <Grid.Column width={11}>
        <Header size='tiny'>
          <Dropdown
            id='details-dropdown-editable'
            name={name}
            placeholder='Choose'
            selection
            error={!object[name]}
            search={beginningSearch}
            options={options}
            value={object[name]}
            onChange={onValueChange}
          />
        </Header>
      </Grid.Column>
    </Grid.Row>
  ) : (
    <Grid.Row>
      <Grid.Column width={5}>
        <Header size='tiny' floated='left'>
          {header}
        </Header>
      </Grid.Column>
      <Grid.Column width={11}>
        <Header id='details-dropdown-not-editable' size='tiny'>
          {valueLabel || object[name]}
        </Header>
      </Grid.Column>
    </Grid.Row>
  );
};

DetailsDropdown.defaultProps = {
  editable: false,
  errorView: null,
  valueLabel: null,
};

DetailsDropdown.propTypes = {
  editable: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
  object: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  errorView: PropTypes.element,
  valueLabel: PropTypes.string,
};

export default DetailsDropdown;

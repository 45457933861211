/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Grid } from 'semantic-ui-react';
import {
  formatTime,
  SHORT_DATE,
  MOMENT_DOB,
  MOMENT_DOB_US,
  MOMENT_HOURS_24,
} from '../../constants/Formats';
import TableWithExport from './TableWithExport';
import Overlay from './Overlay';
import NoMatch from './NoMatch';
import LoadMore from './LoadMore';
import InitialState from './InitialState';

class ChangeLogPreferenceTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      column: 'timestamp',
      data: props.data,
      direction: 'descending',
    };

    this.handleSort = this.handleSort.bind(this);
    this.tableHeader = this.tableHeader.bind(this);
    this.tableBody = this.tableBody.bind(this);
    this.sortTableData = this.sortTableData.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.data });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !!nextState.update || this.props.loading !== nextProps.loading;
  }

  onExportClick() {
    return this.onExportClick ? this.onExportClick() : undefined;
  }

  typeColumn = type => {
    switch (type) {
      case 'CAREGIVER_PROFILE_CREATED':
      case 'CAREGIVER_PROFILE_UPDATED':
      case 'CAREGIVER_COMPLIANCE_BLOCKS':
      case 'CAREGIVER_WORKHISTORY_UPDATE':
      case 'COMPLETE_CAREGIVER_PREHIRE':
        return 'caregiver';
      case 'CLIENT_PROFILE_CREATED':
      case 'CLIENT_PROFILE_UPDATED':
        return 'client';
      default:
        return '--';
    }
  };

  sortTableData = (data, field) => {
    if (!data) {
      return data;
    }

    return [...data].sort((a, b) => {
      let aVal = null;
      let bVal = null;
      switch (field) {
        case 'changedby':
          aVal = a.userName;
          bVal = b.userName;
          break;
        case 'changersRole':
          aVal = a.changerRoleIds;
          bVal = b.changerRoleIds;
          break;
        case 'type':
          aVal = a.type;
          bVal = b.type;
          break;
        case 'record':
          aVal = this.typeColumn(a.type) === 'caregiver' ? a.caregiverName : a.clientName;
          bVal = this.typeColumn(b.type) === 'caregiver' ? b.caregiverName : b.clientName;
          break;
        case 'field':
          aVal = this.alteredFieldColumn((a.details || {}).key, (a.details || {}).name);
          bVal = this.alteredFieldColumn((b.details || {}).key, (b.details || {}).name);
          break;
        case 'from':
          aVal = this.valueColumn((a.details || {}).key, (a.details || {}).old);
          bVal = this.valueColumn((b.details || {}).key, (b.details || {}).old);
          break;
        case 'to':
          aVal = this.valueColumn((a.details || {}).key, (a.details || {}).new);
          bVal = this.valueColumn((b.details || {}).key, (b.details || {}).new);
          break;
        case 'contact':
          aVal =
            this.typeColumn(a.type) === 'caregiver'
              ? a.caregiverPrimaryContactName
              : a.clientPrimaryContactName;
          bVal =
            this.typeColumn(b.type) === 'caregiver'
              ? b.caregiverPrimaryContactName
              : b.clientPrimaryContactName;
          break;
        case 'notified':
          aVal = a.notificationRecipients;
          bVal = b.notificationRecipients;
          break;
        default:
          aVal = a[field];
          bVal = b[field];
      }
      aVal = aVal || '';
      bVal = bVal || '';

      aVal = aVal.toString();
      bVal = bVal.toString();

      if (aVal === bVal) return 0;
      if (aVal < bVal) return -1;
      return 1;
    });
  };

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;

    if (!data) return;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: this.sortTableData(data, clickedColumn),
        direction: 'ascending',
        update: true,
      });

      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
      update: true,
    });
  };

  capitalize = str => (str ? str.replace(/\b\w/g, c => c.toUpperCase()) : str);

  alteredFieldColumn = (key, name) => {
    const names = {
      firstName: 'First Name',
      lastName: 'Last Name',
      middleName: 'Middle Name',
      email: 'Email',
      dob: 'Date of Birth',
      ssn: 'SSN',
      gender: 'Gender',
      phone: 'Phone',
      cellPhone: 'Cell Phone',
      homePhone: 'Home Phone',
      workPhone: 'Work Phone',
      discipline: 'Disciplines',
      complianceEndDate: 'Compliance Date',
      avatar: 'Avatar',
      notificationRecipients: 'Notification Recipients',
      street: 'Street',
      street2: 'Street 2',
      city: 'City',
      state: 'State',
      zip: 'Zip',
      county: 'County',
      apartment: 'Apartment',
      preferredHoursStart: 'Working Start Hours',
      preferredHoursEnd: 'Working End Hours',
      travelUpToMinutes: 'Commute Time',
      transportType: 'Transport Type',
      daysToWork: 'Working Days',
      status: 'Status',
      hireDate: 'Hire Date',
      holdDate: 'Hold Date',
      staffStatus: 'Staff Status',
      suffix: 'Title',
      name: 'Emergency Contact Name',
      relation: 'Emergency Contact Relation',
      secondaryContactNumber: 'Emergency Contact Secondary Phone',
      primaryContactNumber: 'Emergency Contact Primary Phone',
    };

    return names[key] || this.capitalize(name) || this.capitalize(key);
  };

  valueColumn = (key, val) => {
    if (['languages', 'preferences', 'customFields', 'fields', 'considerations'].includes(key)) {
      if (val === 'Y') {
        return 'Yes';
      }

      if (!val || val === 'N') {
        return 'No';
      }
    }

    if (['dob'].includes(key)) {
      const day = moment(val, MOMENT_DOB);
      return day != null ? day.format(MOMENT_DOB_US) : '--';
    }

    if (['hireDate'].includes(key)) {
      const day = val ? moment(val, MOMENT_DOB) : null;
      return day != null ? day.format(MOMENT_DOB_US) : '--';
    }

    if (['blocks-date'].includes(key)) {
      const day = val ? moment(val, MOMENT_DOB) : null;
      return day != null ? day.format(MOMENT_DOB_US) : '--';
    }

    if (['blocks-document'].includes(key)) {
      const documentNames = val ? val.join('\r\n') : null;
      return documentNames && documentNames.length > 0 ? documentNames : '--';
    }

    if (['blocks-event'].includes(key)) {
      const hours = val ? `${val} hrs` : null;
      return hours != null ? hours : '--';
    }

    if (['blockShiftMatching'].includes(key)) {
      if (val) {
        return 'Yes';
      }
      if (!val) {
        return 'No';
      }
    }

    if (['readyToWork'].includes(key)) {
      if (val) {
        return 'Yes';
      }
      if (!val) {
        return 'No';
      }
    }

    if (key.indexOf('workhistory') > -1 && (key.indexOf('start') > -1 || key.indexOf('end') > -1)) {
      const day = val ? moment(val, MOMENT_DOB) : null;
      return day != null ? day.format(MOMENT_DOB_US) : '--';
    }

    if (key.indexOf('workhistory') > -1) {
      return val === '' || val == null ? '--' : val;
    }

    return val != null ? val : '--';
  };

  timestampColumn = timestamp => (
    <span>
      <span className='infoTextBold'>{formatTime(timestamp, null, SHORT_DATE)}</span> at{' '}
      {formatTime(timestamp, null, MOMENT_HOURS_24)}
    </span>
  );

  tableHeader = (column, direction, onHeaderClick) => {
    const { caregiverId } = this.props;

    const headerArray = [
      {
        id: 'change-log-preferences-table-header-timestamp',
        key: 'timestamp',
        content: 'Date & Time',
        filter: true,
      },
      {
        id: 'change-log-preferences-table-header-changedby',
        key: 'changedby',
        content: 'Changed by',
        filter: true,
      },
      {
        id: 'change-log-preferences-table-header-changerRole',
        key: 'changersRole',
        content: "Changer's Role",
        filter: true,
      },
      {
        id: 'change-log-preferences-table-header-type',
        key: 'type',
        content: 'Record Type',
        filter: true,
      },
      ...(caregiverId
        ? []
        : [
            {
              id: 'change-log-preferences-table-header-record',
              key: 'record',
              content: 'Record',
              filter: true,
            },
          ]),
      {
        id: 'change-log-preferences-table-header-field',
        key: 'field',
        content: 'Altered Field',
        filter: true,
      },
      {
        id: 'change-log-preferences-table-header-from',
        key: 'from',
        content: 'From',
        filter: true,
      },
      { id: 'change-log-preferences-table-header-to', key: 'to', content: 'To', filter: true },
      {
        id: 'change-log-preferences-table-header-contact',
        key: 'contact',
        content: 'Primary Contact',
        filter: true,
      },
      {
        id: 'change-log-preferences-table-header-notified',
        key: 'notified',
        content: 'Notified Contacts',
        filter: true,
      },
    ];

    headerArray
      .filter(f => f.filter)
      .forEach(h => {
        // eslint-disable-next-line no-param-reassign
        h.className =
          column === h.key || (Array.isArray(column) && h.key === 'timestamp')
            ? `${direction} sorted`
            : undefined;

        // eslint-disable-next-line no-param-reassign
        h.onClick = onHeaderClick(h.key);

        // eslint-disable-next-line no-param-reassign
        h.filter = undefined;
      });

    return headerArray;
  };

  tableBody = (x, i) => {
    const { caregiverId } = this.props;
    const type = this.typeColumn(x.type);
    const details = x.details || {};

    const roles = x.changerRoleIds
      .map(c => {
        switch (c) {
          case 1:
            return 'Caregiver';
          case 2:
            return 'Employee';
          case 3:
            return 'Admin';
          default:
            return '';
        }
      })
      .join(', ');

    return {
      key: `${x.id}-${i}}`,
      className: 'infoText',
      cells: [
        { key: 'timestamp', content: this.timestampColumn(x.timestamp) },
        { key: 'changedby', content: x.userName },
        { key: 'changersRole', content: roles },
        { key: 'type', content: this.capitalize(type) },
        ...(caregiverId
          ? []
          : [
              {
                key: 'record',
                content:
                  type === 'caregiver' ? (
                    <Overlay caregiver={{ id: x.caregiverId, caregiverName: x.caregiverName }} />
                  ) : (
                    <Overlay client={{ id: x.clientId, clientName: x.clientName }} />
                  ),
              },
            ]),
        { key: 'field', content: this.alteredFieldColumn(details.key, details.name) },
        { key: 'from', content: this.valueColumn(details.key, details.old) },
        { key: 'to', content: this.valueColumn(details.key, details.new) },
        {
          key: 'contact',
          content:
            type === 'caregiver' ? x.caregiverPrimaryContactName : x.clientPrimaryContactName,
        },
        { key: 'notified', content: x.notificationRecipients },
      ],
    };
  };

  render() {
    const { column, data, direction } = this.state;

    return (
      <div>
        <TableWithExport
          className='changeLogResults'
          sortable
          singleLine
          striped
          fixed
          headerRow={this.tableHeader(column, direction, this.handleSort)}
          renderBodyRow={this.tableBody}
          tableData={this.props.loading && !this.props.partialUpdate ? [] : data}
          getOnClick={clickFunc => {
            this.onExportClick = clickFunc;
            return null;
          }}
        />
        <div className='loadMore'>
          <LoadMore
            onLoadMore={() => this.props.onLoadMore(this.props.nextPage)}
            loading={this.props.loading}
            partialUpdate={this.props.partialUpdate}
            nextPage={this.props.nextPage}
          />
        </div>
        {!this.props.loading && data && data.length === 0 && (
          <Grid className='noResults'>
            <NoMatch id='preferenceResultsNoMatch' />
          </Grid>
        )}
        {!data ||
          (this.props.loading && !this.props.partialUpdate && (
            <InitialState searchingFor='preference changes' loading={this.props.loading} />
          ))}
      </div>
    );
  }
}

ChangeLogPreferenceTable.defaultProps = {
  caregiverId: undefined,
};

ChangeLogPreferenceTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  partialUpdate: PropTypes.bool.isRequired,
  nextPage: PropTypes.string.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  caregiverId: PropTypes.number,
};

export default ChangeLogPreferenceTable;

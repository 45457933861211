import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Header, Image, Form, Segment } from 'semantic-ui-react';
import { history } from '../helpers';
import { userActions, alertActions } from '../actions';
import { isValidEmail } from '../constants/Formats';
import WizardView from './subcomponents/WizardView';
import './Login.scss';

class LoginAskToken extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: undefined,
      email: '',
      emailSent: false,
    };

    this.handleMagicLink = this.handleMagicLink.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.caregiverLoginContent = this.caregiverLoginContent.bind(this);
    this.backToSchedulerLogin = this.backToSchedulerLogin.bind(this);
  }

  onViewChanged = () => {};

  handleChange = (e, { name, value }) => this.setState({ [name]: value, emailSent: false });

  handleMagicLink = e => {
    e.preventDefault();

    const { email } = this.state;
    if (isValidEmail(email)) {
      this.setState({
        alert: undefined,
        emailSent: true,
      });
      const { dispatch } = this.props;
      dispatch(alertActions.clearErrors());
      dispatch(
        userActions.getMagicLogin(email, () => {
          this.wizardView.changeForm(LoginAskToken.views.emailSent);
        })
      );
    } else {
      this.setState({
        alert: 'Please enter a valid email',
        emailSent: false,
      });
    }
  };

  // eslint-disable-next-line react/sort-comp
  caregiverLoginContent() {
    const { email, emailSent } = this.state;
    const { loading } = this.props;
    const enabled = email.length > 0;

    return (
      <div style={{ marginTop: '60px' }}>
        <Grid className='actionArea'>
          <Grid.Column verticalAlign='middle'>
            <p>Enter your email to get your login link</p>
            <Form loading={loading} onSubmit={this.handleMagicLink}>
              <Form.Input
                id='loginEmail'
                fluid
                size='large'
                placeholder='Email'
                name='email'
                value={email}
                onChange={this.handleChange}
              />
              <Form.Button
                id='magicLoginButton'
                loading={loading}
                className='care-blue'
                disabled={!enabled || loading || emailSent}
                primary
                content='Get Login Link'
              />
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  backToSchedulerLogin = () => {
    history.push('/login');
  };

  renderContent(index) {
    const { email } = this.state;

    return (
      <div>
        {index === LoginAskToken.views.login && this.caregiverLoginContent()}
        {index === LoginAskToken.views.emailSent && (
          <div style={{ marginTop: '100px' }}>
            <h3>Login link is sent to email:</h3>
            <b>{email}</b>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { alert } = this.props;
    let alertMsg = alert;
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.alert) alertMsg = this.state.alert;

    return (
      <div className='login-form'>
        <Grid centered textAlign='center'>
          <Grid.Row>
            <Grid.Column width='three' verticalAlign='top'>
              <Header as='h2'>
                <Image src='/logo_login.svg' />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width='six' verticalAlign='middle'>
              {alertMsg && (
                <Segment id='loginAlert' basic className='error'>
                  {alertMsg}
                </Segment>
              )}
              <Segment className='contentBox'>
                <div id='wizardParent'>
                  <WizardView
                    onViewChanged={this.onViewChanged}
                    renderContent={this.renderContent}
                    ref={input => {
                      this.wizardView = input;
                    }}
                  />
                  <Image src='/circlesLogin.svg' fluid className='footer' />
                </div>
              </Segment>
              <Segment
                id='backToSchedulerLogin'
                basic
                className='forgetPassword'
                as='a'
                onClick={this.backToSchedulerLogin}
              >
                I have a password
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row />
          <Grid.Row className='footer' verticalAlign='bottom'>
            <Image fluid src='/login_footer.svg' />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

LoginAskToken.propTypes = {
  dispatch: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    message: PropTypes.string,
    success: PropTypes.bool,
    error: PropTypes.bool,
  }),
  loading: PropTypes.bool,
};

LoginAskToken.defaultProps = {
  loading: false,
  alert: undefined,
  // eslint-disable-next-line react/default-props-match-prop-types
  location: {},
};

LoginAskToken.views = {
  login: 0,
  emailSent: 1,
};

const mapStateToProps = state => {
  const { alert, principal } = state;
  const lastAlert = alert ? alert[alert.length - 1] : null;

  let alertMsg;
  if (lastAlert) {
    switch (true) {
      case lastAlert.httpStatus === 404:
        alertMsg = 'The account was not found';
        break;
      default:
        alertMsg = 'Failed to handle your request';
    }
  }

  const { loading } = principal;
  return {
    alert: alertMsg,
    loading,
  };
};

export default connect(mapStateToProps)(LoginAskToken);

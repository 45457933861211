/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { RegularText, SmallText, TitleHeader } from '../../../../styles/commonStyled';
import {
  ColumnsContainer,
  DocLinkText,
  LinkContainer,
  Training,
  TrainingColumn,
  TrainingHeader,
  JustifyContainer,
} from './styles';
import { IEvents } from './trainingTypes';
import colors from '../../../../styles/colors';
import common, { dateFormat } from '../../../../helpers/common';
import { BlockField, IDocumentValue } from '../ComplianceMedicalTypes';
import { LinkButton } from '../../../../styles/common';
import ConfirmDeletionModal from './ConfirmDeletionModal';
import { caregiverActions } from '../../../../actions';

const { neutrals } = colors;

interface IProps {
  training: IEvents;
  documents: BlockField | null;
  caregiverId: number;
}

const minutesToHrsMins = (minutes: any) => {
  if (Number.isNaN(parseInt(minutes, 10))) return null;
  if (minutes === 0) return 0;
  const hh = Math.floor(minutes / 60);
  const mm = minutes % 60;
  const hString = hh > 0 ? `${hh}h ` : '';
  const mString = mm > 0 ? `${mm}min ` : '';
  return `${hString}${mString}`;
};

const TrainingEvent: React.FC<IProps> = ({ training, documents, caregiverId }) => {
  const dispatch = useDispatch();
  const employees = useSelector((state: any) => state.employee);
  const [removeModalDisplay, setRemoveModalDisplay] = useState(false);
  const { employeeList } = employees;
  const employeeName = common.getEmployeeName(employeeList, training.supervisorId);

  const trainingDocuments = ((docs: IDocumentValue[], trainingEvent: IEvents) => {
    if (!docs) {
      return [];
    }
    return docs.filter(
      (d: IDocumentValue) => trainingEvent.documents && trainingEvent.documents.includes(d.filename)
    );
  })(documents?.value, training);

  const toggleRemoveModal = () => {
    setRemoveModalDisplay(!removeModalDisplay);
  };

  const handleConfirmRemove = () => {
    dispatch(caregiverActions.removeCaregiverTrainingEvent(caregiverId, training.id));
    toggleRemoveModal();
  };

  return (
    <>
      {removeModalDisplay && (
        <ConfirmDeletionModal onCancel={toggleRemoveModal} onConfirm={handleConfirmRemove} />
      )}
      <Training>
        <img src='/compliance/inservicetraining.svg' alt='' width='30' />
        <div>
          <TrainingHeader>
            <TitleHeader>{training.title}</TitleHeader>
            <TitleHeader>{minutesToHrsMins(training.inServiceMinutes)}</TitleHeader>
          </TrainingHeader>
          <SmallText color={neutrals.charcoal}>{training.description}</SmallText>
          <ColumnsContainer>
            <TrainingColumn>
              <div>
                <SmallText>Completion Date:</SmallText>{' '}
                <RegularText>{moment(training.completionDate).format(dateFormat)}</RegularText>
              </div>
            </TrainingColumn>
            {!!training.supervisorId && (
              <TrainingColumn>
                <div>
                  <SmallText>Supervisor</SmallText> <RegularText>{employeeName}</RegularText>
                </div>
              </TrainingColumn>
            )}
            {!!trainingDocuments.length && (
              <div>
                <SmallText>Doc</SmallText>
                {trainingDocuments.map((d, idx) => {
                  if (!documents?.downloadLinks) {
                    return null;
                  }
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <LinkContainer key={d.id + idx}>
                      <a href={documents?.downloadLinks[d.filename] || '#'}>
                        <DocLinkText>{d.originalName}</DocLinkText>
                      </a>
                    </LinkContainer>
                  );
                })}
              </div>
            )}
          </ColumnsContainer>
          {training.id > 0 && (
            <JustifyContainer alignItems='center' justifyContent='flex-end'>
              <LinkButton
                type='button'
                aria-label='remove'
                padding='0'
                height='1.5rem'
                onClick={toggleRemoveModal}
              >
                remove
              </LinkButton>
            </JustifyContainer>
          )}
        </div>
      </Training>
    </>
  );
};

export default TrainingEvent;

/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

export const EventContent = styled(Modal.Content)`
  &&&& {
    padding: 1rem 5rem 2rem 5rem;
    & > div.grid .row {
      padding: 0.5rem 0;
      justify-content: flex-start;
    }
  }
`;

export const VirtualEventContainer = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin: 0 1rem 0 0;
  }
`;

export const EventAction = styled(Modal.Actions)`
  &&&& {
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
`;

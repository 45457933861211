/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { Grid, Modal, Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';
import HRCalendarView from './subcomponents/hrApp/HRCalendarView';
import { complianceSettingsActions } from '../actions';
import EventView from './subcomponents/hrApp/eventView';
import CreateEventView from './subcomponents/hrApp/createEventModal';
import './HRApp.css';
import { eventActions } from '../actions/hrapp/eventActions';
import CareLoader from './subcomponents/CareLoader';

class HRCalendar extends React.Component {
  constructor() {
    super();

    this.state = { selectedEvenIdt: null, modalVisible: false, createdSelected: false };
  }

  componentDidMount() {
    this.props.dispatch(complianceSettingsActions.getComplianceTrainingCourses());
    this.props.dispatch(eventActions.getEvents());
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { eventToSelect, createdEvent } = next.trainingEvent;
    if (eventToSelect !== undefined) {
      this.setState({ selectedEventId: eventToSelect, createdSelected: createdEvent });
      if (createdEvent) this.setState({ modalVisible: false });
      this.props.dispatch(eventActions.clearEventToSelect());
    }
  }

  deleteEvent = event =>
    this.setState(
      s => ({ ...s, selectedEventId: null, createdSelected: false }),
      () => this.props.dispatch(eventActions.removeEvent(event))
    );

  render() {
    const { modalVisible, selectedEventId, draftEvent } = this.state;
    const { events, loadingEvents, submittingEvent } = this.props.trainingEvent;
    const selectedEvent = selectedEventId ? events.find(e => e.id === selectedEventId) : null;

    const onSelect = event => {
      if (event.id !== selectedEventId) {
        this.setState({ selectedEventId: event.id, createdSelected: false });
      }
    };

    const onSlotSelected = slot => {
      if (
        moment(slot.start)
          .add(0.5, 'hours')
          .isAfter(moment()) ||
        moment(slot.start).isSame(moment().startOf('day'))
      ) {
        const start = moment(slot.start).toDate() < moment().toDate() ? moment() : slot.start;
        const end =
          moment(slot.end).toDate() <
          moment()
            .add(0.5, 'hours')
            .toDate()
            ? moment().add(0.5, 'hours')
            : slot.end;

        const draft = {
          id: events.length,
          allDay: slot.start.getHours() === 0 && slot.end.getHours() === 0,
          name: 'Title',
          start,
          end,
          hours: moment(end).diff(moment(start), 'minutes') / 60.0,
          blockId: this.props.trainingEvent.preSelectedBlockId,
        };
        this.setState({ draftEvent: draft, modalVisible: true });
      }
    };

    const eventChanged = event => {
      this.setState({ createdSelected: false });
      const eventToSave = events.find(e => e.id === event.id);
      Object.assign(eventToSave, { event });
    };

    const eventSubmitted = event => {
      this.setState({ createdSelected: false });
      const eventToSave = events.find(e => e.id === event.id);
      Object.assign(eventToSave, { event });
    };

    const eventVisible = !!(selectedEvent && selectedEvent.block);
    const loading = loadingEvents || submittingEvent;

    return (
      <Grid style={{ width: '100%' }} className='hrapp'>
        {loading && (
          <Dimmer active inverted page>
            <CareLoader loading showText={false} />
          </Dimmer>
        )}
        <Grid.Column width={16}>
          <div style={{ height: '100%', minHeight: '700px' }}>
            <HRCalendarView
              defaultView='week'
              minHeight='800px'
              maxHeight='1200px'
              events={events}
              onSelect={onSelect}
              onSlotSelected={onSlotSelected}
            />
          </div>
        </Grid.Column>
        <Modal
          dimmed
          open={eventVisible}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          closeIcon
          size='fullscreen'
          className='hrapp'
          onClose={() => this.setState({ selectedEventId: null, createdSelected: false })}
        >
          <Modal.Content>
            <EventView
              event={selectedEvent}
              onChanged={eventChanged}
              onSubmitted={eventSubmitted}
              deleteEvent={this.deleteEvent}
            />
          </Modal.Content>
        </Modal>
        {modalVisible && (
          <CreateEventView
            draftEvent={draftEvent}
            onClose={() => this.setState({ modalVisible: false })}
            isCalendarView
          />
        )}
      </Grid>
    );
  }
}

HRCalendar.defaultProps = {};

HRCalendar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  trainingEvent: PropTypes.shape().isRequired,
};

const mapStateToProps = state => {
  const { principal, employee, trainingEvent } = state;
  return {
    principal,
    trainingEvent,
    employees: employee.employeeList,
  };
};

export default connect(mapStateToProps)(injectIntl(HRCalendar));

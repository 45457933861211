/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import './ELDashboard.css';

function ELSideInfo({ path }) {
  const contactPhone = '(855) 858-4388';
  const contactText = '(855) 858-4388';
  const contactEmail = () => (
    <a href='mailto:support@careconnectmobile.com' target='blank' className='e-learning-help-email'>
      support@careconnectmobile.com
    </a>
  );
  const coursesInfo = () => (
    <div className='course-info-container'>
      <p className='course-info-dark'>
        This is your area for viewing all the training modules that you have been assigned.
      </p>
      <br />
      <p className='course-info-dark'>
        <b>Your Course List</b> contains all the modules you have been assigned and any you have
        started but have yet to complete.
      </p>
      <br />
      <p className='course-info-dark'>
        <b>Completed Courses</b> will show all the modules you have successfully completed.
      </p>
    </div>
  );
  let extraInfo;
  if (path === 'courses') {
    extraInfo = coursesInfo();
  } else if (path === 'manage') {
    extraInfo = 'manage';
  } else {
    extraInfo = null;
  }
  return (
    <div className='e-learning-help-container'>
      {extraInfo}
      <h4 className='e-learning-help-header'>HELP</h4>
      <p className='e-learning-side-help'>
        Contact us if you have any questions or need help with your training. If you are completing
        In-Service training, then you can also contact us to speak with an RN for questions on the
        course content.
      </p>
      <div style={{ paddingBottom: '19px' }} />
      <p className='e-learning-side-help'>
        You can reach us by…
        <br />
        {`Phone: ${contactPhone}`}
        <br />
        {`Text: ${contactText}`}
        <br />
        E-mail: {contactEmail()}
      </p>
    </div>
  );
}

ELSideInfo.defaultProps = {
  path: undefined,
};

ELSideInfo.propTypes = {
  path: PropTypes.string,
};

export default ELSideInfo;

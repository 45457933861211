/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Grid, Button, Image } from 'semantic-ui-react';
import { Form, Dropdown } from 'formsy-semantic-ui-react';
import styled from 'styled-components';

const PhoneTypeMenu = styled(Dropdown)`
  &&& {
    font-size: 12px;
    font-weight: normal;
    color: #a9adb4 !important;
  }
  .hideIcon i.icon {
    display: none;
  }
`;

class PhoneInput extends React.Component {
  constructor(props) {
    super();
    this.state = { phone: props.phone };
    this.onDelete = this.onDelete.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e, input) {
    this.props.onValueChange(this.props.index, input);
  }

  onDelete() {
    this.props.onPhoneDelete(this.props.index);
  }

  render() {
    const { intl, phone, disabled, phones } = this.props;

    const phoneOptions = [
      { key: '1', text: intl.formatMessage({ id: 'records.homePhone' }), value: 1, enable: true },
      { key: '2', text: intl.formatMessage({ id: 'records.workPhone' }), value: 2, enable: true },
      // { key: '3', text: intl.formatMessage({ id: 'records.cellPhone' }), value: 3 },
      // { key: '4', text: intl.formatMessage({ id: 'records.fax' }), value: 4 },
    ];

    const avaliableOptions = phoneOptions.filter(
      item =>
        phones
          .filter(p => p.id)
          .map(p => p.type)
          .indexOf(item.value) === -1
    );

    const type = phoneOptions.filter(p => p.value === phone.type);
    const typeName = type && type[0] ? type[0].text : 'Select';

    const instantValidation = true;

    return (
      <Grid.Row>
        <Grid.Column width={16}>
          <PhoneTypeMenu
            className={avaliableOptions.length > 0 ? '' : 'hideIcon'}
            text={typeName}
            name='phoneType'
            options={avaliableOptions}
            size='tiny'
            defaultValue={phone.type}
            onChange={this.onChange}
            disabled={disabled}
          />
          <Form.Group className='input-with-delete'>
            <Form.Input
              id='phone-input-form-input'
              style={{
                fontSize: '14px',
                color: '#4a4a4a',
                fontWeight: 'normal',
              }}
              fluid
              name='phoneNumber'
              placeholder={typeName}
              width={16}
              validations={{
                matchRegexp: /^[0-9+()#.\s/ext-]+$/,
                minLength: 6,
                maxLength: 30,
              }}
              validationErrors={{ isValid: 'email not valid' }}
              instantValidation={instantValidation}
              onChange={this.onChange}
              value={phone.number}
              disabled={disabled}
            />
            {!disabled && (
              <Button
                id='phone-input-delete-button'
                type='button'
                onClick={this.onDelete}
                disabled={disabled}
              >
                <Image inline size='mini' src='/icon_trashcan.svg' />
              </Button>
            )}
          </Form.Group>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

PhoneInput.defaultProps = {
  phone: {
    type: 3,
    number: '',
  },
  editable: true,
};

PhoneInput.propTypes = {
  intl: intlShape.isRequired,
  phone: PropTypes.shape({
    number: PropTypes.string,
    type: PropTypes.number,
  }),
  phones: PropTypes.shape().isRequired,
  onPhoneDelete: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default injectIntl(PhoneInput);

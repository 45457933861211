import { Grid, Header, Dropdown } from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import { beginningSearch } from '../../constants/Formats';

const Languages = ({ object, editable, languages, onValueChange }) => {
  const capitalize = s => s.toLowerCase().replace(/\b./g, a => a.toUpperCase());
  const selected = object.languages ? object.languages.filter(i => i.value === 'Y') : [];
  const ids = selected.map(i => i.id);
  const options = languages.map(i => ({ value: i.value, text: i.text }));

  return editable ? (
    <Grid.Row>
      <Grid.Column width={5}>
        <Header size='tiny' floated='left'>
          Languages:
        </Header>
      </Grid.Column>
      <Grid.Column width={11}>
        <Header size='tiny'>
          <Dropdown
            id='languages-editable-dropdown'
            name='languages'
            placeholder='Languages'
            floating
            selection
            multiple
            search={beginningSearch}
            upward
            options={options}
            value={ids}
            onChange={onValueChange}
          />
        </Header>
      </Grid.Column>
    </Grid.Row>
  ) : (
    <Grid.Row>
      <Grid.Column width={5}>
        <Header size='tiny' floated='left'>
          Languages:
        </Header>
      </Grid.Column>
      <Grid.Column width={11}>
        <Header id='languages-not-editable-header' size='tiny'>
          {selected.map(i => capitalize(i.name)).join(', ')}
        </Header>
      </Grid.Column>
    </Grid.Row>
  );
};

Languages.propTypes = {
  object: PropTypes.shape().isRequired,
  editable: PropTypes.bool.isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onValueChange: PropTypes.func.isRequired,
};

export default Languages;

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Grid, Image } from 'semantic-ui-react';
import './complianceTabs.css';

class ComplianceTabs extends React.Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/no-unused-state
    this.state = { activeTab: 0 };
  }

  tab = (t, i) => {
    const select = () => {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.onSelect(i);
    };

    if (t.isActive) {
      return (
        <Grid.Column key={`tab${i}`} style={{ padding: '0px' }} onClick={select}>
          <div className='itemContainer'>
            <div className='img'>
              <Image src={t.image} />
            </div>
            <div className='text'>{t.name}</div>
          </div>
        </Grid.Column>
      );
    }
    return (
      <Grid.Column key={`tab${i}`} style={{ padding: '0px' }} onClick={select}>
        <div className='itemContainer dimmed'>
          <div className='img'>
            <Image src={t.image} />
          </div>
          <div className='text'>{t.name}</div>
        </div>
      </Grid.Column>
    );
  };

  render() {
    const { tabs } = this.props;
    return (
      <Grid
        className='complianceTabs'
        columns={tabs.length}
        style={{
          padding: '0px',
          marginLeft: '16px',
          marginRight: '16px',
          marginBottom: '16px',
        }}
      >
        {tabs.map((t, i) => this.tab(t, i))}
      </Grid>
    );
  }
}

ComplianceTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default injectIntl(ComplianceTabs);

import React from 'react';
import { Image } from 'semantic-ui-react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { avatarStyle, avatarUrl } from '../../../constants/DomainTypes';
import { history } from '../../../helpers';

class HRCaregiverNameCell extends React.Component {
  onNameClick(x) {
    const { sourcePath } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    history.push(`/home/hr/${sourcePath}/records/${x.id}`, { prevPath: this.props.location });
  }

  render() {
    const { caregiver } = this.props;
    const toCg = x => (
      <div className='overlayDirect'>
        <div key={x.id} className='name'>
          <Image
            id='hr-caregiver-name-cell-avatar'
            avatar
            centered
            circular
            size='mini'
            floated='left'
            style={avatarStyle(x.avatar, x.gender)}
            src={avatarUrl(x.avatar, x.gender)}
          />
          <div className='nameBlock'>
            <div
              id='hr-caregiver-name-cell-name-block'
              role='presentation'
              className='overlayLink'
              onClick={() => this.onNameClick(x)}
            >
              {x.firstName} {x.lastName}
            </div>
          </div>
        </div>
      </div>
    );

    return <Route render={() => toCg(caregiver)} />;
  }
}

HRCaregiverNameCell.propTypes = {
  caregiver: PropTypes.shape().isRequired,
  sourcePath: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
};

export default HRCaregiverNameCell;

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import { Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'; // ES6

import './Alerts.css';
import { alertActions } from '../../actions/alert';
import { history } from '../../helpers';

class Alerts extends React.Component {
  constructor() {
    super();
    this.onActionClick = this.onActionClick.bind(this);
  }

  // eslint-disable-next-line react/state-in-constructor
  state = {
    alerts: [],
  };

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.alert) {
      this.mapProps(nextProps);
    }
  }

  onActionClick(action, close) {
    return () => {
      if (!close && action.dispatch) {
        this.props.dispatch(action.dispatch);
      }
      if (!close && action.url) {
        history.push(action.url);
      }
      this.props.dispatch(alertActions.clear(action.id));
    };
  }

  mapProps(props) {
    const { alert } = props;
    const groups = {};

    alert
      .filter(i => !!i.group)
      .forEach(i => {
        if (!groups[i.group] || groups[i.group].priority < i.priority) {
          groups[i.group] = i;
        }
      });

    const pickAlerts = () => {
      if (groups.login) {
        return [groups.login];
      }
      if (groups.error) {
        return [groups.error];
      }
      return alert;
    };

    this.setState({ alerts: pickAlerts() });
  }

  render() {
    const { alerts } = this.state;

    const alertBoxes = alerts.map(a => {
      const typeClass = a.error ? 'error' : 'success';

      return (
        <div className={`alertBox show ${typeClass}`} key={a.id}>
          {a.avatar && (
            <div className='img'>
              <Image avatar centered circular size='mini' src={a.avatar} />
            </div>
          )}
          <div className='msg'>{a.message}</div>
          <div className='action'>
            {a.label && (
              <div
                className={a.cancel ? 'btn one' : 'btn single'}
                onClick={this.onActionClick(a)}
                role='presentation'
              >
                <div>{a.label}</div>
              </div>
            )}
            {((a.label && a.cancel) || !a.label) && (
              <div
                className={a.label && a.cancel ? 'btn two' : 'btn single'}
                onClick={this.onActionClick(a, true)}
                role='presentation'
              >
                <div>Close</div>
              </div>
            )}
          </div>
          <div style={{ clear: 'both' }} />
        </div>
      );
    });

    return (
      <div className='alertsContainer'>
        <ReactCSSTransitionGroup
          transitionName='alert'
          transitionEnterTimeout={700}
          transitionLeaveTimeout={700}
        >
          {alertBoxes}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}

Alerts.propTypes = {
  dispatch: PropTypes.func.isRequired,
  alert: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => {
  const { alert } = state;
  return {
    alert,
  };
};

export default connect(mapStateToProps)(Alerts);

import {
  GET_USER_COURSES_LIST_REQUEST,
  GET_USER_COURSES_LIST_SUCCESS,
  GET_USER_COURSES_LIST_FAILURE,
  GET_USER_COURSES_DETAIL_REQUEST,
  GET_USER_COURSES_DETAIL_SUCCESS,
  GET_USER_COURSES_DETAIL_FAILURE,
  CLEAR_COURSES_LIST,
} from '../../constants/ActionTypes';

const initialState = {
  loading: false,
  courses: [],
  courseDetails: {},
  buttonLoading: false,
};

const startState = JSON.parse(JSON.stringify(initialState));

function userCourses(state = startState, action) {
  switch (action.type) {
    case GET_USER_COURSES_LIST_REQUEST:
      return { ...state, loading: true };
    case GET_USER_COURSES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        refreshCourseList: false,
        courses: [...action.results.courses],
        refreshInprogress: true,
      };
    case GET_USER_COURSES_LIST_FAILURE:
      return { ...state, loading: false };
    case CLEAR_COURSES_LIST:
      return { ...state, courses: [] };
    case GET_USER_COURSES_DETAIL_REQUEST:
      return { ...state, loading: true };
    case GET_USER_COURSES_DETAIL_SUCCESS: {
      let newCourseDetails = {};
      if (action.results.userCourse) {
        newCourseDetails = {
          userCourse: action.results.userCourse,
          modules: action.results.modules,
          supportPhone: action.results.supportPhone,
        };
      }
      return {
        ...state,
        loading: false,
        courseDetails: newCourseDetails,
        refreshInProgress: false,
        exitedModule: false,
      };
    }
    case GET_USER_COURSES_DETAIL_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export default userCourses;

const INIT_CONFIG = `
{
  "network": {
    "domain": "my-agency-tracking.at.careconnectmobile.com",
    "locked": false
  },
  "layout": {
    "backgroundColor": "#fff",
    "fontColor": "#000",
    "fontSize": "666",
    "headerImage": "https://s3.amazonaws.com/static.careconnectmobile.com/dev/agencies/default/app-tool-header.png",
    "logoImage": "https://s3.amazonaws.com/static.careconnectmobile.com/dev/agencies/default/agency-logo.jpg"
  },
  "pages": {
    "agencyWelcomeNote": {
      "active": true,
      "required": true,
      "order": 0,
      "fields": [
        {
          "deletable": false,
          "id": "header",
          "name": "Page Header",
          "type": "text",
          "value": "Thank you for your interest in...",
          "visible": true
        }
      ]
    },
    "basicInformation": {
      "active": true,
      "required": true,
      "order": 1,
      "fields": [
        {
          "deletable": false,
          "id": "firstName",
          "label": "First Name",
          "order": 0,
          "required": true,
          "type": "textField",
          "visible": true
        },
        {
          "deletable": false,
          "id": "lastName",
          "label": "Last Name",
          "order": 1,
          "required": true,
          "type": "textField",
          "visible": true
        },
        {
          "deletable": false,
          "id": "email",
          "label": "Email Address",
          "order": 2,
          "required": true,
          "type": "textField",
          "visible": true
        },
        {
          "deletable": false,
          "id": "dob",
          "label": "Birthday",
          "order": 3,
          "required": true,
          "type": "birthdayField",
          "visible": true
        },
        {
          "deletable": false,
          "id": "ssn",
          "label": "Social Security Number",
          "order": 4,
          "required": true,
          "type": "ssnField",
          "visible": true
        }
      ]
    },
    "additionalInformation": {
      "active": true,
      "required": true,
      "order": 2,
      "fields": [
        {
          "deletable": false,
          "id": "address",
          "label": "Address",
          "order": 0,
          "required": true,
          "type": "addressField",
          "visible": true,
          "value": {
            "aptUnit": "",
            "city": "",
            "state": "",
            "street": "",
            "zip": ""
          }
        },
        {
          "deletable": false,
          "id": "cellPhone",
          "label": "Cell Phone",
          "order": 1,
          "required": true,
          "type": "textField",
          "visible": true
        },
        {
          "deletable": true,
          "id": "homePhone",
          "label": "Home Phone",
          "order": 2,
          "required": false,
          "type": "textField",
          "visible": true
        },
        {
          "deletable": false,
          "id": "gender",
          "label": "Gender",
          "order": 3,
          "required": true,
          "type": "genderSelector",
          "visible": true
        }
      ]
    },
    "workExperience": {
      "active": true,
      "required": false,
      "order": 3,
      "fields": [
        {
          "deletable": false,
          "id": "relevantYears",
          "label": "Relevant Experience",
          "options": [
            "0-4 years",
            "5-10 years",
            "10-20 years",
            "20+ years"
          ],
          "order": 0,
          "required": true,
          "type": "dropdown",
          "visible": true
        },
        {
          "deletable": true,
          "id": "previousEmployers",
          "label": "Previous Employer",
          "order": 1,
          "required": false,
          "type": "previousEmployersField",
          "visible": true
        }
      ]
    },
    "backgroundInformation": {
      "active": true,
      "required": false,
      "order": 4,
      "fields": [
        {
          "deletable": true,
          "id": "idUpload",
          "label": "Drivers License/ID Upload",
          "order": 0,
          "required": false,
          "type": "idUploadField",
          "visible": true
        },
        {
          "deletable": false,
          "id": "ageDeclaration",
          "label": "Age Declaration",
          "order": 1,
          "required": true,
          "type": "ageDeclarationField",
          "value": false,
          "visible": true
        }
      ]
    },
    "scheduleWorkPreferences": {
      "active": true,
      "required": false,
      "order": 5,
      "fields": [
        {
          "deletable": true,
          "id": "startDate",
          "label": "What date are you available to start work?",
          "order": 0,
          "required": true,
          "type": "dateSelector",
          "visible": true
        },
        {
          "deletable": true,
          "id": "kindOfWork",
          "label": "What kind of work are you interested in?",
          "options": [
            "Full time",
            "Part Time",
            "Temp / PRN / As Needed"
          ],
          "order": 1,
          "required": true,
          "type": "multiCheckbox",
          "visible": true
        },
        {
          "deletable": true,
          "id": "preferredShiftTime",
          "label": "What preferred shift times are you looking for?",
          "options": [
            "Day Shifts",
            "Evening Shifts",
            "Overnight Shifts"
          ],
          "order": 2,
          "required": true,
          "type": "multiCheckbox",
          "visible": true
        },
        {
          "deletable": true,
          "id": "weekdays",
          "label": "Are you willing to work any week days? (Mon - Fri)",
          "order": 3,
          "required": false,
          "type": "checkbox",
          "visible": true
        },
        {
          "deletable": true,
          "id": "weekends",
          "label": "Are you willing to work any weekends? (Sat - Sun)",
          "order": 4,
          "required": false,
          "type": "checkbox",
          "visible": true
        },
        {
          "deletable": true,
          "id": "liveins",
          "label": "Are you willing to work Live-ins?",
          "order": 5,
          "required": false,
          "type": "checkbox",
          "visible": true
        }
      ]
    },
    "shiftClientPreferences": {
      "active": true,
      "required": true,
      "order": 6,
      "fields": [
        {
          "deletable": false,
          "id": "primaryLanguage",
          "label": "Primary Language",
          "order": 0,
          "required": true,
          "type": "primaryLanguageField",
          "visible": true
        },
        {
          "deletable": true,
          "id": "additionalLanguages",
          "label": "Additional Languages",
          "order": 1,
          "required": false,
          "type": "additionalLanguageField",
          "visible": true
        },
        {
          "deletable": true,
          "id": "smokers",
          "label": "Will you work in a home with smokers present?",
          "order": 2,
          "required": false,
          "type": "checkbox",
          "visible": true
        },
        {
          "deletable": true,
          "id": "dogs",
          "label": "Will you work in a home where there are dogs?",
          "order": 3,
          "required": false,
          "type": "checkbox",
          "visible": true
        },
        {
          "deletable": true,
          "id": "cats",
          "label": "Will you work in a home where there are cats?",
          "order": 4,
          "required": false,
          "type": "checkbox",
          "visible": true
        },
        {
          "deletable": true,
          "id": "travelTime",
          "label": "How long are you willing to travel to a shift?",
          "order": 5,
          "required": true,
          "type": "travelTimeField",
          "visible": true
        },
        {
          "deletable": true,
          "id": "transportMethod",
          "label": "Form of Transport",
          "order": 6,
          "required": true,
          "type": "travelMethodSelector",
          "visible": true
        }
      ]
    },
    "completionPage": {
      "active": true,
      "required": true,
      "order": 7,
      "fields": [
        {
          "deletable": false,
          "id": "header",
          "name": "Page Header",
          "type": "text",
          "value": "Thank you! Your application has been submitted.",
          "visible": true
        }
      ]
    }
  }
}`;

export default INIT_CONFIG;

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Modal, Container } from 'semantic-ui-react';
import styled from 'styled-components';

import { GreenButton, RedButton } from '../styles/common';

const WarningModal = styled(Modal)`
  .header {
    background-color: #dd6071 !important;
    color: white !important;
    text-align: center !important;
  }

  .content {
    padding-left: 10% !important;
    padding-right: 10% !important;
    background-color: #f7f7f7 !important;

    .container {
      font-weight: 700;
      text-align: center;
      margin-bottom: 2rem;
    }
  }
  margin-top: -20vh !important;
`;

const UnsavedChangesModal = ({ intl, onCancel, onDiscard }) => (
  <WarningModal closeOnDimmerClick={false} size='tiny' open onClose={onCancel} closeIcon dimmer>
    <Modal.Header>{intl.formatMessage({ id: 'modal.unsaved.header' })}</Modal.Header>
    <Modal.Content>
      <Container>{intl.formatMessage({ id: 'modal.unsaved.description' })}</Container>
      <GreenButton onClick={onCancel}>
        {intl.formatMessage({ id: 'modal.unsaved.review' })}
      </GreenButton>
      <RedButton floated='right' onClick={onDiscard}>
        {intl.formatMessage({ id: 'modal.unsaved.discard' })}
      </RedButton>
    </Modal.Content>
  </WarningModal>
);

UnsavedChangesModal.propTypes = {
  intl: intlShape.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
};

export default injectIntl(UnsavedChangesModal);

import { Grid, Header, TextArea } from 'semantic-ui-react';
import styled from 'styled-components';
import { RecordsTile, RecordsHeader } from '../../../../styles/records';

export const StyledTextarea = styled(TextArea)`
  &&& {
    background: #eee;
    resize: none;
    border: none;
    rows: 10;
    height: 100px;
  }

  &&&:focus {
    background: #eee;
  }
`;

export const HeaderContainer = styled.div`
  display: grid;
  position: relative;
  grid-template-rows: 1fr 0.2fr;
  grid-template-columns: 0.1fr 1.2fr 0.1fr;
  justify-items: self-start;
  align-items: flex-start;
  top: -20px;
`;

export const HeaderText = styled(Header)`
  &&& {
    margin-bottom: 0 !important;
  }
`;

export const RecordHeader = styled(RecordsHeader)`
  &&&& {
    grid-column: 2/3;
    align-self: flex-start;
    margin-top: 0 !important;
    margin-bottom: 20px !important;
  }
`;

export const CheckBoxField = styled.div`
  grid-column: 3/4;
  grid-row: 1 / span 3;
  align-self: center;
`;

export const HorizontalLine = styled.hr`
  position: relative;
  width: 100% !important;
  bottom: 1em !important;
`;

export const RecordTile = styled(RecordsTile)`
  &&& {
    max-width: 38em;
    margin-top: 1.5em;
    margin-right: 1.5em;
  }
`;

export const BodyText = styled.p`
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #4a4a4a;
  margin-top: 10px !important;
`;

export const TinyHeader = styled.p`
  font-family: Lato, Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #4a4a4a;
  margin-top: 10px;
  font-weight: 900 !important;
`;

export const MiniHeaderContainer = styled.div`
  padding-top: 1em;
  padding-bottom: 1em;
  grid-column: 1 / span 3;
  grid-row: 1 / 2;
`;

export const HeaderIcon = styled.div`
  width: 5em;
  height: 5em;
  background-color: transparent;
  position: relative;
  grid-column: 1/2;
  grid-row: 1 / span 2;
  align-self: center;
  justify-self: flex-center;
  right: 1em;
  text-align: center;
  margin: 1 em 0;
`;

export const IconImage = styled.img`
  vertical-align: middle;
`;

export const IconAlignment = styled.span`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
`;

export const GridRow = styled(Grid.Row)`
  &&& {
    padding: 0 !important;
  }
`;

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import '../../CGCompliance.css';
import ComplianceDOHWorkhistory from './workhistory';
import TileGrid from '../records/TileGrid';
import ComplianceBlock from './complianceBlock';
import CommonComplianceBlock from './CommonComplianceBlock';

class ComplianceDocumentation extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  block = b => <ComplianceBlock block={b} key={b.id} />;

  render() {
    const { blocks, loadingDoh, dohError, doh } = this.props.caregiverCompliance;
    const workhistoryBlock = blocks ? blocks.find(b => b.type === 'workhistory') : null;

    return (
      <TileGrid>
        <div className='infoPanel'>
          <p className='tileHeader'>DOCUMENTATION</p>
          <p className='smallGray'>
            The following are document compliance items that must be tracked by law in your state.
            When an item is expiring, your caregiver will be alerted.
          </p>
          {blocks &&
            blocks
              .filter(
                b =>
                  b.type === 'documentation' &&
                  b.system &&
                  !b.includedInEligibility &&
                  !b.controlledInProfile
              )
              .map(b => this.block(b))}
        </div>
        {!dohError && workhistoryBlock && (
          <ComplianceDOHWorkhistory
            loadingDoh={loadingDoh}
            dohInfo={doh}
            onWorkhistoryAdded={this.props.onWorkhistoryAdded}
            onWorkhistoryDeleted={this.props.onWorkhistoryDeleted}
            hrManager={this.props.hrManager}
          />
        )}
        <div className='infoPanel'>
          <p className='tileHeader'>CUSTOM DOCUMENTATION ITEMS</p>
          <p className='smallGray'>
            The following are document compliance items that your agency has chosen to track in
            addition to the state required compliance items.
          </p>
          {blocks &&
            blocks
              .filter(
                b =>
                  b.type === 'documentation' &&
                  !b.system &&
                  !b.includedInEligibility &&
                  !b.controlledInProfile
              )
              .map(b => this.block(b))}
        </div>
        <div>
          {blocks &&
            blocks
              .filter(
                b => b.type === 'documentation' && b.controlledInProfile && !b.includedInEligibility
              )
              .map(b => <CommonComplianceBlock key={b.id} block={b} />)}
        </div>
      </TileGrid>
    );
  }
}

ComplianceDocumentation.propTypes = {
  caregiverCompliance: PropTypes.shape().isRequired,
  onWorkhistoryAdded: PropTypes.func.isRequired,
  onWorkhistoryDeleted: PropTypes.func.isRequired,
  hrManager: PropTypes.bool.isRequired,
};

export default injectIntl(ComplianceDocumentation);

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputOnChangeData } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Data } from 'react-csv/components/CommonPropTypes';
import {
  MainGrid,
  RailContainer,
  BarContainer,
  ChildContent,
  SelectAllSection,
} from './HRCaregiversContainer';
import '../HRCaregivers.css';
import '../HRCandidates.css';
import HRLeftRail from '../subcomponents/hrApp/hrLeftRail/HRLeftRail';
import HRCaregiverHeader from '../subcomponents/hrApp/HRCaregiverHeader/HRCaregiverHeader';
import HRCaregiverCandidatesTable from '../subcomponents/hrApp/HRCaregiversCandidatesTable/HRCaregiversCandidatesTable';
import { caregiverActions, complianceSettingsActions, uiStateActions } from '../../actions';
import CCTab from '../subcomponents/Tab/CCTab';
import { IHRCandidatesFilter, IHRCaregiversFilter } from '../../reducers/types/HRFilters';
import filterActions from '../../actions/hrapp/filterActions';
import { FilterSections } from '../subcomponents/hrApp/hrLeftRail/helper';
import hrHelper from '../../helpers/hrHelper';
import CaregiverEditor from '../CaregiverEditor';
import CaregiverDetails from '../CaregiverDetails';
import CreateEventView from '../subcomponents/hrApp/createEventModal';
import { ICaregiver, INewCaregiver } from '../../reducers/types/caregiver';
import { GreenButton } from '../../styles/common';
import { CheckBox } from '../../styles/commonStyled';
import { MOMENT_FULL_DATETIME } from '../../constants/Formats';

interface IProps {
  view: 'caregivers' | 'candidates';
}

const HRCaregiversView: React.FC<IProps> = ({ view }) => {
  const [selectedCandidates, setSelectedCandidates] = useState<number[]>([]);
  const [caregiverEditorVisible, setCaregiverEditorVisible] = useState(false);
  const [caregiverName, setCaregiverName] = useState('');
  const [candidateName, setCandidateName] = useState('');
  const [compliancePrintData, setCompliancePrintData] = useState<string | Data>([]);
  const [vaccinePrintData, setVaccinePrintData] = useState<string | Data>([]);
  const [caregiverDetailsId, setCaregiversDetailsId] = useState<number>();
  const [nonCompliantCaregivers, setNonCompliantCaregivers] = useState<ICaregiver[]>([]);
  const [readyToWorkCaregivers, setReadyToWorkCaregivers] = useState<ICaregiver[]>([]);
  const [inProgressCandidates, setInProgressCandidates] = useState<ICaregiver[]>([]);
  const [completeCandidates, setCompleteCandidates] = useState<ICaregiver[]>([]);
  const [activeTab, setActiveTab] = useState<number | string>('');
  const [createEventVisible, setCreateEventVisible] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const candidatesRote = view === 'candidates';

  const caregiversFilters = useSelector(
    (state: any) => state.HRCaregiverFilter as IHRCaregiversFilter
  );
  const candidatesFilters = useSelector(
    (state: any) => state.HRCandidatesFilter as IHRCandidatesFilter
  );

  const principal = useSelector((state: any) => state.principal);
  const {
    // loading,
    complianceSettings: { blocks = [], preHireBlocks = [] } = {},
    settings: { standaloneMode, vaccineServiceEnabled },
  } = principal;

  const complianceHRCaregivers = useSelector((state: any) => state.complianceHRCaregivers);

  const {
    complianceHRCaregivers: { totalCaregivers, caregivers = [], loading: caregiversLoading },
  } = complianceHRCaregivers;

  const candidatesTraining = useSelector((state: any) => state.candidatesTraining);

  const {
    trainingCandidates: { totalCandidates, candidates = [], loading: candidatesLoading },
  } = candidatesTraining;

  useEffect(() => {
    if (!blocks?.length) {
      dispatch(complianceSettingsActions.getComplianceBlocks());
    }
    dispatch(caregiverActions.getTrainingCandidates(candidatesFilters));
    dispatch(caregiverActions.getCaregiversCompliance(caregiversFilters));
    dispatch(complianceSettingsActions.getComplianceTrainingCourses());
    setCandidateName(candidatesFilters.nameOrId.nameOrId);
    setCaregiverName(caregiversFilters.nameOrId.nameOrId);
    const { state } = location;
    if (typeof state === 'string') {
      setActiveTab(state);
    }
  }, []);

  useEffect(() => {
    const newNonCompliantCaregivers = caregivers?.filter((c: ICaregiver) => !c.isCompliant);
    const newReadyToWorkCaregivers = caregivers?.filter((c: ICaregiver) => c.isCompliant);
    setNonCompliantCaregivers(newNonCompliantCaregivers);
    setReadyToWorkCaregivers(newReadyToWorkCaregivers);
    const complianceData = getDataToPrint({
      type: 'compliance',
      caregiversData: {
        notCompliant: newNonCompliantCaregivers,
        readyToWork: newReadyToWorkCaregivers,
      },
    });
    setCompliancePrintData(complianceData);
    const vaccineData = getDataToPrint({
      type: 'vaccine',
      caregiversData: {
        notCompliant: newNonCompliantCaregivers,
        readyToWork: newReadyToWorkCaregivers,
      },
    });
    setVaccinePrintData(vaccineData);
  }, [caregivers, activeTab]);

  useEffect(() => {
    const newInProgressCandidates = candidates?.filter(
      (c: ICaregiver) => c.nonCompliantBlocks?.length
    );
    const newCompleteCandidates = candidates?.filter(
      (c: ICaregiver) => !c.nonCompliantBlocks?.length
    );
    setInProgressCandidates(newInProgressCandidates);
    setCompleteCandidates(newCompleteCandidates);
    const complianceData = getDataToPrint({
      type: 'compliance',
      candidatesData: { inProgress: newInProgressCandidates, complete: newCompleteCandidates },
    });
    setCompliancePrintData(complianceData);
  }, [candidates, activeTab]);

  const {
    compliancesIssues: { eligibility, medical, documentation, training },
    vaccinationValidation: { vaccine },
  } = caregiversFilters;

  useEffect(() => {
    if (!(eligibility.length || medical.length || documentation.length || training.length)) {
      dispatch(filterActions.cleanComplianceOptions());
    }
    if (!vaccine.length) {
      dispatch(filterActions.cleanVaccinationOptions());
    }
  }, [eligibility, medical, documentation, training, vaccine]);

  const setQueryToFilters = (query: string) => {
    if (candidatesRote) {
      dispatch(
        filterActions.candidatesFilterChange('nameOrId' as FilterSections, 'nameOrId', query)
      );
      return;
    }
    dispatch(filterActions.caregiverFilterChange('nameOrId' as FilterSections, 'nameOrId', query));
  };

  const handleSearchChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    if (candidatesRote) {
      setCandidateName(data.value);
    } else {
      setCaregiverName(data.value);
    }
    setTimeout(() => setQueryToFilters(data.value), 0);
  };

  const handleSubmitSearch = (params?: unknown) => {
    let newParams;
    if (candidatesRote) {
      if (typeof params === 'object') {
        newParams = { ...params, ...candidatesFilters };
      } else {
        newParams = { ...candidatesFilters };
      }
      dispatch(caregiverActions.getTrainingCandidates(newParams));
      dispatch(uiStateActions.uiStateChange('complianceTrainingFilter', 'pristine', true));
      return;
    }
    if (typeof params === 'object') {
      newParams = { ...params, ...caregiversFilters };
    } else {
      newParams = { ...caregiversFilters };
    }
    dispatch(caregiverActions.getCaregiversCompliance(newParams));
    dispatch(uiStateActions.uiStateChange('complianceHRCaregiversFilter', 'pristine', true));
  };

  const handleTabChange = (newTab: number | string) => {
    setActiveTab(newTab);
  };

  const handleEditorClick = () => {
    setCaregiverEditorVisible(true);
  };

  const handleEditorClose = (newCaregiver: INewCaregiver) => {
    if (newCaregiver?.profile?.id) {
      setCaregiversDetailsId(newCaregiver.profile.id);
    }
    setCaregiverEditorVisible(false);
  };

  const handleCGDetailsClose = () => {
    setCaregiversDetailsId(undefined);
  };

  const toggleEventModal = () => {
    setCreateEventVisible(!createEventVisible);
  };

  const handleCheckClick = (id?: number) => {
    if (!id) return;
    let newSelection = selectedCandidates;
    if (selectedCandidates.includes(id)) {
      newSelection = selectedCandidates.filter(c => c !== id);
    } else {
      newSelection = [...selectedCandidates, id];
    }
    setSelectedCandidates(newSelection);
  };

  const memoizedCheckClick = useCallback((id?: number) => handleCheckClick(id), []);

  const handleSelectAll = () => {
    let newSelection = selectedCandidates;
    if (selectedCandidates.length && selectedCandidates.length === completeCandidates.length) {
      newSelection = [];
    } else {
      newSelection = completeCandidates.map(c => c.id);
    }
    setSelectedCandidates(newSelection);
  };

  const completeSelected = () => {
    dispatch(
      caregiverActions.completeCaregiverPrehire(selectedCandidates, {
        hrPortal: true,
      })
    );
    setSelectedCandidates([]);
  };

  const createFileName = (prefix: string) => {
    return `${view} ${activeTab} ${prefix} ${moment().format(MOMENT_FULL_DATETIME)}`;
  };

  const getDataToPrint = (data: {
    type: string;
    candidatesData?: { inProgress: ICaregiver[]; complete: ICaregiver[] };
    caregiversData?: { notCompliant: ICaregiver[]; readyToWork: ICaregiver[] };
  }): Data => {
    let activeTable: ICaregiver[] = [];
    let filters: IHRCaregiversFilter | IHRCandidatesFilter = caregiversFilters;
    switch (activeTab) {
      case 'notCompliant':
        activeTable = data?.caregiversData?.notCompliant || [];
        break;
      case 'readyToWork':
        activeTable = data?.caregiversData?.readyToWork || [];
        break;
      case 'inProgress':
        activeTable = data?.candidatesData?.inProgress || [];
        filters = candidatesFilters;
        break;
      case 'complete':
        activeTable = data?.candidatesData?.complete || [];
        filters = candidatesFilters;
        break;
      default:
        break;
    }
    switch (data.type) {
      case 'vaccine':
        return hrHelper.generateVaccineCaregiverData(activeTable, caregiversFilters);
      case 'compliance':
        return hrHelper.generateCaregiverData(activeTable, blocks, filters);
      default:
        return [];
    }
  };

  const caregiverPanes = [
    {
      menuItem: `Not  Compliant (${nonCompliantCaregivers?.length || 0})`,
      key: 'notCompliant',
      content: (
        <HRCaregiverCandidatesTable
          data={nonCompliantCaregivers}
          isCompleted={false}
          includeVaccine={vaccineServiceEnabled}
          loading={caregiversLoading}
          tableType='caregivers'
          blocks={blocks}
        />
      ),
    },
    {
      menuItem: `Ready to Work (${readyToWorkCaregivers?.length || 0})`,
      key: 'readyToWork',
      content: (
        <HRCaregiverCandidatesTable
          data={readyToWorkCaregivers}
          isCompleted
          includeVaccine={vaccineServiceEnabled}
          loading={caregiversLoading}
          tableType='caregivers'
          blocks={blocks}
        />
      ),
    },
  ];
  const candidatesPanes = [
    {
      menuItem: `In Progress (${inProgressCandidates?.length || 0})`,
      key: 'inProgress',
      content: (
        <HRCaregiverCandidatesTable
          data={inProgressCandidates}
          isCompleted={false}
          includeVaccine={false}
          loading={candidatesLoading}
          tableType='candidates'
          blocks={preHireBlocks}
        />
      ),
    },
    {
      menuItem: `Complete (${completeCandidates?.length || 0})`,
      key: 'complete',
      content: (
        <HRCaregiverCandidatesTable
          data={completeCandidates}
          isCompleted
          includeVaccine={false}
          loading={candidatesLoading}
          tableType='candidates'
          blocks={preHireBlocks}
          selectedCandidates={selectedCandidates}
          onCheckClick={memoizedCheckClick}
        />
      ),
    },
  ];

  return (
    <MainGrid>
      {caregiverEditorVisible && <CaregiverEditor onCloseClicked={handleEditorClose} />}
      {caregiverDetailsId && (
        <CaregiverDetails
          caregiverId={caregiverDetailsId}
          onCloseClicked={handleCGDetailsClose}
          tab='prefAndSkills'
        />
      )}
      {createEventVisible && (
        <CreateEventView
          draftEvent={{
            blockId: null,
            start: moment().add(10, 'minutes'),
            end: moment().add(40, 'minutes'),
          }}
          onClose={toggleEventModal}
          isCalendarView={false}
        />
      )}
      <BarContainer>
        <HRCaregiverHeader
          headerText={candidatesRote ? 'CANDIDATES' : 'CAREGIVERS'}
          searchQuery={candidatesRote ? candidateName : caregiverName}
          onChange={handleSearchChange}
          onSubmit={handleSubmitSearch}
          handleEditorClick={handleEditorClick}
          standaloneMode={standaloneMode}
          loading={candidatesLoading || caregiversLoading}
        />
      </BarContainer>
      <RailContainer>
        <HRLeftRail
          handleUpdate={handleSubmitSearch}
          generateFileName={createFileName}
          compliancePrintData={compliancePrintData}
          vaccinePrintData={vaccinePrintData}
          candidates={candidatesRote}
        />
      </RailContainer>
      <ChildContent>
        <CCTab
          defaultTab={candidatesRote ? 'inProgress' : 'notCompliant'}
          setActiveTab={handleTabChange}
          activeTab={activeTab}
          panes={candidatesRote ? candidatesPanes : caregiverPanes}
          actionItems={
            <>
              <p>
                Showing {candidatesRote ? candidates?.length : caregivers?.length} /{' '}
                {candidatesRote ? totalCandidates : totalCaregivers}{' '}
                {candidatesRote ? 'Candidates' : 'Caregivers'}
              </p>
              {activeTab === 'complete' ? (
                <SelectAllSection>
                  <p>Select All</p>{' '}
                  <CheckBox
                    small
                    checked={
                      !!selectedCandidates.length &&
                      selectedCandidates.length === completeCandidates.length
                    }
                    onClick={handleSelectAll}
                  />{' '}
                  <GreenButton disabled={!selectedCandidates.length} onClick={completeSelected}>
                    Activate Selected
                  </GreenButton>
                </SelectAllSection>
              ) : (
                <GreenButton onClick={toggleEventModal}>Create Event</GreenButton>
              )}
            </>
          }
        />
      </ChildContent>
    </MainGrid>
  );
};

export default HRCaregiversView;

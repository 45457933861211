import {
  GET_PREFERENCE_CHANGELOG_REQUEST,
  GET_PREFERENCE_CHANGELOG_SUCCESS,
  GET_PREFERENCE_CHANGELOG_FAILURE,
  GET_SHIFT_CHANGELOG_REQUEST,
  GET_SHIFT_CHANGELOG_SUCCESS,
  GET_SHIFT_CHANGELOG_FAILURE,
  GET_EMPLOYEE_CHANGELOG_REQUEST,
  GET_EMPLOYEE_CHANGELOG_SUCCESS,
  GET_EMPLOYEE_CHANGELOG_FAILURE,
  GET_SHIFT_CHANGELOG_CSV_REQUEST,
  GET_SHIFT_CHANGELOG_CSV_SUCCESS,
  GET_SHIFT_CHANGELOG_CSV_FAILURE,
} from '../constants/ActionTypes';
import { prepareEvent } from './helpers';

const initialState = {
  preference: {
    events: [],
    loading: false,
    partialUpdate: false,
  },
  shift: {
    events: [],
    loading: false,
    partialUpdate: false,
    loadingCSV: false,
  },
  employee: {
    events: [],
    loading: false,
    partialUpdate: false,
  },
};

function changeLog(state = initialState, action) {
  const prepareResult = results => {
    if (results.events) {
      return results.events.map(e => prepareEvent(e));
    }
    return results.events;
  };

  switch (action.type) {
    case GET_PREFERENCE_CHANGELOG_REQUEST:
      return {
        ...state,
        preference: { ...state.preference, loading: true, partialUpdate: action.partialUpdate },
      };
    case GET_PREFERENCE_CHANGELOG_SUCCESS:
      return {
        ...state,
        preference: {
          ...state.preference,
          loading: false,
          events: action.partialUpdate
            ? state.preference.events.concat(prepareResult(action.results))
            : prepareResult(action.results),
          nextPage: action.results.nextPage,
          partialUpdate: action.partialUpdate,
        },
      };
    case GET_PREFERENCE_CHANGELOG_FAILURE:
      return {
        ...state,
        preference: {
          ...state.preference,
          loading: false,
          events: [],
          partialUpdate: action.partialUpdate,
        },
      };
    case GET_SHIFT_CHANGELOG_REQUEST:
      return {
        ...state,
        shift: { ...state.shift, loading: true, partialUpdate: action.partialUpdate },
      };
    case GET_SHIFT_CHANGELOG_SUCCESS:
      return {
        ...state,
        shift: {
          ...state.shift,
          loading: false,
          events: action.partialUpdate
            ? state.shift.events.concat(prepareResult(action.results))
            : prepareResult(action.results),
          nextPage: action.results.nextPage,
          partialUpdate: action.partialUpdate,
        },
      };
    case GET_SHIFT_CHANGELOG_FAILURE:
      return {
        ...state,
        shift: { ...state.shift, loading: false, events: [], partialUpdate: action.partialUpdate },
      };
    case GET_SHIFT_CHANGELOG_CSV_REQUEST:
      return { ...state, shift: { ...state.shift, loadingCSV: true } };
    case GET_SHIFT_CHANGELOG_CSV_SUCCESS:
      return { ...state, shift: { ...state.shift, loadingCSV: false } };
    case GET_SHIFT_CHANGELOG_CSV_FAILURE:
      return { ...state, shift: { ...state.shift, loadingCSV: false } };
    case GET_EMPLOYEE_CHANGELOG_REQUEST:
      return {
        ...state,
        employee: { ...state.employee, loading: true, partialUpdate: action.partialUpdate },
      };
    case GET_EMPLOYEE_CHANGELOG_SUCCESS:
      return {
        ...state,
        employee: {
          ...state.employee,
          loading: false,
          events: action.partialUpdate
            ? state.employee.events.concat(prepareResult(action.results))
            : prepareResult(action.results),
          nextPage: action.results.nextPage,
          partialUpdate: action.partialUpdate,
        },
      };
    case GET_EMPLOYEE_CHANGELOG_FAILURE:
      return {
        ...state,
        employee: {
          ...state.employee,
          loading: false,
          events: [],
          partialUpdate: action.partialUpdate,
        },
      };
    default:
      return state;
  }
}

export default changeLog;

/* eslint-disable import/prefer-default-export */
import * as types from '../constants/ActionTypes';
import { backend } from '../services';
// eslint-disable-next-line import/no-cycle
import { alertActions } from './alert';

function getImports() {
  return dispatch => {
    dispatch(request());

    backend.getImports().then(
      imports => {
        dispatch(success(imports));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_IMPORTS_REQUEST };
  }
  function success(imports) {
    return { type: types.GET_IMPORTS_SUCCESS, imports };
  }
  function failure(error) {
    return { type: types.GET_IMPORTS_FAILURE, error };
  }
}

function getImportLines(importId) {
  return dispatch => {
    dispatch(request());

    backend.getImportLines(importId).then(
      lines => {
        dispatch(success(lines));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_IMPORT_DETAILS_REQUEST };
  }
  function success(lines) {
    return { type: types.GET_IMPORT_DETAILS_SUCCESS, lines };
  }
  function failure(error) {
    return { type: types.GET_IMPORT_DETAILS_FAILURE, error };
  }
}

function importNotification(importObject) {
  return { type: types.IMPORT_NOTIFICATION, importObject };
}

export const importActions = {
  getImports,
  getImportLines,
  importNotification,
};

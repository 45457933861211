/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { Component } from 'react';
import { Button, Image, Loader, Modal, Icon, Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as CompHelper from './complianceHelpers';
import * as compTypes from './ComplianceMedicalTypes';
import { caregiverActions } from '../../../actions';
import CGComplianceUIActions from '../../../actions/CGComplianceUIActions';
import { dateFormat } from '../../../helpers/common';
import {
  SimpleRedButton,
  LinkButton,
  SimpleGreenButton,
  RedButton,
  GreenButton,
} from '../../../styles/common';
import { isAdmin } from '../../../constants/DomainTypes';

type Props = {
  blocks: Array<Block>;
  caregiverComplianceUI: any;
  caregiverCompliance: any;
  dispatch: (arg: any) => void;
  caregiver: any;
  roles: Array<string>;
};

type State = {
  closeEnabled: boolean;
  showResultEditPanel: boolean;
  showDeclinationPanel: boolean;
  showConsentPanel: boolean;
  showReviewModal: boolean;
  docSource: string;
  documentOpen?: Document;
  imageIndex: number;
  openRemoveModal: boolean;
  draftState: DraftState;
};

type DraftState = {
  firstDoseDate?: compTypes.DraftStateField;
  secondDoseDate?: compTypes.DraftStateField;
  manufacturer?: compTypes.DraftStateField;
  completionDate?: compTypes.DraftStateField;
  declinationDate?: compTypes.DraftStateField;
  vaccine1Site?: compTypes.DraftStateField;
  site1Name?: compTypes.DraftStateField;
  vaccine2Site?: compTypes.DraftStateField;
  site2Name?: compTypes.DraftStateField;
};

interface Block extends compTypes.Blocks {
  fields: Array<BlockField>;
  name: 'Coronavirus Vaccine' | 'Coronavirus Vaccine Declination';
}

interface COVIDBlocks {
  [key: string]: Block;
  vaccination: Block;
  declination: Block;
}

interface BlockField extends compTypes.BlockField {
  filesDownloaded?: boolean;
  downloadLinks: FormLinks;
}

interface Document {
  block_name: string;
  created: string;
  field_name: string;
  filename: string;
  id: string;
  originalName: string;
  tag: null;
  type: 'document';
}

interface VaccinationInterval {
  [key: string]: string;
}

type FormLinks = {
  [key: string]: string;
};

const MANUFACTURER_OPTIONS = [
  {
    text: 'Moderna',
    value: 'Moderna',
  },
  {
    text: 'Pfizer',
    value: 'Pfizer',
  },
  {
    text: 'Johnson & Johnson',
    value: 'Johnson & Johnson',
  },
];

const vaccinationInterval: VaccinationInterval = { Moderna: '28 days', Pfizer: '21 days' };

class ComplianceCOVIDVaccine extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showResultEditPanel: false,
      showDeclinationPanel: false,
      showConsentPanel: false,
      closeEnabled: false,
      showReviewModal: false,
      docSource: '',
      documentOpen: undefined,
      imageIndex: 0,
      openRemoveModal: false,
      draftState: {
        firstDoseDate: undefined,
        secondDoseDate: undefined,
        manufacturer: undefined,
        completionDate: undefined,
        declinationDate: undefined,
        vaccine1Site: undefined,
        site1Name: undefined,
        vaccine2Site: undefined,
        site2Name: undefined,
      },
    };
  }

  public componentDidMount = () => {
    this.setFieldsToState();
  };

  private readonly setFieldsToState = (): void => {
    const { blocks } = this.props;
    const [vaccination] = blocks.filter((b: Block) => b.name === 'Coronavirus Vaccine');
    const [declination] = blocks.filter((b: Block) => b.name === 'Coronavirus Vaccine Declination');

    const [firstDoseDate] = vaccination.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === '1st Dose'
    );
    const [secondDoseDate] = vaccination.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === '2nd Dose'
    );
    const [manufacturer] = vaccination.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === 'Manufacturer'
    );

    const [completionDate] = vaccination.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Completion'
    );

    const [firstDoseReview] = vaccination.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === '1st Dose Review'
    );

    const [secondDoseReview] = vaccination.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === '2nd Dose Review'
    );

    const [vaccine1Site] = vaccination.fields.filter((f: BlockField) => f.name === 'vaccine1Site');
    const [site1Name] = vaccination.fields.filter((f: BlockField) => f.name === 'site1Name');
    const [vaccine2Site] = vaccination.fields.filter((f: BlockField) => f.name === 'vaccine2Site');
    const [site2Name] = vaccination.fields.filter((f: BlockField) => f.name === 'site2Name');

    const [declinedDate] = declination
      ? declination?.fields.filter((f: BlockField) => f.type === 'date')
      : [];

    this.setState(s => ({
      ...s,
      draftState: {
        ...s.draftState,
        declinationDate: {
          value: declinedDate?.value ? moment(declinedDate.value) : '',
          id: declinedDate?.id,
        },
        firstDoseDate: {
          value: firstDoseDate.value ? moment(firstDoseDate.value) : '',
          id: firstDoseDate.id,
        },
        secondDoseDate: {
          value: secondDoseDate.value ? moment(secondDoseDate.value) : '',
          id: secondDoseDate.id,
        },
        manufacturer: {
          value: manufacturer.value || '',
          id: manufacturer.id,
        },
        firstDoseReview: {
          value: firstDoseReview.value || '',
          id: firstDoseReview.id,
        },
        secondDoseReview: {
          value: secondDoseReview.value || '',
          id: secondDoseReview.id,
        },
        completionDate: {
          value: completionDate.value ? moment(completionDate.value) : '',
          id: completionDate.id,
        },
        vaccine1Site: vaccine1Site
          ? {
              value: vaccine1Site.value,
              id: vaccine1Site.id,
            }
          : undefined,
        site1Name: site1Name
          ? {
              value: site1Name.value,
              id: site1Name.id,
            }
          : undefined,
        vaccine2Site: vaccine2Site
          ? {
              value: vaccine2Site.value,
              id: vaccine2Site.id,
            }
          : undefined,
        site2Name: site2Name
          ? {
              value: site2Name.value,
              id: site2Name.id,
            }
          : undefined,
      },
    }));
  };

  private readonly addCompletion = (): void => {
    this.setState(s => ({
      ...s,
      showResultEditPanel: !s.showResultEditPanel,
      closeEnabled: true,
    }));
  };

  private readonly openDeclinePanel = (): void => {
    this.setState(s => ({
      ...s,
      showDeclinationPanel: !s.showDeclinationPanel,
      closeEnabled: true,
    }));
  };

  private readonly onClose = (): void => {
    const { dispatch } = this.props;
    this.setFieldsToState();
    this.setState(
      s => ({
        ...s,
        closeEnabled: false,
        showResultEditPanel: false,
        showDeclinationPanel: false,
        showConsentPanel: false,
      }),
      () => dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: [] }))
    );
  };

  private readonly toggleRemoveModal = () => {
    const { openRemoveModal } = this.state;
    this.setState({ openRemoveModal: !openRemoveModal });
  };

  private readonly rejectDocument = (firstDoseReview: BlockField, secondDoseReview: BlockField) => {
    const { draftState } = this.state;
    const { firstDoseDate } = draftState;

    if (!firstDoseDate?.value) {
      this.onFieldChanged(firstDoseReview, 'rejected').then(() => this.updateMedicalBlock());
    } else {
      this.onFieldChanged(secondDoseReview, 'rejected').then(() => this.updateMedicalBlock());
    }
    return this.closeModalClicked();
  };

  private readonly onFieldChanged = async (field: BlockField, value: any) => {
    const { draftState } = this.state;
    const { caregiverComplianceUI, blocks, dispatch } = this.props;
    const { dirtyMedicalBlocks } = caregiverComplianceUI;

    const secondDoseRequired =
      draftState.manufacturer?.value === 'Moderna' || draftState.manufacturer?.value === 'Pfizer';

    const [vaccine] = blocks.filter((b: Block) => b.name === 'Coronavirus Vaccine');

    const [completionDate] = vaccine.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === 'Completion'
    );

    const [secondDoseReview] = vaccine.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === '2nd Dose Review'
    );

    const [firstDoseReview] = vaccine.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === '1st Dose Review'
    );

    let theField = dirtyMedicalBlocks.find((f: BlockField) => f.id === field.id);

    if (!theField) {
      theField = JSON.parse(JSON.stringify(field));
      dirtyMedicalBlocks.push(theField);
    }
    theField.value = value;

    if (field.type === 'date') {
      if (!secondDoseRequired && field.name === '1st Dose') {
        this.onFieldChanged(completionDate, value);
      }
      if (secondDoseRequired && field.name === '2nd Dose') {
        if (secondDoseReview?.value !== 'approved') {
          this.onFieldChanged(secondDoseReview, 'approved');
        }
        this.onFieldChanged(completionDate, value);
      }
      if (firstDoseReview?.value !== 'approved') {
        this.onFieldChanged(firstDoseReview, 'approved');
      }
      this.onDateChange(value, field.id);
    }

    if (field.type === 'text') {
      this.onTextChange(value, field.id);
    }

    dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks }));
  };

  private readonly onDateChange = (date: any, selected: number): void => {
    const { draftState } = this.state;
    let valueToSet: string;
    let selectedId: number;
    /* eslint-disable-next-line no-restricted-syntax */
    for (const [key, value] of Object.entries(draftState)) {
      if (value?.id === selected) {
        valueToSet = key;
        selectedId = value.id;
      }
    }

    this.setState(s => ({
      ...s,
      draftState: {
        ...s.draftState,
        [valueToSet]: {
          value: date && moment(date).isBefore(moment()) ? moment(date) : '',
          id: selectedId,
        },
      },
    }));
  };

  private readonly onTextChange = (value: any, selected: number): void => {
    const { draftState } = this.state;
    let valueToSet: string;
    let selectedId: number;
    /* eslint-disable-next-line no-restricted-syntax */
    for (const [key, val] of Object.entries(draftState)) {
      if (val?.id === selected) {
        valueToSet = key;
        selectedId = val.id;
      }
    }

    this.setState(s => ({
      ...s,
      draftState: {
        ...s.draftState,
        [valueToSet]: {
          value: value || '',
          id: selectedId,
        },
      },
    }));
  };

  private readonly uploadDocument = (e: any, fieldId: number) => {
    const { caregiver, dispatch } = this.props;
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      dispatch(caregiverActions.uploadComplianceDocument(fieldId, file, caregiver.id));
    }
    e.target.value = '';
  };

  private readonly documentRemoved = (fieldId: number, filename: string) => {
    const { caregiver, blocks, dispatch } = this.props;

    const [vaccine] = blocks.filter((b: Block) => b.name === 'Coronavirus Vaccine');

    const [declineVaccine] = blocks.filter(
      (b: Block) => b.name === 'Coronavirus Vaccine Declination'
    );

    const [declinationDate] = declineVaccine.fields.filter((f: BlockField) => f.type === 'date');

    const [secondDoseReview] = vaccine.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === '2nd Dose Review'
    );

    const [firstDoseReview] = vaccine.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === '1st Dose Review'
    );

    const [certificateDocumentsFiles] = vaccine?.fields.filter(
      (f: BlockField) => f.type === 'document' && f.name === 'Certificate Documents'
    );

    if (!firstDoseReview?.value && certificateDocumentsFiles?.value.length === 1) {
      this.onFieldChanged(firstDoseReview, 'rejected');
    }

    if (
      firstDoseReview?.value === 'approved' &&
      !secondDoseReview?.value &&
      !declinationDate?.value
    ) {
      const imagesAfterFirstApproved = certificateDocumentsFiles?.value?.filter((c: Document) =>
        moment(c.created).isAfter(moment(firstDoseReview?.modified))
      ).length;
      if (imagesAfterFirstApproved === 1) {
        this.onFieldChanged(secondDoseReview, 'rejected');
      }
    }

    dispatch(caregiverActions.removeComplianceDocument(caregiver.id, fieldId, filename));
  };

  private readonly updateMedicalBlock = async () => {
    const { caregiver, blocks, caregiverComplianceUI, dispatch } = this.props;
    const { dirtyMedicalBlocks } = caregiverComplianceUI;
    const [vaccine] = blocks.filter((b: Block) => b.name === 'Coronavirus Vaccine');
    const [vaccineCompleted] = vaccine.fields.filter((b: BlockField) => b.name === 'Completion');
    const [completion] = dirtyMedicalBlocks.filter(
      (b: BlockField) => b.name === 'Completion' && b.id === vaccineCompleted?.id
    );

    if (Object.keys(dirtyMedicalBlocks).length) {
      const dirtyFieldsToSave: Array<BlockField> = [];
      dirtyMedicalBlocks.forEach((s: BlockField) => {
        const [block] = blocks.filter((a: Block) =>
          a.fields.find((f: BlockField) => f.id === s.id)
        );
        const b = block?.fields.find((a: BlockField) => a.id === s.id);
        if (
          b &&
          ((b.value && b.value !== s.value) || (!b.value && s.value) || s.value === 'rejected')
        ) {
          dirtyFieldsToSave.push(s);
        }
      });
      if (dirtyFieldsToSave.length) {
        await Promise.resolve(
          dispatch(
            caregiverActions.setCaregiverComplianceBlockFields(caregiver.id, dirtyFieldsToSave)
          )
        ).then(() => {
          const savedFieldsId = dirtyFieldsToSave.map(f => f.id);
          const remainingDirty = dirtyMedicalBlocks.filter(
            (b: BlockField) => !savedFieldsId.includes(b.id)
          );
          dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: remainingDirty }));
        });
      }
    }

    if (completion?.value) {
      dispatch(
        caregiverActions.setCaregiverComplianceByProfile(caregiver.id, {
          blockId: vaccine.id,
          requiredByCaregiverProfile: 1,
          preferenceId: vaccine?.preferenceId,
        })
      );
    }

    return this.setState(prevState => ({
      ...prevState,
      showResultEditPanel: false,
      showDeclinationPanel: false,
      showConsentPanel: false,
      closeEnabled: false,
    }));
  };

  private readonly onRemove = async () => {
    const dirtyFieldToClear: Array<BlockField> = [];
    const { caregiver, dispatch, blocks } = this.props;
    const [vaccine] = blocks.filter((b: Block) => b.name === 'Coronavirus Vaccine');
    blocks.forEach((block: Block) =>
      block.fields.forEach(f => {
        if (f.type === 'document' && f.value?.length) {
          f.value.forEach((v: any) => this.documentRemoved(f.id, v.filename));
        } else {
          dirtyFieldToClear.push({
            id: f.id,
            name: f.name,
            type: f.type,
            fixed: f.fixed,
            value: null,
            isDirty: true,
            downloadLinks: {},
          });
        }
      })
    );

    await Promise.resolve(
      dispatch(caregiverActions.setCaregiverComplianceBlockFields(caregiver.id, dirtyFieldToClear))
    ).then(() => {
      this.setState(
        s => ({
          ...s,
          draftState: {
            ...s.draftState,
            firstDoseDate: {
              ...s.draftState.firstDoseDate,
              value: '',
            },
            secondDoseDate: {
              ...s.draftState.secondDoseDate,
              value: '',
            },
            manufacturer: {
              ...s.draftState.manufacturer,
              value: '',
            },
            completionDate: {
              ...s.draftState.completionDate,
              value: '',
            },
            declinationDate: {
              ...s.draftState.declinationDate,
              value: '',
            },
            vaccine1Site: s.draftState.vaccine1Site
              ? {
                  ...s.draftState.vaccine1Site,
                  value: null,
                }
              : undefined,
            site1Name: s.draftState.site1Name
              ? {
                  ...s.draftState.site1Name,
                  value: '',
                }
              : undefined,
            vaccine2Site: s.draftState.vaccine2Site
              ? {
                  ...s.draftState.vaccine2Site,
                  value: null,
                }
              : undefined,
            site2Name: s.draftState.site2Name
              ? {
                  ...s.draftState.site2Name,
                  value: '',
                }
              : undefined,
          },
          showResultEditPanel: false,
          closeEnabled: false,
        }),
        () => {
          dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: [] }));
          dispatch(
            caregiverActions.setCaregiverComplianceByProfile(caregiver.id, {
              blockId: vaccine.id,
              requiredByCaregiverProfile: 0,
              preferenceId: vaccine?.preferenceId,
            })
          );
        }
      );
    });
    return this.toggleRemoveModal();
  };

  private readonly showRemoveEditVaccination = () => {
    return (
      <>
        <LinkButton fontSize='11px' onClick={this.addCompletion}>
          edit
        </LinkButton>
        <LinkButton fontSize='11px' onClick={this.toggleRemoveModal}>
          remove
        </LinkButton>
      </>
    );
  };

  private readonly showRemoveEditDeclination = () => {
    return (
      <>
        <LinkButton fontSize='11px' onClick={this.openDeclinePanel}>
          edit
        </LinkButton>
        <LinkButton fontSize='11px' onClick={this.toggleRemoveModal}>
          remove
        </LinkButton>
      </>
    );
  };

  private readonly closeModalClicked = () => {
    this.setState({ showReviewModal: false, docSource: '' });
  };

  private readonly prevImage = () => {
    const { imageIndex } = this.state;
    const prevIndex = imageIndex - 1;
    if (prevIndex < 0) {
      return null;
    }
    const { blocks } = this.props;
    const [vaccination] = blocks.filter((b: Block) => b.name === 'Coronavirus Vaccine');
    const [certificateDocumentsFiles] = vaccination?.fields.filter(
      (f: BlockField) => f.type === 'document' && f.name === 'Certificate Documents'
    );
    const file = certificateDocumentsFiles?.value[prevIndex];
    const link = certificateDocumentsFiles.downloadLinks[file.filename];
    return this.documentClicked(link, file, prevIndex);
  };

  private readonly nextImage = () => {
    const { imageIndex } = this.state;
    const { blocks } = this.props;
    const [vaccination] = blocks.filter((b: Block) => b.name === 'Coronavirus Vaccine');
    const [certificateDocumentsFiles] = vaccination?.fields.filter(
      (f: BlockField) => f.type === 'document' && f.name === 'Certificate Documents'
    );
    const nextIndex = imageIndex + 1;
    if (nextIndex + 1 > certificateDocumentsFiles?.value?.length) {
      return null;
    }
    const file = certificateDocumentsFiles?.value[nextIndex];
    const link = certificateDocumentsFiles.downloadLinks[file.filename];
    return this.documentClicked(link, file, nextIndex);
  };

  private readonly renderDocumentField = (documentField: BlockField, editable = true) => {
    const { caregiverCompliance, roles } = this.props;
    const { uploadingDocument } = caregiverCompliance;
    let addNewClass = 'complianceDocumentItem';
    if (uploadingDocument) addNewClass += ' disabled';

    const previewDocument = (link: string, file: Document, index: number) => {
      if (file.filename.endsWith('.pdf')) {
        return (
          <a href={link}>
            <div style={{ textAlign: 'center' }}>.pdf</div>
          </a>
        );
      }
      return (
        <Image
          width='90px'
          height='90px'
          src={link}
          onClick={() => this.documentClicked(link, file, index)}
        />
      );
    };
    return (
      <div className='complianceDocumentsContainer' key={documentField.id}>
        {!documentField.filesDownloaded && documentField.value && !!documentField.value.length && (
          <div className='complianceDocumentItem'>
            <div style={{ height: '90px' }}>
              <Loader active inline />
            </div>
          </div>
        )}
        {documentField.filesDownloaded &&
        documentField.downloadLinks &&
        !!documentField.value?.length
          ? documentField.value.map((file: Document, index: number) => {
              return (
                <div
                  className='complianceDocumentItem'
                  key={file.created}
                  style={{ width: '90px' }}
                >
                  {previewDocument(documentField.downloadLinks[file.filename], file, index)}
                  {isAdmin(roles) && (
                    <Button
                      circular
                      icon='delete'
                      className='documentRemoveButton'
                      onClick={() => this.documentRemoved(documentField.id, file.filename)}
                    />
                  )}
                </div>
              );
            })
          : null}
        {editable && (
          <div className={addNewClass} style={{ height: '90px' }}>
            {uploadingDocument ? (
              <>
                {uploadingDocument === documentField.id ? (
                  <div style={{ height: '90px', width: '90px' }}>
                    <Loader active inline />
                  </div>
                ) : (
                  <p style={{ color: '#ccc' }}>+</p>
                )}
              </>
            ) : (
              <>
                <input
                  type='file'
                  accept='.jpg,.png,.jpeg,.gif,.pdf'
                  id={`fileinput${documentField.id}`}
                  className='fileinput'
                  style={{ display: 'none' }}
                  onChange={e => this.uploadDocument(e, documentField.id)}
                />
                <label htmlFor={`fileinput${documentField.id}`} style={{ padding: '36px' }}>
                  +
                </label>
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  displayReviewButtons = (
    secondDoseRequired: boolean,
    firstDoseReviewField: BlockField,
    secondDoseReviewField: BlockField
  ) => {
    const { documentOpen } = this.state;

    if (
      secondDoseRequired &&
      firstDoseReviewField?.value === 'approved' &&
      secondDoseReviewField?.value === 'approved'
    ) {
      return false;
    }
    if (!secondDoseRequired && firstDoseReviewField?.value === 'approved') {
      return false;
    }
    if (!secondDoseRequired && firstDoseReviewField?.value === 'rejected') {
      return moment(documentOpen?.created).isAfter(firstDoseReviewField?.modified);
    }
    if (secondDoseRequired && firstDoseReviewField?.value === 'rejected') {
      return moment(documentOpen?.created).isAfter(firstDoseReviewField?.modified);
    }
    if (secondDoseRequired && secondDoseReviewField?.value === 'rejected') {
      return moment(documentOpen?.created).isAfter(secondDoseReviewField?.modified);
    }
    if (secondDoseRequired && firstDoseReviewField?.value === 'approved') {
      return moment(documentOpen?.created).isAfter(firstDoseReviewField?.modified);
    }
    return true;
  };

  private documentClicked(link: string, file: Document, index: number) {
    this.setState({
      showReviewModal: true,
      docSource: link,
      documentOpen: file,
      imageIndex: index,
    });
  }

  public render(): JSX.Element {
    const { blocks, caregiverComplianceUI } = this.props;
    const {
      showResultEditPanel,
      showDeclinationPanel,
      closeEnabled,
      draftState,
      showReviewModal,
      docSource,
      imageIndex,
      openRemoveModal,
    } = this.state;

    const {
      firstDoseDate,
      secondDoseDate,
      manufacturer,
      completionDate,
      declinationDate,
      vaccine1Site,
      site1Name,
      vaccine2Site,
      site2Name,
    } = draftState;

    const { dirtyMedicalBlocks } = caregiverComplianceUI;

    const [vaccination] = blocks.filter((b: Block) => b.name === 'Coronavirus Vaccine');
    const [declination] = blocks.filter((b: Block) => b.name === 'Coronavirus Vaccine Declination');

    const [firstDoseField] = vaccination?.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === '1st Dose'
    );
    const [secondDoseField] = vaccination?.fields.filter(
      (f: BlockField) => f.type === 'date' && f.name === '2nd Dose'
    );
    const [manufacturerField] = vaccination?.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === 'Manufacturer'
    );
    const [certificateDocumentsFiles] = vaccination?.fields.filter(
      (f: BlockField) => f.type === 'document' && f.name === 'Certificate Documents'
    );
    const [firstDoseReviewField] = vaccination?.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === '1st Dose Review'
    );
    const [secondDoseReviewField] = vaccination?.fields.filter(
      (f: BlockField) => f.type === 'text' && f.name === '2nd Dose Review'
    );

    const [vaccine1SiteField] = vaccination.fields.filter(
      (f: BlockField) => f.name === 'vaccine1Site'
    );
    const [site1NameField] = vaccination.fields.filter((f: BlockField) => f.name === 'site1Name');
    const [vaccine2SiteField] = vaccination.fields.filter(
      (f: BlockField) => f.name === 'vaccine2Site'
    );
    const [site2NameField] = vaccination.fields.filter((f: BlockField) => f.name === 'site2Name');

    const [declinedDateField] = declination
      ? declination?.fields.filter((f: BlockField) => f.type === 'date')
      : [];

    const [declinationForm] = declination
      ? declination?.fields.filter((f: BlockField) => f.type === 'document')
      : [];

    const COVIDBlocks: COVIDBlocks = {
      vaccination,
      declination,
    };

    const disableUpdate = () => {
      if (vaccine1Site) {
        if (firstDoseField.value) {
          if (secondDoseDate?.value) {
            return !(
              vaccine2Site?.value &&
              vaccine1Site?.value &&
              firstDoseDate?.value &&
              secondDoseDate?.value &&
              dirtyMedicalBlocks.length
            );
          }
          return !(vaccine1Site?.value && firstDoseDate?.value && dirtyMedicalBlocks.length);
        }
        return !(vaccine1Site?.value && firstDoseDate?.value && dirtyMedicalBlocks.length);
      }
      return !dirtyMedicalBlocks.length;
    };

    const handleFieldChange = (e: unknown, data: string) => {
      this.onFieldChanged(e as BlockField, data);
    };

    const secondDoseRequired =
      manufacturer?.value === 'Moderna' || manufacturer?.value === 'Pfizer';
    const blockCompleted = !!completionDate?.value;
    const showDeclineInfo = !!declinedDateField?.value;

    let formLink;

    if (showDeclineInfo) {
      if (Object.getOwnPropertyNames(declinationForm?.downloadLinks || {}).length) {
        formLink = declinationForm?.downloadLinks[declinationForm?.value[0]?.filename];
      }
    }

    const showVaccinationInfoPanel =
      (blockCompleted || !!certificateDocumentsFiles?.value?.length) &&
      !showResultEditPanel &&
      !showDeclineInfo;

    const showRemoveEditButton = showDeclineInfo || showVaccinationInfoPanel;

    return (
      <div className='infoPanelMedicalBlock'>
        {openRemoveModal && (
          <Modal
            open
            onClose={this.toggleRemoveModal}
            closeOnDimmerClick={false}
            closeOnEscape
            size='tiny'
          >
            <Modal.Header
              style={{
                textAlign: 'center',
                backgroundColor: '#f16778',
                color: '#ffffff',
                fontSize: '14px',
              }}
            >
              <h3>Are you sure?</h3>
            </Modal.Header>
            <Modal.Content
              scrolling
              style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 1rem 0 3rem',
                  width: '100%',
                }}
              >
                <p>
                  This action will remove currently added information to the tile and it <br />{' '}
                  cannot be undone.
                </p>
              </div>
            </Modal.Content>
            <Modal.Actions
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignContent: 'center',
                backgroundColor: 'transparent',
              }}
            >
              <GreenButton style={{ padding: '0 3rem 0 3rem' }} onClick={this.toggleRemoveModal}>
                Cancel
              </GreenButton>
              <RedButton style={{ padding: '0 3rem 0 3rem' }} onClick={this.onRemove}>
                Remove
              </RedButton>
            </Modal.Actions>
          </Modal>
        )}
        {showReviewModal && (
          <Modal
            open
            closeIcon
            onClose={this.closeModalClicked}
            closeOnDimmerClick={false}
            closeOnEscape
            size='fullscreen'
            style={{
              backgroundColor: 'transparent',
              boxShadow: 'none',
            }}
          >
            <Modal.Content
              scrolling
              style={{
                backgroundColor: 'transparent',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 0 0 100px',
                  width: '100%',
                  minHeight: '65vh',
                }}
              >
                {imageIndex && (
                  <Button
                    id='nav-image-left'
                    onClick={this.prevImage}
                    style={{
                      backgroundColor: 'transparent',
                      marginLeft: '1rem',
                      color: '#ffffff',
                      fontSize: '20px',
                      border: '1px solid #ffffff',
                    }}
                    circular
                    icon='angle left'
                    color='grey'
                  />
                )}
                <Image centered size='big' src={docSource} />
                {imageIndex + 1 < certificateDocumentsFiles?.value?.length && (
                  <Button
                    id='nav-image-right'
                    onClick={this.nextImage}
                    style={{
                      backgroundColor: 'transparent',
                      marginLeft: '1rem',
                      color: '#ffffff',
                      fontSize: '20px',
                      border: '1px solid #ffffff',
                    }}
                    circular
                    icon='angle right'
                    color='grey'
                  />
                )}
              </div>
              <Button
                id='nav-image-left'
                onClick={this.closeModalClicked}
                style={{
                  backgroundColor: 'transparent',
                  marginLeft: '1rem',
                  alignSelf: 'flex-start',
                  border: 'none',
                  color: '#ffffff',
                  fontSize: '20px',
                }}
                circular
                icon='close'
                color='grey'
              />
            </Modal.Content>
            {this.displayReviewButtons(
              secondDoseRequired,
              firstDoseReviewField,
              secondDoseReviewField
            ) && (
              <Modal.Actions
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  placeItems: 'center',
                  backgroundColor: 'transparent',
                }}
              >
                <p className='certificate-validation-text'>
                  If all information needed is on the certificate clearly, you can close and
                  continue in caregiver medical compliance to edit the vaccination information. If
                  the uploaded image doesn’t show the vaccination manufacturer or the dates clearly,
                  you can reject the certificate image. Caregiver will be notified and asked to
                  upload a new image.
                </p>
                <div className='certificate-buttons-container'>
                  <SimpleGreenButton id='continue-document-button' onClick={this.closeModalClicked}>
                    Close and continue
                  </SimpleGreenButton>
                  <SimpleRedButton
                    id='reject-document-button'
                    onClick={() => this.rejectDocument(firstDoseReviewField, secondDoseReviewField)}
                  >
                    Reject Certificate Image
                  </SimpleRedButton>
                </div>
              </Modal.Actions>
            )}
          </Modal>
        )}
        <div className='compliances-tab-header'>
          <img src='/covid-icon-red.png' alt='compliance-icon' />
          <p
            className={
              certificateDocumentsFiles?.value?.length &&
              !completionDate?.value &&
              !declinationDate?.value
                ? 'warningTitle'
                : 'blackTitle'
            }
            style={{ margin: '0 10px 0 10px' }}
          >
            {COVIDBlocks.vaccination.name}
          </p>
          {certificateDocumentsFiles?.value?.length &&
          !completionDate?.value &&
          !declinationDate?.value ? (
            <p className='incomplete-warning'>(Incomplete)</p>
          ) : (
            ''
          )}
        </div>
        <div className='declined-info'>
          <p className='compliance-medium-gray'>
            Coronavirus vaccine is currently a{' '}
            <b className='compliance-charcoal-text-color'>One-time</b> requirement. Caregivers
            should submit their vaccine certificate or decline the vaccine to complete this item.
          </p>
          <Popup
            trigger={
              <div className='round-grey-question'>
                <Icon name='question' />
              </div>
            }
            content='If the caregiver has uploaded an image of their vaccination certificate, 
            you can see the manufacturer and vaccination dates from the image. 
            If the image of the certificate is not showing the manufacturer or the dates clearly, 
            you can reject the image and the caregiver will be notified to upload a new image. 
            If the image shows all necessary information clearly, click “Edit Vaccination Info”
            and add in the manufacturer and the necessary vaccination dates.'
            on='hover'
            position='top left'
            popperModifiers={{
              preventOverflow: {
                boundariesElement: 'offsetParent',
              },
            }}
          />
        </div>
        {showDeclineInfo && (
          <>
            <div className='declined-info'>
              <p
                className='compliance-medium-gray'
                style={{ display: 'inline-block', marginRight: '10px' }}
              >
                {`Declination Form Submitted: ${moment(declinationDate?.value).format(dateFormat)}`}
              </p>
              {formLink ? (
                <a
                  className='compliance-form-link'
                  href={formLink}
                  target='_blank'
                  rel='noreferrer'
                >
                  Review Declination Form
                </a>
              ) : (
                <LinkButton
                  fontSize='11px'
                  className='compliance-add-decline-text'
                  style={{ display: 'inline-block' }}
                  onClick={this.openDeclinePanel}
                >
                  Add Declination Form
                </LinkButton>
              )}
            </div>
            <div className='declined-complete-footer'>
              {showRemoveEditButton && this.showRemoveEditDeclination()}
            </div>
          </>
        )}
        {showVaccinationInfoPanel && (
          <div className='compliance-info-panel'>
            <div className='covid-documents-container'>
              {this.renderDocumentField(certificateDocumentsFiles, false)}
            </div>
            {!!manufacturer?.value && (
              <div className='covid-result-container'>
                <div
                  className='medicalDateInput'
                  style={{ justifySelf: 'flex-start', marginBottom: '1rem' }}
                >
                  <p className='covid-manufacture-text'>Manufacturer </p>{' '}
                  {manufacturer?.value || (
                    <span style={{ color: '#f5a623', paddingLeft: '0px', fontSize: '10px' }}>
                      Incomplete
                    </span>
                  )}
                </div>
                {secondDoseRequired ? (
                  <>
                    <div className='covid-dose-container'>
                      <div className='medicalDateInput' style={{ justifySelf: 'center' }}>
                        <p className='compliance-results-labels'>Date of 1st Dose</p>
                        <CompHelper.DateText date={firstDoseField?.value} vaccine />
                      </div>
                      {vaccine1Site && (
                        <>
                          <div className='medicalDateInput'>
                            <p className='compliance-results-labels'>1st Dose Clinic Site</p>
                            <p className='compliance-results-labels'>
                              <CompHelper.TextField
                                field={vaccine1SiteField}
                                state={vaccine1Site}
                                disabled
                                handleChange={() => null}
                              />
                            </p>
                          </div>
                          <div className='medicalDateInput'>
                            <p className='compliance-results-labels'>1st Dose Location Name</p>
                            <p className='compliance-results-labels'>
                              <CompHelper.TextField
                                field={site1NameField}
                                state={site1Name}
                                disabled
                                handleChange={() => null}
                              />
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                    <div className='covid-dose-container'>
                      <div className='medicalDateInput' style={{ justifySelf: 'flex-end' }}>
                        <p className='compliance-results-labels'>Date of 2nd Dose</p>
                        <CompHelper.DateText date={secondDoseField?.value} vaccine />
                      </div>
                      {vaccine2Site && (
                        <>
                          <div className='medicalDateInput'>
                            <p className='compliance-results-labels'>2nd Dose Clinic Site</p>
                            <p className='compliance-results-labels'>
                              <CompHelper.TextField
                                field={vaccine2SiteField}
                                state={vaccine2Site}
                                disabled
                                handleChange={() => null}
                              />
                            </p>
                          </div>
                          <div className='medicalDateInput'>
                            <p className='compliance-results-labels'>2nd Dose Location Name</p>
                            <p className='compliance-results-labels'>
                              <CompHelper.TextField
                                field={site2NameField}
                                state={site2Name}
                                disabled
                                handleChange={() => null}
                              />
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <div className='covid-dose-container'>
                    <div className='medicalDateInput' style={{ justifySelf: 'center' }}>
                      <p className='compliance-results-labels'>Date of Vaccination</p>
                      <CompHelper.DateText date={firstDoseField?.value} vaccine />
                    </div>
                    {vaccine1Site && (
                      <>
                        <div className='medicalDateInput'>
                          <p className='compliance-results-labels'>Clinic Site</p>
                          <p className='compliance-block-completion-field-text'>
                            <CompHelper.TextField
                              field={vaccine1SiteField}
                              state={vaccine1Site}
                              disabled
                              handleChange={() => null}
                            />
                          </p>
                        </div>
                        <div className='medicalDateInput'>
                          <p className='compliance-results-labels'>Location Name</p>
                          <p className='compliance-results-labels'>
                            <CompHelper.TextField
                              field={site1NameField}
                              state={site1Name}
                              disabled
                              handleChange={() => null}
                            />
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {!showResultEditPanel && !showDeclinationPanel && !blockCompleted && (
          <div className='COVID-buttons-container'>
            {!blockCompleted && !showDeclineInfo && (
              <>
                <CompHelper.SimpleButton
                  style={{ marginLeft: '35px' }}
                  onClick={this.openDeclinePanel}
                >
                  Add Declination
                </CompHelper.SimpleButton>
                <CompHelper.SimpleButton onClick={this.addCompletion}>
                  {certificateDocumentsFiles?.value?.length
                    ? 'Edit Vaccination Info'
                    : 'Add Vaccination Certificate'}
                </CompHelper.SimpleButton>
                {!!certificateDocumentsFiles?.value?.length && (
                  <LinkButton fontSize='11px' onClick={this.toggleRemoveModal}>
                    remove
                  </LinkButton>
                )}
              </>
            )}
          </div>
        )}
        {blockCompleted && !showResultEditPanel && !showDeclinationPanel && (
          <div className='compliance-completed-container'>
            <p className='completed-text'>
              <Image
                src='/compliance/check.svg'
                style={{ paddingRight: '5px', display: 'inline' }}
              />
              VACCINATION COMPLETED:{' '}
              <CompHelper.DateText date={completionDate?.value || declinationDate?.value} />
            </p>
            {showRemoveEditButton && this.showRemoveEditVaccination()}
          </div>
        )}

        {closeEnabled && showDeclinationPanel && (
          <Image className='tinyCloseButton' src='/close-icon.svg' onClick={this.onClose} />
        )}
        {showDeclinationPanel && (
          <div className='compliance-declination-panel compliance-add-completion-panel'>
            <h3 className='compliance-add-completion-panel-header'>ADD DECLINATION FORM</h3>
            <div className='compliance-hep-date-container'>
              <p className='compliance-results-labels'>Enter Date of Declination Submission</p>
              <div
                className='medicalDateInput'
                style={{ justifySelf: 'flex-end', marginRight: '10px' }}
              >
                <CompHelper.ComplianceDatePicker
                  field={declinedDateField}
                  state={declinationDate}
                  disabled={false}
                  onChange={this.onFieldChanged}
                />
              </div>
              <div>
                <p className='compliance-results-labels'>Upload Declination Form</p>
                <p className='compliance-block-completion-field-description'>
                  The submitted declination form will be stored on records as proof of consent by
                  the caregiver.
                </p>
              </div>
              <div style={{ justifySelf: 'flex-end' }}>
                {this.renderDocumentField(declinationForm)}
              </div>
              <CompHelper.UpdateButton
                disabled={!dirtyMedicalBlocks.length}
                update={this.updateMedicalBlock}
              />
            </div>
          </div>
        )}

        {closeEnabled && showResultEditPanel && (
          <Image className='tinyCloseButton' src='/close-icon.svg' onClick={this.onClose} />
        )}
        {showResultEditPanel && (
          <div className='hep-b-test-series-one-container compliance-add-completion-panel'>
            <h3 className='compliance-add-completion-panel-header'>
              UPLOAD COMPLETED CERTIFICATE IMAGES
            </h3>
            <p className='compliance-block-completion-field-description'>
              The submitted form will be stored on records associated with this caregiver. Open the
              images to approve or reject them. Caregiver will be notified accordingly.
            </p>
            <div className='covid-vaccine-container'>
              <div style={{ justifySelf: 'flex-start' }}>
                {this.renderDocumentField(certificateDocumentsFiles)}
              </div>
              <div className='compliance-hep-date-container' style={{ justifySelf: 'flex-start' }}>
                <p className='compliance-results-labels'>Enter Vaccination Manufacturer:</p>
                <CompHelper.DropdownFields
                  field={manufacturerField}
                  selected={manufacturer}
                  options={MANUFACTURER_OPTIONS}
                  disabled={false}
                  handleChange={handleFieldChange}
                />
              </div>
              {!!manufacturer?.value && secondDoseRequired && (
                <>
                  <div
                    className='compliance-hep-date-container medicalDateInput'
                    style={{ justifySelf: 'flex-start', margin: '1rem 0' }}
                  >
                    <p className='compliance-results-labels'>Enter 1st Vaccination Date:</p>
                    <div className='compliance-justify-flex-end'>
                      <CompHelper.ComplianceDatePicker
                        field={firstDoseField}
                        state={firstDoseDate}
                        disabled={false}
                        onChange={this.onFieldChanged}
                      />
                    </div>
                  </div>
                  {vaccine1Site && (
                    <>
                      <div
                        className='compliance-hep-date-container'
                        style={{ marginBottom: '20px' }}
                      >
                        <p
                          className='compliance-results-labels'
                          style={{ justifySelf: 'flex-start' }}
                        >
                          Select Clinic Site:
                        </p>
                        <div className='medicalDateInput' style={{ justifySelf: 'flex-end' }}>
                          <CompHelper.DropdownFields
                            field={vaccine1SiteField}
                            selected={vaccine1Site}
                            options={CompHelper.siteOptions}
                            disabled={false}
                            handleChange={handleFieldChange}
                          />
                        </div>
                      </div>
                      <div
                        className='compliance-hep-date-container'
                        style={{ marginBottom: '20px' }}
                      >
                        <p
                          className='compliance-results-labels'
                          style={{ justifySelf: 'flex-start' }}
                        >
                          Add Location Name:
                        </p>
                        <div className='medicalDateInput' style={{ justifySelf: 'flex-end' }}>
                          <CompHelper.TextField
                            field={site1NameField}
                            state={site1Name}
                            disabled={false}
                            handleChange={handleFieldChange}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    className='compliance-hep-date-container medicalDateInput'
                    style={{ justifySelf: 'flex-start', margin: '1rem 0' }}
                  >
                    <p className='compliance-results-labels'>Enter 2nd Vaccination Date:</p>
                    <div className='compliance-justify-flex-end'>
                      <CompHelper.ComplianceDatePicker
                        field={secondDoseField}
                        state={secondDoseDate}
                        disabled={!firstDoseDate?.value}
                        onChange={this.onFieldChanged}
                      />
                    </div>
                  </div>
                  {vaccine2Site && (
                    <>
                      <div
                        className='compliance-hep-date-container'
                        style={{ marginBottom: '20px' }}
                      >
                        <p
                          className='compliance-results-labels'
                          style={{ justifySelf: 'flex-start' }}
                        >
                          Select Clinic Site:
                        </p>
                        <div className='medicalDateInput' style={{ justifySelf: 'flex-end' }}>
                          <CompHelper.DropdownFields
                            field={vaccine2SiteField}
                            selected={vaccine2Site}
                            options={CompHelper.siteOptions}
                            disabled={false}
                            handleChange={handleFieldChange}
                          />
                        </div>
                      </div>
                      <div
                        className='compliance-hep-date-container'
                        style={{ marginBottom: '20px' }}
                      >
                        <p
                          className='compliance-results-labels'
                          style={{ justifySelf: 'flex-start' }}
                        >
                          Add Location Name:
                        </p>
                        <div className='medicalDateInput' style={{ justifySelf: 'flex-end' }}>
                          <CompHelper.TextField
                            field={site2NameField}
                            state={site2Name}
                            disabled={false}
                            handleChange={handleFieldChange}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <p className='compliance-medium-gray'>
                    The 2nd vaccine should be administered on or after{' '}
                    <b className='compliance-charcoal-text-color'>{`${
                      vaccinationInterval[manufacturer.value]
                    }`}</b>{' '}
                    of the first vaccine.
                  </p>
                </>
              )}
              {!!manufacturer?.value && !secondDoseRequired && (
                <>
                  <div
                    className='compliance-hep-date-container medicalDateInput'
                    style={{ justifySelf: 'flex-start', margin: '1rem 0' }}
                  >
                    <p className='compliance-results-labels'>Enter Vaccination Date:</p>
                    <div className='compliance-justify-flex-end'>
                      <CompHelper.ComplianceDatePicker
                        field={firstDoseField}
                        state={firstDoseDate}
                        disabled={false}
                        onChange={this.onFieldChanged}
                      />
                    </div>
                  </div>
                  {vaccine1Site && (
                    <>
                      <div
                        className='compliance-hep-date-container'
                        style={{ marginBottom: '20px' }}
                      >
                        <p
                          className='compliance-results-labels'
                          style={{ justifySelf: 'flex-start' }}
                        >
                          Select Clinic Site:
                        </p>
                        <div className='medicalDateInput' style={{ justifySelf: 'flex-end' }}>
                          <CompHelper.DropdownFields
                            field={vaccine1SiteField}
                            selected={vaccine1Site}
                            options={CompHelper.siteOptions}
                            disabled={false}
                            handleChange={handleFieldChange}
                          />
                        </div>
                      </div>
                      <div
                        className='compliance-hep-date-container'
                        style={{ marginBottom: '20px' }}
                      >
                        <p
                          className='compliance-results-labels'
                          style={{ justifySelf: 'flex-start' }}
                        >
                          Add Location Name:
                        </p>
                        <div className='medicalDateInput' style={{ justifySelf: 'flex-end' }}>
                          <CompHelper.TextField
                            field={site1NameField}
                            state={site1Name}
                            disabled={false}
                            handleChange={handleFieldChange}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <CompHelper.UpdateButton disabled={disableUpdate()} update={this.updateMedicalBlock} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { caregiverDetails, caregiverCompliance, caregiverComplianceUI } = state;
  return {
    caregiver: caregiverDetails.caregiver,
    caregiverCompliance,
    caregiverComplianceUI,
  };
};

export default connect(mapStateToProps)(ComplianceCOVIDVaccine);

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { Dimmer, Progress } from 'semantic-ui-react';
import moment from 'moment';
import { history } from '../helpers';
import { sortCoursesList, getDueDate } from '../helpers/common';
import './ELDashboard.css';
import CareLoader from './subcomponents/CareLoader';
import ELSideInfo from './ELSideInfo';
import { RecordsTile } from '../styles/records';
import { GreenButton, SimpleButton } from '../styles/common';
import BlockELearningModal from './subcomponents/eLAPP/BlockELearningModal';
import { ELCoursesActions } from '../actions/ELApp/ElCoursesActions';
import { userActions } from '../actions';
import * as helpers from '../helpers/common';

class ELDashboard extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    // eslint-disable-next-line react/no-unused-state
    eLearning: true,
    fetchCourseDetails: false,
    courseFetchedByLanguage: false,
    coursesLength: 0,
    uninitialized: 0,
    inProgress: 0,
    completed: 0,
    pendingDate: 0,
    loadMore: 5,
  };

  componentWillUnmount() {
    this.setState({ fetchCourseDetails: false });
  }

  // eslint-disable-next-line react/sort-comp
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !prevState.fetchCourseDetails &&
      // eslint-disable-next-line react/prop-types
      !nextProps.loading &&
      nextProps &&
      prevState &&
      nextProps.eLCourses.courses.length !== prevState.coursesLength
    ) {
      const { courses } = nextProps.eLCourses;
      const orderCourses = sortCoursesList(courses);
      const uninitialized = orderCourses.filter(c => !c.isRegistered);
      const inProgress = orderCourses.filter(c => c.isRegistered && !c.completionDate);
      const completed = orderCourses.filter(c => c.isRegistered && c.completionDate).length;
      const pendingDate = orderCourses.filter(c => {
        const dueDate = getDueDate(c.curriculums);
        return !c.completionDate && moment(dueDate).isBefore(moment().add(30, 'd'));
      }).length;
      const coursesLength = courses.length;

      const primaryLanguage = helpers.getProfileLanguage(nextProps.profile);

      if (inProgress.length) {
        nextProps.dispatch(ELCoursesActions.getCoursesDetail(inProgress[0].id, primaryLanguage));
      } else if (uninitialized.length) {
        nextProps.dispatch(ELCoursesActions.getCoursesDetail(uninitialized[0].id, primaryLanguage));
      }

      return {
        uninitialized,
        inProgress,
        completed,
        pendingDate,
        coursesLength,
        fetchCourseDetails: true,
      };
    }

    if (!prevState.courseFetchedByLanguage && nextProps?.principal?.roles?.length) {
      if (nextProps.principal.roles.includes('caregiver')) {
        if (nextProps.profile?.languages) {
          const language = helpers.getProfileLanguage(nextProps.profile);
          nextProps.dispatch(ELCoursesActions.getCoursesList(language));
          return { courseFetchedByLanguage: true };
        }
      } else {
        nextProps.dispatch(ELCoursesActions.getCoursesList());
        return { courseFetchedByLanguage: true };
      }
    }
    return null;
  }

  circle = (num, color, background, completed) => {
    const redirection = completed ? 1 : 0;
    return (
      <div
        role='button'
        tabIndex={0}
        onClick={() => history.push('/home/e-learning/courses', redirection)}
        className='e-learning-dashboard-circle-circle'
        style={{ borderColor: color, background }}
      >
        <div>
          <h4 className='e-learning-dashboard-circle-num'>{num}</h4>
        </div>
      </div>
    );
  };

  handleLoadMore = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newLoadMore = this.state.loadMore + 5;
    return this.setState({ loadMore: newLoadMore });
  };

  handleViewCourses = () => history.push('/home/e-learning/courses');

  handleQuickStart = course => {
    this.props.dispatch(userActions.getUserSummary());
    if (!course.isRegistered) {
      const params = {
        id: course.id,
        courseLanguageId: course.courseLanguageId,
      };
      return this.props.dispatch(ELCoursesActions.startCourse(params));
    }
    return this.props.dispatch(ELCoursesActions.resumeCourse(course.id));
  };

  renderActivity = (activity, more) => {
    const sortedActivity = activity.sort((a, b) =>
      !a.completionDate ? -1 : a.completionDate - b.completionDate
    );
    const sliceActivity = sortedActivity.slice(0, more);
    const moreToLoad = more <= activity.length;

    return sliceActivity && sliceActivity.length ? (
      <RecordsTile className='e-learning-records-tile'>
        <div className='e-learning-records-content'>
          {sliceActivity.map(a => {
            const date = a.completionDate ? a.completionDate : a.startDate;
            const formattedDate = moment(date).format('DD MMM, YYYY');
            const status = a.completionDate ? 'completed' : 'started';
            return (
              <div key={a.id} className='e-learning-activity'>
                <p className='activity-name'>{a.courseName}</p>
                <p className={`completion-${status}`}>
                  {status === 'completed' ? 'completed' : 'In Progress'}
                </p>
                <p className='activity-date'>{formattedDate}</p>
              </div>
            );
          })}
        </div>
        {moreToLoad && (
          <SimpleButton className='e-learning-load-more' onClick={() => this.handleLoadMore()}>
            Load More
          </SimpleButton>
        )}
      </RecordsTile>
    ) : (
      <RecordsTile className='e-learning-empty-activity'>
        <img
          className='e-learning-empty-course-view-image'
          src='/complete-courses-empty.svg'
          alt='empty view'
        />
        <p className='empty-course-view-main-text'>You have no activity.</p>
        <p className='empty-course-view-sub-text' style={{ textAlign: 'center' }}>
          Once you have started your courses they will <br /> appear here started or completed
        </p>
      </RecordsTile>
    );
  };

  renderQuickstart = (quickStart, buttonLoading) => {
    if (quickStart.userCourse && quickStart.userCourse.id) {
      const buttonText = quickStart.userCourse.isRegistered ? 'Resume Course' : 'Start';
      const completedModules = quickStart.modules.filter(m => m.dateCompleted);
      const progress = !completedModules.length
        ? 0
        : (completedModules.length * 100) / quickStart.modules.length;
      const hideBar = progress <= 0 ? 'hide-progress-bar' : '';
      return (
        <RecordsTile className='e-learning-records-tile-quick-start'>
          <h6 className='e-learning-quick-start-course-record-name'>
            {quickStart.userCourse.courseName}
          </h6>
          <div className='e-learning-quick-start-in-progress-bar-container'>
            <Progress
              className={`e-learning-quick-start-in-progress-course-progress-bar ${hideBar}`}
              percent={progress}
              size='tiny'
            />
          </div>
          <ReactMarkdown className='e-learning-quick-start-in-progress-course-description'>
            {quickStart.userCourse.courseDescription || ''}
          </ReactMarkdown>

          <GreenButton
            id='quick-start-resume-button'
            className='course-record-resume-button'
            disabled={buttonLoading}
            loading={buttonLoading}
            onClick={() => this.handleQuickStart(quickStart.userCourse)}
          >
            {buttonText}
          </GreenButton>

          <SimpleButton
            className='e-learning-record-button'
            onClick={() => this.handleViewCourses()}
          >
            View All My Courses
          </SimpleButton>
        </RecordsTile>
      );
    }
    return (
      <RecordsTile className='e-learning-empty-activity'>
        <img
          className='e-learning-empty-course-view-image'
          src='/course-view-empty.svg'
          alt='empty view'
        />
        <p className='empty-course-view-main-text'>No courses to show</p>
        <p className='empty-course-view-sub-text' style={{ textAlign: 'center' }}>
          Once courses are assigned to you <br /> they will appear here
        </p>
      </RecordsTile>
    );
  };

  render() {
    const { loading, courses, buttonLoading, courseDetails } = this.props.eLCourses;
    const { employee } = this.props;
    const { firstName } = this.props.profile;
    const { onShift } = this.props.principal;
    const { uninitialized, inProgress, completed, pendingDate } = this.state;
    const activity = courses.filter(a => a.isRegistered);

    const userName = () => {
      const name = employee && employee.employee ? employee.employee.firstName : firstName;
      return <h4 className='e-learning-welcome-name'>{name ? ` ${name}` : ''}</h4>;
    };

    return (
      <div className='e-learning-main-container'>
        {onShift && <BlockELearningModal />}
        {loading && (
          <Dimmer active inverted>
            <CareLoader loading showText={false} />
          </Dimmer>
        )}
        <div className='e-learning-welcome'>
          <h4 className='e-learning-welcome-text'>WELCOME TO E-LEARNING{', '}</h4>
          {userName()}
        </div>
        <div className='e-learning-side-help-container'>
          <ELSideInfo />
        </div>
        <div className='e-learning-content'>
          <div className='e-learning-info-circle-container'>
            <div className='circle-info-container'>
              <h5 className='e-learning-circle-header'>Uninitiated Modules</h5>
              <p className='e-learning-circle-info'>
                Total number of courses that have been assigned to you that you have yet to begin.
              </p>
              {this.circle(uninitialized && uninitialized.length, '#04A5D5', undefined, false)}
            </div>
            <div className='circle-info-container'>
              <h5 className='e-learning-circle-header'>In-Progress Modules</h5>
              <p className='e-learning-circle-info'>
                Total number of modules that have been assigned to you that you have started, but
                have yet to complete.
              </p>
              {this.circle(inProgress && inProgress.length, '#04A5D5', '#04A5D5', false)}
            </div>
            <div className='circle-info-container'>
              <h5 className='e-learning-circle-header'>Pending Due Date</h5>
              <p className='e-learning-circle-info'>
                Modules with a due date within the next month. You must complete these modules prior
                to the due date.
              </p>
              {this.circle(pendingDate, '#F16778', '#F16778', false)}
            </div>
            <div className='circle-info-container'>
              <h5 className='e-learning-circle-header'>Completed Modules</h5>
              <p className='e-learning-circle-info'>
                Total number of successfully completed modules.
              </p>
              {this.circle(completed, '#69DB8F', '#69DB8F', true)}
            </div>
          </div>
          <div className='e-learning-activity-modules-container'>
            <div className='e-learning-record'>
              <h5 className='e-learning-records-header'>E-LEARNING ACTIVITY</h5>
              {this.renderActivity(activity, this.state.loadMore)}
            </div>
            <div className='e-learning-record'>
              <h5 className='e-learning-records-header'>MODULE QUICKSTART</h5>
              {this.renderQuickstart(courseDetails, buttonLoading)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ELDashboard.propTypes = {
  employee: PropTypes.shape().isRequired,
  profile: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  eLCourses: PropTypes.shape().isRequired,
  principal: PropTypes.shape().isRequired,
};

const mapStateToProps = state => {
  const { principal, employee, eLCourses } = state;
  const { profile } = principal;
  return {
    principal,
    profile,
    employee,
    eLCourses,
  };
};

export default connect(mapStateToProps)(ELDashboard);

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import { Progress } from 'semantic-ui-react';
import { history } from '../../../helpers';
import { getDueDate, sortCoursesList } from '../../../helpers/common';
import { SimpleButton, GreenButton } from '../../../styles/common';
import { ELCoursesActions } from '../../../actions/ELApp/ElCoursesActions';
import { userActions } from '../../../actions';
import '../../ELCourses.css';

class CoursesListView extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    // eslint-disable-next-line react/no-unused-state
    eLearning: true,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(userActions.getUserSummary());
    window.scrollTo(0, 0);
  }

  handleViewCourseInfo = id => history.push(`/home/e-learning/courses/course-details/${id}`);

  handleResumeCourse = id => {
    const { dispatch } = this.props;
    dispatch(userActions.getUserSummary());
    dispatch(ELCoursesActions.resumeCourse(id));
  };

  handleStartCourse = (course, languages) => {
    const { dispatch } = this.props;
    dispatch(userActions.getUserSummary());
    const primaryLanguage = languages?.length
      ? languages.filter(l => l.primary).map(l => l.name)
      : ['english'];

    const coursePrimaryLanguage =
      course.availableLanguages?.length && primaryLanguage.length
        ? course.availableLanguages
            .filter(l => l.name.toLowerCase() === primaryLanguage[0].toLowerCase())
            .map(l => l.id)
        : [];

    const courseLanguage = coursePrimaryLanguage.length
      ? coursePrimaryLanguage[0]
      : course.availableLanguages[0].id;
    const params = {
      id: course.id,
      courseLanguageId: courseLanguage,
    };
    return dispatch(ELCoursesActions.startCourse(params));
  };

  dueDateFormatted = date => moment(date).format('DD MMM YYYY');

  render() {
    const { courses, languages, buttonLoading, inProgressCourse } = this.props;

    const sortedCourses = sortCoursesList(courses);
    const completedModules = inProgressCourse?.modules
      ? inProgressCourse.modules.filter(m => m.dateCompleted)
      : [];
    const progress = !completedModules.length
      ? 0
      : (completedModules.length * 100) / inProgressCourse.modules.length;
    const hideBar = progress <= 0 ? 'hide-progress-bar' : '';

    const courseInProgress = inProgressCourse?.userCourse?.isRegistered || this.props.loading;

    const inProgressCourseDueDate = getDueDate(inProgressCourse?.userCourse?.curriculums);

    const inProgressCourseLength = inProgressCourse?.userCourse?.courseLength;

    return (
      <div className='courses-list-container'>
        {courses?.length ? (
          <div className='courses-list'>
            {inProgressCourse?.userCourse?.isRegistered && (
              <div className='courses-list-in-progress'>
                <h3 className='courses-list-header'>In-Progress Course</h3>
                <p>You must complete your in-progress course before starting any new course</p>
                <div className='courses-list-grid'>
                  <div className='course-List-container'>
                    <h6 className='course-record-name'>{inProgressCourse.userCourse.courseName}</h6>
                    <p className='course-record-due-date'>
                      Due date: {this.dueDateFormatted(inProgressCourseDueDate)} <br />
                      Time: {inProgressCourseLength}mins
                    </p>
                    <div className='in-progress-bra-container'>
                      <Progress
                        className={`in-progress-course-progress-bar ${hideBar}`}
                        percent={progress}
                        size='tiny'
                      />
                    </div>
                    <ReactMarkdown className='in-progress-course-description'>
                      {inProgressCourse.userCourse.courseDescription || ''}
                    </ReactMarkdown>
                    <section className='course-record-buttons-section'>
                      <SimpleButton
                        className='course-record-view-button'
                        onClick={() => this.handleViewCourseInfo(inProgressCourse.userCourse.id)}
                      >
                        View Course Info
                      </SimpleButton>
                      <GreenButton
                        className='course-record-resume-button'
                        onClick={() => this.handleResumeCourse(inProgressCourse.userCourse.id)}
                      >
                        Resume Course
                      </GreenButton>
                    </section>
                  </div>
                </div>
              </div>
            )}
            <div className='courses-list-pending'>
              <h3 className='courses-list-header'>my courses {`(${courses.length})`}</h3>
              <div className='courses-list-grid'>
                {!!courses?.length &&
                  sortedCourses.map(c => {
                    const dueDate = getDueDate(c.curriculums);
                    const courseLength = `${c.courseLength}mins`;
                    return (
                      <div key={c.id} className='course-List-record-container'>
                        <h6 className='course-record-name'>{c.courseName}</h6>
                        <p className='course-record-due-date'>
                          Due date: {this.dueDateFormatted(dueDate)} <br />
                          Time: {courseLength}
                        </p>
                        <ReactMarkdown className='course-record-description'>
                          {c.courseDescription || ''}
                        </ReactMarkdown>
                        <section className='course-record-buttons-section'>
                          <SimpleButton
                            className='course-record-view-button'
                            onClick={() => this.handleViewCourseInfo(c.id)}
                          >
                            View Course Info
                          </SimpleButton>
                          {!courseInProgress && (
                            <GreenButton
                              loading={buttonLoading}
                              disabled={buttonLoading}
                              className='course-record-resume-button'
                              onClick={() => this.handleStartCourse(c, languages)}
                            >
                              Start Course
                            </GreenButton>
                          )}
                        </section>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        ) : (
          <div className='empty-course-view-container'>
            <img
              className='empty-course-view-image'
              src='/course-view-empty.svg'
              alt='empty view'
            />
            <p className='empty-course-view-main-text'>You have no pending courses.</p>
            <p className='empty-course-view-sub-text'>
              We will contact you when you are enrolled in your next course.
            </p>
          </div>
        )}
      </div>
    );
  }
}

CoursesListView.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dispatch: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  inProgressCourse: PropTypes.shape().isRequired,
};

export default CoursesListView;

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import '../../CaregiverRecords.css';

class CGTileInformationNote extends React.Component {
  constructor() {
    super();
    // eslint-disable-next-line react/no-unused-state
    this.state = { draftNotes: [{ type: '', subType: '', message: '' }] };
  }

  render() {
    const { intl, onValueChange, editable, index, note } = this.props;
    const notePlaceholder = intl.formatMessage({ id: 'records.note.placeholder' });
    const noteType = intl.formatMessage({ id: 'records.note.type' });
    const noteTypeOptions = [{ key: 'c', text: 'call', value: 1 }];
    const noteSubType = intl.formatMessage({ id: 'records.note.subType' });
    const noteSubTypeOptions = [
      { key: 'cr', text: 'call received', value: 1 },
      { key: 'mr', text: 'message received', value: 2 },
      { key: 'ml', text: 'message left', value: 3 },
    ];

    return (
      <Grid>
        <Grid.Row className='details-box-wide' style={{ paddingBottom: 0 }}>
          <Grid.Column width={8}>
            <Form.Select
              id='note-type'
              name={`caregiverNotes.${index}.type`}
              label={noteType}
              options={noteTypeOptions}
              placeholder='Choose...'
              onChange={onValueChange}
              value={note ? note.type : null}
              disabled={!editable}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Select
              id='note-sub-type'
              name={`caregiverNotes.${index}.subType`}
              label={noteSubType}
              options={noteSubTypeOptions}
              placeholder='Choose...'
              onChange={onValueChange}
              value={note ? note.subType : null}
              disabled={!editable}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className='details-box-wide'>
          <Form.TextArea
            id='cg-tile-information-note-text-area'
            className='details-note'
            type='text'
            name={`caregiverNotes.${index}.message`}
            rows={4}
            placeholder={notePlaceholder}
            onChange={onValueChange}
            value={note ? note.message : null}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

CGTileInformationNote.propTypes = {
  intl: intlShape.isRequired,
  note: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
  onValueChange: PropTypes.func.isRequired,
};
export default injectIntl(CGTileInformationNote);

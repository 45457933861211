import {
  GET_CAREGIVER_COMPLIANCE_DOH_REQUEST,
  GET_CAREGIVER_COMPLIANCE_DOH_SUCCESS,
  GET_CAREGIVER_COMPLIANCE_DOH_FAILURE,
  GET_CAREGIVER_COMPLIANCE_EXCLUSIONS_REQUEST,
  GET_CAREGIVER_COMPLIANCE_EXCLUSIONS_SUCCESS,
  GET_CAREGIVER_COMPLIANCE_EXCLUSIONS_FAILURE,
  UPDATE_CAREGIVER_COMPLIANCE_DOHINFO_REQUEST,
  UPDATE_CAREGIVER_COMPLIANCE_DOHINFO_SUCCESS,
  UPDATE_CAREGIVER_COMPLIANCE_DOHINFO_FAILURE,
  UPDATE_CAREGIVER_COMPLIANCE_EXCLUSIONS_REQUEST,
  UPDATE_CAREGIVER_COMPLIANCE_EXCLUSIONS_SUCCESS,
  UPDATE_CAREGIVER_COMPLIANCE_EXCLUSIONS_FAILURE,
  GET_CAREGIVER_DOH_CANDIDATES_REQUEST,
  GET_CAREGIVER_DOH_CANDIDATES_SUCCESS,
  GET_CAREGIVER_DOH_CANDIDATES_FAILURE,
  SET_CAREGIVER_COMPLIANCE_BLOCK_REQUEST,
  SET_CAREGIVER_COMPLIANCE_BLOCK_SUCCESS,
  SET_CAREGIVER_COMPLIANCE_BLOCK_FAILURE,
  SET_CAREGIVER_COMPLIANCE_BY_PROFILE_REQUEST,
  SET_CAREGIVER_COMPLIANCE_BY_PROFILE_SUCCESS,
  SET_CAREGIVER_COMPLIANCE_BY_PROFILE_FAILURE,
  GET_CAREGIVER_COMPLIANCE_BLOCK_REQUEST,
  GET_CAREGIVER_COMPLIANCE_BLOCK_SUCCESS,
  GET_CAREGIVER_COMPLIANCE_BLOCK_FAILURE,
  CLEAR_CAREGIVER_COMPLIANCE_BLOCKS,
  DOWNLOAD_CAREGIVER_COMPLIANCE_DOCUMENTS_REQUEST,
  DOWNLOAD_CAREGIVER_COMPLIANCE_DOCUMENTS_SUCCESS,
  DOWNLOAD_CAREGIVER_COMPLIANCE_DOCUMENTS_FAILURE,
  UPLOAD_CAREGIVER_COMPLIANCE_DOCUMENT_REQUEST,
  UPLOAD_CAREGIVER_COMPLIANCE_DOCUMENT_SUCCESS,
  UPLOAD_CAREGIVER_COMPLIANCE_DOCUMENT_FAILURE,
} from '../../constants/ActionTypes';

const initialState = {
  loadingCompliance: false,
  complianceError: false,
  complianceExclusionsError: false,
  hasExclusions: false,
  needToDownloadDocuments: false,
  loadingDohCandidates: false,
  doh: null,
  dohCandidates: null,
  loadingBlocks: false,
  blocks: null,
  blocksError: false,
  inServiceCourses: null,
  loadingInServiceCourses: false,
  inServiceCoursesError: false,
};

const startState = JSON.parse(JSON.stringify(initialState));

function caregiverCompliance(state = startState, action) {
  switch (action.type) {
    case GET_CAREGIVER_COMPLIANCE_DOH_REQUEST: {
      return { ...state, loadingDoh: true, dohError: false };
    }
    case GET_CAREGIVER_COMPLIANCE_DOH_SUCCESS: {
      return { ...state, loadingDoh: false, doh: action.results.dohInfo, dohError: false };
    }
    case GET_CAREGIVER_COMPLIANCE_DOH_FAILURE: {
      return { ...state, loadingDoh: false, doh: null, dohError: true };
    }
    case GET_CAREGIVER_COMPLIANCE_EXCLUSIONS_REQUEST: {
      return { ...state, loadingExclusions: true, exclusionsError: false };
    }
    case GET_CAREGIVER_COMPLIANCE_EXCLUSIONS_SUCCESS: {
      return {
        ...state,
        exclusions: action.results.exclusions,
        hasExclusions: action.results.hasExclusions,
        loadingExclusions: false,
        exclusionsError: false,
      };
    }
    case GET_CAREGIVER_COMPLIANCE_EXCLUSIONS_FAILURE: {
      return { ...state, exclusions: null, loadingExclusions: false, exclusionsError: true };
    }
    case UPDATE_CAREGIVER_COMPLIANCE_DOHINFO_REQUEST: {
      return { ...state, loadingDoh: true, dohError: false };
    }
    case UPDATE_CAREGIVER_COMPLIANCE_DOHINFO_SUCCESS: {
      return { ...state, loadingDoh: false, doh: action.results.dohInfo, dohError: false };
    }
    case UPDATE_CAREGIVER_COMPLIANCE_DOHINFO_FAILURE: {
      return { ...state, loadingDoh: false, dohError: true };
    }
    case UPDATE_CAREGIVER_COMPLIANCE_EXCLUSIONS_REQUEST: {
      return { ...state, loadingExclusions: true, exclusionsError: false };
    }
    case UPDATE_CAREGIVER_COMPLIANCE_EXCLUSIONS_SUCCESS: {
      return {
        ...state,
        loadingExclusions: false,
        exclusions: action.results.exclusions,
        hasExclusions: action.results.hasExclusions,
        exclusionsError: false,
      };
    }
    case UPDATE_CAREGIVER_COMPLIANCE_EXCLUSIONS_FAILURE: {
      return { ...state, loadingExclusions: false, exclusionsError: true };
    }
    case GET_CAREGIVER_COMPLIANCE_BLOCK_REQUEST: {
      return { ...state, loadingBlocks: true, blocksError: false, needToDownloadDocuments: false };
    }
    case GET_CAREGIVER_COMPLIANCE_BLOCK_FAILURE: {
      return { ...state, loadingBlocks: false, blocksError: true, needToDownloadDocuments: false };
    }
    case GET_CAREGIVER_COMPLIANCE_BLOCK_SUCCESS: {
      return {
        ...state,
        loadingBlocks: false,
        blocksError: false,
        blocks: action.results.blocks,
        needToDownloadDocuments: true,
      };
    }
    case CLEAR_CAREGIVER_COMPLIANCE_BLOCKS: {
      return {
        ...state,
        blocksError: false,
        blocks: [],
      };
    }
    case SET_CAREGIVER_COMPLIANCE_BLOCK_REQUEST:
    case SET_CAREGIVER_COMPLIANCE_BY_PROFILE_REQUEST: {
      return { ...state, loadingBlocks: true, blocksError: false, needToDownloadDocuments: false };
    }
    case SET_CAREGIVER_COMPLIANCE_BLOCK_FAILURE:
    case SET_CAREGIVER_COMPLIANCE_BY_PROFILE_FAILURE: {
      return { ...state, loadingBlocks: false, blocksError: true, needToDownloadDocuments: false };
    }
    case SET_CAREGIVER_COMPLIANCE_BLOCK_SUCCESS:
    case SET_CAREGIVER_COMPLIANCE_BY_PROFILE_SUCCESS: {
      return {
        ...state,
        loadingBlocks: false,
        blocks: action.results.blocks,
        blocksError: false,
        needToDownloadDocuments: true,
      };
    }
    case GET_CAREGIVER_DOH_CANDIDATES_REQUEST: {
      return { ...state, loadingDohCandidates: true };
    }
    case GET_CAREGIVER_DOH_CANDIDATES_SUCCESS: {
      return { ...state, loadingDohCandidates: false, dohCandidates: action.results };
    }
    case GET_CAREGIVER_DOH_CANDIDATES_FAILURE: {
      return { ...state, loadingDohCandidates: false };
    }
    case DOWNLOAD_CAREGIVER_COMPLIANCE_DOCUMENTS_REQUEST: {
      return {
        ...state,
        loadingDocuments: true,
        documentsError: false,
        needToDownloadDocuments: false,
      };
    }
    case DOWNLOAD_CAREGIVER_COMPLIANCE_DOCUMENTS_FAILURE: {
      return {
        ...state,
        loadingDocuments: false,
        documentsError: true,
        needToDownloadDocuments: false,
      };
    }
    case DOWNLOAD_CAREGIVER_COMPLIANCE_DOCUMENTS_SUCCESS: {
      if (state.blocks && action.results && action.results.documents) {
        state.blocks.forEach(block => {
          if (block.fields) {
            block.fields.forEach(field => {
              if (field.type === 'document' && field.value) {
                Object.assign(field, { filesDownloaded: true });
                Object.assign(field, { downloadLinks: {} });
                field.value.forEach(file => {
                  Object.defineProperty(field.downloadLinks, `${file.filename}`, {
                    value: action.results.documents.find(f => f.file === file.filename)
                      .downloadLink,
                  });
                });
              }
            });
          }
        });
      }
      return {
        ...state,
        loadingDocuments: false,
        documentsError: false,
        needToDownloadDocuments: false,
      };
    }
    case UPLOAD_CAREGIVER_COMPLIANCE_DOCUMENT_REQUEST: {
      return { ...state, uploadingDocument: action.fieldId };
    }
    case UPLOAD_CAREGIVER_COMPLIANCE_DOCUMENT_SUCCESS: {
      return { ...state, uploadingDocument: null };
    }
    case UPLOAD_CAREGIVER_COMPLIANCE_DOCUMENT_FAILURE: {
      return { ...state, uploadingDocument: null };
    }
    default:
      return state;
  }
}

export default caregiverCompliance;

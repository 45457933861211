import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100vw;
`;

export const BlurredNoAuthorizationBackground: React.FC = () => {
  return (
    <Container>
      <img src='/reports-blurred-bg.jpg' alt='reports disabled' width='100%' />
    </Container>
  );
};

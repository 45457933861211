import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import SubTabHeader from './subcomponents/SubTabHeader';
import DetailsBox from './subcomponents/records/DetailsBox';
import ClientLocation from './subcomponents/records/ClientLocation';
import RecordsLanguages from './subcomponents/RecordsLanguages';
import PreferencesCategory from './subcomponents/PreferencesCategory';

class ClientRequirements extends React.Component {
  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onActive(this.props.tabIndex);
  }

  render() {
    const { intl, languages, onValueChange, saveEnabled, onSave, client, editable } = this.props;

    return (
      <>
        <SubTabHeader
          text={intl.formatMessage({ id: 'clients.records.clientRequirements' }).toUpperCase()}
          buttonTitle={intl.formatMessage({ id: 'records.saveUpdates' })}
          buttonOnClick={onSave}
          buttonDisabled={!saveEnabled}
        />
        <Grid columns={1} style={{ width: '100%' }} doubling>
          <Grid.Column width={16} style={{ flex: '0 0 72rem' }}>
            <DetailsBox
              text={intl.formatMessage({ id: 'clients.records.locationOverview' }).toUpperCase()}
              withoutIcon
            >
              <ClientLocation person={client} />
            </DetailsBox>
          </Grid.Column>
        </Grid>

        <Grid columns={2} style={{ width: '100%' }} doubling>
          <Grid.Column width={8} style={{ flex: '0 0 36rem', maxWidth: '36rem' }}>
            <Grid.Row>
              <DetailsBox
                text={intl.formatMessage({ id: 'records.languages' }).toUpperCase()}
                withoutIcon
              >
                <RecordsLanguages
                  person={client}
                  languages={languages}
                  // editable
                  editable={editable}
                  onValueChange={onValueChange}
                  type='client'
                />
              </DetailsBox>
            </Grid.Row>

            <br />

            <Grid.Row>
              <DetailsBox
                text={intl.formatMessage({ id: 'clients.records.skills' }).toUpperCase()}
                withoutIcon
              >
                <PreferencesCategory
                  person={client}
                  editable
                  category='skills'
                  onValueChange={onValueChange}
                  type='client'
                />
              </DetailsBox>
            </Grid.Row>

            <br />

            <Grid.Row>
              <DetailsBox
                text={intl.formatMessage({ id: 'clients.records.experience' }).toUpperCase()}
                withoutIcon
              >
                <PreferencesCategory
                  person={client}
                  editable
                  category='experience'
                  onValueChange={onValueChange}
                  type='client'
                />
              </DetailsBox>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={8} style={{ flex: '0 0 36rem', maxWidth: '36rem' }}>
            <Grid.Row>
              <DetailsBox
                text={intl.formatMessage({ id: 'clients.records.environment' }).toUpperCase()}
                withoutIcon
              >
                <PreferencesCategory
                  person={client}
                  editable
                  category='environment'
                  onValueChange={onValueChange}
                  type='client'
                />
              </DetailsBox>
            </Grid.Row>

            <br />

            <Grid.Row>
              <DetailsBox
                text={intl.formatMessage({ id: 'clients.records.other' }).toUpperCase()}
                withoutIcon
              >
                <PreferencesCategory
                  person={client}
                  editable
                  category='other'
                  onValueChange={onValueChange}
                  type='client'
                />
              </DetailsBox>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </>
    );
  }
}

ClientRequirements.defaultProps = {
  saveEnabled: false,
};

ClientRequirements.propTypes = {
  intl: intlShape.isRequired,
  editable: PropTypes.bool.isRequired,
  client: PropTypes.shape().isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSave: PropTypes.func.isRequired,
  saveEnabled: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
  onActive: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default injectIntl(ClientRequirements);

/* eslint-disable react/require-default-props */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Input } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import DetailsBox from './DetailsBox';
import { RecordsForm } from '../../../styles/records';
import '../../CaregiverRecords.css';

class TileSocialProfiles extends React.Component {
  constructor() {
    super();
    this.state = {
      facebookLink: '',
      submitedfbLink: '',
      linkedinLink: '',
      submitldLink: '',
      facebookLinkEnabled: false,
      linkedinLinkEnabled: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.form.formsyForm.setFormPristine(false);
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
    if (value.length > 0 && value.length < 225) {
      this.setState({ [`${name}Enabled`]: true });
    } else {
      this.setState({ [`${name}Enabled`]: false });
    }
  };

  handleSubmit = (e, linkName, linkValue) => {
    const { facebookLink, linkedinLink } = this.state;
    this.setState({ submitedfbLink: facebookLink, submitldLink: linkedinLink }, () => {
      this.props.onValueChange(
        e,
        {
          name: linkName,
          value: this.state[linkValue],
        },
        true
      );
    });
  };

  handleDelete = (linkName, linkValue) => {
    this.setState({ [linkValue]: '' }, () => {
      this.props.onValueChange(
        true,
        {
          name: linkName,
          value: '',
        },
        true
      );
    });
  };

  render() {
    const { person, intl, type, onValid, onInvalid, editability } = this.props;
    const isCaregiver = type === 'caregiver';
    const { facebookLink, linkedinLink, facebookLinkEnabled, linkedinLinkEnabled } = this.state;

    return isCaregiver ? (
      <DetailsBox
        text={intl.formatMessage({ id: 'records.socialProfiles' }).toUpperCase()}
        icon='/icon_large_socialprofiles.svg'
      >
        <RecordsForm
          onValid={() => onValid('socialProfile')}
          onInvalid={() => onInvalid('socialProfile')}
          size='tiny'
          ref={c => {
            this.form = c;
          }}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <div>
                  <p style={{ marginBottom: '2px' }}>Facebook</p>
                  {person.facebookLink && (
                    <div>
                      <a target='_blank' href={`https://www.facebook.com/${person.facebookLink}`}>
                        www.facebook.com/{person.facebookLink}
                      </a>
                      {editability.socialprofiles && (
                        <Button
                          id='tile-social-profile-delete-fb-button'
                          icon='trash alternate'
                          style={{
                            background: 'none',
                            position: 'absolute',
                            right: '10px',
                            lineHeight: '0',
                          }}
                          onClick={() => this.handleDelete('facebookLink', 'submitedfbLink')}
                        />
                      )}
                    </div>
                  )}

                  {!person.facebookLink && editability.socialprofiles && (
                    <span style={{ color: '#979797' }}>
                      <Form onSubmit={e => this.handleSubmit(e, 'facebookLink', 'submitedfbLink')}>
                        <Form.Field inline>
                          <label htmlFor='caregiver-social-profile-facebook'>
                            {' '}
                            www.facebook.com/
                          </label>
                          <Input
                            className='social-media-field'
                            width={6}
                            id='caregiver-social-profile-facebook'
                            name='facebookLink'
                            disabled={!editability.socialprofiles}
                            value={facebookLink}
                            onChange={this.handleChange}
                            maxLength={255}
                            type='text'
                          />
                          <Button
                            id='tile-social-profile-add-fb-button'
                            disabled={!facebookLinkEnabled}
                            circular
                            icon='plus'
                            size='mini'
                            style={{
                              background: '#04a5d5',
                              position: 'absolute',
                              right: '2px',
                            }}
                          />
                        </Form.Field>
                      </Form>
                    </span>
                  )}
                  {!person.facebookLink && !editability.socialprofiles && <span>-</span>}
                </div>

                <div style={{ marginTop: '16px' }}>
                  <p style={{ marginBottom: '2px' }}>Linkedin</p>
                  {person.linkedinLink && (
                    <div>
                      <a
                        target='_blank'
                        href={`https://www.linkedin.com/in/${person.linkedinLink}`}
                      >
                        www.linkedin.com/in/{person.linkedinLink}
                      </a>
                      {editability.socialprofiles && (
                        <Button
                          id='tile-social-profile-delete-ld-button'
                          icon='trash alternate'
                          style={{
                            background: 'none',
                            position: 'absolute',
                            right: '10px',
                            lineHeight: '0',
                          }}
                          onClick={() => this.handleDelete('linkedinLink', 'submitldLink')}
                        />
                      )}
                    </div>
                  )}
                  {!person.linkedinLink && editability.socialprofiles && (
                    <span style={{ color: '#979797' }}>
                      <Form onSubmit={e => this.handleSubmit(e, 'linkedinLink', 'submitldLink')}>
                        <Form.Field inline>
                          <label htmlFor='caregiver-social-profile-linkedin'>
                            www.linkedin.com/in/
                          </label>
                          <Input
                            width={6}
                            className='social-media-field'
                            id='caregiver-social-profile-linkedin'
                            name='linkedinLink'
                            disabled={!editability.socialprofiles}
                            value={linkedinLink}
                            onChange={this.handleChange}
                            maxLength={255}
                            type='text'
                          />
                          <Button
                            id='tile-social-profile-add-ld-button'
                            circular
                            disabled={!linkedinLinkEnabled}
                            icon='plus'
                            size='mini'
                            style={{
                              background: '#04a5d5',
                              position: 'absolute',
                              right: '2px',
                            }}
                          />
                        </Form.Field>
                      </Form>
                    </span>
                  )}
                  {!person.linkedinLink && !editability.socialprofiles && <span>-</span>}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </RecordsForm>
      </DetailsBox>
    ) : (
      ''
    );
  }
}

TileSocialProfiles.defaultProps = {
  person: {},
  onValid: () => {},
  onInvalid: () => {},
  type: 'caregiver',
};

TileSocialProfiles.propTypes = {
  person: PropTypes.shape().isRequired,
  intl: intlShape.isRequired,
  onValueChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  onValid: PropTypes.func.isRequired,
  onInvalid: PropTypes.func.isRequired,
  editability: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default injectIntl(TileSocialProfiles);

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Header, Button, Modal, Image, Tab, Dimmer } from 'semantic-ui-react';
import './TrainPref.css';
import { userActions } from '../actions';
import { ProfileMenu } from './Profile';
import { isAdmin } from '../constants/DomainTypes';
import PreferencesTable from './subcomponents/PreferencesTable';
import ProfileFieldTable from './subcomponents/ProfileFieldTable';
import { agencySettingsActions } from '../actions/agencySettings';
import { complianceSettingsActions } from '../actions/complianceSettings';
import BotSettings, { reminderTypes, getKeyOfReminderMode } from './subcomponents/BotSettings';
import ApplicationToolConfig from './subcomponents/apptool/ApplicationToolConfig';
import ComplianceSettings from './subcomponents/ComplianceSettings';
import RateSettings from './subcomponents/RateSettings';
import SystemExtensions from './subcomponents/SystemExtensions';
import CareLoader from './subcomponents/CareLoader';
import ReportingSettings from './subcomponents/ReportingSettings';
import { applicationToolActions } from '../actions/apptool/actions';
import { elearningSettingsActions } from '../actions/elearningSettings';
import AddNewPrefForm from './subcomponents/pref/AddNewPrefForm';
import ElearningSettings from './subcomponents/pref/ElearningSettings/ElearningSettings';

class TrainPref extends React.Component {
  constructor() {
    super();

    this.state = {
      preferences: [],
      profileFields: [],
      complianceSettings: {},
      loading: false,
      attributesDirty: false,
      agencySettingsDirty: false,
      applicationToolConfig: {},
      loadingApplicationToolConfig: false,
      complianceSettingsDirty: false,
      addNewFormVisible: false,
      elearningSettingsDirty: false,
      elearningSettings: {},
    };

    this.toggleAddNewForm.bind(this);
    this.handleUpdate.bind(this);
    this.handleCreate.bind(this);
    this.handleRemove.bind(this);
    this.handleSave.bind(this);
    this.agencySettingsUpdated.bind(this);
    this.saveEnabled.bind(this);
    this.onComplianceBlockUpdated = this.onComplianceBlockUpdated.bind(this);
    this.onClassUpdated = this.onClassUpdated.bind(this);
    this.handleComplianceBlockCreate = this.handleComplianceBlockCreate.bind(this);
    this.addClassRoom = this.addClassRoom.bind(this);
  }

  async componentDidMount() {
    this.props.dispatch(userActions.getAttributes());
    this.props.dispatch(agencySettingsActions.getAgencySettings(true));
    this.props.dispatch(complianceSettingsActions.getComplianceBlocks());
    this.props.dispatch(complianceSettingsActions.getComplianceClassrooms());
    this.props.dispatch(applicationToolActions.getApplicationToolConfig());
    this.props.dispatch(elearningSettingsActions.getElearningSettings());
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.attributes && nextProps.attributes !== this.props.attributes) {
      this.setState(s => ({
        ...s,
        preferences: nextProps.attributes.preferences,
        profileFields: nextProps.attributes.profileFields,
        loading: nextProps.attributes.loading,
        attributesDirty: false,
        addNewFormVisible: false,
      }));
    }
    if (
      nextProps.agencySettings &&
      !nextProps.agencySettings.loading &&
      nextProps.agencySettings !== this.props.agencySettings
    ) {
      this.setState(s => ({
        ...s,
        agencySettingsDirty: false,
        shiftReminderTime: nextProps.agencySettings.shiftReminderTime,
        selectedShiftReminder: getKeyOfReminderMode(nextProps.agencySettings.shiftReminderMode),
        selectedRegistrationReminder: getKeyOfReminderMode(
          nextProps.agencySettings.registrationReminderMode
        ),
        registrationReminderTime: nextProps.agencySettings.registrationReminderTime,
        shiftReminderMaxAttempts: nextProps.agencySettings.shiftReminderMaxAttempts,
        registrationReminderMaxAttempts: nextProps.agencySettings.registrationReminderMaxAttempts,
        shiftReminderAlertEnabled: nextProps.agencySettings.shiftReminderAlertEnabled,
        shiftReminderAlertTiming: nextProps.agencySettings.shiftReminderAlertTiming,
        hourlyTravelTimePayRate: nextProps.agencySettings.hourlyTravelTimePayRate,
        publicTransitPayRate: nextProps.agencySettings.publicTransitPayRate,
        milePayRate: nextProps.agencySettings.milePayRate,
        minWage: nextProps.agencySettings.minWage,
        liveInEquivalency: nextProps.agencySettings.liveInEquivalency,
        smcSupported: nextProps.agencySettings.smcSupport,
        billingReportOn: nextProps.agencySettings.billingReportOn,
      }));
    }
    if (
      nextProps.complianceSettings &&
      !nextProps.complianceSettings.loading &&
      !nextProps.complianceSettings.error &&
      nextProps.complianceSettings !== this.props.complianceSettings
    ) {
      this.setState(s => ({
        ...s,
        complianceSettingsDirty: false,
        complianceSettings: JSON.parse(JSON.stringify(nextProps.complianceSettings)),
      }));
    }
    if (
      nextProps.complianceClassrooms &&
      !nextProps.loadingComplianceClassrooms &&
      !nextProps.complianceClassroomsError &&
      nextProps.complianceClassrooms !== this.props.complianceClassrooms
    ) {
      this.setState(s => ({
        ...s,
        complianceClassroomsDirty: false,
        complianceClassrooms: JSON.parse(JSON.stringify(nextProps.complianceClassrooms)),
      }));
    }
    if (nextProps.applicationToolConfig) {
      this.setState({
        applicationToolConfig: nextProps.applicationToolConfig,
        loadingApplicationToolConfig: nextProps.loadingApplicationToolConfig,
      });
    }
    if (
      nextProps.elearningSettings &&
      !nextProps.elearningSettings.loading &&
      nextProps.elearningSettings !== this.props.elearningSettings
    ) {
      this.setState(s => ({
        ...s,
        elearningSettingsDirty: false,
        elearningSettings: JSON.parse(JSON.stringify(nextProps.elearningSettings)),
      }));
    }
  }

  componentWillUnmount() {
    // refresh attributes to saved version
    this.props.dispatch(userActions.getAttributes());
    this.props.dispatch(agencySettingsActions.getAgencySettings(true));
  }

  handleComplianceBlockCreate(item) {
    const { complianceSettings } = this.state;
    if (!complianceSettings.blocks) complianceSettings.blocks = [];
    complianceSettings.blocks.push(item);
    this.setState({
      complianceSettings,
      complianceSettingsDirty: true,
    });
  }

  onComplianceBlockUpdated(id, type, value) {
    const { complianceSettings } = this.state;
    const selected = complianceSettings.blocks.find(x => x.id === id);
    selected[type] = value;
    selected.updated = true;

    this.setState({
      complianceSettings,
      complianceSettingsDirty: true,
    });
  }

  onClassUpdated(id, type, value) {
    const { complianceClassrooms } = this.state;
    const selected = complianceClassrooms.find(x => x.id === id);
    selected[type] = value;
    selected.updated = true;

    this.setState({
      complianceClassrooms,
      complianceClassroomsDirty: true,
    });
  }

  handleUpdate = (list, id, key, val) => {
    const item = list.find(x => x.id === id);
    if (item[key] !== val) {
      item[key] = val;
      item.updated = true;
      this.setState(s => ({ ...s, attributesDirty: true }));
    }
  };

  // eslint-disable-next-line react/sort-comp
  addClassRoom(classRoom) {
    this.props.dispatch(complianceSettingsActions.createComplianceClassroom(classRoom));
  }

  agencySettingsUpdated(name, value) {
    this.setState({ [name]: value, agencySettingsDirty: true });
  }

  applicationToolConfigUpdated = applicationToolConfig => {
    this.setState(
      s => ({ ...s, applicationToolConfig }),
      () =>
        this.props.dispatch(
          applicationToolActions.updateApplicationToolConfig(this.state.applicationToolConfig)
        )
    );
  };

  elearningSettingsUpdated = (name, value) => {
    const { elearningSettings } = this.state;
    elearningSettings[name] = value;
    this.setState({
      elearningSettings,
      elearningSettingsDirty: true,
    });
  };

  handleCreate = (list, item) => {
    list.push(item);
    this.setState(s => ({ ...s, attributesDirty: true }));
    this.toggleAddNewForm();
  };

  handleRemove = (list, id) => {
    const item = list.find(x => x.id === id);
    const removed = !item.removed;
    if (item.created && removed) {
      const index = list.findIndex(x => x.id === id);
      list.splice(index, 1);
    } else {
      item.removed = removed;
    }
    this.setState(s => ({ ...s, attributesDirty: true }));
  };

  saveEnabled = () =>
    this.state.attributesDirty ||
    this.state.agencySettingsDirty ||
    this.state.complianceSettingsDirty ||
    this.state.complianceClassroomsDirty ||
    this.state.elearningSettingsDirty;

  handleSave = (forceBlockUpdate = false) => {
    if (this.state.attributesDirty) {
      const created = this.state.preferences.filter(x => x.created && !x.removed);
      const updated = this.state.preferences.filter(x => x.updated && !x.created && !x.removed);
      const removed = this.state.preferences.filter(x => x.removed && !x.created);

      const pfCreated = this.state.profileFields.filter(x => x.created && !x.removed);
      const pfUpdated = this.state.profileFields.filter(x => x.updated && !x.created && !x.removed);
      const pfRemoved = this.state.profileFields.filter(x => x.removed && !x.created);

      this.props.dispatch(
        userActions.updateAttributes({
          preferences: { created, updated, removed },
          profileFields: {
            created: pfCreated,
            updated: pfUpdated,
            removed: pfRemoved,
          },
        })
      );
    }
    if (this.state.agencySettingsDirty) {
      this.props.dispatch(
        agencySettingsActions.updateAgencySettings({
          shiftReminderTime: this.state.shiftReminderTime,
          shiftReminderMode: reminderTypes[this.state.selectedShiftReminder],
          registrationReminderMode: reminderTypes[this.state.selectedRegistrationReminder],
          registrationReminderTime: this.state.registrationReminderTime,
          shiftReminderMaxAttempts: this.state.shiftReminderMaxAttempts,
          registrationReminderMaxAttempts: this.state.registrationReminderMaxAttempts,
          shiftReminderAlertEnabled: this.state.shiftReminderAlertEnabled,
          shiftReminderAlertTiming: this.state.shiftReminderAlertTiming,
          hourlyTravelTimePayRate: this.state.hourlyTravelTimePayRate,
          publicTransitPayRate: this.state.publicTransitPayRate,
          milePayRate: this.state.milePayRate,
          minWage: this.state.minWage,
          liveInEquivalency: this.state.liveInEquivalency,
          smcSupported: this.state.smcSupported,
          billingReportOn: this.state.billingReportOn,
        })
      );
    }
    if (this.state.complianceSettingsDirty) {
      this.props.dispatch(
        complianceSettingsActions.updateComplianceBlocks(
          this.state.complianceSettings.blocks.filter(b => b.updated || b.created || b.removed),
          forceBlockUpdate
        )
      );
    }
    if (this.state.complianceClassroomsDirty) {
      this.props.dispatch(
        complianceSettingsActions.updateComplianceClassrooms(
          this.state.complianceClassrooms.filter(c => c.updated || c.removed)
        )
      );
    }
    if (this.state.elearningSettingsDirty) {
      if (this.state.elearningSettings && this.state.elearningSettings.createdOn) {
        this.props.dispatch(
          elearningSettingsActions.updateElearningSettings(this.state.elearningSettings)
        );
      } else {
        this.props.dispatch(
          elearningSettingsActions.createElearningSettings(this.state.elearningSettings)
        );
      }
    }
  };

  toggleAddNewForm = () => {
    this.setState(s => ({ ...s, addNewFormVisible: !s.addNewFormVisible }));
  };

  render() {
    const { loading } = this.state;
    const { employee, rootUser } = this.props.employee;
    const isUserAdmin = rootUser || (employee && isAdmin(employee.roles));

    const panes = [
      {
        menuItem: 'Matching Preferences',
        key: 'preferences',
        render: () => (
          <Tab.Pane style={{ minWidth: 1040 }}>
            {loading && (
              <Dimmer active inverted>
                <CareLoader loading={loading} centered showText={false} />
              </Dimmer>
            )}
            {isUserAdmin && (
              <Grid padded columns={16} className='col-trainings-preferences'>
                <Grid.Row columns={2}>
                  <Grid.Column width={12} style={{ top: '4px' }}>
                    <div className='active-info-text'>
                      Preferences set as{' '}
                      <span className='active-info-highlight'>&quot;YES&quot;</span> will be
                      available as filtering options for matching caregivers to shifts for
                      schedulers.
                    </div>
                  </Grid.Column>
                  <Grid.Column width={4} textAlign='right'>
                    <span className='active-info-text'>
                      Add Custom Preference{' '}
                      <Button
                        id='addCustomPreference'
                        circular
                        icon='plus'
                        size='mini'
                        className='addButton care-blue'
                        onClick={this.toggleAddNewForm}
                      />
                    </span>
                  </Grid.Column>
                </Grid.Row>
                {this.state.addNewFormVisible && (
                  <Modal
                    closeOnDimmerClick={false}
                    open
                    onClose={this.toggleAddNewForm}
                    closeIcon
                    dimmer
                    style={{ marginTop: '-46vh' }}
                  >
                    <Modal.Header>
                      <Grid centered className='modalHeaderGrid'>
                        <Grid.Column width={10} textAlign='center'>
                          <Header size='tiny'>Add Custom Preference</Header>
                        </Grid.Column>
                        <Grid.Column width={10} textAlign='center' className='headerPic'>
                          <div className='modalHeaderImages'>
                            <Image inline size='mini' src='/circles.svg' />
                          </div>
                        </Grid.Column>
                      </Grid>
                    </Modal.Header>
                    <Modal.Content scrolling>
                      <AddNewPrefForm
                        type='preference'
                        onCreate={item => this.handleCreate(this.state.preferences, item)}
                        disciplines={this.props.disciplines}
                      />
                    </Modal.Content>
                  </Modal>
                )}
                <Grid.Row columns={1}>
                  <Grid.Column width={16}>
                    <PreferencesTable
                      data={this.state.preferences}
                      disciplines={this.props.disciplines}
                      onUpdate={(id, key, val) =>
                        this.handleUpdate(this.state.preferences, id, key, val)
                      }
                      onRemove={id => this.handleRemove(this.state.preferences, id)}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Caregiver Preferences',
        key: 'profile',
        render: () => (
          <Tab.Pane>
            {loading && (
              <Dimmer active inverted>
                <CareLoader loading={loading} centered showText={false} />
              </Dimmer>
            )}
            <Grid padded columns={16} className='col-trainings-preferences'>
              <Grid.Row columns={2}>
                <Grid.Column width={13} style={{ top: '4px' }}>
                  <div className='active-info-text'>
                    The following information will be included in caregiver profiles.
                  </div>
                </Grid.Column>
                <Grid.Column width={3} textAlign='right'>
                  <span className='active-info-text'>
                    Add Custom Field{' '}
                    <Button
                      id='addCustomPreference'
                      circular
                      icon='plus'
                      size='mini'
                      className='addButton care-blue'
                      onClick={this.toggleAddNewForm}
                    />
                  </span>
                </Grid.Column>
              </Grid.Row>
              {this.state.addNewFormVisible && (
                <Modal
                  open
                  onClose={this.toggleAddNewForm}
                  closeIcon
                  dimmer
                  style={{ marginTop: '-46vh' }}
                >
                  <Modal.Header>
                    <Grid centered className='modalHeaderGrid'>
                      <Grid.Column width={10} textAlign='center'>
                        <Header size='tiny'>Add Custom Field</Header>
                      </Grid.Column>
                      <Grid.Column width={10} textAlign='center' className='headerPic'>
                        <div className='modalHeaderImages'>
                          <Image inline size='mini' src='/circles.svg' />
                        </div>
                      </Grid.Column>
                    </Grid>
                  </Modal.Header>
                  <Modal.Content scrolling>
                    <AddNewPrefForm
                      type='profileField'
                      onCreate={item => this.handleCreate(this.state.profileFields, item)}
                    />
                  </Modal.Content>
                </Modal>
              )}
              <Grid.Row columns={1}>
                <Grid.Column width={16}>
                  <ProfileFieldTable
                    data={this.state.profileFields}
                    onUpdate={(id, key, val) =>
                      this.handleUpdate(this.state.profileFields, id, key, val)
                    }
                    onRemove={id => this.handleRemove(this.state.profileFields, id)}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        ),
      },
    ];

    if (isUserAdmin) {
      const showRemoveAgreement = this.props.complianceSettings
        ? this.props.complianceSettings.showRemoveAgreement
        : false;

      const isNotDeletable = this.props.complianceSettings
        ? this.props.complianceSettings.isNotDeletable
        : false;

      panes.push({
        menuItem: 'Compliance',
        key: 'compliance',
        render: () => (
          <Tab.Pane>
            {loading && (
              <Dimmer active inverted>
                <CareLoader loading={loading} centered showText={false} />
              </Dimmer>
            )}
            <ComplianceSettings
              disciplines={this.props.disciplines}
              complianceBlocks={
                this.state.complianceSettings && this.state.complianceSettings.blocks
                  ? this.state.complianceSettings.blocks
                  : []
              }
              onBlockUpdated={this.onComplianceBlockUpdated}
              addClassRoom={this.addClassRoom}
              handleComplianceBlockCreate={this.handleComplianceBlockCreate}
              classRooms={this.state.complianceClassrooms}
              onClassUpdated={this.onClassUpdated}
            />
            {showRemoveAgreement && isNotDeletable && (
              <Modal open centered>
                <Modal.Content>
                  These compliance items are already in use and cannot be deleted
                </Modal.Content>

                <Modal.Actions>
                  <Button
                    content='Ok'
                    onClick={() => {
                      this.props.dispatch(
                        complianceSettingsActions.hideComplianceRemoveAgreement()
                      );
                      this.setState(s => ({
                        ...s,
                        complianceSettingsDirty: false,
                        complianceSettings: JSON.parse(
                          JSON.stringify(this.props.complianceSettings)
                        ),
                      }));
                    }}
                  />
                </Modal.Actions>
              </Modal>
            )}

            {showRemoveAgreement && !isNotDeletable && (
              <Modal open>
                <Modal.Content>
                  Are you sure you want to remove the compliance items? This will also remove all
                  the upcoming events related to these items.
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    id='train-pref-remove-event-cancel-button'
                    content='Cancel'
                    onClick={() => {
                      this.props.dispatch(
                        complianceSettingsActions.hideComplianceRemoveAgreement()
                      );
                      this.setState(s => ({
                        ...s,
                        complianceSettingsDirty: false,
                        complianceSettings: JSON.parse(
                          JSON.stringify(this.props.complianceSettings)
                        ),
                      }));
                    }}
                  />
                  <Button
                    id='train-pref-remove-event-yes-button'
                    content='Yes'
                    onClick={() => {
                      this.handleSave(true);
                      this.props.dispatch(
                        complianceSettingsActions.hideComplianceRemoveAgreement()
                      );
                    }}
                  />
                </Modal.Actions>
              </Modal>
            )}
          </Tab.Pane>
        ),
      });
    }

    if (isUserAdmin) {
      panes.push({
        menuItem: 'Application Tool Setup',
        key: 'apptool',
        render: () => (
          <Tab.Pane>
            {this.state.loadingApplicationToolConfig ? (
              <Dimmer active inverted>
                <CareLoader
                  loading={this.state.loadingApplicationToolConfig}
                  centered
                  showText={false}
                />
              </Dimmer>
            ) : (
              <ApplicationToolConfig
                onValueUpdated={this.applicationToolConfigUpdated}
                applicationToolConfig={this.state.applicationToolConfig}
              />
            )}
          </Tab.Pane>
        ),
      });

      panes.push({
        menuItem: 'Agency Settings',
        key: 'bot',
        render: () => (
          <Tab.Pane className='agency-settings-tales-container'>
            {loading && (
              <Dimmer active inverted>
                <CareLoader loading={loading} centered showText={false} />
              </Dimmer>
            )}
            <BotSettings
              onValueUpdated={(name, value) => this.agencySettingsUpdated(name, value)}
              selectedShiftReminder={this.state.selectedShiftReminder}
              selectedRegistrationReminder={this.state.selectedRegistrationReminder}
              shiftReminderTime={this.state.shiftReminderTime}
              registrationReminderTime={this.state.registrationReminderTime}
              shiftReminderMaxAttempts={this.state.shiftReminderMaxAttempts}
              registrationReminderMaxAttempts={this.state.registrationReminderMaxAttempts}
              shiftReminderAlertEnabled={this.state.shiftReminderAlertEnabled}
              shiftReminderAlertTiming={this.state.shiftReminderAlertTiming}
              dirty={this.state.agencySettingsDirty}
            >
              <RateSettings
                onValueUpdated={(name, value) => this.agencySettingsUpdated(name, value)}
                hourlyTravelTimePayRate={this.state.hourlyTravelTimePayRate}
                publicTransitPayRate={this.state.publicTransitPayRate}
                milePayRate={this.state.milePayRate}
                minWage={this.state.minWage}
                liveInEquivalency={this.state.liveInEquivalency}
                dirty={this.state.agencySettingsDirty}
              />
              <SystemExtensions
                onValueUpdated={(name, value) => this.agencySettingsUpdated(name, value)}
                dirty={this.state.agencySettingsDirty}
                smcSupported={this.state.smcSupported}
              />
              <ReportingSettings
                onValueUpdated={(name, value) => this.agencySettingsUpdated(name, value)}
                dirty={this.state.agencySettingsDirty}
                billingReportOn={this.state.billingReportOn}
              />
            </BotSettings>
          </Tab.Pane>
        ),
      });

      panes.push({
        menuItem: 'eLearning',
        key: 'elearningSettings',
        render: () => (
          <Tab.Pane className='elearning-settings-tales-container'>
            {loading && (
              <Dimmer active inverted>
                <CareLoader loading={loading} centered showText={false} />
              </Dimmer>
            )}
            <ElearningSettings
              onValueUpdated={this.elearningSettingsUpdated}
              elearningSettings={this.state.elearningSettings}
              dirty={this.state.elearningSettingsDirty}
            />
          </Tab.Pane>
        ),
      });
    }

    return (
      <>
        <Prompt
          when={!!this.saveEnabled()}
          message={() =>
            'Are you sure you want to leave without saving? All unsaved changes will be lost.'
          }
        />
        <Grid.Row className='noVerticalPadding'>
          <Grid className='contentGrid' padded>
            <Grid.Column width={3} className='menuCol'>
              <ProfileMenu
                email='care@connect.com'
                isUserAdmin={isUserAdmin}
                isRootAdmin={rootUser && isUserAdmin}
                settings={this.props.settings}
              />
            </Grid.Column>

            <Grid.Column width={13}>
              <Grid>
                <Grid.Row verticalAlign='middle' columns={2} className='noVerticalPadding'>
                  <Grid.Column width={14}>
                    <Header>SYSTEM SETTINGS</Header>
                  </Grid.Column>
                  <Grid.Column textAlign='right' width={2}>
                    <Button
                      size='mini'
                      disabled={!this.saveEnabled() || this.props.complianceSettings.loading}
                      basic
                      className='saveButton'
                      id='settings-save-button'
                      onClick={() => this.handleSave()}
                    >
                      Save Updates
                    </Button>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                  <Grid.Column width={16}>
                    <Tab id='system-setting-tab' panes={panes} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid>
        </Grid.Row>
      </>
    );
  }
}

TrainPref.defaultProps = {
  employee: {},
  settings: {},
  complianceClassrooms: [],
  loadingComplianceClassrooms: false,
  complianceClassroomsError: false,
  applicationToolConfig: {},
  loadingApplicationToolConfig: false,
};

TrainPref.propTypes = {
  dispatch: PropTypes.func.isRequired,
  disciplines: PropTypes.shape().isRequired,
  attributes: PropTypes.shape().isRequired,
  agencySettings: PropTypes.shape().isRequired,
  complianceSettings: PropTypes.shape().isRequired,
  complianceClassrooms: PropTypes.arrayOf(PropTypes.shape()),
  loadingComplianceClassrooms: PropTypes.bool,
  complianceClassroomsError: PropTypes.bool,
  employee: PropTypes.shape(),
  settings: PropTypes.shape(),
  applicationToolConfig: PropTypes.shape(),
  loadingApplicationToolConfig: PropTypes.bool,
};

const mapStateToProps = state => {
  const {
    disciplines,
    attributes,
    settings,
    agencySettings,
    complianceSettings,
    complianceClassrooms,
    complianceClassroomsError,
    loadingComplianceClassrooms,
  } = state.principal;
  const { employee, elearningSettings } = state;
  const { applicationToolConfig, loadingApplicationToolConfig } = state.applicationTool;

  return {
    disciplines,
    attributes,
    employee,
    settings,
    agencySettings,
    complianceSettings,
    complianceClassrooms,
    complianceClassroomsError,
    loadingComplianceClassrooms,
    applicationToolConfig,
    loadingApplicationToolConfig,
    elearningSettings,
  };
};

export default connect(mapStateToProps)(TrainPref);

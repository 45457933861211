/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-this-alias */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import URLSearchParams from 'url-search-params';
import { FormattedMessage } from 'react-intl';
import {
  Grid,
  Header,
  Button,
  Icon,
  Form,
  Checkbox,
  Table,
  Image,
  Dimmer,
  Popup,
} from 'semantic-ui-react';
import CareLoader from './subcomponents/CareLoader';
import { caregiverActions, IoT, schedulerActions, alertActions, uiStateActions } from '../actions';
import CaregiversMenu from './CaregiversMenu';
import CaregiverDetails from './CaregiverDetails';
import CaregiverEditor from './CaregiverEditor';
import ShiftDetailsBar from './ShiftDetailsBar';
import MultiShiftBar from './MultiShiftBar';
import ShiftRequests from './subcomponents/ShiftRequests';
import LoadMore from './subcomponents/LoadMore';
import { StaffStatusMap } from '../constants/DomainTypes';
import { caregiverHelpers } from '../helpers/caregiverHelpers';
import './Common.css';
import NoMatch from './subcomponents/NoMatch';
import InitialState from './subcomponents/InitialState';
import Overlay from './subcomponents/Overlay';
import PageSizer from './subcomponents/PageSizer';
import PageMeter from './subcomponents/PageMeter';
import CareRoundButton from './subcomponents/CareRoundButton';
import { formatHours, formatTime, MOMENT_HOURS_WITH_DATE } from '../constants/Formats';
import { history } from '../helpers';
import { analytics, Events } from '../helpers/analytics';
import { caregiverScoresActions } from '../actions/caregiverScores';
import { eventLogActions } from '../actions/eventLog';
import CaregiverMap from './subcomponents/CaregiverMap';
import CaregiverInvitationNote from './subcomponents/CaregiverInvitationNote';

const _ = require('underscore');

const statusItem = (caregiver, onInviteClick, handleCGClick, hasButton = true) => {
  const item = { key: 'status' };
  const onMissingInfoClick = () => {
    handleCGClick(caregiver.id, true);
  };
  switch (caregiver.status) {
    case 'active':
      return Object.assign(item, {
        icon: { name: 'circle', className: 'icon-green' },
        textAlign: hasButton ? 'left' : 'center',
        content: null,
      });
    case 'pending':
      if (caregiver.isInvitable) {
        return Object.assign(item, {
          icon: { name: 'circle', className: 'icon-blue' },
          textAlign: hasButton ? 'left' : 'center',
          content: hasButton ? (
            <span>
              <Button
                id='caregivers-status-item-resend-invitation-button'
                caregiver={caregiver}
                basic
                compact
                size='tiny'
                onClick={onInviteClick}
              >
                Resend Invitation
              </Button>
            </span>
          ) : null,
        });
      }
      break;
    case 'inactive':
    default:
      if (caregiver.isInvitable) {
        return Object.assign(item, {
          icon: { name: 'circle', className: 'icon-red' },
          textAlign: hasButton ? 'left' : 'center',
          content: hasButton ? (
            <span>
              {hasButton && (
                <Button
                  id='caregivers-status-item-send-invitation-button'
                  caregiver={caregiver}
                  className='care-green'
                  compact
                  size='tiny'
                  onClick={onInviteClick}
                >
                  Send Invitation
                </Button>
              )}
            </span>
          ) : null,
        });
      }
      return Object.assign(item, {
        icon: { name: 'warning circle', className: 'icon-red', size: 'large' },
        textAlign: hasButton ? 'left' : 'center',
        content: hasButton ? (
          <span
            id='caregivers-missing-req-info'
            className='overlayLink'
            role='presentation'
            onClick={onMissingInfoClick}
          >
            Missing Req Info
          </span>
        ) : null,
      });
  }
  return item;
};

const freeViewStatusItem = (caregiver = true) => {
  const item = { key: 'freeViewStatusItem' };
  switch (caregiver.status) {
    case 'active':
      Object.assign(item, {
        textAlign: 'left',
        content: <span style={{ color: ' #6ed992', fontWeight: '700' }}>Active</span>,
      });
      break;
    case 'pending':
      if (caregiver.isInvitable) {
        const latestInvitation = caregiver.latestInvitation
          ? formatTime(caregiver.latestInvitation, null, MOMENT_HOURS_WITH_DATE)
          : '';
        return Object.assign(item, {
          textAlign: 'left',
          content: [
            <span style={{ color: '#04a5d5', fontWeight: '700' }}>Invited</span>,
            <br />,
            <span className='time'>
              {caregiver.latestInvitationInput ? caregiver.latestInvitationInput : latestInvitation}
            </span>,
          ],
        });
      }
      break;
    case 'inactive':
    default:
      if (caregiver.isInvitable) {
        return Object.assign(item, {
          textAlign: 'left',
          content: (
            <span style={{ color: ' #f16778', fontWeight: '700' }}>Awaiting Invitation</span>
          ),
        });
      }
      return Object.assign(item, {
        icon: {
          name: 'warning circle',
          className: 'icon-red info-status',
          size: 'large',
        },
        style: { marginRight: '10px !important' },
        textAlign: 'left',
        content: <span style={{ color: ' #f16778', fontWeight: '700' }}>Missing Req Info</span>,
      });
  }
  return item;
};

const freeViewStatusAction = (caregiver, onInviteClick, handleCGClick = true) => {
  const item = { key: 'freeViewStatusAction' };
  const onMissingInfoClick = () => {
    handleCGClick(caregiver.id, true);
  };
  switch (caregiver.status) {
    case 'active':
      Object.assign(item, {
        content: null,
      });
      break;
    case 'pending':
      if (caregiver.isInvitable) {
        return Object.assign(item, {
          textAlign: 'left',
          content: !caregiver.latestInvitationInput ? (
            <span>
              <Button
                id='caregivers-free-view-status-resend-invitation-button'
                caregiver={caregiver}
                className='care-green'
                basic
                compact
                size='tiny'
                onClick={onInviteClick}
              >
                Resend Invitation
              </Button>
            </span>
          ) : (
            <span>Done</span>
          ),
        });
      }
      break;
    case 'inactive':
    default:
      if (caregiver.isInvitable) {
        return Object.assign(item, {
          textAlign: 'left',
          content: (
            <span>
              <Button
                id='caregivers-free-view-status-send-invitation-button'
                caregiver={caregiver}
                className='care-green'
                compact
                size='tiny'
                onClick={onInviteClick}
              >
                Send Invitation
              </Button>
            </span>
          ),
        });
      }
      return Object.assign(item, {
        textAlign: 'left',
        content: (
          <span>
            <Button
              id='caregivers-free-view-amend-record-invitation-button'
              caregiver={caregiver}
              className='care-green'
              basic
              compact
              size='tiny'
              onClick={onMissingInfoClick}
            >
              Amend Record
            </Button>
          </span>
        ),
      });
  }
  return item;
};

function phoneNumbers(phones) {
  return (
    phones
      .filter(p => p.number)
      .map(p => p.number)
      .join('\n') || '--'
  );
}

const ratingInfo = () => (
  <div>
    Rating
    <Popup
      trigger={<Icon name='question circle outline' className='infoIcon' />}
      content='The suitability of the caregiver to this client. Takes into account history, language, preferences and distance.'
      on='hover'
      position='top left'
      popperModifiers={{
        preventOverflow: {
          boundariesElement: 'offsetParent',
        },
      }}
    />
  </div>
);

const hoursInfo = () => (
  <div>
    Hrs
    <Popup
      trigger={<Icon name='question circle outline' className='infoIcon' />}
      content='Total hours assigned in the current week'
      on='hover'
      position='top left'
      popperModifiers={{
        preventOverflow: {
          boundariesElement: 'offsetParent',
        },
      }}
    />
  </div>
);

const costPenaltyInfo = () => (
  <div>
    Cost Penalty
    <Popup
      trigger={<Icon name='question circle outline' className='infoIcon' />}
      content='Estimated additional costs incurred by assigning this caregiver'
      on='hover'
      position='top left'
      popperModifiers={{
        preventOverflow: {
          boundariesElement: 'offsetParent',
        },
      }}
    />
  </div>
);

const invitationResponseHeaders = (sorting, onHeaderClick) => {
  const { column, direction } = sorting;
  const shiftCountInfo = (
    <div>
      Shift #
      <Popup
        trigger={<Icon name='question circle outline' className='infoIcon' />}
        content='This includes all shifts, of any status, assigned to this caregiver in the next 2 weeks'
        on='hover'
        position='top left'
        popperModifiers={{
          preventOverflow: {
            boundariesElement: 'offsetParent',
          },
        }}
      />
    </div>
  );

  const headerArray = [
    {
      id: 'caregivers-invitation-response-table-header-rating',
      key: 'rating',
      content: ratingInfo(),
      filter: true,
    },
    {
      id: 'caregivers-invitation-response-table-header-name',
      key: 'name',
      content: 'Name',
      filter: true,
    },
    {
      id: 'caregivers-invitation-response-table-header-discipline',
      key: 'discipline',
      content: <span>Disciplines</span>,
      textAlign: 'center',
      filter: true,
    },
    {
      id: 'caregivers-invitation-response-table-header-distance',
      key: 'distance',
      content: (
        <span>
          Commute
          <br />
          time
        </span>
      ),
      filter: true,
      textAlign: 'center',
    },
    {
      id: 'caregivers-invitation-response-table-header-shiftCount',
      key: 'shiftCount',
      content: shiftCountInfo,
      filter: true,
      textAlign: 'center',
    },
    {
      id: 'caregivers-invitation-response-table-header-hours',
      key: 'hours',
      content: hoursInfo(),
      textAlign: 'center',
      filter: true,
    },
    {
      id: 'caregivers-invitation-response-table-header-cost_penalty',
      key: 'cost_penalty',
      content: costPenaltyInfo(),
      textAlign: 'center',
      filter: true,
    },
    {
      id: 'caregivers-invitation-response-table-header-responses',
      key: 'responses',
      content: 'Response',
      textAlign: 'left',
      filter: true,
    },
    {
      id: 'caregivers-invitation-response-table-header-select',
      key: 'select',
      content: 'Action',
      textAlign: 'center',
      filter: true,
    },
  ];

  headerArray
    .filter(f => f.filter)
    .forEach(h => {
      // array is initial state, in initial set name as a sorted col
      // eslint-disable-next-line no-param-reassign
      h.className =
        column === h.key || (Array.isArray(column) && h.key === 'name')
          ? `${direction} sorted`
          : undefined;

      // eslint-disable-next-line no-param-reassign
      h.onClick = onHeaderClick(h.key);

      // eslint-disable-next-line no-param-reassign
      h.filter = undefined;
    });

  return headerArray;
};

const headers = (shiftMode, sorting, onHeaderClick) => {
  const { column, direction } = sorting;
  const shiftCountInfo = (
    <div>
      Shift #
      <Popup
        trigger={<Icon name='question circle outline' className='infoIcon' />}
        content='This includes all shifts, of any status, assigned to this caregiver in the next 2 weeks'
        on='hover'
        position='top left'
        popperModifiers={{
          preventOverflow: {
            boundariesElement: 'offsetParent',
          },
        }}
      />
    </div>
  );

  const headerArray = shiftMode
    ? [
        {
          id: 'caregivers-shift-mode-table-header-rating',
          key: 'rating',
          content: ratingInfo(),
          filter: true,
        },
        {
          id: 'caregivers-shift-mode-table-header-name',
          key: 'name',
          content: 'Name',
          filter: true,
        },
        {
          id: 'caregivers-shift-mode-table-header-discipline',
          key: 'discipline',
          content: <span>Disciplines</span>,
          textAlign: 'center',
          filter: true,
        },
        {
          id: 'caregivers-shift-mode-table-header-distance',
          key: 'distance',
          content: (
            <span>
              Commute
              <br />
              Time
            </span>
          ),
          filter: true,
          textAlign: 'center',
        },
        {
          id: 'caregivers-shift-mode-table-header-shiftCount',
          key: 'shiftCount',
          content: shiftCountInfo,
          filter: true,
          textAlign: 'center',
        },
        {
          id: 'caregivers-shift-mode-table-header-hours',
          key: 'hours',
          content: hoursInfo(),
          textAlign: 'center',
          filter: true,
        },
        {
          id: 'caregivers-shift-mode-table-header-cost_penalty',
          key: 'cost_penalty',
          content: costPenaltyInfo(),
          textAlign: 'center',
          filter: true,
        },
        {
          id: 'caregivers-shift-mode-table-header-status',
          key: 'status',
          content: 'Registration Status',
          filter: true,
        },
        {
          id: 'caregivers-shift-mode-table-header-select',
          key: 'select',
          content: 'Action',
          textAlign: 'center',
          filter: true,
        },
      ]
    : [
        { id: 'caregivers-table-header-name', key: 'name', content: 'Name', filter: true },
        {
          id: 'caregivers-table-header-discipline',
          key: 'discipline',
          content: 'Disciplines',
          filter: true,
        },
        {
          id: 'caregivers-table-header-phone',
          key: 'phone',
          content: 'Phone',
          textAlign: 'center',
          filter: true,
        },
        {
          id: 'caregivers-table-header-zip',
          key: 'zip',
          content: 'Zip',
          textAlign: 'center',
          filter: true,
        },
        {
          id: 'caregivers-table-header-distance',
          key: 'distance',
          content: 'Commute Time',
          filter: true,
          textAlign: 'center',
        },
        {
          id: 'caregivers-table-header-shiftCount',
          key: 'shiftCount',
          content: shiftCountInfo,
          filter: true,
          textAlign: 'center',
        },
        {
          id: 'caregivers-table-header-freeViewStatusItem',
          key: 'freeViewStatusItem',
          content: 'Registration Status',
          filter: true,
        },
        {
          id: 'caregivers-table-header-freeViewStatusAction',
          key: 'freeViewStatusAction',
          content: 'Action',
          textAlign: 'center',
          filter: true,
        },
      ];

  headerArray
    .filter(f => f.filter)
    .forEach(h => {
      // eslint-disable-next-line no-param-reassign
      h.className =
        column === h.key || (Array.isArray(column) && h.key === 'name')
          ? `${direction} sorted`
          : undefined;

      // eslint-disable-next-line no-param-reassign
      h.onClick = onHeaderClick(h.key);

      // eslint-disable-next-line no-param-reassign
      h.filter = undefined;
    });

  return headerArray;
};

const InvitationRespHeaderTabs = ({ requestsCount, declinedCount, tabClick, activeTab }) => (
  <div className='invitationRespHeaderTabs'>
    <div>Invitation Response:</div>
    <Button.Group inline size='large'>
      <Button
        className={activeTab === 'Requests' ? 'invitation-response-active' : ''}
        circular
        disabled={!requestsCount}
        onClick={() => tabClick('Requests')}
        name='Requests'
      >
        <Icon color='icon-green' name='checkmark' />
        Requests <span id='caregiver-invitation-requested-count'>({requestsCount})</span>
      </Button>
      <Button
        className={activeTab === 'Declined' ? 'invitation-response-active' : ''}
        circular
        disabled={!declinedCount}
        onClick={() => tabClick('Declined')}
        name='Declined'
      >
        <Icon color='icon-red' name='remove' />
        Declined <span id='caregivers-invitation-declined-count'>({declinedCount})</span>
      </Button>
    </Button.Group>
  </div>
);

InvitationRespHeaderTabs.propTypes = {
  requestsCount: PropTypes.number.isRequired,
  declinedCount: PropTypes.number.isRequired,
  tabClick: PropTypes.func.isRequired,
};

InvitationRespHeaderTabs.defaultProps = {
  declined: false,
};

function filterCaregiver(element, filters) {
  if (filters == null) {
    return element;
  }

  if (filters.statusFilter && filters.statusFilter.length > 0) {
    const failures = [];
    if (!(filters.statusFilter.indexOf(element.status) >= 0)) {
      failures.push(element.status);
    }
    if (failures.length > 0) {
      element.filterFailure.push({ name: 'statusFilter', failures });
    }
  }

  if (element.preferences && filters.preferenceFilter && filters.preferenceFilter.length > 0) {
    const failures = [];
    filters.preferenceFilter.forEach(val => {
      if (
        element.preferences.filter(
          cgVal => Number.parseInt(cgVal.id, 10) === val && cgVal.value === 'Y'
        ).length === 0
      ) {
        failures.push(val);
      }
    });
    if (failures.length > 0) {
      element.filterFailure.push({ name: 'preferenceFilter', failures });
    }
  }

  if (
    element.customFields &&
    filters.profileFieldsFilter &&
    filters.profileFieldsFilter.length > 0
  ) {
    const failures = [];
    filters.profileFieldsFilter.forEach(val => {
      if (
        element.customFields.filter(
          cgVal => Number.parseInt(cgVal.id, 10) === val && cgVal.value === 'Y'
        ).length === 0
      ) {
        failures.push(val);
      }
    });
    if (failures.length > 0) {
      element.filterFailure.push({ name: 'profileFieldsFilter', failures });
    }
  }

  if (element.languages && filters.languages && filters.languages.length > 0) {
    let success = false;
    filters.languages.forEach(val => {
      const filtered = element.languages.filter(
        cgVal => Number.parseInt(cgVal.id, 10) === val && cgVal.value === 'Y'
      );
      if (filtered && filtered.length > 0) {
        success = true;
      }
    });

    if (!success) {
      element.filterFailure.push({ name: 'languages', failures: filters.languages });
    }
  }

  if (filters.staffStatusFilter && filters.staffStatusFilter.length > 0) {
    const failures = [];
    if (!(filters.staffStatusFilter.indexOf(element.staffStatus) >= 0)) {
      failures.push(element.staffStatus);
    }
    if (failures.length > 0) {
      element.filterFailure.push({ name: 'staffStatusFilter', failures });
    }
  }

  if (filters.genderFilter && filters.genderFilter.length > 0) {
    const failures = [];
    if (!(filters.genderFilter.indexOf(element.gender) >= 0)) {
      failures.push(element.gender);
    }
    if (failures.length > 0) {
      element.filterFailure.push({ name: 'genderFilter', failures });
    }
  }

  return element;
}

function filterCaregiverResults(result, filters) {
  result.forEach(val => {
    Object.assign(val, { filterFailure: [] });
    filterCaregiver(val, filters);
  });
}

class Caregivers extends React.Component {
  static preferenceActive(preferences, name) {
    const preference = preferences && preferences.find(p => p.name === name);
    return preference ? preference.value === 'Y' : false;
  }

  static scoreForCaregiver = (caregiver, client, scores) => {
    let score;
    let rank;
    if (client && client.id && scores && scores.results) {
      const clScores = scores.results[client.id];
      if (clScores) {
        rank = _.findIndex(clScores.scores, { caregiver_id: caregiver.id });
        if (rank !== -1) {
          score = clScores.scores[rank];
        }
      }
    }
    return { rank, score };
  };

  static sort(data, sortDetails, client, scores, shiftList) {
    const { column, direction } = sortDetails;
    const fieldArray = Array.isArray(column) ? column : [column];

    const dirMultiplier = direction === 'descending' ? 1 : -1;

    return [...data].sort((a, b) => {
      let aVal = null;
      let bVal = null;

      return fieldArray.reduce((accu, field) => {
        if (accu !== 0) {
          return accu;
        }

        switch (field) {
          case 'name':
            aVal = `${a.lastName ? a.lastName.toLowerCase().replace(/\s/g, '') : ''}${
              a.firstName ? a.firstName.toLowerCase().replace(/\s/g, '') : ''
            }`;
            bVal = `${b.lastName ? b.lastName.toLowerCase().replace(/\s/g, '') : ''}${
              b.firstName ? b.firstName.toLowerCase().replace(/\s/g, '') : ''
            }`;
            break;
          case 'rating': {
            const aScore = Caregivers.scoreForCaregiver(a, client, scores).score;
            const bScore = Caregivers.scoreForCaregiver(b, client, scores).score;
            aVal = aScore ? aScore.score : 0;
            bVal = bScore ? bScore.score : 0;
            break;
          }
          case 'discipline':
            aVal = a.discipline;
            bVal = b.discipline;
            break;
          case 'distance':
            aVal = a.maxTravelTime;
            bVal = b.maxTravelTime;
            break;
          case 'pets':
          case 'smokers':
            aVal = Caregivers.preferenceActive(a.preferences, field);
            bVal = Caregivers.preferenceActive(b.preferences, field);
            break;
          case 'phone':
            aVal = phoneNumbers(a.phones);
            bVal = phoneNumbers(b.phones);
            break;
          case 'zip':
            aVal = a.address.zip;
            bVal = b.address.zip;
            break;
          case 'freeViewStatusItem': {
            aVal =
              a.status === 'active'
                ? parseInt(moment().format('x'), 10) -
                  parseInt(moment(a.latestInvitation).format('x'), 10)
                : (aVal =
                    a.isInvitable && a.latestInvitation
                      ? parseInt(moment(a.latestInvitation).format('x'), 10)
                      : parseInt(moment().format('x'), 10));
            bVal =
              b.status === 'active'
                ? parseInt(moment().format('x'), 10) -
                  parseInt(moment(b.latestInvitation).format('x'), 10)
                : (bVal =
                    b.isInvitable && b.latestInvitation
                      ? parseInt(moment(b.latestInvitation).format('x'), 10)
                      : parseInt(moment().format('x'), 10));
            break;
          }
          case 'freeViewStatusAction': {
            let aStatus;
            let bStatus;
            if (a.status === 'active') {
              aStatus = 1;
            } else if (a.status === 'pending' && a.isInvitable) {
              aStatus = 2;
            } else if ((a.status === 'pending' && !a.isInvitable) || a.status === 'inactive') {
              aStatus = 3;
            } else {
              aStatus = 4;
            }
            if (b.status === 'active') {
              bStatus = 1;
            } else if (b.status === 'pending' && b.isInvitable) {
              bStatus = 2;
            } else if ((b.status === 'pending' && !b.isInvitable) || b.status === 'inactive') {
              bStatus = 3;
            } else {
              bStatus = 4;
            }
            aVal = aStatus;
            bVal = bStatus;
            break;
          }
          case 'shiftCount':
            aVal = a.assignedShifts.length;
            bVal = b.assignedShifts.length;
            break;
          case 'hours':
            aVal = a.weeklyHours;
            bVal = b.weeklyHours;
            break;
          case 'cost_penalty': {
            const aCost = caregiverHelpers.costForCaregiver(a, shiftList, scores).cost;
            const bCost = caregiverHelpers.costForCaregiver(b, shiftList, scores).cost;
            aVal = aCost ? aCost.extra_costs : -1;
            bVal = bCost ? bCost.extra_costs : -1;
            break;
          }
          case 'select': {
            const aval = a.actionStatus;
            const bval = b.actionStatus;
            let response = 0;

            if (aval.assigned === bval.assigned) {
              if (aval.assignAll === bval.assignAll) {
                if (aval.offeredShifts === bval.offeredShifts) {
                  if (a.isInvitable !== b.isInvitable) {
                    return a.isInvitable ? 1 * dirMultiplier : -1 * dirMultiplier;
                  }
                  if (a.status !== b.status) {
                    return a.status < b.status ? -1 * dirMultiplier : 1 * dirMultiplier;
                  }

                  return 0;
                }
                response = aval.offeredShifts ? 1 : -1;
              } else {
                response = aval.assignAll ? 1 : -1;
              }
            } else {
              response = aval.assigned ? 1 : -1;
            }

            return response * dirMultiplier;
          }
          default:
            aVal = a[field];
            bVal = b[field];
        }

        if (aVal === bVal) return 0;
        if (aVal < bVal) return -1 * dirMultiplier;
        return 1 * dirMultiplier;
      }, 0);
    });
  }

  static travelTime(maxTravelTime) {
    if (maxTravelTime) {
      return maxTravelTime > 7200
        ? moment.duration(maxTravelTime, 'seconds').humanize()
        : `${Math.round(moment.duration(maxTravelTime, 'seconds').asMinutes())} min`;
    }
    return '--';
  }

  static showFilterFailure(x) {
    return x.filterFailure && x.filterFailure.length > 0;
  }

  static costPopOver(cost) {
    const totalCost =
      cost !== undefined && cost.extra_costs ? `Total $${cost.extra_costs.toFixed(2)}` : null;
    const sohCost =
      cost !== undefined && cost.soh ? `Spread of Hours $${cost.soh.toFixed(2)}` : null;
    const otCost =
      cost !== undefined && cost.week_ot ? `Weekly Overtime $${cost.week_ot.toFixed(2)}` : null;
    const splitCost =
      cost !== undefined && cost.split ? `Split Shift $${cost.split.toFixed(2)}` : null;
    const seventhDayCost =
      cost !== undefined && cost.seventh_day
        ? `Seventh Day Worked $${cost.seventh_day.toFixed(2)}`
        : null;
    const travelCost =
      cost !== undefined && cost.travel ? `Commute $${cost.travel.toFixed(2)}` : null;

    return cost.extra_costs ? (
      <Popup
        pinned
        trigger={
          <span>
            <Image src='/Attention.svg' inline />{' '}
            <p className='extra-cost-text'>${cost.extra_costs.toFixed(2)} </p>
          </span>
        }
        content={
          <div>
            {otCost}
            <div />
            {seventhDayCost}
            <div />
            {sohCost}
            <div />
            {splitCost}
            <div />
            {travelCost}
            <div />
            <b>{totalCost}</b>
            <div />
          </div>
        }
        position='top left'
        popperModifiers={{
          preventOverflow: {
            boundariesElement: 'offsetParent',
          },
        }}
      />
    ) : (
      <div>$0</div>
    );
  }

  constructor() {
    super();
    this.showMapClick = this.showMapClick.bind(this);
    this.inviteCaregiversClick = this.inviteCaregiversClick.bind(this);
    this.inviteCaregiverClick = this.inviteCaregiverClick.bind(this);

    this.handleCGClick = this.handleCGClick.bind(this);
    this.handleCGDetailsClose = this.handleCGDetailsClose.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.onResetShiftDetails = this.onResetShiftDetails.bind(this);
    this.completeCaregiverInvitation = this.completeCaregiverInvitation.bind(this);
    this.handleCaregiverShiftsClick = this.handleCaregiverShiftsClick.bind(this);
    this.body = this.body.bind(this);
    this.invitationResponseBody = this.invitationResponseBody.bind(this);

    this.onMapMarkerMouseOver = this.onMapMarkerMouseOver.bind(this);
    this.onMapMarkerClick = this.onMapMarkerClick.bind(this);
    this.onLoadMore = this.onLoadMore.bind(this);
    this.updateBounds = this.updateBounds.bind(this);

    this.onMeterChanged = this.onMeterChanged.bind(this);

    this.handleRemind = this.handleRemind.bind(this);
    this.handleUpdateClick = this.handleUpdateClick.bind(this);
    this.handleEditorClick = this.handleEditorClick.bind(this);
    this.handleEditorClose = this.handleEditorClose.bind(this);
  }

  // eslint-disable-next-line react/state-in-constructor
  state = {
    data: null,
    sorting: {
      data: { column: ['rating', 'select', 'name'], direction: 'ascending' },
      requestedShifts: { column: ['rating', 'select', 'name'], direction: 'ascending' },
      declinedShifts: { column: ['rating', 'select', 'name'], direction: 'ascending' },
    },
    showMap: true,
    caregiverDetailId: null,
    searchField: '',
    mapInfoBox: null,
    pendingInvitation: null,
    bounds: null,
    meterState: {},
    showFiltered: false,
    searching: false,
  };

  UNSAFE_componentWillMount() {
    this.props.dispatch(caregiverScoresActions.resetScores());
    this.handleProperties(this.props);

    if (!this.props.shiftMode) {
      if (this.props.caregiver && !this.props.caregiver.initialRequestDone) {
        this.props.dispatch(caregiverActions.listCaregivers({}));
      }
      this.setState(s => ({
        sorting: {
          ...s.sorting,
          data: { column: ['name'], direction: 'descending' },
        },
      }));
    }
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.state && location.state.from) {
      const searchParams = new URLSearchParams(location.state.from.search);
      if (searchParams.has('action') && searchParams.has('id')) {
        switch (searchParams.get('action')) {
          case 'shift':
            this.props.dispatch(caregiverActions.setTargetShifts([searchParams.get('id')]));
            break;
          default:
            break;
        }
      }
    }
    analytics.track(Events.CAREGIVERS_SELECT_TAB);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleProperties(nextProps);
  }

  onResetShiftDetails() {
    if (!this.props.caregiver.loading) {
      uiStateActions.inputChangeHandler(this.props.dispatch, 'caregiverFilter')(
        {},
        {
          name: 'advancedFilter',
          value: [],
        }
      );
      this.props.dispatch(caregiverActions.resetCaregiverList());
    }
  }

  onMapMarkerMouseOver(id) {
    this.setState({ activeMapInfoBox: id });
  }

  onMapMarkerClick(id) {
    document.webkitExitFullscreen();
    this.setState(current => ({ ...current, caregiverDetailId: id }));
  }

  onMeterChanged(state) {
    this.setState(s => ({
      ...s,
      meterState: { ...s.meterState, ...state },
    }));
  }

  onLoadMore() {
    this.props.dispatch(
      caregiverActions.listCaregivers({ nextPage: this.props.caregiver.nextPage }, true)
    );
  }

  setLocationSearchVisibility(showLocationSearch) {
    if (showLocationSearch) {
      this.props.dispatch(uiStateActions.uiStateChange('shiftBarFilter', 'visible', true));
      uiStateActions.inputChangeHandler(this.props.dispatch, 'caregiverFilter')(
        {},
        {
          name: 'advancedFilter',
          value: ['conflict', 'extra_cost'],
        }
      );
    } else {
      this.props.dispatch(uiStateActions.resetInputGroupState('shiftBarFilter'));
      uiStateActions.inputChangeHandler(this.props.dispatch, 'caregiverFilter')(
        {},
        {
          name: 'advancedFilter',
          value: ['conflict', 'extra_cost'],
        }
      );
      this.props.dispatch(caregiverActions.resetCaregiverList());
    }
  }

  handleProperties(nextProps) {
    const scores = nextProps.caregiverScores || this.props.caregiverScores;
    if (nextProps.shiftList || this.props.shiftList) {
      const shiftList = nextProps.shiftList || this.props.shiftList;
      const shiftIds = caregiverHelpers.costKey(shiftList);
      const caregiverIds =
        nextProps.caregiver.results && !nextProps.caregiver.loading
          ? nextProps.caregiver.results.map(s => s.id)
          : null;
      if (
        (!scores || (scores && !(shiftIds in scores.costs)) || this.state.searching) &&
        caregiverIds
      ) {
        this.props.dispatch(
          caregiverScoresActions.getShiftCaregiverScores(
            shiftList.map(s => s.id),
            true,
            nextProps.caregiverFilter || null,
            caregiverIds
          )
        );
        if (this.state.searching) this.setState({ searching: false });
      }
    }

    if (
      nextProps.caregiver &&
      !nextProps.caregiver.loading &&
      (!nextProps.shiftMode || nextProps.shiftList)
    ) {
      if (nextProps.caregiverFilter && nextProps.caregiver.results) {
        filterCaregiverResults(nextProps.caregiver.results, nextProps.caregiverFilter);
      }

      let latestNote = '';
      let latestNoteId = null;
      if (nextProps.firstShift) {
        latestNote = nextProps.firstShift.latestInvitationNote
          ? nextProps.firstShift.latestInvitationNote
          : '';
        latestNoteId = nextProps.firstShift.id;
      }

      // create "virtual" shift for travel time estimation when using shiftBarFilter
      const { shiftList, destination } =
        !nextProps.shiftList && nextProps.shiftBarFilter
          ? {
              shiftList: [
                {
                  start: nextProps.shiftBarFilter.start,
                  end: nextProps.shiftBarFilter.end,
                  address: nextProps.shiftBarFilter.address,
                },
              ],
              destination: nextProps.caregiver ? nextProps.caregiver.destination : null,
            }
          : { shiftList: nextProps.shiftList, destination: null };

      const dataUnsorted =
        shiftList && nextProps.caregiver.results
          ? caregiverHelpers.prepareCaregiverList(
              nextProps.dispatch,
              nextProps.caregiver.results,
              shiftList,
              scores,
              destination,
              nextProps.caregiverFilter ? nextProps.caregiverFilter.advancedFilter : null,
              this.state.showFiltered,
              this.props.principal?.settings?.weekStartDay
            )
          : nextProps.caregiver.results;

      // prepare data with status map (has offered shifts, has applied shifts etc...)
      const selected = nextProps.shiftFilterState;

      if (dataUnsorted) {
        dataUnsorted.forEach(caregiver => {
          const allOffered =
            Object.keys(selected).length > 0 &&
            Object.keys(selected).reduce((a, b) => {
              if (!a) {
                return a;
              }
              return caregiver.shifts
                ? !!caregiver.shifts.find(s => s.id === parseInt(b, 10))
                : false;
            }, true);

          const actionStatus = {
            offeredShifts: false,
            allOffered,
            assignAll: allOffered,
            declineAll: allOffered,
            assigned: false,
          };

          // eslint-disable-next-line no-param-reassign
          caregiver.actionStatus = caregiver.shifts
            ? caregiver.shifts.reduce((a, b) => {
                const t = selected[b.id];
                // eslint-disable-next-line no-param-reassign
                a.offeredShifts = a.offeredShifts || !!t;

                if (t) {
                  // eslint-disable-next-line no-param-reassign
                  a.assignAll = a.assignAll && b.status === 'applied';

                  // eslint-disable-next-line no-param-reassign
                  a.declineAll = a.declineAll && b.status === 'declined';

                  // eslint-disable-next-line no-param-reassign
                  a.assigned = a.assigned || b.status === 'assigned';

                  // eslint-disable-next-line no-param-reassign
                  a.lastOffered =
                    !a.offered || a.lastOffered.isBefore(b.lastInvited)
                      ? b.lastInvited
                      : a.lastOffered;

                  // eslint-disable-next-line no-param-reassign
                  a.lastApplied =
                    b.status === 'applied' &&
                    (!a.lastApplied || a.lastApplied.isBefore(b.timestamp))
                      ? b.timestamp
                      : a.lastApplied;

                  // eslint-disable-next-line no-param-reassign
                  a.lastDeclined =
                    b.status === 'declined' &&
                    (!a.lastDeclined || a.lastDeclined.isBefore(b.timestamp))
                      ? b.timestamp
                      : a.lastDeclined;
                }

                return a;
              }, actionStatus)
            : actionStatus;
        });
      }

      const { sorting } = this.state;
      const allData = dataUnsorted
        ? Caregivers.sort(
            dataUnsorted,
            sorting.data,
            this.props.shiftClient,
            nextProps.caregiverScores || this.props.caregiverScores,
            this.props.shiftList
          )
        : dataUnsorted;

      const data =
        shiftList && allData
          ? allData.filter(c => !c.actionStatus.assignAll && !c.actionStatus.declineAll)
          : allData;
      const requestedShifts = allData
        ? Caregivers.sort(
            allData.filter(c => c.actionStatus.lastApplied),
            sorting.requestedShifts,
            this.props.shiftClient,
            this.props.caregiverScores,
            this.props.shiftList
          )
        : allData;
      const declinedShifts = allData
        ? Caregivers.sort(
            allData.filter(c => c.actionStatus.lastDeclined),
            sorting.declinedShifts,
            this.props.shiftClient,
            this.props.caregiverScores,
            this.props.shiftList
          )
        : allData;

      if (requestedShifts?.length) {
        this.setState({ activeTab: 'Requests' });
      } else if (declinedShifts?.length) {
        this.setState({ activeTab: 'Declined' });
      }

      this.setState(s => ({
        ...s,
        data,
        requestedShifts,
        declinedShifts,
        allData,
        noteTemplate:
          s.noteTemplate && s.latestNoteId === latestNoteId ? s.noteTemplate : latestNote,
        latestNoteId,
      }));
    }

    if (
      this.state.processingInvites &&
      nextProps.caregiver &&
      !nextProps.caregiver.processingInvites
    ) {
      this.setState(s => ({
        ...s,
        processingInvites: false,
      }));
    }
  }

  showMapClick() {
    this.setState(state => ({ showMap: !state.showMap }));
  }

  inviteCaregiverClick(e, input) {
    Object.assign(input.caregiver, {
      latestInvitationInput: moment().format(MOMENT_HOURS_WITH_DATE),
    });
    this.props.dispatch(caregiverActions.inviteCaregiver(input.caregiver, null, null, null, true));
    analytics.track(Events.CAREGIVERS_REGISTRATION_INVITE_CAREGIVER);
  }

  inviteCaregiversClick(e, input) {
    const { caregiver, shiftList, caregiverScores } = this.props;
    const caregivers = Object.keys(caregiver.selectedCaregivers)
      .filter(v => caregiver.selectedCaregivers[v])
      .map(v => {
        const id = parseInt(v, 10);
        return caregiver.results.find(i => i.id === id);
      });
    const logMeta = caregiverHelpers.logMetasForCaregiverCost(
      caregivers,
      shiftList,
      caregiverScores
    );
    const pendingInvitation = {
      caregivers,
      shiftIdList: shiftList
        .filter(shift => shift.shiftAssigmentStatus !== 'assigned')
        .map(s => s.id),
      status: input.value,
      logMeta,
    };

    this.setState(s => ({
      ...s,
      pendingInvitation,
    }));
    analytics.track(
      input.value === 'assigned'
        ? Events.CAREGIVERS_SHIFTS_ASSIGN_CAREGIVER
        : Events.CAREGIVERS_SHIFTS_INVITE_CAREGIVER
    );

    this.createBotTrackEvent(caregivers, input.value === 'assigned' ? 'assign' : 'invite');
  }

  createBotTrackEvent(selectedCaregivers, type) {
    try {
      const { shiftList, caregiverScores, shiftClient, caregiver } = this.props;
      if (caregiverScores.results && shiftClient && caregiver.results) {
        const scores = caregiverHelpers.scoresForCaregivers(
          caregiver.results,
          shiftClient,
          caregiverScores
        );
        const costs = caregiverHelpers.costsForCaregivers(
          caregiver.results,
          shiftList,
          caregiverScores
        );
        const logEvent = {
          type: 'BOT_TRACK_EVENT',
          meta: {
            scores,
            invited: selectedCaregivers.map(c => c.id),
            shiftIds: shiftList.map(s => s.id),
            clientId: shiftClient.id,
            searchResults: caregiver.results.map(c => c.id),
            costs,
            type,
          },
        };
        this.props.dispatch(eventLogActions.createEvent(logEvent));
      }
    } catch (err) {
      /* empty */
    }
  }

  completeCaregiverInvitation(invitationNotes, cancel) {
    const { pendingInvitation } = this.state;
    if (!cancel && pendingInvitation) {
      const { caregivers, shiftIdList, status, logMeta } = pendingInvitation;
      this.props.dispatch(
        caregiverActions.inviteCaregiver(
          caregivers,
          shiftIdList,
          status,
          invitationNotes,
          undefined,
          logMeta
        )
      );
    }

    this.setState(s => ({
      ...s,
      pendingInvitation: null,
      processingInvites: !cancel,
      noteTemplate: invitationNotes || s.noteTemplate,
    }));
  }

  handleCGClick = (caregiverId, direct = false) => {
    if (direct) {
      history.push(`/home/caregivers/records/${caregiverId}`);
      return;
    }
    this.setState(current => ({ ...current, caregiverDetailId: caregiverId }));
  };

  handleRemind(caregiver) {
    this.props.dispatch(
      caregiverActions.inviteCaregiver(
        caregiver,
        this.props.shiftList.map(s => s.id),
        'remind'
      )
    );
  }

  handleChatClick = (caregiverId, caregiverStatus) => {
    const that = this;
    if (caregiverStatus === 'active') {
      that.props.dispatch(IoT.createConversation(caregiverId, that.props.firstShift.id));
    } else {
      that.props.dispatch(alertActions.error('Cannot start chat. This caregiver is not active'));
    }
  };

  handleCaregiverShiftsClick = (caregiverId, shiftIds) => {
    const filter = {
      shiftStatus: 'allshifts',
      shiftCompinedStatus: ['assigned'],
      startDate: moment(),
      endDate: moment().add(14, 'days'),
      timeRange: { min: 1, max: 24 },
      primaryContact: 0,
    };

    if (shiftIds.length > 0) {
      this.props.dispatch(
        schedulerActions.listSchedules(
          {
            caregiverId,
            withIds: shiftIds,
          },
          filter
        )
      );
    } else {
      filter.endDate = moment().add(30, 'days');
      this.props.dispatch(schedulerActions.resetSchedulesSearch(filter));
    }
    history.push('/home/shifts');
  };

  handleCGDetailsClose = () => {
    this.setState({ caregiverDetailId: null });
  };

  handleSearchSubmit() {
    this.setState({ showFiltered: true });

    const params = caregiverActions.combineCaregiverParams(
      this.props.shiftList,
      null, // this.props.caregiverFilter,
      this.props.shiftBarFilter,
      this.state.searchField,
      this.props.shiftMode ? undefined : Object.keys(StaffStatusMap) // do name search by all known staff status values
    );

    if (this.props.caregiver.allSelected) {
      this.props.dispatch(caregiverActions.selectAllClicked());
    }

    this.props.dispatch(caregiverActions.listCaregivers(params));
    analytics.track(Events.CAREGIVERS_SEARCH_NAME);

    this.setState({ searching: true });
  }

  handleSort = sortGroup => clickedColumn => () => {
    const { column, direction } = this.state.sorting[sortGroup];
    const data = this.state[sortGroup];

    if (!data) return;

    let newDirection = direction === 'ascending' ? 'descending' : 'ascending';
    if (column !== clickedColumn) {
      newDirection = 'ascending';
    }

    this.setState(s => {
      const sorting = { ...s.sorting };
      sorting[sortGroup] = { column: clickedColumn, direction: newDirection };

      const n = {
        sorting,
        [sortGroup]: Caregivers.sort(
          data,
          sorting[sortGroup],
          this.props.shiftClient,
          this.props.caregiverScores,
          this.props.shiftList
        ),
      };

      return n;
    });
  };

  shiftCountItem(caregiver) {
    const shiftCount =
      caregiver.assignedShifts.length > 0
        ? caregiver.assignedShifts && caregiver.assignedShifts.length
        : '--';
    const handleClick = () => {
      this.handleCaregiverShiftsClick(caregiver.id, caregiver.assignedShifts);
    };
    const element =
      shiftCount && shiftCount > 0 ? (
        <span
          key={`sc_${caregiver.id}`}
          className='overlayLink'
          role='presentation'
          onClick={handleClick}
        >
          {shiftCount}
        </span>
      ) : (
        <span>{shiftCount}</span>
      );

    return {
      key: 'shiftCount',
      textAlign: 'center',
      content: element,
    };
  }

  filterFailureDesc(filterFailure) {
    if (filterFailure == null || filterFailure.length === 0) {
      return '';
    }

    const statusFailures = filterFailure.filter(obj => obj.name === 'statusFilter');

    const staffStatusFailures = filterFailure.filter(obj => obj.name === 'staffStatusFilter');

    const genderFailures = filterFailure.filter(obj => obj.name === 'genderFilter');

    const preferenceFailures = filterFailure.filter(obj => obj.name === 'preferenceFilter');

    const profileFieldsFailures = filterFailure.filter(obj => obj.name === 'profileFieldsFilter');

    const languageFailures = filterFailure.filter(obj => obj.name === 'languages');

    const errors = [];
    if (statusFailures.length > 0) {
      errors.push('Registration Status');
    }

    if (staffStatusFailures.length > 0) {
      errors.push('Caregiver Status');
    }

    if (genderFailures.length > 0) {
      errors.push('Gender');
    }

    const checkFailure = (failureName, errorText) => {
      if (filterFailure.findIndex(item => item.name === failureName) >= 0) {
        errors.push(errorText);
      }
    };

    checkFailure('nonCompliant', 'Caregiver is out of compliance');
    checkFailure('extra_cost', 'Cost Penalty');
    checkFailure('conflict', 'Shift Conflict');
    checkFailure('travelTime', 'Commute Time');
    checkFailure('unavailable', 'Unavailable');
    checkFailure('extra_cost_not_available', 'Cost Penalty not available');

    if (preferenceFailures.length > 0) {
      const failures = [];
      if (preferenceFailures[0].failures) {
        preferenceFailures[0].failures.forEach(val => {
          const failed = this.props.attributes.preferences.filter(attr => attr.id === val)[0];
          if (failed != null) {
            failures.push(failed.caregiverLabel || failed.label);
          }
        });
        errors.push(`Preferences: ${failures.join(', ')}`);
      }
    }

    if (profileFieldsFailures.length > 0) {
      const failures = [];
      if (profileFieldsFailures[0].failures) {
        profileFieldsFailures[0].failures.forEach(val => {
          const failed = this.props.attributes.profileFields.filter(attr => attr.id === val)[0];
          if (failed != null) {
            failures.push(failed.name);
          }
        });

        errors.push(`Profile Options: ${failures.join(', ')}`);
      }
    }

    if (languageFailures.length > 0) {
      const failures = [];
      if (languageFailures[0].failures) {
        languageFailures[0].failures.forEach(val => {
          const failed = this.props.languages.filter(attr => attr.value === val)[0];
          if (failed != null) {
            failures.push(failed.text);
          }
        });
        errors.push(`Languages ${failures.join(', ')}`);
      }
    }

    return errors.join(', ');
  }

  selectCell(caregiver, invitationField) {
    const assignClicked = caregiverId => {
      const { shiftList, caregiverScores } = this.props;
      this.createBotTrackEvent([caregiver], 'assign');
      this.setState({ assignedCaregiver: caregiverId });

      const logMeta = caregiverHelpers.logMetasForCaregiverCost(
        [caregiver],
        shiftList,
        caregiverScores
      );

      this.props.dispatch(
        caregiverActions.inviteCaregiver(
          caregiver,
          shiftList.filter(shift => shift.shiftAssigmentStatus !== 'assigned').map(s => s.id),
          'assigned',
          undefined,
          undefined,
          logMeta
        )
      );
    };

    if (!caregiver.isInvitable) {
      const onMissingInfoClick = () => {
        this.handleCGClick(caregiver.id, true);
      };

      const content = (
        <Button
          id='caregivers-info-req-button'
          key={`inre_${caregiver.id}`}
          caregiverid={caregiver.id}
          className='care-red'
          compact
          size='tiny'
          onClick={onMissingInfoClick}
        >
          Info Req
        </Button>
      );

      return { key: 'select', content, textAlign: 'center', verticalAlign: 'middle' };
    }

    const content = [];
    const { allOffered, assignAll, assigned } = caregiver.actionStatus;

    let addCheckBox = true;

    if (!this.props.actionableShifts) {
      return {
        key: 'select',
        content: <span>{assigned && 'Assigned'}</span>,
        textAlign: 'center',
        verticalAlign: 'middle',
      };
    }
    if (assignAll) {
      content.push(
        <Button
          id='caregivers-assign-button'
          key={`aall_${caregiver.id}`}
          caregiverid={caregiver.id}
          className='care-green'
          compact
          size='tiny'
          onClick={() => assignClicked(caregiver.id)}
          loading={this.state.assignedCaregiver === caregiver.id}
          disabled={this.state.assignedCaregiver === caregiver.id}
        >
          Assign
        </Button>
      );
      addCheckBox = false;
    } else if (allOffered || invitationField) {
      const { shiftClient } = this.props;
      content.push(
        <ShiftRequests
          key={`sr_${caregiver.id}`}
          caregiver={caregiver}
          client={shiftClient}
          dispatch={this.props.dispatch}
          shiftList={this.props.shiftList}
          handleRemind={this.handleRemind}
          asAssignButton={invitationField}
          loading={this.props.caregiver.processingCaregiver[caregiver.id]}
          caregiverScores={this.props.caregiverScores}
        />
      );
    }

    if (addCheckBox && !allOffered && !invitationField) {
      content.push(
        <Checkbox
          key={`cgcb_${caregiver.id}`}
          checked={this.props.caregiver.selectedCaregivers[caregiver.id]}
          onClick={() => this.props.dispatch(caregiverActions.clickCaregiverCheckbox(caregiver.id))}
        />
      );
    }

    return { key: 'select', content, textAlign: 'center', verticalAlign: 'middle' };
  }

  responsesCell(caregiver, declines) {
    const response = (
      <ShiftRequests
        key={`s_${caregiver.id}`}
        caregiver={caregiver}
        client={this.props.shiftClient}
        dispatch={this.props.dispatch}
        shiftList={this.props.shiftList}
        showAsResponses={declines ? 'declines' : 'applies'}
        handleRemind={this.handleRemind}
        caregiverScores={this.props.caregiverScores}
      />
    );

    return { key: 'responses', content: response, textAlign: 'left' };
  }

  invitationResponseBody = declines => x => ({
    className: Caregivers.showFilterFailure(x) ? 'conflictPadding' : '',
    key: x.id,
    cells: [
      {
        key: 'rating',
        verticalAlign: 'middle',
        content: this.ratingContent(x, this.props.caregiverScores, this.props.shiftClient),
      },
      {
        key: 'name',
        verticalAlign: 'middle',
        content: (
          <div>
            <div className={Caregivers.showFilterFailure(x) ? 'arrow-up' : 'hiddenBox'} />
            <div className={Caregivers.showFilterFailure(x) ? 'tableBottomBar' : 'hiddenBox'}>
              <p>
                <b>Conflict: </b> {this.filterFailureDesc(x.filterFailure)}
              </p>
            </div>
            <Overlay
              key={`cgol_${x.id}`}
              caregiver={x}
              handleChatClick={this.handleChatClick}
              direct
              simple={this.props.shiftMode}
              onCloseClicked={this.handleCGDetailsClose}
              editableCaregiverDetails
            />
          </div>
        ),
      },
      { key: 'discipline', textAlign: 'center', content: x.discipline || '' },
      {
        key: 'distance',
        textAlign: 'center',
        content: Caregivers.travelTime(x.maxTravelTime) || '',
      },
      this.shiftCountItem(x),
      { key: 'hours', content: formatHours(x.weeklyHours) || '0', textAlign: 'center' },
      {
        key: 'cost_penalty',
        verticalAlign: 'middle',
        textAlign: 'center',
        content: this.costPenaltyContent(x, this.props.caregiverScores, this.props.shiftList),
      },
      this.responsesCell(x, declines),
      this.selectCell(x, true),
    ],
  });

  ratingContent = (caregiver, caregiverScores, shiftClient) => {
    let ratingValue;
    let historyPopupVal;
    let languagePopupVal;
    const rating = Caregivers.scoreForCaregiver(caregiver, shiftClient, caregiverScores);
    const colorForRating = (cgRating, byRank = false) => {
      if (cgRating.score === undefined || cgRating.score === null) {
        return 'black';
      }

      if ((byRank && cgRating.rank < 4) || (!byRank && cgRating.score.score > 75)) {
        return '#6ed992';
      }
      if ((byRank && cgRating.rank < 11) || (!byRank && cgRating.score.score >= 40)) {
        return '#f5a623';
      }
      return '#dd6071';
    };
    let loading = false;

    if (
      shiftClient &&
      ((caregiverScores.results &&
        caregiverScores.results[shiftClient.id] &&
        caregiverScores.results[shiftClient.id].loading) ||
        (!caregiverScores.results[shiftClient.id] && caregiverScores.loading))
    ) {
      loading = true;
    } else if (rating.score !== undefined) {
      ratingValue = `${parseInt(rating.score.score, 10)}%`;
      historyPopupVal = parseInt(rating.score.history, 10) > 0 ? '\u2705' : '\u274c';
      languagePopupVal = parseInt(rating.score.language, 10) > 0 ? '\u2705' : '\u274c';
    } else {
      ratingValue = '-';
    }

    const ratingColor = colorForRating(rating, false);

    return loading ? (
      <div>loading...</div>
    ) : (
      <div>
        <Popup
          trigger={<b style={{ color: ratingColor }}>{ratingValue}</b>}
          content={
            rating.score ? (
              <div>
                {`Recent history ${historyPopupVal}`}
                <div />
                {`Common language ${languagePopupVal}`}
                <div />
                {`Preferences match: ${parseInt(rating.score.preferences, 10)} %`}
                <div />
                {`Trainings match: ${parseInt(rating.score.training, 10)} %`}
                <div />
                {`Distance ${parseInt(rating.score.distance * 0.621371, 10)} miles`}
                <div />
              </div>
            ) : (
              'No rating available'
            )
          }
          on='hover'
          position='top left'
          popperModifiers={{
            preventOverflow: {
              boundariesElement: 'offsetParent',
            },
          }}
        />
      </div>
    );
  };

  costPenaltyContent = (caregiver, caregiverScores, shiftList) => {
    const costObj = caregiverHelpers.costForCaregiver(caregiver, shiftList, caregiverScores);
    const { cost } = costObj;
    if (!costObj) {
      return <div />;
    }
    if (costObj.loading) {
      return <div>loading...</div>;
    }
    if (cost === undefined) {
      return (
        <Popup
          trigger={<div>-</div>}
          content={<div>Not available</div>}
          position='top left'
          popperModifiers={{
            preventOverflow: {
              boundariesElement: 'offsetParent',
            },
          }}
        />
      );
    }

    return Caregivers.costPopOver(cost);
  };

  body(x) {
    const { shiftMode, caregiverScores, shiftList, shiftClient } = this.props;

    return {
      className: Caregivers.showFilterFailure(x) ? 'conflictPadding' : '',
      key: x.id,
      cells: shiftMode
        ? [
            {
              key: 'rating',
              verticalAlign: 'middle',
              content: this.ratingContent(x, caregiverScores, shiftClient),
            },
            {
              key: 'name',
              verticalAlign: 'middle',
              content: (
                <div>
                  <div className={Caregivers.showFilterFailure(x) ? 'arrow-up' : 'hiddenBox'} />
                  <div className={Caregivers.showFilterFailure(x) ? 'tableBottomBar' : 'hiddenBox'}>
                    <p>
                      <b>Conflict: </b> {this.filterFailureDesc(x.filterFailure)}
                    </p>
                  </div>

                  <Overlay
                    key={`cgol_${x.id}`}
                    caregiver={x}
                    handleChatClick={this.handleChatClick}
                    direct
                    simple={this.props.shiftMode}
                    onCloseClicked={this.handleCGDetailsClose}
                    editableCaregiverDetails
                  />
                </div>
              ),
            },
            { key: 'discipline', textAlign: 'center', content: x.discipline || '' },
            {
              key: 'distance',
              textAlign: 'center',
              content: Caregivers.travelTime(x.maxTravelTime) || '',
            },
            this.shiftCountItem(x),
            { key: 'hours', content: formatHours(x.weeklyHours) || '0', textAlign: 'center' },
            {
              key: 'cost_penalty',
              verticalAlign: 'middle',
              textAlign: 'center',
              content: this.costPenaltyContent(x, caregiverScores, shiftList),
            },
            statusItem(x, this.inviteCaregiverClick, this.handleCGClick, false),
            this.selectCell(x),
          ]
        : [
            {
              key: 'name',
              content: (
                <div>
                  <div className={Caregivers.showFilterFailure(x) ? 'arrow-up' : 'hiddenBox'} />
                  <div className={Caregivers.showFilterFailure(x) ? 'tableBottomBar' : 'hiddenBox'}>
                    <p>
                      <b>Conflict: </b> {this.filterFailureDesc(x.filterFailure)}
                    </p>
                  </div>

                  <Overlay
                    caregiver={x}
                    direct
                    onCloseClicked={this.handleCGDetailsClose}
                    editableCaregiverDetails
                  />
                </div>
              ),
              verticalAlign: 'middle',
            },
            { key: 'discipline', textAlign: 'center', content: x.discipline || '' },
            { key: 'phone', textAlign: 'center', content: phoneNumbers(x.phones) },
            { key: 'zip', textAlign: 'center', content: x.address.zip },
            {
              key: 'distance',
              textAlign: 'center',
              content: Caregivers.travelTime(x.maxTravelTime),
            },
            this.shiftCountItem(x),
            freeViewStatusItem(x),
            freeViewStatusAction(x, this.inviteCaregiverClick, this.handleCGClick),
          ],
    };
  }

  updateBounds(ref, newBounds) {
    if (!this.state.bounds || JSON.stringify(newBounds) !== JSON.stringify(this.state.bounds)) {
      this.setState(s => ({ ...s, bounds: newBounds }));
      ref.fitBounds(newBounds);
    }
  }

  handleEditorClick = () => {
    this.setState(current => ({ ...current, caregiverEditorId: 289033489 }));
  };

  handleEditorClose = newCaregiver => {
    const newState = { caregiverEditorId: null };
    if (newCaregiver && newCaregiver.profile && newCaregiver.profile.id) {
      newState.caregiverDetailId = newCaregiver.profile.id;
      newState.caregiverDetailTab = 'prefAndSkills';
    }

    this.setState(current => ({ ...current, ...newState }));
  };

  handleUpdateClick() {
    if (this.props.caregiver.allSelected) {
      this.props.dispatch(caregiverActions.selectAllClicked());
    }
    this.props.dispatch(caregiverScoresActions.resetScores());
    this.setState({ showFiltered: false });
    this.pageSizer.scrollToTop();
  }

  tabClick = e => {
    this.setState({
      activeTab: e,
    });
  };

  render() {
    const { settings } = this.props.principal;
    const {
      caregiver,
      shiftMode,
      shiftDestinations,
      shiftList,
      actionableShifts,
      caregiverScores,
    } = this.props;
    const {
      data,
      allData,
      requestedShifts,
      declinedShifts,
      sorting,
      showMap,
      caregiverDetailId,
      caregiverDetailTab,
      searchField,
      activeMapInfoBox,
      pendingInvitation,
      processingInvites,
      noteTemplate,
      caregiverEditorId,
      activeTab,
    } = this.state;
    const caregiverCount = data ? data.length : 0;
    const showLocationSearch = this.props.shiftBarFilter.visible;
    const showRequested = shiftMode && requestedShifts && requestedShifts.length > 0;
    const showDeclined = shiftMode && declinedShifts && declinedShifts.length > 0;

    let loadingCosts = false;
    if (shiftList) {
      const shiftIds = caregiverHelpers.costKey(shiftList);
      if (!(shiftIds in caregiverScores.costs) || caregiverScores.costs[shiftIds].loading) {
        loadingCosts = true;
      }
    }

    const initState = !data || (caregiver.loading && !caregiver.partialUpdate);
    const advfilter = this.props.caregiverFilter.advancedFilter;
    const extracost = advfilter.includes('extra_cost');
    const loading = this.props.caregiver.processingInvites;

    return (
      <Grid.Row className='noVerticalPadding'>
        {(pendingInvitation || processingInvites) && (
          <CaregiverInvitationNote
            submit={this.completeCaregiverInvitation}
            processingInvites={loading}
            pendingInvitation={pendingInvitation}
            noteTemplate={noteTemplate}
          >
            {loading && (
              <Dimmer active inverted>
                <CareLoader loading showText={false} />
              </Dimmer>
            )}
          </CaregiverInvitationNote>
        )}
        {caregiverDetailId && (
          <CaregiverDetails
            caregiverId={caregiverDetailId}
            onCloseClicked={this.handleCGDetailsClose}
            tab={caregiverDetailTab}
          />
        )}
        {caregiverEditorId && <CaregiverEditor onCloseClicked={this.handleEditorClose} />}
        <Grid padded style={{ width: '100%' }} className='contentGrid'>
          <Grid.Column width='three' textAlign='center' className='menuCol'>
            <CaregiversMenu
              loading={caregiver.loading}
              onUpdateClick={this.handleUpdateClick}
              shiftList={shiftList}
              showLocationSearch={showLocationSearch}
            />
            <PageMeter name='bottom' onChange={this.onMeterChanged} />
          </Grid.Column>
          <Grid.Column width='thirteen'>
            <Grid padded>
              <Grid.Row
                className={
                  shiftMode || showLocationSearch ? 'header caregivers' : 'header caregivers border'
                }
              >
                <Grid.Column
                  width='three'
                  verticalAlign='middle'
                  className='noVerticalPadding headerWithCount'
                >
                  <Header floated='left' as='h3'>
                    CAREGIVERS
                  </Header>
                  <div>({caregiverCount})</div>
                </Grid.Column>
                <Grid.Column width='seven' verticalAlign='middle' className='noVerticalPadding'>
                  <Form size='tiny' onSubmit={this.handleSearchSubmit}>
                    <Form.Group className='menu-input-with-button' inline>
                      <Form.Input
                        width='twelve'
                        id='caregiversSearchName'
                        size='tiny'
                        inline
                        floated='left'
                        placeholder='Find by Caregiver Name'
                        value={searchField}
                        onChange={(e, input) =>
                          this.setState({
                            searchField: input.value,
                          })
                        }
                      />
                      <Form.Button
                        id='caregivers-search-button'
                        inline
                        circular
                        loading={caregiver.loading}
                      >
                        Go
                      </Form.Button>
                    </Form.Group>
                  </Form>
                </Grid.Column>
                <Grid.Column
                  width='six'
                  textAlign='right'
                  verticalAlign='middle'
                  style={{ paddingRight: 0 }}
                  className='noVerticalPadding right'
                >
                  {settings && settings.standaloneMode ? (
                    <span className='add-button-with-label' style={{ paddingRight: 0 }}>
                      <span>Add a New Caregiver</span>
                      <CareRoundButton
                        content='+'
                        onClick={this.handleEditorClick}
                        id='caregivers-add-new-caregiver-button'
                      />
                    </span>
                  ) : (
                    undefined
                  )}
                  {(shiftMode || caregiver.destination) && (
                    <Button
                      id='caregivers-show-map-button'
                      style={{ marginLeft: '20px' }}
                      inline
                      hidden={shiftMode || caregiver.destination}
                      basic
                      size='small'
                      floated='right'
                      onClick={this.showMapClick}
                    >
                      <Icon name='marker' />
                      {showMap ? 'Hide Map' : 'Show Map'}
                    </Button>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid padded>
              {shiftMode && !initState && (
                <Grid.Row colums='one'>
                  <MultiShiftBar
                    onReset={this.onResetShiftDetails}
                    onClientUpdate={this.handleUpdateClick}
                    onUpdateClick={this.handleUpdateClick}
                  />
                </Grid.Row>
              )}
              {!shiftMode && showLocationSearch && (
                <div className='shiftDetailsContainer'>
                  <ShiftDetailsBar
                    onReset={this.onResetShiftDetails}
                    timezone={
                      this.props.caregiver.destination
                        ? this.props.caregiver.destination.timezone
                        : null
                    }
                  />
                  <div className='floatTopRight'>
                    <Image
                      id='search-by-location-close-button'
                      className='tinyCloseButton'
                      src='/close_round.svg'
                      onClick={() => this.setLocationSearchVisibility(false)}
                    />
                  </div>
                </div>
              )}
              {!shiftMode && !showLocationSearch && (
                <span
                  id='caregivers-search-by-location'
                  className='overlayLink'
                  role='presentation'
                  onClick={() => this.setLocationSearchVisibility(true)}
                >
                  <FormattedMessage id='search.by.location' />
                </span>
              )}
            </Grid>

            <Grid padded centered>
              {(shiftMode || caregiver.destination) && (
                <div className={initState || !showMap ? 'hiddenElement' : 'wrapper'}>
                  <Grid padded>
                    <Grid.Row columns='one' stretched>
                      <CaregiverMap
                        destinations={shiftMode ? shiftDestinations : [caregiver.destination]}
                        caregivers={allData}
                        activeMapInfoBox={activeMapInfoBox}
                        onMouseOver={this.onMapMarkerMouseOver}
                        onMarkerClick={this.onMapMarkerClick}
                        updateBounds={this.updateBounds}
                        loadingElement={<div style={{ height: '100%', width: '100%' }} />}
                        containerElement={<div style={{ height: '300px', width: '100%' }} />}
                        mapElement={
                          <div
                            style={{
                              height: '100%',
                              width: '100%',
                              position: 'relative',
                              left: '-11px',
                            }}
                          />
                        }
                      />
                    </Grid.Row>
                  </Grid>
                  <div className='floatTopRightMap'>
                    <Image
                      id='caregiver-map-close-button'
                      className='tinyCloseButton'
                      src='/close_round.svg'
                      onClick={this.showMapClick}
                    />
                  </div>
                </div>
              )}
              <PageSizer
                ref={node => {
                  this.pageSizer = node;
                }}
                meterState={this.state.meterState}
                active={data && data.length > 0 && !initState}
                contentHeight={data ? data.length * 50 : 0}
              >
                <PageMeter name='top' onChange={this.onMeterChanged} />
                {showRequested && !initState && activeTab === 'Requests' && (
                  <span>
                    <InvitationRespHeaderTabs
                      requestsCount={requestedShifts.length}
                      declinedCount={declinedShifts.length}
                      tabClick={this.tabClick}
                      activeTab={activeTab}
                    />
                    <Table
                      className='caregiverResults applied'
                      sortable
                      singleLine
                      striped
                      headerRow={invitationResponseHeaders(
                        sorting.requestedShifts,
                        this.handleSort('requestedShifts')
                      )}
                      renderBodyRow={this.invitationResponseBody(false)}
                      tableData={requestedShifts || []}
                    />
                  </span>
                )}
                {showDeclined && !initState && activeTab === 'Declined' && (
                  <span>
                    <InvitationRespHeaderTabs
                      requestsCount={requestedShifts.length}
                      declinedCount={declinedShifts.length}
                      tabClick={this.tabClick}
                      activeTab={activeTab}
                    />
                    <Table
                      className='caregiverResults declined'
                      sortable
                      singleLine
                      striped
                      tabClick
                      headerRow={invitationResponseHeaders(
                        sorting.declinedShifts,
                        this.handleSort('declinedShifts')
                      )}
                      renderBodyRow={this.invitationResponseBody(true)}
                      tableData={declinedShifts || []}
                    />
                  </span>
                )}
                {shiftMode && !initState && (
                  <div className='matchedCaregivers'>
                    <Image src='/matched_icon_grey.svg' />
                    <div className='textContainer'>
                      <div className='header'>
                        Matched Caregivers{data && <span className='count'>({data.length})</span>}
                      </div>
                      <br />
                      <div className='content'>
                        Additional caregivers that match your shift search combination above
                      </div>
                    </div>
                    <div style={{ clear: 'both' }} />
                    <div className='table-assign-caregiver-check-all'>
                      Select All&nbsp;
                      <Checkbox
                        style={{ paddingRight: '50px' }}
                        onClick={() => this.props.dispatch(caregiverActions.selectAllClicked())}
                        checked={caregiver.allSelected}
                      />
                    </div>
                  </div>
                )}
                <Table
                  id='caregiversResultsTable'
                  className='caregiverResults stickyHeader'
                  sortable
                  singleLine
                  striped
                  headerRow={headers(shiftMode, sorting.data, this.handleSort('data'))}
                  renderBodyRow={this.body}
                  tableData={!caregiver.partialUpdate && caregiver.loading ? [] : data || []}
                />
                {shiftMode && actionableShifts && !initState && caregiverCount > 0 && (
                  <Grid style={{ position: 'sticky', bottom: '0', marginTop: '1em' }}>
                    <Grid.Row
                      className={
                        !caregiver.inviteDisabled
                          ? 'invite-assign-bottom-bar-fade-in'
                          : 'invite-assign-bottom-bar-fade-out'
                      }
                    >
                      <Grid.Column
                        width='16'
                        textAlign='right'
                        style={{ backgroundColor: '#f8f8f8' }}
                      >
                        <hr style={{ marginBottom: '1em', marginTop: '0' }} />
                        <Button
                          id='caregivers-table-assign-selected-button'
                          className='care-green'
                          size='mini'
                          disabled={
                            caregiver.assignDisabled ||
                            !actionableShifts ||
                            caregiver.allSelected ||
                            (caregiver.selected && caregiver.selected.status !== 'active')
                          }
                          value='assigned'
                          onClick={this.inviteCaregiversClick}
                        >
                          Assign Selected
                        </Button>
                        <Button
                          id='caregivers-table-invite-selected-button'
                          className='care-green'
                          size='mini'
                          disabled={caregiver.inviteDisabled || !actionableShifts}
                          value='offered'
                          onClick={this.inviteCaregiversClick}
                        >
                          Invite Selected
                        </Button>
                        Select All&nbsp;
                        <Checkbox
                          style={{ paddingRight: '50px' }}
                          onClick={() => this.props.dispatch(caregiverActions.selectAllClicked())}
                          checked={caregiver.allSelected}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                )}
              </PageSizer>
              <LoadMore
                onLoadMore={this.onLoadMore}
                loading={caregiver.loading}
                partialUpdate={caregiver.partialUpdate}
                nextPage={caregiver.nextPage}
              />
              {data && data.length === 0 && !loadingCosts && !caregiver.loading && (
                <NoMatch
                  id='caregiversResultsNoMatch'
                  tooManyResults={caregiver.tooManyResults}
                  dispatch={this.props.dispatch}
                  extracost={extracost}
                />
              )}
              {(initState || loadingCosts) && (
                <InitialState
                  searchingFor='matching caregivers'
                  loading={caregiver.loading || (shiftMode && !data) || loadingCosts}
                />
              )}
            </Grid>
          </Grid.Column>
        </Grid>
      </Grid.Row>
    );
  }
}

Caregivers.propTypes = {
  dispatch: PropTypes.func.isRequired,
  shiftBarFilter: PropTypes.shape().isRequired,
  shiftList: PropTypes.arrayOf(PropTypes.shape()),
  caregiverScores: PropTypes.shape(),
  shiftDestinations: PropTypes.arrayOf(PropTypes.shape()),
  shiftClient: PropTypes.shape(),
  actionableShifts: PropTypes.bool,
  shiftMode: PropTypes.bool,
  location: PropTypes.shape(),
  caregiverFilter: PropTypes.shape({
    advancedFilter: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  caregiver: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape()),
    loading: PropTypes.bool,
    destination: PropTypes.shape(),
    selectedCaregivers: PropTypes.shape(),
    processingInvites: PropTypes.bool,
    nextPage: PropTypes.string,
    partialUpdate: PropTypes.bool,
    initialRequestDone: PropTypes.bool,
    allSelected: PropTypes.bool,
    processingCaregiver: PropTypes.shape(),
  }).isRequired,
  attributes: PropTypes.shape().isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape()),
  principal: PropTypes.shape().isRequired,
};

Caregivers.defaultProps = {
  shiftList: null,
  shiftClient: null,
  shiftMode: false,
  actionableShifts: false,
  shiftFilterState: {},
  shiftDestinations: [],
  location: null,
  languages: [],
  clientEditorId: null,
  caregiverScores: null,
};

const mapStateToProps = state => {
  const { caregiver, uiState, shiftDetails, multiShiftSelector, principal } = state;

  const { caregiverScores } = state;
  const { attributes, languages } = state.principal;

  const shiftFilterState = uiState.inputGroups
    ? uiState.inputGroups.multiShiftSelector.selectedShifts.reduce((a, b) => {
        // eslint-disable-next-line no-param-reassign
        a[b] = true;
        return a;
      }, {})
    : {};

  const shiftList = multiShiftSelector.shiftList
    ? multiShiftSelector.shiftList.filter(s => shiftFilterState[s.id])
    : multiShiftSelector.shiftList;

  const actionableShifts = shiftList ? shiftList.reduce((a, b) => a || b.actionable, false) : false;

  return {
    caregiver,
    caregiverFilter: uiState.inputGroups ? uiState.inputGroups.caregiverFilter : undefined,
    shiftBarFilter: uiState.inputGroups ? uiState.inputGroups.shiftBarFilter : undefined,
    shiftDetails,
    shiftMode: multiShiftSelector.active,
    shiftDestinations: multiShiftSelector.destinations,
    shiftClient: multiShiftSelector.client,
    actionableShifts,
    shiftList,
    firstShift: shiftList ? shiftList[0] : null,
    shiftFilterState,
    attributes,
    languages,
    principal,
    caregiverScores,
  };
};

export default connect(mapStateToProps)(Caregivers);

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import URLSearchParams from 'url-search-params';
import { config } from '../helpers/config';
import { uiStateActions } from '../actions';

const PrivateRoute = ({
  component: Component,
  componentProps,
  mode,
  subTab,
  dispatch,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      const searchParams = new URLSearchParams(props.location.search);

      if (mode) dispatch(uiStateActions.uiStateChange('application', 'mode', mode));
      if (subTab) {
        dispatch(uiStateActions.uiStateChange('application', 'subTab', subTab));
      }

      let redirect = null;
      if (props.match && props.match.path === '/redirect') {
        switch (searchParams.get('action')) {
          case 'shift':
            redirect = '/home/caregivers';
            break;
          default:
        }
      }

      if (localStorage.getItem(config.LOCAL_STORAGE_KEY)) {
        if (redirect) {
          return (
            <Redirect
              to={{
                pathname: redirect,
                state: { from: props.location },
              }}
            />
          );
        }

        return <Component {...props} {...componentProps} />;
      }

      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  componentProps: PropTypes.shape(),
  location: PropTypes.shape(),
  match: PropTypes.shape(),
  mode: PropTypes.string,
  subTab: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

PrivateRoute.defaultProps = {
  componentProps: {},
  location: {},
  match: null,
  mode: null,
  subTab: '',
};

export default PrivateRoute;

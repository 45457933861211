/* eslint-disable max-classes-per-file */
import moment from 'moment';
import { travelTimeActions } from '../actions';

/* eslint-disable-next-line @typescript-eslint/naming-convention */
const _ = require('underscore');

const costKey = shiftList =>
  shiftList
    .map(s => s.id)
    .sort((a, b) => a - b)
    .join();

const costForCaregiver = (caregiver, shifts, scores) => {
  let cost;
  let loading;
  let error;
  if (shifts && scores && scores.costs) {
    const shiftIds = costKey(shifts);
    const clScores = scores.costs[shiftIds];
    if (clScores) {
      loading = clScores.loading;
      error = clScores.error;
      cost = _.findWhere(clScores.costs, { caregiver_id: caregiver.id });
    }
  }
  return { cost, loading, error };
};

const logMetasForCaregiverCost = (caregivers, shifts, scores) => {
  const cgs = Array.isArray(caregivers) ? [...caregivers] : [caregivers];

  const logMetas = cgs.map(caregiver => {
    const { cost } = costForCaregiver(caregiver, shifts, scores);
    const logMeta = cost || { caregiver_id: caregiver.id, extra_costs: null };
    return logMeta;
  });
  return logMetas;
};

const costsForCaregivers = (caregivers, shifts, scores) => {
  let costs;
  if (shifts && scores && scores.costs) {
    const shiftIds = costKey(shifts);
    const clScores = scores.costs[shiftIds];
    if (clScores) {
      const cgIds = caregivers.map(cg => cg.id);
      costs = _.filter(clScores.costs, s => cgIds.indexOf(s.caregiver_id) !== -1);
      costs = costs.map(s => ({ cg_id: s.caregiver_id, cost: s.extra_costs }));
    }
  }
  return costs;
};

const scoresForCaregivers = (caregivers, client, scores) => {
  let cgScores;
  if (client && client.id && scores && scores.results) {
    const clScores = scores.results[client.id];
    if (clScores) {
      const cgIds = caregivers.map(cg => cg.id);
      cgScores = _.filter(clScores.scores, s => cgIds.indexOf(s.caregiver_id) !== -1);
    }
  }
  return cgScores;
};

class WeeklyMinutes {
  static overtimeLimit = 40 * 60;

  constructor() {
    this.assigned = 0;
    this.offered = 0;
  }

  addAssigned(shift) {
    this.assigned += shift.minutes;
  }

  addOffered(shift) {
    this.offered += shift.minutes;
  }

  hasOfferedShifts() {
    return !!this.offered;
  }

  testFilters(options = { overtime: true }) {
    if (options.overtime && this.assigned + this.offered > WeeklyMinutes.overtimeLimit) {
      return false;
    }
    return true;
  }

  static weekStart(datetime, weekStartDay) {
    // set starting day at the given weekStartDay
    // days on Database Mon to Sun [0...6]
    // .day() return day Sun to Sat [0...6]
    switch (weekStartDay) {
      case 5:
        return moment(datetime).day() < 6 ? moment(datetime).day(-1) : moment(datetime).day(6);
      default:
        return moment(datetime).day(0);
    }
  }

  static weekName(datetime, weekStartDay) {
    return WeeklyMinutes.weekStart(datetime, weekStartDay).format('YYYYDDDD');
  }
}

class CaregiverFilter {
  static DefaultMarginIn = 1800;

  static DefaultMarginOut = 0;

  static Margin = 600;

  constructor(dispatch, caregivers, shiftList, scores, destination, options, weekStartDay) {
    this.caregivers = caregivers;
    this.shiftList = shiftList;
    this.scores = scores;
    this.destination = destination;
    this.travelTimeRequests = [];
    this.options = options;
    this.weekStartDay = weekStartDay;
  }

  updateTraveltimeRequest(original, caregiver, targetShift, inShift, outShift, home) {
    let travelTimeRequest = original;

    if (inShift) {
      if (
        !(
          original &&
          original.in &&
          original.in.from === inShift.id &&
          original.in.arrivalTime.isSame(targetShift.start)
        )
      ) {
        travelTimeRequest = original ? { ...original } : {};

        const r = {
          type: 'in',
          caregiverId: caregiver.id,
          targetId: targetShift.id,
          mode: caregiver.casePref.transportType,
          from: inShift.id,
          to: targetShift.id,
          status: null,
          arrivalTime: targetShift.start,
        };

        this.travelTimeRequests.push(r);
        travelTimeRequest.in = r;
      }
    }

    if (outShift) {
      if (
        !(
          original &&
          original.out &&
          original.out.to === outShift.id &&
          original.out.departureTime.isSame(targetShift.end)
        )
      ) {
        travelTimeRequest = original ? { ...original } : {};

        const r = {
          type: 'out',
          caregiverId: caregiver.id,
          targetId: targetShift.id,
          mode: caregiver.casePref.transportType,
          from: targetShift.id,
          to: outShift.id,
          status: null,
          departureTime: targetShift.end,
        };
        this.travelTimeRequests.push(r);
        travelTimeRequest.out = r;
      }
    }

    if (home) {
      if (!(original && original.home && original.home.arrivalTime.isSame(targetShift.start))) {
        travelTimeRequest = original ? { ...original } : {};

        const r = {
          type: 'home',
          caregiverId: caregiver.id,
          targetId: targetShift.id,
          mode: caregiver.casePref.transportType,
          status: null,
          to: targetShift.id,
          arrivalTime: targetShift.start,
        };
        this.travelTimeRequests.push(r);
        travelTimeRequest.home = r;
      }
    }

    return travelTimeRequest;
  }

  caregiverFilter(caregiver, bypass = false) {
    let filterStatus = true;
    const weeklyMinutes = {};
    const getWeeklyContainer = shift => {
      const weekName = WeeklyMinutes.weekName(shift.start, this.weekStartDay);
      if (!weeklyMinutes[weekName]) {
        weeklyMinutes[weekName] = new WeeklyMinutes();
      }
      return weeklyMinutes[weekName];
    };

    const travelTimesRequests = caregiver.travelTimesRequests ? caregiver.travelTimesRequests : {};

    const assigned = caregiver.shifts ? caregiver.shifts.filter(s => s.status === 'assigned') : [];

    const isInvited =
      caregiver.shifts && this.shiftList
        ? this.shiftList.filter(s => caregiver.shifts.find(cs => cs.id === s.id)).length > 0
        : false;

    const isBypassed = bypass || isInvited;

    if (caregiver.filterFailure === null) {
      Object.assign(caregiver, { filterFailure: [] });
    }

    // add offered to weekly containers
    if (this.shiftList) {
      this.shiftList.forEach(i => {
        getWeeklyContainer(i).addOffered(i);
      });

      assigned.forEach(s => {
        // calculate weekly hours
        getWeeklyContainer(s).addAssigned(s);

        // check agains shiftlist
        this.shiftList.forEach(i => {
          // check conflicts
          if (s.id !== i.id) {
            let marginIn = CaregiverFilter.DefaultMarginIn;
            let marginOut = CaregiverFilter.DefaultMarginOut;
            if (caregiver.travelTimesRequests && caregiver.travelTimesRequests[i.id]) {
              const ttr = caregiver.travelTimesRequests[i.id];

              if (ttr.home && ttr.home.status === 'OK') {
                marginIn = ttr.home.travelTime + CaregiverFilter.Margin;
              }
              if (ttr.in && ttr.in.status === 'OK') {
                marginIn = ttr.in.travelTime + CaregiverFilter.Margin;
              }
              if (ttr.out && ttr.out.status === 'OK') {
                marginOut = ttr.out.travelTime + CaregiverFilter.Margin;
              }
            }

            const sWithMargin = moment(i.start).subtract(marginIn, 'seconds');
            const eWithMargin = moment(i.end).add(marginOut, 'seconds');

            if (
              s.start.isBetween(sWithMargin, eWithMargin, null, '[]') ||
              s.end.isBetween(sWithMargin, eWithMargin, null, '[]') ||
              sWithMargin.isBetween(s.start, s.end, null, '[]') ||
              eWithMargin.isBetween(s.start, s.end, null, '[]')
            ) {
              if (this.options.conflict) {
                filterStatus = isBypassed;
              }
              caregiver.filterFailure.push({ name: 'conflict', failures: [] });
            }
          }
        });
      });
    }

    if (filterStatus && caregiver.casePref && caregiver.casePref.lastModified && this.shiftList) {
      this.shiftList.forEach(i => {
        if (filterStatus && !CaregiverFilter.casePrefFilter(caregiver.casePref, i)) {
          filterStatus = isBypassed;
          caregiver.filterFailure.push({ name: 'unavailable', failures: [] });
        }
      });
    }
    if (this.options.extra_cost && this.scores && this.shiftList) {
      const costObj = costForCaregiver(caregiver, this.shiftList, this.scores);
      if (costObj && costObj.cost && costObj.cost.extra_costs > 0) {
        filterStatus = isBypassed;
        caregiver.filterFailure.push({ name: 'extra_cost', failures: [] });
      }
    }
    if (this.scores && this.shiftList && this.shiftList.length && this.shiftList[0].id) {
      const costObj = costForCaregiver(caregiver, this.shiftList, this.scores);
      if (costObj.cost === undefined || costObj.cost === null) {
        if (!costObj.error) {
          filterStatus = isBypassed;
        }
        caregiver.filterFailure.push({ name: 'extra_cost_not_available', failures: [] });
      }
    }

    if (filterStatus && this.shiftList) {
      let maxTravelTime = null;
      const checkTravelTime = tt => {
        if (tt.in && tt.in.status === 'OK') {
          maxTravelTime = maxTravelTime
            ? Math.max(maxTravelTime, tt.in.travelTime)
            : tt.in.travelTime;
        }
        if (tt.home && tt.home.status === 'OK') {
          maxTravelTime = maxTravelTime
            ? Math.max(maxTravelTime, tt.home.travelTime)
            : tt.home.travelTime;
        }
      };

      // iterate all target shifts and populate travel times
      this.shiftList.forEach(shift => {
        // get assigned shifts within same day as target shift
        const assignedForSameDay = assigned.filter(
          a => a.start.isSame(shift.start, 'day') || a.end.isSame(shift.start, 'day')
        );

        // get assigned shift, ending before target shift
        const shiftBefore =
          assignedForSameDay && assignedForSameDay.length
            ? assignedForSameDay
                .sort((a, b) => b.end.diff(a.end)) // sort by end time, DESC
                .find(a => a.end.isBefore(shift.start)) // find first match
            : undefined;

        // get assigned shift, starting after target shift
        const shiftAfter =
          assignedForSameDay && assignedForSameDay.length
            ? assignedForSameDay
                .sort((a, b) => a.start.diff(b.start)) // sort by start time, ASC
                .find(a => a.start.isAfter(shift.end)) // find first match
            : undefined;

        if (shiftBefore) {
          // caregiver has assigned shift before target, get travel time from assigned shift to target
          travelTimesRequests[shift.id] = this.updateTraveltimeRequest(
            travelTimesRequests[shift.id],
            caregiver,
            shift,
            shiftBefore,
            null,
            false
          );
        } else {
          // get travel time from home to target
          travelTimesRequests[shift.id] = this.updateTraveltimeRequest(
            travelTimesRequests[shift.id],
            caregiver,
            shift,
            null,
            null,
            true
          );
        }

        if (shiftAfter) {
          // caregiver has assigned shift after target, get travel time from target to assigned shift (used by conflict filter)
          travelTimesRequests[shift.id] = this.updateTraveltimeRequest(
            travelTimesRequests[shift.id],
            caregiver,
            shift,
            null,
            shiftAfter,
            false
          );
        }

        checkTravelTime(travelTimesRequests[shift.id]);
      });

      // eslint-disable-next-line no-param-reassign
      caregiver.maxTravelTime = maxTravelTime;

      // eslint-disable-next-line no-param-reassign
      caregiver.travelTimesRequests = travelTimesRequests;
    }

    // set traveltime conflicts if needed
    if (
      caregiver.maxTravelTime &&
      caregiver.casePref &&
      caregiver.casePref.lastModified &&
      caregiver.casePref.travelUpToMinutes
    ) {
      if (caregiver.maxTravelTime / 60.0 > caregiver.casePref.travelUpToMinutes) {
        filterStatus = isBypassed;
        caregiver.filterFailure.push({ name: 'travelTime', failures: [] });
      }
    }

    // check is caregiver compliant
    if (caregiver.isCompliant === false) {
      caregiver.filterFailure.push({ name: 'nonCompliant', failures: [] });
    }

    // eslint-disable-next-line no-param-reassign
    caregiver.weeklyHours = moment
      .duration(
        Math.max(
          ...Object.values(weeklyMinutes)
            .filter(w => w.hasOfferedShifts())
            .map(w => w.assigned)
        ),
        'minutes'
      )
      .asHours();
    return filterStatus;
  }

  static casePrefFilter(casePref, shift) {
    const { end, start } = shift;

    /*
    const [psh, psm] = casePref.preferredHoursStart.split(':').map(v => parseInt(v, 10));
    if (psh > start.hour() || (psh === start.hour() && psm > start.minutes())) {
      return false;
    }

    const [peh, pem] = casePref.preferredHoursEnd.split(':').map(v => parseInt(v, 10));
    if (peh < end.hour() || (peh === end.hour() && pem < end.minutes())) {
      return false;
    }

    if (casePref.daysToWork && casePref.daysToWork.indexOf(start.weekday()) < 0) {
      return false;
    } */

    const blockRangeMatch =
      casePref.blockRanges &&
      casePref.blockRanges.find(b => {
        const [bs, be] = b.split('/').map(i => moment(i));
        bs.hours(0).minutes(0);
        be.hours(24).minutes(0);
        return start.isBetween(bs, be) || end.isBetween(bs, be);
      });

    if (blockRangeMatch) {
      return false;
    }

    return true;
  }

  prepareCaregiverList(bypass = false) {
    const caregivers = this.caregivers.filter(c => this.caregiverFilter(c, bypass));
    return {
      caregivers,
      travelTimesRequests: this.travelTimeRequests,
      destination: this.destination,
    };
  }
}

const prepareCaregiverList = (
  dispatch,
  caregivers,
  shiftList,
  caregiverScores,
  destination,
  advancedFilters,
  showFiltered,
  weekStartDay
) => {
  const options = advancedFilters
    ? advancedFilters.reduce((a, b) => ({ ...a, [b]: true }), {})
    : {};

  const caregiverFilter = new CaregiverFilter(
    dispatch,
    caregivers,
    shiftList,
    caregiverScores,
    destination,
    options,
    weekStartDay
  );
  const results = caregiverFilter.prepareCaregiverList(showFiltered);

  if (
    results.travelTimesRequests.filter(r => r.type === 'home').length > 0 &&
    results.destination
  ) {
    // Destination needed if there are no shift ids but there's home type in the request (location search)
    dispatch(travelTimeActions.travelTimeRequest(results.travelTimesRequests, results.destination));
  } else if (results.travelTimesRequests.filter(r => r.from || r.to || r.targetId).length > 0) {
    // No destination needed if there are shift ids in the request (shift search)
    dispatch(travelTimeActions.travelTimeRequest(results.travelTimesRequests, results.destination));
  }
  return results.caregivers;
};

export const caregiverHelpers = {
  prepareCaregiverList,
  costForCaregiver,
  costKey,
  costsForCaregivers,
  scoresForCaregivers,
  logMetasForCaregiverCost,
};

export default caregiverHelpers;

/* eslint-disable import/prefer-default-export */
import * as types from '../constants/ActionTypes';
import { backend } from '../services';
// eslint-disable-next-line import/no-cycle
import { alertActions } from './alert';
import { uiStateActions } from './uiState';

import { prepareCaregiverObject, prepareShiftObject } from '../reducers/helpers';
// eslint-disable-next-line import/no-cycle
import { caregiverActions } from './caregiver';

function shiftNotification(shiftId, reason, caregiverId, schedulerId, state) {
  return (dispatch, getState) => {
    backend.listSchedules({ withIds: [shiftId] }).then(results => {
      dispatch(success(results));
      notificationAlert(dispatch, results, getState());
    });
  };

  function success(results) {
    return { type: types.NOTIFICATION_SHIFT_UPDATED, results: results.results };
  }

  function notificationAlert(dispatch, results) {
    const employeeId = state.employee.employee ? state.employee.employee.id : null;
    const userId = state.principal.user ? state.principal.user.id : null;

    const { settings } = state.principal;

    if (reason !== 'assigned') {
      results.results.forEach(s => {
        const primaryCaregivers = s.assignedCaregivers
          ? !!s.assignedCaregivers.find(i => i.primaryContactId === employeeId)
          : false;

        if (
          s.client.primaryContactId === employeeId ||
          primaryCaregivers ||
          !settings.shiftNotifyPrimaryContactOnly ||
          schedulerId === userId
        ) {
          const caregiver = s.assignedCaregivers.find(
            i => i.id === caregiverId && i.shiftAssigmentStatus === reason
          );

          if (caregiver) {
            dispatch(
              alertActions.shiftStatusUpdated(
                prepareShiftObject({ ...s }),
                prepareCaregiverObject({ ...caregiver }),
                reason
              )
            );
          }
        }
      });
    }
  }
}

function listSchedules(params, filter, partialUpdate = false) {
  return dispatch => {
    if (filter) {
      dispatch(uiStateActions.setInputGroupState('shiftFilter', filter));
    }
    dispatch(request());
    backend.listSchedules(params).then(
      results => {
        dispatch(success(results));
      },
      error => {
        if (error.statusCode === 413) {
          dispatch(success(null, true));
          return;
        }

        dispatch(failure(error.message));
        dispatch(alertActions.error(error, alertActions.SEARCH_ERROR_TYPE));
      }
    );
  };

  function request() {
    return { type: types.LIST_SCHEDULES_REQUEST, partialUpdate };
  }
  function success(results, tooManyResults) {
    return { type: types.LIST_SCHEDULES_SUCCESS, results, tooManyResults, partialUpdate };
  }
  function failure(error) {
    return { type: types.LIST_SCHEDULES_FAILURE, error, partialUpdate };
  }
}

function resetSchedulesSearch(filter) {
  return dispatch => {
    if (filter) {
      dispatch(uiStateActions.setInputGroupState('shiftFilter', filter));
    }
    dispatch({ type: types.RESET_SCHEDULES_SEARCH });
  };
}

function listShiftsByCaregiver(caregiverId, yearMonth) {
  return dispatch => {
    dispatch(request());
    backend.getShiftsByCaregiver(caregiverId, yearMonth).then(
      results => {
        dispatch(success(results, yearMonth));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.SHIFT_BY_CAREGIVER_REQUEST };
  }
  function success(results, yearMonthValue) {
    return { type: types.SHIFT_BY_CAREGIVER_SUCCESS, results, yearMonthValue };
  }
  function failure(error) {
    return { type: types.SHIFT_BY_CAREGIVER_FAILURE, error };
  }
}

function getShift(shiftId) {
  return dispatch => {
    dispatch(request());
    backend.getShift(shiftId).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        if (error.statusCode === 404) {
          dispatch(alertActions.error('No shifts found with given ID'));
        } else {
          dispatch(alertActions.error(error));
        }
      }
    );
  };

  function request() {
    return { type: types.GET_SHIFT_REQUEST };
  }
  function success(results) {
    return { type: types.GET_SHIFT_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_SHIFT_FAILURE, error };
  }
}

function createShifts(shifts) {
  return dispatch => {
    dispatch(request());
    backend.createShifts(shifts).then(
      results => {
        dispatch(success(results));
        dispatch(alertActions.notification('Shift creation successful'));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.CREATE_SHIFTS_REQUEST };
  }
  function success(results) {
    return { type: types.CREATE_SHIFTS_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.CREATE_SHIFTS_FAILURE, error };
  }
}

function updateShift(shift) {
  return dispatch => {
    dispatch(request());
    backend.updateShift(shift).then(
      results => {
        dispatch(success(results));
        dispatch(alertActions.notification('Shift has been updated'));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(
          alertActions.error(
            (error.json.details && error.json.details.end) ||
              (error.json.details && error.json.details.start) ||
              error
          )
        );
      }
    );
  };

  function request() {
    return { type: types.UPDATE_SHIFT_REQUEST };
  }
  function success(results) {
    return { type: types.UPDATE_SHIFT_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.UPDATE_SHIFT_FAILURE, error };
  }
}

function updateShiftWithListCaregivers(shiftParams, shiftList) {
  return dispatch => {
    dispatch(request());
    Promise.all(
      shiftParams.map(s =>
        backend.updateShift(s).then(
          results => {
            dispatch(success(results));
            dispatch(alertActions.notification('Shift has been updated'));
          },
          error => {
            dispatch(failure(error.message));
            dispatch(
              alertActions.errorNotification(
                (error.json.details && error.json.details.end) ||
                  (error.json.details && error.json.details.start) ||
                  error
              )
            );
          }
        )
      )
    ).then(() => {
      dispatch(caregiverActions.listCaregiversByShifts(shiftList));
    });
  };

  function request() {
    return { type: types.UPDATE_SHIFT_WITH_LIST_CAREGIVERS_REQUEST };
  }
  function success(results) {
    return { type: types.UPDATE_SHIFT_WITH_LIST_CAREGIVERS_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.UPDATE_SHIFT_WITH_LIST_CAREGIVERS_FAILURE, error };
  }
}

function clickShiftCheckbox(shift) {
  return dispatch => {
    dispatch({
      type: types.SCHEDULE_CB_CLICKED,
      shiftId: shift.id,
      clientId: shift.clientId,
      service: shift.service,
    });
  };
}

function selectAllClicked() {
  return dispatch => {
    dispatch({ type: types.SCHEDULE_CB_ALL_CLICKED });
  };
}

export const schedulerActions = {
  listSchedules,
  resetSchedulesSearch,
  listShiftsByCaregiver,
  getShift,
  createShifts,
  updateShift,
  updateShiftWithListCaregivers,
  clickShiftCheckbox,
  selectAllClicked,
  shiftNotification,
};

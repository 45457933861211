const common = {
  LOCAL_STORAGE_KEY: 'gotham-care',
  APP_URL: 'http://localhost:3001',
  API_GW: 'http://localhost:3000',
  API_GW_KEY: 'd41d8cd98f00b204e9800998ecf8427e',
  MIXPANEL_API_TOKEN: undefined,
  APPLINK_DOMAIN: 'https://ccapp.test-app.link',
};

const env = {
  local: {
    LOCAL_STORAGE_KEY: 'gotham-care',
    APP_URL: 'http://localhost:3001',
    API_GW: 'http://localhost:3000',
    API_APPLICANTS_GW: 'http://localhost:3003',
    API_GW_KEY: 'd41d8cd98f00b204e9800998ecf8427e',
    MIXPANEL_API_TOKEN: undefined,
    OFFLINE_DETECTOR_POLLING_URL: 'http://localhost:3000/connectiontest',
  },
  sbox: {
    APP_URL: 'https://scheduler-sbox.gothamcare.com',
    API_GW: 'https://api-sbox.gothamcare.com',
    API_GW_KEY: 'FiCwUyPjYZ9nynG7voVL37vAYmdNBYtB1nuyAhYV',
    MIXPANEL_API_TOKEN: undefined,
    OFFLINE_DETECTOR_POLLING_URL: 'https://api-sbox.gothamcare.com/connectiontest',
  },
  dev: {
    APP_URL: 'https://scheduler-dev.gothamcare.com',
    API_GW: 'https://api-dev.gothamcare.com',
    API_APPLICANTS_GW: 'https://yxsfm0ni85.execute-api.eu-west-1.amazonaws.com/dev',
    API_GW_KEY: 'ZiVZuoTAKd2Jn6PHB5DID7WKAv5kAabFaOkacdxO',
    MIXPANEL_API_TOKEN: 'd61e7ccd6e2ffb235b82b1062a4fc9b0',
    OFFLINE_DETECTOR_POLLING_URL: 'https://api-dev.gothamcare.com/connectiontest',
  },
  devnew: {
    APP_URL: 'https://d3migl0flj23ze.cloudfront.net',
    API_GW: 'https://s0ca62glze.execute-api.eu-west-1.amazonaws.com/dev',
    API_APPLICANTS_GW: 'https://9389pkgmxc.execute-api.eu-west-1.amazonaws.com/dev',
    API_GW_KEY: 'ln4ZNBTrxV6sNV3wGABgsZCBxQbD2Sn4n9cGLeLj',
    MIXPANEL_API_TOKEN: 'd61e7ccd6e2ffb235b82b1062a4fc9b0',
    OFFLINE_DETECTOR_POLLING_URL:
      'https://s0ca62glze.execute-api.eu-west-1.amazonaws.com/dev/connectiontest',
  },
  prod: {
    LOCAL_STORAGE_KEY: 'careconnect',
    APP_URL: 'https://app.careconnectmobile.com',
    API_GW: 'https://api.careconnectmobile.com',
    API_APPLICANTS_GW: 'https://yk1xydgfu8.execute-api.us-east-1.amazonaws.com/prod',
    API_GW_KEY: 'uUzZtqyqTW6nlvvKucotP8z3YwQyaBUH4rF5P3xs',
    MIXPANEL_API_TOKEN: 'b0f91e8b42ceba9c12d25e6d315f638b',
    APPLINK_DOMAIN: 'https://ccapp.app.link',
    OFFLINE_DETECTOR_POLLING_URL: 'https://api.careconnectmobile.com/connectiontest',
  },
};

export const config = env[process.env.REACT_APP_STAGE] || {};

export default {
  ...common,
  ...config,
};

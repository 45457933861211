import React, { ReactElement, SyntheticEvent } from 'react';
import { TextArea } from 'semantic-ui-react';
import styled from 'styled-components';

interface Props {
  notes: string;
  handleNotesChange: (_event: SyntheticEvent, data: INotesProps) => void;
}

interface INotesProps {
  value: string;
}

const Notes = styled(TextArea)`
  &&& {
    color: #4a4a4a;
    max-width: 760px;
    min-width: 760px;
    max-height: 300px;
    min-height: 300px;
    font-family: Lato;
    font-weight: bold;
    padding: 1rem;
    resize: none;
  }
`;

function HRApplicantNotes({ notes, handleNotesChange }: Props): ReactElement {
  return (
    <Notes
      name='notes'
      value={notes}
      maxLength={500}
      onChange={handleNotesChange}
      placeHolder='Add additional notes that may be relevant in the screening and pre-hire phases:'
    />
  );
}

export default HRApplicantNotes;

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import WizardView from './WizardView';
import CareEditorTabs from './CareEditorTabs';

class WizardModal extends React.Component {
  constructor() {
    super();
    this.changeForm = this.changeForm.bind(this);
    this.next = this.next.bind(this);
    this.back = this.back.bind(this);
  }

  currentIndex() {
    if (this.wizardView) return this.wizardView.currentIndex();
    return 0;
  }

  next() {
    this.wizardView.next();
  }

  back() {
    this.wizardView.back();
  }

  changeForm(index) {
    this.wizardView.changeForm(index);
  }

  viewChanged = () => {
    this.setState({});
    this.props.onViewChanged();
  };

  render() {
    const index = this.currentIndex();
    const footer = this.props.renderFooter(index);

    return (
      <Modal
        closeOnDimmerClick={false}
        open
        onClose={this.props.onClose}
        closeIcon
        dimmer
        className={this.props.class}
        style={{ marginTop: '-45vh' }}
      >
        <Modal.Header>
          <div style={{ textAlign: 'center', zIndex: '1!important' }}>
            <div style={{ display: 'inline-block', zIndex: '1!important' }}>
              {this.props.renderHeader(index)}
              {this.props.headerLabels && (
                <CareEditorTabs labels={this.props.headerLabels} activeTab={index} />
              )}
            </div>
          </div>
        </Modal.Header>
        <Modal.Content
          scrolling
          style={{ height: `${this.props.contentHeight + 60}px`, overflowX: 'hidden' }}
        >
          <div
            style={{
              paddingTop: '16px',
              paddingBottom: '16px',
              height: `${this.props.contentHeight}px`,
            }}
          >
            <WizardView
              renderContent={this.props.renderContent}
              onViewChanged={this.viewChanged}
              ref={input => {
                this.wizardView = input;
              }}
            />
          </div>
        </Modal.Content>
        {footer && <Modal.Actions>{footer}</Modal.Actions>}
      </Modal>
    );
  }
}

WizardModal.defaultProps = {
  renderFooter: () => null,
  headerLabels: null,
  contentHeight: 440,
  class: '',
  onViewChanged: () => {},
};

WizardModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  renderHeader: PropTypes.func.isRequired,
  renderContent: PropTypes.func.isRequired,
  renderFooter: PropTypes.func,
  headerLabels: PropTypes.arrayOf(PropTypes.shape),
  contentHeight: PropTypes.number,
  class: PropTypes.string,
  onViewChanged: PropTypes.func,
};

export default WizardModal;

import { Grid, Form, Header, Button } from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import AddressList from './AddressList';
import LocationButton from './LocationButton';
import states from '../../constants/States';
import { beginningSearch } from '../../constants/Formats';

const Address = ({
  title,
  dispatch,
  object,
  editable,
  showCoordinates,
  onValueChange,
  onViewMapClick,
  locationUpdate,
}) => {
  const stateOptions = states.states.map(x => ({ value: x.abbreviation, text: x.abbreviation }));

  return editable ? (
    <Grid.Row>
      <Grid.Column width={5}>
        <Header size='tiny'>{title}</Header>
        {showCoordinates && (
          <LocationButton
            address={object.address}
            dispatch={dispatch}
            locationUpdate={loc => {
              onValueChange({}, { name: 'address.lat', value: loc.lat });
              onValueChange({}, { name: 'address.lon', value: loc.lon });
              locationUpdate(loc);
            }}
          />
        )}
      </Grid.Column>
      <Grid.Column width={11} className='ui form'>
        <Form.Group>
          <Form.Input
            placeholder='Address'
            width={16}
            name='address.street'
            value={object.address.street}
            onChange={onValueChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            placeholder='Address 2'
            width={12}
            name='address.street2'
            value={object.address.street2}
            onChange={onValueChange}
          />
          <Form.Input
            placeholder='Apt #'
            width={4}
            name='address.apartment'
            value={object.address.apartment}
            onChange={onValueChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            placeholder='City'
            width={16}
            name='address.city'
            value={object.address.city}
            onChange={onValueChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            placeholder='County'
            width={16}
            name='address.county'
            value={object.address.county}
            onChange={onValueChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Select
            compact
            className='three wide'
            size='small'
            search={beginningSearch}
            scrolling
            selection
            upwards='true'
            placeholder='State'
            name='address.state'
            options={stateOptions}
            value={object.address.state}
            onChange={onValueChange}
          />
          <Form.Input
            fluid
            placeholder='Zip'
            width={8}
            name='address.zip'
            value={object.address.zip}
            onChange={onValueChange}
          />
          {onViewMapClick && (
            <Button
              type='button'
              className='five wide'
              style={{
                background: 'none',
                color: '#04A5D5',
              }}
              onClick={onViewMapClick}
            >
              View Map
            </Button>
          )}
        </Form.Group>
        {showCoordinates && (
          <Form.Group>
            <Form.Input
              placeholder='Latitude'
              width={8}
              name='address.lat'
              value={object.address.lat}
              onChange={(e, input) => {
                onValueChange(e, { name: input.name, value: Number.parseFloat(input.value) });
              }}
            />
            <Form.Input
              placeholder='Longitude'
              width={8}
              name='address.lon'
              value={object.address.lon}
              onChange={(e, input) => {
                onValueChange(e, { name: input.name, value: Number.parseFloat(input.value) });
              }}
            />
          </Form.Group>
        )}
        <div>Timezone: {object.address.timezone || 'Undefined'}</div>{' '}
      </Grid.Column>
    </Grid.Row>
  ) : (
    <Grid.Row>
      <Grid.Column width={5}>
        <Header size='tiny'>Address:</Header>
      </Grid.Column>
      <Grid.Column width={11}>
        <Header size='tiny'>
          <AddressList address={object.address} />
        </Header>
      </Grid.Column>
    </Grid.Row>
  );
};

Address.defaultProps = {
  showCoordinates: false,
  locationUpdate: () => {},
  title: 'Address:',
  onViewMapClick: null,
};

Address.propTypes = {
  dispatch: PropTypes.func.isRequired,
  object: PropTypes.shape().isRequired,
  editable: PropTypes.bool.isRequired,
  showCoordinates: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
  locationUpdate: PropTypes.func,
  title: PropTypes.string,
  onViewMapClick: PropTypes.func,
};

export default Address;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PrimaryContacts from './subcomponents/PrimaryContacts';
import CaregiverBox from './subcomponents/CaregiverBox';
import './ClientAgencyTab.css';

const ClientAgencyTab = ({ client }) => {
  const exclusions = client.caregiverExclusion
    ? client.caregiverExclusion.map(t => (
        <div className=''>
          <p>{t.caregiverName}</p>
        </div>
      ))
    : [];

  const history = client.recentCaregivers
    ? client.recentCaregivers
        .slice(0, 4)
        .map(cg => <CaregiverBox caregiver={cg} showRemove={false} />)
    : [];

  const activationDate = moment(client.createdDate).format('MM/DD/YY');

  return (
    <div className='agency-tab-container'>
      <div className='agency-tab-primary-contact'>
        <PrimaryContacts object={client} />
      </div>

      <h4 className='tiny-header date-header'>client activation date:</h4>
      <p className='agency-tab-text date'>{activationDate}</p>
      <hr className='agency-tab-divider divider-date' />

      <h4 className='tiny-header exclusion-header'>CaregiverExclusions:</h4>
      <p className='agency-tab-text exclusions'>{exclusions}</p>
      <hr className='agency-tab-divider divider-exclusion' />

      <h4 className='big-header'>CAREGIVER HISTORY</h4>
      <p className='history-text'>The most recent caregivers assigned to this client</p>
      <div className='caregiverHistory'>
        {history}
        {history.length === 0 && <span>--</span>}
      </div>
    </div>
  );
};

ClientAgencyTab.propTypes = {
  client: PropTypes.shape().isRequired,
};

export default ClientAgencyTab;

import {
  GET_CLIENT_SUCCESS,
  GET_CLIENT_REQUEST,
  GET_CLIENT_FAILURE,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_FAILURE,
} from '../constants/ActionTypes';

import { prepareClientObject } from './helpers';

const initialState = {
  loading: false,
  client: null,
};

function clientDetails(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CLIENT_REQUEST:
    case GET_CLIENT_REQUEST:
      return { ...state, loading: true, client: null };
    case UPDATE_CLIENT_SUCCESS:
    case GET_CLIENT_SUCCESS: {
      const client = prepareClientObject(action.results.client);
      return { ...state, loading: false, client };
    }
    case UPDATE_CLIENT_FAILURE:
    case GET_CLIENT_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export default clientDetails;

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, Header } from 'semantic-ui-react';
import { RecordsTile, RecordsHeader } from '../../styles/records';
import './ReportingSettings.css';

let changedSettings = {};

class ReportingSettings extends React.Component {
  constructor(props) {
    super(props);

    this.onValueChanged.bind(this);
  }

  onValueChanged = (name, value) => {
    changedSettings[name] = true;
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onValueUpdated(name, value);
  };

  render() {
    const { dirty, billingReportOn } = this.props;
    const reportingBody = 'You can toggle monthly billing report ON/OFF for this agency';

    if (!dirty) changedSettings = {};

    return (
      <RecordsTile className='reporting-setting-tile'>
        <Form>
          <Grid padded className='col-trainings-preferences'>
            <Grid.Row>
              <Grid.Column width={16}>
                <RecordsHeader>Reporting Settings</RecordsHeader>
              </Grid.Column>
            </Grid.Row>
            <hr className='reporting-hr' />

            <Grid.Row>
              <Grid.Column width={16}>
                <p className='reporting-body-text'>{reportingBody}</p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row
              className={
                dirty && changedSettings.billingReportOn
                  ? 'table-item-updated default-reporting-row'
                  : 'default-reporting-row'
              }
            >
              <Grid.Column>
                <Form.Group inline className='reporting-form-group-container'>
                  <Header size='tiny' className='reporting-body-text'>
                    Monthly billing report:
                  </Header>
                  <Form.Checkbox
                    toggle
                    name='billingReportOn'
                    defaultChecked={billingReportOn}
                    onChange={(_, { name, checked }) => this.onValueChanged(name, checked)}
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </RecordsTile>
    );
  }
}

ReportingSettings.defaultProps = {
  dirty: false,
  billingReportOn: false,
};

ReportingSettings.propTypes = {
  onValueUpdated: PropTypes.func.isRequired,
  billingReportOn: PropTypes.bool,
  dirty: PropTypes.bool,
};

export default ReportingSettings;

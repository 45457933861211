/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Grid, Button, Form, Image, Label, Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CareTimePicker from './subcomponents/CareTimePicker';
import DaveBox from './subcomponents/DaveBox';
import { formatTime, SHORT_DATE } from '../constants/Formats';
import { UPDATE_SHIFT_TIMING } from '../constants/ActionTypes';
import { avatarStyle, avatarUrl } from '../constants/DomainTypes';
import './MultiShiftBar.css';
import Overlay from './subcomponents/Overlay';
import { uiStateActions, schedulerActions, caregiverActions } from '../actions';
import { analytics, Events } from '../helpers/analytics';
import { caregiverScoresActions } from '../actions/caregiverScores';

const formatShiftHours = shiftHours => {
  const hours = Math.trunc(shiftHours);
  const minutes = Math.trunc((shiftHours * 60) % 60);

  const hoursText = hours > 0 ? `${hours}hr` : '';
  const coma = hours > 0 && minutes > 0 ? ',' : '';
  const minutesText = minutes > 0 ? `${minutes}min` : '';

  return `${hoursText}${coma} ${minutesText}`;
};

class MultiShiftBar extends React.Component {
  constructor() {
    super();

    // this.handleDetailsClick = this.handleDetailsClick.bind(this);
    this.handleDetailsClose = this.handleDetailsClose.bind(this);
    this.timingPickerChange = this.timingPickerChange.bind(this);
    this.onSaveEdits = this.onSaveEdits.bind(this);
    // eslint-disable-next-line react/no-unused-state
    this.state = { clientDetailId: null };
  }

  onSaveEdits() {
    const { shiftTiming, shiftList } = this.props;

    const shiftparams = Object.keys(shiftTiming)
      .filter(id => shiftTiming[id].isChanged)
      .map(id => {
        const timing = shiftTiming[id];
        const params = {
          id,
          start: timing.start.toISOString(),
          end: timing.end.toISOString(),
        };
        return params;
      });

    this.props.dispatch(schedulerActions.updateShiftWithListCaregivers(shiftparams, shiftList));

    this.props.dispatch(caregiverScoresActions.resetScores());
  }

  timingPickerChange = (shift, stateName) => e => {
    const action = {
      type: UPDATE_SHIFT_TIMING,
      shiftId: shift.id,
      [stateName]: e,
      minutes: shift.minutes,
    };

    this.props.dispatch(action);
  };

  handleDetailsClose = x => {
    if (x && x.wasClientChanged === true && this.props.shiftList) {
      const { shiftList } = this.props;
      const shiftIdList = shiftList.map(shift => shift.id);
      this.props.dispatch(caregiverActions.setTargetShifts(shiftIdList));
      analytics.track(Events.SHIFTS_SEARCH_CAREGIVERS);
      if (this.props.onClientUpdate) {
        this.props.onClientUpdate();
      }
    }
  };

  render() {
    const { shiftList, uiState, shiftTiming } = this.props;
    const shift = shiftList && shiftList.length > 0 ? shiftList[0] : null;

    const hasEdits = !!Object.values(shiftTiming).find(t => t.isChanged);

    const dateRows = shiftList
      ? shiftList.map(s => (
          <Grid.Row key={`srow_${s.id}`} className='value' verticalAlign='middle'>
            <Grid.Column verticalAlign='middle'>
              {formatTime(s.start, s.timezone, SHORT_DATE)}
            </Grid.Column>
          </Grid.Row>
        ))
      : [];

    const hoursRows = shiftList
      ? shiftList.map(s => (
          <Grid.Row key={`srow_${s.id}`} className='value' verticalAlign='middle'>
            <Grid.Column verticalAlign='middle' textAlign='center'>
              {formatShiftHours(s.hours)}
            </Grid.Column>
          </Grid.Row>
        ))
      : [];

    const shiftRows = shiftList
      ? shiftList.map(s => {
          const disabled =
            s.shiftAssigmentStatus === 'assigned' || s.shiftAssigmentStatus === 'cancelled';
          const assigned = s.shiftAssigmentStatus === 'assigned';
          const timing = shiftTiming[s.id];
          const selected = uiState.selectedShifts.indexOf(s.id) >= 0;

          const row = (
            <Grid.Row key={`srow_${s.id}`} columns='equal'>
              <Grid.Column width='6' verticalAlign='middle'>
                <Form>
                  <CareTimePicker
                    disabled={disabled || !timing || assigned}
                    value={timing ? timing.start : shift.start}
                    timezone={shift.timezone}
                    onChange={this.timingPickerChange(s, 'start')}
                  />
                </Form>
              </Grid.Column>
              <Grid.Column width='6' verticalAlign='middle'>
                <Form>
                  <CareTimePicker
                    disabled={disabled || !timing || assigned}
                    value={timing ? timing.end : shift.end}
                    timezone={shift.timezone}
                    onChange={this.timingPickerChange(s, 'end')}
                  />
                </Form>
              </Grid.Column>
              <Grid.Column width='2' verticalAlign='middle' className='selector' textAlign='center'>
                {assigned && 'Assigned'}
                {!assigned && (
                  <DaveBox
                    value={s.id}
                    name='selectedShifts'
                    checked={selected}
                    disabled={disabled || (selected && uiState.selectedShifts.length <= 1)}
                    onChange={uiStateActions.inputChangeHandler(
                      this.props.dispatch,
                      'multiShiftSelector'
                    )}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          );
          return row;
        })
      : [];

    return (
      <div className='wrapper' style={{ width: '100%', marginRight: '25px' }}>
        <Container fluid className='multiShiftBar'>
          <Label className='cell header' size='small' pointing='right'>
            SHIFT
            <br />
            DETAILS
          </Label>
          <div className='cell avatar'>
            <Image
              avatar
              circular
              size='mini'
              style={avatarStyle(false, shift ? shift.client.gender : '')}
              src={shift ? avatarUrl(false, shift.client.gender) : ''}
            />
          </div>
          <div className='cell divided client'>
            <Grid>
              <Grid.Column>
                <Grid.Row>
                  <Grid.Column>
                    <label htmlFor='clientName'>Client</label>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className='clientName' verticalAlign='middle'>
                  <Grid.Column>
                    {shift && shift.client && (
                      <Overlay client={shift.client} onCloseClicked={this.handleDetailsClose} />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
            </Grid>
          </div>
          <div className='cell divided service'>
            <Grid>
              <Grid.Column>
                <Grid.Row>
                  <Grid.Column>
                    <label htmlFor='serviceName'>Service</label>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className='value'>
                  <Grid.Column>
                    <div>{shift ? shift.service : ''}</div>
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
            </Grid>
          </div>
          <div className='cell divided dates'>
            <Grid>
              <Grid.Column>
                <Grid.Row>
                  <Grid.Column>
                    <label htmlFor='date'>Date</label>
                  </Grid.Column>
                </Grid.Row>
                {dateRows}
              </Grid.Column>
            </Grid>
          </div>
          <div className='cell divided hours'>
            <Grid>
              <Grid.Column>
                <Grid.Row>
                  <Grid.Column>
                    <label htmlFor='hours'>Hours</label>
                  </Grid.Column>
                </Grid.Row>
                {hoursRows}
              </Grid.Column>
            </Grid>
          </div>
          <div className='cell divided innerGrid shifts'>
            <Grid>
              <Grid.Row>
                <Grid.Column width='6'>
                  <label htmlFor='startTime'>Start Time</label>
                </Grid.Column>
                <Grid.Column width='6'>
                  <label htmlFor='endTime'>End Time</label>
                </Grid.Column>
                <Grid.Column width='2' />
              </Grid.Row>
              {shiftRows}
            </Grid>
          </div>
          <div className='cell saveButton'>
            <Button
              id='multi-shift-bar-save-changes-button'
              size='mini'
              disabled={!hasEdits}
              className='care-blue save'
              onClick={this.onSaveEdits}
            >
              Save Changes
            </Button>
          </div>
        </Container>
        <div className='floatTopRight'>
          <Image
            id='multi-shift-bar-close-button'
            className='tinyCloseButton'
            src='/close_round.svg'
            onClick={this.props.onReset}
          />
        </div>
      </div>
    );
  }
}

MultiShiftBar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  shiftTiming: PropTypes.shape().isRequired,
  shiftList: PropTypes.shape().isRequired,
  uiState: PropTypes.shape().isRequired,
  onClientUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = props => {
  const { multiShiftSelector } = props;
  return {
    shiftList: multiShiftSelector.shiftList,
    shiftTiming: multiShiftSelector.timing,
  };
};

export default connect(
  uiStateActions.mapStateOfGroupToProps('multiShiftSelector', mapStateToProps)
)(MultiShiftBar);

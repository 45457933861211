const primary = {
  teal: '#04A5D5',
  green: '#69DB8F',
  orange: '#F5A623',
  red: '#F16778',
};

const secondary = {
  navy: '#364866',
  olive: '#417505',
  wine: '#631B24',
};

const additional = {
  electricBlue: '#2D59D6',
  blueNeon: '#29E7FD',
  grassGreen: '#21C75C',
  greenNeon: '#2BF9A5',
  ocean: ' #25334D',
  pink: '#FAD1D6',
};

const neutrals = {
  charcoal: '#4A4A4A',
  gray: '#9B9B9B',
  lightGray: '#C4C4C4',
  blueSteel: '#8494AE',
  steel: '#C8CBD3',
  aluminum: '#E3E3E3',
  cloud: '#F1F1F1',
  white: '#FFFFFF',
};

export default { primary, secondary, additional, neutrals };

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import { Grid, Header, Segment, Button, Icon, Image, Label, Dimmer } from 'semantic-ui-react';
import moment from 'moment';

import { formatTime, SHORT_DATE, MOMENT_HOURS } from '../constants/Formats';
import { dashboardActions, caregiverActions, schedulerActions } from '../actions';
import { getShiftAssigmentStatusColor, isAdmin } from '../constants/DomainTypes';
import { getShiftStatusDetails } from '../constants/StatusMaps';

import './Dashboard.css';
import DashboardMenu from './DashboardMenu';
import Announcements from './subcomponents/announcement/Announcements';
import Overlay from './subcomponents/Overlay';

import { history } from '../helpers';
import { analytics, Events } from '../helpers/analytics';
import NewUpdate from './NewUpdate';
import { SurveySummary } from './subcomponents/SurveyReport';
import CareLoader from './subcomponents/CareLoader';

const NoShiftsState = () => (
  <Grid.Row columns='one' className='emptyStateRow dashboardEmptyStateRow' centered>
    <Segment basic>
      <Header id='Dashboard-no-shift-state-header'>
        No unassigned schedules in the next 2 days
      </Header>
      <Image id='Dashboard-no-shift-state-image' src='/coffee.png' />
      <Header className='coffee'>Time for a coffee!</Header>
    </Segment>
  </Grid.Row>
);

const ShiftTodos = ({ statistic }) => (
  <Grid.Row className='subHeaderCols'>
    <Grid.Column width='8'>
      <Header id='Dashboard-shifts-to-do-header'>
        SHIFT TO-DO’S
        <Header.Subheader>
          <span>
            <Icon
              name='circle'
              id='Dashboard-unassigned-icon'
              className={getShiftAssigmentStatusColor('unassigned').iconClass}
            />
            Unassigned
            <Icon
              name='circle'
              id='Dashboard-on-hold-icon'
              className={getShiftAssigmentStatusColor('onhold').iconClass}
            />
            On Hold
          </span>
        </Header.Subheader>
      </Header>
    </Grid.Column>
    <Grid.Column width='8'>
      <Header id='Dashboard-caregivers-overview-header'>
        CAREGIVERS OVERVIEW
        <Header.Subheader id='Dashboard-caregivers-overview-sub-header'>
          Currently{' '}
          <span
            id='Dashboard-caregivers-overview-active-caregivers'
            style={{ color: '#4A4A4A', fontWeight: 'bold' }}
          >
            {statistic.active}
          </span>{' '}
          active caregivers in system
        </Header.Subheader>
      </Header>
    </Grid.Column>
  </Grid.Row>
);

ShiftTodos.propTypes = {
  statistic: PropTypes.shape().isRequired,
};

const ShiftList = ({
  shifts,
  range,
  dispatch,
  unassigned,
  onhold,
  showEmptyState,
  counts,
  employee,
}) => {
  const searchClick = (historyObj, shiftId) => {
    dispatch(caregiverActions.setTargetShifts([shiftId]));
    historyObj.history.push('/home/caregivers');
    analytics.track(onhold ? Events.DASHBOARD_SELECT_URGENT_SHIFT : Events.DASHBOARD_SELECT_SHIFT);
  };

  const viewAllClick = () => {
    const params = {
      primaryContactAsUserId: employee && employee.employee && employee.employee.userId,
      shiftStatus: 'pending',
      shiftCompinedStatus: ['unassigned'],
      dateStart: moment().toISOString(),
      dateEnd: moment()
        .add(1, 'years')
        .toISOString(),
    };
    const filter = {
      shiftStatus: 'pending',
      shiftCompinedStatus: ['unassigned'],
      startDate: moment(),
      endDate: moment().add(1, 'years'),
      timeRange: { min: 0, max: 24 },
      primaryContact: employee && employee.employee && employee.employee.id,
    };
    dispatch(schedulerActions.listSchedules(params, filter));
    history.push('/home/shifts');
    analytics.track(Events.DASHBOARD_SELECT_VIEW_ALL);
  };

  const render = shiftId => historyObj => (
    <Button
      id='Dashboard-shift-list-button'
      basic
      color='green'
      size='mini'
      onClick={() => searchClick(historyObj, shiftId)}
    >
      {onhold ? 'View' : 'Find'}
    </Button>
  );
  const shiftItems = shifts.map(s => {
    const className = onhold
      ? getShiftStatusDetails(s.status).className
      : getShiftAssigmentStatusColor(s.shiftAssigmentStatus).iconClass;

    return (
      <Grid.Row key={s.id} className='itemRow'>
        <Grid.Column width='6' verticalAlign='middle'>
          <Icon id='Dashboard-shift-list-icon' name='circle' className={className} />
          <Overlay client={s.client} />
        </Grid.Column>
        <Grid.Column width='3' verticalAlign='middle'>
          <span id='Dashboard-shift-list-date'>{formatTime(s.start, s.timezone, SHORT_DATE)}</span>
        </Grid.Column>
        <Grid.Column width='1' verticalAlign='middle'>
          <span className='date-time-divider'>at</span>
        </Grid.Column>
        <Grid.Column width='3' verticalAlign='middle'>
          <span id='Dashboard-shift-list-time'>
            {formatTime(s.start, s.timezone, MOMENT_HOURS)}
          </span>
        </Grid.Column>
        <Grid.Column width='3'>
          <Route render={render(s.id)} />
        </Grid.Column>
      </Grid.Row>
    );
  });

  const includes = onhold ? 'On Hold' : 'Unassigned';

  const header = unassigned ? 'UNASSIGNED CLIENT SHIFTS' : 'HIGH URGENCY SHIFTS';

  // eslint-disable-next-line no-nested-ternary
  const count = unassigned
    ? counts.unassignedShifts
      ? counts.unassignedShifts
      : 0
    : counts.onholdShifts
    ? counts.onholdShifts
    : 0;

  return (
    <Grid padded='horizontally' className='shiftList'>
      {!unassigned && <Label corner='left' color='red' />}
      <Grid.Row className={unassigned ? 'headerRow' : 'headerRow highUrgency'}>
        <Grid.Column width='16'>
          <Header id='Dashboard-unassigned-urgency-shift-header'>
            {header}
            <Header.Subheader id='Dashboard-unassigned-urgency-shift-sub-header'>
              Includes <b id='Dashboard-unassigned-urgency-shift-text'>&quot;{includes}&quot;</b>{' '}
              shifts between <b>now </b>
              and{' '}
              <b id='Dashboard-unassigned-urgency-shift-date'>
                {moment.utc(range.dateEnd).format(SHORT_DATE)}
              </b>
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid.Row>
      {shiftItems}
      {showEmptyState && <NoShiftsState />}
      {count > 0 && (
        <Grid.Row className='footerRow'>
          <Grid.Column id='Dashboard-unassigned-shift-more' width='16' textAlign='right'>
            {count} more {`"${includes}"`} shifts after{' '}
            <b>{formatTime(range.dateEnd, null, SHORT_DATE)}</b>
            <Button
              id='Dashboard-unassigned-shift-more-button'
              size='mini'
              onClick={viewAllClick}
              className='care-blue'
            >
              View All
            </Button>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

ShiftList.defaultProps = {
  unassigned: false,
  onhold: false,
  showEmptyState: false,
};

ShiftList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  shifts: PropTypes.arrayOf(PropTypes.shape).isRequired,
  range: PropTypes.shape().isRequired,
  counts: PropTypes.shape().isRequired,
  unassigned: PropTypes.bool,
  onhold: PropTypes.bool,
  showEmptyState: PropTypes.bool,
  employee: PropTypes.shape().isRequired,
};

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      dashboardFetched: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(dashboardActions.getDashboard());
    analytics.track(Events.DASHBOARD_SELECT_TAB);
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(next) {
    const { caregiverStatistic } = next.dashboard;
    const { dashboardFetched } = this.state;
    if (
      !caregiverStatistic ||
      (caregiverStatistic && caregiverStatistic.length === 0 && !dashboardFetched)
    ) {
      this.setState({ dashboardFetched: true });
    }
  }

  onCaregiverNameClick = id => {
    history.push(`/home/caregivers/records/${id}`);
  };

  render() {
    const { dashboard, employee, principal, dispatch } = this.props;
    const selfEmployee = employee.employee;
    const { dashboardFetched } = this.state;

    const { settings } = principal;

    const surveyEnabled =
      settings?.caregiverCovidSurveyEnabled ||
      settings?.clientCovidSurveyEnabled ||
      settings?.employeeCovidSurveyEnabled;

    return (
      <Grid className='contentGrid dashboard' padded>
        {dashboard.loading && (
          <Dimmer id='Dashboard-animation-loader-image-dimmer' active inverted>
            <CareLoader loading={dashboard.loading} showText={false} />
          </Dimmer>
        )}

        <Grid.Column
          className={!dashboardFetched ? 'menuCol' : 'menuCol menuCol-fade-in'}
          style={{ display: dashboard.loading ? 'none' : null }}
          width='three'
        >
          <DashboardMenu />
        </Grid.Column>

        <Grid.Column width='thirteen'>
          <NewUpdate />
          <Grid>
            <Grid.Row className='welcomeRow'>
              <Grid.Column className={!dashboardFetched ? '' : 'dashboard-welcome'} width='10'>
                Welcome{employee && employee.employee && ', '}
                {employee && employee.employee && (
                  <span id='Dashboard-header-welcome-name' className='name'>
                    {employee.employee.firstName}
                  </span>
                )}
              </Grid.Column>
              <Grid.Column
                style={{ display: dashboard.loading ? 'none' : null }}
                width='6'
                textAlign='right'
              >
                {selfEmployee && isAdmin(principal.roles) && <Announcements />}
              </Grid.Column>
            </Grid.Row>
            {isAdmin(principal.roles) && surveyEnabled && (
              <div className='survey-summary'>
                {settings?.caregiverCovidSurveyEnabled && (
                  <SurveySummary type='caregivers' dispatch={dispatch} />
                )}
                {settings?.employeeCovidSurveyEnabled && (
                  <SurveySummary type='employees' dispatch={dispatch} />
                )}
                {settings?.clientCovidSurveyEnabled && (
                  <SurveySummary type='clients' dispatch={dispatch} />
                )}
              </div>
            )}
            <Grid.Row>
              <Grid.Column width='16'>
                <Grid className='dashboard'>
                  <Grid.Column width='16'>
                    <Grid>
                      <Grid.Row className='topRow'>
                        <Grid.Column width='16' style={{ minWidth: '543px' }}>
                          <Grid
                            className={!dashboardFetched ? '' : 'caregiverStatistic-header-fade-in'}
                            style={{ display: dashboard.loading ? 'none' : null }}
                          >
                            {dashboard.caregiverStatistic && (
                              <ShiftTodos statistic={dashboard.caregiverStatistic} />
                            )}
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width='8' className='leftColGrid'>
                          <Grid>
                            {dashboard.onholdShifts && dashboard.onholdShifts.length > 0 && (
                              <Grid.Row
                                className={
                                  !dashboardFetched ? '' : 'dashboard-on-hold-shifts-fade-in'
                                }
                                style={{ display: dashboard.loading ? 'none' : null }}
                              >
                                <ShiftList
                                  shifts={dashboard.onholdShifts}
                                  range={dashboard.range}
                                  dispatch={dispatch}
                                  counts={dashboard.counts}
                                  onhold
                                  employee={employee}
                                />
                              </Grid.Row>
                            )}
                            <Grid.Row
                              className={!dashboardFetched ? '' : 'dashboard-shifts-fade-in'}
                              style={{ display: dashboard.loading ? 'none' : null }}
                            >
                              {dashboard.unassignedShifts && (
                                <ShiftList
                                  shifts={dashboard.unassignedShifts}
                                  range={dashboard.range}
                                  dispatch={dispatch}
                                  counts={dashboard.counts}
                                  showEmptyState={dashboard.unassignedShifts.length === 0}
                                  unassigned
                                  employee={employee}
                                />
                              )}
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                        <Grid.Column
                          width='8'
                          className={
                            !dashboardFetched
                              ? 'rightColGrid'
                              : 'rightColGrid caregivers-overview-fade-in'
                          }
                          style={{ display: dashboard.loading ? 'none' : null }}
                        >
                          {dashboard.caregiverStatistic && (
                            <Grid>
                              <Grid.Row>
                                <Grid.Column width='16'>
                                  <Grid className='statisticGrid'>
                                    <Grid.Column width='11'>
                                      <Header
                                        id='Dashboard-available-caregiver-pool-header'
                                        size='small'
                                      >
                                        AVAILABLE CAREGIVER POOL
                                      </Header>
                                      <p id='Dashboard-available-caregiver-pool-text'>
                                        New caregivers records that need an invitation to install
                                        the CareConnect app and register to activate. These
                                        caregivers cannot receive shift invites until registered.
                                      </p>
                                    </Grid.Column>
                                    <Grid.Column width='5'>
                                      <Segment
                                        id='Dashboard-available-caregiver-pool-circle'
                                        circular
                                        className={
                                          !dashboardFetched
                                            ? 'blueLargeCircle'
                                            : 'blueLargeCircle blueLargeCircle-fade-in'
                                        }
                                        style={{ display: dashboard.loading ? 'none' : null }}
                                      >
                                        <span
                                          id='Dashboard-available-caregiver-pool-number'
                                          className='blueLargeCircleText'
                                        >
                                          {dashboard.caregiverStatistic.inactive}
                                        </span>
                                      </Segment>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Row>
                                <Grid.Column width='16'>
                                  <Grid className='statisticGrid' padded='vertically'>
                                    <Grid.Column width='11'>
                                      <Header
                                        id='Dashboard-caregivers-awaiting-activation-header'
                                        size='small'
                                      >
                                        CAREGIVERS AWAITING ACTIVATION
                                      </Header>
                                      <p id='Dashboard-caregivers-awaiting-activation-text'>
                                        Caregivers who have received an invitation but have not yet
                                        registered in the CareConnect app.
                                      </p>
                                    </Grid.Column>
                                    <Grid.Column width='5'>
                                      <Segment
                                        id='Dashboard-caregivers-awaiting-activation-circle'
                                        circular
                                        className={
                                          !dashboardFetched
                                            ? 'redLargeCircle'
                                            : 'redLargeCircle redLargeCircle-fade-in'
                                        }
                                        style={{ display: dashboard.loading ? 'none' : null }}
                                      >
                                        <span
                                          id='Dashboard-caregivers-awaiting-activation-number'
                                          className='redLargeCircleText'
                                        >
                                          {dashboard.caregiverStatistic.pending}
                                        </span>
                                      </Segment>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid>
    );
  }
}

Dashboard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  dashboard: PropTypes.shape().isRequired,
  employee: PropTypes.shape().isRequired,
  principal: PropTypes.shape().isRequired,
};

const mapStateToProps = state => {
  const { dashboard, employee, principal } = state;
  return {
    dashboard,
    employee,
    principal,
  };
};

export default connect(mapStateToProps)(Dashboard);

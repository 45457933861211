import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Accordion, Form, Dropdown } from 'semantic-ui-react';
import '../CaregiverRecords.css';

class MenuDropdownItem extends React.Component {
  render() {
    const {
      active,
      title,
      index,
      onTitleClick,
      name,
      options,
      values,
      onValueChange,
      id,
      renderLabel,
    } = this.props;

    const customOnValueChange = (obj, input) => {
      let newValues = values.filter(x => !options.some(o => o.value === x));
      newValues = newValues.concat(input.value);
      const moddedInput = { ...input, value: newValues };
      onValueChange(obj, moddedInput);
    };

    if (options && options.length) {
      const selected = [];
      options.map(x => (values && values.indexOf(x.value) >= 0 ? selected.push(x.value) : null));
      const content = selected.length ? (
        <span>
          {title} <span className='menuTitleCounter'>({selected.length})</span>
        </span>
      ) : (
        title
      );

      return (
        <Menu.Item className='text-align-left side-menu-item'>
          <Accordion.Title
            id='menu-dropdown-item-side-menu-item'
            className='side-menu-item'
            active={active}
            content={content}
            indexname={index}
            onClick={onTitleClick}
          />
          <Accordion.Content active={active}>
            <Form>
              <Form.Group grouped widths='equal'>
                <Dropdown
                  id={id || index}
                  name={name}
                  placeholder={title}
                  fluid
                  className='menuDropdown vertical-line'
                  selection
                  multiple
                  options={options}
                  value={selected}
                  onChange={customOnValueChange}
                  renderLabel={renderLabel}
                />
              </Form.Group>
            </Form>
          </Accordion.Content>
        </Menu.Item>
      );
    }

    return '';
  }
}

MenuDropdownItem.defaultProps = {
  active: false,
  id: undefined,
  renderLabel: undefined,
};

MenuDropdownItem.propTypes = {
  active: PropTypes.bool,
  title: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  onTitleClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  onValueChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  renderLabel: PropTypes.func,
};

export default MenuDropdownItem;

/* eslint-disable import/prefer-default-export */
import * as types from '../constants/ActionTypes';
import { backend } from '../services';
// eslint-disable-next-line import/no-cycle
import { alertActions } from './alert';

function getAnnouncementAudience(params) {
  return dispatch => {
    dispatch(request());
    backend.getAnnouncementAudience(params).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error, alertActions.ANNOUNCEMENT_ERROR));
      }
    );
  };

  function request() {
    return { type: types.ANNOUNCEMENT_AUDIENCE_REQUEST };
  }
  function success(results) {
    return { type: types.ANNOUNCEMENT_AUDIENCE_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_ACTIVE_CONVERSATIONS_FAILURE, error };
  }
}

function sendAnnouncement(audienceFilter, msg) {
  return dispatch => {
    dispatch(request());
    backend.sendAnnouncement({ filter: audienceFilter, message: msg }).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error, alertActions.ANNOUNCEMENT_ERROR));
      }
    );
  };

  function request() {
    return { type: types.SEND_ANNOUNCEMENT_REQUEST };
  }
  function success(results) {
    return { type: types.SEND_ANNOUNCEMENT_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.SEND_ANNOUNCEMENT_FAILURE, error };
  }
}

function openAnnouncements() {
  return dispatch => {
    dispatch({ type: types.OPEN_ANNOUCEMENT_TOOL });
  };
}

function closeAnnouncements() {
  return dispatch => {
    dispatch({ type: types.CLOSE_ANNOUCEMENT_TOOL });
  };
}

export const announcementActions = {
  getAnnouncementAudience,
  openAnnouncements,
  closeAnnouncements,
  sendAnnouncement,
};

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Menu, Accordion, Button, Image, Divider } from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-input-range/lib/css/index.css';
import MenuDropdownItem from './subcomponents/MenuDropdownItem';
import { PreferenceCategories, ClientStatusMap } from '../constants/DomainTypes';
import { uiStateActions, clientActions } from '../actions';
import './Schedules.css';
import TeamForm from './TeamForm';

const FilterGroup = ({ uiState, onTitleClick, onValueChange, attributes, languages }) => (
  <Accordion as={Menu} exclusive={false} widths='sixteen'>
    {Object.keys(PreferenceCategories).map(key => {
      const options = attributes.preferences
        .filter(x => x.category === key && x.active)
        .map(x => ({ text: x.clientLabel || x.label || x.name, value: x.id }));
      const index = `${key}CasePreferencesMenu`;

      return (
        PreferenceCategories[key].clientTitle && (
          <MenuDropdownItem
            active={uiState[index]}
            title={`${PreferenceCategories[key].clientTitle}`}
            index={index}
            onTitleClick={onTitleClick}
            name='preferenceFilter'
            options={options}
            values={uiState.preferenceFilter}
            onValueChange={onValueChange}
            id={`clientsSearchPreferencesDropdown_${key}`}
            key={index}
          />
        )
      );
    })}

    <MenuDropdownItem
      active={uiState.languagesMenu}
      title='Language'
      index='languagesMenu'
      onTitleClick={onTitleClick}
      name='languages'
      options={languages}
      values={uiState.languages}
      onValueChange={onValueChange}
      id='caregiversSearchLanguagesDropdown'
      key='caregiversSearchLanguagesDropdown'
    />

    <MenuDropdownItem
      active={uiState.clientStatusMenu}
      title='Client Status'
      index='clientStatusMenu'
      onTitleClick={onTitleClick}
      name='clientStatusFilter'
      options={Object.entries(ClientStatusMap).map(e => ({ value: e[0], text: e[1].name }))}
      values={uiState.clientStatusFilter}
      onValueChange={onValueChange}
      id='caregiversSearchClientStatusDropdown'
      key='caregiversSearchClientStatusDropdown'
    />

    <TeamForm uiState={uiState} onChange={onValueChange} onTitleClick={onTitleClick} />
  </Accordion>
);

FilterGroup.propTypes = {
  uiState: PropTypes.shape().isRequired,
  onTitleClick: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  attributes: PropTypes.shape().isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

class ClientsMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      hasFilterChange: false,
    };
  }

  handleFilterTitleClick = (e, titleProps) => {
    this.props.dispatch(
      uiStateActions.uiStateChange('clientsFilter', titleProps.indexname, !titleProps.active)
    );

    this.handleUpdateClick = this.handleUpdateClick.bind(this);
  };

  handleUpdateClick = () => {
    this.props.onUpdateClick();
    let primaryContact;
    const { selectedPrimaryContacts } = this.props.uiState;
    if (
      selectedPrimaryContacts &&
      selectedPrimaryContacts.length &&
      !selectedPrimaryContacts.includes(0)
    ) {
      primaryContact = selectedPrimaryContacts;
    }
    const params = {
      preference: this.props.uiState.preferenceFilter,
      languages: this.props.uiState.languages,
      zip: this.props.uiState.zip,
      status: this.props.uiState.clientStatusFilter,
      primaryContact,
    };
    this.props.dispatch(clientActions.listClients(params));
    this.setState({ hasFilterChange: false });
  };

  handleOnValueChange = (e, input) => {
    uiStateActions.inputChangeHandler(this.props.dispatch, 'clientsFilter')(e, input);
    if (this.state.hasFilterChange === false) {
      this.setState({ hasFilterChange: true });
    }
  };

  render() {
    const { loading, uiState, languages, attributes } = this.props;

    const items = [
      {
        key: 'filters',
        as: 'div',
        content: (
          <FilterGroup
            uiState={uiState}
            attributes={attributes}
            languages={languages}
            onTitleClick={this.handleFilterTitleClick}
            onValueChange={(e, input) => this.handleOnValueChange(e, input)}
          />
        ),
      },
      {
        key: 'update',
        as: 'div',
        content: (
          <Button
            id='clientsSearchUpdateButton'
            primary
            content='Update'
            className={!this.state.hasFilterChange ? 'care-grey' : 'care-grey-active'}
            loading={loading}
            onClick={this.handleUpdateClick}
          />
        ),
      },
    ];

    return (
      <div className='filterBar'>
        <div className='filterTitle'>
          <Image src='/filters_icon.svg' verticalAlign='middle' /> <span>Filter Clients</span>
        </div>
        <Divider />
        <Menu className='filterItem' vertical secondary fluid items={items} />
      </div>
    );
  }
}

ClientsMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uiState: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  attributes: PropTypes.shape().isRequired,
  onUpdateClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { languages, attributes } = state.principal;
  return {
    languages,
    attributes,
  };
};

export default connect(uiStateActions.mapStateOfGroupToProps('clientsFilter', mapStateToProps))(
  ClientsMenu
);

/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Grid, Button, Image } from 'semantic-ui-react';
import { announcementActions } from '../../../actions/index';
import CareLoader from '../CareLoader';
import './Announcements.css';
import {
  StatusFilter,
  DisciplineFilter,
  GenderFilter,
  filterWithState,
} from './AnnouncementFilters';
import AnnouncementConfirmed from './AnnouncementConfirmed';

const AudienceList = ({ audienceList, close }) => {
  if (!audienceList || audienceList.length === 0) {
    return (
      <div className='audienceList'>
        <div className='hideBar'>
          <span onClick={close} role='button' tabIndex='0'>
            Hide List
          </span>
        </div>
        <div className='headerBar'>Caregiver Name</div>
        <div className='noAudienceRow'>No audience</div>
      </div>
    );
  }

  const list = audienceList.map(l => `${l.firstName} ${l.lastName}`);
  return (
    <div className='audienceList'>
      <div className='hideBar'>
        <span onClick={close} role='button' tabIndex='0'>
          Hide List
        </span>
      </div>
      <div className='headerBar'>Caregiver Name</div>
      {list.map(n => (
        <div className='cgRow'>{n}</div>
      ))}
    </div>
  );
};

const MatchCountPanel = ({ countTxt, toggleRecipientList }) => (
  <div className='matchCountPanel'>
    <div className='mcpHeader'>This announcement will reach</div>
    <img src='/audience_panel.svg' alt='audience panel' />
    <div className='mcpCount'>{countTxt}</div>
    <Button size='mini' basic onClick={toggleRecipientList}>
      List Recipients
    </Button>
    <div className='mcpFooter'>add additional rules on the left to refine your audience</div>
  </div>
);

class Announcements extends React.Component {
  static getInitialState() {
    return {
      filters: [{ type: 'status', value: 'active' }],
      announcementMessage: undefined,
      showRecipients: false,
      showFilterPicker: false,
    };
  }

  constructor(props) {
    super(props);

    this.addFilter = this.addFilter.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.filterChanged = this.filterChanged.bind(this);
    this.messageChange = this.messageChange.bind(this);
    this.close = this.close.bind(this);
    this.sendAnnouncement = this.sendAnnouncement.bind(this);
    this.toggleFilterPicker = this.toggleFilterPicker.bind(this);
    this.updateAudience = this.updateAudience.bind(this);
    this.clickAnnouncementButton = this.clickAnnouncementButton.bind(this);
    this.toggleRecipientList = this.toggleRecipientList.bind(this);
  }

  // eslint-disable-next-line react/state-in-constructor
  state = Announcements.getInitialState();

  clickAnnouncementButton() {
    this.setState(() => {
      this.props.dispatch(announcementActions.openAnnouncements());
      const state = Announcements.getInitialState();
      Object.assign(state, { announcementSent: false });
      this.updateAudience(state);
      return state;
    });
  }

  toggleRecipientList() {
    this.setState(s => {
      const newState = { ...s, showRecipients: !s.showRecipients };
      this.updateAudience(newState);
      return newState;
    });
  }

  updateAudience(state) {
    const params = {};
    state.filters.forEach(f => {
      params[f.type] = f.value;
      return null;
    });
    params.audienceList = state.showRecipients;

    this.props.dispatch(announcementActions.getAnnouncementAudience(params));
  }

  addFilter(filterType) {
    this.setState(s => {
      const newState = {
        ...s,
        filters: s.filters.concat([{ type: filterType }]),
        showFilterPicker: false,
      };
      this.updateAudience(newState);
      return newState;
    });
  }

  removeFilter(filter) {
    this.setState(s => {
      const filters = s.filters.filter(f => !(f.type === filter.type && f.value === filter.value));
      const newState = {
        ...s,
        filters,
      };
      this.updateAudience(newState);
      return newState;
    });
  }

  filterChanged(filter) {
    this.setState(s => {
      const filters = s.filters.map(f => (f.type === filter.type ? { ...filter } : f));
      const newState = {
        ...s,
        filters,
      };
      this.updateAudience(newState);
      return newState;
    });
  }

  toggleFilterPicker() {
    this.setState(s => ({ ...s, showFilterPicker: !s.showFilterPicker }));
  }

  messageChange(e) {
    const { value } = e.target;
    this.setState(s => ({
      ...s,
      announcementMessage: value,
    }));
  }

  sendAnnouncement() {
    const params = {};

    this.state.filters.forEach(f => Object.assign(params, { [f.type]: f.value }));

    this.setState(s => ({ ...s, announcementSent: true }));

    this.props.dispatch(
      announcementActions.sendAnnouncement(params, this.state.announcementMessage)
    );
  }

  close() {
    this.props.dispatch(announcementActions.closeAnnouncements());
  }

  render() {
    const { audienceCount, audienceList, isConfirmed, isOpen, loading } = this.props;
    const { announcementMessage, showRecipients, showFilterPicker, announcementSent } = this.state;
    const charCount = announcementMessage ? announcementMessage.length : 0;
    const countTxt =
      audienceCount === 1 ? `${audienceCount} Caregiver` : `${audienceCount} Caregivers`;

    const hasType = type => !!this.state.filters.find(t => t.type === type);

    const filterPickers = [];
    if (!hasType('status')) filterPickers.push(<StatusFilter addFilter={this.addFilter} />);
    if (!hasType('discipline')) filterPickers.push(<DisciplineFilter addFilter={this.addFilter} />);
    if (!hasType('gender')) filterPickers.push(<GenderFilter addFilter={this.addFilter} />);

    const activeFilters = this.state.filters.reduce((a, b) => {
      if (a.length) {
        a.push(<div className='filterSepartor'>and</div>);
      }
      a.push(filterWithState(b, this.filterChanged, this.removeFilter));
      return a;
    }, []);

    if (!isOpen) {
      return (
        <Button className='announcementButton' size='small' onClick={this.clickAnnouncementButton}>
          <div className='content'>
            <i className='icons'>
              <i
                className='inverted big white circle icon'
                style={{ position: 'relative', left: '2px' }}
              />
              <i
                className='blue flag icon'
                style={{ position: 'relative', left: '-11px', top: '10px' }}
              />
            </i>
            <div style={{ fontSize: 12, marginBottom: '1px', marginLeft: '-15px' }}>
              Build an Announcement
            </div>
          </div>
        </Button>
      );
    }

    const chartCountStyle = charCount > 110 ? { color: 'red' } : {};

    return (
      <div className='announcements'>
        <div className='mainControl'>
          {isConfirmed && <AnnouncementConfirmed onClose={this.close} />}

          <div className='item topbar'>Create Announcement</div>
          <div className='item message box'>
            <div className='itemHeader'>1. Compose Your Announcement</div>
            <div className='itemContent'>
              <Form>
                <Form.TextArea width={16} onChange={this.messageChange} />
              </Form>
              <div className='infoRow'>
                <div>
                  Messages more that 110 chars long may get truncated in push notifications.
                  <br />
                  Full messages will be visible in the notification center within your caregivers
                  app.
                </div>
                <div style={chartCountStyle}>{charCount} chars</div>
              </div>
            </div>
          </div>
          <div className='item audience box'>
            <div className='itemHeader'>2. Define Your Audience</div>
            <Grid>
              <Grid.Column width={6} className='filterBlock'>
                <p>
                  Add rules to build your audience.
                  <br />
                  Click rule blocks to edit values
                </p>
                <div className='filters'>{activeFilters}</div>
                {!showFilterPicker && !!filterPickers.length && (
                  <div className='addRule'>
                    {!!activeFilters.length && <span>and</span>}
                    <div
                      className='addRuleBtn clickable'
                      role='button'
                      tabIndex='0'
                      onClick={this.toggleFilterPicker}
                    >
                      Add Rule
                    </div>
                  </div>
                )}
                {showFilterPicker && (
                  <div className='filterPicker'>
                    <Image
                      src='/remove_announcement_filter.svg'
                      className='removeFilter clickable'
                      onClick={this.toggleFilterPicker}
                    />
                    {filterPickers}
                  </div>
                )}
              </Grid.Column>
              <Grid.Column width={10}>
                {loading && showRecipients && (
                  <CareLoader
                    className='audienceLoader'
                    loading={loading}
                    text='Finding Audience...'
                    centered
                  />
                )}
                {!loading && showRecipients && (
                  <AudienceList audienceList={audienceList} close={this.toggleRecipientList} />
                )}
                {!showRecipients && (
                  <MatchCountPanel
                    countTxt={countTxt}
                    toggleRecipientList={this.toggleRecipientList}
                  />
                )}
              </Grid.Column>
            </Grid>
          </div>
        </div>
        <div className='controlFooter'>
          <Button size='mini' basic onClick={this.close}>
            Cancel
          </Button>
          <div className='leftSide'>
            <p>
              This announcement will be
              <br />
              sent to
              <br />
              {countTxt}
            </p>
            <Button
              size='mini'
              disabled={charCount < 5 || audienceCount < 1 || announcementSent}
              onClick={this.sendAnnouncement}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

Announcements.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  audienceCount: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  audienceList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => ({
  ...state.announcement,
});

export default connect(mapStateToProps)(Announcements);

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Table, Icon, Grid, Dropdown, Button, Image, Ref } from 'semantic-ui-react';
import { BlocksGroupNames } from './compliance/complianceHelpers';
import '../TrainPref.css';
import AddComplianceBlockForm, { BlockExpirationFields } from './compliance/addComplianceBlockForm';
import AddComplianceClassRoomForm from './compliance/addComlianceClassRoomForm';

const blockExpirationPeriods = [
  { value: 'half_yearly', text: '6 months' },
  { value: 'yearly', text: 'Annual' },
  { value: 'monthly', text: 'Monthly' },
  { value: 'two_years', text: '2 years' },
  { value: 'three_years', text: '3 years' },
  { value: 'four_years', text: '4 years' },
  { value: 'five_years', text: '5 years' },
  { value: 'six_years', text: '6 years' },
  { value: 'ten_years', text: '10 years' },
];

class ComplianceSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = { addNewFormVisible: false, addLocationFormVisible: false };

    this.toggleAddNewForm = this.toggleAddNewForm.bind(this);
    this.toggleAddLocation = this.toggleAddLocation.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.table = this.table.bind(this);
    this.trainingRef = React.createRef();
    this.medicalRef = React.createRef();
    this.documentRef = React.createRef();
    this.otherRef = React.createRef();
  }

  // eslint-disable-next-line react/sort-comp
  toggleAddNewForm() {
    this.setState(s => ({ ...s, addNewFormVisible: !s.addNewFormVisible }));
  }

  toggleAddLocation() {
    this.setState(s => ({ ...s, addLocationFormVisible: !s.addLocationFormVisible }));
  }

  handleCreate(item) {
    Object.assign(item, { created: true });
    this.toggleAddNewForm();
    this.props.handleComplianceBlockCreate(item);
  }

  itemClass = item => {
    const classes = [];
    if (!item.removed && (item.created === true || item.updated)) {
      classes.push('table-item-updated');
    } else if (item.removed) {
      classes.push('table-item-removed');
    } else if (!item.system) classes.push('table-item-custom');

    return classes.join(' ');
  };

  classRoomTable = classRooms => {
    const header = [
      {
        id: 'compliance-settings-class-room-table-header-icon',
        key: 'icon',
        width: '1',
        content: '',
        className: 'compliances-icon-col-width',
      },
      {
        id: 'compliance-settings-class-room-table-header-name',
        key: 'name',
        width: '3',
        content: 'Class Room Name',
        className: 'compliances-header-style',
      },
      {
        id: 'compliance-settings-class-room-table-header-address',
        key: 'address',
        width: '9',
        content: 'Address',
        className: 'compliances-header-style',
      },
      {
        id: 'compliance-settings-class-room-table-header-capacity',
        key: 'capacity',
        width: '2',
        content: 'Capacity',
        className: 'compliances-header-style',
      },
      {
        id: 'compliance-settings-class-room-table-header-remove',
        key: 'remove',
        width: '1',
        content: '',
        className: 'compliances-header-style',
      },
    ];
    const onRemove = x => {
      const removed = !x.removed;
      this.props.onClassUpdated(x.id, 'removed', removed);
    };
    const printAddress = address =>
      `${address.street} ${address.street2 ? address.street2 : ''} ${address.city} ${
        address.state
      } ${address.zip}`;
    return (
      <Table
        id='classRoomsTable'
        tableData={classRooms}
        headerRow={header}
        renderBodyRow={x =>
          x.address.street && {
            className: this.itemClass(Object.assign(x, { system: true })),
            key: x.id,
            cells: [
              {
                key: 'icon',
                content: (
                  <i
                    className='big marker alternate icon'
                    style={{ color: '#979797', width: '25px', height: '25px' }}
                  />
                ),
              },
              { key: 'name', content: x.name },
              { key: 'address', content: printAddress(x.address) },
              { key: 'capacity', content: x.capacity },
              {
                key: 'remove',
                content: (
                  <div>
                    <Icon
                      id='compliance-settings-class-room-table-remove-icon'
                      link
                      size='large'
                      name='trash'
                      onClick={() => onRemove(x)}
                    />
                  </div>
                ),
              },
            ],
          }
        }
      />
    );
  };

  table(blocktype, items, id) {
    const onUpdate = (block, type, value) => {
      const isGrouped = !!block.groupName;
      if (isGrouped) {
        const { complianceBlocks } = this.props;
        const group = complianceBlocks.filter(b => b.groupName === block.groupName);
        return group.forEach(b => this.props.onBlockUpdated(b.id, type, value));
      }
      return this.props.onBlockUpdated(block.id, type, value);
    };

    const onRemove = x => {
      const isGrouped = !!x.groupName;
      if (isGrouped) {
        const { complianceBlocks } = this.props;
        const group = complianceBlocks.filter(b => b.groupName === x.groupName);
        return group.forEach(b => {
          const removed = !b.removed;
          this.props.onBlockUpdated(b.id, 'removed', removed);
        });
      }
      const removed = !x.removed;
      return this.props.onBlockUpdated(x.id, 'removed', removed);
    };

    const data = items;
    const header = [
      {
        id: 'compliance-settings-table-header-icon',
        key: 'icon',
        width: '1',
        content: '',
        className: 'compliances-icon-col-width',
      },
      {
        id: 'compliance-settings-table-header-name',
        key: 'name',
        width: '2',
        content: 'Item Name',
        className: 'compliances-header-style',
      },
      {
        id: 'compliance-settings-table-header-active',
        key: 'active',
        width: '1',
        content: 'Active',
        className: 'compliances-header-style compliance-centered',
      },
      {
        id: 'compliance-settings-table-header-expirationPeriod',
        key: 'expirationPeriod',
        width: '2',
        content: 'Frequency',
        className: 'compliances-header-style compliance-centered',
      },
      {
        id: 'compliance-settings-table-header-includeInPrehire',
        key: 'includedInPrehire',
        width: '1',
        content: 'Included in Pre-Hire',
        className: 'compliances-header-style compliance-centered',
      },
      {
        id: 'compliance-settings-table-header-includeInEligibility',
        key: 'includedInEligibility',
        width: '1',
        content: 'Included in Eligibility',
        className: 'compliances-header-style compliance-centered',
      },
      {
        id: 'compliance-settings-table-header-visibleInApp',
        key: 'visibleInApp',
        width: '1',
        content: 'Visible in App',
        className: 'compliances-header-style compliance-centered',
      },
      // { key: 'editableInApp', width: '1', content: 'Editable in App' },
      {
        id: 'compliance-settings-table-header-disciplines',
        key: 'disciplines',
        width: '2',
        content: 'Disciplines',
        className: 'compliances-header-style compliance-centered',
      },
      {
        id: 'compliance-settings-table-header-remove',
        key: 'remove',
        width: '1',
        content: '',
        className: 'compliances-header-style',
      },
    ];

    const { disciplines } = this.props;

    return (
      <Table
        id={id}
        tableData={data}
        headerRow={header}
        renderBodyRow={x => ({
          className: this.itemClass(x),
          key: x.id,
          cells: [
            {
              key: 'icon',
              content: (
                <Image
                  style={{ height: '25px', width: '25px', padding: '0px' }}
                  src={`/compliance/${x.icon}`}
                />
              ),
            },
            { key: 'name', content: x.name, className: 'name' },
            {
              key: 'active',
              content: (
                <Form.Checkbox
                  className='round compliance-centered'
                  style={{ textAlign: 'center', verticalAlign: '-webkit-baseline-middle' }}
                  checked={x.active}
                  onChange={(_, { checked }) => onUpdate(x, 'active', checked)}
                />
              ),
            },
            {
              key: 'expirationPeriod',
              content: x.expires ? (
                <Dropdown
                  id='compliance-settings-table-expiration-dropdown'
                  className='preference-settings-dropdown'
                  name='expirationPeriod'
                  placeholder='None'
                  selection
                  disabled={
                    x.system || x.expirationFieldName === BlockExpirationFields.ANNUAL_CALENDAR_YEAR
                  }
                  options={blockExpirationPeriods}
                  value={x.expirationPeriod}
                  onChange={(_, { value }) => onUpdate(x, 'expirationPeriod', value)}
                />
              ) : (
                <></>
              ),
            },
            {
              key: 'includedInPrehire',
              content: (
                <Form.Checkbox
                  style={{ textAlign: 'center', paddingTop: '10px' }}
                  className='round compliance-centered'
                  checked={x.includedInPrehire}
                  onChange={(_, { checked }) => onUpdate(x, 'includedInPrehire', checked)}
                />
              ),
            },
            {
              key: 'includedInEligibility',
              content: (
                <Form.Checkbox
                  style={{ textAlign: 'center', paddingTop: '10px' }}
                  className='round compliance-centered'
                  checked={x.includedInEligibility}
                  onChange={(_, { checked }) => onUpdate(x, 'includedInEligibility', checked)}
                />
              ),
            },
            {
              key: 'visibleInApp',
              content: (
                <Form.Checkbox
                  style={{ textAlign: 'center', paddingTop: '10px' }}
                  className='round compliance-centered'
                  checked={x.visibleInApp}
                  onChange={(_, { checked }) => onUpdate(x, 'visibleInApp', checked)}
                />
              ),
            },
            /* {
              key: 'editableInApp',
              content: (
                <Form.Checkbox
                  className='round'
                  checked={x.editableInApp}
                  onChange={(_, { checked }) => onUpdate(x.id, 'editableInApp', checked)}
                />
              ),
            }, */
            {
              key: 'disciplines',
              content: (
                <Form.Dropdown
                  id='compliance-settings-table-discipline-dropdown'
                  className='compliance-centered preference-settings-dropdown'
                  name='disciplines'
                  placeholder='None'
                  selection
                  multiple
                  options={disciplines}
                  value={x.disciplines ? x.disciplines : []}
                  onChange={(_, { value }) => onUpdate(x, 'disciplines', value)}
                />
              ),
            },
            {
              key: 'remove',
              content: (
                <div>
                  {!x.system && (
                    <Icon
                      id='compliance-settings-table-remove-icon'
                      link
                      size='large'
                      name='trash'
                      onClick={() => onRemove(x)}
                    />
                  )}
                </div>
              ),
            },
          ],
        })}
      />
    );
  }

  render() {
    const blocks = this.props.complianceBlocks;
    const trainings = blocks.filter(
      b => b.type === 'training' || b.type === 'inservicetraining' || b.type === 'certifications'
    );
    const medicals = blocks.filter(b => b.type === 'medical' && !b.groupName);

    const [hbMain] = blocks.filter(
      b => b.groupName === BlocksGroupNames.HBT && b.name === 'Hepatitis B Test Series 1'
    );
    let newHB = null;
    if (hbMain) {
      newHB = { ...hbMain, name: 'Hepatitis B Test' };
    }

    const [rubellaMain] = blocks.filter(
      b => b.groupName === BlocksGroupNames.RUBELLA && b.name === 'Rubella Test'
    );

    const [influenzaMain] = blocks.filter(
      b => b.groupName === BlocksGroupNames.INFLUENZA && b.name === 'Influenza Vaccine'
    );

    const [measlesMain] = blocks.filter(
      b => b.groupName === BlocksGroupNames.MEASLES && b.name === 'Measles (Rubeola) Test'
    );

    const [tBMain] = blocks.filter(b => b.groupName === BlocksGroupNames.TB && b.name === 'PPD');
    let newTB = null;
    if (tBMain) {
      newTB = { ...tBMain, name: 'TB Test' };
    }

    const [covidMain] = blocks.filter(
      b => b.groupName === BlocksGroupNames.COVID && b.name === 'Coronavirus Vaccine'
    );

    const mainGroupedBlocks = [
      rubellaMain,
      influenzaMain,
      measlesMain,
      newTB,
      covidMain,
      newHB,
    ].filter(p => !!p);

    medicals.unshift(...mainGroupedBlocks);

    const documentations = blocks.filter(
      b => b.type === 'documentation' || b.type === 'workhistory'
    );
    const { disciplines } = this.props;

    const prehire = blocks.filter(b => b.includedInPrehire && !b.groupName);

    prehire.unshift(...mainGroupedBlocks.filter(b => b.includedInPrehire));

    const others = blocks.filter(
      b =>
        !trainings.includes(b) &&
        !medicals.includes(b) &&
        !documentations.includes(b) &&
        !b.groupName
    );

    const { classRooms } = this.props;

    const handleClick = type => {
      const types = [
        'medical',
        'training',
        'inservicetraining',
        'certifications',
        'documentation',
        'workhistory',
      ];

      if (type === 'medical') {
        this.medicalRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      if (type === 'training' || type === 'inservicetraining' || type === 'certifications') {
        this.trainingRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      if (type === 'documentation' || type === 'workhistory') {
        this.documentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      if (!types.includes(types)) {
        this.otherRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    };

    return (
      <Grid padded columns={16}>
        <Grid.Row style={{ zIndex: 999 }}>
          <Grid.Column width={9} className='active-info-text' style={{ top: '4px' }}>
            The following information will be included in all caregiver profiles and influence
            availability to be staffed on shifts and potentially appear in search matches.
          </Grid.Column>
          <Grid.Column width={7} textAlign='right'>
            <div style={{ display: 'flex', float: 'right' }}>
              <span className='active-info-text'>
                Add Training Location{' '}
                <Button
                  id='compliance-settings-add-location-button'
                  circular
                  icon='plus'
                  size='mini'
                  className='addButton care-blue'
                  style={{ marginRight: '16px' }}
                  onClick={this.toggleAddLocation}
                />
                Add Custom Item{' '}
                <Button
                  id='addCustomBlock'
                  circular
                  icon='plus'
                  size='mini'
                  className='addButton care-blue'
                  onClick={this.toggleAddNewForm}
                />
              </span>
            </div>
          </Grid.Column>
        </Grid.Row>
        {this.state.addNewFormVisible && (
          <AddComplianceBlockForm
            disciplines={disciplines}
            onFinish={this.handleCreate}
            onClose={this.toggleAddNewForm}
          />
        )}
        {this.state.addLocationFormVisible && (
          <AddComplianceClassRoomForm
            onClose={this.toggleAddLocation}
            onFinish={this.props.addClassRoom}
          />
        )}
        <Grid.Row style={{ zIndex: 999 }}>
          <Grid.Column width={16}>
            <div>
              <h3>Pre-Hire Checklist</h3>
              <p className='compliance-description-small'>
                All items set as “Included in Pre-Hire” from the subsequent tables of compliance
                items. These items will be required in the HR Manager tool for candidates before
                they can be activated into your caregiver pool.{' '}
                <span style={{ color: '#0099ff' }}>Click any item to jump to its item table</span>
              </p>
            </div>
          </Grid.Column>
        </Grid.Row>
        {prehire.map(p => (
          <div key={p.id} className='pre-hire-container'>
            <div
              role='button'
              tabIndex='0'
              className='pre-hire-container-tile'
              onClick={() => {
                handleClick(p.type);
              }}
            >
              <Image
                src={`/compliance/${p.icon}`}
                style={{ height: '30px', width: '30px', padding: '0px' }}
              />
              <span style={{ textAlign: 'middle', paddingTop: '2px' }}>{p.name}</span>
            </div>
          </div>
        ))}
        <Grid.Row style={{ zIndex: 998 }}>
          <Grid.Column width={16}>
            <div>
              <h3>Training Information</h3>
              <p className='compliance-description-small'>
                Include all ongoing training assessments required for ongoing employment.
              </p>
              <Ref innerRef={this.trainingRef}>
                {this.table('training', trainings, 'trainingItemsTable')}
              </Ref>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ zIndex: 997 }}>
          <Grid.Column width={16}>
            <div>
              <h3>Medical Information</h3>
              <p className='compliance-description-small'>
                Include all medical checks required while an caregiver is in pre-hire process and
                post hire as employee.
              </p>
              <Ref innerRef={this.medicalRef}>
                {this.table('medical', medicals, 'medicalItemsTable')}
              </Ref>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ zIndex: 996 }}>
          <Grid.Column width={16}>
            <div>
              <h3>Documentation</h3>
              <p className='compliance-description-small'>
                Include any documentation you require as part of pre-hire on ongoing record
                collection.
              </p>
              <Ref innerRef={this.documentRef}>
                {this.table('documentation', documentations, 'documentationItemsTable')}
              </Ref>
            </div>
          </Grid.Column>
        </Grid.Row>
        {others && others.length > 0 && (
          <Grid.Row style={{ zIndex: 995 }}>
            <Grid.Column width={16}>
              <div>
                <h3>Other</h3>
                <Ref innerRef={this.otherRef}>{this.table('other', others, 'otherItemsTable')}</Ref>
              </div>
            </Grid.Column>
          </Grid.Row>
        )}
        {classRooms && classRooms.length > 0 && (
          <Grid.Row style={{ zIndex: 994 }}>
            <Grid.Column width={16}>
              <div>
                <h3>Class Rooms</h3>
                {this.classRoomTable(classRooms)}
              </div>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}

ComplianceSettings.propTypes = {
  onBlockUpdated: PropTypes.func.isRequired,
  addClassRoom: PropTypes.func.isRequired,
  complianceBlocks: PropTypes.arrayOf(PropTypes.shape).isRequired,
  handleComplianceBlockCreate: PropTypes.func.isRequired,
  disciplines: PropTypes.arrayOf(PropTypes.shape).isRequired,
  classRooms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClassUpdated: PropTypes.func.isRequired,
};

export default ComplianceSettings;

import { GEOCODE_REQUEST, GEOCODE_SUCCESS, GEOCODE_FAILURE } from '../constants/ActionTypes';

const initialState = {
  loading: false,
};

function geocode(state = initialState, action) {
  switch (action.type) {
    case GEOCODE_REQUEST:
      return { ...state, loading: true };
    case GEOCODE_SUCCESS: {
      const results = action.results ? action.results : [];

      return { ...state, loading: false, results };
    }
    case GEOCODE_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export default geocode;

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Divider, Button, Modal, Image, Dimmer } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import HRCalendarView from './subcomponents/hrApp/HRCalendarView';
import EventView from './subcomponents/hrApp/eventView';
import { hrDashboardActions } from '../actions/hrapp/dashboard';
import CareRoundButton from './subcomponents/CareRoundButton';
import CaregiverEditor from './CaregiverEditor';
import CaregiverDetails from './CaregiverDetails';
import { eventActions } from '../actions/hrapp/eventActions';
import './Dashboard.css';
import { history } from '../helpers';
import { caregiverActions } from '../actions';
import filterActions from '../actions/hrapp/filterActions';
import HRCaregiverNameCell from './subcomponents/hrApp/HRCaregiverNameCell';
import HRDashboardMenu from './HRDashboardMenu';
import NewUpdate from './NewUpdate';
import CareLoader from './subcomponents/CareLoader';
import { GreenButton } from '../styles/common';

class HRDashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedEventId: null,
      newCaregiverEditorVisible: false,
      employeesFetch: false,
      isCandidate: false,
    };
    this.handleEditorClick = this.handleEditorClick.bind(this);
    this.handleEditorClose = this.handleEditorClose.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(hrDashboardActions.getDashboard());
    this.props.dispatch(eventActions.getEvents());
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(next) {
    const { eventToSelect } = next.trainingEvent;
    if (eventToSelect !== undefined) {
      this.setState({ selectedEventId: eventToSelect });
      this.props.dispatch(eventActions.clearEventToSelect());
    }
    if (!next.employee.loadingEmployee && !this.state.employeesFetch) {
      this.props.dispatch(caregiverActions.getTrainingCandidates());
      this.setState({ employeesFetch: true });
    }
  }

  // eslint-disable-next-line react/sort-comp
  handleEditorClick() {
    this.setState({ newCaregiverEditorVisible: true, isCandidate: true });
  }

  handleCGDetailsClose = () => {
    this.setState({ caregiverDetailId: null });
  };

  handleEditorClose = newCaregiver => {
    const newState = { newCaregiverEditorVisible: false };
    if (newCaregiver && newCaregiver.profile && newCaregiver.profile.id) {
      newState.caregiverDetailId = newCaregiver.profile.id;
      newState.caregiverDetailTab = 'prefAndSkills';
    }
    this.setState(current => ({ ...current, ...newState }));
  };

  circle = (num, color, background, order) => {
    const { loading } = this.props.dashboard;
    return (
      <div
        className={!this.state.employeesFetch ? 'circle' : `circle hr-dashboard-circle${order}`}
        style={{ display: loading ? 'none' : 'flex', borderColor: color, background }}
      >
        <div>
          <h1>{num}</h1>
        </div>
      </div>
    );
  };

  redirectToActiveCGCOVIDFilter = () => {
    const { dispatch } = this.props;
    window.scrollTo(0, 0);
    dispatch(filterActions.filterByCOVIDValidationCaregivers());
    history.push('/home/hr/caregivers');
  };

  redirectToPendingCaregivers = () => {
    const { dispatch } = this.props;
    window.scrollTo(0, 0);
    history.push('/home/hr/caregivers');
    dispatch(filterActions.filterByPendingCaregivers());
  };

  redirectToExpiredCaregivers = () => {
    const { dispatch } = this.props;
    window.scrollTo(0, 0);
    history.push('/home/hr/caregivers');
    dispatch(filterActions.filterByExpiredCaregivers());
  };

  redirectToActiveCG = () => {
    const { dispatch } = this.props;
    window.scrollTo(0, 0);
    history.push('/home/hr/caregivers', 'readyToWork');
    dispatch(filterActions.cleanAllHRFilters());
  };

  redirectToCandidates = () => {
    const { dispatch } = this.props;
    window.scrollTo(0, 0);
    history.push('/home/hr/candidates');
    dispatch(filterActions.cleanAllHRFilters());
  };

  completedCandidate = candidate => {
    const { activationLoading } = this.props;
    const { activatedCandidate } = this.state;
    const completeCaregiver = caregiverId => {
      this.setState({ activatedCandidate: caregiverId });
      this.props.dispatch(
        caregiverActions.completeCaregiverPrehire([caregiverId], { hrPortal: true })
      );
    };

    return (
      <Grid style={{ maxWidth: '100%' }} verticalAlign='middle'>
        <Grid.Column width={7}>
          <HRCaregiverNameCell
            location={this.props.location.pathname}
            caregiver={candidate}
            sourcePath='candidates'
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <p>{moment(candidate.complianceUpdated).format('MM/DD/YYYY')}</p>
        </Grid.Column>
        <Grid.Column width={3} floated='right'>
          <Button
            id='hr-dashboard-activate-button'
            content='Activate'
            basic
            color='green'
            size='mini'
            disabled={activationLoading}
            loading={activatedCandidate === candidate.id && activationLoading}
            onClick={() => completeCaregiver(candidate.id)}
          />
        </Grid.Column>
      </Grid>
    );
  };

  deleteEvent = event =>
    this.setState(
      s => ({ ...s, selectedEventId: null }),
      () => this.props.dispatch(eventActions.removeEvent(event))
    );

  render() {
    const { selectedEventId, employeesFetch } = this.state;

    const { employee, dashboard, trainingEvent, principal } = this.props;
    const { events, loadingEvents } = trainingEvent;
    const circleContainerStyle = { marginLeft: '32px', marginTop: '16px', height: '100%' };
    const {
      vaccineServiceEnabled,
      complianceEnabled,
      virtualTrainingsEnabled,
    } = principal.settings;

    const { caregiverNumbers, loading } = dashboard;

    const selectedEvent =
      events && selectedEventId ? events.find(e => e.id === selectedEventId) : null;

    const eventChanged = event => {
      this.setState({ createdSelected: false });
      const eventToSave = events.find(e => e.id === event.id);
      Object.assign(eventToSave, { event });
    };

    const eventSubmitted = event => {
      this.setState({ createdSelected: false });
      const eventToSave = events.find(e => e.id === event.id);
      Object.assign(eventToSave, { event });
    };

    const onSelect = event => {
      if (event.id !== selectedEventId) {
        this.setState({ selectedEventId: event.id, createdSelected: false });
      }
    };

    const hasActivation = this.props.candidates.candidates
      ? this.props.candidates.candidates.find(c => c.nonCompliantBlocks?.length === 0)
      : null;

    const dbLoading = loading || loadingEvents;

    return (
      <Grid className='contentGrid dashboard hrapp' padded style={{ width: '100%' }}>
        {dbLoading && (
          <Dimmer active inverted>
            <CareLoader loading={dbLoading} showText={false} />
          </Dimmer>
        )}
        {this.state.newCaregiverEditorVisible && (
          <CaregiverEditor
            onCloseClicked={this.handleEditorClose}
            isCandidate={this.state.isCandidate}
          />
        )}
        {this.state.caregiverDetailId && (
          <CaregiverDetails
            caregiverId={this.state.caregiverDetailId}
            onCloseClicked={this.handleCGDetailsClose}
            tab={this.state.caregiverDetailTab}
          />
        )}
        <Grid.Column
          width='three'
          className={!employeesFetch ? 'menuCol' : 'menuCol menuCol-fade-in'}
          style={{ display: loading ? 'none' : null }}
        >
          {complianceEnabled && <HRDashboardMenu />}
        </Grid.Column>

        <Grid.Column width='thirteen'>
          <NewUpdate />
          {!dbLoading && (
            <Dimmer.Dimmable dimmed={!complianceEnabled} blurring>
              <Dimmer active={!complianceEnabled} inverted>
                <div className='hr-disabled-dimmer'>
                  <p style={{ marginTop: '55px', fontSize: '16px', fontWeight: '700' }}>
                    CARECONNECT HR MANAGER
                  </p>

                  <Image centered src='/PrefsUpdate.svg' alt='hr module disabled' />
                  <p style={{ marginTop: '20px', fontSize: '14px', fontWeight: '700' }}>
                    We are working on getting HR Manager up and running for you...
                  </p>
                  <p
                    style={{
                      fontSize: '12px',
                      margin: '20px auto',
                      width: '50%',
                    }}
                  >
                    From HR Manager you will be able to manage your caregivers, calendar and much
                    more. We are currently working on getting the right information for you on the
                    module. Come back soon to use HR Manager.
                    {virtualTrainingsEnabled && (
                      <div
                        style={{
                          paddingTop: '30px',
                          display: 'grid',
                          gridTemplateColumns: 'auto auto',
                          margin: '5px',
                        }}
                      >
                        <div>
                          <Image style={{ float: 'right' }} src='/icon_info_blue.svg' />
                        </div>
                        <div style={{ paddingTop: '3px' }}>
                          <span
                            style={{
                              fontSize: '12px',
                              fontWeight: 'bold',
                              color: '#04a5d5',
                              float: 'left',
                            }}
                          >
                            Your agency has Virtual Training feature enabled. Please navigate to
                            Calendar view to manage your virtual training events. You can find
                            Calendar under HR Mgmt tab, like shown below:
                          </span>
                        </div>
                        <div />
                        <div style={{ paddingTop: '10px' }}>
                          <Image
                            style={{ margin: 'auto', width: '80%' }}
                            src='/virtual_training_nav_info.png'
                          />
                        </div>
                      </div>
                    )}
                  </p>
                </div>
              </Dimmer>

              <Grid>
                <Grid.Row className='welcomeRow'>
                  <Grid.Column width='16'>
                    <span
                      className={!employeesFetch ? '' : 'dashboard-welcome'}
                      style={{ display: loading ? 'none' : null }}
                    >
                      Welcome{employee && employee.employee && ', '}
                      {employee && employee.employee && (
                        <span id='hr-dashboard-employee-name' className='name'>
                          {employee.employee.firstName}
                        </span>
                      )}
                    </span>
                    <span
                      className={
                        !employeesFetch
                          ? 'dashboard-add-button-with-label'
                          : 'dashboard-add-button-with-label dashboard-add-button-with-label-fade-in'
                      }
                      style={{ paddingRight: 14, float: 'right', display: loading ? 'none' : null }}
                    >
                      <span>Add a New Candidate</span>
                      <CareRoundButton
                        id='hr-dashboard-add-candidate-button'
                        content='+'
                        onClick={this.handleEditorClick}
                      />
                    </span>
                  </Grid.Column>
                </Grid.Row>
                {caregiverNumbers && (
                  <Grid.Row style={{ minWidth: '968px', minHeight: '320px' }}>
                    <Grid.Column
                      id='dashboard-column'
                      className={!employeesFetch ? '' : 'circleContainer-fade-in'}
                      style={{ display: loading ? 'none' : null }}
                      width={4}
                    >
                      <div style={circleContainerStyle}>
                        <div style={{ display: 'flex', marginBottom: '12px' }}>
                          <h3
                            style={{
                              fontSize: '14px',
                              marginTop: 'auto',
                              marginBottom: 'auto',
                              marginRight: '24px',
                            }}
                          >
                            Candidates
                          </h3>
                          <Button
                            id='hr-dashboard-view-candidates-button'
                            content='View'
                            basic
                            color='green'
                            size='mini'
                            style={{ maxHeight: '30px', height: 'fit-content' }}
                            onClick={this.redirectToCandidates}
                          />
                        </div>
                        <p className='small gray' style={{ fontSize: '10px', marginRight: '24px' }}>
                          Potential employees currently in the pre-hire process
                        </p>
                        {this.circle(caregiverNumbers.candidates, '#09b4e7', null, 'first')}
                      </div>
                    </Grid.Column>
                    <Grid.Column
                      width={12}
                      style={
                        { marginLeft: '-24px', paddingLeft: '24px' } && loading
                          ? { opacity: '0.4' }
                          : { opacity: '1' }
                      }
                    >
                      <Grid
                        columns='equal'
                        className={!employeesFetch ? '' : 'circleContainer-fade-in'}
                        style={{
                          height: '100%',
                          margin: '0px',
                          padding: '0px',
                          display: loading ? 'none' : null,
                        }}
                      >
                        <Grid.Column
                          style={{
                            minWidth: '220px',
                            height: '100%',
                            margin: '0px',
                            padding: '0px',
                          }}
                        >
                          <div style={circleContainerStyle}>
                            <div style={{ display: 'flex', marginBottom: '12px' }}>
                              <h3
                                style={{
                                  fontSize: '14px',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  marginRight: '24px',
                                }}
                              >
                                Active Caregivers Pool
                              </h3>
                              <Button
                                id='hr-dashboard-view-caregivers-button'
                                content='View'
                                basic
                                color='green'
                                size='mini'
                                style={{ maxHeight: '30px', height: 'fit-content' }}
                                onClick={this.redirectToActiveCG}
                              />
                            </div>
                            <p
                              className='small gray'
                              style={{ fontSize: '10px', marginRight: '24px' }}
                            >
                              Caregivers who are currently in compliance and available for shift
                              matches
                            </p>
                            {this.circle(
                              caregiverNumbers.compliant,
                              '#69db8f',
                              '#69db8f',
                              'second'
                            )}
                          </div>
                        </Grid.Column>
                        <Grid.Column style={{ height: '100%', margin: '0px', padding: '0px' }}>
                          <div style={circleContainerStyle}>
                            <div style={{ display: 'flex', marginBottom: '12px' }}>
                              <h3
                                style={{
                                  fontSize: '14px',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  marginRight: '24px',
                                }}
                              >
                                Pending Expirations
                              </h3>
                              <Button
                                id='hr-dashboard-view-expirations-button'
                                content='View'
                                basic
                                color='green'
                                size='mini'
                                style={{ maxHeight: '30px', height: 'fit-content' }}
                                onClick={this.redirectToPendingCaregivers}
                              />
                            </div>
                            <p
                              className='small gray'
                              style={{ fontSize: '10px', marginRight: '24px' }}
                            >
                              Caregivers who have one or more ongoing compliance item expiring soon
                            </p>
                            {this.circle(caregiverNumbers.pending, '#f5a623', '#f5a623', 'third')}
                          </div>
                        </Grid.Column>
                        <Grid.Column style={{ height: '100%', margin: '0px', padding: '0px' }}>
                          <div style={circleContainerStyle}>
                            <div style={{ display: 'flex', marginBottom: '12px' }}>
                              <h3
                                style={{
                                  fontSize: '14px',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  marginRight: '24px',
                                }}
                              >
                                Out of compliance
                              </h3>
                              <Button
                                id='hr-dashboard-view-compliance-button'
                                content='View'
                                basic
                                color='green'
                                size='mini'
                                style={{ maxHeight: '30px', height: 'fit-content' }}
                                onClick={this.redirectToExpiredCaregivers}
                              />
                            </div>
                            <p
                              className='small gray'
                              style={{ fontSize: '10px', marginRight: '24px' }}
                            >
                              Caregivers with one or more ongoing compliance item expired
                            </p>
                            {this.circle(
                              caregiverNumbers.nonCompliant,
                              '#F16778',
                              '#F16778',
                              'fourth'
                            )}
                          </div>
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                )}
                <Divider />
                <Grid.Row stretched>
                  <Grid.Column
                    className={!employeesFetch ? '' : 'calendar-fade-in'}
                    style={{ minWidth: '472px', display: loading ? 'none' : null }}
                    width={8}
                  >
                    {vaccineServiceEnabled && (
                      <div className='covid-panel'>
                        <img src='/covid-icon-red.png' alt='covid red icon' />
                        <span>
                          <h3>Pending Coronavirus Vaccine Validations</h3>
                          <p>
                            <bold>{caregiverNumbers.covidValidating}</bold> uploaded certificates to
                            be validated
                          </p>
                        </span>
                        <GreenButton
                          onClick={this.redirectToActiveCGCOVIDFilter}
                          style={{ flex: 'flex-end' }}
                        >
                          Review All
                        </GreenButton>
                      </div>
                    )}
                    <div className='panel' style={{ marginBottom: '2em' }}>
                      <div style={{ display: 'flex', marginBottom: '14px', minWidth: '382px' }}>
                        <h3 style={{ color: '#364967' }}>
                          TODAY: <font color='#9b9b9b'>{moment().format(' ddd MMM Do YYYY')}</font>
                        </h3>
                        <Button
                          id='hr-dashboard-view-calendar-button'
                          content='View Calendar'
                          basic
                          color='green'
                          size='mini'
                          style={{
                            height: 'fit-content',
                            maxHeight: '30px',
                            position: 'absolute',
                            right: '40px',
                            top: `${vaccineServiceEnabled ? '120px' : '20px'}`,
                          }}
                          onClick={() => {
                            window.scrollTo(0, 0);
                            history.push('/home/hr/calendar');
                          }}
                        />
                      </div>
                      <HRCalendarView
                        defaultView='day'
                        minHeight='600px'
                        maxHeight='600px'
                        showToolbar={false}
                        events={events}
                        onSelect={onSelect}
                        disabled
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column
                    className={!employeesFetch ? '' : 'activation-list-fade-in'}
                    style={{ minWidth: '472px', display: loading ? 'none' : null }}
                    width={8}
                  >
                    <div className='panel'>
                      <h3 style={{ color: '#364967' }}>ACTIVATION LIST</h3>
                      <p className='gray' style={{ fontSize: '12px' }}>
                        The following candidates have completed their compliance items and are
                        awaiting activation to be added the active caregiver pool and available for
                        shift matches.
                      </p>
                      <Divider id='activation-list-divider' style={{ width: '100%' }} />

                      {this.props.candidates.candidates && [
                        !hasActivation && (
                          <div className='noActivation'>
                            <Image centered src='/candidate_no_activation.svg' />
                            <p>No candidates awaiting activation</p>
                          </div>
                        ),

                        hasActivation && (
                          <Grid>
                            <Grid.Column width={7}>
                              <p className='small gray'>Candidate</p>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <p className='small gray'>Last Updated</p>
                            </Grid.Column>
                            <Grid.Column width={3} floated='right'>
                              <p className='small gray' style={{ paddingRight: '60px' }}>
                                Action
                              </p>
                            </Grid.Column>
                          </Grid>
                        ),
                        <div
                          style={{
                            height: '85%',
                            marginTop: '12px',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                          }}
                        >
                          {this.props.candidates.candidates
                            .filter(c => c.nonCompliantBlocks?.length === 0)
                            .map(c => this.completedCandidate(c))}
                        </div>,
                      ]}
                    </div>
                  </Grid.Column>
                  <Modal
                    dimmed
                    style={{ marginTop: '-46vh' }}
                    open={selectedEvent}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    closeIcon
                    size='fullscreen'
                    className='hrapp'
                    onClose={() => this.setState({ selectedEventId: null })}
                  >
                    <Modal.Content>
                      <EventView
                        event={selectedEvent}
                        onChanged={eventChanged}
                        draft={false}
                        onSubmitted={eventSubmitted}
                        deleteEvent={this.deleteEvent}
                      />
                    </Modal.Content>
                  </Modal>
                </Grid.Row>
              </Grid>
            </Dimmer.Dimmable>
          )}
        </Grid.Column>
      </Grid>
    );
  }
}

HRDashboard.defaultProps = {
  activationLoading: false,
};

HRDashboard.propTypes = {
  location: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  employee: PropTypes.shape().isRequired,
  dashboard: PropTypes.shape().isRequired,
  trainingEvent: PropTypes.shape().isRequired,
  candidates: PropTypes.shape().isRequired,
  principal: PropTypes.shape({ settings: PropTypes.shape({ complianceEnabled: PropTypes.bool }) })
    .isRequired,
  activationLoading: PropTypes.bool,
};

const mapStateToProps = state => {
  const { principal, employee, hrDashboard, trainingEvent, candidatesTraining } = state;
  return {
    principal,
    employee,
    trainingEvent,
    dashboard: hrDashboard,
    candidates: candidatesTraining.trainingCandidates,
    activationLoading: candidatesTraining.activationLoading,
  };
};

export default connect(mapStateToProps)(injectIntl(HRDashboard));

import React from 'react';
import './ELearningUsersFilterForm.css';

type Props = {
  onValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
    value: string | undefined
  ) => void;
  value: string | undefined;
  placeHolder: string;
};

const ELearningUsersFilterForm: React.FC<Props> = ({
  value,
  onValueChange,
  onSubmit,
  placeHolder,
}) => {
  return (
    <form className='e-learning-user-filter-container'>
      <input
        type='text'
        className='e-learning-user-filter-input'
        placeholder={placeHolder}
        onChange={e => onValueChange(e)}
        value={value}
      />
      <input
        type='submit'
        className='e-learning-user-filter-button'
        onClick={e => onSubmit(e, value)}
        value='GO'
      />
    </form>
  );
};

export default ELearningUsersFilterForm;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Image, Checkbox, Divider, CheckboxProps, Dimmer } from 'semantic-ui-react';
import moment from 'moment';
import CGComplianceUIActions from '../../../actions/CGComplianceUIActions';
import { alertActions, caregiverActions } from '../../../actions';
import { ICaregiver } from '../../../reducers/types/caregiver';
import { BlockField, Blocks } from './ComplianceMedicalTypes';
import * as CF from './complianceHelpers';
import { dateFormat } from '../../../helpers/common';
import { RedButton } from '../../../styles/common';
import CareLoader from '../CareLoader';

const expirationDateFormat = 'YYYY-MM-DD';
const nextExpireLabel = 'Next Expires ';
const expired = 'EXPIRED ON ';
const completionDetailsLabel = 'SET REQUIRED COMPLETION DETAILS';
const completedLabel = 'COMPLETED:';
const completionDateLabel = 'Completion Date:';

interface IProps {
  block: Blocks;
  notEditable?: boolean;
}

interface IStore {
  caregiverDetails: any;
  caregiver: ICaregiver;
  caregiverCompliance: any;
  caregiverComplianceUI: any;
  employee: any;
}

const CommonComplianceBlock: React.FC<IProps> = ({ block, notEditable = false }) => {
  const store: IStore = useSelector((state: any) => {
    const { caregiverDetails, caregiverCompliance, caregiverComplianceUI, employee } = state;
    return {
      caregiverDetails,
      caregiver: caregiverDetails.caregiver,
      caregiverCompliance,
      caregiverComplianceUI,
      employee,
    };
  });
  const [showModal, setShowModal] = useState(false);
  const [docSource, setDocSource] = useState<string | null>(null);
  const [showResultEditPanel, setShowResultEditPanel] = useState(false);
  const [closeEnable, setCloseEnable] = useState(false);
  const [viewRemove, setViewRemove] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const dispatch = useDispatch();

  const hrManager = true;
  // if we want to prevent schedulers to change compliance values, just put this to hrManager
  //  (employee.employee && isHRManager(employee.employee.roles)) || employee.rootUser;

  const blockCompletion = block?.completed;

  const sortedFields = CF.sortField(block?.fields || []);

  const {
    caregiverComplianceUI: { dirtyFields },
  } = store;

  const ownDirtyFields = dirtyFields.filter((f: BlockField) =>
    block.fields.find(bF => bF.id === f.id)
  );

  const save = () => {
    const { caregiverDetails } = store;

    if (Object.keys(dirtyFields).length > 0) {
      const dirtyFieldsToSave: BlockField[] = [];
      dirtyFields.forEach((s: BlockField) => {
        const b = block.fields.find(a => a.id === s.id);
        if (b !== undefined) {
          if ((b.value != null && b.value !== s.value) || (b.value == null && s.value != null)) {
            dirtyFieldsToSave.push(s);
          }
        }
      });
      dispatch(
        caregiverActions.setCaregiverComplianceBlockFields(
          caregiverDetails.caregiver.id,
          dirtyFieldsToSave
        )
      );
    }
    const newDirtyFields = dirtyFields.filter(
      (f: BlockField) => !block.fields.find(bF => bF.id === f.id)
    );
    dispatch(CGComplianceUIActions.updateState({ dirtyFields: newDirtyFields }));
  };

  const handleSave = () => {
    save();
    addCompletion();
  };

  const blockChanged = (_e: React.FormEvent<HTMLInputElement>, input: CheckboxProps) => {
    // this.setCaregiverComplianceByProfile();
    const { caregiver } = store;
    dispatch(
      caregiverActions.setCaregiverComplianceByProfile(caregiver.id, {
        blockId: block?.id,
        requiredByCaregiverProfile: input.checked ? 1 : 0,
        preferenceId: block?.preferenceId,
      })
    );
  };

  const handleUploadDocument = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const { caregiver } = store;
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      dispatch(caregiverActions.uploadComplianceDocument(id, file, caregiver.id));
    }
    e.target.value = '';
  };

  const documentRemoved = (fieldId: number, filename: string) => {
    const { caregiver } = store;
    dispatch(caregiverActions.removeComplianceDocument(caregiver.id, fieldId, filename));
  };

  const documentClicked = (link: string) => {
    setShowModal(true);
    setDocSource(link);
  };

  const checkDoubleValid = (field: BlockField, value: string) => {
    const { caregiverComplianceUI } = store;
    const isNumber = typeof value === 'number';
    const tooLong = value.length > 6;
    const notValid = caregiverComplianceUI && caregiverComplianceUI.notValidValuesIds;
    if (!isNumber || tooLong) {
      if (!notValid.includes(field.id)) {
        notValid.push(field.id);
      }
    } else if (isNumber && !tooLong) {
      if (notValid.includes(field.id)) {
        const index = notValid.indexOf(field.id);
        notValid.splice(index, 1);
      }
    }

    return dispatch(
      CGComplianceUIActions.updateState({
        isNotValid: !!notValid.length,
        notValidValuesIds: [...notValid],
      })
    );
  };

  const fieldChanged = (field: BlockField, value: any) => {
    let changedValue = value;

    if (field.type === 'double') {
      checkDoubleValid(field, value);
      if (value.length === 0) {
        changedValue = null;
      }
    }

    let theField = dirtyFields.find((f: BlockField) => f.id === field.id);
    if (!theField) {
      theField = JSON.parse(JSON.stringify(field));
      dirtyFields.push(theField);
    }
    theField.value = changedValue;

    dispatch(CGComplianceUIActions.updateState({ dirtyFields }));
  };

  const toEmployeeList = (employees: any) =>
    [{ value: null, text: 'None' }].concat(
      employees.map((e: any) => ({
        employee: e,
        text: `${e.firstName} ${e.lastName}`,
        value: e.id,
      }))
    );

  const employees = toEmployeeList(store.employee.employeeList);

  const validate = () => {
    type ResultsType = {
      valid: boolean;
      errors: string[];
    };

    if (!block) return { valid: false, errors: [] };

    const result: ResultsType = { valid: block?.completed, errors: [] };
    if (result.valid) return result;

    // check that fields is fulfilled
    block?.fields.forEach(field => {
      if (!field.value || (field.type === 'document' && field.value.length < 1)) {
        result.errors.push(field.name as never);
      }
    });

    // check expiration date
    if (
      block?.expires &&
      ((block?.expirationDate &&
        !moment
          .utc(block?.expirationDate)
          .local()
          .isSameOrAfter(moment())) ||
        !block?.expirationDate)
    ) {
      result.errors.push('expirationDate' as never);
    }

    return result;
  };

  const getField = (field?: BlockField) => {
    if (!field) return null;
    const dirty = dirtyFields.find((f: BlockField) => f.id === field.id);
    if (dirty) {
      dirty.isDirty = true;
      return dirty;
    }
    return field;
  };

  const recurring =
    block?.expires && block?.expirationPeriod !== 'one_time' ? 'recurring' : 'one time ';

  const elearningBlockSource = () =>
    block?.payload && JSON.parse(block.payload).source === 'elearning';

  const isDirty = block?.fields
    ? block.fields.reduce((a, c) => (getField(c).isDirty ? true : a), false)
    : false;

  const { errors } = validate();

  const findValue = (id: number, notEditableValue = false) => {
    const selected = employees.find(e => e.value === id);
    if (selected) return notEditableValue ? selected.text : selected.value;
    return null;
  };

  const resultOptionMapping = (blockName: string) => {
    switch (blockName) {
      case 'Drug Screening':
        return [
          { text: 'Positive', value: '+' },
          { text: 'Negative', value: '-' },
          { text: 'Drug Attestation', value: 'DA' },
          { text: 'Disqualified', value: 'D' },
        ];
      case 'Rubella Test':
        return [
          { text: 'Pass', value: 'I' },
          { text: 'Fail', value: 'NI' },
        ];
      case 'Covid-19 Nasal Swab':
      case 'Covid-19 Antibody IgG':
        return [
          { text: 'Positive', value: 'Positive' },
          { text: 'Negative', value: 'Negative' },
          { text: 'Indeterminate', value: 'Indeterminate' },
        ];
      default:
        return [
          { text: 'Positive', value: '+' },
          { text: 'Negative', value: '-' },
        ];
    }
  };

  const fieldName = (name: string) => {
    if (name === 'Completion') {
      return completionDateLabel;
    }
    if (name === 'measure_expiration_date') {
      return 'Enter expiration date';
    }
    return name;
  };

  const editField = (field: BlockField, child: JSX.Element, notEditableField = false) => {
    return (
      <div
        className={`${
          field.type === 'document' ? 'two-columns-field' : ''
        } 'compliance-field-container'`}
      >
        <p
          className={`${
            errors.includes(field.name) || errors.includes('expirationDate')
              ? 'small red'
              : 'smallGray'
          }`}
        >
          {notEditableField && field.name === 'measure_expiration_date'
            ? 'Expiration Date'
            : fieldName(field.name)}
        </p>
        <div className='compliance-input-field'>{child}</div>
      </div>
    );
  };

  const renderField = (field: BlockField, readOnly = false, notEditableField = false) => {
    const {
      caregiverCompliance: { uploadDocument },
    } = store;
    const onDocumentUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
      handleUploadDocument(e, field.id);
    };
    switch (field.type) {
      case 'date':
        if (notEditableField || (field.name === 'Completion' && elearningBlockSource())) {
          return editField(
            field,
            <CF.DateText date={field.value || undefined} />,
            notEditableField
          );
        }
        return editField(
          field,
          <CF.ComplianceDatePicker
            state={getField(field)}
            field={field}
            disabled={readOnly}
            onChange={fieldChanged}
          />
        );
      case 'text':
        if (notEditableField) {
          return editField(field, <CF.ResultText value={field.value} group={field.mhFieldName} />);
        }
        if (field.name === 'Result') {
          return editField(
            field,
            <CF.ResultFields
              field={getField(field)}
              disabled={readOnly}
              handleChange={fieldChanged}
              resultsOptions={resultOptionMapping(block?.name)}
            />
          );
        }
        return editField(
          field,
          <CF.TextField
            field={getField(field)}
            state={getField(field)}
            disabled={readOnly}
            handleChange={fieldChanged}
          />
        );
      case 'double': {
        const stateField = getField(field);
        if (notEditableField) {
          return editField(field, <CF.ReadingText testReading={field.value} />);
        }
        return editField(
          field,
          <CF.ComplianceReading field={field} state={stateField?.value} onChange={fieldChanged} />
        );
      }
      case 'supervisor':
        if (notEditableField) {
          return editField(
            field,
            <CF.ReadingText testReading={findValue(field.value, true) || ''} />
          );
        }
        return editField(
          field,
          <CF.SupervisorField
            field={getField(field)}
            disabled={readOnly}
            employees={employees}
            findValue={findValue(getField(field).value)}
            handleChange={fieldChanged}
          />
        );
      case 'document':
        return editField(
          field,
          <CF.DocumentField
            field={field as CF.IDocumentField}
            documentClicked={documentClicked}
            documentRemoved={documentRemoved}
            readOnly={readOnly}
            uploadDocument={onDocumentUpload}
            uploadingDocument={uploadDocument}
          />
        );
      default:
        return <></>;
    }
  };

  const completionField = getField(
    block?.fields.find(
      f =>
        (block.expires &&
          block.expirationPeriod !== 'one_time' &&
          (block.expirationFieldName === f.name ||
            block.expirationFieldName === 'measure_caregiver_hire_date')) ||
        ((!block.expires || block.expirationPeriod === 'one_time') &&
          f.fixed &&
          f.type === 'date' &&
          (f.name === 'Completion' || f.name === 'Completion Date'))
    )
  );

  const closeModalClicked = () => {
    setShowModal(!showModal);
    setDocSource(null);
  };

  const toggleRemove = () => {
    setViewRemove(!viewRemove);
  };

  const handleRemove = () => {
    const { caregiver } = store;
    const dirtyFieldToClear: Array<CF.IDocumentField> = [];
    try {
      setRemoveLoading(true);
      block?.fields.forEach(f => {
        if (f.type === 'document' && f.value?.length) {
          f.value.forEach((v: any) => documentRemoved(f.id, v.filename));
        } else {
          dirtyFieldToClear.push({
            id: f.id,
            name: f.name,
            type: f.type,
            fixed: f.fixed,
            value: null,
            isDirty: true,
            downloadLinks: {},
          });
        }
      });
      dispatch(caregiverActions.setCaregiverComplianceBlockFields(caregiver.id, dirtyFieldToClear));
    } catch (error) {
      dispatch(alertActions.error(error));
    }
    setRemoveLoading(false);
    toggleRemove();
  };

  const addCompletion = () => {
    setShowResultEditPanel(!showResultEditPanel);
    setCloseEnable(!closeEnable);
  };

  const onClose = () => {
    setShowResultEditPanel(!showResultEditPanel);
    setCloseEnable(!closeEnable);
    const newDirtyFields = dirtyFields.filter(
      (f: BlockField) => !block.fields.find(bF => bF.id === f.id)
    );

    return dispatch(CGComplianceUIActions.updateState({ dirtyFields: newDirtyFields }));
  };

  const shouldShowInfoPanel = () => {
    const fixField = block?.fields.filter(f => f.fixed) || [];
    return fixField.some(f => (f.type === 'document' ? f.value?.length : f.value));
  };

  const showInfoPanel = shouldShowInfoPanel();

  const showRemoveAndEdit = () => {
    return (
      <div className='removeButton'>
        <span role='presentation' style={{ paddingRight: '10px' }} onClick={toggleRemove}>
          remove
        </span>
        <span role='presentation' onClick={addCompletion}>
          edit
        </span>
      </div>
    );
  };

  return (
    <div className='infoPanelMedicalBlock'>
      {removeLoading && (
        <Dimmer active inverted>
          <CareLoader loading showText={false} />
        </Dimmer>
      )}
      {showModal && (
        <Modal open onClose={closeModalClicked}>
          <Image src={docSource} />
        </Modal>
      )}
      {viewRemove && (
        <Modal open onClose={toggleRemove} closeOnDimmerClick={false} closeOnEscape size='tiny'>
          <Modal.Header
            style={{
              textAlign: 'center',
              backgroundColor: '#f16778',
              color: '#ffffff',
              fontSize: '14px',
            }}
          >
            <h3>Are you sure?</h3>
          </Modal.Header>
          <Modal.Content
            scrolling
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 1rem 0 3rem',
                width: '100%',
              }}
            >
              <p>
                This action will remove currently added information to the tile and it <br /> cannot
                be undone.
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignContent: 'center',
              backgroundColor: 'transparent',
            }}
          >
            <CF.GreenButton style={{ padding: '0 3rem 0 3rem' }} onClick={toggleRemove}>
              Cancel
            </CF.GreenButton>
            <RedButton style={{ padding: '0 3rem 0 3rem' }} onClick={handleRemove}>
              Remove
            </RedButton>
          </Modal.Actions>
        </Modal>
      )}
      <div className='compliances-tab-header'>
        <img src={`/compliance/${block?.icon}`} alt='compliance-icon' />
        <p className={blockCompletion ? 'blackTitle' : 'redTitle'} style={{ margin: '0' }}>
          {block?.name}
        </p>

        {!blockCompletion ? (
          <img
            src='/compliance/attention.svg'
            style={{ marginLeft: '10px' }}
            alt='attention-icon'
          />
        ) : (
          ''
        )}
      </div>

      <div className='compliance-medium-gray' style={{ margin: '0', paddingLeft: '35px' }}>
        <p style={{ display: 'inline-block', marginBottom: 0 }}>{block?.name} is a</p>
        {block?.expires && block?.expirationPeriod !== 'one_time' ? (
          <span style={{ paddingRight: '2px' }}>
            <b className='compliance-charcoal-text-color' style={{ paddingLeft: '4px' }}>
              {' '}
              {recurring}{' '}
            </b>{' '}
            item that repeats
            <b
              className='compliance-charcoal-text-color'
              style={{ paddingRight: '2px', paddingLeft: '4px' }}
            >
              {CF.getFrequencyName(block?.expirationPeriod || '')}
            </b>
            {block?.description ? (
              <span>
                <br />
                {block?.description} <br />
              </span>
            ) : (
              <></>
            )}
            <span>
              {block?.hasExpired ? (
                <span className='expiredBlockLabel'>
                  {expired}
                  {block?.expirationDate ? (
                    <CF.DateText
                      date={moment
                        .utc(block?.expirationDate)
                        .local()
                        .format(expirationDateFormat)}
                    />
                  ) : null}
                  .
                </span>
              ) : (
                <p style={{ display: 'inline-block', paddingRight: '5px', marginBottom: 0 }}>
                  {nextExpireLabel}
                  <b className='compliance-charcoal-text-color'>
                    {block?.expirationDate ? (
                      <CF.DateText
                        date={moment
                          .utc(block?.expirationDate)
                          .local()
                          .format(expirationDateFormat)}
                      />
                    ) : null}
                    .
                  </b>
                </p>
              )}
            </span>
          </span>
        ) : (
          <span style={{ paddingRight: '1px' }}>
            <b className='compliance-charcoal-text-color'> {recurring} </b> requirement needed to be
            in compliance to work.{' '}
            {block?.description ? (
              <span>
                <br />
                {block?.description} <br />
              </span>
            ) : (
              <></>
            )}
            <br />
            {block?.expires && block?.expirationPeriod === 'one_time' && block?.lagDays && (
              <span className={!completionField.value ? 'red bold' : ''}>
                Must complete before:{' '}
                {block?.lagDate ? (
                  <span style={{ paddingLeft: '0px', fontSize: '10px' }}>
                    {moment(block?.lagDate).format(dateFormat)}
                  </span>
                ) : (
                  'N/A'
                )}
              </span>
            )}
          </span>
        )}
      </div>

      {!showResultEditPanel && !showInfoPanel && (
        <CF.SimpleButton onClick={addCompletion}>Add Completion Details</CF.SimpleButton>
      )}
      {closeEnable && <Image className='tinyCloseButton' src='/close-icon.svg' onClick={onClose} />}
      {showResultEditPanel && (
        <div
          className={`hep-b-test-series-one-container compliance-add-completion-panel ${
            isDirty ? 'dirtyField' : ''
          }`}
        >
          <h3 className='compliance-add-completion-panel-header'>{completionDetailsLabel}</h3>
          <div className='compliance-fields'>
            {sortedFields.map(f => {
              return (
                <React.Fragment key={f.id}>
                  {renderField(f, !hrManager, notEditable)}
                </React.Fragment>
              );
            })}
          </div>
          <div className='compliance-update-container'>
            <CF.UpdateButton disabled={!ownDirtyFields.length} update={handleSave} />
          </div>
        </div>
      )}
      {showInfoPanel && !showResultEditPanel && (
        <div className='hep-b-test-series-one-container compliance-info-panel'>
          <div className='compliance-fields'>
            {sortedFields.map(f => {
              return <React.Fragment key={f.id}>{renderField(f, false, true)}</React.Fragment>;
            })}
          </div>

          <div className='compliance-buttons-container'>
            {block?.completed && block?.required && (
              <p className='block-result-text' style={{ color: '#70DC94', margin: '0' }}>
                <Image
                  src='/compliance/check.svg'
                  style={{ paddingRight: '5px', display: 'inline' }}
                />
                {completedLabel}{' '}
                <CF.DateText
                  date={moment
                    .utc(completionField.value)
                    .local()
                    .format(expirationDateFormat)}
                />
              </p>
            )}
            {showRemoveAndEdit()}
          </div>
        </div>
      )}

      {block?.controlledInProfile && !closeEnable && (
        <>
          <Divider />
          <div style={{ margin: '0px', paddingLeft: '35px' }}>
            <div style={{ marginBottom: '10px' }}>
              Is this Item required for this Caregiver
              <Checkbox
                style={{ marginLeft: '90px', marginBottom: '-5px' }}
                toggle
                checked={block?.requiredByCaregiverProfile}
                onChange={blockChanged}
                id={block?.id}
              />
            </div>
            <p className='smallGray' style={{ width: '360px' }}>
              When set to “NO” it will not impact in the caregivers complaince needs, and will
              automatically eliminate them from shifts requiring this item.
            </p>
          </div>
        </>
      )}
    </div>
  ); // close return
};

export default CommonComplianceBlock;

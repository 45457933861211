import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Dimmer } from 'semantic-ui-react';
import SubTabHeader from './subcomponents/SubTabHeader';
import { ELManageActions } from '../actions/ELApp/ELManageActions';
import ProgramTile from './subcomponents/eLAPP/ProgramsTile';
import ELearningProfileAssignments from './subcomponents/ELearningProfileAssignments';
import CareLoader from './subcomponents/CareLoader';
import './CGeLearning.css';

class CGeLearning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programsFetched: false,
      courseChecked: [],
      programWithCurriculums: [],
    };
  }

  componentDidMount() {
    const { onActive, dispatch, tabIndex } = this.props;
    onActive(tabIndex);
    dispatch(ELManageActions.clearCurriculumsAndPrograms());
  }

  // eslint-disable-next-line react/sort-comp
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !nextProps.eLManage?.traineePrograms?.length &&
      nextProps.caregiverDetails?.caregiver?.uuid &&
      !prevState.programsFetched
    ) {
      nextProps.dispatch(
        ELManageActions.getTraineeProgramsAndCurriculums(nextProps.caregiverDetails.caregiver.uuid)
      );
      return { programsFetched: true };
    }
    if (
      nextProps.eLManage?.traineePrograms?.length &&
      !nextProps.eLManage?.clear &&
      prevState.programsFetched &&
      !prevState.curriculumsPareToPrograms
    ) {
      const programWithCurriculums = nextProps.eLManage.traineePrograms.map(p => {
        const curriculums = nextProps.eLManage.traineeCurriculums.filter(
          c => c.clientProgramId === p.id
        );
        return {
          program: p,
          curriculums,
        };
      });
      return { curriculumsPareToPrograms: true, programWithCurriculums };
    }
    return null;
  }

  handleSaveUpdates = changes => {
    const { dispatch } = this.props;
    dispatch(ELManageActions.handleCourseExemption(changes));
    return this.setState(s => ({ ...s, courseChecked: [] }));
  };

  handleCourseRequiredChange = (curriculumId, userCourseId, courseCurriculums) => {
    const { courseChecked } = this.state;
    const newCourseChecked = courseChecked;
    const isCourseChecked = newCourseChecked.find(c => c.userCourseId === userCourseId);
    if (isCourseChecked) {
      const coursesCheckedFiltered = newCourseChecked.filter(c => c.userCourseId !== userCourseId);
      return this.setState(s => ({ ...s, courseChecked: coursesCheckedFiltered }));
    }
    const courseExempted = courseCurriculums.filter(c => c.curriculumId === curriculumId)[0];
    const checkedCourse = {
      curriculumId,
      userCourseId,
      exempt: courseExempted.exemption,
    };
    newCourseChecked.push(checkedCourse);
    return this.setState(s => ({ ...s, courseChecked: newCourseChecked }));
  };

  render() {
    const { programWithCurriculums, courseChecked } = this.state;
    const { userCourses, eLManage, intl, caregiverDetails, dispatch } = this.props;

    return (
      <div className='caregiver-records-e-learning'>
        {eLManage.loading && (
          <Dimmer active page>
            <CareLoader loading={eLManage.loading} invert showText={false} />
          </Dimmer>
        )}
        <SubTabHeader
          text={intl.formatMessage({ id: 'caregivers.records.elearning' }).toUpperCase()}
          buttonTitle={intl.formatMessage({ id: 'records.saveUpdates' })}
          buttonOnClick={() => this.handleSaveUpdates(courseChecked)}
          buttonDisabled={!courseChecked.length}
        />

        <div className='caregiver-records-e-learning-container'>
          <ProgramTile
            programWithCurriculums={programWithCurriculums}
            loading={eLManage.loading}
            agencyPrograms={eLManage.agencyPrograms}
            disabledPrograms={eLManage.disabledPrograms}
            disabledCurriculums={eLManage.disabledCurriculums}
            profileId={caregiverDetails.caregiver.uuid}
            dispatch={dispatch}
            programCurriculums={eLManage.programCurriculums}
            courses={userCourses.courses}
            coursesLoading={eLManage.coursesLoading}
            handleCourseRequiredChange={this.handleCourseRequiredChange}
            courseChecked={courseChecked}
            updateInProgress={eLManage.updateInProgress}
            userId={caregiverDetails.caregiver.userId}
          />
          <ELearningProfileAssignments
            className='caregiver-e-learning-courses'
            courses={userCourses.courses}
            loading={userCourses.loading}
          />
        </div>
      </div>
    );
  }
}

CGeLearning.propTypes = {
  onActive: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  userCourses: PropTypes.shape().isRequired,
  eLManage: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.shape().isRequired,
  caregiverDetails: PropTypes.shape().isRequired,
};

const mapStateToProps = state => {
  const { eLManage, caregiverDetails } = state;
  return {
    eLManage,
    caregiverDetails,
  };
};

export default connect(mapStateToProps)(injectIntl(CGeLearning));

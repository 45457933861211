import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Form, Dropdown } from 'semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import { beginningSearch } from '../../constants/Formats';
import { RecordsForm } from '../../styles/records';
import '../CaregiverRecords.css';

class RecordsLanguages extends React.Component {
  render() {
    const { person, intl, onValueChange, editable, languages, editability, type } = this.props;

    const primaryId = (person.languages
      ? person.languages.find(i => i.value === 'Y' && i.primary) || {}
      : {}
    ).id;

    const advancedIds = (person.languages
      ? person.languages.filter(i => i.value === 'Y' && !i.primary)
      : []
    ).map(i => i.id);

    const primaryOptions = languages.map(i => ({ value: i.value, text: i.text }));
    const advancedOptions = primaryOptions.filter(i => i.value !== primaryId);

    const mergeValues = (primary, advanced) => {
      const idx = primary != null ? advanced.indexOf(primary) : -1;
      if (idx > -1) {
        advanced.splice(idx, 1);
      }
      const priList = primary != null ? [{ id: primary, value: 'Y', primary: true }] : [];
      return advanced.map(x => ({ id: x, value: 'Y', primary: false })).concat(priList);
    };
    const languagesEditable = type === 'caregiver' ? editability.languages : editable;

    return (
      <Grid.Row>
        <RecordsForm>
          <Form.Field className='detailsBox'>
            <label htmlFor='primary' style={{ color: 'grey' }}>
              {intl.formatMessage({ id: 'caregivers.records.primaryLanguage' })}
            </label>
            <Dropdown
              id='records-language-primary-language'
              name='primary'
              placeholder={intl.formatMessage({ id: 'caregivers.records.primaryLanguage' })}
              selection
              fluid
              disabled={!languagesEditable}
              search={beginningSearch}
              upward
              options={primaryOptions}
              value={primaryId}
              onChange={(e, { value }) =>
                onValueChange(e, { value: mergeValues(value, advancedIds), name: 'languages' })
              }
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor='advanced' style={{ color: 'grey' }}>
              {intl.formatMessage({ id: 'caregivers.records.advancedLanguages' })}
            </label>
            <Dropdown
              id='records-language-advance-languages'
              className={languagesEditable ? 'vertical-line' : null}
              name='advanced'
              placeholder={intl.formatMessage({ id: 'caregivers.records.advancedLanguages' })}
              fluid
              selection
              multiple
              disabled={!languagesEditable}
              search={beginningSearch}
              upward
              options={advancedOptions}
              value={advancedIds}
              onChange={(e, { value }) =>
                onValueChange(e, { value: mergeValues(primaryId, value), name: 'languages' })
              }
            />
          </Form.Field>
        </RecordsForm>
      </Grid.Row>
    );
  }
}

RecordsLanguages.defaultProps = {
  editable: true,
  editability: {},
  type: '',
};

RecordsLanguages.propTypes = {
  editable: PropTypes.bool,
  editability: PropTypes.arrayOf(PropTypes.shape()),
  person: PropTypes.shape().isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  intl: intlShape.isRequired,
  onValueChange: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default injectIntl(RecordsLanguages);

import React from 'react';
import PropTypes from 'prop-types';
import './PrimaryContacts.css';

const PrimaryContacts = ({ object }) => (
  <div className='primary-contact-container'>
    <h4 className='primary-contact-header tiny-header' size='tiny' floated='left'>
      Primary Contact:
    </h4>
    <p
      className='primary-contact-name primary-contact-text'
      id='primary-contact-not-editable-header'
      size='tiny'
    >
      {object.primaryContact ? object.primaryContact.employeeName : '-'}
    </p>
    <hr className='primary-contact-separator' />
    <h4 className='primary-contact-manager-header tiny-header' size='tiny' floated='left'>
      Primary Contact Manager:
    </h4>
    <p
      className='primary-contact-manager-name primary-contact-text'
      id='primary-contact-manager-not-editable-header'
      size='tiny'
    >
      {object.manager ? object.manager.employeeName : '-'}
    </p>
  </div>
);

PrimaryContacts.propTypes = {
  object: PropTypes.shape().isRequired,
};

export default PrimaryContacts;

/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Segment, Grid, Modal, Dimmer, Button, Header, Divider } from 'semantic-ui-react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { history } from '../helpers';
import { getClientStatusData } from '../constants/DomainTypes';
import { clientActions, uiStateActions } from '../actions';
import { CovidSurveyActions } from '../actions/covid/covidSurveyActions';
import ClientInformation from './ClientInformation';
import ClientAgencyInformation from './ClientAgencyInformation';
import ClientRequirements from './ClientRequirements';
import CareLoader from './subcomponents/CareLoader';
import LeftRail from './subcomponents/LeftRail';
import SubTabButton from './subcomponents/SubTabButton';
import UnsavedChangesModal from './UnsavedChangesModal';
import { formatPhoneNumbers, MOMENT_DOB, EMAIL_LIST_PATTERN, isFloat } from '../constants/Formats';

class ClientRecords extends React.Component {
  static validateInputs(person) {
    // since each tab defines 1..n form elements, and save operation is common -> just in case
    // validate some fields before sending request to back-end
    const errorFields = [];

    if (person.notificationRecipients && !person.notificationRecipients.match(EMAIL_LIST_PATTERN)) {
      errorFields.push('notificationRecipients');
    }

    if (person.lastName == null || (person.lastName != null && person.lastName.trim().length < 2)) {
      errorFields.push('names');
    }

    return errorFields.length === 0;
  }

  constructor() {
    super();
    this.state = {
      clientDetailId: null,
      activeSubTab: 0,
      unsavedTabChange: null,
      client: null,
      isAdmin: false,
    };
    this.backupClient = {};
    this.saveChanges = () => {};
    this.onSubTabClick = this.onSubTabClick.bind(this);
    this.applyChangesClick = this.applyChangesClick.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onAgencyChange = this.onAgencyChange.bind(this);
    this.cancelNavigation = this.cancelNavigation.bind(this);
    this.discardChanges = this.discardChanges.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onActive = this.onActive.bind(this);
    this.onRemoveClient = this.onRemoveClient.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { clientId } = ((this.props || {}).match || {}).params || {};
    this.props.dispatch(clientActions.getClient(clientId));
  }

  componentDidMount() {
    if (this.props.employeeRoles?.length) {
      this.checkUserRole(this.props.employeeRoles);
    }
    const { clientId } = ((this.props || {}).match || {}).params || {};
    this.props.dispatch(CovidSurveyActions.getClientSurvey(clientId));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.clientDetails && !nextProps.clientDetails.loading) {
      const { client } = nextProps.clientDetails;
      if (client && client !== this.props.clientDetails.client) {
        this.backupClient = JSON.parse(JSON.stringify(client));
        this.storeClient(client);
      } else {
        // this.props.onCloseClicked();
      }
    }
  }

  checkUserRole = roles => {
    const isAdmin = roles.find(r => r === 'admin');
    const ELearn = roles.find(r => r === 'elearn');
    if (isAdmin) {
      this.setState({ isAdmin: true });
      if (ELearn) {
        this.setState({ isElearnAdmin: true });
      }
    }
  };

  onSubTabClick = tabIndex => {
    const { match } = this.props;

    let link = '/';
    switch (tabIndex) {
      case ClientRecords.tabs.delete:
        this.RemoveClientClick();
        return;
      case ClientRecords.tabs.information:
        link = '/information';
        break;
      case ClientRecords.tabs.agency:
        link = '/agency';
        break;
      case ClientRecords.tabs.requirements:
        link = '/requirements';
        break;
      default:
        link = '/information';
    }
    history.push(`${match.url}${link}`);
  };

  onValueChange(e, input) {
    if (!e) {
      return;
    }

    this.setState(s => {
      const setValue = (path, obj, value) => {
        const key = path.shift();
        if (path.length > 0) {
          setValue(path, obj[key], value);
        } else {
          // eslint-disable-next-line no-param-reassign
          obj[key] = value?.length || Number.isInteger(value) || isFloat(value) ? value : null;
        }
      };

      const newState = { ...s };
      // make new object to force render
      newState.client = { ...newState.client };
      // eslint-disable-next-line no-underscore-dangle
      if (e._isAMomentObject) {
        setValue(['dob'], newState.client, e.format(MOMENT_DOB));
      } else if (input.type === 'checkbox') {
        setValue(input.name.split('.'), newState.client, input.checked ? 'Y' : 'N');
      } else {
        setValue(input.name.split('.'), newState.client, input.value);
      }

      const isChanged = JSON.stringify(newState.client) !== JSON.stringify(newState.original);
      const isValid = ClientRecords.validateInputs(newState.client);
      const statusChange = newState.client.status !== newState.original.status;

      return Object.assign(newState, { isChanged, isValid, statusChange });
    });
  }

  onAgencyChange(e, data) {
    if (!e) {
      return;
    }

    this.setState(s => {
      const setValue = (path, obj, value) => {
        const key = path.shift();
        if (path.length > 0) {
          setValue(path, obj[key], value);
        } else {
          // eslint-disable-next-line no-param-reassign
          obj[key] = value?.length || Number.isInteger(value) ? value : null;
        }
      };
      const newState = { ...s };

      if (data) {
        if (data.name === 'primaryContactId') {
          setValue(['primaryContactId'], newState.client, data.value);
        } else if (data.name === 'managerId') {
          setValue(['managerId'], newState.client, data.value);
        } else if (data.name === 'notificationRecipients') {
          setValue(['notificationRecipients'], newState.client, data.value);
        }
      }

      const isValid = ClientRecords.validateInputs(newState.client);

      return Object.assign(newState, { isChanged: true, isValid });
    });
  }

  onActive(tabIndex) {
    if (this.state.isChanged && this.state.activeSubTab !== tabIndex) {
      this.setState({ unsavedTabChange: tabIndex });
    } else {
      this.setState({ activeSubTab: tabIndex });
    }
  }

  onBack = () => {
    history.push('/home/clients/');
  };

  onNoteClose = () => {
    this.setState({
      isModalOpen: false,
      text: null,
    });
  };

  onRemoveClient() {
    const { dispatch, uiState } = this.props;
    const { clientId } = ((this.props || {}).match || {}).params || {};
    dispatch(clientActions.removeClient(clientId, uiState.clientStatusFilter));
    history.push('/home/clients');
  }

  storeClient(client) {
    this.setState({
      client,
      original: {
        ...client,
        address: { ...client.address },
        // casePref: { ...client.casePref },
        phones: client.phones ? client.phones.map(p => ({ ...p })) : [],
        caregiverExclusion: client.caregiverExclusion || [],
        // customFields: client.customFields && JSON.parse(JSON.stringify(client.customFields)),*/
      },
      isChanged: false,
      isValid: false,
    });
  }

  applyChangesClick = () => {
    // casePref update is using same model with mobile API, modify payload before request...
    // const considerations = this.state.caregiver.preferences;
    // const casePref = Object.assign({}, this.state.caregiver.casePref, { considerations });
    // delete this.state.caregiver.preferences;
    let setReferral = false;
    let setStart = false;
    let setHold = false;
    let setDischarge = false;
    let setCancel = false;
    if (this.state.statusChange === true) {
      switch (this.state.client.status) {
        case '01':
          setReferral = true;
          break;
        case '02':
          setStart = true;
          break;
        case '03':
          setHold = true;
          break;
        case '04':
          setDischarge = true;
          break;
        case '05':
          setCancel = true;
          break;
        default:
          setStart = true;
      }
    }

    this.props.dispatch(
      clientActions.updateClient({
        ...this.state.client,
        email: this.state.client.email || null,
        ssn: null,
        phones:
          this.state.client.phones &&
          formatPhoneNumbers(this.state.client.phones.filter(p => p.number)),
        caregiverExclusion: this.state.client.caregiverExclusion || [],
        notes: this.state.client.notes || null,
        // casePref,
        referralDate: setReferral ? moment.tz(null).format('YYYY-MM-DD') : null,
        startDate: setStart ? moment.tz(null).format('YYYY-MM-DD') : null,
        holdDate: setHold ? moment.tz(null).format('YYYY-MM-DD') : null,
        dischargeDate: setDischarge ? moment.tz(null).format('YYYY-MM-DD') : null,
        cancelDate: setCancel ? moment.tz(null).format('YYYY-MM-DD') : null,
      })
    );
  };

  section = () => {
    const { original, client, isChanged, isValid } = this.state;
    const { languages, dispatch, employees, settings } = this.props;
    const { clientSurvey } = this.props.covidSurvey;
    const surveyResults = clientSurvey?.results;

    const editable = client.source !== 'sam' && client.source !== 'hcp';
    const { editableAttributes } = settings;
    const { match } = this.props;

    return (
      <Router history={history}>
        <Switch>
          <Route
            exact
            path={`${match.url}`}
            render={() => <Redirect to={`${match.url}/information`} />}
          />
          <PrivateRoute
            path={`${match.url}/information`}
            component={ClientInformation}
            redirectTo={`${match.url}`}
            componentProps={{
              isAdmin: this.state.isAdmin,
              surveyResults,
              surveyEnabled: settings?.clientCovidSurveyEnabled,
              original,
              client,
              dispatch,
              onValueChange: this.onValueChange,
              onSave: this.applyChangesClick,
              saveEnabled: isChanged && isValid,
              editable,
              editableAttributes,
              onActive: this.onActive,
              tabIndex: ClientRecords.tabs.information,
            }}
            dispatch={this.props.dispatch}
          />
          <PrivateRoute
            path={`${match.url}/agency`}
            component={ClientAgencyInformation}
            componentProps={{
              original,
              client,
              employees,
              onAgencyChange: this.onAgencyChange,
              onValueChange: this.onValueChange,
              onSave: this.applyChangesClick,
              saveEnabled: isChanged && isValid,
              editable,
              onActive: this.onActive,
              tabIndex: ClientRecords.tabs.agency,
            }}
            dispatch={this.props.dispatch}
          />
          <PrivateRoute
            path={`${match.url}/requirements`}
            component={ClientRequirements}
            componentProps={{
              client,
              languages,
              dispatch,
              onValueChange: this.onValueChange,
              onSave: this.applyChangesClick,
              saveEnabled: isChanged && isValid,
              editable,
              onActive: this.onActive,
              tabIndex: ClientRecords.tabs.requirements,
            }}
            dispatch={this.props.dispatch}
          />
        </Switch>
      </Router>
    );
  };

  cancelNavigation = () => {
    this.onSubTabClick(this.state.activeSubTab);
    this.setState({ unsavedTabChange: null });
  };

  discardChanges = () => {
    const unsaved = this.state.unsavedTabChange;
    this.storeClient(JSON.parse(JSON.stringify(this.backupClient)));
    this.onSubTabClick(unsaved);

    this.setState({ activeSubTab: unsaved, unsavedTabChange: null });
  };

  RemoveClientClick = () => {
    const { client } = this.props.clientDetails;

    this.setState({
      isModalOpen: true,
    });
    const { upcomingShifts } = client;
    if (upcomingShifts.length > 0) {
      this.setState({ text: `Client has ${upcomingShifts.length} upcoming shifts` });
    } else {
      this.setState({ text: null });
    }
  };

  render() {
    const { intl } = this.props;

    const { activeSubTab, client, unsavedTabChange } = this.state;
    const { loading } = this.props.clientDetails;

    if (loading || !client) {
      return (
        <Dimmer active page>
          <CareLoader loading={loading} invert showText={false} />
        </Dimmer>
      );
    }
    const tabs = [
      {
        id: ClientRecords.tabs.information,
        icon: SubTabButton.icons.information,
        labelId: 'clients.records.information',
      },
      {
        id: ClientRecords.tabs.agency,
        icon: SubTabButton.icons.agency,
        labelId: 'clients.records.agencyInformation',
      },
      {
        id: ClientRecords.tabs.requirements,
        icon: SubTabButton.icons.preferences,
        labelId: 'clients.records.clientReqs',
      },
    ];
    if (client.source !== 'sam' && client.source !== 'hcp') {
      tabs.push({
        isBreak: true,
      });
      tabs.push({
        id: ClientRecords.tabs.delete,
        icon: SubTabButton.icons.delete,
        labelId: 'clients.records.deleteClient',
      });
    }
    return (
      <div>
        <>
          {unsavedTabChange != null && (
            <UnsavedChangesModal onCancel={this.cancelNavigation} onDiscard={this.discardChanges} />
          )}
          <Grid.Row className='noVerticalPadding caregiverRecords'>
            <Grid padded style={{ width: '100%' }} className='contentGrid'>
              <Grid.Column width='three' className='menuCol'>
                <Segment basic style={{ height: '3rem' }}>
                  <span
                    id='client-records-back-text'
                    className='overlayLink backTotext'
                    role='presentation'
                    onClick={this.onBack}
                  >
                    {intl.formatMessage({ id: 'clients.records.button.back' })}
                  </span>
                </Segment>
                <LeftRail
                  person={{
                    ...client,
                    clientStatus: getClientStatusData(client),
                  }}
                  tabs={tabs}
                  clientrecord={{
                    ...client,
                    clientStatus: getClientStatusData(client),
                  }}
                  onSubTabClick={this.onSubTabClick}
                  activeSubTab={activeSubTab}
                />
                <Segment basic style={{ font: 'lato', color: '#A9ADB4', fontSize: '10px' }}>
                  {`${intl.formatMessage({
                    id: 'clients.records.created',
                  })}  ${client.createdDate}`}
                </Segment>
              </Grid.Column>
              <Grid.Column width='13'>{this.section()}</Grid.Column>
            </Grid>
          </Grid.Row>
        </>

        <Modal
          closeOnDimmerClick={false}
          open={this.state.isModalOpen}
          closeIcon
          size='tiny'
          className='invitationModel'
          text={this.state.text}
          onClose={this.onNoteClose}
          style={{ marginTop: '-20vh' }}
        >
          <Modal.Header>
            <Header textAlign='center' as='h2'>
              Remove Client ?
            </Header>
          </Modal.Header>
          ,
          <Divider />,
          <Modal.Content>
            <Grid centered>
              <Grid.Column width={14}>
                <Segment basic>Are you sure to delete the client ?</Segment>

                <Segment basic>{this.state.text}</Segment>
              </Grid.Column>
            </Grid>
          </Modal.Content>
          ,
          <Modal.Actions>
            <Grid centered>
              <Grid.Column width={14} textAlign='center'>
                <Button
                  id='client-records-remove-client-yes-button'
                  onClick={this.onRemoveClient}
                  className='care-green'
                >
                  YES
                </Button>
                <Button
                  id='client-records-remove-client-cancel-button'
                  style={{ marginLeft: '10px' }}
                  onClick={this.onNoteClose}
                  className='care-red'
                >
                  Cancel
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

ClientRecords.defaultProps = {};

ClientRecords.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  uiState: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  clientDetails: PropTypes.shape().isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  employees: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  settings: PropTypes.shape().isRequired,
  employeeRoles: PropTypes.arrayOf(PropTypes.strings).isRequired,
  covidSurvey: PropTypes.shape().isRequired,
};

ClientRecords.tabs = {
  information: 0,
  agency: 1,
  requirements: 2,
  delete: 3,
};

const mapStateToProps = state => {
  const { clientDetails, covidSurvey } = state;
  const { languages, roles } = state.principal;

  return {
    clientDetails,
    languages,
    employees: state.employee.employeeList,
    settings: state.principal.settings,
    employeeRoles: roles,
    covidSurvey,
  };
};

export default connect(uiStateActions.mapStateOfGroupToProps('clientsFilter', mapStateToProps))(
  injectIntl(ClientRecords)
);

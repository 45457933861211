import {
  CREATE_EMPLOYEE_REQUEST,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_CLEAR,
  INVITE_EMPLOYEE_REQUEST,
  INVITE_EMPLOYEE_SUCCESS,
} from '../constants/ActionTypes';

import { prepareEmployeeObject } from './helpers';

const initialState = {
  loading: false,
  done: false,
  inviting: false,
};

function employeeEditor(state = initialState, action) {
  switch (action.type) {
    case CREATE_EMPLOYEE_REQUEST:
      return { ...state, loading: true, done: false };
    case CREATE_EMPLOYEE_SUCCESS: {
      const employee = prepareEmployeeObject(action.results);
      return { ...state, done: true, loading: false, employee };
    }
    case CREATE_EMPLOYEE_FAILURE:
      return { ...state, loading: false, done: false };
    case CREATE_EMPLOYEE_CLEAR:
      return { ...state, done: false, employee: null };
    case INVITE_EMPLOYEE_REQUEST: {
      const tmp = { ...state.invitingEmployee, [action.employeeId]: true };
      return {
        ...state,
        invitingEmployee: tmp,
      };
    }
    case INVITE_EMPLOYEE_SUCCESS: {
      const tmp = { ...state.invitingEmployee, [action.employeeId]: false };
      return {
        ...state,
        invitingEmployee: tmp,
      };
    }
    default:
      return state;
  }
}

export default employeeEditor;

/* eslint-disable import/prefer-default-export */
import * as types from '../constants/ActionTypes';
import { backend } from '../services';
import { alertActions } from './alert';

function getEvents(params) {
  return dispatch => {
    dispatch(request());

    backend.getEvents(params).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_EVENTS_REQUEST };
  }
  function success(results) {
    return { type: types.GET_EVENTS_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_EVENTS_FAILURE, error };
  }
}

function createEvent(params) {
  return dispatch => {
    dispatch(request());

    backend.createEvent(params).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.CREATE_EVENT_REQUEST };
  }
  function success(results) {
    return { type: types.CREATE_EVENT_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.CREATE_EVENT_FAILURE, error };
  }
}

export const eventLogActions = {
  getEvents,
  createEvent,
};

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import { Modal, Dimmer, Header, Button, Grid, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Detector } from 'react-detect-offline';
import 'react-datepicker/dist/react-datepicker.css';
import { MOMENT_DOB, isFloat } from '../constants/Formats';
import { caregiverActions, uiStateActions, alertActions } from '../actions';
import CareEditorTabs from './subcomponents/CareEditorTabs';
import CCEditorInfo from './CCEditorInfo';
import CCEditorAgency from './CCEditorAgency';
import CareLoader from './subcomponents/CareLoader';
import './Common.css';
import './Clients.css';
import { agencySettingsActions } from '../actions/agencySettings';
import { config } from '../helpers/config';
import { GoBackButton } from '../styles/common';

class CaregiverEditor extends React.Component {
  constructor() {
    super();
    this.state = {
      activeTab: null,
      isValid: false,
      isChanged: false,
      invitedCaregiver: false,
    };

    this.closeDetailsClicked = this.closeDetailsClicked.bind(this);
    this.tabClick = this.tabClick.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onPreferencesChange = this.onPreferencesChange.bind(this);
    this.disableSubmitButton = this.disableSubmitButton.bind(this);
    this.enableSubmitButton = this.enableSubmitButton.bind(this);
    this.onAgencyChange = this.onAgencyChange.bind(this);
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.dispatch(caregiverActions.createCaregiverClear());

    this.setState(() => ({
      activeTab: this.props.tab ? this.props.tab : 0,
      isPreferencesChanged: false,
      caregiver: {
        address: { forceGeocoding: true },
        languages: [10],
        staffStatus: '01',
      },
      invitedCaregiver: false,
    }));
  }

  componentDidMount() {
    this.props.dispatch(agencySettingsActions.getAgencySettings(true));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.caregiverEditor.submitError) {
      this.setState({
        activeTab: 0,
      });
      this.props.dispatch(caregiverActions.createCaregiverClear());
    }

    if (nextProps.uiState) {
      this.setState({ uiState: nextProps.uiState });
    }

    if (
      (nextProps.caregiverEditor &&
        nextProps.caregiverEditor.done === true &&
        this.state.invitedCaregiver === true &&
        !nextProps.caregiver.processingInvites) ||
      (nextProps.caregiverEditor &&
        nextProps.caregiverEditor.done === true &&
        nextProps.caregiverEditor.caregiver.profile.staffStatus !== '02')
    ) {
      this.props.onCloseClicked(nextProps.caregiverEditor.caregiver);
    }

    if (
      nextProps.caregiverEditor &&
      nextProps.caregiverEditor.done === true &&
      !this.state.invitedCaregiver &&
      nextProps.caregiverEditor.caregiver.profile.staffStatus === '02'
    ) {
      this.props.dispatch(
        caregiverActions.inviteCaregiver(
          { id: nextProps.caregiverEditor.caregiver.profile.id },
          null,
          null,
          null,
          true
        )
      );
      this.setState({ invitedCaregiver: true });
    }
  }

  onValueChange(e, input) {
    if (!e) {
      return;
    }

    this.setState(s => {
      const setValue = (path, obj, value) => {
        const key = path.shift();
        if (path.length > 0) {
          setValue(path, obj[key], value);
        } else {
          // eslint-disable-next-line no-param-reassign
          obj[key] =
            value != null && (value.length || Number.isInteger(value) || isFloat(value))
              ? value
              : undefined;
        }
      };

      const newState = { ...s };

      // eslint-disable-next-line no-underscore-dangle
      if (e._isAMomentObject) {
        setValue(['dob'], newState.caregiver, e.format(MOMENT_DOB));
      } else if (input.type === 'checkbox') {
        setValue(input.name.split('.'), newState.caregiver, input.checked ? 'Y' : 'N');
      } else {
        setValue(input.name.split('.'), newState.caregiver, input.value);
      }

      const isChanged = JSON.stringify(newState.caregiver) !== JSON.stringify(newState.original);
      // const isValid = this.validateInputs();
      return Object.assign(newState, { isChanged });
    });
  }

  onPreferencesChange(category, selected) {
    this.setState(s => {
      const newState = { ...s };
      newState.caregiver.preferences.forEach(x => {
        if (x.category === category) {
          // eslint-disable-next-line no-param-reassign
          x.value = selected.indexOf(x.id) >= 0 ? 'Y' : 'N';
        }
      });

      return Object.assign(newState, { isPreferencesChanged: true });
    });
  }

  onAgencyChange(e, data) {
    if (!e) {
      return;
    }

    this.setState(s => {
      const setValue = (path, obj, value) => {
        const key = path.shift();
        if (path.length > 0) {
          setValue(path, obj[key], value);
        } else {
          // eslint-disable-next-line no-param-reassign
          obj[key] = value?.length || Number.isInteger(value) ? value : null;
        }
      };
      const newState = { ...s };

      if (data) {
        if (data.name === 'primaryContact') {
          setValue(['primaryContactId'], newState.caregiver, data.value);
        } else if (data.name === 'primaryManager') {
          setValue(['managerId'], newState.caregiver, data.value);
        } else if (data.name === 'notificationRecipients') {
          setValue(['notificationRecipients'], newState.caregiver, data.value);
        } else if (data.name === 'caregiverAgencyId') {
          setValue(['caregiverAgencyId'], newState.caregiver, data.value);
        }
      }

      return Object.assign(newState, { isChanged: true });
    });
  }

  onBackButtonClick() {
    this.setState({ activeTab: 0 });
  }

  tabClick(e, input) {
    this.setState({
      activeTab: input.name,
    });
  }

  closeDetailsClicked() {
    this.props.onCloseClicked();
  }

  disableSubmitButton() {
    this.setState({ isValid: false });
  }

  enableSubmitButton() {
    this.setState({ isValid: true });
  }

  render() {
    const { loading } = this.props.caregiverEditor;
    const { dispatch, uiState, languages, disciplines, agencySettings } = this.props;
    const { activeTab, isChanged, isValid, caregiver } = this.state;

    if (loading || !agencySettings) {
      return (
        <Dimmer active page>
          <CareLoader loading={loading} invert showText={false} />
        </Dimmer>
      );
    }

    const { manualExternalIdEnabled } = agencySettings;

    const editorTabs = ['Basic Info', 'Agency Info'];
    const lastTab = editorTabs.length - 1;

    const applyChangesClick = () => {
      if (this.state.activeTab < lastTab) {
        this.setState(s => ({
          activeTab: s.activeTab + 1,
          isValid: false,
        }));
        return;
      }

      const newCaregiver = {
        ...this.state.caregiver,
        staffStatus: `${this.state.caregiver.staffStatus}`,
        languages: this.state.caregiver.languages
          ? this.state.caregiver.languages.map((x, i) => ({
              id: x,
              value: 'Y',
              primary: !!(i === 0),
            }))
          : undefined,
      };

      if (newCaregiver.phones) {
        const phoneMobile = 3;
        newCaregiver.phones = [{ primary: true, type: phoneMobile, number: newCaregiver.phones }];
      }

      if (newCaregiver.address.lat != null && newCaregiver.address.lon != null) {
        newCaregiver.address.forceGeocoding = undefined;
      }

      if (newCaregiver.caregiverAgencyId) {
        newCaregiver.caregiverAgencyId = parseInt(newCaregiver.caregiverAgencyId, 10);
      }

      dispatch(caregiverActions.createCaregiver(newCaregiver));
    };

    const offlineClick = () => {
      const error = new Error('Error no network connection');
      dispatch(alertActions.error(error, alertActions.REASON_NETWORK_CONNECTIVITY));
    };

    return (
      <Modal
        closeOnDimmerClick={false}
        open
        closeIcon={<Image src='/icon_close.svg' />}
        dimmer
        size='tiny'
        onClose={this.closeDetailsClicked}
        className='clientEditor'
        style={{ marginTop: '-46vh' }}
      >
        <Modal.Header>
          <Grid centered className='headerGrid'>
            <Grid.Column width={16} textAlign='center'>
              {activeTab === 1 ? (
                <div className='care-editor-header-container'>
                  <GoBackButton onClick={this.onBackButtonClick} />
                  <Header className='care-editor-header' size='tiny'>
                    ADD NEW CAREGIVER
                  </Header>
                </div>
              ) : (
                <Header size='tiny' className='care-editor-header'>
                  ADD NEW CAREGIVER
                </Header>
              )}
            </Grid.Column>
            <Grid.Column width={16} textAlign='center' className='careEditorTabContainer'>
              <CareEditorTabs labels={editorTabs} activeTab={this.state.activeTab} />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content scrolling>
          {activeTab === 0 && (
            <CCEditorInfo
              dispatch={dispatch}
              values={caregiver}
              type={CCEditorInfo.CAREGIVER}
              onValueChange={this.onValueChange}
              languages={languages}
              disciplines={disciplines}
              disableSubmitButton={this.disableSubmitButton}
              enableSubmitButton={this.enableSubmitButton}
              isCandidate={this.props.isCandidate}
              multiDisciplineEnabled={agencySettings.multiDisciplineEnabled}
              secondaryStatusCodes={this.props.secondaryStatusCodes}
            />
          )}
          {activeTab === 1 && (
            <CCEditorAgency
              isClientView={false}
              uiState={uiState}
              manualExternalIdEnabled={manualExternalIdEnabled}
              values={caregiver}
              onAgencyChange={this.onAgencyChange}
              disableSubmitButton={this.disableSubmitButton}
              enableSubmitButton={this.enableSubmitButton}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Grid centered className='buttonArea'>
            <Grid.Column width={10} textAlign='center'>
              <Detector
                render={({ online }) => (
                  <Button
                    id='caregiver-editor-create-next-button'
                    disabled={!isChanged || !isValid || loading}
                    onClick={online ? applyChangesClick : offlineClick}
                  >
                    {this.state.activeTab === 1 ? 'Create Caregiver Record' : 'Next'}
                  </Button>
                )}
                polling={{
                  url: config.OFFLINE_DETECTOR_POLLING_URL,
                  interval: 60000,
                  timeout: 15000,
                }}
              />
            </Grid.Column>
            {activeTab === 1 && (
              <label className='editorBottomLabel' htmlFor='createButton'>
                This will invite your caregiver to your agency through the CareConnect App and
                manage their profile in app.
              </label>
            )}
          </Grid>
        </Modal.Actions>
      </Modal>
    );
  }
}

CaregiverEditor.defaultProps = {
  tab: 0,
  caregiver: null,
};

CaregiverEditor.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uiState: PropTypes.shape().isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  caregiverEditor: PropTypes.shape().isRequired,
  caregiver: PropTypes.shape(),
  disciplines: PropTypes.shape().isRequired,
  onCloseClicked: PropTypes.func.isRequired,
  tab: PropTypes.number,
  isCandidate: PropTypes.bool.isRequired,
  secondaryStatusCodes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  agencySettings: PropTypes.shape().isRequired,
};

const mapStateToProps = state => {
  const { disciplines, languages, secondaryStatusCodes, agencySettings } = state.principal;
  const { caregiverEditor, caregiver } = state;

  return {
    caregiverEditor,
    caregiver,
    disciplines,
    languages,
    secondaryStatusCodes,
    agencySettings,
  };
};

export default connect(uiStateActions.mapStateOfGroupToProps('clientsFilter', mapStateToProps))(
  CaregiverEditor
);

import React from 'react';
import { Form, FormProps, InputOnChangeData } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

interface IProps {
  searchValue: string;
  onSubmit: (event: React.FormEvent<HTMLFormElement>, data: FormProps) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
  loading: boolean;
}

const HRCandidatesFilterForm: React.FC<IProps> = ({ searchValue, onSubmit, onChange, loading }) => {
  return (
    <Form className='candidates-search' size='tiny' onSubmit={onSubmit}>
      <Form.Group className='menu-input-with-button' inline>
        <Form.Input
          id='caregiverNameOrId'
          size='mini'
          name='caregiverNameOrId'
          inline
          floated='left'
          placeholder='Enter agency id, name or SSN'
          value={searchValue}
          onChange={onChange}
          style={{ width: '294px' }}
        />
        <Form.Button
          id='hr-candidates-filter-form-go-button'
          inline
          circular
          loading={loading}
          marginleft={5}
          style={{ width: '30px', height: '30px', position: 'relative' }}
        >
          <FormattedMessage id='changeLog.submit.button' />
        </Form.Button>
      </Form.Group>
    </Form>
  );
};

export default HRCandidatesFilterForm;

import React from 'react';
import { Modal } from 'semantic-ui-react';

const BlockELearningModal: React.FC = () => {
  return (
    <Modal open dimmer size='tiny' style={{ marginTop: '-40vh', height: '80vh' }}>
      <Modal.Content>
        <div className='e-learning-blocked-modal'>
          <img alt='e-learning blocked' src='/e-learning-block.svg' />
          <p>
            You cannot undertake e-learning courses when you have an active shift or event currently
            in-progress.
          </p>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default BlockELearningModal;

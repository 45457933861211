import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface IProps {
  onChange: (v: string) => void;
}

interface ISelectionContainerProps {
  open: boolean;
}

interface ISelectionProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  isFocused?: boolean;
}

const QuarterContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SelectionContainer = styled.div.attrs({
  tabIndex: 0,
})<ISelectionContainerProps>`
  display: ${props => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  background-color: #fefefe;
  outline: #04a5d5;
  border-radius: 4px;
`;

const SelectedContainer = styled.div.attrs({
  tabIndex: 0,
})<ISelectionProps>`
  position: relative;
  width: 70px;
  height: 25px;
  border: 1px solid #c8cbd3;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  border-radius: 4px;
  &::before {
    content: '';
    position: absolute;
    top: 55%;
    left: 77%;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 6px solid #c8cbd3;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
  &::after {
    content: '';
    position: absolute;
    top: 15%;
    left: 77%;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-bottom: 6px solid #c8cbd3;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
  &:hover {
    border-color: #04a5d5;
  }
  &:focus {
    border-color: #04a5d5;
  }
`;

const Selection = styled.div.attrs({
  tabIndex: 0,
})<ISelectionProps>`
  width: 70px;
  height: 25px;
  border: 1px solid #c8cbd3;
  border-color: ${props => (props.isFocused ? '#04a5d5' : '#c8cbd3')};
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  &:hover {
    border-color: #04a5d5;
    font-weight: bold;
  }
  &:focus {
    border-color: #04a5d5;
  }
`;

const QuarterSelector: React.FC<IProps> = ({ onChange }) => {
  const [value, setValue] = useState('00');
  const [contentOpen, setContentOpen] = useState(false);
  const [focused, setFocused] = useState('');
  const selectContainer = useRef<HTMLDivElement>(null);
  const oClock = useRef<HTMLDivElement>(null);
  const quarterPass = useRef<HTMLDivElement>(null);
  const half = useRef<HTMLDivElement>(null);
  const quarterTo = useRef<HTMLDivElement>(null);

  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  useEffect(() => {
    if (contentOpen) {
      selectContainer?.current?.focus();
    }
  }, [contentOpen]);

  const valueIdMap = { '00': 'oClock', '15': 'quarterPass', '30': 'half', '45': 'quarterTo' };

  const handleSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const newValue = e.currentTarget.dataset.value || '00';
    setValue(newValue);
    onChange(newValue);
    setContentOpen(false);
  };

  const toggleOpen = () => {
    setContentOpen(!contentOpen);
    const newFocus = valueIdMap[value as keyof typeof valueIdMap];
    setFocused(newFocus);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    let newFocused = '';
    if (e.key === 'ArrowUp') {
      switch (focused) {
        case valueIdMap['00']:
          newFocused = valueIdMap['45'];
          break;
        case valueIdMap['15']:
          newFocused = valueIdMap['00'];
          break;
        case valueIdMap['30']:
          newFocused = valueIdMap['15'];
          break;
        case valueIdMap['45']:
          newFocused = valueIdMap['30'];
          break;
        default:
          newFocused = valueIdMap['00'];
          break;
      }
      return setFocused(newFocused);
    }
    if (e.key === 'ArrowDown') {
      switch (focused) {
        case valueIdMap['00']:
          newFocused = valueIdMap['15'];
          break;
        case valueIdMap['15']:
          newFocused = valueIdMap['30'];
          break;
        case valueIdMap['30']:
          newFocused = valueIdMap['45'];
          break;
        case valueIdMap['45']:
          newFocused = valueIdMap['00'];
          break;
        default:
          newFocused = valueIdMap['00'];
          break;
      }
      return setFocused(newFocused);
    }
    if (e.key === 'Enter') {
      switch (focused) {
        case valueIdMap['00']:
          oClock?.current?.click();
          break;
        case valueIdMap['15']:
          quarterPass?.current?.click();
          break;
        case valueIdMap['30']:
          half?.current?.click();
          break;
        case valueIdMap['45']:
          quarterTo?.current?.click();
          break;
        default:
          oClock?.current?.click();
          break;
      }
    }
    return null;
  };

  const handleClose = async () => {
    await sleep(250);
    setContentOpen(false);
  };

  return (
    <QuarterContainer>
      <SelectedContainer onFocus={toggleOpen}>{value} min</SelectedContainer>
      <SelectionContainer
        ref={selectContainer}
        onBlur={handleClose}
        onKeyDown={handleKeyDown}
        open={contentOpen}
      >
        <Selection
          id={valueIdMap['00']}
          ref={oClock}
          isFocused={focused === valueIdMap['00']}
          data-value='00'
          onClick={handleSelect}
        >
          00
        </Selection>
        <Selection
          id={valueIdMap['15']}
          ref={quarterPass}
          isFocused={focused === valueIdMap['15']}
          data-value='15'
          onClick={handleSelect}
        >
          15
        </Selection>
        <Selection
          id={valueIdMap['30']}
          ref={half}
          isFocused={focused === valueIdMap['30']}
          data-value='30'
          onClick={handleSelect}
        >
          30
        </Selection>
        <Selection
          id={valueIdMap['45']}
          ref={quarterTo}
          isFocused={focused === valueIdMap['45']}
          data-value='45'
          onClick={handleSelect}
        >
          45
        </Selection>
      </SelectionContainer>
    </QuarterContainer>
  );
};

export default QuarterSelector;

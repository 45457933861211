import {
  GET_IMPORTS_REQUEST,
  GET_IMPORTS_SUCCESS,
  GET_IMPORTS_FAILURE,
  GET_IMPORT_DETAILS_REQUEST,
  GET_IMPORT_DETAILS_SUCCESS,
  GET_IMPORT_DETAILS_FAILURE,
  IMPORT_NOTIFICATION,
} from '../constants/ActionTypes';

const initialState = {
  imports: [],
  loading: false,
};

function imports(state = initialState, action) {
  switch (action.type) {
    case GET_IMPORTS_REQUEST:
      return { ...state, loading: true };
    case GET_IMPORTS_SUCCESS:
      return { ...state, loading: false, imports: action.imports };
    case GET_IMPORTS_FAILURE:
      return { ...state, loading: false, imports: [] };
    case GET_IMPORT_DETAILS_REQUEST:
      return { ...state, loadingLines: true };
    case GET_IMPORT_DETAILS_SUCCESS:
      return { ...state, loadingLines: false, lines: action.lines };
    case GET_IMPORT_DETAILS_FAILURE:
      return { ...state, loadingLines: false, lines: [] };
    case IMPORT_NOTIFICATION: {
      if (!state.imports) {
        return state;
      }

      const newImports = [...state.imports];
      const index = newImports.findIndex(i => i.id === action.importObject.id);
      if (index >= 0) {
        newImports[index] = action.importObject;
      } else {
        newImports.push(action.importObject);
      }

      return { ...state, imports: newImports };
    }
    default:
      return state;
  }
}

export default imports;

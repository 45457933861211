/* eslint-disable max-len */
import _ from 'underscore';
import { config } from '../helpers/config';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS_AGENCIES,
  RECOVER_REQUEST,
  RECOVER_FAILURE,
  RECOVER_SUCCESS,
  UPDATE_PASSWORD_SUCCESS,
  LOGOUT,
  UNAUTHORIZED,
  SIGNUP_SUCCESS,
  GET_LANGUAGES_SUCCESS,
  GET_DISCIPLINES_SUCCESS,
  GET_ATTRIBUTES_REQUEST,
  GET_ATTRIBUTES_SUCCESS,
  UPDATE_ATTRIBUTES_REQUEST,
  UPDATE_ATTRIBUTES_SUCCESS,
  UPDATE_ATTRIBUTES_FAILURE,
  UPDATE_AGENCY_SETTINGS_REQUEST,
  GET_AGENCY_SETTINGS_REQUEST,
  UPDATE_AGENCY_SETTINGS_SUCCESS,
  GET_AGENCY_SETTINGS_SUCCESS,
  UPDATE_AGENCY_SETTINGS_FAILURE,
  GET_AGENCY_SETTINGS_FAILURE,
  GET_COMPLIANCE_BLOCKS_REQUEST,
  GET_COMPLIANCE_BLOCKS_SUCCESS,
  GET_COMPLIANCE_BLOCKS_FAILURE,
  UPDATE_COMPLIANCE_BLOCKS_REQUEST,
  UPDATE_COMPLIANCE_BLOCKS_SUCCESS,
  UPDATE_COMPLIANCE_BLOCKS_FAILURE,
  GET_COMPLIANCE_TRAINING_COURSES_REQUEST,
  GET_COMPLIANCE_TRAINING_COURSES_SUCCESS,
  GET_COMPLIANCE_TRAINING_COURSES_FAILURE,
  COMPLIANCE_SETTINGS_HIDE_REMOVE_AGREEMENT,
  GET_COMPLIANCE_CLASS_ROOMS_REQUEST,
  GET_COMPLIANCE_CLASS_ROOMS_FAILURE,
  GET_COMPLIANCE_CLASS_ROOMS_SUCCESS,
  GET_COMPLIANCE_INSTRUCTORS_REQUEST,
  GET_COMPLIANCE_INSTRUCTORS_FAILURE,
  GET_COMPLIANCE_INSTRUCTORS_SUCCESS,
  CREATE_COMPLIANCE_INSTRUCTORS_REQUEST,
  CREATE_COMPLIANCE_INSTRUCTORS_FAILURE,
  CREATE_COMPLIANCE_INSTRUCTORS_SUCCESS,
  UPDATE_COMPLIANCE_CLASS_ROOMS_REQUEST,
  UPDATE_COMPLIANCE_CLASS_ROOMS_FAILURE,
  UPDATE_COMPLIANCE_CLASS_ROOMS_SUCCESS,
  GET_MAGIC_LOGIN_REQUEST,
  GET_MAGIC_LOGIN_SUCCESS,
  GET_MAGIC_LOGIN_FAILURE,
  UPDATE_EMPLOYEE_SUCCESS,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  GET_USER_SUMMARY_REQUEST,
  GET_USER_SUMMARY_SUCCESS,
  GET_USER_SUMMARY_FAILURE,
  GET_AGENCIES_FAILURE,
  GET_AGENCIES_SUCCESS,
  GET_AGENCIES_REQUEST,
  SWITCH_AGENCY_FAILURE,
  SWITCH_AGENCY_SUCCESS,
  SWITCH_AGENCY_REQUEST,
  TERMS_PENDING,
  PRE_LOGIN,
} from '../constants/ActionTypes';

const data = JSON.parse(localStorage.getItem(config.LOCAL_STORAGE_KEY));
const initialState = data
  ? {
      loading: false,
      authenticated: true,
      user: data.user,
      profile: {},
      settings: data.settings,
      roles: data.roles,
      agencySettings: data.agencySettings,
      lastHeartbeat: undefined,
      languages: [],
      disciplines: [],
      secondaryStatusCodes: [],
      attributes: { loading: false, preferences: [], profileFields: [] },
      onShift: false,
    }
  : {
      profile: {},
      attributes: { loading: false, preferences: [], profileFields: [] },
      agencySettings: { loading: false },
      onShift: false,
    };

function principal(state = initialState, action) {
  switch (action.type) {
    case SWITCH_AGENCY_REQUEST:
      return { ...state, loading: true };
    case SWITCH_AGENCY_SUCCESS:
      return {
        ...state,
        loading: true,
        user: action.user,
        settings: action.settings,
        roles: action.roles,
      };
    case SWITCH_AGENCY_FAILURE:
      return { ...state, loading: false };
    case GET_AGENCIES_REQUEST:
      return { ...state, loading: true };
    case GET_AGENCIES_SUCCESS:
      return { ...state, loading: false, agencies: action.results };
    case GET_AGENCIES_FAILURE:
      return { ...state, loading: false };
    case GET_MAGIC_LOGIN_REQUEST:
      return { ...state, loading: true, error: undefined };
    case GET_MAGIC_LOGIN_SUCCESS:
      return { ...state, loading: false, error: undefined };
    case TERMS_PENDING:
      return {
        ...state,
        loading: false,
        error: undefined,
        pendingTerms: true,
        terms: action.pendingTerm,
      };
    case LOGIN_SUCCESS_AGENCIES:
      return {
        ...state,
        loading: false,
        agencies: action.agencies,
        pendingTerms: false,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        authenticated: false,
        user: undefined,
        settings: undefined,
        agencySettings: undefined,
        error: undefined,
        pendingTerms: false,
      };
    case PRE_LOGIN:
      return {
        ...state,
        preLogin: {
          user: action.user,
          settings: action.settings,
          roles: action.roles,
        },
      };
    case LOGIN_SUCCESS:
    case SIGNUP_SUCCESS:
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        authenticated: true,
        user: action.user,
        settings: action.settings,
        roles: action.roles,
        error: undefined,
        pendingTerms: false,
      };
    case RECOVER_REQUEST:
      return { ...state, loading: true };
    case RECOVER_FAILURE:
      return { ...state, loading: false, error: action.error };
    case RECOVER_SUCCESS:
      return { ...state, loading: false };
    case GET_MAGIC_LOGIN_FAILURE:
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        authenticated: false,
        user: undefined,
        settings: undefined,
        error: action.error,
      };
    case LOGOUT:
    case UNAUTHORIZED:
      return {
        ...state,
        loading: false,
        authenticated: false,
        user: undefined,
        settings: undefined,
        error: undefined,
      };
    case GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: action.languages.map(l => ({
          value: l.id,
          text: l.name.charAt(0).toUpperCase() + l.name.slice(1),
        })),
      };
    case GET_DISCIPLINES_SUCCESS:
      return {
        ...state,
        disciplines: action.result.disciplines.map(l => ({
          value: l,
          text: l,
        })),
        secondaryStatusCodes: action.result.secondaryStatusCodes?.map(r => ({
          value: r.code,
          text: r.description,
          staffStatus: r.staffStatus,
        })),
      };
    case GET_ATTRIBUTES_REQUEST:
    case UPDATE_ATTRIBUTES_REQUEST:
      return { ...state, attributes: { ...state.attributes, loading: true } };
    case GET_ATTRIBUTES_SUCCESS:
    case UPDATE_ATTRIBUTES_SUCCESS:
      return { ...state, attributes: { ...action.results, loading: false } };
    case UPDATE_ATTRIBUTES_FAILURE:
      return { ...state, attributes: { ...state.attributes, loading: false } };
    case UPDATE_AGENCY_SETTINGS_REQUEST:
    case GET_AGENCY_SETTINGS_REQUEST:
      return { ...state, agencySettings: { ...state.agencySettings, loading: true } };
    case UPDATE_AGENCY_SETTINGS_SUCCESS:
    case GET_AGENCY_SETTINGS_SUCCESS:
      return { ...state, agencySettings: { ...action.agencySettings.settings, loading: false } };
    case UPDATE_AGENCY_SETTINGS_FAILURE:
    case GET_AGENCY_SETTINGS_FAILURE:
      return { ...state, agencySettings: { ...state.agencySettings, loading: false } };
    case UPDATE_COMPLIANCE_BLOCKS_REQUEST:
    case GET_COMPLIANCE_BLOCKS_REQUEST:
      return {
        ...state,
        complianceSettings: { ...state.complianceSettings, loading: true, error: false },
      };
    case UPDATE_COMPLIANCE_BLOCKS_SUCCESS:
    case GET_COMPLIANCE_BLOCKS_SUCCESS:
      return {
        ...state,
        complianceSettings: {
          ...state.complianceSettings,
          blocks: action.blocks,
          preHireBlocks: _.filter(action.blocks, b => b.includedInPrehire),
          loading: false,
          error: false,
        },
      };
    case UPDATE_COMPLIANCE_BLOCKS_FAILURE:
    case GET_COMPLIANCE_BLOCKS_FAILURE: {
      const newState = {
        ...state,
        complianceSettings: { ...state.complianceSettings, loading: false, error: true },
      };

      // !temporarily fix till BE solution coming for error type
      if (
        action.error.json &&
        (action.error.json.type === 4014 || action.error.json.type === 'ComplianceBlockInUseError')
      ) {
        Object.assign(newState.complianceSettings, { showRemoveAgreement: true });
      }

      if (
        action.error.json &&
        (action.error.json.type === 4018 ||
          action.error.json.type === 'ComplianceBlockHasPastEventsError')
      ) {
        Object.assign(newState.complianceSettings, {
          showRemoveAgreement: true,
          isNotDeletable: true,
        });
      }

      return newState;
    }
    case COMPLIANCE_SETTINGS_HIDE_REMOVE_AGREEMENT:
      return {
        ...state,
        complianceSettings: {
          ...state.complianceSettings,
          showRemoveAgreement: undefined,
          isNotDeletable: undefined,
        },
      };
    case GET_COMPLIANCE_TRAINING_COURSES_REQUEST: {
      return { ...state, loadingInServiceCourses: true, inServiceCoursesError: false };
    }
    case GET_COMPLIANCE_TRAINING_COURSES_FAILURE: {
      return { ...state, loadingInServiceCourses: false, inServiceCoursesError: true };
    }
    case GET_COMPLIANCE_TRAINING_COURSES_SUCCESS: {
      return {
        ...state,
        loadingInServiceCourses: false,
        inServiceCoursesError: false,
        inServiceCourses: action.results,
      };
    }
    case CREATE_COMPLIANCE_INSTRUCTORS_REQUEST:
    case GET_COMPLIANCE_INSTRUCTORS_REQUEST: {
      return {
        ...state,
        loadingComplianceInstructors: true,
        complianceInstructorsError: false,
        createdInstructor: undefined,
      };
    }
    case CREATE_COMPLIANCE_INSTRUCTORS_FAILURE:
    case GET_COMPLIANCE_INSTRUCTORS_FAILURE: {
      return { ...state, loadingComplianceInstructors: false, complianceInstructorsError: true };
    }
    case GET_COMPLIANCE_INSTRUCTORS_SUCCESS: {
      return {
        ...state,
        loadingComplianceInstructors: false,
        complianceInstructorsError: false,
        complianceInstructors: action.results,
      };
    }
    case CREATE_COMPLIANCE_INSTRUCTORS_SUCCESS: {
      return {
        ...state,
        loadingComplianceInstructors: false,
        complianceInstructorsError: false,
        complianceInstructors: state.complianceInstructors.concat([action.results]),
        createdInstructor: action.results.id,
      };
    }
    case UPDATE_COMPLIANCE_CLASS_ROOMS_REQUEST:
    case GET_COMPLIANCE_CLASS_ROOMS_REQUEST: {
      return { ...state, loadingComplianceClassrooms: true, complianceClassroonsError: false };
    }
    case UPDATE_COMPLIANCE_CLASS_ROOMS_FAILURE:
    case GET_COMPLIANCE_CLASS_ROOMS_FAILURE: {
      return { ...state, loadingComplianceClassrooms: false, complianceClassroonsError: true };
    }
    case UPDATE_COMPLIANCE_CLASS_ROOMS_SUCCESS:
    case GET_COMPLIANCE_CLASS_ROOMS_SUCCESS: {
      return {
        ...state,
        loadingComplianceClassrooms: false,
        complianceClassroonsError: false,
        complianceClassrooms: action.results,
      };
    }
    case UPDATE_EMPLOYEE_SUCCESS: {
      if (state.user.id === action.results.profile.userId) {
        return { ...state, roles: action.results.profile.roles };
      }
      return state;
    }
    case GET_USER_PROFILE_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_USER_PROFILE_SUCCESS:
      return { ...state, loading: false, profile: action.results.profile };
    case GET_USER_PROFILE_FAILURE:
      return { ...state, loading: false };
    case GET_USER_SUMMARY_REQUEST:
      return { ...state, loading: true };
    case GET_USER_SUMMARY_SUCCESS:
      return { ...state, loading: false, onShift: action.results.onShift };
    case GET_USER_SUMMARY_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default principal;

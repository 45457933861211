/* eslint-disable import/prefer-default-export */
import * as types from '../../constants/ActionTypes';
import { backend } from '../../services';
import { alertActions } from '../alert';

function getDashboard() {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.getComplianceHRDashboard();
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: types.GET_COMPLIANCE_HR_DASHBOARD_REQUEST };
  }
  function success(results) {
    return { type: types.GET_COMPLIANCE_HR_DASHBOARD_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_COMPLIANCE_HR_DASHBOARD_FAILURE, error };
  }
}

export const hrDashboardActions = {
  getDashboard,
};

/* eslint-disable import/prefer-default-export */
import * as types from '../constants/ActionTypes';
import { backend } from '../services';

function resetScores() {
  return dispatch => {
    dispatch(request());
  };

  function request() {
    return { type: types.CAREGIVERS_SCORE_RESET_REQUEST };
  }
}

function getShiftCaregiverScores(shiftIds, includeTravelCost, caregiverFilter, caregiverIds) {
  return dispatch => {
    const shiftIdString = shiftIds.sort((a, b) => a - b).join();
    dispatch(request(shiftIdString));
    backend
      .getRecommendationsForShifts(shiftIds, includeTravelCost, caregiverFilter, caregiverIds)
      .then(
        results => {
          dispatch(success(shiftIdString, results));
        },
        error => {
          dispatch(failure(shiftIdString, error.message));
        }
      );
  };

  function request(shIds) {
    return { type: types.SHIFT_CAREGIVERS_SCORE_REQUEST, params: shIds };
  }
  function success(shIds, results) {
    return { type: types.SHIFT_CAREGIVERS_SCORE_SUCCESS, params: shIds, results };
  }
  function failure(shIds, error) {
    return { type: types.SHIFT_CAREGIVERS_SCORE_FAILURE, params: shIds, error };
  }
}

function getClientCaregiverScores(clientId) {
  return dispatch => {
    dispatch(request(clientId));
    backend.getRecommendations(clientId).then(
      results => {
        dispatch(success(clientId, results));
      },
      error => {
        dispatch(failure(clientId, error.message));
      }
    );
  };

  function request(clId) {
    return { type: types.CLIENT_CAREGIVERS_SCORE_REQUEST, params: clId };
  }
  function success(clId, results) {
    return { type: types.CLIENT_CAREGIVERS_SCORE_SUCCESS, params: clId, results };
  }
  function failure(clId, error) {
    return { type: types.CLIENT_CAREGIVERS_SCORE_FAILURE, params: clId, error };
  }
}

export const caregiverScoresActions = {
  getClientCaregiverScores,
  getShiftCaregiverScores,
  resetScores,
};

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import SubTabHeader from './subcomponents/SubTabHeader';
import TileAgencyExclusion from './subcomponents/records/TileAgencyExclusion';
import TileAgencyContacts from './subcomponents/records/TileAgencyContacts';
import CGTileAgencyNotifications from './subcomponents/records/CGTileAgencyNotifications';
import TilePreHireItems from './subcomponents/records/TilePreHireItems';
import TileGrid from './subcomponents/records/TileGrid';
import TileProfileStatus from './subcomponents/records/TileProfileStatus';

class CGAgencyInformation extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { onActive, tabIndex } = this.props;
    onActive(tabIndex);
  }

  render() {
    const {
      original,
      intl,
      caregiver,
      employees,
      onValueChange,
      handlePressEnter,
      onAgencyChange,
      onSave,
      saveEnabled,
      editable,
      editability,
      secondaryStatusCodes,
      preHireBlocks,
      dispatch,
      complianceEnabled,
      showActivation,
    } = this.props;

    return (
      <>
        <SubTabHeader
          text={intl.formatMessage({ id: 'records.agency.information' }).toUpperCase()}
          buttonTitle={intl.formatMessage({ id: 'records.saveUpdates' })}
          buttonOnClick={onSave}
          buttonDisabled={!saveEnabled}
        />

        <TileGrid>
          {!!preHireBlocks?.length && complianceEnabled && (
            <TilePreHireItems preHireItems={preHireBlocks} dispatch={dispatch} />
          )}
          <TileProfileStatus
            person={caregiver}
            original={original}
            onAgencyChange={onAgencyChange}
            onValueChange={onValueChange}
            editable={editable}
            editability={editability}
            secondaryStatusCodes={secondaryStatusCodes}
            showActivation={showActivation}
            dispatch={dispatch}
          />

          <TileAgencyExclusion
            person={caregiver}
            onValueChange={onValueChange}
            editable={editable}
            type='caregiver'
          />

          <TileAgencyContacts
            original={original}
            person={caregiver}
            employees={employees}
            onAgencyChange={onAgencyChange}
            onValueChange={onValueChange}
            editable={editable}
            editability={editability}
          />

          <CGTileAgencyNotifications
            person={caregiver}
            onValueChange={onValueChange}
            handlePressEnter={handlePressEnter}
          />
        </TileGrid>
      </>
    );
  }
}

CGAgencyInformation.defaultProps = {
  saveEnabled: false,
  editable: false,
  editability: {},
  onActive: () => {},
};

CGAgencyInformation.propTypes = {
  original: PropTypes.shape().isRequired,
  intl: intlShape.isRequired,
  caregiver: PropTypes.shape().isRequired,
  onSave: PropTypes.func.isRequired,
  saveEnabled: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
  handlePressEnter: PropTypes.func.isRequired,
  onAgencyChange: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  editability: PropTypes.arrayOf(PropTypes.shape()),
  employees: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onActive: PropTypes.func,
  tabIndex: PropTypes.number.isRequired,
  secondaryStatusCodes: PropTypes.shape().isRequired,
  preHireBlocks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dispatch: PropTypes.func.isRequired,
  complianceEnabled: PropTypes.bool.isRequired,
  showActivation: PropTypes.bool.isRequired,
};

export default injectIntl(CGAgencyInformation);

import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';
import { RenderHeaderCell, Sorted } from './ReportTableHelper';

type Props = {
  data: Array<Questions>;
  sortClick(column: string, sorting: Sorted, quiz: boolean): void;
};

interface Questions {
  profileUuid: string;
  firstName: string;
  lastName: string;
  masterCourseName: string;
  quizName: string;
  questionNumber: number;
  question: string;
  answerChosen: string;
  correctChoise: boolean;
  answerDateTime: string;
  passingScore: number;
  examScore: number;
  examPassed: boolean;
  isGraded: boolean;
  allowedAttempts: number;
  numberOfAttempts: number;
  courseCompleted: boolean;
}

const QuizAnswersReportTable: React.FC<Props> = ({ data, sortClick }) => {
  const [sortedHeader, setSortedHeader] = useState('user');
  const [sorting, setSorting] = useState<Sorted>('descending');

  const swapSorting = () => {
    const newSort = sorting === 'ascending' ? 'descending' : 'ascending';
    setSorting(newSort);
    return sortClick(sortedHeader, newSort, true);
  };

  const onClickHeader = (column: string): void => {
    if (sortedHeader === column) {
      return swapSorting();
    }
    setSortedHeader(column);
    setSorting('descending');
    return sortClick(column, 'descending', true);
  };

  const header = [
    RenderHeaderCell({
      id: 'quiz-answers-report-user',
      key: 'user',
      width: '3',
      content: 'User',
      textAlign: 'left',
      onClick: () => onClickHeader('user'),
      sorted: sortedHeader === 'user' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'quiz-answers-report-course-name',
      key: 'masterCourseName',
      width: '3',
      content: 'Course Name',
      onClick: () => onClickHeader('masterCourseName'),
      sorted: sortedHeader === 'masterCourseName' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'quiz-answers-report-quiz-name',
      key: 'quizName',
      width: '3',
      content: 'Quiz Name',
      onClick: () => onClickHeader('quizName'),
      sorted: sortedHeader === 'quizName' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-active-user-course-course-name',
      key: 'question',
      width: '4',
      content: 'Question',
      onClick: () => onClickHeader('question'),
      sorted: sortedHeader === 'question' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-active-user-course-course-name',
      key: 'answerChosen',
      width: '3',
      content: 'Answer Chosen',
      onClick: () => onClickHeader('answerChosen'),
      sorted: sortedHeader === 'answerChosen' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-active-user-course-course-name',
      key: 'correctChoice',
      width: '3',
      content: 'Correct',
      onClick: () => onClickHeader('correctChoise'),
      sorted: sortedHeader === 'correctChoise' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-active-user-course-course-name',
      key: 'passingScore',
      width: '3',
      content: 'Min Score',
      onClick: () => onClickHeader('passingScore'),
      sorted: sortedHeader === 'passingScore' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-active-user-course-course-name',
      key: 'examScore',
      width: '3',
      content: 'Score',
      onClick: () => onClickHeader('examScore'),
      sorted: sortedHeader === 'examScore' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-active-user-course-course-name',
      key: 'examPassed',
      width: '3',
      content: 'Passed',
      onClick: () => onClickHeader('examPassed'),
      sorted: sortedHeader === 'examPassed' ? sorting : undefined,
    }),
    RenderHeaderCell({
      id: 'el-active-user-report-completion-date',
      key: 'Attempts',
      width: '3',
      content: 'Attempts',
      onClick: () => onClickHeader('numberOfAttempts'),
      sorted: sortedHeader === 'numberOfAttempts' ? sorting : undefined,
    }),
  ];

  return (
    <Table
      id='elearningQuizAnswersReportTable'
      className='e-learning-reports-table'
      tableData={data}
      headerRow={header}
      padded
      striped
      compact
      sortable
      textAlign='center'
      renderBodyRow={(x: Questions) => ({
        key: `${x.profileUuid}${x.answerDateTime}${x.questionNumber}`,
        cells: [
          {
            key: 'user',
            content: `${x.firstName} ${x.lastName}`,
            singleLine: true,
            textAlign: 'left',
          },
          { key: 'courseName', content: x.masterCourseName, singleLine: true },
          { key: 'quizName', content: x.quizName, singleLine: true },
          { key: 'question', content: <ReactMarkdown>{x.question}</ReactMarkdown> },
          { key: 'answerChosen', content: x.answerChosen },
          {
            key: 'correctChoice',
            content: (
              <span style={{ color: x.correctChoise ? '#69db8f' : '#f16778' }}>
                {x.correctChoise ? 'Yes' : 'No'}
              </span>
            ),
            singleLine: true,
          },
          { key: 'passingScore', content: x.passingScore, singleLine: true },
          { key: 'examScore', content: x.examScore, singleLine: true },
          {
            key: 'examPassed',
            content: (
              <span style={{ color: x.examPassed ? '#69db8f' : '#f16778' }}>
                {x.examPassed ? 'Yes' : 'No'}
              </span>
            ),
            singleLine: true,
          },
          {
            key: 'Attempts',
            content: `${x.numberOfAttempts} / ${x.allowedAttempts}`,
            singleLine: true,
          },
        ],
      })}
    />
  );
};

export default QuizAnswersReportTable;

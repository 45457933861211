/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-empty-pattern */
/* eslint-disable no-restricted-globals */
import React from 'react';
import styled from 'styled-components';
import { Button, Dropdown, DropdownProps, Input, Image, Loader } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { dateFormat } from '../../../helpers/common';
import { BlockField } from './ComplianceMedicalTypes';

export interface IDocumentField extends BlockField {
  filesDownloaded?: boolean;
  downloadLinks: FormLinks;
}

type FormLinks = {
  [key: string]: string;
};

type RemoveButtonProps = {
  onRemove: () => void;
};

type EditButtonProps = {
  onEdit: () => void;
};

type CancelButtonProps = {
  onCancel: () => void;
};

type UpdateButtonProps = {
  disabled: boolean;
  update: () => void;
};

type ComplianceDatePickerProps = {
  field: { name: string; id: number };
  state: any;
  disabled: boolean;
  onChange: (...[]: any) => void;
};

type TextFieldProps = {
  field: BlockField;
  disabled: boolean;
  state: any;
  handleChange: (field: BlockField, value: any) => void;
};

type SupervisorFieldProps = {
  findValue: any;
  employees: any[];
  field: BlockField;
  disabled: boolean;
  handleChange: (field: BlockField, value: any) => void;
};

type ResultFieldProps = {
  field: BlockField;
  disabled: boolean;
  resultsOptions: any[];
  handleChange: (field: BlockField, value: any) => void;
};

interface DropdownFieldProps extends DropdownProps {
  field: BlockField;
  disabled: boolean;
  selected?: any;
  options: any[];
  handleChange: (field: BlockField, value: any) => void;
}

type DocumentFieldProps = {
  field: IDocumentField;
  uploadingDocument: number;
  readOnly: boolean;
  documentClicked: (link: string) => void;
  documentRemoved: (id: number, name: string) => void;
  uploadDocument: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
};

type ComplianceReadingProps = {
  field: { name: string; id: number };
  state: any;
  onChange: (...[]: any) => void;
};

type ResultTextProps = {
  value: string;
  group?: string;
};

type DateTextProps = {
  date?: Date | string | null;
  vaccine?: boolean;
};

type ReadingTextProps = {
  testReading: string | number;
};

export const BlocksGroupNames = {
  HBT: 'HBT',
  RUBELLA: 'RUBELLA',
  INFLUENZA: 'INFLUENZA',
  MEASLES: 'MEASLES',
  TB: 'TB',
  COVID: 'COVID',
};

export const siteOptions = [
  { value: null, text: null },
  { value: 'Off-Site', text: 'Off-Site' },
  { value: 'On-Site', text: 'On-Site' },
  { value: 'Unknown', text: 'Unknown' },
];

export const influenzaDeclinationOptions = [
  { text: '', value: null },
  { text: 'Declined, Non-Medical', value: 'Declined, Non-Medical' },
  { text: 'Declined, Medical Exemption', value: 'Declined, Medical Exemption' },
  { text: 'Unknown/No Documentation', value: 'Unknown/No Documentation' },
];

export const TBDocTypes = [
  { value: '', text: '' },
  { value: 'Risk Assessment', text: 'Risk Assessment' },
  { value: 'Screening Questionnaire', text: 'Screening Questionnaire' },
];

export const GreenButton = styled(Button)`
  &&& {
    margin: 0;
    border: none;
    background-color: #69db8f;
    color: #1c5e3f;
    font-size: 11px;
    font-weight: normal;
    border-radius: 8px;
  }
`;

export const SimpleButton = styled(Button)`
  &&& {
    font-size: 12px;
    font-family: Lato, 'HelveticaNeue-Light';
    background-color: transparent;
    border: 1px solid #c8cbd3;
    color: #9b9b9b;
    font-weight: normal;
    margin-bottom: 1rem;
    margin-left: 2rem;
    border-radius: 6px;
  }
`;

const StyledTextInput = styled(Input)`
  &&& {
    border: none;
    border-bottom: ${props => (props.disabled ? '' : '1px solid #c8c8c8')};
    min-width: ${props => (props.disabled ? '' : '195px')};
  }
  &&&& > input {
    border: none;
  }
`;

const StyledDatePickerInput = styled(Input)`
  &&& {
    border: 1px solid #e3e3e3;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 2px;
    width: 120px;
  }
  &&&& > input {
    border: none;
  }
`;

const StyledDropdown = styled(Dropdown)`
  &&&& {
    min-width: 195px;
  }
`;

export const RemoveButton: React.FC<RemoveButtonProps> = ({ onRemove }) => {
  return (
    <span
      className='removeButton'
      role='presentation'
      style={{ paddingRight: '10px', width: '40px' }}
      onClick={onRemove}
    >
      remove
    </span>
  );
};

export const EditButton: React.FC<EditButtonProps> = ({ onEdit }) => {
  return (
    <span
      className='removeButton'
      role='presentation'
      style={{ paddingRight: '10px', width: '40px' }}
      onClick={onEdit}
    >
      edit
    </span>
  );
};

export const CancelButton: React.FC<CancelButtonProps> = ({ onCancel }) => {
  return (
    <span
      className='removeButton'
      role='presentation'
      style={{ paddingRight: '10px', width: '40px' }}
      onClick={onCancel}
    >
      cancel
    </span>
  );
};

export const UpdateButton: React.FC<UpdateButtonProps> = ({ disabled, update }) => {
  return (
    <div className='compliance-update-container'>
      <GreenButton
        className='compliance-update-button'
        positive
        disabled={disabled}
        onClick={update}
      >
        Update
      </GreenButton>
    </div>
  );
};

export const ComplianceDatePicker: React.FC<ComplianceDatePickerProps> = ({
  field,
  state,
  disabled,
  onChange,
}) => {
  const selection: unknown = state?.value ? moment(state?.value) : undefined;
  return (
    <DatePicker
      name={field.name}
      id={field.id.toString()}
      customInput={<StyledDatePickerInput icon='calendar' />}
      placeholderText='Select Date'
      selected={selection as Date}
      disabled={disabled}
      onChange={(date: any) => onChange(field, date)}
      dateFormat={dateFormat}
    />
  );
};

export const ComplianceReading: React.FC<ComplianceReadingProps> = ({ field, state, onChange }) => {
  return (
    <div className='medicalDateInput' style={{ justifySelf: 'flex-end', marginRight: '20px' }}>
      <Input
        key={field.id}
        value={state}
        error={isNaN(state)}
        maxLength='6'
        onChange={(_e, input) => {
          onChange(field, input.value);
        }}
      />
    </div>
  );
};

export const ResultText: React.FC<ResultTextProps> = ({ value, group }) => {
  let resultText;
  if (group === 'HBT') {
    switch (value) {
      case '+':
        resultText = <span style={{ color: '#F16778', fontSize: '10px' }}>Not Immune</span>;
        return resultText;
      case '-':
        resultText = <span style={{ color: '#07c957', fontSize: '10px' }}>Immune</span>;
        return resultText;
      default:
        resultText = <span style={{ color: '#f5a623', fontSize: '10px' }}>N/A</span>;
        return resultText;
    }
  }
  if (group === 'DsResult') {
    switch (value) {
      case '+':
        resultText = (
          <span style={{ color: '#F16778', fontSize: '10px', marginLeft: '10px' }}>Positive</span>
        );
        return resultText;
      case '-':
        resultText = (
          <span style={{ color: '#07c957', fontSize: '10px', marginLeft: '10px' }}>Negative</span>
        );
        return resultText;
      case 'DA':
        resultText = (
          <span style={{ color: '#07c957', fontSize: '10px', marginLeft: '10px' }}>
            Drug Attestation
          </span>
        );
        return resultText;
      case 'D':
        resultText = (
          <span style={{ color: '#07c957', fontSize: '10px', marginLeft: '10px' }}>
            Disqualified
          </span>
        );
        return resultText;
      default:
        resultText = (
          <span style={{ color: '#F16778', fontSize: '10px', marginLeft: '10px' }}>N/A</span>
        );
        return resultText;
    }
  }
  if (group === 'TB') {
    switch (value) {
      case '+':
        resultText = (
          <span style={{ color: '#F16778', fontSize: '10px', marginLeft: '10px' }}>Positive</span>
        );
        return resultText;
      case '-':
        resultText = (
          <span style={{ color: '#07c957', fontSize: '10px', marginLeft: '10px' }}>Negative</span>
        );
        return resultText;
      default:
        resultText = (
          <span style={{ color: '#F16778', fontSize: '10px', marginLeft: '10px' }}>N/A</span>
        );
        return resultText;
    }
  }
  if (group === 'CVDResult' || group === 'CovidAntibodyResult') {
    switch (value) {
      case 'Positive':
      case 'Indeterminate':
        resultText = (
          <span style={{ color: '#F16778', fontSize: '10px', marginLeft: '10px' }}>{value}</span>
        );
        return resultText;
      case 'Negative':
        resultText = (
          <span style={{ color: '#07c957', fontSize: '10px', marginLeft: '10px' }}>{value}</span>
        );
        return resultText;
      default:
        resultText = (
          <span style={{ color: '#f5a623', fontSize: '10px', marginLeft: '10px' }}>N/A</span>
        );
        return resultText;
    }
  }
  if (group === 'Rubella') {
    switch (value) {
      case 'NI':
        resultText = <span style={{ color: '#F16778', fontSize: '10px' }}>Not Immune</span>;
        return resultText;
      case 'I':
        resultText = <span style={{ color: '#07c957', fontSize: '10px' }}>Immune</span>;
        return resultText;
      default:
        resultText = (
          <span style={{ color: '#f5a623', fontSize: '10px', marginLeft: '10px' }}>N/A</span>
        );
        return resultText;
    }
  }
  switch (value) {
    case '-':
      resultText = (
        <span style={{ color: '#F16778', fontSize: '10px', marginLeft: '10px' }}>Fail</span>
      );
      return resultText;
    case '+':
      resultText = (
        <span style={{ color: '#07c957', fontSize: '10px', marginLeft: '10px' }}>Pass</span>
      );
      return resultText;
    case 'NI':
      resultText = <span style={{ color: '#F16778', fontSize: '10px' }}>Fail</span>;
      return resultText;
    case 'I':
      resultText = <span style={{ color: '#07c957', fontSize: '10px' }}>Pass</span>;
      return resultText;
    default:
      resultText = value ? (
        <span style={{ fontSize: '10px', marginLeft: '10px' }}>{value}</span>
      ) : (
        <span style={{ color: '#F16778', fontSize: '10px', marginLeft: '10px' }}>N/A</span>
      );
      return resultText;
  }
};

export const ResultFields: React.FC<ResultFieldProps> = ({
  field,
  resultsOptions,
  disabled,
  handleChange,
}) => {
  const handleSupervisorChange = (
    _e: React.SyntheticEvent<HTMLElement, Event>,
    d: DropdownProps
  ) => {
    const { value } = d;
    handleChange(field, value);
  };
  return (
    <StyledDropdown
      placeholder='Select result'
      fluid
      selection
      disabled={disabled}
      className='compliance-dropdown'
      key={field.id}
      value={field.value}
      options={resultsOptions}
      onChange={handleSupervisorChange}
    />
  );
};

export const DropdownFields: React.FC<DropdownFieldProps> = ({
  field,
  selected,
  options,
  disabled,
  error,
  handleChange,
}) => {
  const handleOnChange = (_e: React.SyntheticEvent<HTMLElement, Event>, d: DropdownProps) => {
    const { value } = d;
    handleChange(field, value);
  };
  return (
    <StyledDropdown
      placeholder='Please Select'
      fluid
      selection
      disabled={disabled}
      className='compliance-dropdown'
      key={field.id}
      value={selected.value}
      options={options}
      error={error}
      onChange={handleOnChange}
    />
  );
};

export const TextField: React.FC<TextFieldProps> = ({ field, disabled, state, handleChange }) => {
  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    handleChange(field, value);
  };
  return (
    <StyledTextInput
      key={field.id}
      disabled={disabled}
      value={state.value}
      onChange={handleFieldChange}
    />
  );
};

export const SupervisorField: React.FC<SupervisorFieldProps> = ({
  field,
  findValue,
  employees,
  disabled,
  handleChange,
}) => {
  const handleSupervisorChange = (
    _e: React.SyntheticEvent<HTMLElement, Event>,
    d: DropdownProps
  ) => {
    const { value } = d;
    handleChange(field, value);
  };
  return (
    <StyledDropdown
      placeholder='Select supervisor'
      fluid
      selection
      disabled={disabled}
      className='compliance-dropdown'
      key={field.id}
      value={field.value ? findValue : null}
      options={employees}
      onChange={handleSupervisorChange}
    />
  );
};

export const DocumentField: React.FC<DocumentFieldProps> = ({
  field,
  uploadingDocument,
  readOnly,
  documentClicked,
  documentRemoved,
  uploadDocument,
}) => {
  let addNewClass = 'complianceDocumentItem';
  if (uploadingDocument) addNewClass += ' disabled';

  const previewDocument = (link: string, filename: string) => {
    if (filename.endsWith('.pdf')) {
      return (
        <a href={link}>
          <div style={{ textAlign: 'center' }}>.pdf</div>
        </a>
      );
    }

    return <Image width='100px' height='100px' src={link} onClick={() => documentClicked(link)} />;
  };

  return (
    <div className='complianceDocumentsContainer' key={field.id}>
      {!field.filesDownloaded && field.value && field.value.length > 0 && (
        <div className='complianceDocumentItem'>
          <div style={{ height: '100px' }}>
            <Loader active inline />
          </div>
        </div>
      )}
      {field.filesDownloaded &&
        field.value &&
        field.value.length > 0 &&
        field.value.map((file: any) => (
          <div className='complianceDocumentItem' key={file.filename}>
            {previewDocument(field.downloadLinks[file.filename], file.filename)}
            {!readOnly && (
              <Button
                circular
                icon='delete'
                className='documentRemoveButton'
                onClick={() => documentRemoved(field.id, file.filename)}
              />
            )}
          </div>
        ))}
      {!readOnly && (
        <div className={addNewClass}>
          {uploadingDocument ? (
            <>
              {uploadingDocument === field.id ? (
                <div style={{ height: '100px' }}>
                  <Loader active inline />
                </div>
              ) : (
                <p style={{ color: '#ccc' }}>+</p>
              )}
            </>
          ) : (
            <>
              <label htmlFor={`fileInput${field.id}`} style={{ padding: '36px' }}>
                +
                <input
                  type='file'
                  accept='.jpg,.png,.jpeg,.gif,.pdf'
                  id={`fileInput${field.id}`}
                  className='fileinput'
                  style={{ display: 'none' }}
                  onChange={e => uploadDocument(e, field.id)}
                />
              </label>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export const DateText: React.FC<DateTextProps> = ({ date, vaccine = false }) => {
  if (date) {
    return (
      <span style={{ color: '#4a4a4a', paddingLeft: '0px', fontSize: '10px' }}>
        {moment(date).format(dateFormat)}
      </span>
    );
  }
  if (vaccine) {
    return (
      <span style={{ color: '#f5a623', paddingLeft: '0px', fontSize: '10px' }}>Incomplete</span>
    );
  }
  return <span style={{ color: '#f5a623', paddingLeft: '0px', fontSize: '10px' }}>N/A</span>;
};

export const ReadingText: React.FC<ReadingTextProps> = ({ testReading }) => {
  if (testReading) {
    return (
      <span style={{ color: '#4a4a4a', paddingLeft: '0px', fontSize: '10px' }}>
        {testReading || 'N/A'}
      </span>
    );
  }
  return <span style={{ color: '#f5a623', paddingLeft: '0px', fontSize: '10px' }}>N/A</span>;
};

export const getFrequencyName = (period: string) => {
  switch (period) {
    case 'half_yearly':
      return '6 months.';
    case 'monthly':
      return 'Monthly.';
    case 'one_time':
      return 'One time.';
    case 'two_years':
      return '2 years.';
    case 'three_years':
      return '3 years.';
    case 'four_years':
      return '4 years.';
    case 'five_years':
      return '5 years.';
    case 'six_years':
      return '6 years.';
    case 'ten_years':
      return '10 years.';
    case 'expiration_date':
      return 'based on expiration date.';
    case 'yearly':
    default:
      return 'Annual.';
  }
};

export const sortField = (fields: BlockField[]) => {
  const types = [
    { name: 'date', val: 1 },
    { name: 'text', val: 2 },
    { name: 'double', val: 3 },
    { name: 'supervisor', val: 4 },
    { name: 'document', val: 5 },
  ];
  const fieldsSorted = fields.sort((a, b) => {
    const [aVal] =
      a.name === 'measure_expiration_date'
        ? [0]
        : types.filter(t => t.name === a.type).map(s => s.val);
    const [bVal] =
      b.name === 'measure_expiration_date'
        ? [0]
        : types.filter(t => t.name === b.type).map(s => s.val);
    if (aVal === bVal) return 0;
    if (aVal < bVal) return -1;
    return 1;
  });
  return fieldsSorted;
};

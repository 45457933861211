/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Dimmer, Dropdown, Grid, Header, Image, Table, Pagination } from 'semantic-ui-react';
import moment from 'moment';
import { parse } from 'papaparse';
import PQueue from 'p-queue';
import { CSVLink } from 'react-csv';
import _ from 'underscore';
import { alertActions, importActions } from '../actions';
import { backend } from '../services';
import { isAdmin } from '../constants/DomainTypes';
import CareRoundButton from './subcomponents/CareRoundButton';
import { GreenButton } from '../styles/common';
import {
  caregiverSchema,
  clientSchema,
  employeeSchema,
  shiftSchema,
} from '../helpers/importValidation';
import { FileConverterType, applyConverter } from '../helpers/csvFileConverter';
import {
  caregiverHeaders,
  clientHeaders,
  employeeHeaders,
  shiftHeaders,
  caregiverCells,
  clientCells,
  employeeCells,
  shiftCells,
} from './subcomponents/imports/importTableHelper';
import { dateFormat } from '../helpers/common';
import './Common.css';
import './Imports.css';
import { ProfileMenu } from './Profile';
import CareLoader from './subcomponents/CareLoader';
import PageMeter from './subcomponents/PageMeter';
// import PageSizer from './subcomponents/PageSizer';
import PageSizerHorizontal from './subcomponents/PageSizerHorizontal';

const PAGE_SIZE = 500;

const CSVFileImport = connect()(({ parseData }) => {
  function importDataEvent(e) {
    parseData(e.target.files[0]);
    e.target.value = '';
  }

  return (
    <input
      type='file'
      accept='.csv'
      style={{ display: 'none' }}
      onChange={importDataEvent}
      ref={node => {
        CSVFileImport.importDataInput = node;
      }}
    />
  );
});

const queue = new PQueue({ concurrency: 10, interval: 2000, intervalCap: 10 });

class Imports extends React.Component {
  static sort(data, field) {
    if (!data) {
      return data;
    }

    return [...data].sort((a, b) => {
      let aVal = null;
      let bVal = null;
      switch (field) {
        default:
          aVal = a[field];
          bVal = b[field];
      }

      if (aVal === bVal) return 0;
      if (aVal > bVal) return -1;
      return 1;
    });
  }

  static importState(state, unhandledRecords) {
    switch (state) {
      case 'new':
        return 'New';
      case 'invalid':
        return 'Invalid file';
      case 'failed':
        return 'Failed import';
      case 'processed':
        if (unhandledRecords) {
          return 'Processed, skipped rows';
        }
        return 'Processed';

      default:
        return state;
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      meterState: {},
      importDetailId: null,
      column: 'timestamp',
      caregiverCsvData: [],
      employeeCsvData: [],
      clientCsvData: [],
      shiftCsvData: [],
      caregiverInvalidData: [],
      employeeInvalidData: [],
      clientInvalidData: [],
      shiftInvalidData: [],
      isValidCsvData: false,
      missingData: [],
      direction: 'ascending',
      fileType: '',
      fileConverter: 'none',
      agencyExternalId: null,
      timezone: null,
      ssnRequired: true,
      loadingImport: false,
      buttonLoading: false,
      startImport: false,
      failRecords: [],
      successRecords: [],
      validTotalCount: 0,
      invalidTotalCount: 0,
      validTotalPages: 0,
      invalidTotalPages: 0,
      activeValidPage: [],
      activeInvalidPage: [],
      buttonDisabled: false,
    };

    this.tableHeader = this.tableHeader.bind(this);
    this.tableBody = this.tableBody.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.onMeterChanged = this.onMeterChanged.bind(this);
    this.onFileClick = this.onFileClick.bind(this);
  }

  async componentDidMount() {
    const { agencyExternalId, timezone, ssnRequired } = await backend.getCachedAgencySettings();
    this.setState({ agencyExternalId, timezone, ssnRequired });
  }

  onMeterChanged(state) {
    this.setState(s => ({
      ...s,
      meterState: { ...s.meterState, ...state },
    }));
  }

  onFileClick = importId => () => {
    this.setState(
      s => ({
        ...s,
        importDetailId: importId,
      }),
      () => this.props.dispatch(importActions.getImportLines(importId))
    );
  };

  onFileTypeChange = (e, input) => {
    this.setState(s => ({
      ...s,
      fileType: input.value,
      failRecords: [],
      successRecords: [],
      validTotalCount: 0,
      invalidTotalCount: 0,
      validTotalPages: 0,
      invalidTotalPages: 0,
      activeValidPage: [],
      activeInvalidPage: [],
      buttonDisabled: false,
    }));
  };

  onFileConverterChange = (e, input) => {
    this.setState({ fileConverter: input.value });
  };

  onImportDataClick = () => {
    this.setState(s => ({
      ...s,
      failRecords: [],
      successRecords: [],
      validTotalCount: 0,
      invalidTotalCount: 0,
      validTotalPages: 0,
      invalidTotalPages: 0,
      activeValidPage: [],
      activeInvalidPage: [],
      buttonDisabled: false,
    }));
    if (CSVFileImport.importDataInput) {
      CSVFileImport.importDataInput.click();
    }
  };

  onUploadDataClick = () => {
    this.setState(s => ({ ...s, buttonLoading: true }));
    this.enqueueItems();
  };

  parseCsvData = csv => {
    const { dispatch } = this.props;
    const { fileType, fileConverter, agencyExternalId, timezone } = this.state;
    this.setState(s => ({ ...s, loadingImport: true }));
    let tableData;
    switch (fileType) {
      case 'caregiver-csv':
        tableData = 'caregiverCsvData';
        break;
      case 'employee-csv':
        tableData = 'employeeCsvData';
        break;
      case 'client-csv':
        tableData = 'clientCsvData';
        break;
      case 'shift-csv':
        tableData = 'shiftCsvData';
        break;
      default:
    }
    try {
      parse(csv, {
        download: true,
        header: true,
        skipEmptyLines: true,
        complete: results => {
          const lastModified = moment.utc().toISOString();
          const converted = results.data?.map(x =>
            applyConverter({
              agencyExternalId,
              timezone,
              lastModified,
              fileType,
              fileConverter,
              data: x,
            })
          );
          this.setState(
            s => ({ ...s, [tableData]: converted }),
            () => this.validateCsvData()
          );
        },
      });
    } catch (e) {
      dispatch(alertActions.error(e));
    }
  };

  handleImportResult = () => {
    const { dispatch } = this.props;
    const { failRecords, successRecords } = this.state;

    if (successRecords.length) {
      dispatch(alertActions.message(`${successRecords.length} Records have been imported`));
    }

    if (failRecords.length) {
      dispatch(alertActions.message(`Importing of ${failRecords.length} records failed`));
    }
  };

  enqueueItems = () => {
    const formattedData = this.setJsonStructure();
    this.setState(s => ({ ...s, startImport: true }));

    if (!_.isEmpty(formattedData)) {
      formattedData.forEach(item => {
        this.enqueueItem(item);
      });
    }

    queue.onIdle().then(() => {
      this.setState(
        s => ({
          ...s,
          buttonLoading: false,
          buttonDisabled: true,
        }),
        () => setTimeout(this.handleImportResult, 1000)
      );
    });
  };

  enqueueItem = item => {
    const externalId = item.externalId || item.clientExternalId;
    (async () => {
      await queue
        .add(() => this.buildRequest(item))
        .then(() => {
          this.setState(s => ({
            ...s,
            successRecords: [...s.successRecords, { externalId }],
          }));
        })
        .catch(error => {
          this.setState(s => ({
            ...s,
            failRecords: [...s.failRecords, { externalId, error: error.json.error }],
          }));
        });
    })();
  };

  buildRequest = item => {
    const { fileType } = this.state;
    switch (fileType) {
      case 'caregiver-csv':
        return backend.createProfileForAgency(item);
      case 'employee-csv':
        return backend.createProfileForAgency(item);
      case 'client-csv':
        return backend.createClientForAgency(item);
      case 'shift-csv':
        return backend.createShiftForAgency(item);
      default:
        return null;
    }
  };

  setJsonStructure = () => {
    const { fileType, caregiverCsvData, clientCsvData, employeeCsvData, shiftCsvData } = this.state;

    let csvData;
    let profileType;
    switch (fileType) {
      case 'caregiver-csv':
        profileType = 'caregiver';
        csvData = JSON.parse(JSON.stringify(caregiverCsvData));
        break;
      case 'employee-csv':
        profileType = 'employee';
        csvData = JSON.parse(JSON.stringify(employeeCsvData));
        break;
      case 'client-csv':
        profileType = 'client';
        csvData = JSON.parse(JSON.stringify(clientCsvData));
        break;
      case 'shift-csv':
        profileType = 'shift';
        csvData = JSON.parse(JSON.stringify(shiftCsvData));
        break;
      default:
    }

    const newData = csvData.map(d => {
      if (fileType === 'client-csv') {
        delete Object.assign(d, { clientExternalId: d.externalId }).externalId;
      }

      if (fileType === 'caregiver-csv') {
        const blockShift = d.blockShiftMatching ? parseInt(d.blockShiftMatching, 10) : 0;
        Object.assign(d, { blockShiftMatching: !!blockShift });
      }

      if (fileType === 'shift-csv') {
        const shiftTemplate = d.template ? parseInt(d.template, 10) : 0;
        Object.assign(d, { template: !!shiftTemplate });
      }

      if (fileType === 'employee-csv') {
        const coordinator = d.isCoordinator ? parseInt(d.isCoordinator, 10) : 0;
        const manager = d.isManager ? parseInt(d.isManager, 10) : 0;
        const meta = this.createEmployeeMeta(d);
        Object.assign(d, { isCoordinator: !!coordinator });
        Object.assign(d, { isManager: !!manager });
        Object.assign(d, { meta });
      }

      return Object.assign(d, {
        profileType,
        source: 'csv',
        phones: this.getPhones(d),
        languages: this.getLanguages(d),
        address: this.getAddress(d),
        disciplines: this.getDisciplines(d),
        preferenceNames: this.getPreferenceNames(d),
      });
    });

    return newData;
  };

  validateCsvData = async () => {
    const { fileType, caregiverCsvData, employeeCsvData, clientCsvData, shiftCsvData } = this.state;

    let validTableData;
    let inValidTableData;
    let dataToValidate;

    const validateData = async schema => {
      const validData = await schema.validate(dataToValidate, { abortEarly: false }).catch(e => {
        const errors = e.inner.map(err => {
          const index = parseInt(err.path.slice(1, err.path.indexOf(']')), 10);
          const field = err.params.path.slice(err.params.path.indexOf('.') + 1);
          const { message } = err;
          return { index, field, message };
        });

        const mapInvalidFields = dataToValidate.map((d, i) => {
          const invalidFields = errors
            .filter(er => er.index === i)
            .map(err => {
              const { field, message } = err;
              return { field, message };
            });
          if (invalidFields.length) {
            Object.assign(d, { invalidFields });
          }
          return d;
        });

        const invalidData = mapInvalidFields.filter((d, i) => errors.find(er => er.index === i));
        const validatedData = mapInvalidFields.filter((d, i) => !errors.find(er => er.index === i));
        this.setState({
          [validTableData]: validatedData,
          activeValidPage: validatedData.slice(0, PAGE_SIZE),
          validTotalPages: Math.ceil(validatedData.length / PAGE_SIZE),
          validTotalCount: validatedData.length,
          [inValidTableData]: invalidData,
          activeInvalidPage: invalidData.slice(0, PAGE_SIZE),
          invalidTotalPages: Math.ceil(invalidData.length / PAGE_SIZE),
          invalidTotalCount: invalidData.length,
        });
      });

      if (validData) {
        return this.setState(s => ({
          ...s,
          isValidCsvData: true,
          loadingImport: false,
          [validTableData]: dataToValidate,
          activeValidPage: dataToValidate.slice(0, PAGE_SIZE),
          activeInvalidPage: [],
          invalidTotalCount: 0,
          invalidTotalPages: 0,
          validTotalPages: Math.ceil(dataToValidate.length / PAGE_SIZE),
          validTotalCount: dataToValidate.length,
          [inValidTableData]: [],
        }));
      }

      return this.setState({
        loadingImport: false,
        successRecords: [],
        failRecords: [],
        startImport: false,
      });
    };

    switch (fileType) {
      case 'caregiver-csv':
        validTableData = 'caregiverCsvData';
        inValidTableData = 'caregiverInvalidData';
        dataToValidate = caregiverCsvData;
        return validateData(caregiverSchema);
      case 'employee-csv':
        validTableData = 'employeeCsvData';
        inValidTableData = 'employeeInvalidData';
        dataToValidate = employeeCsvData;
        return validateData(employeeSchema);
      case 'client-csv':
        validTableData = 'clientCsvData';
        inValidTableData = 'clientInvalidData';
        dataToValidate = clientCsvData;
        return validateData(clientSchema);
      case 'shift-csv':
        validTableData = 'shiftCsvData';
        inValidTableData = 'shiftInvalidData';
        dataToValidate = shiftCsvData;
        return validateData(shiftSchema);
      default:
        return null;
    }
  };

  createEmployeeMeta = data => {
    const meta = {};
    const { unit, location, surveyNames, days, sendAt } = data;
    meta.unit = unit || undefined;
    meta.location = location || undefined;
    const surveys = surveyNames ? surveyNames.split(',') : undefined;
    const daysSplit = days ? days.split(';') : undefined;
    const sendAtSplit = sendAt ? sendAt.split(',') : undefined;
    if (surveys) {
      surveys.forEach((s, i) =>
        Object.assign(meta, { [s]: { days: daysSplit[i], sendAt: sendAtSplit[i], isSurvey: 1 } })
      );
    }
    return meta;
  };

  getPhones = data => {
    const phones = [];
    if (!_.isEmpty(data.mobilePhone)) {
      const phone = {
        type: 'mobile',
        number: data.mobilePhone,
        primary: false,
      };
      phones.push(phone);
    }
    if (!_.isEmpty(data.homePhone)) {
      const phone = {
        type: 'home',
        number: data.homePhone,
        primary: false,
      };
      phones.push(phone);
    }
    if (!_.isEmpty(data.workPhone)) {
      const phone = {
        type: 'work',
        number: data.workPhone,
        primary: false,
      };
      phones.push(phone);
    }

    return phones;
  };

  getLanguages = data => {
    const languages = [];
    if (!_.isEmpty(data.primaryLanguage)) {
      const splitLanguages = !_.isEmpty(data.languages) ? data.languages.toString().split(',') : [];
      splitLanguages.push(data.primaryLanguage);
      const flatLanguages = new Set(splitLanguages);
      const items = [...flatLanguages];
      if (!_.isEmpty(items)) {
        items.forEach(item => {
          const language = {
            code: item,
            primary: data.primaryLanguage === item,
          };
          languages.push(language);
        });
      }
    }
    return languages;
  };

  getDisciplines = data => {
    let disciplines = [];
    if (!_.isEmpty(data.disciplines)) {
      disciplines = data.disciplines.toString().split(',');
    }
    return disciplines;
  };

  getPreferenceNames = data => {
    let preferenceNames = [];
    if (!_.isEmpty(data.preferenceNames)) {
      preferenceNames = data.preferenceNames.toString().split(',');
    }
    return preferenceNames;
  };

  getAddress = data => {
    const address = {
      street: data.street,
      street2: data.street2,
      apartment: data.apartment,
      city: data.city,
      state: data.state,
      zip: data.zip,
      countryCode: data.countryCode,
    };
    return address;
  };

  formatCsv = () => {
    const {
      fileType,
      caregiverInvalidData,
      clientInvalidData,
      employeeInvalidData,
      shiftInvalidData,
    } = this.state;

    let csvData;
    switch (fileType) {
      case 'caregiver-csv':
        csvData = caregiverInvalidData;
        break;
      case 'employee-csv':
        csvData = employeeInvalidData;
        break;
      case 'client-csv':
        csvData = clientInvalidData;
        break;
      case 'shift-csv':
        csvData = shiftInvalidData;
        break;
      default:
    }

    return csvData?.map(item => {
      const invalidFields = item.invalidFields
        ? item.invalidFields
            .map(f => `${f.field}: ${f.message}`)
            .join(' | ')
            .replace(/"/g, "'")
        : '';
      return {
        ...item,
        invalidFields,
      };
    });
  };

  getFileName = time => {
    const { fileType } = this.state;

    const fileName = `${fileType}-invalid-records${time}`;
    return fileName;
  };

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;

    if (!data) return;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: Imports.sort(data, clickedColumn),
        direction: 'ascending',
      });

      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  };

  errorTableData = () => {
    const {
      fileType,
      caregiverInvalidData,
      employeeInvalidData,
      clientInvalidData,
      shiftInvalidData,
    } = this.state;
    let tableData;
    switch (fileType) {
      case 'caregiver-csv':
        tableData = caregiverInvalidData;
        break;
      case 'employee-csv':
        tableData = employeeInvalidData;
        break;
      case 'client-csv':
        tableData = clientInvalidData;
        break;
      case 'shift-csv':
        tableData = shiftInvalidData;
        break;
      default:
        tableData = [];
    }
    return tableData;
  };

  tableData = () => {
    const { fileType, caregiverCsvData, employeeCsvData, clientCsvData, shiftCsvData } = this.state;
    let tableData;
    switch (fileType) {
      case 'caregiver-csv':
        tableData = caregiverCsvData;
        break;
      case 'employee-csv':
        tableData = employeeCsvData;
        break;
      case 'client-csv':
        tableData = clientCsvData;
        break;
      case 'shift-csv':
        tableData = shiftCsvData;
        break;
      default:
        tableData = [];
    }
    return tableData;
  };

  tableHeader = (column, direction, onHeaderClick) => {
    const { fileType } = this.state;
    let headerArray;

    switch (fileType) {
      case 'caregiver-csv':
        headerArray = caregiverHeaders;
        break;
      case 'employee-csv':
        headerArray = employeeHeaders;
        break;
      case 'client-csv':
        headerArray = clientHeaders;
        break;
      case 'shift-csv':
        headerArray = shiftHeaders;
        break;
      default:
        return null;
    }

    headerArray
      .filter(f => f.filter)
      .forEach(h => {
        // array is initial state, in initial set name as a sorted col
        // eslint-disable-next-line no-param-reassign
        h.className =
          column === h.key || (Array.isArray(column) && h.key === 'name')
            ? `${direction} sorted`
            : undefined;

        // eslint-disable-next-line no-param-reassign
        h.onClick = onHeaderClick(h.key);

        // eslint-disable-next-line no-param-reassign
        h.filter = undefined;
      });

    return headerArray;
  };

  tableBody = (x, i) => {
    const { fileType, failRecords, successRecords } = this.state;
    let bodyCells;

    switch (fileType) {
      case 'caregiver-csv':
        bodyCells = caregiverCells(x, failRecords);
        break;
      case 'employee-csv':
        bodyCells = employeeCells(x, failRecords);
        break;
      case 'client-csv':
        bodyCells = clientCells(x, failRecords);
        break;
      case 'shift-csv':
        bodyCells = shiftCells(x, failRecords);
        break;
      default:
        return null;
    }
    return {
      key: `${x.externalId}${i}` || `row-${i}`,
      positive: !!successRecords.find(r => r.externalId === x.externalId),
      negative: !!failRecords.find(r => r.externalId === x.externalId),
      cells: bodyCells,
    };
  };

  onValidPageChange = (e, data) => {
    const { fileType, caregiverCsvData, employeeCsvData, clientCsvData, shiftCsvData } = this.state;
    const newPage = data.activePage;
    const newIndex = (newPage - 1) * PAGE_SIZE;
    const newEnd = newIndex - 1 + PAGE_SIZE;

    let newActiveData;
    switch (fileType) {
      case 'caregiver-csv':
        newActiveData = caregiverCsvData.slice(newIndex, newEnd);
        break;
      case 'employee-csv':
        newActiveData = employeeCsvData.slice(newIndex, newEnd);
        break;
      case 'client-csv':
        newActiveData = clientCsvData.slice(newIndex, newEnd);
        break;
      case 'shift-csv':
        newActiveData = shiftCsvData.slice(newIndex, newEnd);
        break;
      default:
        newActiveData = [];
    }
    return this.setState(s => ({
      ...s,
      activeValidPage: newActiveData,
    }));
  };

  onInvalidPageChange = (e, data) => {
    const {
      fileType,
      caregiverInvalidData,
      employeeInvalidData,
      clientInvalidData,
      shiftInvalidData,
    } = this.state;
    const newPage = data.activePage;
    const newIndex = (newPage - 1) * PAGE_SIZE;
    const newEnd = newIndex - 1 + PAGE_SIZE;

    let newActiveData;
    switch (fileType) {
      case 'caregiver-csv':
        newActiveData = caregiverInvalidData.slice(newIndex, newEnd);
        break;
      case 'employee-csv':
        newActiveData = employeeInvalidData.slice(newIndex, newEnd);
        break;
      case 'client-csv':
        newActiveData = clientInvalidData.slice(newIndex, newEnd);
        break;
      case 'shift-csv':
        newActiveData = shiftInvalidData.slice(newIndex, newEnd);
        break;
      default:
        newActiveData = [];
    }
    return this.setState(s => ({
      ...s,
      activeInvalidPage: newActiveData,
    }));
  };

  fileTypes = () => [
    { text: 'Caregiver CSV', value: 'caregiver-csv' },
    { text: 'Employee CSV', value: 'employee-csv' },
    { text: 'Client CSV', value: 'client-csv' },
    { text: 'Shift CSV', value: 'shift-csv' },
  ];

  fileConverters = () => [
    { text: 'None', value: 'none' },
    { text: 'HHAX', value: FileConverterType.HHAX },
  ];

  isSsnRequired = () => this.state.ssnRequired;

  render() {
    const {
      column,
      direction,
      loadingImport,
      fileType,
      buttonLoading,
      startImport,
      failRecords,
      successRecords,
      validTotalCount,
      invalidTotalCount,
      validTotalPages,
      invalidTotalPages,
      activeValidPage,
      activeInvalidPage,
      buttonDisabled,
    } = this.state;

    const { rootUser } = this.props.employee;
    const isUserAdmin = rootUser || isAdmin(this.props.roles);

    return (
      <Grid.Row className='noVerticalPadding'>
        {loadingImport && (
          <Dimmer active page>
            <CareLoader loading={loadingImport} invert showText={false} />
          </Dimmer>
        )}
        <Grid className='contentGrid' padded>
          <Grid.Column width={3} className='menuCol'>
            <ProfileMenu
              email='care@connect.com'
              isUserAdmin={isUserAdmin}
              isRootAdmin={rootUser && isUserAdmin}
              settings={this.props.settings}
            />
          </Grid.Column>

          <Grid.Column width='thirteen'>
            <CSVFileImport csvFileType={this.state.csvFileType} parseData={this.parseCsvData} />

            <div className='import-header-container'>
              <Header floated='left' as='h3' style={{ color: '#364866' }}>
                IMPORTS
              </Header>
              <div className='file-type-container'>
                <span style={{ marginRight: '10px' }}>File Type:</span>
                <Dropdown
                  id='importFileTypeDropdown'
                  selection
                  name='importFileType'
                  options={this.fileTypes()}
                  onChange={this.onFileTypeChange}
                  value={this.state.fileType}
                />
                <span style={{ marginLeft: '10px', marginRight: '10px' }}>File Converter:</span>
                <Dropdown
                  id='fileConverterDropdown'
                  selection
                  name='fileConverter'
                  options={this.fileConverters()}
                  onChange={this.onFileConverterChange}
                  value={this.state.fileConverter}
                />
              </div>
              <CareRoundButton
                id='import-button'
                className={`${!fileType ? 'import-disabled-button' : 'care-round-button'}`}
                content='+'
                onClick={this.onImportDataClick}
              />
            </div>
            <PageMeter name='top' onChange={this.onMeterChanged} />
            <PageMeter name='bottom' onChange={this.onMeterChanged} />
            {!!activeValidPage.length && (
              <Grid padded style={{ maxWidth: '85vw', paddingRight: '70px' }}>
                <div>
                  <p style={{ color: '#364866', fontSize: '14px', fontWeight: 'bold' }}>
                    {validTotalCount} VALID RECORDS OF {validTotalCount + invalidTotalCount}
                  </p>
                </div>
                <PageSizerHorizontal
                  meterState={this.state.meterState}
                  active={activeValidPage && activeValidPage.length > 0}
                  contentHeight={activeValidPage ? activeValidPage.length * 50 : 0}
                >
                  <Table
                    className='importResults stickyHeader'
                    sortable
                    singleLine
                    striped
                    headerRow={this.tableHeader(column, direction, this.handleSort)}
                    renderBodyRow={this.tableBody}
                    tableData={activeValidPage}
                  />
                </PageSizerHorizontal>
                {validTotalPages > 1 && (
                  <Pagination
                    defaultActivePage={1}
                    totalPages={validTotalPages}
                    onPageChange={this.onValidPageChange}
                  />
                )}

                {!!activeValidPage.length && (
                  <div className='import-footer' style={{ padding: '0' }}>
                    <div className='import-footer-results'>
                      <h4> RESULTS: </h4>
                      <p>
                        <b style={{ color: '#07c957', marginRight: '5px' }}>
                          {successRecords.length}
                        </b>
                        Complete Imports
                      </p>
                      <span />
                      {!!failRecords.length && (
                        <p>
                          <Image src='/Attention.svg' inline />{' '}
                          <b style={{ color: '#f16778', marginRight: '5px' }}>
                            {failRecords.length}
                          </b>
                          Fails to Import
                        </p>
                      )}
                    </div>
                    <div className='import-footer-continue'>
                      <p>
                        <b>{validTotalCount} </b>records will be imported
                      </p>{' '}
                      <GreenButton
                        disabled={buttonLoading || buttonDisabled}
                        loading={buttonLoading}
                        onClick={this.onUploadDataClick}
                      >
                        Continue
                      </GreenButton>
                    </div>
                  </div>
                )}
              </Grid>
            )}
            {!!activeInvalidPage.length && !startImport && (
              <Grid padded style={{ maxWidth: '85vw', paddingRight: '70px', paddingTop: '25px' }}>
                <div className='imports-invalid-records-text'>
                  <p>
                    <Image src='/Attention.svg' inline />
                    <b>{invalidTotalCount} </b>
                    Records have invalid or missing required values
                  </p>
                  <CSVLink
                    data={this.formatCsv()}
                    filename={this.getFileName(moment().format(dateFormat))}
                    target='_blank'
                    className='survey-export-link'
                  >
                    <span>Export Invalid Records</span>
                  </CSVLink>
                </div>
                <PageSizerHorizontal
                  meterState={this.state.meterState}
                  active={activeInvalidPage && activeInvalidPage.length > 0}
                  contentHeight={activeInvalidPage ? activeInvalidPage.length * 50 : 0}
                >
                  <Table
                    className='importResults stickyHeader'
                    sortable
                    singleLine
                    striped
                    headerRow={this.tableHeader(column, direction, this.handleSort)}
                    renderBodyRow={this.tableBody}
                    tableData={activeInvalidPage}
                  />
                </PageSizerHorizontal>
                {invalidTotalPages > 1 && (
                  <Pagination
                    defaultActivePage={1}
                    totalPages={invalidTotalPages}
                    onPageChange={this.onInvalidPageChange}
                  />
                )}
              </Grid>
            )}
            {!activeValidPage.length && !activeInvalidPage.length && (
              <Grid.Row
                columns='one'
                style={{ marginTop: '10px' }}
                className='emptyStateRow'
                centered
              >
                <div className='imports-empty-state'>
                  <Header color='grey' size='medium'>
                    No CSV files imported
                  </Header>
                  <Image src='/searchmatch.svg' />
                </div>
              </Grid.Row>
            )}
          </Grid.Column>
        </Grid>
      </Grid.Row>
    );
  }
}

Imports.propTypes = {
  dispatch: PropTypes.func.isRequired,
  employee: PropTypes.shape.isRequired,
  settings: PropTypes.shape.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = state => {
  const { imports, employee } = state;
  return {
    imports,
    employee,
    settings: state.principal.settings,
    roles: state.principal.roles,
  };
};

export default connect(mapStateToProps)(Imports);

/* eslint-disable react/destructuring-assignment */
import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Button, Container, Divider } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../HRApp.css';

const localizer = momentLocalizer(moment);

class HRCalendarView extends React.Component {
  componentDidMount() {
    try {
      if (this.props.scrollBottom) {
        const node = document.querySelector('.rbc-time-content');
        // try to estimate perfect position to see actual workday
        node.scrollTo(0, (node.scrollHeight - node.offsetHeight) * 0.7);
      }
    } catch (e) {
      // console.log(e);
    }
  }

  onSlotSelecting = () => !this.props.disabled;

  customToolbar = toolbar => {
    if (!this.props.showToolbar) return <></>;

    const onNext = () => {
      let date = moment(toolbar.date);
      if (toolbar.view === 'day') date = date.add(1, 'day');
      if (toolbar.view === 'week') date = date.add(1, 'week');
      if (toolbar.view === 'month') date = date.add(1, 'month');
      toolbar.onNavigate('next', date.toDate());
    };
    const onPrevious = () => {
      let date = moment(toolbar.date);
      if (toolbar.view === 'day') date = date.subtract(1, 'day');
      if (toolbar.view === 'week') date = date.subtract(1, 'week');
      if (toolbar.view === 'month') date = date.subtract(1, 'month');
      toolbar.onNavigate('next', date.toDate());
    };

    const onToday = () => {
      toolbar.onNavigate(
        'today',
        moment()
          .startOf('day')
          .toDate()
      );
    };

    const selectedStyle = { color: '#4a4a4a' };
    const selectedDot = { backgroundColor: '#04a5d5' };
    return (
      <div style={{ marginTop: '40px', minHeight: '20px' }}>
        <Container>
          <div style={{ position: 'absolute', left: '14px', top: '40px' }}>
            <Button
              id='hr-calendar-view-today-button'
              content='Today'
              className='todayButton'
              onClick={onToday}
            />
            <Button
              id='hr-calendar-view-prev-button'
              className='angleButton'
              onClick={onPrevious}
              icon='angle left'
            />
            <Button
              id='hr-calendar-view-next-button'
              className='angleButton'
              onClick={onNext}
              icon='angle right'
            />
          </div>
          <h3>{toolbar.label}</h3>
          <div style={{ position: 'absolute', right: '14px', top: '26px' }}>
            <Button.Group floated='right'>
              <Button
                id='hr-calendar-view-month-button'
                className='viewMode vertical'
                style={toolbar.view === 'month' ? selectedStyle : {}}
                onClick={() => toolbar.onView('month')}
              >
                <span className='dot' style={toolbar.view === 'month' ? selectedDot : {}} />
                <span style={{ marginTop: '10px' }}>Month </span>
              </Button>
              <Button
                id='hr-calendar-view-week-button'
                className='viewMode vertical'
                style={toolbar.view === 'week' ? selectedStyle : {}}
                onClick={() => toolbar.onView('week')}
              >
                <span className='dot' style={toolbar.view === 'week' ? selectedDot : {}} />
                <span style={{ marginTop: '10px' }}>Week </span>
              </Button>
              <Button
                id='hr-calendar-view-day-button'
                className='viewMode vertical'
                style={toolbar.view === 'day' ? selectedStyle : {}}
                onClick={() => toolbar.onView('day')}
              >
                {' '}
                <span className='dot' style={toolbar.view === 'day' ? selectedDot : {}} />
                <span style={{ marginTop: '10px' }}>Day </span>
              </Button>
            </Button.Group>
          </div>
        </Container>
        <Divider style={{ margin: '40px 0 20px 0' }} />
      </div>
    );
  };

  coloredDateCellWrapper = ({ children, value }) => {
    const today = moment(value)
      .startOf('day')
      .isSame(moment().startOf('day'));
    const past = !today && value < moment().toDate();
    let color;
    if (today && this.props.showToolbar) color = '#e5f5ff';
    else if (past) color = '#e5e5e5';
    else color = '#fff';

    return React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        backgroundColor: color,
        color: '#aaa',
      },
    });
  };

  eventStyleGetter = event => {
    const style = {
      backgroundColor: '#ffffff',
      color: 'black',
      borderRadius: '4px',
      borderWidth: '1px',
      borderStyle: 'solid',
      display: 'block',
      borderColor: event.signedOff ? '#69db8f' : '#09b4e7',
    };
    return {
      style,
    };
  };

  slotStyleGetter = start => {
    const style = {
      backgroundColor: moment(start)
        .add(0.5, 'hours')
        .isBefore(moment())
        ? '#e5e5e5'
        : '#fff',
      borderColor: moment(start)
        .add(0.5, 'hours')
        .isBefore(moment())
        ? '#e5e5e5'
        : '#fff',
    };
    return {
      style,
    };
  };

  toCalendarEvent = event => ({
    id: event.id,
    allDay: false,
    title: event.block.name,
    description: event.block.description,
    start: moment(event.date).toDate(),
    end: moment(event.date)
      .add(event.hours, 'hours')
      .toDate(),
    blockId: event.block.id,
    signedOff: !!event.signedOff,
  });

  render() {
    const { events, loadingEvents } = this.props;

    return (
      <div
        style={{ height: '100%', minHeight: this.props.minHeight, maxHeight: this.props.maxHeight }}
      >
        {loadingEvents && <Dimmer />}
        <Calendar
          selectable
          localizer={localizer}
          defaultView={this.props.defaultView}
          events={events.map(e => this.toCalendarEvent(e))}
          onSelectEvent={this.props.onSelect}
          onSelectSlot={this.props.onSlotSelected}
          selected={this.props.selectedEvent}
          onSelecting={this.onSlotSelecting}
          startAccessor='start'
          endAccessor='end'
          eventPropGetter={this.eventStyleGetter}
          slotPropGetter={this.slotStyleGetter}
          className='hr-calendar'
          components={{
            dateCellWrapper: this.coloredDateCellWrapper,
            toolbar: this.customToolbar,
          }}
          formats={{
            dateFormat: 'D',
            dayFormat: 'ddd D',
            timeGutterFormat: (date, culture, lzr) => lzr.format(date, 'h a', culture),
          }}
        />
      </div>
    );
  }
}

HRCalendarView.defaultProps = {
  events: [],
  loadingEvents: false,
  onSelect: () => {},
  onSlotSelected: () => {},
  selectedEvent: null,
  defaultView: 'week',
  showToolbar: true,
  scrollBottom: true,
  minHeight: '700px',
  maxHeight: undefined,
  disabled: false,
};

HRCalendarView.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape()),
  loadingEvents: PropTypes.bool,
  onSelect: PropTypes.func,
  onSlotSelected: PropTypes.func,
  selectedEvent: PropTypes.shape(),
  defaultView: PropTypes.string,
  showToolbar: PropTypes.bool,
  scrollBottom: PropTypes.bool,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  disabled: PropTypes.bool,
};

export default injectIntl(HRCalendarView);

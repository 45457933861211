import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, Checkbox, Image } from 'semantic-ui-react';
import { RecordsTile, RecordsHeader } from '../../styles/records';
import '../TrainPref.css';
import './SystemExtensions.css';

let changedExtensions = {};

class SystemExtensions extends React.Component {
  constructor(props) {
    super(props);

    this.onValueChanged.bind(this);
  }

  onValueChanged = (name, value) => {
    changedExtensions[name] = true;
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onValueUpdated(name, value);
  };

  render() {
    const { dirty, smcSupported } = this.props;

    const extensionsBody =
      'Select any additional tools your caregivers utilize that CareConnect can work alongside. This will help direct your caregivers to the correct tools at the right time';
    const SandataBody = 'Utilized for electronic visit verification';

    if (!dirty) changedExtensions = {};

    return (
      <RecordsTile className='system-extensions-tile'>
        <Form>
          <Grid padded>
            <Grid.Row>
              <Grid.Column width={16}>
                <RecordsHeader>System Extensions</RecordsHeader>
              </Grid.Column>
            </Grid.Row>
            <hr className='rates-hr' />
            <Grid.Row>
              <Grid.Column width={16}>
                <p className='system-extension-body-text'>{extensionsBody}</p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row
              style={{ margin: '0 1rem 0 1rem' }}
              className={dirty && changedExtensions.smcSupported ? 'table-item-updated' : ''}
            >
              <hr className='extensions-hr' />
              <Grid.Column width={4} style={{ paddingLeft: '0' }}>
                <Image src='/sandataLogo.svg' />
              </Grid.Column>
              <Grid.Column width={10} style={{ paddingLeft: '0' }}>
                <h5 size='tiny' className='system-extension-header-Text'>
                  Sandata Mobile Connect
                </h5>
                <p className='system-extension-sandata-body-text'>{SandataBody}</p>
              </Grid.Column>

              <Grid.Column width={2} style={{ paddingLeft: '0' }}>
                <Form.Group grouped widths='equal'>
                  <div className='extensions-check-box-field'>
                    <Checkbox
                      id='smcSupported'
                      checked={smcSupported}
                      onChange={() => {
                        const value = !smcSupported;
                        return this.onValueChanged('smcSupported', value);
                      }}
                      toggle
                    />
                  </div>
                </Form.Group>
              </Grid.Column>
              <hr className='extensions-hr' />
            </Grid.Row>
          </Grid>
        </Form>
      </RecordsTile>
    );
  }
}
SystemExtensions.defaultProps = {
  dirty: false,
};

SystemExtensions.propTypes = {
  onValueUpdated: PropTypes.func.isRequired,
  smcSupported: PropTypes.bool.isRequired,
  dirty: PropTypes.bool,
};

export default SystemExtensions;

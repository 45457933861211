import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Grid,
  Image,
  Icon,
  Input,
  Dropdown,
  TextArea,
  Button,
  Divider,
  Checkbox,
} from 'semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import './addComplianceBlockForm.css';
import WizardModal from '../WizardModal';
import '../../CGCompliance.css';

const icons = {
  documentation: '/compliance/documentupload.svg',
  training: '/compliance/training.svg',
  medical: '/compliance/medical.svg',
};

const types = {
  DOCUMENT: 'documentation',
  TRAINING: 'training',
  MEDICAL: 'medical',
};

const fieldTypes = {
  text: { value: 'text', text: 'Text Entry' },
  date: { value: 'date', text: 'Date Entry' },
  supervisor: { value: 'supervisor', text: 'Supervisor' },
  document: { value: 'document', text: 'Document Upload' },
};

const subtypes = {
  training: [
    { value: 'in-service', text: 'In-Service Training', enableHours: true },
    { value: 'other', text: 'Other', enableHours: false },
  ],
  medical: [
    { value: 'vaccines', text: 'Vaccines' },
    { value: 'physical', text: 'Physical' },
    { value: 'drug-testing', text: 'Drug Testing' },
  ],
  workhistory: [{ value: 'NY', text: 'New York' }],
  certificates: [{ value: 'NY', text: 'New York' }],
  exclusions: [{ value: 'NY', text: 'New York' }],
};

const blockExpirationPeriods = [
  { value: 'half_yearly', text: '6 months' },
  { value: 'yearly', text: 'year' },
  { value: 'monthly', text: 'month' },
  { value: 'two_years', text: '2 years' },
  { value: 'three_years', text: '3 years' },
  { value: 'four_years', text: '4 years' },
  { value: 'five_years', text: '5 years' },
  { value: 'six_years', text: '6 years' },
  { value: 'ten_years', text: '10 years' },
];

export const BlockExpirationFields = {
  HIRE_DATE: 'measure_caregiver_hire_date',
  ANNUAL_CALENDAR_YEAR: 'measure_annual_calendar_year',
};

class AddComplianceBlockForm extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedBlock: null,
      data: {
        fields: [],
        active: true,
        required: true,
        includedInEligibility: false,
        visibleInApp: true,
        editableInApp: false,
        schedulable: false,
        icon: 'agencycustom.svg',
        expires: false,
        expirationPeriod: null,
      },
    };
    // this.renderHeader = this.renderHeader.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.typeAndAdditionalItems = this.typeAndAdditionalItems.bind(this);
    this.naming = this.naming.bind(this);
    this.chooseBlock = this.chooseBlock.bind(this);
    this.expirationSettings = this.expirationSettings.bind(this);
    this.onViewChanged = this.onViewChanged.bind(this);
    this.nameRef = createRef();
  }

  onViewChanged = index => {
    if (index === 1) {
      this.nameRef.current.focus();
    }
  };

  getBlockName = blockType => {
    const { intl } = this.props;
    switch (blockType) {
      case types.DOCUMENT:
        return intl.formatMessage({ id: 'compliance.settings.document' });
      case types.MEDICAL:
        return intl.formatMessage({ id: 'compliance.settings.medical' });
      case types.TRAINING:
        return intl.formatMessage({ id: 'compliance.settings.training' });
      default:
        return '';
    }
  };

  createFixedFields = (type, schedulable) => {
    switch (type) {
      case types.MEDICAL:
        return [{ type: fieldTypes.date.value, name: 'Completion', fixed: true }];
      case types.TRAINING:
        return schedulable
          ? []
          : [{ type: fieldTypes.date.value, name: 'Completion', fixed: true }];
      case types.DOCUMENT:
        return [
          { type: fieldTypes.date.value, name: 'Completion', fixed: true },
          { type: fieldTypes.document.value, name: 'Uploaded documents', fixed: true },
        ];
      default:
        return [];
    }
  };

  // CHOOSE A TYPE OF BLOCK
  // eslint-disable-next-line react/sort-comp
  chooseBlock() {
    const { data } = this.state;
    const { intl } = this.props;
    return (
      <Form>
        <Grid textAlign='center' divided='vertically'>
          <Grid.Row>
            <div style={{ textAlign: 'center', width: '90%', margin: 'auto' }}>
              <h3>{intl.formatMessage({ id: 'compliance.settings.chooseABlock' })}</h3>
              <p className='description'>
                {intl.formatMessage({ id: 'compliance.settings.chooseABlockDescription' })}
              </p>
            </div>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={2} verticalAlign='middle'>
              <Image src={icons[types.DOCUMENT]} height='50px' />
            </Grid.Column>
            <Grid.Column width={11} textAlign='left'>
              <Grid.Row>
                <h3>{intl.formatMessage({ id: 'compliance.settings.documentUploader' })}</h3>
              </Grid.Row>
              <Grid.Row>
                <p className='description'>
                  {intl.formatMessage({ id: 'compliance.settings.documentUploaderDescription' })}
                </p>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={2} verticalAlign='middle'>
              <Button
                circular
                icon='add'
                onClick={() => {
                  this.wizard.next();
                  this.setState({
                    selectedBlock: types.DOCUMENT,
                    data: {
                      ...data,
                      type: types.DOCUMENT,
                      fields: this.createFixedFields(types.DOCUMENT),
                      schedulable: false,
                      subtype: null,
                    },
                  });
                }}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={2} verticalAlign='middle'>
              <Image src={icons[types.TRAINING]} height='50px' />
            </Grid.Column>
            <Grid.Column width={11} textAlign='left'>
              <Grid.Row>
                <h3>{intl.formatMessage({ id: 'compliance.settings.training' })}</h3>
              </Grid.Row>
              <Grid.Row>
                <p className='description'>
                  {intl.formatMessage({ id: 'compliance.settings.trainingDescription' })}
                </p>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={2} verticalAlign='middle'>
              <Button
                circular
                icon='add'
                onClick={() => {
                  this.wizard.next();
                  this.setState({
                    selectedBlock: types.TRAINING,
                    data: {
                      ...data,
                      type: types.TRAINING,
                      fields: this.createFixedFields(types.TRAINING, data.schedulable),
                    },
                  });
                }}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={2} verticalAlign='middle'>
              <Image src={icons[types.MEDICAL]} height='50px' />
            </Grid.Column>
            <Grid.Column width={11} textAlign='left'>
              <Grid.Row>
                <h3>{intl.formatMessage({ id: 'compliance.settings.medical' })}</h3>
              </Grid.Row>
              <Grid.Row>
                <p className='description'>
                  {intl.formatMessage({ id: 'compliance.settings.medicalDescription' })}
                </p>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={2} verticalAlign='middle'>
              <Button
                circular
                icon='add'
                onClick={() => {
                  this.wizard.next();
                  this.setState({
                    selectedBlock: types.MEDICAL,
                    data: {
                      ...data,
                      type: types.MEDICAL,
                      fields: this.createFixedFields(types.MEDICAL),
                      schedulable: false,
                    },
                  });
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }

  // GIVE A NAME FOR A BLOCK AND ADD OPTIONAL DESCRIPTION
  naming() {
    const { data } = this.state;
    const { intl } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    const options = this.props.disciplines.map(d => ({
      key: d.value,
      text: d.text,
      value: d.value,
    }));

    const nameChanged = e => {
      data.name = e.target.value;
      this.setState({ data });
    };

    const disciplinesChanged = (e, input) => {
      data.disciplines = input.value;
      this.setState({ data });
    };

    const descriptionChanged = (e, input) => {
      data.description = input.value;
      this.setState({ data });
    };

    const setNamingHeader = () => {
      const {
        data: { type },
      } = this.state;
      switch (type) {
        case types.DOCUMENT:
          return intl.formatMessage({ id: 'compliance.settings.nameDocument' });
        case types.MEDICAL:
          return intl.formatMessage({ id: 'compliance.settings.nameMedicalItem' });
        case types.TRAINING:
          return intl.formatMessage({ id: 'compliance.settings.nameTraining' });
        default:
          return intl.formatMessage({ id: 'compliance.settings.itemNamePlaceholder' });
      }
    };

    return (
      <Form>
        <Grid divided='vertically'>
          <Grid.Row columns={1}>
            <Grid.Column>
              <div className='padded'>
                <h4>{setNamingHeader()}</h4>
                <Input
                  ref={this.nameRef}
                  type='text'
                  fluid
                  placeholder={intl.formatMessage({
                    id: 'compliance.settings.itemNamePlaceholder',
                  })}
                  className='nameInput'
                  onChange={nameChanged}
                  value={data.name}
                  error={!data.name}
                  maxLength={100}
                />
                <p className='description light'>
                  {intl.formatMessage({ id: 'compliance.settings.itemNameDescription' })}
                </p>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <div className='padded'>
                <h4>{intl.formatMessage({ id: 'compliance.settings.relevantDisciplines' })}</h4>
                <p className='description light'>
                  {intl.formatMessage({ id: 'compliance.settings.relevantDisciplinesDescription' })}
                </p>
                <Dropdown
                  placeholder={intl.formatMessage({ id: 'compliance.settings.disciplines' })}
                  fluid
                  multiple
                  selection
                  options={options}
                  value={data.disciplines ? data.disciplines : []}
                  onChange={disciplinesChanged}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <div className='padded'>
                <h4>{intl.formatMessage({ id: 'compliance.settings.addOptionalDescription' })}</h4>
                <p className='description light'>
                  {intl.formatMessage({
                    id: 'compliance.settings.addOptionalDescriptionDescription',
                  })}
                </p>
                <TextArea
                  placeholder={intl.formatMessage({
                    id: 'compliance.settings.addOptionalDescriptionPlaceholder',
                  })}
                  onChange={descriptionChanged}
                  value={data.description}
                  maxLength={1024}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }

  // SELECT SUBTYPE OF BLOCK AND ADD ADDITIOMAL FIELDS
  typeAndAdditionalItems() {
    const { data, selectedBlock } = this.state;
    const { intl } = this.props;

    const schedulableChanged = (e, input) => {
      data.schedulable = input.checked;
      this.setState({
        data: {
          ...data,
          fields: this.createFixedFields(types.TRAINING, data.schedulable),
        },
      });
    };

    const addField = () => {
      data.fields.push({ type: fieldTypes.text.value, fixed: false });
      this.setState({ data });
    };

    // additional item UI
    const additionalItem = i => {
      const item = i;
      const typeChanged = (e, input) => {
        item.type = input.value;
        this.setState({ data });
      };

      const fieldNameChanged = (e, input) => {
        item.name = input.value;
        this.setState({ data });
      };

      const removeField = () => {
        const index = data.fields.indexOf(item);
        if (index > -1) {
          data.fields.splice(index, 1);
        }
        this.setState({ data });
      };

      return (
        <Grid>
          <Grid.Column width={5} verticalAlign='middle'>
            <Dropdown
              selection
              single
              options={Object.keys(fieldTypes).map(k => fieldTypes[k])}
              value={item.type}
              onChange={typeChanged}
              disabled={item.fixed}
            />
          </Grid.Column>
          <Grid.Column width={4} verticalAlign='middle'>
            <Input
              placeholder={intl.formatMessage({ id: 'compliance.settings.enterName' })}
              disabled={item.fixed}
              onChange={fieldNameChanged}
              value={item.name}
            />
          </Grid.Column>
          <Grid.Column width={5} />
          <Grid.Column width={2}>
            {!item.fixed && <Icon link size='large' name='trash' onClick={removeField} />}
          </Grid.Column>
        </Grid>
      );
    };

    // subtype selection of block
    const subtypeSelection = () => {
      const fixedFields = data.fields.filter(f => f.fixed);
      const selectedSubtype = subtypes[selectedBlock]
        ? subtypes[selectedBlock].find(s => s.value === data.subtype)
        : null;

      const subtypeChanged = (e, input) => {
        const subtype = input.value;
        const isSchedulable = data.type === types.TRAINING && subtype === 'in-service';
        data.schedulable = isSchedulable;
        data.fields = data.fields.filter(f => !f.fixed);
        const newFixedFields = this.createFixedFields(data.type, data.schedulable);
        newFixedFields.forEach(f => {
          data.fields.push(f);
        });
        data.subtype = subtype;
        if (!isSchedulable) {
          this.setState({ data });
        } else {
          this.setState({
            data: {
              ...data,
              fields: this.createFixedFields(data.type, data.schedulable),
            },
          });
        }
      };

      const hoursChanged = (e, input) => {
        Object.assign(data, { hours: input.value });
        this.setState({ data });
      };

      switch (selectedBlock) {
        case types.DOCUMENT:
          return (
            <Grid>
              <Grid.Row>
                <div>
                  <h5>{intl.formatMessage({ id: 'compliance.settings.documentUploader' })}</h5>
                  <p className='description'>
                    {intl.formatMessage({ id: 'compliance.settings.documentUploaderIntroduction' })}
                  </p>
                </div>
              </Grid.Row>
              <Grid.Row>
                <p>
                  {intl.formatMessage({ id: 'compliance.settings.fixedFieldsToInclude' })}:{' '}
                  <b>{fixedFields.map(f => f.name).join(', ')}</b>
                </p>
              </Grid.Row>
            </Grid>
          );
        case types.MEDICAL:
          return (
            <Grid>
              <Grid.Row>
                <div>
                  <p className='description'>
                    {intl.formatMessage({ id: 'compliance.settings.medicalCheckType' })}:
                  </p>
                  <Dropdown
                    selection
                    placeholder={intl.formatMessage({ id: 'compliance.settings.selectSubtype' })}
                    options={subtypes[types.MEDICAL]}
                    onChange={subtypeChanged}
                    value={data.subtype}
                  />
                </div>
              </Grid.Row>
              <Grid.Row>
                <div>
                  {/* <p>Here should be some information about selected medical type.</p> */}
                  <p>
                    {intl.formatMessage({ id: 'compliance.settings.fixedFieldsToInclude' })}:{' '}
                    <b>{fixedFields.map(f => f.name).join(', ')}</b>
                  </p>
                </div>
              </Grid.Row>
            </Grid>
          );
        case types.TRAINING:
          return (
            <Grid>
              <Grid.Row>
                <div>
                  <p className='description'>
                    {intl.formatMessage({ id: 'compliance.settings.trainingType' })}:
                  </p>
                  <Dropdown
                    selection
                    placeholder={intl.formatMessage({ id: 'compliance.settings.selectSubtype' })}
                    options={subtypes[types.TRAINING]}
                    onChange={subtypeChanged}
                    value={data.subtype}
                  />
                </div>
              </Grid.Row>
              {fixedFields.length > 0 && (
                <Grid.Row>
                  <div>
                    <p>
                      {intl.formatMessage({ id: 'compliance.settings.fixedFieldsToInclude' })}:{' '}
                      <b>{fixedFields.map(f => f.name).join(', ')}</b>
                    </p>
                  </div>
                </Grid.Row>
              )}
              {selectedSubtype && (
                <Grid.Row>
                  <Grid.Column width={4} style={{ padding: '0px' }}>
                    <div>
                      <p className='description' style={{ fontWeight: 'bold' }}>
                        {intl.formatMessage({ id: 'compliance.settings.trainingLength' })}
                      </p>
                      {selectedSubtype.enableHours ? (
                        <span>
                          <Input
                            type='number'
                            min={0}
                            style={{ width: '80px' }}
                            onChange={hoursChanged}
                          />{' '}
                          {intl.formatMessage({ id: 'compliance.settings.hrs' })}
                        </span>
                      ) : (
                        <h3>N/A</h3>
                      )}
                    </div>
                  </Grid.Column>
                  <Grid.Column width={12} style={{ padding: '0px' }}>
                    <br />
                    <br />
                    <p className='description light'>
                      {selectedSubtype.enableHours
                        ? intl.formatMessage({
                            id: 'compliance.settings.fixedCourseLengthDescription',
                          })
                        : intl.formatMessage({
                            id: 'compliance.settings.openCourseLengthDescription',
                          })}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row>
                <Grid.Column width={12} style={{ padding: '0px' }}>
                  <div>
                    <p className='description' style={{ fontWeight: 'bold' }}>
                      {intl.formatMessage({ id: 'compliance.settings.canThisItemBeScheduled' })}
                    </p>
                    <p className='description light'>
                      {intl.formatMessage({ id: 'compliance.settings.itemSchedulableDescription' })}
                      <q>{intl.formatMessage({ id: 'general.yes' })}</q>.
                    </p>
                    <p className='description light'>
                      {data.schedulable
                        ? intl.formatMessage({
                            id: 'compliance.settings.schedulableItemWillAppearOnCalendar',
                          })
                        : intl.formatMessage({
                            id: 'compliance.settings.nonSchedulableItemWontAppearOnCalendar',
                          })}
                    </p>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <Divider hidden />
                  <Checkbox
                    toggle
                    checked={data.schedulable}
                    onChange={schedulableChanged}
                    id='schedulableToggle'
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          );
        default:
          return <></>;
      }
    };

    return (
      <Form>
        <Grid divided='vertically'>
          <Grid.Row centered>
            <Grid.Column width={6}>
              <div style={{ textAlign: 'center' }}>
                <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '60px' }}>
                  <Image width='60px' height='60px' src={icons[selectedBlock]} />
                </div>
                <h3 className='create-training-name'>{data.name}</h3>
              </div>
            </Grid.Column>
            <Grid.Column width={10}>{subtypeSelection(selectedBlock)}</Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} centered>
            <div style={{ textAlign: 'center', margin: 'auto', width: '80%' }}>
              <h4>{intl.formatMessage({ id: 'compliance.settings.entryFields' })}</h4>
              <p className='description light'>
                {intl.formatMessage({ id: 'compliance.settings.entryFieldsDescription' })}
              </p>
            </div>
          </Grid.Row>
          <Grid.Row columns={1} centered>
            <div>
              {data.fields && data.fields.map(i => additionalItem(i))}
              <Divider hidden />
              <Button icon='add' circular onClick={addField} />
            </div>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }

  // SELECT EXPIRATION SETTINGS
  expirationSettings() {
    const { data } = this.state;
    const { intl } = this.props;

    const expiresChanged = (e, input) => {
      data.expires = input.checked;
      this.setState({ data });
    };

    const periodChanged = (e, input) => {
      data.expirationPeriod = input.value;
      this.setState({ data });
    };

    const fieldNameChanged = (e, input) => {
      const { value } = input;
      data.expirationFieldName = value;
      if (value === BlockExpirationFields.ANNUAL_CALENDAR_YEAR) {
        data.expirationPeriod = 'yearly';
        data.expirationPeriodDisabled = true;
      } else {
        data.expirationPeriodDisabled = false;
      }
      this.setState({ data });
    };

    const drivingMeasurementFields = data.schedulable
      ? []
      : data.fields
          .filter(f => f.type === fieldTypes.date.value && f.name === 'Completion')
          .map(f => ({ text: f.name, value: f.name }));
    drivingMeasurementFields.push({
      text: intl.formatMessage({ id: 'compliance.settings.caregiverHireDate' }),
      value: BlockExpirationFields.HIRE_DATE,
    });
    drivingMeasurementFields.push({
      text: intl.formatMessage({ id: 'compliance.settings.annualCalendarYear' }),
      value: BlockExpirationFields.ANNUAL_CALENDAR_YEAR,
    });

    return (
      <Form>
        <Grid divided='vertically'>
          <Grid.Row centered>
            <Grid.Column>
              <div style={{ textAlign: 'center', width: '60%', margin: 'auto' }}>
                <h5>{intl.formatMessage({ id: 'compliance.settings.doesThisExpire' })}</h5>
                <p className='description'>
                  {intl.formatMessage({ id: 'compliance.settings.expiryDescription' })}
                  <q>{intl.formatMessage({ id: 'general.yes' })}</q>.
                </p>
                <Checkbox
                  name='expires'
                  checked={data.expires}
                  toggle
                  onChange={expiresChanged}
                  style={{ marginBottom: '12px', marginTop: '6px' }}
                />
                {data.expires && (
                  <p className='description light'>
                    {intl.formatMessage({ id: 'compliance.settings.expiryFieldDescription' })}
                  </p>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
          {data.expires && (
            <Grid.Row>
              <Grid.Column width={8}>
                <div style={{ margin: '24px' }}>
                  <p>{intl.formatMessage({ id: 'compliance.settings.recurringPeriod' })}</p>
                  <Dropdown
                    options={blockExpirationPeriods}
                    selection
                    single
                    fluid
                    disabled={data.expirationPeriodDisabled}
                    placeholder={intl.formatMessage({ id: 'compliance.settings.selectPeriod' })}
                    value={data.expirationPeriod}
                    onChange={periodChanged}
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={8}>
                <div style={{ margin: '24px' }}>
                  <p>{intl.formatMessage({ id: 'compliance.settings.drivingMeasurementField' })}</p>
                  <Dropdown
                    selection
                    single
                    fluid
                    placeholder={intl.formatMessage({ id: 'compliance.settings.selectField' })}
                    options={drivingMeasurementFields}
                    value={data.expirationFieldName}
                    onChange={fieldNameChanged}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Form>
    );
  }

  requirementOptions() {
    const { data } = this.state;
    const { intl } = this.props;

    const visibleInAppChanged = (e, input) => {
      data.visibleInApp = input.checked;
      this.setState({ data });
    };

    const includedInPrehireChanged = (e, input) => {
      data.includedInPrehire = input.checked;
      this.setState({ data });
    };

    return (
      <Form>
        <Grid divided='vertically'>
          <Grid.Row centered columns={14} verticalAlign='middle'>
            <Grid.Column width={12}>
              <div>
                <b>{intl.formatMessage({ id: 'compliance.settings.includedInPrehire' })}</b>
                <p className='description light'>
                  {intl.formatMessage({ id: 'compliance.settings.includedInPrehireDescription' })}
                </p>
              </div>
            </Grid.Column>
            <Grid.Column width={2} verticalAlign='middle'>
              <Checkbox
                id='includedInPrehireToggle'
                toggle
                checked={data.includedInPrehire}
                onChange={includedInPrehireChanged}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered columns={14} verticalAlign='middle'>
            <Grid.Column width={12}>
              <div>
                <b>{intl.formatMessage({ id: 'compliance.settings.visibleInApp' })}</b>
                <p className='description light'>
                  {intl.formatMessage({ id: 'compliance.settings.visibleInAppDescription' })}
                </p>
              </div>
            </Grid.Column>
            <Grid.Column width={2} verticalAlign='middle'>
              <Checkbox
                toggle
                checked={data.visibleInApp}
                onChange={visibleInAppChanged}
                id='visibleInAppToggle'
              />
            </Grid.Column>
          </Grid.Row>
          {/* <Grid.Row centered columns={14} verticalAlign='middle'>
            <Grid.Column width={12}>
              <div>
                <b>Editable in app</b>
                <p className='description light'>
                  Caregivers will be able to edit the information stored against this item in their
                  profiles within the Care Connect Caregiver -app. Change notifications will report
                  any edits to the agency when they occur.
                </p>
              </div>
            </Grid.Column>
            <Grid.Column width={2} verticalAlign='middle'>
              <Checkbox
                toggle
                checked={data.editableInApp}
                onChange={editableInAppChanged}
                id='editableInAppToggle'
              />
            </Grid.Column>
          </Grid.Row> */}
        </Grid>
      </Form>
    );
  }

  validateContinue = () => {
    const { data } = this.state;
    switch (this.wizard.currentIndex()) {
      case 1:
        return data.name && data.name.length;
      case 2:
        if (data.type === types.MEDICAL) {
          const allFieldsHasName = data.fields.filter(f => f.name).length === data.fields.length;
          return allFieldsHasName && data.subtype;
        }
        if (data.type === types.TRAINING) {
          const subtype = data.subtype
            ? subtypes[types.TRAINING].find(s => s.value === data.subtype)
            : null;
          const allFieldsHasName = data.fields.filter(f => f.name).length === data.fields.length;
          return (
            allFieldsHasName &&
            subtype &&
            ((subtype.enableHours && data.hours > 0) || !subtype.enableHours)
          );
        }
        if (data.type === types.DOCUMENT) {
          const allFieldsHasName = data.fields.filter(f => f.name).length === data.fields.length;
          return allFieldsHasName;
        }
        return true;
      case 3:
        if (data.expires) {
          return data.expirationPeriod && data.expirationFieldName;
        }
        return true;
      default:
        return true;
    }
  };

  renderHeader = () => (
    // eslint-disable-next-line react/destructuring-assignment
    <h3>{this.props.intl.formatMessage({ id: 'compliance.settings.complianceItemBuilder' })}</h3>
  );

  renderFooter(index) {
    const { intl } = this.props;
    const next = () => {
      this.wizard.next();
    };
    const back = () => {
      this.wizard.back();
    };
    const finish = () => {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.onFinish(this.state.data);
    };
    if (index > 0 && index < 4) {
      return (
        <div>
          <Button labelPosition='left' icon='left arrow' content='Back' onClick={back} />
          <Button
            color='green'
            labelPosition='right'
            icon='right arrow'
            content={intl.formatMessage({ id: 'general.continue' })}
            disabled={!this.validateContinue()}
            onClick={next}
          />
        </div>
      );
    }
    if (index === 4) {
      return (
        <div>
          <Button
            labelPosition='left'
            icon='left arrow'
            content={intl.formatMessage({ id: 'general.back' })}
            onClick={back}
          />
          <Button
            color='green'
            content={intl.formatMessage({ id: 'general.finish' })}
            onClick={finish}
          />
        </div>
      );
    }
    return null;
  }

  renderContent(index) {
    switch (index) {
      case 0:
        return this.chooseBlock();
      case 1:
        return this.naming();
      case 2:
        return this.typeAndAdditionalItems();
      case 3:
        return this.expirationSettings();
      case 4:
        return this.requirementOptions();
      default:
        return <></>;
    }
  }

  render() {
    return (
      <WizardModal
        contentHeight={500}
        // eslint-disable-next-line react/destructuring-assignment
        onClose={this.props.onClose}
        renderContent={this.renderContent}
        renderHeader={this.renderHeader}
        renderFooter={this.renderFooter}
        onViewChanged={this.onViewChanged}
        headerLabels={['', '', '', '', '']}
        class='complianceBlockForm'
        ref={input => {
          this.wizard = input;
        }}
      />
    );
  }
}

AddComplianceBlockForm.propTypes = {
  intl: intlShape.isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  disciplines: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default injectIntl(AddComplianceBlockForm);

/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Image, Segment } from 'semantic-ui-react';

import styled from 'styled-components';

import SubTabButton from './SubTabButton';
import { avatarStyle, avatarUrl } from '../../constants/DomainTypes';
import './LeftRail.css';

const StatusText = styled.span`
  margin-left: 0.5rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: ${props => props.color};
  letter-spacing: 0.03125rem;
`;

const StatusIcon = styled(Image)`
  &&& {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    margin-left: 0.5rem;
    position: relative;
    top: -1px;
  }
`;

const Name = styled.span`
  margin-left: 0.5rem;
  font-weight: bold;
  color: #4a4a4a;
  letter-spacing: 0.03125rem;
  font-size: 14px;
`;

const RailAvatar = styled(Image)`
  width: 50px !important;
  height: 50px !important;
  object-fit: cover;
`;
const hr = styled.span`
  border: 5px solid #d9d9d9;
`;

class LeftRail extends React.PureComponent {
  render() {
    const { person, tabs, intl, activeSubTab, complianceEnabled } = this.props;
    const personId = person.clientName ? 'Client ID' : 'ID';

    const CaregiverStatus = caregiver => {
      const isCompliant = complianceEnabled ? !caregiver.unCompletedBlocks.length : true;
      const cgReady = caregiver.readyToWork && isCompliant;

      const statusTextId = `caregivers.records.${cgReady ? 'ready' : 'notReady'}`;

      return (
        <StatusText color={cgReady ? '#69DB8F' : '#F16778'}>
          {intl.formatMessage({ id: statusTextId })}
          <StatusIcon src={cgReady ? '/icon_check_yes.svg' : '/icon_check_no.svg'} />
        </StatusText>
      );
    };

    const ClientStatus = ({ clientStatus }) => {
      const { text, color } = clientStatus;
      const clientReady = clientStatus.text === 'Active';

      return (
        <StatusText color={color}>
          <span style={{ display: 'inline' }}>&nbsp;{text}</span>
          <StatusIcon src={clientReady ? '/icon_check_yes.svg' : '/icon_check_no.svg'} />
        </StatusText>
      );
    };

    return (
      <div className='left-rail-CG-client'>
        <hr />
        <Segment basic>
          <div>
            <RailAvatar
              avatar
              centered
              circular
              size='large'
              floated='left'
              style={avatarStyle(person.avatar, person.gender)}
              src={avatarUrl(person.avatar, person.gender)}
            />
            <Name>{person.firstName}</Name>
            <br />
            <Name>{person.lastName}</Name>
          </div>
          {(person.clientName || person.caregiverName) && (
            <p className='left-rail-client-id'>{`${personId}: ${person.id}`}</p>
          )}
          {person.caregiverStatus && CaregiverStatus(person)}
          {person.clientStatus && ClientStatus(person)}
        </Segment>
        <hr />

        {tabs.map(t => {
          if (activeSubTab === 4 && (t.id === 5 || t.isBreak)) {
            return null;
          }

          if (t.isBreak) {
            return <hr />;
          }

          return (
            <SubTabButton
              key={t.id}
              icon={t.icon}
              additionalContent={t.additionalContent}
              label={intl.formatMessage({ id: t.labelId })}
              inactive={activeSubTab !== t.id}
              onClick={() => {
                // eslint-disable-next-line react/destructuring-assignment
                this.props.onSubTabClick(t.id);
              }}
              alert={t.alert}
            />
          );
        })}
        <hr />
      </div>
    );
  }
}

LeftRail.propTypes = {
  intl: intlShape.isRequired,
  person: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatar: PropTypes.string,
    gender: PropTypes.string,
    caregiverStatus: PropTypes.string,
    clientStatus: PropTypes.shape(),
  }).isRequired,
  complianceEnabled: PropTypes.bool.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      icon: PropTypes.string,
      labelId: PropTypes.string,
      alert: PropTypes.number,
      isBreak: PropTypes.bool,
      additionalContent: PropTypes.shape(),
    })
  ).isRequired,
  activeSubTab: PropTypes.number.isRequired,
  onSubTabClick: PropTypes.func.isRequired,
};

export default injectIntl(LeftRail);

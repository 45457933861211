import moment from 'moment-timezone';
import { formatNumber } from 'libphonenumber-js';
import { renderToString } from 'react-dom/server';
import { unparse } from 'papaparse';

export const MOMENT_FULLDATE = 'dddd, MMMM Do YYYY, h:mm a';
export const MOMENT_HOURS = 'h:mm a';
export const MOMENT_HOURS_24 = 'HH:mm';
export const MOMENT_HOURS_CAPITAL = 'h.mm A';

export const MOMENT_HOURS_WITH_DATE = 'h:mm A MM/DD/YY';
export const MOMENT_FULL_DATETIME = 'h:mm:ss A MM/DD/YY';
export const SHORT_DATE = 'MM/DD/YY';
export const MOMENT_DATE = 'dddd, MMMM Do YYYY';
export const MOMENT_SHORT_DATE = 'ddd MMM Do';
export const MOMENT_DOB = 'YYYY-MM-DD';
export const MOMENT_DOB_US = 'MM/DD/YYYY';

export const precisionRound = (number, precision) => {
  const factor = 10 ** precision;
  return Math.round(number * factor) / factor;
};

export const formatTime = (datetime, zone, format) => {
  if (zone) {
    return moment.tz(datetime, zone.name).format(format);
  }
  return moment(datetime).format(format);
};

export const isoStartOfDay = date =>
  moment(date)
    .startOf('day')
    .toISOString();

export const isoEndOfDay = date =>
  moment(date)
    .endOf('day')
    .toISOString();

export const formatHours = hours => `${precisionRound(hours, 1)} hrs`;

export const formatPhoneNumber = phoneNumber => {
  const formatted = formatNumber({ country: 'US', phone: phoneNumber }, 'National');
  if (formatted.length > 0) return formatted;
  return phoneNumber;
};

export const formatPhoneNumbers = phones =>
  phones.map(p => ({ ...p, number: formatPhoneNumber(p.number) }));

export const isValidEmail = email => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};

export const isValidUsZipCode = zip => /^\d{5}(-\d{4})?$/.test(zip);

export const isValidPhoneNumber = phone =>
  // eslint-disable-next-line no-useless-escape
  phone && /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(phone);

export const EMAIL_LIST_PATTERN = /^([a-zA-Z0-9_+\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25}(,[ ]{0,1}([a-zA-Z0-9_+\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})*$/;

export const isFloat = val => val === Number.parseFloat(val);

export const beginningSearch = (opts, param) =>
  opts.filter(
    x =>
      x.text && typeof x.text === 'string' && x.text.toLowerCase().startsWith(param.toLowerCase())
  );

function strip(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

function renderText(component) {
  const html = renderToString(component);
  const text = strip(html);
  return text;
}

export const tableToCSV = (header, rowBody, data, delimiter = ',') => {
  const rows = data.map(row => rowBody(row, 0));
  const headerContent = [header.map(h => h.textContent || h.content)];

  // Reformat date/time columns. In the UI they are shown in format '13/01/19 at 14:00'. In csv format should be '13/01/19 14:00'
  const rowContent = rows.map(row =>
    row.cells.map(cell => {
      let content = cell.textContent || renderText(cell.content);
      const regexp = /(\/[0-9]{2})( at )([0-9]{2}:)/;
      content = content.replace(regexp, '$1 $3');
      return content;
    })
  );
  const rowData = headerContent.concat(rowContent);
  const csvContent = unparse(rowData, { delimiter });
  return csvContent;
};

import {
  GET_TRAINING_CANDIDATES_SUCCESS,
  GET_TRAINING_CANDIDATES_REQUEST,
  GET_TRAINING_CANDIDATES_FAILURE,
  COMPLETE_CAREGIVER_PREHIRE_REQUEST,
  COMPLETE_CAREGIVER_PREHIRE_SUCCESS,
  COMPLETE_CAREGIVER_PREHIRE_FAILURE,
} from '../../constants/ActionTypes';

const initialState = {
  trainingCandidates: {
    totalCandidates: 0,
    candidates: null,
    loading: false,
    error: false,
    partialUpdate: false,
  },
};

const startState = JSON.parse(JSON.stringify(initialState));

function candidatesTraining(state = startState, action) {
  switch (action.type) {
    case GET_TRAINING_CANDIDATES_REQUEST: {
      return {
        ...state,
        trainingCandidates: {
          ...state.trainingCandidates,
          loading: true,
          partialUpdate: action.partialUpdate,
        },
        activationLoading: false,
      };
    }
    case GET_TRAINING_CANDIDATES_SUCCESS: {
      return {
        ...state,
        trainingCandidates: {
          ...state.trainingCandidates,
          totalCandidates: action.results.total || 0,
          loading: false,
          error: false,
          candidates: action.partialUpdate
            ? state.trainingCandidates.candidates.concat(action.results.results)
            : action.results.results,
          nextPage: action.results.nextPage,
          partialUpdate: action.partialUpdate,
        },
      };
    }
    case GET_TRAINING_CANDIDATES_FAILURE: {
      return {
        ...state,
        trainingCandidates: {
          ...state.trainingCandidates,
          loading: false,
          error: true,
          partialUpdate: action.partialUpdate,
        },
      };
    }
    case COMPLETE_CAREGIVER_PREHIRE_REQUEST: {
      return {
        ...state,
        activationLoading: true,
      };
    }
    case COMPLETE_CAREGIVER_PREHIRE_FAILURE:
    case COMPLETE_CAREGIVER_PREHIRE_SUCCESS: {
      return {
        ...state,
        activationLoading: false,
      };
    }
    default:
      return state;
  }
}

export default candidatesTraining;

/* eslint-disable import/prefer-default-export */
import {
  GET_USER_COURSES_LIST_REQUEST,
  GET_USER_COURSES_LIST_SUCCESS,
  GET_USER_COURSES_LIST_FAILURE,
  GET_USER_COURSES_DETAIL_REQUEST,
  GET_USER_COURSES_DETAIL_SUCCESS,
  GET_USER_COURSES_DETAIL_FAILURE,
  CLEAR_COURSES_LIST,
} from '../../constants/ActionTypes';
import { backend } from '../../services';
import { alertActions } from '../alert';

function getUserCoursesList(id) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.getUserCoursesList(id);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: GET_USER_COURSES_LIST_REQUEST };
  }
  function success(results) {
    return { type: GET_USER_COURSES_LIST_SUCCESS, results };
  }
  function failure(error) {
    return { type: GET_USER_COURSES_LIST_FAILURE, error };
  }
}

function clearUserCoursesList() {
  return { type: CLEAR_COURSES_LIST };
}

function getUserCoursesDetail(id) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.getUserCoursesDetail(id);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: GET_USER_COURSES_DETAIL_REQUEST };
  }
  function success(results) {
    return { type: GET_USER_COURSES_DETAIL_SUCCESS, results };
  }
  function failure(error) {
    return { type: GET_USER_COURSES_DETAIL_FAILURE, error };
  }
}

export const ELUserActions = {
  getUserCoursesList,
  getUserCoursesDetail,
  clearUserCoursesList,
};

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { Image, Input, Dropdown, Loader, Button } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import WizardModal from '../WizardModal';
import '../../CGCompliance.css';

const moment = require('moment');

class AddInServiceTrainingForm extends React.Component {
  constructor() {
    super();
    this.renderContent = this.renderContent.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.selectCourse = this.selectCourse.bind(this);
    this.addDetails = this.addDetails.bind(this);
    this.documentForm = this.documentForm.bind(this);
    this.state = { draftItem: { draft: true } };
  }

  componentDidMount() {}

  componentWillUnmount() {
    const { draftItem } = this.state;
    const { block, loadedDocuments } = this.props;
    if (block && loadedDocuments && draftItem.draft) {
      const field = block.fields.find(f => f.type === 'document');
      loadedDocuments.forEach(doc => {
        this.props.documentRemoved(field.id, doc);
      });
    }
  }

  // eslint-disable-next-line react/sort-comp
  documentForm() {
    const { block, loadedDocuments } = this.props;
    const { uploadingDocument } = this.props.caregiverCompliance;
    const { draftItem } = this.state;
    const field = block.fields.find(f => f.type === 'document');

    let addNewClass = 'complianceDocumentItem';
    if (uploadingDocument) addNewClass += ' disabled';

    const previewDocument = (link, filename) => {
      if (filename.endsWith('.pdf')) {
        return (
          <a href={link}>
            <div style={{ textAlign: 'center' }}>.pdf</div>
          </a>
        );
      }

      return <Image width='100px' height='100px' src={link} />;
    };

    return (
      <div className='complianceDocumentsContainer' key={field.id}>
        {!field.filesDownloaded && field.value && field.value.length > 0 && (
          <div className='complianceDocumentItem'>
            <div style={{ height: '100px' }}>
              <Loader active inline />
            </div>
          </div>
        )}
        {field.filesDownloaded &&
          field.value
            .filter(file => loadedDocuments.includes(file.filename))
            .map(file => (
              <div className='complianceDocumentItem' key={file}>
                {previewDocument(field.downloadLinks[file.filename], file.filename)}
                <Button
                  circular
                  icon='delete'
                  className='documentRemoveButton'
                  onClick={() => this.props.documentRemoved(field.id, file.filename)}
                />
              </div>
            ))}
        <div className={addNewClass}>
          {uploadingDocument ? (
            <>
              {uploadingDocument === field.id ? (
                <div style={{ height: '100px' }}>
                  <Loader active inline />
                </div>
              ) : (
                <p style={{ color: '#ccc' }}>+</p>
              )}
            </>
          ) : (
            <>
              <input
                type='file'
                accept='.jpg,.png,.jpeg,.gif,.pdf'
                id={`fileinput${field.id}`}
                className='fileinput'
                style={{ display: 'none' }}
                onChange={e => this.props.uploadDocument(e, field.id, draftItem.id)}
              />
              <label htmlFor={`fileinput${field.id}`} style={{ padding: '36px' }}>
                +
              </label>
            </>
          )}
        </div>
      </div>
    );
  }

  selectCourse() {
    const { draftItem } = this.state;
    const { trainingCourses } = this.props;

    if (!draftItem) return <></>;

    const courses = trainingCourses.map(c => ({ text: c.name, value: c.id }));
    const selectedCourse = trainingCourses.find(c => c.id === draftItem.blockId);
    const courseChanged = (e, input) => {
      draftItem.blockId = input.value;
      draftItem.block = trainingCourses.find(c => c.id === draftItem.blockId);
      draftItem.hours = draftItem.block.hours ? draftItem.block.hours : 0;
      this.setState({ draftItem });
    };

    const handleFocus = e => {
      e.target.select();
    };

    const handleHoursChange = (e, i) => {
      const newDraft = { ...draftItem };
      const newHour = i.value ? parseFloat(i.value) : '';
      const roundHour = (Math.round(newHour * 4) / 4).toFixed(2);
      newDraft.hours = roundHour;
      this.setState({ draftItem: newDraft });
    };

    return (
      <div className='add-service-grid-container'>
        <div className='add-service-training-item'>
          <h4>Select Training Course</h4>
          <Dropdown
            className='compliance-dropdown'
            options={courses}
            search
            selection
            placeholder='Select Course'
            value={draftItem.blockId}
            onChange={courseChanged}
          />
          <hr />
        </div>
        {selectedCourse ? (
          <div className='add-service-training-item'>
            <p className='smallGray'>In-Service Hours</p>
            <Input
              style={{ marginTop: '-16px' }}
              value={draftItem.hours}
              type='number'
              onFocus={handleFocus}
              step={0.25}
              min={0}
              onChange={handleHoursChange}
            />
            <br />
            <br />
            <p>
              <b>Training Course Details</b>
            </p>
            <p className='small'>{selectedCourse.description}</p>
          </div>
        ) : (
          <div className='add-service-training-item-text'>
            <p className='smallGray'>Choose a training course to view details</p>
          </div>
        )}
      </div>
    );
  }

  addDetails() {
    const { draftItem } = this.state;
    if (!draftItem) return <></>;
    const { trainingCourses, employees, classRooms } = this.props;

    const classRoomOptions = classRooms.map(c => ({ text: c.name, value: c.id }));
    const selectedCourse = trainingCourses.find(c => c.id === draftItem.blockId);
    const employeesOptions = employees.map(e => ({
      text: `${e.firstName} ${e.lastName}`,
      value: e.id,
    }));

    const supervisorChanged = (e, input) => {
      draftItem.supervisor = input.value;
      this.setState({ draftItem });
    };

    const completionChanged = date => {
      draftItem.completion = date;
      this.setState({ draftItem });
    };

    const onClassRoomChanged = (e, input) => {
      const classRoom = input.value;
      Object.assign(draftItem, { classRoom });
      this.setState({ draftItem });
    };

    return (
      <div className='add-service-grid-container'>
        <div className='add-service-training-item'>
          <h3>Add {selectedCourse.name} Item Details</h3>
          <p>
            Complete the additional information associated with this training course in order to
            contribute to the caregivers annual in-service quota.
          </p>
          <hr />
        </div>
        <div className='add-service-training-item'>
          <p>
            <b>Training Location</b>
          </p>
          <Dropdown
            className='compliance-dropdown'
            options={classRoomOptions}
            selection
            placeholder='Select Classroom'
            onChange={onClassRoomChanged}
            value={draftItem.classRoom}
          />
          <br />
          <hr />
          <p>
            <b>Completion</b>
          </p>
          <DatePicker
            key='inserviceCompletion'
            customInput={<Input />}
            placeholderText='Select Date'
            float
            name='inserviceCompletion'
            id='inserviceCompletion'
            popperPlacement='top-end'
            selected={draftItem.completion && moment.utc(draftItem.completion).local()}
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            width={16}
            className='dateInput'
            onChange={e => completionChanged(e.toDate())}
            dateFormat='MM/DD/YYYY'
          />
          <br />
          <hr />
          <p>
            <b>Supervisor (optional):</b>
          </p>
          <Dropdown
            className='compliance-dropdown'
            options={employeesOptions}
            selection
            placeholder='Select Supervisor'
            value={draftItem.supervisor}
            onChange={supervisorChanged}
          />
          <br />
          <hr />
          <p>
            <b>Proof of completion (optional):</b>
          </p>
          {this.documentForm()}
        </div>
      </div>
    );
  }

  validateContinue = () => {
    const { draftItem } = this.state;
    return draftItem && !!draftItem.blockId && draftItem.hours > 0;
  };

  validateSubmit = () => {
    const { draftItem } = this.state;
    return draftItem && draftItem.blockId && !!draftItem.classRoom;
  };

  renderHeader = () => <h3>ADD IN-SERVICE TRAINING ITEM</h3>;

  renderFooter(index) {
    const { draftItem } = this.state;
    const { loadedDocuments } = this.props;
    const next = () => {
      this.wizard.next();
      if (!draftItem.completion) {
        draftItem.completion = new Date();
      }
    };
    const back = () => {
      this.wizard.back();
    };
    const finish = () => {
      Object.assign(draftItem, { draft: false, documents: loadedDocuments });
      this.props.onFinish(draftItem);
    };
    if (index < 1) {
      return (
        <div>
          <Button
            color='green'
            labelPosition='right'
            icon='right arrow'
            content='Next'
            disabled={!this.validateContinue()}
            onClick={next}
          />
        </div>
      );
    }
    if (index === 1) {
      return (
        <div>
          <Button labelPosition='left' icon='left arrow' content='Back' onClick={back} />
          <Button
            color='green'
            disabled={!this.validateSubmit()}
            content='Submit'
            onClick={finish}
          />
        </div>
      );
    }
    return null;
  }

  renderContent(index) {
    switch (index) {
      case 0:
        return this.selectCourse();
      case 1:
        return this.addDetails();
      default:
        return <></>;
    }
  }

  render() {
    const { draftError } = this.props.caregiverComplianceUI;
    if (draftError) {
      this.props.onClose();
    }

    return (
      <WizardModal
        class='compliance'
        contentHeight={550}
        onClose={this.props.onClose}
        renderContent={this.renderContent}
        renderHeader={this.renderHeader}
        renderFooter={this.renderFooter}
        headerLabels={['', '']}
        ref={input => {
          this.wizard = input;
        }}
      />
    );
  }
}

AddInServiceTrainingForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  employees: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  trainingCourses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  classRooms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  caregiverCompliance: PropTypes.shape().isRequired,
  caregiverComplianceUI: PropTypes.shape().isRequired,
  block: PropTypes.shape().isRequired,
  uploadDocument: PropTypes.func.isRequired,
  documentRemoved: PropTypes.func.isRequired,
  loadedDocuments: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default injectIntl(AddInServiceTrainingForm);

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import SubTabHeader from './subcomponents/SubTabHeader';
import TileInformationDetails from './subcomponents/records/TileInformationDetails';
import TileInformationContact from './subcomponents/records/TileInformationContact';
import TileInformationAddress from './subcomponents/records/TileInformationAddress';
import CGTileCustomFields from './subcomponents/records/CGTileCustomFields';
import TileSocialProfiles from './subcomponents/records/TileSocialProfiles';
import TileGrid from './subcomponents/records/TileGrid';
import { CaregiverSurveyResult } from './subcomponents/SurveyReport';

class CGInformation extends React.Component {
  constructor(props) {
    super();
    this.state = {
      caregiver: props.caregiver,
      isValid: false,
      isChanged: false,
      areFormsValid: false,
      latestSurvey: [],
    };
    this.validForms = { details: false, contacts: false, address: false };
    this.onValid = this.onValid.bind(this);
    this.onInvalid = this.onInvalid.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.onActive(this.props.tabIndex);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.editable) {
      this.validForms.details = true;
      this.validForms.address = true;
    }
    if (nextProps.surveyResults) {
      const latestSurvey = nextProps.surveyResults.filter(s =>
        moment(s.batch).isSame(moment().format('YYYY-MM-DD'))
      );
      this.setState({ latestSurvey });
    }
  }

  onValid(form) {
    this.validForms[form] = true;
    this.setState({ areFormsValid: Object.values(this.validForms).every(f => f === true) });
  }

  onInvalid(form) {
    this.validForms[form] = false;
    this.setState({ areFormsValid: false });
  }

  render() {
    const {
      intl,
      caregiver,
      disciplines,
      dispatch,
      onValueChange,
      onSave,
      saveEnabled,
      editable,
      editability,
      editableAttributes,
      mobileHealthIntegration,
      isAdmin,
      surveyEnabled,
      multiDisciplineEnabled,
      homeLocations,
      onDelete,
    } = this.props;
    const { areFormsValid, latestSurvey } = this.state;

    return (
      <>
        <SubTabHeader
          text={intl.formatMessage({ id: 'caregivers.records.information' }).toUpperCase()}
          buttonTitle={intl.formatMessage({ id: 'records.saveUpdates' })}
          buttonOnClick={onSave}
          buttonDisabled={!(saveEnabled && areFormsValid)}
        />
        {isAdmin && surveyEnabled && <CaregiverSurveyResult surveyResults={latestSurvey} />}
        <TileGrid type='caregiver'>
          <TileInformationDetails
            person={caregiver}
            editability={editability}
            disciplines={disciplines}
            onValueChange={onValueChange}
            onValid={this.onValid}
            onInvalid={this.onInvalid}
            mobileHealthIntegration={mobileHealthIntegration}
            multiDisciplineEnabled={multiDisciplineEnabled}
            homeLocations={homeLocations}
            dispatch={dispatch}
          />
          <TileInformationContact
            person={caregiver}
            disciplines={disciplines}
            onValueChange={onValueChange}
            editability={editability}
            onValid={this.onValid}
            onInvalid={this.onInvalid}
            onDelete={onDelete}
          />
          <TileInformationAddress
            person={caregiver}
            editability={editability}
            onValueChange={onValueChange}
            onValid={this.onValid}
            onInvalid={this.onInvalid}
            dispatch={dispatch}
          />
          <TileSocialProfiles
            person={caregiver}
            editability={editability}
            onValueChange={onValueChange}
            onValid={this.onValid}
            onInvalid={this.onInvalid}
          />
          {(editable || editableAttributes) &&
            caregiver.source !== 'hhax' &&
            caregiver.customFields &&
            caregiver.customFields.length > 0 && (
              <CGTileCustomFields
                person={caregiver}
                onValueChange={onValueChange}
                onValid={this.onValid}
                onInvalid={this.onInvalid}
                dispatch={dispatch}
              />
            )}
        </TileGrid>
      </>
    );
  }
}

CGInformation.defaultProps = {
  saveEnabled: false,
  editable: false,
  editability: {},
  editableAttributes: false,
  onActive: () => {},
  mobileHealthIntegration: undefined,
  isAdmin: false,
  surveyEnabled: false,
  homeLocations: [],
};

CGInformation.propTypes = {
  caregiver: PropTypes.shape().isRequired,
  disciplines: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dispatch: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  intl: PropTypes.shape().isRequired,
  mobileHealthIntegration: PropTypes.bool,
  saveEnabled: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  editability: PropTypes.shape(),
  editableAttributes: PropTypes.bool,
  onActive: PropTypes.func,
  tabIndex: PropTypes.number.isRequired,
  surveyResults: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isAdmin: PropTypes.bool,
  surveyEnabled: PropTypes.bool,
  multiDisciplineEnabled: PropTypes.bool.isRequired,
  homeLocations: PropTypes.arrayOf(PropTypes.shape()),
  onDelete: PropTypes.func.isRequired,
};

export default injectIntl(CGInformation);

/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Dropdown, Image } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import { EMAIL_LIST_PATTERN } from '../../../constants/Formats';
import { RecordsTile, RecordsHeader, RecordsSubHeader } from '../../../styles/records';
import '../../Caregivers.css';

class CGTileAgencyNotifications extends React.Component {
  constructor() {
    super();
    this.state = { mapVisible: false };
    this.onViewMapClick = this.onViewMapClick.bind(this);
    this.locUpdate = this.locUpdate.bind(this);
  }

  componentDidMount() {
    // this.form.formsyForm.setFormPristine(false);
  }

  onViewMapClick() {
    this.setState({ mapVisible: !this.state.mapVisible });
  }

  locUpdate(loc) {
    if (this.googleMapRef) {
      this.googleMapRef.setCenter({ lat: loc.lat, lng: loc.lon });
    }
  }

  render() {
    const { person, intl, onValueChange, handlePressEnter } = this.props;

    const caregiver = person;

    const title = intl
      .formatMessage({ id: 'caregivers.records.notificationRecipients' })
      .toUpperCase();
    const subTitle = intl.formatMessage({
      id: 'caregivers.records.notificationRecipients.details',
    });

    const dropdownTags = caregiver.notificationRecipients
      ? caregiver.notificationRecipients.split(',')
      : [];
    const options = dropdownTags.map((t, i) => {
      const x = { text: t, key: i, value: t };
      return x;
    });
    const values = options.map(o => o.value);

    return (
      <RecordsTile>
        <RecordsHeader>{title}</RecordsHeader>
        <RecordsSubHeader>{subTitle}</RecordsSubHeader>
        <Grid padded='vertically' className='detailGrid'>
          <Grid.Row className='headerRow'>
            <Grid.Column width={16}>
              <Form>
                <Form.Group>
                  <Form.Input
                    id='notification-recipients-field'
                    placeholder='Email address'
                    width={16}
                    name='notificationRecipientsField'
                    maxLength={500}
                    pattern={EMAIL_LIST_PATTERN}
                    validations={{
                      matchRegexp: EMAIL_LIST_PATTERN,
                    }}
                    validationErrors={{ isValid: 'email addresses not valid' }}
                    instantValidation
                    value={caregiver.notificationRecipientsField}
                    onChange={onValueChange}
                    onKeyDown={handlePressEnter}
                  />
                  <Image size='mini' inline src='/recipient_icon.svg' />
                </Form.Group>
              </Form>
              <Dropdown
                id='cg-tile-agency-notifications-dropdown'
                icon='none'
                name='notificationRecipientsTag'
                simple
                fluid
                multiple
                onChange={onValueChange}
                options={options}
                value={values}
                defaultValue={values}
                placeholder='Email address'
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </RecordsTile>
    );
  }
}

CGTileAgencyNotifications.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  person: {},
};

CGTileAgencyNotifications.propTypes = {
  // eslint-disable-next-line react/require-default-props
  person: PropTypes.shape().isRequired,
  intl: intlShape.isRequired,
  onValueChange: PropTypes.func.isRequired,
  handlePressEnter: PropTypes.func.isRequired,
};

export default injectIntl(CGTileAgencyNotifications);

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Dimmer } from 'semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import SubTabHeader from './subcomponents/SubTabHeader';
import { caregiverActions, complianceSettingsActions } from '../actions';
import { BlocksGroupNames } from './subcomponents/compliance/complianceHelpers';
import ComplianceTabs from './subcomponents/compliance/complianceTabs';
import CareLoader from './subcomponents/CareLoader';
import TileGrid from './subcomponents/records/TileGrid';
import './CGCompliance.css';
import ComplianceEligibility from './subcomponents/compliance/eligibility';
import ComplianceTraining from './subcomponents/compliance/training';
import ComplianceDocumentation from './subcomponents/compliance/documentation';
import CommonComplianceBlock from './subcomponents/compliance/CommonComplianceBlock';
import ComplianceHepBTest from './subcomponents/compliance/ComplianceHepBTest';
import ComplianceRubellaTest from './subcomponents/compliance/ComplianceRubellaTest';
import ComplianceInfluenzaVaccine from './subcomponents/compliance/ComplianceInfluenzaVaccine';
import ComplianceMeaslesTest from './subcomponents/compliance/ComplianceMeaslesTest';
import ComplianceTBTest from './subcomponents/compliance/ComplianceTBTest';
import ComplianceCOVIDVaccine from './subcomponents/compliance/ComplianceCOVIDVaccine';
import CGComplianceUIActions from '../actions/CGComplianceUIActions';
import ComplianceBlock from './subcomponents/compliance/complianceBlock';

class CGCompliance extends React.Component {
  constructor(props) {
    super(props);
    this.tabSelected = this.tabSelected.bind(this);
    this.downloadDocuments = this.downloadDocuments.bind(this);
    this.addWorkHistory = this.addWorkHistory.bind(this);
    this.deleteWorkHistory = this.deleteWorkHistory.bind(this);
    this.submitExclusion = this.submitExclusion.bind(this);
    this.onRemoveDocument = this.onRemoveDocument.bind(this);
    this.onUploadDocument = this.onUploadDocument.bind(this);
    this.state = {
      dohFetched: false,
      exclusionFetched: false,
      redirectTab: false,
    };
  }

  // eslint-disable-next-line react/sort-comp
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.caregiverCompliance?.blocks?.length && !prevState.dohFetched) {
      const dohBlock = nextProps.caregiverCompliance.blocks.filter(b => b.name === 'DOH');
      if (dohBlock?.length) {
        const { caregiver } = nextProps.caregiverDetails;
        nextProps.dispatch(caregiverActions.getCaregiverComplianceDoh(caregiver.id));
        return { dohFetched: true };
      }
    }
    if (nextProps.caregiverCompliance?.blocks?.length && !prevState.exclusionFetched) {
      const exclusionBlock = nextProps.caregiverCompliance.blocks.filter(
        b => b.name === 'Exclusions'
      );
      if (exclusionBlock?.length) {
        const { caregiver } = nextProps.caregiverDetails;
        nextProps.dispatch(caregiverActions.getCaregiverComplianceExclusions(caregiver.id));
        return { exclusionFetched: true };
      }
    }
    if (nextProps.location?.state?.medical && !prevState.redirectTab) {
      nextProps.dispatch(CGComplianceUIActions.updateState({ activeTab: 1 }));
      return { redirectTab: true };
    }
    return null;
  }

  componentDidMount() {
    const { caregiver } = this.props.caregiverDetails;
    const { onActive, tabIndex } = this.props;
    onActive(tabIndex);
    // this.props.dispatch(caregiverActions.getCaregiverComplianceDoh(caregiver.id));
    // this.props.dispatch(caregiverActions.getCaregiverComplianceExclusions(caregiver.id));
    // this.props.dispatch(caregiverActions.getCaregiverComplianceBlocks(caregiver.id));
    this.props.dispatch(complianceSettingsActions.getComplianceTrainingCourses());
    this.props.dispatch(caregiverActions.getCaregiverInServiceSummary(caregiver.id));
    this.props.dispatch(complianceSettingsActions.getComplianceClassrooms());
  }

  componentWillUnmount() {
    // remove draft items from backend as they are not saved
    const { caregiver } = this.props.caregiverDetails;
    const { dirtyTrainingItems } = this.props.caregiverComplianceUI;
    if (dirtyTrainingItems) {
      const removable = dirtyTrainingItems
        .filter(t => t.draft)
        .map(t => Object.assign(t, { removed: true }));
      if (removable && removable.length) {
        this.props.dispatch(
          caregiverActions.updateCaregiverInServiceTrainings(caregiver.id, removable)
        );
      }
    }
  }

  onUploadDocument(e, fieldId, tag, callback = null) {
    const { dispatch } = this.props;
    const { caregiver } = this.props.caregiverDetails;
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      dispatch(
        caregiverActions.uploadComplianceDocument(fieldId, file, caregiver.id, tag, callback)
      );
    }
    e.target.value = '';
  }

  onRemoveDocument(fieldId, filename) {
    const { dispatch } = this.props;
    const { caregiver } = this.props.caregiverDetails;
    if (fieldId && filename) {
      dispatch(caregiverActions.removeComplianceDocument(caregiver.id, fieldId, filename));
    }
  }

  addWorkHistory(item) {
    const { dispatch } = this.props;
    const { caregiver } = this.props.caregiverDetails;
    dispatch(caregiverActions.addWorkhistory(caregiver.id, item));
  }

  deleteWorkHistory(item) {
    const { dispatch } = this.props;
    const { caregiver } = this.props.caregiverDetails;
    dispatch(caregiverActions.deleteWorkhistory(caregiver.id, item));
  }

  submitExclusion(exclusion) {
    const { dispatch } = this.props;
    const { caregiver } = this.props.caregiverDetails;
    dispatch(caregiverActions.updateComplianceExclusion(caregiver.id, exclusion));
  }

  downloadDocuments(blocks) {
    const { caregiver } = this.props.caregiverDetails;

    let fields = [];
    blocks.forEach(block => {
      const docFields = block.fields.filter(f => f.type === 'document' && f.value);
      fields = fields.concat(docFields);
    });
    if (fields.length > 0) {
      const documentsStr = fields.map(f => f.value.map(v => v.filename).join(',')).join(',');
      if (documentsStr.length) {
        this.props.dispatch(caregiverActions.downloadDocuments(caregiver.id, documentsStr));
      }
    }
  }

  itemsList = (
    blocks,
    type,
    systemTitle,
    customTitle,
    systemDescription,
    customDescription,
    medicalRecordIntegration
  ) => {
    const hBTBlocks = blocks?.length
      ? blocks.filter(b => b.groupName === BlocksGroupNames.HBT)
      : [];
    const rubellaBlocks = blocks?.length
      ? blocks.filter(b => b.groupName === BlocksGroupNames.RUBELLA)
      : [];
    const influenzaBlocks = blocks?.length
      ? blocks.filter(b => b.groupName === BlocksGroupNames.INFLUENZA)
      : [];
    const measlesBlocks = blocks?.length
      ? blocks.filter(b => b.groupName === BlocksGroupNames.MEASLES)
      : [];
    const tBBlocks = blocks?.length ? blocks.filter(b => b.groupName === BlocksGroupNames.TB) : [];
    const covidBlock = blocks?.length
      ? blocks.filter(b => b.groupName === BlocksGroupNames.COVID)
      : [];

    return (
      <TileGrid>
        {medicalRecordIntegration ? (
          <div className='mobile-health-container'>
            <div className='mobile-health-logo'>
              <img src='/mobile-health.svg' alt='mobile health logo' />
            </div>
            <div className='mobile-health-text-container'>
              <p className='tileHeader mobile-health-text'>Mobile Health Integration</p>
              <p className='smallGray mobile-health-text'>
                Your system is integrated with Mobile Health and information obtained from Mobile
                Health will automatically update most medical items. Adding or overriding
                information for items is not advised.
              </p>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className='infoPanel' style={{ marginTop: medicalRecordIntegration ? '' : '40px' }}>
          <p className='tileHeader'>{customTitle}</p>
          <p className='smallGray'>{customDescription}</p>
          {blocks &&
            blocks
              .filter(b => b.type === type && !b.system && !b.controlledInProfile)
              .map(b => <ComplianceBlock key={b.id} block={b} />)}
        </div>
        <div className='medicalBlocks'>
          {!!covidBlock.length && (
            <ComplianceCOVIDVaccine blocks={covidBlock} roles={this.props.principal.roles} />
          )}
          {!!hBTBlocks.length && <ComplianceHepBTest blocks={hBTBlocks} />}
          {!!rubellaBlocks.length && <ComplianceRubellaTest blocks={rubellaBlocks} />}
          {!!influenzaBlocks.length && <ComplianceInfluenzaVaccine blocks={influenzaBlocks} />}
          {!!measlesBlocks.length && <ComplianceMeaslesTest blocks={measlesBlocks} />}
          {!!tBBlocks.length && <ComplianceTBTest blocks={tBBlocks} />}
          {blocks &&
            blocks
              .filter(b => b.type === 'medical' && b.system && !b.groupName)
              .map(b => (
                <CommonComplianceBlock
                  key={b.id}
                  block={b}
                  notEditable={medicalRecordIntegration}
                />
              ))}
        </div>
        <div>
          {blocks &&
            blocks
              .filter(b => b.type === type && b.controlledInProfile)
              .map(b => <CommonComplianceBlock key={b.id} block={b} />)}
        </div>
      </TileGrid>
    );
  };

  content(activeTab, hrManager) {
    const medicalRecordIntegration = !!(
      this.props.agencySettings && this.props.agencySettings.medicalRecordIntegration
    );
    switch (activeTab) {
      case 0:
        return (
          <ComplianceEligibility
            caregiverCompliance={this.props.caregiverCompliance}
            onSubmitExclusion={this.submitExclusion}
            uploadDocument={this.onUploadDocument}
            documentRemoved={this.onRemoveDocument}
            hrManager={hrManager}
          />
        );
      case 1:
        return this.itemsList(
          this.props.caregiverCompliance.blocks,
          'medical',
          'MEDICAL INFORMATION',
          'CUSTOM MEDICAL ITEMS',
          'The following are medical compliance items that must be tracked by law in your state. When an item is expiring, your caregiver will be alerted.',
          'The following are medical compliance items that your agency has chosen to track in addition to the state required compliance items.',
          medicalRecordIntegration
        );
      case 2:
        return (
          <ComplianceDocumentation
            caregiverCompliance={this.props.caregiverCompliance}
            onWorkhistoryAdded={this.addWorkHistory}
            onWorkhistoryDeleted={this.deleteWorkHistory}
            hrManager={hrManager}
          />
        );
      case 3:
        return (
          <ComplianceTraining
            uploadDocument={this.onUploadDocument}
            removeDocument={this.onRemoveDocument}
            hrManager={hrManager}
          />
        );
      default:
        return <></>;
    }
  }

  tabSelected(i) {
    this.props.dispatch(CGComplianceUIActions.updateState({ activeTab: i }));
  }

  render() {
    const { intl, caregiverDetails, saveEnabled, onSave } = this.props;
    const {
      activeTab,
      dirtyFields,
      dirtyTrainingItems,
      isNotValid,
    } = this.props.caregiverComplianceUI;
    const {
      blocks,
      needToDownloadDocuments,
      loadingDocuments,
      loadingBlocks,
    } = this.props.caregiverCompliance;

    const { loadingInServiceSummary } = this.props.caregiverInServiceSummary;

    const hrManager = true;
    // if we want to prevent schedulers to change compliance values, just put this to hrManager
    // (employee.employee && isHRManager(employee.employee.roles)) || employee.rootUser;

    if (blocks && needToDownloadDocuments && !loadingDocuments) {
      this.downloadDocuments(blocks);
    }

    const save = () => {
      const { dispatch } = this.props;
      if (saveEnabled) onSave();

      if (Object.keys(dirtyFields).length > 0) {
        const dirtyFieldsToSave = [];
        dirtyFields.forEach(s => {
          blocks.forEach(block => {
            const b = block.fields.find(a => a.id === s.id);
            if (b !== undefined) {
              if (
                (b.value != null && b.value !== s.value) ||
                (b.value == null && s.value != null)
              ) {
                dirtyFieldsToSave.push(s);
              }
            }
          });
        });
        dispatch(
          caregiverActions.setCaregiverComplianceBlockFields(
            caregiverDetails.caregiver.id,
            dirtyFieldsToSave
          )
        );
        const newDirtyFields = dirtyFields.filter(
          f => !dirtyFieldsToSave.find(bF => bF.id === f.id)
        );
        dispatch(CGComplianceUIActions.updateState({ dirtyFields: newDirtyFields }));
      }
      if (dirtyTrainingItems.length > 0) {
        const itemsToSave = dirtyTrainingItems.map(i => ({ ...i, draft: false }));
        dispatch(
          caregiverActions.updateCaregiverInServiceTrainings(
            caregiverDetails.caregiver.id,
            itemsToSave
          )
        );
      }
    };

    let dirtyFieldChanged = false;
    dirtyFields.forEach(s => {
      const blockField = blocks
        .map(block => block.fields.find(a => a.id === s.id))
        .filter(b => b !== undefined);
      if (
        (blockField[0].value && blockField[0].value !== s.value) ||
        (!blockField[0].value && s.value) ||
        (blockField[0].value && s.value === null)
      ) {
        dirtyFieldChanged = true;
      }
    });

    const isChanged = dirtyFieldChanged || dirtyTrainingItems.length > 0 || saveEnabled;

    const tabs = [
      { name: 'Eligibility', image: '/compliance/skillsasses.svg' },
      { name: 'Medical', image: '/compliance/physicalexam.svg' },
      { name: 'Documentation', image: '/compliance/19doc.svg' },
      { name: 'Training', image: '/compliance/inservicetraining.svg' },
    ];
    tabs.forEach((t, i) => {
      const tab = t;
      tab.isActive = activeTab === i;
    });
    return (
      <div>
        <SubTabHeader
          text={intl.formatMessage({ id: 'caregivers.records.compliance' }).toUpperCase()}
          buttonTitle={intl.formatMessage({ id: 'records.saveUpdates' })}
          buttonOnClick={save}
          buttonDisabled={!(isChanged && !isNotValid)}
        />
        {(loadingBlocks || loadingInServiceSummary) && (
          <Dimmer active page>
            <CareLoader loading showText={false} />
          </Dimmer>
        )}
        <Grid className='compliance' style={{ marginTop: '-2rem' }}>
          <Grid.Row style={{ paddingTop: '0px', marginTop: '-1px' }}>
            <ComplianceTabs tabs={tabs} onSelect={this.tabSelected} />
          </Grid.Row>

          <Grid.Row>{this.content(activeTab, hrManager)}</Grid.Row>
        </Grid>
      </div>
    );
  }
}

CGCompliance.defaultValues = {
  onActive: () => {},
  tabIndex: 0,
};

CGCompliance.propTypes = {
  intl: intlShape.isRequired,
  caregiverDetails: PropTypes.shape().isRequired,
  caregiverCompliance: PropTypes.shape().isRequired,
  caregiverComplianceUI: PropTypes.shape().isRequired,
  agencySettings: PropTypes.shape({
    medicalRecordIntegration: PropTypes.number,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  saveEnabled: PropTypes.bool.isRequired,
  onActive: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const {
    caregiverDetails,
    caregiverCompliance,
    caregiverComplianceUI,
    employee,
    principal,
    caregiverInServiceSummary,
  } = state;
  return {
    caregiverDetails,
    caregiverCompliance,
    caregiverComplianceUI,
    employee,
    principal,
    caregiverInServiceSummary,
  };
};

export default connect(mapStateToProps)(injectIntl(CGCompliance));

import {
  GET_CAREGIVER_SUCCESS,
  GET_CAREGIVER_REQUEST,
  GET_CAREGIVER_FAILURE,
  GET_CAREGIVER_EVENTS_SUCCESS,
  GET_CAREGIVER_EVENTS_REQUEST,
  GET_CAREGIVER_EVENTS_FAILURE,
  UPDATE_CAREGIVER_REQUEST,
  UPDATE_CAREGIVER_SUCCESS,
  UPDATE_CAREGIVER_FAILURE,
  SHIFT_BY_CAREGIVER_REQUEST,
  SHIFT_BY_CAREGIVER_SUCCESS,
  SHIFT_BY_CAREGIVER_FAILURE,
  UPDATE_CAREGIVER_CASE_PREFERENCES_SUCCESS,
} from '../constants/ActionTypes';

import { prepareCaregiverObject, prepareShiftObject } from './helpers';

const initialState = {
  loading: false,
  loadingShifts: false,
  loadingEvents: false,
  caregiver: null,
  shifts: {},
  events: {},
};

function caregiverDetails(state = initialState, action) {
  switch (action.type) {
    case SHIFT_BY_CAREGIVER_REQUEST:
      return { ...state, loadingShifts: true };
    case GET_CAREGIVER_REQUEST:
      return { ...state, loading: true, caregiver: null, compliance: null, complianceError: false };
    case GET_CAREGIVER_SUCCESS:
    case UPDATE_CAREGIVER_SUCCESS: {
      const profile = prepareCaregiverObject(action.results.profile);
      return { ...state, loading: false, caregiver: profile };
    }
    case GET_CAREGIVER_EVENTS_REQUEST:
      return { ...state, loadingEvents: true, events: null };
    case GET_CAREGIVER_EVENTS_SUCCESS: {
      return { ...state, loadingEvents: false, events: action.results.events };
    }
    case UPDATE_CAREGIVER_CASE_PREFERENCES_SUCCESS: {
      if (
        action.results.casePreferences &&
        action.results.casePreferences.version > state.caregiver.casePref.version
      ) {
        const caregiver = { ...state.caregiver, casePref: action.results.casePreferences };
        return { ...state, caregiver };
      }
      return state;
    }
    case SHIFT_BY_CAREGIVER_FAILURE:
      return { ...state, loadingShifts: false };
    case GET_CAREGIVER_EVENTS_FAILURE:
      return { ...state, loadingEvents: false };
    case GET_CAREGIVER_FAILURE:
      return { ...state, loading: false };
    case UPDATE_CAREGIVER_REQUEST:
      return { ...state, loading: true };
    case UPDATE_CAREGIVER_FAILURE:
      return { ...state, loading: false };
    case SHIFT_BY_CAREGIVER_SUCCESS: {
      const shifts = { ...state.shifts };

      shifts[action.yearMonth] = action.results.results.map(s => prepareShiftObject(s));
      return { ...state, shifts, loadingShifts: false };
    }
    default:
      return state;
  }
}

export default caregiverDetails;

/* eslint-disable import/prefer-default-export */
import * as types from '../constants/ActionTypes';
import { backend } from '../services';
// eslint-disable-next-line import/no-cycle
import { alertActions } from './alert';

const moment = require('moment');

function listClients(params, partialUpdate = false) {
  return dispatch => {
    dispatch(request());
    const timestamp = moment();
    backend.listClients(params).then(
      results => {
        dispatch(success(Object.assign(results, { timestamp })));
      },
      error => {
        if (error.statusCode === 413) {
          dispatch(success(null, true));
          return;
        }
        dispatch(failure(error.message));
        dispatch(alertActions.error(error, alertActions.SEARCH_ERROR_TYPE));
      }
    );
  };

  function request() {
    return { type: types.LIST_CLIENT_REQUEST, partialUpdate };
  }
  function success(results, tooManyResults) {
    return { type: types.LIST_CLIENT_SUCCESS, results, tooManyResults, partialUpdate };
  }
  function failure(error) {
    return { type: types.LIST_CLIENT_FAILURE, error, partialUpdate };
  }
}

function searchClients(params, partialUpdate = false) {
  return dispatch => {
    dispatch(request());
    backend.listClients(params).then(
      results => {
        dispatch(success(results));
      },
      error => {
        if (error.statusCode === 413) {
          dispatch(success(null, true));
          return;
        }

        dispatch(failure(error.message));
        dispatch(alertActions.error(error, alertActions.SEARCH_ERROR_TYPE));
      }
    );
  };

  function request() {
    return { type: types.SEARCH_CLIENT_REQUEST };
  }
  function success(results, tooManyResults) {
    return { type: types.SEARCH_CLIENT_SUCCESS, results, tooManyResults, partialUpdate };
  }
  function failure(error) {
    return { type: types.SEARCH_CLIENT_FAILURE, error };
  }
}

function getClient(clientId) {
  return dispatch => {
    dispatch(request());
    backend.getClient(clientId).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_CLIENT_REQUEST };
  }
  function success(results) {
    return { type: types.GET_CLIENT_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_CLIENT_FAILURE, error };
  }
}

function updateClient(client) {
  return dispatch => {
    dispatch(request());
    backend.updateClient(client).then(
      results => {
        dispatch(success(results));
        dispatch(alertActions.notification('Client settings saved.'));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.UPDATE_CLIENT_REQUEST };
  }
  function success(results) {
    return { type: types.UPDATE_CLIENT_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.UPDATE_CLIENT_FAILURE, error };
  }
}

function createClient(client) {
  return dispatch => {
    dispatch(request());
    backend.createClient(client).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error, alertActions.CREATE_CLIENT_ERROR));
      }
    );
  };
  function request() {
    return { type: types.CREATE_CLIENT_REQUEST };
  }
  function success(results) {
    return { type: types.CREATE_CLIENT_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.CREATE_CLIENT_FAILURE, error };
  }
}

function createClientClear() {
  return dispatch => dispatch({ type: types.CREATE_CLIENT_CLEAR });
}

function listServices() {
  return dispatch => {
    dispatch(request());
    backend.listServices().then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
      }
    );
  };
  function request() {
    return { type: types.LIST_SERVICES_REQUEST };
  }
  function success(results) {
    return { type: types.LIST_SERVICES_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.LIST_SERVICES_FAILURE, error };
  }
}

function removeClient(clientId, status) {
  return dispatch => {
    dispatch(request());
    backend.removeClient(clientId).then(
      results => {
        dispatch(success(results));
        dispatch(listClients(status));
        dispatch(alertActions.notification('Client Deleted .'));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.REMOVE_CLIENT_REQUEST };
  }
  function success(results) {
    return { type: types.REMOVE_CLIENT_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.REMOVE_CLIENT_FAILURE, error };
  }
}

function getShiftsByClient(clientId) {
  return dispatch => {
    dispatch(request());
    backend.getShiftsByClient(clientId).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_CLIENT_SHIFT_REQUEST };
  }
  function success(results) {
    return { type: types.GET_CLIENT_SHIFT_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_CLIENT_SHIFT_FAILURE, error };
  }
}

export const clientActions = {
  listClients,
  searchClients,
  getClient,
  updateClient,
  createClient,
  createClientClear,
  listServices,
  removeClient,
  getShiftsByClient,
};

import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';

const AddressList = ({ address }) => (
  <List>
    {address.street && <List.Item content={address.street} />}
    {address.street2 && <List.Item content={address.street2} />}
    {address.apartment && <List.Item content={address.apartment} />}
    {address.city && <List.Item content={address.city} />}
    {address.county && <List.Item content={address.county} />}
    {address.state && <List.Item content={`${address.state} ${address.zip}`} />}
    {address.country && <List.Item content={address.country} />}
  </List>
);

AddressList.propTypes = {
  address: PropTypes.shape().isRequired,
};

export default AddressList;

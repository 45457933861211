/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Grid, Input, Button } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import states from '../../../constants/States';
import '../../CGCompliance.css';
import { beginningSearch } from '../../../constants/Formats';
import './addComplianceClassRoomForm.css';

class AddComplianceClassRoomForm extends React.Component {
  constructor() {
    super();
    this.state = {
      draftClass: { address: {} },
      isValid: false,
    };
    this.disableSubmitButton = this.disableSubmitButton.bind(this);
    this.enableSubmitButton = this.enableSubmitButton.bind(this);
  }

  // eslint-disable-next-line react/sort-comp
  disableSubmitButton() {
    this.setState({ isValid: false });
  }

  enableSubmitButton() {
    this.setState({ isValid: true });
  }

  capacityValidation = () => {
    const { draftClass } = this.state;
    if (draftClass.capacity && draftClass.capacity > 0) return true;
    return false;
  };

  render() {
    const { intl } = this.props;
    const { draftClass } = this.state;
    const { street2 } = this.state.draftClass.address;
    const onFinish = () => {
      if (street2 === '') {
        Object.assign(draftClass.address, { street2: undefined });
        this.setState({ draftClass });
      }
      this.props.onFinish(draftClass);
      this.props.onClose();
    };
    const valueChange = (name, value) => {
      if (name !== 'name' && name !== 'capacity') draftClass.address[name] = value;
      else draftClass[name] = value;
      this.setState({ ...draftClass });
    };

    const capacityValueChange = (e, value) => {
      const capacityVal = parseInt(value, 10);
      this.setState({ draftClass: Object.assign(draftClass, { capacity: capacityVal }) });
    };

    const instantValidation = true;
    const stateOptions = states.states.map(s => ({ text: s.abbreviation, value: s.abbreviation }));
    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        className='compliance'
        open
        onClose={this.props.onClose}
        style={{ marginTop: '-46vh' }}
      >
        <Modal.Content>
          <Grid>
            <Grid.Row centered>
              <p>{intl.formatMessage({ id: 'compliance.settings.addClassRoom' })}</p>
            </Grid.Row>
            <Grid.Row centered>
              <Grid.Column width={14}>
                <Form
                  className='compliance-form'
                  onInvalid={this.disableSubmitButton}
                  onValid={this.enableSubmitButton}
                  style={{ color: '#aaa' }}
                >
                  <Form.Input
                    label='Name'
                    id='class-name'
                    name='classRoom'
                    validations={{
                      minLength: 1,
                      maxLength: 60,
                    }}
                    validationErrors={{ isValid: 'classname not valid' }}
                    instantValidation={instantValidation}
                    required
                    value={draftClass.name}
                    onChange={(e, i) => valueChange('name', i.value)}
                    fluid
                    placeholder={intl.formatMessage({
                      id: 'compliance.settings.classRoomNamePlaceholder',
                    })}
                  />
                  <br />
                  <Form.Input
                    label='Street'
                    id='class-street'
                    name='classStreet'
                    validations={{
                      minLength: 2,
                      maxLength: 200,
                    }}
                    validationErrors={{ isValid: 'class street not valid' }}
                    instantValidation={instantValidation}
                    required
                    value={draftClass.address.street}
                    onChange={(e, i) => valueChange('street', i.value)}
                    fluid
                    placeholder={intl.formatMessage({
                      id: 'compliance.settings.classRoomStreet1Placeholder',
                    })}
                  />
                  <br />
                  <Form.Input
                    label='Street 2'
                    id='class-street2'
                    name='classStreet2'
                    value={draftClass.address.street2}
                    onChange={(e, i) => valueChange('street2', i.value)}
                    fluid
                    validations={{
                      minLength: 2,
                      maxLength: 200,
                    }}
                    instantValidation={instantValidation}
                    placeholder={intl.formatMessage({
                      id: 'compliance.settings.classRoomStreet2Placeholder',
                    })}
                  />
                  <br />
                  <Form.Group>
                    <Form.Input
                      width={8}
                      label='City'
                      id='class-city'
                      name='classCity'
                      value={draftClass.address.city}
                      onChange={(e, i) => valueChange('city', i.value)}
                      fluid
                      validations={{
                        minLength: 2,
                        maxLength: 50,
                      }}
                      required
                      instantValidation={instantValidation}
                      placeholder={intl.formatMessage({
                        id: 'compliance.settings.classRoomCityPlaceholder',
                      })}
                    />
                    <div className='select-input three wide column field'>
                      <Form.Select
                        label='State'
                        name='class-state'
                        required
                        className='hideLabel'
                        options={stateOptions}
                        onChange={(e, i) => valueChange('state', i.value)}
                        placeholder={intl.formatMessage({
                          id: 'compliance.settings.classRoomStatePlaceholder',
                        })}
                        search={beginningSearch}
                      />
                    </div>

                    <Form.Input
                      label='Zip'
                      id='class-zip'
                      name='classZip'
                      width={5}
                      value={draftClass.address.zip}
                      onChange={(e, i) => valueChange('zip', i.value)}
                      fluid
                      validations={{
                        minLength: 1,
                        maxLength: 50,
                      }}
                      required
                      instantValidation={instantValidation}
                      placeholder={intl.formatMessage({
                        id: 'compliance.settings.classRoomZipPlaceholder',
                      })}
                    />
                  </Form.Group>
                  <Grid.Row centered>
                    <div className='capacity'>
                      <p>{intl.formatMessage({ id: 'compliance.settings.classRoomCapacity' })}</p>
                      <Input
                        id='class-capacity'
                        value={draftClass.capacity}
                        onChange={(e, i) => capacityValueChange(e, i.value)}
                        placeholder={intl.formatMessage({
                          id: 'compliance.settings.classRoomCapacityPlaceholder',
                        })}
                        fluid
                        type='number'
                        min={1}
                      />
                    </div>
                  </Grid.Row>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content='Add'
            onClick={onFinish}
            color='green'
            disabled={!this.state.isValid || !this.capacityValidation()}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

AddComplianceClassRoomForm.propTypes = {
  intl: intlShape.isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default injectIntl(AddComplianceClassRoomForm);

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable @typescript-eslint/no-this-alias */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Grid } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import LocationMap from '../LocationMap';
import LocationButton from '../LocationButton';
import { beginningSearch } from '../../../constants/Formats';
import states from '../../../constants/States';
import DetailsBox from './DetailsBox';
import { RecordsForm } from '../../../styles/records';
import '../../CaregiverRecords.css';

class TileInformationAddress extends React.Component {
  constructor() {
    super();
    this.state = { mapVisible: false };
    this.onViewMapClick = this.onViewMapClick.bind(this);
    this.locUpdate = this.locUpdate.bind(this);
  }

  componentDidMount() {
    this.form.formsyForm.setFormPristine(false);
  }

  onViewMapClick() {
    this.setState({ mapVisible: !this.state.mapVisible });
  }

  locUpdate(loc) {
    if (this.googleMapRef) {
      this.googleMapRef.setCenter({ lat: loc.lat, lng: loc.lon });
    }
  }

  render() {
    const stateOptions = states.states.map(x => ({ value: x.abbreviation, text: x.abbreviation }));

    const {
      person,
      intl,
      onValueChange,
      onValid,
      onInvalid,
      dispatch,
      editable,
      editability,
      type,
    } = this.props;

    const object = person;

    const isCaregiver = type === 'caregiver';
    const isClient = type === 'client';
    const addressEditability = isClient ? editable : editability.address;

    const { mapVisible } = this.state;

    const homeAddress = intl.formatMessage({ id: 'records.homeAddress' });
    const timezone = intl.formatMessage({ id: 'records.timezone' });
    const viewMap = intl.formatMessage({ id: 'records.viewMap' });
    const hideMap = intl.formatMessage({ id: 'records.hideMap' });
    const evacuationZone = intl.formatMessage({ id: 'records.evacuationZone' });

    const self = this;

    return (
      <DetailsBox
        text={intl.formatMessage({ id: 'records.addressBook' }).toUpperCase()}
        icon='/icon_large_address.svg'
      >
        <RecordsForm
          onValid={() => onValid('address')}
          onInvalid={() => onInvalid('address')}
          size='tiny'
          ref={c => {
            self.form = c;
          }}
          className='detailsBox'
        >
          <h2 className='address-home-address'>{homeAddress}</h2>
          <Grid.Row>
            <Grid.Column width={16} id='client-address' className='ui form'>
              {(addressEditability || object.address.street) && (
                <Form.Group>
                  <Form.Input
                    id='tile-information-address-street-input'
                    placeholder='Address'
                    width={16}
                    name='address.street'
                    value={object.address.street}
                    onChange={onValueChange}
                    disabled={!addressEditability}
                  />
                </Form.Group>
              )}
              <Form.Group>
                {(addressEditability || object.address.street2) && (
                  <Form.Input
                    id='tile-information-address-street-2-input'
                    placeholder='Address 2'
                    width={12}
                    name='address.street2'
                    value={object.address.street2}
                    onChange={onValueChange}
                    disabled={!addressEditability}
                  />
                )}
                {(addressEditability || object.address.apartment) && (
                  <Form.Input
                    id='tile-information-apartment-input'
                    placeholder='Apt #'
                    width={4}
                    name='address.apartment'
                    value={object.address.apartment}
                    onChange={onValueChange}
                    disabled={!addressEditability}
                  />
                )}
              </Form.Group>
              {(addressEditability || object.address.city) && (
                <Form.Group>
                  <Form.Input
                    id='tile-information-city-input'
                    placeholder='City'
                    width={16}
                    name='address.city'
                    value={object.address.city}
                    onChange={onValueChange}
                    disabled={!addressEditability}
                  />
                </Form.Group>
              )}
              {(addressEditability || object.address.county) && (
                <Form.Group>
                  <Form.Input
                    id='tile-information-county-input'
                    placeholder='County'
                    width={16}
                    name='address.county'
                    value={object.address.county}
                    onChange={onValueChange}
                    disabled={!addressEditability}
                  />
                </Form.Group>
              )}
              <Form.Group>
                {(addressEditability || object.address.state) && (
                  <Form.Select
                    id='addressSelector'
                    className='small'
                    compact
                    search={beginningSearch}
                    selection
                    width={6}
                    upwards='true'
                    placeholder='State'
                    name='address.state'
                    options={stateOptions}
                    value={object.address.state}
                    onChange={onValueChange}
                    disabled={!addressEditability}
                  />
                )}
                {(addressEditability || object.address.zip) && (
                  <Form.Input
                    id='tile-information-zip-input'
                    fluid
                    placeholder='Zip'
                    width={6}
                    name='address.zip'
                    value={object.address.zip}
                    onChange={onValueChange}
                    style={{
                      paddingBottom: 0,
                      paddingTop: addressEditability ? '0.8rem' : '0.1rem',
                    }}
                    disabled={!addressEditability}
                  />
                )}
                {isCaregiver && (
                  <Button
                    id='client-address-map-button'
                    type='button'
                    className='five wide borderlessBlueButton'
                    onClick={this.onViewMapClick}
                  >
                    {mapVisible ? (
                      <>
                        {hideMap}&nbsp;
                        <i className='ChevronUp' />
                      </>
                    ) : (
                      <>
                        {viewMap}&nbsp;
                        <i className='Chevron' />
                      </>
                    )}
                  </Button>
                )}
              </Form.Group>

              {isClient && (
                <Grid.Row>
                  <Grid.Column width={11}>
                    <Header size='tiny' style={{ color: '#B8B8B8' }}>
                      Additional Address Directions
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={11}>
                    <Form>
                      <Form.Group>
                        <Form.TextArea
                          id='tile-information-text-area'
                          name='address.directions'
                          autoHeight
                          // style={{ width: '100%', backgroundColor: '#F1F1F1' }}
                          style={
                            !editable
                              ? { width: '100%', border: '0px', backgroundColor: '#F1F1F1' }
                              : {
                                  border: '0px',
                                  backgroundColor: '#F1F1F1',
                                  fontSize: '11px',
                                  color: '#6A6A6A',
                                }
                          }
                          width={16}
                          value={object.address.directions}
                          onChange={onValueChange}
                          disabled={!editable}
                        />
                      </Form.Group>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              )}
              {isClient && (
                <Grid.Row>
                  <Grid.Column>
                    <Form.Group>
                      <Button
                        id='client-address-map-button'
                        type='button'
                        className='five wide clientborderlessBlueButton'
                        onClick={this.onViewMapClick}
                      >
                        {mapVisible ? (
                          <>
                            {hideMap}&nbsp;
                            <i className='ChevronUp' />
                          </>
                        ) : (
                          <>
                            {viewMap}&nbsp;
                            <i className='Chevron' />
                          </>
                        )}
                      </Button>
                    </Form.Group>
                  </Grid.Column>
                </Grid.Row>
              )}
              {mapVisible && (
                <Form.Group>
                  <Form.Input
                    id='tile-information-lat-input'
                    placeholder='Latitude'
                    width={8}
                    name='address.lat'
                    value={object.address.lat}
                    disabled={!addressEditability}
                    onChange={(e, input) => {
                      onValueChange(e, { name: input.name, value: Number.parseFloat(input.value) });
                    }}
                  />
                  <Form.Input
                    id='tile-information-lon-input'
                    placeholder='Longitude'
                    width={8}
                    name='address.lon'
                    value={object.address.lon}
                    disabled={!addressEditability}
                    onChange={(e, input) => {
                      onValueChange(e, { name: input.name, value: Number.parseFloat(input.value) });
                    }}
                  />
                  <LocationButton
                    address={object.address}
                    className='borderlessBlueButton'
                    dispatch={dispatch}
                    locationUpdate={loc => {
                      onValueChange({}, { name: 'address.lat', value: loc.lat });
                      onValueChange({}, { name: 'address.lon', value: loc.lon });
                      self.locUpdate(loc);
                    }}
                  />
                </Form.Group>
              )}
            </Grid.Column>
          </Grid.Row>

          {mapVisible && (
            <LocationMap
              editable
              loadingElement={<div style={{ height: '100%' }} />}
              containerElement={<div style={{ height: '400px' }} />}
              mapElement={<div style={{ height: '100%' }} />}
              showMarker
              lat={person.address.lat}
              lon={person.address.lon}
              mapRefCallback={(mapRef, googleMap) => {
                this.googleMapRef = googleMap;
              }}
              locationCallback={loc => {
                onValueChange({}, { name: 'address.lat', value: loc.lat });
                onValueChange({}, { name: 'address.lon', value: loc.lon });
              }}
            />
          )}
          <Grid style={{ marginTop: '16px' }}>
            <Grid.Row>
              {object.address.evacuationZone && (
                <Grid.Column width={10}>
                  <>
                    <div>
                      <Header size='tiny' style={{ marginBottom: '5px' }}>
                        {evacuationZone}
                      </Header>
                      <span>{object.address.evacuationZone}</span>
                    </div>
                  </>
                </Grid.Column>
              )}
              <Grid.Column width={6}>
                {object.address.timezone && (
                  <>
                    <div>
                      <Header size='tiny' style={{ marginBottom: '5px' }}>
                        {timezone}
                      </Header>
                      <span>{object.address.timezone}</span>
                    </div>
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </RecordsForm>
      </DetailsBox>
    );
  }
}

TileInformationAddress.defaultProps = {
  person: {},
  onValid: () => {},
  onInvalid: () => {},
  editable: true,
  editability: {},
  type: 'caregiver',
};

TileInformationAddress.propTypes = {
  person: PropTypes.shape(),
  intl: intlShape.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onValid: PropTypes.func,
  onInvalid: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  editability: PropTypes.arrayOf(PropTypes.shape()),
  type: PropTypes.string,
};

export default injectIntl(TileInformationAddress);

import {
  IOT_CONNECT_MESSAGE,
  GET_ACTIVE_CONVERSATIONS_SUCCESS,
  GET_CONVERSATION_SUCCESS,
  IOT_LIFECYCLE,
  MARK_CONVERSATION_READ,
  CLOSE_CONVERSATION,
  IOT_CONNECT_SUCCESS,
  IOT_HEARTBEAT,
} from '../constants/ActionTypes';
import { prepareShiftObject } from './helpers';
import { formatTime, MOMENT_HOURS, SHORT_DATE } from '../constants/Formats';

const moment = require('moment');

const initialState = {
  conversations: {},
  config: {},
  online: false,
};

function prepareTitle(conversation) {
  const participants = conversation.participants.map(p => `${p.firstName} ${p.lastName}`).join(',');

  const tmp = conversation.participants.filter(p => p.avatar);
  const avatar = tmp.length > 0 ? tmp[0].avatar : null;

  const shift = prepareShiftObject(conversation.shift);

  const shiftSchedule = `${formatTime(shift.start, shift.timezone, SHORT_DATE)}
    ${formatTime(shift.start, shift.timezone, MOMENT_HOURS)}
    ${formatTime(shift.end, shift.timezone, MOMENT_HOURS)}`;

  return { participants, shiftSchedule, avatar };
}

function carechat(state = initialState, action) {
  let newState = {};
  let newConversation = null;

  switch (action.type) {
    case GET_CONVERSATION_SUCCESS:
    case IOT_CONNECT_MESSAGE:
      if (state.conversations[action.conversationId]) {
        const conversation = state.conversations[action.conversationId];
        const messages = [...conversation.messages, ...action.message];
        newConversation = {
          ...conversation,
          messages,
          newMessages:
            conversation.newMessages +
            (action.principal &&
              action.message.filter(message => message.sender.userId !== action.principal.user.id)
                .length),
        };
      } else {
        newConversation = {
          messages: [],
          initiated: false,
          newMessages: 0,
        };
      }

      newConversation.messages.sort((a, b) => a.timestamp - b.timestamp);
      newConversation.lastMessage = newConversation.messages.length
        ? newConversation.messages[newConversation.messages.length - 1]
        : null;

      newState = { ...state, conversations: { ...state.conversations } };
      newState.conversations[action.conversationId] = newConversation;
      return newState;

    case GET_ACTIVE_CONVERSATIONS_SUCCESS: {
      newState = { ...state, conversations: { ...state.conversations } };
      const conversations = Array.isArray(action.results.conversations)
        ? action.results.conversations
        : [action.results.conversations];

      conversations.forEach(c => {
        if (newState.conversations[c.id]) {
          newConversation = { ...newState.conversations[c.id] };
          newConversation.title = prepareTitle(c);
          newConversation.latestMessage = c.latestMessage;
          newConversation.timestamp = c.timestamp;
          newConversation.initiated = true;
          newConversation.newMessages = c.newMessages;
        } else {
          newConversation = {
            messages: [],
            title: prepareTitle(c),
            latestMessage: c.latestMessage,
            lastMessage: null,
            timestamp: c.timestamp,
            initiated: true,
            newMessages: c.newMessages,
          };
        }
        newState.conversations[c.id] = newConversation;
      });

      return newState;
    }
    case IOT_LIFECYCLE: {
      newState = { ...state };
      newState.online = action.online;
      return newState;
    }
    case MARK_CONVERSATION_READ: {
      newState = { ...state, conversations: { ...state.conversations } };
      if (newState.conversations[action.conversationId]) {
        newState.conversations[action.conversationId].newMessages = 0;
      }
      return newState;
    }
    case CLOSE_CONVERSATION: {
      newState = { ...state, conversations: { ...state.conversations } };
      newState.conversations[action.conversationId] = undefined;
      return newState;
    }
    case IOT_CONNECT_SUCCESS: {
      return { ...state, config: action.config };
    }
    case IOT_HEARTBEAT: {
      return { ...state, lastHeartbeat: moment(action.timestamp) };
    }
    default:
      return state;
  }
}

export default carechat;

/* eslint-disable react/sort-comp */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Segment, Header, Divider, Button, Dropdown } from 'semantic-ui-react';
import moment from 'moment-timezone';

import { clientActions, schedulerActions, caregiverActions, uiStateActions } from '../actions';
import CareRoundButton from './subcomponents/CareRoundButton';
import './ShiftBuilder.css';
import ClientSearchResults from './subcomponents/ClientSearchResults';
import ShiftInformationEditor from './subcomponents/ShiftInformationEditor';
import ClientEditor from './ClientEditor';
import { history } from '../helpers';
import { analytics, Events } from '../helpers/analytics';

const uuid = require('uuid/v4');

const SEARCH_INTERVAL_MS = 300;

class ShiftBuilder extends React.Component {
  static createNewShift = (shift, tzName) =>
    shift
      ? {
          ...shift,
          start: moment(shift.start).add('days', 1),
          end: moment(shift.end).add('days', 1),
          index: shift.index + 1,
          closed: false,
          key: uuid(),
        }
      : {
          start: moment
            .tz(tzName)
            .add('hours', 1)
            .minutes(0)
            .seconds(0)
            .milliseconds(0),
          end: moment
            .tz(tzName)
            .add('hours', 1)
            .minutes(0)
            .seconds(0)
            .milliseconds(0),
          timezone: moment.tz.zone(tzName),
          service: null,
          valid: false,
          index: 1,
          closed: false,
          key: uuid(),
        };

  constructor(params) {
    super(params);

    this.addNewShift = this.addNewShift.bind(this);
    this.createShifts = this.createShifts.bind(this);
    this.createShiftsAndAssign = this.createShiftsAndAssign.bind(this);
    this.toggleClientEditor = this.toggleClientEditor.bind(this);
  }

  // eslint-disable-next-line react/state-in-constructor
  state = {
    searchQuery: '',
    searchResults: null,
    client: null,
    newShifts: [],
    allShiftsValid: false,
    closeEnabled: false,
    submitting: false,
    assignAfterSubmit: false,
    clientEditor: false,
    singleService: true,
    pastShifts: false,
  };

  UNSAFE_componentWillMount() {
    this.addNewShift();
    this.props.dispatch(clientActions.listServices());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.submitting && !nextProps.shiftBuilder.submitting) {
      if (nextProps.shiftBuilder.createdShifts) {
        if (this.state.assignAfterSubmit) {
          this.assignCreated(nextProps.shiftBuilder.createdShifts);
        } else {
          this.setState({
            newShifts: [],
            allShiftsValid: false,
          });
          this.addNewShift();
        }
      }
      this.setState({
        submitting: false,
      });
    }
    if (this.state.clientEditor && nextProps.shiftBuilder.createdClient) {
      this.setState({
        clientEditor: false,
        client: nextProps.shiftBuilder.createdClient,
        searchQuery: '',
        searchResults: null,
      });
    } else {
      this.setState({
        searchResults:
          this.state.searchQuery && nextProps.shiftBuilder.results
            ? nextProps.shiftBuilder.results.map(c => ({
                client: c,
                key: c.id,
                value: c.id,
                text: c.clientName,
              }))
            : null,
      });
    }
  }

  onClientChange = (e, { value }) => {
    const client = this.state.searchResults.find(c => c.value === value);
    this.setState({
      client: client ? client.client : null,
      searchQuery: '',
      searchResults: null,
      newShifts: [],
      allShiftsValid: false,
    });
    this.addNewShift();
  };

  onSearchTextChange = (e, { searchQuery }) => {
    this.setState({ searchQuery });
    if (searchQuery) {
      this.searchClients();
    } else {
      this.setState({ searchResults: null });
    }
  };

  onClearClient = () => {
    this.setState({
      client: null,
      newShifts: [],
      allShiftsValid: false,
    });
    this.addNewShift();
  };

  onShiftChanged = shift => {
    const newShifts = [...this.state.newShifts];
    const index = newShifts.findIndex(s => s.key === shift.key);
    if (index > -1) {
      newShifts[index] = shift;
    }
    this.updateShifts(newShifts, shift);
  };

  onShiftClose = shift => {
    let count = 0;
    const newShifts = this.state.newShifts
      .filter(s => s.key !== shift.key)
      .map(s => {
        count += 1;
        return { ...s, index: count };
      });
    this.updateShifts(newShifts, shift);
  };

  updateShifts = (newShifts, shift) => {
    this.setState({
      newShifts,
      allShiftsValid: newShifts.every(s => s.valid),
      singleService: newShifts.every(s => s.service === shift.service),
      pastShifts: !newShifts.every(s => s.start.isAfter(moment())),
      closeEnabled: newShifts.length > 1,
    });
  };

  toggleClientEditor() {
    this.setState(s => ({ clientEditor: !s.clientEditor }));
  }

  assignCreated = shifts => {
    this.props.dispatch(caregiverActions.setTargetShifts(shifts.map(s => s.id)));
    history.push('/home/caregivers');
    analytics.track(Events.SHIFTS_SEARCH_CAREGIVERS_MULTI);
  };

  addNewShift() {
    this.setState(s => {
      const { client } = s;
      const tz = ((client || {}).address || {}).timezone;
      const shiftTz = tz || moment.tz.guess();
      return {
        newShifts: [
          ...s.newShifts.map(shift => ({ ...shift, closed: true })),
          ShiftBuilder.createNewShift(s.newShifts.slice(-1)[0], shiftTz),
        ],
        closeEnabled: s.newShifts.length + 1 > 1,
      };
    });
  }

  searchClients = _.debounce(() => {
    if (this.state.searchQuery) {
      this.props.dispatch(
        clientActions.searchClients({
          name: this.state.searchQuery,
        })
      );
    }
  }, SEARCH_INTERVAL_MS);

  createShifts() {
    this.props.dispatch(
      schedulerActions.createShifts(
        this.state.newShifts.map(s => ({
          clientId: this.state.client.id,
          start: s.start,
          end: s.end,
          clientExternalId: this.state.client.externalId,
          timezone: s.timezone.name,
          service: s.service,
          status: '01',
        }))
      )
    );
    this.setState({
      submitting: true,
    });
  }

  createShiftsAndAssign() {
    this.createShifts();
    this.setState({
      assignAfterSubmit: true,
    });
  }

  render() {
    const {
      client,
      searchQuery,
      searchResults,
      newShifts,
      allShiftsValid,
      closeEnabled,
      submitting,
      assignAfterSubmit,
      clientEditor,
      singleService,
      pastShifts,
    } = this.state;
    const { services } = this.props.shiftBuilder;

    return (
      <Grid.Row className='noVerticalPadding shiftBuilder'>
        {clientEditor && (
          <ClientEditor uiState={this.props.uiState} onCloseClicked={this.toggleClientEditor} />
        )}
        <Grid padded style={{ width: '100%' }} className='contentGrid'>
          <Grid.Column width='three' className='menuCol'>
            <Segment basic>
              <span
                id='shift-builder-back-button'
                className='overlayLink backTotext'
                role='presentation'
                onClick={history.goBack}
              >
                ‹‹ Back To Shifts
              </span>
            </Segment>
            <Segment basic>
              Build a shift or series of shifts by finding the relevant client and defining the
              individual shift information.
            </Segment>
            <Segment basic>
              If a client is new use the “Add a New Client” option to add that new client to the
              system.
            </Segment>
            <Segment basic>
              You may add multiple shifts for a single client. Each shift will be added to the
              available shifts in your system and be available for caregiver matches.
            </Segment>
          </Grid.Column>

          <Grid.Column width='13'>
            <Grid padded>
              <Grid.Row>
                <Header as='h3'>SHIFT BUILDER</Header>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column widescreen='8' computer='16' className='client'>
                  <Grid className='clientColumn'>
                    <Grid.Row className='headerRow'>
                      <Grid.Column width='3' verticalAlign='middle'>
                        <Header as='h4'>CLIENT</Header>
                      </Grid.Column>
                      <Grid.Column width='7' verticalAlign='middle'>
                        {!client && (
                          <Dropdown
                            id='shift-builder-client-search-dropdown'
                            className='clientsearch'
                            fluid
                            placeholder='Enter Client Name'
                            selection
                            options={searchResults}
                            search
                            searchQuery={searchQuery}
                            onSearchChange={this.onSearchTextChange}
                            onChange={this.onClientChange}
                            noResultsMessage={searchResults !== null ? 'No matching results' : null}
                            icon={null}
                            value={client && client.id}
                          />
                        )}
                      </Grid.Column>
                      <Grid.Column width='6' verticalAlign='middle' textAlign='right'>
                        {!client && (
                          <span className='add-button-with-label'>
                            <span>Add a New Client</span>
                            <CareRoundButton
                              id='shift-builder-add-client-button'
                              content='+'
                              onClick={this.toggleClientEditor}
                            />
                          </span>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                    <Divider />
                    <ClientSearchResults client={client} onClear={this.onClearClient} />
                  </Grid>
                </Grid.Column>
                {client && (
                  <Grid.Column widescreen='8' computer='16'>
                    <Grid className='shiftColumn'>
                      <Grid.Row className='headerRow'>
                        <Header as='h4'>SHIFT INFORMATION</Header>
                      </Grid.Row>
                      <Divider />
                      {newShifts.map(s => (
                        <ShiftInformationEditor
                          key={s.key}
                          index={s.index}
                          shift={s}
                          services={services}
                          closeEnabled={closeEnabled}
                          onChange={this.onShiftChanged}
                          onClose={this.onShiftClose}
                        />
                      ))}
                      {allShiftsValid && (
                        <Grid.Row className='add'>
                          <div>
                            <Button
                              id='shift-builder-add-shift-button'
                              circular
                              basic
                              icon='plus'
                              size='mini'
                              onClick={this.addNewShift}
                            />
                          </div>
                        </Grid.Row>
                      )}
                    </Grid>
                  </Grid.Column>
                )}
              </Grid.Row>
              <Divider />
              <Grid.Row>
                <Grid.Column widescreen='8' computer='16'>
                  <div className='footer'>
                    Submitting your shifts will store them in the system and they will be available
                    for matching. If you know the caregiver you want to apply to these shifts,
                    submit and match will take you directly into the caregiver search where you can
                    enter your caregivers name to make your match.
                  </div>
                  {(!singleService || pastShifts) && (
                    <div className='footer warning'>
                      Submit and match is not available
                      {pastShifts
                        ? ' for shifts in the past.'
                        : ' when shifts have different services.'}
                    </div>
                  )}
                </Grid.Column>
                <Grid.Column widescreen='8' computer='16'>
                  <Button
                    id='shift-builder-submit-match-caregivers-button'
                    disabled={
                      !client ||
                      !allShiftsValid ||
                      !client.address.validLatLon ||
                      !singleService ||
                      pastShifts
                    }
                    floated='right'
                    className='care-green'
                    onClick={this.createShiftsAndAssign}
                    loading={submitting && assignAfterSubmit}
                  >
                    Submit &amp; Match Caregivers
                  </Button>
                  <Button
                    id='shift-builder-submit-new-shift-button'
                    disabled={!client || !allShiftsValid}
                    floated='right'
                    className='care-green'
                    onClick={this.createShifts}
                    loading={submitting && !assignAfterSubmit}
                  >
                    Submit New Shifts
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid>
      </Grid.Row>
    );
  }
}

ShiftBuilder.propTypes = {
  dispatch: PropTypes.func.isRequired,
  shiftBuilder: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape()),
    nextPage: PropTypes.shape(),
    services: PropTypes.arrayOf(PropTypes.string),
    submitting: PropTypes.bool,
    createdShifts: PropTypes.arrayOf(PropTypes.shape()),
    createdClient: PropTypes.shape(),
  }).isRequired,
  uiState: PropTypes.shape().isRequired,
};

const mapStateToProps = state => {
  const { shiftBuilder } = state;
  return {
    shiftBuilder,
  };
};

export default connect(uiStateActions.mapStateOfGroupToProps('clientsFilter', mapStateToProps))(
  ShiftBuilder
);

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import SubTabHeader from './subcomponents/SubTabHeader';
import DetailsBox from './subcomponents/records/DetailsBox';
import CGTravelTransit from './subcomponents/CGTravelTransit';
import RecordsLanguages from './subcomponents/RecordsLanguages';
import PreferencesCategory from './subcomponents/PreferencesCategory';

class CGPreferences extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillMount() {
    this.props.onActive(this.props.tabIndex);
  }

  render() {
    const {
      intl,
      languages,
      onValueChange,
      saveEnabled,
      onSave,
      caregiver,
      editability,
    } = this.props;

    /* const applyChangesClick = () => {
      // casePref update is using same model with mobile API, modify payload before request...
      const considerations = this.state.caregiver.preferences;
      const casePref = Object.assign({}, this.state.caregiver.casePref, { considerations });
      delete this.state.caregiver.preferences;

      this.props.dispatch(caregiverActions.updateCaregiver(
        Object.assign({},
          {
            ...this.state.caregiver,
            casePref,
          })));
    };
    */

    return (
      <>
        <SubTabHeader
          text={intl.formatMessage({ id: 'caregivers.records.preferences' }).toUpperCase()}
          buttonTitle={intl.formatMessage({ id: 'records.saveUpdates' })}
          buttonOnClick={onSave}
          buttonDisabled={!saveEnabled}
        />
        <Grid columns={1} style={{ width: '100%' }} doubling>
          <Grid.Column width={16} style={{ flex: '0 0 72rem' }}>
            <DetailsBox
              caregiver={caregiver}
              text={intl.formatMessage({ id: 'caregivers.records.travelTransit' }).toUpperCase()}
              withoutIcon
            >
              <CGTravelTransit
                person={caregiver}
                onValueChange={onValueChange}
                dispatch={this.props.dispatch}
              />
            </DetailsBox>
          </Grid.Column>
        </Grid>
        <Grid columns={2} style={{ width: '100%' }} doubling>
          <Grid.Column
            width={8}
            style={{
              flex: '0 0 36rem',
              maxWidth: '36rem',
              paddingRight: '10px',
              paddingTop: '6px',
            }}
          >
            <Grid.Row>
              <DetailsBox
                text={intl.formatMessage({ id: 'records.languages' }).toUpperCase()}
                icon='/spokenlanguages@2x.png'
              >
                <RecordsLanguages
                  person={caregiver}
                  type='caregiver'
                  languages={languages}
                  editability={editability}
                  onValueChange={onValueChange}
                />
              </DetailsBox>
            </Grid.Row>

            <br />

            <Grid.Row>
              <DetailsBox
                text={intl.formatMessage({ id: 'caregivers.records.skills' }).toUpperCase()}
                icon='/TrainingClass@2x.png'
              >
                <PreferencesCategory
                  person={caregiver}
                  editable
                  category='skills'
                  onValueChange={onValueChange}
                />
              </DetailsBox>
            </Grid.Row>

            <br />

            <Grid.Row>
              <DetailsBox
                text={intl.formatMessage({ id: 'caregivers.records.experience' }).toUpperCase()}
                withoutIcon
              >
                <PreferencesCategory
                  person={caregiver}
                  editable
                  category='experience'
                  onValueChange={onValueChange}
                />
              </DetailsBox>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{ flex: '0 0 36rem', maxWidth: '36rem', paddingLeft: '10px', paddingTop: '6px' }}
          >
            <Grid.Row>
              <DetailsBox
                text={intl.formatMessage({ id: 'caregivers.recors.availability' }).toUpperCase()}
                icon='/availability.@2x.png'
              >
                <PreferencesCategory
                  person={caregiver}
                  editable
                  category='availability'
                  onValueChange={onValueChange}
                />
              </DetailsBox>
            </Grid.Row>

            <br />

            <Grid.Row>
              <DetailsBox
                text={intl.formatMessage({ id: 'caregivers.records.environment' }).toUpperCase()}
                icon='/Environment@2x.png'
              >
                <PreferencesCategory
                  person={caregiver}
                  editable
                  category='environment'
                  onValueChange={onValueChange}
                />
              </DetailsBox>
            </Grid.Row>

            <br />

            <Grid.Row>
              <DetailsBox
                text={intl.formatMessage({ id: 'caregivers.records.other' }).toUpperCase()}
                withoutIcon
              >
                <PreferencesCategory
                  person={caregiver}
                  editable
                  category='other'
                  onValueChange={onValueChange}
                />
              </DetailsBox>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </>
    );
  }
}

CGPreferences.defaultProps = {
  saveEnabled: false,
};

CGPreferences.propTypes = {
  intl: intlShape.isRequired,
  caregiver: PropTypes.shape().isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSave: PropTypes.func.isRequired,
  saveEnabled: PropTypes.bool,
  editability: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onValueChange: PropTypes.func.isRequired,
  onActive: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default injectIntl(CGPreferences);

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Sticky } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './CareChat.css';
import CareChat from './CareChat';
import { IoT } from '../actions';

class CareChatContainer extends React.Component {
  componentDidMount() {
    this.props.dispatch(IoT.connectIOT());
    this.props.dispatch(IoT.getActiveConversations());
  }

  componentWillUnmount() {
    this.props.dispatch(IoT.disconnectIOT());
  }

  render() {
    const conversations = [];
    Object.keys(this.props.carechat.conversations)
      .filter(v => this.props.carechat.conversations[v])
      .map(v => conversations.push(<CareChat channelId={v} key={v} visible />));

    return (
      <Sticky className='ui fixed bottom chatContainer' padded='horizontally'>
        {conversations}
      </Sticky>
    );
  }
}

CareChatContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  carechat: PropTypes.shape().isRequired,
};

const mapStateToProps = state => {
  const { carechat } = state;
  return {
    carechat,
  };
};

export default connect(mapStateToProps)(CareChatContainer);

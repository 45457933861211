import React from 'react';
import { Grid, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import CareLoader from './CareLoader';

const LoadMore = ({
  onLoadMore,
  loading,
  partialUpdate,
  nextPage,
  loadingShowText = true,
  loadingCentered = false,
}) => {
  if (nextPage && !(loading && !partialUpdate)) {
    return (
      <Grid.Row colums='one' className='loadMoreRow' centered>
        {!loading && (
          <Button size='small' onClick={onLoadMore}>
            Load more
          </Button>
        )}
        <CareLoader loading={loading} showText={loadingShowText} centered={loadingCentered} />
      </Grid.Row>
    );
  }
  return null;
};

LoadMore.defaultProps = {
  nextPage: null,
  loadingShowText: true,
  loadingCentered: false,
};

LoadMore.propTypes = {
  onLoadMore: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  partialUpdate: PropTypes.bool.isRequired,
  nextPage: PropTypes.string,
  loadingShowText: PropTypes.bool,
  loadingCentered: PropTypes.bool,
};

export default LoadMore;

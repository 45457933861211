import moment from 'moment-timezone';
import { config } from '../helpers/config';

import {
  INPUT_CHANGED,
  UI_STATE_CHANGED,
  SET_UI_GROUP_STATE,
  RESET_UI_GROUP_STATE,
  GET_EMPLOYEE_SUCCESS,
} from '../constants/ActionTypes';

import { ActiveStaffStatus } from '../constants/DomainTypes';

const data = JSON.parse(localStorage.getItem(config.LOCAL_STORAGE_KEY));

const initialState = {
  inputGroups: {
    shiftFilter: {
      clientName: '',
      shiftStatusMenu: true,
      datetimeMenu: true,
      teamMenu: true,
      shiftStatus: ((data || {}).settings || {}).defaultShiftSearchMode || 'allshifts',
      shiftCompinedStatus: ['unassigned'],
      startDate: moment(),
      endDate: moment().add(30, 'days'),
      timeRange: { min: 0, max: 24 },
      selectedPrimaryContacts: undefined,
    },
    clientsFilter: {
      environmentCasePreferencesMenu: false,
      teamMenu: true,
      clientStatusMenu: true,
      preferenceFilter: [],
      clientStatusFilter: ['02'],
      zip: '',
      selectedPrimaryContacts: [0],
      languages: [],
    },
    caregiverFilter: {
      registrationStatusMenu: false,
      staffStatusMenu: true,
      environmentPreferencesMenu: false,
      profileFieldsMenu: false,
      travelTimeMenu: true,
      advancedMenu: true,
      advancedEnabled: false,
      teamMenu: true,
      genderMenu: false,
      statusFilter: [],
      staffStatusFilter: [ActiveStaffStatus],
      preferenceFilter: [],
      profileFieldsFilter: [],
      advancedFilter: [],
      travelTime: 30,
      selectedPrimaryContacts: [0],
      languages: [],
      genderFilter: [],
    },
    shiftBarFilter: {
      date: moment(),
      address: '',
      start: moment
        .tz(moment.tz.guess())
        .hours(9)
        .minutes(0),
      end: moment
        .tz(moment.tz.guess())
        .hours(17)
        .minutes(0),
      visible: false,
    },
    dashboardFilter: {
      findClientMenu: true,
      findCaregiverMenu: true,
      clientName: '',
      caregiverName: '',
    },
    newEmployee: {
      firstName: '',
      lastName: '',
      email: '',
      cellPhone: '',
      officeNumber: '',
      roles: [],
    },
    multiShiftSelector: {
      originalShifts: [],
      selectedShifts: [],
    },
    complianceTrainingFilter: {
      teamMenu: true,
      selectedPrimaryContacts: [0],
      selectedPrimaryContactsManager: [0],
      caregiverNameOrId: '',
      pristine: true,
      selectedDisciplines: [],
      selectedComplianceItems: [],
      selectedVaccinations: [],
      primaryContacts: [],
      searchDisciplineOptions: [],
      searchComplianceOptions: [],
    },
    complianceHRCaregiversFilter: {
      teamMenu: true,
      selectedPrimaryContacts: [0],
      selectedPrimaryContactsManager: [0],
      caregiverNameOrId: '',
      pristine: true,
      selectedDisciplines: [],
      selectedComplianceItems: [],
      selectedVaccinations: [],
      primaryContacts: [],
      searchDisciplineOptions: [],
      searchComplianceOptions: [],
      selectedActiveTab: undefined,
    },
    hrDashboardFilter: {
      findCandidateMenu: true,
      findCaregiverMenu: true,
      candidateNameOrId: '',
      caregiverNameOrId: '',
    },
    application: {
      mode: 'scheduler',
      subTab: '',
    },
  },
  shiftSearchTarget: 1,
};

const cloneNewUIState = (currentState, groupName, stateName, newInputState) => {
  const group = currentState.inputGroups[groupName] ? currentState.inputGroups[groupName] : {};

  if (groupName === 'shiftBarFilter' && stateName === 'date' && newInputState) {
    // When date is changed in shiftBarFilter, start and end times should also change
    const newStart = group.start.clone();
    newStart.set('year', newInputState.year());
    newStart.set('month', newInputState.month());
    newStart.set('date', newInputState.date());

    const newEnd = group.end.clone();
    newEnd.set('year', newInputState.year());
    newEnd.set('month', newInputState.month());
    newEnd.set('date', newInputState.date());

    const newState = {
      ...currentState.inputGroups,
      ...{
        [groupName]: {
          ...group,
          ...{ date: newInputState, start: newStart, end: newEnd },
        },
      },
    };

    return { inputGroups: newState };
  }

  const newState = {
    ...currentState.inputGroups,
    ...{
      [groupName]: {
        ...group,
        ...{ [stateName]: newInputState },
      },
    },
  };

  return { inputGroups: newState };
};

const checkboxHandler = (currentState, input) =>
  input.checked
    ? [...new Set(currentState.concat(input.value))]
    : currentState.filter(x => x !== input.value);

function uiState(state = initialState, action) {
  switch (action.type) {
    case INPUT_CHANGED: {
      const { groupName, input } = action;
      const group = state.inputGroups[groupName] ? state.inputGroups[groupName] : {};
      const inputState = group[input.name] ? group[input.name] : null;

      let newInputState = null;
      switch (input.type) {
        case 'checkbox':
          newInputState = checkboxHandler(inputState, input);
          break;
        case 'range':
          newInputState = parseInt(input.value, 10);
          break;
        default:
          newInputState = input.value;
          break;
      }
      if (
        groupName === 'complianceTrainingFilter' ||
        groupName === 'complianceHRCaregiversFilter'
      ) {
        const pristine =
          input.name === 'caregiverNameOrId' ||
          input.name === 'selectedPrimaryContacts' ||
          input.name === 'selectedPrimaryContactsManager'
            ? false
            : state.inputGroups[groupName].pristine;
        const newState = {
          ...state.inputGroups,
          ...{
            [groupName]: {
              ...group,
              ...{ [input.name]: newInputState, pristine },
            },
          },
        };
        return { inputGroups: newState };
      }

      const newState = {
        ...state.inputGroups,
        ...{
          [groupName]: {
            ...group,
            ...{ [input.name]: newInputState },
          },
        },
      };

      return { inputGroups: newState };
    }
    case UI_STATE_CHANGED:
      return cloneNewUIState(state, action.groupName, action.stateName, action.value);

    case SET_UI_GROUP_STATE: {
      const group = state.inputGroups[action.groupName] ? state.inputGroups[action.groupName] : {};
      const newState = {
        ...state.inputGroups,
        ...{
          [action.groupName]: {
            ...group,
            ...action.state,
          },
        },
      };
      return { inputGroups: newState };
    }
    case RESET_UI_GROUP_STATE: {
      const newState = {
        ...state.inputGroups,
        ...{
          [action.groupName]: {
            ...initialState.inputGroups[action.groupName],
          },
        },
      };
      return { inputGroups: newState };
    }
    case GET_EMPLOYEE_SUCCESS: {
      let nextState = state;
      if (
        nextState.inputGroups.shiftFilter.selectedPrimaryContacts === undefined &&
        (action.results || action.rootUser)
      ) {
        const selectedPrimaryContacts =
          action.results &&
          action.results.profile.isCoordinator &&
          !action.settings.defaultSearchAllEmployees
            ? [action.results.profile.id]
            : [0];
        nextState = cloneNewUIState(
          nextState,
          'shiftFilter',
          'selectedPrimaryContacts',
          selectedPrimaryContacts
        );
        nextState = cloneNewUIState(
          nextState,
          'clientsFilter',
          'selectedPrimaryContacts',
          selectedPrimaryContacts
        );
        nextState = cloneNewUIState(
          nextState,
          'complianceTrainingFilter',
          'selectedPrimaryContacts',
          selectedPrimaryContacts
        );
        nextState = cloneNewUIState(
          nextState,
          'complianceHRCaregiversFilter',
          'selectedPrimaryContacts',
          selectedPrimaryContacts
        );
      }

      return nextState;
    }
    default:
      if (action.invalidFields && action.uiStateGroup) {
        const invalidFields = {};
        action.invalidFields.forEach(f => {
          invalidFields[`_${f}`] = true;
        });

        const group = state.inputGroups[action.uiStateGroup]
          ? state.inputGroups[action.uiStateGroup]
          : {};
        const newState = {
          ...state.inputGroups,
          ...{
            [action.uiStateGroup]: {
              ...group,
              ...invalidFields,
            },
          },
        };
        return { inputGroups: newState };
      }

      return state;
  }
}

export default uiState;

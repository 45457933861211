/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

class PageMeter extends React.Component {
  constructor(props) {
    super(props);

    this.divElement = null;
  }

  componentDidMount() {
    let top = 0;
    let obj = this.divElement;
    do {
      top += obj.offsetTop;
      obj = obj.offsetParent;
    } while (obj);
    this.props.onChange({ [this.props.name]: top });
  }

  render() {
    return (
      <div
        ref={divElement => {
          this.divElement = divElement;
          return this.divElement;
        }}
        style={{ height: 0 }}
      />
    );
  }
}

PageMeter.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default PageMeter;

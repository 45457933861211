/* eslint-disable react/require-default-props */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import { Image } from 'semantic-ui-react';
import { compose, withProps } from 'recompose';
import { avatarUrl } from '../../constants/DomainTypes';
import { googleMapURL } from '../../constants/Constants';
import './CaregiverMap.css';

class CaregiverMap extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const bounds = new window.google.maps.LatLngBounds();

    const { destinations, caregivers, onMarkerClick, onMouseOver, activeMapInfoBox } = this.props;

    const activeDestination =
      destinations && destinations.length > 0
        ? destinations[0]
        : { lat: 40.726141, lon: -73.998255 };

    const updateBounds = (ref, newBounds) => {
      if (!this.state.bounds || JSON.stringify(newBounds) !== JSON.stringify(this.state.bounds)) {
        this.setState(s => ({ ...s, bounds: newBounds }));
        ref.fitBounds(newBounds);
      }
    };

    const onRefUpdate = ref => {
      this.ref = ref;
      if (ref) {
        updateBounds(ref, bounds);

        // eslint-disable-next-line
        const googleMapsRef = ((ref || {}).context || {})
          .__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
        if (googleMapsRef) {
          const noPoi = [
            {
              featureType: 'poi',
              elementType: 'labels',

              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
          ];

          googleMapsRef.setOptions({
            styles: noPoi,
          });
        }
      }
    };
    try {
      bounds.extend({ lat: activeDestination.lat, lng: activeDestination.lon });
    } catch (e) {
      // console.log(e);
    }

    const markers =
      caregivers &&
      caregivers
        .filter(c => c.address && c.address.lat)
        .map(c => {
          const pos = { lat: c.address.lat, lng: c.address.lon };
          bounds.extend(pos);

          return (
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            <Marker
              key={c.id}
              position={pos}
              onClick={() => onMarkerClick(c.id)}
              onMouseOver={() => onMouseOver(c.id)}
              onMouseOut={() => onMouseOver(undefined)}
              icon='/caregiver_marker.svg'
            >
              {activeMapInfoBox === c.id && (
                <InfoBox options={{ closeBoxURL: '', enableEventPropagation: true }}>
                  <div className='map-info-box'>
                    <div className='map-info-box-container'>
                      <Image
                        className='info-box-image'
                        avatar
                        size='mini'
                        src={avatarUrl(c.avatar, c.gender)}
                      />
                      <span className='iw-text'>{c.caregiverName}</span>
                    </div>
                    <div className='map-info-box-pointer' />
                  </div>
                </InfoBox>
              )}
            </Marker>
          );
        });

    return (
      <GoogleMap
        ref={onRefUpdate}
        options={{ minZoom: 3, maxZoom: 19 }}
        defaultCenter={{ lat: activeDestination.lat, lng: activeDestination.lon }}
        defaultZoom={6}
      >
        {markers}
        <Marker
          position={{ lat: activeDestination.lat, lng: activeDestination.lon }}
          icon='/MapPin.png'
        />
      </GoogleMap>
    );
  }
}

CaregiverMap.defaultProps = {
  onMouseOver: () => {},
  showMarker: false,
  activeMapInfoBox: undefined,
};

CaregiverMap.propTypes = {
  destinations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  caregivers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onMarkerClick: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func.isRequired,
  activeMapInfoBox: PropTypes.number,
};

export default compose(withProps({ googleMapURL }), withScriptjs, withGoogleMap)(CaregiverMap);

import {
  GET_ELEARNING_PROFILE_LIST_REQUEST,
  GET_ELEARNING_PROFILE_LIST_SUCCESS,
  GET_ELEARNING_PROFILE_LIST_FAILURE,
  GET_TRAINEE_PROGRAMS_REQUEST,
  GET_TRAINEE_PROGRAMS_SUCCESS,
  GET_TRAINEE_PROGRAMS_FAILURE,
  GET_AGENCY_PROGRAMS_REQUEST,
  GET_AGENCY_PROGRAMS_SUCCESS,
  GET_AGENCY_PROGRAMS_FAILURE,
  LOAD_MORE_ELEARNING_PROFILE_LIST_REQUEST,
  LOAD_MORE_ELEARNING_PROFILE_LIST_SUCCESS,
  LOAD_MORE_ELEARNING_PROFILE_LIST_FAILURE,
  CLEAR_CURRICULUMS_AND_PROGRAMS,
  GET_CURRICULUM_WITH_COURSES_REQUEST,
  GET_CURRICULUM_WITH_COURSES_SUCCESS,
  GET_CURRICULUM_WITH_COURSES_FAILURE,
  HANDLE_E_LEARNING_CALL_REQUEST,
  HANDLE_E_LEARNING_CALL_SUCCESS,
  HANDLE_E_LEARNING_CALL_FAILURE,
} from '../../constants/ActionTypes';

const initialState = {
  agencyPrograms: [],
  traineesList: [],
  traineePrograms: [],
  traineeCurriculums: [],
  disabledPrograms: [],
  disabledCurriculums: [],
  loading: false,
  clear: false,
  programCurriculums: [],
  coursesLoading: false,
  updateInProgress: false,
};

const startState = JSON.parse(JSON.stringify(initialState));

function eLManage(state = startState, action) {
  switch (action.type) {
    case GET_ELEARNING_PROFILE_LIST_REQUEST:
      return { ...state, loading: true, traineesList: [] };
    case GET_ELEARNING_PROFILE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        traineesList: [...action.results.profiles],
        paging: action.results.paging,
      };
    case GET_ELEARNING_PROFILE_LIST_FAILURE:
      return { ...state, loading: false };
    case CLEAR_CURRICULUMS_AND_PROGRAMS:
      return {
        ...state,
        clear: true,
        traineePrograms: [],
        traineeCurriculums: [],
        updateInProgress: false,
      };
    case LOAD_MORE_ELEARNING_PROFILE_LIST_REQUEST:
      return { ...state, loading: true };
    case LOAD_MORE_ELEARNING_PROFILE_LIST_SUCCESS: {
      const newTrainingList = state.traineesList;
      newTrainingList.push(...action.results.profiles);
      return {
        ...state,
        loading: false,
        traineesList: newTrainingList,
        paging: action.results.paging,
      };
    }
    case LOAD_MORE_ELEARNING_PROFILE_LIST_FAILURE:
      return { ...state, loading: false };
    case GET_TRAINEE_PROGRAMS_REQUEST:
      return { ...state, loading: true };
    case GET_TRAINEE_PROGRAMS_SUCCESS:
      return {
        ...state,
        clear: false,
        loading: false,
        traineePrograms: [...action.results.programs.programs],
        disabledPrograms: [...action.results.programs.disabledPrograms],
        traineeCurriculums: [...action.results.curriculums.curriculums],
        disabledCurriculums: [...action.results.curriculums.disabledCurriculums],
      };
    case GET_TRAINEE_PROGRAMS_FAILURE:
      return { ...state, loading: false };
    case GET_AGENCY_PROGRAMS_REQUEST:
      return { ...state, loading: true, agencyPrograms: [] };
    case GET_AGENCY_PROGRAMS_SUCCESS:
      return { ...state, loading: false, agencyPrograms: [...action.results] };
    case GET_AGENCY_PROGRAMS_FAILURE:
      return { ...state, loading: false };
    case GET_CURRICULUM_WITH_COURSES_REQUEST:
      return { ...state, coursesLoading: true, traineesList: [] };
    case GET_CURRICULUM_WITH_COURSES_SUCCESS:
      return { ...state, coursesLoading: false, programCurriculums: [...action.results] };
    case GET_CURRICULUM_WITH_COURSES_FAILURE:
      return { ...state, coursesLoading: false };
    case HANDLE_E_LEARNING_CALL_REQUEST:
      return { ...state, loading: true };
    case HANDLE_E_LEARNING_CALL_SUCCESS:
      return { ...state, loading: false, updateInProgress: true };
    case HANDLE_E_LEARNING_CALL_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export default eLManage;

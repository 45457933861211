import React from 'react';
import { AccordionTitleProps, DropdownProps } from 'semantic-ui-react';
import {
  IDropdownOptions,
  IComplianceOptions,
  IHRCandidatesFilter,
} from '../../../../reducers/types/HRFilters';
import { CCDropDown } from '../../../../styles/commonStyled';
import AccordionSection from './AccordionSection';
import { displaySelectedOptions, FilterSections } from './helper';
import { DropdownLabelContainer, RailLabel } from './styles';

interface IProps {
  active: FilterSections[];
  disciplinesOptions: IDropdownOptions[];
  primaryContactOption: IDropdownOptions[];
  primaryManagerOption: IDropdownOptions[];
  complianceOptions: IComplianceOptions;
  filters: IHRCandidatesFilter;
  onAccordionClick: (_e: unknown, titleProps: AccordionTitleProps) => void;
  onDropdownChange: (data: DropdownProps, type: string) => void;
}

const CandidatesRail: React.FC<IProps> = ({
  active,
  complianceOptions,
  primaryContactOption,
  primaryManagerOption,
  filters,
  onAccordionClick,
  onDropdownChange,
  disciplinesOptions = [],
}) => {
  const { disciplines, compliancesIssues, primaryContact, primaryManager } = filters;
  const handleDropdownChange = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    onDropdownChange(data, 'candidates');
  };
  return (
    <>
      <AccordionSection
        title={`${FilterSections.DISCIPLINES}  ${displaySelectedOptions([
          disciplines.disciplines.length,
        ])}`}
        active={active.includes(FilterSections.CANDIDATES_DISCIPLINES)}
        index={FilterSections.CANDIDATES_DISCIPLINES}
        handleClick={onAccordionClick}
      >
        <CCDropDown
          placeholder='Please Select'
          tabIndex={FilterSections.DISCIPLINES}
          id='disciplines'
          multiple
          selection
          value={disciplines.disciplines}
          options={[{ text: 'All Disciplines', value: 0 }, ...disciplinesOptions]}
          onChange={handleDropdownChange}
        />
      </AccordionSection>
      <AccordionSection
        title={`${FilterSections.COMPLIANCES_ISSUES} ${displaySelectedOptions([
          compliancesIssues.eligibility.length,
          compliancesIssues.medical.length,
          compliancesIssues.documentation.length,
          compliancesIssues.training.length,
        ])}`}
        active={active.includes(FilterSections.CANDIDATES_COMPLIANCES)}
        index={FilterSections.CANDIDATES_COMPLIANCES}
        handleClick={onAccordionClick}
      >
        <DropdownLabelContainer>
          <RailLabel>Eligibility</RailLabel>
          <CCDropDown
            placeholder='Please Select'
            tabIndex={FilterSections.COMPLIANCES_ISSUES}
            id='eligibility'
            multiple
            selection
            value={compliancesIssues.eligibility}
            options={[{ text: 'All Eligibility', value: 0 }, ...complianceOptions.eligibility]}
            onChange={handleDropdownChange}
          />
        </DropdownLabelContainer>
        <DropdownLabelContainer>
          <RailLabel>Medical</RailLabel>
          <CCDropDown
            placeholder='Please Select'
            tabIndex={FilterSections.COMPLIANCES_ISSUES}
            id='medical'
            multiple
            selection
            value={compliancesIssues.medical}
            options={[{ text: 'All Medical', value: 0 }, ...complianceOptions.medical]}
            onChange={handleDropdownChange}
          />
        </DropdownLabelContainer>
        <DropdownLabelContainer>
          <RailLabel>Documentation</RailLabel>
          <CCDropDown
            placeholder='Please Select'
            tabIndex={FilterSections.COMPLIANCES_ISSUES}
            id='documentation'
            multiple
            selection
            value={compliancesIssues.documentation}
            options={[{ text: 'All Documentation', value: 0 }, ...complianceOptions.documentation]}
            onChange={handleDropdownChange}
          />
        </DropdownLabelContainer>
        <DropdownLabelContainer>
          <RailLabel>Training</RailLabel>
          <CCDropDown
            placeholder='Please Select'
            tabIndex={FilterSections.COMPLIANCES_ISSUES}
            id='training'
            multiple
            selection
            value={compliancesIssues.training}
            options={[{ text: 'All Training', value: 0 }, ...complianceOptions.training]}
            onChange={handleDropdownChange}
          />
        </DropdownLabelContainer>
      </AccordionSection>
      <AccordionSection
        title={`${FilterSections.PRIMARY_CONTACT} ${displaySelectedOptions([
          primaryContact.primaryContact.length,
          primaryManager.primaryManager.length,
        ])}`}
        active={active.includes(FilterSections.CANDIDATES_PRIMARY_CONTACT)}
        index={FilterSections.CANDIDATES_PRIMARY_CONTACT}
        handleClick={onAccordionClick}
      >
        <DropdownLabelContainer>
          <RailLabel>Primary Contact</RailLabel>
          <CCDropDown
            placeholder='Please Select'
            tabIndex={FilterSections.PRIMARY_CONTACT}
            id='primaryContact'
            multiple
            selection
            value={primaryContact.primaryContact}
            options={[{ text: 'All Primary Contacts', value: 0 }, ...primaryContactOption]}
            onChange={handleDropdownChange}
          />
        </DropdownLabelContainer>
        <DropdownLabelContainer>
          <RailLabel>Primary Contact Manager</RailLabel>
          <CCDropDown
            placeholder='Please Select'
            tabIndex={FilterSections.PRIMARY_MANAGER}
            id='primaryManager'
            multiple
            selection
            value={primaryManager.primaryManager}
            options={[{ text: 'All Managers', value: 0 }, ...primaryManagerOption]}
            onChange={handleDropdownChange}
          />
        </DropdownLabelContainer>
      </AccordionSection>
    </>
  );
};

export default CandidatesRail;

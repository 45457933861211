/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component, createRef } from 'react';
import CanvasDraw from 'react-canvas-draw';
import { LinkButton } from '../../../styles/common';
import { ELCoursesActions } from '../../../actions/ELApp/ElCoursesActions';
import './ESignature.scss';

interface Props {
  onSignatureSave(): void;
  dispatch: (arg: any) => void;
}

interface State {
  imageUrl: string;
  signatureEmpty: boolean;
}

class SignatureCanvas extends Component<Props, State> {
  public state = {
    imageUrl: '',
    signatureEmpty: true,
  };

  private canvasRef = createRef<CanvasDraw>();

  private readonly saveSignature = () => {
    const { dispatch, onSignatureSave } = this.props;
    const signature = this.canvasRef.current?.getSaveData();
    const jsonSignature = JSON.parse(signature || '');
    const canvas = document.createElement('canvas');
    canvas.width = 400;
    canvas.height = 140;
    const context = canvas.getContext('2d');
    jsonSignature?.lines?.forEach((l: any) => {
      l.points.forEach((p: any, i: number, a: any) => {
        Object.assign(context, { lineWidth: 3, strokeStyle: '#000000' });
        context?.moveTo(a[i - 1]?.x || a[i]?.x + 2, a[i - 1]?.y || a[i]?.y);
        context?.lineTo(p.x, p.y);
      });
      context?.stroke();
    });
    canvas.toBlob(
      blob => dispatch(ELCoursesActions.putESignature({ signature: blob })),
      'image/png'
    );
    return onSignatureSave();
  };

  private readonly catchEmptyCanvas = () => {
    const { signatureEmpty } = this.state;
    if (!signatureEmpty) {
      return null;
    }
    return this.setState(s => ({ ...s, signatureEmpty: false }));
  };

  private readonly onClearCanvas = () => {
    this.setState(s => ({ ...s, signatureEmpty: true }));
    this.canvasRef.current?.clear();
  };

  public render(): JSX.Element {
    const { signatureEmpty } = this.state;

    return (
      <div className='eSignatureGrid'>
        <CanvasDraw
          ref={this.canvasRef}
          brushColor='#000000'
          onChange={this.catchEmptyCanvas}
          lazyRadius={0}
          brushRadius={2}
          canvasWidth={400}
          canvasHeight={140}
          style={{ borderBottom: '1px solid #c4c4c4', margin: '10px' }}
        />
        <div className='drawSignatureButtonsContainer'>
          <LinkButton onClick={this.onClearCanvas}>Clear</LinkButton>
          <LinkButton
            style={{ color: '#07c957' }}
            onClick={this.saveSignature}
            disabled={signatureEmpty}
          >
            Save
          </LinkButton>
        </div>
      </div>
    );
  }
}

export default SignatureCanvas;

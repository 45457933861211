import { Grid, Header, Form } from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

const Email = ({ object, editable, onValueChange }) =>
  editable ? (
    <Grid.Row>
      <Grid.Column width={5}>
        <Header size='tiny' floated='left'>
          Email:
        </Header>
      </Grid.Column>
      <Grid.Column width={11}>
        <Header size='tiny'>
          <Form.Input
            placeholder='Email'
            width={16}
            name='email'
            value={object.email}
            onChange={onValueChange}
          />
        </Header>
      </Grid.Column>
    </Grid.Row>
  ) : (
    <Grid.Row>
      <Grid.Column width={5}>
        <Header size='tiny' floated='left'>
          Email:
        </Header>
      </Grid.Column>
      <Grid.Column width={11}>
        <Header size='tiny'>{object.email ? object.email : '-'}</Header>
      </Grid.Column>
    </Grid.Row>
  );

Email.propTypes = {
  object: PropTypes.shape().isRequired,
  editable: PropTypes.bool.isRequired,
  onValueChange: PropTypes.func.isRequired,
};

export default Email;

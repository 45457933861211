/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { tableToCSV } from '../../constants/Formats';

class TableWithExport extends React.Component {
  constructor() {
    super();
    this.exportCSV = this.exportCSV.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillMount() {
    if (this.props.getOnClick) {
      this.props.getOnClick(this.exportCSV);
    }
  }

  componentWillUnmount() {
    if (this.props.getOnClick) {
      this.props.getOnClick(null);
    }
  }

  exportCSV() {
    if (this.props) {
      const { headerRow, renderBodyRow, tableData } = this.props;
      return headerRow && renderBodyRow && tableData
        ? tableToCSV(headerRow, renderBodyRow, tableData)
        : '';
    }
    return '';
  }

  render() {
    const { ...allProps } = this.props;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Table {...allProps} />;
  }
}

TableWithExport.propTypes = {
  getOnClick: PropTypes.func.isRequired,
  /* eslint-disable react/forbid-prop-types */
  headerRow: PropTypes.any.isRequired,
  renderBodyRow: PropTypes.any.isRequired,
  tableData: PropTypes.any.isRequired,
  allProps: PropTypes.any.isRequired,
};

export default TableWithExport;

import React from 'react';
import { SolutionName } from '../../constants/Solution';
import { getUserName } from '../../helpers/user';

interface Props {
  solution: SolutionName;
}

export const WelcomeTitle: React.FC<Props> = ({ solution }) => {
  const { firstName } = getUserName() || { firstName: '' };
  let reportTitle = '';

  if (solution === SolutionName.COMPLIANCE) {
    reportTitle = ', to the Compliance report';
  }

  return (
    <div
      style={{
        marginTop: '30px',
        marginLeft: '50px',
      }}
    >
      <span
        style={{
          color: '#9b9b9b',
          fontWeight: 'bold',
          fontSize: '16px',
          lineHeight: '22px',
        }}
      >
        {'WELCOME, '}
        <span
          style={{
            color: '#4a4a4a',
          }}
        >{`${firstName}${reportTitle}`}</span>
      </span>
    </div>
  );
};

import * as actions from '../constants/ActionTypes';
import { config } from './config';

const mixpanel = require('mixpanel-browser');

/* eslint-disable import/prefer-default-export */
export const Events = {
  LOGIN: 'web.event.login',
  LOGOUT: 'web.event.logout',

  DASHBOARD_SELECT_TAB: 'web.screen.dashboard.select.tab',
  DASHBOARD_SEARCH_CLIENT: 'web.event.dashboard.search.client',
  DASHBOARD_SEARCH_CAREGIVER: 'web.event.dashboard.search.caregiver',
  DASHBOARD_SELECT_URGENT_SHIFT: 'web.event.dashboard.select.urgent.shift',
  DASHBOARD_SELECT_SHIFT: 'web.event.dashboard.select.shift',
  DASHBOARD_SELECT_VIEW_ALL: 'web.event.dashboard.select.view.all',

  SHIFTS_SELECT_TAB: 'web.screen.shifts.select.tab',
  SHIFTS_SEARCH: 'web.event.shifts.search',
  SHIFTS_SEARCH_FIELD: 'web.event.shifts.search.field',
  SHIFTS_SEARCH_CAREGIVERS: 'web.event.shifts.search.caregivers',
  SHIFTS_SEARCH_CAREGIVERS_MULTI: 'web.event.shifts.search.caregivers.multi',
  SHIFTS_FILTERS_STATUS: 'web.screen.shifts.filters.status',
  SHIFTS_FILTERS_DATE: 'web.screen.shifts.filters.date',
  SHIFTS_FILTERS_TIME: 'web.screen.shifts.filters.time',
  SHIFTS_FILTERS_CONTACT: 'web.screen.shifts.filters.contact',

  CLIENTS_SELECT_TAB: 'web.screen.clients.select.tab',

  CAREGIVERS_SELECT_TAB: 'web.screen.caregivers.select.tab',
  CAREGIVERS_SEARCH: 'web.event.caregivers.search',
  CAREGIVERS_SEARCH_FIELD: 'web.event.caregivers.search.field',
  CAREGIVERS_SEARCH_LOCATION_DATE: 'web.event.caregivers.search.location.date',

  CAREGIVERS_FILTERS_STATUS: 'web.screen.caregivers.filters.status',
  CAREGIVERS_FILTERS_PREFERENCES: 'web.screen.caregivers.filters.preferences',
  CAREGIVERS_FILTERS_LANGUAGES: 'web.screen.caregivers.filters.languages',
  CAREGIVERS_FILTERS_TRAVEL: 'web.screen.caregivers.filters.travel',
  CAREGIVERS_FILTERS_CONTACT: 'web.screen.caregivers.filters.contact',
  CAREGIVERS_FILTERS_ADVANCED: 'web.screen.caregivers.filters.advanced',

  CAREGIVERS_SHIFTS_CHANGE_TIME: 'web.screen.caregivers.shifts.change.time',
  CAREGIVERS_SHIFTS_SAVE_CHANGES: 'web.screen.caregivers.shifts.save.changes',
  CAREGIVERS_SHIFTS_SELECT_CAREGIVER: 'web.screen.caregivers.shifts.select.caregiver',
  CAREGIVERS_SHIFTS_SELECT_CAREGIVER_ALL: 'web.screen.caregivers.shifts.select.caregiver.all',
  CAREGIVERS_SHIFTS_ASSIGN_CAREGIVER: 'web.event.caregivers.shifts.assign.caregiver',
  CAREGIVERS_SHIFTS_INVITE_CAREGIVER: 'web.event.caregivers.shifts.invite.requested',
  CAREGIVERS_REGISTRATION_INVITE_CAREGIVER: 'web.event.caregivers.registration.invite.requested',
  CAREGIVERS_SHIFTS_RECEIVE_INVITATION: 'web.event.caregivers.shifts.invite.received',
  CAREGIVERS_REGISTRATION_RECEIVE_INVITATION: 'web.event.caregivers.registration.invite.received',

  CAREGIVERS_SHIFTS_FILTERS_STATUS: 'web.screen.caregivers.shifts.filters.status',
  CAREGIVERS_SHIFTS_FILTERS_PREFERENCES: 'web.screen.caregivers.shifts.filters.preferences',
  CAREGIVERS_SHIFTS_FILTERS_LANGUAGES: 'web.screen.caregivers.shifts.filters.languages',
  CAREGIVERS_SHIFTS_FILTERS_TRAVEL: 'web.screen.caregivers.shifts.filters.travel',
  CAREGIVERS_SHIFTS_FILTERS_CONTACT: 'web.screen.caregivers.shifts.filters.contact',
  CAREGIVERS_SHIFTS_FILTERS_ADVANCED: 'web.screen.caregivers.shifts.filters.advanced',
};

class Analytics {
  constructor() {
    if (config.MIXPANEL_API_TOKEN) {
      mixpanel.init(config.MIXPANEL_API_TOKEN);
      this.mixpanelEnabled = true;
    } else {
      this.mixpanelEnabled = false;
    }
  }

  // Analytics "service" is registered as Redux middleware. Following
  // trackReduxAction() function can be used for catching Redux actions and tracking
  // events to analytics services, like Mixpanel. Some events triggers are bind
  // directly in UI components to get more accurate analytics data.
  trackReduxAction(action) {
    // console.log(`Action ${action.type}`);
    switch (action.type) {
      case actions.LOGIN_SUCCESS:
        this.registerUser(action.user);
        this.track(Events.LOGIN);
        break;
      case actions.LOGOUT:
        this.track(Events.LOGOUT);
        this.resetUser();
        break;
      case actions.INPUT_CHANGED:
        switch (action.groupName) {
          case 'shiftFilter':
            switch (action.input.name) {
              case 'shiftStatus':
                this.track(Events.SHIFTS_FILTERS_STATUS);
                break;
              case 'shiftCompinedStatus':
                this.track(Events.SHIFTS_FILTERS_STATUS);
                break;
              case 'primaryContact':
                this.track(Events.SHIFTS_FILTERS_CONTACT);
                break;
              default: // NOP
            }
            break;
          case 'caregiverFilter':
            switch (action.input.name) {
              case 'statusFilter':
                this.track(Events.CAREGIVERS_FILTERS_STATUS);
                break;
              case 'preferenceFilter':
                this.track(Events.CAREGIVERS_FILTERS_PREFERENCES);
                break;
              case 'languages':
                this.track(Events.CAREGIVERS_FILTERS_LANGUAGES);
                break;
              case 'travelTime':
                this.track(Events.CAREGIVERS_FILTERS_TRAVEL);
                break;
              case 'primaryContact':
                this.track(Events.CAREGIVERS_FILTERS_CONTACT);
                break;
              case 'advancedFilter':
                this.track(Events.CAREGIVERS_FILTERS_ADVANCED);
                break;
              default: // NOP
            }
            break;
          default: // NOP
        }
        break;
      case actions.UI_STATE_CHANGED:
        switch (action.groupName) {
          case 'shiftFilter':
            switch (action.stateName) {
              case 'startDate':
                this.track(Events.SHIFTS_FILTERS_DATE);
                break;
              case 'endDate':
                this.track(Events.SHIFTS_FILTERS_DATE);
                break;
              case 'timeRange':
                this.track(Events.SHIFTS_FILTERS_TIME);
                break;
              default: // NOP
            }
            break;
          default: // NOP
        }
        break;
      case actions.CAREGIVERS_CHECKBOX_CLICKED:
        this.track(Events.CAREGIVERS_SHIFTS_SELECT_CAREGIVER);
        break;
      case actions.CAREGIVERS_SELECT_ALL_CLICKED:
        this.track(Events.CAREGIVERS_SHIFTS_SELECT_CAREGIVER_ALL);
        break;
      case actions.UPDATE_SHIFT_TIMING:
        this.track(Events.CAREGIVERS_SHIFTS_CHANGE_TIME);
        break;
      case actions.UPDATE_SHIFT_SUCCESS:
        this.track(Events.CAREGIVERS_SHIFTS_SAVE_CHANGES);
        break;
      default: // NOP
    }
  }

  registerUser(user) {
    if (this.mixpanelEnabled) {
      mixpanel.identify(user.id);
      mixpanel.people.set({
        $email: user.username,
        $first_name: user.firstName,
        $last_name: user.lastName,
      });
    }
  }

  resetUser() {
    if (this.mixpanelEnabled) mixpanel.reset();
  }

  track(event) {
    // console.log(`Track ${event}`);
    if (this.mixpanelEnabled) mixpanel.track(event);
  }

  trackForCaregiver(caregiver, event) {
    if (this.mixpanelEnabled) {
      if (mixpanel.caregiver == null) {
        mixpanel.init(config.MIXPANEL_API_TOKEN, {}, 'caregiver');
      }
      mixpanel.caregiver.identify(caregiver.id);
      mixpanel.caregiver.people.set({
        $email: caregiver.email,
        $first_name: caregiver.firstName,
        $last_name: caregiver.lastName,
      });

      mixpanel.caregiver.track(event);
    }
  }
}

/* eslint-disable import/prefer-default-export */
export const analytics = new Analytics();

/* eslint-disable import/prefer-default-export */
export const analyticsMiddleware = () => next => action => {
  try {
    analytics.trackReduxAction(action);
  } catch (e) {
    // Ignore error
  }

  return next(action);
};

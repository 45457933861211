import React from 'react';
import styled from 'styled-components';
import { Blocks } from '../compliance/ComplianceMedicalTypes';

interface IProps {
  discipline: string;
  notComplete: number[];
  pending: number[];
  expired: number[];
  blocks: Blocks[];
}

interface IProgressBarProps {
  completed: number;
  pending: number;
  expired: number;
}

const BarContainer = styled.div`
  width: 100px;
  height: 10px;
  background-color: #e3e3e3;
  border-radius: 25px;
  overflow: hidden;
`;

const ProgressBar = styled.div<IProgressBarProps>`
  width: 100%;
  height: 10px;
  box-shadow: ${(props: IProgressBarProps) =>
    `${props.completed}px 0 #69db8f inset, ${props.pending}px 0 #f5a623 inset, ${props.expired}px 0 #f16778 inset`};
`;

const ComplianceCompletionBar: React.FC<IProps> = ({
  discipline,
  notComplete,
  pending,
  expired,
  blocks = [],
}) => {
  const activeBlocks = blocks.filter(b => {
    const caregiverDisciplines = discipline?.split(',') || [];
    const includedIndiscipline = b.disciplines?.length
      ? b.disciplines.find(bd => caregiverDisciplines.includes(bd))
      : true;
    return b.active && !b.schedulable && b.required && includedIndiscipline;
  });
  const percentageCompleted = (track: number) => {
    let percent = (track * 100) / activeBlocks.length;
    if (activeBlocks.length > 100 && percent < 1) percent *= 10;
    return Math.floor(percent);
  };
  const completed = activeBlocks.length - notComplete.length - pending.length;

  const completedSpace = percentageCompleted(completed);
  const pendingSpace = completedSpace + percentageCompleted(pending.length);
  const expiredSpace = pendingSpace + percentageCompleted(expired.length);

  return (
    <BarContainer>
      <ProgressBar completed={completedSpace} pending={pendingSpace} expired={expiredSpace} />
    </BarContainer>
  );
};

export default ComplianceCompletionBar;

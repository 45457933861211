import { useSelector } from 'react-redux';

interface State {
  principal: {
    user: {
      firstName: string;
      lastName: string;
    };
  };
}

export const getUserName = (): {
  firstName: string | null;
  lastName: string | null;
} => {
  const {
    user: { firstName, lastName },
  } = useSelector((state: State) => state?.principal) || { user: {} };

  return { firstName, lastName };
};

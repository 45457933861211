/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import '../../Common.css';

const TileGrid = ({ children, tileSize, type }) => {
  let inputArray = [];
  const isClient = type === 'client';
  const isCaregiver = type === 'caregiver';

  if (Array.isArray(children)) {
    inputArray = children;
  } else {
    inputArray = [children];
  }
  const tiles = inputArray.filter(c => c != null && c !== false);

  const leftTiles = [];
  const rightTiles = [];
  tiles.forEach((t, i) => {
    if (isClient) {
      if (i % 2 === 1) {
        leftTiles.push(t);
      } else {
        rightTiles.push(t);
      }
    } else if (isCaregiver) {
      if (i === 0) {
        leftTiles.push(t);
      } else if (i > 0 && i < 4) {
        rightTiles.push(t);
      } else if (i % 2 === 0) {
        leftTiles.push(t);
      } else {
        rightTiles.push(t);
      }
    } else if (i % 2 === 0) {
      leftTiles.push(t);
    } else {
      rightTiles.push(t);
    }
  });

  return (
    <Grid columns={2} style={{ width: '100%' }} doubling>
      <Grid.Column id='TileGrid-column-left' width={8} style={{ flex: tileSize }}>
        {leftTiles.map((l, index) => (
          <React.Fragment key={`tileLeft${index}`}>
            <Grid.Row>{l}</Grid.Row>
            <br />
          </React.Fragment>
        ))}
      </Grid.Column>
      <Grid.Column id='TileGrid-column-right' width={8} style={{ flex: tileSize }}>
        {rightTiles.map((l, index) => (
          <React.Fragment key={`tileRight${index}`}>
            <Grid.Row>{l}</Grid.Row>
            <br />
          </React.Fragment>
        ))}
      </Grid.Column>
    </Grid>
  );
};

TileGrid.defaultProps = {
  tileSize: '0 0 40rem',
};

TileGrid.propTypes = {
  tileSize: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
};

export default TileGrid;

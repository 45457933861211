import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { ICaregiver } from '../../../../reducers/types/caregiver';
import {
  formatTime,
  formatPhoneNumber,
  SHORT_DATE,
  MOMENT_HOURS_24,
} from '../../../../constants/Formats';
import HRCaregiverNameCell from '../HRCaregiverNameCell';
import { LinkButton } from '../../../../styles/common';
import ComplianceCompletionBar from '../ComplianceCompletionBar';
import { Blocks } from '../../compliance/ComplianceMedicalTypes';
import { CompletionViewContainer, ViewButton } from './styles';
import { CheckBox } from '../../../../styles/commonStyled';
import hrHelper from '../../../../helpers/hrHelper';

interface IProps {
  tableType: 'candidates' | 'caregivers';
  completed: boolean;
  vaccine: boolean;
  data: ICaregiver[];
  blocks: Blocks[];
  selectedCandidates?: number[];
  viewCompliance?: (caregiver?: ICaregiver) => void;
  onCheckClick?: (id?: number) => void;
}

const BodyRows: React.FC<IProps> = ({
  completed,
  vaccine,
  tableType,
  data,
  viewCompliance = () => null,
  onCheckClick = () => null,
  blocks = [],
  selectedCandidates = [],
}) => {
  const history = useHistory();
  const location = useLocation();

  const activeBlocks = blocks.filter(b => b.active && !b.schedulable && b.required);

  const timestampColumn = (timestamp?: string) => (
    <span>
      <span className='infoTextBold'>{formatTime(timestamp, null, SHORT_DATE)}</span>{' '}
      {formatTime(timestamp, null, MOMENT_HOURS_24)}
    </span>
  );

  const onValidateClick = (person: ICaregiver) => {
    return history.push(`/home/hr/caregivers/records/${person.id}/compliance`, {
      prevPath: location,
      medical: true,
    });
  };

  const vaccinationCell = (person: ICaregiver) => {
    const [covidVaccination] = person.vaccinations.filter(v => v.type === 'COVID');
    let covidContent;
    if (covidVaccination?.validationStatus === 'completed') {
      covidContent = <p className='green-complete-text'>Complete</p>;
    } else if (
      covidVaccination?.validationStatus === 'rejected' ||
      (covidVaccination?.validationStatus === 'pending' && covidVaccination?.validationRound === 1)
    ) {
      covidContent = (
        <p className='black-status-text'>
          Awaiting
          <br />
          upload
        </p>
      );
    } else if (
      covidVaccination?.validationRound === 2 &&
      covidVaccination?.validationStatus === 'pending'
    ) {
      covidContent = (
        <p className='black-status-text'>
          1st vacc.
          <br />
          completed
        </p>
      );
    } else if (covidVaccination?.validationStatus === 'declined') {
      covidContent = <p className='black-status-text'>Declined</p>;
    } else if (covidVaccination?.validationStatus === 'validating') {
      covidContent = <LinkButton onClick={() => onValidateClick(person)}>Validate</LinkButton>;
    }
    return covidContent;
  };

  const completionPercentage = (caregiver: ICaregiver) => {
    return Math.floor(
      ((activeBlocks.filter(b => {
        const includedIndiscipline = b.disciplines?.length
          ? b.disciplines.includes(caregiver.discipline)
          : true;
        return b.active && !b.schedulable && b.required && includedIndiscipline;
      }).length -
        (caregiver.nonCompliantBlocks?.length || 0)) *
        100) /
        activeBlocks.length
    );
  };

  return (
    <>
      {data.map(d => {
        const handleView = () => {
          viewCompliance(d);
        };
        const handleCheckClick = () => {
          onCheckClick(d.id);
        };
        const phone = d.phoneNumber ? formatPhoneNumber(d.phoneNumber) : '-';
        const hasRequiredExpired = hrHelper.checkForRequiredExpiredOrPending(
          blocks,
          d.expiredBlocks
        );
        const hasRequiredPending = hrHelper.checkForRequiredExpiredOrPending(
          blocks,
          d.aboutToExpireBlocks
        );
        return (
          <Table.Row key={d.id}>
            <Table.Cell key='name'>
              <HRCaregiverNameCell caregiver={d} sourcePath={tableType} />
            </Table.Cell>
            <Table.Cell key='id' verticalAlign='middle' textAlign='center'>
              <p>{d.id}</p>
            </Table.Cell>
            {vaccine && (
              <Table.Cell key='COVID' verticalAlign='middle' textAlign='center'>
                {vaccinationCell(d)}
              </Table.Cell>
            )}
            <Table.Cell key='discipline' verticalAlign='middle' textAlign='center'>
              <p>{d.discipline || ''}</p>
            </Table.Cell>
            <Table.Cell key='phone' verticalAlign='middle' textAlign='center'>
              <p>{phone}</p>
            </Table.Cell>
            <Table.Cell key='date-of-added' verticalAlign='middle' textAlign='center'>
              {timestampColumn(d?.created)}
            </Table.Cell>
            <Table.Cell key='last-updated' verticalAlign='middle' textAlign='center'>
              {timestampColumn(d?.lastModified)}
            </Table.Cell>
            {tableType === 'candidates' && completed ? (
              <Table.Cell key='selection' verticalAlign='middle' textAlign='center'>
                <CheckBox
                  small
                  checked={selectedCandidates.includes(d.id)}
                  onClick={handleCheckClick}
                />
              </Table.Cell>
            ) : (
              <Table.Cell key='completion-status' verticalAlign='middle' textAlign='center'>
                <CompletionViewContainer>
                  <ComplianceCompletionBar
                    discipline={d.discipline}
                    notComplete={d.nonCompliantBlocks || []}
                    pending={d.aboutToExpireBlocks?.map(b => b.id) || []}
                    expired={d.expiredBlocks?.map(b => b.id) || []}
                    blocks={blocks}
                  />
                  <p>{`${completionPercentage(d)} %`}</p>
                  <ViewButton
                    expired={hasRequiredExpired}
                    pending={hasRequiredPending}
                    onClick={handleView}
                  >
                    View
                    <span />
                  </ViewButton>
                </CompletionViewContainer>
              </Table.Cell>
            )}
          </Table.Row>
        );
      })}
    </>
  );
};

export default BodyRows;

import {
  GET_CONVERSATIONS_REQUEST,
  GET_CONVERSATIONS_SUCCESS,
  GET_CONVERSATIONS_FAILURE,
} from '../constants/ActionTypes';

const initialState = {
  events: [],
  loading: false,
  partialUpdate: false,
};

function chatLog(state = initialState, action) {
  switch (action.type) {
    case GET_CONVERSATIONS_REQUEST:
      return { ...state, loading: true, partialUpdate: action.partialUpdate };
    case GET_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.partialUpdate
          ? state.events.concat(action.conversations.results.conversation)
          : action.conversations.results.conversation,
        nextPage: action.conversations.results.nextPage,
        partialUpdate: action.partialUpdate,
      };
    case GET_CONVERSATIONS_FAILURE:
      return { ...state, loading: false, events: [], partialUpdate: action.partialUpdate };
    default:
      return state;
  }
}

export default chatLog;

/* eslint-disable import/prefer-default-export */
import * as types from '../constants/ActionTypes';
import { backend } from '../services';
import { history } from '../helpers';
// eslint-disable-next-line import/no-cycle
import { alertActions } from './alert';

const WEB_T_AND_C = ['sms'];

function login(username, password, location, agencyId) {
  return dispatch => {
    dispatch(request());

    backend.login(username, password, agencyId).then(
      async res => {
        if (res.agencies) {
          dispatch(successAgencies({ agencies: res.agencies }));
        } else {
          const terms = await backend.getTerms();
          const pendingTerm = terms?.terms?.length
            ? terms.terms.find(t => WEB_T_AND_C.includes(t.feature) && t.status === 'pending')
            : null;
          if (pendingTerm) {
            dispatch(pendingTerms(pendingTerm));
          } else {
            const settings = await backend.getAgencySettings(true);
            Object.assign(res, settings);
            dispatch(success(res));
            if (location) {
              history.push(location);
            } else {
              history.push('/');
            }
          }
        }
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.LOGIN_REQUEST };
  }
  function pendingTerms(pendingTerm) {
    return { type: types.TERMS_PENDING, pendingTerm };
  }
  function success({ user, settings, roles }) {
    return { type: types.LOGIN_SUCCESS, user, settings, roles };
  }
  function successAgencies({ agencies }) {
    return { type: types.LOGIN_SUCCESS_AGENCIES, agencies };
  }
  function failure(error) {
    return { type: types.LOGIN_FAILURE, error };
  }
}

function magicLogin(token, dob, location, preLoginData, preLoginSet = false) {
  return async dispatch => {
    dispatch(request());

    if (preLoginSet) {
      const terms = await backend.getTerms();
      const pendingTerm = terms?.terms?.length
        ? terms.terms.find(t => WEB_T_AND_C.includes(t.feature) && t.status === 'pending')
        : null;
      if (pendingTerm) {
        dispatch(pendingTerms(pendingTerm));
      }
      dispatch(success(preLoginData));
      if (location) {
        return history.push(location);
      }
      return history.push('/');
    }

    return backend.loginWithToken(token, dob).then(
      async user => {
        const terms = await backend.getTerms();
        const pendingTerm = terms?.terms?.length
          ? terms.terms.find(t => WEB_T_AND_C.includes(t.feature) && t.status === 'pending')
          : null;
        if (pendingTerm) {
          dispatch(pendingTerms(pendingTerm));
          dispatch(preLogin(user));
        } else {
          dispatch(success(user));
          if (location) {
            history.push(location);
          } else {
            history.push('/');
          }
        }
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.LOGIN_REQUEST };
  }
  function pendingTerms(pendingTerm) {
    return { type: types.TERMS_PENDING, pendingTerm };
  }
  function preLogin({ user, settings, roles }) {
    return { type: types.PRE_LOGIN, user, settings, roles };
  }
  function success({ user, settings, roles }) {
    return { type: types.LOGIN_SUCCESS, user, settings, roles };
  }
  function failure(error) {
    return { type: types.LOGIN_FAILURE, error };
  }
}

function signupV2(token, dob, preLoginData, preLoginSet = false) {
  return async dispatch => {
    dispatch(request());

    if (preLoginSet) {
      const terms = await backend.getTerms();
      const pendingTerm = terms?.terms?.length
        ? terms.terms.find(t => WEB_T_AND_C.includes(t.feature) && t.status === 'pending')
        : null;
      if (pendingTerm) {
        dispatch(pendingTerms(pendingTerm));
      }
      dispatch(success(preLoginData));
      return history.push('/');
    }

    return backend.signupV2(token, dob).then(
      async user => {
        const terms = await backend.getTerms();
        const pendingTerm = terms?.terms?.length
          ? terms.terms.find(t => WEB_T_AND_C.includes(t.feature) && t.status === 'pending')
          : null;
        if (pendingTerm) {
          dispatch(pendingTerms(pendingTerm));
          dispatch(preLogin(user));
        } else {
          dispatch(success(user));
          history.push('/');
        }
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.LOGIN_REQUEST };
  }
  function success({ user, settings, roles }) {
    return { type: types.LOGIN_SUCCESS, user, settings, roles };
  }
  function pendingTerms(pendingTerm) {
    return { type: types.TERMS_PENDING, pendingTerm };
  }
  function preLogin({ user, settings, roles }) {
    return { type: types.PRE_LOGIN, user, settings, roles };
  }
  function failure(error) {
    return { type: types.LOGIN_FAILURE, error };
  }
}

function getMagicLogin(email, callback) {
  return dispatch => {
    dispatch(request());

    backend.getMagicLogin(email).then(
      () => {
        if (callback) callback();
        dispatch(success());
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_MAGIC_LOGIN_REQUEST };
  }
  function success() {
    return { type: types.GET_MAGIC_LOGIN_SUCCESS };
  }
  function failure(error) {
    return { type: types.GET_MAGIC_LOGIN_FAILURE, error };
  }
}

function signup(email, password, invitationToken) {
  return dispatch => {
    dispatch(request());

    backend.signup(email, password, invitationToken).then(
      async user => {
        const terms = await backend.getTerms();
        const pendingTerm = terms?.terms
          ? terms.terms.find(t => WEB_T_AND_C.includes(t.feature) && t.status === 'pending')
          : null;
        if (pendingTerm) {
          dispatch(pendingTerms(pendingTerm));
          dispatch(preLogin(user));
        } else {
          dispatch(success(user));
          history.push('/');
        }
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error, alertActions.SIGNUP_ERROR));
      }
    );
  };

  function request() {
    return { type: types.SIGNUP_REQUEST };
  }
  function success({ user, settings, roles }) {
    return { type: types.SIGNUP_SUCCESS, user, settings, roles };
  }
  function pendingTerms(pendingTerm) {
    return { type: types.TERMS_PENDING, pendingTerm };
  }
  function preLogin({ user, settings, roles }) {
    return { type: types.PRE_LOGIN, user, settings, roles };
  }
  function failure(error) {
    return { type: types.SIGNUP_FAILURE, error };
  }
}

function updatePassword(email, password, recoveryToken) {
  return dispatch => {
    dispatch(request());

    backend.updatePassword(email, password, recoveryToken).then(
      user => {
        dispatch(success(user));
        dispatch(alertActions.clearErrors());
        history.push('/');
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error, alertActions.UPDATE_PASSWORD_ERROR));
      }
    );
  };

  function request() {
    return { type: types.UPDATE_PASSWORD_REQUEST };
  }
  function success({ user, settings, roles }) {
    return { type: types.UPDATE_PASSWORD_SUCCESS, user, settings, roles };
  }
  function failure(error) {
    return { type: types.UPDATE_PASSWORD_FAILURE, error };
  }
}

function recover(email, callback) {
  return dispatch => {
    dispatch(request());

    backend.recover(email).then(
      response => {
        if (callback) callback();
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.RECOVER_REQUEST };
  }
  function success(response) {
    return { type: types.RECOVER_SUCCESS, response };
  }
  function failure(error) {
    return { type: types.RECOVER_FAILURE, error };
  }
}

function getDisciplines() {
  return dispatch => {
    dispatch(request());

    backend.getDisciplines().then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_DISCIPLINES_REQUEST };
  }
  function success(result) {
    return { type: types.GET_DISCIPLINES_SUCCESS, result };
  }
  function failure(error) {
    return { type: types.GET_DISCIPLINES_FAILURE, error };
  }
}

function getLanguages() {
  return dispatch => {
    dispatch(request());

    backend.getLanguages().then(
      user => {
        dispatch(success(user));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_LANGUAGES_REQUEST };
  }
  function success(languages) {
    return { type: types.GET_LANGUAGES_SUCCESS, languages };
  }
  function failure(error) {
    return { type: types.GET_LANGUAGES_FAILURE, error };
  }
}

function getAttributes() {
  return dispatch => {
    dispatch(request());

    backend.getAttributes().then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_ATTRIBUTES_REQUEST };
  }
  function success(results) {
    return { type: types.GET_ATTRIBUTES_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_ATTRIBUTES_FAILURE, error };
  }
}

function getAgencies() {
  return dispatch => {
    dispatch(request());

    backend.getAgencies().then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_AGENCIES_REQUEST };
  }
  function success(results) {
    return { type: types.GET_AGENCIES_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_AGENCIES_FAILURE, error };
  }
}

function switchAgency(attributes) {
  return dispatch => {
    dispatch(request());

    backend.switchAgency(attributes).then(
      async results => {
        const settings = await backend.getAgencySettings(true);
        Object.assign(results, settings);
        dispatch(success(results));

        // Hard refresh for the app
        window.location.href = '/';
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.SWITCH_AGENCY_REQUEST };
  }
  function success({ user, settings, roles }) {
    return { type: types.SWITCH_AGENCY_SUCCESS, user, settings, roles };
  }
  function failure(error) {
    return { type: types.SWITCH_AGENCY_FAILURE, error };
  }
}

function getUserProfile() {
  return dispatch => {
    dispatch(request());

    backend.getUserProfile().then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_USER_PROFILE_REQUEST };
  }
  function success(results) {
    return { type: types.GET_USER_PROFILE_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_USER_PROFILE_FAILURE, error };
  }
}

function getUserSummary() {
  return dispatch => {
    dispatch(request());

    backend.getUserSummary().then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_USER_SUMMARY_REQUEST };
  }
  function success(results) {
    return { type: types.GET_USER_SUMMARY_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_USER_SUMMARY_FAILURE, error };
  }
}

const updateAttributes = attributes => {
  return dispatch => {
    dispatch(request());
    backend.updateAttributes(attributes).then(
      results => {
        dispatch(success(results));
        dispatch(alertActions.notification('System settings saved'));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.UPDATE_ATTRIBUTES_REQUEST };
  }
  function success(results) {
    return { type: types.UPDATE_ATTRIBUTES_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.UPDATE_ATTRIBUTES_FAILURE, error };
  }
};

function logout() {
  return async dispatch => {
    dispatch({ type: types.LOGOUT });
    dispatch({ type: types.CLEAR_ALL });
    if (backend.isLoggedIn()) {
      history.push('/');
      await backend.logout();
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(names => {
          names.forEach(name => {
            caches.delete(name);
          });
        });
      }
      window.location.reload(true);
    }
  };
}

function unauthorized() {
  return dispatch => {
    dispatch({ type: types.UNAUTHORIZED });
    dispatch({ type: types.CLEAR_ALL });
  };
}

export const userActions = {
  login,
  getMagicLogin,
  signup,
  signupV2,
  recover,
  updatePassword,
  getDisciplines,
  getLanguages,
  getAttributes,
  updateAttributes,
  logout,
  unauthorized,
  magicLogin,
  getUserProfile,
  getUserSummary,
  getAgencies,
  switchAgency,
};

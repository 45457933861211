import React from 'react';
import PropTypes from 'prop-types';
import { Table, Icon, Form, Popup } from 'semantic-ui-react';
import '../TrainPref.css';

const ProfileFieldTable = ({ data, onUpdate, onRemove }) => {
  const header = [
    { id: 'profile-field-table-header-name', key: 'name', width: '3', content: 'Name' },
    {
      id: 'profile-field-table-header-visibleInApp',
      key: 'visibleInApp',
      width: '1',
      content: 'Visible in App',
    },
    {
      id: 'profile-field-table-header-editableInApp',
      key: 'editableInApp',
      width: '1',
      content: 'Editable in App',
    },
    {
      id: 'profile-field-table-header-notifyOnChange',
      key: 'notifyOnChange',
      width: '1',
      content: 'Notify on Change',
    },
    { id: 'profile-field-table-header-remove', key: 'remove', width: '1', content: '' },
  ];

  const itemClass = item => {
    const classes = [];
    if (!item.removed && (item.created || item.updated)) {
      classes.push('table-item-updated');
    } else if (item.removed) {
      classes.push('table-item-removed');
    } else if (!item.global) classes.push('table-item-custom');

    if (!item.active) classes.push('table-item-inactive');

    return classes.join(' ');
  };

  return (
    <Table
      id='systemSettingsPreferencesTable'
      tableData={data}
      headerRow={header}
      renderBodyRow={x => ({
        className: itemClass(x),
        key: x.id,
        cells: [
          { key: 'name', content: x.name },
          {
            key: 'visibleInApp',
            content: (
              <Form.Checkbox
                className='round'
                defaultChecked={x.visibleInApp}
                onChange={(_, { checked }) => onUpdate(x.id, 'visibleInApp', checked)}
              />
            ),
          },
          {
            key: 'editableInApp',
            content:
              x.editable && x.visibleInApp ? (
                <Form.Checkbox
                  className='round'
                  defaultChecked={x.editableInApp}
                  onChange={(_, { checked }) => onUpdate(x.id, 'editableInApp', checked)}
                />
              ) : (
                '--'
              ),
          },
          {
            key: 'notifyOnChange',
            content:
              x.editable && x.visibleInApp && x.editableInApp ? (
                <Form.Checkbox
                  toggle
                  defaultChecked={x.notifyOnChange}
                  onChange={(_, { checked }) => onUpdate(x.id, 'notifyOnChange', checked)}
                />
              ) : (
                '--'
              ),
          },
          {
            key: 'remove',
            content: (
              <div>
                {!x.global ? (
                  <Icon
                    id='profile-field-table-remove-icon'
                    link
                    size='large'
                    name='trash'
                    onClick={() => onRemove(x.id, x.deleted)}
                  />
                ) : (
                  ''
                )}
                {x.created || x.updated || x.removed ? (
                  <Popup
                    trigger={<Icon name='question circle outline' />}
                    content='Updates have not been saved'
                    on='hover'
                    position='top left'
                    popperModifiers={{
                      preventOverflow: {
                        boundariesElement: 'offsetParent',
                      },
                    }}
                  />
                ) : (
                  ''
                )}
              </div>
            ),
          },
        ],
      })}
    />
  );
};

ProfileFieldTable.propTypes = {
  data: PropTypes.shape.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default ProfileFieldTable;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react';
import { Image, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as CompHelper from './complianceHelpers';
import * as compTypes from './ComplianceMedicalTypes';
import { caregiverActions } from '../../../actions';
import CGComplianceUIActions from '../../../actions/CGComplianceUIActions';

type Props = {
  blocks: Array<Block>;
  caregiverComplianceUI: any;
  caregiverCompliance: any;
  dispatch: (arg: any) => void;
  caregiver: any;
};

type State = {
  closeEnabled: boolean;
  showTestEditPanel: boolean;
  showVaccinationEditPanel: boolean;
  draftState: DraftState;
};

type DraftState = {
  testDate?: compTypes.DraftStateField;
  testResult?: compTypes.DraftStateField;
  testReading?: compTypes.DraftStateField;
  firstDose?: compTypes.DraftStateField;
  secondDose?: compTypes.DraftStateField;
};

interface Block extends compTypes.Blocks {
  name: 'Measles (Rubeola) Test' | 'Rubeola Vaccination';
}

interface MeaslesBlocks {
  [key: string]: Block;
  measlesTest: Block;
  measlesRubeolaVaccination: Block;
}

type BlockField = compTypes.BlockField;

class ComplianceMeaslesTest extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showTestEditPanel: false,
      showVaccinationEditPanel: false,
      closeEnabled: false,
      draftState: {
        testDate: undefined,
        testResult: undefined,
        testReading: undefined,
        firstDose: undefined,
        secondDose: undefined,
      },
    };
  }

  public componentDidMount = () => {
    this.setFieldsToState();
  };

  private readonly setFieldsToState = (): void => {
    const { blocks } = this.props;
    const [measlesTest] = blocks.filter((b: Block) => b.name === 'Measles (Rubeola) Test');
    const [measlesRubeolaVaccination] = blocks.filter(
      (b: Block) => b.name === 'Rubeola Vaccination'
    );

    const [testDate] = measlesTest.fields.filter((f: BlockField) => f.type === 'date');
    const [testResult] = measlesTest.fields.filter((f: BlockField) => f.type === 'text');
    const [testReading] = measlesTest.fields.filter((f: BlockField) => f.type === 'double');
    const [firstDose] = measlesRubeolaVaccination.fields.filter(
      (f: BlockField) => f.name === '1stDose'
    );
    const [secondDose] = measlesRubeolaVaccination.fields.filter(
      (f: BlockField) => f.name === 'Completion'
    );

    this.setState(s => ({
      ...s,
      draftState: {
        ...s.draftState,
        testDate: {
          value: testDate.value ? moment(testDate.value) : '',
          id: testDate.id,
        },
        testResult: {
          value: testResult.value || '',
          id: testResult.id,
        },
        testReading: {
          value: testReading.value || '',
          id: testReading.id,
        },
        firstDose: firstDose
          ? {
              value: firstDose.value ? moment(firstDose.value) : '',
              id: firstDose.id,
            }
          : undefined,
        secondDose: {
          value: secondDose.value ? moment(secondDose.value) : '',
          id: secondDose.id,
        },
      },
    }));
  };

  private readonly addTestCompletion = (): void => {
    const { showTestEditPanel } = this.state;
    this.setState(s => ({
      ...s,
      showTestEditPanel: !showTestEditPanel,
      closeEnabled: true,
    }));
  };

  private readonly addVaccinationCompletion = (): void => {
    const { showVaccinationEditPanel } = this.state;
    this.setState(s => ({
      ...s,
      showVaccinationEditPanel: !showVaccinationEditPanel,
      closeEnabled: true,
    }));
  };

  private readonly onClose = (): void => {
    const { dispatch } = this.props;
    this.setFieldsToState();
    this.setState(
      s => ({
        ...s,
        closeEnabled: false,
        showTestEditPanel: false,
        showVaccinationEditPanel: false,
      }),
      () => dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: [] }))
    );
  };

  private readonly checkDoubleValid = (field: BlockField, value: any) => {
    const { caregiverComplianceUI, dispatch } = this.props;
    // eslint-disable-next-line no-restricted-globals
    const isNumber = !isNaN(value);
    const tooLong = value.length > 6;
    const notValid = caregiverComplianceUI && caregiverComplianceUI.notValidValuesIds;
    if ((!isNumber || tooLong) && !notValid.includes(field.id)) {
      notValid.push(field.id);
    }
    if (isNumber && !tooLong && notValid.includes(field.id)) {
      const index = notValid.indexOf(field.id);
      notValid.splice(index, 1);
    }

    return dispatch(
      CGComplianceUIActions.updateState({
        isNotValid: !!notValid.length,
        notValidValuesIds: [...notValid],
      })
    );
  };

  private readonly onFieldChanged = (field: BlockField, value: any) => {
    const { caregiverComplianceUI, dispatch } = this.props;
    const { dirtyMedicalBlocks } = caregiverComplianceUI;

    let changedValue = value;

    if (field.type === 'double') {
      this.checkDoubleValid(field, value);
      if (value.length === 0) {
        changedValue = null;
      }
    }

    let theField = dirtyMedicalBlocks.find((f: BlockField) => f.id === field.id);

    if (!theField) {
      theField = JSON.parse(JSON.stringify(field));
      dirtyMedicalBlocks.push(theField);
    }
    theField.value = changedValue;

    if (field.type === 'date') {
      this.onDateChange(value, field.id);
    }

    if (field.type === 'text') {
      this.setState(s => ({
        ...s,
        draftState: { ...s.draftState, testResult: { value, id: field.id } },
      }));
    }

    if (field.type === 'double') {
      this.setState(s => ({
        ...s,
        draftState: { ...s.draftState, testReading: { value, id: field.id } },
      }));
    }

    dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks }));
  };

  private readonly onDateChange = (date: any, selected: number): void => {
    const { draftState } = this.state;
    let valueToSet: string;
    let selectedId: number;
    /* eslint-disable-next-line no-restricted-syntax */
    for (const [key, value] of Object.entries(draftState)) {
      if (value?.id === selected) {
        valueToSet = key;
        selectedId = value.id;
      }
    }

    this.setState(s => ({
      ...s,
      draftState: {
        ...s.draftState,
        [valueToSet]: {
          value: date && moment(date).isBefore(moment()) ? moment(date) : '',
          id: selectedId,
        },
      },
    }));
  };

  private readonly updateMedicalBlock = () => {
    const { caregiver, blocks, caregiverComplianceUI, dispatch } = this.props;
    const { dirtyMedicalBlocks } = caregiverComplianceUI;

    if (Object.keys(dirtyMedicalBlocks).length) {
      const dirtyFieldsToSave: Array<BlockField> = [];
      dirtyMedicalBlocks.forEach((s: BlockField) => {
        const [block] = blocks.filter((a: Block) =>
          a.fields.find((f: BlockField) => f.id === s.id)
        );
        const b = block?.fields.find((a: BlockField) => a.id === s.id);
        if (b && ((b.value && b.value !== s.value) || (!b.value && s.value))) {
          dirtyFieldsToSave.push(s);
        }
      });

      if (dirtyFieldsToSave.length) {
        dispatch(
          caregiverActions.setCaregiverComplianceBlockFields(caregiver.id, dirtyFieldsToSave)
        );
        dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: [] }));
      }
    }

    this.setState(s => ({
      ...s,
      closeEnabled: false,
      showTestEditPanel: false,
      showVaccinationEditPanel: false,
    }));
  };

  private readonly onRemove = () => {
    const dirtyFieldToClear: Array<BlockField> = [];
    const { caregiver, dispatch, blocks } = this.props;
    blocks.forEach((block: Block) =>
      block.fields.forEach(f => {
        dirtyFieldToClear.push({
          id: f.id,
          name: f.name,
          type: f.type,
          fixed: 1,
          value: null,
          isDirty: true,
        });
      })
    );
    dispatch(caregiverActions.setCaregiverComplianceBlockFields(caregiver.id, dirtyFieldToClear));

    this.setState(
      s => ({
        ...s,
        draftState: {
          ...s.draftState,
          testDate: {
            ...s.draftState.testDate,
            value: '',
          },
          testResult: {
            ...s.draftState.testResult,
            value: '',
          },
          testReading: {
            ...s.draftState.testReading,
            value: '',
          },
          firstDose: s.draftState.firstDose
            ? {
                ...s.draftState.firstDose,
                value: '',
              }
            : undefined,
          secondDose: {
            ...s.draftState.secondDose,
            value: '',
          },
        },
        showResultEditPanel: false,
        closeEnabled: false,
      }),
      () => dispatch(CGComplianceUIActions.updateState({ dirtyMedicalBlocks: [] }))
    );
  };

  public render(): JSX.Element {
    const { blocks, caregiverComplianceUI } = this.props;
    const { showTestEditPanel, showVaccinationEditPanel, closeEnabled, draftState } = this.state;

    const { testDate, testResult, testReading, firstDose, secondDose } = draftState;

    const { dirtyMedicalBlocks } = caregiverComplianceUI;

    const [measlesTest] = blocks.filter((b: Block) => b.name === 'Measles (Rubeola) Test');
    const [measlesRubeolaVaccination] = blocks.filter(
      (b: Block) => b.name === 'Rubeola Vaccination'
    );

    const [testDateField] = measlesTest?.fields.length
      ? measlesTest.fields.filter((f: BlockField) => f.type === 'date')
      : [];
    const [testResultField] = measlesTest?.fields.length
      ? measlesTest.fields.filter((f: BlockField) => f.type === 'text')
      : [];
    const [testReadingField] = measlesTest?.fields.length
      ? measlesTest.fields.filter((f: BlockField) => f.type === 'double')
      : [];
    const [firstDoseField] = measlesRubeolaVaccination.fields.filter(
      (f: BlockField) => f.name === '1stDose'
    );
    const [secondDoseField] = measlesRubeolaVaccination.fields.filter(
      (f: BlockField) => f.name === 'Completion'
    );

    const { birthday } = measlesTest;

    const MeaslesBlocks: MeaslesBlocks = {
      measlesTest,
      measlesRubeolaVaccination,
    };

    const RequiredByBirthday = moment(birthday).isAfter(moment('1957-01-01').toISOString());
    const notRequired = measlesTest.completed;

    const testResultCompleted = !!testResult?.value;
    const vaccinationDateCompleted = !!secondDose?.value;

    const testResultPass = testResultField?.value === 'I';
    const testResultFails = testResultField?.value === 'NI';

    const showTestInfoPanel = (testResultCompleted && !showTestEditPanel) || testResultFails;

    const showVaccinationPanel =
      (!!firstDoseField?.value || !!secondDoseField?.value) && !showVaccinationEditPanel;

    const showRemoveButton = showTestInfoPanel || showVaccinationPanel;

    const showCompletion = !showVaccinationEditPanel && !showTestEditPanel;

    const blockCompleted = testResultPass || vaccinationDateCompleted || !RequiredByBirthday;

    const incomplete = !!firstDoseField?.value && !blockCompleted && RequiredByBirthday;

    const titleStatusColor = () => {
      if (notRequired) {
        return 'blackTitle';
      }
      if (incomplete) {
        return 'warningTitle';
      }
      if (!blockCompleted) {
        return 'redTitle';
      }
      return 'blackTitle';
    };

    return (
      <div className='infoPanelMedicalBlock'>
        <div className='compliances-tab-header'>
          <img src={`/compliance/${MeaslesBlocks.measlesTest.icon}`} alt='compliance-icon' />
          <p className={titleStatusColor()} style={{ margin: '0' }}>
            Measles (Rubeola) Test
          </p>
          {incomplete ? <p className='incomplete-warning'>(Incomplete)</p> : ''}
          {!incomplete && !blockCompleted && RequiredByBirthday && !notRequired ? (
            <img
              style={{ marginLeft: '10px' }}
              src='/compliance/attention.svg'
              alt='attention-icon'
            />
          ) : (
            ''
          )}
        </div>
        {!RequiredByBirthday && (
          <div>
            <p className='compliance-medium-gray'>
              <b className='compliance-charcoal-text-color'>
                This caregiver was born prior to 1/1/1957
              </b>{' '}
              and, as a result, is not required to take a Rubeola test.
            </p>
          </div>
        )}
        {RequiredByBirthday && (
          <>
            <div>
              <p className='compliance-medium-gray'>
                Rubeola testing is a <b className='compliance-charcoal-text-color'>One-time</b>{' '}
                requirement needed to be in compliance to work. Caregivers must receive, or prove
                they have received the vaccination and undergo a test to determine immunity.
              </p>
            </div>
            {showTestInfoPanel && (
              <div className='hep-b-test-series-one-container compliance-info-panel'>
                <div className='label-result-in-line-container'>
                  <p className='compliance-block-completion-field-text'>Rubeola Titre Date:</p>
                  <div className='medicalDateInput'>
                    <CompHelper.DateText date={testDate?.value} />
                  </div>
                </div>
                <div className='label-result-in-line-container'>
                  <p className='compliance-block-completion-field-text'>Rubeola Titre Reading:</p>
                  <div className='medicalDateInput'>
                    <CompHelper.ReadingText testReading={testReading?.value} />
                  </div>
                </div>
                <div className='label-result-in-line-container'>
                  <p className='compliance-block-completion-field-text'>Rubeola Titre Result:</p>
                  <div className='medicalDateInput'>
                    <CompHelper.ResultText value={testResult?.value} group='Rubella' />
                  </div>
                </div>
              </div>
            )}
            {showVaccinationPanel && (
              <div
                className={`compliance-info-panel ${showTestInfoPanel ? 'series-two-divider' : ''}`}
              >
                <h3 className='compliance-add-completion-panel-header'>
                  MEASLES (RUBEOLA) VACCINATION{' '}
                </h3>
                <div className='hep-b-dose-dates-container'>
                  {firstDoseField && (
                    <div className='label-input-block-container'>
                      <p className='compliance-block-completion-field-text'>Vaccination 1 Date</p>
                      <div
                        className='medicalDateInput'
                        style={{ justifySelf: 'flex-start', marginRight: '20px' }}
                      >
                        <CompHelper.DateText date={firstDoseField?.value} />
                      </div>
                    </div>
                  )}
                  <div className='label-input-block-container'>
                    <p
                      className={
                        firstDose
                          ? 'compliance-block-completion-field-text'
                          : 'label-result-in-line-container'
                      }
                    >
                      {firstDose ? 'Vaccination 2 Date' : 'Vaccination Date'}
                    </p>
                    <div
                      className='medicalDateInput'
                      style={{ justifySelf: 'flex-start', marginRight: '20px' }}
                    >
                      <CompHelper.DateText date={secondDoseField?.value} />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {closeEnabled && (
              <Image className='tinyCloseButton' src='/close-icon.svg' onClick={this.onClose} />
            )}

            {showVaccinationEditPanel && (
              <div className='hep-b-test-series-one-container compliance-add-completion-panel'>
                <h3 className='compliance-add-completion-panel-header'>
                  ENTER VACCINATION DETAILS
                </h3>
                <>
                  {firstDoseField && (
                    <div className='label-input-in-line-container'>
                      <p
                        className='compliance-block-completion-field-text'
                        style={{ justifySelf: 'flex-start' }}
                      >
                        1st Vaccination date:
                      </p>
                      <CompHelper.ComplianceDatePicker
                        field={firstDoseField}
                        state={firstDose}
                        disabled={false}
                        onChange={this.onFieldChanged}
                      />
                    </div>
                  )}
                  <div className='label-input-in-line-container'>
                    <p
                      className='compliance-block-completion-field-text'
                      style={{ justifySelf: 'flex-start' }}
                    >
                      {firstDose ? '2nd Vaccination date:' : 'Vaccination Date:'}
                    </p>
                    <CompHelper.ComplianceDatePicker
                      field={secondDoseField}
                      state={secondDose}
                      disabled={firstDose ? !firstDose?.value : false}
                      onChange={this.onFieldChanged}
                    />
                  </div>
                  {firstDose && (
                    <div className='multi-dose-info-container'>
                      <p>
                        2nd vaccine should be administered at least <strong>28 days</strong> after
                        the first vaccination.
                      </p>
                    </div>
                  )}
                  <CompHelper.UpdateButton
                    disabled={!dirtyMedicalBlocks.length}
                    update={this.updateMedicalBlock}
                  />
                </>
              </div>
            )}
            {showTestEditPanel && (
              <div className='hep-b-test-series-one-container compliance-add-completion-panel'>
                <h3 className='compliance-add-completion-panel-header'>ENTER TITRE TEST DETAILS</h3>
                <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
                  <p
                    className='compliance-block-completion-field-text'
                    style={{ justifySelf: 'flex-start' }}
                  >
                    Rubeola Titre Test Date:
                  </p>
                  <div
                    className='medicalDateInput'
                    style={{ justifySelf: 'flex-end', marginRight: '20px' }}
                  >
                    <CompHelper.ComplianceDatePicker
                      field={testDateField}
                      state={testDate}
                      disabled={false}
                      onChange={this.onFieldChanged}
                    />
                  </div>
                </div>
                <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
                  <p
                    className='compliance-block-completion-field-text'
                    style={{ justifySelf: 'flex-start' }}
                  >
                    Rubeola Titre Test result:
                  </p>
                  <div
                    className='medicalDateInput'
                    style={{ justifySelf: 'flex-end', marginRight: '20px' }}
                  >
                    <Dropdown
                      placeholder='choose'
                      selection
                      className='compliance-dropdown'
                      key={testResultField.id}
                      value={testResult?.value}
                      options={[
                        { text: 'Immune', value: 'I' },
                        { text: 'Not Immune', value: 'NI' },
                      ]}
                      onChange={(_e, input) => {
                        this.onFieldChanged(testResultField, input.value);
                      }}
                    />
                  </div>
                </div>
                <div className='compliance-hep-date-container' style={{ marginBottom: '20px' }}>
                  <p
                    className='compliance-block-completion-field-text'
                    style={{ justifySelf: 'flex-start' }}
                  >
                    Rubeola Titre Test reading (optional):
                  </p>
                  <CompHelper.ComplianceReading
                    field={testReadingField}
                    state={testReading?.value}
                    onChange={this.onFieldChanged}
                  />
                </div>
                <CompHelper.UpdateButton
                  disabled={
                    !(
                      dirtyMedicalBlocks.length &&
                      !!testDate?.value &&
                      !!testResult?.value &&
                      /* eslint-disable-next-line no-restricted-globals */
                      !isNaN(testReading?.value)
                    )
                  }
                  update={this.updateMedicalBlock}
                />
              </div>
            )}
            {showCompletion && (
              <>
                <div>
                  {!blockCompleted && (
                    <div className='compliance-buttons-container'>
                      <CompHelper.SimpleButton onClick={this.addTestCompletion}>
                        Add titre test info
                      </CompHelper.SimpleButton>
                      <CompHelper.SimpleButton onClick={this.addVaccinationCompletion}>
                        Add vaccination info
                      </CompHelper.SimpleButton>
                    </div>
                  )}
                </div>
                <div className='compliance-buttons-container'>
                  {blockCompleted ? (
                    <p className='block-result-text' style={{ color: '#70DC94' }}>
                      <Image
                        src='/compliance/check.svg'
                        style={{ paddingRight: '5px', display: 'inline' }}
                      />
                      COMPLETED: <CompHelper.DateText date={secondDose?.value || testDate?.value} />
                    </p>
                  ) : (
                    <div />
                  )}
                  {showRemoveButton && (
                    <span style={{ justifySelf: 'flex-end' }}>
                      <CompHelper.RemoveButton onRemove={this.onRemove} />
                    </span>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { caregiverDetails, caregiverCompliance, caregiverComplianceUI } = state;
  return {
    caregiver: caregiverDetails.caregiver,
    caregiverCompliance,
    caregiverComplianceUI,
  };
};

export default connect(mapStateToProps)(ComplianceMeaslesTest);

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Intercom from 'react-intercom';
import IdleTimer from 'react-idle-timer';
import { employeeActions, userActions, caregiverActions } from '../actions';
import {
  isHRManager,
  isScheduler,
  isAdmin,
  isTrainee,
  isCaregiver,
  ApplicationModes,
  activeModeTab,
} from '../constants/DomainTypes';
import TopMenu from './TopMenu';
import Schedules from './Schedules';
import Clients from './Clients';
import ShiftBuilder from './ShiftBuilder';
import Caregivers from './Caregivers';
import Dashboard from './Dashboard';
import Profile from './Profile';
import Imports from './Imports';
import Exports from './Exports';
import ChangeLog from './ChangeLog';
import PrivateRoute from './PrivateRoute';
import { history } from '../helpers';
import Alerts from './subcomponents/Alerts';
import './Home.css';
import CareChatContainer from './CareChatContainer';
import UserDirectory from './UserDirectory';
import TrainPref from './TrainPref';
import CaregiverRecords from './CaregiverRecords';
import ClientRecords from './ClientRecords';
import HRApplicants from './HRApplicants';
import HRDashboard from './HRDashboard';
import HRCalendar from './HRCalendar';
import HRCaregiversView from './HRCaregiversView/HRCaregiversView';
import ELDashboard from './ELDashboard';
import { heartbeatActions } from '../actions/heartbeat';
import { ELManageActions } from '../actions/ELApp/ELManageActions';
import ELCourses from './ELCourses';
import ELManage from './ELManage';
import ELReports from './ELReports';
import HandleCourseViewDetails from './subcomponents/eLAPP/HandleCourseViewDetail';
import { backend } from '../services';
import { EmbedReports } from './embedReportsView/EmbedReports';
import { SolutionName } from '../constants/Solution';

const NoMatch = ({ location }) => (
  <div className='ui inverted red raised very padded text container segment'>
    <strong>Not implemented!</strong> No route found matching:
    <div className='ui inverted black segment'>
      <code>{location.pathname}</code>
    </div>
  </div>
);

NoMatch.propTypes = {
  location: PropTypes.shape().isRequired,
};

class Home extends React.Component {
  static onIdle() {
    history.push('/login');
  }

  constructor() {
    super();
    this.timerDispatchFunction = new caregiverActions.OnlineStatusChecks();
    this.hearbeatDispatchFunction = new heartbeatActions.HeartbeatTimer();
  }

  componentDidMount() {
    const { roles } = this.props;
    if (isAdmin(roles) || isScheduler(roles) || isHRManager(roles)) {
      this.props.dispatch(employeeActions.getEmployeeList());
      this.props.dispatch(userActions.getLanguages());
      this.props.dispatch(userActions.getDisciplines());
      this.props.dispatch(userActions.getAttributes());
      this.props.dispatch(employeeActions.getEmployee('self', false));
      this.props.dispatch(this.timerDispatchFunction.initialize);
      this.props.dispatch(this.hearbeatDispatchFunction.initialize);
    }
    if (isCaregiver(roles)) {
      this.props.dispatch(userActions.getUserProfile());
      this.props.dispatch(userActions.getUserSummary());
    }
    if (isAdmin(roles) && isTrainee(roles)) {
      this.props.dispatch(ELManageActions.getAgencyPrograms());
    }
    backend.registerCaregiverOnline();
  }

  componentWillUnmount() {
    this.timerDispatchFunction.clear();
    this.hearbeatDispatchFunction.clear();
  }

  checkRoles = roles => {
    if (!roles || !roles.length) {
      history.push('/login');
    }
  };

  // HR MANAGER ROUTES
  hrManagerRoutes = match => [
    // prettier-ignore
    <PrivateRoute path={`${match.url}/hr/caregivers/records/:caregiverId`} mode={ApplicationModes.hr} subTab={activeModeTab.hrCaregivers} component={CaregiverRecords} dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/hr/candidates/records/:caregiverId`} mode={ApplicationModes.hr} subTab={activeModeTab.hrCandidates} component={CaregiverRecords} dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/hr`} mode={ApplicationModes.hr} component={HRDashboard} subTab={activeModeTab.hrDashBoard} exact dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/hr/applicants`} mode={ApplicationModes.hr} subTab={activeModeTab.hrApplicants} component={HRApplicants} exact dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/hr/candidates`} mode={ApplicationModes.hr} subTab={activeModeTab.hrCandidates} component={HRCaregiversView} exact dispatch={this.props.dispatch} componentProps={{ view: 'candidates' }} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/hr/caregivers`} mode={ApplicationModes.hr} subTab={activeModeTab.hrCaregivers} component={HRCaregiversView} exact dispatch={this.props.dispatch} componentProps={{ view: 'caregivers' }} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/hr/calendar`} mode={ApplicationModes.hr} subTab={activeModeTab.hrCalendar} component={HRCalendar} exact dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/profile`} component={Profile} subTab={activeModeTab.profile} dispatch={this.props.dispatch} />,
    // prettier-ignore
    <Route exact path={`${match.url}`} render={() => <Redirect to={`${match.url}/hr`} />} />,
  ];

  // SCHEDULER ROUTES
  schedulerRoutes = (user, feed, match) => [
    // prettier-ignore
    <PrivateRoute path={`${match.url}/shifts`} mode={ApplicationModes.scheduler} subTab={activeModeTab.shifts} component={Schedules} exact dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/shifts/add`} mode={ApplicationModes.scheduler} subTab={activeModeTab.shifts} component={ShiftBuilder} dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/clients`} mode={ApplicationModes.scheduler} subTab={activeModeTab.clients} component={Clients} exact dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/reports/compliance`} mode={ApplicationModes.embedReports} subTab={activeModeTab.embedComplianceReports} component={EmbedReports} componentProps={{ solution: SolutionName.COMPLIANCE }} dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/clients/records/:clientId`} mode={ApplicationModes.scheduler} subTab={activeModeTab.clients} component={ClientRecords} dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/caregivers`} mode={ApplicationModes.scheduler} subTab={activeModeTab.caregivers} component={Caregivers} exact dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/caregivers/records/:caregiverId`} subTab={activeModeTab.caregivers} mode={ApplicationModes.scheduler} component={CaregiverRecords} dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/profile`} component={Profile} subTab={activeModeTab.profile} dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/changelog`} mode={ApplicationModes.scheduler} subTab={activeModeTab.changeLog} component={ChangeLog} dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute exact path={`${match.url}`} mode={ApplicationModes.scheduler} subTab={activeModeTab.ccDashBoard} component={Dashboard} componentProps={{ user, feed, i: 200 }} dispatch={this.props.dispatch} />,
  ];

  // E LEARNING ROUTES
  traineeRoutes = match => [
    // prettier-ignore
    <PrivateRoute path={`${match.url}/e-learning`} mode={ApplicationModes.training} subTab={activeModeTab.ELDashboard}  component={ELDashboard} exact dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/e-learning/courses`} mode={ApplicationModes.training} subTab={activeModeTab.ELCourses}  component={ELCourses} exact dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/e-learning/manage`} mode={ApplicationModes.eLearningMgmt} subTab={activeModeTab.ELUserDirectory}  component={ELManage} exact dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/e-learning/reports`} mode={ApplicationModes.eLearningMgmt} subTab={activeModeTab.ELReports}  component={ELReports} exact dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/e-learning/courses/course-details/:courseId`} mode={ApplicationModes.training} subTab={activeModeTab.ELCourses} dispatch={this.props.dispatch}  component={HandleCourseViewDetails} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/profile`} component={Profile} subTab={activeModeTab.profile} dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/e-learning/caregivers/records/:caregiverId`} mode={ApplicationModes.eLearningMgmt} subTab={activeModeTab.ELUserDirectory} component={CaregiverRecords} dispatch={this.props.dispatch} />,
    // prettier-ignore
    <Route exact path={`${match.url}`} render={() => <Redirect to={`${match.url}/e-learning`} />} />,
  ];

  // ADMIN ROUTES
  adminRoutes = match => [
    // prettier-ignore
    <PrivateRoute path={`${match.url}/userdirectory`} component={UserDirectory} dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/systemsettings`} component={TrainPref} subTab={activeModeTab.settings} dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/imports`} component={Imports} dispatch={this.props.dispatch} />,
    // prettier-ignore
    <PrivateRoute path={`${match.url}/Exports`} component={Exports} dispatch={this.props.dispatch} />,
  ];

  render() {
    const {
      match,
      user,
      roles,
      feed,
      employee,
      settings,
      heartbeat,
      applicationMode,
      subTab,
    } = this.props;

    const intercomUser = {
      user_id: user.id,
      email: user.username,
      name: `${user.firstName} ${user.lastName}`,
    };

    this.checkRoles(roles);

    const isNotCaregiver = roles.find(r => r === 'scheduler' || r === 'admin' || r === 'hrmanager');

    // idle timeout 3 hours
    return (
      <span>
        <IdleTimer idleAction={Home.onIdle} timeout={10800000} />
        {isNotCaregiver && <CareChatContainer />}
        <Alerts />
        <Grid padded='horizontally' className='mainGrid'>
          <Grid.Row>
            <TopMenu
              heartbeat={heartbeat}
              employee={employee?.employee}
              user={user}
              roles={roles}
              settings={settings}
              mode={applicationMode}
              subTab={subTab}
              dispatch={this.props.dispatch}
              agencies={this.props.agencies}
            />
          </Grid.Row>
          <Switch>
            {isScheduler(roles) && this.schedulerRoutes(user, feed, match)}
            {isHRManager(roles) && this.hrManagerRoutes(match)}
            {isTrainee(roles) && this.traineeRoutes(match)}
            {isAdmin(roles) && this.adminRoutes(match)}
            <PrivateRoute
              path={`${match.url}/settings`}
              component={Profile}
              componentProps={{ employeeId: 'self' }}
              dispatch={this.props.dispatch}
            />
            <PrivateRoute component={NoMatch} dispatch={this.props.dispatch} />
          </Switch>
        </Grid>
        {process.env.REACT_APP_STAGE === 'prod' && (
          <Intercom appID='aom7hqhl' alignment='left' {...intercomUser} />
        )}
      </span>
    );
  }
}

Home.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  feed: PropTypes.shape(),
  employee: PropTypes.shape(),
  settings: PropTypes.shape().isRequired,
  location: PropTypes.shape(),
  heartbeat: PropTypes.shape(),
  roles: PropTypes.arrayOf(PropTypes.string),
  applicationMode: PropTypes.string,
  profile: PropTypes.shape().isRequired,
};

Home.defaultProps = {
  feed: {},
  employee: {},
  location: {},
  heartbeat: {},
  roles: [],
  applicationMode: ApplicationModes.scheduler,
};

const mapStateToProps = state => {
  const { feed, principal, employee, uiState } = state;
  const { user, settings, roles, profile, agencies } = principal;
  const { application } = uiState.inputGroups;
  const mode = application != null ? application.mode : ApplicationModes.scheduler;
  const subTab = application != null ? application.subTab : '';

  return {
    alert,
    user,
    feed,
    employee,
    settings,
    roles,
    applicationMode: mode,
    subTab,
    heartbeat: uiState.inputGroups.heartbeat,
    profile,
    agencies,
  };
};

export default connect(mapStateToProps)(Home);

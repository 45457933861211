import React from 'react';
import { Accordion, AccordionTitleProps, Icon } from 'semantic-ui-react';

interface IProps {
  title: string;
  active: boolean;
  index: number | string;
  handleClick: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    titleProps: AccordionTitleProps
  ) => void;
}

const AccordionSection: React.FC<IProps> = ({ title, active, index, children, handleClick }) => {
  return (
    <>
      <Accordion.Title
        index={index}
        active={active}
        onClick={handleClick}
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: '#9b9b9b',
          fontSize: '12px',
          fontWeight: 'bold',
          textTransform: 'capitalize',
        }}
      >
        {title}
        <Icon name={active ? 'minus' : 'plus'} />
      </Accordion.Title>
      <Accordion.Content active={active}>{children}</Accordion.Content>
    </>
  );
};

export default AccordionSection;

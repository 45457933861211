/* eslint-disable import/prefer-default-export */
import {
  GET_ELEARNING_PROFILE_LIST_REQUEST,
  GET_ELEARNING_PROFILE_LIST_SUCCESS,
  GET_ELEARNING_PROFILE_LIST_FAILURE,
  GET_TRAINEE_PROGRAMS_REQUEST,
  GET_TRAINEE_PROGRAMS_SUCCESS,
  GET_TRAINEE_PROGRAMS_FAILURE,
  LOAD_MORE_ELEARNING_PROFILE_LIST_REQUEST,
  LOAD_MORE_ELEARNING_PROFILE_LIST_SUCCESS,
  LOAD_MORE_ELEARNING_PROFILE_LIST_FAILURE,
  CLEAR_CURRICULUMS_AND_PROGRAMS,
  GET_AGENCY_PROGRAMS_REQUEST,
  GET_AGENCY_PROGRAMS_SUCCESS,
  GET_AGENCY_PROGRAMS_FAILURE,
  GET_CURRICULUM_WITH_COURSES_REQUEST,
  GET_CURRICULUM_WITH_COURSES_SUCCESS,
  GET_CURRICULUM_WITH_COURSES_FAILURE,
  HANDLE_E_LEARNING_CALL_REQUEST,
  HANDLE_E_LEARNING_CALL_SUCCESS,
  HANDLE_E_LEARNING_CALL_FAILURE,
} from '../../constants/ActionTypes';
import { backend } from '../../services';
import { alertActions } from '../alert';

function getElearningProfilesList(params) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.getElearningProfilesList(params);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: GET_ELEARNING_PROFILE_LIST_REQUEST };
  }
  function success(results) {
    return { type: GET_ELEARNING_PROFILE_LIST_SUCCESS, results };
  }
  function failure(error) {
    return { type: GET_ELEARNING_PROFILE_LIST_FAILURE, error };
  }
}

function getTraineeProgramsAndCurriculums(traineeId) {
  return async dispatch => {
    dispatch(request());
    try {
      const curriculums = await backend.getElearningTraineeCurriculums(traineeId);
      const programs = await backend.getElearningTraineePrograms(traineeId);
      const res = { curriculums, programs };
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: GET_TRAINEE_PROGRAMS_REQUEST };
  }
  function success(results) {
    return { type: GET_TRAINEE_PROGRAMS_SUCCESS, results };
  }
  function failure(error) {
    return { type: GET_TRAINEE_PROGRAMS_FAILURE, error };
  }
}

function loadMoreElearningProfilesList(params) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.getElearningProfilesList(params);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: LOAD_MORE_ELEARNING_PROFILE_LIST_REQUEST };
  }
  function success(results) {
    return { type: LOAD_MORE_ELEARNING_PROFILE_LIST_SUCCESS, results };
  }
  function failure(error) {
    return { type: LOAD_MORE_ELEARNING_PROFILE_LIST_FAILURE, error };
  }
}

function clearCurriculumsAndPrograms() {
  return { type: CLEAR_CURRICULUMS_AND_PROGRAMS };
}

function getAgencyPrograms(params) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.getAgencyPrograms(params);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: GET_AGENCY_PROGRAMS_REQUEST };
  }
  function success(results) {
    return { type: GET_AGENCY_PROGRAMS_SUCCESS, results };
  }
  function failure(error) {
    return { type: GET_AGENCY_PROGRAMS_FAILURE, error };
  }
}

function getCurriculumWithCourses(params) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.getCurriculumWithCourses(params);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: GET_CURRICULUM_WITH_COURSES_REQUEST };
  }
  function success(results) {
    return { type: GET_CURRICULUM_WITH_COURSES_SUCCESS, results };
  }
  function failure(error) {
    return { type: GET_CURRICULUM_WITH_COURSES_FAILURE, error };
  }
}

function changeAccessToProgram(params) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.changeAccessToProgram(params);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      error.json.error = error.json?.error?.message;
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: HANDLE_E_LEARNING_CALL_REQUEST };
  }
  function success(results) {
    return { type: HANDLE_E_LEARNING_CALL_SUCCESS, results };
  }
  function failure(error) {
    return { type: HANDLE_E_LEARNING_CALL_FAILURE, error };
  }
}

function deleteUserFromProgram(params) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.deleteUserFromProgram(params);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: HANDLE_E_LEARNING_CALL_REQUEST };
  }
  function success(results) {
    return { type: HANDLE_E_LEARNING_CALL_SUCCESS, results };
  }
  function failure(error) {
    return { type: HANDLE_E_LEARNING_CALL_FAILURE, error };
  }
}

function changeAccessToCurriculum(params) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.changeAccessToCurriculum(params);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: HANDLE_E_LEARNING_CALL_REQUEST };
  }
  function success(results) {
    return { type: HANDLE_E_LEARNING_CALL_SUCCESS, results };
  }
  function failure(error) {
    return { type: HANDLE_E_LEARNING_CALL_FAILURE, error };
  }
}

function deleteUserFromCurriculum(params) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.deleteUserFromCurriculum(params);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: HANDLE_E_LEARNING_CALL_REQUEST };
  }
  function success(results) {
    return { type: HANDLE_E_LEARNING_CALL_SUCCESS, results };
  }
  function failure(error) {
    return { type: HANDLE_E_LEARNING_CALL_FAILURE, error };
  }
}

function handleCourseExemption(courses) {
  return async dispatch => {
    dispatch(request());
    try {
      const coursesPromises = courses.map(c => {
        const params = {
          curriculumId: c.curriculumId,
          userCourseId: c.userCourseId,
          exempt: !c.exempt,
        };
        return backend.handleCourseExemption(params);
      });
      const res = await Promise.all(coursesPromises);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: HANDLE_E_LEARNING_CALL_REQUEST };
  }
  function success(results) {
    return { type: HANDLE_E_LEARNING_CALL_SUCCESS, results };
  }
  function failure(error) {
    return { type: HANDLE_E_LEARNING_CALL_FAILURE, error };
  }
}

export const ELManageActions = {
  getElearningProfilesList,
  getTraineeProgramsAndCurriculums,
  loadMoreElearningProfilesList,
  clearCurriculumsAndPrograms,
  changeAccessToProgram,
  deleteUserFromProgram,
  changeAccessToCurriculum,
  deleteUserFromCurriculum,
  getAgencyPrograms,
  getCurriculumWithCourses,
  handleCourseExemption,
};

/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { history } from '../../../helpers';
import CourseViewDetails from './CourseViewDetails';
import PrivateRoute from '../../PrivateRoute';

import '../../ELCourses.css';

class HandleCourseViewDetail extends React.Component {
  state = { eLearning: true };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { match } = this.props;
    const { courseId } = ((this.props || {}).match || {}).params || {};
    return (
      <Router history={history}>
        <Switch>
          <Route
            exact
            path={`${match.url}`}
            render={() => <Redirect to={`${match.url}/details`} />}
          />
          <PrivateRoute
            path={`${match.url}/details`}
            component={CourseViewDetails}
            redirectTo={`${match.url}`}
            componentProps={{
              courseId,
              // eslint-disable-next-line react/destructuring-assignment
              dispatch: this.props.dispatch,
            }}
          />
        </Switch>
      </Router>
    );
  }
}

HandleCourseViewDetail.propTypes = {
  match: PropTypes.shape.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { eLCourses } = state;
  return {
    eLCourses,
  };
};

export default connect(mapStateToProps)(HandleCourseViewDetail);

import React, { useRef } from 'react';
import { AccordionTitleProps, DropdownProps } from 'semantic-ui-react';
import InputRange, { Range } from 'react-input-range';
import {
  IDropdownOptions,
  IComplianceOptions,
  IHRCaregiversFilter,
  ISecondaryStatus,
} from '../../../../reducers/types/HRFilters';
import { CCDropDown, CheckBox } from '../../../../styles/commonStyled';
import AccordionSection from './AccordionSection';
import { displaySelectedOptions, FilterSections } from './helper';
import {
  DropdownLabelContainer,
  RailLabel,
  SubHeader,
  CheckLabelContainer,
  HiddenInput,
  HRInputRangeContainer,
} from './styles';
import { staffStatusOptions } from '../../../../constants/DomainTypes';

interface IProps {
  active: FilterSections[];
  disciplinesOptions: IDropdownOptions[];
  primaryContactOption: IDropdownOptions[];
  primaryManagerOption: IDropdownOptions[];
  complianceOptions: IComplianceOptions;
  secondaryStatus: ISecondaryStatus[];
  filters: IHRCaregiversFilter;
  vaccineEnabled: boolean;
  onAccordionClick: (_e: unknown, titleProps: AccordionTitleProps) => void;
  onDropdownChange: (data: DropdownProps, type: string) => void;
  onCheckBoxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onRangeChange: (value: number | Range) => void;
}

const CaregiversRail: React.FC<IProps> = ({
  active,
  complianceOptions,
  primaryContactOption,
  primaryManagerOption,
  secondaryStatus,
  filters,
  vaccineEnabled,
  onAccordionClick,
  onDropdownChange,
  onCheckBoxChange,
  onRangeChange,
  disciplinesOptions = [],
}) => {
  const incompleteRef = useRef<HTMLInputElement>(null);
  const pendingRef = useRef<HTMLInputElement>(null);
  const expiredRef = useRef<HTMLInputElement>(null);
  const validationRef = useRef<HTMLInputElement>(null);
  const uploadRef = useRef<HTMLInputElement>(null);
  const completedRef = useRef<HTMLInputElement>(null);
  const declinedRef = useRef<HTMLInputElement>(null);
  const shiftBlockRef = useRef<HTMLInputElement>(null);
  const {
    disciplines,
    compliancesIssues,
    vaccinationValidation,
    primaryContact,
    primaryManager,
    caregiverStatus,
    secondaryStatus: secondaryStatusFilter,
    blockShift,
  } = filters;

  const vaccinationsOptions = [
    {
      key: 'COVID',
      text: 'COVID',
      value: 'COVID',
    },
  ];

  const activeOnHoldStatus = staffStatusOptions.filter(s => s.value === '02' || s.value === '03');

  const handleCheckClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const { filter } = e.currentTarget.dataset;
    switch (filter) {
      case 'incomplete':
        incompleteRef.current?.click();
        break;
      case 'pending':
        pendingRef.current?.click();
        break;
      case 'expired':
        expiredRef.current?.click();
        break;
      case 'validation':
        validationRef.current?.click();
        break;
      case 'declined':
        declinedRef.current?.click();
        break;
      case 'upload':
        uploadRef.current?.click();
        break;
      case 'complete':
        completedRef.current?.click();
        break;
      case 'block shift':
        shiftBlockRef.current?.click();
        break;
      default:
        break;
    }
  };

  const handleDropdownChange = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    onDropdownChange(data, 'caregivers');
  };

  const rangeLabel = () => {
    return `${compliancesIssues?.dueDate || 0} days`;
  };

  const getSecondaryStatus = () => {
    if (!secondaryStatus) {
      return [];
    }
    const secondaryStatuses = secondaryStatus.filter(
      s => s.staffStatus === caregiverStatus.status[0]
    );
    return secondaryStatuses;
  };

  const complianceStatusDisable = !(
    compliancesIssues.eligibility.length ||
    compliancesIssues.medical.length ||
    compliancesIssues.documentation.length ||
    compliancesIssues.training.length
  );

  return (
    <>
      <AccordionSection
        title={`${FilterSections.DISCIPLINES} ${displaySelectedOptions([
          disciplines.disciplines.length,
        ])}`}
        active={active.includes(FilterSections.DISCIPLINES)}
        index={FilterSections.DISCIPLINES}
        handleClick={onAccordionClick}
      >
        <CCDropDown
          placeholder='Please Select'
          tabIndex={FilterSections.DISCIPLINES}
          id='disciplines'
          multiple
          selection
          value={disciplines.disciplines}
          options={[{ text: 'All Disciplines', value: 0 }, ...disciplinesOptions]}
          onChange={handleDropdownChange}
        />
      </AccordionSection>
      <AccordionSection
        title={`${FilterSections.COMPLIANCES_ISSUES} ${displaySelectedOptions([
          compliancesIssues.eligibility.length,
          compliancesIssues.medical.length,
          compliancesIssues.documentation.length,
          compliancesIssues.training.length,
          compliancesIssues.incomplete ? 1 : 0,
          compliancesIssues.expired ? 1 : 0,
          compliancesIssues.pending ? 1 : 0,
        ])}`}
        active={active.includes(FilterSections.COMPLIANCES_ISSUES)}
        index={FilterSections.COMPLIANCES_ISSUES}
        handleClick={onAccordionClick}
      >
        <DropdownLabelContainer>
          <RailLabel>Eligibility</RailLabel>
          <CCDropDown
            placeholder='Please Select'
            tabIndex={FilterSections.COMPLIANCES_ISSUES}
            id='eligibility'
            multiple
            selection
            value={compliancesIssues.eligibility}
            options={[{ text: 'All Eligibility', value: 0 }, ...complianceOptions.eligibility]}
            onChange={handleDropdownChange}
          />
        </DropdownLabelContainer>
        <DropdownLabelContainer>
          <RailLabel>Medical</RailLabel>
          <CCDropDown
            placeholder='Please Select'
            tabIndex={FilterSections.COMPLIANCES_ISSUES}
            id='medical'
            multiple
            selection
            value={compliancesIssues.medical}
            options={[{ text: 'All Medical', value: 0 }, ...complianceOptions.medical]}
            onChange={handleDropdownChange}
          />
        </DropdownLabelContainer>
        <DropdownLabelContainer>
          <RailLabel>Documentation</RailLabel>
          <CCDropDown
            placeholder='Please Select'
            tabIndex={FilterSections.COMPLIANCES_ISSUES}
            id='documentation'
            multiple
            selection
            value={compliancesIssues.documentation}
            options={[{ text: 'All Documentation', value: 0 }, ...complianceOptions.documentation]}
            onChange={handleDropdownChange}
          />
        </DropdownLabelContainer>
        <DropdownLabelContainer>
          <RailLabel>Training</RailLabel>
          <CCDropDown
            placeholder='Please Select'
            tabIndex={FilterSections.COMPLIANCES_ISSUES}
            id='training'
            multiple
            selection
            value={compliancesIssues.training}
            options={[{ text: 'All Training', value: 0 }, ...complianceOptions.training]}
            onChange={handleDropdownChange}
          />
        </DropdownLabelContainer>
        <SubHeader>Show caregivers with the selected...</SubHeader>
        <CheckLabelContainer>
          <RailLabel>Incomplete compliance items</RailLabel>
          <CheckBox
            data-filter='incomplete'
            checked={compliancesIssues.incomplete}
            small
            disabled={complianceStatusDisable}
            onClick={handleCheckClick}
          >
            <HiddenInput
              ref={incompleteRef}
              data-section={FilterSections.COMPLIANCES_ISSUES}
              id='incomplete'
              onChange={onCheckBoxChange}
              type='checkbox'
              checked={compliancesIssues.incomplete}
            />
          </CheckBox>
        </CheckLabelContainer>
        <CheckLabelContainer>
          <RailLabel>Expired compliance items</RailLabel>
          <CheckBox
            data-filter='expired'
            checked={compliancesIssues.expired}
            small
            disabled={complianceStatusDisable}
            onClick={handleCheckClick}
          >
            <HiddenInput
              ref={expiredRef}
              data-section={FilterSections.COMPLIANCES_ISSUES}
              id='expired'
              onChange={onCheckBoxChange}
              type='checkbox'
              checked={compliancesIssues.expired}
            />
          </CheckBox>
        </CheckLabelContainer>
        <CheckLabelContainer>
          <RailLabel>Pending expirations</RailLabel>
          <CheckBox
            data-filter='pending'
            checked={compliancesIssues.pending}
            small
            disabled={complianceStatusDisable}
            onClick={handleCheckClick}
          >
            <HiddenInput
              ref={pendingRef}
              data-section={FilterSections.COMPLIANCES_ISSUES}
              id='pending'
              onChange={onCheckBoxChange}
              type='checkbox'
              checked={compliancesIssues.pending}
            />
          </CheckBox>
        </CheckLabelContainer>
        <SubHeader>Due within...</SubHeader>
        <div className='field shiftsSearchTimeRange'>
          <HRInputRangeContainer>
            <InputRange
              disabled={!compliancesIssues.pending}
              maxValue={30}
              minValue={0}
              step={5}
              value={compliancesIssues?.dueDate || 0}
              formatLabel={rangeLabel}
              onChange={onRangeChange}
            />
          </HRInputRangeContainer>
        </div>
      </AccordionSection>
      {vaccineEnabled && (
        <AccordionSection
          title={`${FilterSections.VACCINATION_VALIDATION} ${displaySelectedOptions([
            vaccinationValidation.vaccine.length,
            vaccinationValidation.validation ? 1 : 0,
            vaccinationValidation.upload ? 1 : 0,
            vaccinationValidation.complete ? 1 : 0,
          ])}`}
          active={active.includes(FilterSections.VACCINATION_VALIDATION)}
          index={FilterSections.VACCINATION_VALIDATION}
          handleClick={onAccordionClick}
        >
          <DropdownLabelContainer>
            <RailLabel>Vaccination Type</RailLabel>
            <CCDropDown
              placeholder='Please Select'
              tabIndex={FilterSections.VACCINATION_VALIDATION}
              id='vaccine'
              multiple
              selection
              value={vaccinationValidation.vaccine}
              options={vaccinationsOptions}
              onChange={handleDropdownChange}
            />
          </DropdownLabelContainer>
          <SubHeader>Show caregivers with the selected...</SubHeader>
          <CheckLabelContainer>
            <RailLabel>Waiting for validation</RailLabel>
            <CheckBox
              data-filter='validation'
              checked={vaccinationValidation.validation}
              small
              disabled={!vaccinationValidation.vaccine.length}
              onClick={handleCheckClick}
            >
              <HiddenInput
                ref={validationRef}
                data-section={FilterSections.VACCINATION_VALIDATION}
                id='validation'
                onChange={onCheckBoxChange}
                type='checkbox'
                checked={vaccinationValidation.validation}
              />
            </CheckBox>
          </CheckLabelContainer>
          <CheckLabelContainer>
            <RailLabel>Awaiting upload</RailLabel>
            <CheckBox
              data-filter='upload'
              checked={vaccinationValidation.upload}
              small
              disabled={!vaccinationValidation.vaccine.length}
              onClick={handleCheckClick}
            >
              <HiddenInput
                ref={uploadRef}
                data-section={FilterSections.VACCINATION_VALIDATION}
                id='upload'
                onChange={onCheckBoxChange}
                type='checkbox'
                checked={vaccinationValidation.upload}
              />
            </CheckBox>
          </CheckLabelContainer>
          <CheckLabelContainer>
            <RailLabel>Completed</RailLabel>
            <CheckBox
              data-filter='complete'
              checked={vaccinationValidation.complete}
              small
              disabled={!vaccinationValidation.vaccine.length}
              onClick={handleCheckClick}
            >
              <HiddenInput
                ref={completedRef}
                data-section={FilterSections.VACCINATION_VALIDATION}
                id='complete'
                onChange={onCheckBoxChange}
                type='checkbox'
                checked={vaccinationValidation.complete}
              />
            </CheckBox>
          </CheckLabelContainer>
          <CheckLabelContainer>
            <RailLabel>Declined</RailLabel>
            <CheckBox
              data-filter='declined'
              checked={vaccinationValidation.declined}
              small
              disabled={!vaccinationValidation.vaccine.length}
              onClick={handleCheckClick}
            >
              <HiddenInput
                ref={declinedRef}
                data-section={FilterSections.VACCINATION_VALIDATION}
                id='declined'
                onChange={onCheckBoxChange}
                type='checkbox'
                checked={vaccinationValidation.declined}
              />
            </CheckBox>
          </CheckLabelContainer>
        </AccordionSection>
      )}
      <AccordionSection
        title={`${FilterSections.PRIMARY_CONTACT} ${displaySelectedOptions([
          primaryContact.primaryContact.length,
          primaryManager.primaryManager.length,
        ])}`}
        active={active.includes(FilterSections.PRIMARY_CONTACT)}
        index={FilterSections.PRIMARY_CONTACT}
        handleClick={onAccordionClick}
      >
        <DropdownLabelContainer>
          <RailLabel>Primary Contact</RailLabel>
          <CCDropDown
            placeholder='Please Select'
            tabIndex={FilterSections.PRIMARY_CONTACT}
            id='primaryContact'
            multiple
            selection
            value={primaryContact.primaryContact}
            options={[{ text: 'All Primary Contacts', value: 0 }, ...primaryContactOption]}
            onChange={handleDropdownChange}
          />
        </DropdownLabelContainer>
        <DropdownLabelContainer>
          <RailLabel>Primary Contact Manager</RailLabel>
          <CCDropDown
            placeholder='Please Select'
            tabIndex={FilterSections.PRIMARY_MANAGER}
            id='primaryManager'
            multiple
            selection
            value={primaryManager.primaryManager}
            options={[{ text: 'All Managers', value: 0 }, ...primaryManagerOption]}
            onChange={handleDropdownChange}
          />
        </DropdownLabelContainer>
      </AccordionSection>
      <AccordionSection
        title={`${FilterSections.CAREGIVER_STATUS} ${displaySelectedOptions([
          caregiverStatus.status.length,
          secondaryStatusFilter.secondaryStatus.length,
        ])}`}
        active={active.includes(FilterSections.CAREGIVER_STATUS)}
        index={FilterSections.CAREGIVER_STATUS}
        handleClick={onAccordionClick}
      >
        <DropdownLabelContainer>
          <RailLabel>Staff Status</RailLabel>
          <CCDropDown
            placeholder='Please Select'
            tabIndex={FilterSections.CAREGIVER_STATUS}
            id='status'
            multiple
            selection
            value={caregiverStatus.status}
            options={[{ text: 'All Staff Status', value: '00' }, ...activeOnHoldStatus]}
            onChange={handleDropdownChange}
          />
        </DropdownLabelContainer>

        {secondaryStatus && !!secondaryStatus.length && (
          <DropdownLabelContainer>
            <RailLabel>Secondary Status</RailLabel>
            <CCDropDown
              placeholder='Please Select'
              tabIndex={FilterSections.CAREGIVER_SECONDARY_STATUS}
              id='secondaryStatus'
              disabled={
                !caregiverStatus.status.length ||
                caregiverStatus.status.length > 1 ||
                caregiverStatus.status[0] === '00'
              }
              multiple
              selection
              value={secondaryStatusFilter.secondaryStatus}
              options={getSecondaryStatus()}
              onChange={handleDropdownChange}
            />
          </DropdownLabelContainer>
        )}
      </AccordionSection>
      <AccordionSection
        title={`${FilterSections.BLOCK_SHIFT} ${displaySelectedOptions([
          blockShift.blockShift ? 1 : 0,
        ])}`}
        active={active.includes(FilterSections.BLOCK_SHIFT)}
        index={FilterSections.BLOCK_SHIFT}
        handleClick={onAccordionClick}
      >
        <CheckLabelContainer>
          <RailLabel>Block from Shift</RailLabel>
          <CheckBox
            data-filter='block shift'
            checked={blockShift.blockShift}
            small
            onClick={handleCheckClick}
          >
            <HiddenInput
              ref={shiftBlockRef}
              data-section={FilterSections.BLOCK_SHIFT}
              id='blockShift'
              onChange={onCheckBoxChange}
              type='checkbox'
              checked={blockShift.blockShift}
            />
          </CheckBox>
        </CheckLabelContainer>
      </AccordionSection>
    </>
  );
};

export default CaregiversRail;

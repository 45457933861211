/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Form, Divider, Icon, Input, Dropdown, Button, Image } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';

import 'react-datepicker/dist/react-datepicker.css';

import '../ShiftBuilder.css';
import CareTimePicker from './CareTimePicker';
import {
  precisionRound,
  formatTime,
  SHORT_DATE,
  MOMENT_HOURS_CAPITAL,
} from '../../constants/Formats';

class ShiftInformationEditor extends React.Component {
  UNSAFE_componentWillMount() {
    this.setState({ shift: this.props.shift });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ shift: nextProps.shift });
  }

  render() {
    const { shift } = this.state;
    const { index, services, closeEnabled } = this.props;

    const serviceOptions = services && services.map(s => ({ key: s, text: s, value: s }));

    const updateShift = s => {
      // eslint-disable-next-line no-param-reassign
      s.valid = !!(s.hours >= 0.1 && s.service);
      this.setState({ shift: s });
      this.props.onChange(s);
    };

    const onDateChange = e => {
      const newShift = { ...this.state.shift };
      const duration = moment.duration(moment.utc(newShift.end).diff(moment.utc(newShift.start)));
      const oldStart = newShift.start;
      const newStart = e;
      newStart.hour(oldStart.hour()).minute(oldStart.minute());
      newShift.start = newStart;
      newShift.end = moment(newStart);
      newShift.end.add(duration);
      updateShift(newShift);
    };

    const onHoursChange = (e, data) => {
      const newShift = { ...this.state.shift };
      newShift.hours = precisionRound(data.value, 2);
      newShift.end = moment(newShift.start);
      newShift.end.add('hours', newShift.hours);
      updateShift(newShift);
    };

    const onServiceChange = (e, data) => {
      const newShift = { ...this.state.shift };
      newShift.service = data.value;
      updateShift(newShift);
    };

    const onTimeChange = type => e => {
      const newShift = { ...this.state.shift };
      if (type === 'start') {
        newShift.start = e;
        newShift.end = moment(newShift.start);
        newShift.end.add('hours', newShift.hours);
      } else if (type === 'end') {
        newShift.end = e;
        if (newShift.start.isAfter(newShift.end)) {
          newShift.start = newShift.end;
        }
        const duration = moment.duration(moment.utc(newShift.end).diff(moment.utc(newShift.start)));
        newShift.hours = duration.asHours();
      }
      updateShift(newShift);
    };

    const toggleState = () => {
      const newShift = { ...this.state.shift };
      newShift.closed = !newShift.closed;
      updateShift(newShift);
    };

    if (shift.closed) {
      return (
        <Grid className='shiftEditorCollapsed'>
          <Grid.Row>
            <Grid.Column width='14'>
              <Grid.Row className='subHeader'>Shift {index}</Grid.Row>
              <Grid.Row>
                <Grid className='collapsedShift'>
                  <Grid.Column width='3'>
                    {formatTime(shift.start, shift.timezone, SHORT_DATE)}
                  </Grid.Column>
                  <Grid.Column width='7'>
                    {formatTime(shift.start, shift.timezone, MOMENT_HOURS_CAPITAL)}
                    &nbsp;›&nbsp;
                    {formatTime(shift.end, shift.timezone, MOMENT_HOURS_CAPITAL)}
                  </Grid.Column>
                  <Grid.Column width='3'>{precisionRound(shift.hours, 2)} hrs</Grid.Column>
                  <Grid.Column width='3'>{shift.service}</Grid.Column>
                </Grid>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width='2'>
              <div className='editButton' onClick={toggleState} role='presentation' floated='right'>
                <Icon name='pencil' />
                edit
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Divider />
          </Grid.Row>
        </Grid>
      );
    }

    return (
      <Grid className='shiftInformationEditor'>
        <Grid.Row>
          {closeEnabled && (
            <div className='floatTopRightShift'>
              <Image
                className='tinyCloseButton'
                src='/close_round.svg'
                onClick={() => this.props.onClose(shift)}
              />
            </div>
          )}
          <Form>
            <Form.Group widths='16'>
              <Form.Field width='5'>
                <label htmlFor='shiftStartDate'>Shift Start Date</label>
                <DatePicker
                  id='shiftStartDate'
                  selected={shift.start}
                  onChange={onDateChange}
                  dateFormat='MM/DD/YY'
                  minDate={moment()}
                />
              </Form.Field>
              <Form.Field width='5'>
                <label htmlFor='shiftHours'>Shift Length</label>
                <Input
                  id='shiftHours'
                  type='number'
                  onChange={onHoursChange}
                  min='0'
                  max='24'
                  step='any'
                  className='shiftHours'
                  value={shift.hours ? precisionRound(shift.hours, 2) : 0}
                />
                <span className='hours'>&nbsp;hrs</span>
              </Form.Field>
              <Form.Field width='6'>
                <label htmlFor='requiredService'>Required Service</label>
                <Dropdown
                  id='requiredService'
                  placeholder='choose'
                  fluid
                  selection
                  value={shift.service}
                  onChange={onServiceChange}
                  options={serviceOptions}
                />
              </Form.Field>
            </Form.Group>
            <Divider />
            <Form.Group className='timeGroup'>
              <CareTimePicker
                value={shift.start}
                timezone={shift.timezone}
                onChange={onTimeChange('start')}
              />
              <div
                className='timeSeparator'
                style={{ position: 'relative', top: '-23px', right: '2px' }}
              >
                ›
              </div>
              <CareTimePicker
                value={shift.end}
                timezone={shift.timezone}
                onChange={onTimeChange('end')}
              />
            </Form.Group>
          </Form>
        </Grid.Row>
        <Grid.Row className='checkbox'>
          <div>
            <Button circular icon='checkmark' disabled={!shift.valid} onClick={toggleState} />
          </div>
        </Grid.Row>
      </Grid>
    );
  }
}

ShiftInformationEditor.propTypes = {
  shift: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  services: PropTypes.arrayOf(PropTypes.string).isRequired,
  closeEnabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ShiftInformationEditor;

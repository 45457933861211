/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Divider,
  Image,
  Input,
  Icon,
  Dropdown,
  Loader,
  Modal,
  Button,
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import CGComplianceUIActions from '../../../actions/CGComplianceUIActions';
import { caregiverActions } from '../../../actions';

const checkIcon = '/compliance/check.svg';
const warningIcon = '/compliance/attention.svg';
const expirationDateFormat = 'MM/DD/YYYY';

const REFERENCE_OPTIONS = [
  { text: null, value: null },
  { text: 'Business', value: 'BUSINESS' },
  { text: 'Personal', value: 'PERSONAL' },
  { text: 'Unknown', value: 'UNKNOWN' },
];

class ComplianceBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, docSource: null };
    this.uploadDocument = this.uploadDocument.bind(this);
    this.fieldChanged = this.fieldChanged.bind(this);
    this.uploadDocument = this.uploadDocument.bind(this);
    this.fieldUI = this.fieldUI.bind(this);
    this.documentClicked = this.documentClicked.bind(this);
    this.documentRemoved = this.documentRemoved.bind(this);
    this.checkDoubleValid = this.checkDoubleValid.bind(this);
  }

  getFrequencyName = period => {
    switch (period) {
      case 'half_yearly':
        return '6 months';
      case 'monthly':
        return 'Monthly';
      case 'one_time':
        return 'One time';
      case 'two_years':
        return '2 years';
      case 'three_years':
        return '3 years';
      case 'four_years':
        return '4 years';
      case 'five_years':
        return '5 years';
      case 'six_years':
        return '6 years';
      case 'ten_years':
        return '10 years';
      case 'expiration_date':
        return 'Based on expiration date';
      case 'yearly':
      default:
        return 'Annual';
    }
  };

  // eslint-disable-next-line react/sort-comp
  documentRemoved(fieldId, filename) {
    const { caregiver, dispatch } = this.props;
    dispatch(caregiverActions.removeComplianceDocument(caregiver.id, fieldId, filename));
  }

  documentClicked(link) {
    this.setState({ showModal: true, docSource: link });
  }

  checkDoubleValid(field, value) {
    // eslint-disable-next-line no-restricted-globals
    const isNumber = !isNaN(value);
    const tooLong = value.length > 6;
    const notValid =
      this.props.caregiverComplianceUI && this.props.caregiverComplianceUI.notValidValuesIds;
    if (!isNumber || tooLong) {
      if (!notValid.includes(field.id)) {
        notValid.push(field.id);
      }
    } else if (isNumber && !tooLong) {
      if (notValid.includes(field.id)) {
        const index = notValid.indexOf(field.id);
        notValid.splice(index, 1);
      }
    }

    return this.props.dispatch(
      CGComplianceUIActions.updateState({
        isNotValid: !!notValid.length,
        notValidValuesIds: [...notValid],
      })
    );
  }

  fieldUI(field, error, readOnly = false, notEditableField) {
    const { uploadingDocument } = this.props.caregiverCompliance;
    if (field.type === 'date' && !notEditableField) {
      return (
        <DatePicker
          key={field.id}
          customInput={
            <Input
              disabled={readOnly || notEditableField}
              icon={
                field.value && (
                  <Icon
                    name='remove'
                    basic
                    link
                    onClick={() => {
                      if (!readOnly) this.fieldChanged(field, null);
                    }}
                  />
                )
              }
            />
          }
          placeholderText='Select Date'
          readOnly={readOnly}
          float
          disabled={readOnly || notEditableField}
          name={field.name}
          id={field.id.toString()}
          popperPlacement='top-end'
          selected={field.value && moment.utc(field.value).local()}
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          width={16}
          maxDate={field.name === 'measure_expiration_date' ? false : moment()}
          className={error ? 'dateInput errorInput' : 'dateInput'}
          onChange={e => {
            if (
              parseInt(
                moment(e)
                  .tz(moment.tz.guess())
                  .format('ZZ'),
                10
              ) > 0
            ) {
              this.fieldChanged(
                field,
                moment(e)
                  .endOf('day')
                  .format()
              );
            } else {
              this.fieldChanged(
                field,
                moment(e)
                  .startOf('day')
                  .format()
              );
            }
          }}
          dateFormat={expirationDateFormat}
        />
      );
    }
    if (field.type === 'date' && notEditableField) {
      return (
        <p className={field.value ? 'input-date' : 'not-editable-input-error'}>
          {field.value
            ? `${moment(field.value)
                .local()
                .format('MM/DD/YYYY')}`
            : 'Not Completed'}
        </p>
      );
    }
    if (field.type === 'text') {
      if (notEditableField) {
        return field.value ? (
          <p className='not-editable-input'>{field.value.toLowerCase()}</p>
        ) : (
          <p className='not-editable-input-error'>{`${field.name} missing`}</p>
        );
      }
      if (field.mhFieldName === 'referenceOption') {
        return (
          <Dropdown
            placeholder='Please select ...'
            fluid
            selection
            disabled={readOnly}
            className='compliance-dropdown'
            key={field.id}
            value={field?.value?.toUpperCase()}
            options={REFERENCE_OPTIONS}
            onChange={(e, i) => {
              this.fieldChanged(field, i.value);
            }}
          />
        );
      }
      return (
        <Input
          key={field.id}
          disabled={readOnly || notEditableField}
          value={field.value}
          onChange={(e, input) => {
            this.fieldChanged(field, input.value);
          }}
        />
      );
    }
    if (field.type === 'double') {
      if (notEditableField) {
        return field.value ? (
          <p className='not-editable-input'>{field.value}</p>
        ) : (
          <p className='not-editable-input-error'>{`${field.name} missing`}</p>
        );
      }
      return (
        <Input
          key={field.id}
          disabled={readOnly || notEditableField}
          value={field.value}
          // eslint-disable-next-line no-restricted-globals
          error={isNaN(field.value) || (field.value && field.value.length > 6)}
          onChange={(e, input) => {
            this.fieldChanged(field, input.value);
          }}
        />
      );
    }
    if (field.type === 'supervisor') {
      const employees = this.toEmployeeList(this.props.employee.employeeList);
      const findValue = id => {
        const selected = employees.find(e => e.value === id);
        if (selected) return selected.value;
        return null;
      };
      return (
        <Dropdown
          placeholder='Select supervisor'
          fluid
          selection
          disabled={readOnly}
          className='compliance-dropdown'
          key={field.id}
          value={field.value ? findValue(field.value) : null}
          options={employees}
          onChange={(e, i) => {
            this.fieldChanged(field, i.value);
          }}
        />
      );
    }
    if (field.type === 'document') {
      let addNewClass = 'complianceDocumentItem';
      if (uploadingDocument) addNewClass += ' disabled';

      const previewDocument = (link, filename) => {
        if (filename.endsWith('.pdf')) {
          return (
            <a href={link}>
              <div style={{ textAlign: 'center' }}>.pdf</div>
            </a>
          );
        }

        return (
          <Image
            width='100px'
            height='100px'
            src={link}
            onClick={() => this.documentClicked(link)}
          />
        );
      };

      return (
        <div className='complianceDocumentsContainer' key={field.id}>
          {!field.filesDownloaded && field.value && field.value.length > 0 && (
            <div className='complianceDocumentItem'>
              <div style={{ height: '100px' }}>
                <Loader active inline />
              </div>
            </div>
          )}
          {field.filesDownloaded &&
            field.value &&
            field.value.length > 0 &&
            field.value.map(file => (
              <div className='complianceDocumentItem' key={file}>
                {previewDocument(field.downloadLinks[file.filename], file.filename)}
                {!readOnly && (
                  <Button
                    circular
                    icon='delete'
                    className='documentRemoveButton'
                    onClick={() => this.documentRemoved(field.id, file.filename)}
                  />
                )}
              </div>
            ))}
          {!readOnly && (
            <div className={addNewClass}>
              {uploadingDocument ? (
                <>
                  {uploadingDocument === field.id ? (
                    <div style={{ height: '100px' }}>
                      <Loader active inline />
                    </div>
                  ) : (
                    <p style={{ color: '#ccc' }}>+</p>
                  )}
                </>
              ) : (
                <>
                  <input
                    type='file'
                    accept='.jpg,.png,.jpeg,.gif,.pdf'
                    id={`fileinput${field.id}`}
                    className='fileinput'
                    style={{ display: 'none' }}
                    onChange={e => this.uploadDocument(e, field.id)}
                  />
                  <label htmlFor={`fileinput${field.id}`} style={{ padding: '36px' }}>
                    +
                  </label>
                </>
              )}
            </div>
          )}
        </div>
      );
    }

    // fallback
    return <></>;
  }

  formatDate = date => (date ? date.format(expirationDateFormat) : 'N/A');

  toEmployeeList = employees =>
    [{ value: null, text: 'None' }].concat(
      employees.map(e => ({
        employee: e,
        text: `${e.firstName} ${e.lastName}`,
        value: e.id,
      }))
    );

  validate = block => {
    if (!block) return { valid: false };

    const result = { valid: block.completed, errors: [] };
    if (result.valid) return result;

    // check that fields is fulfilled
    block.fields.forEach(field => {
      if (!field.value || (field.type === 'document' && field.value.length < 1)) {
        result.errors.push(field.name);
      }
    });

    // check expiration date
    if (
      block.expires &&
      ((block.expirationDate &&
        !moment
          .utc(block.expirationDate)
          .local()
          .isSameOrAfter(moment())) ||
        !block.expirationDate)
    ) {
      result.errors.push('expirationDate');
    }

    return result;
  };

  fieldChanged(field, value) {
    const { dirtyFields } = this.props.caregiverComplianceUI;

    let changedValue = value;

    if (field.type === 'double') {
      this.checkDoubleValid(field, value);
      if (value.length === 0) {
        changedValue = null;
      }
    }

    let theField = dirtyFields.find(f => f.id === field.id);
    if (!theField) {
      theField = JSON.parse(JSON.stringify(field));
      dirtyFields.push(theField);
    }
    theField.value = changedValue;

    this.props.dispatch(CGComplianceUIActions.updateState({ dirtyFields }));
  }

  uploadDocument = (e, fieldId) => {
    const { caregiver, dispatch } = this.props;
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      dispatch(caregiverActions.uploadComplianceDocument(fieldId, file, caregiver.id));
    }
    e.target.value = '';
  };

  fieldName = fieldName => {
    if (fieldName === 'measure_expiration_date') {
      return 'Enter expiration date';
    }
    return fieldName;
  };

  render() {
    const {
      block,
      caregiverComplianceUI,
      dispatch,
      minimized,
      divided,
      showViewLink,
      notEditable,
    } = this.props;
    const { dirtyFields } = caregiverComplianceUI;

    const hrManager = true;
    // if we want to prevent schedulers to change compliance values, just put this to hrManager
    //  (employee.employee && isHRManager(employee.employee.roles)) || employee.rootUser;

    const getField = field => {
      if (!field) return null;

      const dirty = dirtyFields.find(f => f.id === field.id);
      if (dirty) {
        dirty.isDirty = true;
        return dirty;
      }
      return field;
    };

    const completionField = getField(
      block.fields.find(
        f =>
          (block.expires &&
            block.expirationPeriod !== 'one_time' &&
            (block.expirationFieldName === f.name ||
              block.expirationFieldName === 'measure_caregiver_hire_date')) ||
          ((!block.expires || block.expirationPeriod === 'one_time') &&
            f.fixed &&
            f.type === 'date' &&
            (f.name === 'Completion' || f.name === 'Completion Date'))
      )
    );

    const expirationField = getField(
      block.fields.find(
        f =>
          block.expires &&
          block.expirationPeriod === 'one_time' &&
          block.expirationFieldName === f.name
      )
    );

    const goToOtherTab = (type, id) => {
      let tab = 0;
      switch (type) {
        case 'medical':
          tab = 1;
          break;
        case 'documentation':
          tab = 2;
          break;
        case 'training':
        case 'inservicetraining':
          tab = 3;
          break;
        default:
          tab = 4;
          break;
      }
      dispatch(CGComplianceUIActions.updateState({ activeTab: tab, selectedBlock: id }));
    };

    const dirty = block.fields
      ? block.fields.reduce((a, c) => (getField(c).isDirty ? true : a), false)
      : false;

    const { valid, errors } = this.validate(block);

    const closeModalClicked = () => {
      this.setState({ showModal: false, docSource: null });
    };

    const elearningBlockSource = b => b?.payload && JSON.parse(b.payload).source === 'elearning';

    return (
      <div ref={this.props.blockRef}>
        {this.state.showModal && (
          <Modal open onClose={closeModalClicked}>
            <Image src={this.state.docSource} />
          </Modal>
        )}
        {divided && <Divider />}
        <Grid style={dirty ? { background: '#FFF1C2' } : {}}>
          <Grid.Row>
            <Grid.Column width={2}>
              <Image style={{ float: 'right' }} src={`/compliance/${block.icon}`} />
            </Grid.Column>
            <Grid.Column width={minimized ? 11 : 12}>
              <p>{block.name}</p>
            </Grid.Column>
            {showViewLink ? (
              <Grid.Column width={3}>
                <div
                  className='small blue unselectable'
                  role='button'
                  tabIndex='0'
                  onClick={() => goToOtherTab(block.type, block.id)}
                >
                  View in {block.type}
                </div>
              </Grid.Column>
            ) : (
              <Grid.Column width={2}>
                {!dirty && !minimized && block.required && !block.schedulable && (
                  <Image src={valid ? checkIcon : warningIcon} />
                )}
              </Grid.Column>
            )}
          </Grid.Row>
          {minimized ? (
            <Grid.Row style={{ marginTop: '-12px' }}>
              <Grid.Column width={2} />
              <Grid.Column width={14}>
                <span>
                  <p className='smallGray'>
                    Expires:{' '}
                    <b className={block.aboutToExpire || block.hasExpired ? 'small red' : 'small'}>
                      {this.formatDate(moment.utc(block.expirationDate).local())}
                    </b>
                  </p>
                </span>
              </Grid.Column>
            </Grid.Row>
          ) : (
            <>
              {block.description && (
                <Grid.Row style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                  <Grid.Column width={2} />
                  <Grid.Column width={14}>
                    <p className='small'>{block.description}</p>
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row>
                <Grid.Column width={2} />
                <Grid.Column width={3}>
                  <div>
                    <p className='smallGray'>Frequency</p>
                    <p className='small'>
                      {this.getFrequencyName(block.expires ? block.expirationPeriod : 'one_time')}
                    </p>
                  </div>
                </Grid.Column>
                <Grid.Column width={5}>
                  {completionField && (
                    <div>
                      <p
                        className={
                          errors.includes(completionField.name) ? 'small red bold' : 'smallGray'
                        }
                      >
                        {this.fieldName(completionField.name)}
                      </p>
                      {this.fieldUI(
                        completionField,
                        errors.includes(completionField.name),
                        !hrManager,
                        notEditable || elearningBlockSource(block)
                      )}
                    </div>
                  )}
                </Grid.Column>
                {block.expires && !expirationField && (
                  <Grid.Column width={5}>
                    <div>
                      <p
                        className={
                          errors.includes('expirationDate') ? 'small red bold' : 'smallGray'
                        }
                      >
                        Expiration date
                      </p>
                      <p
                        className={errors.includes('expirationDate') ? 'red' : ''}
                        style={{ fontSize: '11px' }}
                      >
                        {this.formatDate(
                          block.expirationDate ? moment.utc(block.expirationDate).local() : null
                        )}
                      </p>
                    </div>
                  </Grid.Column>
                )}
                {block.expires && expirationField && !block.lagDays && (
                  <Grid.Column width={5}>
                    <div>
                      <p
                        className={
                          errors.includes('expirationDate') ? 'small red bold' : 'smallGray'
                        }
                      >
                        {expirationField.name}
                      </p>
                      {this.fieldUI(
                        expirationField,
                        errors.includes('expirationDate'),
                        !hrManager,
                        notEditable
                      )}
                    </div>
                  </Grid.Column>
                )}
                {block.expires && expirationField && block.lagDays && (
                  <Grid.Column width={5}>
                    <div>
                      <p className={!expirationField.value ? 'small red bold' : 'smallGray'}>
                        Must complete before
                      </p>
                      <p
                        className={!expirationField.value ? 'red' : ''}
                        style={{ fontSize: '11px' }}
                      >
                        {this.formatDate(block.lagDate ? moment.utc(block.lagDate).local() : null)}
                      </p>
                    </div>
                  </Grid.Column>
                )}
              </Grid.Row>
              {block.fields &&
                block.fields
                  .filter(
                    f =>
                      (!completionField || f.id !== completionField.id) &&
                      (!expirationField || f.id !== expirationField.id)
                  )
                  .map(f => (
                    <Grid.Row key={f.id.toString()}>
                      <Grid.Column width={2} />
                      <Grid.Column width={12}>
                        <div>
                          <p className={errors.includes(f.name) ? 'small red bold' : 'smallGray'}>
                            {f.name}
                          </p>
                          {this.fieldUI(
                            getField(f),
                            errors.includes(f.name),
                            !hrManager,
                            notEditable
                          )}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  ))}
            </>
          )}
        </Grid>
      </div>
    );
  }
}

ComplianceBlock.defaultProps = {
  minimized: false,
  divided: true,
  showViewLink: false,
  notEditable: false,
  blockRef: {},
};

ComplianceBlock.propTypes = {
  block: PropTypes.shape().isRequired,
  caregiverComplianceUI: PropTypes.shape().isRequired,
  caregiverCompliance: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  employee: PropTypes.shape().isRequired,
  caregiver: PropTypes.shape().isRequired,
  minimized: PropTypes.bool,
  divided: PropTypes.bool,
  showViewLink: PropTypes.bool,
  notEditable: PropTypes.bool,
  blockRef: PropTypes.shape(),
};

const mapStateToProps = state => {
  const { caregiverComplianceUI, caregiverCompliance, caregiverDetails, employee } = state;
  return {
    caregiverComplianceUI,
    caregiver: caregiverDetails.caregiver,
    employee,
    caregiverCompliance,
  };
};

export default connect(mapStateToProps)(ComplianceBlock);

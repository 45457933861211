/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Grid, Input, Image, Button, Modal, Header, Label, Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import CareLoader from '../CareLoader';
import { beginningSearch, isValidPhoneNumber } from '../../../constants/Formats';
import states from '../../../constants/States';
import '../../CGCompliance.css';
import { dateFormat } from '../../../helpers/common';

const moment = require('moment');

const mmmmddyyyy = 'MMMM DD, YYYY';
const yyyymmdd = dateFormat;
const locationIcon = '/compliance/MapPin.svg';
const phoneIcon = '/compliance/phone.svg';
const warning = '/compliance/warning.svg';
const editWorkItemIcon = '/preferences_inactive.svg';

class ComplianceDOHWorkhistory extends React.Component {
  // eslint-disable-next-line react/sort-comp
  constructor(props) {
    super(props);
    this.state = { historyFormVisible: false, draftWorkhistory: null };

    this.workhistoryForm = this.workhistoryForm.bind(this);
  }

  stateOptions = states.states.map(x => ({ value: x.abbreviation, text: x.abbreviation }));

  workHistory = (history, index, hrManager) => {
    const startDate = moment(history.start);
    const endDate = history.end ? moment(history.end) : moment();
    let months = endDate.diff(startDate, 'months', true);
    const years = Math.floor(months / 12);
    months %= 12;
    months = Number.parseFloat(months.toFixed(1));

    const editWorkHistory = () => {
      const draftWorkhistory = {
        ...history,
        address1: history.addressLine1,
        address2: history.addressLine2,
        addressLine1: undefined,
        addressLine2: undefined,
      };

      this.setState({ historyFormVisible: true, draftWorkhistory });
    };

    return (
      <div className='listElement' id={index} key={index}>
        {history.source === 'cc' && hrManager && (
          <Image className='editWorkHistoryItem' src={editWorkItemIcon} onClick={editWorkHistory} />
        )}
        <p>
          <b>{history.agency}</b>
        </p>
        <p className='small'>
          <Image inline size='large' className='iconImage' name='location' src={locationIcon} />
          {history.addressLine1}, {history.addressLine2}
        </p>
        <p className='small'>
          <Image inline size='large' className='iconImage' name='phone' src={phoneIcon} />
          {history.phone}
        </p>
        {history.start && (
          <p>
            <b>{moment(history.start).format(mmmmddyyyy)}</b> -{' '}
            <b>
              {history.end ? (
                moment(history.end).format(mmmmddyyyy)
              ) : (
                <font color='#4C3'>Present</font>
              )}
            </b>
            <label className='smallGray'>&nbsp;&#8226;&nbsp;</label>
            {years > 0 && <label className='small'>{years} yrs </label>}
            <label className='smallGray'>{months} mos</label>
          </p>
        )}
      </div>
    );
  };

  workhistoryForm() {
    const { historyFormVisible, draftWorkhistory } = this.state;

    const inputChanged = (valueName, value) => {
      draftWorkhistory[valueName] = value;
      this.setState({ draftWorkhistory });
    };

    const onClose = () => {
      this.setState({ historyFormVisible: false, draftWorkhistory: null });
    };

    const onSubmit = () => {
      this.props.onWorkhistoryAdded(draftWorkhistory);
      onClose();
    };

    const onDelete = () => {
      this.props.onWorkhistoryDeleted(draftWorkhistory);
      onClose();
    };

    const checkPhone = phone => {
      if (phone) return isValidPhoneNumber(phone);
      return true;
    };

    const showDelete = draftWorkhistory.dohId;
    const submitLabel = draftWorkhistory.id ? 'Update' : 'Submit';

    const validate = () =>
      draftWorkhistory.agency &&
      draftWorkhistory.address1 &&
      draftWorkhistory.start &&
      (!draftWorkhistory.end ||
        moment(draftWorkhistory.start).isBefore(moment(draftWorkhistory.end))) &&
      (!draftWorkhistory.phone || checkPhone(draftWorkhistory.phone));

    const itemStyle = { marginTop: '8px', marginBottom: '8px' };

    return (
      <Modal
        closeOnDimmerClick={false}
        size='tiny'
        open={historyFormVisible}
        onClose={onClose}
        closeIcon={<Image src='/icon_close.svg' />}
        style={{ marginTop: '-46vh' }}
        dimmer
        className='workHistoryModal'
      >
        <Modal.Header>
          <Grid centered className='headerGrid'>
            <Grid.Column width={16} textAlign='center'>
              <Header size='tiny' style={{ color: '#364866' }}>
                ADD ITEM TO WORKHISTORY
              </Header>
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <div style={{ marginTop: '24px', marginBottom: '24px', textAlign: 'center' }}>
            <div
              style={{
                marginTop: '32px',
              }}
            >
              <Grid centered>
                <Grid.Row className='companyNameRow'>
                  <Grid.Column width={8}>
                    <Input
                      placeholder='Enter Company Name'
                      size='tiny'
                      id='agencyName'
                      key='agencyName'
                      fluid
                      style={itemStyle}
                      value={draftWorkhistory.agency}
                      onChange={(e, i) => {
                        inputChanged('agency', i.value);
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className='subHeaderRow'>
                  <Grid.Column width={12}>
                    <b>Company Address</b>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={12}>
                    <Input
                      placeholder='Address Line 1'
                      key='addressLine1'
                      id='addressLine1'
                      fluid
                      size='mini'
                      style={itemStyle}
                      value={draftWorkhistory.address1}
                      onChange={(e, i) => {
                        inputChanged('address1', i.value);
                      }}
                    />
                    <Input
                      placeholder='Address Line 2'
                      key='addressLine2'
                      id='addressLine2'
                      size='mini'
                      fluid
                      style={itemStyle}
                      value={draftWorkhistory.address2}
                      onChange={(e, i) => {
                        inputChanged('address2', i.value);
                      }}
                    />

                    {false && (
                      <Grid>
                        <Grid.Column width={6}>
                          <Input
                            placeholder='City'
                            key='city'
                            id='city'
                            fluid
                            style={itemStyle}
                            value={draftWorkhistory.city}
                            onChange={(e, i) => {
                              inputChanged('city', i.value);
                            }}
                          />
                        </Grid.Column>
                        <Grid.Column width={4}>
                          <Form.Select
                            compact
                            className='three wide'
                            size='small'
                            search={beginningSearch}
                            scrolling
                            selection
                            upwards='true'
                            placeholder='State'
                            name='address.state'
                            options={this.stateOptions}
                            style={itemStyle}
                            onChange={(e, i) => {
                              inputChanged('state', i.value);
                            }}
                          />
                        </Grid.Column>

                        <Grid.Column width={6}>
                          <Input
                            placeholder='Zip'
                            key='zip'
                            id='zip'
                            fluid
                            value={draftWorkhistory.zip}
                            style={itemStyle}
                            onChange={(e, i) => {
                              inputChanged('zip', i.value);
                            }}
                          />
                        </Grid.Column>
                      </Grid>
                    )}
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row className='subHeaderRow'>
                  <Grid.Column width={12}>
                    <b>Contact Telephone Number</b>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={12}>
                    <Input
                      placeholder='Phone Number'
                      id='phoneNumber'
                      key='phoneNumber'
                      error={!checkPhone(draftWorkhistory.phone)}
                      fluid
                      size='mini'
                      style={itemStyle}
                      value={draftWorkhistory.phone}
                      onChange={(e, i) => {
                        inputChanged('phone', i.value);
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className='subHeaderRow'>
                  <Grid.Column width={12}>
                    <b>Dates of Employment</b>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <div className='datesOfEmployment'>
                      <DatePicker
                        key='start'
                        customInput={<Input style={itemStyle} size='small' fluid />}
                        placeholderText='Select date'
                        float
                        name='start'
                        id='start'
                        style={itemStyle}
                        popperPlacement='top-end'
                        selected={
                          draftWorkhistory.start ? moment.utc(draftWorkhistory.start).local() : null
                        }
                        showMonthDropdown
                        showYearDropdown
                        maxDate={moment()}
                        dropdownMode='select'
                        onChange={e => {
                          Object.assign(draftWorkhistory, { start: e.toDate() });
                          this.setState({ draftWorkhistory });
                        }}
                        dateFormat={dateFormat}
                      />
                      <div>Until</div>
                      <DatePicker
                        key='end'
                        customInput={<Input style={itemStyle} size='small' fluid />}
                        placeholderText='Select date'
                        float
                        name='end'
                        id='end'
                        popperPlacement='top-end'
                        selected={
                          draftWorkhistory.end ? moment.utc(draftWorkhistory.end).local() : null
                        }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        onChange={e => {
                          Object.assign(draftWorkhistory, { end: e.toDate() });
                          this.setState({ draftWorkhistory });
                        }}
                        dateFormat={dateFormat}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Grid centered className='buttonArea'>
            <Grid.Column width={10} textAlign='center'>
              {showDelete && (
                <Button color='green' content='Delete' style={itemStyle} onClick={onDelete} />
              )}
              <Button
                color='green'
                content={submitLabel}
                style={itemStyle}
                onClick={onSubmit}
                disabled={!validate()}
              />
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
    );
  }

  render() {
    const { historyFormVisible } = this.state;
    const information = this.props.dohInfo ? this.props.dohInfo : {};
    const showWorkHistoryForm = () => {
      this.setState({ historyFormVisible: true, draftWorkhistory: {} });
    };

    return (
      <div className='infoPanel' key='work_history'>
        <p className='tileHeader'>WORK HISTORY</p>
        {!information || this.props.loadingDoh ? (
          <CareLoader loading centered showText={false} />
        ) : (
          <>
            {information.workHistory && !information.previousEmploymentVerification && (
              <Label
                basic
                image
                color='red'
                style={{
                  marginLeft: '12px',
                  marginTop: '12px',
                  marginBottom: '12px',
                  border: 'none',
                }}
              >
                <Image spaced='right' src={warning} />
                Last employed over 2 years ago. Training required.
              </Label>
            )}

            {information.workHistory &&
              information.workHistory.map((history, index) =>
                this.workHistory(history, index, this.props.hrManager)
              )}

            <br />
            <p className='smallGray'>Updated: {moment(information.modified).format(yyyymmdd)}</p>
            {this.props.hrManager && (
              <Button
                style={{ marginLeft: '50%', marginRight: '50%', marginTop: '12px' }}
                icon='plus'
                circular
                onClick={showWorkHistoryForm}
              />
            )}
            <br />
            {historyFormVisible && this.workhistoryForm()}
          </>
        )}
      </div>
    );
  }
}

ComplianceDOHWorkhistory.defaultProps = {
  dohInfo: {},
  loadingDoh: true,
  hrManager: false,
};

ComplianceDOHWorkhistory.propTypes = {
  dohInfo: PropTypes.shape(),
  loadingDoh: PropTypes.bool,
  onWorkhistoryAdded: PropTypes.func.isRequired,
  onWorkhistoryDeleted: PropTypes.func.isRequired,
  hrManager: PropTypes.bool,
};

export default injectIntl(ComplianceDOHWorkhistory);

import React, { useEffect } from 'react';
import {
  TabMenuItem,
  TabMenuBar,
  TabContainer,
  TabContent,
  TabsButtonsContent,
} from './CCTabStyles';

interface ITabPane {
  menuItem: string;
  key: string;
  content: JSX.Element;
}

interface IProps {
  defaultTab: string;
  panes: ITabPane[];
  actionItems?: JSX.Element;
  activeTab: number | string;
  setActiveTab: (tab: number | string) => void;
}

const CCTab: React.FC<IProps> = ({ defaultTab, panes, actionItems, activeTab, setActiveTab }) => {
  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);
  return (
    <TabContainer>
      <TabMenuBar role='tablist'>
        <TabsButtonsContent>
          {panes.map(p => {
            const handleClick = () => {
              setActiveTab(p.key);
            };
            const active = activeTab === p.key;
            return (
              <TabMenuItem
                role='tab'
                key={p.key}
                aria-selected={active}
                active={active}
                tabIndex={active ? -1 : 0}
                type='button'
                onClick={handleClick}
              >
                {p.menuItem}
              </TabMenuItem>
            );
          })}
        </TabsButtonsContent>
        {actionItems}
      </TabMenuBar>
      <div>
        {panes.map(p => {
          const active = activeTab === p.key;
          return (
            <TabContent key={p.key} role='tabpanel' tabIndex={active ? -1 : 0} active={active}>
              {active && p.content}
            </TabContent>
          );
        })}
      </div>
    </TabContainer>
  );
};

export default CCTab;

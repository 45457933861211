import React from 'react';
import { Image } from 'semantic-ui-react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  color: #4a4a4a;
  width: 100%;
  height: 366px;
  background: #f7f7f7;
  position: fixed;
  top: 240px;
`;

const HeaderText = styled.p<{ sideMargin: number }>`
  font-weight: bold;
  font-size: 16px;
  padding-top: 20px;
  margin-left: ${props => props.sideMargin}%;
  margin-right: ${props => props.sideMargin}%;
`;

const BodyText = styled.p<{ sideMargin: number }>`
  font-weight: normal;
  font-size: 12px;
  margin-left: ${props => props.sideMargin}%;
  margin-right: ${props => props.sideMargin}%;
`;

interface Props {
  header: string;
  title: string;
  body?: string;
}

export const NoModuleAuthorizationBanner: React.FC<Props> = ({ header, title, body }) => {
  return (
    <Container>
      <HeaderText sideMargin={10}>{header}</HeaderText>
      <Image centered src='/PrefsUpdate.svg' alt='reports disabled' />
      <HeaderText sideMargin={10}>{title}</HeaderText>
      {body && <BodyText sideMargin={10}>{body}</BodyText>}
    </Container>
  );
};

import * as yup from 'yup';
import moment from 'moment';
import { backend } from '../services';
import { isValidTimezone } from './common';

const PHONEREGX = /^[0-9+() -.]+$/;

const SSNREGX = /^\d{3}-\d{2}-\d{4}$/;

const SURVEY_DAYS_REGX = /^[1-7](,[1-7]){0,6}$/;

const SEND_AT_REGEX = /^(?:[01]\d|2[0-4]):(?:[0-5]\d):(?:[0-5]\d)$/;

const DOB_MDY_REGEX = /^(?:([1-9]|0[1-9])|1[012])([/.-])(?:([1-9]|0[1-9])|[12]\d|3[01])([/.-])(?:19|20)\d\d$/;

const DOB_YMD_REGEX = /^(?:19|20)\d\d([/.-])(?:([1-9]|0[1-9])|1[012])([/.-])(?:([1-9]|0[1-9])|[12]\d|3[01])$/;

const correctPhoneLength = (value: string) => value.length <= 30 && value.length >= 6;

const validMaritalStatus = ['s', 'm', 'd', 'w'];

const validGenders = ['m', 'f', 'u'];

const validTitles = ['mr', 'mrs', 'miss', 'ms', 'dr'];

const validEthnicity = ['0', '1', '2', '3', '4', '5'];

const validEmergencyContactRelation = ['parent', 'sibling', 'child', 'spouse', 'other'];

const validBooleanValue = ['1', '0'];

const stringValidation = (char: number) =>
  yup
    .string()
    .required()
    .max(char);

const notRequiredString = (char: number) =>
  yup.string().test('text', 'Text length exceed the require length', value => {
    if (value) {
      return value.length <= char;
    }
    return true;
  });

const date = yup.date().required();

const caregiverEmployeeStatus = yup
  .string()
  .lowercase()
  .oneOf(['pending', 'hold', 'cancelled', 'terminated', 'active']);

const mobilePhone = yup
  .string()
  .test('Mobile Phone', 'Mobile Phone should match a correct phone format', value => {
    if (value) {
      return !!value.match(PHONEREGX) && correctPhoneLength(value);
    }
    return true;
  });

const homePhone = yup
  .string()
  .test('Home Phone', 'Home Phone should match a correct phone format', value => {
    if (value) {
      return !!value.match(PHONEREGX) && correctPhoneLength(value);
    }
    return true;
  });

const workPhone = yup
  .string()
  .test('Work Phone', 'Work Phone should match a correct phone format', value => {
    if (value) {
      return !!value.match(PHONEREGX) && correctPhoneLength(value);
    }
    return true;
  });

const maritalStatus = yup
  .string()
  .test('Marital Status', 'Marital status should match one of ["s", "m", "d", "w"]', value => {
    if (value) {
      return validMaritalStatus.includes(value.toLowerCase());
    }
    return true;
  });

const gender = yup.string().test('Gender', 'Gender should match one of ["m", "f", "o"]', value => {
  if (value) {
    return validGenders.includes(value.toLowerCase());
  }
  return true;
});

const titles = yup
  .string()
  .test('Title', 'Title should match one of ["mr", "mrs", "miss", "ms", "dr"]', value => {
    if (value) {
      return validTitles.includes(value.toLowerCase());
    }
    return true;
  });

const ethnicity = yup
  .string()
  .test('Ethnicity', 'Ethnicity should match one of ["0", 1", "2", "3", "4", "5"]', value => {
    if (value) {
      return validEthnicity.includes(value.toLowerCase());
    }
    return true;
  });

const emergencyContactRelation = yup
  .string()
  .test(
    'Emergency Contact Relation',
    'Emergency Contact Relation should match one of ["parent", "sibling", "child", "spouse", "other"]',
    value => {
      if (value) {
        return validEmergencyContactRelation.includes(value.toLowerCase());
      }
      return true;
    }
  );

const blockShift = yup
  .string()
  .test('blockShift', 'blockShift should match one of ["1","0"]', value => {
    if (value) {
      return validBooleanValue.includes(value);
    }
    return true;
  });

const boolean = yup.string().test('boolean', 'value should match one of ["1","0"]', value => {
  if (value) {
    return validBooleanValue.includes(value);
  }
  return true;
});

const notRequiredDate = yup.string().test('Date', 'Date should match Date format', value => {
  if (value) {
    return moment.isMoment(moment(value));
  }
  return true;
});

const surveyDays = yup
  .string()
  .test('surveyDays', 'days should be number 1 to 7 separate by comma', value => {
    if (value) {
      const trimValues = value.replace(/\s/g, '');
      const splitValues = trimValues.split(';');
      const findMissMatch = splitValues.filter((v: string) => !v.match(SURVEY_DAYS_REGX));
      return !findMissMatch.length;
    }
    return true;
  });

const sendAt = yup.string().test('send_at', 'send at should match format of HH:mm:ss', value => {
  if (value) {
    const splitValues = value.split(',');
    const findMissMatch = splitValues.filter((v: string) => !v.match(SEND_AT_REGEX));
    return !findMissMatch.length;
  }
  return true;
});

const dob = yup
  .string()
  .test(
    'date of birth',
    'Min age is 16 years and date of birth must match one of the formats: "MM/DD/YYYY", "YYYY/MM/DD"',
    value => {
      const validDob =
        (value?.match(DOB_MDY_REGEX) || value?.match(DOB_YMD_REGEX)) &&
        moment(value).isBefore(moment().subtract('16', 'years'));
      return !!validDob;
    }
  );

const requiredTimezone = yup
  .string()
  .required()
  .test(
    'timezone',
    'Timezone must be one of the IANA timezone database names, for example "America/New_York"',
    value => isValidTimezone(value)
  );

const caregiverSchema = yup.array().of(
  yup.object().shape({
    externalId: stringValidation(30),
    agencyExternalId: stringValidation(30),
    lastModified: date,
    firstName: stringValidation(30),
    lastName: stringValidation(30),
    middleName: notRequiredString(30),
    gender,
    suffix: titles,
    ethnicity,
    marital: maritalStatus,
    blockShiftMatching: blockShift,
    complianceEndDate: notRequiredDate,
    notificationRecipients: notRequiredString(500),
    hireDate: notRequiredDate,
    activationDate: notRequiredDate,
    facebookLink: notRequiredString(250),
    linkedinLink: notRequiredString(250),
    ssn: yup
      .string()
      .test(
        'SSN',
        'SSN is a required field and should match a correct format xxx-xx-xxxx',
        value => {
          const settings = backend.getCachedSettings();
          const ssnRequired =
            settings && settings.ssnRequired != null ? settings.ssnRequired : true;

          return (!value && !ssnRequired) || !!value?.match(SSNREGX);
        }
      ),
    dob,
    staffStatus: caregiverEmployeeStatus.required(),
    street: stringValidation(200),
    street2: notRequiredString(200),
    apartment: notRequiredString(10),
    city: stringValidation(50),
    state: stringValidation(2),
    zip: stringValidation(50),
    countryCode: stringValidation(2),
    primaryLanguage: yup.string().required(),
    mobilePhone: yup.string().when('email', {
      is: '',
      then: yup
        .string()
        .test(
          'Mobile Phone',
          'Mobile Phone OR Email is a required field and should match a correct phone or email format',
          value => !!value?.match(PHONEREGX) && correctPhoneLength(value)
        ),
      otherwise: yup
        .string()
        .test('Mobile Phone', 'Mobile Phone should match a correct phone format', value => {
          if (value) {
            return !!value.match(PHONEREGX) && correctPhoneLength(value);
          }
          return true;
        }),
    }),
    homePhone,
    workPhone,
    email: yup.string().email(),
    primaryContactExternalId: notRequiredString(30),
    emergencyContactName: notRequiredString(30),
    emergencyContactNumber: mobilePhone,
    emergencyContactRelation,
  })
);

const employeeSchema = yup.array().of(
  yup.object({
    externalId: stringValidation(30),
    agencyExternalId: stringValidation(30),
    lastModified: date,
    firstName: stringValidation(30),
    lastName: stringValidation(30),
    middleName: notRequiredString(30),
    gender,
    ssn: yup.string().test('SSN', 'SSN should match a correct format xxx-xx-xxxx', value => {
      if (value) {
        return !!value.match(SSNREGX);
      }
      return true;
    }),
    dob: notRequiredDate,
    email: yup
      .string()
      .required()
      .email(),
    staffStatus: caregiverEmployeeStatus,
    isCoordinator: boolean,
    isManager: boolean,
    unit: notRequiredString(30),
    location: notRequiredString(30),
    surveyName: notRequiredString(300),
    days: surveyDays,
    sendAt,
  })
);

const clientSchema = yup.array().of(
  yup.object({
    agencyExternalId: stringValidation(30),
    externalId: stringValidation(30),
    status: yup
      .string()
      .lowercase()
      .oneOf(['active', 'pending', 'hold', 'inactive', 'cancelled']),
    lastModified: date,
    firstName: stringValidation(30),
    lastName: stringValidation(30),
    middleName: notRequiredString(30),
    gender,
    title: notRequiredString(4),
    mobilePhone,
    homePhone,
    workPhone,
    street: stringValidation(200),
    street2: notRequiredString(200),
    apartment: notRequiredString(10),
    city: stringValidation(50),
    state: stringValidation(2),
    zip: stringValidation(50),
    countryCode: stringValidation(2),
    startDate: notRequiredDate,
    holdDate: notRequiredDate,
    referralDate: notRequiredDate,
    dischargeDate: notRequiredDate,
    cancelDate: notRequiredDate,
    defaultService: notRequiredString(32),
    priorityCode: notRequiredString(6),
    primaryLanguage: yup.string().required(),
    primaryContactExternalId: notRequiredString(30),
    notes: notRequiredString(2048),
  })
);
const shiftSchema = yup.array().of(
  yup.object({
    agencyExternalId: stringValidation(30),
    externalId: stringValidation(30),
    lastModified: date,
    clientExternalId: stringValidation(30),
    start: date,
    end: date,
    service: stringValidation(6),
    status: yup
      .string()
      .lowercase()
      .oneOf(['pending', 'confirmed', 'in process', 'cancelled', 'closed', 'hold']),
    type: yup
      .string()
      .lowercase()
      .oneOf(['scheduled', 'general', 'training']),
    eventCode: notRequiredString(20),
    clientScheduleType: notRequiredString(20),
    employeeScheduleType: notRequiredString(20),
    admissionType: notRequiredString(20),
    company: notRequiredString(20),
    payerId: notRequiredString(2),
    payerName: notRequiredString(100),
    serviceDescription: notRequiredString(100),
    cancellationCode: notRequiredString(6),
    recurringPattern: notRequiredString(20),
    template: boolean,
    notes: notRequiredString(1000),
    assignTo: notRequiredString(100),
    timezone: requiredTimezone,
  })
);

export { caregiverSchema, employeeSchema, clientSchema, shiftSchema };

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Grid, Image, Checkbox, Icon, Header, Segment } from 'semantic-ui-react';
import { analytics, Events } from '../../helpers/analytics';

import { caregiverActions } from '../../actions';
import { avatarUrl } from '../../constants/DomainTypes';
import {
  formatTime,
  SHORT_DATE,
  MOMENT_HOURS,
  MOMENT_DATE,
  MOMENT_HOURS_WITH_DATE,
  formatHours,
} from '../../constants/Formats';

import './ShiftRequests.css';
import { caregiverHelpers } from '../../helpers/caregiverHelpers';

const DirectAssignNote = ({ submit, disabled }) => {
  const clicked = () => {
    submit();
  };

  const closeClicked = e => {
    submit(true);
    if (e && e.nativeEvent) {
      e.nativeEvent.stopImmediatePropagation();
    }
  };

  return (
    <Modal
      closeOnDimmerClick={false}
      open
      closeIcon
      dimmer
      size='tiny'
      className='invitationModel'
      onClose={closeClicked}
      style={{ marginTop: '-20vh' }}
    >
      <Modal.Header
        className='assignedHeader'
        style={{
          backgroundColor: '#F16778',
        }}
      >
        <Header textAlign='center' as='h2'>
          Directly Assign Caregiver?
        </Header>
      </Modal.Header>
      <Modal.Content>
        <Grid centered>
          <Grid.Column width={14}>
            <Segment basic>
              <p>
                By assigning the selected caregiver you will bypass the invitation process and
                immediately update the status of the associated shift.
              </p>
              <p style={{ fontWeight: 'bold' }}>
                Only proceed if you are sure that your caregiver has acknowledged and agreed that
                they are available and willing to undertake the shift.
              </p>
            </Segment>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Grid centered>
          <Grid.Column width={14} textAlign='center'>
            <Button
              id='shift-requests-cancel-button'
              style={{ width: '140px', marginRight: '15px' }}
              onClick={closeClicked}
              className='care-red'
            >
              Cancel
            </Button>
            <Button
              id='shift-requests-continue-button'
              style={{ width: '140px' }}
              onClick={clicked}
              className='care-green'
              disabled={disabled}
            >
              Continue
            </Button>
          </Grid.Column>
        </Grid>
      </Modal.Actions>
    </Modal>
  );
};

DirectAssignNote.propTypes = {
  submit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

class ShiftRequests extends React.Component {
  constructor() {
    super();
    this.onToggleDialog = this.onToggleDialog.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.assignSelected = this.assignSelected.bind(this);
    this.remindClicked = this.remindClicked.bind(this);
    this.onNoteOpen = this.onNoteOpen.bind(this);
    this.closeShiftRequestModal = this.closeShiftRequestModal.bind(this);
  }

  // eslint-disable-next-line react/state-in-constructor
  state = {
    visible: false,
    selectedShifts: {},
    count: 0,
    hours: 0,
    directAssignNote: false,
    noSelectedShifts: true,
    assignedCount: 0,
    assignedHours: 0,
  };

  onToggleDialog() {
    this.setState(s => ({
      ...s,
      visible: !s.visible,
    }));
  }

  onCheckboxChange(e, input) {
    const { caregiver } = this.props;
    const { assignedCount, assignedHours } = this.state;
    this.setState(s => {
      const newSelected = { ...s.selectedShifts };
      newSelected[input.shiftid] = newSelected[input.shiftid] ? !newSelected[input.shiftid] : true;

      const { count, hours } = caregiver.shifts.reduce(
        (a, b) => {
          if (newSelected[b.id]) {
            return { count: a.count + 1, hours: a.hours + b.hours };
          }
          return a;
        },
        { count: assignedCount, hours: assignedHours }
      );

      return { ...s, selectedShifts: newSelected, count, hours, noSelectedShifts: count === 0 };
    });
  }

  onNoteOpen() {
    const { caregiver } = this.props;
    const hasSelectd = caregiver.shifts.filter(s => this.state.selectedShifts[s.id]);
    const hasOffered = hasSelectd.filter(o => o.status === 'offered');
    if (hasOffered.length !== 0) {
      this.setState({ directAssignNote: true, noSelectedShifts: true });
    } else {
      this.assignSelected();
      this.setState({ directAssignNote: false, noSelectedShifts: true });
    }
  }

  closeShiftRequestModal() {
    this.setState({
      visible: false,
      count: 0,
      hours: 0,
      assignedCount: 0,
      assignedHours: 0,
    });
  }

  assignSelected(cancel) {
    if (!cancel) {
      const { caregiver, caregiverScores } = this.props;
      const { count, hours } = this.state;

      const cgShifts = caregiver.shifts.filter(s => this.state.selectedShifts[s.id]);
      const shiftIdList = cgShifts
        .filter(shift => shift.shiftAssigmentStatus !== 'assigned')
        .map(s => s.id);
      const logMeta = caregiverHelpers.logMetasForCaregiverCost(
        [caregiver],
        cgShifts,
        caregiverScores
      );
      this.props.dispatch(
        caregiverActions.inviteCaregiver(
          caregiver,
          shiftIdList,
          'assigned',
          undefined,
          undefined,
          logMeta
        )
      );
      this.setState({ selectedShifts: [], assignedCount: count, assignedHours: hours });
      analytics.track(Events.CAREGIVERS_SHIFTS_ASSIGN_CAREGIVER);
    }

    this.setState({ directAssignNote: false });
  }

  remindClicked() {
    this.setState(s => ({ ...s, remindSent: true }));
    this.props.handleRemind(this.props.caregiver);
  }

  render() {
    const { caregiver, client, shiftList, showAsResponses, asAssignButton, loading } = this.props;
    const {
      declineAll,
      lastDeclined,
      lastOffered,
      lastApplied,
      assignAll,
    } = caregiver.actionStatus;
    const {
      visible,
      selectedShifts,
      count,
      hours,
      directAssignNote,
      noSelectedShifts,
    } = this.state;

    if (!visible) {
      if (showAsResponses) {
        if (showAsResponses === 'declines') {
          const allDeclined = shiftList.length === 1 ? 'Declined' : 'All Declined';
          return (
            <div className='responsesLink left'>
              <span
                role='button'
                tabIndex={0}
                className='link icon-red'
                onClick={this.onToggleDialog}
              >
                {declineAll ? allDeclined : 'Partial Declined'}
              </span>
              <br />
              <span className='time'>{formatTime(lastDeclined, null, MOMENT_DATE)}</span>
            </div>
          );
        }

        if (showAsResponses === 'applies') {
          const allRequested = shiftList.length === 1 ? 'Requested' : 'All Requested';
          return (
            <div className='responsesLink left'>
              <span
                role='button'
                tabIndex={0}
                className='link icon-green'
                onClick={this.onToggleDialog}
              >
                {assignAll ? allRequested : 'Requests'}
              </span>
              <br />
              <span className='time'>{formatTime(lastApplied, null, MOMENT_DATE)}</span>
            </div>
          );
        }
      } else {
        if (asAssignButton) {
          return [
            <Button
              id='shift-requests-assign-button'
              key={`aall_${caregiver.id}`}
              caregiverid={caregiver.id}
              className='care-green partialAssignButton'
              compact
              basic
              size='tiny'
              onClick={this.onToggleDialog}
            >
              Assign
            </Button>,
          ];
        }

        let invitedText = 'Invited';
        const shifts = caregiver.shifts.filter(s => !!shiftList.find(i => i.id === s.id));
        if (shifts && shifts.length === 1 && shifts[0].numInvitesSent > 1) {
          invitedText += ` ${shifts[0].numInvitesSent} times`;
        }

        return [
          <div className='requestLink'>
            <span
              id='shift-requests-invited-text'
              role='button'
              tabIndex={0}
              className='link'
              onClick={this.onToggleDialog}
            >
              {lastApplied && <Icon name='circle' className='icon-green' />}
              {invitedText}
            </span>
            <br />
            <span className='time'>{formatTime(lastOffered, null, MOMENT_HOURS_WITH_DATE)}</span>
          </div>,
          <div className='requestLink'>
            <Button
              id='shift-requests-done-remind-button'
              basic
              compact
              size='tiny'
              onClick={this.remindClicked}
              loading={this.props.loading}
              disabled={this.state.remindSent}
            >
              {this.state.remindSent ? 'Done' : 'Remind'}
            </Button>
          </div>,
          <div style={{ clear: 'both' }} />,
        ];
      }
    }

    const statusName = status => {
      switch (status) {
        case 'assigned':
          return 'Assigned';
        case 'offered':
          return 'Invited';
        case 'applied':
          return 'Requested';
        case 'declined':
          return 'Declined';
        case 'cancelled':
          return 'Staffed';
        default:
          return 'NA';
      }
    };

    const shifts = caregiver.shifts
      .filter(s => !!shiftList.find(i => i.id === s.id))
      .map(s => (
        <Grid.Row key={`shiftrow_${s.id}`} columns='six' className='shiftRow'>
          <Grid.Column className='date'>{formatTime(s.start, s.timezone, SHORT_DATE)}</Grid.Column>
          <Grid.Column>{formatTime(s.start, s.timezone, MOMENT_HOURS)}</Grid.Column>
          <Grid.Column>{formatTime(s.end, s.timezone, MOMENT_HOURS)}</Grid.Column>
          <Grid.Column>{formatHours(s.hours)}</Grid.Column>
          <Grid.Column className='icon-blue'>{statusName(s.status)}</Grid.Column>
          <Grid.Column>
            {(s.status === 'applied' ||
              (s.status === 'offered' && caregiver.status === 'active')) &&
              !!shiftList.find(i => i.id === s.id && i.shiftAssigmentStatus !== 'assigned') && (
                <Checkbox
                  shiftid={s.id}
                  disabled={caregiver.status !== 'active'}
                  onChange={this.onCheckboxChange}
                  checked={selectedShifts[s.id]}
                />
              )}
          </Grid.Column>
        </Grid.Row>
      ));

    const { avatar } = caregiver;
    if (visible) {
      return (
        <Modal
          closeOnDimmerClick={false}
          open
          size='tiny'
          closeIcon
          onClose={this.closeShiftRequestModal}
          className='requestsModal'
          style={{ top: '15%' }}
        >
          <Modal.Header className='header'>
            <div className='img left floated'>
              <Image avatar centered circular size='mini' floated='left' src={avatarUrl(avatar)} />
            </div>
            <div className='left floated caregiver'>
              <div className='main'>{caregiver.caregiverName}</div>
            </div>
            <div className='right floated'>
              <div className='sub'>Client</div>
              <div className='main'>{client.clientName}</div>
            </div>
            <div style={{ clear: 'both' }} />
          </Modal.Header>
          <Modal.Content>
            <Grid>{shifts}</Grid>
            {directAssignNote && (
              <DirectAssignNote submit={this.assignSelected} disabled={loading} />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Grid>
              <Grid.Column width='7' verticalAlign='middle'>
                <div className='right floated'>
                  {count > 0 && `${count} shifts + ${formatHours(hours)}`}
                </div>
              </Grid.Column>
              <Grid.Column width='9' verticalAlign='middle'>
                <Button
                  id='shift-requests-assign-selected-button'
                  caregiverid={caregiver.id}
                  className='care-green'
                  compact
                  size='tiny'
                  onClick={this.onNoteOpen}
                  disabled={noSelectedShifts || caregiver.status !== 'active'}
                  loading={!noSelectedShifts && loading}
                  floated='right'
                >
                  Assign Selected
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Actions>
        </Modal>
      );
    }

    return <span />;
  }
}

ShiftRequests.defaultProps = {
  showAsResponses: false,
  asAssignButton: false,
  loading: false,
  caregiverScores: null,
};

ShiftRequests.propTypes = {
  dispatch: PropTypes.func.isRequired,
  caregiver: PropTypes.shape().isRequired,
  client: PropTypes.shape().isRequired,
  showAsResponses: PropTypes.bool,
  asAssignButton: PropTypes.bool,
  handleRemind: PropTypes.func.isRequired,
  shiftList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool,
  caregiverScores: PropTypes.shape(),
};

export default ShiftRequests;

/* eslint-disable import/prefer-default-export */
import * as types from '../constants/ActionTypes';
import { backend } from '../services';
/* eslint-disable import/no-cycle */
import { alertActions } from './alert';
import { schedulerActions } from './schedules';
import { employeeActions } from './employee';
import { importActions } from './imports';
import { heartbeatActions } from './heartbeat';
/* eslint-enable import/no-cycle */

import { iot } from '../services/carechat';
import { notificationHelpers } from './notificationHelpers';

function prepareMessage(msg) {
  return {
    text: msg.text,
    timestamp: new Date(msg.timestamp),
    sender: msg.sender,
    messageId: msg.messageId,
  };
}

function disconnectIOT() {
  return () => {
    iot.disconnect();
  };
}

function connectIOT() {
  return (dispatch, getState) => {
    dispatch(request());
    const userId = getState().principal.user.id;
    const webportalMessages = (topic, messageObj) => {
      switch (messageObj.type) {
        case 'SHIFT_UPDATED': {
          if (
            (messageObj.reason !== 'assigned' ||
              notificationHelpers.isActiveShift(messageObj.shiftId, getState())) &&
            (userId === messageObj.schedulerId || userId === messageObj.primaryContact)
          ) {
            dispatch(
              schedulerActions.shiftNotification(
                messageObj.shiftId,
                messageObj.reason,
                messageObj.caregiverId,
                messageObj.schedulerId,
                getState()
              )
            );
          }
          break;
        }
        case 'AVATAR_UPDATED': {
          const employeeId = notificationHelpers.getEmployeeId(messageObj.userId, getState());
          if (employeeId) {
            dispatch(
              employeeActions.getEmployee(
                employeeId,
                notificationHelpers.getEmployeeEditor(messageObj.userId, getState()),
                true
              )
            );
          }
          break;
        }
        case 'SHIFT_CSV_EXPORT_READY': {
          if (userId === messageObj.schedulerId) {
            const lnk = document.createElement('a');
            const url = messageObj.message;

            lnk.download = 'shift records' || 'untitled';
            lnk.href = url;
            lnk.dispatchEvent(new MouseEvent('click'));
          }
          dispatch(csvSuccess());
          break;
        }
        case 'SHIFT_CSV_EXPORT_ERROR': {
          if (userId === messageObj.schedulerId) {
            dispatch(alertActions.error(messageObj.message));
            dispatch(csvFailure());
          }
          break;
        }
        case 'FILE_EXPORT_READY': {
          if (userId === messageObj.userId) {
            const lnk = document.createElement('a');
            const { url } = messageObj;

            lnk.download = 'shift records' || 'untitled';
            lnk.href = url;
            lnk.dispatchEvent(new MouseEvent('click'));
          }
          dispatch(csvSuccess());
          break;
        }
        case 'FILE_EXPORT_ERROR': {
          if (userId === messageObj.userId) {
            dispatch(alertActions.error(messageObj.message));
            dispatch(csvFailure());
          }
          break;
        }
        case 'IMPORT_UPDATED': {
          dispatch(importActions.importNotification(messageObj.import));
          break;
        }
        case 'IOT_HEARTBEAT': {
          dispatch(heartbeatActions.heartbeatReceived(messageObj.timestamp));
          break;
        }
        default:
      }
    };

    window.webpush = () => {
      webportalMessages('', {
        type: 'SHIFT_UPDATED',
        shiftId: 5,
        reason: 'applied',
        schedulerId: 123,
      });
      // webportalMessages('', { type: 'IMPORT_UPDATED', import: { id: 200 } });
    };

    const chatMessages = (topic, messageObj) => {
      switch (messageObj.type) {
        case 'message':
          dispatch(message(messageObj.conversationId, messageObj, getState()));
          break;
        case 'activeConversations':
          dispatch(activeConversations(messageObj.conversations));
          break;
        case 'conversationInfo':
          dispatch(message(messageObj.conversationId, messageObj, getState()));
          break;
        default:
      }
    };

    const onMessage = (topic, messagePayload) => {
      const messageObj = JSON.parse(messagePayload.toString());
      if (topic.startsWith('webportal')) {
        webportalMessages(topic, messageObj);
      } else {
        chatMessages(topic, messageObj);
      }
    };

    const onLifecycleEvent = (eventName, online, error) => {
      dispatch({ type: types.IOT_LIFECYCLE, eventName, online, error });
    };

    backend
      .getIOTKeys()
      .then(
        iotConfig => {
          iot.connect(
            iotConfig.topics,
            iotConfig.endpoint,
            iotConfig.region,
            iotConfig.accessKey,
            iotConfig.secretKey,
            iotConfig.sessionToken,
            onMessage,
            onLifecycleEvent
          );
          dispatch(success(iotConfig));
        },
        error => {
          dispatch(failure(error.message));
          dispatch(alertActions.error(error));
        }
      )
      .catch(() => {
        // dispatch(failure(error.message));
        // dispatch(alertActions.error(error.message));
      });
  };

  function request() {
    return { type: types.IOT_CONNECT_REQUEST };
  }
  function success(config) {
    return { type: types.IOT_CONNECT_SUCCESS, config };
  }
  function csvSuccess() {
    return { type: types.GET_SHIFT_CHANGELOG_CSV_SUCCESS };
  }
  function csvFailure(error) {
    return { type: types.GET_SHIFT_CHANGELOG_CSV_FAILURE, error };
  }

  function message(conversationId, messages, state) {
    const messageList = (Array.isArray(messages) ? messages : [messages]).map(msg =>
      prepareMessage(msg)
    );

    return {
      type: types.IOT_CONNECT_MESSAGE,
      message: messageList,
      conversationId,
      principal: state.principal,
    };
  }
  function activeConversations(conversations) {
    return { type: types.IOT_CONNECT_ACTIVE_CONVERSATION, conversations };
  }

  function failure(error) {
    return { type: types.IOT_CONNECT_FAILURE, error };
  }
}

function sendMessage(message, conversationId) {
  iot.sendMessage(message, conversationId);
}

function readAck(conversationId, timestamp) {
  iot.readAck(conversationId, timestamp);
}

function markConversationRead(conversationId) {
  return { type: types.MARK_CONVERSATION_READ, conversationId };
}

function closeConversation(conversationId) {
  return { type: types.CLOSE_CONVERSATION, conversationId };
}

function createConversation(caregiverId, shiftId) {
  return dispatch => {
    dispatch(request());
    backend.createConversations(caregiverId, shiftId).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_ACTIVE_CONVERSATIONS_REQUEST };
  }
  function success(results) {
    return { type: types.GET_ACTIVE_CONVERSATIONS_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_ACTIVE_CONVERSATIONS_FAILURE, error };
  }
}

function getActiveConversations() {
  return dispatch => {
    dispatch(request());
    backend.getActiveConversations({ containsNewMessages: true }).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_ACTIVE_CONVERSATIONS_REQUEST };
  }
  function success(results) {
    return { type: types.GET_ACTIVE_CONVERSATIONS_SUCCESS, results };
  }
  function failure(error) {
    return { type: types.GET_ACTIVE_CONVERSATIONS_FAILURE, error };
  }
}

function getConversation(conversationId) {
  return dispatch => {
    dispatch(request());
    backend.getConversation(conversationId).then(
      results => {
        dispatch(successMessage(results));
        dispatch(successConversation(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_CONVERSATION_REQUEST, conversationId };
  }
  function successMessage(results) {
    return {
      type: types.GET_CONVERSATION_SUCCESS,
      message: results.conversation.map(m => prepareMessage(m)),
      conversationId,
    };
  }
  function successConversation(results) {
    return {
      type: types.GET_ACTIVE_CONVERSATIONS_SUCCESS,
      results: { conversations: results.conversationDetails },
    };
  }

  function failure(error) {
    return { type: types.GET_CONVERSATION_FAILURE, conversationId, error };
  }
}

function getConversations(params, partialUpdate = false) {
  return dispatch => {
    dispatch(request());
    backend.getConversations(params).then(
      results => {
        dispatch(success(results));
      },
      error => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: types.GET_CONVERSATIONS_REQUEST, partialUpdate };
  }
  function success(results) {
    return { type: types.GET_CONVERSATIONS_SUCCESS, conversations: { results }, partialUpdate };
  }
  function failure(error) {
    return { type: types.GET_CONVERSATIONS_FAILURE, error, partialUpdate };
  }
}

export const IoT = {
  connectIOT,
  disconnectIOT,
  sendMessage,
  readAck,
  getActiveConversations,
  createConversation,
  getConversation,
  getConversations,
  markConversationRead,
  closeConversation,
};

import { Reducer, combineReducers, AnyAction } from '@reduxjs/toolkit';
import alert from './alert';
import principal from './principal';
import feed from './feed';
import caregiver from './caregiver';
import carechat from './carechat';
import schedules from './schedules';
import uiState from './uiState';
import shift from './shiftDetails';
import clients from './clients';
import dashboard from './dashboard';
import clientDetails from './clientDetails';
import clientEditor from './clientEditor';
import caregiverDetails from './caregiverDetails';
import caregiverEditor from './caregiverEditor';
import employee from './employee';
import employeeEditor from './employeeEditor';
import shiftTiming from './shiftTiming';
import multiShiftSelector from './multiShiftSelector';
import imports from './imports';
import eventLog from './eventLog';
import changeLog from './changeLog';
import shiftBuilder from './shiftBuilder';
import clientEnvironmentTab from './clientEnvironmentTab';
import geocode from './geocode';
import caregiverScores from './caregiverScores';
import chatLog from './chatLog';
import caregiverCompliance from './compliance/CGCompliance';
import caregiverComplianceUI from './compliance/CGCompliance-ui';
import candidatesTraining from './hrapp/HRCandidates';
import complianceHRCaregivers from './hrapp/HRCaregivers';
import trainingEvent from './hrapp/event';
import hrDashboard from './hrapp/dashboard';
import announcement from './announcement';
import eLCourses from './ElApp/courses';
import userCourses from './ElApp/userCourses';
import eLManage from './ElApp/manage';
import covidSurvey from './covid/CovidSurvey';
import applicants from './hrapp/HRApplicants';
import HRCaregiverFilter from './hrapp/HRCaregiversFilter';
import HRCandidatesFilter from './hrapp/HRCandidatesFilter';
import applicationTool from './apptool/reducers';
import caregiverInServiceSummary from './compliance/inServiceSummary';
import elearningSettings from './elearningSettings';
import { CLEAR_ALL } from '../constants/ActionTypes';

const appReducer = combineReducers({
  alert,
  principal,
  caregiver,
  feed,
  carechat,
  schedules,
  uiState,
  shift,
  clients,
  dashboard,
  clientDetails,
  clientEditor,
  caregiverDetails,
  caregiverEditor,
  employee,
  employeeEditor,
  shiftTiming,
  multiShiftSelector,
  imports,
  eventLog,
  changeLog,
  shiftBuilder,
  clientEnvironmentTab,
  geocode,
  caregiverScores,
  chatLog,
  caregiverCompliance,
  caregiverComplianceUI,
  candidatesTraining,
  complianceHRCaregivers,
  trainingEvent,
  hrDashboard,
  announcement,
  eLCourses,
  userCourses,
  eLManage,
  covidSurvey,
  applicants,
  applicationTool,
  HRCaregiverFilter,
  HRCandidatesFilter,
  caregiverInServiceSummary,
  elearningSettings,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  const newState = action.type === CLEAR_ALL ? appReducer(undefined, { type: '' }) : state;
  return appReducer(newState, action);
};

export default rootReducer;

import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { dateFormat } from '../../../../helpers/common';
import hrHelper from '../../../../helpers/hrHelper';
import { TitleHeader, SmallText, SubHeader, RegularText } from '../../../../styles/commonStyled';
import { Blocks } from '../ComplianceMedicalTypes';
import {
  HeaderContainer,
  MainContent,
  TrainingColumn,
  ColumnsContainer,
  TailContainer,
  AddTrainingButton,
  JustifyContainer,
  WarningIcon,
  StyledSpan,
} from './styles';
import TrainingEvent from './TrainingEvent';
import { IInServiceSummary } from './trainingTypes';
import colors from '../../../../styles/colors';
import { LinkButton } from '../../../../styles/common';
import { InServiceTrainingStatus } from '../../../../constants/InServiceTrainingStatus';

interface IProps {
  hrManager: boolean;
  inServiceTrainingBlock: Blocks;
  handleAddTraining: () => void;
}

const textColor = (status: string): string => {
  switch (status) {
    case InServiceTrainingStatus.OUT_OF_COMPLIANCE:
      return colors.primary.red;
    case InServiceTrainingStatus.COMPLETED:
      return colors.primary.green;
    case InServiceTrainingStatus.PENDING_EXPIRATION:
      return colors.primary.orange;
    case InServiceTrainingStatus.READY_TO_WORK:
      return colors.primary.green;
    case InServiceTrainingStatus.NOT_REQUIRED:
      return colors.neutrals.charcoal;
    default:
      return colors.neutrals.charcoal;
  }
};

const headerColor = (status: string): string => {
  switch (status) {
    case InServiceTrainingStatus.OUT_OF_COMPLIANCE:
      return colors.primary.red;
    case InServiceTrainingStatus.PENDING_EXPIRATION:
      return colors.primary.orange;
    default:
      return colors.neutrals.charcoal;
  }
};

const minutesToHrsMins = (minutes: any) => {
  if (Number.isNaN(parseInt(minutes, 10))) return null;
  if (minutes === 0) return 0;
  const hh = Math.floor(minutes / 60);
  const mm = minutes % 60;
  const hString = hh > 0 ? `${hh}h ` : '';
  const mString = mm > 0 ? `${mm}min ` : '';
  return `${hString}${mString}`;
};

const AnnualTraining: React.FC<IProps> = ({
  hrManager,
  inServiceTrainingBlock,
  handleAddTraining,
}) => {
  // view more/less button magic
  const [sliceShow, setSliceShow] = useState<number | undefined>(4);
  const [showMore, setShowMore] = useState(false);

  // CC colors
  const { neutrals, primary } = colors;

  // document helper
  const documents = hrHelper.getFieldFromBlockByType(inServiceTrainingBlock, 'document');

  // Destcuture redux state
  const summary = useSelector((state: any) => state.caregiverInServiceSummary as IInServiceSummary);
  const {
    inServiceSummaryError,
    status,
    previousPeriod,
    currentPeriod,
    caregiverId,
    hireDate: hDate,
    defaultRequiredMinutes,
  } = summary;

  if (inServiceSummaryError) {
    return <></>;
  }

  // Sort all training events from previous and current period descending
  const trainingEvents = [
    ...(previousPeriod?.trainingEvents || []),
    ...(currentPeriod?.trainingEvents || []),
  ];
  trainingEvents.sort(
    (a, b) => new Date(b.completionDate).valueOf() - new Date(a.completionDate).valueOf()
  );

  // text color based on inService status
  const tColor = textColor(status);

  // header color based on inService status
  const hColor = headerColor(status);

  // warning icon color based on inService status
  const warningIconSrc =
    status === InServiceTrainingStatus.OUT_OF_COMPLIANCE
      ? '/Attention.svg'
      : '/Attention-orange.svg';

  /* display magic, move current completed hours to previous if needed */
  // if (previousPeriod?.remainingMinutes && currentPeriod?.completedMinutes) {
  //   if (currentPeriod.completedMinutes >= previousPeriod.remainingMinutes) {
  //     currentPeriod.completedMinutes -= previousPeriod.remainingMinutes;
  //     currentPeriod.remainingMinutes += previousPeriod.remainingMinutes;
  //     previousPeriod.completedMinutes += previousPeriod.remainingMinutes;
  //     previousPeriod.remainingMinutes = 0;
  //   } else {
  //     currentPeriod.remainingMinutes += previousPeriod.completedMinutes;
  //     previousPeriod.completedMinutes += currentPeriod.completedMinutes;
  //     previousPeriod.remainingMinutes -= currentPeriod.completedMinutes;
  //     currentPeriod.completedMinutes = 0;
  //   }
  // }

  // click handler
  const toggleShowMore = () => {
    const newShowMore = !showMore;
    setShowMore(newShowMore);
    setSliceShow(newShowMore ? undefined : 4);
  };

  // Hire date format & style
  const hireDate = ((givenDate?: string | null) => {
    if (!givenDate) {
      return { date: 'N/A', color: primary.red };
    }
    return {
      date: moment(givenDate).format(dateFormat),
      color: neutrals.charcoal,
    };
  })(hDate);

  // date format
  const dateString = (date: string) => moment(date).format(dateFormat);

  const remainingMinutes =
    summary?.remainingMinutes && previousPeriod?.remainingMinutes
      ? Math.min(summary.remainingMinutes, previousPeriod.remainingMinutes)
      : null;

  return (
    <TailContainer>
      <MainContent>
        <HeaderContainer>
          <TitleHeader uppercase color={hColor}>
            IN-SERVICE TRAINING
          </TitleHeader>
        </HeaderContainer>
        {status === 'not_required' ? (
          <SmallText>Annual training hours not required</SmallText>
        ) : (
          <>
            <SmallText>
              The following are training courses this caregiver has undertaken as part of their
              annual {minutesToHrsMins(defaultRequiredMinutes)} requirement. If there are remaining
              hours to be completed from the previous period, they will be added to the current year
              to be completed.
            </SmallText>
            <br />
            <SubHeader>Caregiver hire date:</SubHeader>{' '}
            <RegularText display='inline-block' color={hireDate.color}>
              {hireDate.date}
            </RegularText>
            <ColumnsContainer>
              {previousPeriod && (
                <TrainingColumn>
                  <div>
                    <SubHeader>Previous period</SubHeader>
                    <RegularText>
                      {dateString(previousPeriod.startDate)} - {dateString(previousPeriod.endDate)}
                    </RegularText>
                  </div>
                  <div>
                    <SubHeader>Previous period completed hours</SubHeader>
                    {hDate ? (
                      <RegularText fontWeight='bold' color={neutrals.gray}>
                        {minutesToHrsMins(previousPeriod?.completedMinutes)} of{' '}
                        {minutesToHrsMins(previousPeriod?.requiredMinutes)}
                        {!!remainingMinutes && (
                          <>
                            <WarningIcon marginLeft src='/Attention.svg' alt='' />
                            <StyledSpan
                              display='inline-block'
                              fontWeight='bold'
                              color={primary.red}
                            >
                              {' '}
                              {minutesToHrsMins(remainingMinutes)}missing
                            </StyledSpan>
                          </>
                        )}
                      </RegularText>
                    ) : (
                      <RegularText color={primary.red}>N/A</RegularText>
                    )}
                  </div>
                </TrainingColumn>
              )}
              <TrainingColumn>
                <div>
                  <SubHeader>Current period</SubHeader>
                  <RegularText>
                    {dateString(currentPeriod?.startDate)} - {dateString(currentPeriod?.endDate)}
                  </RegularText>
                </div>
                <div>
                  <SubHeader>Current period completed hours</SubHeader>
                  {hDate ? (
                    <RegularText fontWeight='bold' color={tColor}>
                      {status === 'pending_expiration' && (
                        <>
                          <WarningIcon src={warningIconSrc} alt='' />
                        </>
                      )}
                      {minutesToHrsMins(currentPeriod?.completedMinutes)} of{' '}
                      {minutesToHrsMins(currentPeriod?.requiredMinutes)}
                    </RegularText>
                  ) : (
                    <RegularText color={primary.red}>N/A</RegularText>
                  )}
                </div>
              </TrainingColumn>
            </ColumnsContainer>
          </>
        )}
      </MainContent>
      <br />
      <HeaderContainer>
        <TitleHeader>Completed trainings</TitleHeader>
        {hrManager && (
          <AddTrainingButton onClick={handleAddTraining}>+ Add training</AddTrainingButton>
        )}
      </HeaderContainer>
      <div>
        {trainingEvents.slice(0, sliceShow).map(tr => (
          <TrainingEvent
            key={`${tr.id}${tr.title}`}
            training={tr}
            documents={documents}
            caregiverId={caregiverId}
          />
        ))}
      </div>
      {trainingEvents.length > 4 ? (
        <JustifyContainer alignItems='center' justifyContent='center'>
          <LinkButton fontSize='11px' onClick={toggleShowMore}>
            {!showMore ? `View More (${trainingEvents.length - 4})` : 'View Less'}
          </LinkButton>
        </JustifyContainer>
      ) : null}
    </TailContainer>
  );
};

export default AnnualTraining;

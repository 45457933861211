import React from 'react';
import { Form } from 'formsy-semantic-ui-react';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import AgencySelect from './subcomponents/AgencySelect';
import './Common.css';
import './Clients.css';
import { EMAIL_LIST_PATTERN } from '../constants/Formats';

class CCEditorAgency extends React.Component {
  componentDidMount() {
    this.form.formsyForm.setFormPristine(false);
  }

  render() {
    const {
      uiState,
      onAgencyChange,
      isClientView,
      disableSubmitButton,
      enableSubmitButton,
      values,
      manualExternalIdEnabled,
    } = this.props;

    return (
      <Form
        className='careAddForm'
        ref={c => {
          this.form = c;
        }}
        onInvalid={disableSubmitButton}
        onValid={enableSubmitButton}
        size='mini'
      >
        {!isClientView && manualExternalIdEnabled && (
          <div>
            <Form.Input
              id='Agency-id'
              name='caregiverAgencyId'
              label='Agency Id:'
              placeholder='Enter Agency Id'
              onChange={onAgencyChange}
              validations={{
                matchRegexp: /^[0-9]+$/,
                minLength: 1,
                maxLength: 6,
              }}
              value={values.caregiverAgencyId}
              instantValidation
              required
            />
            <hr />
          </div>
        )}
        <AgencySelect
          isClientView={isClientView}
          id='clientEditorPrimaryContact'
          uiState={uiState}
          onChange={onAgencyChange}
          value={values.primaryContactId}
        />
        <hr />
        <AgencySelect
          isClientView={isClientView}
          isManager
          id='clientEditorPrimaryManager'
          uiState={uiState}
          onChange={onAgencyChange}
          value={values.managerId}
        />
        <hr />

        {!isClientView && (
          <div>
            <Header>NOTIFICATION RECIPIENTS</Header>
            <Form.Input
              id='notification-recipient-input'
              fluid
              label='Enter the emails of users to be notified when
        caregiver updates information in-app. Separate with
        a comma ",".'
              name='notificationRecipients'
              placeholder='Email Address'
              onChange={onAgencyChange}
              validations={{
                minLength: 2,
                maxLength: 200,
                matchRegexp: EMAIL_LIST_PATTERN,
              }}
              validationErrors={{ isValid: 'notificationRecipients not valid' }}
              instantValidation
              value={values.notificationRecipients}
            />
          </div>
        )}
      </Form>
    );
  }
}

CCEditorAgency.defaultProps = {
  isClientView: false,
};

CCEditorAgency.propTypes = {
  uiState: PropTypes.shape().isRequired,
  values: PropTypes.shape().isRequired,
  onAgencyChange: PropTypes.func.isRequired,
  isClientView: PropTypes.bool,
  disableSubmitButton: PropTypes.func.isRequired,
  enableSubmitButton: PropTypes.func.isRequired,
};

export default CCEditorAgency;

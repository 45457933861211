/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Form } from 'formsy-semantic-ui-react';
import PropTypes from 'prop-types';
import { backend } from '../../services';

const maskSSN = ssn => {
  const mask = '###-##-####';
  const ssnLength = ssn.replace(/-/g, '').length;
  const lengths = [0, 1, 2, 4, 5, 7, 8, 9, 10, 11, 11];
  const length = ssnLength ? lengths[ssnLength] : 0;
  return mask.substr(0, length);
};

const starMaskSSN = ssn => {
  const mask = '***-**-****';
  const ssnLength = ssn.replace(/-/g, '').length;
  const lengths = [0, 1, 2, 4, 5, 7, 8, 9, 10, 11, 11];
  const length = ssnLength ? lengths[ssnLength] : 0;
  return mask.substr(0, length);
};

class SSNInput extends React.Component {
  constructor() {
    super();
    this.state = {
      ssn: '',
      maskedSsn: '',
    };
    this.ssnChanged = this.ssnChanged.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      const maskedSSN = nextProps.starMask
        ? starMaskSSN(nextProps.value)
        : maskSSN(nextProps.value);
      this.setState({
        ssn: nextProps.value,
        maskedSsn: maskedSSN,
      });
    }
  }

  ssnChanged(event, input) {
    const { length } = input.value;
    // eslint-disable-next-line react/no-access-state-in-setstate
    let newSsn = this.state.ssn.substr(0, length);
    const diff = length - newSsn.length;
    if (diff > 0) {
      const changed = input.value.substr(length - diff, diff);
      newSsn += changed;
    }

    this.props.onValueChange({}, { name: 'ssn', value: newSsn });
    const maskedSSN = this.props.starMask ? starMaskSSN(newSsn) : maskSSN(newSsn);
    this.setState({
      ssn: newSsn,
      maskedSsn: maskedSSN,
    });
  }

  render() {
    const { maskedValue, label, isNewCaregiver, disabled } = this.props;
    const { ssn, maskedSsn } = this.state;
    const settings = backend.getCachedSettings();
    const ssnRequired = settings && settings.ssnRequired != null ? settings.ssnRequired : true;
    const validationOld = () => !ssn || ssn.match(/^\d{3}-\d{2}-\d{4}$/);
    const validationNew = () => ssn && ssn.match(/^\d{3}-\d{2}-\d{4}$/);
    const validation = isNewCaregiver && ssnRequired ? validationNew : validationOld;

    return (
      <Form.Input
        id='ssn-input'
        name='maskedSsn'
        maxLength={11}
        label={label}
        placeholder={maskedValue || 'SSN#'}
        validations={{ customValidation: validation }}
        validationErrors={{ isValid: 'SSN not valid' }}
        instantValidation
        className='hideLabel'
        isRequired={isNewCaregiver && ssnRequired}
        value={maskedSsn}
        onChange={this.ssnChanged}
        disabled={disabled}
      />
    );
  }
}

SSNInput.defaultProps = {
  value: null,
  label: undefined,
  starMask: false,
  maskedValue: null,
  isNewCaregiver: false,
  disabled: false,
};

SSNInput.propTypes = {
  isNewCaregiver: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  maskedValue: PropTypes.string,
  label: PropTypes.string,
  starMask: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SSNInput;

/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { isBoolean } from 'util';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Segment, Grid, Dimmer, Checkbox } from 'semantic-ui-react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { history } from '../helpers';
import { caregiverActions, complianceSettingsActions } from '../actions';
import { CovidSurveyActions } from '../actions/covid/covidSurveyActions';
import CGInformation from './CGInformation';
import CGAgencyInformation from './CGAgencyInformation';
import CGPreferences from './CGPreferences';
import CGCompliance from './CGCompliance';
import CGeLearning from './CGeLearning';
import CareLoader from './subcomponents/CareLoader';
import LeftRail from './subcomponents/LeftRail';
import SubTabButton from './subcomponents/SubTabButton';
import UnsavedChangesModal from './UnsavedChangesModal';
import ChangeLog from './ChangeLog';
import { formatPhoneNumbers, MOMENT_DOB, EMAIL_LIST_PATTERN, isFloat } from '../constants/Formats';
import { caregiverScoresActions } from '../actions/caregiverScores';
import CGComplianceUIActions from '../actions/CGComplianceUIActions';
import { ELUserActions } from '../actions/ELApp/ELUserActions';
import { agencySettingsActions } from '../actions/agencySettings';

class CaregiverRecords extends React.Component {
  static validateInputs(person, statusToInactive = false) {
    // since each tab defines 1..n form elements, and save operation is common -> just in case
    // validate some fields before sending request to back-end
    const errorFields = [];

    if (
      person.notificationRecipients &&
      !person.notificationRecipients.replace(/\s/g, '').match(EMAIL_LIST_PATTERN)
    ) {
      errorFields.push('notificationRecipients');
    }

    if (person.lastName == null || (person.lastName != null && person.lastName.trim().length < 2)) {
      errorFields.push('names');
    }

    if (
      statusToInactive &&
      person.hireHistory?.length &&
      !person.hireHistory[person.hireHistory.length - 1].separationDate
    ) {
      errorFields.push('noSeparationDate');
    }

    return errorFields.length === 0;
  }

  constructor() {
    super();
    this.state = {
      caregiverDetailId: null,
      activeSubTab: 0,
      unsavedTabChange: null,
      caregiver: null,
      prevPath: '',
      isElearnAdmin: false,
      isAdmin: false,
      userCoursesFetched: false,
      statusToInactive: false,
    };
    this.backupCaregiver = {};
    this.saveChanges = () => {};
    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.handleCGDetailsClose = this.handleCGDetailsClose.bind(this);
    this.onSubTabClick = this.onSubTabClick.bind(this);
    this.applyChangesClick = this.applyChangesClick.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.handlePressEnter = this.handlePressEnter.bind(this);
    this.onAgencyChange = this.onAgencyChange.bind(this);
    this.cancelNavigation = this.cancelNavigation.bind(this);
    this.discardChanges = this.discardChanges.bind(this);
    this.onComplianceChanged = this.onComplianceChanged.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onActive = this.onActive.bind(this);
    this.onEmergencyContactDelete = this.onEmergencyContactDelete.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { caregiverId } = ((this.props || {}).match || {}).params || {};
    this.props.dispatch(caregiverActions.getCaregiver(caregiverId));
    this.props.dispatch(CGComplianceUIActions.clearState());
    this.props.dispatch(caregiverActions.getCaregiverComplianceBlocks(caregiverId));
    this.props.dispatch(CovidSurveyActions.getCaregiverSurvey(caregiverId));
    this.props.dispatch(complianceSettingsActions.getComplianceBlocks());
  }

  componentDidMount() {
    if (this.props.employeeRoles.length) {
      this.checkUserRole(this.props.employeeRoles);
    }
    this.props.dispatch(agencySettingsActions.getAgencySettings(true));
    this.props.dispatch(ELUserActions.clearUserCoursesList());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.caregiverDetails && !nextProps.caregiverDetails.loading) {
      if (
        nextProps.caregiverDetails.caregiver &&
        nextProps.caregiverDetails.caregiver !== this.props.caregiverDetails.caregiver
      ) {
        this.backupCaregiver = JSON.parse(JSON.stringify(nextProps.caregiverDetails.caregiver));
        this.storeCaregiver(nextProps.caregiverDetails.caregiver);
      } else {
        // this.props.onCloseClicked();
      }
    }
    if (nextProps.location.state) {
      this.setState({ prevPath: nextProps.location.state.prevPath });
    }
    if (
      this.state.isElearnAdmin &&
      nextProps.caregiverDetails?.caregiver?.userId &&
      !this.state.userCoursesFetched
    ) {
      this.props.dispatch(
        ELUserActions.getUserCoursesList(nextProps.caregiverDetails.caregiver.userId)
      );
      this.setState({ userCoursesFetched: true });
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(caregiverActions.clearCaregiverComplianceBlocks());
  }

  onDetailsClick(id) {
    this.setState(current => ({ ...current, caregiverDetailId: id }));
  }

  onSubTabClick = tabIndex => {
    const { match } = this.props;
    const { caregiver } = this.state;

    let link = '/';
    switch (tabIndex) {
      case CaregiverRecords.tabs.masterSwitch:
        this.onValueChange('virtualToglleSwitch', {
          type: 'checkbox',
          booleanMode: true,
          name: 'blockShiftMatching',
          checked: !caregiver.blockShiftMatching,
        });
        return;
      case CaregiverRecords.tabs.information:
        link = '/information';
        break;
      case CaregiverRecords.tabs.agency:
        link = '/agency';
        break;
      case CaregiverRecords.tabs.preferences:
        link = '/preferences';
        break;
      case CaregiverRecords.tabs.activity:
        link = '/activity';
        break;
      case CaregiverRecords.tabs.compliance:
        link = '/compliance';
        break;
      case CaregiverRecords.tabs.eLearning:
        link = '/e-learning';
        break;
      default:
        link = '/information';
    }
    history.push(`${match.url}${link}`);
  };

  onComplianceChanged = () => {
    this.setState(s =>
      Object.assign(s, {
        isChanged: true,
      })
    );
  };

  onValueChange(e, input, enableNull = false) {
    if (!e || !input.name) {
      return;
    }

    this.setState(s => {
      const setValue = (path, obj, value) => {
        const key = path.shift();
        if (path.length > 0) {
          setValue(path, obj[key], value);
        } else {
          // eslint-disable-next-line no-param-reassign
          obj[key] =
            (value && value.length) ||
            Number.isInteger(value) ||
            isFloat(value) ||
            isBoolean(value) ||
            (enableNull && (value === null || value === ''))
              ? value
              : undefined;
        }
      };

      const newState = { ...s };
      const fields = input.name.split('.');

      // make new object to force render
      newState.caregiver = { ...newState.caregiver };

      if (fields[0] === 'caregiverNote' && !newState.caregiver.caregiverNote) {
        newState.caregiver.caregiverNote = { type: null, subType: null, message: null };
      }

      // deep copy field, if its array
      if (newState.caregiver[fields[0]] && newState.caregiver[fields[0]].constructor === Array) {
        newState.caregiver[fields[0]] = JSON.parse(JSON.stringify(newState.caregiver[fields[0]]));
      }

      // eslint-disable-next-line no-underscore-dangle
      if (e._isAMomentObject && input.name === 'dob') {
        setValue(['dob'], newState.caregiver, e.format(MOMENT_DOB));
      } else if (input.type === 'checkbox' && input.booleanMode) {
        setValue(fields, newState.caregiver, input.checked);
      } else if (input.type === 'checkbox') {
        setValue(fields, newState.caregiver, input.checked ? 'Y' : 'N');
      } else if (input.name === 'notificationRecipientsTag') {
        const notificationsToArray = newState.caregiver.notificationRecipients.split(',');
        const notificationFilter = notificationsToArray.filter(n => input.value.find(v => v === n));
        const notificationToString = notificationFilter.join(',');
        setValue(['notificationRecipients'], newState.caregiver, notificationToString);
      } else {
        setValue(fields, newState.caregiver, input.value);
      }

      const isChanged = JSON.stringify(newState.caregiver) !== JSON.stringify(newState.original);
      const statusToInactive =
        newState.caregiver.status === 'inactive' && newState.original.status !== 'inactive';
      const isValid = CaregiverRecords.validateInputs(newState.caregiver, statusToInactive);

      return Object.assign(newState, { isChanged, isValid, statusToInactive });
    });
  }

  onEmergencyContactDelete() {
    this.setState(s => {
      const { statusToInactive, original, caregiver } = s;
      caregiver.emergencyContact = {};
      const isChanged = JSON.stringify(caregiver) !== JSON.stringify(original);
      const isValid = CaregiverRecords.validateInputs(caregiver, statusToInactive);
      return { caregiver, isChanged, isValid };
    });
  }

  onAgencyChange(e, data) {
    if (!e) {
      return;
    }

    const { statusToInactive } = this.state;

    this.setState(s => {
      const setValue = (path, obj, value) => {
        const firstPath = path.shift();
        const pathSplit = firstPath.split('.');
        const key = pathSplit.shift();
        if (path.length > 0) {
          setValue(path, obj[key], value);
        }
        if (pathSplit.length > 0) {
          // eslint-disable-next-line no-param-reassign
          obj[key][parseInt(pathSplit[0], 10)][pathSplit[1]] =
            value?.length || Number.isInteger(value) ? value : null;
        } else {
          // eslint-disable-next-line no-param-reassign
          obj[key] = value?.length || Number.isInteger(value) ? value : null;
        }
      };
      const newState = { ...s };

      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        if (e._isAMomentObject && data.name === 'hireDate') {
          setValue(['hireDate'], newState.caregiver, e.format(MOMENT_DOB));
        } else if (data.name === 'primaryContactId') {
          setValue(['primaryContactId'], newState.caregiver, data.value);
        } else if (data.name === 'managerId') {
          setValue(['managerId'], newState.caregiver, data.value);
        } else if (data.name === 'notificationRecipients') {
          setValue(['notificationRecipients'], newState.caregiver, data.value);
          // eslint-disable-next-line no-underscore-dangle
        } else if (e._isAMomentObject && data.name.includes('separationDate')) {
          setValue([data.name], newState.caregiver, e.format(MOMENT_DOB));
        }
      }

      const isChanged = JSON.stringify(newState.caregiver) !== JSON.stringify(newState.original);
      const isValid = CaregiverRecords.validateInputs(newState.caregiver, statusToInactive);

      return Object.assign(newState, { isChanged, isValid });
    });
  }

  onActive(tabIndex) {
    if (
      (this.state.isChanged || this.props.caregiverComplianceUI.dirtyFields.length > 0) &&
      this.state.activeSubTab !== tabIndex
    ) {
      this.setState({ unsavedTabChange: tabIndex });
    } else {
      this.setState({ activeSubTab: tabIndex });
    }
  }

  onBack = () => {
    if (this.props.location && this.props.location.pathname) {
      const splitPath = this.props.location.pathname.split('records');
      if (this.state.prevPath === '/home/hr/') {
        history.push('/home/hr/');
      } else if (this.state.prevPath?.pathname === '/home/e-learning/manage') {
        history.push('/home/e-learning/manage');
      } else if (splitPath[0] === '/home/e-learning/caregivers/') {
        history.push('/home/e-learning/manage');
      } else {
        history.push(splitPath[0]);
      }
    } else {
      history.goBack();
    }
  };

  checkUserRole = roles => {
    const isAdmin = roles.find(r => r === 'admin');
    const ELearn = roles.find(r => r === 'elearn');
    if (isAdmin) {
      this.setState({ isAdmin: true });
      if (ELearn) {
        this.setState({ isElearnAdmin: true });
      }
    }
  };

  handlePressEnter = e => {
    if (e.key === 'Enter') {
      this.setState(s => {
        const setValue = (path, obj, value) => {
          const key = path.shift();
          if (path.length > 0) {
            setValue(path, obj[key], value);
          } else {
            // eslint-disable-next-line no-param-reassign
            obj[key] = value?.length || Number.isInteger(value) ? value : null;
          }
        };

        const newState = { ...s };

        let isValid;

        if (
          newState.caregiver.notificationRecipientsField &&
          newState.caregiver.notificationRecipientsField
            .replace(/\s/g, '')
            .match(EMAIL_LIST_PATTERN)
        ) {
          isValid = true;
        }

        if (isValid) {
          const notificationsToArray = newState.caregiver.notificationRecipients
            ? newState.caregiver.notificationRecipients.split(',')
            : [];
          notificationsToArray.push(newState.caregiver.notificationRecipientsField);
          const concatRecipients = notificationsToArray.join(',');
          const trimString = concatRecipients.replace(/\s/g, '');
          const splitToCheckDuplicates = trimString.split(',');
          const filterDuplicates = splitToCheckDuplicates.filter((d, i, a) => a.indexOf(d) >= i);
          const notificationToString = filterDuplicates.join(',');
          setValue(['notificationRecipients'], newState.caregiver, notificationToString);
          setValue(['notificationRecipientsField'], newState.caregiver, '');
        }
        return Object.assign(newState, { isValid });
      });
    }
  };

  applyChangesClick = () => {
    // casePref update is using same model with mobile API, modify payload before request...
    const considerations = this.state.caregiver.preferences;
    const casePref = { ...this.state.caregiver.casePref, considerations };
    delete this.state.caregiver.preferences;

    const notificationRecipientsField = this.state.caregiver.notificationRecipientsField
      ? this.state.caregiver.notificationRecipientsField.replace(/\s/g, '')
      : null;

    const concatRecipients =
      this.state.caregiver.notificationRecipients &&
      this.state.caregiver.notificationRecipientsField
        ? `${this.state.caregiver.notificationRecipients},${notificationRecipientsField}`
        : notificationRecipientsField || this.state.caregiver.notificationRecipients;

    const notificationsToArray = concatRecipients ? concatRecipients.split(',') : [];
    const filterDuplicates = notificationsToArray.filter((d, i, a) => a.indexOf(d) >= i);
    const recipient = filterDuplicates.join(',');

    const { firstName } = this.state.caregiver;

    this.props.dispatch(
      caregiverActions.updateCaregiver({
        ...this.state.caregiver,
        email: this.state.caregiver.email || null,
        notificationRecipients: recipient || null,
        firstName: firstName || null,
        phones: formatPhoneNumbers(this.state.caregiver.phones.filter(p => p.number)),
        casePref,
        clientExclusion: this.state.caregiver.clientExclusion || [],
      })
    );
    this.props.dispatch(caregiverScoresActions.resetScores());
  };

  handleCGDetailsClose = () => {
    this.setState(current => ({ ...current, caregiverDetailId: null }));
  };

  storeCaregiver(caregiver) {
    this.setState({
      caregiver,
      original: {
        ...caregiver,
        address: { ...caregiver.address },
        caregiverNote: { ...caregiver.caregiverNote },
        casePref: { ...caregiver.casePref },
        phones: caregiver.phones.map(p => ({ ...p })),
        emergencyContact: { ...caregiver.emergencyContact },
        customFields: caregiver.customFields && JSON.parse(JSON.stringify(caregiver.customFields)),
        clientExclusion: caregiver.clientExclusion || [],
      },
      isChanged: false,
      isValid: false,
    });
  }

  section = () => {
    const { original, caregiver, isChanged, isValid } = this.state;
    const {
      languages,
      disciplines,
      dispatch,
      employees,
      settings,
      userCourses,
      secondaryStatusCodes,
      blocks,
      profileFields,
      agencySettings,
    } = this.props;

    /* eslint-disable no-param-reassign */
    const editability = profileFields.reduce((a, c) => {
      a[c.type] = c.editable;
      return a;
    }, {});
    /* eslint-enable no-param-reassign */

    // TODO: Remove this eventually once all fields exist for all agencies in
    // profile_field table
    const fieldsNotInAllAgencies = [
      'ethnicity',
      'caregivernotes',
      'maritalstatus',
      'socialprofiles',
      'staffstatus',
      'hiredate',
      'primaryContact',
    ];
    const editable =
      (caregiver.source !== 'sam' && caregiver.source !== 'hcp') ||
      agencySettings.complianceEnabled ||
      caregiver.pilot;
    editability.bySource = editable;

    // for Agency with HR enabled, allow to input SSN from CC Web if value is null, otherwise controlled from profile_field table
    if (agencySettings.complianceEnabled && !caregiver.maskedSsn) {
      editability.ssn = true;
    }
    fieldsNotInAllAgencies.forEach(field => {
      // Figure out editability for agencies that do not have all fields in profile_field table
      if (!(field in editability)) {
        if (field === 'hiredate') {
          editability[field] = caregiver.staffStatus === '02' ? editable : !editable;
        } else if (field === 'primaryContact') {
          editability[field] = editable;
        } else {
          editability[field] = editable;
        }
      }
    });

    // Standalong agency if HR module is disabled, CG can be activated from profile page
    const showActivationCheck =
      agencySettings.standaloneMode &&
      !agencySettings.complianceEnabled &&
      editability.staffstatus &&
      original.staffStatus === '01';

    const { editableAttributes } = settings;
    const { mobileHealthIntegration } = settings;
    const { multiDisciplineEnabled } = agencySettings;
    const { match } = this.props;
    const { caregiverSurvey } = this.props.covidSurvey;
    const surveyResults = caregiverSurvey?.results;

    const preHire = blocks ? blocks.filter(b => b.includedInPrehire) : [];
    let preHireCgDiscipline = preHire;
    if (caregiver.discipline) {
      preHireCgDiscipline = _.filter(preHire, c =>
        c.disciplines ? _.filter(c.disciplines, n => caregiver.discipline.includes(n)).length : true
      );
    }

    const homeLocations = (
      agencySettings?.homeLocations?.map(x => ({
        key: x.code,
        text: x.name,
        value: x.code,
      })) || []
    ).sort((a, b) => {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    });

    return (
      <Router history={history}>
        <Switch>
          <Route
            exact
            path={`${match.url}`}
            render={() => <Redirect to={`${match.url}/information`} />}
          />
          <PrivateRoute
            path={`${match.url}/information`}
            component={CGInformation}
            redirectTo={`${match.url}`}
            componentProps={{
              isAdmin: this.state.isAdmin,
              surveyResults,
              surveyEnabled: settings?.caregiverCovidSurveyEnabled,
              caregiver,
              disciplines,
              dispatch,
              onValueChange: this.onValueChange,
              onSave: this.applyChangesClick,
              saveEnabled: isChanged && isValid,
              editable,
              editability,
              editableAttributes,
              mobileHealthIntegration,
              onActive: this.onActive,
              tabIndex: CaregiverRecords.tabs.information,
              multiDisciplineEnabled,
              homeLocations,
              onDelete: this.onEmergencyContactDelete,
            }}
            dispatch={this.props.dispatch}
          />
          <PrivateRoute
            path={`${match.url}/agency`}
            component={CGAgencyInformation}
            componentProps={{
              original,
              caregiver,
              employees,
              preHireBlocks: preHireCgDiscipline,
              dispatch,
              onAgencyChange: this.onAgencyChange,
              onValueChange: this.onValueChange,
              handlePressEnter: this.handlePressEnter,
              onSave: this.applyChangesClick,
              saveEnabled: isChanged && isValid,
              editable,
              editability,
              onActive: this.onActive,
              tabIndex: CaregiverRecords.tabs.agency,
              secondaryStatusCodes,
              complianceEnabled: agencySettings.complianceEnabled,
              showActivation: showActivationCheck,
            }}
            dispatch={this.props.dispatch}
          />
          <PrivateRoute
            path={`${match.url}/preferences`}
            component={CGPreferences}
            componentProps={{
              caregiver,
              languages,
              dispatch,
              onValueChange: this.onValueChange,
              onSave: this.applyChangesClick,
              saveEnabled: isChanged && isValid,
              editable,
              editability,
              onActive: this.onActive,
              tabIndex: CaregiverRecords.tabs.preferences,
            }}
            dispatch={this.props.dispatch}
          />
          <PrivateRoute
            path={`${match.url}/activity`}
            component={ChangeLog}
            componentProps={{
              caregiverId: caregiver.id,
              dispatch,
              onActive: this.onActive,
              tabIndex: CaregiverRecords.tabs.activity,
            }}
            dispatch={this.props.dispatch}
          />
          <PrivateRoute
            path={`${match.url}/compliance`}
            component={CGCompliance}
            componentProps={{
              onChanged: this.onComplianceChanged,
              onActive: this.onActive,
              tabIndex: CaregiverRecords.tabs.compliance,
              onSave: this.applyChangesClick,
              saveEnabled: isChanged && isValid,
              agencySettings,
            }}
            dispatch={this.props.dispatch}
          />
          <PrivateRoute
            path={`${match.url}/e-learning`}
            component={CGeLearning}
            componentProps={{
              userCourses,
              onChanged: this.onComplianceChanged,
              onActive: this.onActive,
              tabIndex: CaregiverRecords.tabs.eLearning,
              onSave: this.applyChangesClick,
              saveEnabled: isChanged && isValid,
            }}
            dispatch={this.props.dispatch}
          />
        </Switch>
      </Router>
    );
  };

  cancelNavigation = () => {
    this.onSubTabClick(this.state.activeSubTab);
    this.setState({ unsavedTabChange: null });
  };

  discardChanges = () => {
    const unsaved = this.state.unsavedTabChange;
    this.storeCaregiver(JSON.parse(JSON.stringify(this.backupCaregiver)));
    this.onSubTabClick(unsaved);

    this.setState({ activeSubTab: unsaved, unsavedTabChange: null });
    this.props.dispatch(CGComplianceUIActions.clearState());
  };

  render() {
    const { intl } = this.props;

    const { activeSubTab, caregiver, unsavedTabChange } = this.state;
    const { loading } = this.props.caregiverDetails;
    const { blocks, agencySettings } = this.props;

    const unCompletedBlocks = blocks ? blocks.filter(b => b.completed === false) : [];
    const unCompletedPrehireBlocks = unCompletedBlocks
      ? unCompletedBlocks.filter(b => b.includedInPrehire === true)
      : [];

    const { prevPath } = this.state;

    const leftRailTabs = caregiver
      ? [
          {
            id: CaregiverRecords.tabs.information,
            icon: SubTabButton.icons.information,
            labelId: 'caregivers.records.information',
            alert: !caregiver.infoCompleted || caregiver.status !== 'active',
          },
          {
            id: CaregiverRecords.tabs.agency,
            icon: SubTabButton.icons.agency,
            labelId: 'records.agency.information',
            alert: caregiver.staffStatus !== '02',
          },
          {
            id: CaregiverRecords.tabs.preferences,
            icon: SubTabButton.icons.preferences,
            labelId: 'caregivers.records.preferences',
          },
          {
            id: CaregiverRecords.tabs.compliance,
            icon: SubTabButton.icons.compliance,
            labelId: 'caregivers.records.compliance',
            alert:
              (caregiver.staffStatus === '02' && unCompletedBlocks.length > 0) ||
              (caregiver.staffStatus !== '02' && unCompletedPrehireBlocks.length > 0),
          },
          {
            id: CaregiverRecords.tabs.activity,
            icon: SubTabButton.icons.activity,
            labelId: 'caregivers.records.activity',
          },
          {
            isBreak: true,
          },
          {
            id: CaregiverRecords.tabs.masterSwitch,
            icon: caregiver.blockShiftMatching
              ? SubTabButton.icons.masterSwitchOn
              : SubTabButton.icons.masterSwitch,
            labelId: 'caregivers.records.masterswitch',
            additionalContent: (
              <Checkbox
                className='caregiver-block-shift-matching'
                name='blockShiftMatching'
                toggle
                checked={caregiver.blockShiftMatching}
                onChange={this.onValueChange}
                booleanMode
              />
            ),
          },
        ]
      : [];

    if (this.state.isElearnAdmin) {
      leftRailTabs.splice(4, 0, {
        id: CaregiverRecords.tabs.eLearning,
        icon: SubTabButton.icons.compliance,
        labelId: 'caregivers.records.eLearning',
      });
    }

    if (loading || !caregiver) {
      return (
        <Dimmer active page>
          <CareLoader loading={loading} invert showText={false} />
        </Dimmer>
      );
    }

    let returnText;
    if (prevPath?.pathname === '/home/e-learning/manage') {
      returnText = intl.formatMessage({ id: 'el.manage.button.back' });
    } else if (prevPath === '/home/hr') {
      returnText = intl.formatMessage({ id: 'hr.dashboard.button.back' });
    } else {
      returnText = intl.formatMessage({ id: 'caregivers.button.back' });
    }
    return (
      <>
        {unsavedTabChange != null && (
          <UnsavedChangesModal onCancel={this.cancelNavigation} onDiscard={this.discardChanges} />
        )}
        <Grid.Row className='noVerticalPadding caregiverRecords'>
          <Grid padded style={{ width: '100vw' }} className='contentGrid'>
            <Grid.Column width='three' className='menuCol'>
              <Segment basic style={{ height: '3rem' }}>
                <span className='overlayLink backTotext' role='presentation' onClick={this.onBack}>
                  {returnText}
                </span>
              </Segment>
              <LeftRail
                person={{
                  ...caregiver,
                  caregiverStatus: caregiver.status,
                  unCompletedBlocks,
                }}
                complianceEnabled={agencySettings.complianceEnabled}
                baseUrl={this.props.match.url}
                tabs={leftRailTabs}
                onSubTabClick={this.onSubTabClick}
                activeSubTab={activeSubTab}
              />
            </Grid.Column>
            <Grid.Column width='13'>{this.section()}</Grid.Column>
          </Grid>
        </Grid.Row>
      </>
    );
  }
}

CaregiverRecords.defaultProps = {
  userCourses: {},
};

CaregiverRecords.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  match: PropTypes.shape().isRequired,
  caregiverDetails: PropTypes.shape().isRequired,
  profileFields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  caregiverComplianceUI: PropTypes.shape({
    dirtyFields: PropTypes.arrayOf(PropTypes.shape()),
    dirtyMedicalBlocks: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  employees: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  settings: PropTypes.shape().isRequired,
  disciplines: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  location: PropTypes.shape().isRequired,
  blocks: PropTypes.shape().isRequired,
  secondaryStatusCodes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  employeeRoles: PropTypes.arrayOf(PropTypes.strings).isRequired,
  userCourses: PropTypes.shape(),
  covidSurvey: PropTypes.shape().isRequired,
  agencySettings: PropTypes.shape().isRequired,
};

CaregiverRecords.tabs = {
  information: 0,
  agency: 1,
  preferences: 2,
  compliance: 3,
  activity: 4,
  masterSwitch: 5,
  eLearning: 6,
};

const mapStateToProps = state => {
  const { caregiverDetails, caregiverComplianceUI, userCourses, covidSurvey } = state;
  const {
    disciplines,
    languages,
    roles,
    secondaryStatusCodes,
    attributes,
    agencySettings,
  } = state.principal;

  return {
    caregiverDetails,
    profileFields: attributes.profileFields,
    languages,
    employees: state.employee.employeeList,
    settings: state.principal.settings,
    agencySettings,
    employeeRoles: roles,
    disciplines,
    caregiverComplianceUI,
    blocks: state.caregiverCompliance.blocks,
    secondaryStatusCodes,
    userCourses,
    covidSurvey,
  };
};

export default connect(mapStateToProps)(injectIntl(CaregiverRecords));

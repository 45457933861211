// prettier-ignore
/* eslint-disable */
const Version = {
  major: 1,
  minor: 0,
  patch: 499 ,
  githash: 'f846c457ae15d5d1b8853bbefe2734b3f3d5ecc1',
};

const getVersion = () => `${Version.major}.${Version.minor}.${Version.patch}`;
const getGitHash = () => Version.githash.substring(0, 16);

module.exports = {
  getVersion,
  getGitHash,
};

/* eslint-disable import/prefer-default-export */
import {
  CREATE_QUIZ_ATTEMPT_REQUEST,
  CREATE_QUIZ_ATTEMPT_SUCCESS,
  CREATE_QUIZ_ATTEMPT_FAILURE,
  GET_QUIZ_ATTEMPT_REQUEST,
  GET_QUIZ_ATTEMPT_SUCCESS,
  GET_QUIZ_ATTEMPT_FAILURE,
  UPDATE_QUIZ_ATTEMPT_REQUEST,
  UPDATE_QUIZ_ATTEMPT_SUCCESS,
  UPDATE_QUIZ_ATTEMPT_FAILURE,
  REVIEW_QUIZ_REQUEST,
  REVIEW_QUIZ_SUCCESS,
  REVIEW_QUIZ_FAILURE,
  CLEAR_QUIZ,
} from '../../constants/ActionTypes';
import { backend } from '../../services';
import { ELCoursesActions } from './ElCoursesActions';
import { alertActions } from '../alert';

function createQuizAttempt(params) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.createQuizAttempt(params);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: CREATE_QUIZ_ATTEMPT_REQUEST };
  }
  function success(results) {
    return { type: CREATE_QUIZ_ATTEMPT_SUCCESS, results, params };
  }
  function failure(error) {
    return { type: CREATE_QUIZ_ATTEMPT_FAILURE, error };
  }
}

function getQuizAttempt(params) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.getQuizAttempt(params);
      dispatch(success(res));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: GET_QUIZ_ATTEMPT_REQUEST };
  }
  function success(results) {
    return { type: GET_QUIZ_ATTEMPT_SUCCESS, results };
  }
  function failure(error) {
    return { type: GET_QUIZ_ATTEMPT_FAILURE, error };
  }
}

function updateQuizAttemptAnswers(params) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.updateQuizAttemptAnswers(params);
      dispatch(success(res, params, dispatch));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: UPDATE_QUIZ_ATTEMPT_REQUEST };
  }
  function success(results, parameters, dispatch) {
    if (parameters.answers.isSubmitted) {
      const paramsReview = {
        moduleId: parameters.moduleId,
        courseId: parameters.courseId,
        quizId: parameters.quizId,
      };
      dispatch(reviewQuiz(paramsReview));
    }
    return { type: UPDATE_QUIZ_ATTEMPT_SUCCESS, results };
  }
  function failure(error) {
    return { type: UPDATE_QUIZ_ATTEMPT_FAILURE, error };
  }
}

function reviewQuiz(params) {
  return async dispatch => {
    dispatch(request());
    try {
      const res = await backend.reviewQuiz(params);
      dispatch(success(res, dispatch, params));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }
  };

  function request() {
    return { type: REVIEW_QUIZ_REQUEST };
  }
  function success(results, dispatch, param) {
    dispatch(ELCoursesActions.exitModule(param));
    return { type: REVIEW_QUIZ_SUCCESS, results };
  }
  function failure(error) {
    return { type: REVIEW_QUIZ_FAILURE, error };
  }
}

function clearQuiz() {
  return { type: CLEAR_QUIZ };
}

export const QuizActions = {
  createQuizAttempt,
  getQuizAttempt,
  updateQuizAttemptAnswers,
  reviewQuiz,
  clearQuiz,
};

function isActiveShift(shiftId, state) {
  if (
    state.multiShiftSelector.shiftList &&
    state.multiShiftSelector.shiftList.find(i => i.id === shiftId)
  ) {
    return true;
  }

  if (state.schedules.results && state.schedules.results.find(i => i.id === shiftId)) {
    return true;
  }

  return false;
}

function getEmployeeId(userId, state) {
  // eslint-disable-next-line eqeqeq
  if (state.employee.employee && state.employee.employee.userId == userId) {
    return 'self';
  }

  return false;
}

function getEmployeeEditor(userId, state) {
  // eslint-disable-next-line eqeqeq
  if (state.employee.employee && state.employee.employee.userId == userId) {
    if (
      state.employee.employeeEditor &&
      state.employee.employeeEditor.id === state.employee.employee.id
    ) {
      return state.employee.employeeEditor;
    }
  }

  return false;
}

// eslint-disable-next-line import/prefer-default-export
export const notificationHelpers = {
  isActiveShift,
  getEmployeeId,
  getEmployeeEditor,
};

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ComplianceDOHCertifications from './certifications';
import AddInServiceTrainingForm from './addInServiceTrainingForm';
import TileGrid from '../records/TileGrid';
import ComplianceBlock from './complianceBlock';
import AnnualTraining from './AnnualTraining/AnnualTraining';
import { caregiverActions } from '../../../actions';
import CGComplianceUIActions from '../../../actions/CGComplianceUIActions';
import '../../CGCompliance.css';
import './training.css';
import CommonComplianceBlock from './CommonComplianceBlock';

class ComplianceTraining extends React.Component {
  constructor() {
    super();
    this.blockRef = [];
    this.state = { modalVisible: false, loadedDocuments: [] };
  }

  componentDidMount() {
    if (this.blockRef?.length && this.props.caregiverComplianceUI?.selectedBlock) {
      window.scrollTo({
        top: this.blockRef[this.props.caregiverComplianceUI.selectedBlock]?.current.offsetTop,
        behavior: 'smooth',
      });
    }
  }

  componentWillUnmount() {}

  block = b => <ComplianceBlock block={b} />;

  render() {
    const {
      caregiverCompliance: { blocks, doh, loadingDoh, dohError },
      caregiverDetails: { caregiver },
      hrManager,
    } = this.props;
    const { inServiceCourses, complianceClassrooms } = this.props.principal;
    const { modalVisible, loadedDocuments } = this.state;
    const showModal = () => {
      if (!this.state.modalVisible) {
        this.setState({ modalVisible: true, loadedDocuments: [] });
      }
    };

    const inServiceTrainingBlock = blocks ? blocks.find(b => b.type === 'inservicetraining') : null;
    const certificationsBlock = blocks ? blocks.find(b => b.type === 'certifications') : null;

    const onCreate = item => {
      this.props.dispatch(
        caregiverActions.createCaregiverInServiceTrainingByCourse(caregiver.id, item, true)
      );
      this.setState({ modalVisible: false, loadedDocuments: [] });
    };

    const onUploadDocument = (e, fieldId) => {
      this.props.uploadDocument(e, fieldId, 'inservicetraining', results => {
        loadedDocuments.push(results.file);
      });
    };

    const onRemoveDocument = (fieldId, filename) => {
      if (fieldId && filename) {
        this.props.removeDocument(fieldId, filename);
        loadedDocuments.splice(loadedDocuments.indexOf(filename), 1);
        this.setState({ loadedDocuments });
      }
    };

    return (
      <TileGrid>
        {!dohError && certificationsBlock && (
          <ComplianceDOHCertifications dohInfo={doh} loadingDoh={loadingDoh} />
        )}
        {inServiceTrainingBlock && (
          <AnnualTraining
            hrManager={hrManager}
            inServiceTrainingBlock={inServiceTrainingBlock}
            handleAddTraining={showModal}
          />
        )}
        <div className='infoPanel'>
          <p className='tileHeader'>TRAINING INFORMATION</p>
          <p className='smallGray'>
            The following are training compliance items that must be tracked by law in your state.
            When an item is expiring, your caregiver will be alerted.
          </p>

          {blocks &&
            blocks
              .filter(b => b.type === 'training' && b.system && !b.controlledInProfile)
              .map(b => {
                this.blockRef[b.id] = React.createRef();
                return (
                  <ComplianceBlock
                    // eslint-disable-next-line no-return-assign
                    blockRef={this.blockRef[b.id]}
                    key={b.id}
                    block={b}
                  />
                );
              })}
        </div>
        <div>
          {blocks &&
            blocks
              .filter(b => b.type === 'training' && b.controlledInProfile)
              .map(b => <CommonComplianceBlock key={b.id} block={b} />)}
        </div>
        {blocks &&
        blocks.filter(b => b.type === 'training' && b.controlledInProfile).length % 2 ===
          0 ? null : (
          <></>
        )}

        <div className='infoPanel'>
          <p className='tileHeader'>CUSTOM TRAINING ITEMS</p>
          <p className='smallGray'>
            The following are training compliance items that your agency has chosen to track in
            addition to the state required compliance items.
          </p>
          {blocks &&
            blocks
              .filter(
                b => b.type === 'training' && !b.system && !b.controlledInProfile && !b.schedulable
              )
              .map(b => {
                this.blockRef[b.id] = React.createRef();
                return (
                  <ComplianceBlock
                    // eslint-disable-next-line no-return-assign
                    blockRef={this.blockRef[b.id]}
                    key={b.id}
                    block={b}
                  />
                );
              })}
        </div>
        <div className='infoPanel'>
          <p className='tileHeader'>SCHEDULED TRAINING EVENTS</p>
          <p className='smallGray'>
            The following are a reference list of training compliance items that can be scheduled to
            caregivers and bee part of the annual training.
          </p>

          {blocks &&
            blocks
              .filter(
                b => b.type === 'training' && !b.system && !b.controlledInProfile && b.schedulable
              )
              .map(b => {
                this.blockRef[b.id] = React.createRef();
                return (
                  <ComplianceBlock
                    // eslint-disable-next-line no-return-assign
                    blockRef={this.blockRef[b.id]}
                    key={b.id}
                    block={b}
                  />
                );
              })}
        </div>

        {inServiceTrainingBlock && modalVisible && (
          /* <Modal open={modalVisible} onClose={showModal}>
            {this.addTrainingItemForm()}
            <Modal.Actions>
              <Button disabled={!this.checkItemValidity(item)} onClick={onCreate}>
                Add
              </Button>
            </Modal.Actions>
          </Modal> */
          <AddInServiceTrainingForm
            trainingCourses={inServiceCourses.courses}
            classRooms={complianceClassrooms}
            employees={this.props.employees}
            onClose={() => {
              this.setState({ modalVisible: false });
              CGComplianceUIActions.updateState({ undoneDraftTrainingItem: null });
            }}
            onFinish={onCreate}
            block={inServiceTrainingBlock}
            caregiverCompliance={this.props.caregiverCompliance}
            caregiverComplianceUI={this.props.caregiverComplianceUI}
            uploadDocument={onUploadDocument}
            documentRemoved={onRemoveDocument}
            loadedDocuments={loadedDocuments}
          />
        )}
      </TileGrid>
    );
  }
}

ComplianceTraining.propTypes = {
  principal: PropTypes.shape().isRequired,
  caregiverCompliance: PropTypes.shape().isRequired,
  employees: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  caregiverComplianceUI: PropTypes.shape().isRequired,
  caregiverDetails: PropTypes.shape().isRequired,
  removeDocument: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  hrManager: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const {
    principal,
    caregiverDetails,
    caregiverCompliance,
    caregiverComplianceUI,
    employee,
  } = state;

  return {
    caregiverDetails,
    caregiverCompliance,
    caregiverComplianceUI,
    employees: employee.employeeList,
    principal,
  };
};

export default connect(mapStateToProps)(injectIntl(ComplianceTraining));

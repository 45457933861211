import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Form, Header } from 'semantic-ui-react';
import { formatTime, SHORT_DATE } from '../constants/Formats';
import { ClientStatusMap, getClientStatusData } from '../constants/DomainTypes';
import Name from './subcomponents/Name';
import Address from './subcomponents/Address';
import Notes from './subcomponents/Notes';
import Phones from './subcomponents/Phones';
import Email from './subcomponents/Email';
import Languages from './subcomponents/Languages';
import LocationMap from './subcomponents/LocationMap';
import DetailsDropdown from './subcomponents/DetailsDropdown';
import Timezone from './subcomponents/Timezone';
import './Clients.css';

const WrapColumns = ({ title, content }) => (
  <Grid.Row>
    <Grid.Column width={5}>
      <Header size='tiny' floated='left'>
        {title}
      </Header>
    </Grid.Column>
    <Grid.Column width={11}>
      <Header size='tiny'>{content}</Header>
    </Grid.Column>
  </Grid.Row>
);

WrapColumns.propTypes = {
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
};

const ClientStatus = ({ client }) => {
  const { text, color, date } = getClientStatusData(client);

  const since = <span className='icon-gray'>Since&nbsp;{formatTime(date, null, SHORT_DATE)}</span>;

  return (
    <span>
      <span className='dot' style={{ backgroundColor: color }} />
      <span style={{ dislay: 'inline' }}>
        &nbsp;{text}&emsp;{since}
      </span>
    </span>
  );
};

ClientStatus.propTypes = {
  client: PropTypes.shape().isRequired,
};

const clientStatusOptions = Object.keys(ClientStatusMap).map(x => ({
  key: x,
  value: x,
  text: ClientStatusMap[x].name,
}));

class ClientDetailsTab extends React.Component {
  constructor() {
    super();
    this.googleMapRef = null;
  }

  render() {
    const { dispatch, client, editable, languages, onValueChange } = this.props;

    const statusDropDown = editable ? (
      <Grid padded='vertically' className='detailGrid'>
        <DetailsDropdown
          header='Status:'
          name='status'
          editable={editable}
          object={client}
          options={clientStatusOptions}
          onValueChange={onValueChange}
        />
        <Name object={client} editable={editable} onValueChange={onValueChange} />
      </Grid>
    ) : (
      <div />
    );
    return (
      <Form>
        {statusDropDown}
        <LocationMap
          editable={editable}
          loadingElement={<div style={{ width: '410px', height: '100%' }} />}
          containerElement={
            <div
              style={{
                position: 'relative',
                left: '-75px',
                width: editable ? '410px' : '150px',
                height: editable ? '400px' : '150px',
              }}
            />
          }
          mapElement={<div style={{ width: '410px', height: '100%' }} />}
          showMarker
          lat={client.address.lat}
          lon={client.address.lon}
          mapRefCallback={(mapRef, googleMap) => {
            this.googleMapRef = googleMap;
          }}
          locationCallback={loc => {
            onValueChange({}, { name: 'address.lat', value: loc.lat });
            onValueChange({}, { name: 'address.lon', value: loc.lon });
          }}
        />
        <Grid padded='vertically' className='detailGrid lastRowWithBorder' centered>
          <Address
            dispatch={dispatch}
            object={client}
            editable={editable}
            showCoordinates
            onValueChange={onValueChange}
            locationUpdate={loc => {
              if (this.googleMapRef) {
                this.googleMapRef.setCenter({ lat: loc.lat, lng: loc.lon });
              }
            }}
          />
          <Timezone object={client} />
          <Notes
            object={client}
            editable={editable}
            onValueChange={onValueChange}
            maxLength={2048}
          />
          <WrapColumns title='Client Status:' content={<ClientStatus client={client} />} />
          <Phones object={client} editable={editable} onValueChange={onValueChange} />
          <Email object={client} editable={editable} onValueChange={onValueChange} />
          <Languages
            object={client}
            editable={editable}
            languages={languages}
            onValueChange={onValueChange}
          />
        </Grid>
      </Form>
    );
  }
}

ClientDetailsTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  client: PropTypes.shape().isRequired,
  editable: PropTypes.bool.isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onValueChange: PropTypes.func.isRequired,
};

export default ClientDetailsTab;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image, List } from 'semantic-ui-react';
import './TilePreHireItems.css';
import CGComplianceUIActions from '../../../actions/CGComplianceUIActions';
import { history } from '../../../helpers';
import { RecordsHeader, RecordsTile, RecordsSubHeader } from '../../../styles/records';

class TilePreHireItems extends Component {
  getTabIndex = type => {
    let tab;
    switch (type) {
      case 'medical':
        tab = 1;
        break;
      case 'documentation':
        tab = 2;
        break;
      case 'training':
      case 'inservicetraining':
        tab = 3;
        break;
      default:
        tab = 0;
        break;
    }
    return { activeTab: tab };
  };

  render() {
    const { preHireItems, dispatch } = this.props;
    const preHireList = preHireItems.map(r => (
      <div className='preHire'>
        <List bulleted>
          <List.Item>
            {r.completed ? (
              <div className='preHireCompleted '>
                <div className='pre'>{r.name}</div>
                <div className='completeIcon'>
                  <Image src='/compliance/check.svg' />
                </div>
              </div>
            ) : (
              <div
                className='pre-hire-not-completed'
                role='presentation'
                onClick={() =>
                  history.push(
                    'compliance',
                    !r.includedInEligibility
                      ? dispatch(CGComplianceUIActions.updateState(this.getTabIndex(r.type)))
                      : dispatch(CGComplianceUIActions.updateState(this.getTabIndex()))
                  )
                }
              >
                <div className='pre'>{r.name}</div>
              </div>
            )}
          </List.Item>
        </List>
      </div>
    ));

    return (
      <RecordsTile>
        <RecordsHeader>PRE-HIRE CHECKLIST</RecordsHeader>
        <RecordsSubHeader>
          The following items are required to be collected from a caregiver prior to employment
        </RecordsSubHeader>
        <List className='listColumns'>{preHireList}</List>
      </RecordsTile>
    );
  }
}

TilePreHireItems.propTypes = {
  preHireItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default TilePreHireItems;

import {
  GET_CAREGIVERS_COMPLIANCE_REQUEST,
  GET_CAREGIVERS_COMPLIANCE_SUCCESS,
  GET_CAREGIVERS_COMPLIANCE_FAILURE,
} from '../../constants/ActionTypes';

const initialState = {
  complianceHRCaregivers: {
    totalCaregivers: 0,
    caregivers: null,
    loading: false,
    error: false,
    partialUpdate: false,
  },
};

const startState = JSON.parse(JSON.stringify(initialState));

function complianceHRCaregivers(state = startState, action) {
  switch (action.type) {
    case GET_CAREGIVERS_COMPLIANCE_REQUEST: {
      return {
        ...state,
        complianceHRCaregivers: {
          ...state.complianceHRCaregivers,
          loading: true,
          partialUpdate: action.partialUpdate,
        },
      };
    }
    case GET_CAREGIVERS_COMPLIANCE_SUCCESS: {
      return {
        ...state,
        complianceHRCaregivers: {
          ...state.complianceHRCaregivers,
          totalCaregivers: action.results.total || 0,
          loading: false,
          error: false,
          caregivers: action.partialUpdate
            ? state.complianceHRCaregivers.caregivers.concat(action.results.results)
            : action.results.results,
          nextPage: action.results.nextPage,
          partialUpdate: action.partialUpdate,
        },
      };
    }
    case GET_CAREGIVERS_COMPLIANCE_FAILURE: {
      return {
        ...state,
        complianceHRCaregivers: {
          ...state.complianceHRCaregivers,
          loading: false,
          error: true,
          partialUpdate: action.partialUpdate,
        },
      };
    }
    default:
      return state;
  }
}

export default complianceHRCaregivers;

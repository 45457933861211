import React from 'react';
import PropTypes from 'prop-types';
import { Image, Grid, Icon } from 'semantic-ui-react';

class SubTabButton extends React.Component {
  constructor() {
    super();
    // eslint-disable-next-line react/no-unused-state
    this.state = { selected: false };
  }

  render() {
    const { icon, label, inactive, alert, additionalContent } = this.props;
    const postfix = inactive ? '_inactive' : '';
    const color = inactive ? '#C8CBD3' : '#4A4A4A';
    const backgroundColor = inactive ? '#FFFFFF00' : '#ECECEC';

    return (
      // eslint-disable-next-line react/button-has-type
      <button
        id='sub-tab-button'
        className='subTabButton'
        // eslint-disable-next-line react/destructuring-assignment
        onClick={this.props.onClick}
        // eslint-disable-next-line react/no-unused-state
        onFocus={() => this.setState({ outline: 0 })}
        style={{
          display: 'inline-block',
          width: '100%',
          height: '35px',
          borderWidth: '0px',
          borderRadius: '17.5px',
          marginLeft: '0px',
          marginTop: '8px',
          marginBottom: '8px',
          backgroundColor,
        }}
      >
        <Grid verticalAlign='middle'>
          <Grid.Column width={3} style={{ paddingRight: '0px' }}>
            <Image style={{ float: 'left', maxWidth: '26px', maxHeight: '26px' }}>
              <Image
                style={{ float: 'left', paddingRight: '0px', marginLeft: '-3px' }}
                src={`/${icon}${postfix}.svg`}
              />
              {alert && (
                <Icon
                  name='circle'
                  className='icon-red'
                  size='small'
                  style={{ position: 'absolute', top: '1px', left: '-5px' }}
                />
              )}
            </Image>
          </Grid.Column>
          <Grid.Column width={additionalContent ? 9 : 13} style={{ padding: '0px' }}>
            <span
              style={{
                fontFamily: 'Lato',
                float: 'left',
                color,
                fontWeight: '500',
                fontSize: '14px',
                textAlign: 'left',
                padding: '5px',
              }}
            >
              {label}
            </span>
          </Grid.Column>
          {additionalContent && (
            <Grid.Column width={4} style={{ padding: '0px' }}>
              {additionalContent}
            </Grid.Column>
          )}
        </Grid>
      </button>
    );
  }
}

SubTabButton.icons = {
  information: 'caregiver_avatar',
  agency: 'agency',
  preferences: 'preferences',
  compliance: 'compliance',
  eLearning: 'e-learning',
  activity: 'activity',
  delete: 'delete',
  masterSwitch: 'nomatch',
  masterSwitchOn: 'not-match-shift',
};

SubTabButton.defaultProps = {
  label: '',
  icon: SubTabButton.icons.information,
  inactive: false,
  alert: false,
  additionalContent: undefined,
};

SubTabButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.oneOf(Object.values(SubTabButton.icons)),
  inactive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  alert: PropTypes.bool,
  additionalContent: PropTypes.shape(),
};

export default SubTabButton;

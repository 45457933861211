import React, { useEffect, useState } from 'react';
import {
  Grid,
  Header,
  Divider,
  Form,
  Dropdown,
  InputOnChangeData,
  CheckboxProps,
  DropdownProps,
  DropdownItemProps,
} from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import { PreferenceCategories } from '../../../constants/DomainTypes';
import { beginningSearch } from '../../../constants/Formats';
import { GreenButton } from '../../../styles/common';

interface IData {
  id: string;
  label: string | null;
  caregiverLabel: string | null;
  clientLabel: string | null;
  active: boolean;
  category: string;
  visibleInApp: boolean;
  editableInApp: boolean;
  notifyOnChange: boolean;
  created: boolean;
  name?: string;
  editable: boolean;
  type?: string;
  disciplines?: DropdownItemProps[];
}

interface IProps {
  type: string;
  onCreate: (data: IData) => void;
  disciplines: DropdownItemProps[];
}

const AddNewPrefForm = (props: IProps): React.ReactElement => {
  const { type, onCreate, disciplines } = props;

  const initialData = {
    id: uuid(),
    label: null,
    caregiverLabel: null,
    clientLabel: null,
    active: true,
    category: 'other',
    visibleInApp: false,
    editableInApp: false,
    notifyOnChange: false,
    created: true,
    name: undefined,
    editable: true,
    type: undefined,
    disciplines: undefined,
  };

  const [data, setData] = useState(initialData);
  const [nameField, setNameField] = useState('label');
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    if (type === 'profileField') {
      const newData = Object.assign(data, { type: 'checkbox' });
      setData(newData);
      setNameField('name');
    }
  }, []);

  const setPrefCategoriesOptions = () => {
    const categoriesOptions = Object.keys(PreferenceCategories)
      .filter(x => PreferenceCategories[x as keyof typeof PreferenceCategories].availableToCreate)
      .map(x => ({
        value: x,
        text: PreferenceCategories[x as keyof typeof PreferenceCategories].label,
      }));
    return categoriesOptions;
  };

  const handleValidation = (newData: IData) => {
    const { caregiverLabel, clientLabel, name: prefName, label } = newData;
    if (type === 'preference') {
      return setDisable(!(label && caregiverLabel && clientLabel));
    }
    return setDisable(!prefName);
  };

  const handleChange = (
    _e: React.ChangeEvent<HTMLInputElement> | React.SyntheticEvent<HTMLElement, Event>,
    d: InputOnChangeData | CheckboxProps | DropdownProps
  ) => {
    const { name, value, checked } = d;
    const newData = Object.assign(data, { [name]: value || checked });
    setData(newData);
    handleValidation(newData);
  };

  const handleSubmit = () => onCreate(data);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid centered>
        <Grid.Column width={10}>
          <Grid padded='vertically' className='detailGrid'>
            <Grid.Row>
              <Grid.Column width={5}>
                <Header size='tiny'>Name:</Header>
              </Grid.Column>
              <Grid.Column width={11}>
                <Form.Input
                  id='train-pref-add-new-form-name-input'
                  name={nameField}
                  maxLength={100}
                  required
                  placeholder='Name'
                  onChange={handleChange}
                />
              </Grid.Column>
            </Grid.Row>
            {type === 'preference' && <Divider />}
            {type === 'preference' && (
              <Grid.Row>
                <Grid.Column width={5}>
                  <Header size='tiny'>Caregiver Label:</Header>
                </Grid.Column>
                <Grid.Column width={11}>
                  <Form.Input
                    id='train-pref-add-new-form-caregiver-lable-input'
                    name='caregiverLabel'
                    maxLength={100}
                    placeholder='Alt Label'
                    onChange={handleChange}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {type === 'preference' && (
              <Grid.Row>
                <Grid.Column width={5}>
                  <Header size='tiny'>Client Label:</Header>
                </Grid.Column>
                <Grid.Column width={11}>
                  <Form.Input
                    id='train-pref-add-new-form-client-label-input'
                    name='clientLabel'
                    maxLength={100}
                    placeholder='Alt Label'
                    onChange={handleChange}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {type === 'preference' && <Divider />}
            {type === 'preference' && (
              <Grid.Row>
                <Grid.Column width={5}>
                  <Header size='tiny'>Category:</Header>
                </Grid.Column>
                <Grid.Column width={11}>
                  <Form.Dropdown
                    id='train-pref-add-new-form-category-dropdown'
                    name='category'
                    required
                    defaultValue={data.category}
                    options={setPrefCategoriesOptions()}
                    onChange={handleChange}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {type === 'preference' && (
              <Grid.Row>
                <Grid.Column width={5}>
                  <Header size='tiny'>Applicable Disciplines:</Header>
                </Grid.Column>
                <Grid.Column width={11}>
                  <Dropdown
                    id='train-pref-add-new-form-disciplines-dropdown'
                    className='preference-settings-dropdown'
                    name='disciplines'
                    placeholder='Disciplines'
                    selection
                    multiple
                    search={beginningSearch}
                    options={disciplines}
                    onChange={handleChange}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {type === 'preference' && <Divider />}
            {type === 'preference' && (
              <Grid.Row>
                <Grid.Column width={5}>
                  <Header size='tiny'>Active:</Header>
                </Grid.Column>
                <Grid.Column width={11}>
                  <Form.Checkbox
                    toggle
                    name='active'
                    defaultChecked={data.active}
                    onChange={handleChange}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column width={5}>
                <Header size='tiny'>Notify on Change:</Header>
              </Grid.Column>
              <Grid.Column width={11}>
                <Form.Checkbox
                  toggle
                  name='notifyOnChange'
                  defaultChecked={data.notifyOnChange}
                  onChange={handleChange}
                />
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column width={5}>
                <Header size='tiny'>Visible in App:</Header>
              </Grid.Column>
              <Grid.Column width={11}>
                <Form.Checkbox
                  name='visibleInApp'
                  className='round'
                  defaultChecked={data.visibleInApp}
                  onChange={handleChange}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <Header size='tiny'>Editable in App:</Header>
              </Grid.Column>
              <Grid.Column width={11}>
                <Form.Checkbox
                  name='editableInApp'
                  className='round'
                  defaultChecked={data.editableInApp}
                  onChange={handleChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider />
          <Grid centered className='buttonArea'>
            <Grid.Column width={10} textAlign='center'>
              <GreenButton
                id='train-pref-add-new-form-submit-button'
                type='submit'
                disabled={disable}
              >
                Add
              </GreenButton>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    </Form>
  );
};

export default AddNewPrefForm;

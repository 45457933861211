/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Menu, Accordion, Button, Icon, Image, Divider } from 'semantic-ui-react';

import { caregiverActions, uiStateActions } from '../actions';
import { PreferenceCategories, StaffStatusMap } from '../constants/DomainTypes';

import TeamForm from './TeamForm';
import MenuDropdownItem from './subcomponents/MenuDropdownItem';
import { analytics, Events } from '../helpers/analytics';

const FilterGroup = ({
  uiState,
  languages,
  attributes,
  onTitleClick,
  onValueChange,
  shiftList,
  showLocationSearch,
}) => {
  const showAdvancedFilters = showLocationSearch || (shiftList && shiftList.length > 0);

  return (
    <Accordion as={Menu} exclusive={false} widths='sixteen'>
      <MenuDropdownItem
        active={uiState.registrationStatusMenu}
        title='Registration Status'
        index='registrationStatusMenu'
        onTitleClick={onTitleClick}
        name='statusFilter'
        options={[
          {
            value: 'active',
            text: 'Activated',
            icon: <Icon name='circle' className='icon-green' />,
          },
          {
            value: 'pending',
            text: 'Awaiting Activation',
            icon: <Icon name='circle' className='icon-blue' />,
          },
          {
            value: 'inactive',
            text: 'Awaiting Invitation',
            icon: <Icon name='circle' className='icon-red' />,
          },
        ]}
        values={uiState.statusFilter}
        onValueChange={onValueChange}
        id='caregiversSearchStatusDropdown'
        key='caregiversSearchStatusDropdown'
        renderLabel={x => ({ content: x.text, icon: x.icon })}
      />

      {Object.keys(PreferenceCategories).map(key => {
        const options = attributes.preferences
          .filter(x => x.category === key && x.active)
          .map(x => ({ text: x.caregiverLabel || x.label || x.name, value: x.id }));
        const index = `${key}PreferencesMenu`;

        return (
          <MenuDropdownItem
            active={uiState[index]}
            title={`${PreferenceCategories[key].caregiverTitle}`}
            index={index}
            onTitleClick={onTitleClick}
            name='preferenceFilter'
            options={options}
            values={uiState.preferenceFilter}
            onValueChange={onValueChange}
            id={`caregiversSearchPreferencesDropdown_${key}`}
            key={index}
          />
        );
      })}

      <MenuDropdownItem
        active={uiState.profileFieldsMenu}
        title='Profile Options'
        index='profileFieldsMenu'
        onTitleClick={onTitleClick}
        name='profileFieldsFilter'
        options={attributes.profileFields
          .filter(f => !f.global)
          .map(x => ({ text: x.name, value: x.id }))}
        values={uiState.profileFieldsFilter}
        onValueChange={onValueChange}
        id='caregiversProfileFieldsDropdown'
        key='caregiversProfileFieldsDropdown'
      />

      <MenuDropdownItem
        active={uiState.languagesMenu}
        title='Language'
        index='languagesMenu'
        onTitleClick={onTitleClick}
        name='languages'
        options={languages}
        values={uiState.languages}
        onValueChange={onValueChange}
        id='caregiversSearchLanguagesDropdown'
        key='caregiversSearchLanguagesDropdown'
      />

      <MenuDropdownItem
        active={uiState.genderMenu}
        title='Caregiver Gender'
        index='genderMenu'
        onTitleClick={onTitleClick}
        name='genderFilter'
        options={[
          {
            value: 'm',
            text: 'Male',
          },
          {
            value: 'f',
            text: 'Female',
          },
          {
            value: 'u',
            text: 'Other',
          },
        ]}
        values={uiState.genderFilter}
        onValueChange={onValueChange}
        id='caregiversSearchGenderDropdown'
        key='caregiversSearchGenderDropdown'
      />

      <MenuDropdownItem
        active={uiState.staffStatusMenu}
        title='Caregiver Status'
        index='staffStatusMenu'
        onTitleClick={onTitleClick}
        name='staffStatusFilter'
        options={Object.entries(StaffStatusMap).map(e => ({ value: e[0], text: e[1].name }))}
        values={uiState.staffStatusFilter}
        onValueChange={onValueChange}
        id='caregiversSearchStaffStatusDropdown'
        key='caregiversSearchStaffStatusDropdown'
      />

      {(showAdvancedFilters || uiState.advancedFilter.length > 0) && (
        <MenuDropdownItem
          active={uiState.advancedMenu}
          title='Advanced Filters'
          index='advancedMenu'
          onTitleClick={onTitleClick}
          name='advancedFilter'
          options={[
            {
              value: 'extra_cost',
              text: 'Cost Penalty',
              icon: <Image inline className='coin' spaced='right' src='/coin.svg' />,
            },
            { value: 'conflict', text: 'Shift Conflict' },
          ]}
          values={uiState.advancedFilter}
          onValueChange={onValueChange}
          renderLabel={x => ({ content: x.text, icon: x.icon })}
          id='caregiversSearchAdvancedFilterDropdown'
          key='caregiversSearchAdvancedFilterDropdown'
        />
      )}

      <TeamForm uiState={uiState} onChange={onValueChange} onTitleClick={onTitleClick} />
    </Accordion>
  );
};

FilterGroup.defaultProps = {
  shiftList: null,
  showLocationSearch: false,
};

FilterGroup.propTypes = {
  uiState: PropTypes.shape().isRequired,
  onTitleClick: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  attributes: PropTypes.shape().isRequired,
  shiftList: PropTypes.arrayOf(PropTypes.shape()),
  showLocationSearch: PropTypes.bool,
};

class CaregiversMenu extends React.Component {
  constructor() {
    super();

    this.handleUpdateClick = this.handleUpdateClick.bind(this);
  }

  // eslint-disable-next-line react/state-in-constructor
  state = {
    filters: {
      preferences: {
        active: false,
        value: [],
      },
      proximity: {
        active: false,
        value: 0,
      },
    },
    hasFilterChange: false,
  };

  handleUpdateClick = () => {
    this.props.onUpdateClick();

    const params = caregiverActions.combineCaregiverParams(
      this.props.shiftList,
      this.props.uiState,
      null,
      null,
      null,
      this.props.originalShifts
    );

    this.props.dispatch(caregiverActions.listCaregivers(params));
    analytics.track(Events.CAREGIVERS_SEARCH);
    this.setState({ hasFilterChange: false });
  };

  handleFilterTitleClick = (e, titleProps) => {
    this.props.dispatch(
      uiStateActions.uiStateChange('caregiverFilter', titleProps.indexname, !titleProps.active)
    );
  };

  handleFilterValueChange = (e, inputProps) => {
    //    console.log(`Value is ${e.target.value}`, inputProps);
    const filter = this.state.filters[inputProps.name];
    let { value } = inputProps;

    switch (inputProps.type) {
      case 'checkbox':
        value = inputProps.checked
          ? [...new Set(filter.value.concat(value))]
          : filter.value.filter(x => x !== value);
        break;
      case 'range':
      default:
        break;
    }

    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        ...{
          [inputProps.name]: {
            ...filter,
            value,
          },
        },
      },
    }));
  };

  handleOnValueChange = (e, input) => {
    uiStateActions.inputChangeHandler(this.props.dispatch, 'caregiverFilter')(e, input);
    if (this.state.hasFilterChange === false) {
      this.setState({ hasFilterChange: true });
    }
  };

  render() {
    const { loading, uiState, shiftList, languages, attributes, showLocationSearch } = this.props;

    const items = [
      {
        key: 'filters',
        as: 'div',
        content: (
          <FilterGroup
            uiState={uiState}
            languages={languages}
            attributes={attributes}
            onTitleClick={this.handleFilterTitleClick}
            onValueChange={(e, input) => this.handleOnValueChange(e, input)}
            shiftList={shiftList}
            showLocationSearch={showLocationSearch}
          />
        ),
      },
      {
        key: 'update',
        as: 'div',
        content: (
          <Button
            id='caregiversSearchUpdateButton'
            primary
            content='Update'
            className={!this.state.hasFilterChange ? 'care-grey' : 'care-grey-active'}
            loading={loading}
            onClick={this.handleUpdateClick}
          />
        ),
      },
    ];

    return (
      <div className='filterBar'>
        <div className='filterTitle'>
          <Image src='/filters_icon.svg' verticalAlign='middle' /> <span>Filter Caregivers</span>
        </div>
        <Divider />
        <Menu className='filterItem' vertical secondary fluid items={items} />
      </div>
    );
  }
}

CaregiversMenu.defaultProps = {
  shiftList: null,
  showLocationSearch: false,
  originalShifts: null,
};

CaregiversMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onUpdateClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  shiftList: PropTypes.arrayOf(PropTypes.shape()),
  uiState: PropTypes.shape().isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  attributes: PropTypes.shape().isRequired,
  showLocationSearch: PropTypes.bool,
  originalShifts: PropTypes.arrayOf(PropTypes.shape()),
};

const mapStateToProps = state => {
  const { languages, attributes } = state.principal;
  const { originalShifts } = state.uiState.inputGroups.multiShiftSelector;
  return {
    languages,
    attributes,
    originalShifts,
  };
};

export default connect(uiStateActions.mapStateOfGroupToProps('caregiverFilter', mapStateToProps))(
  CaregiversMenu
);

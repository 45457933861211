/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/destructuring-assignment */
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Image, Dropdown, Divider, Icon } from 'semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';
import memoize from 'memoize-one';
import { avatarUrl, avatarStyle } from '../../../constants/DomainTypes';
import { caregiverActions, clientActions } from '../../../actions';
import { RecordsTile, RecordsHeader, RecordsSubHeader } from '../../../styles/records';
import Overlay from '../Overlay';
import './TileAgencyExclussion.css';

const SEARCH_INTERVAL_MS = 300;

const Avatar = styled(Image)`
  &&& {
    object-fit: cover;
    height: 40px;
    width: 40px;
    margin-right: 10px;
  }
`;

const RemoveButton = styled.span`
  float: right;
  line-height: 40px;
  color: #c8cbd3;
  margin-right: 1em;
  cursor: pointer;
  font-weight: normal;
  font-size: 10px;

  i {
    margin-left: 1em;
    color: #a9adb4;
    background-color: #d9d9d9;
    box-shadow: none !important;
  }

  i.icon.close:before {
    padding-left: 1px !important;
  }
`;

class TileAgencyExclusion extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      searchQuery: '',
    };
  }

  onSearchTextChange = (e, { searchQuery }) => {
    this.setState({ searchQuery });
    if (searchQuery && searchQuery.length > 2) {
      if (this.props.type === 'client') {
        this.searchCaregivers();
      } else {
        this.searchClients();
      }
    }
  };

  onCaregiverClick = (e, { value }) => {
    const { clientEnvironmentTab } = this.props;
    if (clientEnvironmentTab && clientEnvironmentTab.results) {
      const selectedCaregiver = clientEnvironmentTab.results.find(c => c.id === value);
      this.setState({
        searchQuery: '',
      });
      this.addCaregiver(selectedCaregiver);
    }
  };

  onClientClick = (e, { value }) => {
    const { shiftBuilder } = this.props;
    if (shiftBuilder && shiftBuilder.results) {
      const selectedclient = shiftBuilder.results.find(c => c.id === value);
      this.setState({
        searchQuery: '',
      });
      this.addClient(selectedclient);
    }
  };

  onSelectedChange = (e, data) => {
    if (e.code === 'Enter') {
      if (this.props.type === 'client') {
        this.onCaregiverClick(e, data);
      } else {
        this.onClientClick(e, data);
      }
    }
  };

  addCaregiver = caregiver => {
    const caregiverExclusion = this.props.person.caregiverExclusion || [];
    this.props.onValueChange(
      {},
      {
        name: 'caregiverExclusion',
        value: [...caregiverExclusion, caregiver],
      }
    );
  };

  addClient = client => {
    const clientExclusion = this.props.person.clientExclusion || [];
    this.props.onValueChange(
      {},
      {
        name: 'clientExclusion',
        value: [...clientExclusion, client],
      }
    );
  };

  removeCaregiver = id => {
    this.props.onValueChange(
      {},
      {
        name: 'caregiverExclusion',
        value: this.props.person.caregiverExclusion.filter(e => e.id !== id),
      }
    );
  };

  removeClient = id => {
    this.props.onValueChange(
      {},
      {
        name: 'clientExclusion',
        value: this.props.person.clientExclusion.filter(e => e.id !== id),
      }
    );
  };

  // eslint-disable-next-line react/sort-comp
  searchCaregivers = _.debounce(() => {
    if (this.state.searchQuery) {
      this.props.dispatch(
        caregiverActions.searchCaregivers({
          name: this.state.searchQuery,
        })
      );
    }
  }, SEARCH_INTERVAL_MS);

  searchClients = _.debounce(() => {
    if (this.state.searchQuery) {
      this.props.dispatch(
        clientActions.searchClients({
          name: this.state.searchQuery,
        })
      );
    }
  }, SEARCH_INTERVAL_MS);

  filterCaregiverResults = memoize((results, caregiverExclusion) => {
    const filtered = caregiverExclusion
      ? results.filter(r => !caregiverExclusion.find(ex => ex.id === r.id))
      : results;

    return filtered.map(c => ({
      key: c.id,
      value: c.id,
      id: c.id,
      text: c.caregiverName,
      image: { avatar: true, src: avatarUrl(c.avatar, c.gender) },
      onClick: this.onCaregiverClick,
    }));
  });

  filterClientResults = memoize((results, clientExclusion) => {
    const filtered = clientExclusion
      ? results.filter(r => !clientExclusion.find(ex => ex.id === r.id))
      : results;

    return filtered.map(c => ({
      key: c.id,
      value: c.id,
      id: c.id,
      text: c.clientName,
      image: { avatar: true, src: avatarUrl(c.avatar, c.gender) },
      onClick: this.onClientClick,
    }));
  });

  render() {
    const { person, intl, clientEnvironmentTab, shiftBuilder, editable, type } = this.props;
    const { searchQuery } = this.state;

    const target = type === 'client' ? 'caregiver' : 'client';

    let searchResults = null;
    if (searchQuery && searchQuery.length > 2) {
      if (target === 'caregiver') {
        searchResults =
          clientEnvironmentTab && clientEnvironmentTab.results
            ? this.filterCaregiverResults(clientEnvironmentTab.results, person.caregiverExclusion)
            : null;
      } else {
        searchResults =
          shiftBuilder && shiftBuilder.results
            ? this.filterClientResults(shiftBuilder.results, person.clientExclusion)
            : null;
      }
    }

    const title = intl.formatMessage({ id: `${type}s.records.${target}Exclusions` });
    const subTitle = intl.formatMessage({
      id: `${type}s.records.${target}Exclusions.details`,
    });
    const searchHint = intl.formatMessage({
      id: `${type}s.records.${target}Exclusions.addAnother`,
    });

    return (
      <RecordsTile className='tileAgencyExclusionsContainer'>
        <RecordsHeader>{title}</RecordsHeader>
        <RecordsSubHeader>{subTitle}</RecordsSubHeader>
        <Divider />
        {person.caregiverExclusion &&
          person.caregiverExclusion.map(e => (
            <div className='exclusion-list nameLink' key={e.id}>
              <Avatar
                avatar
                circular
                style={avatarStyle(e.avatar, e.gender)}
                src={avatarUrl(e.avatar, e.gender)}
              />
              <Overlay caregiver={e} editableCaregiverDetails />
              {editable && (
                <RemoveButton
                  id='tile-agency-exclusion-remove-button'
                  onClick={() => this.removeCaregiver(e.id)}
                >
                  remove
                  <Icon name='close' circular size='small' />
                </RemoveButton>
              )}
              <Divider />
            </div>
          ))}
        {person.clientExclusion &&
          person.clientExclusion.map(e => (
            <div key={e.id} className='exclusion-list'>
              <Avatar
                avatar
                circular
                style={avatarStyle(e.avatar, e.gender)}
                src={avatarUrl(e.avatar, e.gender)}
              />
              <Overlay client={e} />
              {editable && (
                <RemoveButton
                  id='tile-agency-exclusion-remove-button'
                  onClick={() => this.removeClient(e.id)}
                >
                  remove
                  <Icon name='close' circular />
                </RemoveButton>
              )}
              <Divider />
            </div>
          ))}
        {editable && (
          <Dropdown
            id='tile-agency-exclusion-dropdown'
            style={{ borderRadius: '20px' }}
            fluid
            placeholder={searchHint}
            selection
            options={searchQuery ? searchResults : null}
            search
            searchQuery={searchQuery}
            onSearchChange={this.onSearchTextChange}
            onChange={this.onSelectedChange}
            noResultsMessage={searchResults !== null ? 'No matching results' : null}
            icon={null}
            value={null}
          />
        )}
      </RecordsTile>
    );
  }
}

TileAgencyExclusion.defaultProps = {
  person: {},
  onValid: () => {},
  onInvalid: () => {},
};

TileAgencyExclusion.propTypes = {
  person: PropTypes.shape(),
  editable: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  onValueChange: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  clientEnvironmentTab: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  shiftBuilder: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  type: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  const { clientEnvironmentTab, shiftBuilder } = state;
  return {
    clientEnvironmentTab,
    shiftBuilder,
  };
};

export default connect(mapStateToProps)(injectIntl(TileAgencyExclusion));

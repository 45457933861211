import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Divider, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import moment from 'moment';

import { RecordsHeader, RecordsSubHeader, RecordsTile } from '../../../styles/records';
import { avatarUrl, avatarStyle } from '../../../constants/DomainTypes';
import Overlay from '../Overlay';

const Avatar = styled(Image)`
  &&& {
    object-fit: cover;
    height: 40px;
    width: 40px;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
`;

const DetailText = styled.div`
  color: #4a4a4a;
  font-size: 12px;
`;

const CaregiverDivider = styled(Divider)`
  &&&& {
    border-top: 1px solid #d8d8d8;
    clear: both;
  }
`;

const TileRecentCaregivers = ({ client, intl }) => {
  const { recentCaregivers } = client;
  const recentCaregiversMap = new Map();

  recentCaregivers.forEach(c => {
    const caregiver = recentCaregiversMap.get(c.id);
    if (caregiver) {
      caregiver.count += 1;
    } else {
      recentCaregiversMap.set(c.id, { ...c, count: 1 });
    }
  });
  const combinedCaregivers = Array.from(recentCaregiversMap.values()).slice(0, 3);

  const title = intl.formatMessage({ id: 'records.recentCaregivers' });
  const subTitle = intl.formatMessage({ id: 'records.recentCaregivers.details' });

  return (
    <RecordsTile>
      <RecordsHeader>{title}</RecordsHeader>
      <RecordsSubHeader>{subTitle}</RecordsSubHeader>
      {combinedCaregivers.map(c => (
        <div key={c.id}>
          <div className='left floated'>
            <Avatar
              avatar
              circular
              style={avatarStyle(c.avatar, c.gender)}
              src={avatarUrl(c.avatar, c.gender)}
            />
          </div>
          <div className='left floated'>
            <Overlay caregiver={c} />
            <DetailText>{c.discipline}</DetailText>
          </div>
          <div className='right floated'>
            <DetailText>{moment(c.assigned).fromNow()}</DetailText>
            <DetailText>
              {intl.formatMessage(
                { id: 'records.recentCaregivers.recentShifts' },
                { count: c.count }
              )}
            </DetailText>
          </div>
          <CaregiverDivider />
        </div>
      ))}
    </RecordsTile>
  );
};

TileRecentCaregivers.propTypes = {
  intl: intlShape.isRequired,
  client: PropTypes.shape().isRequired,
};

export default injectIntl(TileRecentCaregivers);

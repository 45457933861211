/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/state-in-constructor */
import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'formsy-semantic-ui-react';

class AgencySelect extends React.Component {
  state = {
    employeeList: [{ text: 'Select', value: -1 }],
    managerList: [{ text: 'Select', value: -1 }],
  };

  UNSAFE_componentWillMount() {
    this.prepareEmployeeLists(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.prepareEmployeeLists(nextProps);
  }

  prepareEmployeeLists(props) {
    if (props.uiState && props.uiState.primaryContacts && props.uiState.primaryManagers) {
      const { primaryContacts, primaryManagers } = props.uiState;
      const employeeList = primaryContacts
        ? primaryContacts.map(e => ({
            text: `${e.lastName ? e.lastName : ''}, ${e.firstName ? e.firstName : ''}`,
            value: e.id,
          }))
        : [];

      const managerList = primaryManagers
        ? primaryManagers.map(e => ({
            text: `${e.lastName ? e.lastName : ''}, ${e.firstName ? e.firstName : ''}`,
            value: e.id,
          }))
        : [];

      this.setState({ employeeList, managerList });
    }
  }

  render() {
    const { onChange, isManager, isClientView, isRequired } = this.props;
    const { employeeList, managerList } = this.state;

    if (isManager) {
      return (
        <Form.Select
          id='agency-select-primary-manager'
          name='primaryManager'
          label={isClientView ? 'Primary Case Manager:' : 'Primary Contacts Manager:'}
          options={managerList}
          placeholder='Select'
          onChange={onChange}
          required={isRequired}
          value={this.props.value}
        />
      );
    }

    return (
      <Form.Select
        id='agency-select-primary-contact'
        name='primaryContact'
        label={isClientView ? 'Primary Agency Contact:' : 'Primary Contact:'}
        options={employeeList}
        placeholder='Select'
        onChange={onChange}
        required={isRequired}
        value={this.props.value}
      />
    );
  }
}

AgencySelect.defaultProps = {
  isManager: false,
  isClientView: true,
  isRequired: false,
};

AgencySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  isManager: PropTypes.bool,
  isClientView: PropTypes.bool,
  isRequired: PropTypes.bool,
  value: PropTypes.number.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  uiState: PropTypes.shape().isRequired,
};

export default AgencySelect;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Form, Label, Icon, Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Caregivers.css';
import { uiStateActions, caregiverActions } from '../actions';
import { analytics, Events } from '../helpers/analytics';
import CareTimePicker from './subcomponents/CareTimePicker';

class ShiftDetailsBar extends React.Component {
  constructor() {
    super();
    this.pickerChange = this.pickerChange.bind(this);
    this.handleUpdateClick = this.handleUpdateClick.bind(this);
  }

  pickerChange = stateName => e => {
    this.props.dispatch(uiStateActions.uiStateChange('shiftBarFilter', stateName, e));
  };

  handleUpdateClick = () => {
    const params = caregiverActions.combineCaregiverParams(
      null,
      this.props.caregiverFilter,
      this.props.uiState,
      null
    );
    this.props.dispatch(caregiverActions.listCaregivers(params));
    analytics.track(Events.CAREGIVERS_SEARCH_LOCATION_DATE);
  };

  render() {
    const { uiState } = this.props;

    const locationTimezone = this.props.timezone
      ? moment.tz.zone(this.props.timezone)
      : moment.tz.zone(moment.tz.guess());

    return (
      <Container fluid className='medium-grey-bg shiftDetailsBar'>
        <Label className='leftHeader greyColor' pointing='right'>
          <Icon name='search' size='big' />
        </Label>
        <div className='mainContent'>
          <Form size='mini' id='caregiversSearchForm' className='detailItem'>
            <Form.Group>
              <Form.Input
                className='divided'
                id='caregiversSearchShiftLocation'
                style={{ minWidth: '20em' }}
                size='mini'
                value={uiState.address}
                name='address'
                label='Shift Location'
                onChange={uiStateActions.inputChangeHandler(this.props.dispatch, 'shiftBarFilter')}
                placeholder='Enter address or Zip code'
              />
              <div className='field datepickerField divided'>
                <label htmlFor='date'>Date</label>
                <div className='ui input'>
                  <DatePicker
                    id='caregiversSearchShiftDate'
                    selected={uiState.date}
                    onChange={this.pickerChange('date')}
                  />
                </div>
              </div>
              <div className='caregivers-search-time-picker-container'>
                <label className='start-label' htmlFor='start'>
                  Start
                </label>
                <CareTimePicker
                  timezone={locationTimezone}
                  value={uiState.start}
                  onChange={this.pickerChange('start')}
                />
                <label className='end-label' htmlFor='end'>
                  End
                </label>
                <CareTimePicker
                  value={uiState.end}
                  timezone={locationTimezone}
                  onChange={this.pickerChange('end')}
                />
              </div>
            </Form.Group>
          </Form>

          <Form.Button
            id='caregiversSearchShiftView'
            style={{
              position: 'absolute',
              right: '50px',
              marginTop: '4px',
            }}
            form='caregiversSearchForm'
            className='care-blue viewButton'
            size='mini'
            onClick={this.handleUpdateClick}
          >
            View
          </Form.Button>
        </div>
      </Container>
    );
  }
}

ShiftDetailsBar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uiState: PropTypes.shape().isRequired,
  caregiverFilter: PropTypes.shape().isRequired,
  timezone: PropTypes.shape().isRequired,
};

const mapStateToProps = state => {
  const { uiState, shiftTiming } = state;
  return {
    caregiverFilter: uiState.inputGroups ? uiState.inputGroups.caregiverFilter : undefined,
    shiftTiming,
  };
};

export default connect(uiStateActions.mapStateOfGroupToProps('shiftBarFilter', mapStateToProps))(
  ShiftDetailsBar
);

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Divider, Image } from 'semantic-ui-react';
import ComplianceExclusions from './exclusions';
import TileGrid from '../records/TileGrid';
import ComplianceBlock from './complianceBlock';
import CGInformationDohRegistrynumber from '../CGInformationDohRegistrynumber';
import '../../CGCompliance.css';
import CommonComplianceBlock from './CommonComplianceBlock';

class ComplianceEligibility extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  block = (b, minimized = true) => {
    const style = {};
    if (minimized) style.marginTop = '24px';
    else style.marginBottom = '24px';
    return (
      <div className={!minimized ? 'infoPanel' : ''} style={style} key={b.id}>
        <ComplianceBlock
          key={b.id}
          block={b}
          showViewLink={!b.includedInEligibility}
          minimized={minimized}
          divided={false}
        />
        {minimized && <Divider />}
      </div>
    );
  };

  render() {
    const {
      exclusions,
      hasExclusions,
      loadingExclusions,
      exclusionsError,
      blocks,
      uploadingDocument,
    } = this.props.caregiverCompliance;

    const { hrManager } = this.props;

    const expiringBlocks = blocks ? blocks.filter(b => b.aboutToExpire) : null;
    const eligibilityDocumentation = blocks
      ? blocks.filter(
          b => b.includedInEligibility && b.type === 'documentation' && !b.controlledInProfile
        )
      : null;
    const eligibilityDocWithProfileControl = blocks
      ? blocks.filter(
          b => b.includedInEligibility && b.type === 'documentation' && b.controlledInProfile
        )
      : null;
    const exclusionsBlock = blocks ? blocks.find(b => b.type === 'exclusions') : null;
    const dohBlock = blocks ? blocks.find(b => b.type === 'doh') : null;

    return (
      <TileGrid>
        {expiringBlocks && expiringBlocks.length > 0 && (
          <div className='infoPanel' key='pendingExpirations'>
            <div
              style={{
                background: '#ffeeee',
                margin: '-16px',
                marginBottom: '16px',
                padding: '32px',
                borderWidth: '4px 0 0 0',
                borderStyle: 'solid',
                borderColor: '#ff8888',
              }}
            >
              <Image style={{ float: 'left', marginRight: '16px' }} src='/compliance/warning.svg' />
              <p className='tileHeader'>
                <b>PENDING EXPIRATIONS</b>
              </p>
              <p className='small'>
                The following compliance items will soon expire. If these items expire, they will
                render the caregiver <b className='red'>UNAVAILABLE FOR WORK</b>. Action should be
                taken to renew these items as soon as possible.
              </p>
            </div>
            {blocks && expiringBlocks.map(b => this.block(b))}
          </div>
        )}
        {hrManager && dohBlock && <CGInformationDohRegistrynumber />}
        {eligibilityDocumentation && eligibilityDocumentation.map(b => this.block(b, false))}
        {eligibilityDocWithProfileControl &&
          eligibilityDocWithProfileControl.map(b => <CommonComplianceBlock key={b.id} block={b} />)}
        {!exclusionsError && exclusionsBlock && (
          <ComplianceExclusions
            exclusions={exclusions}
            blocks={blocks}
            hasExclusions={hasExclusions}
            loadingExclusions={loadingExclusions}
            exclusionsError={exclusionsError}
            onSubmitExclusion={this.props.onSubmitExclusion}
            uploadDocument={this.props.uploadDocument}
            documentRemoved={this.props.documentRemoved}
            uploadingDocument={uploadingDocument}
            hrManager={hrManager}
          />
        )}
      </TileGrid>
    );
  }
}

ComplianceEligibility.propTypes = {
  caregiverCompliance: PropTypes.shape().isRequired,
  onSubmitExclusion: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired,
  documentRemoved: PropTypes.func.isRequired,
  hrManager: PropTypes.bool.isRequired,
};

export default injectIntl(ComplianceEligibility);

/* eslint-disable  @typescript-eslint/no-explicit-any */
import _ from 'underscore';
import { Moment } from 'moment';
import {
  LIST_CLIENT_REQUEST,
  LIST_CLIENT_SUCCESS,
  LIST_CLIENT_FAILURE,
} from '../constants/ActionTypes';

import { prepareClientObject } from './helpers';

const initialState = {
  loading: false,
  initialRequestDone: false,
  partialUpdate: false,
  listClientsTimestamp: undefined as Moment | undefined,
  results: [],
};

function clients(state = initialState, action: any): any {
  switch (action.type) {
    case LIST_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
        initialRequestDone: true,
        partialUpdate: action.partialUpdate,
      };
    case LIST_CLIENT_SUCCESS: {
      const lct = state.listClientsTimestamp;
      if (!lct || !action.results.timestamp || lct.isBefore(action.results.timestamp)) {
        const results = action.results
          ? action.results.results.map((x: any) => prepareClientObject(x))
          : [];
        let newResults = action.partialUpdate ? state.results.concat(results) : results;
        newResults = _.uniq(newResults, false, (s: any) => s.id);

        return {
          ...state,
          initialRequestDone: true,
          loading: false,
          results: newResults,
          tooManyResults: action.tooManyResults,
          nextPage: action.results.nextPage,
          partialUpdate: action.partialUpdate,
          listClientsTimestamp: action.results.timestamp,
        };
      }
      return state;
    }
    case LIST_CLIENT_FAILURE:
      return {
        ...state,
        initialRequestDone: true,
        loading: false,
        partialUpdate: action.partialUpdate,
      };
    default:
      return state;
  }
}

export default clients;

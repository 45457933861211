import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Form, Divider } from 'semantic-ui-react';
import styled from 'styled-components';

const PreferenceLabel = styled.label`
  margin-left: 1rem;
  margin-right: 1rem;
  color: ${props => (props.defaultChecked ? 'black' : 'grey')};
  min-width: 80%;
  max-width: 80%;
  word-wrap: break-word;
`;

class PreferencesCategory extends React.Component {
  render() {
    const {
      person,
      onValueChange,
      editable,
      category,
      propertyName,
      appendMode,
      type,
    } = this.props;

    const items = person[propertyName] && person[propertyName].length ? person[propertyName] : [];

    const preferences = category ? items.filter(x => x.category === category) : items;

    const mergeValues = (id, checked) => {
      const value = checked ? 'Y' : 'N';
      return person[propertyName].map(x => (x.id === id ? { ...x, value } : x));
    };

    if (preferences && preferences.length) {
      const rows = preferences.map((pref, idx) => {
        const defaultChecked = pref.value === 'Y';
        const typeLabel = type === 'caregiver' ? pref.caregiverLabel : pref.clientLabel;

        return (
          <Grid.Row key={pref.id}>
            {idx === 0 && !appendMode && <Divider />}
            <Form.Group>
              <PreferenceLabel htmlFor={pref.id} defaultChecked={defaultChecked}>
                {typeLabel || pref.label || pref.name}
              </PreferenceLabel>
              <Form.Checkbox
                toggle
                name={propertyName}
                id={pref.id}
                disabled={!editable}
                defaultChecked={defaultChecked}
                onChange={(e, { id, checked, name }) =>
                  onValueChange(e, { value: mergeValues(id, checked), name })
                }
              />
            </Form.Group>
            <Divider />
          </Grid.Row>
        );
      });

      return <Form>{rows}</Form>;
    }

    return null;
  }
}

PreferencesCategory.defaultProps = {
  category: null,
  propertyName: 'preferences',
  appendMode: false,
  type: 'caregiver',
};

PreferencesCategory.propTypes = {
  editable: PropTypes.bool.isRequired,
  person: PropTypes.shape().isRequired,
  category: PropTypes.string,
  propertyName: PropTypes.string,
  appendMode: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default PreferencesCategory;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Form } from 'formsy-semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MOMENT_DOB, beginningSearch } from '../constants/Formats';
import { EmployeeAvailableRoles, StaffStatusMap } from '../constants/DomainTypes';
import './Common.css';
import './Clients.css';
import AddressFormsy from './subcomponents/AddressFormsy';
import SSNInput from './subcomponents/SSNInput';

const options = [
  { key: 'm', text: 'Male', value: 'm' },
  { key: 'f', text: 'Female', value: 'f' },
  { key: 'u', text: 'Other', value: 'u' },
];

const staffStatusOptions = Object.keys(StaffStatusMap).map(x => ({
  key: x,
  value: x,
  text: StaffStatusMap[x].name,
}));

const candidateStatus = [
  {
    key: '01',
    value: '01',
    text: 'Pending',
  },
];

class CCEditorInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.form.formsyForm.setFormPristine(false);
  }

  render() {
    const {
      dispatch,
      onValueChange,
      disableSubmitButton,
      enableSubmitButton,
      disciplines,
      languages,
      type,
      intl,
      secondaryStatusCodes,
      isCandidate,
      multiDisciplineEnabled,
    } = this.props;

    const titleOptions = [
      { key: '1', text: intl.formatMessage({ id: 'add.employee.manager' }), value: 'manager' },
      {
        key: '2',
        text: intl.formatMessage({ id: 'add.employee.coordinator' }),
        value: 'scheduler',
      },
    ];

    const labelWidth = 4;
    const instantValidation = true;
    const languageOptions = languages.map(l => ({ value: l.value, text: l.text }));
    const disciplineOptions = disciplines;
    const person = this.props.values;
    const isClient = type === CCEditorInfo.CLIENT;
    const isEmployee = type === CCEditorInfo.EMPLOYEE;
    const isCaregiver = type === CCEditorInfo.CAREGIVER;
    const secondaryStatusCodeOptions = isCandidate
      ? secondaryStatusCodes?.filter(x => x.staffStatus === '01')
      : secondaryStatusCodes;

    if (isCaregiver && !person.secondaryStatusCode && secondaryStatusCodeOptions) {
      person.secondaryStatusCode = secondaryStatusCodeOptions.length
        ? secondaryStatusCodeOptions[0].value
        : null;
    }

    const showAddress = !isEmployee;
    const showSSN = true;
    const showPhone = true;

    const userTitle = () => {
      switch (type) {
        case CCEditorInfo.CLIENT:
          return 'client';
        case CCEditorInfo.EMPLOYEE:
          return 'employee';
        case CCEditorInfo.CAREGIVER:
          return 'caregiver';
        default:
          return 'user';
      }
    };

    return (
      <Form
        ref={c => {
          this.form = c;
        }}
        className='careAddForm'
        size='mini'
        onInvalid={disableSubmitButton}
        onValid={enableSubmitButton}
      >
        <label className='formNoticeLabel' htmlFor='firstName'>
          ! All red fields are required to create a {userTitle(type)} record
        </label>
        <Form.Group>
          <Form.Input
            id='cc-editor-info-first-name-input'
            fluid
            name='firstName'
            placeholder='First name'
            className='smallRightPadding hideLabel'
            width={14}
            onChange={onValueChange}
            value={person.firstName}
            validations={{
              minLength: 2,
              maxLength: 25,
            }}
            validationErrors={{ isValid: 'firstName not valid' }}
            instantValidation={instantValidation}
            required
          />
          <Form.Input
            id='cc-editor-info-middle-name-input'
            fluid
            name='middleName'
            placeholder='MI'
            width={2}
            className='smallLeftPadding hideLabel'
            onChange={onValueChange}
            value={person.middleName}
            validations={{
              minLength: 1,
              maxLength: 25,
            }}
            validationErrors={{ isValid: 'middleName not valid' }}
            instantValidation={instantValidation}
          />
        </Form.Group>
        <Form.Input
          id='cc-editor-info-last-name-input'
          fluid
          className='hideLabel'
          name='lastName'
          placeholder='Last name'
          onChange={onValueChange}
          value={person.lastName}
          validations={{
            minLength: 2,
            maxLength: 50,
          }}
          validationErrors={{ isValid: 'lastName not valid' }}
          instantValidation={instantValidation}
          required
        />
        <hr />
        <Form.Select
          id='cc-editor-info-gender-select'
          name='gender'
          label='Gender'
          options={options}
          placeholder='Choose'
          onChange={onValueChange}
          value={person.gender}
          required={!isEmployee}
        />
        <hr />
        {isCaregiver && (
          <div>
            {multiDisciplineEnabled ? (
              <Form.Select
                id='cc-editor-info-discipline-select'
                name='discipline'
                label='Disciplines'
                options={disciplineOptions}
                placeholder='Choose'
                multiple
                onChange={(e, i) => {
                  const strVal = i.value && i.value.length ? i.value.join(',') : null;
                  return onValueChange(e, { ...i, value: strVal }, true);
                }}
                value={person.discipline ? person.discipline.split(',') : []}
                required
              />
            ) : (
              <Form.Select
                id='cc-editor-info-discipline-select'
                name='discipline'
                label='Discipline'
                options={disciplineOptions}
                placeholder='Choose'
                onChange={onValueChange}
                value={person.discipline}
                required
              />
            )}
            <hr />
            <Form.Select
              id='cc-editor-info-status-select'
              name='staffStatus'
              label='Caregiver Status'
              options={!isCandidate ? staffStatusOptions : candidateStatus}
              placeholder='Choose'
              onChange={onValueChange}
              value={person.staffStatus}
              required
              style={isCandidate ? { pointerEvents: 'none' } : {}}
            />
            <hr />
            {isCaregiver && isCandidate && (
              <>
                <Form.Select
                  id='cc-editor-info-secondary-status-select'
                  name='secondaryStatusCode'
                  label='Secondary Status'
                  options={secondaryStatusCodeOptions}
                  placeholder='Choose'
                  onChange={onValueChange}
                  value={person.secondaryStatusCode}
                  required
                />
                <hr />
              </>
            )}
          </div>
        )}
        {isEmployee && (
          <div>
            <Form.Select
              id='cc-editor-info-role-select'
              name='title'
              label='Role'
              options={titleOptions}
              placeholder='Choose'
              onChange={onValueChange}
              required
            />
            <hr />
            <Form.Select
              id='cc-editor-info-roles-select'
              name='roles' // badly named in API
              label='Access Rights'
              options={EmployeeAvailableRoles}
              placeholder='Choose'
              onChange={onValueChange}
              validations={{
                customValidation: (values, value) => !(!value || value.length < 1),
              }}
              required
              multiple
            />
            <hr />
          </div>
        )}
        {!isEmployee && (
          <div>
            <Form.Select
              id='cc-editor-info-languages-select'
              name='languages'
              label='Languages'
              multiple
              search={beginningSearch}
              options={languageOptions}
              value={person.languages}
              validations={{
                customValidation: (values, value) => !(!value || value.length < 1),
              }}
              onChange={onValueChange}
              required
            />
            <hr />
          </div>
        )}
        {showAddress && (
          <div>
            <AddressFormsy
              onValueChange={onValueChange}
              isEmployee={isEmployee}
              person={person}
              dispatch={dispatch}
            />
            <hr />
          </div>
        )}
        {showSSN && (
          <div>
            <Grid>
              <Grid.Row>
                <Grid.Column width={labelWidth} className='smallRightPadding'>
                  <label htmlFor='ssn'>Social Security:</label>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>
                    <SSNInput
                      onValueChange={onValueChange}
                      isNewCaregiver={isCaregiver}
                      value={person.ssn}
                    />
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <hr />
          </div>
        )}
        <Grid>
          <Grid.Row>
            <Grid.Column width={labelWidth}>
              <label htmlFor='dobInput'>Birthday:</label>
            </Grid.Column>
            <Grid.Column width={12}>
              <DatePicker
                customInput={
                  <Form.Input
                    id='cc-editor-info-dob-input'
                    name='dobInput'
                    placeholder='MM/DD/YYYY'
                    onChange={onValueChange}
                    value={person.phones}
                    validationErrors={{ isPhone: 'Phone not valid' }}
                    instantValidation={instantValidation}
                    required={!isClient}
                  />
                }
                className='addClientBirthdayInput hideLabel'
                placeholderText='Birthday'
                float
                name='dob'
                id='clientBirthDate'
                popperPlacement='top-end'
                selected={person && person.dob && moment(person.dob, MOMENT_DOB)}
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                width={16}
                onChange={onValueChange}
                dateFormat='MM/DD/YYYY'
                maxDate={moment()}
                required={isCaregiver}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <hr />
        {showPhone && (
          <div>
            <Grid>
              <Grid.Row>
                <Grid.Column width={labelWidth}>
                  <label htmlFor='phones'>{isClient ? 'Phone:' : 'Cell Phone:'}</label>
                </Grid.Column>
                <Grid.Column width={12}>
                  <Form.Input
                    id='cc-editor-info-phones-input'
                    name='phones'
                    placeholder={isClient ? 'Enter Phone Number' : 'Enter Cellphone number'}
                    onChange={onValueChange}
                    validations={{
                      matchRegexp: /^[0-9+()#.\s/ext-]+$/,
                      minLength: 6,
                      maxLength: 30,
                    }}
                    value={person.phones}
                    validationErrors={{ isPhone: 'Phone not valid' }}
                    instantValidation={instantValidation}
                    required={!isClient && !person.email}
                    className='hideLabel'
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <hr />
          </div>
        )}
        <Grid>
          <Grid.Row>
            <Grid.Column width={labelWidth}>
              <label htmlFor='email'>Email:</label>
            </Grid.Column>
            <Grid.Column width={12}>
              <Form.Input
                id='cc-editor-info-email-input'
                name='email'
                placeholder='Enter Email Address'
                onChange={onValueChange}
                validations={{
                  matchRegexp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  maxLength: 200,
                }}
                validationErrors={{ isEmail: 'Email not valid' }}
                instantValidation={instantValidation}
                value={person.email}
                className='hideLabel'
                required={isEmployee || (isCaregiver && !person.phones)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {isEmployee && (
          <div>
            <hr />
            <Grid>
              <Grid.Row>
                <Grid.Column width={labelWidth}>
                  <label htmlFor='email'>Unit:</label>
                </Grid.Column>
                <Grid.Column width={12}>
                  <Form.Input
                    id='cc-editor-info-unit-input'
                    name='meta.unit'
                    placeholder='Unit'
                    onChange={onValueChange}
                    validations={{
                      maxLength: 30,
                    }}
                    validationErrors={{ maxLength: 'Unit not valid' }}
                    instantValidation={instantValidation}
                    value={person.meta?.unit}
                    className='hideLabel'
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <hr />
            <Grid>
              <Grid.Row>
                <Grid.Column width={labelWidth}>
                  <label htmlFor='email'>Location:</label>
                </Grid.Column>
                <Grid.Column width={12}>
                  <Form.Input
                    id='cc-editor-info-unit-input'
                    name='meta.location'
                    placeholder='Location'
                    onChange={onValueChange}
                    validations={{
                      maxLength: 30,
                    }}
                    validationErrors={{ maxLength: 'Location not valid' }}
                    instantValidation={instantValidation}
                    value={person.meta?.location}
                    className='hideLabel'
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        )}

        {isClient && (
          <div>
            <hr />
            <label id='notesLabel' htmlFor='notes'>
              NOTES
            </label>
            <Form.TextArea
              id='cc-editor-info-notes-text-area'
              name='notes'
              autoHeight
              placeholder='Add any additional notes for this client'
              onChange={onValueChange}
              rows={5}
              style={{ color: '#4A4A4A' }}
              validations={{
                minLength: 1,
                maxLength: 2048,
              }}
              validationErrors={{ isNotes: 'Notes not valid' }}
              instantValidation={instantValidation}
              className='hideLabel'
            />
          </div>
        )}
      </Form>
    );
  }
}

CCEditorInfo.CLIENT = 1;
CCEditorInfo.CAREGIVER = 2;
CCEditorInfo.EMPLOYEE = 3;

CCEditorInfo.defaultProps = {
  values: null,
};

CCEditorInfo.propTypes = {
  dispatch: PropTypes.func.isRequired,
  values: PropTypes.shape(),
  type: PropTypes.oneOf([CCEditorInfo.CLIENT, CCEditorInfo.CAREGIVER, CCEditorInfo.EMPLOYEE])
    .isRequired,
  onValueChange: PropTypes.func.isRequired,
  disableSubmitButton: PropTypes.func.isRequired,
  enableSubmitButton: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disciplines: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  intl: intlShape.isRequired,
  isCandidate: PropTypes.bool.isRequired,
  secondaryStatusCodes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  multiDisciplineEnabled: PropTypes.bool.isRequired,
};

export default injectIntl(CCEditorInfo);

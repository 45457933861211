import { INotesParams } from '../../actions/applicants';
import {
  GET_APPLICANTS_LIST_REQUEST,
  GET_APPLICANTS_LIST_SUCCESS,
  GET_APPLICANTS_LIST_FAILURE,
  FILTER_APPLICANT_BY_NAME,
  FILTER_APPLICANT_BY_ZIP,
  APPLICANT_TO_PROCEED_STATUS_REQUEST,
  APPLICANT_TO_PROCEED_STATUS_SUCCESS,
  APPLICANT_TO_PROCEED_STATUS_FAILURE,
  APPLICANT_RESET_STATUS_REQUEST,
  APPLICANT_RESET_STATUS_FAILURE,
  APPLICANT_DECLINE_STATUS_REQUEST,
  APPLICANT_DECLINE_STATUS_SUCCESS,
  APPLICANT_DECLINE_STATUS_FAILURE,
  APPLICANT_SAVE_NOTES_REQUEST,
  APPLICANT_SAVE_NOTES_SUCCESS,
  APPLICANT_SAVE_NOTES_FAILURE,
} from '../../constants/ActionTypes';

import { IApplicantProfile, IApplicantsState } from '../types/applicants';

const initialState: IApplicantsState = {
  allApplicants: {},
  newApplicants: [],
  approvedApplicants: [],
  declinedApplicants: [],
  customFields: [],
  loading: false,
  error: false,
};

const startState = JSON.parse(JSON.stringify(initialState));

const filterCompletedProfiles = (profiles: IApplicantProfile[]) => {
  const completedProfiles = profiles.filter(p => p.profileStatus?.status === 'COMPLETED');
  const newApplicants = completedProfiles.filter(
    p => !p.agencyStatus || p.agencyStatus?.status === 'NEW'
  );
  const approvedApplicants = completedProfiles.filter(p => p.agencyStatus?.status === 'TO_PROCEED');
  const declinedApplicants = completedProfiles.filter(p => p.agencyStatus?.status === 'DECLINED');
  const filterApplicants = {
    completedProfiles,
    newApplicants,
    approvedApplicants,
    declinedApplicants,
  };
  return filterApplicants;
};

const addApplicantNote = (profiles: IApplicantProfile[], params: INotesParams) => {
  const profileIndex = profiles.findIndex((p: IApplicantProfile) => p.token === params.token);
  if (profileIndex >= 0) {
    const newProfiles = profiles;
    newProfiles[profileIndex].notes = params.notes;
    return newProfiles;
  }
  return profiles;
};

const filterByName = (allApplicants: IApplicantProfile[], name: string, status: string) => {
  let filterProfiles: IApplicantProfile[] = [];
  switch (status) {
    case 'new':
      if (!name) {
        filterProfiles = allApplicants.filter(
          p => !p.agencyStatus || p.agencyStatus?.status === 'NEW'
        );
      } else {
        filterProfiles = allApplicants.filter(a => {
          const applicantName = `${a.basicInformation.firstName.toLowerCase()} ${a.basicInformation.lastName.toLowerCase()}`;
          return (
            applicantName.includes(name.toLowerCase()) &&
            (!a.agencyStatus || a.agencyStatus?.status === 'NEW')
          );
        });
      }
      break;
    case 'approved':
      if (!name) {
        filterProfiles = allApplicants.filter(p => p.agencyStatus?.status === 'TO_PROCEED');
      } else {
        filterProfiles = allApplicants.filter(a => {
          const applicantName = `${a.basicInformation.firstName.toLowerCase()} ${a.basicInformation.lastName.toLowerCase()}`;
          return (
            applicantName.includes(name.toLowerCase()) && a.agencyStatus?.status === 'TO_PROCEED'
          );
        });
      }
      break;
    case 'declined':
      if (!name) {
        filterProfiles = allApplicants.filter(p => p.agencyStatus?.status === 'DECLINED');
      } else {
        filterProfiles = allApplicants.filter(a => {
          const applicantName = `${a.basicInformation.firstName.toLowerCase()} ${a.basicInformation.lastName.toLowerCase()}`;
          return (
            applicantName.includes(name.toLowerCase()) && a.agencyStatus?.status === 'DECLINED'
          );
        });
      }
      break;
    default:
      break;
  }
  return filterProfiles;
};

const filterByZip = (allApplicants: IApplicantProfile[], zip: string, status: string) => {
  let filterProfiles: IApplicantProfile[] = [];
  switch (status) {
    case 'new':
      if (!zip) {
        filterProfiles = allApplicants.filter(
          p => !p.agencyStatus || p.agencyStatus?.status === 'NEW'
        );
      } else {
        filterProfiles = allApplicants.filter(
          a =>
            a.additionalInformation.address.zip.includes(zip) &&
            (!a.agencyStatus || a.agencyStatus?.status === 'NEW')
        );
      }
      break;
    case 'approved':
      if (!zip) {
        filterProfiles = allApplicants.filter(p => p.agencyStatus?.status === 'TO_PROCEED');
      } else {
        filterProfiles = allApplicants.filter(
          a =>
            a.additionalInformation.address.zip.includes(zip) &&
            a.agencyStatus?.status === 'TO_PROCEED'
        );
      }
      break;
    case 'declined':
      if (!zip) {
        filterProfiles = allApplicants.filter(p => p.agencyStatus?.status === 'DECLINED');
      } else {
        filterProfiles = allApplicants.filter(
          a =>
            a.additionalInformation.address.zip.includes(zip) &&
            a.agencyStatus?.status === 'DECLINED'
        );
      }
      break;
    default:
      break;
  }
  return filterProfiles;
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function applicants(state = startState, action: any): IApplicantsState {
  switch (action.type) {
    case GET_APPLICANTS_LIST_REQUEST: {
      return {
        ...state,
        newApplicants: [...state.newApplicants],
        approvedApplicants: [...state.approvedApplicants],
        declinedApplicants: [...state.declinedApplicants],
        loading: !action.partialUpdate,
        partialUpdate: action.partialUpdate,
      };
    }
    case GET_APPLICANTS_LIST_SUCCESS: {
      const allProfiles = filterCompletedProfiles(action.results.profiles);
      return {
        ...state,
        loading: false,
        error: false,
        allApplicants: action.partialUpdate
          ? state.allApplicants.concat(allProfiles.completedProfiles)
          : allProfiles.completedProfiles,
        newApplicants: action.partialUpdate
          ? state.newApplicants.concat(allProfiles.newApplicants)
          : allProfiles.newApplicants,
        approvedApplicants: action.partialUpdate
          ? state.approvedApplicants.concat(allProfiles.approvedApplicants)
          : allProfiles.approvedApplicants,
        declinedApplicants: action.partialUpdate
          ? state.declinedApplicants.concat(allProfiles.declinedApplicants)
          : allProfiles.declinedApplicants,
        customFields: action.partialUpdate ? state.customFields : action.results.customFields,
        lastKey: action.results.lastKey,
        partialUpdate: action.partialUpdate,
      };
    }
    case GET_APPLICANTS_LIST_FAILURE: {
      return {
        ...state,
        newApplicants: [...state.newApplicants],
        approvedApplicants: [...state.approvedApplicants],
        declinedApplicants: [...state.declinedApplicants],
        loading: false,
        error: true,
        partialUpdate: action.partialUpdate,
      };
    }
    case FILTER_APPLICANT_BY_NAME: {
      return {
        ...state,
        newApplicants: filterByName(state.allApplicants, action.name, 'new'),
        approvedApplicants: filterByName(state.allApplicants, action.name, 'approved'),
        declinedApplicants: filterByName(state.allApplicants, action.name, 'declined'),
      };
    }
    case FILTER_APPLICANT_BY_ZIP: {
      return {
        ...state,
        newApplicants: filterByZip(state.allApplicants, action.zip, 'new'),
        approvedApplicants: filterByZip(state.allApplicants, action.zip, 'approved'),
        declinedApplicants: filterByZip(state.allApplicants, action.zip, 'declined'),
      };
    }
    case APPLICANT_TO_PROCEED_STATUS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case APPLICANT_TO_PROCEED_STATUS_SUCCESS: {
      const newNewApplicants = state.newApplicants.filter(
        (a: IApplicantProfile) => a.token !== action.response.success[0]
      );
      const newApproved = state.newApplicants.find(
        (a: IApplicantProfile) => a.token === action.response.success[0]
      );
      const newApprovedApplicants = state.approvedApplicants;
      newApprovedApplicants.push(newApproved);
      return {
        ...state,
        newApplicants: newNewApplicants,
        approvedApplicants: newApprovedApplicants,
        loading: false,
      };
    }
    case APPLICANT_TO_PROCEED_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case APPLICANT_DECLINE_STATUS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case APPLICANT_DECLINE_STATUS_SUCCESS: {
      const newNewApplicants = state.newApplicants.filter(
        (a: IApplicantProfile) => a.token !== action.response.success[0]
      );
      const newDecline = state.newApplicants.find(
        (a: IApplicantProfile) => a.token === action.response.success[0]
      );
      const newDeclinesApplicants = state.declinedApplicants;
      newDeclinesApplicants.push(newDecline);
      return {
        ...state,
        newApplicants: newNewApplicants,
        declinedApplicants: newDeclinesApplicants,
        loading: false,
      };
    }
    case APPLICANT_DECLINE_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case APPLICANT_RESET_STATUS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case APPLICANT_RESET_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case APPLICANT_SAVE_NOTES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case APPLICANT_SAVE_NOTES_SUCCESS: {
      return {
        ...state,
        loading: false,
        newApplicants: addApplicantNote(state.newApplicants, action.givenParams),
        approvedApplicants: addApplicantNote(state.approvedApplicants, action.givenParams),
        declinedApplicants: addApplicantNote(state.declinedApplicants, action.givenParams),
      };
    }
    case APPLICANT_SAVE_NOTES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}

export default applicants;
